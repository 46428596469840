<h5 mat-dialog-title>
    Set a status
</h5>

<mat-dialog-content>
    <ng-container *ngIf="selectedStatus === undefined; else finalizeForm">
        <mat-list *ngFor="let s of status">
            <mat-list-item (click)="selectedStatus = s">
                <img class="icon" [src]="s.icon">
                 {{s.name}}
            </mat-list-item>
        </mat-list>
    </ng-container>

    <ng-template #finalizeForm>
        <div class="form-container">
            <mat-form-field appearance="outline" >
                <input matInput
                    [(ngModel)]="selectedStatus.name" disabled>
    
                <mat-icon 
                    class="material-icons-outlined clear-selectedStatus-icon" 
                    matSuffix
                    (click)="selectedStatus = undefined"
                >highlight_off</mat-icon>
            </mat-form-field>
    
            <mat-form-field appearance="outline">
                <mat-label>
                    Clear After
                </mat-label>
                
                <mat-select disableOptionCentering [(ngModel)]="selectedStatus.time">
                    <ng-container *ngFor="let time of times">
                        <mat-option [value]="time">{{time}}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
        <!-- <form [formGroup]="form"> -->
        <!-- </form> -->
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Cancel</button>
    <button *ngIf="selectedStatus !== undefined" mat-raised-button setColor="primaryColour" color="primary" (click)="save()">Save</button> 
</mat-dialog-actions>