import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {routes} from '@app/consts/routes';
import {features} from '@app/consts';
import { TranslateService } from '@ngx-translate/core';
import { FeatureService } from '@app/core/services/feature.service';
import { modules } from '@app/consts/modules';
import { ModuleConfigurationService } from '@app/core/services/module-configuration/module-configuration.service';

@Component({
    selector: 'toil-menu',
    templateUrl: './toil-menu.component.html',
    styleUrls: ['./toil-menu.component.scss']
})
export class ToilMenuComponent implements OnInit {
    public routes: typeof routes = routes;
    navList = [
        {
            text: this.translate.instant('ToilPolicys'),
            link: `${routes.SITE_SETTINGS}${routes.TOIL}${routes.TOIL_POLICIES}`,
            svg: '/assets/icons/calendar-clock-icon.svg',
            featureCode: features.TOIL
        },
        {
            text: this.translate.instant('Emails'),
            link: `${routes.SITE_SETTINGS}${routes.TOIL}${routes.TOIL_EMAILS}`,
            icon: 'email',
            featureCode: features.TOIL
        },
        
    ];

    constructor(
        private translate: TranslateService,
        public router: Router,
        private featureService: FeatureService,
        private moduleConfigurationService: ModuleConfigurationService,
    ) {
    }

    ngOnInit(): void {
        this.navigateToFirstAllowedSection();
    }

    navigateToFirstAllowedSection() {
        let urlToNavigateTo = '';

        if(this.router.url === `${routes.SITE_SETTINGS}`) {
          this.navList.every(element => {
            let hasAccess = this.featureService.hasFeatureAccess(element.featureCode)

            if(hasAccess){
              urlToNavigateTo = element.link;
              return false;
            }

            return true;
          });
        }

        if(urlToNavigateTo !== ''){
          this.router.navigate([urlToNavigateTo]);
        }
    }
}