export enum features {
    ALLOW = 'allow',
    BENEFITS = "Benefits",
    COST_CENTERS = 'CostCenters',
    CURRENCY = "Currency",
    DASHBOARDS = "Dashboards",
    FORMS = "Forms",
    GLOBALIZATION = "Globalization",
    INTEGRATIONS = "Integrations",
    HOMEPAGE = "Homepage",
    NEWS_FEED = "NewsFeed",
    LISTS = "Lists",
    ORGANIZATION = "Organization",
    POSITIONS = "Positions",
    REPORTS = "Reports",
    SCHEDULES = "Schedules",
    SECURITY = "Security",
    SITE_SETTINGS = "SiteSettings",
    TALENT = "Talent",
    TIME_OFF = "Timeoff",
    TOIL = "Toil",
    TOIL_ADMINISTRATION = "ToilAdministration",
    WORK_LOCATIONS = "WorkLocations",
    WORK_ROTATIONS = "WorkRotations",
    BANK_FEATURES_REPORT = "BankFeaturesReport",
    ORG_REPORT = "OrganizationReport",
    POSITIONS_REPORT = "PositionReport",
    BASIC_EMPLOYEE_DETAILS_REPORT = "BasicEmployeeReport",
    HEAD_COUNT_REPORT = "HeadCountReport",
    STATUTORY_HOLIDAYS = "StatutoryHolidays",
    COUNTRY_CONFIG = "Countries",
    PERFORMANCE = "Performance",
    PERFORMANCE_GOAL_PLANS = "PerformanceGoalPlans",
    PERFORMANCE_GOAL_TYPES = "PerformanceGoalTypes",
    PERFORMANCE_MANAGE_GOAL_PLANS = "PerformanceManageGoalPlans",
    PERFORMANCE_DIRECT_REPORTEE_GOALS = "PerformanceDirectReporteeGoals",
    PERFORMANCE_MANAGER_REVIEW_PLANS = "PerformanceManagerReviewPlans",
    PERFORMANCE_REVIEW_PLANS_ADMINISTRATION = "PerformanceReviewPlansAdministration",
    PERFORMANCE_MY_GOALS = "PerformanceMyGoals",
    ABSENCE_REPORT = "AbsenceReport",
    SITE_STYLING = "SiteStyling",
    BUSINESS_RULE_AUTOMATION = "RuleAutomations",
}
