<app-employee-details>
    <ng-container *ngIf="isLoadingPermissions; else permissionsLoaded">
        <ngx-skeleton-loader
            count="5"
            [theme]="{
                'height.px': 50
            }"
        ></ngx-skeleton-loader>
    </ng-container>
    <ng-template #permissionsLoaded>
        <mat-card class="mat-elevation-z0">
            <mat-card-title class="header">
                <h5 appLocalizationPopupDirective localizationCode="IntegrationDetails">{{ 'IntegrationDetails' | translate: {Default: "Integration Details"} }}</h5>

                <div class="k-flex-grow"></div>

                <div class="buttons-container">
                    <mat-form-field appearance="fill" class="effective-date-form-field">
                        <mat-label>
                            {{ 'EffectiveDate' | translate: {Default: "Effective Date"} }}
                        </mat-label>

                        <input matInput [matDatepicker]="effectiveDatePicker" (dateChange)="effectiveDateChange($event)" [(ngModel)]="effectiveDate">
                        <span fxLayout="row" matSuffix>
                            <mat-datepicker-toggle [for]="effectiveDatePicker"></mat-datepicker-toggle>
                        </span>
                        <mat-datepicker #effectiveDatePicker></mat-datepicker>
                    </mat-form-field>

                    <mat-icon 
                        setColor 
                        [setColorRules]="[
                            {
                                color: 'primaryColour',
                                events: ['hover']
                            }
                        ]"
                        aria-hidden="false" 
                        class="material-icons-outlined k-mr-3 clickable-icon" 
                        aria-label="add icon"
                        (click)="$event.stopPropagation(); openAccessLogsDialog()"
                    >fingerprint</mat-icon>
                    <mat-icon 
                        setColor 
                        [setColorRules]="[
                            {
                                color: 'primaryColour',
                                events: ['hover']
                            }
                        ]"
                        aria-hidden="false" 
                        class="material-icons-outlined k-mr-3 clickable-icon" 
                        aria-label="add icon"
                        (click)="$event.stopPropagation(); openHistoryDialog()"
                    >history</mat-icon>
                    <button
                        mat-raised-button
                        setColor="primaryColour" 
                        color="primary"
                        [disabled]="formValid === false"
                        appPreventDoubleClick
                        (throttledClick)="getFormData = !getFormData"
                        *appTableAccess="[tableCreateAccessObj, tableUpdateAccessObj]">
                        {{ 'Save' | translate: {Default: "Save"} }}
                    </button>
                </div>
            </mat-card-title>
            <mat-card-content>
                <app-form-generator
                    *ngIf="!isLoading"
                    [formId]="formId"
                    [formData]="formData"
                    [emitFormDataTrigger]="getFormData"
                    [targetEmployeeTablePermissions]="targetEmployeeTablePermissions"
                    (emitFormData)="formDataEmitted($event)"
                    (emitFormStatus)="formStatusUpdated($event)"
                ></app-form-generator>
            </mat-card-content>
        </mat-card>
    </ng-template>
</app-employee-details>
