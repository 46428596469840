<div class="footer">
  <div class="footer__link">
    <a class="footer__link-item" >Hubbub HR</a>
    <a class="footer__link-item" >About Us</a>
    <a class="footer__link-item" >Blog</a>
  </div>
  <div class="footer__icon">
    <i class="fab fa-facebook-square"></i>
    <button mat-mini-fab>
      <mat-icon fontSet="fa fa-fs" fontIcon="fa-facebook-square"></mat-icon>
    </button>
    <button mat-mini-fab>
      <mat-icon fontSet="fa fa-fs" fontIcon="fa-twitter-square"></mat-icon>
    </button>
    <button mat-mini-fab>
      <mat-icon fontSet="fa fa-fs" fontIcon="fa-github-square"></mat-icon>
    </button>
  </div>
</div>
