<h5 mat-dialog-title>Notification</h5>

<mat-dialog-content>
    <ng-container *ngIf="!form; else LoadedFormElement">
        <div class="loading-container" style="width:100%;">
            <ngx-skeleton-loader
                count="5"
                [theme]="{
                'height.px': 50
                }"
            ></ngx-skeleton-loader>
        </div>
    </ng-container>

    <ng-template #LoadedFormElement>
        <form class="form" *ngIf="form" [formGroup]="form">

            <mat-form-field appearance="fill">
                <mat-label>
                    Contact Type
                </mat-label>

                <mat-select disableOptionCentering formControlName="type">
                    <ng-container *ngFor="let option of notificationTypes">
                        <mat-option [value]="option.id">{{option.name}}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>

            <div class="slide-toggle-container">
                <mat-slide-toggle setColor="primaryColour" formControlName="active">Active</mat-slide-toggle>
            </div>

            <mat-form-field appearance="fill">
                <mat-label>
                    Subject
                </mat-label>

                <input matInput type="text" formControlName="subject">
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>
                    Recipient
                </mat-label>

                <mat-select disableOptionCentering formControlName="recipientType">
                    <ng-container *ngFor="let option of recipientTypes">
                        <mat-option [value]="option.id">{{option.name}}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>


            <mat-form-field class="example-chip-list" appearance="fill" *ngIf="this.form.get('recipientType').value == 'AutomationsNotificationRecipientTypeSpecific'">
                <mat-label>Emails</mat-label>
                <mat-chip-list #chipList>
                    <mat-chip *ngFor="let email of emails" [selectable]="selectable"
                              [removable]="removable" (removed)="remove(email)">
                        {{email}}
                        <mat-icon matChipRemove *ngIf="removable">x</mat-icon>
                    </mat-chip>
                    <input
                        placeholder="New email..."
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="addOnBlur"
                        (matChipInputTokenEnd)="add($event)">
                </mat-chip-list>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>
                    Body
                </mat-label>

                <textarea matInput formControlName="body" rows="8"></textarea>
            </mat-form-field>


        </form>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button>
</mat-dialog-actions>
