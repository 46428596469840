import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { FormElement } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { GoalPlan } from '@app/modules/performance/components/goal-plans/models/goal-plan.model';
import { GoalPlanService } from '@app/modules/performance/components/goal-plans/services/goal-plan.service';
import { EmployeeSecurityService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-security/services/employee-security.service';
import { finalize } from 'rxjs';
import { SelectGoalPlanDialogComponent } from './components/select-goal-plan-dialog/select-goal-plan-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-goal-plan-selection-form-field',
  templateUrl: './goal-plan-selection-form-field.component.html',
  styleUrls: ['./goal-plan-selection-form-field.component.scss']
})
export class GoalPlanSelectionFormFieldComponent implements OnInit {
  @Input() formElement: FormElement;
  @Input() parentGroup: UntypedFormGroup;
  @Input() readOnly: boolean;
  @ViewChild('goalPlanSelect') goalPlanSelect;
  goalPlanOptions: GoalPlan[] = [];
  isLoading: boolean;
  user$: any;
  filterString: string;

  constructor(
    public translate: TranslateService,
    private goalService: GoalPlanService,
    private oidcAuthService: OidcAuthService,
    private employeeSecurityService: EmployeeSecurityService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.user$ = this.oidcAuthService.userProfile;

    if(this.parentGroup.controls[this.formElement.formControl].value !== null){
      this.getGoalPlans();
    }
  }

  getGoalPlans() {
    this.isLoading = true;

    if(this.parentGroup.controls[this.formElement.formControl].value !== undefined){
      this.filterString = `(id = "${this.parentGroup.controls[this.formElement.formControl].value}")`
    }

    this.goalService.getGoalPlans('10', 0, null, this.filterString)
    .pipe(
      finalize(() => this.isLoading = false)
    )
    .subscribe(
      (res) => {
        this.goalPlanOptions = res.data
      }
    );
  }

  openSelectGoalPlanDialog() {
    this.goalPlanSelect.close();

    if(!this.readOnly){
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      const dialogRef = this.dialog.open(SelectGoalPlanDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        data => {
          if (data !== undefined) {
            this.setValue(data)
          }
        }
      );
    }
  }

  clearSelectedPosition() {
    this.goalPlanSelect.close();
    this.parentGroup.controls[this.formElement.formControl].setValue(null);
  }

  setValue(selectedGoalPlan: GoalPlan) {
    this.parentGroup.controls[this.formElement.formControl].setValue(selectedGoalPlan.id);
    this.getGoalPlans();
  }

}
