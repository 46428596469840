<h5 mat-dialog-title appLocalizationPopupDirective [localizationCode]="'AccrueToil'">
    {{ 'AccrueToil' | translate: {Default: "Accrue TOIL"} }}
</h5>

<mat-dialog-content>
    <form *ngIf="extraFieldsForm" [formGroup]="extraFieldsForm">
        <mat-form-field appearance="fill">
            <mat-label>
                {{formElements[0].text}}
            </mat-label>

            <mat-select disableOptionCentering formControlName="toilPolicyId">
                <mat-option></mat-option>
                <mat-option *ngFor="let option of employeeToilPolicies" [value]="option.toilPolicy?.id">{{option.toilPolicy?.toilType?.name}} - {{option.toilPolicy?.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
    
    <app-form-generator 
        [formId]="formId"
        [formData]="formData"
        [emitFormDataTrigger]="getFormData"
        [targetEmployeeTablePermissions]="targetEmployeeTablePermissions"
        (emitFormData)="formDataEmitted($event)"
        (emitFormPristine)="formPristineEmitted($event)"
        (emitFormStatus)="formStatusUpdated($event)"
    ></app-form-generator>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
    <button 
        *ngIf="readOnly !== true"
        mat-raised-button 
        setColor="primaryColour" 
        color="primary" 
        [disabled]="formValid === false" 
        appPreventDoubleClick 
        (throttledClick)="getFormData = !getFormData"> 
    {{ 'Save' | translate: {Default: "Save"} }}</button>
</mat-dialog-actions>
