import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { NewReportDialogComponent } from './components/new-report-dialog/new-report-dialog.component';
import { ReportsService } from './services/reports.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  reports$: Observable<any>;
  public displayedColumns: string[] = ['name'];

  constructor(
    private dialog: MatDialog,
    private reportsService: ReportsService,
  ) { 
    this.reports$ = reportsService.getReports();
  }

  ngOnInit(): void {
  }

  openNewReportDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 1200;

    const dialogRef = this.dialog.open(NewReportDialogComponent, dialogConfig);
  }

}
