<h5 mat-dialog-title>{{ 'UpdateTranslationCode' | translate: {Default: "Update Translation Code"} }}</h5>

<mat-dialog-content>
    <form class="form" [formGroup]="form">
        <mat-form-field appearance="fill">
            <mat-label>
                {{ 'Culture' | translate: {Default: "Culture"} }}
            </mat-label>
    
            <mat-select disableOptionCentering formControlName="cultureId">
                <mat-option></mat-option>
                <mat-option 
                    *ngFor="let data of cultures" 
                    [value]="data.id">
                        {{data.text}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                {{ 'TranslationCode' | translate: {Default: "Translation Code"} }}
            </mat-label>
            
            <input 
                matInput
                type="text"
                formControlName="systemId">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                {{ 'Translation' | translate: {Default: "Translation"} }}
            </mat-label>
            
            <input 
                matInput
                type="text"
                formControlName="text">
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="updateTranslation()" [disabled]="!form.valid" *ngIf="form">{{ 'Save' | translate: {Default: "Save"} }}</button> 
</mat-dialog-actions>