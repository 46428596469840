import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import { ToilPolicy } from '@app/modules/lookup/models/toil-policy.model';
import { TimeOff } from '@app/modules/lookup/models/time-off.model';
import { TimeOffInLieuService } from '@app/core/services/time-off-in-lieu/time-off-in-lieu.service';
import { RefreshListener } from '@app/shared/models/refresh-listener';
import { TimeOffListVariant } from '@app/modules/lookup/models/time-off-list-variant.model';
import { ToilExpiryPointType } from "@app/modules/lookup/models/toil-expiry-point-type.model";
import { ToilExpiryPeriodUnitType } from "@app/modules/lookup/models/toil-expiry-period-unit-type.model";
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'toil-policy',
    templateUrl: './toil-policy.component.html',
    styleUrls: ['./toil-policy.component.scss']
})
export class ToilPolicyComponent implements OnInit {

    @Input() timeOffType: TimeOffListVariant;
    @Input() timeOffPolicyId: string;
    @Input() public timeOffTypeList: TimeOff[] = [];
    @Input() refreshListener: RefreshListener;

    isLoading: boolean;
    toilPolicy: ToilPolicy;
    policyForm: UntypedFormGroup;
    cultures: Culture[];
    toilExpiryPeriodUnitTypes: ToilExpiryPeriodUnitType[];
    toilExpiryPointList: ToilExpiryPointType[];
    months = [
        { value: 1, text: "January" },
        { value: 2, text: "February" },
        { value: 3, text: "March" },
        { value: 4, text: "April" },
        { value: 5, text: "May" },
        { value: 6, text: "June" },
        { value: 7, text: "July" },
        { value: 8, text: "August" },
        { value: 9, text: "September" },
        { value: 10, text: "October" },
        { value: 11, text: "November" },
        { value: 12, text: "December" }
    ];

    constructor(
        private translate: TranslateService,
        private snackbarService: SnackbarService,
        private fb: UntypedFormBuilder,
        private overlayService: OverlayService,
        private timeOffInLieuService: TimeOffInLieuService,
        private dialog: MatDialog,
        private cultureService: CultureService,
    ) {
    }

    ngOnInit(): void {
        this.cultureService.getCultures().subscribe(res => {
            this.cultures = res;
        });
        this.timeOffInLieuService.getToilExpiryPeriodUnitTypes().subscribe(res => {
            this.toilExpiryPeriodUnitTypes = res;
        });
        this.timeOffInLieuService.getToilExpiryPointTypes().subscribe(res => {
            this.toilExpiryPointList = res;
        });

        if (this.timeOffPolicyId) {
            this.getTimeOffPolicy(this.timeOffType.id, this.timeOffPolicyId);
        } else {
            this.createPolicyForm();
        }
    }

    private getTimeOffPolicy(timeOffTypeId: string, timeOffPolicyId: string) {
        this.isLoading = true;

        this.timeOffInLieuService.getToilPolicyById(timeOffTypeId, timeOffPolicyId)
        .subscribe(
            timeOffPolicy => {
                this.toilPolicy = timeOffPolicy;
                this.isLoading = false;
                this.createPolicyForm();
            }
        );
    }


    createPolicyForm() {

        this.policyForm = this.fb.group({
            id: this.toilPolicy ? this.toilPolicy.id : null,
            localizations: this.fb.array([]),
            maximumAccrualHours: [this.toilPolicy?.maximumAccrualHours ? this.toilPolicy.maximumAccrualHours : null],
            expiryPeriodLength: [this.toilPolicy?.expiryPeriodLength ? this.toilPolicy.expiryPeriodLength : null],
            expiryPeriodUnit: [this.toilPolicy?.expiryPeriodUnit ? this.toilPolicy.expiryPeriodUnit.id : null],
            expiryPoint: [this.toilPolicy?.expiryPoint ? this.toilPolicy?.expiryPoint.id : null],
            allowNegativeHours: [this.toilPolicy ? this.toilPolicy.allowNegativeHours === true : false],
            timeOffType: [this.timeOffType ? this.timeOffType.id : null, Validators.required]
        });
        if (this.toilPolicy) {
            this.toilPolicy.name.forEach(
                localization => {
                    this.addNewLocalization(localization.culture, localization.text);
                }
            );
        } else {
            this.addNewLocalization();
        }
    }


    get localizations() {
        return this.policyForm.controls['localizations'] as UntypedFormArray;
    }

    //Create a textLocalization form group object to add to the localizations form array
    addNewLocalization(culture?: string, localization?: string) {
        const localizationForm = this.fb.group({
            culture: [culture || '', Validators.required],
            text: [localization || '', Validators.required]
        });

        this.localizations.push(localizationForm);
    }

    deleteLocalization(index: number) {
        this.localizations.removeAt(index);
    }

    savePolicy() {
        let timeOffPolicyData = {
            maximumAccrualHours: this.policyForm.value.maximumAccrualHours,
            allowNegativeHours: this.policyForm.value.allowNegativeHours,
            name: this.policyForm.value.localizations,
            expiryPeriodLength: this.policyForm.value.expiryPeriodLength,
            expiryPeriodUnitType: this.policyForm.value.expiryPeriodUnit,
            expiryPointType: this.policyForm.value.expiryPoint,
        };

        this.isLoading = true;
        if (this.policyForm.value.id) {
            this.timeOffInLieuService.updateToilPolicy(this.policyForm.value.timeOffType, this.policyForm.value.id, timeOffPolicyData)
                .subscribe(
                    {
                        next: (res) => {
                            this.isLoading = false;
                            this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
                            this.refreshListener.refresh();
                        },
                        error: (e) => {
                            this.isLoading = false;
                            this.snackbarService.openSnackBar(e, 'clear', 'warn');
                        },
                        complete: () => console.info('complete') 
                    }
                );
        } else {
            this.timeOffInLieuService.createToilPolicy(this.policyForm.value.timeOffType, timeOffPolicyData)
            .subscribe(
                {
                    next: (res) => {
                        this.isLoading = false;
                        this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
                        this.refreshListener.refresh();
                    },
                    error: (e) => {
                        this.isLoading = false;
                        this.snackbarService.openSnackBar(e, 'clear', 'warn');
                    },
                    complete: () => console.info('complete') 
                }
            );
        }
    }

    close() {
        this.refreshListener.cancel();
    }

}
