<div class="auth-page">
    <div class="auth-page__content-block">
      <div class="auth-page__content-wrapper">
        <mat-tab-group class="auth-page__group">
          <mat-tab label="Login">
            <h4 class="auth-page__group-title">Good Morning, User</h4>
            <div class="auth-page__google-button-wrapper">
              <button mat-raised-button class="auth-page__google-button">
                <img class="auth-page__google-button-icon" src="./assets/auth/google.svg" alt="google">Sign in with Google
              </button>
            </div>
  
            <div class="auth-page__border-wrapper">
              <div class="auth-page__border-line"></div>
              <p class="auth-page__border-title">or</p>
              <div class="auth-page__border-line"></div>
            </div>
  
            <app-login></app-login>
  
          </mat-tab>
          <mat-tab label="New User">
            <h4 class="auth-page__group-title">Welcome!</h4>
            <p class="auth-page__group-sub-title">Create you account</p>
  
            <!-- <app-register></app-register> -->
  
            <div class="auth-page__border-wrapper">
              <div class="auth-page__border-line"></div>
              <p class="auth-page__border-title">or</p>
              <div class="auth-page__border-line"></div>
            </div>
  
            <div class="auth-page__google-button-wrapper">
              <button mat-raised-button class="auth-page__google-button">
                <img class="auth-page__google-button-icon" src="./assets/auth/google.svg" alt="google">Sign in with Google
              </button>
            </div>
          </mat-tab>
        </mat-tab-group>
  
        <p class="auth-page__footer-title">© 2014-{{todayDate}} Hubbub HR, LLC. All rights reserved.</p>
      </div>
    </div>
    <div class="auth-page__logo">
      <div class="auth-page__logo-wrapper">
        <img class="auth-page__logo-img" src="./assets/HubbubHrLogo.svg" alt="logo">
        <!-- <h6 class="auth-page__logo-title">Hubbub HR</h6> -->
      </div>
    </div>
  </div>
  