<app-security-section>
<div class="main-content">
    <div class="row">
        <app-api-users-data-grid></app-api-users-data-grid>
        
        <app-total-calls-chart></app-total-calls-chart>
    </div>
    
    <div class="">
        <app-total-user-calls-chart></app-total-user-calls-chart>
    </div>
</div>
</app-security-section>
