<kendo-grid
    [kendoGridBinding]="gridData"
    [pageSize]="10"
    [pageable]="true"
    [sortable]="true"
    [height]="420"
    (cellClick)="cellClickHandler($event)"
    [rowClass]="rowCallback"
>

    <kendo-grid-column field="fullName" title="Full Name" [width]="250">
        <ng-template kendoGridCellTemplate let-dataItem>

            <div class="employee-name">
                <app-employee-image class="profile-pic" [width]="50" [height]="50" [round]="true" [employeeId]="dataItem.employeeId"></app-employee-image> {{getEmployeeName(dataItem.employeeId)}}
            </div>

        </ng-template>
    </kendo-grid-column>

    <ng-container
    *ngFor="let col of columns"
    >
        <kendo-grid-column
            *ngIf="!col.type || col.type === 'navigation'"
            [field]="col.field"
            [title]="col.title"
            [width]="200">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="grid-cell-vertical">
                    <ng-container *ngIf="col.subField && dataItem[col.field]; else noSubField">
                        {{dataItem[col.field][col.subField]}}
                    </ng-container>
                    <ng-template #noSubField>
                        {{dataItem[col.field]}}
                    </ng-template>
                </div>
            </ng-template>
        </kendo-grid-column>

        <!-- Limit Text Length Column Data -->
        <kendo-grid-column
            *ngIf="col.type === 'longText'"
            [field]="col.field"
            [title]="col.title"
            [width]="200">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="grid-cell-vertical">
                    <ng-container *ngIf="col.subField && dataItem[col.field]; else noSubField">
                        {{ (dataItem[col.field][col.subField].length > 40) ? (dataItem[col.field][col.subField] | slice:0:40) + '...' : (dataItem[col.field][col.subField]) }}
                    </ng-container>
                    <ng-template #noSubField>
                        {{ (dataItem[col.field].length > 40) ? (dataItem[col.field] | slice:0:40) + '...' : (dataItem[col.field]) }}
                    </ng-template>
                </div>
            </ng-template>
        </kendo-grid-column>

        <!-- Date Column Data -->
        <kendo-grid-column
            *ngIf="col.type === 'date'"
            [field]="col.field"
            [title]="col.title"
            [width]="200">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="grid-cell-vertical">
                    <span>
                        {{dataItem[col.field] | date:'mediumDate' }}
                    </span>
                </div>
            </ng-template>
        </kendo-grid-column>

        <!-- Percentage Column Data -->
        <kendo-grid-column
            *ngIf="col.type === 'percentage'"
            [field]="col.field"
            [title]="col.title"
            [width]="200">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="grid-cell-vertical percentage-column">
                    <span class="percentage-value" *ngIf="dataItem[col.field] !== null">
                        <ng-container *ngIf="col.percentageMaxValue !== undefined; else showValue">
                            {{ calculateSliderPercentage(dataItem[col.field], col.percentageMaxValue) }}%
                        </ng-container>
                        <ng-template #showValue>
                            {{ dataItem[col.field] }}%
                        </ng-template>
                    </span>

                    <ng-container *ngIf="col.percentageMaxValue !== undefined; else showProgress">
                        <mat-progress-bar mode="determinate" [value]="calculateSliderPercentage( dataItem[col.field], col.percentageMaxValue )"></mat-progress-bar>
                    </ng-container>
                    <ng-template #showProgress>
                        <mat-progress-bar mode="determinate" [value]="dataItem[col.field]"></mat-progress-bar>
                    </ng-template>

                </div>
            </ng-template>
        </kendo-grid-column>
    </ng-container>

    <kendo-grid-messages 
        [noRecords]="isLoading ? '' : translate.instant('Grid-NoRecordsAvailable')"
        [pagerItemsPerPage]="translate.instant('Grid-Pager-ItemsPerPage')" 
        [pagerItems]="translate.instant('Grid-Pager-Items')"
        [pagerOf]="translate.instant('Grid-Pager-Of')"
    ></kendo-grid-messages>
</kendo-grid>