import { Component, OnInit } from '@angular/core';
import { EmployeeService } from '@app/core/services/employee.service';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { CurrentUserDetails } from '@app/shared/models/employee.model';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { interval, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-welcome-message',
  templateUrl: './welcome-message.component.html',
  styleUrls: ['./welcome-message.component.scss']
})
export class WelcomeMessageComponent implements OnInit {
  user$: any;
  currentEmployeeDetails: CurrentUserDetails;
  pageLoaded: Moment;
  time: Observable<string>;
  greeting:  Observable<string>;

  constructor(
    private employeeService: EmployeeService,
    private translate: TranslateService,
    private oidcAuthService: OidcAuthService
  ) { 
    this.user$ = this.oidcAuthService.userProfile;
  }

  ngOnInit(): void {
    this.getEmployeeDetails();

    this.time = interval(1000).pipe( // why you need 1s interval with HH:mm time format simply update it every minute not every second.
      map(() => {
        moment.locale(this.currentEmployeeDetails?.culture?.id);
        this.pageLoaded = moment(new Date()); // you need the value of now not the value of the initialized time.
        return this.pageLoaded.format("ddd MMM D, YYYY HH:mm A");
      })
    );

    this.greeting = interval(1000).pipe( // why you need 1s interval with HH:mm time format simply update it every minute not every second.
      map(() => {
        return this.getGreeting();
      })
    );
  }

  getEmployeeDetails() {
    this.employeeService.getCurrentUserDetails()
    .subscribe(
      res => {
        this.currentEmployeeDetails = res;
      }
    )
  }

  getGreeting(): string {
    let now = moment(new Date(), 'HH:mm');
    let midNight = moment("00:00", 'HH:mm');
    let twoAM = moment("02:00", 'HH:mm');
    let noon = moment("12:00", 'HH:mm');
    let sevenPM = moment("19:00", 'HH:mm');

    if(now.isBetween(twoAM, noon)){
      return this.translate.instant('GoodMorning');
    }
    else if(now.isBetween(noon, sevenPM)) {
      return this.translate.instant('GoodAfternoon');
    }
    else if(now.isSameOrAfter(sevenPM) || now.isBetween(midNight, twoAM)) {
      return this.translate.instant('GoodEvening');
    }
    else {
      return 'Hi';
    }
  }

}
