import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import { MetadataFormService } from '@app/core/services/metadata/metadata-form.service';
import { Form } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { ChangeReasonDialogComponent } from '../change-reason-dialog/change-reason-dialog.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-form-generator-dialog',
  templateUrl: './form-generator-dialog.component.html',
  styleUrls: ['./form-generator-dialog.component.scss']
})
export class FormGeneratorDialogComponent implements OnInit {
  @Input() formTitle: string;
  @Input() formId: string;
  @Input() changeReasonFormId: string;
  @Input() formData: any;
  @Output() emitFormData: EventEmitter<void> = new EventEmitter<void>();
  
  getFormData: boolean = false;
  formValid: boolean = false;
  loadingFormDetails: boolean = true;
  formPristine: boolean = true;
  formDetails: Form;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private metadataFormService: MetadataFormService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<FormGeneratorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data)
  {
    this.formTitle = data.formTitle;
    this.formId = data.formId;
    this.changeReasonFormId = data.changeReasonFormId;
    this.formData = data.formData;
    this.targetEmployeeTablePermissions = data.targetEmployeeTablePermissions;

    router.events.forEach((event) => {
      if(event instanceof NavigationStart) {
        this.dialogRef.close();
      }
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });
  } 

  ngOnInit(): void {
    this.getFormDetails();
  }

  // Get the form details to find the table assigned to the form 
  getFormDetails() {
    this.loadingFormDetails = true;
    
    this.metadataFormService.getForm(this.formId)
    .pipe(
      finalize(() => this.loadingFormDetails = false)
    )
    .subscribe( 
      res => {
        this.formDetails = res; 
      }
    );
  }

  get tableReadAccessObj() {
    return {
      tableId: this.formDetails.table ? this.formDetails.table.id : 'no-table',
      permission: 'read',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableCreateAccessObj() {
    return {
      tableId: this.formDetails.table ? this.formDetails.table.id : 'no-table',
      permission: 'create',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableUpdateAccessObj() {
    return {
      tableId: this.formDetails.table ? this.formDetails.table.id : 'no-table',
      permission: 'update',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableDeleteAccessObj() {
    return {
      tableId: this.formDetails.table ? this.formDetails.table.id : 'no-table',
      permission: 'delete',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  openChangeReasonDialog(formData: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formId: this.changeReasonFormId,
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions,
    };

    const dialogRef = this.dialog.open(ChangeReasonDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.addChangeReasonsToFormDataAndEmit(formData, data);
        }
      }
    );
  }

  openConfirmCloseDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      text: this.translate.instant('UnsavedChangesMessage')
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.dialogRef.close();
        }
      }
    ); 
  }

  addChangeReasonsToFormDataAndEmit(formData: any, changeReasonFormData: any) {
    let merged = {...formData, ...changeReasonFormData};
    this.emitFormData.emit(merged);
  }

  formDataEmitted(formData) {
    //if there is no change reason form id emit form data, if there is open the change reason dialog
    if(this.changeReasonFormId === undefined){
      this.emitFormData.emit(formData);
    }
    else {
      this.openChangeReasonDialog(formData);
    }
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  formPristineEmitted(formPristine: boolean) {
    this.formPristine = formPristine;
  }

  close(){
    this.formPristine ? this.dialogRef.close() : this.openConfirmCloseDialog();
  }

}
