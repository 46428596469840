<script src="orgchart.js"></script>
<style>
  ::ng-deep [data-n-id='{{chartTitle}}'] rect {
    fill: #fcd9a7;
  }
</style>
<mat-card>
  <mat-card-title class="header">
    <h5>{{chartTitle}}</h5>
  </mat-card-title>
  <button mat-stroked-button setColor="primaryColour" color="primary" (click)="toggleTreeLayout()">Toggle Layout</button>
  <button mat-stroked-button setColor="primaryColour" color="primary" (click)="expandCollapse()">Expand All</button>
  <button mat-stroked-button id ="showFiltersButton" setColor="primaryColour" color="primary" (click)="showFilters()">Show Filters</button>
  <mat-card-content>
    <div id="OrgTree" class="OrgTreeContainer">
    </div>

  </mat-card-content>

</mat-card>
