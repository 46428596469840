import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { EmergencyContact } from '../models/emergency-contact.model';
import { api_routes } from '@app/consts';
import { catchError, map } from 'rxjs/operators';
import { EnvironmentService } from "@app/core/services/environment.service";


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class EmergencyContactService {

  constructor(private http: HttpClient, private envService: EnvironmentService) {}

  getEmergencyContacts(employeeId: string, take?: string, skip?: number, sortString?: string): Observable<any> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    sortString ? params = params.append('Sort', sortString) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.EMERGENCY_CONTACTS}`, {
      headers: headers,
      params: params
    })
    .pipe(
      catchError(err => throwError(err)),
      map(res => {
        let data = {
          data: res.data,
          total: res.total
        }

        return data;
      })
    );
  }

  getEmergencyContact(employeeId: string, emergencyContactId: string): Observable<EmergencyContact> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.EMERGENCY_CONTACTS}/${emergencyContactId}`, {
      headers: headers
    });
  }

  deleteEmergencyContact(employeeId: string, emergencyContactId: string) {
    return this.http.delete<any>(
      `${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.EMERGENCY_CONTACTS}/${emergencyContactId}`, {
      headers: headers
    });
  }

  createNewEmergencyContact(employeeId: string, emergencyContactSubmit: any): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.EMERGENCY_CONTACTS}`, emergencyContactSubmit, {
      headers: headers
    });
  }

  updateEmergencyContact(employeeId: string, emergencyContactSubmit: any): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.EMERGENCY_CONTACTS}/${emergencyContactSubmit.id}`, emergencyContactSubmit, {
      headers: headers
    });
  }
}
