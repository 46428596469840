/* tslint:disable:whitespace */
/* tslint:disable:max-line-length */

export const employees = [{"id":"848e6002-8a92-447d-951b-1ffd5e695578","full_name":"Sig Jeannel","job_title":"Human Resources Assistant III","country":"US","is_online":true,"rating":3,"target":100,"budget":47601,"phone":"(936) 9429601","address":"138 Buhler Avenue","img_id":1,"gender":"M"},
{"id":"19d18d40-0e64-4837-9420-92130a0ed253","full_name":"Shelden Greyes","job_title":"Operator","country":"GB","is_online":true,"rating":5,"target":40,"budget":12253,"phone":"(343) 6656271","address":"2 Waxwing Point","img_id":2,"gender":"M"},
{"id":"bebdc6eb-9179-484a-917d-2e16a23bfdfe","full_name":"Megen Cody","job_title":"Operator","country":"BR","is_online":true,"rating":1,"target":66,"budget":96183,"phone":"(178) 2336256","address":"4082 Stephen Court","img_id":6,"gender":"F"},
{"id":"38b08b88-e482-46fc-8976-83590c02ec23","full_name":"Clevey Thursfield","job_title":"VP Quality Control","country":"BR","is_online":true,"rating":2,"target":58,"budget":54936,"phone":"(277) 7415010","address":"1563 Glacier Hill Parkway","img_id":5,"gender":"M"},
{"id":"2aac53f8-b72d-4629-9082-6d8239a8fecf","full_name":"Ruthi Baldini","job_title":"Data Coordiator","country":"BR","is_online":true,"rating":3,"target":37,"budget":46572,"phone":"(766) 5691615","address":"6 Laurel Avenue","img_id":8,"gender":"F"},
{"id":"1aa789e5-de01-406e-a2ee-cc5ce20f7e34","full_name":"Annecorinne Morter","job_title":"Professor","country":"FR","is_online":false,"rating":2,"target":35,"budget":37198,"phone":"(807) 2524830","address":"106 Green Street","img_id":3,"gender":"F"},
{"id":"d2ff1b02-3808-44aa-9056-3b5df34bf865","full_name":"Gracia Punyer","job_title":"Assistant Manager","country":"ES","is_online":true,"rating":4,"target":64,"budget":84752,"phone":"(515) 9749536","address":"69 Brentwood Alley","img_id":2,"gender":"F"},
{"id":"26b2b760-27e8-47a6-81c2-07870d1b2b30","full_name":"Duky Hurring","job_title":"Account Executive","country":"BR","is_online":false,"rating":3,"target":61,"budget":-1266,"phone":"(897) 7202034","address":"39 Morning Circle","img_id":3,"gender":"M"},
{"id":"91c6b652-4206-4a0c-bac6-dc21283a72d7","full_name":"Briana Shemelt","job_title":"Professor","country":"US","is_online":false,"rating":3,"target":63,"budget":-9308,"phone":"(205) 2560799","address":"11 Walton Court","img_id":2,"gender":"F"},
{"id":"1e8289dc-2ef3-4045-ad6b-786d00368427","full_name":"Lexis Mostin","job_title":"Analyst Programmer","country":"FR","is_online":true,"rating":4,"target":81,"budget":38153,"phone":"(903) 8388089","address":"38547 Westend Way","img_id":4,"gender":"F"},
{"id":"797387bd-c247-48b3-97b6-5e75791f8809","full_name":"Felizio Gooda","job_title":"GIS Technical Architect","country":"DE","is_online":true,"rating":3,"target":89,"budget":81585,"phone":"(372) 2389397","address":"9 Summer Ridge Circle","img_id":2,"gender":"M"},
{"id":"24c541b0-4978-4072-84d0-abe94fcd0756","full_name":"Aubry Oxberry","job_title":"Financial Advisor","country":"BR","is_online":false,"rating":2,"target":3,"budget":-6095,"phone":"(665) 4176083","address":"06 Lerdahl Point","img_id":10,"gender":"F"},
{"id":"d3416440-7411-42cc-a913-7dd319ca8311","full_name":"Orly Glasbey","job_title":"Environmental Tech","country":"BR","is_online":true,"rating":5,"target":63,"budget":63945,"phone":"(449) 8482879","address":"4035 Porter Parkway","img_id":6,"gender":"F"},
{"id":"139066b5-60c5-4cf5-9afe-fb4e5558b087","full_name":"Stephanus Culp","job_title":"Cost Accountant","country":"BR","is_online":false,"rating":2,"target":60,"budget":10613,"phone":"(148) 3124030","address":"57028 Moland Terrace","img_id":8,"gender":"M"},
{"id":"eb844971-b97d-4f79-bd5a-a266fcfaaf70","full_name":"Roseanna Janecek","job_title":"Database Administrator IV","country":"FR","is_online":false,"rating":4,"target":97,"budget":77351,"phone":"(125) 4421623","address":"21973 Beilfuss Alley","img_id":4,"gender":"F"},
{"id":"5cb391fe-4855-445c-a8b8-617c04d1d999","full_name":"Weidar McCombe","job_title":"Civil Engineer","country":"FR","is_online":true,"rating":1,"target":77,"budget":35924,"phone":"(488) 7911627","address":"7 Dahle Terrace","img_id":1,"gender":"M"},
{"id":"4ba9ad7e-d8b7-40e7-b8cd-67a8e743a249","full_name":"Evelin Spirritt","job_title":"Analyst Programmer","country":"BR","is_online":false,"rating":2,"target":18,"budget":58552,"phone":"(821) 9538078","address":"89418 Knutson Pass","img_id":1,"gender":"M"},
{"id":"e4a31407-39d1-4ab7-aad1-4e026a8c42fa","full_name":"Andria Helbeck","job_title":"Nurse Practicioner","country":"BR","is_online":true,"rating":4,"target":53,"budget":72526,"phone":"(387) 9614638","address":"8589 Vernon Drive","img_id":8,"gender":"F"},
{"id":"cd050499-dbf1-4a43-86ab-54feaacef401","full_name":"Mariellen Ravelus","job_title":"Systems Administrator I","country":"DE","is_online":true,"rating":2,"target":22,"budget":-6659,"phone":"(300) 6741661","address":"707 Gale Hill","img_id":9,"gender":"F"},
{"id":"fda1c419-c0b8-4278-94b0-2ebef6bda164","full_name":"Corri Pergens","job_title":"Help Desk Operator","country":"BR","is_online":true,"rating":2,"target":74,"budget":12376,"phone":"(769) 7145603","address":"856 Forest Crossing","img_id":3,"gender":"F"},
{"id":"4a962db9-136e-4fcc-a683-2f961e27fb6e","full_name":"Friedrick Macknish","job_title":"Human Resources Assistant II","country":"FR","is_online":true,"rating":2,"target":38,"budget":97848,"phone":"(196) 4156385","address":"157 Talisman Trail","img_id":9,"gender":"M"},
{"id":"fa47e456-83c4-4fdc-b57b-07effb279967","full_name":"Georgette Trevorrow","job_title":"VP Accounting","country":"FR","is_online":true,"rating":3,"target":3,"budget":17327,"phone":"(319) 1412549","address":"07 Marquette Point","img_id":3,"gender":"F"},
{"id":"67656444-fbcd-4813-84e7-1bdcd82f803e","full_name":"Vanya Goalby","job_title":"Senior Cost Accountant","country":"FR","is_online":false,"rating":2,"target":77,"budget":33584,"phone":"(496) 7538982","address":"2192 Iowa Lane","img_id":6,"gender":"F"},
{"id":"05c098c2-9617-4e66-928a-e29b97297138","full_name":"Abel Ansell","job_title":"Actuary","country":"US","is_online":false,"rating":4,"target":52,"budget":12312,"phone":"(571) 9908377","address":"4355 Iowa Parkway","img_id":4,"gender":"M"},
{"id":"3b263e99-51bf-4461-bb20-d4303d326cd9","full_name":"Odille Barus","job_title":"Speech Pathologist","country":"FR","is_online":true,"rating":4,"target":60,"budget":46911,"phone":"(974) 1137672","address":"496 Lotheville Avenue","img_id":8,"gender":"F"},
{"id":"c09ddfaf-569e-4a75-8e53-1be27cf25927","full_name":"Rudolf Consadine","job_title":"Structural Analysis Engineer","country":"FR","is_online":true,"rating":4,"target":20,"budget":94258,"phone":"(450) 7612220","address":"93 Thierer Park","img_id":6,"gender":"M"},
{"id":"a48abbc9-6143-4f77-a720-0b76ad862a9a","full_name":"Christabel Bick","job_title":"Engineer III","country":"FR","is_online":true,"rating":5,"target":0,"budget":65359,"phone":"(361) 5159566","address":"191 Bunting Pass","img_id":5,"gender":"F"},
{"id":"d8816aee-4230-4392-a045-0a9c297451f7","full_name":"Lancelot Tanzer","job_title":"Senior Developer","country":"US","is_online":true,"rating":5,"target":80,"budget":13246,"phone":"(502) 3949900","address":"4287 Corben Plaza","img_id":8,"gender":"M"},
{"id":"9f5af32c-fb0d-4449-a658-f2c7f182b63a","full_name":"Bink Byk","job_title":"Software Engineer I","country":"FR","is_online":false,"rating":3,"target":17,"budget":56472,"phone":"(475) 9070061","address":"075 Eggendart Avenue","img_id":9,"gender":"M"},
{"id":"dbd7f48a-6540-4560-bb7c-cd60456b55b8","full_name":"Rhys Pheazey","job_title":"Speech Pathologist","country":"BR","is_online":false,"rating":5,"target":98,"budget":62483,"phone":"(365) 8904529","address":"987 Carioca Lane","img_id":9,"gender":"M"},
{"id":"844189fe-d36e-4e06-9761-932259701fc8","full_name":"Lyndell Howieson","job_title":"Speech Pathologist","country":"DE","is_online":true,"rating":2,"target":82,"budget":16694,"phone":"(461) 3130038","address":"9 Pennsylvania Crossing","img_id":8,"gender":"F"},
{"id":"5544d9d5-40ea-4e5a-8fb8-f5016b313b8f","full_name":"Cassey Fitchell","job_title":"Software Engineer III","country":"FR","is_online":true,"rating":2,"target":10,"budget":91253,"phone":"(985) 5060547","address":"652 Merry Place","img_id":9,"gender":"F"},
{"id":"1c669764-3cff-424a-b7e4-e7711d6c7d6c","full_name":"Coralyn Steljes","job_title":"Accounting Assistant III","country":"FR","is_online":false,"rating":2,"target":81,"budget":-9871,"phone":"(760) 5696853","address":"4 Norway Maple Pass","img_id":9,"gender":"F"},
{"id":"4cc649dc-7f85-40ab-bc59-950f37268a65","full_name":"Bruis Creavin","job_title":"Nuclear Power Engineer","country":"BR","is_online":false,"rating":1,"target":59,"budget":-5798,"phone":"(570) 8801169","address":"71 Carioca Park","img_id":6,"gender":"M"},
{"id":"8c9505f1-e8f8-4ba6-8c9a-5a1d089c6f09","full_name":"Adrianne Peery","job_title":"Chief Design Engineer","country":"FR","is_online":false,"rating":2,"target":2,"budget":56575,"phone":"(793) 1143493","address":"3 2nd Drive","img_id":7,"gender":"F"},
{"id":"ced0905e-44ac-4317-a203-76994e53a751","full_name":"Port Gerauld","job_title":"Senior Cost Accountant","country":"FR","is_online":true,"rating":2,"target":27,"budget":97919,"phone":"(155) 5488067","address":"9 High Crossing Center","img_id":5,"gender":"M"},
{"id":"f0bcc7cd-999e-4611-a04f-7bdc16bc6160","full_name":"Boy Antoszewski","job_title":"VP Accounting","country":"GB","is_online":true,"rating":2,"target":11,"budget":74779,"phone":"(715) 9192627","address":"3773 Hazelcrest Road","img_id":6,"gender":"M"},
{"id":"322540e3-b72e-4b7d-ae4a-d927911340b7","full_name":"Gib Yakebowitch","job_title":"Marketing Assistant","country":"BR","is_online":false,"rating":5,"target":57,"budget":89028,"phone":"(923) 6953600","address":"7 Mariners Cove Point","img_id":8,"gender":"M"},
{"id":"b1ed798d-6177-4a27-9571-2e02be932214","full_name":"Minerva Gilders","job_title":"Administrative Officer","country":"BR","is_online":false,"rating":3,"target":54,"budget":32267,"phone":"(773) 8863232","address":"82 Annamark Way","img_id":4,"gender":"F"},
{"id":"d26bcf84-a8b4-471c-a582-2de64d7e7715","full_name":"Wait Peperell","job_title":"Web Designer III","country":"US","is_online":true,"rating":4,"target":94,"budget":72251,"phone":"(419) 5806752","address":"0355 Petterle Avenue","img_id":8,"gender":"M"},
{"id":"da813d45-bd3b-4bfa-abdd-e12050a5da14","full_name":"Ailsun Esmead","job_title":"Software Test Engineer III","country":"FR","is_online":true,"rating":1,"target":12,"budget":69596,"phone":"(852) 7039628","address":"0 Lunder Crossing","img_id":8,"gender":"F"},
{"id":"944d8752-ef63-47bc-8db0-f40808d50b15","full_name":"Vallie Van der Velde","job_title":"Nurse","country":"GB","is_online":true,"rating":3,"target":89,"budget":19530,"phone":"(331) 7690832","address":"2 Almo Lane","img_id":4,"gender":"F"},
{"id":"3d2f991c-58ef-436d-912e-49496fd2065c","full_name":"Dov Amber","job_title":"Sales Representative","country":"FR","is_online":false,"rating":4,"target":55,"budget":61106,"phone":"(793) 4768356","address":"4 Rockefeller Street","img_id":3,"gender":"M"},
{"id":"29b406e9-ca20-45cb-8630-6df3a632276a","full_name":"Tedie Alu","job_title":"Business Systems Analyst","country":"FR","is_online":false,"rating":1,"target":76,"budget":3114,"phone":"(602) 5831373","address":"28 Claremont Plaza","img_id":5,"gender":"M"},
{"id":"767de27a-421c-4147-a552-26ac56390339","full_name":"Tally Rizzi","job_title":"Civil Engineer","country":"US","is_online":false,"rating":1,"target":5,"budget":78575,"phone":"(302) 6856300","address":"1 Roth Park","img_id":10,"gender":"M"},
{"id":"2b6e6902-11b5-4ca7-8a59-c32b3f57533b","full_name":"Ilise Lewnden","job_title":"Database Administrator I","country":"BR","is_online":false,"rating":4,"target":33,"budget":-1059,"phone":"(659) 8660881","address":"19 Stuart Alley","img_id":2,"gender":"F"},
{"id":"c891e87f-b19b-4cb5-8902-b19c59e37494","full_name":"Farr Penwright","job_title":"Senior Editor","country":"US","is_online":true,"rating":3,"target":82,"budget":30505,"phone":"(505) 3900066","address":"9200 Karstens Crossing","img_id":6,"gender":"M"},
{"id":"65821428-adc7-4743-9dd8-cf62400e62f2","full_name":"Chantal Bidgod","job_title":"VP Sales","country":"BR","is_online":false,"rating":3,"target":62,"budget":25742,"phone":"(746) 3962702","address":"8807 Kinsman Drive","img_id":2,"gender":"F"},
{"id":"f42907e0-d92c-4fb0-b886-2e84331ffe28","full_name":"Johanna Bergin","job_title":"Electrical Engineer","country":"BR","is_online":false,"rating":2,"target":54,"budget":25864,"phone":"(720) 6442547","address":"93166 Spohn Court","img_id":3,"gender":"F"},
{"id":"4531be30-eec7-477f-bdc5-88acb8d902af","full_name":"Padriac Syphas","job_title":"Marketing Assistant","country":"BR","is_online":true,"rating":1,"target":50,"budget":20879,"phone":"(606) 9062546","address":"50465 Buena Vista Plaza","img_id":5,"gender":"M"},
{"id":"4f0ab8f6-7a36-4a5c-a513-d5495223a9cd","full_name":"Garey De Maria","job_title":"Operator","country":"US","is_online":true,"rating":2,"target":54,"budget":94251,"phone":"(972) 1133958","address":"24 Maple Wood Terrace","img_id":9,"gender":"M"},
{"id":"13556e73-d43a-485d-8603-cbf1769bd0ac","full_name":"Glenn Thorwarth","job_title":"Developer IV","country":"BR","is_online":false,"rating":5,"target":39,"budget":33511,"phone":"(394) 3115946","address":"8 Dryden Park","img_id":1,"gender":"M"},
{"id":"c8389213-a2cd-4bb8-ba99-9c29e9b70881","full_name":"Bron Crosson","job_title":"GIS Technical Architect","country":"FR","is_online":true,"rating":2,"target":80,"budget":62081,"phone":"(686) 7934926","address":"974 Mayfield Place","img_id":9,"gender":"M"},
{"id":"cd233a4e-0315-496d-8f5f-4c8787b0f1ba","full_name":"Garey Malecky","job_title":"Account Executive","country":"BR","is_online":false,"rating":5,"target":51,"budget":76354,"phone":"(304) 6324519","address":"3 Paget Court","img_id":9,"gender":"M"},
{"id":"339838eb-99dc-4fc0-8e18-060dbcf6bf78","full_name":"Elisabet Kimblin","job_title":"Automation Specialist I","country":"US","is_online":true,"rating":3,"target":35,"budget":26755,"phone":"(904) 3334563","address":"75 Buell Court","img_id":1,"gender":"F"},
{"id":"90a03a5a-1f16-4721-8c56-af4bb89a1f57","full_name":"Berk Uttley","job_title":"Health Coach IV","country":"FR","is_online":false,"rating":4,"target":58,"budget":82064,"phone":"(200) 2124940","address":"4 Mockingbird Trail","img_id":8,"gender":"M"},
{"id":"ce5ba778-43eb-4658-a6bd-56f04e6f125f","full_name":"Kenny Clackers","job_title":"Assistant Manager","country":"FR","is_online":true,"rating":4,"target":37,"budget":47477,"phone":"(926) 3235464","address":"53315 Bashford Lane","img_id":2,"gender":"M"},
{"id":"df3dffe4-6fb4-4a9c-8512-71f7cb508180","full_name":"Rickard Pingston","job_title":"Environmental Specialist","country":"BR","is_online":true,"rating":5,"target":72,"budget":94173,"phone":"(902) 4658383","address":"8 Mesta Court","img_id":2,"gender":"M"},
{"id":"2757a92d-5eed-4215-818f-fdf15b6b27d0","full_name":"Hobard Clabburn","job_title":"Systems Administrator I","country":"BR","is_online":true,"rating":2,"target":37,"budget":66166,"phone":"(711) 6587550","address":"29796 Mcbride Court","img_id":2,"gender":"M"},
{"id":"71ebd82a-f9f2-48ea-ada4-ce8db424df64","full_name":"Uri Champken","job_title":"Software Test Engineer IV","country":"BR","is_online":false,"rating":1,"target":39,"budget":65176,"phone":"(514) 1953919","address":"78 Rowland Alley","img_id":6,"gender":"M"},
{"id":"b7a77c35-1f9f-45c5-ba44-7622d279b377","full_name":"Obidiah Fold","job_title":"Budget/Accounting Analyst I","country":"FR","is_online":false,"rating":5,"target":66,"budget":74529,"phone":"(747) 8711780","address":"58 Lakeland Court","img_id":9,"gender":"M"},
{"id":"9c0fbca6-b793-4fdc-8dcf-5136cb5d049c","full_name":"Angelia Marconi","job_title":"Associate Professor","country":"FR","is_online":false,"rating":4,"target":91,"budget":38996,"phone":"(530) 7506861","address":"7 Ohio Parkway","img_id":2,"gender":"F"},
{"id":"9083010c-4f59-49e7-96c6-894cbf24ed75","full_name":"Ronda Lock","job_title":"Quality Engineer","country":"US","is_online":false,"rating":5,"target":36,"budget":69903,"phone":"(916) 6819628","address":"802 American Ash Street","img_id":6,"gender":"F"},
{"id":"4225013e-fd75-46f6-b678-b25675e131bc","full_name":"Rachele Fantham","job_title":"Business Systems Analyst","country":"FR","is_online":true,"rating":2,"target":100,"budget":79333,"phone":"(652) 3112938","address":"62 Grim Center","img_id":4,"gender":"F"},
{"id":"b75a42e9-db84-479c-96b0-d88109abfebb","full_name":"Emanuel Deery","job_title":"Electrical Engineer","country":"BR","is_online":false,"rating":3,"target":93,"budget":77477,"phone":"(860) 8318923","address":"850 Mifflin Junction","img_id":8,"gender":"M"},
{"id":"b2e28f42-7a35-4e23-b2e4-40fe3ed909ca","full_name":"Clay Ellins","job_title":"Design Engineer","country":"US","is_online":true,"rating":4,"target":98,"budget":4120,"phone":"(682) 4688237","address":"458 Scoville Lane","img_id":5,"gender":"M"},
{"id":"4239103e-80bc-4f09-8655-c2165b063027","full_name":"Bettina Menlove","job_title":"Senior Financial Analyst","country":"FR","is_online":false,"rating":5,"target":78,"budget":80574,"phone":"(293) 8801412","address":"29 Onsgard Junction","img_id":8,"gender":"F"},
{"id":"8442ff74-6f05-43f7-90de-b948a5c2c895","full_name":"Claiborne Willoughey","job_title":"Food Chemist","country":"US","is_online":true,"rating":5,"target":44,"budget":44673,"phone":"(707) 3380798","address":"39114 Killdeer Terrace","img_id":6,"gender":"M"},
{"id":"1701041a-2ede-4c1d-a51b-8fe4b3ec1c35","full_name":"Desmond Gritskov","job_title":"Pharmacist","country":"BR","is_online":false,"rating":4,"target":66,"budget":45319,"phone":"(412) 1336481","address":"6912 Farwell Street","img_id":6,"gender":"M"},
{"id":"609fd74e-e6c3-41ae-a227-d3d9caba0582","full_name":"Codee Maybery","job_title":"Clinical Specialist","country":"US","is_online":true,"rating":2,"target":26,"budget":43462,"phone":"(682) 7969803","address":"4 Tennessee Plaza","img_id":3,"gender":"F"},
{"id":"f535ef7f-c79d-4936-af40-d062d2b2d5c0","full_name":"Denys Beynke","job_title":"Occupational Therapist","country":"BR","is_online":true,"rating":4,"target":60,"budget":32402,"phone":"(110) 4341370","address":"99 Trailsway Park","img_id":4,"gender":"M"},
{"id":"ae126e65-b6ab-4388-ba5c-f6f5d54fc435","full_name":"Guthry Limpricht","job_title":"Geologist II","country":"DE","is_online":true,"rating":3,"target":65,"budget":68462,"phone":"(100) 8946768","address":"3117 Commercial Crossing","img_id":2,"gender":"M"},
{"id":"9b1efdfc-4cce-4350-9516-028884fb1725","full_name":"Cammie Knoble","job_title":"Community Outreach Specialist","country":"GB","is_online":false,"rating":2,"target":1,"budget":70198,"phone":"(352) 1663192","address":"55 Longview Place","img_id":4,"gender":"F"},
{"id":"a44c41e2-3adc-4d52-acb3-06f90e0214c9","full_name":"Kiri Espinal","job_title":"Accounting Assistant I","country":"FR","is_online":false,"rating":3,"target":47,"budget":3712,"phone":"(946) 6686147","address":"90480 Chinook Drive","img_id":4,"gender":"F"},
{"id":"9d6d3063-7be8-49fe-be31-64d0d5949f02","full_name":"Joy Tiddy","job_title":"Engineer III","country":"FR","is_online":true,"rating":5,"target":80,"budget":61355,"phone":"(849) 1097790","address":"76 Summerview Hill","img_id":9,"gender":"F"},
{"id":"44def3bb-7c65-45d3-aef6-bf8b49f934b3","full_name":"Arlene Karlsson","job_title":"Civil Engineer","country":"BR","is_online":true,"rating":4,"target":98,"budget":14267,"phone":"(699) 4713080","address":"90 Dorton Point","img_id":5,"gender":"F"},
{"id":"7b991e13-bef7-4f19-bd18-d5aa9d263cba","full_name":"Melony Millwater","job_title":"Chief Design Engineer","country":"US","is_online":true,"rating":2,"target":27,"budget":67126,"phone":"(352) 5197697","address":"65242 Derek Place","img_id":1,"gender":"F"},
{"id":"ab6704b8-20ed-4881-ab28-96e3d55f7792","full_name":"Mab Ballham","job_title":"Programmer Analyst II","country":"FR","is_online":false,"rating":1,"target":34,"budget":65648,"phone":"(699) 9285470","address":"2579 Messerschmidt Crossing","img_id":8,"gender":"F"},
{"id":"03f0f5a0-8b7c-486e-ae4b-c9e5858bbde8","full_name":"Nixie MacAskie","job_title":"Structural Analysis Engineer","country":"BR","is_online":true,"rating":2,"target":13,"budget":-5312,"phone":"(434) 6153212","address":"59672 Village Court","img_id":4,"gender":"F"},
{"id":"6b63462d-b181-4055-813d-983002ea57b8","full_name":"Gianni Crafts","job_title":"Tax Accountant","country":"FR","is_online":false,"rating":3,"target":54,"budget":85744,"phone":"(428) 3135618","address":"53766 Buhler Parkway","img_id":8,"gender":"M"},
{"id":"dcafac96-c436-4232-8d0e-eda10d029e1d","full_name":"Moyra Summerlie","job_title":"Business Systems Analyst","country":"FR","is_online":true,"rating":5,"target":3,"budget":19109,"phone":"(960) 7792763","address":"127 Spohn Crossing","img_id":6,"gender":"F"},
{"id":"a765924f-8ffb-4186-8991-6000a6ce2652","full_name":"Hagan Trevers","job_title":"Mechanical Systems Engineer","country":"FR","is_online":false,"rating":2,"target":93,"budget":28290,"phone":"(749) 8913157","address":"516 Clove Road","img_id":7,"gender":"M"},
{"id":"2c3423dd-81d0-4e41-987e-1c39956c9ba8","full_name":"Sela Westmacott","job_title":"Account Executive","country":"BR","is_online":false,"rating":3,"target":29,"budget":58239,"phone":"(130) 9778509","address":"81 Maple Point","img_id":2,"gender":"F"},
{"id":"e8bce62e-3bf4-4232-801c-bbcc8dab2e74","full_name":"Lilia Wiz","job_title":"VP Product Management","country":"FR","is_online":false,"rating":1,"target":65,"budget":1438,"phone":"(418) 5940094","address":"905 Hauk Point","img_id":1,"gender":"F"},
{"id":"5b9b9235-e0f2-4102-b5f1-d512774e7191","full_name":"Orlan MacAlpin","job_title":"Statistician I","country":"US","is_online":false,"rating":1,"target":1,"budget":9094,"phone":"(512) 7106617","address":"6055 Truax Way","img_id":2,"gender":"M"},
{"id":"1124d1b2-6ac3-4f2f-ba35-1da116844b0e","full_name":"Ty Nutton","job_title":"Speech Pathologist","country":"FR","is_online":false,"rating":5,"target":88,"budget":76684,"phone":"(250) 2530424","address":"37247 Merry Plaza","img_id":1,"gender":"M"},
{"id":"01e7f525-ab3f-48b7-941f-c6ad601ea69f","full_name":"Mirelle Jandl","job_title":"Civil Engineer","country":"DE","is_online":true,"rating":3,"target":19,"budget":94486,"phone":"(871) 7917453","address":"31 Anderson Hill","img_id":10,"gender":"F"},
{"id":"078b38d3-79a5-4df1-bd97-f83e300224a4","full_name":"Diann Bertram","job_title":"Structural Engineer","country":"BR","is_online":true,"rating":2,"target":38,"budget":2769,"phone":"(716) 9445650","address":"958 Swallow Drive","img_id":4,"gender":"F"},
{"id":"7ca6af51-11bc-44f3-b92f-7af02923d040","full_name":"Cindra Slobom","job_title":"Web Designer III","country":"BR","is_online":true,"rating":5,"target":48,"budget":81624,"phone":"(409) 9223432","address":"82525 Northridge Junction","img_id":1,"gender":"F"},
{"id":"86a585e1-b76d-43b5-982d-e74b5b4b616b","full_name":"Tymon Mattheissen","job_title":"Dental Hygienist","country":"FR","is_online":false,"rating":2,"target":51,"budget":42972,"phone":"(724) 3234885","address":"64363 Anzinger Plaza","img_id":3,"gender":"M"},
{"id":"37e366fb-9371-4df5-980c-e9678ec9cde2","full_name":"Fancie Heighton","job_title":"Paralegal","country":"FR","is_online":true,"rating":5,"target":52,"budget":67403,"phone":"(738) 9982239","address":"4353 Hermina Junction","img_id":1,"gender":"F"},
{"id":"b7df7c2a-4d40-4c03-bfb6-ab6d6930340c","full_name":"Kit Utteridge","job_title":"Office Assistant I","country":"FR","is_online":true,"rating":4,"target":93,"budget":36268,"phone":"(710) 2371400","address":"46806 Moland Center","img_id":7,"gender":"F"},
{"id":"7d740468-fad9-4687-895b-cee07aa3bc3d","full_name":"Lea Willey","job_title":"VP Quality Control","country":"US","is_online":false,"rating":3,"target":19,"budget":54614,"phone":"(304) 3327700","address":"1 Moland Crossing","img_id":6,"gender":"F"},
{"id":"88df162f-24fa-495d-a65c-b948607193b8","full_name":"Arri Halton","job_title":"Staff Accountant II","country":"GB","is_online":true,"rating":5,"target":73,"budget":32500,"phone":"(743) 8449571","address":"3 Starling Trail","img_id":3,"gender":"M"},
{"id":"cb93d087-2f15-43cd-9bc3-89716e39a724","full_name":"Malva Pierpoint","job_title":"Operator","country":"US","is_online":true,"rating":4,"target":98,"budget":20312,"phone":"(423) 8195288","address":"57584 Algoma Plaza","img_id":1,"gender":"F"},
{"id":"b76e54a1-1c46-49aa-9464-7dd9aa738b65","full_name":"Dalston Sabben","job_title":"Social Worker","country":"BR","is_online":true,"rating":4,"target":85,"budget":99048,"phone":"(388) 2523908","address":"53578 Clove Lane","img_id":4,"gender":"M"},
{"id":"608f098b-16f8-4460-bb35-9599a8a74280","full_name":"Delinda Margrie","job_title":"Financial Advisor","country":"US","is_online":false,"rating":1,"target":1,"budget":8398,"phone":"(702) 6480692","address":"9 Dapin Plaza","img_id":9,"gender":"F"},
{"id":"23b426f8-76d5-4808-84cb-c42bde8362fd","full_name":"Meggi Westman","job_title":"Librarian","country":"US","is_online":true,"rating":3,"target":64,"budget":63413,"phone":"(682) 3495155","address":"82 Duke Terrace","img_id":7,"gender":"F"},
{"id":"7ecfb74e-64d9-43ee-a028-d2e3841acba9","full_name":"Bret Barnsdall","job_title":"Registered Nurse","country":"BR","is_online":true,"rating":3,"target":52,"budget":55153,"phone":"(222) 2089814","address":"4 Corry Park","img_id":8,"gender":"M"},
{"id":"cf505561-d9e1-4d2a-93d5-4745d50595c2","full_name":"Matthus Shyres","job_title":"Marketing Manager","country":"FR","is_online":false,"rating":3,"target":68,"budget":33697,"phone":"(539) 9486139","address":"460 Norway Maple Junction","img_id":1,"gender":"M"}];