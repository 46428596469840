import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TimeOffInLieuService } from '@app/core/services/time-off-in-lieu/time-off-in-lieu.service';
import { MyRequest } from '@app/shared/models/workflows-absences/workflows-absences.model';

@Component({
  selector: 'app-toil-approval-list-item',
  templateUrl: './toil-approval-list-item.component.html',
  styleUrls: ['./toil-approval-list-item.component.scss']
})
export class ToilApprovalListItemComponent implements OnInit {
  @Output() openPendingTimeOffDialog = new EventEmitter<MyRequest>();
  @Input() employeeToilRequest;
  iconUrl: string;

  constructor(
    private timeOffInLieuService: TimeOffInLieuService,
    public domSanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.timeOffInLieuService.getToilTypeIcon(this.employeeToilRequest?.timeOffType?.id)
    .subscribe(
      res => {
        this.iconUrl = res;
      }
    )
  }

}
