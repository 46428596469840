import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { api_routes } from '@app/consts';
import { db_tables } from '@app/consts/db-tables';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import * as moment from 'moment';
import { defer, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TimeOffInLieuService } from '@app/core/services/time-off-in-lieu/time-off-in-lieu.service';
import { EmployeeToilRecord, EmployeeToilRecordVerbose } from '@app/shared/models/time-off-in-lieu/time-off-in-lieu.model';
import { EmployeeToilRecordFormDialogComponent } from './components/employee-toil-record-form-dialog/employee-toil-record-form-dialog.component';

@Component({
  selector: 'app-employee-absences-toil',
  templateUrl: './employee-absences-toil.component.html',
  styleUrls: ['./employee-absences-toil.component.scss']
})
export class EmployeeAbsencesToilComponent implements OnInit {
  @Input() employeeID: string;

  public columns: any[] = [
    { field: "toilClassType", subField:"name", title: "Toil Class Type", type: "navigation", tableId: "tfi_ToilRecordToilClassType"},
    { field: "toilPolicy", subField:"name", title: "Toil Policy", type: "navigation", tableId: "tfi_ToilRecordToilPolicy"},
    { field: "startDate", title: "Start Date", type: "date", tableId: "tfi_ToilRecordStartDate"},
    { field: "endDate", title: "End Date", type: "date", tableId: "tfi_ToilRecordEndDate"},
    { field: "startTime", title: "Start Time", tableId: "tfi_ToilRecordStartTime"},
    { field: "endTime", title: "End Time", tableId: "tfi_ToilRecordEndTime"},
    { field: "hours", title: "Hours", tableId: "tfi_ToilRecordHours"},
    { field: "halfDay", title: "Half Day", tableId: "tfi_ToilRecordHalfDay", type: "boolean" , default: [this.translate.instant("Yes"), this.translate.instant("No")]},
    { field: "comments", title: "Comments", tableId: "tfi_ToilRecordComments"},
  ];

  public filterCategories: any[] = [
    // { field: "toilClassType", subField:"name", title: "Toil Class Type", type: "navigation", tableId: "tfi_ToilRecordToilClassType"},
    // { field: "toilPolicy", subField:"name", title: "Toil Policy", type: "navigation", tableId: "tfi_ToilRecordToilPolicy"},
    { field: "startDate", title: "Start Date", type: "date", tableId: "tfi_ToilRecordStartDate", dataType: 'Date'},
    { field: "endDate", title: "End Date", type: "date", tableId: "tfi_ToilRecordEndDate", dataType: 'Date'},
    // { field: "startTime", title: "Start Time", tableId: "tfi_ToilRecordStartTime"},
    // { field: "endTime", title: "End Time", tableId: "tfi_ToilRecordEndTime"},
    { field: "hours", title: "Hours", tableId: "tfi_ToilRecordHours", dataType: 'Number'},
    { field: "halfDay", title: "Half Day", tableId: "tfi_ToilRecordHalfDay", dataType: "ProvidedList", listOptions: {
        list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
        id: 'id',
        name: 'name'
      }
    },
    { field: "comments", title: "Comments", tableId: "tfi_ToilRecordComments", dataType: 'String'},
  ];

  sortableColumns: any[] = [
    { field: "toilClassType", sortValue: "toilClassType.name"},
    { field: "toilPolicy", sortValue: "toilPolicy.name" },
    { field: "startDate", sortValue: "startDate" },
    { field: "endDate", sortValue: "endDate" },
    { field: "startTime", sortValue: "startTime" },
    { field: "endTime", sortValue: "endTime" },
    { field: "hours", sortValue: "hours" },
    { field: "halfDay", sortValue: "halfDay" },
    { field: "comments", sortValue: "comments" },
  ];

  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 5;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  dialogRef: any;
  public sortString: string = 'startDate-desc';
  isLoadingPermissions: boolean = true;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];
  filterString: string;
  accessAndHistoryURL: string = `${api_routes.TOIL}${api_routes.RECORDS}/[entityId]`;

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private timeOffInLieuService: TimeOffInLieuService,
    private snackbarService: SnackbarService,
    private tablePermissionsService: TablePermissionsService,
    private overlayService: OverlayService
  ) { }

  ngOnInit(): void {
    this.getEmployeeToilRecords();
    this.getTargetEmployeeTablePermissions();
  }

  getEmployeeToilRecords() {
    this.isLoading = true;

    this.timeOffInLieuService.getEmployeeToilRecords(this.employeeID, String(this.pageSize), this.skip, this.sortString, this.filterString)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          }
        }
      );
  }

  getTargetEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeID);

    // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
    if(currentFetchedData !== null){
      this.targetEmployeeTablePermissions = currentFetchedData;
      this.isLoadingPermissions = false;
    }
    else {
      this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeID)
      .pipe(
        finalize(() => {
            this.isLoadingPermissions = false;
        })
      )
      .subscribe(
        res => {
          this.targetEmployeeTablePermissions = res;
        }
      );
    }
  }

  openFormDialog(employeeToilRecord?: EmployeeToilRecord) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      employeeToilRecord: employeeToilRecord,
      employeeId: this.employeeID,
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions,
    };

    this.dialogRef = this.dialog.open(EmployeeToilRecordFormDialogComponent, dialogConfig);

    this.dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.getEmployeeToilRecords();
        }
      }
    );
  }

  deleteEmployeeToilRecords(employeeToilRecordIds: string[]) {
    const observables = employeeToilRecordIds.map(selectedItem => defer(() => this.timeOffInLieuService.deleteEmployeeToilRecord(selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.view = this.getEmployeeToilRecords();
        this.clearSelectedItems = !this.clearSelectedItems;
        this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.view = this.getEmployeeToilRecords();
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;
    this.getEmployeeToilRecords();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getEmployeeToilRecords();
  }

}
