import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { TranslationService } from '@app/core/services/translation.service'

@Component({
  selector: 'app-auth-page',
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.scss']
})
export class AuthPageComponent implements OnInit {

  constructor(
    private translate: TranslateService,
    private translationService: TranslationService
    ) { }
  
  public todayDate: Date = new Date();

  useLanguage(language: string): void {

    this.translate.use(language)
    .subscribe(
      res => {
        this.translationService.getTranslations(language)
        .subscribe(
          t => {
            this.translate.setTranslation(language, t, true);
          }
        ); 
      }
    ); 
    
  }

  ngOnInit(): void {
  }

}
