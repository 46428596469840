import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api_routes } from '@app/consts';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { BasicHeadcountReport } from '../models/basic-headcount-report.model';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { SanitiseGridDataService } from '@app/core/services/sanitise-grid-data/sanitise-grid-data.service';
import { EnvironmentService } from "@app/core/services/environment.service";


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class BasicHeadcountReportService {

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private overlayService: OverlayService,
    private currencyPipe: CurrencyPipe,
    private sanitiseGridDataService: SanitiseGridDataService,
    private datePipe: DatePipe,
    private envService: EnvironmentService
  ) { }

  getHeadcountReport(skip?: number, take?: string, filterString?: string, sortString?: string): Observable<PagedData<BasicHeadcountReport>> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    filterString ? params = params.append('Filter', filterString) : null;
    sortString ? params = params.append('Sort', sortString) : null;

    return this.http
      .get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${api_routes.REPORTS}/${api_routes.HEADCOUNT}`, {
        headers: headers,
        params: params
      })
      .pipe(
        catchError(err => throwError(err)),
        map(res => {
          return res;
        })
      );
  }

  public fetch(state: State, filterString: string, sortString: string, showOverlay?: boolean, formatData?: boolean, filterInactive?: boolean): Observable<GridDataResult> {
    let params = new HttpParams();
    state.skip ? params = params.append('skip', state.skip.toString()) : null;
    state.take ? params = params.append('take', state.take.toString()) : null;
    filterString ? params = params.append('Filter', filterString) : null;
    sortString ? params = params.append('Sort', sortString) : null;
    filterInactive ? params = params.append('FilterInactive', filterInactive.toString()) : false;

    if(showOverlay){
      this.overlayService.show(this.translate.instant('FetchingData'));
    }

    return this.http.get(`${this.envService.env.apiUrl}${api_routes.TALENT}/${api_routes.REPORTS}/${api_routes.HEADCOUNT}`, {
      headers: headers,
      params: params
    })
    .pipe(
      finalize( () => showOverlay ? this.overlayService.hide() : null ),
      map(
        (response) =>
          <GridDataResult>{
            data: formatData ? this.formatData(response['data']) : response['data'],
            total: parseInt(response["total"], 10),
          }
      )
    );
  }

  public async queryAll(st?: State, filterString?: string, sortString?: string, filterInactive?: boolean): Promise<GridDataResult> {
    const state = Object.assign({}, st);
    state.skip = 0;
    state.take = 100;

    let dataToReturn: GridDataResult = {
      data: [],
      total: null
    };

    while(dataToReturn.total === null || state.skip < dataToReturn.total) {
      const res = await this.fetch(state, filterString, sortString, true, true, filterInactive).toPromise();;

      if (res && res.data && res.data.length > 0) {
        dataToReturn.data = dataToReturn.data.concat(res.data);
        state.skip += state.take;
        dataToReturn.total = res.total;
      } else {
          break;
      }
    }

    return dataToReturn
  }

  formatData(data: any) {

    let result: any[] = [];

    data.forEach((element, index) => {

        // Create a new entry with a position object for each position an employee has
        element.positions?.forEach( (position, i) => {
            let employeeCopy = JSON.parse(JSON.stringify(element));
                if (i === 0){
                    employeeCopy.position = position;
                    result.push(this.transformValue(employeeCopy));
                }
                else {
                    employeeCopy.position = position;
                    employeeCopy.positions = null;
                    result.push(this.transformValue(employeeCopy));
                }
            }
        );
    });

    return result;
  }


    transformValue(element: any) {

        if(element?.position?.startDate) {
            element.position.startDate = this.datePipe.transform(element.position.startDate, 'longDate');
        }
        if(element?.position?.udd1) {
            element.position.udd1 = this.datePipe.transform(element.position.udd1, 'longDate');
        }
        if(element?.position?.fixedTermEndDate) {
            element.position.fixedTermEndDate = this.datePipe.transform(element.position.fixedTermEndDate, 'longDate');
        }

        if(element?.compensation?.baseSalary) {
            element.compensation.baseSalary =  Number(element.compensation.baseSalary).toLocaleString();
        }

        if(element?.compensation?.baseSalaryFteAdjusted) {
            element.compensation.baseSalaryFteAdjusted =  Number(element.compensation.baseSalaryFteAdjusted).toLocaleString();
        }

        if(element?.employmentRecord?.hireDate) {
            element.employmentRecord.hireDate = this.datePipe.transform(element.employmentRecord.hireDate, 'longDate');
        }
        if(element?.employmentRecord?.terminationDateActual) {
            element.employmentRecord.terminationDateActual = this.datePipe.transform(element.employmentRecord.terminationDateActual, 'longDate');
        }

        if(element?.workLocations?.length > 0) {
            element.workLocations.forEach(workLocation => {
                let regions = [];
                if (workLocation?.region && workLocation.region.name !== null) {
                    regions.push(workLocation.region);
                }
                if (regions.length > 0) {
                    element['regions'] = regions;
                }
            });
        }

        for (const [key, value] of Object.entries(element)) {

            // If it is an array extract the values into a string and set the value as this string
            if (Array.isArray(value) && key !== 'positionsObject'){
                let val: string = '';

                value.forEach( (item) => {
                    if (typeof item === 'object' && item !== null) {
                        if (item.hasOwnProperty('name')){
                            if (val !== ''){
                                val = val.concat(', ');
                            }
                            if (item.name !== null){
                                val = val.concat(item.name);
                            }
                            if (item.hasOwnProperty('startDate') && item.startDate !== null &&
                                item.hasOwnProperty('clientPositionId') &&
                                item.clientPositionId !== null)  {
                                val = val.concat(' (')
                                    .concat(this.datePipe.transform(item.startDate, 'longDate'))
                                    .concat(', ID: ').concat(item.clientPositionId).concat(')');
                            } else if(item.hasOwnProperty('startDate') && item.startDate !== null) {
                                val = val.concat(' (')
                                    .concat(this.datePipe.transform(item.startDate, 'longDate'))
                                    .concat(')');
                            } else if (item.hasOwnProperty('clientPositionId') && item.clientPositionId !== null) {
                                val = val.concat(' (ID: ').concat(item.clientPositionId).concat(')');
                            }
                        }
                        else if(item.hasOwnProperty('firstName') && item.hasOwnProperty('lastName')){
                            if(val !== ''){
                                val = val.concat(', ')
                            }
                            if(item.firstName !== null && item.lastName !== null){
                                val = val.concat(`${item.firstName} ${item.lastName}`)
                            }
                        }

                    }
                });

                if (val !== ''){
                    element[key] = val;
                }
            }
        }
        return element;
    }
}

