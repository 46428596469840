import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { defer, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BenefitGroupPlanDialogComponent } from './components/benefit-group-plan-dialog/benefit-group-plan-dialog.component';
import { BenefitsGroupPlan } from './models/benefits-group-plans.model';
import { BenefitsGroupPlanService } from './services/benefits-group-plan.service';

@Component({
  selector: 'app-benefit-group-plans',
  templateUrl: './benefit-group-plans.component.html',
  styleUrls: ['./benefit-group-plans.component.scss']
})
export class BenefitGroupPlansComponent implements OnInit {
  @Input() group: { id: string, name: string };
  loadingGroupPlans: boolean;
  groupPlans: BenefitsGroupPlan[];

  constructor(
    private dialog: MatDialog,
    private benefitsGroupPlanService: BenefitsGroupPlanService,
    private overlayService: OverlayService,
    public router: Router,
    private snackbarService: SnackbarService,
  ) { }

  ngOnInit(): void {
    this.getGroupPlans();
  }

  getGroupPlans() {
    this.loadingGroupPlans = true;

    this.benefitsGroupPlanService.getGroupPlans(this.group.id)
    .pipe(
      finalize( () => { this.loadingGroupPlans = false } )
    )
    .subscribe(
      res => {
        this.groupPlans = res.data;
      }
    )
  }

  openGroupPlanDialog(planId?: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      group: this.group,
      planId: planId ? planId : null
    };

    const dialogRef = this.dialog.open(BenefitGroupPlanDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.getGroupPlans();
        }
      }
    ); 
  }

  openConfirmDeleteDialog(groupPlan: BenefitsGroupPlan) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      text: `Are you sure you want to delete this group plan? (${groupPlan.plan.name})`
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.deleteGroupPlan(groupPlan);
        }
      }
    ); 
  }

  deleteGroupPlan(groupPlan: BenefitsGroupPlan) {
    this.overlayService.show();

    this.benefitsGroupPlanService.deleteGroupPlan(this.group.id, groupPlan.plan.id) 
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.getGroupPlans();
        this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
      }
    );
  }

  
}

