import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SettingsService } from '@app/core/services/settings.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { Currency } from '@app/modules/lookup/models/currency.model';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BenefitsCoverage } from '../../../benefits-coverages/models/benefits-coverage.model';
import { BenefitsCoverageService } from '../../../benefits-coverages/services/benefits-coverage.service';
import { BenefitsGroupVerbose, DeductionFrequencies } from '../../../benefits-groups/models/benefits-group.model';
import { BenefitsGroupsService } from '../../../benefits-groups/services/benefits-groups.service';
import { BenefitsPlan, BenefitsWaitPeriodLengthUnit } from '../../../benefits-plans/models/benefits-plan.model';
import { BenefitsPlansService } from '../../../benefits-plans/services/benefits-plans.service';
import { BenefitsGroupPlan } from '../../models/benefits-group-plans.model';
import { BenefitsGroupPlanCoverageService } from '../../services/benefits-group-plan-coverage.service';
import { BenefitsGroupPlanService } from '../../services/benefits-group-plan.service';

@Component({
  selector: 'app-benefit-group-plan-dialog',
  templateUrl: './benefit-group-plan-dialog.component.html',
  styleUrls: ['./benefit-group-plan-dialog.component.scss']
})
export class BenefitGroupPlanDialogComponent implements OnInit {
  form: UntypedFormGroup;
  planId: string;
  group: BenefitsGroupVerbose;
  groupPlan: BenefitsGroupPlan;
  currencyOptions: Observable<Currency[]>
  coverageOptions: BenefitsCoverage[] = [];
  loadingCoverages: boolean;
  loading: boolean;

  planOptions: BenefitsPlan[] = [];
  waitPeriodLengthUnitOptions: Observable<BenefitsWaitPeriodLengthUnit[]>;
  groupPlanEligibilityOptions: Observable<any>;
  deductionFrequencyOptions: Observable<DeductionFrequencies[]>;
  loadingPlans: boolean;
  loadingGroupPlanCoverages: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private overlayService: OverlayService,
    private settingsService: SettingsService,
    private benefitsPlansService: BenefitsPlansService,
    private benefitsGroupsService: BenefitsGroupsService,
    private benefitsGroupPlanService: BenefitsGroupPlanService,
    private benefitsCoverageService: BenefitsCoverageService,
    private snackbarService: SnackbarService,
    private benefitsGroupPlanCoverageService: BenefitsGroupPlanCoverageService,
    private dialogRef: MatDialogRef<BenefitGroupPlanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data)
  {
    this.planId = data.planId;
    this.group = data.group;
  }

  ngOnInit(): void {
    this.loading = true;
    this.currencyOptions = this.settingsService.getCurrencies();
    this.getDropdownValues();

    if(this.planId !== null && this.group !== null){
      this.getGroupPlan();
    }
    else {
      this.loading = false;
      this.createForm();
    }
  }

  getDropdownValues() {
    this.getPlans();
    this.waitPeriodLengthUnitOptions = this.benefitsPlansService.getWaitPeriodLengthUnits();
    this.groupPlanEligibilityOptions = this.benefitsGroupPlanService.getGroupPlanEligibilities();
    this.deductionFrequencyOptions = this.benefitsGroupsService.getDeductionFrequencies();
    this.benefitsCoverageService.getCoverages().subscribe(
      res => {
        this.coverageOptions = res.data;
      }
    )
  }

  getPlans() {
    this.loadingPlans = true;

    this.benefitsPlansService.getPlans(null, null, '1000', 0)
    .pipe(
      finalize( () => { this.loadingPlans = false } )
    )
    .subscribe(
      res => {
        this.planOptions = res.data;
      }
    )
  }

  getGroupPlan() {
    this.loading = true;

    this.benefitsGroupPlanService.getGroupPlan(this.group.id, this.planId)
    .pipe(
      finalize( () => this.loading = false )
    )
    .subscribe(
      res => {
        this.groupPlan = res;
        this.createForm();
      }
    )
  }

  // getGroupPlanCoverages() {
  //   this.loadingGroupPlanCoverages = true;

  //   this.benefitsGroupPlanCoverageService.getBenefitsGroupPlanCoverages(this.group.id, this.planId)
  //   .pipe(
  //     finalize( () => this.loading = false )
  //   )
  //   .subscribe(
  //     res => {
  //       // this.groupPlan = res;
  //       // this.createForm();
  //     }
  //   )
  // }

  createForm() {
    this.form = this.fb.group({
      asOf: [moment().format()],
      group: [this.group !== undefined ? this.group.name[0].text : null, Validators.required],
      plan: [this.planId !== undefined ? this.planId : null, Validators.required],
      waitPeriodLengthUnit: [this.groupPlan ? this.groupPlan.waitPeriodLengthUnit?.id : null, Validators.required],
      waitPeriodLength: [this.groupPlan ? this.groupPlan.waitPeriodLength : null, Validators.required],
      groupPlanEligibility: [this.groupPlan ? this.groupPlan.groupPlanEligibility?.id : null, Validators.required],
      deductionFrequency: [this.groupPlan ? this.groupPlan.deductionFrequency?.id : null, Validators.required],
      // coverage: [null],
      // employerContributionAmount: [null],
      // employeeContributionAmount: [null],
      // employerContributionPercentage: [null],
      // employeeContributionPercentage: [null],
      // coveragedeductionFrequency: [null],
      changeReason: [''],
      changeReasonComments: [''],
    });
  }

  save() {
    this.overlayService.show();

    if(this.planId !== null && this.group !== null){
      this.benefitsGroupPlanService.updateGroupPlan(this.group.id, this.form.value.plan, this.form.value)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.dialogRef.close(true);
          this.snackbarService.openSnackBar('Group Plan updated successfully', 'clear', 'success');
        }
      );
    }
    else {
      this.benefitsGroupPlanService.addGroupPlan(this.group.id, this.form.value.plan, this.form.value)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.dialogRef.close(true);
          this.snackbarService.openSnackBar('Group Plan created successfully', 'clear', 'success');
        }
      );
    }
  }

  close() {
    this.dialogRef.close();
  }
}

