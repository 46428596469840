import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {SnackbarService} from '@app/core/services/snackbar.service';
import {RefreshListener} from '@app/shared/models/refresh-listener';
import {CultureService} from '@app/core/services/system-language/culture.service';
import {Culture} from '@app/shared/models/system-language/culture.model';
import {OverlayService} from '@app/shared/components/overlay/overlay.service';
import {finalize} from 'rxjs/operators';
import {TimeOffInLieuService} from '@app/core/services/time-off-in-lieu/time-off-in-lieu.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'add-toil',
    templateUrl: './add-toil.component.html',
    styleUrls: ['./add-toil.component.scss']
})
export class AddToilComponent implements OnInit {

    @Input() refreshListener: RefreshListener;

    public form: UntypedFormGroup;

    filePath: SafeUrl;
    cultures: Culture[];

    constructor(
        private translate: TranslateService,
        private fb: UntypedFormBuilder,
        private timeOffInLieuService: TimeOffInLieuService,
        private snackbarService: SnackbarService,
        private cultureService: CultureService,
        private overlayService: OverlayService,
        private sanitizer: DomSanitizer
    ) {
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            localizations: this.fb.array([]),
            image: null
        });

        this.cultureService.getCultures().subscribe(res => {
            this.cultures = res;
            this.addNewLocalization();
        });
    }

    get localizations() {
        return this.form.controls['localizations'] as UntypedFormArray;
    }

    deleteLocalization(index: number) {
        this.localizations.removeAt(index);
    }

    //Create a textLocalization form group object to add to the localizations form array
    addNewLocalization(culture?: string, localization?: string) {
        const localizationForm = this.fb.group({
            culture: [culture || '', Validators.required],
            text: [localization || '', Validators.required]
        });

        this.localizations.push(localizationForm);
    }

    saveForm() {
        this.overlayService.show();
        let data = {
            'name': this.form.value.localizations,
            'disabled': false,
        };
        this.timeOffInLieuService.postToilType(data)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe((res) => {
                console.log(res);

                if (this.form.value.image) {
                    this.timeOffInLieuService.postToilTypeIcon(this.form.value.image, res.toilTypeId)
                        .subscribe(res => {
                                this.handleSuccess();
                            },
                            err => {
                                this.handleError(err);
                            });
                } else {
                    this.handleSuccess();
                }
            },
            err => {
                this.handleError(err);
            });
    }

    handleSuccess() {
        this.refreshListener.refresh();
        this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
        this.form.value.typeName = '';
    }

    handleError(err) {
        console.log(err);
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
    }

    cancel() {
        this.refreshListener.cancel();
    }


    imagePreview(e) {
        const file = (e.target as HTMLInputElement).files[0];

        this.form.patchValue({
            image: file
        });

        this.form.get('image').updateValueAndValidity();

        const reader = new FileReader();
        reader.onload = () => {
            const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
            this.filePath = value;
        };
        reader.readAsDataURL(file);
    }
}
