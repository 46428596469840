import { Component, ViewChild, OnInit, Input, SimpleChanges } from "@angular/core";
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
};

@Component({
  selector: 'app-holidays-bar-chart',
  templateUrl: './holidays-bar-chart.component.html',
  styleUrls: ['./holidays-bar-chart.component.scss']
})
export class HolidaysBarChartComponent implements OnInit {
  @Input() employee: any;
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  constructor() {
  }
  
  ngOnInit(): void {
    this.chartOptions = {
      series: [
        {
          name: "basic",
          data: [this.employee.totalHolidaysCalc, this.employee.totalHolidayTaken, this.employee.totalHolidayBooked]
        }
      ],
      chart: {
        type: "bar",
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: [
          "Total Holidays",
          "Total Holidays Taken",
          "Total Holidays Booked"
        ]
      }
    };

    
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.chart){
      this.chart.render();
    }
  }

}
