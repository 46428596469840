<app-layout>

    <mat-toolbar class="page-header" role="heading">
        <h1>{{ getReportName() }}</h1>

        <button mat-stroked-button setColor="primaryColour" color="primary" (click)="backToReportGroups()">
            Back to Custom Reports
        </button>
    </mat-toolbar>

    <ng-container *ngIf="reportElements.length == 0 && !isLoading">
        <div class="report-empty">
            <mat-icon class="material-icons-outlined">report</mat-icon>
            <h4 class="no-data k-mb-0">This report is empty</h4>
        </div>
    </ng-container>

    <ng-container *ngFor="let reportElement of reportElements; let i = index">
        <mat-card>
            <mat-card-title class="header">
                <h5>{{reportsMapping.getElementName(reportElement)}}</h5>
            </mat-card-title>

            <mat-card-content>

                <div *ngIf="reportElement.name =='datagrid_element'" class="form-element-container" style="position: relative;">

                    <kendo-grid [data]="getPagedData(i)"
                                [loading]="false"
                                [pageSize]="paginationSettings[i].pageSize"
                                [skip]="paginationSettings[i].skip"
                                [style.minHeight.vh]="55"
                                [pageable]="pageable"
                                (pageChange)="pageChange(i, $event)"
                    >
                        <ng-container *ngIf="reportElementData[i] != null">

                            <ng-template kendoGridToolbarTemplate>
                                <button class="export-to-excel-button" kendoGridExcelCommand>
                                    <img
                                        class="ms-icon"
                                        src="assets/icons/excel-icon.svg"
                                        style="width: 35px;
                            height: 35px;
                            vertical-align: middle;">
                                    {{ 'ExportToExcel' | translate: {Default: "Export to Excel"} }}
                                </button>
                            </ng-template>

                                <kendo-grid-column *ngFor="let column of getColumns(reportElement)"
                                               field="{{getFieldMapping(reportElement, column.table, column.field)}}"
                                               title="{{ column.title }}"
                                               [resizable]="true"
                                               [width]="getColumnWidth(reportElement)"
                            >
                            </kendo-grid-column>
                        </ng-container>

                        <ng-template kendoGridNoRecordsTemplate>
                            <p *ngIf="reportElementData[i] != null && reportElementData[i].length === 0">No records available</p>
                        </ng-template>

                        <kendo-grid-excel
                            fileName="Report.xlsx"
                            [fetchData]="allData(i)"
                        ></kendo-grid-excel>

                        <kendo-grid-messages 
                            [noRecords]="isLoading ? '' : translate.instant('Grid-NoRecordsAvailable')"
                            [pagerItemsPerPage]="translate.instant('Grid-Pager-ItemsPerPage')" 
                            [pagerItems]="translate.instant('Grid-Pager-Items')"
                            [pagerOf]="translate.instant('Grid-Pager-Of')"
                        ></kendo-grid-messages>

                    </kendo-grid>

                    <!-- Progress Bar -->
                    <div *ngIf="reportElementData[i] == null"
                         style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; display: flex; align-items: center; justify-content: center;">
                        <div style="text-align: center;">
                            <div style="text-align: center;">
                                <mat-progress-bar class="custom-progress-bar" mode="determinate"
                                                  [value]="customLoadingIndicator.progress"
                                                  color="accent">
                                </mat-progress-bar>
                            </div>
                            <p class="k-m-5" style="text-align: center; color: grey;">{{ customLoadingIndicator.message }}</p>
                        </div>
                    </div>
                </div>


                <div *ngIf="reportElement.name =='piechart_element'" class="form-element-container">
                    <ng-template #loading>
                        <div class="pie-chart-loader">
                            <ngx-skeleton-loader
                                count="1"
                                appearance="circle"
                                [theme]="{ 'height.px': 250,'width.px': 250}">
                            </ngx-skeleton-loader>
                        </div>
                    </ng-template>

                    <kendo-chart title="" *ngIf="reportElementData[i] != null; else loading">
                        <kendo-chart-legend position="bottom"></kendo-chart-legend>
                        <kendo-chart-series>
                            <kendo-chart-series-item
                                type="pie"
                                [data]="reportElementData[i]"
                                field="value"
                                categoryField="category"
                                [labels]="{ visible: true, content: labelContent }"
                                colorField="colorField"
                            >
                            </kendo-chart-series-item>
                        </kendo-chart-series>
                    </kendo-chart>
                </div>

            </mat-card-content>
        </mat-card>
    </ng-container>

</app-layout>
