<app-admin-controls>
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
            <h5 appLocalizationPopupDirective localizationCode="ModuleConfiguration">{{ 'ModuleConfiguration' | translate: {Default: "Module Configuration"} }}</h5>
        </mat-card-title>

        <mat-card-content>

            <ng-container *ngIf="currentEmployeeDetails.userType === 'User'; else noAccess">
                <div class="page-description-section">
                    Control the module access for the site.
                </div>
    
                <app-form-generator
                    [formId]="formId"
                    [formData]="formData"
                    [emitFormDataTrigger]="getFormData"
                    (emitFormData)="formDataEmitted($event)"
                    (emitFormStatus)="formStatusUpdated($event)"
                    (emitFormPristine)="formPristineEmitted($event)"
                ></app-form-generator>
    
                <button 
                    mat-flat-button 
                    setColor="primaryColour" 
                    color="primary" 
                    appPreventDoubleClick 
                    (throttledClick)="getFormData = !getFormData" 
                    [disabled]="!formValid"
                >{{ 'Save' | translate: {Default: "Save"} }}</button>
            </ng-container>

            <ng-template #noAccess>
                <h5 appLocalizationPopupDirective localizationCode="NoAccess">{{ 'NoAccess' | translate: {Default: "NoAccess"} }}</h5>
            </ng-template>

        </mat-card-content>
    </mat-card>
</app-admin-controls>
