import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-talent-and-cem',
  templateUrl: './talent-and-cem.component.html',
  styleUrls: ['./talent-and-cem.component.scss']
})
export class TalentAndCemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
