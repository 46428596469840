import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { api_routes, db_tables } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { ChangeReasonDialogComponent } from '@app/shared/components/change-reason-dialog/change-reason-dialog.component';
import { FormGeneratorComponent } from '@app/shared/components/form-generator/form-generator.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { AccessLogsDialogComponent } from '../../../employment-records/components/employment-record-details/components/access-logs-dialog/access-logs-dialog.component';
import { HistoryDialogComponent } from '../../../employment-records/components/employment-record-details/components/history-dialog/history-dialog.component';
import { EmployeeIntegrationDetailsService } from './services/employee-integration-details.service';
import { EmployeeIntegrationDetail } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-integration-details/models/employee-integration-details.model";

@Component({
    selector: 'app-employee-integration-details',
    templateUrl: './employee-integration-details.component.html',
    styleUrls: ['./employee-integration-details.component.scss']
})
export class EmployeeIntegrationDetailsComponent implements OnInit {
    @ViewChild(FormGeneratorComponent) formGeneratorComponent: FormGeneratorComponent
    employeeID: string;
    form: UntypedFormGroup;
    integrationDetails: EmployeeIntegrationDetail;
    isLoading: boolean = true;
    formData: any;
    formId: string = 'frm_dnhGEugtt6lLI5';
    changeReasonFormId: string = 'frm_DRabiNEGSZSrOs';
    getFormData: boolean = false;
    formValid: boolean = false;
    targetEmployeeTablePermissions: EmployeeTableSecurity[];
    isLoadingPermissions: boolean = true;
    effectiveDate = new Date();

    public columns: any[] = [
        {field: 'integrationUdtf1',  title: 'Text Field 1', tableId: "tfi_IntegrationUdtf1"},
        {field: 'integrationUdtf2',  title: 'Text Field 2', tableId: "tfi_IntegrationUdtf2"},
        {field: 'integrationUdtf3',  title: 'Text Field 3', tableId: "tfi_IntegrationUdtf3"},
        {field: 'integrationUdtf4',  title: 'Text Field 4', tableId: "tfi_IntegrationUdtf4"},
        {field: 'integrationUdtf5',  title: 'Text Field 5', tableId: "tfi_IntegrationUdtf5"},
        {field: 'integrationUdtf6',  title: 'Text Field 6', tableId: "tfi_IntegrationUdtf6"},
        {field: 'integrationUdtf7',  title: 'Text Field 7', tableId: "tfi_IntegrationUdtf7"},
        {field: 'integrationUdtf8',  title: 'Text Field 8', tableId: "tfi_IntegrationUdtf8"},
        {field: 'integrationUdtf9',  title: 'Text Field 9', tableId: "tfi_IntegrationUdtf9"},
        {field: 'integrationUdtf10',  title: 'Text Field 10', tableId: "tfi_IntegrationUdtf10"},
        {field: 'integrationUdtf11',  title: 'Text Field 11', tableId: "tfi_IntegrationUdtf11"},
        {field: 'integrationUdtf12',  title: 'Text Field 12', tableId: "tfi_IntegrationUdtf12"},
        {field: 'integrationUdtf13',  title: 'Text Field 13', tableId: "tfi_IntegrationUdtf13"},
        {field: 'integrationUdtf14',  title: 'Text Field 14', tableId: "tfi_IntegrationUdtf14"},
        {field: 'integrationUdtf15',  title: 'Text Field 15', tableId: "tfi_IntegrationUdtf15"},
        {field: 'integrationUdtf16',  title: 'Text Field 16', tableId: "tfi_IntegrationUdtf16"},
        {field: 'integrationUdtf17',  title: 'Text Field 17', tableId: "tfi_IntegrationUdtf17"},
        {field: 'integrationUdtf18',  title: 'Text Field 18', tableId: "tfi_IntegrationUdtf18"},
        {field: 'integrationUdtf19',  title: 'Text Field 19', tableId: "tfi_IntegrationUdtf19"},
        {field: 'integrationUdtf20',  title: 'Text Field 20', tableId: "tfi_IntegrationUdtf20"},
        {field: 'integrationUdtf21',  title: 'Text Field 21', tableId: "tfi_IntegrationUdtf21"},
        {field: 'integrationUdtf22',  title: 'Text Field 22', tableId: "tfi_IntegrationUdtf22"},
        {field: 'integrationUdtf23',  title: 'Text Field 23', tableId: "tfi_IntegrationUdtf23"},
        {field: 'integrationUdtf24',  title: 'Text Field 24', tableId: "tfi_IntegrationUdtf24"},
    ];

    constructor(
        private translate: TranslateService,
        private dialog: MatDialog,
        private overlayService: OverlayService,
        private snackbarService: SnackbarService,
        private employeeIntegrationDetailsService: EmployeeIntegrationDetailsService,
        private tablePermissionsService: TablePermissionsService,
        private route: ActivatedRoute,
    ) {
        this.route.paramMap.subscribe(
            params => this.employeeID = params.get("id")
        )
    }

    ngOnInit(): void {
        this.getData()
        this.getTargetEmployeeTablePermissions();
    }

    get tableReadAccessObj() {
        return {
            tableId: db_tables.IntegrationDetails,
            permission: 'read',
            targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        }
    }

    get tableCreateAccessObj() {
        return {
            tableId: db_tables.IntegrationDetails,
            permission: 'create',
            targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        }
    }

    get tableUpdateAccessObj() {
        return {
            tableId: db_tables.IntegrationDetails,
            permission: 'update',
            targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        }
    }

    get tableDeleteAccessObj() {
        return {
            tableId: db_tables.IntegrationDetails,
            permission: 'delete',
            targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        }
    }

    getTargetEmployeeTablePermissions() {
        this.isLoadingPermissions = true;

        let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeID);

        // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
        if (currentFetchedData !== null) {
            this.targetEmployeeTablePermissions = currentFetchedData;
            this.isLoadingPermissions = false;
        } else {
            this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeID)
            .pipe(
                finalize(() => {
                    this.isLoadingPermissions = false;
                })
            )
            .subscribe(
                res => {
                    this.targetEmployeeTablePermissions = res;
                }
            );
        }
    }

    getData() {
        this.isLoading = true;
        let asOf = moment(this.effectiveDate).format('YYYY-MM-DD');

        this.isLoading = true;

        this.employeeIntegrationDetailsService.getIntegrationDetails(this.employeeID, asOf)
        .pipe(
            finalize(() => {
                this.isLoading = false;
                this.createForm();
            })
        )
        .subscribe(
            res => {
                this.integrationDetails = res;
            }
        );
    }

    createForm() {

        this.formData = {
            asOf: moment().format(),
            changeReason: '',
            changeReasonComments: '',
            integrationUdtf1: this.integrationDetails ? this.integrationDetails?.integrationUdtf1 : null,
            integrationUdtf2: this.integrationDetails ? this.integrationDetails?.integrationUdtf2 : null,
            integrationUdtf3: this.integrationDetails ? this.integrationDetails?.integrationUdtf3 : null,
            integrationUdtf4: this.integrationDetails ? this.integrationDetails?.integrationUdtf4 : null,
            integrationUdtf5: this.integrationDetails ? this.integrationDetails?.integrationUdtf5 : null,
            integrationUdtf6: this.integrationDetails ? this.integrationDetails?.integrationUdtf6 : null,
            integrationUdtf7: this.integrationDetails ? this.integrationDetails?.integrationUdtf7 : null,
            integrationUdtf8: this.integrationDetails ? this.integrationDetails?.integrationUdtf8 : null,
            integrationUdtf9: this.integrationDetails ? this.integrationDetails?.integrationUdtf9 : null,
            integrationUdtf10: this.integrationDetails ? this.integrationDetails?.integrationUdtf10 : null,
            integrationUdtf11: this.integrationDetails ? this.integrationDetails?.integrationUdtf11 : null,
            integrationUdtf12: this.integrationDetails ? this.integrationDetails?.integrationUdtf12 : null,
            integrationUdtf13: this.integrationDetails ? this.integrationDetails?.integrationUdtf13 : null,
            integrationUdtf14: this.integrationDetails ? this.integrationDetails?.integrationUdtf14 : null,
            integrationUdtf15: this.integrationDetails ? this.integrationDetails?.integrationUdtf15 : null,
            integrationUdtf16: this.integrationDetails ? this.integrationDetails?.integrationUdtf16 : null,
            integrationUdtf17: this.integrationDetails ? this.integrationDetails?.integrationUdtf17 : null,
            integrationUdtf18: this.integrationDetails ? this.integrationDetails?.integrationUdtf18 : null,
            integrationUdtf19: this.integrationDetails ? this.integrationDetails?.integrationUdtf19 : null,
            integrationUdtf20: this.integrationDetails ? this.integrationDetails?.integrationUdtf20 : null,
            integrationUdtf21: this.integrationDetails ? this.integrationDetails?.integrationUdtf21 : null,
            integrationUdtf22: this.integrationDetails ? this.integrationDetails?.integrationUdtf22 : null,
            integrationUdtf23: this.integrationDetails ? this.integrationDetails?.integrationUdtf23 : null,
            integrationUdtf24: this.integrationDetails ? this.integrationDetails?.integrationUdtf24 : null,

        };
    }

    formDataEmitted(formData) {
        this.openChangeReasonDialog(formData);
    }

    formStatusUpdated(formValid) {
        this.formValid = formValid;
    }

    openChangeReasonDialog(formData: any) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            formId: this.changeReasonFormId,
        };

        const dialogRef = this.dialog.open(ChangeReasonDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data) {
                    this.addChangeReasonsToFormData(formData, data);
                }

            }
        );
    }

    addChangeReasonsToFormData(formData: any, changeReasonFormData: any) {
        let merged = {...formData, ...changeReasonFormData};
        this.save(merged);
    }

    save(formData: any) {
        formData.asOf = this.effectiveDate;
        formData.originalCreatedOn = this.integrationDetails?.version?.createdOn ? this.integrationDetails?.version?.createdOn : null;

        this.overlayService.show();

        this.employeeIntegrationDetailsService.updateIntegrationDetails(this.employeeID, formData)
        .pipe(
            finalize(() => {
                this.overlayService.hide();
            })
        )
        .subscribe(
            (res) => {
                this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
                this.getData()
            }
        );
    }

    openAccessLogsDialog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            employeeId: this.employeeID,
            entityId: this.employeeID,
            url: api_routes.TALENT + '/' + this.employeeID + '/' + api_routes.INTEGRATION_DETAIL + '/' + this.employeeID,
        };

        const dialogRef = this.dialog.open(AccessLogsDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
            }
        );
    }

    openHistoryDialog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            employeeId: this.employeeID,
            entityId: null,
            url: api_routes.TALENT + '/' + this.employeeID + '/' + api_routes.INTEGRATION_DETAIL,
            columns: this.columns,
            tableId: db_tables.IntegrationDetails,
        };

        const dialogRef = this.dialog.open(HistoryDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
            }
        );
    }

    effectiveDateChange(event) {
        if (this.integrationDetails) {
            this.getData();
        }
    }

}

