import { Component, Input, OnInit, QueryList, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {api_routes, db_tables, features} from '@app/consts';
import { FeatureService } from '@app/core/services/feature.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { PositionsService } from '@app/modules/positions/services/positions.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { SecurityProtectedBase } from '@app/shared/components/security-protected/security-protected';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { AddPositionsDialogComponent } from './components/add-positions-dialog/add-positions-dialog.component';
import { EmployeePosition } from './models/employment-record-position.model';
import { EmploymentRecordPositionsService } from './services/employment-record-positions.service';
import { routes } from '@app/consts';
import {
  EmergencyContact
} from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/emergency-contacts/models/emergency-contact.model';
import {FormGeneratorDialogComponent} from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {ChangeReasonDialogComponent} from "@app/shared/components/change-reason-dialog/change-reason-dialog.component";
import {HistoryDialogComponent} from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/history-dialog/history-dialog.component";
import { ActivatedRoute } from '@angular/router';
import { FormGeneratorComponent } from '@app/shared/components/form-generator/form-generator.component';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { PositionFormDialogComponent } from '@app/modules/org-chart/components/position-editor/position-form-dialog.component';

@Component({
  selector: 'app-employment-record-positions',
  templateUrl: './employment-record-positions.component.html',
  styleUrls: ['./employment-record-positions.component.scss']
})
export class EmploymentRecordPositionsComponent extends SecurityProtectedBase implements OnInit {
  @ViewChild(FormGeneratorComponent) formGeneratorComponents: QueryList<FormGeneratorComponent>

  formId: string = 'frm_fAyLSe2p9XQkC4';
  changeReasonFormId: string = 'frm_k6xojHb9B23uM8';
  dialogRef: any;
  formValid: any;

  employeeId: string;
  public routes: typeof routes = routes;
  positions: EmployeePosition[];
  loading: boolean = true;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];
  isLoadingPermissions: boolean = true;

  public columns: any[] = [
    {field: 'name', tableId: "tfi_PosName", title: 'name'},
    {field: 'costCenters', tableId: "tfi_CostCentre", title: 'cost Centers', type: 'costCenter'},
    {field: 'assistant', tableId: "tfi_PosAssistant", title: 'assistant'},
    {field: 'department',  subField: "text", tableId: "tfi_PosDepartment", title: 'department'},
    {field: 'division',  subField: "text", tableId: "tfi_PosDivision", title: 'division'},
    {field: 'employeeCategory',  subField: "text", tableId: "tfi_PosEmployeeCategory", title: 'employee Category'},
    {field: 'employmentGroup',  subField: "text", tableId: "tfi_PosEmploymentGroup", title: 'employment Group'},
    {field: 'employmentType',  subField: "text", tableId: "tfi_PosEmploymentType", title: 'employment Type'},
    {field: 'functionalJobTitle',  subField: "text", tableId: "tfi_PosFunctionalJobTitle", title: 'functional Job Title'},
    {field: 'jobBand',  subField: "text", tableId: "tfi_PosJobBand", title: 'job Band'},
    {field: 'jobFamily',  subField: "text", tableId: "tfi_PosJobFamily", title: 'job Family'},
    {field: 'jobGroup',  subField: "text", tableId: "tfi_PosJobGroup", title: 'job Group'},
    {field: 'jobStep',  subField: "text", tableId: "tfi_PosJobStep", title: 'job Step'},
    {field: 'jobType',  subField: "text", tableId: "tfi_PosJobType", title: 'job Type'},
    {field: 'marketPosition',  subField: "text", tableId: "tfi_PosMarketPosition", title: 'market Position'},
    {field: 'marketPositionTitle',  subField: "text", tableId: "tfi_PosMarketPositionTitle", title: 'market Position Title'},
    {field: 'marketView',  subField: "text", tableId: "tfi_PosMarketView", title: 'market View'},
    {field: 'projectTeam',  subField: "text", tableId: "tfi_PosProjectTeam", title: 'project Team'},
    {field: 'region',  subField: "text", tableId: "tfi_PosRegion", title: 'region'},
    {field: 'shiftCode',  subField: "text", tableId: "tfi_PosShiftCode", title: 'shift Code'},
    {field: 'subDivision',  subField: "text", tableId: "tfi_PosSubDivision", title: 'sub Division'},
    {field: 'unionCode',  subField: "text", tableId: "tfi_PosUnionCode", title: 'union Code'},
    {field: 'workLocation',  subField: "name", tableId: "tfi_PosWorkLocation", title: 'work Location'},
    {field: 'workRotation',  subField: "name", tableId: "tfi_PosWorkRotation", title: 'work Rotation'},
    {field: 'workRotationStartDate', tableId: "tfi_PosWorkRotationStartDate", title: 'work Rotation Start Date', type: 'date'},
    {field: 'organization',  subField: "name", tableId: "tfi_PosOrganization", title: 'organization'},
    {field: 'parentPosition',  subField: "name", tableId: "tfi_PositionParent", title: 'parent Position'},
    {field: 'numberOfSlots', tableId: "tfi_PosNumberOfSlots", title: 'number Of Slots'},
    {field: 'startDate', tableId: "tfi_StartDate", title: 'start Date', type: 'date'},
    {field: 'endDate', tableId: "tfi_EndDate", title: 'end Date', type: 'date'},
    {field: 'enforceSlotLimit', tableId: "tfi_PosEnforceSlotLimit", title: 'enforce Slot Limit'},
    // {field: 'slotsFilled', tableId: "tfi_EdAdjustedOriginalHireDate", title: 'slots Filled'},
    // {field: 'slotsAvailable', tableId: "tfi_EdAdjustedOriginalHireDate", title: 'slots Available'},
    {field: 'clientPositionId', tableId: "tfi_PosClientPositionId", title: 'client Position Id'},
    {field: 'fte', tableId: "tfi_PosFte", title: 'fte'},
    {field: 'udl1',  subField: "text", tableId: "tfi_PosUdl1", title: 'udl1'},
    {field: 'udl2',  subField: "text", tableId: "tfi_PosUdl2", title: 'udl2'},
    {field: 'udt1', tableId: "tfi_PosUdt1", title: 'udt1'},
    {field: 'udt2', tableId: "tfi_PosUdt2", title: 'udt2'},
    {field: 'udd1', tableId: "tfi_PosUdd1", title: 'udd1', type: 'date'},
    {field: 'udd2', tableId: "tfi_PosUdd2", title: 'udd2', type: 'date'},
  ];

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private employmentRecordPositionsService: EmploymentRecordPositionsService,
    private positionsService: PositionsService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private tablePermissionsService: TablePermissionsService,
    private route: ActivatedRoute,
    featureService: FeatureService
  ) {
    super(featureService, features.POSITIONS)
  }

  ngOnInit(): void {
    this.employeeId = this.getIdInURL();
    this.getPositionDetails();
    this.getTargetEmployeeTablePermissions();
  }

  get tableReadAccessObj() {
    return {
      tableId: db_tables.PositionsEmployees,
      permission: 'read',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableCreateAccessObj() {
    return {
      tableId: db_tables.PositionsEmployees,
      permission: 'create',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableUpdateAccessObj() {
    return {
      tableId: db_tables.PositionsEmployees,
      permission: 'update',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableDeleteAccessObj() {
    return {
      tableId: db_tables.PositionsEmployees,
      permission: 'delete',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  getTargetEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeId);

    // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
    if(currentFetchedData !== null){
      this.targetEmployeeTablePermissions = currentFetchedData;
      this.isLoadingPermissions = false;
    }
    else {
      this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeId)
      .pipe(
        finalize(() => {
            this.isLoadingPermissions = false;
        })
      )
      .subscribe(
        res => {
          this.targetEmployeeTablePermissions = res;
        }
      );
    }
  }

  getIdInURL(): string {
    let IdInURL: string;
    
    this.route.paramMap.subscribe(
      params => IdInURL = params.get("id")
    )
      
    return IdInURL;
  }

  isCurrentPosition(date: string) {
    if(!date){
      return true;
    }

    if(moment().isBefore(date)){
      return true;
    }

    return false;
  }

  getPositionDetails() {
    this.loading = true;

    this.employmentRecordPositionsService.getEmployeePositions(this.employeeId)
    .pipe(
      finalize( () => {
        this.loading = false;
      })
    )
    .subscribe(
      res => {
        this.positions = res;

        this.positions.sort((a, b) => {
          return moment(b.startDate).diff(a.startDate);
        });

        this.positions.forEach(
          employeePosition => {
            employeePosition.positionDetails = this.positionsService.getPosition(employeePosition.position.id);
            this.getPositionManagers(employeePosition)
          }
        );
      }
    )
  }

  createFormData(position) {
    return {
        id: position ? position.id : null,
        clientPositionId: position ? position.clientPositionId : null,
        startDate: position ? position.startDate : null,
        endDate: position ? position.endDate : null,
        organization: position ? position.organization : null,
        assistant: position ? position.assistant : false,
        functionalJobTitle: position && position.functionalJobTitle ? position.functionalJobTitle.id : null,
        region: position && position.region ? position.region.id : null,
        division: position && position.division ? position.division.id : null,
        subDivision: position && position.subDivision ? position.subDivision.id : null,
        department: position && position.department ? position.department.id : null,
        workLocation: position ? position.workLocation?.id : null,
        workRotationStartDate: position ? position.workRotationStartDate : null,
        workRotation: position ? position.workRotation?.id : null,
        jobGroup: position && position.jobGroup ? position.jobGroup.id : null,
        jobFamily: position && position.jobFamily ? position.jobFamily.id : null,
        jobBand: position && position.jobBand ? position.jobBand.id : null,
        jobType: position && position.jobType ? position.jobType.id : null,
        jobStep: position && position.jobStep ? position.jobStep.id : null,
        projectTeam: position && position.projectTeam ? position.projectTeam.id : null,
        marketPositionTitle: position && position.marketPositionTitle ? position.marketPositionTitle.id : null,
        marketPosition: position && position.marketPosition ? position.marketPosition.id : null,
        marketView: position && position.marketView ? position.marketView.id : null,
        unionCode: position && position.unionCode ? position.unionCode.id : null,
        shiftCode: position && position.shiftCode ? position.shiftCode.id : null,
        employmentGroup: position && position.employmentGroup ? position.employmentGroup.id : null,
        employeeCategory: position && position.employeeCategory ? position.employeeCategory.id : null,
        employmentType: position && position.employmentType ? position.employmentType.id : null,
        enforceSlotLimit: position ? position.enforceSlotLimit : false,
        numberOfSlots: position ? position.numberOfSlots : 1,
        slotsAvailable: position ? position.slotsAvailable : null,
        slotsFilled: position ? position.slotsFilled : null,
        parentPosition: position?.parentPosition?.id ? position.parentPosition.id : null,
        name: position ? position.name : null,
        costCenters: position ? position.costCenters : null,
        fte: position ? position.fte : null,
        udl1: position ? position.udl1?.id : null,
        udl2: position ? position.udl2?.id : null,
        udt1: position ? position.udt1 : null,
        udt2: position ? position.udt2 : null,
        udd1: position ? position.udd1 : null,
        udd2: position ? position.udd2 : null,
    };
}

  openAddPositionDialog(positionId = null) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      employeeId: this.employeeId,
      positionId: positionId
    };

    const dialogRef = this.dialog.open(AddPositionsDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.getPositionDetails();
        }
      }
    );
  }

  openConfirmDeleteDialog(positionId: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
        text: this.translate.instant('ConfirmRemovePositionText')
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.deletePositionFromEmploymentRecord(positionId);
        }
      }
    );
  }

  deletePositionFromEmploymentRecord(positionId: string) {
    this.overlayService.show();

    this.employmentRecordPositionsService.deleteEmployeePosition(positionId)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.getPositionDetails();
        this.snackbarService.openSnackBar('Position removed successfully', 'clear', 'success');
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  getPositionManagers(employeePosition: EmployeePosition) {
    this.positionsService.getPositionManagers(employeePosition.position.id, employeePosition.endDate)
    .subscribe(
        res => {
          employeePosition.positionManagers = res;
        }
    );
  }

  openPositionFormDialog(employeePosition: EmployeePosition) {
    this.positionsService.getPosition(employeePosition.position.id )
    .subscribe(
        res => {
          const dialogConfig = new MatDialogConfig();

          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;

          dialogConfig.data = {
            position: res,
            targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
          };

          this.dialogRef = this.dialog.open(PositionFormDialogComponent, dialogConfig);

          const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
            if(event !== null) {
              this.getPositionDetails();
            }
          });
        }
    );
    
  }

  openHistoryDialog(positionId) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      employeeId: null,
      entityId: positionId,
      url: `${api_routes.POSITIONS}/${positionId}`,
      tableId: 'tbl_Positions',
      columns: this.columns
    };

    const dialogRef = this.dialog.open(HistoryDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
      }
    );
  }
}
