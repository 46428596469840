import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Position, PositionEmployee, PositionManager, PositionSubmit, PositionToEmploymentRecordSubmit } from '../models/positions.model';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { api_routes } from '@app/consts';
import { EnvironmentService } from "@app/core/services/environment.service";


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});
@Injectable({
  providedIn: 'root'
})
export class PositionsService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getPositions(skip?: number, take?: string, filterString?: string, sortString?: string, includeEmployees?: string, filterInactive?: string): Observable<PagedData<any>> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    filterString ? params = params.append('Filter', filterString) : null;
    sortString ? params = params.append('Sort', sortString) : null;
    includeEmployees ? params = params.append('includeEmployees', includeEmployees) : null;
    filterInactive ? params = params.append('filterInactive', filterInactive) : null;

    return this.http
      .get<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}`, {
        headers: headers,
        observe: 'response',
        params: params
      })
      .pipe(
        catchError(err => throwError(err)),
        map(res => {
          return res.body;
        })
      );
  }

  getPositionsAsync(skip?: number, take?: string, filterString?: string, sortString?: string): Observable<Position[]> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    filterString ? params = params.append('Filter', filterString) : null;
    sortString ? params = params.append('Sort', sortString) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}`, {
      headers: headers,
      params: params
    });
  }

  getPosition(positionId: string, asOf?: string): Observable<any> {
    let params = new HttpParams();
    asOf ? params = params.append('AsOf', asOf) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}/${positionId}`, {
      headers: headers,
      params: params
    });
  }

  postPosition(position: PositionSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}`, position, {
      headers: headers
    });
  }

  putPosition(positionId: string, position: PositionSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}/${positionId}`, position, {
      headers: headers
    });
  }

  deletePosition(positionId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}/${positionId}`, {
      headers: headers
    });
  }

  addPositionToEmploymentRecord(positionId: string, positionToEmploymentRecordSubmit: PositionToEmploymentRecordSubmit): Observable<any>{
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}/${positionId}/${api_routes.EMPLOYMENT_RECORD}`, positionToEmploymentRecordSubmit, {
      headers: headers
    });
  }

  deletePositionToEmploymentRecord(positionId: string, positionEmploymentRecordId: string): Observable<any>{
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}/${positionId}/${api_routes.EMPLOYMENT_RECORD}/${positionEmploymentRecordId}`, {
      headers: headers
    });
  }

  getPositionEmployees(positionId: string): Observable<PositionEmployee[]>{
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}/${positionId}/${api_routes.EMPLOYEES}`, {
      headers: headers
    });
  }

  getPositionManagers(positionId: string, asOf?: string): Observable<PositionManager[]>{
    let params = new HttpParams();
    asOf ? params = params.append('AsOf', asOf) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}/${positionId}/${api_routes.MANAGERS}`, {
      headers: headers,
      params: params
    });
  }

  getPositionHistory(positionId: string): Observable<PagedData<Position>> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}/${positionId}/${api_routes.HISTORY}`, {
      headers: headers
    });
  }
}
