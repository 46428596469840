import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { ReviewPlanGoalSetting } from '../../../../models/review-plan.model';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { ReviewPlansService } from '../../../../services/review-plans.service';
import { finalize } from 'rxjs';
import { GoalVerbose } from '@app/modules/performance/components/goal-plans/models/goal-plan.model';
import { MatExpansionPanel } from '@angular/material/expansion';
import { PageChangeEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SelectEmployeesDialogComponent } from '@app/shared/components/select-employees-dialog/select-employees-dialog.component';
import { ChangeReasonDialogComponent } from '@app/shared/components/change-reason-dialog/change-reason-dialog.component';

@Component({
  selector: 'app-review-plan-goal-setting',
  templateUrl: './review-plan-goal-setting.component.html',
  styleUrls: ['./review-plan-goal-setting.component.scss']
})
export class ReviewPlanGoalSettingComponent implements OnInit {
  @Input() goalSetting: ReviewPlanGoalSetting

  isChecked: boolean = false;
  @Output() checkboxChange = new EventEmitter< { goalId: string, isChecked: boolean } >();

  @ViewChild(MatExpansionPanel) panel: MatExpansionPanel;
  // Flag to track if the panel has been opened
  panelOpened = false;

  isLoading: boolean = true;
  goalSettingDetails: any;
  
  public columns: any[] = [
    { field: "reviewer", title: "Name", type: 'employee'}
  ];
  reviewersGridDataResult: { data: {
    reviewer: {
      id: string
      firstName: string
      lastName: string
    }
  }[]; total: number; };
  pageSize: number = 5;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  searchValue: string;
  searchFilterString: string;
  getUsersRequest: any;
  selectEmployeesDialogRef: any;

  // Goal Settings Form
  getFormData: boolean = false;
  formValid: boolean = false;
  formPristine: boolean = true;
  ratingsPristine: boolean = true;
  currentGoalSettingsFormData: any;

  constructor(
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private reviewPlansService: ReviewPlansService,
    private dialog: MatDialog,
    private ngZone: NgZone,
  ) { }


  ngOnInit(): void {
  }

  onCheckboxChange() {
    this.checkboxChange.emit({ goalId: this.goalSetting?.goal?.id, isChecked: this.isChecked });
  }

  // Function to handle panel open event
  onPanelOpened() {
    if (!this.panelOpened) {
      this.getData();
      this.panelOpened = true;
    }
  }

  getData(){
    this.getGoalSetting();
  }

  getGoalSetting() {
    this.isLoading = true;

    this.reviewPlansService.getReviewPlanGoalSetting(this.goalSetting.reviewPlan.id, this.goalSetting.goal.id)
      .pipe(
        finalize( () => {
          this.isLoading = false;
          this.getGridDataReviewers();
        })
      )
      .subscribe(
      (res) => {
        this.currentGoalSettingsFormData = undefined;
        this.goalSettingDetails = res;

        if(this.goalSettingDetails.reviewers === undefined){
          this.goalSettingDetails.reviewers = []
        }
      }
    )
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.getGridDataReviewers();
  }

  getGridDataReviewers(){
    let reviewers = this.goalSettingDetails.reviewers;

    if(this.searchValue) {
      reviewers = reviewers.filter(
        reviewer => reviewer.firstName.toLowerCase().includes(this.searchValue) || reviewer.lastName.toLowerCase().includes(this.searchValue) 
      )
    }

    let reviewersInRange = reviewers.slice(this.skip, this.skip + this.pageSize + 1);

    // convert data to display in grid
    const transformedData = reviewersInRange.map(item => ({
      reviewer: {
        id: item.id,
        firstName: item.firstName,
        lastName: item.lastName
      }
    }));

    this.reviewersGridDataResult = {
      data: transformedData,
      total: this.goalSettingDetails.reviewers.length
    }
  }

  showAddUserModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      nonSelectableEmployees: []
    };

    this.ngZone.runOutsideAngular(() => {
      this.selectEmployeesDialogRef = this.dialog.open(SelectEmployeesDialogComponent, dialogConfig);
    });

    const sub = this.selectEmployeesDialogRef.componentInstance.emitSelectedEmployees.subscribe((event) => {
      this.saveGoalSettings(event)
    });
  }

  deleteAllSelected(employees: {
    id: string
    firstName: string
    lastName: string
  }[]) {
    this.goalSettingDetails.reviewers = this.goalSettingDetails?.reviewers.filter( reviewer => !employees.some(employee => employee.id === reviewer.id) )
    this.saveGoalSettings();
  }

  search(newSearchString: string){
    this.searchValue = newSearchString.toLowerCase();
    this.skip = 0;
    this.getGridDataReviewers();
  }

  saveGoalSettings(selectedEmployees?: string[]) {
    let reviewerIds = this.goalSettingDetails?.reviewers ? this.goalSettingDetails?.reviewers.map( reviewer => reviewer.id ) : [];

    if(selectedEmployees){
      reviewerIds = [...reviewerIds, ...selectedEmployees] 
    }

    let submitData = {
      ...this.currentGoalSettingsFormData,
      ...{ reviewers: reviewerIds }
    }

    this.overlayService.show();

    this.reviewPlansService.updateReviewPlanGoalSetting(this.goalSetting.reviewPlan.id, this.goalSetting.goal.id, submitData)
    .pipe(finalize(() => this.overlayService.hide()))
    .subscribe(
        (res) => {
          if(this.selectEmployeesDialogRef) {
            this.selectEmployeesDialogRef.close();
          }
          
          this.getGoalSetting();
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
        }
    );
  }

  openChangeReasonDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formId: 'frm_kFYw2RpJlweXyv',
    };

    const dialogRef = this.dialog.open(ChangeReasonDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
          if (data) {
            this.currentGoalSettingsFormData = { ...this.currentGoalSettingsFormData, ...data }
            this.saveGoalSettings();
          }
      }
    );
  }
  

  formDataEmitted(formDataEmitted) {
    console.log(formDataEmitted)
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  formPristineEmitted(formPristine: boolean) {
    this.formPristine = formPristine;
  }

  formDataChange(formData) {
    if(this.currentGoalSettingsFormData === undefined){
      this.currentGoalSettingsFormData = formData;
    }
    else {
      this.currentGoalSettingsFormData = formData;
      this.openChangeReasonDialog();
    }
  }

}
