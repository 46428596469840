<app-layout>

    <mat-card>
        <mat-card-title class="header">
            <h5 appLocalizationPopupDirective localizationCode="BuildANewCustomReport">{{ 'BuildANewCustomReport' | translate: {Default: "Build A New Custom Report"} }}</h5>
            <div>
                <button class="mat-raised-button" (click)="close()">Close</button>
                <button mat-raised-button setColor="primaryColour" color="primary" (click)="getFormData = !getFormData" [disabled]="!form.valid || !formValid || reportElements.length == 0" *ngIf="form" class="k-ml-4">Save</button>
            </div>
        </mat-card-title>

        <mat-card-content>
            <ng-container *ngIf="isFormLoading; else loaded">
                <ngx-skeleton-loader
                    count="5"
                    [theme]="{
                              'height.px': 50
                          }"
                ></ngx-skeleton-loader>
            </ng-container>

            <ng-template #loaded>
                <app-form-generator
                    [formId]="formId"
                    [formData]="formData"
                    [emitFormDataTrigger]="getFormData"
                    (emitFormData)="formDataEmitted($event)"
                    (emitFormStatus)="formStatusUpdated($event)"
                    (emitFormPristine)="formPristineEmitted($event)"
                ></app-form-generator>

                <form class="form-container" *ngIf="form" [formGroup]="form">
                    <div class="section">

                        <mat-form-field appearance="fill">
                            <mat-label>
                                Report Type
                            </mat-label>

                            <mat-select disableOptionCentering formControlName="reportTypeId">
                                <ng-container *ngFor="let option of reportTypes">
                                    <mat-option [value]="option.id">{{option.display}}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </form>

                <div class="section filter-section">
                    <div class="section-header">
                        <h6>Add Report Elements</h6>

                        <button mat-stroked-button setColor="primaryColour" color="primary" (click)="openReportElementDialog()" class="k-mb-4">
                            <mat-icon class="material-icons-outlined">add</mat-icon>
                            Add Report Element
                        </button>
                    </div>
                </div>
            </ng-template>
        </mat-card-content>
    </mat-card>


    <div cdkDropList (cdkDropListDropped)="drop($event, reportElements, reportElementData)">

        <ng-container *ngFor="let reportElement of reportElements; let i = index">

            <mat-card cdkDrag>
                <div class="example-custom-placeholder" *cdkDragPlaceholder></div>

                <mat-card-title class="header">
                    <h5>{{reportsMapping.getElementName(reportElement)}}</h5>
                </mat-card-title>

                <mat-card-content>

                    <div *ngIf="reportElement.name =='datagrid_element'" class="form-element-container">
                        <kendo-grid
                            [data]="reportElementData[i]"
                            [loading]="false"
                            class="custom-grid"
                        >
                            <ng-container *ngIf="reportElementData[i] != null">
                                <kendo-grid-column
                                    *ngFor="let column of getColumns(reportElement)"
                                    field="{{ getFieldMapping(reportElement, column.table, column.field) }}"
                                    title="{{ column.title }}"
                                    [style.minHeight.vh]="55"
                                    [resizable]="true"
                                    class="custom-column"
                                    [width]="getColumnWidth(reportElement)"
                                >
                                </kendo-grid-column>
                            </ng-container>
                            <ng-template kendoGridNoRecordsTemplate>
                                <div class="k-m-8">
                                    <p *ngIf="reportElementData[i] != null && reportElementData[i].length === 0">No records available</p>
                                </div>
                            </ng-template>

                            <kendo-grid-messages 
                                [noRecords]="isLoading ? '' : translate.instant('Grid-NoRecordsAvailable')"
                                [pagerItemsPerPage]="translate.instant('Grid-Pager-ItemsPerPage')" 
                                [pagerItems]="translate.instant('Grid-Pager-Items')"
                                [pagerOf]="translate.instant('Grid-Pager-Of')"
                            ></kendo-grid-messages>
                        </kendo-grid>

                        <!-- Progress Bar -->
                        <div *ngIf="reportElementData[i] == null"
                             style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; display: flex; align-items: center; justify-content: center;">
                            <div style="text-align: center;">
                                <div style="text-align: center;">
                                    <mat-progress-bar class="custom-progress-bar" mode="determinate"
                                                      [value]="customLoadingIndicator.progress"
                                                      color="accent">
                                    </mat-progress-bar>
                                </div>
                                <p class="k-m-5" style="text-align: center; color: grey;">{{ customLoadingIndicator.message }}</p>
                            </div>
                        </div>


                        <div class="form-element-controls">
                            <span
                                (click)="openConfirmDeleteDialog(i)"
                                class="material-icons-outlined clickable-icon"
                                setColor 
                                [setColorRules]="[
                                    {
                                        color: 'primaryColour',
                                        events: ['hover']
                                    }
                                ]" 
                                matTooltip="Delete"
                                matTooltipPosition="above">
                                delete
                            </span>
                            <span
                                (click)="openReportElementDialog(i)"
                                class="material-icons-outlined clickable-icon"
                                setColor 
                                [setColorRules]="[
                                    {
                                        color: 'primaryColour',
                                        events: ['hover']
                                    }
                                ]" 
                                matTooltip="Edit"
                                matTooltipPosition="above">edit</span>

                        </div>
                    </div>


                    <div *ngIf="reportElement.name =='piechart_element'" class="form-element-container">

                        <ng-template #loading>
                            <div class="pie-chart-loader">
                                <ngx-skeleton-loader
                                    count="1"
                                    appearance="circle"
                                    [theme]="{ 'height.px': 250,'width.px': 250}">
                                </ngx-skeleton-loader>
                            </div>
                        </ng-template>

                        <kendo-chart title="" *ngIf="reportElementData[i] != null; else loading">
                            <kendo-chart-legend position="bottom"></kendo-chart-legend>
                            <kendo-chart-series>
                                <kendo-chart-series-item
                                    type="pie"
                                    [data]="reportElementData[i]"
                                    field="value"
                                    categoryField="category"
                                    [labels]="{ visible: true, content: labelContent }"
                                    colorField="colorField"
                                >
                                </kendo-chart-series-item>
                            </kendo-chart-series>
                        </kendo-chart>
                        <div class="form-element-controls">
                            <span
                                (click)="openConfirmDeleteDialog(i)"
                                class="material-icons-outlined clickable-icon"
                                setColor 
                                [setColorRules]="[
                                    {
                                        color: 'primaryColour',
                                        events: ['hover']
                                    }
                                ]" 
                                matTooltip="Delete"
                                matTooltipPosition="above">
                                delete
                            </span>
                            <span
                                (click)="openReportElementDialog(i)"
                                class="material-icons-outlined clickable-icon"
                                setColor 
                                [setColorRules]="[
                                    {
                                        color: 'primaryColour',
                                        events: ['hover']
                                    }
                                ]" 
                                matTooltip="Edit"
                                matTooltipPosition="above">edit</span>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </ng-container>
    </div>
</app-layout>
