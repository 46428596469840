<mat-card class="mat-elevation-z0">
    <mat-card-title class="header">
        <h5 appLocalizationPopupDirective localizationCode="Goals">
            {{ 'Goals' | translate: {Default: "Goals"} }}
        </h5>

        <button 
            mat-flat-button 
            class="action-button"
            setColor="primaryColour"
            color="primary" 
            (click)="openGoalDialog()" 
        >
                <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon>
                <span class="button-text" appLocalizationPopupDirective localizationCode="CreateNew">{{ 'CreateNew' | translate: {Default: "Create new"} }}</span>
        </button>
    </mat-card-title>
    <mat-card-content>
        <kendo-grid
            [data]="gridDataResult"
            [loading]="isLoading"
            [pageSize]="pageSize"
            [skip]="skip"
            [pageable]="{
                buttonCount: 6,
                info: true,
                type: 'numeric',
                pageSizes: [5, 10, 20, 50, 100],
                previousNext: true
            }"
            (cellClick)="openGoalDialog($event.dataItem)"
        >
            <kendo-grid-column
                [width]="70"
                class="center-align"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    <button class="row-action-button" (click)="$event.stopPropagation()" mat-mini-fab [matMenuTriggerFor]="settings">
                        <mat-icon 
                            setColor 
                            [setColorRules]="[
                                {
                                    color: 'primaryColour',
                                    events: ['hover']
                                }
                            ]"
                            class="settings-button__icon material-icons-outlined"
                        >
                            more_vert
                        </mat-icon>
                    </button>
                    <div class="settings-menu">
                        <mat-menu #settings="matMenu" xPosition="before">
                            <button class="settings-menu__item material-icons-outlined" (click)="openConfirmDeleteDialog(dataItem.id)" mat-menu-item>
                                <mat-icon class="settings-button__icon material-icons-outlined">delete</mat-icon>
                                {{ 'Delete' | translate: {Default: "Delete"} }}
                            </button>
                        </mat-menu>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <!-- <kendo-grid-column field="id"></kendo-grid-column> -->
            <kendo-grid-column
                field=""
                [title]="translate.instant('EmployeeGoals-Category')"
                class="center-align"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        <mat-chip-list>
                            <mat-chip 
                                appLocalizationPopupDirective 
                                [localizationCode]="'PersonalGoal'"
                                [ngClass]="'green-chip'"
                            >
                                {{ translate.instant('PersonalGoal') }}
                            </mat-chip>
                        </mat-chip-list>
                    </div>
                </ng-template>
            </kendo-grid-column>    

            <kendo-grid-column
                field="objective"
                [title]="getColumnTitle('tfi_PEgObjective')"
            ></kendo-grid-column>

            <kendo-grid-column
                field="description"
                [title]="getColumnTitle('tfi_PEgDescription')"
            >

                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        {{ (dataItem.description?.length > 60) ? (dataItem.description | slice: 0 : 60) + '...' : (dataItem.description) }}
                    </div>
                </ng-template>
        
            </kendo-grid-column>

            <kendo-grid-column
                field="goalType"
                [title]="getColumnTitle('tfi_PEgGoalType')"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        {{ dataItem.goalType.name }}
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
                field="actualCompletionDate"
                [title]="getColumnTitle('tfi_PEgActualCompletionDate')"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        {{ dataItem.actualCompletionDate | date:'mediumDate' }}
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
                field="expectedCompletionDate"
                [title]="getColumnTitle('tfi_PEgExpectedCompletionDate')"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        {{ dataItem.expectedCompletionDate | date:'mediumDate' }}
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
                field="percentageComplete"
                [title]="getColumnTitle('tfi_PEgPercentageComplete')"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical percentage-column">
                        <span class="percentage-value" *ngIf="dataItem.percentageComplete !== null">
                            {{ calculateSliderPercentage(dataItem.percentageComplete, 1) }}%
                        </span>

                        <mat-progress-bar setColor="primaryColour" mode="determinate" [value]="calculateSliderPercentage( dataItem.percentageComplete, 1 )"></mat-progress-bar>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
                field="weight"
                [title]="getColumnTitle('tfi_PEgWeight')"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical percentage-column">
                        <span class="percentage-value" *ngIf="dataItem.weight !== null">
                            {{ calculateSliderPercentage(dataItem.weight, 1) }}%
                        </span>

                        <mat-progress-bar setColor="primaryColour" mode="determinate" [value]="calculateSliderPercentage( dataItem.weight, 1 )"></mat-progress-bar>
                    </div>
                </ng-template>
            </kendo-grid-column>



            <div *kendoGridDetailTemplate="let dataItem">
                <app-employee-personal-goals-child-goals-data-grid
                    [employeeId]="employeeId" 
                    [parent]="dataItem" 
                    [parentId]="dataItem.id"
                    goalEntryAllowed="false"
                ></app-employee-personal-goals-child-goals-data-grid>
            </div>
        </kendo-grid>
    </mat-card-content>
</mat-card>
