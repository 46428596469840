<div>
    <form class="form" *ngIf="form" [formGroup]="form">

        <div class="form-section">
            <div class="form-section-title">
                <label>{{label}}</label>
            </div>
            <div class="form-section-contents">

                <mat-form-field appearance="fill">
                    <mat-label>Start Time</mat-label>
                    <input
                            matInput
                            aria-label="start time"
                            [ngxTimepicker]="startTime"
                            [format]="24"
                            formControlName="startTime"
                    />
                    <ngx-material-timepicker #startTime>
                    </ngx-material-timepicker>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>End Time</mat-label>
                    <input
                            matInput
                            aria-label="end time"
                            [ngxTimepicker]="endTime"
                            [format]="24"
                            formControlName="endTime"
                    />
                    <ngx-material-timepicker #endTime>
                    </ngx-material-timepicker>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>
                        Break Time
                    </mat-label>

                    <input matInput type="number" placeholder="1.0" min="0" step="0.01" formControlName="breakTime">

                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>
                        Unpaid Break Time (Hours)
                    </mat-label>

                    <input matInput type="number" placeholder="1.0" min="0" step="0.01" formControlName="unpaidBreakTime">

                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>
                        Working Hours
                    </mat-label>

                    <input matInput
                           [readonly]="true"
                           type="number"
                           formControlName="workingHours">
                </mat-form-field>

                <mat-icon 
                    setColor 
                    [setColorRules]="[
                        {
                            color: 'primaryColour',
                            events: ['hover']
                        }
                    ]"
                    class="material-icons-outlined clickable-icon" 
                    matTooltip="Clear Values" 
                    (click)="clearForm()"
                >clear</mat-icon>
            </div>
        </div>
    </form>
</div>
