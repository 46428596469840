import { Version } from "@app/shared/models/version.model";

export interface Lookup { 
  id: string,
  startDate: string,
  endDate: string,
  group: string,
  isDeleted: boolean,
  prioritize: boolean,
  text: string,
  textLocalizations: {
    culture: string,
    localization: string
  }[],
  version: Version
}

export interface returnedLookup {
  id: string,
  text: string
}

export interface LookupSubmit {  
  effectiveDate: string,
  changeReason: string,
  changeReasonComments: string,
  id: string,
  prioritize: boolean,
  startDate: string,
  endDate: string,
  text: {
    localizations: {
      culture: string,
      localization: string
    }[]
  }
}

export interface LookupVerbose {
  id: string,
  group: string,
  text: string,
  prioritize: boolean,
  startDate: string,
  endDate: string,
  textLocalizations: {
    culture: string,
    localization: string
  }[],
  isDeleted: boolean,
  versionCreatedOn: string,
  versionCreatedById: string,
  versionCreatedByFirstName: string,
  versionCreatedByLastName: string,
  versionEffectiveDate: string,
  modifiedList: string[],
  changeReason: string,
  changeReasonComments: string
}

export interface TableLookups {
  lookups: TableLookup[]
}

export interface TableLookup {
  group: string,
  name: string,
  url: string,
  fieldMappings: [
    {
      id: string,
      fieldName: string,
      tableName: string
    }
  ]
}

export interface TableList {
  id: string,
  name: string,
  parentTable: { id: string, name: string },
  fields: TableListField[]
}

export interface TableListField {
  id: string,
  name: string,
  defaultValue: string,
  enabled: boolean,
  requiredField: boolean,
  format: string,
  minimumValue: string,
  properties: {
    property: string,
    value: string,
  }[],
  GetOptionUrl?: string,
  ListGroup?: string,
}

export interface TableListFieldOption {
  id: string,
  startDate: string,
  endDate: string,
  prioritize: boolean,
  text: string
}

export interface TableListFieldOptionVerbose {
  id: string,
  startDate: string,
  endDate: string,
  prioritize: boolean,
  text: { culture: string, text: string }[],
  version: Version
}

export interface TableListFieldOptionSubmit {
  id: string,
  startDate: string,
  endDate: string,
  prioritize: boolean,
  text: { culture: string, text: string }[],
  asOf: string,
  changeReason: string,
  changeReasonComments: string,
}