import { Version } from "@app/shared/models/version.model";

export class EmployeeAbsenseTime {
    ticks: number;
    days: number;
    hours: number;
    milliseconds: number;
    minutes: number;
    seconds: number;
    totalDays: number;
    totalHours: number;
    totalMilliseconds: number;
    totalMinutes: number;
    totalSeconds: number;
}

export class EmployeeAbsence {
    id: string;
    startDate: string;
    endDate: string;
    startTime: EmployeeAbsenseTime;
    endTime: EmployeeAbsenseTime;
    hours: number;
    timeOffType: {
      id: string;
      name: string
    };
    absenceNumber: string;
    comments: string;
    returnToWork: string;
    udl1: {id: string, text: string};
}

export class EmployeeAbsenceVerbose {
    id: string;
    startDate: string;
    endDate: string;
    startTime: EmployeeAbsenseTime;
    endTime: EmployeeAbsenseTime;
    hours: number;
    timeOffType: {
      id: string;
      name: string
    };
    absenceNumber: string;
    comments: string;
    returnToWork: string;
    udl1: {id: string, text: string};
    version: Version;
}

export class EmployeeAbsenseSubmit {
    id?: string;
    asOf: string;
    changeReason: string;
    changeReasonComments: string;
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
    hours: number;
    timeOffType: string;
    absenceNumber: string;
    comments: string;
    returnToWork: string;
}

export class ScheduleAbsence {
  id: string
  startDate: string
  endDate: string
  timeOffType: {
    id: string
    name: string
  }
}