import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bootstrap-alert',
  templateUrl: './bootstrap-alert.component.html',
  styleUrls: ['./bootstrap-alert.component.scss']
})
export class BootstrapAlertComponent implements OnInit {
  @Input() text: string;
  @Input() localizationCode: string;
  @Input() showClearButton: boolean = false;
  @Input() type: string = 'success';
  
  constructor() { }

  ngOnInit(): void {
  }

  dismiss(){}

}
