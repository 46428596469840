import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CompensationHistoryWidgetDialogComponent } from './components/compensation-history-widget-dialog/compensation-history-widget-dialog.component';

@Component({
  selector: 'app-compensation-history-widget',
  templateUrl: './compensation-history-widget.component.html',
  styleUrls: ['./compensation-history-widget.component.scss']
})
export class CompensationHistoryWidgetComponent implements OnInit {

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 500;

    const dialogRef = this.dialog.open(CompensationHistoryWidgetDialogComponent, dialogConfig);
    
  }
}
