import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { TimeOffInLieuService } from '@app/core/services/time-off-in-lieu/time-off-in-lieu.service';
import { TranslationService } from '@app/core/services/translation.service';
import { TimeOffEmailTranslation } from '@app/modules/lookup/models/time-off.model';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import { TranslateService } from '@ngx-translate/core';
import { finalize, forkJoin } from 'rxjs';

@Component({
  selector: 'app-toil-emails',
  templateUrl: './toil-emails.component.html',
  styleUrls: ['./toil-emails.component.scss']
})
export class ToilEmailsComponent implements OnInit {
  emails = [
    {
      id: 'ToilAccrualApprovalEmail',
      text: this.translate.instant('ToilAccrualApprovalEmail')
    },
    {
      id: 'ToilAccrualDeniedEmail',
      text: this.translate.instant('ToilAccrualDeniedEmail')
    },
    {
      id: 'ToilAccrualRequestEmployee',
      text: this.translate.instant('ToilAccrualRequestEmployee')
    },
    {
      id: 'ToilAccrualRequestManager',
      text: this.translate.instant('ToilAccrualRequestManager')
    },
    {
      id: 'ToilTimeOffRequestEmployee',
      text: this.translate.instant('ToilTimeOffRequestEmployee')
    },
    {
      id: 'ToilTimeOffRequestManager',
      text: this.translate.instant('ToilTimeOffRequestManager')
    },
    {
      id: 'ToilTimeOffRequestEmployeeApproval',
      text: this.translate.instant('ToilTimeOffRequestEmployeeApproval')
    },
    {
      id: 'ToilTimeOffRequestEmployeeDenied',
      text: this.translate.instant('ToilTimeOffRequestEmployeeDenied')
    },
    {
      id: 'ToilTimeOffRequestManagerCancel',
      text: this.translate.instant('ToilTimeOffRequestManagerCancel')
    },
    {
      id: 'ToilTimeOffRequestEmployeeCancel',
      text: this.translate.instant('ToilTimeOffRequestEmployeeCancel')
    },
    {
      id: 'ToilTimeOffRequestEmployeeModification',
      text: this.translate.instant('ToilTimeOffRequestEmployeeModification')
    },
    {
      id: 'ToilTimeOffRequestManagerModification',
      text: this.translate.instant('ToilTimeOffRequestManagerModification')
    },
  ];
  selectedEmail;
  loadingCultures: boolean;
  cultures: Culture[] = [];
  selectedCulture: Culture;
  loadingTranslations: boolean;
  translations: TimeOffEmailTranslation;
  showForm: boolean = false;

  form: FormGroup;
  submittingForm: boolean = false;

  constructor(
    private cultureService: CultureService,
    private translate: TranslateService,
    private timeOffInLieuService: TimeOffInLieuService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private formBuilder: FormBuilder) {
      this.form = this.formBuilder.group({
        culture: ['', Validators.required],
        subject: ['', Validators.required],
        body: ['', Validators.required]
      });

      // Subscribe to value changes of the form control
      this.form.get('culture').valueChanges.subscribe((value) => {
        this.selectedCulture = this.cultures.find( culture => culture.id === value)
        this.populateForm();
      });
    }

  ngOnInit(): void {
    this.getCultures();
  }

  getCultures() {
    this.loadingCultures = true;
    
    this.cultureService.getCultures()
    .pipe(
      finalize(
        () => {
          this.loadingCultures = false;
          this.getCultureTranslations(this.emails[0]);
        }
      )
    )
    .subscribe(
      res => {
        this.cultures = res;
        this.selectedCulture = this.cultures[0];
      }
    );
  }

  // get the translations for a culture
  getCultureTranslations(email: any) {
    this.loadingTranslations = true;
    this.selectedEmail = email;

    this.timeOffInLieuService.getEmailTranslations(this.selectedEmail?.id) 
    .pipe(
      finalize(
        () => {
          this.populateForm();
          this.loadingTranslations = false;
        }
      )
    )
    .subscribe(
      res => {
        this.translations = res;
      }
    )
  }

  populateForm() {
    let subject = this.translations.subject.find(translation => translation.culture === this.selectedCulture.id)
    let body = this.translations.body.find(translation => translation.culture === this.selectedCulture.id)

    if(this.form.get('culture').value !== this.selectedCulture.id){
      this.form.get('culture').setValue(this.selectedCulture.id);
    }

    this.form.patchValue({
      subject: subject.text,
      body: body.text
    });
  }

  save() {
    // Update the translation when the user presses save in the form
    this.overlayService.show();

    let submitData: TimeOffEmailTranslation = {
      subject: [
        {
          culture: this.form.get('culture').value,
          text: this.form.get('subject').value
        }
      ],
      body: [
        {
          culture: this.form.get('culture').value,
          text: this.form.get('body').value
        }
      ]
    }

    this.timeOffInLieuService.updateEmailTranslation(this.selectedEmail.id, submitData)
    .pipe(
      finalize(
        () => {
          this.overlayService.hide();
        }
      )
    )
    .subscribe(
      res => {
        this.snackbarService.openSnackBar(this.translate.instant('SavedSuccessfully'), 'clear', 'success');
        this.getCultureTranslations(this.selectedEmail);
      }
    );
  }

}
