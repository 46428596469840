import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-monthly-holiday-utilisation-report',
  templateUrl: './monthly-holiday-utilisation-report.component.html',
  styleUrls: ['./monthly-holiday-utilisation-report.component.scss']
})
export class MonthlyHolidayUtilisationReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
