<app-performance>
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
          <h5 appLocalizationPopupDirective localizationCode="EditGoalPlan" mat-dialog-title>{{ 'EditGoalPlan' | translate: {Default: 'Edit Goal Plan'} }}</h5>
      
          <div class="k-flex-grow"></div>

          <button mat-stroked-button setColor="primaryColour" color="primary" class="publish-history-button" appPreventDoubleClick (throttledClick)="openPublishHistoryDialog()">
            <mat-icon aria-hidden="false" class="material-icons-outlined  k-mr-3">history</mat-icon>
            {{ 'PublishHistory' | translate: {Default: "Publish History"} }}</button>
          <button mat-stroked-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openConfirmPublishGoalPlanDialog()">{{ 'Publish' | translate: {Default: "Publish"} }}</button>
          <button class="mat-stroked-button" appPreventDoubleClick (throttledClick)="navigateBack()">{{ 'Close' | translate: {Default: "Close"} }}</button>
          <button 
              mat-raised-button 
              setColor="primaryColour" 
              color="primary" 
              appPreventDoubleClick 
              (throttledClick)="getFormData = !getFormData" 
              [disabled]="formValid === false"
          >{{ 'Save' | translate: {Default: "Save"} }}</button>  
        </mat-card-title>

        <mat-card-content>
          <ng-container *ngIf="isLoading; else loaded">
            <ngx-skeleton-loader
              count="5"
              [theme]="{ 
                  'height.px': 50
              }"
            ></ngx-skeleton-loader>
          </ng-container>
          <ng-template #loaded>
            <div class="top-row">
                <app-form-generator 
                    [formId]="formId"
                    [formData]="formData"
                    [emitFormDataTrigger]="getFormData"
                    (emitFormData)="formDataEmitted($event)"
                    (emitFormStatus)="formStatusUpdated($event)"
                    (emitFormPristine)="formPristineEmitted($event)"
                ></app-form-generator>
    
                <div class="grid-container" *ngIf="goalPlanId">
                  <app-goal-plan-owners
                    *ngIf="!isLoading"
                    [goalPlan]="goalPlan"
                  ></app-goal-plan-owners>

                    <app-data-grid-comp
                        [columns]="columns"
                        [gridDataResult]="employeesGridDataResult"
                        [pageSize]="pageSize"
                        [skip]="skip"
                        category="Employees"
                        [rowSelectionSettings]="{
                            canSelectRows: true,
                            selectableMode: 'multiple',
                            selectRowBy: 'employee'
                        }"
                        selectRowBy="employee"
                        actionButtonText="Add"
                        [isLoading]="isLoadingEmployees"
                        [clearSelectedItems]="clearSelectedItems"
                        searchable="true"
                        [rowActionButtons]="gridActionButtons"
                        (rowActionEvent)="showDetails($event)"
                        (emitSearchEvent)="search($event)"
                        (emitPageChangeEvent)="pageChange($event)"
                        (emitOpenDialogEvent)="showAddUserModal()"
                        (emitDeleteEvent)="deleteAllSelected($event)"
                    ></app-data-grid-comp>
                </div>
            </div>

            <div class="goals-container">
                <app-goal-plan-goals [goalPlanId]="goalPlanId"></app-goal-plan-goals>
                <app-goal-plan-employee-goals [goalPlanId]="goalPlanId"></app-goal-plan-employee-goals>
            </div>


          </ng-template>
        </mat-card-content>
    </mat-card>
</app-performance>
