<ng-container *ngIf="reports$ | withLoading | async as reports">
    <ng-template [ngIf]="reports.value">
        <div class="content">
            <div class="title">
                <h5>{{ 'PreBuiltReports' | translate: {Default: "Pre Built Reports"} }}</h5>

                <mat-form-field class="search-field" appearance="outline" >
                    <input matInput
                           [(ngModel)]="reportSearchValue"
                           placeholder="Search...">
                    <mat-icon matPrefix class="material-icons-outlined">
                        search
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="reports">
                <ng-container *ngFor="let report of reports.value | reportsSearch:reportSearchValue">
                    <mat-card 
                        setColor
                        [setColorRules]="[
                            {
                                color: 'primaryColour',
                                style: 'border-color'
                            }
                        ]"
                        [routerLink]="report.href" 
                        class="hardcoded-form-card mat-elevation-z0 primaryHoverColor" 
                        *appHasFeature="report.featureCode"
                    >
                        <div class="icon-div">
                            <span setColor="primaryColour" class="material-icons-outlined primaryColor">
                                {{report.icon}}
                            </span>
                        </div>
                
                        <div class="text">
                            <mat-card-title class="header">
                                {{report.name}}
                            </mat-card-title>
                            <mat-card-content>
                                {{report.detail}}
                            </mat-card-content>
                        </div>
                    </mat-card>
                </ng-container>

                <!-- <h6 *ngIf="reports.value.length === 0">No Data</h6> -->
            </div>

        </div>
    </ng-template>
    <ng-template [ngIf]="reports.error">Error {{ obs.error }}</ng-template>
    <ng-template [ngIf]="reports.loading">
        <ngx-skeleton-loader
            count="5"
            [theme]="{ 
                'height.px': 50
            }"
        ></ngx-skeleton-loader>
    </ng-template>
</ng-container>


<!-- <app-local-data-grid-comp
    [isLoading]="isLoading"
    category="PreBuiltReports"
    [columns]="columns"
    [data]="reports"
    clickableRows="true"
    (emitRowClickEvent)="openReport($event)"
></app-local-data-grid-comp> -->