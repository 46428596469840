import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';

@Component({
  selector: 'app-pdf-viewer-dialog',
  templateUrl: './pdf-viewer-dialog.component.html',
  styleUrls: ['./pdf-viewer-dialog.component.scss']
})
export class PdfViewerDialogComponent implements OnInit {
  pdfUrl: string;
  signatureRequired: boolean;
  allowFormSave: boolean;

  @ViewChild('signature')
  public signaturePad: SignaturePadComponent;
  @ViewChild('typeSignature')
  public typeSignatureInput: ElementRef;

  url: string;
  showSignature: boolean = true;
  signaturePadOptions: NgSignaturePadOptions = { // passed through to szimek/signature_pad constructor
    minWidth: 5,
    canvasWidth: 500,
    canvasHeight: 100
  };

  constructor(
    private dialogRef: MatDialogRef<PdfViewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      this.pdfUrl = data.pdfUrl;
      this.signatureRequired = data.signatureRequired;
      this.allowFormSave = data.allowFormSave;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    // if(this.signaturePad){
    //   this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
    //   this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    // }
  }

  drawComplete(event: MouseEvent | Touch) {
    // will be notified of szimek/signature_pad's onEnd event
    console.log('Completed drawing', event);
    console.log(this.signaturePad.toDataURL());
  }

  drawStart(event: MouseEvent | Touch) {
    // will be notified of szimek/signature_pad's onBegin event
    console.log('Start drawing', event);
  }

  clearSignature() {
    this.signaturePad ? this.signaturePad.clear() : this.typeSignatureInput.nativeElement.value = '';
  }

  saveForm() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close();
  }
}
