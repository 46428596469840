import { Injectable, ViewChild }           from '@angular/core';
import { Observable, of, Subject }           from 'rxjs';
import { CanDeactivate,
         ActivatedRouteSnapshot,
         RouterStateSnapshot }  from '@angular/router';
import { FormGeneratorComponent } from '@app/shared/components/form-generator/form-generator.component';

@Injectable({ providedIn: 'root' })

export class CanDeactivateGuard implements CanDeactivate<FormGeneratorComponent> {
  canDeactivate(
    component: any,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if(component?.formGeneratorComponent?.isFormPristine() === false){
      return component.formGeneratorComponent.openConfirmCloseDialog();
    } 
    // If there is multiple form generator componenets to check
    else if(component?.formGeneratorComponents?.length > 0){
      let openDialog = false;

      component.formGeneratorComponents.forEach(formGeneratorComponentObj => {
        if(formGeneratorComponentObj.formGeneratorComponent.isFormPristine() === false){
          openDialog = true;
        }
      });

      if(openDialog) {
        return component?.formGeneratorComponents[0].formGeneratorComponent.openConfirmCloseDialog();
      }
      else {
        return of(true);
      }
      
    }
    else {
      return of(true);
    }
  }
}