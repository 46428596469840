<h5 mat-dialog-title>{{ 'SelectEmployee' | translate: {Default: "Select Employee"} }}</h5>

<mat-dialog-content>
    <app-data-grid-comp
        class="select-employee-data-grid"
        [hideActionButtons]="true"
        [columns]="columns"
        [filterCategories]="filterCategories"
        [bindingType]="bindingType"
        [gridDataResult]="gridDataResult"
        [isLoading]="isLoading"
        [pageSize]="pageSize"
        [skip]="skip"
        [rowSelectionSettings]="rowSelectionSettings"
        [isLoading]="isLoading"
        searchable="true"
        [sortable]="{
            mode: 'multiple'
        }"
        [sortableColumns]="sortableColumns"
        [tableId]="db_tables.Talent"
        [filterToggleDetails]="filterToggleDetails"
        [clearSelectedItems]="clearSelectedItems"
        [selectedRowsButtonSettings]="selectedRowsButtonSettings"
        [nonSelectableRows]="nonSelectableEmployees"
        (emitToggleFilter)="updateFilterToggleDetails($event)"
        (emitPageChangeEvent)="pageChange($event)"
        (emitSearchEvent)="search($event)"
        (emitFilterEvent)="filterCallback($event)"
        (emitSortEvent)="sortChange($event)"
        (emitSelectedRows)="selectedRowsChanged($event)"
    ></app-data-grid-comp>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Cancel</button>

    <button class="add-all-selected-button" [disabled]="selectedItems.length === 0" mat-raised-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openConfirmAddUsersDialog()">
        <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> Add All Selected ({{selectedItems.length}})
    </button>
</mat-dialog-actions>