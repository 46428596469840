import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EmploymentRecordOtherCompensation, EmploymentRecordOtherCompensationSubmit } from '../models/employment-record-other-compensation.model';
import { EnvironmentService } from "@app/core/services/environment.service";


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});
@Injectable({
  providedIn: 'root'
})
export class EmploymentRecordOtherCompensationsService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getOtherCompensations(employeeId: string, employmentRecordId: string, skip?: number, take?: string): Observable<any> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http
      .get<any>(this.envService.env.apiUrl + `Employee/${employeeId}/EmploymentRecord/${employmentRecordId}/OtherCompensation`, {
        headers: headers,
        observe: 'response',
        params: params
      })
      .pipe(
        catchError(err => throwError(err)),
        map(res => {
          let data = {
            otherCompensations: res.body,
            pagination: JSON.parse(res.headers.get('X-Pagination'))
          }

          return data;
        })
      );
  }

  getOtherCompensation(employeeId: string, employmentRecordId: string, otherCompensationId: string): Observable<EmploymentRecordOtherCompensation> {
    return this.http.get<any>(this.envService.env.apiUrl + `Employee/${employeeId}/EmploymentRecord/${employmentRecordId}/OtherCompensation/${otherCompensationId}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  postOtherCompensation(employeeId: string, employmentRecordId: string, otherCompensation: EmploymentRecordOtherCompensationSubmit): Observable<EmploymentRecordOtherCompensation> {
    return this.http.post<any>(this.envService.env.apiUrl + `Employee/${employeeId}/EmploymentRecord/${employmentRecordId}/OtherCompensation`, otherCompensation, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  deleteOtherCompensation(employeeId: string, employmentRecordId: string, otherCompensationId: string): Observable<any> {
    return this.http.delete<any>(this.envService.env.apiUrl + `Employee/${employeeId}/EmploymentRecord/${employmentRecordId}/OtherCompensation/${otherCompensationId}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
}

