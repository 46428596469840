<app-layout>
    <mat-toolbar class="page-header" role="heading">
      <h1>{{ 'EmployeeBankDetailsReport' | translate: {Default: "Employee Bank Details"} }}</h1>
    </mat-toolbar>
  
    <div class="content">
        <app-bank-details-data-grid></app-bank-details-data-grid>

        <!-- <app-bank-name-chart></app-bank-name-chart> -->
    </div>
</app-layout>