import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';

@Component({
  selector: 'app-employment-record-payroll',
  templateUrl: './employment-record-payroll.component.html',
  styleUrls: ['./employment-record-payroll.component.scss']
})
export class EmploymentRecordPayrollComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.createForm();

  }

  createForm() {
    this.form = this.fb.group({
      payrollGroup: ['ID4'],
      payrollNumber: ['AI23556548'],
      grossAmount: ['4146.00'],
      bankTransitAccountNo: ['002-7689-009876'],
      payrollGroupCorporateSalary: ['1'],
      onCycle: ['yes'],
      payrollYear: ['2021'],
      payrollStatus: ['active'],
      deductions: ['1,567.00'],
      payPeriodEndDate: ['2021-10-29'],
    });
  }

  save() {
    this.overlayService.show();

    setTimeout(() => {
      this.overlayService.hide();
      this.snackbarService.openSnackBar('Payroll details saved successfully', 'clear', 'success');
    }, 2000);
  }

}
