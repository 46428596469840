<button class="user-button" mat-mini-fab [matMenuTriggerFor]="userMenu">
  <mat-icon class="user-button__icon material-icons-outlined">person</mat-icon>
</button>
<mat-menu #userMenu="matMenu" xPosition="before">
  <div class="user-menu-title">
    <div class="user-menu-title__name">
      <ng-container *ngIf="currentEmployeeDetails?.preferredName; else showFirstName">{{currentEmployeeDetails?.preferredName}}</ng-container>
      <ng-template #showFirstName>{{currentEmployeeDetails?.firstname}}</ng-template> {{currentEmployeeDetails?.lastname}}
    </div>
    <a class="user-menu-title__link">{{currentEmployeeDetails?.username}}</a>
  </div>
  <button 
    mat-menu-item 
    *ngIf="currentEmployeeDetails.userType === 'Employee'" 
    class="user-menu__item-title" 
    routerLink="{{routes.TALENT_TRACK}}{{routes.EDIT}}/{{user.userId}}{{routes.EMPLOYEE_DETAILS}}"
  >
    <mat-icon class="user-menu-icon material-icons-outlined">person</mat-icon>Profile
  </button>
  <div class="sign-button-wrapper">
    <button class="sign-button" mat-stroked-button setColor="primaryColour" color="primary" (click)="signOutEmit()">Sign out</button>
  </div>
</mat-menu>
