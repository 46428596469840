import { Component, OnInit } from '@angular/core';
import { Letter } from '../../models/leter.model';

@Component({
  selector: 'app-send-letter',
  templateUrl: './send-letter.component.html',
  styleUrls: ['./send-letter.component.scss']
})
export class SendLetterComponent implements OnInit {
  selectedIndex: number = 0;
  selectedLetter: Letter;
  selectedRecipients: any;
  letterVariables: any;

  constructor() { }

  ngOnInit(): void {
  }

  letterSelected(event: Letter){
    this.selectedLetter = event;
  }

  recipientsSelected(event: any){
    this.selectedRecipients = event;
  }

  variablesCompleted(event: any){
    this.letterVariables = event;
  }

}
