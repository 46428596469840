import { Component, Input, OnInit, SkipSelf } from '@angular/core';
import { fieldTypes } from '@app/consts/index';
import { ControlContainer, FormGroupDirective } from "@angular/forms";

@Component({
  selector: 'app-dynamic-form-field',
  templateUrl: './dynamic-form-field.component.html',
  styleUrls: ['./dynamic-form-field.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]]
    }
  ]
})
export class DynamicFormFieldComponent implements OnInit {
  public fieldTypes: typeof fieldTypes = fieldTypes;
  @Input() controlName: string;
  @Input() field;

  constructor() { }

  ngOnInit(): void {
    console.log(this.controlName)
  }

}
