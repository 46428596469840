import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { GoalTypeService } from '../../../goal-types/services/goal-type.service';
import { finalize } from 'rxjs';
import { GoalTypeRating } from '../../../goal-types/models/goal-type.model';
import { ReviewGoalPlan, ReviewGoalPlanGoalReview, ReviewSetting } from '../../../reviews/models/review.model';

@Component({
  selector: 'app-review-goal-review',
  templateUrl: './review-goal-review.component.html',
  styleUrls: ['./review-goal-review.component.scss']
})
export class ReviewGoalReviewComponent implements OnInit {
  @Input() review: ReviewGoalPlanGoalReview;
  @Input() settings: ReviewSetting;
  @Input() goalPlan: ReviewGoalPlan;
  @Input() goalTypeId: string;
  @Input() goalId: string;

  @Output() saveReview = new EventEmitter<{goalId: string, id: string, rating: string, comments: string}>();

  user$: any;
  reviewRatingForm: any;
  goalRatingOptions
  isLoadingRatings: boolean;
  goalTypeRatings: GoalTypeRating[];

  showForm: boolean = true;
  reviewerPermissions: { reviewer: string; rating: string; comments: string; };
  goalPermissions: any;
  goalPlanPermissions: { goalPlan: string; reviewerPermissions: { reviewer: string; rating: string; comments: string; }[]; goalPermissions: { globalGoal: boolean; goal: string; reviewerPermissions: { reviewer: string; rating: string; comments: string; }[]; }[]; };

  constructor(
    private fb: FormBuilder,
    private oidcAuthService: OidcAuthService,
    private goalTypeService: GoalTypeService
  ) {
    this.user$ = this.oidcAuthService.userProfile;
  }

  ngOnInit(): void {
    if(this.user$.userId === this.review.reviewedBy?.id){
      this.getGoalTypeRatings();
    }

    this.goalPlanPermissions = this.settings.goalPlanPermissions?.find( permission => permission?.goalPlan === this.goalPlan.id );
    this.goalPermissions = this.goalPlanPermissions.goalPermissions?.find( permission => permission?.goal === this.goalId );
    this.reviewerPermissions = this.goalPermissions?.reviewerPermissions?.find( permission => permission?.reviewer === this.review.reviewedBy?.id );
  }

  getGoalTypeRatings(){
    this.goalTypeService.getGoalTypeRatings(this.goalTypeId, '1000', 0, null)
    .pipe(
      finalize( () => {
        this.createReviewRatingForm();
      })
    )
    .subscribe(
      (res) => {
        this.goalTypeRatings = res.data;
      }
    );
  }

  createReviewRatingForm() {
    this.reviewRatingForm = this.fb.group({});

    if(this.reviewerPermissions.rating !== "None"){
      this.reviewRatingForm.addControl('rating', this.fb.control(this.review?.rating?.id, Validators.required));

      if(this.reviewerPermissions.rating === "View") {
        this.reviewRatingForm.get('rating').disable();
      }
    }

    if(this.reviewerPermissions.comments !== "None"){
      this.reviewRatingForm.addControl('comments', this.fb.control(this.review?.comments, Validators.required))

      if(this.reviewerPermissions.comments === "View") {
        this.reviewRatingForm.get('comments').disable();
      }
    }
  }

  deleteRating() {
    this.saveReview.emit(
      {
        goalId: this.goalId,
        id: this.review?.id,
        rating: null,
        comments: null
      }
    );
  }

  save(){
    this.saveReview.emit(
      {
        goalId: this.goalId,
        id: this.review?.id,
        rating: this.reviewRatingForm.get('rating') ? this.reviewRatingForm.get('rating').value : null,
        comments: this.reviewRatingForm.get('comments') ? this.reviewRatingForm.get('comments').value : null
      }
    );
  }
}
