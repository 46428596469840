import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { routes } from '@app/consts';
import { DirectReportee } from '../../models/direct-reportee.model';

@Component({
  selector: 'app-direct-reportee-card',
  templateUrl: './direct-reportee-card.component.html',
  styleUrls: ['./direct-reportee-card.component.scss']
})
export class DirectReporteeCardComponent implements OnInit {
  @Input() directReportee: DirectReportee;
  
  constructor(
    public router: Router
    // private toastr: ToastrService
  ) {}
  
  ngOnInit(): void {
  }

  openEditEmployee(id: string): void {
    this.router.navigateByUrl(`${routes.TALENT_TRACK}${routes.EDIT}/${id}${routes.EMPLOYEE_DETAILS}`);
  }

}
