<div class="header k-mb-4">
    <h5>{{ 'OrganizationTypes' | translate: {Default: "Organization Types"} }}</h5>
    <div>
        <button mat-flat-button setColor="primaryColour" color="primary" (click)="showAddOrgTypeModal()">
            <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> 
            {{ 'AddOrganizationType' | translate: {Default: "Add Organization Type"} }}
        </button>
    </div>
</div>

<mat-dialog-content>
    <kendo-grid
            class="grid"
            [data]="gridDataResult"
            [loading]="isLoading"
            [style.maxHeight.vh]="85" 
            [selectable]="{enabled: true}"
            kendoGridSelectBy="id"
            [(selectedKeys)]="selectedItems"
            [pageSize]="pageSize"
            [skip]="skip"
            [pageable]="{
                info: true,
                pageSizes: [5, 10, 20, 50, 100],
                previousNext: true
            }"
            (pageChange)="pageChange($event)"
            [sortable]="true">

        <ng-template kendoGridToolbarTemplate>
            <div *ngIf="selectedItems.length > 0">
                <button class="delete-all-selected-button" mat-stroked-button setColor="primaryColour" color="primary" (click)="deleteAllSelected()">
                    <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">delete</mat-icon>
                    Delete All Selected ({{selectedItems.length}})
                </button>
            </div>
        </ng-template>

        <kendo-grid-checkbox-column
                width="60"
                showSelectAll="true"
        ></kendo-grid-checkbox-column>

        <ng-container *ngFor="let col of columns">
            <!-- Normal Column Data -->
            <kendo-grid-column
                    *ngIf="!col.type"
                    [field]="col.field"
                    [title]="col.title"
                    >
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        <ng-container *ngIf="col.subField && dataItem[col.field]; else noSubField">
                            {{dataItem[col.field][col.subField]}}
                        </ng-container>
                        <ng-template #noSubField>
                            {{dataItem[col.field]}}
                        </ng-template>
                    </div>
                </ng-template>
            </kendo-grid-column>
        </ng-container>

        <kendo-grid-messages 
            [noRecords]="isLoading ? '' : translate.instant('Grid-NoRecordsAvailable')"
            [pagerItemsPerPage]="translate.instant('Grid-Pager-ItemsPerPage')" 
            [pagerItems]="translate.instant('Grid-Pager-Items')"
            [pagerOf]="translate.instant('Grid-Pager-Of')"
        ></kendo-grid-messages>

    </kendo-grid>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Cancel</button>

    <div *ngIf="selectedItems.length > 0">
<!--        <button class="add-all-selected-button" mat-raised-button setColor="primaryColour" color="primary" (click)="openConfirmAddUsersDialog()">-->
<!--            <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> Add All Selected ({{selectedItems.length}})-->
<!--        </button>-->
    </div>
</mat-dialog-actions>
