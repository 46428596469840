<h5 mat-dialog-title>{{ 'GroupPlan' | translate: {Default: "Group Plan"} }}</h5>

<mat-dialog-content>
    <ng-container *ngIf="form === undefined">
        <ngx-skeleton-loader
            count="5"
            [theme]="{ 
                'height.px': 50
            }"
        ></ngx-skeleton-loader>
    </ng-container>

    <form class="form" *ngIf="form" [formGroup]="form">

        <div class="form-section">
            <div class="form-section-title">
                <h6></h6>
            </div>
            <div class="form-section-contents">
                <div class="section-row">
                    <mat-form-field appearance="fill">
                        <mat-label>
                            {{ 'GroupPlanGroup' | translate: {Default: "Group"} }}
                        </mat-label>

                        <input 
                            matInput
                            type="text"
                            readonly
                            formControlName="group">
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>
                            {{ 'GroupPlanPlan' | translate: {Default: "Plan"} }}
                        </mat-label>
                
                        <mat-select 
                            [disabled]="planId !== null"
                            disableOptionCentering 
                            formControlName="plan">
                            <mat-option></mat-option>
                            <ng-container *ngFor="let option of planOptions">
                                <mat-option [value]="option.id">{{option.name}}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>
                            {{ 'GroupPlanWaitPeriodLengthUnit' | translate: {Default: "Wait Period Length Unit"} }}
                        </mat-label>
                
                        <mat-select disableOptionCentering formControlName="waitPeriodLengthUnit">
                            <ng-container *ngIf="waitPeriodLengthUnitOptions | async as waitPeriodLengthUnitOptions">
                                <mat-option></mat-option>
                                <ng-container *ngFor="let option of waitPeriodLengthUnitOptions">
                                    <mat-option [value]="option.id">{{option.name}}</mat-option>
                                </ng-container>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>
                            {{ 'GroupPlanWaitPeriodLength' | translate: {Default: "Wait Period Length"} }}
                        </mat-label>
                        
                        <input 
                            matInput
                            type="number"
                            formControlName="waitPeriodLength">
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>
                            {{ 'GroupPlanGroupPlanEligibility' | translate: {Default: "Group Plan Eligibility"} }}
                        </mat-label>
                
                        <mat-select disableOptionCentering formControlName="groupPlanEligibility">
                            <ng-container *ngIf="groupPlanEligibilityOptions | async as groupPlanEligibilityOptions">
                                <mat-option></mat-option>
                                <ng-container *ngFor="let option of groupPlanEligibilityOptions">
                                    <mat-option [value]="option.id">{{option.name}}</mat-option>
                                </ng-container>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>
                            {{ 'GroupPlanDeductionFrequency' | translate: {Default: "Deduction Frequency"} }}
                        </mat-label>
                
                        <mat-select disableOptionCentering formControlName="deductionFrequency">
                            <ng-container *ngIf="deductionFrequencyOptions | async as deductionFrequencyOptions">
                                <mat-option></mat-option>
                                <ng-container *ngFor="let option of deductionFrequencyOptions">
                                    <mat-option [value]="option.id">{{option.name}}</mat-option>
                                </ng-container>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>

</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button> 
</mat-dialog-actions>