<app-security-section>

    <div class="main-container security-setup">
        <div class="left">

            <!--    <div class="main-content">-->
            <div class="row">
                <div class="col-12">
                    <mat-card>

                        <mat-card-title class="header">
                            <h5 appLocalizationPopupDirective localizationCode="SecuritySetup">{{ 'SecuritySetup' | translate: {Default: "Security Setup"} }}</h5>
                            <div>
                                <!-- <mat-icon matTooltip="Help" aria-hidden="false" class="material-icons-outlined" aria-label="help icon" (click)="showHelp = !showHelp">help_outline</mat-icon> -->
                            </div>
                        </mat-card-title>

                        <mat-card-content>
                            <mat-form-field appearance="fill">
                                <mat-label>Table</mat-label>
                                <mat-select disableOptionCentering [(value)]="selectedTable" (valueChange)="handleSelectTableClick($event)" class="mat-form-field">
                                    <mat-option>-- None --</mat-option>

                                    <mat-optgroup *ngFor="let group of tableGroups" [label]="group.groupName">
                                        <mat-option *ngFor="let table of group.tables" [value]="table">
                                            {{table.name}}
                                        </mat-option>
                                    </mat-optgroup>

                                </mat-select>
                            </mat-form-field>


                            <mat-form-field appearance="fill" *ngIf="selectedTable != null">
                                <mat-label>Fields</mat-label>
                                <mat-select disableOptionCentering [(value)]="selectedField" (valueChange)="handleSelectFieldClick($event)">
                                    <mat-option [value]="'All fields'">
                                        All fields
                                    </mat-option>
                                    <mat-option *ngFor="let field of fieldList" [value]="field">
                                        {{field.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>


                            <div *ngIf="!isLoading; else showLoading">
                                <kendo-grid *ngIf="tableRolePermissions != null" [data]="tableRolePermissions">
                                    <kendo-grid-column field="role.name" title="Role" 
                                    [headerClass]="{ 'text-center': true }"
                                    [class]="{ 'text-center': true }"
                                    >
                                    </kendo-grid-column>

                                    <kendo-grid-column field="read" title="Read" 
                                    [width]="120"
                                    [headerClass]="{ 'text-center': true }"
                                    [class]="{ 'text-center': true }"
                                    >
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            <ng-container *ngIf="!isTheEditedTable(dataItem); else edited">
                                                <input kendoCheckBox size="large" type="checkbox" [(ngModel)]="dataItem.read.enabled" [attr.disabled]="true"
                                                />
                                            </ng-container>
                                            <ng-template #edited>
                                                <input kendoCheckBox size="large" type="checkbox" [(ngModel)]="updatedTablePermission.read.enabled"/>
                                            </ng-template>
                                        </ng-template>
                                    </kendo-grid-column>

                                    <kendo-grid-column field="create" title="Create" 
                                    [width]="120"
                                    [headerClass]="{ 'text-center': true }"
                                    [class]="{ 'text-center': true }"
                                    >
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            <ng-container *ngIf="!isTheEditedTable(dataItem); else edited">
                                                <input kendoCheckBox size="large" type="checkbox" [(ngModel)]="dataItem.create.enabled" [attr.disabled]="true"
                                                />
                                            </ng-container>
                                            <ng-template #edited>
                                                <input kendoCheckBox size="large" type="checkbox" [(ngModel)]="updatedTablePermission.create.enabled"/>
                                            </ng-template>
                                        </ng-template>
                                    </kendo-grid-column>

                                    <kendo-grid-column field="update" title="Update" 
                                    [width]="120"
                                    [headerClass]="{ 'text-center': true }"
                                    [class]="{ 'text-center': true }"
                                    >
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            <ng-container *ngIf="!isTheEditedTable(dataItem); else edited">
                                                <input kendoCheckBox size="large" type="checkbox" [(ngModel)]="dataItem.update.enabled" [attr.disabled]="true"
                                                />
                                            </ng-container>
                                            <ng-template #edited>
                                                <input kendoCheckBox size="large" type="checkbox" [(ngModel)]="updatedTablePermission.update.enabled"/>
                                            </ng-template>
                                        </ng-template>
                                    </kendo-grid-column>

                                    <kendo-grid-column field="delete" title="Delete" 
                                    [width]="120"
                                    [headerClass]="{ 'text-center': true }"
                                    [class]="{ 'text-center': true }"
                                    >
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            <ng-container *ngIf="!isTheEditedTable(dataItem); else edited">
                                                <input kendoCheckBox size="large" type="checkbox" [(ngModel)]="dataItem.delete.enabled" [attr.disabled]="true"
                                                />
                                            </ng-container>
                                            <ng-template #edited>
                                                <input kendoCheckBox size="large" type="checkbox" [(ngModel)]="updatedTablePermission.delete.enabled"/>
                                            </ng-template>
                                        </ng-template>
                                    </kendo-grid-column>

                                    <kendo-grid-column field="viewHistory" title="View History" 
                                    [width]="200"
                                    [headerClass]="{ 'text-center': true }"
                                    [class]="{ 'text-center': true }"
                                    >
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            <ng-container *ngIf="!isTheEditedTable(dataItem); else edited">
                                                <input kendoCheckBox size="large" type="checkbox" [(ngModel)]="dataItem.viewHistory.enabled" [attr.disabled]="true"
                                                />
                                            </ng-container>
                                            <ng-template #edited>
                                                <input kendoCheckBox size="large" type="checkbox" [(ngModel)]="updatedTablePermission.viewHistory.enabled"/>
                                            </ng-template>
                                        </ng-template>
                                    </kendo-grid-column>

                                    <kendo-grid-column field="viewFutureDated" title="View Future Dated Changes" 
                                    [width]="250"
                                    [headerClass]="{ 'text-center': true }"
                                    [class]="{ 'text-center': true }"
                                    >
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            <ng-container *ngIf="!isTheEditedTable(dataItem); else edited">
                                                <input kendoCheckBox size="large" type="checkbox" [(ngModel)]="dataItem.viewFutureDated.enabled" [attr.disabled]="true"
                                                />
                                            </ng-container>
                                            <ng-template #edited>
                                                <input kendoCheckBox size="large" type="checkbox" [(ngModel)]="updatedTablePermission.viewFutureDated.enabled"/>
                                            </ng-template>
                                        </ng-template>
                                    </kendo-grid-column>

                                    <kendo-grid-column>
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            <ng-container *ngIf="updatedTablePermission == null">
                                                <button mat-flat-button setColor="primaryColour" color="primary" (click)="editTablePermission(dataItem)" [disabled]="">Edit</button>
                                            </ng-container>
                                            <ng-container *ngIf="updatedTablePermission != null && isTheEditedTable(dataItem)">
                                                <button mat-stroked-button setColor="warnColour" color="warn" (click)="cancelTableChanges()" class="k-mr-2">Discard</button>
                                                <button mat-stroked-button (click)="selectDeselectAllForTable()" class="k-mr-2">{{ shouldSelectForTable() ? "Select All" : "Deselect All"}}</button>
                                                <button mat-flat-button setColor="primaryColour" color="primary" (click)="saveSecurityPermissionsForTable()" [disabled]="">Save</button>
                                            </ng-container>
                                        </ng-template>
                                    </kendo-grid-column>
                                </kendo-grid>


                                <kendo-grid *ngIf="fieldRolePermissions != null" [data]="fieldRolePermissions">
                                    <kendo-grid-column field="role.name" title="Role" [headerClass]="{ 'text-center': true }" [class]="{ 'text-center': true }">
                                    </kendo-grid-column>

                                    <kendo-grid-column field="read" title="Read" [width]="120" [headerClass]="{ 'text-center': true }" [class]="{ 'text-center': true }">
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            <ng-container *ngIf="!isTheEditedField(dataItem); else edited">
                                                <input kendoCheckBox size="large" type="checkbox" [checked]="dataItem.read.enabled" [attr.disabled]="true"
                                                   (change)="updateFieldPermission(eFieldPermissionType.READ, $event.target.checked)"/>
                                            </ng-container>
                                            <ng-template #edited>
                                                <input kendoCheckBox size="large" type="checkbox" [(ngModel)]="updatedFieldPermission.read.enabled"/>
                                            </ng-template>
                                        </ng-template>
                                    </kendo-grid-column>

                                    <kendo-grid-column field="update" title="Update" [width]="120" [headerClass]="{ 'text-center': true }" [class]="{ 'text-center': true }">
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            <ng-container *ngIf="!isTheEditedField(dataItem); else edited">
                                                <input kendoCheckBox size="large" type="checkbox" [checked]="dataItem.update.enabled" [attr.disabled]="true"
                                                       (change)="updateFieldPermission(eFieldPermissionType.UPDATE, $event.target.checked)"/>
                                            </ng-container>
                                            <ng-template #edited>
                                                <input kendoCheckBox size="large" type="checkbox" [(ngModel)]="updatedFieldPermission.update.enabled"/>
                                            </ng-template>
                                        </ng-template>
                                    </kendo-grid-column>

                                    <kendo-grid-column field="deny" title="Deny" [width]="120" [headerClass]="{ 'text-center': true }" [class]="{ 'text-center': true }">
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            <ng-container *ngIf="!isTheEditedField(dataItem); else edited">
                                                <input kendoCheckBox size="large" type="checkbox" [checked]="dataItem.deny.enabled" [attr.disabled]="true"
                                                       (change)="updateFieldPermission(eFieldPermissionType.DENY, $event.target.checked)"/>
                                            </ng-container>
                                            <ng-template #edited>
                                                <input kendoCheckBox size="large" type="checkbox" [(ngModel)]="updatedFieldPermission.deny.enabled"/>
                                            </ng-template>
                                        </ng-template>
                                    </kendo-grid-column>

                                    <kendo-grid-column>
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            <ng-container *ngIf="updatedFieldPermission == null">
                                                <button mat-flat-button setColor="primaryColour" color="primary" (click)="editFieldPermission(dataItem)" [disabled]="">Edit</button>
                                            </ng-container>
                                            <ng-container *ngIf="updatedFieldPermission != null && isTheEditedField(dataItem)">
                                                <button mat-stroked-button setColor="warnColour" color="warn" (click)="cancelFieldChanges()" class="mr-2">Discard</button>
                                                <button mat-stroked-button (click)="selectDeselectAllForField()" class="k-mr-2">{{ shouldSelectForField() ? "Select All" : "Deselect All"}}</button>
                                                <button mat-flat-button setColor="primaryColour" color="primary" (click)="saveSecurityPermissionsForField()" [disabled]="">Save</button>
                                            </ng-container>
                                        </ng-template>
                                    </kendo-grid-column>
                                </kendo-grid>
                            </div>

                            <ng-template #showLoading>
                                <ngx-skeleton-loader
                                        count="5"
                                        [theme]="{
                            'height.px': 50
                        }"
                                ></ngx-skeleton-loader>
                            </ng-template>

                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>


        <!-- <div class="right" *ngIf="showHelp">
            <div>
                <h5 appLocalizationPopupDirective localizationCode="SecuritySetupHelp">
                    {{ 'SecuritySetupHelp' | translate: {Default: "Security Setup Help"} }}
                </h5>

                <p><span class="k-font-weight-bold">Step 1.</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tempus, nulla at consequat ornare, magna tellus ultricies mauris, ac iaculis sem nulla ut risus.</p>
                <br>
                <p><span class="k-font-weight-bold">Step 2.</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tempus, nulla at consequat ornare, magna tellus ultricies mauris, ac iaculis sem nulla ut risus.</p>
                <br>
                <p><span class="k-font-weight-bold">READ.</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tempus, nulla at consequat ornare, magna tellus ultricies mauris, ac iaculis sem nulla ut risus.</p>
                <p><span class="k-font-weight-bold">CREATE.</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tempus, nulla at consequat ornare, magna tellus ultricies mauris, ac iaculis sem nulla ut risus.</p>
                <p><span class="k-font-weight-bold">UPDATE.</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tempus, nulla at consequat ornare, magna tellus ultricies mauris, ac iaculis sem nulla ut risus.</p>
                <p><span class="k-font-weight-bold">DELETE.</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tempus, nulla at consequat ornare, magna tellus ultricies mauris, ac iaculis sem nulla ut risus.</p>

            </div>
        </div> -->
    </div>
</app-security-section>
