import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {api_routes} from '@app/consts';
import {EmployeeTableFieldSecurity, EmployeeTableSecurity} from '@app/shared/models/employee.model';
import {Observable, of} from 'rxjs';
import {OidcAuthService} from '../oidc-auth.service';
import {catchError, map} from 'rxjs/operators';
import {EnvironmentService} from "@app/core/services/environment.service";

const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class TablePermissionsService {

  private tablePermissions: EmployeeTableSecurity[] = [];
  private targetEmployeeTablePermissions: { employeeId: string, tablePermissions: EmployeeTableSecurity[] };
  user$: any;

  constructor(
    private http: HttpClient,
    private oidcAuthService: OidcAuthService,
    private envService: EnvironmentService
  ) {}

  syncTablePermissions(callback: () => any) {
    this.user$ = this.oidcAuthService.userProfile;
      return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${this.user$.userId}/${api_routes.TABLE_SECURITY}`,
          {
              headers: headers
          })
          .subscribe(res => {
              this.tablePermissions = res;
              callback();
          });
  }

  getTablePermissions(): EmployeeTableSecurity[] {
    console.log(this.tablePermissions)
    return this.tablePermissions;
  }

  // returns the current employees permissions for table_Employees (This is used to show/hide create employee button)
  getEmployeeTablePermissions(): Observable<EmployeeTableSecurity> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${api_routes.SECURITY}/Table`,
        {
            headers: headers
        }
      )
  }

  getFetchedTargetEmployeeTablePermissions(employeeId: string): EmployeeTableSecurity[] {
    if(this.targetEmployeeTablePermissions?.employeeId === employeeId){
      return this.targetEmployeeTablePermissions.tablePermissions;
    }
    else {
      return null;
    }
  }

  getTargetEmployeeTablePermissions(employeeId: string): Observable<EmployeeTableSecurity[]> {
    if(this.targetEmployeeTablePermissions?.employeeId === employeeId){
      return of(this.targetEmployeeTablePermissions.tablePermissions);
    }
    else {
      return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.TABLE_SECURITY}`,
        {
            headers: headers
        }
      )
      .pipe(
        catchError(
          err => this.targetEmployeeTablePermissions = null
        ),
        map(
          res => {
            this.targetEmployeeTablePermissions = { employeeId: employeeId, tablePermissions: res };
            return res;
          }
        )
      )
    }

    // .subscribe(
    //   res => {
    //   },
    //   err => {
    //     this.targetEmployeeTablePermissions = null;
    //   }
    // );
  }

  public hasReadPermission(tableId: string, targetEmployeeTablePermissions?: EmployeeTableSecurity[]) {
    let tablePermission: EmployeeTableSecurity;

    targetEmployeeTablePermissions
    ? tablePermission = targetEmployeeTablePermissions.find( tablePermission => tablePermission.table?.id === tableId )
    : tablePermission = this.tablePermissions.find( tablePermission => tablePermission.table?.id === tableId )

    if(tablePermission === undefined){
      return true;
    }
    else {
      return tablePermission.read;
    }
  }

  public hasUpdatePermission(tableId: string, targetEmployeeTablePermissions?: EmployeeTableSecurity[]) {
    let tablePermission: EmployeeTableSecurity;

    targetEmployeeTablePermissions
    ? tablePermission = targetEmployeeTablePermissions.find( tablePermission => tablePermission.table?.id === tableId )
    : tablePermission = this.tablePermissions.find( tablePermission => tablePermission.table?.id === tableId )

    if(tablePermission === undefined){
      return true;
    }
    else {
      return tablePermission.update;
    }
  }

  public hasCreatePermission(tableId: string, targetEmployeeTablePermissions?: EmployeeTableSecurity[]) {
    let tablePermission: EmployeeTableSecurity;

    targetEmployeeTablePermissions
    ? tablePermission = targetEmployeeTablePermissions.find( tablePermission => tablePermission.table?.id === tableId )
    : tablePermission = this.tablePermissions.find( tablePermission => tablePermission.table?.id === tableId )

    if(tablePermission === undefined){
      return true;
    }
    else {
      return tablePermission.create;
    }
  }

  public hasDeletePermission(tableId: string, targetEmployeeTablePermissions?: EmployeeTableSecurity[]) {
    let tablePermission: EmployeeTableSecurity;

    targetEmployeeTablePermissions
    ? tablePermission = targetEmployeeTablePermissions.find( tablePermission => tablePermission.table?.id === tableId )
    : tablePermission = this.tablePermissions.find( tablePermission => tablePermission.table?.id === tableId )

    if(tablePermission === undefined){
      return true;
    }
    else {
      return tablePermission.delete;
    }
  }

  getTableFieldsPermissions(tableId: string, targetEmployeeTablePermissions?: EmployeeTableSecurity[]): EmployeeTableFieldSecurity[] {
    let tablePermission: EmployeeTableSecurity;

    targetEmployeeTablePermissions
    ? tablePermission = targetEmployeeTablePermissions.find( tablePermission => tablePermission.table?.id === tableId )
    : tablePermission = this.tablePermissions.find( tablePermission => tablePermission.table?.id === tableId )

    if(tablePermission) {
      return tablePermission.fields
    }

    return null;
  }

}
