import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-work-rotation-element-form',
    templateUrl: './work-rotation-element-form.component.html',
    styleUrls: ['./work-rotation-element-form.component.scss']
})
export class WorkRotationElementFormComponent implements OnInit {

    @Input() label: String;
    @Input() form: UntypedFormGroup;

    constructor(
    ) {
    }

    ngOnInit(): void {
    }

    clearForm() {
        this.form.reset();
    }


}
