<app-site-settings-menu>
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
          <h5 appLocalizationPopupDirective localizationCode="EditOrganization" mat-dialog-title>{{ 'EditOrganization' | translate: {Default: 'Edit Organization'} }}</h5>
      
          <div class="k-flex-grow"></div>
          <button mat-raised-button (click)="navigateBack()">Close</button>
          <button 
            mat-raised-button 
            setColor="primaryColour" 
            color="primary"          
            (click)="getFormData = !getFormData" 
            [disabled]="formValid === false"
          >Save</button>   
        </mat-card-title>

        <mat-card-content>
          <ng-container *ngIf="isLoading; else loaded">
            <ngx-skeleton-loader
              count="5"
              [theme]="{ 
                  'height.px': 50
              }"
            ></ngx-skeleton-loader>
          </ng-container>
          <ng-template #loaded>
            <app-form-generator 
              [formId]="formId"
              [formData]="formData"
              [emitFormDataTrigger]="getFormData"
              (emitFormData)="formDataEmitted($event)"
              (emitFormStatus)="formStatusUpdated($event)"
            ></app-form-generator>
          </ng-template>
        </mat-card-content>
        
      </mat-card>
</app-site-settings-menu>
