<toil-menu>
    <ng-container *ngIf="isLoading; else loaded">
        <ngx-skeleton-loader
            count="5"
            [theme]="{
                'height.px': 50
            }"
        ></ngx-skeleton-loader>
    </ng-container>
    <ng-template #loaded>
        <div class="content" *ngIf="!isLoading">
    
            <div class="main-content k-p-5">
                <div *ngIf="this.viewState == eTimeOffViewState.ADD_TYPE">
                    <add-toil [refreshListener]="this"></add-toil>
                </div>
    
                <div *ngIf="this.viewState == eTimeOffViewState.EDIT_TYPE">
                    <edit-toil  [timeOffTypeId]="toilTypeId" [refreshListener]="this"></edit-toil>
                </div>
    
                <div *ngIf="this.viewState == eTimeOffViewState.POLICY">
                    <toil-policy [timeOffType]="toilType" [timeOffPolicyId]="toilPolicyId" [timeOffTypeList]="toilTypeList" [refreshListener]="this"></toil-policy>
                </div>
    
    
                <div *ngIf="this.viewState == eTimeOffViewState.LIST_TYPES">
    
                    <div class="k-d-flex k-d-flex-row k-mb-3">
                        <h5>{{ 'TimeOffInLieu' | translate: {Default: "Time Off In Lieu"} }}</h5>
                        <div class="k-flex-grow"></div>
                        <div class="buttons-container">
                            <button mat-flat-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="goToTimeOffPolicy(null,null)" class="k-mr-4">
                                <mat-icon aria-hidden="false" class="material-icons-outlined white" aria-label="add icon">add</mat-icon>
                                {{ 'AddTOILPolicy' | translate: {Default: "Add TOIL Policy"} }}
                            </button>
    
                            <button mat-flat-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="goToAdd()">
                                <mat-icon aria-hidden="false" class="material-icons-outlined white" aria-label="add icon">add</mat-icon>
                                {{ 'AddTOILType' | translate: {Default: "Add TOIL Type"} }}
                            </button>
                        </div>
                    </div>
    
                    <div class="list-types-container">
                        <mat-form-field class="search-field" appearance="outline" >
                            <input matInput
                                   [(ngModel)]="timeOffTypeSearchValue"
                                   placeholder="Search...">
                            <mat-icon matPrefix class="material-icons-outlined">
                                search
                            </mat-icon>
                        </mat-form-field>
    
                        <div class="cards-container">
                            <mat-card *ngFor="let timeOffType of toilTypeList | toilTypeSearch:timeOffTypeSearchValue">
                                <mat-card-title class="header">
                                    <img *ngIf="timeOffType.image" [src]="domSanitizer.bypassSecurityTrustUrl(timeOffType.image)" class="timeoff-type-icon"/>
                                    <h5 
                                        class="title" 
                                        setColor 
                                        [setColorRules]="[
                                            {
                                                color: 'primaryColour',
                                                events: ['hover']
                                            }
                                        ]"
                                        appPreventDoubleClick 
                                        (throttledClick)="goToEdit(timeOffType.id)"
                                    >
                                        {{timeOffType.name}}
                                    </h5>
    
                                    <div class="k-flex-grow"></div>
    
                                    <div>
                                        <mat-icon 
                                            setColor
                                            [setColorRules]="[
                                                {
                                                    color: 'primaryColour',
                                                    events: ['hover']
                                                }
                                            ]"
                                            class="material-icons-outlined" 
                                            appPreventDoubleClick 
                                            (throttledClick)="goToEdit(timeOffType.id)" 
                                            matTooltip="Edit"
                                        >
                                            edit
                                        </mat-icon>
                                        <mat-icon 
                                            setColor
                                            [setColorRules]="[
                                                {
                                                    color: 'primaryColour',
                                                    events: ['hover']
                                                }
                                            ]"
                                            class="material-icons-outlined" 
                                            appPreventDoubleClick 
                                            (throttledClick)="showDeleteTypeConfirmation(timeOffType)" 
                                            matTooltip="Delete"
                                        >
                                            delete
                                        </mat-icon>
                                    </div>
                                </mat-card-title>
                                <mat-card-content>
                                    <app-toil-policies-data-grid
                                        [toilType]="timeOffType"
                                        (emitGoToToilPolicy)="goToTimeOffPolicy(timeOffType, $event)"
                                    ></app-toil-policies-data-grid>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</toil-menu>
