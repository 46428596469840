import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { db_tables } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TimeOffInLieuService } from '@app/core/services/time-off-in-lieu/time-off-in-lieu.service';
import { GenerateScheduleService } from '@app/modules/generate-schedules/services/generate-schedule.service';
import { TableField } from '@app/modules/security-setup/models/table-field.model';
import { SecuritySetupService } from '@app/modules/security-setup/services/security-setup.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { EmployeeToilPolicy, EmployeeToilRequest } from '@app/shared/models/time-off-in-lieu/time-off-in-lieu.model';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-request-toil-dialog',
  templateUrl: './request-toil-dialog.component.html',
  styleUrls: ['./request-toil-dialog.component.scss']
})
export class RequestToilDialogComponent implements OnInit {
  public db_tables = db_tables;
  employeeToilRequest: EmployeeToilRequest;
  employeeToilPolicy: EmployeeToilPolicy;
  employeeId: string;
  form: UntypedFormGroup;
  workingHours: number;
  loadingWorkingHours: boolean;
  loadingDbTableFields: boolean;
  dbTableFields: TableField[];
  formElements = [
    {
      formControl: 'startDate',
      text: 'Start Date',
      tableField: {
        id: 'tfi_ToilRecordStartDate'
      }
    },
    {
      formControl: 'endDate',
      text: 'End Date',
      tableField: {
        id: 'tfi_ToilRecordEndDate'
      }
    },
    {
      formControl: 'startTime',
      text: 'Start Time',
      tableField: {
        id: 'tfi_ToilRecordStartTime'
      }
    },
    {
      formControl: 'endTime',
      text: 'End Time',
      tableField: {
        id: 'tfi_ToilRecordEndTime'
      }
    },
    {
      formControl: 'hours',
      text: 'Hours',
      tableField: {
        id: 'tfi_ToilRecordHours'
      }
    },
    {
      formControl: 'halfDay',
      text: 'Half Day',
      tableField: {
        id: 'tfi_ToilRecordHalfDay'
      }
    },
    {
      formControl: 'comments',
      text: 'Comments',
      tableField: {
        id: 'tfi_ToilRecordComments'
      }
    },
  ]

  constructor(
    private generateScheduleService: GenerateScheduleService,
    private fb: FormBuilder,
    private timeOffInLieuService: TimeOffInLieuService,
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private dialogRef: MatDialogRef<RequestToilDialogComponent>,
    private securitySetupService: SecuritySetupService,
    @Inject(MAT_DIALOG_DATA) data
  ){
    this.employeeToilPolicy = data.employeeToilPolicy,
    this.employeeToilRequest = data.employeeToilRequest,
    this.employeeId = data.employeeId
  }

  ngOnInit(): void {
    this.buildForm();
    this.getDbTableFields();
  }

  buildForm() {

    this.form = this.fb.group({
      startDate: [this.employeeToilRequest ? this.employeeToilRequest.startDate : null, [Validators.required]],
      endDate: [this.employeeToilRequest ? this.employeeToilRequest.endDate : null, [Validators.required]],
      comments: [this.employeeToilRequest ? this.employeeToilRequest.comments : null],
      halfDay: [this.employeeToilRequest ? this.employeeToilRequest.halfDay : false, [Validators.required]],
      setTime: [this.employeeToilRequest ? this.employeeToilRequest.startTime || this.employeeToilRequest.endTime || this.employeeToilRequest.hours : false],
      startTime: [this.employeeToilRequest ? this.formatTime(this.employeeToilRequest.startTime) : null],
      endTime: [this.employeeToilRequest ? this.formatTime(this.employeeToilRequest.endTime) : null],
      hours: [this.employeeToilRequest ? this.employeeToilRequest.hours : null],
    });

    this.form.get('setTime').valueChanges.subscribe(value => {
        if (value) {
            this.form.get('startTime').setValidators([Validators.required]);
            this.form.get('endTime').setValidators([Validators.required]);
            this.form.get('hours').setValidators([Validators.required]);

            const halfDayControl = this.form.get('halfDay');
            if (halfDayControl.value) {
                halfDayControl.setValue(false);
            }
        } else {
            this.form.get('startTime').setValidators(null);
            this.form.get('endTime').setValidators(null);
            this.form.get('hours').setValidators(null);
        }

        this.form.get('startTime').updateValueAndValidity();
        this.form.get('endTime').updateValueAndValidity();
        this.form.get('hours').updateValueAndValidity();
    });

    this.form.get('halfDay').valueChanges.subscribe(value => {
        if (value) {
            const setTimeControl = this.form.get('setTime');
            if (setTimeControl.value) {
                setTimeControl.setValue(false);
            }
        }
    });

    this.formControlValueChanged('startDate');
    this.formControlValueChanged('endDate');
    this.getEmployeeWorkingHours();

    this.form.controls.endDate.valueChanges.subscribe(endDate => {
        const startDate = this.form.controls.startDate.value;
        // if (startDate && to && startDate.toISOString().slice(0,10) === to.toISOString().slice(0,10)) {
        if (startDate && endDate && this.startDateValue === this.endDateValue) {
            this.showTimeSelection();
        } else {
            this.hideTimeSelection();
        }
    })
  }

  getDbTableFields() {
    this.loadingDbTableFields = true;

    this.securitySetupService.getFields(this.db_tables.TOIL, 0, '100')
    .pipe(
      finalize(()=>{
        this.loadingDbTableFields = false;
      })
    )
    .subscribe(
      res => {
        this.dbTableFields = res.data;
        this.addDBTableValues();
      }
    );
  }

  addDBTableValues() {
    this.dbTableFields.forEach(
      dbTableField => {
        let formElIndex = this.formElements.findIndex( el => el.tableField?.id === dbTableField?.id)

        if(formElIndex !== -1){
          this.formElements[formElIndex] = this.useDbTableFieldValues(this.formElements[formElIndex], dbTableField)
        }
      }
    )

  }

  useDbTableFieldValues(formElement: any, dbTableField: TableField) {
    // set field name
    // let currentCultureFieldName: Localization[] = dbTableField.name.filter( n => n.culture === this.currentCulture.id );
    // formElement.text = currentCultureFieldName[0].text;
    formElement.text = dbTableField.name;

    // set field hidden value
    formElement.hidden = dbTableField.enabled ? 'false' : 'true';

    // set required value
    formElement.requiredField = dbTableField.requiredField ? 'true' : 'false';

    // set min length value
    // formElement.minLength = dbTableField.minimumValue;

    // set max length value
    // formElement.maxLength = dbTableField.maximumValue;

    // set the placeholder
    formElement.placeholder = dbTableField.defaultValue;

    return formElement
  }

  private showTimeSelection() {

  }

  private hideTimeSelection() {

  }

  formControlValueChanged(formControl: string) {
    this.form.get(formControl).valueChanges
    .subscribe(
        (mode: string) => {
            this.getEmployeeWorkingHours();
            this.form.get('halfDay').setValue(false);
        }
    );
  }

  get startDateValue() {
      const date = this.formatDate(this.form.get('startDate').value);
      // console.log("fromValue", date);
      if (date === 'Invalid date') {
          return null;
      }
      return date;
  }

  get endDateValue() {
      // return this.formatDate(this.form.get('endDate').value);
      const date = this.formatDate(this.form.get('endDate').value);
      // console.log("toValue", date, this.form.get('setTime').value);
      if (date === 'Invalid date') {
          return null;
      }
      return date;
  }

  get calculatedWorkingHours() {
      if (this.form.get('halfDay').value === true) {
          return this.workingHours / 2;
      } else {
          return this.workingHours;
      }
  }

  formatDate(date: any): string {
      if (typeof date === 'string') {
          return moment(date).format('YYYY-MM-DD')
      } else {
          if (date !== null) {
              return date.format('YYYY-MM-DD')
          }

          return null;
      }
  }

  getEmployeeWorkingHours() {
      if (this.isValidDates()) {
          this.loadingWorkingHours = true;

          this.generateScheduleService.getEmployeeWorkingHours(this.employeeId, this.startDateValue, this.endDateValue)
          .pipe(
              finalize(() => this.loadingWorkingHours = false)
          )
          .subscribe(
              (res) => {
                  this.workingHours = res.workingHours;
              }
          );
      }
  }

  isValidDates(): boolean {
      if (this.startDateValue != null && this.endDateValue !== '' && this.endDateValue !== 'Invalid date') {
          const startDateValue = this.form.get('startDate').value;
          const endDateValue = this.form.get('endDate').value;

          if (moment(startDateValue).isSameOrBefore(moment(endDateValue))) {

              // if (this.form.get('from').value.isSameOrBefore(this.form.get('to').value)) {
              return true;
          }
      }

      return false;
  }

  formatTime(time) {
    try {
        const [hours, minutes] = time.split(':');
        return `${hours}:${minutes}`;
    } catch (error) {
        return '';
    }
  }

  close() {
    this.dialogRef.close();
  }

  submitForm() {
    if (this.employeeToilRequest) {
        this.editToilRequest();
    } else {
      this.requestToil();
    }
  }

  requestToil() {
    this.overlayService.show();

    let data = {
      startDate: this.form.get('startDate').value,
      endDate: this.form.get('endDate').value,
      comments: this.form.get('comments').value,
      halfDay: this.form.get('halfDay').value,
      startTime:  this.form.get('startTime').value,
      endTime: this.form.get('endTime').value,
      hours: this.form.get('hours').value ?? this.calculatedWorkingHours,
      toilPolicyId: this.employeeToilPolicy.toilPolicy.id,
      toilClassTypeId: 'RequestToil',
    }

    this.timeOffInLieuService.submitRequest(data)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
            this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
            this.dialogRef.close(true);
        }
    );
  }

  editToilRequest() {
    this.overlayService.show();

    let data = {
      startDate: this.form.get('startDate').value,
      endDate: this.form.get('endDate').value,
      comments: this.form.get('comments').value,
      halfDay: this.form.get('halfDay').value,
      startTime:  this.form.get('startTime').value,
      endTime: this.form.get('endTime').value,
      hours: this.form.get('hours').value,
      toilPolicyId: this.employeeToilRequest.toilPolicy.id,
      toilClassTypeId: this.employeeToilRequest.toilClassType.id,
    }

    this.timeOffInLieuService.editRequest(this.employeeToilRequest.id, data)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
            this.snackbarService.openSnackBar(`${this.translate.instant('EdittedSuccessfully')}`, 'clear', 'success');
            this.dialogRef.close(true);
        }
    );
  }
}

