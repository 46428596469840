import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { api_routes } from '@app/consts';
import { db_tables } from '@app/consts/db-tables';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import * as moment from 'moment';
import { defer, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EmployeeAbsence, EmployeeAbsenceVerbose } from './models/employee-absence.model';
import { EmployeeAbsenceService } from './services/employee-absence.service';

@Component({
  selector: 'app-employee-absences',
  templateUrl: './employee-absences.component.html',
  styleUrls: ['./employee-absences.component.scss']
})
export class EmployeeAbsencesComponent implements OnInit {
  @Input() employeeID: string;

  public columns: any[] = [
    { field: "timeOffType", subField:"name", title: "Time Off Type", type: "navigation", tableId: "tfi_TimeOffType"},
    { field: "startDate", title: "Start Date", type: "date", tableId: "tfi_AbsenceStartDate"},
    { field: "endDate", title: "End Date", type: "date", tableId: "tfi_AbsenceEndDate"},
    { field: "startTime", title: "Start Time", tableId: "tfi_AbsenceStartTime"},
    { field: "endTime", title: "End Time", tableId: "tfi_AbsenceEndTime"},
    { field: "hours", title: "Hours", tableId: "tfi_AbsenceHours"},
    { field: "udl1", subField:"text", title: "Time Off Reason", tableId: "tfi_AbsenceUdl1"},
    { field: "returnToWork", title: "Return To Work", tableId: "tfi_ReturnToWork"},
    { field: "comments", title: "Comments", tableId: "tfi_AbsenceComments"},
  ];
  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 5;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  formId: string = 'frm_WXUz5jVeeptJHl'
  changeReasonFormId: string = 'frm_4shlPLavT0CtkO';
  dialogRef: any;
  formValid: any;
  public sortString: string = 'startDate-desc';
  isLoadingPermissions: boolean = true;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];
  accessAndHistoryURL: string = `${api_routes.TALENT}/[employeeId]/${api_routes.ABSENCES}/[entityId]`;

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private employeeAbsenceService: EmployeeAbsenceService,
    private snackbarService: SnackbarService,
    private tablePermissionsService: TablePermissionsService,
    private overlayService: OverlayService
  ) { }

  ngOnInit(): void {
    this.view = this.getEmployeeAbsences();
    this.getTargetEmployeeTablePermissions();
  }

  getEmployeeAbsences() {
    this.isLoading = true;

    this.employeeAbsenceService.getEmployeeAbsences(this.employeeID, String(this.pageSize), this.skip, this.sortString)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          }
          this.gridData = res.data;
        }
      );
  }

  getTargetEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeID);

    // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
    if(currentFetchedData !== null){
      this.targetEmployeeTablePermissions = currentFetchedData;
      this.isLoadingPermissions = false;
    }
    else {
      this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeID)
      .pipe(
        finalize(() => {
            this.isLoadingPermissions = false;
        })
      )
      .subscribe(
        res => {
          this.targetEmployeeTablePermissions = res;
        }
      );
    }
  }

  getEmployeeAbsence(employeeAbsence?: EmployeeAbsenceVerbose) {
    if(employeeAbsence === undefined){
      this.openFormDialog();
    }
    else {
      this.isLoading = true;

      this.employeeAbsenceService.getEmployeeAbsence(this.employeeID, employeeAbsence.id)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        res => {
          this.openFormDialog(res);
        }
      );
    }
  }

  openFormDialog(employeeAbsence?: EmployeeAbsence) {
      console.log("timeoff absenceform", employeeAbsence);
    let formData = {
      id: employeeAbsence ? employeeAbsence.id : null,
      asOf: moment().format(),
      changeReason: '',
      changeReasonComments: '',
      startDate: employeeAbsence ? employeeAbsence.startDate : null,
      endDate: employeeAbsence ? employeeAbsence.endDate : null,
      startTime: employeeAbsence ? employeeAbsence.startTime : null,
      endTime: employeeAbsence ? employeeAbsence.endTime : null,
      hours: employeeAbsence ? employeeAbsence.hours : null,
      comments: employeeAbsence ? employeeAbsence.comments : null,
      returnToWork: employeeAbsence ? employeeAbsence.returnToWork : null,
      timeOffType: employeeAbsence ? employeeAbsence.timeOffType?.id : null,
      udl1: employeeAbsence ? employeeAbsence.udl1?.id : null,
    }

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formTitle: `${this.translate.instant('EmployeeAbsence')}`,
      formId: this.formId,
      changeReasonFormId: this.changeReasonFormId,
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions,
      formData: formData
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      this.save(event)
    });
  }

  formDataEmitted(formData) {
    this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  save(formData: any){
    this.overlayService.show();

    if(formData.id === null){
      this.employeeAbsenceService.createNewEmployeeAbsence(this.employeeID, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getEmployeeAbsences();
        }
      );
    }
    else {
      this.employeeAbsenceService.updateEmployeeAbsence(this.employeeID, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getEmployeeAbsences();
        }
      );
    }
  }

  deleteEmployeeAbsences(employeeAbsenceIds: string[]) {
    const observables = employeeAbsenceIds.map(selectedItem => defer(() => this.employeeAbsenceService.deleteEmployeeAbsence(this.employeeID, selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.view = this.getEmployeeAbsences();
        this.clearSelectedItems = !this.clearSelectedItems;
        this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.view = this.getEmployeeAbsences();
  }

}
