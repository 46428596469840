import { Component, Inject, OnInit } from '@angular/core';
import { finalize } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReviewPlanPublishHistory } from '../../models/review-plan.model';
import { ReviewPlansService } from '../../services/review-plans.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { api_routes, db_tables } from '@app/consts';

@Component({
  selector: 'app-review-plan-publish-history-dialog',
  templateUrl: './review-plan-publish-history-dialog.component.html',
  styleUrls: ['./review-plan-publish-history-dialog.component.scss']
})
export class ReviewPlanPublishHistoryDialogComponent implements OnInit {
  reviewPlanId: string;

  columns = [
    { field: "reviewPlan", subField: "name", title: "Name", tableId: "tfi_RpName" },
    { field: "publishedBy", title: "Published By", tableId: "", type: "employee" },
    { field: "publishedOn", title: "Published On", tableId: "", type: "dateTime" },
    { field: "startedAt", title: "Started At", tableId: "", type: "dateTime" },
    { field: "completedAt", title: "Completed At", tableId: "", type: "dateTime" },
  ]

  filterCategories: any[] = [
    { field: "name", title: "Name", tableId: "tfi_RpName", type: "String", dataType: 'String' },
    // { field: "publishedBy", title: "Published By", tableId: "", type: "employee" },
    { field: "publishedOn", title: "Published On", tableId: "", type: "date", dataType: 'Date' },
    { field: "startedAt", title: "Started At", tableId: "", type: "date", dataType: 'Date' },
    { field: "completedAt", title: "Completed At", tableId: "", type: "date", dataType: 'Date' },
  ];

  sortableColumns: any[] = [
    { field: "reviewPlan", sortValue: "ReviewPlan.Name"},
    { field: "publishedBy", sortValue: ["PublishedBy.FirstName", "PublishedBy.LastName"]},
    { field: "publishedOn", sortValue: "publishedOn"},
    { field: "startedAt", sortValue: "startedAt"},
    { field: "completedAt", sortValue: "completedAt"},
  ];

  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 10;
  skip: number = 0;
  sortString: string = "publishedOn-desc";
  filterString: string;

  constructor(
    private reviewPlansService: ReviewPlansService,
    public translate: TranslateService,
    private dialogRef: MatDialogRef<ReviewPlanPublishHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ){
    this.reviewPlanId = data.reviewPlanId;
  }

  ngOnInit(): void {
    this.getGoalPlanPublishHistory();
  }

  getGoalPlanPublishHistory() {
    this.isLoading = true;

    this.reviewPlansService.getReviewPlanPublishHistory(this.reviewPlanId, String(this.pageSize), this.skip, this.sortString, this.filterString)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          }
        }
      );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.view = this.getGoalPlanPublishHistory();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getGoalPlanPublishHistory();
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;
    this.getGoalPlanPublishHistory();
  }

  close(){
    this.dialogRef.close()
  }

}
