import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';

@Component({
  selector: 'app-employee-status-dialog',
  templateUrl: './employee-status-dialog.component.html',
  styleUrls: ['./employee-status-dialog.component.scss']
})
export class EmployeeStatusDialogComponent implements OnInit {
  selectedStatus: {
    name: string,
    time: string,
    icon: string
  };

  status: any[] = [
    {
      name: 'In a meeting',
      time: '1 Hour',
      icon: '/assets/icons/notes-icon.png'
    },
    {
      name: 'Commuting',
      time: '30 Minutes',
      icon: '/assets/icons/bus-icon.png'
    },
    {
      name: 'Out Sick',
      time: 'Today',
      icon: '/assets/icons/sick-icon.png'
    },
    {
      name: 'Vacationing',
      time: 'Dont Clear',
      icon: '/assets/icons/vacation-icon.png'
    },
    {
      name: 'Working Remotely',
      time: 'Today',
      icon: '/assets/icons/home-icon.png'
    },
  ];

  times: string[] = [
    '30 Minutes',
    '1 Hour',
    'Today',
    'Don\'t Clear',
  ];
  form: UntypedFormGroup;

  constructor(
    private dialogRef: MatDialogRef<EmployeeStatusDialogComponent>,
    private fb: UntypedFormBuilder,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      name: [this.selectedStatus ? this.selectedStatus.name : null],
      time: [this.selectedStatus ? this.selectedStatus.time : null],
    });
  }

  close() {
    this.dialogRef.close(false);
  }

  save() {
    this.snackbarService.openSnackBar('Status updated', 'clear', 'success');
    this.dialogRef.close(this.selectedStatus);
  }
}
