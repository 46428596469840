<h5 mat-dialog-title>{{ 'SelectAStyle' | translate: {Default: "Select A Style"} }}</h5>

<mat-dialog-content>
    <div *ngIf="selectedStylingColour" class="selected-style page-description-section">
        <h6>{{ 'SelectedStyle' | translate: {Default: "Selected style"} }}: {{selectedStylingColour.name}}</h6>
    </div>

    <app-data-grid-comp
        [columns]="columns"
        [gridDataResult]="gridDataResult"
        [isLoading]="isLoading"
        [pageSize]="pageSize"
        [skip]="skip"
        [rowSelectionSettings]="{
            canSelectRows: true,
            selectableMode: 'single',
            selectRowBy: 'id'
        }"
        clickableRows="false"
        hideActionButtons="true"
        [clearSelectedItems]="clearSelectedItems"
        [selectedRowsButtonSettings]="selectedRowsButtonSettings"
        (emitPageChangeEvent)="pageChange($event)"
        (emitSelectedRows)="selectedRowsChanged($event)"
    ></app-data-grid-comp>

</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!selectedStylingColour">{{ 'Save' | translate: {Default: "Save"} }}</button>
</mat-dialog-actions>

