import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-positioned-for-succession',
  templateUrl: './positioned-for-succession.component.html',
  styleUrls: ['./positioned-for-succession.component.scss']
})
export class PositionedForSuccessionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
