import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { GridDataResult, PageChangeEvent, } from '@progress/kendo-angular-grid';
import { defer, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { Filterable } from '@app/shared/components/filter/filterable';
import { routes } from '@app/consts';
import { StatutoryHolidaysService } from '@app/modules/statutory-holidays/services/statutory-holidays.service';

@Component({
    selector: 'app-holiday-groups-data-grid',
    templateUrl: './holiday-groups-data-grid.component.html',
    styleUrls: ['./holiday-groups-data-grid.component.scss']
})
export class HolidayGroupsDataGridComponent implements OnInit, Filterable {

    @Input() refreshView: boolean;
    public routes: typeof routes = routes;

    public columns: any[] = [[{field: 'name', title: 'Name'}, {field: 'country', title: 'Country'}]];
    public bindingType: String = 'array';
    public gridDataResult: GridDataResult;
    public selectedPositions: any[] = [];
    isLoading: boolean;
    pageSize: number = 20;
    skip: number = 0;

    public filterCategories: any[] = this.columns;
    filterString: string;
    getRequest: any;
    public searchValue: string;

    constructor(
        private dialog: MatDialog,
        private overlayService: OverlayService,
        private snackbarService: SnackbarService,
        private holidayService: StatutoryHolidaysService
    ) {
    }

    ngOnInit(): void {
        this.getHolidayGroups();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.refreshView && (changes.refreshView.currentValue !== changes.refreshView.previousValue)) {
            this.getHolidayGroups();
        }
    }

    getHolidayGroups(): void {
        this.isLoading = true;

        this.getRequest = this.holidayService.getHolidayGroups(this.skip, String(this.pageSize), this.filterString)
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                })
            )
            .subscribe(
                res => {
                    this.gridDataResult = {
                        data: res.data,
                        total: res.total,
                    };
                }
            );
    }

    public pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.pageSize = event.take;
        this.getHolidayGroups();
    }

    deleteAllSelected() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            text: `Are you sure you want to delete ${this.selectedPositions.length} selected Holiday Group(s)?`
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data === true) {
                    const observables = this.selectedPositions.map(selectedPosition => defer(() => this.holidayService.deleteHolidayGroup(selectedPosition)));
                    this.overlayService.show();
                    forkJoin(observables)
                        .pipe(
                            finalize(() => this.overlayService.hide())
                        )
                        .subscribe(
                            (res) => {
                                this.selectedPositions = [];
                                this.getHolidayGroups();
                                this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
                            },
                            err => {
                                this.snackbarService.openSnackBar(err, 'clear', 'warn');
                            }
                        );
                }
            }
        );
    }

    filterCallback(filterString: string) {
        this.filterString = filterString;
        this.skip = 0;;
        this.getHolidayGroups();
    }


    search() {
        this.filterString = `(Name like "${this.searchValue}")`;
        this.getRequest.unsubscribe();
        this.getHolidayGroups();
    }

}
