<app-layout>
    <mat-toolbar class="page-header" role="heading">
      <h1>Headcount By Month</h1>
    </mat-toolbar>
  
    <div class="content">
        <app-terminations-by-month></app-terminations-by-month>

        <div>
            <app-hires-and-promotions-by-month></app-hires-and-promotions-by-month>
            <app-tenure-by-month></app-tenure-by-month>
        </div>

        <app-headcount-by-month-chart-table></app-headcount-by-month-chart-table>
    </div>

</app-layout>