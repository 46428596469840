import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'systemVariableSearch' })
export class SystemVariableFilterPipe implements PipeTransform {
  public transform(tables: any[], searchText: any): any {
    if (searchText == null || tables == null) {
      return tables;
    }
    return tables.filter(table => table.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
  }
}