import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { ReviewPlansService } from '../../../review-plans/services/review-plans.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-overall-rating-comment',
  templateUrl: './overall-rating-comment.component.html',
  styleUrls: ['./overall-rating-comment.component.scss']
})
export class OverallRatingCommentComponent implements OnInit {
  @Input() reviewPlanId: string;

  @Input() review: {
    reviewedBy: {
      id: string
      firstName: string
      lastName: string
    },
    comments: string
    rating: {
      id: string
      name: string
      description: string
    }
  }

  @Input() reviewerPermissions: {
    comments: string
    rating: string
    review: string
  }

  @Output() saveReview = new EventEmitter<
    {
      reviewedBy: {
        id: string
        firstName: string
        lastName: string
      }, 
      rating: string, 
      comments: string
    }
  >();

  user$: any;
  isLoadingRatings: boolean;
  reviewRatingOptions: any[];
  reviewRatingForm: any;
  showForm: boolean = true;

  constructor(
    private fb: FormBuilder,
    private oidcAuthService: OidcAuthService,
    private reviewPlansService: ReviewPlansService
  ) {
    this.user$ = this.oidcAuthService.userProfile;
  }

  ngOnInit(): void {
    if(this.user$.userId === this.review.reviewedBy?.id){
      this.getReviewPlanRatings();
    }
  }

  getReviewPlanRatings() {
    this.isLoadingRatings = true;

    this.reviewPlansService.getReviewPlanRatings(this.reviewPlanId, '100', 0, 'SortOrder-asc')
      .pipe(
        finalize( () => {
          this.isLoadingRatings = false;
          this.createReviewRatingForm();
        })
      )
      .subscribe(
        (res) => {
          this.reviewRatingOptions = res.data;
        }
      );
  }

  createReviewRatingForm() {
    this.reviewRatingForm = this.fb.group({});

    if(this.reviewerPermissions.rating !== "None"){
      this.reviewRatingForm.addControl('rating', this.fb.control(this.review?.rating?.id, Validators.required));

      if(this.reviewerPermissions.rating === "View") {
        this.reviewRatingForm.get('rating').disable();
      }
    }

    if(this.reviewerPermissions.comments !== "None"){
      this.reviewRatingForm.addControl('comments', this.fb.control(this.review?.comments, Validators.required))

      if(this.reviewerPermissions.comments === "View") {
        this.reviewRatingForm.get('comments').disable();
      }
    }
  }

  deleteRating() {
    this.saveReview.emit(
      {
        reviewedBy: this.review.reviewedBy,
        rating: null,
        comments: null
      }
    );
  }

  save(){
    this.saveReview.emit(
      {
        reviewedBy: this.review.reviewedBy,
        rating: this.reviewRatingForm.get('rating').value,
        comments: this.reviewRatingForm.get('comments').value
      }
    );
  }

}
