<app-admin-controls>
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
            <h5 appLocalizationPopupDirective localizationCode="AdminControlsWorklocations">{{ 'AdminControlsWorklocations' | translate: {Default: "Admin Controls: Cost Centers"} }}</h5>
        </mat-card-title>

        <mat-card-content>
            <div class="page-description-section">
                This will create {{numberOfItems}} random Cost Center(s)
            </div>

            <mat-form-field appearance="fill">
                <mat-label>
                    Number Of Cost Centers
                </mat-label>
        
                <input matInput type="number" [(ngModel)]="numberOfItems">
            </mat-form-field>

            <button mat-flat-button setColor="primaryColour" color="primary" (click)="createItems()">Create</button>
        </mat-card-content>
    </mat-card>
</app-admin-controls>