<div appLocalizationPopupDirective [localizationCode]="'SearchBarPlaceholder'" class="search">
    <div class="button-container">
        <mat-icon class="material-icons-outlined search-icon" *ngIf="searchValue" aria-label="Clear" (click)="searchValue=''">close</mat-icon>
        <mat-icon class="material-icons-outlined search-icon">search</mat-icon>
    </div>
    <input class="search-input" [placeholder]="translate.instant('SearchBarPlaceholder')" [(ngModel)]="searchValue" (input)="search()">
</div>
<div class="search-results" *ngIf="searchValue">
    <ng-template #loading>
        <div
            class="result">
            <div class="thumbnail">
                <ngx-skeleton-loader
                    count="1"
                    appearance="circle"
                    [theme]="{
                    'height.px': 50,
                    'width.px': 50
                    }">
                </ngx-skeleton-loader>
            </div>
            <div class="text">
                <div class="loader">
                    <ngx-skeleton-loader
                        count="1"
                        [theme]="{
                        'height.px': 20,
                        'width.px': 200
                        }"
                    ></ngx-skeleton-loader>

                    <ngx-skeleton-loader
                        count="1"
                        [theme]="{
                        'height.px': 15,
                        'width.px': 175
                        }"
                    ></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </ng-template>

    <div *ngIf="!isLoading && results; else loading">
        <ng-container>
            <ng-container *ngIf="results.employees != null && results.employees.length > 0">
                <div class="results-section-title" appLocalizationPopupDirective [localizationCode]="'Employees'">
                    {{ 'Employees' | translate: {Default: "Employees"} }}
                </div>
                <div
                    class="result employee"
                    *ngFor="let employee of results.employees"
                    routerLink="{{routes.TALENT_TRACK}}{{routes.EDIT}}/{{employee.id}}{{routes.EMPLOYEE_DETAILS}}">
                    <div class="thumbnail k-mr-4">
                        <app-employee-image [employeeId]="employee.id" [width]="50" [height]="50" [round]="true"></app-employee-image>
                    </div>
                    <div class="text">
                        <div class="name">{{employee.firstName}} {{employee.lastName}}</div>
                    </div>
                </div>
                <div class="load-more">
                    <ng-container *ngIf="loadingMore === false; else showLoadingMore">
                        <button mat-stroked-button setColor="primaryColour" color="primary" (click)="loadMore()" appLocalizationPopupDirective [localizationCode]="'LoadMore'">
                            {{ 'LoadMore' | translate: {Default: "Load More"} }}
                        </button>
                    </ng-container>

                    <ng-template #showLoadingMore>
                        <div
                            class="result">
                            <div class="thumbnail">
                                <ngx-skeleton-loader
                                    count="1"
                                    appearance="circle"
                                    [theme]="{
                                    'height.px': 50,
                                    'width.px': 50
                                    }">
                                </ngx-skeleton-loader>
                            </div>
                            <div class="text">
                                <div class="loader">
                                    <ngx-skeleton-loader
                                        count="1"
                                        [theme]="{
                                        'height.px': 20,
                                        'width.px': 200
                                        }"
                                    ></ngx-skeleton-loader>

                                    <ngx-skeleton-loader
                                        count="1"
                                        [theme]="{
                                        'height.px': 15,
                                        'width.px': 175
                                        }"
                                    ></ngx-skeleton-loader>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </ng-container>
        </ng-container>

        <ng-container>
            <ng-container *ngIf="results.positions != null && results.positions.length > 0">
                <div class="results-container">
                    <div class="results-section-title" appLocalizationPopupDirective [localizationCode]="'Positions'">
                        {{ 'Positions' | translate: {Default: "Positions"} }}
                    </div>
                    <div
                        class="result form"
                        *ngFor="let position of results.positions"
                        routerLink="{{routes.SITE_SETTINGS}}{{routes.POSITIONS}}{{routes.EDITOR}}/{{position.id}}"
                    >
                        <div class="thumbnail k-mr-4">
                            <mat-icon class="material-icons-outlined">account_box</mat-icon>
                        </div>
                        <div class="text">
                            <h5 class="name">{{position.name}}</h5>
                        </div>
                    </div>
                    <div class="load-more">
                        <ng-container *ngIf="loadingMore === false; else showLoadingMore">
                            <button mat-stroked-button setColor="primaryColour" color="primary" (click)="loadMore()" appLocalizationPopupDirective [localizationCode]="'LoadMore'">
                                {{ 'LoadMore' | translate: {Default: "Load More"} }}
                            </button>
                        </ng-container>

                        <ng-template #showLoadingMore>
                            <div
                                class="result">
                                <div class="thumbnail">
                                    <ngx-skeleton-loader
                                        count="1"
                                        appearance="circle"
                                        [theme]="{
                                        'height.px': 50,
                                        'width.px': 50
                                        }">
                                    </ngx-skeleton-loader>
                                </div>
                                <div class="text">
                                    <div class="loader">
                                        <ngx-skeleton-loader
                                            count="1"
                                            [theme]="{
                                            'height.px': 20,
                                            'width.px': 200
                                            }"
                                        ></ngx-skeleton-loader>

                                        <ngx-skeleton-loader
                                            count="1"
                                            [theme]="{
                                            'height.px': 15,
                                            'width.px': 175
                                            }"
                                        ></ngx-skeleton-loader>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </ng-container>
        </ng-container>

       <ng-container  *ngIf="results.employees.length === 0 && results.positions.length === 0">
            <div class="result">
                <div class="thumbnail">
                    <mat-icon class="material-icons-outlined">manage_search</mat-icon>
                </div>
                <div class="text">
                    <h5 class="name" appLocalizationPopupDirective [localizationCode]="'NothingFoundFor'">{{ 'NothingFoundFor' | translate: {Default: "Nothing found for"} }} '{{searchValue}}'</h5>
                </div>
            </div>
        </ng-container>
    </div>
</div>
