import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {

  action = this.data.action;
  panelOpenState = false;
  
  constructor( 
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public sbRef: MatSnackBarRef<SnackbarComponent>
  ) { 
    if(data.panelOpenState){
      this.panelOpenState = data.panelOpenState;
    }
  }

  ngOnInit(): void {
  }

  get getIcon() {
    switch (this.data.snackType) {
      case 'success':
        return 'done';
      case 'error':
        return 'error';
      case 'warn':
        return 'warning';
      case 'info':
        return 'info';
    }
  }

  dismiss(){
    this.sbRef.dismiss();
  }


}
