import { Version } from "@app/shared/models/version.model"

export class Review {
  id: string
  currentUser: {
    isPendingForReview: boolean
    isReviewPlanOwner: boolean
  }
  status: {
    id: string
    name: string
  }
  employee: {
    id: string
    firstName: string
    lastName: string
  }
  reviewPlan: {
    id: string
    name: string
    description: string
    startDate: string
    endDate: string
  }
  reviewers: {
    id: string
    firstName: string
    lastName: string
    order: [
      {
        order: number
        status: {
          id: string
          name: string
        }
        reviewerType: {
          id: string
          name: string
        }
      }
    ],
    reviewer?: any
    orderExtracted?: number
    staturExtracted?: string
    reviewerTypeExtracted?: string
  }[]
  reviews: {
    reviewedBy: {
      id: string
      firstName: string
      lastName: string
    },
    comments: string
    rating: {
      id: string
      name: string
      description: string
    }
  }[]
  goalPlans: ReviewGoalPlan[]
  settings: ReviewSetting
  allGoalPlansReviewStatus?: string
  overallReviewStatus?: string
}

export class ReviewSetting {
  includeCalculatedRatings: boolean
  reviewerPermissions: {
    reviewer: string
    rating: string
    comments: string
  }[]
  goalPlanPermissions: {
    goalPlan: string
    reviewerPermissions: {
      reviewer: string
      rating: string
      comments: string
    }[]
    goalPermissions: {
      globalGoal: boolean
      goal: string
      reviewerPermissions: {
        reviewer: string
        rating: string
        comments: string
      }[]
    }[]
  }[]
}

export class ReviewGoalPlan {
  id: string
  name: string
  lockGoals: boolean
  goals: ReviewGoalPlanGoal[]
  reviews: ReviewGoalPlanReview[]
  weight: number
  overallStatus?: string
  goalsStatus?: string
  reviewStatus?: string
}

export class ReviewGoalPlanReview {
  id: string
  reviewedBy: {
    id: string
    firstName: string
    lastName: string
  }
  comments: string
  rating: {
    id: string
    name: string
    description: string
  }
  version: Version
}

export class ReviewGoalPlanGoal {
  id: string
  description: string
  objective: string
  percentageComplete: number
  expectedCompletionDate: string
  actualCompletionDate: string
  goalType: {
    id: string
    name: string
  }
  parent: {
    id: string
    objective: string
    description: string
  }
  weight: number
  reviews: ReviewGoalPlanGoalReview[]
  childGoals?: ReviewGoalPlanGoal[]
  status?: string
}

export class ReviewGoalPlanGoalReview {
  id: string
  reviewedBy: {
    id: string
    firstName: string
    lastName: string
  }
  comments: string
  rating: {
    id: string
    name: string
  }
  version: Version
}

export class ReviewSubmit {
  rating: string
  comments: string
  goalPlans: {
    id: string
    goals: {
      id: string
      review: {
        id: string
        comments: string
        rating: {
          id: string
        }
      }
    }[]
  }[]
}