<app-data-grid-comp
    [columns]="columns"
    [bindingType]="bindingType"
    [view]="view"
    [gridData]="gridData"
    [gridDataResult]="gridDataResult"
    [pageSize]="pageSize"
    [skip]="skip"
    category="OrganizationAccessByModule"
    [rowSelectionSettings]="{
        canSelectRows: true,
        selectableMode: 'multiple',
        selectRowBy: selectEntireRow
    }"
    actionButtonText="Add"
    [isLoading]="isLoading"
    [clearSelectedItems]="clearSelectedItems"
    filterable="true"
    [filterCategories]="filterCategories"
    (emitFilterEvent)="filterCallback($event)"
    (emitPageChangeEvent)="pageChange($event)"
    (emitOpenDialogEvent)="showAddOrgModal()"
    (emitDeleteEvent)="deleteAllSelected($event)"
></app-data-grid-comp>