<app-benefits>
    <mat-toolbar class="page-header" role="heading">
        <h1>{{ 'Benefits' | translate: {Default: "Benefits"} }}: {{ 'PlanTypes' | translate: {Default: "PlanTypes"} }}</h1>
    </mat-toolbar>
    
    <ng-container *ngIf="loading === true; else loaded">
        <mat-card>
            <ngx-skeleton-loader
                count="5"
                [theme]="{ 
                    'height.px': 50
                }"
            ></ngx-skeleton-loader>
        </mat-card>
    </ng-container>
    
    <ng-template #loaded>
    
        <div class="plan-types-container">
            <ng-container *ngFor="let planType of planTypes">
                <mat-card class="plan-type-card" (click)="navigateToPlanTypeDetailsPage(planType.id)">
                    <div class="pic-container">
                        <img 
                          mat-card-image 
                          [src]="planType.icon ? planType.icon : miscellaneous.BENEFIT_ICON">
                      </div>
    
                      <mat-card-content>
                        <h6 class="name" *ngIf="planType.name">{{planType.name}}</h6>
                        <h6 class="totalPlans">
                            {{planType.totalPlans}}
                            <span *ngIf="planType.totalPlans === 1"> Plan </span>
                            <span *ngIf="planType.totalPlans !== 1"> Plans </span>
                        </h6>
                      </mat-card-content>
                </mat-card>
            </ng-container>
        </div>
    
    </ng-template>
</app-benefits>
