import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { CompanyLogosService } from '../../services/company-logos.service';
import * as moment from 'moment';
import { finalize } from 'rxjs';
import { routes } from '@app/consts';

@Component({
  selector: 'app-create-company-logo',
  templateUrl: './create-company-logo.component.html',
  styleUrls: ['./create-company-logo.component.scss']
})
export class CreateCompanyLogoComponent implements OnInit {
  formId: string ='frm_zdqTAxaidsmCUG';
  getFormData: boolean = false;
  formValid: boolean = false;
  formData: any = {
    id: null,
    name: null,
    defaultLogo: null,
    active: null,
    file: null,
  }

  constructor(
    private router: Router,
    private translate: TranslateService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private companyLogosService: CompanyLogosService,
  ) { }

  ngOnInit(): void {
  }
  
  formDataEmitted(formData) {
    this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  save(formData) {
    this.overlayService.show()
  
    formData.asOf = moment().format('YYYY-MM-DD'),
    formData.changeReason = null,
    formData.changeReasonComments = null,
    formData.originalCreatedOn = moment().format('YYYY-MM-DD'),
  
    this.companyLogosService.postCompanyLogo(formData)
    .pipe(
      finalize(() => {
          this.overlayService.hide();
        }
      )
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
        this.router.navigate([`${routes.SITE_SETTINGS}${routes.STYLING}${routes.COMPANY_LOGOS}${routes.EDITOR}/${res.id}`]);
      }
    );
  }

  close(){
    this.router.navigate([`${routes.SITE_SETTINGS}${routes.STYLING}${routes.COMPANY_LOGOS}`]);
  }

}
