<app-employee-details>
    <div class="main-container">
        <div class="top-container">
            <h5 appLocalizationPopupDirective localizationCode="Documents">{{ 'Documents' | translate: {Default: "Documents"} }}</h5>
        </div>
    
        <app-documents-tree-view 
            *ngIf="selectedView == 'treeView'"
            [employeeID]="employeeID"
            (editAction)="openDocumentEditDialog($event)"
            (deleteAction)="openConfirmDeleteDialog($event)"
            (viewAction)="download($event)"
            (moveAction)="moveItem($event)"
            (downloadAction)="download($event)"
            (showCreateNewDirectoryForm)="openDirectoryFormDialog($event)"
            (showDocumentUploadDialog)="openDocumentUploadDialog($event)"
            [refreshView]="refreshView"
        ></app-documents-tree-view>
    </div>
</app-employee-details>