<app-layout>
    <div class="navigation-menu-list main-container">
        <div class="menu">
            <mat-list>
                <ng-container *ngFor="let item of navList">
<!--                    <mat-list-item [routerLink]="item.link" routerLinkActive="active" *appHasFeature="item.featureCode">-->
                    <mat-list-item 
                        *appHasFeature="item.featureCode"
                        appLocalizationPopupDirective 
                        [localizationCode]="item.text"
                        [routerLink]="item.link" 
                        routerLinkActive="primaryColor" 
                        class="primaryHoverColor" 
                        setColor
                        [setColorRules]="[
                            {
                                color: 'primaryColour',
                                events: ['hover']
                            },
                            {
                                color: 'primaryColour',
                                setIfClass: ['primaryColor'],
                                events: ['routerLinkActive']
                            },
                        ]"
                    >
                        <ng-container *ngIf="item.svg; else iconTemplate">
                            <svg xmlns="http://www.w3.org/2000/svg" class="mat-icon">
                                <image [attr.href]="item.svg" width="100%" height="100%"/>
                            </svg>
                        </ng-container>
                        <ng-template #iconTemplate>
                            <mat-icon 
                                class="material-icons-outlined"
                                routerLinkActive="primaryColor" 
                                setColor
                                [setColorRules]="[
                                    {
                                        color: 'primaryColour',
                                        events: ['hover']
                                    },
                                    {
                                        color: 'primaryColour',
                                        setIfClass: ['primaryColor'],
                                        events: ['routerLinkActive']
                                    },
                                ]"
                            >
                                {{item.icon}}
                            </mat-icon>
                        </ng-template>
                        <div class="text">{{ translate.instant(item.text) }}</div>
                    </mat-list-item>
                </ng-container>
            </mat-list>
        </div>
        <div class="content">
            <div class="main-content">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</app-layout>
