import { Component, OnInit, ViewChild } from '@angular/core';
import { EmployeeService } from '@app/core/services/employee.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { Router } from "@angular/router";
import { db_tables, features, miscellaneous, routes } from '@app/consts' ;
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NewEmployeeFormDialogComponent } from './components/new-employee-form-dialog/new-employee-form-dialog.component';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { finalize } from 'rxjs/operators';
import { EmployeeContactCardService } from '../talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/services/employee-contact-card.service';
import { FeatureService } from '@app/core/services/feature.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DataBindingDirective, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { EmployeeContactCard } from '../talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/models/employee-contact-card.model';
import { SortDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'app-talent-track-profiles',
  templateUrl: './talent-track-profiles.component.html',
  styleUrls: ['./talent-track-profiles.component.scss']
})
export class TalentTrackProfilesComponent implements OnInit {
  public columns: any[] = [
    { field: "employee", title: this.translate.instant('FullName'), type: 'employee' },
    { field: "preferredName", title: "Preferred Name", tableId: "tfi_PreferredName" },
    { field: "userId", title: "Employee ID", tableId: "tfi_EmpUserId" },
    { field: "customClientId", title: "Custom Client ID", tableId: "tfi_EmployeeId" },
    {field: 'managers', title: this.translate.instant('Managers'), type: 'employees'},
    {field: 'positions', title: this.translate.instant('Position'), type: 'array', arrayItemField: 'name'},
    {field: 'organization', title: 'Organisation', subField: 'name', tableId: "tfi_EmpOrganization"},
    {field: 'workLocations', title: this.translate.instant('WorkLocations'), type: 'array', arrayItemField: 'name'},
    {field: 'email', title: 'Email', tableId: "tfi_EmpEmail"},
    {field: 'cellNumber', title: 'Cell Number', tableId: "tfi_EmpCellPhone"},
    {field: 'phoneNumber', title: 'Phone Number', tableId: "tfi_EmpWorkPhone"},
    {field: 'status', title: this.translate.instant('Status'), type: 'chip', chipValue: ['Active']},
    {field: 'subStatus', title: this.translate.instant('SubStatus'), type: 'chip', chipValue: ['Active']},
    {field: 'timezone', subField: 'text', title: 'Timezone', tableId: "tfi_EmpTimezone"},
    {field: 'inOffice', title: this.translate.instant('InOffice'), type: 'booleanToggle'},
    {field: 'office', title: this.translate.instant('Office')},
    {field: 'facebookUrl', title: 'Facebook Url', tableId: "tfi_EmpFacebookUrl"},
    {field: 'linkedInUrl', title: 'LinkedIn Url', tableId: "tfi_EmpLinkedInUrl"},
    {field: 'twitterHandle', title: 'Twitter Handle', tableId: "tfi_EmpTwitterHandle"},
  ];
  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public mySelection: string[] = [];
  public routes: typeof routes = routes;
  public miscellaneous: typeof miscellaneous = miscellaneous;
  public sort: SortDescriptor[] = [];
  public db_tables = db_tables;
  pageSize: number = 20;
  skip: number = 0;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  isLoading: boolean;
  filterString: string;
  searchFilterString: string;
  searchValue: string;
  getEmployeesRequest: any;
  sortString: string = 'Lastname-asc~Firstname-asc';

  public filterCategories: any[] = [
    {field: 'firstname', title: 'Firstname', dataType: 'String', tableId: "tfi_FirstName"},
    {field: 'lastname', title: 'Lastname', dataType: 'String', tableId: "tfi_LastName"},
    {field: 'preferredName', title: 'Preferred Name', dataType: 'String', tableId: "tfi_PreferredName"},
    {field: 'userId', title: 'User ID', dataType: 'String', tableId: "tfi_EmpUserId"},
    {field: 'customClientId', title: 'Custom Client ID', dataType: 'String', tableId: "tfi_EmployeeId"},
    {field: 'Position', title: this.translate.instant('Position'), dataType: 'SpecialLookup', lookupCode: 'POSITION'},
    {field: 'Organization', title: this.translate.instant('Organization'), dataType: 'SpecialLookup', lookupCode: 'ORGANISATION'},
    {field: 'WorkLocation', title: this.translate.instant('WorkLocation'), dataType: 'SpecialLookup', lookupCode: 'WORK_LOCATION'},
    {field: 'email', title: 'Email', dataType: 'String', tableId: "tfi_EmpEmail"},
    {field: 'cellNumber', title: 'Cell Number', dataType: 'Number', tableId: "tfi_EmpCellPhone"},
    {field: 'PhoneNumber', title: 'Phone Number', dataType: 'String', tableId: "tfi_EmpWorkPhone"},
    {field: 'SubStatus', title: this.translate.instant('SubStatus'), dataType: 'String'},
    // {field: 'Status', title: 'Status'},
    {field: 'timezone', title: 'Timezone', dataType: 'String', tableId: "tfi_EmpTimezone"},
  ].sort((a, b) => a.field.localeCompare(b.field));

  sortableColumns: any[] = [
    { field: "employee", sortValue: ["Firstname", "Lastname"] },
    { field: "preferredName", sortValue: "preferredName" },
    { field: "userId", sortValue: "userId" },
    { field: "customClientId", sortValue: "customClientId" },
    // {field: 'managers', sortValue: "managers"},
    // {field: 'positions', sortValue: "positions"},
    {field: 'organization', sortValue: "organization"},
    // {field: 'workLocations', sortValue: "workLocations"},
    {field: 'email', sortValue: "email"},
    {field: 'cellNumber', sortValue: "cellNumber"},
    {field: 'phoneNumber', sortValue: "phoneNumber"},
    // {field: 'status', sortValue: "status"},
    // {field: 'subStatus', sortValue: "subStatus"},
    {field: 'timezone', sortValue: "timezone"},
    // {field: 'inOffice', sortValue: "inOffice"},
    // {field: 'office', sortValue: "office"},
    // {field: 'facebookUrl', sortValue: "facebookUrl"},
    // {field: 'linkedInUrl', sortValue: "linkedInUrl"},
    // {field: 'twitterHandle', sortValue: "twitterHandle"},
  ];

  // sort function
  filterToggleDetails: {
    text: string,
    checked: boolean
  } = {
    text: 'FilterInactive',
    checked: true
  }
  isLoadingPermissions: boolean;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];

  get tableCreateAccessObj() {
    return {
      tableId: this.db_tables.Talent,
      permission: 'create',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  constructor(
    public employeeService: EmployeeService,
    public router: Router,
    private snackbarService: SnackbarService,
    private tablePermissionsService: TablePermissionsService,
    private dialog: MatDialog,
    private employeeContactCardService: EmployeeContactCardService,
    private featureService: FeatureService,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.getEmployees();
    this.getEmployeeTablePermissions();
  }

  getEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    this.tablePermissionsService.getEmployeeTablePermissions()
    .pipe(
      finalize(() => {
          this.isLoadingPermissions = false;
      })
    )
    .subscribe(
      res => {
        this.targetEmployeeTablePermissions = [res];
      }
    );
  }

  openEditEmployee(id: string): void {
    this.router.navigateByUrl(`${routes.TALENT_TRACK}${routes.EDIT}/${id}${routes.EMPLOYEE_DETAILS}`);
  }

  openNewEmployeeDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 500;

    const dialogRef = this.dialog.open(NewEmployeeFormDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data.result) {
          this.openEditEmployee(data.result.employeeId);
          this.snackbarService.openSnackBar(this.translate.instant('CreatedSuccessfully'), 'clear', 'success');
        }
      }
    ); 
  }

  getEmployees(): void {
    this.isLoading = true;

    let filter;
    if(this.searchFilterString) {
      filter = this.searchFilterString;
    }
    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }

    this.getEmployeesRequest = this.employeeContactCardService.getAllEmployeeContactCards(this.skip, String(this.pageSize), filter, this.sortString, this.filterToggleDetails.checked)
    .pipe(
        finalize(()=>{ this.isLoading = false; })
    )
    .subscribe(
      res => {
        this.gridDataResult = {
          data: res.data,
          total: res.total,
        }

        this.formatGridData();
      }
    )
  }

  formatGridData() {
    this.gridDataResult.data.forEach(employeeContactCard => {
      employeeContactCard.employee = {
        id: employeeContactCard.id,
        firstName: employeeContactCard.firstname,
        lastName: employeeContactCard.lastname,
      }
    });
  }

  editOrCreateEmployee(employeeContactCard?: EmployeeContactCard) {
    if(employeeContactCard) {
      this.openEditEmployee(employeeContactCard.id);
    }
    else {
      this.openNewEmployeeDialog();
    }
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;

    let variants = this.searchValue.split(' ').filter(value => value);
    this.searchFilterString = '';
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `((FirstName like "${variant}") OR (LastName like "${variant}") OR (PreferredName like "${variant}") OR (customClientId like "${variant}") OR (userId like  "${variant}"))`;
        }
    });
    this.skip = 0;;
    this.getEmployeesRequest.unsubscribe();
    this.getEmployees();
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.getEmployees();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getEmployees();
  }

  filterCallback(filterString: string) {
    this.searchFilterString = filterString;
    this.skip = 0;;
    this.getEmployees();
  }

  updateFilterToggleDetails(toggleChange: MatSlideToggleChange){
    this.filterToggleDetails.checked = toggleChange.checked;
    this.getEmployees();
  }
  
  hasPositionFeatureAccess():boolean {
    return this.featureService.hasFeatureAccess(features.POSITIONS);
  }


}
