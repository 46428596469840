import { Component, Input, OnInit } from '@angular/core';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-homepage-employee-goals-widget',
  templateUrl: './homepage-employee-goals-widget.component.html',
  styleUrls: ['./homepage-employee-goals-widget.component.scss']
})
export class HomepageEmployeeGoalsWidgetComponent implements OnInit {
  @Input() employeeId: string;

  user$: any;

  constructor(
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {

  }

}
