import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { api_routes, db_tables } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CustomReport } from '@app/modules/custom-reports/models/custom-report.model';
import { CustomReportsService } from '@app/modules/custom-reports/services/reports.service';
import { RowActionButtonInput } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/data-grid-comp/models/data-grid-comp.model';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { defer, finalize, forkJoin } from 'rxjs';

@Component({
    selector: 'app-custom-reports-data-grid',
    templateUrl: './custom-reports-data-grid.component.html',
    styleUrls: ['./custom-reports-data-grid.component.scss']
})
export class CustomReportsDataGridComponent implements OnInit {
    @Input() reportType: string;

    public columns: any[] = [
        {field: "name", title: "Name"},
        {field: "createdBy", title: "Created By", type: "employee"},
        {field: "createdOn", title: "Created On", type: "date"},
    ];

    filterCategories: any[] = [
        {field: "name", title: "Name", type: "String", dataType: 'String'},
        {field: "Version.CreatedOn", title: "Created On", type: "String", dataType: 'Date'},
        {field: "Version.CreatedBy.FirstName", title: "Created By (Firstname)", type: "String", dataType: 'String'},
        {field: "Version.CreatedBy.LastName", title: "Created By (Lastname)", type: "String", dataType: 'String'},
    ];

    sortableColumns: any[] = [
        {field: "name", sortValue: "name"},
        {field: "createdBy", sortValue: "version.createdBy"},
        {field: "createdOn", sortValue: "version.createdOn"},
    ];

    gridActionButtons: RowActionButtonInput[] = [
        {
            id: 'edit',
            name: this.translate.instant('Edit'),
            icon: 'edit'
        }
    ];

    public bindingType: String = "array";
    public view: any;
    public gridData: any;
    public gridDataResult: GridDataResult;
    public api_routes = api_routes;
    public db_tables = db_tables;
    isLoading: boolean;
    pageSize: number = 10;
    skip: number = 0;
    clearSelectedItems: boolean = false;
    searchValue: string;
    searchFilterString: string;
    getReportsRequest: any;
    sortString: string;
    filterString: string;
    public selectedItems: any[] = [];

    constructor(
        private router: Router,
        private reportsService: CustomReportsService,
        private overlayService: OverlayService,
        private dialog: MatDialog,
        public translate: TranslateService,
        private snackbarService: SnackbarService,
    ) {
    }

    ngOnInit(): void {
        this.view = this.getReports();
    }

    getReports() {
        this.isLoading = true;
        let reportTypeId: string;

        if (this.reportType == 'Terminations') {
            reportTypeId = 'terminations_report';
        } else if (this.reportType == 'Employees') {
            reportTypeId = 'employees_report';
        } else if (this.reportType == 'TimeOff') {
            reportTypeId = 'timeoff_report';
        } else if (this.reportType == 'Configuration') {
            reportTypeId = 'configuration_report';
        } else if (this.reportType == 'Security') {
            reportTypeId = 'security_report';
        } else if (this.reportType == 'Performance') {
            reportTypeId = 'performance_report';
        }

        let filter: string = `(Type.Id = "${reportTypeId}")`;
        if (this.filterString) {
            filter = `${this.filterString} AND ${filter}`
        }
        if (this.searchFilterString) {
            filter = `${this.searchFilterString} AND ${filter}`
        }

        this.getReportsRequest = this.reportsService.getNewReports(String(this.pageSize), String(this.skip), filter, this.sortString)
        .pipe(
            finalize(() => {
                this.isLoading = false;
            })
        )
        .subscribe(
            res => {
                res.data.forEach(
                    report => {
                        report.createdBy = report.version?.createdBy;
                        report.createdOn = report.version?.createdOn;
                    }
                )

                this.gridDataResult = {
                    data: res.data,
                    total: res.total,
                };
            }
        );
    }


    goToRenderReport(dataItem: CustomReport) {
        if (dataItem.url) {
            this.router.navigate([`${dataItem.url}`]);
        } else {
            this.router.navigate([`Reports/Custom/${dataItem.id}`]);
        }
    }

    goToEditReport(data: { id: string, rowData: CustomReport }) {
        this.router.navigate([`Reports/Builder/Edit/${data.rowData.id}`]);
    }

    public pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.pageSize = event.take
        this.view = this.getReports();
    }

    search(newSearchString: string) {
        this.searchValue = newSearchString;

        let variants = this.searchValue.split(' ').filter(value => value);
        this.searchFilterString = '';
        variants.map(variant => {
            if (variant) {
                if (this.searchFilterString.length > 2) {
                    this.searchFilterString += ' AND ';
                }
                this.searchFilterString += `(Name like "${variant}")`;
            }
        });

        this.getReportsRequest.unsubscribe();
        this.getReports();
    }

    sortChange(sortString: string) {
        this.sortString = sortString;
        this.getReports();
    }

    filterCallback(filterString: string) {
        this.filterString = this.adjustCreatedOnFilter(filterString);
        this.skip = 0;
        this.getReports();
    }

    adjustCreatedOnFilter(filterString: string): string {
        let match = filterString.match(/(Version\.CreatedOn\s*=\s*"(\d{4}-\d{2}-\d{2})")/);

        if (match) {
            try {
                let date = new Date(match[2]);

                if (isNaN(date.getTime())) { // Check if date is valid
                    throw new Error(`Invalid date: ${match[2]}`);
                }

                let startDate = new Date(date);
                startDate.setDate(startDate.getDate() - 1);

                let endDate = new Date(date);
                endDate.setDate(endDate.getDate() + 1);

                let startFilter = `(Version.CreatedOn >= "${startDate.toISOString().slice(0, 10)}")`;
                let endFilter = `(Version.CreatedOn <= "${endDate.toISOString().slice(0, 10)}")`;

                let newFilter = `${startFilter} AND ${endFilter}`;

                return filterString.replace(match[0], newFilter);
            } catch (error) {
                console.error("Error parsing date from filter string: ", error);
                // Keep the original filter string if there's an error
            }
        }

        return filterString;
    }

    deleteAllSelected(itemsToDelete: string[]) {
        const observables = itemsToDelete.map(item => defer(() => this.reportsService.deleteReport(item)));

        this.overlayService.show();

        forkJoin(observables)
        .pipe(
            finalize(() => this.overlayService.hide())
        )
        .subscribe(
            (res) => {
                this.selectedItems = [];
                this.clearSelectedItems = true;
                this.getReports();
                this.snackbarService.openSnackBar(this.translate.instant('DeletedSuccessfully'), 'clear', 'success');
            },
            err => {
                this.snackbarService.openSnackBar(err, 'clear', 'warn');
            }
        );
    }

}
