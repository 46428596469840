import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { api_routes, db_tables } from '@app/consts';
import { TranslateService } from '@ngx-translate/core';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { GoalPlanService } from '../../../goal-plans/services/goal-plan.service';
import { finalize } from 'rxjs';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { EmployeeGoal } from '../../../goal-plans/models/goal-plan.model';

@Component({
  selector: 'app-employee-personal-goal-child-goals',
  templateUrl: './employee-personal-goal-child-goals.component.html',
  styleUrls: ['./employee-personal-goal-child-goals.component.scss']
})
export class EmployeePersonalGoalChildGoalsComponent implements OnInit {
  @Input() employeeId: string;
  @Input() goalId: string;
  @Input() parentId: string;
  @Input() category: string;
  @Input() readOnly: boolean;
  
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 20;
  skip: number = 0;

  getGoalsRequest: any;
  filterString: string;
  searchFilterString: string;
  searchValue: string = "";
  sortString: string;
  translationsLoaded: boolean = false;
  employeeGoals: EmployeeGoal[] = [];

  constructor(
    public translate: TranslateService,
    private goalService: GoalPlanService
  ) { }

  ngOnInit(): void {
    if(this.parentId){
      this.getGoals();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.parentId && changes.parentId.currentValue !== undefined){
      this.getGoals();
    }
  }

  getGoals() {
    this.isLoading = true;

    let filter = `(parent.id = "${this.parentId}") AND (id != "${this.goalId}")`;
    if(this.searchFilterString) {
      filter = this.searchFilterString;
    }
    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }

    this.getGoalsRequest = this.goalService.getEmployeeGoals(this.employeeId, String(this.pageSize), this.skip, this.sortString, filter)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          res.employeeGoals.data.map( employeeGoal => employeeGoal.employee.id = res.employeeId )

          this.employeeGoals = res.employeeGoals.data;
        }
      );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.getGoals();
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;;
    this.getGoals();
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;

    let variants = this.searchValue.split(' ').filter(value => value);
    this.searchFilterString = '';
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `(Objective like "${variant}")`;
        }
    });

    this.getGoalsRequest.unsubscribe();
    this.getGoals();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getGoals();
  }

}
