<app-site-settings-menu>

    <div class="main-container" *ngIf="hasFeatureAccess">
        <div class="left">
            <h5>{{ 'Cultures' | translate: {Default: "Cultures"} }}</h5>

            <div *ngIf="!loadingCultures; else showLoadingCultures">
                <div *ngIf="cultures.length > 0; else noCulturesFound">
                    <mat-list class="culture-list">
                        <ng-container *ngFor="let culture of cultures">
                            <mat-list-item class="culture-list-item" (click)="selectedCultureChange(culture)" class="primaryHoverColor" [ngClass]="{'primaryColor': selectedCulture === culture }">
                                {{culture.text}}
                            </mat-list-item>
                            <mat-divider></mat-divider>
                        </ng-container>
                    </mat-list>
                </div>

                <ng-template #noCulturesFound>
                    <div>{{ 'NoCulturesFound' | translate: {Default: "No cultures found"} }}</div>
                </ng-template>
            </div>

            <ng-template #showLoadingCultures>
                <ngx-skeleton-loader
                        count="5"
                        [theme]="{ 
                            'height.px': 50
                        }"
                ></ngx-skeleton-loader>
            </ng-template>
        </div>

        <div class="center">
            <div *ngIf="!loadingTranslations; else showLoadingTranslations">
                <div class="center-header">
                    <mat-form-field class="search-field" appearance="outline" >
                        <input matInput
                               (ngModelChange)="translationSearchChange($event)"
                               [(ngModel)]="translationsListSearchValue"
                               placeholder="Search...">
                        <mat-icon matPrefix class="material-icons-outlined">
                            search
                        </mat-icon>
                    </mat-form-field>

                    <div>
                        <button 
                            class="update-translation-button"
                            mat-raised-button 
                            setColor="primaryColour" 
                            color="primary" 
                            appPreventDoubleClick 
                            (throttledClick)="openUpdateTranslationDialog()" 
                            *ngIf="currentEmployeeDetails.userType === 'User'">
                            <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon>
                            {{ 'UpdateTranslation' | translate: {Default: "Update Translation"} }}
                        </button> 
                        
                        <button mat-raised-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openNewTranslationCodeDialog()">
                            <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon>
                            {{ 'AddNewTranslationCode' | translate: {Default: "Add New Translation Code"} }}
                        </button> 
                    </div>
                </div>
    
                <div>
                    <mat-list  *ngIf="currentTranslations">
                        <mat-list-item class="translations-list-header">
                            <div class="translationId">
                                {{ 'TranslationCode' | translate: {Default: "Translation Code"} }}
                            </div>
                            <div class="translationValue">
                                {{ 'Translation' | translate: {Default: "Translation"} }}
                            </div>
                        </mat-list-item>
                        <mat-divider></mat-divider>

                        <div class="translations-list">
                            <ng-container *ngFor="let item of filteredCurrentTranslations | keyvalue ">
                                <mat-list-item class="culture-list-item" (click)="selectedTranslationChange(item.key, item.value)" class="primaryHoverColor" [ngClass]="{'primaryColor': selectedTranslationId === item.key }">
                                        <div class="translationId">{{item.key}}</div>
                                        <div class="translationValue">{{item.value}}</div>
                                </mat-list-item>
                            </ng-container>
                        </div>
                    </mat-list>
                </div>
            </div>

            <ng-template #showLoadingTranslations>
                <ngx-skeleton-loader
                        count="5"
                        [theme]="{ 
                            'height.px': 50
                        }"
                ></ngx-skeleton-loader>
            </ng-template>

        </div>

        <div class="right" *ngIf="form">
            <div>
                <h5>
                    {{ 'EditTranslation' | translate: {Default: "Edit Translation"} }}
                </h5>
            </div>

            <form class="form" [formGroup]="form">

                <mat-form-field appearance="fill">
                    <mat-label>
                        {{ 'Culture' | translate: {Default: "Culture"} }}
                    </mat-label>
            
                    <mat-select disableOptionCentering disabled="true" formControlName="cultureId">
                        <mat-option></mat-option>
                        <mat-option 
                            *ngFor="let data of cultures" 
                            [value]="data.id">
                                {{data.text}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>
                        {{ 'TranslationCode' | translate: {Default: "Translation Code"} }}
                    </mat-label>
                    
                    <input 
                        matInput
                        readonly
                        type="text"
                        formControlName="id">
                </mat-form-field>


                <mat-form-field appearance="fill">
                    <mat-label>
                        {{ 'Translation' | translate: {Default: "Translation"} }}
                    </mat-label>
                    <textarea matInput matTextareaAutosize matAutosizeMinRows=1 matAutosizeMaxRows=30
                        formControlName="newTranslationValue"></textarea>
                </mat-form-field>

                <div class="buttons-container">
                    <button class="mat-raised-button" (click)="closeTranslationsForm()">
                        {{ 'Close' | translate: {Default: "Close"} }}
                    </button>
                    <button mat-raised-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="updateTranslation()" [disabled]="!form.valid" *ngIf="form">
                        {{ 'Save' | translate: {Default: "Save"} }}
                    </button> 
                </div>
            </form>
        </div>
    </div>

    <no-feature-access *ngIf="!hasFeatureAccess"></no-feature-access>
</app-site-settings-menu>
