import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmployeeService } from '@app/core/services/employee.service';
import { Employee, Talent, TalentVerbose } from '@app/shared/models/employee.model';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-compensation-section-demo',
  templateUrl: './compensation-section-demo.component.html',
  styleUrls: ['./compensation-section-demo.component.scss']
})
export class CompensationSectionDemoComponent implements OnInit {
  employee: TalentVerbose;
  navList = [
    {
      text: 'My Compensation',
      icon: 'build'
    },
    {
      text: 'My Benefits',
      icon: 'build'
    }
  ];
  selectedComponent: any = this.navList[0];
  loadingError: boolean;
  isLoading: boolean = true;
  employeeId: string;

  constructor(
    private route: ActivatedRoute,
    private employeeService: EmployeeService,
  ) { }

  ngOnInit(): void {
    this.employeeId = this.getIdInURL();
    this.getEmployee(this.employeeId);
  }

  getIdInURL(): string {
    let IdInURL: string;
    
    this.route.paramMap.subscribe(
      params => IdInURL = params.get("id")
    )
      
    return IdInURL;
  }

  getEmployee(id: string): void {
    this.isLoading = true;
    this.employeeService.getTalent(id)
    .pipe(
      finalize( () => {
        this.isLoading = false;
      })
    )
    .subscribe(
      (res: TalentVerbose) => {
        this.employee = res;
      },
      err => {
        this.loadingError = true;
      },
      () => console.log("done")
    );
  }
}
