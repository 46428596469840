<app-benefits>
    <mat-toolbar class="page-header" role="heading">
        <h1 appLocalizationPopupDirective localizationCode="Benefits-PlanType">{{ 'Benefits-PlanType' | translate: {Default: "Benefits: Plan Type"} }} </h1>

        <button mat-flat-button setColor="primaryColour" color="primary" routerLink="{{routes.BENEFITS}}{{routes.PLAN_TYPES}}">
            <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">arrow_back</mat-icon> {{ 'BenefitsPlanReturnToPlanTypes' | translate: {Default: "Return To Plan Types"} }}
        </button>
    </mat-toolbar>

    <mat-card>
        <mat-card-title *ngIf="planType !== undefined" class="header">
            <div>
                <img mat-card-image [src]="planType.icon ? planType.icon : miscellaneous.BENEFIT_ICON">

                <h5>
                    {{planType.name}}: {{ 'BenefitsPlans' | translate: {Default: "Plans"} }}
                </h5>
            </div>
        </mat-card-title>


        <mat-card-content>
            <ng-container *ngIf="planType === undefined; else loaded">
                <ngx-skeleton-loader
                    count="5"
                    [theme]="{ 
                        'height.px': 50
                    }"
                ></ngx-skeleton-loader>
            </ng-container>
    
            <ng-template #loaded>
                <kendo-grid 
                    [data]="gridDataResult" 
                    [pageSize]="pageSize"
                    [skip]="skip"
                    [style.maxHeight.vh]="85" 
                    [resizable]="true"
                    [pageable]="{
                        info: true,
                        pageSizes: [5, 10, 20, 50, 100],
                        previousNext: true
                    }"
                    (pageChange)="pageChange($event)"
                    [loading]="loadingPlans">

                    <ng-template kendoGridToolbarTemplate>
                        <div class="filters-container">
                            <mat-form-field class="search-field" appearance="outline">
                                <input matInput
                                    placeholder="Search..."
                                    [(ngModel)]="searchValue" 
                                    (input)="search()"
                                    >
                                <mat-icon matPrefix class="material-icons-outlined">
                                    search
                                </mat-icon>
                            </mat-form-field>
                        </div>
                    </ng-template>
                    
                    <kendo-grid-column field="name" title="name" [width]="150">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="name navigation-link" routerLink="{{routes.BENEFITS}}{{routes.PLANS}}/{{routes.EDITOR}}/{{dataItem.id}}">
                                {{dataItem.name}} {{dataItem.lastname}}
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="startDate" title="Start Date" [width]="80">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            {{dataItem.startDate | date:'mediumDate'}}
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="endDate" title="End Date" [width]="80">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            {{dataItem.endDate | date:'mediumDate'}}
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="planType" title="plan Type" [width]="80">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <ng-container *ngIf="dataItem.planType">
                                {{dataItem.planType.name}}
                            </ng-container>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="planProvider" title="plan Provider" [width]="80">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <ng-container *ngIf="dataItem.planProvider">
                                {{dataItem.planProvider.name}}
                            </ng-container>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="rateType" title="rate Type" [width]="80">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <ng-container *ngIf="dataItem.rateType">
                                {{dataItem.rateType.name}}
                            </ng-container>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-messages 
                        [noRecords]="isLoading ? '' : translate.instant('Grid-NoRecordsAvailable')"
                        [pagerItemsPerPage]="translate.instant('Grid-Pager-ItemsPerPage')" 
                        [pagerItems]="translate.instant('Grid-Pager-Items')"
                        [pagerOf]="translate.instant('Grid-Pager-Of')"
                    ></kendo-grid-messages>
                </kendo-grid>
            </ng-template>
        </mat-card-content>
    </mat-card>
</app-benefits>