import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DirectReportees } from './models/direct-reportee.model';
import { DirectReporteesService } from './services/direct-reportees.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-direct-reportees',
  templateUrl: './direct-reportees.component.html',
  styleUrls: ['./direct-reportees.component.scss']
})
export class DirectReporteesComponent implements OnInit {
  directReportees$: Observable<DirectReportees>;
  directReporteeSearchValue: string = '';

  constructor(
    public translate: TranslateService,
    private directReporteesService: DirectReporteesService
  ) { }

  ngOnInit(): void {
    this.directReportees$ = this.directReporteesService.getDirectReportees(0, '1000', null, 'Lastname-desc~Firstname-desc');
  }

}
