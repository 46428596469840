import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api_routes } from '@app/consts';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CompanyAssetSubmit, CompanyAssetVerbose } from '../models/employee-company-asset.model';
import { EnvironmentService } from "@app/core/services/environment.service";


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});
@Injectable({
  providedIn: 'root'
})
export class EmployeeCompanyAssetsService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getCompanyAssets(employeeId: string, take?: string, skip?: number, sort?: string): Observable<any> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    sort ? params = params.append('sort', sort) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.COMPANY_ASSETS}`, {
      headers: headers,
      params: params
    })
    .pipe(
      catchError(err => throwError(err)),
      map(res => {
        let data = {
          data: res.data,
          total: res.total
        }

        return data;
      })
    );
  }

  deleteCompanyAsset(employeeId: string, id: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.COMPANY_ASSETS}/${id}`, {
      headers: headers
    });
  }

  getCompanyAsset(employeeId: string, id: string): Observable<CompanyAssetVerbose> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.COMPANY_ASSETS}/${id}`, {
      headers: headers
    });
  }

  updateCompanyAsset(employeeId: string, employeeCompanyAsset: CompanyAssetSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.COMPANY_ASSETS}/${employeeCompanyAsset.id}`, employeeCompanyAsset, {
      headers: headers
    });
  }

  createCompanyAsset(employeeId: string, employeeCompanyAsset: CompanyAssetSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.COMPANY_ASSETS}`, employeeCompanyAsset, {
      headers: headers
    });
  }
}
