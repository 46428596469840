import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Employee, EmployeePositionBody, EmployeeSubmit, Organization, PositionsData, PositionSubmit, ReporToOrganizationBody, ReportsTo, ReportToPositionBody } from '../models/org-chart.model';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { api_routes, miscellaneous } from '@app/consts';
import { EnvironmentService } from "@app/core/services/environment.service";


const headers = new HttpHeaders({
  'Content-Type': 'application/json',
});

@Injectable({
  providedIn: 'root'
})

@Injectable({
  providedIn: 'root'
})
export class OrgChartService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getPosition(parentId: string): Observable<ReportsTo[]> {
    let params = new HttpParams();
    params = params.append('skip', 0);
    params = params.append('take', '2000');

    let link = `${this.envService.env.apiUrl}${api_routes.POSITIONS}/${api_routes.REPORTS_TO}`
    if (parentId) {
      link += `/` + parentId
    }
    return this.http.get<any>(link, {
      headers: headers,
      params: params
    })
  }


  getPositions(skip: number): Observable<PositionsData> {
    let params = new HttpParams();
    params = params.append('skip', String(skip));
    params = params.append('take', '2000');

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}?includeEmployees=true`, {
      headers: headers,
      params: params
    }).pipe(
      catchError(err => throwError(err)),
      map(res => {
        return res;
      })
    );
  }

  getPositionDetails(positionId: string): Observable<any> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}/${positionId}`, {
      headers: headers
    });
  }

  getPositionEmployee(parentId: string): Observable<Employee[]> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}/${parentId}/${api_routes.EMPLOYEES}`, {
      headers: headers
    }).pipe(
      catchError(err => throwError(err)),
      map(res => {
        res.forEach(emp => {
          emp.parentId = parentId
        })
        return res;
      })
    );
  }

  getOrganizationEmployees(skip: number, organizationId: string): Observable<Employee[]> {
    let filterString = `(Organization = "${organizationId}")`
    let params = new HttpParams();
    params = params.append('skip', String(skip));
    params = params.append('take', '2000');
    params = params.append('Filter', filterString);

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${api_routes.CONTACT_CARDS}?`, {
      headers: headers,
      params: params
    }).pipe(
      catchError(err => throwError(err)),
      map(res => {
        return res["data"];
      })
    );
  }


  getEmployeePhoto(employeeId: string): Observable<string> {
    return this.http.get(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.PHOTO}`, {
      headers: headers,
      observe: 'response',
      responseType: 'blob' as 'json',
    })
      .pipe(
        catchError(err => { return throwError(err) }),
        switchMap(res => {
          return from((res.body as Blob).text())
        })
      );
  }

  getEmployeeDetails(employeeId: string): Observable<any> {
    return this.http.get(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}`, {
      headers: headers,
    })
      .pipe(
        catchError(err => { return throwError(err) }),
        tap(res => {
          return res["data"]
        })
      );
  }

  deleteEmployeePosition(posId: string): Observable<any> {
    return this.http.delete(`${this.envService.env.apiUrl}${api_routes.POSITIONS_EMPLOYEES}/${posId}`, {
      headers: headers
    })
  }

  postPositionReportToPosition(posId: string, data: ReportToPositionBody): Observable<any> {
    return this.http.put(`${this.envService.env.apiUrl}${api_routes.POSITIONS}/${posId}/${api_routes.REPORTS_TO_POSITION}`, data, {
      headers: headers
    })
  }

  postEmployeeReportToPosition(data: EmployeePositionBody): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS_EMPLOYEES}`, data, {
      headers: headers
    });
  }

  putEmployeeReportToPosition(positionId: string, data: EmployeePositionBody): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS_EMPLOYEES}/${positionId}`, data, {
      headers: headers
    });
  }

  postPosition(data: PositionSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}`, data, {
      headers: headers
    });
  }

  putPosition(positionId: string, position: PositionSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}/${positionId}`, position, {
      headers: headers
    });
  }

  deletePosition(posId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}/${posId}`, {
    });
  }

  postEmployee(data: EmployeeSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}`, data, {
      headers: headers
    });
  }

  putEmployee(empId: string, employee: EmployeeSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${empId}`, employee, {
      headers: headers
    });
  }

  deleteEmployee(empId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${empId}`, {
    });
  }


  getOrganizationsForRoot(organizationId?: string): Observable<Organization[]> {
    let params = new HttpParams();
    params = organizationId ? params.append('id', organizationId) : null;
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.ORGANIZATION}/${api_routes.UNITS}/${api_routes.REPORTS_TO}`, {
      headers: headers,
      params: params
    }).pipe(
      catchError(err => throwError(err)),
    );
  }

  // Organizations
  getOrganizations(): Observable<Organization[]> {
    let params = new HttpParams();
    params = params.append('skip', 0);
    params = params.append('take', '2000');

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.ORGANIZATION}/${api_routes.UNITS}`, {
      headers: headers,
      params: params
    }).pipe(
      catchError(err => throwError(err)),
      map(res => {
        return res.data;
      })
    );
  }

  postOrganizationReportToOrganization(orgId: string, data: ReporToOrganizationBody): Observable<any> {
    return this.http.put(`${this.envService.env.apiUrl}${api_routes.ORGANIZATION}/${api_routes.UNITS}/${orgId}/${api_routes.PARENT_ORGANIZATION}`, data, {
      headers: headers
    })
  }

  deleteOrganization(orgId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.ORGANIZATION}/${api_routes.UNITS}/${orgId}`, {
      headers: headers
    })
  }

  getStockImage(): Observable<any> {
    return this.http.get(miscellaneous.STOCK_EMPLOYEE_IMAGE_PNG, {
      headers: headers,
      observe: 'response',
      responseType: 'blob' as 'json',
    })
  }
}
