<mat-card *ngIf="employee; else loading" class="employee-card" fxLayout="column" fxLayoutAlign="space-around center">
  <div class="profile-pic-container" fxLayout="column" fxLayoutAlign="space-around center">
    <img 
      class="profile-pic"
      mat-card-image 
      appPreventDoubleClick (throttledClick)="openEditEmployee(employee.id)"
      [src]="employee.profilePictureThumbnailUri">
  </div>
  <mat-card-content>
    <div class="name" *ngIf="employee.firstName && employee.lastName">{{employee.firstName}} {{employee.lastName}}</div>
    <div class="position" *ngIf="employee.positions.length > 0">{{employee.positions[0].name | uppercase }}</div>
    <div class="location" *ngIf="employee.timezone"><mat-icon class="material-icons-outlined">location_on</mat-icon> {{employee.timezone}}</div>
  </mat-card-content>
  <mat-card-actions fxLayoutGap="5px">
    <button mat-mini-fab [matMenuTriggerFor]="phone">
      <mat-icon class="material-icons-outlined">phone</mat-icon>
    </button>
    <mat-menu #phone="matMenu" yPosition="above">
      <button mat-menu-item>
        <img class="ms-icon" src="assets/icons/google.svg" style="width: 30px;
        margin-right: 10px;
        vertical-align: middle;">
        Google - Call through Google
      </button>
      <button mat-menu-item >
        <mat-icon class="material-icons-outlined">phone</mat-icon>Phone - 778-456-4352
      </button>
    </mat-menu>

    <button mat-mini-fab [matMenuTriggerFor]="mail">
      <mat-icon class="material-icons-outlined">mail_outline</mat-icon>
    </button>
    <mat-menu #mail="matMenu" yPosition="above">
      <button mat-menu-item>
          <img class="ms-icon" src="assets/icons/outlook-2019.svg" style="width: 30px;
          margin-right: 10px;
          vertical-align: middle;">
          Outlook - Send email
      </button>
      <button mat-menu-item>
          <img class="ms-icon" src="assets/icons/gmail.svg" style="width: 30px;
          margin-right: 10px;
          vertical-align: middle;">
          Gmail - Send email
      </button>
      <button mat-menu-item >
          <img class="ms-icon" src="assets/icons/yahoo.svg" style="width: 30px;
          margin-right: 10px;
          vertical-align: middle;">Yahoo - Send email
      </button>
    </mat-menu>

    <button mat-mini-fab [matMenuTriggerFor]="video">
      <mat-icon class="material-icons-outlined">videocam</mat-icon>
    </button>
    <mat-menu #video="matMenu" yPosition="above">
      <button mat-menu-item>
          <img class="ms-icon" src="assets/icons/teams-2019.svg" style="width: 30px;
          margin-right: 10px;
          vertical-align: middle;">MS Teams - Meet now
      </button>
      <button mat-menu-item >
          <img class="ms-icon" src="assets/icons/teams-2019.svg" style="width: 30px;
          margin-right: 10px;
          vertical-align: middle;">MS Teams - Schedule a meeting
      </button>
    </mat-menu>
    <button mat-mini-fab [matMenuTriggerFor]="dm">
      <mat-icon class="material-icons-outlined">forum</mat-icon>
    </button>
    <mat-menu #dm="matMenu" yPosition="above">
      <button mat-menu-item>
          <img class="ms-icon" src="assets/icons/teams-2019.svg" style="width: 30px;
          margin-right: 10px;
          vertical-align: middle;">MS Teams - Create message
      </button>
      <button mat-menu-item >
          <img class="ms-icon" src="assets/icons/slack.svg" style="width: 30px;
          margin-right: 10px;
          vertical-align: middle;">Slack - Create message
      </button>
    </mat-menu>
  </mat-card-actions>
</mat-card>

<ng-template #loading>
  <mat-card class="employee-card empty" fxLayout="column" fxLayoutAlign="space-around center">
    <div class="profile-pic-container" fxLayout="column" fxLayoutAlign="space-around center">
      <ngx-skeleton-loader 
        count="1" 
        appearance="circle"
        [theme]="{ 
          'height.px': 200,
          'width.px': 200
        }">
      </ngx-skeleton-loader>
    </div>
    <mat-card-content>
      <div style="padding: 10px; overflow:hidden;">
        <ngx-skeleton-loader
          count="3"
          [theme]="{ 
            'height.px': 50,
            'width.px': 300
          }"
        ></ngx-skeleton-loader>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>