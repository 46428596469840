<mat-card>
    <kendo-grid
        [data]="query | async"
        [loading]="isLoading"
        [skip]="state.skip"
        [pageSize]="state.take"
        [sort]="sort"
        [sortable]="true"
        (sortChange)="sortChange($event)"
        scrollable="virtual"
        [rowHeight]="36"
        [height]="750"
        (pageChange)="pageChange($event)"
        (excelExport)="onExcelExport($event)"
        >

        <ng-template kendoGridToolbarTemplate>
            <button class="export-to-excel-button" kendoGridExcelCommand>
                <img
                    class="ms-icon"
                    src="assets/icons/excel-icon.svg"
                    style="width: 35px;
                            height: 35px;
                            vertical-align: middle;">
                    {{ 'ExportToExcel' | translate: {Default: "Export to Excel"} }}
            </button>

            <div class="filters-container" *ngIf="!loadingPositions">
                <filter-control [categories]="filterCategories" (callback)="filterCallback($event)"></filter-control>
            </div>

            <div class="k-mb-2">
                <mat-slide-toggle setColor="primaryColour" [checked]="filterInactive" disabled="false" (change)="toggleFilterInactive($event)">{{ 'ActiveOnly' | translate: {Default: "Active Only"} }}</mat-slide-toggle>
            </div>
        </ng-template>


        <kendo-grid-column
            *ngFor="let col of columns"
            [field]="col.field"
            [title]="col.title"
            [width]="300"
        ></kendo-grid-column>

        <!-- <div *kendoGridDetailTemplate="let dataItem">
            <app-basic-employee-positions-data-grid [positions]="dataItem.positionsObject"></app-basic-employee-positions-data-grid>
        </div> -->

        <kendo-grid-excel
            fileName="BasicEmployeeDetailsReport.xlsx"
            [fetchData]="allData"
        ></kendo-grid-excel>
    </kendo-grid>
</mat-card>

