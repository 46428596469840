<app-employee-layout selectedComponent="Performance">
    <div 
        class="main-container" 
        *appHasModuleAccess="modules.PERFORMANCE" 
    >
        <div class="left">
            <mat-list>
                <mat-list-item 
                    *ngFor="let item of navList" 
                    (click)="navigateToSection(item.route)" 
                    [ngClass]="{'selected': section === item.route }"
                    appLocalizationPopupDirective 
                    [localizationCode]="item.text"
                    setColor 
                    [setColorRules]="[
                        {
                            color: 'primaryColour',
                            events: ['hover']
                        },
                        {
                            color: 'primaryColour',
                            setIfClass: ['selected']
                        },
                    ]"
                >
                    {{ translate.instant(item.text) }}
                </mat-list-item>
            </mat-list>
        </div>

        <div class="main-content" [ngSwitch]="section">
            <mat-card class="mat-elevation-z0 employee-goals-card" *ngSwitchCase="'EmployeeGoals'" >
                <mat-card-title class="header">
                    <h5 appLocalizationPopupDirective localizationCode="Performance-EmployeeGoals">{{ 'Performance-EmployeeGoals' | translate: {Default: "Employee Goals"} }}</h5>
                </mat-card-title>
        
                <mat-card-content>
                    <app-homepage-employee-goals-widget [employeeId]="employeeId"></app-homepage-employee-goals-widget>
                </mat-card-content>
            </mat-card>
            <app-my-reviews *ngSwitchCase="'EmployeeReviews'" [employeeId]="employeeId"></app-my-reviews>
        </div>
    </div>
</app-employee-layout>