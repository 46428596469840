<mat-card>
    <mat-card-title class="header">
      <!-- <h5>{{ 'EmployeeDirectory' | translate: {Default: "Employee Directory"} }}</h5> -->
    </mat-card-title>
  
    <mat-card-content>
        <button mat-stroked-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openDialog()">
            {{ 'MyCompensationHistory' | translate: {Default: "My Compensation History"} }}
        </button>
    </mat-card-content>
</mat-card>