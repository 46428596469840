<mat-card>
    <mat-card-title class="header">
        <h5 appLocalizationPopupDirective localizationCode="Benefits-GroupPlans">{{ 'Benefits-GroupPlans' | translate: {Default: "Group Plans"} }}</h5>
        <div>
            <button mat-stroked-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openGroupPlanDialog()">
                <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> Create new
            </button>
        </div>
    </mat-card-title>
    <mat-card-content>
        <div *ngIf="groupPlans === undefined || groupPlans.length === 0" class="no-group-plans-container">
            <h6>No Group Plans</h6>
        </div>

        <div class="group-plan-cards-container">

            <app-benefit-group-plan-card 
                *ngFor="let groupPlan of groupPlans"
                [groupPlan]="groupPlan"
                (emitOpenGroupPlanDialog)="openGroupPlanDialog($event)"
                (emitOpenDeletePlanDialog)="openConfirmDeleteDialog($event)"
            >
            </app-benefit-group-plan-card>

        </div>

        <!-- <kendo-grid 
            [data]="groupPlans" 
            [height]="410" 
            [loading]="loadingGroupPlans"
            selectable="true"
            kendoGridSelectBy="plan"
            [(selectedKeys)]="selectedGroupPlans"
            >
        
            <ng-template kendoGridToolbarTemplate>
                <div *ngIf="selectedGroupPlans.length > 0">
                    <button class="delete-all-selected-button" mat-stroked-button color="primary" (click)="openConfirmDeleteDialog()">
                    <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">delete</mat-icon> Delete All Selected ({{selectedGroupPlans.length}})
                    </button>
                </div>
            </ng-template>
        
            <kendo-grid-checkbox-column [width]="80"></kendo-grid-checkbox-column>
        
            <kendo-grid-column 
                field="plan"
                title="plan" 
                [width]="200">
                <ng-template kendoGridCellTemplate let-dataItem >
                    <div class="grid-cell-vertical">
                        <span class="navigation-link" (click)="openGroupPlanDialog(dataItem.plan.id); $event.stopPropagation();">
                            {{dataItem.plan?.name }}
                        </span>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column 
                field="group"
                title="group" 
                [width]="200">
                <ng-template kendoGridCellTemplate let-dataItem >
                    <div class="grid-cell-vertical">
                        <span>
                            {{dataItem.group?.name }}
                        </span>
                    </div>
                </ng-template>
            </kendo-grid-column>
        
            <kendo-grid-column 
                field="waitPeriodLength"
                title="wait Period Length" 
                [width]="200">
                <ng-template kendoGridCellTemplate let-dataItem >
                    <div class="grid-cell-vertical">
                        <span>
                            {{dataItem.waitPeriodLength }}
                        </span>
                    </div>
                </ng-template>
            </kendo-grid-column>
        
            <kendo-grid-column 
                field="waitPeriodLengthUnit"
                title="wait Period Length Unit" 
                [width]="200">
                <ng-template kendoGridCellTemplate let-dataItem >
                    <div class="grid-cell-vertical">
                        <span>
                            {{dataItem.waitPeriodLengthUnit?.name }}
                        </span>
                    </div>
                </ng-template>
            </kendo-grid-column>
        
            <kendo-grid-column 
                field="groupPlanEligibility"
                title="group Plan Eligibility" 
                [width]="200">
                <ng-template kendoGridCellTemplate let-dataItem >
                    <div class="grid-cell-vertical">
                        <span>
                            {{dataItem.groupPlanEligibility?.name }}
                        </span>
                    </div>
                </ng-template>
            </kendo-grid-column>
        
            <kendo-grid-column 
                field="deductionFrequency"
                title="deduction Frequency" 
                [width]="200">
                <ng-template kendoGridCellTemplate let-dataItem >
                    <div class="grid-cell-vertical">
                        <span>
                            {{dataItem.deductionFrequency?.name }}
                        </span>
                    </div>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid> -->
    </mat-card-content>
</mat-card>
