import { Component, OnInit, ViewChild } from '@angular/core';
import { routes } from '@app/consts';
import { EmployeeService } from '@app/core/services/employee.service';
import { DataBindingDirective, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { finalize } from 'rxjs/operators';
import { process } from "@progress/kendo-data-query";

@Component({
  selector: 'app-monthly-holiday-utilisation-data-grid',
  templateUrl: './monthly-holiday-utilisation-data-grid.component.html',
  styleUrls: ['./monthly-holiday-utilisation-data-grid.component.scss']
})
export class MonthlyHolidayUtilisationDataGridComponent implements OnInit {

  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public mySelection: string[] = [];
  public routes: typeof routes = routes;
  pageSize: number = 20;
  skip: number = 0;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  isLoading: boolean;
  selectedEmployee: any;

  constructor(
    private employeeService: EmployeeService
  ) { }

  ngOnInit(): void {
    this.view = this.employeeService.getEmployeesKendo();
    this.getEmployees();
  }

  getEmployees(): void {
    this.isLoading = true;

    this.employeeService.getEmployeesKendo(this.skip, String(this.pageSize))
    .pipe(
        finalize(()=>{ this.isLoading = false; })
    )
    .subscribe(
      res => {
        this.gridDataResult = {
          data: res.employees,
          total: res.pagination.TotalCount,
        }

        this.gridDataResult.data.forEach(
          employee => {
            employee.basicHolidayEntitlement = this.getRandomIntInclusive(26, 35);
            employee.holidayCarryoverEntitlement = this.getRandomIntInclusive(0, 5);
            employee.serviceAward = this.getRandomIntInclusive(0, 5);
            employee.otherAdjustments = this.getRandomIntInclusive(0, 5);
            employee.totalHolidaysCalc = (employee.basicHolidayEntitlement + employee.holidayCarryoverEntitlement + employee.serviceAward + employee.otherAdjustments);
            employee.totalHolidayTaken = this.getRandomIntInclusive(0, employee.totalHolidaysCalc);
            employee.totalHolidayBooked = this.getRandomIntInclusive(0, (employee.totalHolidaysCalc - employee.totalHolidayTaken));
            employee.basicHolidayRemaining = this.getRandomIntInclusive(0, (employee.totalHolidaysCalc - (employee.totalHolidayTaken + employee.totalHolidayBooked)));

            employee.basicHolidayRemaining < employee.holidayCarryoverEntitlement ? employee.carryoverRemaining = employee.basicHolidayRemaining : employee.carryoverRemaining = employee.holidayCarryoverEntitlement;

            employee.totalOutstandingRequests = this.getRandomIntInclusive(0, employee.basicHolidayRemaining);

            employee.TOILClaimed = this.getRandomIntInclusive(0, 5);
            employee.TOILUsed = this.getRandomIntInclusive(0, employee.TOILClaimed);
            employee.TOILAvailable = employee.TOILClaimed - employee.TOILUsed;
          }
        );

        this.gridData = res.employees;
        // this.selectedEmployee = this.gridDataResult.data[0];
      }
    )
  }

  getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.round(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
  }

  public onFilter(inputValue: string): void {
    this.view = process(this.gridData, {
      filter: {
        logic: "or",
        filters: [
          {
            field: "full_name",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "job_title",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "budget",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "phone",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "address",
            operator: "contains",
            value: inputValue,
          },
        ],
      },
    }).data;

    this.dataBinding.skip = 0;
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.getEmployees();
  }

}
