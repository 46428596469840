<div class="k-d-flex k-d-flex-row k-mb-3">
    <h5 mat-dialog-title *ngIf="toilPolicy">{{ 'EditATimeOffInLieuPolicy' | translate: {Default: "Edit a Time Off In Lieu Policy"} }}</h5>
    <h5 mat-dialog-title *ngIf="!toilPolicy">{{ 'CreateATimeOffInLieuPolicy' | translate: {Default: "Create a Time Off In Lieu Policy"} }}</h5>
    <div class="k-flex-grow"></div>
    <div class="buttons-container">
        <button mat-raised-button (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
        <button mat-raised-button setColor="primaryColour" color="primary" (click)="savePolicy()" [disabled]="!policyForm?.valid">{{ 'Save' | translate: {Default: "Save"} }}</button>
    </div>
</div>

<div class="form-section-title">
</div>

<div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white; width:100%">
    <mat-spinner></mat-spinner>
</div>

<div class="form-container" *ngIf="!isLoading" [formGroup]="policyForm">

    <div class="lookup-form-row">
        <mat-form-field appearance="fill">
            <mat-label>
                {{ 'TimeOffInLieuType' | translate: {Default: "Time Off In Lieu Type"} }}
            </mat-label>

            <mat-select disableOptionCentering formControlName="timeOffType" [disabled]="timeOffType">
                <mat-option *ngFor="let tot of timeOffTypeList" [value]="tot.id">{{tot.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <ng-container formArrayName="localizations">
        <ng-container *ngFor="let localizationForm of localizations.controls; let i = index">
            <div class="lookup-form-row" [formGroup]="localizationForm">
                <mat-form-field appearance="fill" class="k-mr-2">
                    <mat-label>
                        {{ 'SelectLanguage' | translate: {Default: "Select Language"} }}
                    </mat-label>

                        <mat-select disableOptionCentering formControlName="culture">
                            <mat-option *ngFor="let culture of cultures" [value]="culture.id">{{culture.text}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                <mat-form-field appearance="fill" class="k-mr-2">
                    <mat-label>
                        {{ 'ToilPolicyName' | translate: {Default: "TOIL Policy Name"} }}
                    </mat-label>

                    <input matInput
                           formControlName="text"
                           placeholder="Type Name">
                </mat-form-field>
                <mat-icon
                    setColor
                    [setColorRules]="[
                        {
                            color: 'primaryColour',
                            events: ['hover']
                        }
                    ]" 
                    class="material-icons-outlined k-pb-5" 
                    (click)="deleteLocalization(i)" 
                    matTooltip="Delete"
                >delete</mat-icon>

            </div>
        </ng-container>
    </ng-container>

    <div class="k-mb-3 k-mt-3">
        <button mat-raised-button (click)="addNewLocalization()" class="k-mr-2">
            <mat-icon class="material-icons-outlined">add</mat-icon>
            {{ 'AddTextLocalization' | translate: {Default: "Add Text Localization"} }}
        </button>
    </div>


    <div class="form-section-title">
        <h6>
            {{ 'AccrualOptions' | translate: {Default: "Accrual Options"} }}
        </h6>
    </div>
    <div class="lookup-form-row" style="justify-content: normal">
        <div class="k-mr-2">
            {{ 'MaximumAccrual' | translate: {Default: "Maximum Accrual"} }}
        </div>
        <mat-form-field appearance="fill">
            <mat-label>
                {{ 'Hours' | translate: {Default: "Hours"} }}
            </mat-label>

            <input matInput type="number" formControlName="maximumAccrualHours">
        </mat-form-field>
    </div>

    <div class="lookup-form-row">
        <mat-form-field appearance="fill" class="k-mr-2">
        <mat-label>
            {{ 'ExpiryPeriodLength' | translate: {Default: "Expiry Period Length"} }}
        </mat-label>

        <input matInput type="number" formControlName="expiryPeriodLength">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                {{ 'ExpiryPeriodUnits' | translate: {Default: "Expiry Period Units"} }}
            </mat-label>

            <mat-select disableOptionCentering formControlName="expiryPeriodUnit">
                <mat-option *ngFor="let toilExpiryPeriodUnit of toilExpiryPeriodUnitTypes" [value]="toilExpiryPeriodUnit.id">{{toilExpiryPeriodUnit.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="lookup-form-row">
        <mat-form-field appearance="fill">
            <mat-label>
                {{ 'ExpiryPoint' | translate: {Default: "Expiry Point"} }}
            </mat-label>

            <mat-select disableOptionCentering formControlName="expiryPoint">
                <mat-option *ngFor="let toilExpiryPoint of toilExpiryPointList" [value]="toilExpiryPoint.id">{{toilExpiryPoint.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="lookup-form-row">
        <mat-slide-toggle setColor="primaryColour" class="permit-negatives" formControlName="allowNegativeHours">
            {{ 'PermitNegativeNumbers' | translate: {Default: "Permit Negative Numbers"} }}
        </mat-slide-toggle>
    </div>
</div>
