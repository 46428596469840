import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { defer, forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EmploymentRecordInjuryIllness } from './models/employment-record-injury-illness.model';
import { EmploymentRecordInjuryIllnessService } from './services/employment-record-injury-illness.service';
import { EmploymentRecordInjuryIllnessDialogComponent } from './components/employment-record-injury-illness-dialog/employment-record-injury-illness-dialog.component';

@Component({
  selector: 'app-employment-record-injury-illness',
  templateUrl: './employment-record-injury-illness.component.html',
  styleUrls: ['./employment-record-injury-illness.component.scss']
})
export class EmploymentRecordInjuryIllnessComponent implements OnInit {
  @Input() employeeId: string;
  @Input() employmentRecordId: string;
  public columns: any[] = [
    { field: "occurrenceType", subField: "text", title: "occurrence Type", type: "navigation" },
    { field: "occurrenceCountry", subField: "text", title: "occurrence Country" },
    { field: "dateReported", title: "Date Reported", type: "date" },
    { field: "daysOffWork", title: "Days Off Work" },
    { field: "comments", title: "Comments" },
  ];
  public bindingType: String = "array";
  public view: Observable<GridDataResult>;
  public gridData: any;
  public gridDataResult: GridDataResult;
  isLoading: boolean;
  pageSize: number = 5;
  skip: number = 0;

  constructor(
    private dialog: MatDialog,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private employmentRecordInjuryIllnessService: EmploymentRecordInjuryIllnessService
  ) {}

  ngOnInit(): void {
    this.view = this.employmentRecordInjuryIllnessService.getInjuryIllnesss(this.employeeId, this.employmentRecordId);
    this.getInjuryIllnesss();
  }

  getInjuryIllnesss(): void {
    this.isLoading = true;

    this.employmentRecordInjuryIllnessService.getInjuryIllnesss(this.employeeId, this.employmentRecordId, this.skip, String(this.pageSize))
    .pipe(
        finalize(()=>{ this.isLoading = false; })
    )
    .subscribe(
      res => {
        this.gridDataResult = {
          data: res.injuryIllnesss,
          total: res.pagination.TotalCount,
        }
        this.gridData = res.employmentRecords;
      }
    )
  }

  openEmploymentRecordInjuryIllnessDialog(employmentRecordInjuryIllness?: EmploymentRecordInjuryIllness) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      employmentRecordId: this.employmentRecordId,
      employeeId: this.employeeId,
      InjuryIllnessId: employmentRecordInjuryIllness ? employmentRecordInjuryIllness.id : null
    };

    const dialogRef = this.dialog.open(EmploymentRecordInjuryIllnessDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.getInjuryIllnesss();
        }
      }
    );
  }

  deleteEmploymentRecordInjuryIllness(employmentRecordInjuryIllnessIds: string[]) {
    const observables = employmentRecordInjuryIllnessIds.map(selectedItem => defer(() => this.employmentRecordInjuryIllnessService.deleteInjuryIllness(this.employeeId, this.employmentRecordId, selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.getInjuryIllnesss();
        this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.getInjuryIllnesss();
  }

}
