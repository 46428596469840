<app-benefits>
    <app-data-grid-comp
        [columns]="columns"
        [bindingType]="bindingType"
        [view]="view"
        [gridData]="gridData"
        [gridDataResult]="gridDataResult"
        [pageSize]="pageSize"
        [skip]="skip"
        category="Benefits-Groups"
        searchable="true"
        [rowSelectionSettings]="{
            canSelectRows: true,
            selectableMode: 'multiple',
            selectRowBy: 'id'
        }"
        clickableRows="true"
        [isLoading]="loadingGroups"
        [clearSelectedItems]="clearSelectedItems"
        (emitPageChangeEvent)="pageChange($event)"
        (emitOpenDialogEvent)="navigateToGroupEditor($event)"
        (emitDeleteEvent)="deleteGroups($event)"
        (emitSearchEvent)="search($event)"
    ></app-data-grid-comp>
</app-benefits>
