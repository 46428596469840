<mat-card>
    <kendo-grid
        [data]="query | async"
        [loading]="isLoading"
        [skip]="state.skip"
        [pageSize]="state.take"
        scrollable="virtual"
        [rowHeight]="36"
        [height]="750"
        [sort]="sort"
        [sortable]="{
            mode: 'multiple'
        }"
        (sortChange)="sortChange($event)"
        (pageChange)="pageChange($event)"
        (excelExport)="onExcelExport($event)"
    >

        <ng-template kendoGridToolbarTemplate>
            <button class="export-to-excel-button" kendoGridExcelCommand>
                <img
                    class="ms-icon"
                    src="assets/icons/excel-icon.svg"
                    style="width: 35px;
                            height: 35px;
                            vertical-align: middle;">
                {{ 'ExportToExcel' | translate: {Default: "Export to Excel"} }}
            </button>

            <filter-control [categories]="filterCategories" (callback)="filterCallback($event)"></filter-control>

        </ng-template>


        <kendo-grid-column
            *ngFor="let col of columns"
            [field]="col.field"
            [title]="col.title"
            [width]="300"
        ></kendo-grid-column>

        <kendo-grid-excel
            fileName="EmployeeLoginReport.xlsx"
            [fetchData]="allData"
        ></kendo-grid-excel>
    </kendo-grid>
</mat-card>

