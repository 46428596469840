<mat-card>
    <mat-card-title >
      <h5>Global Talent Overview</h5>
  
      <app-settings-menu></app-settings-menu>
    </mat-card-title>
  
    <mat-card-content fxLayout="row" fxLayoutAlign="space-around start" >
        <div>
            <div class="block">
                <div class="label">Top Talent</div>
                <div class="value">284</div>
            </div>
            <div class="block">
                <div class="label">Top Talent %</div>
                <div class="value">12.4%</div>
            </div>
            <div class="block">
                <div class="label">Top Performers</div>
                <div class="value">697</div>
            </div>
            <div class="block">
                <div class="label">Top Performers %</div>
                <div class="value">30.5%</div>
            </div>
        </div>

        <div>
            <div class="block">
                <div class="label">Global Assignments Previous Year</div>
                <div class="value">352</div>
            </div>
            <div class="block">
                <div class="label">Reduction</div>
                <div class="value">66</div>
            </div>
            <div class="block">
                <div class="label">Global Assignments YTD</div>
                <div class="value">286</div>
            </div>
        </div>
    </mat-card-content>
</mat-card>