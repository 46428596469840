import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TranslationService } from '@app/core/services/translation.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-new-translation-dialog',
  templateUrl: './new-translation-dialog.component.html',
  styleUrls: ['./new-translation-dialog.component.scss']
})
export class NewTranslationDialogComponent implements OnInit {
  form: UntypedFormGroup;
  
  constructor(
    private fb: UntypedFormBuilder,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private translationService: TranslationService,
    private dialogRef: MatDialogRef<NewTranslationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data)
  {
    this.form = this.fb.group({
      id: [data?.systemId ?? null, Validators.required]
    });
  } 

  ngOnInit(): void {}

  save(){
    this.overlayService.show();

    this.translationService.createNewTranslationCode(this.form.value)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar('Translation code created successfully', 'clear', 'success');
        this.dialogRef.close(true);
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  close(){
    this.dialogRef.close();
  }

}

