<div class="container">
    <mat-card>
        <mat-card-content>
            <div class="top">
                <div class="employee-name">
                    <app-employee-image [employeeId]="globalGoalPlanReview.reviewer?.id" [width]="35" [height]="35" [round]="true"></app-employee-image>
        
                    <span class="employee">
                        {{globalGoalPlanReview.reviewer?.firstName}} {{globalGoalPlanReview.reviewer?.lastName}}
                    </span>
        
                    <mat-chip-list>
                        <mat-chip 
                            [ngClass]="{
                                'red-chip': globalGoalPlanReview.status?.id === 'Archived',
                                'green-chip': globalGoalPlanReview.status?.id === 'Complete',
                                'orange-chip': globalGoalPlanReview.status?.id === 'InProgress',
                                'blue-chip': globalGoalPlanReview.status?.id === 'Pending'
                            }"
                        >{{ globalGoalPlanReview.status?.name }}</mat-chip>
                    </mat-chip-list>
                </div>

                <button 
                    mat-icon-button [matMenuTriggerFor]="menu" class="actions-btn"
                    *ngIf="
                        (user$.userId === globalGoalPlanReview.reviewer?.id && globalGoalPlanReview?.status?.id !== 'Complete') || 
                        (user$.userId === globalGoalPlanReview.reviewer?.id && globalGoalPlanReview?.status?.id !== 'Complete') ||
                        (isOwner && globalGoalPlanReview?.status?.id === 'Complete')
                    "
                >
                    <mat-icon class="material-icons-outlined">more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button *ngIf="user$.userId === globalGoalPlanReview.reviewer?.id && globalGoalPlanReview?.status?.id !== 'Complete'" mat-menu-item appPreventDoubleClick (throttledClick)="showForm = true">
                        <mat-icon class="material-icons-outlined">edit</mat-icon> 
                        <span>Edit</span>
                    </button>
                    <ng-container *ngIf="user$.userId === globalGoalPlanReview.reviewer?.id">
                        <button *ngIf="globalGoalPlanReview?.status?.id !== 'Complete'" class="green-button" mat-menu-item appPreventDoubleClick (throttledClick)="complete()">
                            <mat-icon class="material-icons-outlined">check_circle</mat-icon>
                            {{ 'Complete' | translate: {Default: "Complete"} }}
                        </button>
                    </ng-container>
                    <ng-container *ngIf="isOwner">
                        <button *ngIf="globalGoalPlanReview?.status?.id === 'Complete'" class="blue-button" mat-menu-item appPreventDoubleClick (throttledClick)="revert()">
                            <mat-icon class="material-icons-outlined">undo</mat-icon>
                            {{ 'Revert' | translate: {Default: "Revert"} }}
                        </button>
                    </ng-container>
                </mat-menu> 
            </div>
        
            <div *ngIf="user$.userId === globalGoalPlanReview.reviewer?.id && showForm; else showDetails">
                <form *ngIf="reviewRatingForm" [formGroup]="reviewRatingForm">
                    <mat-form-field appearance="fill">
                      <mat-label>{{ 'ReviewRating' | translate: {Default: 'Rating'} }}</mat-label>
                      <mat-select disableOptionCentering formControlName="rating">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let reviewRatingOption of reviewRatingOptions" [value]="reviewRatingOption?.rating.id">
                          {{reviewRatingOption?.rating?.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
        
                    <mat-form-field appearance="fill">
                      <mat-label>{{ 'ReviewComments' | translate: {Default: 'Comments'} }}</mat-label>
                      <textarea
                        matInput
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="20"
                        cdkAutosizeMaxRows="30"
                        formControlName="comments">
                      </textarea>
                    </mat-form-field>
        
                    <div class="buttons-container">
                        <button
                            mat-raised-button
                            appPreventDoubleClick
                            (throttledClick)="showForm = false"
                        >{{ 'Cancel' | translate: {Default: "Cancel"} }}</button>
        
                        <button
                            mat-raised-button
                            color="primary"
                            setColor="primaryColour"
                            appPreventDoubleClick
                            (throttledClick)="save()"
                            [disabled]="!reviewRatingForm.valid"
                        >{{ 'Save' | translate: {Default: "Save"} }}</button>
                    </div>
                </form>
            </div>
            <ng-template #showDetails>
                <ng-container *ngIf="globalGoalPlanReview?.rating === null && globalGoalPlanReview?.comments === null; else showReview">
                    <div>{{ 'NoReview' | translate: {Default: "No Review"} }}</div>
                </ng-container>
                <ng-template #showReview>
                    <div>
                        <div class="label">{{ 'ReviewRating' | translate: {Default: 'Rating'} }}</div>
                        <div class="value">{{globalGoalPlanReview.rating?.name}}</div>
                    </div>
                    <div>
                        <div class="label">{{ 'ReviewComments' | translate: {Default: 'Comments'} }}</div>
                        <div class="value">{{globalGoalPlanReview?.comments}}</div>
                    </div>
                </ng-template>
            </ng-template>
        </mat-card-content>
    </mat-card>
    
</div>
