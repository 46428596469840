import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { routes } from '@app/consts';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-employee-security',
  templateUrl: './employee-security.component.html',
  styleUrls: ['./employee-security.component.scss']
})
export class EmployeeSecurityComponent implements OnInit {
  navList = [
    {
      text: 'EmployeeSecurity-ResetPassword',
      route: 'Password'
    },
    {
      text: 'EmployeeSecurity-OrganizationalAccess',
      route: 'Organization'
    },
    {
      text: 'EmployeeSecurity-LoginHistory',
      route: 'LoginHistory'
    },
    {
      text: 'EmployeeSecurity-SecurityRoles',
      route: 'SecurityRoles'
    },
    {
      text: 'EmployeeSecurity-DeleteEmployee',
      route: 'DeleteEmployee'
    },
  ]

  selectedComponent: string;
  employeeId: string;
  section: string;

  constructor(
    public translate: TranslateService,
    private route: ActivatedRoute,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.employeeId = this.getIdInURL();
    this.section = this.getSectionInURL();
  }

  getIdInURL(): string {
    let IdInURL: string;
    
    this.route.paramMap.subscribe(
      params => IdInURL = params.get("id")
    )
      
    return IdInURL;
  }

  getSectionInURL(): string {
    let sectionInURL: string;
    
    this.route.paramMap.subscribe(
      params => sectionInURL = params.get("section")
    )
      
    return sectionInURL;
  }

  navigateToSection(section: string) {
    this.router.navigateByUrl(`${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_SECURITY}/${section}`);
    this.section = section;
  }

}
