import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SecurityRoleService } from '@app/modules/security-roles/services/security-role.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'add-user-dialog',
    templateUrl: './add-user-dialog.component.html',
    styleUrls: ['./add-user-dialog.component.scss']
})
export class AddUserDialogComponent implements OnInit {

    public gridDataResult: GridDataResult;
    public isLoading: boolean;
    public pageSize: number = 20;
    public skip: number = 0;
    public searchFilterString: string;
    public searchValue: string;
    public selectedItems: any[] = [];
    private getUsersRequest: any;

    public columns: any[] = [
        {field: 'firstname', title: 'First name'},
        {field: 'lastname', title: 'Last name'},
        {field: 'title', subField: 'text', title: 'Title'},
        {field: 'country', subField: 'text', title: 'Country'},
        {field: 'email', title: 'Email'},
        {field: 'workPhone', title: 'workPhone'},
        {field: 'cellPhone', title: 'cellPhone'},
    ];

    constructor(
        private dialog: MatDialog,
        private overlayService: OverlayService,
        private dialogRef: MatDialogRef<AddUserDialogComponent>,
        private securityRoleService: SecurityRoleService,
        @Inject(MAT_DIALOG_DATA) data
    ) {

    }

    ngOnInit(): void {
        this.getAllUsers();
    }

    private getAllUsers() {
        this.isLoading = true;

        this.getUsersRequest = this.securityRoleService.getAllUsers(this.skip, String(this.pageSize), this.searchFilterString)
            .subscribe(pagedUsers => {
                console.log(pagedUsers);
                this.gridDataResult = {
                    data: pagedUsers.data,
                    total: pagedUsers.total,
                };
                this.isLoading = false;
            });
    }

    close() {
        this.dialogRef.close();
    }


    pageChange(event: PageChangeEvent) {
        this.skip = event.skip;
        this.pageSize = event.take;
        this.getAllUsers();
    }

    openConfirmAddUsersDialog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            text: `Are you sure you want to add ${this.selectedItems.length} selected User(s)?`
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data === true) {
                    // let users = [];
                    // this.selectedItems.forEach(userId => this.gridDataResult.data.find(user => {if (user.id === userId){ users.push(user) }}))
                    this.dialogRef.close({selectedItems: this.selectedItems})
                }
            });
    }

    search(){
        this.searchFilterString = `(FirstName like "${this.searchValue}") OR (LastName like "${this.searchValue}")`;
        this.getUsersRequest.unsubscribe();
        this.getAllUsers()
    }

}
