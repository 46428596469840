<div>
    <div class="title">
        <h3>
            {{data.title}}
        </h3>
    </div>
    <div class="text">
        <p>
            {{data.text}}
        </p>
    </div>
</div>


