import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EmploymentRecordCompensation, EmploymentRecordCompensationSubmit } from '../models/employment-record-compensation.model';
import { EnvironmentService } from "@app/core/services/environment.service";


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});
@Injectable({
  providedIn: 'root'
})
export class EmploymentRecordCompensationService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getCompensations(employeeId: string, employmentRecordId: string, skip?: number, take?: string): Observable<any> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http
      .get<any>(this.envService.env.apiUrl + `Employee/${employeeId}/EmploymentRecord/${employmentRecordId}/Compensation`, {
        headers: headers,
        observe: 'response',
        params: params
      })
      .pipe(
        catchError(err => throwError(err)),
        map(res => {
          let data = {
            compensations: res.body,
            pagination: JSON.parse(res.headers.get('X-Pagination'))
          }

          return data;
        })
      );
  }

  getCompensation(employeeId: string, employmentRecordId: string, compensationId: string): Observable<EmploymentRecordCompensation> {
    return this.http.get<any>(this.envService.env.apiUrl + `Employee/${employeeId}/EmploymentRecord/${employmentRecordId}/Compensation/${compensationId}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  postCompensation(employeeId: string, employmentRecordId: string, compensation: EmploymentRecordCompensationSubmit): Observable<EmploymentRecordCompensation> {
    return this.http.post<any>(this.envService.env.apiUrl + `Employee/${employeeId}/EmploymentRecord/${employmentRecordId}/Compensation`, compensation, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  deleteCompensation(employeeId: string, employmentRecordId: string, compensationId: string): Observable<any> {
    return this.http.delete<any>(this.envService.env.apiUrl + `Employee/${employeeId}/EmploymentRecord/${employmentRecordId}/Compensation/${compensationId}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
}

