<app-site-settings-menu>
    <div class="main-container" *ngIf="hasFeatureAccess">
        <div 
            class="loading-container"
            *ngIf="isLoading">
            <ngx-skeleton-loader
                count="10"
                [theme]="{ 
                    'height.px': 50
                }"
            ></ngx-skeleton-loader>
        </div>

        <div class="left" *ngIf="!isLoading">
            <mat-form-field class="search-field" appearance="outline" >
                <input matInput
                       [(ngModel)]="tableListSearchValue"
                       placeholder="Search...">
                <mat-icon matPrefix class="material-icons-outlined">
                    search
                </mat-icon>
            </mat-form-field>

            <div class="tables-list-container">
                <ng-container *ngIf="loadingTableList; else showList">
                    <ngx-skeleton-loader
                        count="10"
                        [theme]="{ 
                            'height.px': 50
                        }"
                    ></ngx-skeleton-loader>
                </ng-container>
                <ng-template #showList>
                    <mat-list>
                        <ng-container *ngFor="let tableList of tableLists | lookupListSearch:tableListSearchValue">
                            <div mat-subheader>{{tableList.name}}</div>
                            <mat-list-item 
                                *ngFor="let item of tableList.fields" 
                                (click)="selectedTableListField = item" 
                                [ngClass]="{'selected': selectedTableListField === item }"
                            >
                                {{item.name}}
                            </mat-list-item>
                            <mat-divider></mat-divider>
                        </ng-container>
                    </mat-list>
                </ng-template>
            </div>
        </div>
        <div class="right" *ngIf="!isLoading">
            <app-lookup-list *ngIf="selectedTableListField" [tableList]="tableLists" [tableListField]="selectedTableListField"></app-lookup-list>
        </div>
    </div>

    <no-feature-access *ngIf="!hasFeatureAccess"></no-feature-access>
</app-site-settings-menu>
