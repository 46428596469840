import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { BenefitsCoverageVerbose } from '../../models/benefits-coverage.model';
import { BenefitsCoverageService } from '../../services/benefits-coverage.service';

@Component({
  selector: 'app-benefit-coverage-dialog',
  templateUrl: './benefit-coverage-dialog.component.html',
  styleUrls: ['./benefit-coverage-dialog.component.scss']
})
export class BenefitCoverageDialogComponent implements OnInit {
  form: UntypedFormGroup;
  coverageId: string;
  cultures: Culture[];
  loading: boolean;
  coverage: BenefitsCoverageVerbose;

  constructor(
    private snackbarService: SnackbarService,
    private fb: UntypedFormBuilder,
    private benefitsCoverageService: BenefitsCoverageService,
    private overlayService: OverlayService,
    private cultureService: CultureService,
    private dialogRef: MatDialogRef<BenefitCoverageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      this.coverageId = data.coverageId;
    }

  ngOnInit(): void {
    this.cultureService.getCultures().subscribe( res => { this.cultures = res } );

    if(this.coverageId !== null){
      this.getCoverage() 
    }
    else {
      this.loading = false; 
      this.createForm();
    }
  }

  getCoverage() {
    this.loading = true;

    this.benefitsCoverageService.getCoverage(this.coverageId)
    .pipe(
      finalize(() => this.loading = false)
    )
    .subscribe(
      res => {
        this.coverage = res;
        this.createForm();
      }
    )
  }

  createForm() {
    this.form = this.fb.group({
      id: [this.coverage ? this.coverage.id : null],
      asOf: [moment().format()],
      name: this.fb.array([]),
      changeReason: [''],
      changeReasonComments: [''],
    });

    if(this.coverageId){
      this.coverage.name.forEach(
        localization => {
          this.addNewLocalization(localization.culture, localization.text);
        }
      );
    }
    else {
      this.addNewLocalization();
    }

  }

  get localizations() {
    return this.form.controls["name"] as UntypedFormArray;
  }

  //Create a textLocalization form group object to add to the localizations form array
  addNewLocalization(culture?: string, text?: string) {
    const localizationForm = this.fb.group({
      culture: [culture || '', Validators.required],
      text: [text || '', Validators.required]
    });

    this.localizations.push(localizationForm);
  }

  deleteLocalization(index: number) {
    this.localizations.removeAt(index);
  }

  save() {
    this.overlayService.show();

    if(this.coverageId){
      this.benefitsCoverageService.updateCoverage(this.form.value)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.dialogRef.close(true);
          this.snackbarService.openSnackBar('Coverage updated successfully', 'clear', 'success');
        }
      );
    }
    else {
      this.benefitsCoverageService.createCoverage(this.form.value)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.dialogRef.close(true);
          this.snackbarService.openSnackBar('Coverage created successfully', 'clear', 'success');
        }
      );
    }
  }

  close() {
    this.dialogRef.close();
  }

  

}
