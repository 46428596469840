import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { routes } from '@app/consts';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { BusMessage } from '@app/shared/models/bus/bus.model';
import { Observable } from 'rxjs';
import { EnvironmentService } from "@app/core/services/environment.service";

const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class BusService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getBusMessages(skip?: number, take?: string, filterString?: string, sortString?: string): Observable<PagedData<BusMessage>> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    filterString ? params = params.append('Filter', filterString) : null;
    sortString ? params = params.append('Sort', sortString) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${routes.BUS}${routes.MESSAGES}`, {
      headers: headers,
      params: params
    });
  }

  getBusMessage(messageId: string): Observable<BusMessage> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${routes.BUS}${routes.MESSAGES}/${messageId}`, {
      headers: headers
    });
  }
}
