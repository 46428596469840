import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { ModuleConfigurationService } from '@app/core/services/module-configuration/module-configuration.service';

@Directive({
  selector: '[appHasModuleAccess]'
})
export class HasModuleAccessDirective {
  @Input() appHasModuleAccess: string;
  private hasView = false;

  constructor(
    private moduleConfigurationService: ModuleConfigurationService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  ngOnInit() {
    let hasAccess = this.hasModuleAccess(this.appHasModuleAccess);

    if (hasAccess && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!hasAccess && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  public hasModuleAccess(moduleCode: string | string[]) {
    if(typeof moduleCode === 'string') {
      return this.moduleConfigurationService.hasModuleAccess(moduleCode);
    }
    else {
      let hasAccess = false;

      for(let module of moduleCode){
          if(this.moduleConfigurationService.hasModuleAccess(module)){
              hasAccess = true;
              break;
          }
      }

      return hasAccess;
    }
  }
}
