import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { EmployeeGoalSubmit, GoalSubmit, GoalVerbose } from '../goal-plans/models/goal-plan.model';
import { GoalPlanService } from '../goal-plans/services/goal-plan.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GoalTypeService } from '../goal-types/services/goal-type.service';
import { Observable } from 'rxjs';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { GoalType } from '../goal-types/models/goal-type.model';

@Component({
  selector: 'app-personal-goal-dialog',
  templateUrl: './personal-goal-dialog.component.html',
  styleUrls: ['./personal-goal-dialog.component.scss']
})
export class PersonalGoalDialogComponent implements OnInit {
  goal: GoalVerbose;
  employeeId: string;
  readOnly: boolean;
  checkingIfParent: boolean;
  isAParent: boolean;
  selectedParentId: string;
  goalPlan: { id: string, name: string };
  goalHasBeenSaved: boolean = false;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private translate: TranslateService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    public router: Router,
    public dialogRef: MatDialogRef<PersonalGoalDialogComponent>,
    private goalTypeService: GoalTypeService,
    private goalService: GoalPlanService,
    @Inject(MAT_DIALOG_DATA) data
  ){
    this.goal = data.goal;
    this.goalPlan = data.goalPlan;
    this.employeeId = data.employeeId;
    this.readOnly = data.readOnly;
    this.selectedParentId = this.goal?.parent?.id || data.parentId;
  }

  ngOnInit(): void {
    this.checkIfIsAParent();
  }
  

  checkIfIsAParent() {
    if(this.goal !== undefined) {
      this.checkingIfParent = true;
  
      let filter = `(parent.id = "${this.goal.id}")`;
  
      this.goalService.getEmployeeGoals(this.employeeId, '20', 0, null, filter)
        .pipe(
          finalize( () => {
            this.checkingIfParent = false;
          })
        )
        .subscribe(
          (res) => {
            if(res.employeeGoals.data.length > 0){
              this.isAParent = true;
            }
          }
        );
    }
    else {
      this.checkingIfParent = false;
    }
  }

  saveComplete() {
    if(this.goal) {
      this.goalHasBeenSaved = true;
    }
    else {
      this.dialogRef.close(true);
    }
  }
  

  close() {
    this.dialogRef.close(this.goalHasBeenSaved);
  }

}

