import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-basic-employee-positions-data-grid',
  templateUrl: './basic-employee-positions-data-grid.component.html',
  styleUrls: ['./basic-employee-positions-data-grid.component.scss']
})
export class BasicEmployeePositionsDataGridComponent implements OnInit {
  @Input() public positions: any;

  public columns: any[] = [
    // { field: "employee['id']", subField:"id", title: "ID" },
    { field: "name", title: "name" },
    { field: "clientPositionId", title: "Client Position Id" },
    { field: "employmentType", subField:"text", title: "Employment Type" },
    { field: "workRotation", subField:"name", title: "Work Rotation" },
    { field: "startDate", title: "Start Date", type: 'date' },
    { field: "fixedTermEndDate", title: "Fixed Term End Date", type: 'date' },
  ]

  constructor() { }

  ngOnInit(): void {
    console.log(this.positions)
  }

}
