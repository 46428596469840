import { Component, OnInit } from '@angular/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { finalize } from 'rxjs/operators';
import { EmployeeContactCardService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/services/employee-contact-card.service'
import { PositionsService } from '@app/modules/positions/services/positions.service';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import { TableListFieldOption } from '@app/modules/lookup/models/lookup.model';
import {
    EmploymentRecordPositionsService
} from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-positions/services/employment-record-positions.service'
import { from } from 'rxjs';

@Component({
  selector: 'app-assign-positions-to-all',
  templateUrl: './assign-positions-to-all.component.html',
  styleUrls: ['./assign-positions-to-all.component.scss']
})
export class AssignPositionsToAllComponent implements OnInit {
  numberOfItems: number = 20;
  employees: any = [];
  positions: any = [];
  loadingEmployees: boolean = true;
  loadingPositions: boolean;
  startReasons: TableListFieldOption[];
  loadingStartReasons: boolean;
  currentEmployee: any;
  currentEmployeeIndex: any;
  assigningPositions: boolean;
  numberOfAssigned: number = 0;

  constructor(
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private employeeContactCardService: EmployeeContactCardService,
    private positionsService: PositionsService,
    private employmentRecordPositionsService: EmploymentRecordPositionsService,
    private lookupService: LookupService
  ) { }

  ngOnInit(): void {
    this.getAllEmployees();
    this.getAllPositions();
    this.getStartReasons();
  }

  isLoading(): boolean {
    if(!this.loadingEmployees && !this.loadingPositions && !this.loadingStartReasons){
      return false;
    }
    else {
      return true;
    }
  }

  getProgress(): number {
    return ((this.numberOfAssigned / this.employees.length) * (100/1))
  }

  getAllEmployees() {
    this.loadingEmployees = true;

    this.employeeContactCardService.getAllEmployeeContactCards(0, '10000', undefined, undefined)
    .pipe(
      finalize( () => {
        this.loadingEmployees = false;
      })
    )
    .subscribe(
      res => {
        this.employees = res.data;
      }
    )
  }

  getAllPositions() {
    this.loadingPositions = true;

    this.positionsService.getPositions(0, '10000')
    .pipe(
      finalize( () => {
        this.loadingPositions = false;
      })
    )
    .subscribe(
      res => {
        this.positions = res.data;
      }
    )
  }

  getStartReasons() {
    this.loadingStartReasons = true;

    from(this.lookupService.getListOptions('POSITION_EMPLOYEE_START_REASON'))
    .pipe(
      finalize( () => {
        this.loadingStartReasons = false;
      })
    )
    .subscribe(pagedData => {
        this.startReasons = pagedData.data;
    });
  }

  createRandomString(length: number): string {
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, length);
  }

  random_item(items){
    return items[Math.floor(Math.random()*items.length)];
  }

  getNewAssignPosition(employeeId: string): any {
    return {
      employeeId: employeeId,
      positionId: this.random_item(this.positions).id,
      startReason: this.random_item(this.startReasons).id,
      startDate: '2022-05-26',
    };
  }

  createItems() {
    for (let i = 0; i < this.employees.length; i++) {
      this.employmentRecordPositionsService.assignEmployeeToPosition(this.getNewAssignPosition(this.employees[i].id))
      .subscribe(
        (res) => {
            this.snackbarService.openSnackBar(`Position number ${i+1} assigned`, 'clear', 'success');
            this.numberOfAssigned = this.numberOfAssigned + 1;
        }
      );
    }
  }
}
