<div class="main-container">
    <div class="top">
        <div *appTableAccess="[tableCreateAccessObj, tableUpdateAccessObj]">
            <mat-icon class="settings-button__icon material-icons-outlined"></mat-icon>

        </div>

        <div class="title">
            <h5>{{employeeTimeOffType.timeOffType?.name}}</h5>
            <h6>{{employeeTimeOffType.timeOffPolicy?.name}}</h6>
        </div>
        
        <div class="admin-controls" *appTableAccess="[tableCreateAccessObj, tableUpdateAccessObj, tableDeleteAccessObj]">
            <button class="settings-button" mat-mini-fab [matMenuTriggerFor]="settings">
                <mat-icon class="settings-button__icon material-icons-outlined">more_vert</mat-icon>
            </button>
            <div class="settings-menu">
                <mat-menu #settings="matMenu" xPosition="before">
                    <button class="settings-menu__item material-icons-outlined" appPreventDoubleClick (throttledClick)="openAssignPolicyDialog()" mat-menu-item *appTableAccess="[tableUpdateAccessObj]" appLocalizationPopupDirective localizationCode="EmployeeTimeOffTypeEdit">
                        <mat-icon class="settings-button__icon material-icons-outlined">edit</mat-icon>
                        {{ 'EmployeeTimeOffTypeEdit' | translate: {Default: "Edit"} }}
                    </button>
                    <button class="settings-menu__item material-icons-outlined" appPreventDoubleClick (throttledClick)="openConfirmDeleteDialog()" mat-menu-item *appTableAccess="[tableDeleteAccessObj]" appLocalizationPopupDirective localizationCode="EmployeeTimeOffTypeDelete">
                        <mat-icon class="settings-button__icon material-icons-outlined">delete</mat-icon>
                        {{ 'EmployeeTimeOffTypeDelete' | translate: {Default: "Delete"} }}
                    </button>
                    <button class="settings-menu__item material-icons-outlined" mat-menu-item appPreventDoubleClick (throttledClick)="openModifyBalanceFormDialog()" *appTableAccess="[tableUpdateAccessObj]" appLocalizationPopupDirective localizationCode="EmployeeTimeOffTypeModifyBalance">
                        <mat-icon class="settings-button__icon material-icons-outlined">settings</mat-icon>
                        {{ 'EmployeeTimeOffTypeModifyBalance' | translate: {Default: "Modify Balance"} }}
                    </button>
                    <button class="settings-menu__item material-icons-outlined" mat-menu-item appPreventDoubleClick (throttledClick)="openModifyBalanceHistoryDialog()" *appTableAccess="[tableUpdateAccessObj]" appLocalizationPopupDirective localizationCode="EmployeeTimeOffTypeModifyBalanceHistory">
                        <mat-icon class="settings-button__icon material-icons-outlined">history</mat-icon>
                        {{ 'EmployeeTimeOffTypeModifyBalanceHistory' | translate: {Default: "Modify Balance History"} }}
                    </button>
                    <button class="settings-menu__item material-icons-outlined" mat-menu-item appPreventDoubleClick (throttledClick)="openCalculationLogDialog()" *appTableAccess="[tableUpdateAccessObj]" appLocalizationPopupDirective localizationCode="EmployeeTimeOffTypeCalculationLog">
                        <mat-icon class="settings-button__icon material-icons-outlined">calculate</mat-icon>
                        {{ 'EmployeeTimeOffTypeCalculationLog' | translate: {Default: "Calculation Log"} }}
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
    <div class="balance-container">
        <div class="label">
            <h6 appLocalizationPopupDirective localizationCode="EmployeeTimeOffTypeBalance">
                {{ 'EmployeeTimeOffTypeBalance' | translate: {Default: "Balance"} }}
            </h6>
        </div>
        <div setColor="primaryColour" class="balance">
            <ng-container *ngIf="employeeTimeOffType.availableHours; else noAvailableHours">
                <h3>{{employeeTimeOffType.availableHours | number:'1.2-2' }}</h3>
            </ng-container>
            <ng-template #noAvailableHours>
                <h3>0</h3>
            </ng-template>
        </div>
        <div class="hours">
            <h6 appLocalizationPopupDirective localizationCode="EmployeeTimeOffTypeHours">
                {{ 'Hours' | translate: {Default: "Hours"} }}
            </h6>
        </div>
    </div>

    <div class="carry-over-details">
        <div class="row" *ngIf="employeeTimeOffType.timeOffPolicy?.carryOverAmount !== null">
            <span appLocalizationPopupDirective localizationCode="EmployeeTimeOffTypeCarryOverAmount">
                {{ 'CarryOverAmount' | translate: {Default: "Carry Over Amount"} }} : 
            </span>
            <span>
                {{employeeTimeOffType.timeOffPolicy?.carryOverAmount}} 
            </span>
            <span appLocalizationPopupDirective localizationCode="EmployeeTimeOffTypeHours">
                {{  'EmployeeTimeOffTypeHours' | translate: {Default: "Hours"} }}
            </span>
        </div>
        <div class="row" *ngIf="employeeTimeOffType.timeOffPolicy?.carryOverDay !== null && employeeTimeOffType.timeOffPolicy?.carryOverMonth !== null" appLocalizationPopupDirective localizationCode="EmployeeTimeOffTypeCarryOverDate">
            {{ 'EmployeeTimeOffTypeCarryOverDate' | translate: {Default: "Carry Over Date"} }} : {{employeeTimeOffType.timeOffPolicy?.carryOverDay}}/{{employeeTimeOffType.timeOffPolicy?.carryOverMonth}}
        </div>
    </div>

    <!-- <div class="buttons-container">
        <button mat-stroked-button color="primary" appPreventDoubleClick (throttledClick)="openLeaveRequestDialog()">
            {{ 'EmployeeTimeOffTypeRequestTimeOff' | translate: {Default: "Request Time Off"} }}
        </button>
    </div> -->
</div>