<mat-card class="group-plan-card">
    <div class="card-header">
        <h6 class="name navigation-link" *ngIf="groupPlan.plan?.name" matTooltip="Edit Group Plan" (click)="emitOpenGroupPlanDialog.emit(groupPlan.plan.id)">{{groupPlan.plan.name}}</h6>

        <div>
            <button mat-stroked-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openGroupPlanCoverageDialog()">
                <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> 
                {{ 'CreateNewCoverage' | translate: {Default: "Create New Coverage"} }}
            </button>
            <mat-icon class="material-icons-outlined" matTooltip="Delete Group Plan" (click)="emitOpenDeletePlanDialog.emit(groupPlan)">delete</mat-icon> 
        </div>
    </div>

    <mat-card-content>
        <div class="coverage-container" *ngIf="groupPlanCoverages$ | withLoading | async as groupPlanCoverages">
            <ng-template [ngIf]="groupPlanCoverages.value">
                <div class="list-container">
                    <ng-container *ngIf="groupPlanCoverages.value.length > 0; else noGroupPlanCoverages">
                        <mat-list>
                            <mat-list-item>
                                <div class="list-item-content list-header-row">
                                    <div>{{ 'GroupPlanCoverage' | translate: {Default: "Coverage"} }}</div>
                                    <div>{{ 'GroupPlanCoverageDeductionFrequency' | translate: {Default: "Deduction Frequency"} }}</div>
                                    <div>{{ 'GroupPlanCoverageEmployeeContributionAmount' | translate: {Default: "Employee Contribution Amount"} }}</div>
                                    <div>{{ 'GroupPlanCoverageEmployeeContributionPercentage' | translate: {Default: "Employee Contribution Percentage"} }}</div>
                                    <div>{{ 'GroupPlanCoverageEmployerContributionAmount' | translate: {Default: "Employer Contribution Amount"} }}</div>
                                    <div>{{ 'GroupPlanCoverageEmployerContributionPercentage' | translate: {Default: "Employer Contribution Percentage"} }}</div>
                                    <div></div>
                                </div>
                            </mat-list-item>
    
                            <mat-list-item *ngFor="let groupPlanCoverage of groupPlanCoverages.value" [value]="groupPlanCoverage">
                                <div class="list-item-content">
                                    <div class="coverageName">
                                        {{groupPlanCoverage.coverage.name}}
                                    </div>
    
                                    <div class="frequency">
                                        {{groupPlanCoverage.deductionFrequency.name}}
                                    </div>
    
                                    <div class="employeeContributionAmount">
                                        {{groupPlanCoverage.employeeContributionAmount}}
                                    </div>
    
                                    <div class="employeeContributionPercentage">
                                        {{groupPlanCoverage.employeeContributionPercentage}} %
                                    </div>
    
                                    <div class="employerContributionAmount">
                                        {{groupPlanCoverage.employerContributionAmount}}
                                    </div>
    
                                    <div class="employerContributionPercentage">
                                        {{groupPlanCoverage.employerContributionPercentage}} %
                                    </div>
    
                                    <div class="groupPlanCoverage-action-buttons">
                                        <mat-icon class="material-icons-outlined" matTooltip="Edit" appPreventDoubleClick (throttledClick)="openGroupPlanCoverageDialog(groupPlanCoverage)">edit</mat-icon> 
                                        <mat-icon class="material-icons-outlined" matTooltip="Delete" appPreventDoubleClick (throttledClick)="openConfirmDeleteDialog(groupPlanCoverage)">delete</mat-icon> 
                                    </div>
                                </div>
                            </mat-list-item>
                        </mat-list>
                    </ng-container>
                    <ng-template #noGroupPlanCoverages>
                        <div class="no-group-plan-coverages">
                            No Group Plan Coverages
                        </div>
                    </ng-template>
                </div>
            </ng-template>
            <ng-template [ngIf]="groupPlanCoverages.error">Error {{ obs.error }}</ng-template>
            <ng-template [ngIf]="groupPlanCoverages.loading">
                <ngx-skeleton-loader
                    count="5"
                    [theme]="{ 
                        'height.px': 50
                    }"
                ></ngx-skeleton-loader>
            </ng-template>
        </div>
    </mat-card-content>
</mat-card>