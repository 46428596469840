import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { User } from '@app/shared/models/user';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { routes } from '@app/consts';
import {EnvironmentService} from "@app/core/services/environment.service";
import { CompanyLogosService } from '@app/modules/site-settings-styling/components/company-logos/services/company-logos.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  @Input() isMenuOpened: boolean;
  @Output() isShowSidebar = new EventEmitter<boolean>();
  public user$: any;
  public routers: typeof routes = routes;

  constructor(
    private router: Router,
    private oidcAuthService: OidcAuthService,
    public environmentService: EnvironmentService,
    public companyLogosService: CompanyLogosService
  ) {
    this.user$ = this.oidcAuthService.userProfile;
  }

  ngOnInit(): void {
  }

  public openMenu(): void {
    this.isMenuOpened = !this.isMenuOpened;

    this.isShowSidebar.emit(this.isMenuOpened);
  }

  public signOut(): void {
    this.oidcAuthService.signout();
  }

}
