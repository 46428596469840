<h5 mat-dialog-title>Edit User Security</h5>

<mat-dialog-content>
    <mat-tab-group>
        <mat-tab label="Endpoint Access"> 
            <div class="access-list">
                <mat-list>
                    <mat-list-item>
                        <div class="section category-section">Securable</div>
                        <div class="section read-section">Read</div>
                        <div class="section create-section">Create</div>
                        <div class="section edit-section">Edit</div>
                        <div class="section delete-section">Delete</div>
                        <div class="section update-partial-section">Update Partial</div>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                    <ng-container *ngFor="let item of apiUser.access">
                        <mat-list-item> 
                            <div class="section category-section">
                                {{item.category}}
                            </div>
                            <div class="section read-section">
                                <mat-checkbox setColor="primaryColour" [(ngModel)]="item.read"></mat-checkbox>
                            </div>
                            <div class="section create-section">
                                <mat-checkbox setColor="primaryColour" [(ngModel)]="item.create"></mat-checkbox>
                            </div>
                            <div class="section edit-section">
                                <mat-checkbox setColor="primaryColour" [(ngModel)]="item.edit"></mat-checkbox>
                            </div>
                            <div class="section delete-section">
                                <mat-checkbox setColor="primaryColour" [(ngModel)]="item.delete"></mat-checkbox>
                            </div>
                            <div class="section update-partial-section">
                                <mat-checkbox setColor="primaryColour" [(ngModel)]="item.updatePartial"></mat-checkbox>
                            </div>
        
                            <mat-divider></mat-divider>
                        </mat-list-item>
                    </ng-container>
                </mat-list>
            </div>    
        </mat-tab>
        <mat-tab label="Password Reset">
            <div class="password-reset-container">
                <div class="title">
                    <!-- <h6>Password Reset</h6> -->
                </div>
                <div class="description">
                    Please enter a new password and enter it again for confirmation. Then click Reset Password.
                    The conditions are the password must be between 8 and 15 characters long, must contain at least one number, must contain at least one uppercase letter, must contain at least one lowercase letter.
                </div>
                <div class="form-fields">
                    <mat-form-field appearance="fill">
                        <mat-label>
                            New Password
                        </mat-label>
                    
                        <input matInput type="password" [(ngModel)]="newPassword">
                        <mat-hint></mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>
                            Retype Password
                        </mat-label>
                    
                        <input matInput type="password" [(ngModel)]="newPasswordConfirm">
                        <mat-hint></mat-hint>
                    </mat-form-field>

                    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="newPassword === '' || newPassword !== newPasswordConfirm">Reset Password</button> 
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>

    

</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
</mat-dialog-actions>