<mat-card>
    <mat-card-title class="header">
        <h5 appLocalizationPopupDirective localizationCode="Schedule" class="title">{{ 'Schedule' | translate: {Default: "Schedule"} }}</h5>
    </mat-card-title>
  
    <mat-card-content class="content">
        <div class="filters-container">
            <mat-checkbox
                setColor="primaryColour"
                *ngFor="let item of filterOptions"
                [checked]="item.selected"
                (change)="filterChanged(item)">

                {{item.label}}
                <div class="circle" [ngClass]="item.id"></div>

            </mat-checkbox>
        </div>

        <kendo-scheduler
            class="employee-scheduler"
            [kendoSchedulerBinding]="sampleData"
            [selectedDate]="selectedDate"
            [selectedViewIndex]="1"
            [loading]="isLoading"
            (dateChange)="dateChange($event)"
        >

            <ng-template kendoSchedulerEventTemplate let-event="event">
                <div class="event-container" [ngClass]="event.description">
                    <mat-icon *ngIf="event.description === 'StatutoryHoliday'" class="material-icons-outlined  public-holiday">celebration</mat-icon>
                    <mat-icon *ngIf="event.description === 'Leave' || event.description === 'Toil'" class="material-icons-outlined  absence">date_range</mat-icon>
                    <mat-icon *ngIf="event.description === 'Work'" class="material-icons-outlined  company-event">work</mat-icon>
                    
                    {{event.title}}
                </div>
            </ng-template>

            <ng-template kendoSchedulerAllDayEventTemplate let-event="event">
                <div class="event-container" [ngClass]="event.description">
                    <mat-icon *ngIf="event.description === 'StatutoryHoliday'" class="material-icons-outlined  public-holiday">celebration</mat-icon>
                    <mat-icon *ngIf="event.description === 'Leave'" class="material-icons-outlined  absence">date_range</mat-icon>
                    <mat-icon *ngIf="event.description === 'Work'" class="material-icons-outlined  company-event">work</mat-icon>
                    
                    {{event.title}}
                </div>
            </ng-template>

            <kendo-scheduler-day-view startTime="07:00"> </kendo-scheduler-day-view>

            <kendo-scheduler-month-view [eventHeight]="30">
            </kendo-scheduler-month-view>

            <kendo-scheduler-messages
                [today]="translate.instant('Scheduler-Today')"
                [allDay]="translate.instant('Scheduler-AllDay')"
                [dayViewTitle]="translate.instant('Scheduler-DayViewTitle')"
                [weekViewTitle]="translate.instant('Scheduler-WeekViewTitle')"
                [monthViewTitle]="translate.instant('Scheduler-MonthViewTitle')"
                [showWorkDay]="translate.instant('Scheduler-ShowWorkDay')"
                [showFullDay]="translate.instant('Scheduler-ShowFullDay')"
            >
            </kendo-scheduler-messages>
        </kendo-scheduler>
    </mat-card-content>
</mat-card>