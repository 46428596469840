
<mat-card class="revenue-chart">
    <mat-card-title class="revenue-chart__header">
      <h5 class="revenue-chart__header-title">Diversity Scorecard</h5>
  
      <app-settings-menu></app-settings-menu>
    </mat-card-title>
  
    <mat-card-content class="revenue-chart__content">

        <div class="item" *ngFor="let item of data">
            <div class="left">
                <img 
                    [src]="item.icon"
                    style="width: 80px;
                            height: 80px;
                            vertical-align: middle;"> 
            </div>
            <div class="right">
                <div class="label">{{item.label}}</div>
                <div class="value">{{item.value}}</div>
                <div class="taget">Target {{item.target}}</div>
            </div>
        </div>

    </mat-card-content>
</mat-card>