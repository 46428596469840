<form class="form" *ngIf="form" [formGroup]="form">

    <div class="form-section">
        <label class="radio-group-label">Multi-value fields handling</label>
        <mat-radio-group class="tp-radio-group" aria-label="Select an option" formControlName="separateLines">
            <mat-radio-button class="tp-radio-button" [value]="false">Combine</mat-radio-button>
            <mat-radio-button class="tp-radio-button" [value]="true">Separate</mat-radio-button>
        </mat-radio-group>
    </div>

    <mat-form-field appearance="fill">
        <mat-label>
            Parent Table
        </mat-label>

        <mat-select disableOptionCentering formControlName="parentTable">
            <ng-container *ngFor="let option of reportsMapping.parentChildTables">
                <mat-option [value]="option.name">{{option.name}}</mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>

    <div class="form-section">
        <div class="form-section-title">
            <h6>Columns</h6>
        </div>
        <div class="form-section-contents properties">
            <div class="section-row">
                <ng-container formArrayName="columns">

                    <div cdkDropList (cdkDropListDropped)="drop($event)">

                        <ng-container *ngFor="let propertyForm of columns.controls; let i = index">
                            <div class="form-row" cdkDrag>
                                <div class="example-custom-placeholder" *cdkDragPlaceholder></div>

                                <div>
                                    <ng-container *ngIf="form.value.parentTable">

                                        <report-element-grid-column [propertyForm]="propertyForm"
                                                                    [tables]="getSubTables(form.value.parentTable)"
                                                                    [parentTable]="form.value.parentTable"
                                                                    [index]="i"
                                        ></report-element-grid-column>

                                        <mat-icon 
                                        setColor 
                                        [setColorRules]="[
                                            {
                                                color: 'primaryColour',
                                                events: ['hover']
                                            }
                                        ]" 
                                        class="material-icons-outlined clickable-icon" 
                                        (click)="deleteColumn(i)" 
                                        matTooltip="Delete">delete</mat-icon>

                                        <mat-icon 
                                        class="material-icons-outlined clickable-icon" 
                                        setColor 
                                        [setColorRules]="[
                                            {
                                                color: 'primaryColour',
                                                events: ['hover']
                                            }
                                        ]" 
                                        (click)="copyColumn(i)" 
                                        matTooltip="Copy">copy</mat-icon>
                                    </ng-container>

                                </div>
                            </div>
                        </ng-container>

                    </div>

                </ng-container>
            </div>
        </div>
    </div>
</form>

<mat-dialog-actions>
    <button mat-stroked-button setColor="primaryColour" color="primary" (click)="debugDataTimeOff()" *ngIf="isDebug">
        <mat-icon class="material-icons-outlined">add</mat-icon>
        Debug (Time Off)
    </button>
    <button mat-stroked-button setColor="primaryColour" color="primary" (click)="debugDataWorkRotation()" *ngIf="isDebug">
        <mat-icon class="material-icons-outlined">add</mat-icon>
        Debug (Work Rotation)
    </button>
    <button mat-stroked-button setColor="primaryColour" color="primary" (click)="debugDataWorkLocation()" *ngIf="isDebug">
        <mat-icon class="material-icons-outlined">add</mat-icon>
        Debug (Work Location)
    </button>
    <button mat-stroked-button setColor="primaryColour" color="primary" (click)="debugDataCostCentre()" *ngIf="isDebug">
        <mat-icon class="material-icons-outlined">add</mat-icon>
        Debug (Cost Centre)
    </button>
    <button mat-stroked-button setColor="primaryColour" color="primary" (click)="debugDataOrg()" *ngIf="isDebug">
        <mat-icon class="material-icons-outlined">add</mat-icon>
        Debug (Org)
    </button>
    <button mat-stroked-button setColor="primaryColour" color="primary" (click)="debugDataEmp()" *ngIf="isDebug">
        <mat-icon class="material-icons-outlined">add</mat-icon>
        Debug (Emp)
    </button>
    <button mat-stroked-button setColor="primaryColour" color="primary" (click)="addNewColumn()"  *ngIf="form.get('parentTable').value">
        <mat-icon class="material-icons-outlined">add</mat-icon>
        Add Column
    </button>
</mat-dialog-actions>
