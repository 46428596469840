<app-employee-layout selectedComponent="CurrentEmploymentRecord">
    <div class="main-container">
        <div class="left">
            <mat-list>
                <mat-list-item (click)="navigateToEmploymentRecordsList()">
                    <mat-icon class="material-icons-outlined">arrow_back</mat-icon> Employment Records List
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item *ngFor="let item of navList" (click)="selectedNav = item" [ngClass]="{'selected': selectedNav === item }">
                    {{item.text}}
                </mat-list-item>
            </mat-list>
        </div>
        
        <div class="main-content" [ngSwitch]="selectedNav.text">
            <app-employment-record *ngSwitchCase="'Employment Record'" [employeeId]="employeeId" [employmentRecordId]="employmentRecordId"></app-employment-record>
            <app-employment-record-positions *ngSwitchCase="'Position'" [employeeId]="employeeId" [employmentRecordId]="employmentRecordId"></app-employment-record-positions>
            <app-employment-record-benefits *ngSwitchCase="'Benefits'" [employeeId]="employeeId" [employmentRecordId]="employmentRecordId"></app-employment-record-benefits>
            <app-employment-record-company-asset *ngSwitchCase="'Company Assets'" [employeeId]="employeeId" [employmentRecordId]="employmentRecordId"></app-employment-record-company-asset>
            <app-employment-record-grievance *ngSwitchCase="'Grievances'" [employeeId]="employeeId" [employmentRecordId]="employmentRecordId"></app-employment-record-grievance>
            <app-employment-record-payroll *ngSwitchCase="'Payroll'" [employeeId]="employeeId" [employmentRecordId]="employmentRecordId"></app-employment-record-payroll>
            <app-employment-record-compensation *ngSwitchCase="'Compensation'" [employeeId]="employeeId" [employmentRecordId]="employmentRecordId"></app-employment-record-compensation>
            <app-employment-record-other-compensations *ngSwitchCase="'Other Compensation'" [employeeId]="employeeId" [employmentRecordId]="employmentRecordId"></app-employment-record-other-compensations>
            <app-employment-record-pensions *ngSwitchCase="'Pension'" [employeeId]="employeeId" [employmentRecordId]="employmentRecordId"></app-employment-record-pensions>
            <app-employment-record-relocation *ngSwitchCase="'Relocation'" [employeeId]="employeeId" [employmentRecordId]="employmentRecordId"></app-employment-record-relocation>
            <app-employment-record-stock-purchase-plan *ngSwitchCase="'Stock Purchase Plans'" [employeeId]="employeeId" [employmentRecordId]="employmentRecordId"></app-employment-record-stock-purchase-plan>
            <app-employment-record-discipline *ngSwitchCase="'Discipline'" [employeeId]="employeeId" [employmentRecordId]="employmentRecordId"></app-employment-record-discipline>
            <app-employment-record-injury-illness *ngSwitchCase="'Injury Illness'" [employeeId]="employeeId" [employmentRecordId]="employmentRecordId"></app-employment-record-injury-illness>
        </div>
    </div>
</app-employee-layout>
