<mat-list>
    <mat-list-item>
        <div class="employeeToilRequest-row" appPreventDoubleClick (throttledClick)="openPendingTimeOffDialog.emit(employeeToilRequest)">
            <div class="left">
                <div class="timeofftype-details">
                    <img *ngIf="iconUrl" [src]="domSanitizer.bypassSecurityTrustUrl(iconUrl)"/>
                    <h6>{{employeeToilRequest.toilPolicy?.toilType?.name}} - {{employeeToilRequest.toilPolicy?.name}}</h6>
                </div>
    
                <div class="dates">
                    {{employeeToilRequest.startDate | date:'mediumDate'}} - {{employeeToilRequest.endDate | date:'mediumDate'}}
                </div>
    
                <mat-chip 
                    [ngClass]="{
                        'blue-chip': employeeToilRequest.toilClassType?.id === 'AccrueToil',
                        'yellow-chip': employeeToilRequest.toilClassType?.id === 'RequestToil'
                    }"
                >{{ employeeToilRequest.toilClassType?.name }}</mat-chip>
    
                <mat-chip class="green-chip half-day-chip" *ngIf="employeeToilRequest.halfDay === true" appLocalizationPopupDirective [localizationCode]="'EmployeeToilRequestHalfDay'">{{ 'EmployeeToilRequestHalfDay' | translate: {Default: "Half Day"} }}</mat-chip>
            </div>
            <div class="middle">
                <app-employee-image class="profile-pic" round="true" width="50" height="50" [employeeId]="employeeToilRequest.createdBy.id"></app-employee-image>
                {{employeeToilRequest.createdBy.firstName}} {{employeeToilRequest.createdBy.lastName}}
            </div>
            <div class="right">
                <mat-chip-list>
                    <mat-chip 
                        [ngClass]="{
                            'red-chip': employeeToilRequest.requestStatus?.id === 'trs_Denied',
                            'green-chip': employeeToilRequest.requestStatus?.id === 'trs_Approved',
                            'orange-chip': employeeToilRequest.requestStatus?.id === 'trs_Pending'
                        }"
                    >{{ employeeToilRequest.requestStatus?.name }}</mat-chip>
                </mat-chip-list>
            </div>
        </div>
    </mat-list-item>
</mat-list>
