<ng-container *ngIf="translationsLoaded; else loading">
    <app-data-grid-comp
        [tableId]="db_tables.EmployeeGoals"
        [columns]="columns"
        [bindingType]="bindingType"
        [view]="view"
        [gridData]="gridData"
        [gridDataResult]="gridDataResult"
        [isLoading]="isLoading"
        [pageSize]="pageSize"
        [skip]="skip"
        searchable="true"
        category="ChildGoals"
        [rowSelectionSettings]="{
            canSelectRows: true,
            selectableMode: 'multiple',
            selectRowBy: 'id'
        }"
        [hideActionButtons]="!goalType?.allowEmployeeSubGoalCreation || !goalEntryAllowed"
        clickableRows="true"
        [isLoading]="isLoading"
        [clearSelectedItems]="clearSelectedItems"
        [filterCategories]="filterCategories"
        [sortable]="{
            mode: 'multiple'
        }"
        [sortableColumns]="sortableColumns"
        (emitSortEvent)="sortChange($event)"
        (emitSearchEvent)="search($event)"
        (emitFilterEvent)="filterCallback($event)"
        (emitPageChangeEvent)="pageChange($event)"
        (emitOpenDialogEvent)="openGoalDialog($event)"
        (emitDeleteEvent)="deleteGoals($event)"
    ></app-data-grid-comp>
</ng-container>

<ng-template #loading>
    <mat-card>
        <mat-card-header></mat-card-header>
        <mat-card-content>
            <ngx-skeleton-loader
                    count="5"
                    [theme]="{ 
                        'height.px': 50
                    }"
            ></ngx-skeleton-loader>
        </mat-card-content>
    </mat-card>
</ng-template>
