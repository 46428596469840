<h5 mat-dialog-title>{{ 'EnterAChangeReason' | translate: {Default: "Enter A Change Reason"} }}</h5>

<mat-dialog-content>
    <app-form-generator 
      *ngIf="formId !== undefined"
      [formId]="formId"
      [emitFormDataTrigger]="getFormData"
      [targetEmployeeTablePermissions]="targetEmployeeTablePermissions"
      (emitFormData)="formDataEmitted($event)"
      (emitFormStatus)="formStatusUpdated($event)"
    ></app-form-generator>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button (click)="cancel()">Cancel</button>
    <button mat-raised-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="getFormData = !getFormData">{{ 'Save' | translate: {Default: "Save"} }}</button> 
</mat-dialog-actions>
