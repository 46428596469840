import { Component, OnInit } from '@angular/core';
import { routes } from '@app/consts';

@Component({
  selector: 'app-new-hire-packet-stepper',
  templateUrl: './new-hire-packet-stepper.component.html',
  styleUrls: ['./new-hire-packet-stepper.component.scss']
})
export class NewHirePacketStepperComponent implements OnInit {
  public routes: typeof routes = routes;

  constructor() { }

  ngOnInit(): void {
  }

}
