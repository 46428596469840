import { Component, OnInit } from '@angular/core';
import '@progress/kendo-ui';
import { forkJoin, EMPTY, Observable} from 'rxjs';
import { tap, expand, reduce, map, concatMap } from 'rxjs/operators';
import 'rxjs'
import { Position, Organization, EmployeeDetails, PositionEmployee } from '../../models/org-chart.model';
import { OrgChartService } from '../../services/org-chart.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import OrgChart from '../../../../../assets/balkanapp/orgchart';
import * as moment from 'moment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NewEmployeeFormDialogComponent } from '../../../talent-track/talent-track-profiles/components/new-employee-form-dialog/new-employee-form-dialog.component';
import { ActivatedRoute, Router } from "@angular/router";
import { PositionFormDialogComponent } from '../position-editor/position-form-dialog.component';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { NewOrganizationFormDialogComponent } from '@app/modules/organization-structure/components/new-organization-form-dialog/new-organization-form-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { OrgChartListener } from "@app/modules/org-chart/components/org-chart-display/org-chart-listener";
import { OrgChartsUtil } from "@app/modules/org-chart/components/org-chart-display/org-charts-util";

@Component({
  selector: 'app-org-chart-display',
  templateUrl: './org-chart-display.component.html',
  styleUrls: ['./org-chart-display.component.scss'],
})
export class OrgChartDisplayComponent implements OnInit, OrgChartListener {

  private orgChartsUtil: OrgChartsUtil = new OrgChartsUtil(this.translate);
  chartType = this.chartTypes.positions
  chartTitle = ""

  chart: OrgChart
  chartSender: any = null
  editPermission: boolean
  orientation = OrgChart.orientation.top;

  stockEmployeePhoto = null;
  private selected_node = null;

  private fetchedOrgIds = []
  private alreadyFetchedPositionPhotosIds = []

  private containerIDs: string[] = [];

  private displayFilters = false;

  private employees: PositionEmployee[] = [];

  get tags() {
    return this.orgChartsUtil.tags
  }

  get positionFields() {
    return this.orgChartsUtil.POSITION_FIELDS
  }

  get employeeFields() {
    return this.orgChartsUtil.EMPLOYEE_FIELDS
  }

  get organizationFields() {
    return this.orgChartsUtil.ORGANIZATION_FIELDS
  }

  get chartTypes() {
    return this.orgChartsUtil.CHART_TYPES
  }

  get nodeBindings() {
    return this.orgChartsUtil.NODE_BINDiNGS
  }

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private orgChartService: OrgChartService,
    private snackbarService: SnackbarService,
    public router: Router,
    private dialog: MatDialog,
  ) {
    this.orgChartsUtil.initTemplates()
  }

  ngOnInit() {
    console.log('IsTrial: ',OrgChart.isTrial())
    this.chartType = this.route.snapshot.paramMap.get('type');
    this.editPermission = JSON.parse(this.route.snapshot.paramMap.get('isEditable'))
    this.editPermission = this.editPermission == true ? true : false

    this.orgChartService.getStockImage().subscribe((res) => {
      const reader = new FileReader();
      const binaryString = reader.readAsDataURL((res.body as Blob));
      reader.onload = (event: any) => {
        this.stockEmployeePhoto = event.target.result
      };
    })

    this.initChart();
  }

  initChart() {
    let positionMenu = {
      details: { text: "Details" },
    }

    let employeeMenu = {
      details: {
        text: "Details", onClick: (nodeId) => {
          let employee = this.getEmployeeByNodeId(nodeId)
          if (employee) {
            this.loadEmployeeDetails(nodeId, employee.id)
          }
        }
      }
    }

    let organizationMenu = {
      details: { text: "Details" },
    }

    if (this.editPermission) {
      this.orgChartsUtil.addEditPermissionMenuItems(this, positionMenu, employeeMenu, organizationMenu);
    }

    let editFormElements: any = []

    for (let key in this.positionFields) {
      editFormElements.push({ type: "textbox", label: this.positionFields[key], binding: this.positionFields[key] })
    }

    for (let key in this.employeeFields) {
      editFormElements.push({ type: "textbox", label: this.employeeFields[key], binding: this.employeeFields[key] })
    }

    for (let key in this.organizationFields) {
      editFormElements.push({ type: "textbox", label: this.organizationFields[key], binding: this.organizationFields[key] })
    }

    let filterBy = null
    if (this.chartType == this.chartTypes.positions) {
      filterBy = {
        [this.positionFields.positionName]: {},
        [this.positionFields.isVacant]: {},
        [this.positionFields.department]: {},
        [this.positionFields.division]: {},
        [this.positionFields.workLocation]: {},
        [this.positionFields.region]: {},
        // TODO readd when filters are working
        // [this.positionFields.status]: {
        //   'Active': { checked: true, text: 'Active' },
        //   'Expired': { checked: false, text: 'Expired' }
        // },
        [this.employeeFields.country]: {},
        [this.positionFields.projectTeam]: {},
        [this.positionFields.empType]: {},
        [this.positionFields.empCategory]: {},
      }
    } else if (this.chartType == this.chartTypes.organizations) {
      filterBy = {
      }
    }

    this.chart = new OrgChart(document.getElementById("OrgTree"), {
      mouseScrool: OrgChart.action.zoom,
      padding: 100,
      template: "ana",
      align: OrgChart.align.center,
      // layout: OrgChart.treeLeftOffset,
      orientation: this.orientation,
      lazyLoading: true,
      enableDragDrop: this.editPermission,
      menu: {
        pdf: {
          text: "Export PDF", onClick: () => {
            this.chart.exportPDF({ filename: this.chartTitle + ".pdf", expandChildren: true });
          }
        }
      },
      filterBy: filterBy,
      editForm: {
        titleBinding: this.employeeFields.name,
        buttons: {
          edit: null,
          share: null,
        },
        generateElementsFromFields: false,
        elements: editFormElements,
      },
      nodeMenu: {
        details: { text: "Details" }
      },
      scaleInitial: OrgChart.match.boundary,
      nodeBinding: this.nodeBindings,
      toolbar: {
        zoom: true,
        fit: true,
        // layout: true,
      },
      zoom: { speed: 40, smooth: 12 },
      scaleMax: 2, // max zoom-in
      scaleMin: 0.4, // max zoom-out
      collapse: {
        level: 2,
        allChildren: true,
      },
      min: true,
      tags: this.orgChartsUtil.treeTags(positionMenu, employeeMenu, organizationMenu)
    })

    // Custom action definitions
    this.chart.on('click', (sender, args) => {
      return this.orgChartsUtil.handleNodeClick(this, sender, args)
    });

    this.chart.on('searchclick', (sender, nodeId) => {
      this.selectNode(nodeId);
      sender.center(nodeId, {
        horizontal: true,
        vertical: true,
        rippleId: undefined,
        parentState: undefined,
        childrenState: undefined
      }, () => {
        this.chart.searchUI.hide()
        this.loadPhotos()
      });
      return true;
    });

    this.chart.on('expcollclick', (sender, collapse, id, ids) => {
      return this.orgChartsUtil.handleExpandCollapse(this, this.chartType, sender, collapse, id, ids)
    });

    this.chart.on('field', (sender, args) => {
      if (args["node"].min) {
        if (args["name"] == "img") {
          var childrenIds = args["node"].stChildrenIds
          var count = childrenIds.length > 5 ? 5 : childrenIds.length;

          if (args["node"].tags.indexOf(this.tags.ORGANIZATION) > -1) {
            var x = args["node"].w / 2 - (count * 48) / 2;
            for (var i = 0; i < count; i++) {
              var data = sender.get(childrenIds[i]);
              args["value"] += '<image xlink:href="' + data["img"] + '" x="' + (x + i * 48) + '" y="60" width="48" height="48"></image>';
              args["value"] += '<rect e-c="' + args["node"].id + '" rx="12" ry="12" x="0" y="0" width="' + 24 + '" height="24" fill="#039be5"></rect><text style="font-size: 14px; cursor: pointer;" fill="#000000" x="' + (24 / 2) + '" y="17" text-anchor="middle" >' + childrenIds.length + '</text>';
            }
          }
          else if (args["node"].tags.indexOf(this.tags.POSITION) > -1) {
            var x = args["node"].w / 2 - (count * 32) / 2;

            if (args["data"]) {
              let slots = args["data"][this.positionFields.numberSlots];
              if (slots && slots > 0) {
                let displayString = childrenIds.length + " / " + slots
                let length = displayString.length * 8
                args["value"] += '<rect e-c="' + args["node"].id + '" rx="12" ry="12" x="0" y="-10" width="' + length + '" height="24" fill="#039be5"></rect><text style="font-size: 14px; cursor: pointer;" fill="#000000" x="' + (length / 2) + '" y="7" text-anchor="middle" >' + displayString + '</text>';
              } else {
                args["value"] += '<rect e-c="' + args["node"].id + '" rx="12" ry="12" x="0" y="0" width="' + 24 + '" height="24" fill="#039be5"></rect><text style="font-size: 14px; cursor: pointer;" fill="#000000" x="' + (24 / 2) + '" y="17" text-anchor="middle" >' + childrenIds.length + '</text>';
              }
            }
            for (var i = 0; i < count; i++) {
              var data = sender.get(childrenIds[i]);
              args["value"] += '<image xlink:href="' + data["img"] + '" x="' + (x + i * 32) + '" y="60" width="32" height="32 "></image>';
            }
          }
        }
      } else {

        if (args["name"] == this.employeeFields.name) {
          var mid = args["node"].w / 2
          var dataLength = args["node"].w - 80
          if (args["node"].tags.indexOf(this.tags.POSITION) > -1) {
            args["element"] = '<text data-width="' + dataLength + '" data-text-overflow="multiline-2-ellipsis" style="font-size: 16px;" fill="#757575" x="' + mid + '" y="22" text-anchor="middle">{val}</text>';
          }
          if (args["node"].tags.indexOf(this.tags.ORGANIZATION) > -1) {
            args["element"] = '<text data-width="' + dataLength + '" data-text-overflow="multiline-2-ellipsis" style="font-size: 16px;" fill="#757575" x="' + mid + '" y="22" text-anchor="middle">{val}</text>';
          }
        }
      }

    });

    // Custom drop handling
    this.chart.on('drop', (sender: OrgChart, draggedNodeId: string, droppedNodeId: string) => {
      if (!sender.nodes[droppedNodeId]) {
        return false
      }

      if (!this.editPermission) {
        return false
      }

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      if (sender.nodes[draggedNodeId].tags.includes(this.tags.EMPLOYEE)
        && sender.nodes[droppedNodeId].tags.includes(this.tags.POSITION)) {

        let currEmp = this.getEmployeeByNodeId(draggedNodeId)
        if (currEmp) {
          let newEmpData = {
            positionId: droppedNodeId,
            employeeId: currEmp.id,
            startDate: new Date(),
            endDate: null
          }

          let employee = Object.assign({}, currEmp);

          this.orgChartService.postEmployeeReportToPosition(newEmpData)
            .subscribe(res => {
              let node = this.chart.get(draggedNodeId)

              employee.randomId = this.chart.generateId();
              employee.parentId = droppedNodeId

              node['id'] = employee.randomId
              node["stpid"] = droppedNodeId

              this.employees.push(employee);
              this.chart.addNode(node)
            })
        }

        return false
      } else if ((sender.nodes[draggedNodeId].tags.includes(this.tags.POSITION)
        && sender.nodes[droppedNodeId].tags.includes(this.tags.POSITION))
      ) {
        let data = {
          id: draggedNodeId,
          asOf: new Date(),
          changeReason: "",
          changeReasonComments: "",
          parentPosition: droppedNodeId
        }

        dialogConfig.data = {
          text: 'Are you sure you want to re-parent this position?'
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
          res => {
            if (res === true) {
              this.orgChartService.postPositionReportToPosition(draggedNodeId, data).subscribe(res => {
                var node = this.chart.get(draggedNodeId)
                node["pid"] = droppedNodeId
                this.chart.update(node)
              })
            }
          }
        );
      } else if ((sender.nodes[draggedNodeId].tags.includes(this.tags.ORGANIZATION)
        && sender.nodes[droppedNodeId].tags.includes(this.tags.ORGANIZATION))
      ) {
        let data = {
          id: draggedNodeId,
          asOf: new Date(),
          changeReason: "",
          changeReasonComments: "",
          parentPosition: droppedNodeId
        }

        dialogConfig.data = {
          text: 'Are you sure you want to re-parent this organization?'
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
          res => {
            if (res === true) {
              this.orgChartService.postOrganizationReportToOrganization(draggedNodeId, data).subscribe(() => {
                var node = this.chart.get(draggedNodeId)
                node["pid"] = droppedNodeId
                this.chart.update(node)
              })
            }
          }
        );
      }

      return false
    });

    this.chart.on('init', (sender) => {
      this.chartSender = sender;
      OrgChart.loading.show(sender);
    });

    if (this.chartType == this.chartTypes.organizations) {
      // Disable filters for now
      const button = document.getElementById('showFiltersButton');

      if (button != null) {
        button.style.display = 'none';
      }
      this.loadOrganizationData()
      this.chartTitle = "Organization Chart"
    } else if (this.chartType == this.chartTypes.positions) {
      this.loadPositionData()
      this.chartTitle = "Position Chart"
    } else {
      this.loadOrganizationData()
    }
  }

  loadOrganizationData() {
    this.orgChartService.getOrganizations().pipe(
      map((orgs: any[]) => orgs.map(org => {
        return this.orgChartService.getOrganizationEmployees(0, org.id).pipe(
          map((emps: any[]) => {
            return { org, emps };
          })
        );

      })),
      concatMap(orgObservables => forkJoin(orgObservables)),
      map(orgsWithEmployees => {

        let nodes = []
        orgsWithEmployees.forEach(data => {
          nodes.push(this.orgToNode(data.org))

          let posEmployees = []
          data.emps.forEach(empData => {

            let emp = {
              id: empData.id,
              firstName: empData['firstname'],
              lastName: empData['lastname'],
              employeeId: empData.id,
              parentId: data.org.id,
              randomId: this.chart.generateId(),

            }

            nodes.push(this.loadEmployee(emp))
            posEmployees.push(emp);

          })
          this.employees.push(...posEmployees);
        })
        return nodes
      })
    ).subscribe(res => {
      this.chart.load(res);
      OrgChart.loading.hide(this.chart);

      this.alreadyFetchedPositionPhotosIds = []
      this.loadPhotos()
    })
  }

  loadPositionData() {
    let expiredPositions = [];
    this.getAllPositions().subscribe(positions => {
      let nodes = [];
      positions.forEach(pos => {
        nodes.push(this.loadPosition(pos, expiredPositions));
        pos.employees.forEach(employee => {
          employee.parentId = pos.id;
          employee.randomId = this.chart.generateId();
          nodes.push(this.loadEmployee(employee))
        });

        this.employees.push(...pos.employees);
      })

      this.chart.load(nodes)
      OrgChart.loading.hide(this.chart)

      this.alreadyFetchedPositionPhotosIds = []
      this.loadPhotos()

      for (let i = 0; i < expiredPositions.length; i++) {
        this.removeChildrenRecusively(expiredPositions[i]);
      }

      this.chart.fit()
    })
  }

  getAllPositions(): Observable<any[]> {
    var skip = 0;
    return this.orgChartService.getPositions(skip).pipe(
      expand(data =>
        data.data.length === 0 ? EMPTY : this.orgChartService.getPositions(skip)),
      tap(data => skip += data.take),
      reduce((acc, val) => {
        acc = [...acc, ...val.data]
        return acc
      }, [])
    )
  }

  // Ready for template switching when it is needed
  templateChanged(arg) {
    this.chart.config.template = arg;
    OrgChart.templates.employee = Object.assign({}, OrgChart.templates[arg])
  }

  updateNodeParams(nodeId: string, params: {}) {
    var emp = this.chart.get(nodeId)
    for (let key in params) {
      if (params[key]) {
        emp[key] = params[key]
      }
    }
    this.chart.updateNode(emp)
  }

  // Employee CRUD
  openNewEmployeeDialog(parentId) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 500;

    const dialogRef = this.dialog.open(NewEmployeeFormDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data && data.result) {
        let positionChange = {
          positionId: parentId,
          employeeId: data.result.employeeId,
          startDate: new Date(),
          endDate: null,
        }
        this.orgChartService.postEmployeeReportToPosition(positionChange).subscribe(res => {

          this.chart.addNode({
            id: data.result.employeeId,
            stpid: parentId,
            tags: [this.tags.EMPLOYEE],
            [this.employeeFields.name]: data.formData.firstname + data.formData.lastname,
            [this.employeeFields.firstName]: data.formData.firstname,
            [this.employeeFields.lastName]: data.formData.lastname,
            [this.employeeFields.title]: data.formData.title,
            [this.employeeFields.preferred]: data.formData.preferred,
            [this.employeeFields.maidenName]: data.formData.maidenName,
            [this.employeeFields.middleName]: data.formData.middleName,
            [this.employeeFields.gender]: data.formData.gender,
            [this.employeeFields.cultureAffil]: data.formData.culturalAffiliation,
            [this.employeeFields.religiousAffil]: data.formData.religiousAffiliation,
            [this.employeeFields.linkedin]: data.formData.linkedInUrl,
            [this.employeeFields.twitter]: data.formData.twitterHandle,
            [this.employeeFields.facebook]: data.formData.facebookUrl,
            [this.employeeFields.status]: data.formData.status,
            [this.employeeFields.birthday]: data.formData.birthday,
            [this.employeeFields.maritalStatus]: data.formData.maritalStatus,
            [this.employeeFields.subStatus]: data.formData.subStatus,
            [this.employeeFields.language]: data.formData.preferredLanguage,
            [this.employeeFields.streetAddress]: data.formData.streetAddress,
            [this.employeeFields.city]: data.formData.city,
            [this.employeeFields.province]: data.formData.province,
            [this.employeeFields.zip]: data.formData.zip,
            [this.employeeFields.country]: data.formData.country,
            [this.employeeFields.email]: data.formData.email,
            [this.employeeFields.workPhone]: data.formData.workPhone,
            [this.employeeFields.homePhone]: data.formData.homePhone,
            [this.employeeFields.cellPhone]: data.formData.cellPhone,
            [this.employeeFields.culture]: data.formData.culture,
            img: this.stockEmployeePhoto
          })

          this.snackbarService.openSnackBar('Employee created successfully', 'clear', 'success');
        })
      }
    })
  }

  loadEmployee(employee: PositionEmployee) {

    let res = {
      id: employee.randomId,
      stpid: employee.parentId,
      tags: [this.tags.EMPLOYEE],
      [this.employeeFields.name]: employee.firstName + ' ' + employee.lastName,
      img: this.stockEmployeePhoto,
      employeeId: employee.id
    }

    return res
  }

  getEmployeePhoto(nodeId, employeeId: string) {
    this.orgChartService.getEmployeePhoto(employeeId).subscribe((res) => {
      let params = { img: res }
      this.updateNodeParams(nodeId, params)
    });
  }

  loadEmployeeDetails(nodeId: string, employeeId: string) {

    let emp = this.chart.get(nodeId)

    this.orgChartService.getEmployeeDetails(employeeId).subscribe(res => {

      let empDetails = <EmployeeDetails>res

      emp[this.employeeFields.firstName] = empDetails.firstname
      emp[this.employeeFields.lastName] = empDetails.lastname
      emp[this.employeeFields.title] = empDetails.title ? empDetails.title.text : null
      emp[this.employeeFields.preferred] = empDetails.preferred
      emp[this.employeeFields.maidenName] = empDetails.maidenName
      emp[this.employeeFields.middleName] = empDetails.middleName
      emp[this.employeeFields.gender] = empDetails.gender ? empDetails.gender.text : null
      emp[this.employeeFields.cultureAffil] = empDetails.culturalAffiliation ? empDetails.culturalAffiliation.text : null
      emp[this.employeeFields.religiousAffil] = empDetails.religiousAffiliation ? empDetails.religiousAffiliation.text : null
      emp[this.employeeFields.linkedin] = empDetails.linkedInUrl
      emp[this.employeeFields.twitter] = empDetails.twitterHandle
      emp[this.employeeFields.facebook] = empDetails.facebookUrl
      emp[this.employeeFields.status] = empDetails.status
      emp[this.employeeFields.birthday] = empDetails.birthday ? (moment(empDetails.birthday).format('DD-MMM-YYYY')) : null
      emp[this.employeeFields.maritalStatus] = empDetails.maritalStatus ? empDetails.maritalStatus.text : null
      emp[this.employeeFields.subStatus] = empDetails.subStatus
      emp[this.employeeFields.language] = empDetails.preferredLanguage
      emp[this.employeeFields.streetAddress] = empDetails.streetAddress
      emp[this.employeeFields.city] = empDetails.city
      emp[this.employeeFields.province] = empDetails.province
      emp[this.employeeFields.zip] = empDetails.zip
      emp[this.employeeFields.country] = empDetails.country ? empDetails.country.name : null
      emp[this.employeeFields.email] = empDetails.email
      emp[this.employeeFields.workPhone] = empDetails.workPhone
      emp[this.employeeFields.homePhone] = empDetails.homePhone
      emp[this.employeeFields.cellPhone] = empDetails.cellPhone
      emp[this.employeeFields.culture] = empDetails.culture ? empDetails.culture.text : null

      this.chart.updateNode(emp, () => {
        this.chart.editUI.show(nodeId, true, false);
      })
    })
  }

  deleteEmployeePosition(nodeId: string) {
    let employee = this.getEmployeeByNodeId(nodeId)
    if (employee) {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      dialogConfig.data = {
        text: 'Are you sure you want to delete this employee position?'
      };

      const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        data => {
          if (data === true) {
            this.orgChartService.deleteEmployeePosition(employee.id).subscribe(res => {
              this.chart.removeNode(nodeId)
              this.snackbarService.openSnackBar('Employee position deleted successfully', 'clear', 'success');
            })
          }
        }
      );
    }
  }

  // Position CRUD
  openNewPositionDialog(parentId) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 500;
    dialogConfig.data = { position: null, parentId: parentId }

    const dialogRef = this.dialog.open(PositionFormDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data && data.result && data.formData) {
        this.chart.addNode({
          id: data.result.positionId,
          pid: parentId,
          tags: [this.tags.POSITION],
          [this.employeeFields.name]: data.formData.name[0].text,
          [this.positionFields.positionName]: data.formData.name[0].text,
          [this.positionFields.jobTitle]: data.formData.functionalJobTitle ? data.formData.functionalJobTitle.text : null,
          [this.positionFields.marketPosition]: data.formData.marketPositionTitle ? data.formData.marketPositionTitle.text : null,
          [this.positionFields.marketPosition]: data.formData.marketPosition ? data.formData.marketPosition.text : null,
          [this.positionFields.marketView]: data.formData.marketView ? data.formData.marketView.text : null,
          [this.positionFields.unionCode]: data.formData.unionCode ? data.formData.unionCode.text : null,
          [this.positionFields.shiftCode]: data.formData.shiftCode ? data.formData.shiftCode.text : null,
          [this.positionFields.numberSlots]: data.formData.numberOfSlots,
          [this.positionFields.enforceSlotLimit]: data.formData.enforceSlotLimit,
          [this.positionFields.department]: data.formData.department ? data.formData.department.text : null,
          [this.positionFields.division]: data.formData.division ? data.formData.division.text : null,
          [this.positionFields.empCategory]: data.formData.employeeCategory ? data.formData.employeeCategory.text : null,
          [this.positionFields.empGroup]: data.formData.employmentGroup ? data.formData.employmentGroup.text : null,
          [this.positionFields.empType]: data.formData.employmentType ? data.formData.employmentType.text : null,
          [this.positionFields.jobBand]: data.formData.jobBand ? data.formData.jobBand.text : null,
          [this.positionFields.jobFamily]: data.formData.jobFamily ? data.formData.jobFamily.text : null,
          [this.positionFields.jobGroup]: data.formData.jobGroup ? data.formData.jobGroup.text : null,
          [this.positionFields.jobType]: data.formData.jobType ? data.formData.jobType.text : null,
          [this.positionFields.projectTeam]: data.formData.projectTeam ? data.formData.projectTeam.text : null,
          [this.positionFields.region]: data.formData.region ? data.formData.region.text : null,
          [this.positionFields.workLocation]: data.formData.workLocation ? data.formData.workLocation.name : null,
          [this.positionFields.subDivision]: data.formData.subDivision ? data.formData.subDivision.text : null,
          [this.positionFields.workRotation]: data.formData.workRotation ? data.formData.workRotation.name : null,
          [this.positionFields.workRotationStart]: data.formData.workRotationStartDate ? (moment(data.formData.workRotationStartDate).format('DD-MMM-YYYY')) : null
        })
      }
    })
  }

  openEditPositionDialog(nodeId) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 500;

    this.orgChartService.getPositionDetails(nodeId).subscribe(res => {

      // let node = this.chart.getNode(nodeId)
      dialogConfig.data = { position: res }
      const dialogRef = this.dialog.open(PositionFormDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(data => {
        if (data && data.result && data.formData) {
          this.chart.update({
            id: data.result.positionId,
            pid: data.formData.parentPosition ? data.formData.position.id : null,
            tags: [this.tags.POSITION],
            [this.employeeFields.name]: data.formData.name[0].text,
            [this.positionFields.positionName]: data.formData.name[0].text,
            [this.positionFields.jobTitle]: data.formData.functionalJobTitle ? data.formData.functionalJobTitle.text : null,
            [this.positionFields.marketPosition]: data.formData.marketPositionTitle ? data.formData.marketPositionTitle.text : null,
            [this.positionFields.marketPosition]: data.formData.marketPosition ? data.formData.marketPosition.text : null,
            [this.positionFields.marketView]: data.formData.marketView ? data.formData.marketView.text : null,
            [this.positionFields.unionCode]: data.formData.unionCode ? data.formData.unionCode.text : null,
            [this.positionFields.shiftCode]: data.formData.shiftCode ? data.formData.shiftCode.text : null,
            [this.positionFields.numberSlots]: data.formData.numberOfSlots,
            [this.positionFields.enforceSlotLimit]: data.formData.enforceSlotLimit,
            [this.positionFields.department]: data.formData.department ? data.formData.department.text : null,
            [this.positionFields.division]: data.formData.division ? data.formData.division.text : null,
            [this.positionFields.empCategory]: data.formData.employeeCategory ? data.formData.employeeCategory.text : null,
            [this.positionFields.empGroup]: data.formData.employmentGroup ? data.formData.employmentGroup.text : null,
            [this.positionFields.empType]: data.formData.employmentType ? data.formData.employmentType.text : null,
            [this.positionFields.jobBand]: data.formData.jobBand ? data.formData.jobBand.text : null,
            [this.positionFields.jobFamily]: data.formData.jobFamily ? data.formData.jobFamily.text : null,
            [this.positionFields.jobGroup]: data.formData.jobGroup ? data.formData.jobGroup.text : null,
            [this.positionFields.jobType]: data.formData.jobType ? data.formData.jobType.text : null,
            [this.positionFields.projectTeam]: data.formData.projectTeam ? data.formData.projectTeam.text : null,
            [this.positionFields.region]: data.formData.region ? data.formData.region.text : null,
            [this.positionFields.workLocation]: data.formData.workLocation ? data.formData.workLocation.name : null,
            [this.positionFields.subDivision]: data.formData.subDivision ? data.formData.subDivision.text : null,
            [this.positionFields.workRotation]: data.formData.workRotation ? data.formData.workRotation.name : null,
            [this.positionFields.workRotationStart]: data.formData.workRotationStartDate ? (moment(data.formData.workRotationStartDate).format('DD-MMM-YYYY')) : null
          })
        }
      })
    })

  }

  loadPosition(element: Position, expiredPositions: string[]) {

    let tags = [this.tags.POSITION]

    var today = new Date();
    var isExpired = 'Active';

    if (element.endDate) {
      var endDate = new Date(element.endDate)
      if (endDate < today) {
        isExpired = 'Expired';
        expiredPositions.push(element.id)
      }
    }

    if (element.assistant) {
      tags.push(this.tags.ASSISTANT)
    }

    let isVacant = "Occupied"

    if (element.numberOfSlots) {
      if (element.numberOfSlots > element.employees.length) {
        isVacant = "Vacant"
      }
    } else {
      if (element.employees.length == 0) {
        isVacant = "Vacant"
      }
    }

    this.containerIDs.push(element.id)

    return {
      id: element.id,
      pid: element.parentPosition ? element.parentPosition.id : null,
      tags: tags,
      [this.employeeFields.name]: element.name,
      [this.positionFields.positionName]: element.name,
      [this.positionFields.jobTitle]: element.functionalJobTitle ? element.functionalJobTitle.text : null,
      [this.positionFields.marketPosition]: element.marketPositionTitle ? element.marketPositionTitle.text : null,
      [this.positionFields.marketPosition]: element.marketPosition ? element.marketPosition.text : null,
      [this.positionFields.marketView]: element.marketView ? element.marketView.text : null,
      [this.positionFields.unionCode]: element.unionCode ? element.unionCode.text : null,
      [this.positionFields.shiftCode]: element.shiftCode ? element.shiftCode.text : null,
      [this.positionFields.numberSlots]: element.numberOfSlots,
      [this.positionFields.enforceSlotLimit]: element.enforceSlotLimit,
      [this.positionFields.department]: element.department ? element.department.text : null,
      [this.positionFields.division]: element.division ? element.division.text : null,
      [this.positionFields.empCategory]: element.employeeCategory ? element.employeeCategory.text : null,
      [this.positionFields.empGroup]: element.employmentGroup ? element.employmentGroup.text : null,
      [this.positionFields.empType]: element.employmentType ? element.employmentType.text : null,
      [this.positionFields.jobBand]: element.jobBand ? element.jobBand.text : null,
      [this.positionFields.jobFamily]: element.jobFamily ? element.jobFamily.text : null,
      [this.positionFields.jobGroup]: element.jobGroup ? element.jobGroup.text : null,
      [this.positionFields.jobType]: element.jobType ? element.jobType.text : null,
      [this.positionFields.projectTeam]: element.projectTeam ? element.projectTeam.text : null,
      [this.positionFields.region]: element.region ? element.region.text : null,
      [this.positionFields.workLocation]: element.workLocation ? element.workLocation.name : null,
      [this.positionFields.subDivision]: element.subDivision ? element.subDivision.text : null,
      [this.positionFields.workRotation]: element.workRotation ? element.workRotation.name : null,
      [this.positionFields.workRotationStart]: element.workRotationStartDate ? (moment(element.workRotationStartDate).format('DD-MMM-YYYY')) : null,
      [this.positionFields.isVacant]: isVacant,
      [this.positionFields.startDate]: element.startDate ? (moment(element.startDate).format('DD-MMM-YYYY')) : null,
      [this.positionFields.endDate]: element.endDate ? (moment(element.startDate).format('DD-MMM-YYYY')) : null,
      [this.positionFields.status]: isExpired,

    }
  }

  deletePosition(id: string) {
    let node = this.chart.getNode(id)
    if (node["childrenIds"] && node["childrenIds"].length > 0) {
      this.snackbarService.openSnackBar('Cannot delete position with employees', 'clear', 'warn');
    } else {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      dialogConfig.data = {
        text: 'Are you sure you want to delete this position ?'
      };

      const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        data => {
          if (data === true) {
            this.orgChartService.deletePosition(id).subscribe(res => {
              this.chart.removeNode(id)
              this.snackbarService.openSnackBar('Position deleted successfully', 'clear', 'success');
            })
          }
        }
      );
    }
  }


  // Organization CRUD

  openNewOrganizationDialog(parentId) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 500;
    dialogConfig.data = { position: null, parentId: parentId }
    //TODO create organization

    //frm_qK0FYWUEQe7FOz

    const dialogRef = this.dialog.open(NewOrganizationFormDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data && data.result && data.formData) {

        this.chart.addNode(
          {
            id: data.result.organizationId,
            pid: data.formData.parentOrganization,
            tags: [this.tags.ORGANIZATION],
            [this.employeeFields.name]: data.formData.name && data.formData.name[0] ? data.formData.name[0].text : null,
            [this.organizationFields.startDate]: data.formData.startDate,
            [this.organizationFields.endDate]: data.formData.endDate,
          }
        )

        this.snackbarService.openSnackBar('Organization created successfully', 'clear', 'success');
      }
    })
  }

  orgsToNodes(organizations: Organization[]) {
    let nodes = [];
    organizations.forEach(org => {
      nodes.push(this.orgToNode(org))
    })
    return nodes;
  }

  orgToNode(element: Organization) {
    this.containerIDs.push(element.id)
    return {
      id: element.id,
      pid: element.parentOrganization ? element.parentOrganization.id : null,
      tags: [this.tags.ORGANIZATION],
      [this.employeeFields.name]: element.name ? element.name : null,
      [this.organizationFields.startDate]: element.startDate ? (moment(element.startDate).format('DD-MMM-YYYY')) : null,
      [this.organizationFields.endDate]: element.endDate ? (moment(element.endDate).format('DD-MMM-YYYY')) : null,
      [this.organizationFields.organizationType]: element.organizationType ? element.organizationType.name : null,
    }
  }

  deleteOrganization(id: string) {
    let node = this.chart.getNode(id)
    if (node["childrenIds"] && node["childrenIds"].length > 0) {
      this.snackbarService.openSnackBar('Cannot delete organization with underlying organizations', 'clear', 'warn');
    } else {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      dialogConfig.data = {
        text: 'Are you sure you want to delete this organization ?'
      };

      const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        data => {
          if (data === true) {
            this.orgChartService.deleteOrganization(id).subscribe(res => {
              this.chart.removeNode(id)
              this.snackbarService.openSnackBar('Organization deleted successfully', 'clear', 'success');
            })
          }
        }
      );
    }
  }

  redirectTo(route: string, type: string) {
    this.router.navigate([route])
  }


  selectNode(nodeId) {
    if (this.selected_node) {
      let node = this.chart.get(this.selected_node)

      if (node['tags'].includes(this.tags.ORGANIZATION_SELECTED)) {
        node["tags"] = [this.tags.ORGANIZATION]
      } else if (node['tags'].includes(this.tags.POSITION_SELECTED)) {
        node["tags"] = [this.tags.POSITION]
      } else if (node['tags'].includes(this.tags.EMPLOYEE_SELECTED)) {
        node["tags"] = [this.tags.EMPLOYEE]
      }
      this.chart.update(node)
    }

    let node = this.chart.get(nodeId)
    if (node['tags'].includes(this.tags.ORGANIZATION)) {
      node["tags"] = [this.tags.ORGANIZATION_SELECTED]
    } else if (node['tags'].includes(this.tags.POSITION)) {
      node["tags"] = [this.tags.POSITION_SELECTED]
    } else if (node['tags'].includes(this.tags.EMPLOYEE)) {
      node["tags"] = [this.tags.EMPLOYEE_SELECTED]
    }
    this.chart.update(node)
    this.selected_node = nodeId
  }

  loadAllPhotos() {
    for (let nodesKey in this.containerIDs) {
      if (!this.hasAlreadyFetchedPhotosForPosition(this.containerIDs[nodesKey])) {
        let node = this.chart.getNode(this.containerIDs[nodesKey]);
        if (node) {
          this.alreadyFetchedPositionPhotosIds.push(node.id)
          node.stChildrenIds.forEach(employeeNodeId => {
            let node = this.chart.getNode(employeeNodeId);
            if (node.tags.includes(this.tags.EMPLOYEE) || node.tags.includes(this.tags.EMPLOYEE_SELECTED)) {
              let employee = this.getEmployeeByNodeId(node.id)
              if (employee) {
                this.getEmployeePhoto(node.id, employee.id)
              }
            }
          })
        }
      }
    }
  }

  loadPhotos() {
    if (this.chartSender?.visibleNodeIds?.length) {
      for (let i = 0; i < this.chartSender.visibleNodeIds.length; i++) {
        let node = this.chart.getNode(this.chartSender.visibleNodeIds[i]);
        if (node.tags.includes(this.tags.POSITION) || node.tags.includes(this.tags.POSITION_SELECTED)
          || node.tags.includes(this.tags.ORGANIZATION) || node.tags.includes(this.tags.ORGANIZATION_SELECTED)) {
          if (!this.hasAlreadyFetchedPhotosForPosition(node.id)) {
            this.alreadyFetchedPositionPhotosIds.push(node.id)
            node.stChildrenIds.forEach(employeeNodeId => {
              let node = this.chart.getNode(employeeNodeId);
              if (node.tags.includes(this.tags.EMPLOYEE) || node.tags.includes(this.tags.EMPLOYEE_SELECTED)) {
                let employee = this.getEmployeeByNodeId(node.id)
                if (employee) {
                  this.getEmployeePhoto(node.id, employee.id)
                }
              }
            })
          }
        }
      }
    }
  }

  private hasAlreadyFetchedPhotosForPosition(positionId) {
    return this.alreadyFetchedPositionPhotosIds.find(id => id == positionId) != null
  }

  getEmployeeByNodeId(nodeId) {
    return this.employees.find(emp => emp.randomId == nodeId)
  }

  toggleTreeLayout() {
    this.orientation = this.orientation == OrgChart.orientation.top ? OrgChart.orientation.left : OrgChart.orientation.top;
    this.chart.setOrientation(this.orientation);
  }


  expandCollapse() {
    // OrgChart.loading.show(this.chart);
    this.chart.expand(null, ["all"]);
    this.chart.maximize();
    this.loadAllPhotos();
  }

  showFilters() {

    const filters = Array.from(
      document.getElementsByClassName('boc-filter') as HTMLCollectionOf<HTMLElement>,
    );

    filters.forEach(filter => {
      if (this.displayFilters) {
        filter.style.display = 'none';
      } else {
        filter.style.display = 'inline';
      }
    });

    this.displayFilters = !this.displayFilters;
  }

  removeChildrenRecusively(nodeId) {
    let node = this.chart.getNode(nodeId)
    if (node) {
      let childrenNodes = node.childrenIds;

      for (let i = 0; i < childrenNodes.length; i++) {
        this.removeChildrenRecusively(childrenNodes[i])
        this.chart.removeNode(nodeId)
      }
    }
  }
}
