import { SchedulerEvent } from '@progress/kendo-angular-scheduler';

/* tslint:disable */

const baseData: any[] = [
    {
        "TaskID": 6,
        "OwnerID": 2,
        "Title": "Work",
        "Description": "Work",
        "StartTimezone": null,
        "Start": "2013-10-10T11:30:00.000Z",
        "End": "2013-10-10T13:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false,
        "color": 'blue',
    },
    {
        "TaskID": 6,
        "OwnerID": 2,
        "Title": "Work",
        "Description": "Work",
        "StartTimezone": null,
        "Start": "2013-10-11T11:30:00.000Z",
        "End": "2013-10-11T13:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false,
        "color": 'blue',
    },
    {
        "TaskID": 6,
        "OwnerID": 2,
        "Title": "Work",
        "Description": "Work",
        "StartTimezone": null,
        "Start": "2013-10-12T11:30:00.000Z",
        "End": "2013-10-12T13:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false,
        "color": 'blue',
    },
    {
        "TaskID": 6,
        "OwnerID": 2,
        "Title": "Work",
        "Description": "Work",
        "StartTimezone": null,
        "Start": "2013-10-13T11:30:00.000Z",
        "End": "2013-10-13T13:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false,
        "color": 'blue',
    },
    {
        "TaskID": 6,
        "OwnerID": 2,
        "Title": "Work",
        "Description": "Work",
        "StartTimezone": null,
        "Start": "2013-10-18T11:30:00.000Z",
        "End": "2013-10-18T13:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false,
        "color": 'blue',
    },
    {
        "TaskID": 6,
        "OwnerID": 2,
        "Title": "Work",
        "Description": "Work",
        "StartTimezone": null,
        "Start": "2013-10-19T11:30:00.000Z",
        "End": "2013-10-19T13:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false,
        "color": 'blue',
    },
    {
        "TaskID": 6,
        "OwnerID": 2,
        "Title": "Work",
        "Description": "Work",
        "StartTimezone": null,
        "Start": "2013-10-20T11:30:00.000Z",
        "End": "2013-10-20T13:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false,
        "color": 'blue',
    },
    {
        "TaskID": 6,
        "OwnerID": 2,
        "Title": "Work",
        "Description": "Work",
        "StartTimezone": null,
        "Start": "2013-10-21T11:30:00.000Z",
        "End": "2013-10-21T13:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false,
        "color": 'blue',
    },
    {
        "TaskID": 6,
        "OwnerID": 2,
        "Title": "Work",
        "Description": "Work",
        "StartTimezone": null,
        "Start": "2013-10-26T11:30:00.000Z",
        "End": "2013-10-26T13:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false,
        "color": 'blue',
    },
    {
        "TaskID": 6,
        "OwnerID": 2,
        "Title": "Work",
        "Description": "Work",
        "StartTimezone": null,
        "Start": "2013-10-27T11:30:00.000Z",
        "End": "2013-10-27T13:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false,
        "color": 'blue',
    },
    {
        "TaskID": 6,
        "OwnerID": 2,
        "Title": "Work",
        "Description": "Work",
        "StartTimezone": null,
        "Start": "2013-10-28T11:30:00.000Z",
        "End": "2013-10-28T13:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false,
        "color": 'blue',
    },
    {
        "TaskID": 6,
        "OwnerID": 2,
        "Title": "Work",
        "Description": "Work",
        "StartTimezone": null,
        "Start": "2013-10-29T11:30:00.000Z",
        "End": "2013-10-29T13:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false,
        "color": 'blue',
    },
    {
        "TaskID": 6,
        "OwnerID": 2,
        "Title": "Work",
        "Description": "Work",
        "StartTimezone": null,
        "Start": "2013-11-03T11:30:00.000Z",
        "End": "2013-11-03T13:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false,
        "color": 'blue',
    },
    {
        "TaskID": 6,
        "OwnerID": 2,
        "Title": "Work",
        "Description": "Work",
        "StartTimezone": null,
        "Start": "2013-11-04T11:30:00.000Z",
        "End": "2013-11-04T13:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false,
        "color": 'blue',
    },
    {
        "TaskID": 6,
        "OwnerID": 2,
        "Title": "Work",
        "Description": "Work",
        "StartTimezone": null,
        "Start": "2013-11-05T11:30:00.000Z",
        "End": "2013-11-05T13:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false,
        "color": 'blue',
    },
    {
        "TaskID": 6,
        "OwnerID": 2,
        "Title": "Work",
        "Description": "Work",
        "StartTimezone": null,
        "Start": "2013-11-06T11:30:00.000Z",
        "End": "2013-11-06T13:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false,
        "color": 'blue',
    },

    {
        "TaskID": 6,
        "OwnerID": 2,
        "Title": "Leave",
        "Description": "Leave",
        "StartTimezone": null,
        "Start": "2013-10-13T11:30:00.000Z",
        "End": "2013-10-13T13:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false,
        "color": 'blue',
    },


    {
        "TaskID": 6,
        "OwnerID": 2,
        "Title": "Call Charlie about the project",
        "Description": "Task",
        "StartTimezone": null,
        "Start": "2013-10-11T11:30:00.000Z",
        "End": "2013-10-11T13:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false,
        "color": 'blue',
    },
    {
        "TaskID": 9,
        "OwnerID": 2,
        "Title": "Alex's Birthday",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-14T02:00:00.000Z",
        "End": "2013-10-14T02:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": true
    },
    {
        "TaskID": 14,
        "OwnerID": 3,
        "RoomID": 2,
        "Title": "Replace the printer on the 1st floor",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-24T10:00:00.000Z",
        "End": "2013-10-24T11:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 15,
        "OwnerID": 1,
        "Title": "Attending HR Conference",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-25T00:00:00.000Z",
        "End": "2013-10-26T00:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": true
    },
    {
        "TaskID": 16,
        "OwnerID": 1,
        "Title": "Business Lunch with Gregory Watkins",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-25T12:00:00.000Z",
        "End": "2013-10-25T13:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 17,
        "OwnerID": 1,
        "Title": "Breakfast with CFO and COO",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-27T08:30:00.000Z",
        "End": "2013-10-27T09:30:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 18,
        "OwnerID": 1,
        "Title": "Job Interview - Mathew Stevens",
        "Description": "Junior Researcher",
        "StartTimezone": null,
        "Start": "2013-10-27T10:00:00.000Z",
        "End": "2013-10-27T11:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 19,
        "OwnerID": 1,
        "Title": "Review CVs with Tim",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-27T11:00:00.000Z",
        "End": "2013-10-27T11:30:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 20,
        "OwnerID": 1,
        "Title": "Lunch with Monica",
        "Description": "Discuss the Employee handbook",
        "StartTimezone": null,
        "Start": "2013-10-27T12:00:00.000Z",
        "End": "2013-10-27T13:30:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 21,
        "OwnerID": 1,
        "Title": "Job Interview - John Stewart",
        "Description": "Accountant",
        "StartTimezone": null,
        "Start": "2013-10-27T14:00:00.000Z",
        "End": "2013-10-27T15:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 22,
        "OwnerID": 1,
        "Title": "Job Interview - Mary Smith",
        "Description": "Accountant",
        "StartTimezone": null,
        "Start": "2013-10-27T15:30:00.000Z",
        "End": "2013-10-27T16:30:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 24,
        "OwnerID": 3,
        "Title": "Register new Access Cards",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-24T12:00:00.000Z",
        "End": "2013-10-24T12:30:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 25,
        "OwnerID": 1,
        "Title": "HR Lecture",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-04T19:00:00.000Z",
        "End": "2013-10-04T21:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": "FREQ=WEEKLY;BYDAY=TU,TH",
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 27,
        "OwnerID": 1,
        "Title": "Job Interview - Laura Bailey",
        "Description": "Helpdesk",
        "StartTimezone": null,
        "Start": "2013-10-28T09:30:00.000Z",
        "End": "2013-10-28T10:30:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 28,
        "OwnerID": 1,
        "Title": "Job Interview - Jenny Baxter",
        "Description": "Helpdesk",
        "StartTimezone": null,
        "Start": "2013-10-28T11:00:00.000Z",
        "End": "2013-10-28T12:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 31,
        "OwnerID": 1,
        "Title": "Team building prep tasks",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-28T14:00:00.000Z",
        "End": "2013-10-28T17:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 32,
        "OwnerID": 1,
        "Title": "Job Interview - Bernard Atkins",
        "Description": "Helpdesk",
        "StartTimezone": null,
        "Start": "2013-10-24T13:30:00.000Z",
        "End": "2013-10-24T14:30:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 34,
        "OwnerID": 1,
        "Title": "Review Job Applications",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-24T15:00:00.000Z",
        "End": "2013-10-24T17:30:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 35,
        "OwnerID": 1,
        "Title": "Grand Canyon tour",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-23T00:00:00.000Z",
        "End": "2013-10-23T00:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": true
    },
    {
        "TaskID": 40,
        "OwnerID": 3,
        "Title": "Install new laptops in conference rooms",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-24T13:30:00.000Z",
        "End": "2013-10-24T18:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 66,
        "OwnerID": 3,
        "Title": "AGM Prep",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-29T08:00:00.000Z",
        "End": "2013-10-29T06:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": true
    },
    {
        "TaskID": 68,
        "OwnerID": 1,
        "RoomID": 2,
        "Title": "Breakfast with Tom",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-24T08:30:00.000Z",
        "End": "2013-10-24T09:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 69,
        "OwnerID": 2,
        "Title": "Team planning meeting",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-24T10:00:00.000Z",
        "End": "2013-10-24T12:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 70,
        "OwnerID": 2,
        "Title": "Support Phone Call",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-24T16:00:00.000Z",
        "End": "2013-10-24T16:30:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 71,
        "OwnerID": 2,
        "Title": "Business breakfast with Caroline",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-25T09:00:00.000Z",
        "End": "2013-10-25T10:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 72,
        "OwnerID": 2,
        "Title": "Discuss preojects' deadlines",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-25T11:00:00.000Z",
        "End": "2013-10-25T11:30:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 73,
        "OwnerID": 2,
        "Title": "Support Meeting",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-25T15:00:00.000Z",
        "End": "2013-10-25T16:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 74,
        "OwnerID": 2,
        "Title": "Dine with Mathew",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-25T18:30:00.000Z",
        "End": "2013-10-25T20:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 79,
        "OwnerID": 2,
        "Title": "Banking",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-26T09:00:00.000Z",
        "End": "2013-10-26T10:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 80,
        "OwnerID": 3,
        "Title": "Software updates",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-25T10:00:00.000Z",
        "End": "2013-10-25T12:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 81,
        "OwnerID": 3,
        "Title": "UPS maintenance",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-25T16:30:00.000Z",
        "End": "2013-10-25T18:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 82,
        "OwnerID": 2,
        "Title": "Support Call",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-26T11:30:00.000Z",
        "End": "2013-10-26T12:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 83,
        "OwnerID": 3,
        "Title": "Phone Sync with NY office ",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-26T13:30:00.000Z",
        "End": "2013-10-26T14:30:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 84,
        "OwnerID": 3,
        "Title": "Phone Sync with Boston Office",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-26T15:00:00.000Z",
        "End": "2013-10-26T16:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 85,
        "OwnerID": 3,
        "Title": "Server maintenance",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-26T18:30:00.000Z",
        "End": "2013-10-26T21:30:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 86,
        "OwnerID": 2,
        "Title": "Status meeting",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-28T13:30:00.000Z",
        "End": "2013-10-28T15:30:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 87,
        "OwnerID": 3,
        "Title": "Helpdesk status meeting",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-27T10:30:00.000Z",
        "End": "2013-10-27T11:30:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 88,
        "OwnerID": 2,
        "Title": "Business Lunch",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-27T12:00:00.000Z",
        "End": "2013-10-27T13:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 89,
        "OwnerID": 3,
        "Title": "Employee database update",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-27T14:00:00.000Z",
        "End": "2013-10-27T15:30:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 91,
        "OwnerID": 2,
        "Title": "Meeting with marketing guys",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-27T17:00:00.000Z",
        "End": "2013-10-27T18:30:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 92,
        "OwnerID": 3,
        "Title": "Meeting with Internet provider",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-28T10:30:00.000Z",
        "End": "2013-10-28T11:30:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 93,
        "OwnerID": 3,
        "Title": "AGM",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-29T20:00:00.000Z",
        "End": "2013-10-29T23:30:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": null,
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 95,
        "OwnerID": 2,
        "Title": "Training",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-03T18:30:00.000Z",
        "End": "2013-10-03T20:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": "FREQ=WEEKLY;BYDAY=MO,WE",
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 114,
        "OwnerID": 3,
        "Title": "Software updates",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-04T09:00:00.000Z",
        "End": "2013-10-04T12:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": "",
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 115,
        "OwnerID": 1,
        "Title": "Breakfast at Starbucks",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-04T08:00:00.000Z",
        "End": "2013-10-04T09:30:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": "",
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 116,
        "OwnerID": 2,
        "Title": "Performance review",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-04T14:00:00.000Z",
        "End": "2013-10-04T17:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": "",
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 118,
        "OwnerID": 1,
        "Title": "HR seminar preparation",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-05T10:00:00.000Z",
        "End": "2013-10-05T12:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": "",
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 119,
        "OwnerID": 3,
        "Title": "Helpdesk weekly meeting",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-05T15:00:00.000Z",
        "End": "2013-10-05T16:00:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": "FREQ=WEEKLY;BYDAY=WE",
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    },
    {
        "TaskID": 120,
        "OwnerID": 3,
        "Title": "Website upload",
        "Description": "",
        "StartTimezone": null,
        "Start": "2013-10-07T07:00:00.000Z",
        "End": "2013-10-07T08:30:00.000Z",
        "EndTimezone": null,
        "RecurrenceRule": "",
        "RecurrenceID": null,
        "RecurrenceException": null,
        "IsAllDay": false
    }
];

const currentYear = new Date().getFullYear();
const parseAdjust = (eventDate: string): Date => {
    const date = new Date(eventDate);
    date.setFullYear(currentYear);
    return date;
};

const randomInt = (min, max): number => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const displayDate = new Date(currentYear, 9, 24);

export const sampleData = baseData.map(dataItem => (
    <SchedulerEvent> {
        id: dataItem.TaskID,
        start: parseAdjust(dataItem.Start),
        startTimezone: dataItem.startTimezone,
        end: parseAdjust(dataItem.End),
        endTimezone: dataItem.endTimezone,
        isAllDay: dataItem.IsAllDay,
        title: dataItem.Title,
        description: dataItem.Description,
        recurrenceRule: dataItem.RecurrenceRule,
        recurrenceId: dataItem.RecurrenceID,
        recurrenceException: dataItem.RecurrenceException,
        color: dataItem.color,
        roomId: dataItem.RoomID,
        ownerID: dataItem.OwnerID
    }
));

export const sampleDataWithResources = baseData.map(dataItem => (
    <SchedulerEvent> {
        id: dataItem.TaskID,
        start: parseAdjust(dataItem.Start),
        startTimezone: dataItem.startTimezone,
        end: parseAdjust(dataItem.End),
        endTimezone: dataItem.endTimezone,
        isAllDay: dataItem.IsAllDay,
        title: dataItem.Title,
        description: dataItem.Description,
        recurrenceRule: dataItem.RecurrenceRule,
        recurrenceId: dataItem.RecurrenceID,
        recurrenceException: dataItem.RecurrenceException,
        roomId: randomInt(1, 2),
        attendees: [randomInt(1, 3)]
    }
));

export const sampleDataWithCustomSchema = baseData.map(dataItem => (
    {
        ...dataItem,
        Start: parseAdjust(dataItem.Start),
        End: parseAdjust(dataItem.End)
    }
));