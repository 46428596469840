import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { OidcAuthService } from '../services/oidc-auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private oidcAuthService: OidcAuthService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler)
        : Observable<HttpEvent<any>> {

        let auth_header = "";
        if (this.oidcAuthService.isAuthenticated()) {
            auth_header = this.oidcAuthService.authorizationHeaderValue;
        }
        req = req.clone({
            setHeaders: {
                "Authorization": auth_header
            }
        });

        // passes on the request to next handler
        // once all the handlers are executed
        // the request is now pushed into the network
        // for handling by the API
        return next.handle(req);
    }
}
