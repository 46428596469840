<ng-container *ngIf="isLoading; else loaded">
    <mat-card>
        <mat-card-header></mat-card-header>
        <mat-card-content>
            <ngx-skeleton-loader
                    count="5"
                    [theme]="{ 
                        'height.px': 50
                    }"
            ></ngx-skeleton-loader>
        </mat-card-content>
    </mat-card>
</ng-container>

<ng-template #loaded>
    <div class="employee-goal-plans-container">
        <div class="top">
            <mat-accordion class="example-headers-align" multi>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title appLocalizationPopupDirective localizationCode="GoalPlan-Filters">
                            {{ 'GoalPlan-Filters' | translate: {Default: "Filters"} }}
                        </mat-panel-title>
                        <mat-panel-description>
                            <div class="k-flex-grow"></div>
                            <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="filter_alt icon">filter_alt</mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="left">
                    <mat-form-field class="search-field" appearance="outline">
                        <input 
                            matInput
                            [placeholder]="translate.instant('Search')"
                            [(ngModel)]="searchValue"
                            (input)="getGoalPlans()"
                        >
                        <mat-icon matPrefix class="material-icons-outlined">
                            search
                        </mat-icon>
                    </mat-form-field>
        
                    <mat-form-field appLocalizationPopupDirective [localizationCode]="'GoalPlan-Status'" appearance="outline">
                        <mat-label>{{ 'GoalPlan-Status' | translate: {Default: "Status"} }}</mat-label>
                        <mat-select (selectionChange)="getGoalPlans()" [(ngModel)]="selectedStatusFilter">
                            <mat-select-trigger>
                                {{ selectedStatusFilter?.name | translate}}
                            </mat-select-trigger>
        
                            <mat-option>-- None --</mat-option>
        
                            <mat-option *ngFor="let option of statusFilterOptions" [value]="option">
                                <div appLocalizationPopupDirective [localizationCode]="option.name">{{ option.name | translate}}</div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <mat-form-field appLocalizationPopupDirective [localizationCode]="'GoalPlan-EmployeeGoalEntryAllowed'" appearance="outline">
                        <mat-label>{{ 'GoalPlan-EmployeeGoalEntryAllowed' | translate: {Default: "Employee Goal Entry Allowed"} }}</mat-label>
                        <mat-select (selectionChange)="getGoalPlans()" [(ngModel)]="employeeGoalEntryAllowedFilter">
                            <mat-select-trigger>
                                {{ employeeGoalEntryAllowedFilter?.name | translate}}
                            </mat-select-trigger>
        
                            <mat-option>All</mat-option>
        
                            <mat-option *ngFor="let option of employeeGoalEntryAllowedFilterOptions" [value]="option">
                                <div appLocalizationPopupDirective [localizationCode]="option.name">{{ option.name | translate}}</div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <!-- Date picker field -->
                    <mat-form-field appLocalizationPopupDirective [localizationCode]="'GoalPlan-From'" appearance="outline" >
                        <mat-label>
                            {{ 'GoalPlan-From' | translate: {Default: "From"} }}
                        </mat-label>
        
                        <input matInput [matDatepicker]="picker" [(ngModel)]="selectedFromFilter" (ngModelChange)="getGoalPlans()">
                        <span fxLayout="row" matSuffix>
                            <mat-datepicker-toggle  [for]="picker"></mat-datepicker-toggle>
                        </span>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
        
                    <!-- Date picker field -->
                    <mat-form-field  appLocalizationPopupDirective [localizationCode]="'GoalPlan-To'" appearance="outline" >
                        <mat-label>
                            {{ 'GoalPlan-To' | translate: {Default: "To"} }}
                        </mat-label>
        
                        <input matInput [matDatepicker]="topicker" [(ngModel)]="selectedToFilter" (ngModelChange)="getGoalPlans()">
                        <span fxLayout="row" matSuffix>
                            <mat-datepicker-toggle  [for]="topicker"></mat-datepicker-toggle>
                        </span>
                        <mat-datepicker #topicker></mat-datepicker>
                    </mat-form-field>
                    </div>

                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title appLocalizationPopupDirective localizationCode="GoalPlan-Sorting">
                            {{ 'GoalPlan-Sorting' | translate: {Default: "Sorting"} }}
                        </mat-panel-title>
                        <mat-panel-description>
                            <div class="k-flex-grow"></div>
                            <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="sort icon">sort</mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                
                    <mat-form-field appLocalizationPopupDirective [localizationCode]="'GoalPlan-SortBy'" appearance="outline">
                        <mat-label>{{ 'GoalPlan-SortBy' | translate: {Default: "Sort By"} }}</mat-label>
                        <mat-select (selectionChange)="getGoalPlans()" [(ngModel)]="sortByFilter">
                            <mat-select-trigger>
                                {{ sortByFilter?.name | translate}}
                            </mat-select-trigger>
        
                            <mat-option>-- None --</mat-option>
        
                            <mat-option *ngFor="let option of sortByFilterOptions" [value]="option">
                                <div appLocalizationPopupDirective [localizationCode]="option.name">{{ option.name | translate}}</div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                  </mat-expansion-panel>
            </mat-accordion>
        </div>

        <ng-container *ngIf="isLoadingGoalPlans; else loadedGoalPlans">
            <ngx-skeleton-loader
                    count="5"
                    [theme]="{ 
                        'height.px': 50
                    }"
            ></ngx-skeleton-loader>
        </ng-container>
        <ng-template #loadedGoalPlans>
            <app-employee-goal-plan
                *ngFor="let goalPlan of goalPlans" 
                class="goal-plan"
                [employeeId]="employeeId"
                [employeeAssignedGoalPlan]="goalPlan"
                [goalEntryAllowed]="goalPlan.isGoalEntryAllowed"
            ></app-employee-goal-plan>
        
            <div *ngIf="goalPlans === undefined || goalPlans.length === 0" class="no-goal-plans-container">
                <h6 appLocalizationPopupDirective localizationCode="NoGoalPlansAssigned">
                    {{ 'NoGoalPlansAssigned' | translate: {Default: "No Goal Plans Assigned"} }}
                </h6>
            </div>
        </ng-template>
    </div>
</ng-template>