import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { WorkRotation, WorkRotationElementSubmit, WorkRotationPosition, WorkRotationSubmit } from '../models/work-rotation.model';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { EnvironmentService } from "@app/core/services/environment.service";


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class WorkRotationService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getWorkRotations(skip?: number, take?: string, filterString?: string, sort?: string): Observable<PagedData<WorkRotation>> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    filterString ? params = params.append('Filter', filterString) : null;
    sort ? params = params.append('sort', sort) : null;

    return this.http
      .get<any>(`${this.envService.env.apiUrl}WorkRotations`, {
        headers: headers,
        params: params
      })
  }

  async getAllWorkRotations(): Promise<PagedData<any>> {
    let take = 100;
    let skip = 0;
    let workRotations = {
      skip: null,
      take: null,
      totalPages: null,
      total: null,
      data: []
    }

    while (workRotations.total === null || skip < workRotations.total) {
        const res = await this.getWorkRotations(skip, take.toString()).toPromise();

        if (res && res.data && res.data.length > 0) {
            workRotations.data = workRotations.data.concat(res.data);
            skip += take;
            workRotations.total = res.total;
        } else {
            break;
        }
    }

    return workRotations;
  }

  getWorkRotationsAsync(): Observable<PagedData<WorkRotation>> {
    let params = new HttpParams();
    params = params.append('skip', 0);
    params = params.append('take', '1000');
    params = params.append('filterString', '');
    params = params.append('sortString', '');
    params = params.append('asOf', '');

    return this.http.get<any>(`${this.envService.env.apiUrl}WorkRotations`, {
      headers: {
        'Content-Type': 'application/json'
      },
      params: params
    });
  }

  getWorkRotation(workRotationId: string): Observable<WorkRotation> {
    return this.http.get<any>(`${this.envService.env.apiUrl}WorkRotations/${workRotationId}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  postWorkRotation(WorkRotation: WorkRotationSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}WorkRotations`, WorkRotation, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  putWorkRotation(workRotationId: string, WorkRotation: WorkRotationSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}WorkRotations/${workRotationId}`, WorkRotation, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  deleteWorkRotation(workRotationId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}WorkRotations/${workRotationId}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  getWorkRotationPositions(workRotationId: string, skip?: number, take?: string): Observable<PagedData<WorkRotationPosition>> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}WorkRotations/${workRotationId}/Positions`, {
      headers: {
        'Content-Type': 'application/json'
      },
      params: params
    });
  }

  postWorkRotationElement(WorkRotationId: string, workRotationElementSubmit: WorkRotationElementSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}WorkRotations/${WorkRotationId}/Elements`, workRotationElementSubmit, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }


  putWorkRotationElement(WorkRotationId: string, WorkRotationElementId: string, workRotationElementSubmit: WorkRotationElementSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}WorkRotations/${WorkRotationId}/Elements/${WorkRotationElementId}`, workRotationElementSubmit, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  deleteWorkRotationElement(workRotationId: string, workRotationElementId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}WorkRotations/${workRotationId}/Elements/${workRotationElementId}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
}
