<app-site-settings-menu>
    <mat-card>
        <mat-card-title class="header">
            <h5 mat-dialog-title *ngIf="holidayGroupId" appLocalizationPopupDirective localizationCode="EditHolidayGroup">{{ 'EditHolidayGroup' | translate: {Default: "Edit Holiday Group"} }}</h5>
            <h5 mat-dialog-title *ngIf="!holidayGroupId" appLocalizationPopupDirective localizationCode="EditHolidayGroup">{{ 'CreateAHolidayGroup' | translate: {Default: "Create A Holiday Group"} }}</h5>

            <div class="buttons-container">
                <button mat-raised-button (click)="navigateBack()">Close</button>
                <button 
                    mat-raised-button 
                    setColor="primaryColour" 
                    color="primary" 
                    (click)="getFormData = !getFormData" 
                    [disabled]="formValid === false">
                Save</button>
            </div>
        </mat-card-title>
        <mat-card-content>
            <div class="position-details-form">
                <ng-container *ngIf="isLoading; else loaded">
                    <ngx-skeleton-loader
                      count="5"
                      [theme]="{ 
                          'height.px': 50
                      }"
                    ></ngx-skeleton-loader>
                </ng-container>
                <ng-template #loaded>
                    <app-form-generator 
                        [formId]="formId"
                        [formData]="formData"
                        [emitFormDataTrigger]="getFormData"
                        (emitFormData)="formDataEmitted($event)"
                        (emitFormStatus)="formStatusUpdated($event)"
                    ></app-form-generator>
                    
                    <div class="k-d-flex-col k-mb-3 k-pb-3" *ngIf="holidayGroupId && !isLoading">
                        <app-data-grid-comp
                            [columns]="columns"
                            [bindingType]="bindingType"
                            [view]="view"
                            [gridData]="gridData"
                            [gridDataResult]="gridDataResult"
                            [pageSize]="pageSize"
                            [skip]="skip"
                            [clearSelectedItems]="clearSelectedItems"
                            category="HolidaysInGroup"
                            [rowSelectionSettings]="{
                                canSelectRows: true,
                                selectableMode: 'multiple',
                                selectRowBy: 'id'
                            }"
                            clickableRows="true"
                            [isLoading]="isLoadingHoliday"
                            (emitPageChangeEvent)="pageChange($event)"
                            (emitOpenDialogEvent)="getHoliday($event)"
                            (emitDeleteEvent)="deleteHolidays($event)"
                        ></app-data-grid-comp>
                    </div>
                </ng-template>
            </div>
        </mat-card-content>
    </mat-card>
</app-site-settings-menu>
