import { Component, OnInit } from '@angular/core';
import { colors } from '@app/consts'; 

@Component({
  selector: 'app-contingent-worker-analysis',
  templateUrl: './contingent-worker-analysis.component.html',
  styleUrls: ['./contingent-worker-analysis.component.scss']
})
export class ContingentWorkerAnalysisComponent implements OnInit {

  chartData = {
    groupA: 1,
    groupB: 1,
    groupC: 3,
    groupD: 9,
    groupE: 9,
    groupF: 16,
    groupG: 1,
  }

  tableColumns = [
    { name: 'Tenure', dataKey: 'label', isSortable: true, position: 'left' },
    { name: 'Total', dataKey: 'value', isSortable: true, position: 'left' }
  ];

  labelData = [
    {
      label: '0 - 3 Months',
      color: '#b676b1',
      value: '1',
    },
    {
      label: '4 - 6 Months',
      color: '#82caaf',
      value: '1',
    },
    {
      label: '12 - 18 Months',
      color: '#75c0e0',
      value: '3',
    },
    {
      label: '19 - 24 Months',
      color: '#fecf6a',
      value: '9',
    },
    {
      label: '2 - 3 Years',
      color: '#39a275',
      value: '9',
    },
    {
      label: '4 - 5 Years',
      color: '#df1c44',
      value: '16',
    },
    {
      label: '6 - 10 Years',
      color: '#8F3985',
      value: '1',
    },
  ]

  public chart: any;
  public colors: typeof colors = colors;

  constructor() { }
  
  public ngOnInit(): void {
    this.initChart();
  }

  public initChart(): void {
    this.chart = {
      color: ['#b676b1', '#82caaf', '#75c0e0', '#fecf6a', '#39a275', '#df1c44', '#8F3985'],
      tooltip: {
        trigger: 'item'
      },
      series: [{
        type: 'pie',
        radius: ['60%', '70%'],
        center: ['50%', '50%'],
        label: {
          show: false
        },
        labelLine: {
          normal: {
            show: false
          }
        },
        hoverAnimation: false,
        avoidLabelOverlap: false,
        data: [
          {
            name: '0 - 3 Months',
            value: this.chartData.groupA
          },
          {
            name: '4 - 6 Months',
            value: this.chartData.groupB
          },
          {
            name: '12 - 18 Months',
            value: this.chartData.groupC
          },
          {
            name: '19 - 24 Months',
            value: this.chartData.groupD
          },
          {
            name: '2 - 3 Years',
            value: this.chartData.groupE
          },
          {
            name: '4 - 5 Years',
            value: this.chartData.groupF
          },
          {
            name: '6 - 10 Years',
            value: this.chartData.groupG
          }
        ]
      }]
    };
  }

}
