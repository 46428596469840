import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { api_routes, db_tables } from '@app/consts';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { GoalPlanService } from '../../../goal-plans/services/goal-plan.service';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-child-goals-data-grid',
  templateUrl: './child-goals-data-grid.component.html',
  styleUrls: ['./child-goals-data-grid.component.scss']
})
export class ChildGoalsDataGridComponent implements OnInit {
  @Input() employeeId: string;
  @Input() goalId: string;
  @Input() parentId: string;
  @Input() category: string;
  
  columns = [
    { field: "objective", tableId: "tfi_PEgObjective", title: this.translate.instant("EmployeeGoals-Objective") },
    { field: "description", tableId: "tfi_PEgDescription", title: this.translate.instant("EmployeeGoals-Description"), type: "longText" },
    { field: "expectedCompletionDate", tableId: "tfi_PEgExpectedCompletionDate", title: this.translate.instant("EmployeeGoals-ExpectedCompletionDate"), type: "date" },
    { field: "actualCompletionDate", tableId: "tfi_PEgActualCompletionDate", title: this.translate.instant("EmployeeGoals-ActualCompletionDate"), type: "date" },
    { field: "goalType", subField: "name", tableId: "tfi_PEgGoalType", title: this.translate.instant("EmployeeGoals-GoalType"),},
    { field: "goalPlan", subField: "name", title: this.translate.instant("EmployeeGoals-GoalPlan"), },
    { field: "weight", tableId: "tfi_PEgWeight", title: this.translate.instant("EmployeeGoals-Weight"), type: "percentage", percentageMaxValue: 1 },
    { field: "percentageComplete", tableId: "tfi_PEgPercentageComplete", title: this.translate.instant("EmployeeGoals-PercentageComplete"), type: "percentage", percentageMaxValue: 1 },
    { field: "parent", subField: "objective", tableId: "tfi_PEgParentGoal", title: this.translate.instant("EmployeeGoals-Parent") },
  ]

  filterCategories = [
    { field: "expectedCompletionDate", tableId: "tfi_PEgExpectedCompletionDate", title: this.translate.instant("EmployeeGoals-ExpectedCompletionDate"), type: "date", dataType: 'Date'},
    { field: "actualCompletionDate", tableId: "tfi_PEgActualCompletionDate", title: this.translate.instant("EmployeeGoals-ActualCompletionDate"), type: "date", dataType: 'Date'},
    { field: "GoalTypeId", subField: "name", tableId: "tfi_PEgGoalType", title: this.translate.instant("EmployeeGoals-GoalType"), dataType: "SpecialLookup", lookupCode: 'GOAL_TYPE'},
    { field: "GoalPlanId", subField: "name", title: this.translate.instant("EmployeeGoals-GoalPlan"), dataType: "SpecialLookup", lookupCode: 'GOAL_PLAN'},
  ]

  sortableColumns: any[] = [
    // { field: "description", sortValue: "description"},
    { field: "expectedCompletionDate", sortValue: "expectedCompletionDate"},
    { field: "actualCompletionDate", sortValue: "actualCompletionDate"},
    { field: "goalType", sortValue: "goalTypeName"},
    { field: "goalPlan", sortValue: "goalPlanName"},
    { field: "percentageComplete", sortValue: "percentageComplete"},
    { field: "weight", sortValue: "weight"},
  ];

  public bindingType: String = "array";
  public view: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 20;
  skip: number = 0;

  getGoalsRequest: any;
  filterString: string;
  searchFilterString: string;
  searchValue: string = "";
  sortString: string;
  translationsLoaded: boolean = false;

  constructor(
    public translate: TranslateService,
    private goalService: GoalPlanService
  ) { }

  ngOnInit(): void {
    if(this.parentId){
      this.getGoals();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.parentId && changes.parentId.currentValue !== undefined){
      this.getGoals();
    }
  }

  getGoals() {
    this.isLoading = true;

    let filter = `(parent.id = "${this.parentId}") AND (id != "${this.goalId}")`;
    if(this.searchFilterString) {
      filter = this.searchFilterString;
    }
    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }

    this.getGoalsRequest = this.goalService.getEmployeeGoals(this.employeeId, String(this.pageSize), this.skip, this.sortString, filter)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          res.employeeGoals.data.map( employeeGoal => employeeGoal.employee.id = res.employeeId )

          this.gridDataResult = {
            data: res.employeeGoals.data,
            total: res.employeeGoals.total,
          }

          // If the goal doesnt have a parent mark it as a sub goal
          this.gridDataResult.data.forEach(
            goal => {
              if(goal.parent === null){
                goal.isSubGoal = false;
              }
              else{
                goal.isSubGoal = true;
              }
            }
          )
        }
      );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.view = this.getGoals();
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;;
    this.getGoals();
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;

    let variants = this.searchValue.split(' ').filter(value => value);
    this.searchFilterString = '';
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `(Objective like "${variant}")`;
        }
    });

    this.getGoalsRequest.unsubscribe();
    this.getGoals();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getGoals();
  }

}
