import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { EmploymentRecordsService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/services/employment-records.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'access-logs-dialog',
  templateUrl: './access-logs-dialog.component.html',
  styleUrls: ['./access-logs-dialog.component.scss']
})
export class AccessLogsDialogComponent implements OnInit {
  private url: string;
  public gridDataResult: GridDataResult;
  public isLoading: boolean;
  public pageSize: number = 20;
  public skip: number = 0;

  constructor(
    private overlayService: OverlayService,
    public translate: TranslateService,
    private dialogRef: MatDialogRef<AccessLogsDialogComponent>,
    private employmentRecordService: EmploymentRecordsService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.url = data.url;
  }

  ngOnInit(): void {
    this.getAccessLogs();
  }

  private getAccessLogs() {
    this.isLoading = true;
    this.employmentRecordService.getAccessLogs(this.url, this.skip, String(this.pageSize))
        .subscribe(pagedAccessLogs => {
          this.gridDataResult = {
            data: pagedAccessLogs.data,
            total: pagedAccessLogs.total,
          };
          this.isLoading = false;
        });
  }

  close(){
    this.dialogRef.close();
  }


  pageChange(event: PageChangeEvent) {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.getAccessLogs();
  }
}
