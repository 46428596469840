import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BenefitsGroupPlanCoverage } from '../../models/benefits-group-plan-coverages.model';
import { BenefitsGroupPlan } from '../../models/benefits-group-plans.model';
import { BenefitsGroupPlanCoverageService } from '../../services/benefits-group-plan-coverage.service';
import { BenefitGroupPlanCoverageDialogComponent } from '../benefit-group-plan-coverage-dialog/benefit-group-plan-coverage-dialog.component';

@Component({
  selector: 'app-benefit-group-plan-card',
  templateUrl: './benefit-group-plan-card.component.html',
  styleUrls: ['./benefit-group-plan-card.component.scss']
})
export class BenefitGroupPlanCardComponent implements OnInit {
  @Input() groupPlan: BenefitsGroupPlan;
  @Output() emitOpenGroupPlanDialog: EventEmitter<any> = new EventEmitter();
  @Output() emitOpenDeletePlanDialog: EventEmitter<any> = new EventEmitter();
  groupPlanCoverages$: Observable<BenefitsGroupPlanCoverage[]>;
  selectedGroupPlanCoverages: BenefitsGroupPlanCoverage[] = [];

  constructor(
    private dialog: MatDialog,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private benefitsGroupPlanCoverageService: BenefitsGroupPlanCoverageService
  ) { }

  ngOnInit(): void {
    this.getGroupPlanCoverages();
  }
  
  getGroupPlanCoverages() {
    this.groupPlanCoverages$ = this.benefitsGroupPlanCoverageService.getBenefitsGroupPlanCoverages(this.groupPlan.group.id, this.groupPlan.plan.id);
  }

  openGroupPlanCoverageDialog(groupPlanCoverage?: BenefitsGroupPlanCoverage){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      groupId: this.groupPlan.group.id,
      planId: this.groupPlan.plan.id,
      coverageId: groupPlanCoverage ? groupPlanCoverage.coverage.id : null,
    };

    const dialogRef = this.dialog.open(BenefitGroupPlanCoverageDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.getGroupPlanCoverages();
        }
      }
    ); 
  }

  deleteGroupPlan(groupPlanCoverage: BenefitsGroupPlanCoverage) {
    this.overlayService.show();

    this.benefitsGroupPlanCoverageService.deleteBenefitsGroupPlanCoverages(groupPlanCoverage.group.id, groupPlanCoverage.plan.id, groupPlanCoverage.coverage.id) 
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.getGroupPlanCoverages();
        this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
      }
    );
  }

  openConfirmDeleteDialog(groupPlanCoverage: BenefitsGroupPlanCoverage) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      text: `Are you sure you want to delete this group plan coverage? (${groupPlanCoverage.coverage.name})`
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.deleteGroupPlan(groupPlanCoverage);
        }
      }
    ); 
  }
}
