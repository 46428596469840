import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { defer, forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EmploymentRecordBenefitDialogComponent } from './components/employment-record-benefit-dialog/employment-record-benefit-dialog.component';
import { EmploymentRecordBenefit } from './models/employment-record-benefit.model';
import { EmploymentRecordBenefitsService } from './services/employment-record-benefits.service';

@Component({
  selector: 'app-employment-record-benefits',
  templateUrl: './employment-record-benefits.component.html',
  styleUrls: ['./employment-record-benefits.component.scss'],
})
export class EmploymentRecordBenefitsComponent implements OnInit {
  @Input() employeeId: string;
  @Input() employmentRecordId: string;
  public columns: any[] = [
    { field: "planName", subField: "text", title: "Plan Name", type: "navigation" },
    { field: "benefitGroup", subField: "text", title: "Benefit Group" },
    { field: "benefitsDivision", subField: "text", title: "Benefit Division" },
    { field: "contractNo", subField: "text", title: "Contract No" },
    { field: "language", subField: "text", title: "Language" },
    { field: "subGroup", subField: "text", title: "Sub Group" },
    { field: "planProvider", subField: "text", title: "Plan Provider" },
    { field: "benefitsSubDivision", subField: "text", title: "Benefit Sub Division" },
    { field: "numberOfDependents", title: "Number Of Dependents" },
    { field: "startDate", title: "Start Date", type: "date" },
    { field: "endDate", title: "End Date", type: "date" },
  ];
  public bindingType: String = "array";
  public view: Observable<GridDataResult>;
  public gridData: any;
  public gridDataResult: GridDataResult;
  isLoading: boolean;
  pageSize: number = 5;
  skip: number = 0;

  constructor(
    private dialog: MatDialog,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private employmentRecordBenefitsService: EmploymentRecordBenefitsService
  ) {}

  ngOnInit(): void {
    this.view = this.employmentRecordBenefitsService.getBenefits(this.employeeId, this.employmentRecordId);
    this.getBenefits();
  }

  getBenefits(): void {
    this.isLoading = true;

    this.employmentRecordBenefitsService.getBenefits(this.employeeId, this.employmentRecordId, this.skip, String(this.pageSize))
    .pipe(
        finalize(()=>{ this.isLoading = false; })
    )
    .subscribe(
      res => {
        this.gridDataResult = {
          data: res.benefits,
          total: res.pagination.TotalCount,
        }
        this.gridData = res.employmentRecords;
      }
    )
  }


  openEmploymentRecordBenefitDialog(employmentRecordBenefit?: EmploymentRecordBenefit) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      employmentRecordId: this.employmentRecordId,
      employeeId: this.employeeId,
      benefitId: employmentRecordBenefit ? employmentRecordBenefit.id : null
    };

    const dialogRef = this.dialog.open(EmploymentRecordBenefitDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.getBenefits();
        }
      }
    );
  }

  deleteEmploymentRecordBenefit(employmentRecordBenefitIds: string[]) {
    const observables = employmentRecordBenefitIds.map(selectedItem => defer(() => this.employmentRecordBenefitsService.deleteBenefit(this.employeeId, this.employmentRecordId, selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.getBenefits();
        this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.getBenefits();
  }

}

