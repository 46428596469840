import { Pipe, PipeTransform } from '@angular/core';
import { HardcodedReport } from '@app/modules/custom-reports/models/custom-report.model';

@Pipe({ name: 'reportsSearch' })
export class ReportsSearchPipe implements PipeTransform {
  public transform(reports: HardcodedReport[], searchText: any): any {
    if (searchText == '' || reports == null) {
      return reports;
    }

    return reports.filter(report => 
        report.name.toUpperCase().includes(searchText.toUpperCase())
    );
  }
}