import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api_routes } from '@app/consts';
import { CostCenter } from '@app/modules/cost-centers/models/cost-center.model';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EnvironmentService } from "@app/core/services/environment.service";

//
const headers = new HttpHeaders({
  'Content-Type': 'application/json',
});

@Injectable({
  providedIn: 'root'
})

@Injectable({
  providedIn: 'root'
})


export class FilterService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getArrayTypes(url): Observable<any> {
      return this.http.get<any>(this.envService.env.apiUrl + url, {
          headers: {
              'Content-Type': 'application/json'
          }
      });
  }

  getPositions(): Observable<any> {
    let params = new HttpParams();
    params = params.append('skip', 0);
    params = params.append('take', '2000');

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}`, {
      headers: headers,
      params: params
    }).pipe(
      map(res => {
        return res.data;
      })
    );
  }

  getGoalTypes(): Observable<any> {
    let params = new HttpParams();
    params = params.append('skip', 0);
    params = params.append('take', '2000');

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_TYPES}`, {
      headers: headers,
      params: params
    }).pipe(
      map(res => {
        return res.data;
      })
    );
  }

  getGoalPlans(): Observable<any> {
    let params = new HttpParams();
    params = params.append('skip', 0);
    params = params.append('take', '2000');

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_PLANS}`, {
      headers: headers,
      params: params
    }).pipe(
      map(res => {
        return res.data;
      })
    );
  }

  getReviewPlans(): Observable<any> {
    let params = new HttpParams();
    params = params.append('skip', 0);
    params = params.append('take', '2000');

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.REVIEW_PLANS}`, {
      headers: headers,
      params: params
    }).pipe(
      map(res => {
        return res.data;
      })
    );
  }

  getGoalTypeFieldSettings(): Observable<any> {
    let params = new HttpParams();
    params = params.append('skip', 0);
    params = params.append('take', '2000');

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_TYPES}/${api_routes.FIELD_SETTINGS}`, {
      headers: headers,
      params: params
    }).pipe(
      map(res => {
        return res.data;
      })
    );
  }

  getOrganizations(): Observable<any> {
    let params = new HttpParams();
    params = params.append('skip', 0);
    params = params.append('take', '2000');

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.ORGANIZATION}/${api_routes.UNITS}`, {
      headers: headers,
      params: params
    }).pipe(
      catchError(err => throwError(err)),
      map(res => {
        return res.data;
      })
    );
  }

  getWorkLocations(): Observable<any> {
    let params = new HttpParams();
    params = params.append('skip', 0);
    params = params.append('take', '2000');

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.WORK_LOCATIONS}`, {
      headers: headers,
      params: params
    }).pipe(
      catchError(err => throwError(err)),
      map(res => {
        return res.data;
      })
    );
  }

  getWorkRotations(): Observable<any> {
    let params = new HttpParams();
    params = params.append('skip', 0);
    params = params.append('take', '2000');

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.WORK_ROTATIONS}`, {
      headers: headers,
      params: params
    }).pipe(
      catchError(err => throwError(err)),
      map(res => {
        return res.data;
      })
    );
  }

  getCostCenters(): Observable<CostCenter> {
    let params = new HttpParams();
    params.append('skip', '0');
    params.append('take', '200');

    return this.http
      .get<any>(`${this.envService.env.apiUrl}${api_routes.COST_CENTERS}`, {
        headers: headers,
        params: params
      })
      .pipe(
        catchError(err => throwError(err)),
        map(res => {
          return res.data.map( costCenter => ({
            id: costCenter.code,
            name: `${costCenter.name} (${costCenter.code})`
          }));
        })
      );
  }

  getCountries(): Observable<any> {
    let params = new HttpParams();
    params = params.append('skip', '0');
    params = params.append('take', '2000');

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.COUNTRIES}`, {
      headers: headers,
      params: params
    }).pipe(
      catchError(err => throwError(err)),
      map(res => {
        return res.data;
      })
    );
  }

  getModules(): Observable<any> {
    return of(
      [
        {
          id: 'Talent',
          name: 'Talent'
        },
        {
            id: 'Performance',
            name: 'Performance'
        },
      ]
    )
  }
}
