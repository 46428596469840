import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { routes } from '@app/consts' ;


@Component({
  selector: 'app-employee-card',
  templateUrl: './employee-card.component.html',
  styleUrls: ['./employee-card.component.scss']
})
export class EmployeeCardComponent {
  @Input() employee;
  
  constructor(
    public router: Router
    // private toastr: ToastrService
  ) {}

  openEditEmployee(id: string): void {
    this.router.navigateByUrl(`${routes.TALENT_TRACK}${routes.EDIT}/${id}`);
  }

}
