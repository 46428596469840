import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {api_routes} from '@app/consts';
import {EmployeeBankDetails} from '../models/employee-bank-details.model';
import {EnvironmentService} from "@app/core/services/environment.service";


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class EmployeeBankDetailsService {

  constructor(private http: HttpClient, private envService: EnvironmentService) {}

  getBankDetails(employeeId: string, asOf?: string): Observable<EmployeeBankDetails> {
    let params = new HttpParams();
    asOf ? params = params.append('AsOf', asOf) : null;
    
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.BANK_DETAIL}`, {
      headers: headers,
      params: params
    });
  }

  updateBankDetails(employeeId: string, employeeBankDetailsSubmit: any): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.BANK_DETAIL}`, employeeBankDetailsSubmit, {
      headers: headers
    });
  }
}
