<mat-card>
    <kendo-chart>
        <kendo-chart-series>
          <kendo-chart-series-item
            type="donut"
            [data]="data"
            categoryField="kind"
            field="share"
          >
            <kendo-chart-series-item-labels
              [content]="labelContent"
              color="#fff"
              background="none"
            >
            </kendo-chart-series-item-labels>
          </kendo-chart-series-item>
        </kendo-chart-series>
        <kendo-chart-legend [visible]="false"></kendo-chart-legend>
      </kendo-chart>
</mat-card>