import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { ChangeReasonDialogComponent } from '@app/shared/components/change-reason-dialog/change-reason-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { TableField, TableFieldVerbose } from '../security-setup/models/table-field.model';
import { Table } from '../security-setup/models/table.model';
import { SecuritySetupService } from '../security-setup/services/security-setup.service';
interface TableGroup {
  id: string;
  name: string;
  parentTable: { id: string; name: string } | null;
}

interface Group {
  groupName: string;
  groupId: string;
  tables: TableGroup[];
}

@Component({
  selector: 'app-table-field-editor',
  templateUrl: './table-field-editor.component.html',
  styleUrls: ['./table-field-editor.component.scss']
})

export class TableFieldEditorComponent implements OnInit {

  public selectedTable: Table;
  public tableGroups: Group[];
  public filteredTableGroups: Group[];
  public fieldList: Array<TableField>;
  public selectedField: TableField;
  public selectedFieldVerbose: TableFieldVerbose;
  updatedFieldPermission: any;
  isLoading: boolean = true;
  formData: any;
  formId: string = 'frm_yegAe6EGDJAYP0';
  changeReasonFormId: string = 'frm_yPsGlEqUSjqwkp';
  getFormData: boolean = false;
  formValid: boolean = false;
  loadingTableField: boolean;
  loadingFields: boolean;
  loadingTables: boolean;
  filteredFieldList: TableField[];

  constructor(
    private snackbarService: SnackbarService,
    private securitySetupService: SecuritySetupService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private route: ActivatedRoute,
    public router: Router,
    private overlayService: OverlayService,
  ) {}

  ngOnInit(): void {
    this.getTables();
    // this.handleSelectTableClick(this.route.snapshot.paramMap.get('tableId'));
    // this.handleSelectFieldClick(this.route.snapshot.paramMap.get('fieldId'));
  }

  get routeTable() {
    return this.route.snapshot.paramMap.get('tableId')
  }

  get routeField() {
    return this.route.snapshot.paramMap.get('fieldId')
  }

  getTables() {
    this.loadingTables = true;

    this.securitySetupService.getTables(0, '200')
    .pipe(
      finalize(()=>{
        this.loadingTables = false;
      })
    )
    .subscribe(
        pagedTableList => {
            let tableList = pagedTableList.data;
            let tableMap = [];
            tableList.map(table => {
                if (table.parentTable != null) {
                    let groupIndex = tableMap.findIndex(t => t.groupId === table.parentTable.id);

                    if (groupIndex >= 0) {
                        tableMap[groupIndex].tables.push(table);
                    } else {
                        tableMap.push({groupName: table.parentTable.name, groupId: table.parentTable.id, tables: [table]});
                    }
                } else {
                    tableMap.push({groupName: table.name, groupId: table.id, tables: [table]});
                }
            });
            this.tableGroups = tableMap;
            this.filteredTableGroups = [...this.tableGroups];

            if(this.routeTable){
              this.handleSelectTableClick(tableList.find( table => table.id === this.routeTable ));
            }

        }
    );
  }

  getFields(tableId) {
    this.loadingFields = true;

    this.securitySetupService.getFields(tableId, 0, '200')
    .pipe(
      finalize(()=>{
        this.loadingFields = false;
      })
    )
    .subscribe(
      pagedFieldList => {
          this.fieldList = pagedFieldList.data;
          this.filteredFieldList = [...this.fieldList];

          if(this.routeField){
            this.handleSelectFieldClick(this.fieldList.find( field => field.id === this.routeField ));
          }
      }
    );
  }

  getSelectedField() {
    this.loadingTableField = true;

    this.securitySetupService.getField(this.selectedTable.id, this.selectedField.id)
    .pipe(
      finalize(()=>{
        this.loadingTableField = false;
      })
    )
    .subscribe(
      res => {
        this.selectedFieldVerbose = res;
        this.createFormData();
      }
    );
  }

  onTableDropdownSearchKeyReceived(searchString: string, tableGroups: Group[]){
    this.filteredTableGroups = this.filterTableGroups(searchString, tableGroups);
  }

  filterTableGroups(value: string, groups: Group[]): Group[] {
    const lowerSearchString = value.toLowerCase();
  
    return groups.map(group => {
      if (group.groupName.toLowerCase().includes(lowerSearchString)) {
        // If the search string is in the group name, keep all children
        return group;
      } else {
        // Otherwise, filter the children to only those that contain the search string in their name
        const filteredTables = group.tables.filter(table => 
          table.name.toLowerCase().includes(lowerSearchString)
        );
        return { ...group, tables: filteredTables };
      }
    }).filter(group => group.tables.length > 0); // Remove groups with no tables
  }

  onFieldDropdownSearchKeyReceived(searchString: string, fields: TableField[]){
    const lowerSearchString = searchString.toLowerCase();

    this.filteredFieldList = fields.filter( field => field.name.toLowerCase().includes(lowerSearchString) )
  }

  handleSelectTableClick(table) {
    this.selectedField = null;
    this.selectedFieldVerbose = null;
    if(table !== '' && table !== undefined) {
      this.selectedTable = table;
      this.getFields(table.id);
    }
    else {
      this.selectedTable = null;
      this.fieldList = [];
    }
  }

  handleSelectFieldClick(tableField) {
    if(tableField) {
      this.selectedField = tableField;
      this.getSelectedField();
    }
    else {
      this.selectedFieldVerbose = null;
      this.selectedField = null;
    }
  }

  createFormData() {
    this.formData = {
      id: this.selectedFieldVerbose ? this.selectedFieldVerbose.id : null,
      changeReason: '',
      changeReasonComments: '',
      name: this.selectedFieldVerbose ? this.selectedFieldVerbose.name : null,
      enabled: this.selectedFieldVerbose ? this.selectedFieldVerbose.enabled : null,
      requiredField: this.selectedFieldVerbose ? this.selectedFieldVerbose.requiredField : null,
      minimumValue: this.selectedFieldVerbose ? this.selectedFieldVerbose.minimumValue : null,
      maximumValue: this.selectedFieldVerbose ? this.selectedFieldVerbose.maximumValue : null,
      defaultValue: this.selectedFieldVerbose ? this.selectedFieldVerbose.defaultValue : null,
      format: this.selectedFieldVerbose ? this.selectedFieldVerbose.format : null,
      originalCreatedOn: this.selectedFieldVerbose ? this.selectedFieldVerbose.version?.createdOn : null,
      asOf: moment().format(),
    };
  }

  formDataEmitted(formData) {
    this.openChangeReasonDialog(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  openChangeReasonDialog(formData: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formId: this.changeReasonFormId,
    };

    const dialogRef = this.dialog.open(ChangeReasonDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
          if (data) {
              this.addChangeReasonsToFormData(formData, data);
          }
      }
    );
  }

  addChangeReasonsToFormData(formData: any, changeReasonFormData: any) {
    let merged = {...formData, ...changeReasonFormData};
    this.save(merged);
  }

  save(formData) {
    this.overlayService.show();

    this.securitySetupService.updateField(this.selectedTable.id, this.selectedField.id, formData)
    .pipe(
      finalize(()=>{
        this.overlayService.hide();
      })
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

}
