import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import { FormFieldService } from '@app/core/services/form-field.service';
import { FieldRenameDialogComponent } from '@app/shared/components/field-rename-dialog/field-rename-dialog.component';
import { fieldTypes } from '@app/consts/index';
import { forkJoin, Observable } from 'rxjs';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { finalize } from 'rxjs/operators';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { Culture } from '@app/shared/models/system-language/culture.model';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit {

  @Input() formId: string;
  @Input() data;
  @Input() submittingForm: boolean;
  @Input() emptyForm: boolean;
  @Input() canDelete: boolean;
  @Input() canViewHistory: boolean;
  @Input() hideTitle?: boolean;
  @Input() hideButtons?: boolean;
  @Output() formSubmit: EventEmitter<any> = new EventEmitter();
  @Output() getHistory: EventEmitter<any> = new EventEmitter();
  @Output() dialogFormSubmit: EventEmitter<any> = new EventEmitter();
  @Output() deleteEntry: EventEmitter<any> = new EventEmitter();
  @Output() closeDialog: EventEmitter<any> = new EventEmitter();

  public fieldTypes: typeof fieldTypes = fieldTypes;
  formName: string;
  formInfo: any;
  formData: any;
  formSubmissionEndpoint: string;
  form: UntypedFormGroup;
  payLoad: Object;
  showForm: boolean = true;
  isLoading: boolean = true;

  stepHour = false;
  stepMinute = false;
  stepSecond = false;
  showSpinners;
  showSeconds;
  color;
  touchUi;
  enableMeridian;
  hideTime;
  disableMinute;
  cultures: Culture[];

  constructor(
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private formFieldService: FormFieldService,
    private lookupService: LookupService,
    private cultureService: CultureService
  ) {  }

  ngOnInit() {
    this.cultureService.getCultures().subscribe( res => { this.cultures = res; } );

    this.getFormFields()
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (formLayout) => {
          this.formData = formLayout[1];
          this.formInfo = formLayout[0].body;
    
          this.setFormName(formLayout[0]);
          // this.refactorFormData(this.formData);
          this.getDropdownOptions(this.formData);
          if(this.data) {
            this.addDataProperties(this.formData, this.data);
          }

          this.form = this.toFormGroup(this.formData);

        },
        err => {
          console.log("error: " + err);
        },
        () => console.log("done")
      );
  }

  getFormFields(): Observable<any> {
    return forkJoin(this.formFieldService.getFormDetails(this.formId), this.formFieldService.getFormFieldDetails(this.formId));
  }

  toggleForm() {
    this.showForm = !this.showForm;
  }
  
  setSubmissionEndpoint() {
    this.formSubmissionEndpoint = 'Employee/';
  }
  
  setFormName(form) {
    this.formName = form.body.name;
  }
  
  getDropdownOptions(form) {
    form.body.forEach(field => { 

      if(field.type === fieldTypes.DROPDOWN) {
        field.properties.forEach(element => {
          if(element.property === "lookupGroup"){
            this.lookupService.getLookups(`lookup/${(element.value).toUpperCase()}`)
            .subscribe((res) => {
              field.dropDownOptions = res;
            })
          }
        });


        // field.tableField
        // ? this.formFieldService.getDropdownOptions((field.tableField.properties[0].value).toUpperCase())
        //     .pipe()
        //     .subscribe((res) => {
        //       field.dropDownOptions = res.body;
        //     },
        //     err => {
        //       console.log("error: " + err);
        //     },
        //     () => console.log("done"))
        // : field.properties.forEach(element => {
        //   if(element.property === "lookupGroup"){
        //     this.lookupService.getLookups(`lookup/${(element.value).toUpperCase()}`)
        //     .subscribe((res) => {
        //       field.dropDownOptions = res;
        //     })
        //   }
        // });
      }      

      field.children.forEach(child => {
        child.properties.forEach(element => {
          if(element.property === "lookupGroup"){
            this.lookupService.getLookups(`lookup/${(element.value).toUpperCase()}`)
            .subscribe((res) => {
              child.dropDownOptions = res;
            })
          }
        });

        child.type === fieldTypes.DROPDOWN
        ? this.formFieldService.getDropdownOptions((child.tableField.properties[0].value).toUpperCase())
            .pipe()
            .subscribe((res) => {
              child.dropDownOptions = res.body;
            },
            err => {
              console.log("error: " + err);
            },
            () => console.log("done"))
        : null
      })
    });
  }

  refactorFormData(form){
    form.body.forEach(field => {
      
      field.children.forEach(child => {
        child.properties.forEach(prop => {
          child[prop.property] = prop.value;
        });
      });

      field.properties.forEach(prop => {
        field[prop.property] = prop.value;
      });
    });
  }

  addDataProperties(form, data) {
    form.body.forEach(field => { 

      if(field.type === fieldTypes.CONTAINER){
        field.children.forEach(child => {
          child.value = data[child.formControl];
        });
      }
      else {
        field.value = data[field.formControl];
      }

    });
  }

  toFormGroup(form) {
    const group: any = {};

    form.body.forEach(field => { 
      if(field.type === fieldTypes.CONTAINER) {
        field.children.forEach( child => {
          group[child.formControl] = this.toFormControl(child);
        });
      }
      else {


        field.type === fieldTypes.TEXT_LOCALIZATION 
          ? group['localizations'] = this.fb.array([])
          : group[field.formControl] = this.toFormControl(field);
        
        
      }      
    });

    if(group['localizations']) {
      form.body[2].value.forEach(
        localization => {
          // this.addNewLocalization(localization.culture, localization.text);
          const localizationForm = this.fb.group({
            culture: [localization.culture || '', Validators.required],
            localization: [localization.localization || '', Validators.required]
          });
      
          group['localizations'].push(localizationForm);
        }
      )
    }

    return new UntypedFormGroup(group);
  }

  toFormControl(field) {
    let validators = [];
  
    if (field.requiredField === 'true') { validators.push(Validators.required) }
    if (field.validation == 'email') { validators.push(Validators.email) }
    if (field.minimumValue) { validators.push(Validators.minLength(field.minimumValue)) }
    if (field.maximumValue) { validators.push(Validators.maxLength(field.maximumValue)) }

    if(field.type === fieldTypes.TOGGLE) {
      return new UntypedFormControl(
        {
          value: field.value || false,
          disabled: (field.disabled === 'true') //converting string to boolean
        }, validators);  
    }
    else if(field.type === fieldTypes.DROPDOWN) {
      return new UntypedFormControl(
        {
          value: field.value ? field.value.id || null : null,
          disabled: (field.disabled === 'true') //converting string to boolean
        }, validators); 
    }
    else {
      return new UntypedFormControl(
        {
          value: field.value || null,
          disabled: (field.disabled === 'true') //converting string to boolean
        }, validators);
    }
  }

  get localizations() {
    return this.form.controls["localizations"] as UntypedFormArray;
  }

  //Create a textLocalization form group object to add to the localizations form array
  addNewLocalization(culture?: string, localization?: string) {
    const localizationForm = this.fb.group({
      culture: [culture || '', Validators.required],
      localization: [localization || '', Validators.required]
    });

    this.localizations.push(localizationForm);
  }

  deleteLocalization(index: number) {
    this.localizations.removeAt(index);
  }

  getFormValidationErrors() {
    let errors = '';

    Object.keys(this.form.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.form.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            switch (keyError) {
              case 'required': errors = `Field is required!`; break;
              case 'pattern': errors = `Field has wrong pattern!`; break;
              case 'email': errors = `Not a valid email!`; break;
              case 'minlength': errors = `Field has wrong length! Required length: ${controlErrors[keyError].requiredLength}`; break;
              case 'areEqual': errors = `${key} must be equal!`; break;
              default: errors = `${key}: ${key}: ${keyError}`;
            }
          });
        }
    });
    return errors;
  }

  openConfirmDeleteDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
        text: 'Are you sure you want to delete this?'
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.delete();
        }
      }
    ); 
  }

  emitGetHistory() {
    this.getHistory.emit();
  }

  emitCloseDialog() {
    this.closeDialog.emit();
  }

  delete() {
    this.payLoad = {
      formInfo: this.formInfo,
      submitData: this.form.getRawValue()
    }

    this.deleteEntry.emit(this.payLoad);
  }

  onSubmit() {
    this.payLoad = {
      formInfo: this.formInfo,
      submitData: this.form.getRawValue()
    }
    this.dialogFormSubmit.emit(this.payLoad);
    this.formSubmit.emit(this.payLoad);
  }
}
