import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { finalize } from 'rxjs/operators';
import { WorkRotation } from '../../models/work-rotation.model';
import { WorkRotationService } from '../../services/work-rotation.service';
import { WorkRotationDialogComponent } from '../work-rotation-dialog/work-rotation-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-work-rotations-expansion-panels',
  templateUrl: './work-rotations-expansion-panels.component.html',
  styleUrls: ['./work-rotations-expansion-panels.component.scss']
})
export class WorkRotationsExpansionPanelsComponent implements OnInit {
  @Input() refreshView: boolean;

  workRotations: WorkRotation[];
  isLoading: boolean;
  pageSize: number = 20;
  skip: number = 0;


  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private workRotationService: WorkRotationService
  ) { }

  ngOnInit(): void {
    this.getWorkRotations();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.refreshView && (changes.refreshView.currentValue !== changes.refreshView.previousValue)) {
      this.getWorkRotations();
    }
  }

  getWorkRotations(): void {
    this.isLoading = true;

    this.workRotationService.getWorkRotations(this.skip, String(this.pageSize))
        .pipe(
            finalize(()=>{ this.isLoading = false; })
        )
        .subscribe(
            res => {
              this.workRotations = res.data
            }
        )
  }


  openConfirmDeleteDialog(workRotaionId: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
        text: 'Are you sure you want to delete this?'
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.deleteWorkRotation(workRotaionId);
        }
      }
    );
  }

  deleteWorkRotation(workRotaionId: string) {
    this.overlayService.show();

    this.workRotationService.deleteWorkRotation(workRotaionId)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.getWorkRotations();
        this.snackbarService.openSnackBar(this.translate.instant('DeletedSuccessfully'), 'clear', 'success');
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  openWorkRotationDialog(workRotationId?: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      workRotationId: workRotationId
    };

    const dialogRef = this.dialog.open(WorkRotationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.getWorkRotations();
        }
      }
    );
  }

}
