import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TableColumn } from './table-column.model';
import { DatePipe } from '@angular/common';
import { Regex } from '@app/consts/index';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  public tableDataSource = new MatTableDataSource([]);
  public displayedColumns: string[];
  public btnsColumn: string = "btns-column";
  resultsLength = 0;

  @ViewChild(MatPaginator) matPaginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) matSort: MatSort;

  @Input() isPageable = false;
  @Input() isSortable = false;
  @Input() isFilterable = false;
  @Input() tableColumns: TableColumn[];
  @Input() rowActionIcon: string[];
  @Input() pagination: Object;
  @Input() paginationSizes: number[] = [5, 10, 15];
  @Input() defaultPageSize = this.paginationSizes[1];
  @Input() parent: Object;
  @Input() setPaginatorBugFix: boolean;
  // this property needs to have a setter, to dynamically get changes from parent component
  @Input() set tableData(data: any[]) {
    this.changeDateFormat(data);
    this.setTableDataSource(data);
  }

  @Output() sort: EventEmitter<MatSort> = new EventEmitter();
  @Output() rowAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageChangeAction: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
    const columnNames = this.tableColumns.map((tableColumn: TableColumn) => tableColumn.name);
    if (this.rowActionIcon) {
      this.displayedColumns = [this.btnsColumn, ...columnNames]
    } else {
      this.displayedColumns = columnNames;
    }
  }

  // we need this, in order to make pagination work with *ngIf
  ngAfterViewInit(): void {
    if(this.setPaginatorBugFix) {
      this.tableDataSource.paginator = this.matPaginator;
    }
  }

  setTableDataSource(data: any) {
    this.tableDataSource = new MatTableDataSource<any>(data);
    this.tableDataSource.paginator = this.matPaginator;
    this.tableDataSource.sort = this.matSort;
  }

  //If data is returned as 1991-02-06T00:00:00 or 2021-05-18T20:31:01.5134269+00:00 it will be converted to standard angular date pipe
  changeDateFormat(data) {
    const regex = Regex.DATE_TIME;
    let pipe = new DatePipe('en-US');

    data.forEach(element => {
      for (let key of Object.keys(element)) {
        // if(Array.isArray(element[key]) == false && element[key] !== null && element[key].match(regex)){
        if(typeof element[key] === 'string' && element[key].match(regex)){
          element[key] = pipe.transform(element[key]);
        }
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
  }

  sortTable(sortParameters: MatSort) {
    // defining name of data property, to sort by, instead of column name
    sortParameters.active = this.tableColumns.find(column => column.name === sortParameters.active).dataKey;
    this.sort.emit(sortParameters);
  }

  emitRowAction(row: any, icon: any) {
    this.rowAction.emit({row, icon, parent: this.parent});
  }

  emitChangePage(event: PageEvent) {
    this.pageChangeAction.emit(event);
  }
}
