import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';

@Component({
  selector: 'app-direct-deposit-details',
  templateUrl: './direct-deposit-details.component.html',
  styleUrls: ['./direct-deposit-details.component.scss']
})
export class DirectDepositDetailsComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.createForm();

  }

  createForm() {
    this.form = this.fb.group({
      bankName: ['CIBC'],
      accountNumber: ['23423556548'],
      routingNumber: ['002-7689-009876'],
      accountType: ['checking'],
    });
  }

  save() {
    this.overlayService.show();

    setTimeout(() => {
      this.overlayService.hide();
      this.snackbarService.openSnackBar('Direct deposit details saved successfully', 'clear', 'success');
    }, 2000);
  }

}
