import { Component, OnInit } from '@angular/core';
import { Form, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { routes } from '@app/consts';
import { MetadataFormService } from '@app/core/services/metadata/metadata-form.service';
import { MetadataTableService } from '@app/core/services/metadata/metadata-table.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { finalize } from 'rxjs/operators';
import { Router } from "@angular/router";
import { Culture } from '@app/shared/models/system-language/culture.model';
import { Table } from '../edit-form-v3/models/form.model';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import * as moment from 'moment';

@Component({
  selector: 'app-create-form',
  templateUrl: './create-form.component.html',
  styleUrls: ['./create-form.component.scss']
})
export class CreateFormComponent implements OnInit {
  form: UntypedFormGroup;
  cultures: Culture[];
  formDetails: Form;
  tables: Table[];

  constructor(
    private fb: UntypedFormBuilder,
    private cultureService: CultureService,
    private metadataFormService: MetadataFormService,
    private metadataTableService: MetadataTableService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getTables();
    this.cultureService.getCultures().subscribe( res => { this.cultures = res } );
    this.createForm();
  }

  getTables() {
    this.metadataTableService.getTables(0, '1000')
      .subscribe(
        res => {
          this.tables = res.data;
        }
      );
  }

  createForm() {
    this.form = this.fb.group({
      id: null,
      asOf: [moment().subtract(1, "days").format()],
      changeReason: [''],
      changeReasonComments: [''],
      name: this.fb.array([]),
      table: [null],
    });

    this.addNewLocalization();
  }

  get localizations() {
    return this.form.controls["name"] as UntypedFormArray;
  }

  //Create a textLocalization form group object to add to the localizations form array
  addNewLocalization(culture?: string, text?: string) {
    const localizationForm = this.fb.group({
      culture: [culture || '', Validators.required],
      text: [text || '', Validators.required]
    });

    this.localizations.push(localizationForm);
  }

  deleteLocalization(index: number) {
    this.localizations.removeAt(index);
  }

  save() {
    this.overlayService.show();

    this.metadataFormService.postForm(this.form.value)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
          this.snackbarService.openSnackBar('Form created successfully', 'clear', 'success');
          this.openEditForm(res.formId);
      },
    )
  }

  openEditForm(id: string): void {
    this.router.navigateByUrl(`${routes.FORM_EDIT}/${id}`);
  }

}
