import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-filter-dropdown',
    template: `
    <form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
      <label for="filter-type">Filter Type:</label>
      <select formControlName="filterType" id="filter-type">
        <option value="like">Like</option>
        <option value="not-like">Not Like</option>
        <option value="not-empty">Not Empty</option>
      </select>
      <div *ngIf="showInput()">
        <label for="filter-value">Filter Value:</label>
        <input formControlName="filterValue" id="filter-value">
      </div>
      <button type="submit">Filter</button>
    </form>
  `
})
export class FilterDropdownComponent implements OnInit {

    filterForm = new FormGroup({
        filterType: new FormControl('like'),
        filterValue: new FormControl('')
    });

    showInput() {
        const filterType = this.filterForm.get('filterType').value;
        return filterType === 'like' || filterType === 'not-like';
    }

    onSubmit() {
        const filterType = this.filterForm.get('filterType').value;
        const filterValue = this.filterForm.get('filterValue').value;
        // Use filterType and filterValue to filter your data
    }

    ngOnInit(): void {
    }
}
