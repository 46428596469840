<mat-card class="form-details-card">
    <mat-card-title class="header">
        <div></div>
        
        <div>
            <button mat-stroked-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openDemoFormDialog()">
                View Demo Form
            </button>

            <button mat-stroked-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openFormElementDialog(form.table?.id, null, null, formElements.length)">
                <mat-icon class="material-icons-outlined">add</mat-icon> Add Form Element
            </button>

            <button mat-stroked-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="saveFormElementsOrder()">
                Save Order
            </button>
        </div>
    </mat-card-title>
    <mat-card-content>
        <ng-container  *ngIf="!isFinishedLoading(); else LoadedFormElements">
            <div class="loading-container" style="width:100%;">
                <ngx-skeleton-loader
                    count="5"
                    [theme]="{ 
                    'height.px': 50
                    }"
                ></ngx-skeleton-loader>
            </div>
        </ng-container>

        <ng-template #LoadedFormElements>
            <div *ngIf="formElements.length === 0">
                <h6>No Form Elements</h6>
            </div>

            <div cdkDropList (cdkDropListDropped)="drop($event, formElements)">
                
                <div class="form-element-container" *ngFor="let formElement of formElements" cdkDrag>
                    <div class="example-custom-placeholder" *cdkDragPlaceholder></div>

                    <ng-container *ngIf="formElement.formElementType.id !== fieldTypes.CONTAINER; else containerElement">
                        <app-form-field
                            [field]="formElement"
                        ></app-form-field>
                        <div class="form-element-controls">
                            <span 
                                *ngIf="currentEmployeeDetails.userType !== 'Employee'"
                                (click)="openConfirmDeleteDialog(formElement)" 
                                class="material-icons-outlined clickable-icon"
                                setColor 
                                [setColorRules]="[
                                    {
                                        color: 'primaryColour',
                                        events: ['hover']
                                    }
                                ]" 
                                matTooltip="Delete"
                                matTooltipPosition="above">
                                delete
                            </span>
                            <span 
                                appPreventDoubleClick 
                                (throttledClick)="openFormElementDialog(form.table?.id, formElement, form)" 
                                class="material-icons-outlined clickable-icon"
                                setColor 
                                [setColorRules]="[
                                    {
                                        color: 'primaryColour',
                                        events: ['hover']
                                    }
                                ]" 
                                matTooltip="Edit"
                                matTooltipPosition="above">
                                edit
                            </span>
                        </div>
                    </ng-container>
    
                    <ng-template #containerElement>
                        <div class="form-section">
                            <div class="form-section-title">
                                <h6>
                                    {{formElement.text}} ({{formElement.id}})

                                    <span class="material-icons-outlined"  *ngIf="formElement.hidden === 'true'" matSuffix>
                                        disabled_visible
                                    </span>
                                </h6>
                                
    
                                <div class="form-element-controls">
                                    <span 
                                        *ngIf="currentEmployeeDetails.userType !== 'Employee'"
                                        (click)="openConfirmDeleteDialog(formElement)" 
                                        class="material-icons-outlined clickable-icon"
                                        setColor 
                                        [setColorRules]="[
                                            {
                                                color: 'primaryColour',
                                                events: ['hover']
                                            }
                                        ]" 
                                        matTooltip="Delete"
                                        matTooltipPosition="above">
                                        delete
                                    </span>
                                    <span 
                                        appPreventDoubleClick 
                                        (throttledClick)="openFormElementDialog(form.table?.id, formElement)" 
                                        class="material-icons-outlined clickable-icon"
                                        setColor 
                                        [setColorRules]="[
                                            {
                                                color: 'primaryColour',
                                                events: ['hover']
                                            }
                                        ]" 
                                        matTooltip="Edit"
                                        matTooltipPosition="above">
                                        edit
                                    </span>
                                </div>
                            </div>
                            <div class="form-section-contents">
                                <div class="section-row">
                                    <button mat-stroked-button class="add-child-element-button" setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openFormElementDialog(form.table?.id, null, formElement, formElement.children.length)">
                                        <mat-icon class="material-icons-outlined">add</mat-icon> Add Child Element
                                    </button> 
    
                                    <div class="child-elements" cdkDropList (cdkDropListDropped)="drop($event, formElement.children)">
                                        <ng-container *ngFor="let childElement of formElement.children">
                                            <div class="child-element" cdkDrag>
                                                <app-form-field
                                                    [field]="childElement"
                                                ></app-form-field>
                                                <div class="form-element-controls">
                                                    <span 
                                                        *ngIf="currentEmployeeDetails.userType !== 'Employee'"
                                                        (click)="openConfirmDeleteDialog(childElement)" 
                                                        class="material-icons-outlined clickable-icon"
                                                        setColor 
                                                        [setColorRules]="[
                                                            {
                                                                color: 'primaryColour',
                                                                events: ['hover']
                                                            }
                                                        ]" 
                                                        matTooltip="Delete"
                                                        matTooltipPosition="above">
                                                        delete
                                                    </span>
                                                    <span 
                                                        appPreventDoubleClick 
                                                        (throttledClick)="openFormElementDialog(form.table?.id, childElement)" 
                                                        class="material-icons-outlined clickable-icon"
                                                        setColor 
                                                        [setColorRules]="[
                                                            {
                                                                color: 'primaryColour',
                                                                events: ['hover']
                                                            }
                                                        ]" 
                                                        matTooltip="Edit"
                                                        matTooltipPosition="above">
                                                        edit
                                                    </span>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>

        </ng-template>
    </mat-card-content>
</mat-card>