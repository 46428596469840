import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { db_tables } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { FormGeneratorComponent } from '@app/shared/components/form-generator/form-generator.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { EmploymentRecord } from '../../models/employment-record.model';
import { EmploymentRecordsService } from '../../services/employment-records.service';

@Component({
  selector: 'app-employement-records-v2',
  templateUrl: './employement-records-v2.component.html',
  styleUrls: ['./employement-records-v2.component.scss']
})
export class EmployementRecordsV2Component implements OnInit {
  formGeneratorComponents: {id: string, formGeneratorComponent: FormGeneratorComponent}[] = [];
  isLoading: boolean;
  employeeId: string;
  employmentRecords: EmploymentRecord[] = [];
  formId: string = 'frm_ygxyk9Zfg6zaXh';
  dialogRef: any;

  constructor(
    private dialog: MatDialog,
    private employmentRecordsService: EmploymentRecordsService,
    private translate: TranslateService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.employeeId = this.getIdInURL();
    this.getEmploymentRecords();
  }

  get tableCreateAccessObj() {
    return {
      tableId: db_tables.EmploymentRecords,
      permission: 'create'
    }
  }

  // This is for getting the form generator componenets to check if the forms are pristine
  formGeneratorComponentEmitted(formGeneratorComponent: FormGeneratorComponent, employementRecordId: string) {
    let index = this.formGeneratorComponents.findIndex( obj => obj.id === employementRecordId );

    if(index !== -1){
      this.formGeneratorComponents[index] = {id: employementRecordId, formGeneratorComponent: formGeneratorComponent};
    }
    else {
      this.formGeneratorComponents.push({id: employementRecordId, formGeneratorComponent: formGeneratorComponent});
    }
  }

  getIdInURL(): string {
    let IdInURL: string;

    this.route.paramMap.subscribe(
      params => IdInURL = params.get("id")
    )

    return IdInURL;
  }

  getEmploymentRecords(): void {
    this.isLoading = true;

    this.employmentRecordsService.getEmploymentRecords(this.employeeId, 0, '100')
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.employmentRecords = res.data
        }
      );
  }

  openFormDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formTitle: `${this.translate.instant('EmploymentRecord')}`,
      formId: this.formId,
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      this.save(event)
    });
  }

  save(formData: any) {
    this.overlayService.show();

    this.employmentRecordsService.postEmploymentRecord(this.employeeId, formData)
    .pipe(
        finalize(() => {
            this.overlayService.hide();
        })
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close();
          this.getEmploymentRecords();
        }
    );
}

}
