<app-layout>
    <mat-card>
        <mat-card-title class="header">
          <h5 appLocalizationPopupDirective localizationCode="Reports">{{ 'Reports' | translate: {Default: "Reports"} }}</h5>
          <div>
            <button mat-flat-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openNewReportDialog()">
                <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> Create Custom Report
            </button>
          </div>
        </mat-card-title>
      
        <mat-card-content>
          <app-reports-list-data-grid></app-reports-list-data-grid>
        </mat-card-content>
      </mat-card>


    <!-- <mat-toolbar class="page-header" role="heading">
      <h1>Reports</h1>
      <button mat-flat-button color="warn">Latest Reports</button>
    </mat-toolbar>
  
    <div class="content">


        <mat-card *ngFor="let report of reports$ | async" [routerLink]="report.href" class="mat-elevation-z0">
            <div class="icon-div">
                <span class="material-icons-outlined">
                    {{report.icon}}
                </span>
            </div>

            <div class="text">
                <mat-card-title class="header">
                    {{report.name}}
                </mat-card-title>
                <mat-card-content>
                    {{report.detail}}
                </mat-card-content>
            </div>
        </mat-card>
    </div>
  
    <app-footer></app-footer> -->
</app-layout>
