import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { EmployeeService } from '@app/core/services/employee.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';

@Component({
  selector: 'app-new-report-dialog',
  templateUrl: './new-report-dialog.component.html',
  styleUrls: ['./new-report-dialog.component.scss']
})
export class NewReportDialogComponent implements OnInit {
  form: UntypedFormGroup;
  tables: any;
  selectedFields: any[] = [];
  employees: any[] = [];
  divisions: any[] = [
    'Corporate',
    'Exploration - Africa',
    'Exploration - Americas',
  ];
  orgUnits: any[] = [
    'Africa',
    'BC',
    'Finance',
  ];
  departments: any[] = [
    'Accounting',
    'Administration',
    'Executive',
  ];
  employmentStatus: any[] = [
    'Absence with Benefits',
    'Absence without Benefits',
    'Accepted',
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private overlayService: OverlayService,
    private dialogRef: MatDialogRef<NewReportDialogComponent>,
    private snackbarService: SnackbarService,
    private lookupService: LookupService,
    private employeeService: EmployeeService
  ) { }

  ngOnInit(): void {
    this.getTablesAndFields();
    this.createForm();
    this.getEmployees();
  }

  createForm() {
    this.form = this.fb.group({
      reportName: [''],
      filterResult: [''],
      sortBy: [''],
      employee: [''],
      manager: [''],
      division: [''],
      orgUnit: [''],
      department: [''],
      employmentStatus: [''],
    });
  }

  getEmployees() {
    this.employeeService.getEmployees()
    .subscribe(
      res => {
        this.employees = res.employees;
      }
    )
  }

  save() {
    this.overlayService.show();

    setTimeout(() => {
      this.overlayService.hide();
      this.snackbarService.openSnackBar('Report created successfully', 'clear', 'success');
    }, 2000);
  }

  close() {
    this.dialogRef.close();
  }

  getTablesAndFields() {
    this.lookupService.getTables()
    .subscribe(
      res => {
        this.tables = res;

        this.tables.forEach(table => {
          table.fields = this.lookupService.getFields(table.id);
        });
      }
    );
  }

  addToSelectedFields(field:any) {
    this.selectedFields.push(field);
  }

  removeFromSelectedFields(field:any) {
    const index = this.selectedFields.indexOf(field);
    
    this.selectedFields.splice(index, 1); 
  }

}
