import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {
    BusinessRuleAutomationNotificationType,
    BusinessRuleAutomationRecipientType,
    BusinessRuleAutomationRule,
    BusinessRuleAutomationVerbose,
    ConditionType,
    TriggerType
} from "@app/modules/business-rule-automation/models/business-rule-automation.model";
import { BusinessRuleAutomationService } from "@app/modules/business-rule-automation/services/business-rule-automation.service";
import { MetadataTableService } from "@app/core/services/metadata/metadata-table.service";
import { Table, TableField } from "@app/modules/form-generator/edit-form-v3/models/form.model";
import { forkJoin } from "rxjs";
import { tap } from "rxjs/operators";
import { MatSelectChange } from "@angular/material/select";
import { fieldTypes } from "@app/consts";
import * as moment from 'moment';

@Component({
    selector: 'app-business-rule-automation-form',
    templateUrl: './business-rule-automation-form.component.html',
    styleUrls: ['./business-rule-automation-form.component.scss']
})
export class BusinessRuleAutomationFormComponent implements OnInit {

    public businessRuleId: string;
    public businessRule: BusinessRuleAutomationVerbose;

    public formId: string = 'frm_hOU10wBeRpuvho';
    public formData: any;
    public formLoaded: boolean = true;
    public formValid: boolean = false;
    public formPristine: boolean;
    public getFormData: boolean = false;

    public form: UntypedFormGroup;

    // public rules: BusinessRuleAutomationRule[]
    public triggerTypes: TriggerType[];
    public conditionTypes: ConditionType[];
    public filteredConditionTypes: ConditionType[];
    public tables: Table[];
    public fields: TableField[];
    public notificationTypes: BusinessRuleAutomationNotificationType[];
    public outcomeTypes: any[] = [
        {id: 'notifications', name: 'Notifications'},
    ];
    private shouldCloseForm: boolean = true;
    fieldsCache = {};
    conditionTypesCache = {};

    fieldsToFilter = new Set([
        'tfi_AbsenceNumber',
        'tfi_AbsenceChangeReasonComments',
        'tfi_AbsenceChangeReason',
        'tfi_AbsenceEndTime',
        'tfi_AbsenceStartTime',
        'tfi_OCompReasonComments',
        'tfi_OCompChangeReason',
        'tfi_ChangeReasonComments',
        'tfi_ChangeReason',
        'tfi_PositionChangeReason',
        'tfi_PositionChangeReasonComments',
        'tfi_CoChangeReason',
        'tfi_CohangeReasonComments',
        'tfi_PrefferedLanguage',
        'tfi_ErChangeReason',
        'tfi_ErChangeReasonComments',
        'tfi_ToilChangeReason',
        'tfi_ToilChangeReasonComments',
        'tfi_ToilRecordEndTime',
        'tfi_ToilRecordStartTime',
        'tfi_CostCentre' // TODO need to get this one working on backend
       ])

    // selectedConditionType: ConditionType | null = null;
    private notificationRecipientTypes: BusinessRuleAutomationRecipientType[];

    constructor(
        private fb: UntypedFormBuilder,
        private overlayService: OverlayService,
        private snackbarService: SnackbarService,
        private service: BusinessRuleAutomationService,
        private metadataTableService: MetadataTableService,
        private translate: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef
    ) {
        this.businessRuleId = this.getIdInURL();
        console.log("this.businessRuleId", this.businessRuleId)
    }

    ngOnInit(): void {

        this.loadData().subscribe(() => {
            if (!this.businessRuleId) {
                this.createForm();
            } else {
                // fetch item
                this.service.getBusinessRuleById(this.businessRuleId).subscribe(
                    (data: BusinessRuleAutomationVerbose) => {
                        this.businessRule = data;
                        this.createForm();
                    });
            }
        });
    }

    getIdInURL(): string {
        let IdInURL: string;

        this.route.paramMap.subscribe(
            params => IdInURL = params.get("id")
        )
        return IdInURL;
    }

    loadData() {
        const triggerTypes$ = this.service.getTriggerTypes(0, 100);
        const conditionTypes$ = this.service.getConditionTypes(0, 100);
        const tables$ = this.metadataTableService.getTables(0, '1000');
        const notificationTypes$ = this.service.getNotificationTypes(0, 100);
        const notificationRecipientTypes$ = this.service.getNotificationRecipientTypes(0, 100);

        return forkJoin({
            triggerTypes: triggerTypes$,
            conditionTypes: conditionTypes$,
            tables: tables$,
            notificationTypes: notificationTypes$,
            notificationRecipientTypes: notificationRecipientTypes$,
        }).pipe(
            tap(results => {

                const includeTriggerTypeIds = ['AutomationsDate'];
                this.triggerTypes = results.triggerTypes.data.filter(triggerType => includeTriggerTypeIds.includes(triggerType.id));

                this.conditionTypes = results.conditionTypes.data;

                const includeIds = ['tbl_Employees', 'tbl_Toil', 'tbl_Absences', 'tbl_EmploymentRecords', 'tbl_Compensations', 'tbl_OtherCompensations', 'tbl_Positions'];
                this.tables = results.tables.data.filter(table => includeIds.includes(table.id));

                this.notificationTypes = results.notificationTypes.data;

                this.notificationRecipientTypes = results.notificationRecipientTypes.data.filter(field => {
                    return field.id != "AutomationsNotificationRecipientTypeManagerL2";
                })
            })
        );
    }

    async createForm() {

        this.formData = {
            name: [],
            description: [],
            status: true,
            activeUsersOnly: false,
        }

        this.form = this.fb.group({
            triggerId: [null],
            triggerType: [null, Validators.required],
            tableId: ['', Validators.required],
            fieldId: ['', Validators.required],
            conditionType: [{value: null}, Validators.required],
            valueOne: [{value: '', disabled: true}, Validators.required],
            rules: this.fb.array([]),
            outcomeType: ['']
        });


        if (this.businessRuleId) {
            console.log("this.businessRule", this.businessRule)
            this.formData.name = this.businessRule.name;
            this.formData.description = this.businessRule.description;
            this.formData.status = this.businessRule.status;
            this.formData.activeUsersOnly = this.businessRule.activeUsersOnly;

            this.form.patchValue({
                triggerId: this.businessRule.trigger.id,
                triggerType: this.businessRule.trigger.triggerType ? this.businessRule.trigger.triggerType.id : null,
                tableId: this.businessRule.trigger.tableId,
                outcomeType: 'notifications',
            });

            await this.updateTriggerFieldsBasedOnTable(this.businessRule.trigger.tableId, this.form);

            this.form.patchValue({
                fieldId: this.businessRule.trigger.fieldId,
            });

            this.updateConditionTypes(this.businessRule.trigger.triggerType ? this.businessRule.trigger.triggerType.id : null);

            this.form.patchValue({
                conditionType: this.businessRule.trigger.conditionType ? this.businessRule.trigger.conditionType.id : null,
                valueOne: this.businessRule.trigger.valueOne
            });
            this.form.get('valueOne').enable();

            for (const rule of this.businessRule.rules) {
                this.addNewRule(rule);
            }
        }

        this.form.get('tableId').valueChanges.subscribe(async selectedTableId => {
            await this.updateTriggerFieldsBasedOnTable(selectedTableId, this.form);
        });

        this.form.get('triggerType').valueChanges.subscribe(triggerTypeId => {
            this.updateConditionTypes(triggerTypeId);
        });

        this.form.get('conditionType').valueChanges.subscribe(selectedValue => {
            if (selectedValue) {
                this.form.get('valueOne').setValue('');
                this.form.get('valueOne').enable();
            } else {

                this.form.get('valueOne').disable();
            }
        });
    }

    formDataEmitted(formData) {
        this.save(formData);
    }

    formPristineEmitted(formPristine: boolean) {
        this.formPristine = formPristine;
    }

    formStatusUpdated(formValid) {
        if (!this.formLoaded) {
            this.formLoaded = true;
        }
        this.formValid = formValid;
    }

    get localizations() {
        return this.formData.controls["name"] as UntypedFormArray;
    }

    addNewLocalization(culture?: string, text?: string) {
        const localizationForm = this.fb.group({
            culture: [culture || '', Validators.required],
            text: [text || '', Validators.required]
        });

        this.localizations.push(localizationForm);
    }

    deleteLocalization(index: number) {
        this.localizations.removeAt(index);
    }


    save(formData) {
        formData.trigger = {
            triggerType: this.form.get('triggerType').value,
            tableId: this.form.get('tableId').value,
            fieldId: this.form.get('fieldId').value,
            conditionType: this.form.get('conditionType').value,
            valueOne: this.form.get('valueOne').value,
        }

        const rulesArray = this.form.get('rules').value;

        formData.rules = rulesArray.map(rule => {
            let formattedRule = {
                id: rule.id ? rule.id : null,
                tableId: rule.tableId,
                fieldId: rule.fieldId,
                conditionType: rule.conditionType,
                valueOne: rule.valueOne,
                valueTwo: rule.valueTwo
            };

            if (rule.conditionType === 'DateBefore' || rule.conditionType === 'DateAfter') {
                if (rule.valueOne) {
                    formattedRule.valueOne = moment(rule.valueOne).format('YYYY-MM-DD');
                }
            }

            return formattedRule;
        });

        console.log("formData updated", formData);
        this.overlayService.show();
        if (!this.businessRuleId) {

            this.service.createBusinessRule(formData).subscribe(
                (data) => {
                    this.overlayService.hide();

                    console.log("data", data);
                    this.businessRuleId = data.dataTriggerId;

                    if (this.shouldCloseForm) {
                        this.snackbarService.openSnackBar(this.translate.instant('BusinessRuleAutomationCreatedSuccessfully'), 'clear', 'success');
                        this.close();
                    } else {
                        this.shouldCloseForm = true;
                    }
                });

        } else {
            formData.id = this.businessRuleId;
            this.service.updateBusinessRule(formData).subscribe(
                (data) => {
                    this.overlayService.hide();
                    this.snackbarService.openSnackBar(this.translate.instant('BusinessRuleAutomationUpdatedSuccessfully'), 'clear', 'success');
                    this.close();
                });
        }
    }

    close() {
        this.router.navigate(['SiteSettings/BusinessRuleAutomation']);
    }

    get shouldShowForm(): boolean {
        let res = this.formLoaded && this.form && (this.businessRuleId === null || this.businessRule !== null);
        return res;
    }


    addNewRule(rule?: BusinessRuleAutomationRule) {
        console.log('Rule data:', rule);

        if (this.rules.length >= 3) {
            return;
        }

        const ruleForm = this.fb.group({
            id: rule?.id ? rule.id : null,
            tableId: [rule?.tableId || '', Validators.required],
            fieldId: ['', Validators.required],
            conditionType: [null, Validators.required],
            valueOne: [{value: '', disabled: true}, Validators.required],
            valueTwo: [{value: '', disabled: true}, Validators.required],
        });

        if (rule) {
            this.loadAndPatchRule(rule, ruleForm);
        } else {
            this.evaluateRuleStatus('', ruleForm)
            this.setupValueChangesListeners(ruleForm);
            this.rules.push(ruleForm);
        }
    }

    loadAndPatchRule(rule: BusinessRuleAutomationRule, ruleForm: FormGroup) {
        const tableId = rule.tableId;
        if (tableId && !this.fieldsCache[tableId]) {
            this.metadataTableService.getTableFields(tableId, 0, '1000').subscribe(res => {
                // Filter out unnecessary fields
                let filteredFields = res.data.filter(field => {
                    return this.fieldsToFilter.has(field.id) === false;
                })
                this.fieldsCache[tableId] = filteredFields;
                this.updateConditionTypesCache(tableId, rule.fieldId);
                this.patchRuleForm(rule, ruleForm);
                this.setupValueChangesListeners(ruleForm);
                this.rules.push(ruleForm);
            });
        } else {
            this.patchRuleForm(rule, ruleForm);
            this.setupValueChangesListeners(ruleForm);
            this.rules.push(ruleForm);
        }
        this.evaluateRuleStatus(rule.conditionType.id, ruleForm);
    }

    patchRuleForm(rule: BusinessRuleAutomationRule, ruleForm: FormGroup) {
        ruleForm.patchValue({
            tableId: rule.tableId,
            fieldId: rule.fieldId,
            conditionType: rule.conditionType.id,
            valueOne: rule.valueOne,
            valueTwo: rule.valueTwo,
        });

    }

    deleteRule(index: number) {
        this.rules.removeAt(index);
    }

    get rules() {
        return this.form.controls["rules"] as UntypedFormArray;
    }

    setupValueChangesListeners(ruleForm) {

        ruleForm.get('tableId').valueChanges.subscribe(selectedTableId => {
            console.log('setupValueChangesListeners > tableId', selectedTableId)
            if (selectedTableId) {
                if (!this.fieldsCache[selectedTableId]) {
                    console.log('setupValueChangesListeners > tableId > !this.fieldsCache[selectedTableId]', selectedTableId)
                    this.metadataTableService.getTableFields(selectedTableId, 0, '1000').subscribe(res => {

                        // Filter out unnecessary fields
                        let filteredFields = res.data.filter(field => {
                            return this.fieldsToFilter.has(field.id) === false;
                        })

                        this.fieldsCache[selectedTableId] = filteredFields;
                        ruleForm.get('fieldId').setValue('');
                        this.updateFieldsForRule(ruleForm, selectedTableId);
                    });
                } else {
                    console.log('setupValueChangesListeners > tableId > this.fieldsCache[selectedTableId]', selectedTableId)
                    this.updateFieldsForRule(ruleForm, selectedTableId);
                }
            }
        });

        ruleForm.get('conditionType').valueChanges.subscribe(selectedValue => {
            this.evaluateRuleStatus(selectedValue, ruleForm)
        });

        ruleForm.get('fieldId').valueChanges.subscribe(selectedFieldId => {
            console.log('setupValueChangesListeners > fieldId', selectedFieldId)
            if (selectedFieldId) {
                this.updateConditionTypesCache(ruleForm.get('tableId').value, selectedFieldId);
            }
        });
    }

    updateConditionTypesCache(tableId:string, selectedFieldId: string) {
        // const selectedField = this.fields.find(f => f.id === selectedFieldId);
        const selectedField = this.fieldsCache[tableId].find(f => f.id === selectedFieldId);
        console.log('updateConditionTypesCache > selectedField', selectedField);
        if (!selectedField) return;

        switch (selectedField.fieldType.id) {
            case 'TEXT' :
            case 'LOCALIZED' :
                this.conditionTypesCache[selectedFieldId] = this.conditionTypes.filter(ct => ct.dataType === 'string');
                break;
            case 'DATETIME':
                this.conditionTypesCache[selectedFieldId] = this.conditionTypes.filter(ct => ct.dataType === 'date');
                break;
            case 'INTEGER':
            case 'DECIMAL':
                this.conditionTypesCache[selectedFieldId] = this.conditionTypes.filter(ct => ct.dataType === 'number');
                break;
            case 'DROPDOWN':
            case 'BOOLEAN':
                this.conditionTypesCache[selectedFieldId] = this.conditionTypes.filter(ct => ct.dataType === 'bool');
                break;
        }

        // this.filteredConditionTypes = this.conditionTypesCache[selectedFieldId];
        console.log('Updated conditionTypesCache:', this.conditionTypesCache[selectedFieldId]);
    }

    updateFieldsForRule(ruleForm, tableId) {
        ruleForm.get('fieldId').setValue('');
    }

    private updateConditionTypes(triggerTypeId: string) {
        const selectedTriggerType = this.triggerTypes.find(tt => tt.id === triggerTypeId);
        if (selectedTriggerType) {
            const dataType = this.getDataTypeForTriggerType(selectedTriggerType);
            this.filteredConditionTypes = this.conditionTypes.filter(ct => ct.dataType === dataType);
        } else {
            this.filteredConditionTypes = [];
        }
    }

    private getDataTypeForTriggerType(triggerType: TriggerType): string {

        if (triggerType.id === 'AutomationsDate') {
            return 'date';
        }
        return '';
    }

    private async updateTriggerFieldsBasedOnTable(selectedTableId: string, formGroup: FormGroup): Promise<void> {
        if (selectedTableId) {
            const res = await this.metadataTableService.getTableFields(selectedTableId, 0, '1000').toPromise();
            formGroup.get('fieldId').setValue('');

            let fields;
            if (formGroup.get('triggerType').value === 'AutomationsDate') {
                fields = res.data.filter(field => field.fieldType && field.fieldType.id === 'DATETIME');
            } else {
                fields = res.data;
            }

            this.fields = fields;
            // Additional logic to handle fields (e.g., updating a dropdown)
        }
    }

    onOutcomeSelectionChange(event: MatSelectChange) {
        const selectedValue = event.value;

        if (this.businessRuleId == null) {
            this.shouldCloseForm = false;
            this.getFormData = !this.getFormData;
        }
    }

    evaluateRuleStatus(selectedValue, ruleForm) {
        if (selectedValue == 'StringIsEmpty' || selectedValue == 'StringIsNotEmpty') {
            ruleForm.get('valueOne').setValidators(null);
            ruleForm.get('valueOne').setValue('');
            ruleForm.get('valueOne')?.disable();
        } else if (selectedValue == ''){
            ruleForm.get('valueOne').setValue('');
            ruleForm.get('valueOne')?.disable();
        } else {
            ruleForm.get('valueOne').setValue('');
            ruleForm.get('valueOne').setValidators([Validators.required]);
            ruleForm.get('valueOne')?.enable();
        }

        if (selectedValue != 'NumberIsBetween') {
            ruleForm.get('valueTwo').setValidators(null);
            ruleForm.get('valueTwo').setValue('');
            ruleForm.get('valueTwo')?.disable();
        } else {
            ruleForm.get('valueTwo').setValue('');
            ruleForm.get('valueTwo').setValidators([Validators.required]);
            ruleForm.get('valueTwo')?.enable();
        }
    }

    protected readonly fieldTypes = fieldTypes;
}
