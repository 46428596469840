<mat-card class="chart">
    <mat-card-title class="chart__header">
      <h5 class="chart__header-title">Headcount By Month (Terminations)</h5>
  
      <app-settings-menu></app-settings-menu>
    </mat-card-title>
  
    <mat-card-content class="chart__content">
        <div id="chart">
            <apx-chart
                [series]="chartOptions.series"
                [chart]="chartOptions.chart"
                [xaxis]="chartOptions.xaxis"
                [title]="chartOptions.title"
            ></apx-chart>
        </div>

        <app-table
            [tableData]="terminations"
            [tableColumns]="tableColumns"
            [isFilterable]="false"
            [isPageable]="false"
        ></app-table>
    </mat-card-content>
</mat-card>