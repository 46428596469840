import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TimeOffInLieuService } from '@app/core/services/time-off-in-lieu/time-off-in-lieu.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { EmployeeLeaveService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-leave/services/employee-leave.service'
import { EmployeeTimeOffType } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-leave/models/leave-request.model';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { finalize } from 'rxjs/operators';
import { RequestToilDialogComponent } from './components/request-toil-dialog/request-toil-dialog.component';
import { AccrueToilDialogComponent } from './components/accrue-toil-dialog/accrue-toil-dialog.component';
import { pipe } from 'rxjs';
import { EmployeeToilPolicy } from '@app/shared/models/time-off-in-lieu/time-off-in-lieu.model';

@Component({
    selector: 'app-toil-policy-widget',
    templateUrl: './toil-policy-widget.component.html',
    styleUrls: ['./toil-policy-widget.component.scss']
})
export class ToilPolicyWidgetComponent implements OnInit {
    @Output() toilRequestCreated = new EventEmitter<boolean>();
    formId: string = 'frm_Sp9UWjaZBsb1Ha';
    user$: any;
    timeOffTypes: EmployeeTimeOffType[];
    public gridDataResult: GridDataResult;
    public isLoading: boolean = true;
    dialogRef: any;

    constructor(
        private dialog: MatDialog,
        private employeeLeaveService: EmployeeLeaveService,
        private oidcAuthService: OidcAuthService,
        public translate: TranslateService,
        private timeOffInLieuService: TimeOffInLieuService,
        private snackbarService: SnackbarService,
        private overlayService: OverlayService,
    ) {
        this.user$ = this.oidcAuthService.userProfile;
    }

    ngOnInit(): void {
        this.getEmployeeToilPolicies();
    }

    getEmployeeToilPolicies() {
        this.timeOffInLieuService.getEmployeeToilPolicies(this.user$.userId)
        .pipe(
            finalize(() => this.isLoading = false)
        )
        .subscribe(
            res => {
                this.gridDataResult = {
                    data: res.data,
                    total: res.data.length,
                }

            }
        )
    }


    // getTimeOffTypes() {
    //     this.isLoading = true;

    //     this.employeeLeaveService.getEmployeeTimeOffTypes(this.user$.userId)
    //     .pipe(
    //         finalize(() => this.isLoading = false)
    //     )
    //     .subscribe(
    //         res => {
    //             this.timeOffTypes = res.filter(timeOffType => timeOffType.timeOffPolicy !== null);
    //             this.gridDataResult = {
    //                 data: this.timeOffTypes,
    //                 total: this.timeOffTypes.length,
    //             }

    //         }
    //     )
    // }

    openRequestDialog(employeeToilPolicy: EmployeeToilPolicy) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            employeeToilPolicy: employeeToilPolicy,
            employeeId: this.user$.userId
        };

        const dialogRef = this.dialog.open(RequestToilDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(
            data => {
                if (data === true) {
                    this.toilRequestCreated.emit(true);
                }
            }
        );
    }

    openAccrualDialog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            employeeId: this.user$.userId,
        };

        const dialogRef = this.dialog.open(AccrueToilDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(
            data => {
                if (data === true) {
                    this.toilRequestCreated.emit(true);
                }
            }
        );
    }
}
