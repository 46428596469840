import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-splash-page',
  templateUrl: './splash-page.component.html',
  styleUrls: ['./splash-page.component.scss']
})
export class SplashPageComponent implements OnInit {
  sequence = '';

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }


  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const typedString = event.key;
    const specificString = 'skip';

    if(typedString === specificString[this.sequence.length]) {
      this.sequence = this.sequence.concat(typedString)
    }
    else {
      this.sequence = '';
    }

    if (this.sequence === specificString) {
      this.sequence = '';
      
      this.router.navigateByUrl('Homepage');


    }
  }

}
