import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingsService } from '@app/core/services/settings.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { Currency } from '@app/modules/lookup/models/currency.model';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BenefitsCoverage } from '../../../benefits-coverages/models/benefits-coverage.model';
import { BenefitsCoverageService } from '../../../benefits-coverages/services/benefits-coverage.service';
import { BenefitsPlanCoverage } from '../../models/benefits-plan-coverage.model';
import { BenefitsPlanCoverageService } from '../../services/benefits-plan-coverage.service';

@Component({
  selector: 'app-benefits-plan-coverage-dialog',
  templateUrl: './benefits-plan-coverage-dialog.component.html',
  styleUrls: ['./benefits-plan-coverage-dialog.component.scss']
})
export class BenefitsPlanCoverageDialogComponent implements OnInit {
  form: UntypedFormGroup;
  planId: string;
  coverageId: string;
  planCoverage: BenefitsPlanCoverage;
  currencyOptions: Observable<Currency[]>
  coverageOptions: BenefitsCoverage[] = [];
  loadingCoverages: boolean;
  loading: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private overlayService: OverlayService,
    private settingsService: SettingsService,
    private benefitsCoverageService: BenefitsCoverageService,
    private benefitsPlanCoverageService: BenefitsPlanCoverageService,
    private snackbarService: SnackbarService,
    private dialogRef: MatDialogRef<BenefitsPlanCoverageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data)
  {
    this.planId = data.planId;
    this.coverageId = data.coverageId;
  } 

  ngOnInit(): void {
    this.loading = true; 
    this.currencyOptions = this.settingsService.getCurrencies();
    this.getCoverages();

    if(this.planId !== null && this.coverageId !== null){
      this.getPlanCoverage() 
    }
    else {
      this.loading = false; 
      this.createForm();
    }
  }

  getPlanCoverage() {
    this.loading = true;

    this.benefitsPlanCoverageService.getPlanCoverage(this.planId, this.coverageId)
    .pipe(
      finalize( () => this.loading = false )
    )
    .subscribe(
      res => {
        this.planCoverage = res;
        this.createForm();
      }
    )
  }

  getCoverages() {
    this.loadingCoverages = true;

    this.benefitsCoverageService.getCoverages()
    .pipe(
      finalize( () => this.loadingCoverages = false )
    )
    .subscribe(
      res => {
        this.coverageOptions = res.data;
      }
    )
  }

  createForm() {
    this.form = this.fb.group({
      id: [''],
      asOf: [moment().format()],
      coverage: [this.planCoverage ? this.planCoverage.coverage?.id : null, Validators.required],
      currency: [this.planCoverage ? this.planCoverage.currency?.id : null, Validators.required],
      costPerMonth: [this.planCoverage ? this.planCoverage.costPerMonth : null, Validators.required],
      changeReason: [''],
      changeReasonComments: [''],
    });
  }

  save() {
    this.overlayService.show();

    if(this.coverageId){
      this.benefitsPlanCoverageService.updatePlanCoverage(this.planId, this.form.value.coverage, this.form.value)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.dialogRef.close(true);
          this.snackbarService.openSnackBar('Plan Coverage updated successfully', 'clear', 'success');
        }
      );
    }
    else {
      this.benefitsPlanCoverageService.addPlanCoverage(this.planId, this.form.value.coverage, this.form.value)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.dialogRef.close(true);
          this.snackbarService.openSnackBar('Plan Coverage created successfully', 'clear', 'success');
        }
      );
    }
  }

  close() {
    this.dialogRef.close();
  }
}
