<app-site-settings-menu>
    <app-data-grid-comp
        *ngIf="hasFeatureAccess"
        [columns]="columns"
        [bindingType]="bindingType"
        [view]="view"
        [gridData]="gridData"
        [gridDataResult]="gridDataResult"
        [isLoading]="isLoading"
        [pageSize]="pageSize"
        [skip]="skip"
        category="WorkRotations"
        [rowSelectionSettings]="{
            canSelectRows: true,
            selectableMode: 'multiple',
            selectRowBy: 'id'
        }"
        clickableRows="true"
        searchable="true"
        [isLoading]="isLoading"
        [clearSelectedItems]="clearSelectedItems"
        (emitPageChangeEvent)="pageChange($event)"
        (emitOpenDialogEvent)="navigateToWorkRotation($event)"
        (emitSearchEvent)="search($event)"
        (emitDeleteEvent)="deleteAllSelected($event)"
        (emitFilterEvent)="filterCallback($event)"
    ></app-data-grid-comp>

    <no-feature-access *ngIf="!hasFeatureAccess"></no-feature-access>
</app-site-settings-menu>
