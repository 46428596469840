<div class="main-container">
    <div class="top">
        <h5>Add multiple list option values</h5>

        <mat-icon 
            setColor 
            [setColorRules]="[
                {
                    color: 'primaryColour',
                    events: ['hover']
                }
            ]"
            class="material-icons-outlined" 
            (click)="emitClose()" 
            matTooltip="Close"
        >
            close
        </mat-icon> 
    </div>

    <div class="bottom">
        <div class="bottom-controls"> 
            <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()">Save</button>
            <button mat-raised-button (click)="emitClose()">Close</button>
        </div>

        <div class="radio-container">
            <label>Value Separator:</label>
            <mat-radio-group name="valueSeparatorRadio" [(ngModel)]="valueSeparator" (change)="splitLookupValues()">
                <mat-radio-button setColor="primaryColour" value="newLine">New Line</mat-radio-button>
                <mat-radio-button setColor="primaryColour" value="space">Space</mat-radio-button>
                <mat-radio-button setColor="primaryColour" value="custom">Custom</mat-radio-button>
            </mat-radio-group>
        </div>
    
        <ng-container *ngIf="valueSeparator === 'custom'">
            <mat-form-field appearance="fill">
                <mat-label>
                    Custom Value Separator
                </mat-label>
            
                <input matInput type="text" [(ngModel)]="customValueSeparator" (ngModelChange)="splitLookupValues()">
            </mat-form-field>
        </ng-container>

        <mat-form-field appearance="fill">
            <mat-label>
                Language
            </mat-label>

            <mat-select disableOptionCentering [(ngModel)]="cultureValue" >
                <mat-option *ngFor="let culture of cultures$ | async" [value]="culture.id">{{culture.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field  appearance="fill">
            <mat-label>
                Start Date
            </mat-label>
        
            <input matInput [matDatepicker]="startDatePicker" [(ngModel)]="startDate">
            <span fxLayout="row" matSuffix>
                <mat-datepicker-toggle  [for]="startDatePicker"></mat-datepicker-toggle>
            </span>
            <mat-datepicker #startDatePicker></mat-datepicker> 
        </mat-form-field>

        <mat-form-field  appearance="fill">
            <mat-label>
                End Date
            </mat-label>
        
            <input matInput [matDatepicker]="endDatePicker" [(ngModel)]="endDate">
            <span fxLayout="row" matSuffix>
                <mat-datepicker-toggle  [for]="endDatePicker"></mat-datepicker-toggle>
            </span>
            <mat-datepicker #endDatePicker></mat-datepicker> 
        </mat-form-field>
    
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>List Option Values</mat-label>
            <textarea matInput matTextareaAutosize matAutosizeMinRows=1 matAutosizeMaxRows=30 placeholder="Add list of values here" 
                      [(ngModel)]="lookupValues" (ngModelChange)="splitLookupValues()"></textarea>
        </mat-form-field>
    
        <div class="preview-container">
            <mat-list>
                <mat-list-item *ngFor="let lookupValue of separatorPreview; let i = index;">
                    {{i+1}}. {{lookupValue}}
                </mat-list-item>
            </mat-list>
        </div>
    </div>

    
</div>