import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { ReviewPlansService } from '../../services/review-plans.service';
import { finalize, from } from 'rxjs';
import { ReviewPlanGoalSetting } from '../../models/review-plan.model';

@Component({
  selector: 'app-review-plan-goal-settings',
  templateUrl: './review-plan-goal-settings.component.html',
  styleUrls: ['./review-plan-goal-settings.component.scss']
})
export class ReviewPlanGoalSettingsComponent implements OnInit {
  @Input() reviewPlanId: string;
  @Input() refresh: boolean;

  isLoading: boolean = true;
  searchFilterString: any;
  filterString: any;
  getGoalsRequest: any;
  pageSize: number = 20;
  skip: number = 0;
  sortString: string;
  gridDataResult: { data: ReviewPlanGoalSetting[]; total: number; };
  groupedData: any;
  selectedGoals: string[] = [];

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private reviewPlansService: ReviewPlansService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.refresh && (changes.refresh.currentValue !== changes.refresh.previousValue)) {
      this.getReviewPlanGoalSettings();
    }
  }

  ngOnInit(): void {
    this.getReviewPlanGoalSettings()
  }

  getReviewPlanGoalSettings() {
    let filter;
    if(this.searchFilterString) {
      filter = this.searchFilterString;
    }
    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }

    from(this.reviewPlansService.getAllReviewGoalSettings(this.reviewPlanId, 'goal.objective-asc'))
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          }

          this.groupedData = res.data.reduce((acc, obj) => {
            const goalPlanName = obj.goal.goalPlan.name;

            if (!acc[goalPlanName]) {
              acc[goalPlanName] = [];
            }

            acc[goalPlanName].push(obj);

            return acc;
          }, {});
        }
      );
    }
    
    onGoalSettingCheckboxChange(event: { goalId: string, isChecked: boolean }){
      const { goalId, isChecked } = event;
    
      if (isChecked) {
        // Add goalId to selectedGoals if isChecked is true
        if (!this.selectedGoals.includes(goalId)) {
          this.selectedGoals.push(goalId);
        }
      } else {
        // Remove goalId from selectedGoals if isChecked is false
        const index = this.selectedGoals.indexOf(goalId);
        if (index !== -1) {
          this.selectedGoals.splice(index, 1);
        }
      }
    }  
  

}
