import { Injectable } from '@angular/core';
import { User, UserManager, UserManagerSettings } from 'oidc-client';
import { Router } from '@angular/router';
import { EnvironmentService } from "@app/core/services/environment.service";

@Injectable({
    providedIn: 'root'
})
export class OidcAuthService {

    private manager: UserManager;
    private user: User;
    private tenantId: string;

    constructor(
        private router: Router,
        private environmentService: EnvironmentService,
    ) {
        this.manager = new UserManager(this.getClientSettings())

        this.manager.events.addAccessTokenExpiring(() => {
                console.log("access token expiring");

                this.manager.signinSilent().then(user => {
                    console.log('Silent renew successful');
                    if (user) {
                        this.user = user;
                        // console.log('expires at:', user.expires_at);
                        console.log('expires at1:', new Date(user.expires_at * 1000));
                    }
                    // this.user = user;
                }).catch(error => {
                    console.error('Silent renew failed:', error);
                });
            }
        );

        // this.manager.events.addAccessTokenExpired(() => {
        //     console.log("access token expired");
        //     this.manager.signinSilent().then(user => {
        //         console.log('Silent renew successful, new user:', user);
        //     }).catch(error => {
        //         console.error('Silent renew failed:', error);
        //     });
        // });
        // this.manager.signinSilentCallback().then(user => {
        //     console.log('signinSilentCallback:', user);
        //     // console.log('signinSilentCallback:', user);
        //     // this.user = user;
        // }).catch(error => {
        //     console.error('Silent renew failed:', error);
        // });
    }

    redirectToLogin() {
        console.log('redirectToLogin');
        return this.manager.signinRedirect();
        // return this.manager.signinPopup();
        // return this.manager.signinSilent();
    }

    async completeAuthentication() {
        // console.log('completeAuthentication');

        await this.manager.signinRedirectCallback()
        .then(
            user => {
                this.user = user;
                console.log("completeAuthentication success, token expires at", new Date(this.user.expires_at * 1000));
            }
        )
        .catch(
            (err) => {
                console.log(err);
                this.manager.signinRedirect();
            }
        );
    }

    isAuthenticated(): boolean {
        const isAuthenticated = this.user != null && !this.user.expired;
        // console.log('expires at:', new Date(this.user?.expires_at * 1000));
        return isAuthenticated;
    }

    get authorizationHeaderValue(): string {
        return `${this.user.token_type} ${this.user.access_token}`;
    }

    get name(): string {
        return this.user != null ? this.user.profile.name : '';
    }

    get userProfile(): any {
        return this.user != null ? {userId: this.user.profile.user_id} : '';
    }

    async signout() {
        await this.manager.signoutRedirect();
    }

    getTenantId() {
        return this.tenantId;
    }


    getClientSettings(): UserManagerSettings {
        let environment = this.environmentService.env;
        this.tenantId = environment.tenantId;

        let settings = {
            authority: environment.authUrl,
            client_id: environment.clientId,
            redirect_uri: `${environment.baseUrl}auth-callback`,
            post_logout_redirect_uri: environment.baseUrl,
            // response_type: "id_token token",
            response_type: "code",
            scope: "offline_access openid profile",
            filterProtocolClaims: true,
            loadUserInfo: true,
            // option 1
            // automaticSilentRenew: true,
            // silent_redirect_uri: `${environment.baseUrl}silent-refresh`,
            // option 2
            automaticSilentRenew: false,
            // includeIdTokenInSilentRenew: false,
            accessTokenExpiringNotificationTime: 30,
            // accessTokenExpiringNotificationTime: 60,
            // silent_redirect_uri: `${environment.baseUrl}silent-refresh`,
            // silent_redirect_uri: `${environment.baseUrl}silent-refresh.html`,
        };

        console.log('settings', settings);

        return settings;
    }
}
