import { Component, OnInit } from '@angular/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { Organization } from '@app/modules/organization-structure/models/organization-structure.model';
import { OrganizationStructureService } from '@app/modules/organization-structure/services/organization-structure.service';
import { Position } from '@app/modules/positions/models/positions.model';
import { PositionsService } from '@app/modules/positions/services/positions.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-add-dummy-positions',
  templateUrl: './add-dummy-positions.component.html',
  styleUrls: ['./add-dummy-positions.component.scss']
})
export class AddDummyPositionsComponent implements OnInit {
  loadingPositions: boolean = true;
  loadingOrgs: boolean = true;
  positions: Position[];
  orgs: Organization[];
  cultures: Culture[];
  numberOfPositions: number = 20;

  constructor(
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private positionsService: PositionsService,
    private cultureService: CultureService,
    private organizationService: OrganizationStructureService
  ) { }

  ngOnInit(): void {
    this.getPositions();
    this.getCultures();
    this.getAllOrganizations();
  }

  getCultures() {
    this.cultureService.getCultures()
    .subscribe(
      res => {
        this.cultures = res;
      }
    )
  }

  createRandomString(length: number): string {
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, length);
  }

  getAllOrganizations() {
    this.loadingOrgs = true;

    this.organizationService.getOrganizations(0, '200')
    .pipe(
      finalize( () => {
        this.loadingOrgs = false;
      })
    )
    .subscribe(
      res => {
        this.orgs = res.data;
      }
    )
  }

  getPositions(){
    this.loadingPositions = true;

    this.positionsService.getPositions(0, "1000", null, 'name-asc')
    .pipe(
      finalize( () => {
        this.loadingPositions = false;
      })
    )
    .subscribe(
      res => {
        this.positions = res.data;
      }
    )
  }

  random_item(items){
    return items[Math.floor(Math.random()*items.length)];
  }

  getNewPosition(): any {
    return {
        startDate: '2022-05-26',
        parentPosition: this.random_item(this.positions).id,
        organization: this.random_item(this.orgs).id,
        name: [{
          "culture": this.random_item(this.cultures).id,
          "text": `Dummy - ${this.createRandomString(10)}`
        }],
    };
  }

  createPositions() {
    let createPositionRequests: Observable<any>[] = [];

    for (let i = 0; i < this.numberOfPositions; i++) {
      createPositionRequests.push(this.positionsService.postPosition(this.getNewPosition()))
    }

    this.overlayService.show();
    forkJoin(createPositionRequests)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
            this.snackbarService.openSnackBar(`Created ${this.numberOfPositions} Position(s) Successfully`, 'clear', 'success');
        }
    );
  }
}
