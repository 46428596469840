import { Pipe, PipeTransform } from '@angular/core';
import { TimeOffListVariant } from '../lookup/models/time-off-list-variant.model';

@Pipe({ name: 'timeOffTypeSearch' })
export class TimeOffTypeSearchPipe implements PipeTransform {
  public transform(timeOffTypes: TimeOffListVariant[], searchText: any): any {
    if (searchText == '' || timeOffTypes == null) {
      return timeOffTypes;
    }

    return timeOffTypes.filter(timeOffType => 
        timeOffType.name.toUpperCase().includes(searchText.toUpperCase())
    );
  }
}