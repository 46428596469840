import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { api_routes, db_tables } from '@app/consts';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { GoalDialogComponent } from '@app/modules/performance/components/goal-dialog/goal-dialog.component';
import { AllGoalsListItem, EmployeeGoal, EmployeeGoalSubmit, Goal } from '@app/modules/performance/components/goal-plans/models/goal-plan.model';
import { GoalPlanService } from '@app/modules/performance/components/goal-plans/services/goal-plan.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { defer, finalize, forkJoin } from 'rxjs';

@Component({
  selector: 'app-select-goal-dialog',
  templateUrl: './select-goal-dialog.component.html',
  styleUrls: ['./select-goal-dialog.component.scss']
})
export class SelectGoalDialogComponent implements OnInit {
  public columns: any[] = [
    { field: "objective", tableId: "tfi_PEgObjective", title: this.translate.instant("EmployeeGoals-Objective") },
    { field: "description", tableId: "tfi_PEgDescription", title: "Description", type: "longText", longTextLimit: 200 },
    { field: "expectedCompletionDate", tableId: "tfi_PEgExpectedCompletionDate", title: "Expected Completion Date", type: "date", dataType: 'Date'},
    { field: "actualCompletionDate", tableId: "tfi_PEgActualCompletionDate", title: "Actual Completion Date", type: "date", dataType: 'Date'},
    { field: "goalType", subField: "name", tableId: "tfi_PEgGoalType", title: "Goal Type", dataType: "SpecialLookup", lookupCode: 'GOAL_TYPE'},
    { field: "goalPlan", subField: "name", tableId: "", title: this.translate.instant("EmployeeGoals-GoalPlan"), dataType: "SpecialLookup", lookupCode: 'GOAL_PLAN'},
    { field: "percentageComplete", tableId: "tfi_PEgPercentageComplete", title: "Percentage Complete", type: "percentage", percentageMaxValue: 1 },
    { field: "weight", tableId: "tfi_PEgWeight", title: "Weight", type: "percentage", percentageMaxValue: 1 },
    { field: "createdBy", tableId: "", title: this.translate.instant("EmployeeGoals-CreatedBy"), type: "employee" },
  ];

  filterCategories: any[] = [
    { field: "expectedCompletionDate", tableId: "tfi_PEgExpectedCompletionDate", title: "Expected Completion Date", type: "date", dataType: 'Date'},
    { field: "actualCompletionDate", tableId: "tfi_PEgActualCompletionDate", title: "Actual Completion Date", type: "date", dataType: 'Date'},
    { field: "GoalTypeId", subField: "name", tableId: "tfi_PEgGoalType", title: "Goal Type", dataType: "SpecialLookup", lookupCode: 'GOAL_TYPE'},
    { field: "GoalPlanId", subField: "name", tableId: "", title: this.translate.instant("EmployeeGoals-GoalPlan"), dataType: "SpecialLookup", lookupCode: 'GOAL_PLAN'},
    { field: "percentageComplete", tableId: "tfi_PEgPercentageComplete", title: "Percentage Complete", dataType: "Percentage"},
  ];

  sortableColumns: any[] = [
    // { field: "description", sortValue: "description"},
    { field: "objective", sortValue: "objective"},
    { field: "description", sortValue: "description"},
    { field: "expectedCompletionDate", sortValue: "expectedCompletionDate"},
    { field: "actualCompletionDate", sortValue: "actualCompletionDate"},
    { field: "goalType", sortValue: "goalTypeName"},
    { field: "goalPlan", sortValue: "goalPlanName"},
    // { field: "percentageComplete", sortValue: "percentageComplete"},
  ];

  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 5;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  formValid: any;
  user$: any;
  selectedGoal: Goal

  getGoalsRequest: any;
  filterString: string;
  searchFilterString: string;
  searchValue: string = "";
  sortString: string;

  mandatoryFilter: string;

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private oidcAuthService: OidcAuthService,
    private dialogRef: MatDialogRef<SelectGoalDialogComponent>,
    private goalService: GoalPlanService,
    @Inject(MAT_DIALOG_DATA) data)
  {
    this.user$ = this.oidcAuthService.userProfile;

    data !== null && data.mandatoryFilter !== null ? this.mandatoryFilter = data.mandatoryFilter : null
  }

  ngOnInit(): void {
    this.view = this.getGoals();
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;

    let variants = this.searchValue.split(' ').filter(value => value);
    this.searchFilterString = '';
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `((EmployeeFirstName like "${variant}") OR (EmployeeLastName like "${variant}") OR (EmployeeId like "${variant}") OR (Objective like "${variant}") OR (Description like "${variant}"))`;
        }
    });

    this.getGoalsRequest.unsubscribe();
    this.getGoals();
  }

  getGoals() {
    let filter = this.mandatoryFilter;

    if(this.searchFilterString) {
      if(filter === undefined){
        filter = this.searchFilterString;
      }
      else {
        filter = `${filter} AND ${this.searchFilterString}`;
      }
    }
    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }

    this.isLoading = true;

    // this.getGoalsRequest = this.goalService.getEmployeeGoals(this.user$.userId, this.pageSize.toString(), this.skip, this.sortString, filter)
    // .pipe(
    //   finalize(() => this.isLoading = false)
    // )
    // .subscribe(
    //   (res) => {
    //     this.gridDataResult = {
    //       data: res.employeeGoals.data,
    //       total: res.employeeGoals.total,
    //     }
    //     this.gridData = res.employeeGoals.data;
    //   }
    // );

    this.getGoalsRequest = this.goalService.getAllGoals(this.pageSize.toString(), this.skip, this.sortString, filter)
    .pipe(
      finalize(() => this.isLoading = false)
    )
    .subscribe(
      (res) => {

        this.gridDataResult = {
          data: res.data,
          total: res.total,
        }

        // If the goal doesnt have a parent mark it as a sub goal
        this.gridDataResult.data.forEach(
          goal => {
            if(goal.parent === null){
              goal.isSubGoal = false;
            }
            else{
              goal.isSubGoal = true;
            }
          }
        )

        this.gridData = res.data;
      }
    );
  }

  getGoalVerbose(goal?: AllGoalsListItem) {
    if(goal === undefined){
      this.openGoalDialog();
    }
    else {
      let employeeGoal: EmployeeGoal;

      this.isLoading = true;

      this.goalService.getEmployeeGoal(goal.employee?.id, goal.id)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        res => {
          employeeGoal = res;
          employeeGoal.employee.id = goal.employee?.id;
          this.openGoalDialog(employeeGoal);
        }
      );
    }
  }

  openGoalDialog(goal?: EmployeeGoal) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      goal: goal,
      employeeId: goal.employee.id,
      readOnly: true
    };

    this.dialog.open(GoalDialogComponent, dialogConfig)
    .afterClosed().subscribe(
      data => {
        if (data === true) {
          this.getGoals();
        }
      }
    );
  }

  createNewGoal(formData: EmployeeGoalSubmit){
    this.overlayService.show();

    this.goalService.createEmployeeGoal(this.user$.userId, formData)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(res);
          this.getGoals();
        }
    );
  }

  updateGoal(formData: EmployeeGoalSubmit){
    this.overlayService.show();

    this.goalService.updateEmployeeGoal(this.user$.userId, formData)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(res);
          this.getGoals();
        }
    );
  }

  deleteGoals(goalIds: string[]) {
    const observables = goalIds.map(selectedItem => defer(() => this.goalService.deleteEmployeeGoal(this.user$.userId, selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.view = this.getGoals();
        this.clearSelectedItems = !this.clearSelectedItems;
        this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.view = this.getGoals();
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;;
    this.getGoals();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getGoals();
  }

  save() {
    this.dialogRef.close(this.selectedGoal);
  }

  close() {
    this.dialogRef.close();
  }

  selectedRowsChanged(selectionEvent: SelectionEvent){
    if(selectionEvent?.selectedRows[0]?.dataItem){
      this.selectedGoal = selectionEvent.selectedRows[0].dataItem;
    }
    else {
      this.selectedGoal = null;
    }
  }
}

