import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiUser } from '../models/api-setup.model';

@Injectable({
  providedIn: 'root'
})
export class ApiSetupService {

  constructor() { }

  getApiUsers(): Observable<ApiUser[]> {
    return of(
      [
        {
          apiUserId: '2549156',
          name: 'ADP API',
          username: 'ADP',
          access: [
            {
              category: 'Employee Asset',
              read: false,
              create: true,
              edit: true,
              delete: false,
              updatePartial: false
            },
            {
              category: 'Employee Benefits',
              read: true,
              create: true,
              edit: true,
              delete: false,
              updatePartial: false
            },
            {
              category: 'Employee Compensation',
              read: false,
              create: true,
              edit: true,
              delete: false,
              updatePartial: true
            },
            {
              category: 'Employee Emergency Contacts',
              read: false,
              create: true,
              edit: false,
              delete: false,
              updatePartial: true
            },
            {
              category: 'Employee Family',
              read: true,
              create: false,
              edit: true,
              delete: false,
              updatePartial: true
            },
            {
              category: 'Employee General',
              read: false,
              create: true,
              edit: true,
              delete: false,
              updatePartial: false
            },
            {
              category: 'Employee Job Assignemnt',
              read: false,
              create: true,
              edit: true,
              delete: false,
              updatePartial: false
            },
            {
              category: 'Employee Leave',
              read: false,
              create: true,
              edit: true,
              delete: false,
              updatePartial: true
            },
            {
              category: 'Employee Other Compensation',
              read: false,
              create: false,
              edit: true,
              delete: false,
              updatePartial: true
            },
            {
              category: 'Employee Payroll',
              read: true,
              create: false,
              edit: true,
              delete: false,
              updatePartial: true
            },
            {
              category: 'Employee Pension',
              read: true,
              create: true,
              edit: true,
              delete: false,
              updatePartial: true
            },
            {
              category: 'Employee Personal Contact',
              read: true,
              create: true,
              edit: true,
              delete: false,
              updatePartial: true
            },
            {
              category: 'Employee Position',
              read: true,
              create: true,
              edit: true,
              delete: false,
              updatePartial: true
            },
          ]
        },
        {
          apiUserId: '567643',
          name: 'iCIMS API',
          username: 'iCIMS',
          access: [
            {
              category: 'Employee Asset',
              read: true,
              create: false,
              edit: true,
              delete: false,
              updatePartial: true
            },
            {
              category: 'Employee Benefits',
              read: true,
              create: false,
              edit: true,
              delete: false,
              updatePartial: true
            },
            {
              category: 'Employee Compensation',
              read: false,
              create: false,
              edit: true,
              delete: false,
              updatePartial: true
            },
            {
              category: 'Employee Emergency Contacts',
              read: true,
              create: false,
              edit: true,
              delete: false,
              updatePartial: false
            },
            {
              category: 'Employee Family',
              read: true,
              create: true,
              edit: true,
              delete: false,
              updatePartial: true
            },
            {
              category: 'Employee General',
              read: false,
              create: false,
              edit: false,
              delete: false,
              updatePartial: false
            },
            {
              category: 'Employee Job Assignemnt',
              read: true,
              create: true,
              edit: false,
              delete: false,
              updatePartial: true
            },
            {
              category: 'Employee Leave',
              read: false,
              create: true,
              edit: true,
              delete: false,
              updatePartial: false
            },
            {
              category: 'Employee Other Compensation',
              read: true,
              create: true,
              edit: true,
              delete: false,
              updatePartial: false
            },
            {
              category: 'Employee Payroll',
              read: false,
              create: true,
              edit: true,
              delete: false,
              updatePartial: true
            },
            {
              category: 'Employee Pension',
              read: true,
              create: true,
              edit: false,
              delete: false,
              updatePartial: true
            },
            {
              category: 'Employee Personal Contact',
              read: false,
              create: true,
              edit: true,
              delete: false,
              updatePartial: false
            },
            {
              category: 'Employee Position',
              read: true,
              create: true,
              edit: true,
              delete: false,
              updatePartial: true
            },
          ]
        }
      ]
    )
  }
}
