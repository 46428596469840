import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api_routes } from '@app/consts';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '@app/core/services/environment.service';


const headers = new HttpHeaders({
    'Content-Type': 'application/json',
});

@Injectable({
    providedIn: 'root'
})
export class GoalsReportService {

    constructor(
        private http: HttpClient,
        private translate: TranslateService,
        private envService: EnvironmentService
    ) {
    }

    public fetch(filterString: string, sortString: string, filterInactive?: boolean): Observable<GridDataResult> {
        let params = new HttpParams();
        // state.skip ? params = params.append('skip', state.skip.toString()) : null;
        // state.take ? params = params.append('take', state.take.toString()) : null;
        filterString ? params = params.append('Filter', filterString) : null;
        sortString ? params = params.append('Sort', sortString) : null;
        filterInactive ? params = params.append('FilterInactive', filterInactive.toString()) : false;

        return this.http.get(`${this.envService.env.apiUrl}${api_routes.REPORTS}/${api_routes.PREDEFINED}/${api_routes.GOALS_REPORT}`,
            {
                headers,
                params,
            })
            .pipe(
                map(
                    (response) => {
                        return <GridDataResult> {
                            data: response['data'],
                            total: response['data'].length,
                        };
                    }
                )
            );
    }

}
