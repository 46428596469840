<app-data-grid-comp
    [columns]="columns"
    [bindingType]="bindingType"
    [view]="view"
    [gridData]="gridData"
    [gridDataResult]="gridDataResult"
    [pageSize]="pageSize"
    [skip]="skip"
    [tableId]="db_tables.ABSENCES"
    category="tbl_Absences"
    [isLoading]="isLoading"
    [clearSelectedItems]="clearSelectedItems"
    [accessAndHistoryURL]="accessAndHistoryURL"
    [parentModelId]="employeeID"
    [targetEmployeeTablePermissions]="targetEmployeeTablePermissions"
    export="excel"
    [rowSelectionSettings]="{
        canSelectRows: true,
        selectableMode: 'multiple',
        selectRowBy: 'id'
    }"
    clickableRows="true"
    (emitPageChangeEvent)="pageChange($event)"
    (emitOpenDialogEvent)="getEmployeeAbsence($event)"
    (emitDeleteEvent)="deleteEmployeeAbsences($event)"
></app-data-grid-comp>
