import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { routes } from '@app/consts';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GoalComment } from '../../../goal-plans/models/goal-plan.model';
import { GoalPlanService } from '../../../goal-plans/services/goal-plan.service';

@Component({
  selector: 'app-goal-comments',
  templateUrl: './goal-comments.component.html',
  styleUrls: ['./goal-comments.component.scss']
})
export class GoalCommentsComponent implements OnInit {
  @Input() goalId: string;
  @Input() employeeId: string;

  public routes: typeof routes = routes;

  formId: string = 'frm_0h2Z0sjoLVLB56';
  formData = {
    comments: null
  }
  formValid: boolean = false;
  formPristine: boolean = true;

  loadingComments: boolean = true;
  pageSize: number = 50;
  skip: number = 0;
  comments$: Observable<PagedData<GoalComment>>;
  comments: PagedData<GoalComment>;
  user$: any;
  showForm: string;
  getFormData: boolean = false;

  constructor(
    private dialog: MatDialog,
    private goalService: GoalPlanService,
    private oidcAuthService: OidcAuthService,
    private overlayService: OverlayService,
    private translate: TranslateService,
    private snackbarService: SnackbarService
  ) {
    this.user$ = this.oidcAuthService.userProfile;
  }

  ngOnInit(): void {
    this.getComments();
  }

  getComments() {
    this.comments$ = this.goalService.getGoalComments(this.employeeId, this.goalId, this.pageSize.toString(), this.skip)
  }

  createComment(formData: any){
    this.overlayService.show();

    formData.originalCreatedOn = moment().format();
    formData.asOf = moment().format();
    formData.changeReason = null;
    formData.changeReasonComments = null;

    this.goalService.createComment(this.employeeId, this.goalId, formData)
    .pipe(
        finalize(() =>
          {
            this.overlayService.hide();
            this.getComments();
          }
        )
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
        }
    );
  }

  updateComment(formData: any){
    this.overlayService.show();

    formData.originalCreatedOn = moment().format();
    formData.asOf = moment().format();
    formData.changeReason = null;
    formData.changeReasonComments = null;

    this.goalService.updateComment(this.employeeId, this.goalId, formData.id, formData)
    .pipe(
        finalize(() =>
          {
            this.overlayService.hide();
            this.getComments();
          }
        )
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
        }
    );
  }

  formDataEmitted(formDataEmitted) {
    this.createComment(formDataEmitted)
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  formPristineEmitted(formPristine: boolean) {
    this.formPristine = formPristine;
  }

  openEditCommentDialog(commentId: string) {
    console.log(`open dialog ${commentId}`);
  }

  openConfirmDeleteDialog(commentId: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      text: `${this.translate.instant('ConfirmDeleteCommentMessage')}`
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.delete(commentId);
        }
      }
    );
  }

  delete(commentId: string){
    this.overlayService.show();

    this.goalService.deleteComment(this.user$.userId, this.goalId, commentId)
    .pipe(
      finalize(() =>
        {
          this.overlayService.hide();
          this.getComments();
        }
      )
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar(this.translate.instant('DeletedSuccessfully'), 'clear', 'success');
      }
    );
  }

}
