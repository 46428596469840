import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-position-details-dialog',
  templateUrl: './view-position-details-dialog.component.html',
  styleUrls: ['./view-position-details-dialog.component.scss']
})
export class ViewPositionDetailsDialogComponent implements OnInit {
  positionId: string;

  constructor(
    private dialogRef: MatDialogRef<ViewPositionDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    this.positionId = data.positionId;
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

}
