<mat-card>
    <mat-card-title class="header">
      <h5 class="title" appLocalizationPopupDirective localizationCode="NewsAndHolidays">{{ 'NewsAndHolidays' | translate: {Default: "News and Holidays"} }}</h5>
    </mat-card-title>
  
    <mat-card-content class="content">
        <kendo-scheduler
            [kendoSchedulerBinding]="events"
            [selectedDate]="selectedDate"
            [selectedViewIndex]="1"
            style="height: 650px;"
        >
            <kendo-scheduler-day-view startTime="07:00"> </kendo-scheduler-day-view>

            <kendo-scheduler-month-view [eventHeight]="30">
            </kendo-scheduler-month-view>
        </kendo-scheduler>

        <mat-list role="list">
            <mat-list-item class="event" role="listitem" *ngFor="let event of events">
                <div class="details">
                    <mat-icon *ngIf="event.description === 'Public Holiday'" class="material-icons-outlined  public-holiday">celebration</mat-icon>
                    <mat-icon *ngIf="event.description === 'Company Event'" class="material-icons-outlined  company-event">work</mat-icon>

                    {{event.title}}
                </div>
                <div class="date">
                    {{event.start | date:'mediumDate'}}
                </div>
            </mat-list-item>
        </mat-list>
    </mat-card-content>
</mat-card>