import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fieldTypes } from '@app/consts';
import { MetadataFormService } from '@app/core/services/metadata/metadata-form.service';
import { MetadataTableService } from '@app/core/services/metadata/metadata-table.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import { CustomReportDataGridColumn, ServerReportElement } from "@app/modules/custom-reports/models/custom-report.model";
import { CustomReportsService } from "@app/modules/custom-reports/services/reports.service";
import { ReportsMapping } from "@app/modules/custom-reports/util/reports-mapping";


@Component({
    selector: 'app-form-element-dialog-v2',
    templateUrl: './report-element-dialog.component.html',
    styleUrls: ['./report-element-dialog.component.scss']
})
export class ReportElementDialogComponent implements OnInit {

    private element: ServerReportElement;
    public reportsMapping: ReportsMapping = new ReportsMapping();

    public fieldTypes: typeof fieldTypes = fieldTypes;

    form: UntypedFormGroup;
    formId: string;
    cultures: Culture[];
    loading: boolean;

    formElementId: string;
    parentId: string;
    formTableId: string;
    order: number;


    public elementTypes: { id: string, display: string }[] = [
        {id: "datagrid_element", display: 'Data Grid'},
        // {id: "piechart_element", display: 'Pie Chart'},
    ]
    private elementIndex: number;

    constructor(
        private snackbarService: SnackbarService,
        private overlayService: OverlayService,
        private metadataFormService: MetadataFormService,
        private reportsService: CustomReportsService,
        private fb: UntypedFormBuilder,
        private cultureService: CultureService,
        private lookupService: LookupService,
        private metadataTableService: MetadataTableService,
        private dialogRef: MatDialogRef<ReportElementDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data) {
        this.element = data.element
        this.elementIndex = data.elementIndex
    }

    ngOnInit(): void {

        console.log(this.element)
        this.createForm();
    }

    createForm() {
        this.form = this.fb.group({
            // todo fix: name should be type
            elementType: [this.element?.name ? this.element?.name : '', Validators.required],
            elementName: [''],
        });

        if (this.element) {
            let elementName = this.reportsMapping.getElementName(this.element);
            this.form.controls.elementName.setValue(elementName);
        }
    }


    save() {
        let element: ServerReportElement = {
            id: this.element?.id,
            name: this.form.value.elementType,
            elementTypeId: this.form.value.elementType,
            properties: []
        }

        let parentTableProp = {
            property: 'parentTable',
            value: JSON.stringify(this.form.value.parentTable)
        }
        element.properties.push(parentTableProp);

        let columnProp = {
            property: "columns",
            value: ""
        }
        let newPropValArray = [];
        // console.log(this.form.value.columns)
        this.form.value.columns.forEach((prop: CustomReportDataGridColumn) => {
            newPropValArray.push(prop);
        })
        columnProp.value = JSON.stringify(newPropValArray);
        element.properties.push(columnProp)

        let elementName = {
            property: "elementName",
            value: this.form.value.elementName
        }
        element.properties.push(elementName)

        if (element.elementTypeId === 'datagrid_element') {

            this.reportsMapping.setSeparateLines(element, this.form.value.separateLines);

        } else if (element.elementTypeId === 'piechart_element') {

            let chartTypeProp = {
                property: 'chartType',
                value: JSON.stringify(this.form.value.chartType)
            }
            element.properties.push(chartTypeProp);

            let groupingThresholdProp = {
                property: 'groupingThreshold',
                value: this.form.value.groupingThreshold
            }
            element.properties.push(groupingThresholdProp);

            let colorSchemeProp = {
                property: 'colorScheme',
                value: JSON.stringify(this.form.value.colorScheme)
            }
            element.properties.push(colorSchemeProp);
        }

        this.dialogRef.close({element: element, elementIndex: this.elementIndex});
    }

    close() {
        this.dialogRef.close();
    }
}
