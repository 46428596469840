<app-data-grid-comp
    [columns]="columns"
    [bindingType]="bindingType"
    [view]="view"
    [gridData]="gridData"
    [gridDataResult]="gridDataResult"
    [isLoading]="isLoading"
    [pageSize]="pageSize"
    [skip]="skip"
    category="OtherCompensations"
    [isLoading]="isLoading"
    (emitPageChangeEvent)="pageChange($event)"
    (emitOpenDialogEvent)="openEmploymentRecordOtherCompensationDialog($event)"
    (emitDeleteEvent)="deleteEmploymentRecordCompensation($event)"
></app-data-grid-comp>