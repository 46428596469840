import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { routes } from '@app/consts';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss']
})
export class BenefitsComponent implements OnInit {
  public routes: typeof routes = routes;

  navList = [
    {
      text: this.translate.instant('Coverages'),
      link: `${routes.BENEFITS}${routes.COVERAGES}`,
    },
    {
      text: this.translate.instant('PlanTypes'),
      link: `${routes.BENEFITS}${routes.PLAN_TYPES}`,
    },
    {
      text: this.translate.instant('Plans'),
      link: `${routes.BENEFITS}${routes.PLANS}`,
    },
    {
      text: this.translate.instant('Groups'),
      link: `${routes.BENEFITS}${routes.GROUPS}`,
    },
  ]

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
  }

  

}
