import { Component, Input, NgZone, OnInit } from '@angular/core';
import { ReviewPlanEmployee, ReviewPlanVerbose } from '../../models/review-plan.model';
import { TranslateService } from '@ngx-translate/core';
import { ReviewPlansService } from '../../services/review-plans.service';
import { defer, finalize, forkJoin } from 'rxjs';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SelectEmployeesDialogComponent } from '@app/shared/components/select-employees-dialog/select-employees-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { SnackbarService } from '@app/core/services/snackbar.service';

@Component({
  selector: 'app-review-plan-employees',
  templateUrl: './review-plan-employees.component.html',
  styleUrls: ['./review-plan-employees.component.scss']
})
export class ReviewPlanEmployeesComponent implements OnInit {
  @Input() reviewPlan: ReviewPlanVerbose;

  isLoadingEmployees: boolean;
  employeesGridDataResult: { data: ReviewPlanEmployee[]; total: number; };
  pageSize: number = 5;
  skip: number = 0;
  public columns: any[] = [
    { field: "employee", title: this.translate.instant('ReviewPlanEmployees-Name'), type:'employee' },
  ];
  clearSelectedItems: boolean = false;
  searchValue: string;
  searchFilterString: string;
  getUsersRequest: any;
  selectEmployeesDialogRef: any;
  
  constructor(
    public translate: TranslateService,
    private reviewPlansService: ReviewPlansService,
    private dialog: MatDialog,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private ngZone: NgZone,
    ) { }

  ngOnInit(): void {
    this.getEmployees();
  }

  getEmployees() {
    this.isLoadingEmployees = true;

    this.getUsersRequest = this.reviewPlansService.getReviewPlanEmployees(this.reviewPlan.id, this.pageSize.toString(), this.skip, null, this.searchFilterString)
    .pipe(
      finalize( () => {
        this.isLoadingEmployees = false;
      })
    )
    .subscribe(
      (res) => {
        this.employeesGridDataResult = {
          data: res.data,
          total: res.total,
        }
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.getEmployees();
  }

  showAddUserModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      nonSelectableEmployees: this.employeesGridDataResult?.data.map( reviewPlanEmployee => reviewPlanEmployee.employee.id )
    };

    this.ngZone.runOutsideAngular(() => {
      this.selectEmployeesDialogRef = this.dialog.open(SelectEmployeesDialogComponent, dialogConfig);
    });

    const sub = this.selectEmployeesDialogRef.componentInstance.emitSelectedEmployees.subscribe((event) => {
      this.addEmployees(event)
    });
  }

  addEmployees(selectedEmployees: string[]) {
    const observables = selectedEmployees.map(selectedUserId => defer(() => this.reviewPlansService.addReviewPlanEmployee(this.reviewPlan.id, { employeeId: selectedUserId})));

    this.overlayService.show();

    forkJoin(observables)
        .pipe(finalize(() => this.overlayService.hide()))
        .subscribe(
            (res) => {
                this.getEmployees();
                this.selectEmployeesDialogRef.close();
                this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
            }
        );
  }

  deleteAllSelected(employees: string[]) {
    const observables = employees.map(employee => defer(() => this.reviewPlansService.deleteReviewPlanEmployee(this.reviewPlan.id, employee)));

    this.overlayService.show();

    forkJoin(observables)
      .pipe(finalize(() => this.overlayService.hide()))
      .subscribe(
          (res) => {
              this.clearSelectedItems = !this.clearSelectedItems;
              this.getEmployees();
              this.snackbarService.openSnackBar(this.translate.instant('DeletedSuccessfully'), 'clear', 'success');
          }
      );
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;
    this.searchFilterString = '';
    this.skip = 0;

    let variants = this.searchValue.split(' ').filter(value => value);
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `((EmployeeFirstName like "${variant}") OR (EmployeeLastName like "${variant}"))`;
        }
    });

    this.getUsersRequest.unsubscribe();
    this.getEmployees();
  }
}
