<app-layout>
    <div class="">
        <mat-card class="content">
            <mat-card-title class="content__header">
                <h5 class="content__header-title" appLocalizationPopupDirective localizationCode="CompensationReport">{{ 'CompensationReport' | translate: {Default: "Compensation Report"} }}</h5>

                <app-settings-menu></app-settings-menu>
            </mat-card-title>

            <mat-card-content class="content__charts my-3">
                <div class="item k-mr-4" *ngFor="let item of data; let i = index">
                    <div class="left k-px-4">
                        <apx-chart [chart]="chartSettings[i].chart"
                                   [colors]="chartSettings[i].colors"
                                   [labels]="chartSettings[i].labels"
                                   [plotOptions]="chartSettings[i].plotOptions"
                                   [series]="chartSettings[i].series"
                                   [states]="chartSettings[i].states"
                                   [tooltip]="chartSettings[i].tooltip"
                                   [stroke]="chartSettings[i].stroke">
                        </apx-chart>
                    </div>
                    <div class="right">
                        <div class="label k-font-weight-bold">{{item.label}}</div>
                        <div class="label">{{item.currency}}</div>
                        <div class="value" [ngClass]="i % 2 == 0 ? 'text-green' : 'text-orange'">{{item.displayValue}}</div>
                        <div class="taget">of {{item.target}}</div>
                    </div>
                </div>
            </mat-card-content>

            <mat-card-content class="mt-3">
                <!-- <app-table
                        [tableData]="tableData"
                        [tableColumns]="tableColumns"
                        [isFilterable]="false"
                        [isPageable]="false"
                ></app-table> -->

                <kendo-grid 
                    [style.maxHeight.vh]="85" 
                    [data]="tableData" 
                    [resizable]="true">
                    
                    <kendo-grid-column field="organization" title="organization" [width]="150">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="org-name">
                                <img 
                                class="profile-pic"
                                [src]="dataItem.picture">
                                <span class="org-name">
                                    {{dataItem.label}}
                                </span>
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="subOrgs" title="sub Orgs" [width]="80">
                        <ng-template kendoGridCellTemplate let-dataItem >
                            <div class="grid-cell-vertical">
                                <mat-icon class="material-icons-outlined">{{dataItem.subOrgs}}</mat-icon>
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="view" title="view" [width]="80">
                        <ng-template kendoGridCellTemplate let-dataItem >
                            <div class="grid-cell-vertical">
                                <mat-icon class="material-icons-outlined">{{dataItem.view}}</mat-icon>
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="activity" title="activity" [width]="80">
                        <ng-template kendoGridCellTemplate let-dataItem >
                            <div class="grid-cell-vertical">
                                <mat-icon class="material-icons-outlined">{{dataItem.activity}}</mat-icon>
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="status" title="status" [width]="120">
                        <ng-template kendoGridCellTemplate let-dataItem >
                            <div class="grid-cell-vertical">
                                {{dataItem.status}}
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="positions" title="positions" [width]="120">
                        <ng-template kendoGridCellTemplate let-dataItem >
                            <div class="grid-cell-vertical">
                                {{dataItem.positions}}
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="errors" title="errors" [width]="120">
                        <ng-template kendoGridCellTemplate let-dataItem >
                            <div class="grid-cell-vertical" class="errors-item">
                                <img 
                                src="/assets/icons/error-icon.png">
                                <span>
                                    {{dataItem.errors}}
                                </span>
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="merit" title="merit" [width]="120">
                        <ng-template kendoGridCellTemplate let-dataItem >
                            <div class="grid-cell-vertical">
                                {{dataItem.merit}}
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    
                    <kendo-grid-column field="bonus" title="bonus" [width]="120">
                        <ng-template kendoGridCellTemplate let-dataItem >
                            <div class="grid-cell-vertical">
                                {{dataItem.bonus}}
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="stock" title="stock" [width]="120">
                        <ng-template kendoGridCellTemplate let-dataItem >
                            <div class="grid-cell-vertical">
                                {{dataItem.stock}}
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </mat-card-content>

        </mat-card>
    </div>

</app-layout>
