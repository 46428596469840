import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LookupSubmit, TableListFieldOptionSubmit } from '../../models/lookup.model';
import { LookupService } from '../../services/lookup.service';

@Component({
  selector: 'app-add-multiple-values',
  templateUrl: './add-multiple-values.component.html',
  styleUrls: ['./add-multiple-values.component.scss']
})
export class AddMultipleValuesComponent implements OnInit {
  @Input() listGroup: string;
  @Output() saveComplete = new EventEmitter<boolean>();
  @Output() close = new EventEmitter<boolean>();
  valueSeparator: any = 'newLine';
  lookupValues: any = [];
  customValueSeparator: any;
  separatorPreview: [];
  startDate: string = moment().subtract('1', 'day').format();
  endDate: string;
  cultureValue: string;
  cultures$: Observable<Culture[]>;

  constructor(
    private snackbarService: SnackbarService,
    private lookupService: LookupService,
    private cultureService: CultureService,
    private overlayService: OverlayService
  ) { }

  ngOnInit(): void {
    this.cultures$ = this.cultureService.getCultures();
  }

  splitLookupValues() {
    if (this.valueSeparator === 'space' && this.lookupValues.length > 0) {
      this.separatorPreview = this.lookupValues?.split(' ');
    }
    else if (this.valueSeparator === 'newLine' && this.lookupValues.length > 0) {
      this.separatorPreview = this.lookupValues?.split('\n');
    }
    else if( this.lookupValues.length > 0) {
      this.separatorPreview = this.lookupValues?.split(this.customValueSeparator);
    }
  }

  emitClose() {
    this.close.emit(true);
  }

  save() {
    let apiRequests = [];

    this.separatorPreview.forEach(
      lookupValue => {
        

        let tableListFieldOptionSubmit: TableListFieldOptionSubmit = {
          asOf: moment().format(),
          changeReason: '',
          changeReasonComments: '',
          id: '',
          prioritize: false,
          startDate: moment().subtract('1', 'day').format(),
          endDate: this.endDate,
          text: [
            {
              culture: this.cultureValue,
              text: lookupValue
            }
          ]
        }

        apiRequests.push(this.lookupService.addNewTableListFieldOption(this.listGroup, tableListFieldOptionSubmit));
      }
    );

    this.overlayService.show();

    forkJoin(apiRequests)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.saveComplete.emit(true);
        this.snackbarService.openSnackBar('List option values saved successfully', 'clear', 'success');
      },
      err => {
        this.snackbarService.openSnackBar('We ran into issues saving all the list option values', 'clear', 'warn');
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

}
