import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { api_routes, db_tables } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { defer, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Goal } from "@app/modules/performance/components/goal-plans/models/goal-plan.model";
import { BusinessRuleAutomationService } from "@app/modules/business-rule-automation/services/business-rule-automation.service";
import { NotificationDialogComponent } from "@app/modules/business-rule-automation/components/business-rule-automation-notification-dialog/notification-dialog.component";
import { BusinessRuleAutomationNotification, BusinessRuleAutomationNotificationType, BusinessRuleAutomationRecipientType } from "@app/modules/business-rule-automation/models/business-rule-automation.model";

@Component({
    selector: 'app-business-rule-automation-notifications',
    templateUrl: './business-rule-automation-notifications.component.html',
    styleUrls: ['./business-rule-automation-notifications.component.scss']
})
export class BusinessRuleAutomationNotificationsComponent implements OnInit {

    @Input() businessRuleId: string;
    @Input() notificationTypes: BusinessRuleAutomationNotificationType[] = [];
    @Input() notificationRecipientTypes: BusinessRuleAutomationRecipientType[] = [];

    public columns: any[] = [
        {field: "subject", tableId: "", title: "Subject", type: "longText"},
        {field: "status", tableId: "", title: "Status", type: "booleanToggle"},
        {field: "notificationType", subField: 'name', tableId: "", title: "Contact Type"},
        {field: "notificationRecipients", arrayItemField: "emailAddress", tableId: "", title: "Recipients", type: 'array'},
    ];

    // filterCategories: any[] = [
    //
    // ];
    filterCategories: any[] = null;

    sortableColumns: any[] = [];

    public bindingType: String = "array";
    public view: any;
    public gridData: any;
    public gridDataResult: GridDataResult;
    public api_routes = api_routes;
    public db_tables = db_tables;
    isLoading: boolean;
    pageSize: number = 5;
    skip: number = 0;
    clearSelectedItems: boolean = false;
    dialogRef: any;
    formValid: any;
    sortString: string;
    searchValue: string;
    searchFilterString: string;
    filterString: any;
    getDataRequest: any;

    constructor(
        private dialog: MatDialog,
        public translate: TranslateService,
        private snackbarService: SnackbarService,
        private route: ActivatedRoute,
        private router: Router,
        private overlayService: OverlayService,
        private service: BusinessRuleAutomationService,
    ) {
    }

    ngOnInit(): void {
        this.view = this.getData();
    }

    getData() {
        this.isLoading = true;

        let filter;
        if (this.searchFilterString) {
            filter = this.searchFilterString;
        }
        if (this.filterString) {
            if (filter) {
                filter = `${filter} AND ${this.filterString}`;
            } else {
                filter = `${this.filterString}`;
            }
        }

        this.getDataRequest = this.service.getBusinessRuleAutomationNotifications(this.businessRuleId, this.skip, this.pageSize, this.sortString, filter)
        .pipe(
            finalize(() => {
                this.isLoading = false;
            })
        )
        .subscribe(
            (res) => {
                this.gridDataResult = {
                    data: res.data,
                    total: res.total,
                }
                this.gridData = res.data;
            }
        );
    }

    getNotificationVerbose(goal?: Goal) {
        if (goal === undefined) {
            this.openNotificationDialog();
        } else {
            let notification: BusinessRuleAutomationNotification;

            this.isLoading = true;

            this.service.getBusinessRuleAutomationNotificationById(this.businessRuleId, goal.id)
            .pipe(
                finalize(() => this.isLoading = false)
            )
            .subscribe(
                res => {
                    notification = res;
                    this.openNotificationDialog(notification);
                }
            );
        }
    }

    openNotificationDialog(notification?: BusinessRuleAutomationNotification) {

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.minWidth = 600;

        dialogConfig.data = {
            notification: notification,
            notificationTypes: this.notificationTypes,
            recipientTypes: this.notificationRecipientTypes,
        };

        const dialogRef = this.dialog.open(NotificationDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data) {
                    console.log("data", data);

                    this.isLoading = true;
                    if (data.notification.id === null) {
                        this.service.createBusinessRuleAutomationNotification(this.businessRuleId, data.notification)
                        .pipe(
                            finalize(() => this.isLoading = false)
                        )
                        .subscribe(
                            res => {
                                this.view = this.getData();
                                this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
                            }
                        );
                    } else {
                        this.service.updateBusinessRuleAutomationNotification(this.businessRuleId, data.notification.id, data.notification)
                        .pipe(
                            finalize(() => this.isLoading = false)
                        )
                        .subscribe(
                            res => {
                                this.view = this.getData();
                                this.snackbarService.openSnackBar(`${this.translate.instant('UpdatedSuccessfully')}`, 'clear', 'success');
                            }
                        );
                    }
                }
            }
        );
    }


    deleteNotifications(ids: string[]) {
        const observables = ids.map(selectedItem => defer(() => this.service.deleteBusinessRuleAutomationNotification(this.businessRuleId, selectedItem)));

        this.overlayService.show();

        forkJoin(observables)
        .pipe(
            finalize(() => this.overlayService.hide())
        )
        .subscribe(
            (res) => {
                this.view = this.getData();
                this.clearSelectedItems = !this.clearSelectedItems;
                this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
            }
        );
    }

    public pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.pageSize = event.take
        this.view = this.getData();
    }

    search(newSearchString: string) {
        this.searchValue = newSearchString;

        let variants = this.searchValue.split(' ').filter(value => value);
        this.searchFilterString = '';
        variants.map(variant => {
            if (variant) {
                if (this.searchFilterString.length > 2) {
                    this.searchFilterString += ' AND ';
                }
                this.searchFilterString += `(Description like "${variant}")`;
            }
        });

        this.getDataRequest.unsubscribe();
        this.getData();
    }

    sortChange(sortString: string) {
        this.sortString = sortString;
        this.getData();
    }

    filterCallback(filterString: string) {
        this.filterString = filterString;
        this.skip = 0;
        ;
        this.getData();
    }
}
