import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'no-feature-access',
    templateUrl: './no-feature-access.component.html',
    styleUrls: ['./no-feature-access.component.scss']
})
export class NoFeatureAccessComponent implements OnInit {
    constructor() {}
    ngOnInit(): void {}
}
