<ng-container *ngIf="!loadingFormDetails; loading">
    <div class="form">
        <h5 mat-dialog-title>{{ 'GoalTypeSettings' | translate: {Default: "Goal Type Settings"} }}</h5>
        
        <mat-dialog-content>
            <app-form-generator 
                [formId]="formId"
                [formData]="formData"
                [emitFormDataTrigger]="getFormData"
                [targetEmployeeTablePermissions]="targetEmployeeTablePermissions"
                (emitFormData)="formDataEmitted($event)"
                (emitFormPristine)="formPristineEmitted($event)"
                (emitFormStatus)="formStatusUpdated($event)"
            ></app-form-generator>

            <div class="form-section">
                <div class="form-section-title">
                    <h6>{{ 'GoalTypeRatingsValues' | translate: {Default: "Goal Type Ratings Values"} }}</h6>
                </div>
                <div class="form-section-contents">
                    <div class="section-row">
                        <div [formGroup]="ratingsForm">
                            <ng-container formArrayName="ratings">
                                <ng-container *ngFor="let ratingForm of ratings.controls; let i = index">
                                    <div class="lesson-form-row" [formGroup]="ratingForm">
                                        <mat-form-field appearance="fill">
            
                                            <mat-label>{{ getRatingName(i) }} {{ 'Rating' | translate: {Default: "Rating"} }}</mat-label>
            
                                            <input matInput
                                                   type="number"
                                                   formControlName="value"
                                                   placeholder="value">
                                        </mat-form-field>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

        </mat-dialog-content>
        
        <mat-dialog-actions>
            <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
            
            <button 
                *appTableAccess="[tableUpdateAccessObj]" 
                mat-raised-button 
                setColor="primaryColour" 
                color="primary" 
                [disabled]="formValid === false" 
                appPreventDoubleClick 
                (throttledClick)="getFormData = !getFormData">
                {{ 'Save' | translate: {Default: "Save"} }}
            </button> 
        </mat-dialog-actions>
    </div>
</ng-container>

<ng-template #loading>
    <mat-dialog-content class="loading">
        <ngx-skeleton-loader
        count="5"
        [theme]="{ 
            'height.px': 50
        }"
        ></ngx-skeleton-loader>
    </mat-dialog-content>
</ng-template>
