<app-data-grid-comp
    [columns]="columns"
    [gridDataResult]="employeesGridDataResult"
    [pageSize]="pageSize"
    [skip]="skip"
    category="Employees"
    [rowSelectionSettings]="{
        canSelectRows: true,
        selectableMode: 'multiple',
        selectRowBy: 'id'
    }"
    selectRowBy="employee"
    actionButtonText="Add"
    [isLoading]="isLoadingEmployees"
    [clearSelectedItems]="clearSelectedItems"
    searchable="true"
    (emitSearchEvent)="search($event)"
    (emitPageChangeEvent)="pageChange($event)"
    (emitOpenDialogEvent)="showAddUserModal()"
    (emitDeleteEvent)="deleteAllSelected($event)"
></app-data-grid-comp>