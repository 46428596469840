<table mat-table
    [dataSource]="tableDataSource" multiTemplateDataRows
    class="mat-elevation-z0">

  <!-- action column -->
  <ng-container *ngIf="rowActionIcon?.length" [matColumnDef]="btnsColumn">
    <th mat-header-cell *matHeaderCellDef></th>
    <td class="btns-column" mat-cell *matCellDef="let element" [id]="btnsColumn">
      <!-- <mat-icon *ngFor="let icon of rowActionIcon" (click)="emitRowAction(element, icon)">{{icon}}</mat-icon> -->
      <span class="material-icons-outlined actionIcon" 
            *ngFor="let icon of rowActionIcon" 
            (click)="emitRowAction(element, icon); expandedElement = expandedElement === element ? null : element">
        {{icon}}
      </span>
    </td>
  </ng-container>


  <ng-container matColumnDef="{{column}}" *ngFor="let column of tableColumns">
    <th mat-header-cell *matHeaderCellDef> {{column}} </th>
    <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail" >
    <td mat-cell *matCellDef="let element" [attr.colspan]="tableColumns.length" class="expanded-detail">
      <div class="example-element-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        
        <div class="expand-data-container" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutAlign="space-between stretch" fxLayoutGap="10px">
          

          <div class="expansion-table-container" *ngFor="let expansionTableData of dataForTableExpansions[element.id]">
            <!-- <div class="add-new-div">
              <button mat-flat-button color="warn" (click)="emitAddNew(expansionTableData.label, element.id)">
                <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> Add New
              </button>
            </div> -->
            <div class="expansion-table-data-header">
              <h5>{{expansionTableData.label}}</h5>
              <span 
                  (click)="emitAddNew(expansionTableData.label, element.id)" 
                  class="material-icons-outlined"
                  matTooltip="Add To {{expansionTableData.label}}">
                  add
              </span>
            </div>

            <div *ngIf="expansionTableData.data | async as data">
              <app-table
                [tableData]="data"
                [tableColumns]="expansionTableData.tableColumns"
                [rowActionIcon]="['create', 'delete']"
                [isFilterable]="false"
                [parent]="{parentId:element.id, parentLabel:expansionTableData.label}"
                [setPaginatorBugFix]="true"
                (sort)="sortData($event)"
                (rowAction)="rowEvent($event)"
                (pageChangeAction)="pageChangeEvent($event)"
              ></app-table>
            </div>
          </div>

        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>