<app-employee-details>
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
            <h5 appLocalizationPopupDirective localizationCode="LeaveHistory">
                {{ 'LeaveHistory' | translate: {Default: "Leave History"} }}
            </h5>
        </mat-card-title>
        <mat-card-content>
            <div class="top-container">
                <ng-container *ngIf="isLoadingPermissions; else loaded">
                    <ngx-skeleton-loader
                      count="5"
                      [theme]="{ 
                          'height.px': 50
                      }"
                    ></ngx-skeleton-loader>
                  </ng-container>
                  <ng-template #loaded>
                      <div class="coverage-container" *ngIf="timeOffLeaveTypes$ | withLoading | async as timeOffLeaveTypes">
                          <ng-template [ngIf]="timeOffLeaveTypes.value">
                              <button appLocalizationPopupDirective localizationCode="AddAPolicy" mat-stroked-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openAssignPolicyDialog()" *appTableAccess="[tableCreateAccessObj, tableUpdateAccessObj]">
                                  <mat-icon class="material-icons-outlined" >add</mat-icon>
                                  {{ 'AddAPolicy' | translate: {Default: "Add A Policy"} }}
                              </button>
          
                              <div class="coverages">
                                  <h6 *ngIf="timeOffLeaveTypes.value.length === 0">{{ 'NoTimeOffPoliciesAssigned' | translate: {Default: "No time off policies assigned"} }}</h6>
              
                                  <ng-container *ngFor="let timeOffLeaveType of timeOffLeaveTypes.value" >
                                      <app-balance-control 
                                            *ngIf="timeOffLeaveType.timeOffPolicy !== null"
                                            [employeeId]="employeeId"
                                            [employeeTimeOffType]="timeOffLeaveType"
                                            [targetEmployeeTablePermissions]="targetEmployeeTablePermissions"
                                            (emitDeleted)="getTimeOffTypes()">
                                      </app-balance-control>
                                  </ng-container>
                              </div>
                          </ng-template>
          
                          <ng-template [ngIf]="timeOffLeaveTypes.error">Error</ng-template>
                          <ng-template [ngIf]="timeOffLeaveTypes.loading">
                              <ngx-skeleton-loader
                                  count="5"
                                  [theme]="{ 
                                      'height.px': 50
                                  }"
                              ></ngx-skeleton-loader>
                          </ng-template>
                      </div>
                  </ng-template>
            </div>
            
            <app-employee-absences [employeeID]="employeeId"></app-employee-absences>
    
            <app-employee-stat-holiday-group 
                *ngIf="!isLoadingPermissions"
                [employeeId]="employeeId" 
                [targetEmployeeTablePermissions]="targetEmployeeTablePermissions"
            ></app-employee-stat-holiday-group>
    
            
            <!-- <div class="bottom-container">
                <div>
                    <mat-chip-list>
                        <mat-chip *ngFor="let chip of chips" [selected]="chip.state" (selectionChange)="changeSelected($event, chip)" (click)="chip.state=!chip.state">{{chip.name}}</mat-chip>
                    </mat-chip-list>
            
                    <table *ngIf="leaveRequests != null" mat-table [dataSource]="leaveRequests" class="support-requests__table" #myTable>
            
                        <ng-container  *ngFor="let column of displayedColumns" matColumnDef="{{column}}" class="support-requests__table-row">
                            <th mat-header-cell *matHeaderCellDef class="support-requests__table-row-title">
                            <span *ngIf="column !== 'delete'">{{column}}</span>
                            </th>
                            <td mat-cell *matCellDef="let element" class="support-requests__table-content">
                            <span *ngIf="column !== 'status' && column !== 'actions'">{{element[column]}}</span>
                            <div *ngIf="column === 'status'" class="support-requests__content-badge" [ngClass]="element[column]">
                                <span>{{element[column]}}</span>
                            </div>
                            <div *ngIf="column === 'actions'">
                                <button class="settings-button" mat-mini-fab [matMenuTriggerFor]="settings">
                                    <mat-icon class="settings-button__icon material-icons-outlined">more_vert</mat-icon>
                                </button>
                                <mat-menu #settings="matMenu" xPosition="before">
                                    <button class="settings-menu__item material-icons-outlined" (click)="changeStatus(element, 'Approved')" mat-menu-item>Approve</button>
                                    <button class="settings-menu__item material-icons-outlined" (click)="changeStatus(element, 'Rejected')" mat-menu-item>Reject</button>
                                    <button class="settings-menu__item material-icons-outlined" (click)="changeStatus(element, 'Pending')" mat-menu-item>Pending</button>
                                </mat-menu>
                            </div>
                            </td>
                        </ng-container>
                    
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
    
                    <div class="leave-chart-container">
                        <div class="leave-requests-chart__content-chart" echarts [options]="leaveRequestChart"></div>
                        <div class="chart-figures">
                            <div class="row days-remaining">
                                <div class="dot"></div>
                                <div>
                                    <div class="label"> Days Remaining</div>
                                    <div class="value">11 Days</div>
                                </div>
                            </div>
                            <div class="row days-used">
                                <div class="dot"></div>
                                <div>
                                    <div class="label"> Days Used</div>
                                    <div class="value">3 Days</div>
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <div class="leave-chart-container">
                        <div class="leave-requests-chart__content-chart" echarts [options]="sickDaysChart"></div>
                        <div class="chart-figures">
                            <div class="row days-remaining">
                                <div class="dot"></div>
                                <div>
                                    <div class="label"> Days Remaining</div>
                                    <div class="value">7 Days</div>
                                </div>
                            </div>
                            <div class="row days-used">
                                <div class="dot"></div>
                                <div>
                                    <div class="label"> Days Used</div>
                                    <div class="value">1 Days</div>
                                </div>
                            </div>
                        </div>
                    </div>
    
            </div> -->
        </mat-card-content>
      </mat-card>
</app-employee-details>
