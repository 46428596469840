import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@app/core/services/environment.service';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { Observable, of } from 'rxjs';
import { api_routes } from '@app/consts';
import { ReviewPlan } from '../../review-plans/models/review-plan.model';
import { GlobalGoalPlanReview, GlobalGoalPlanReviewCompleteSubmit, GlobalGoalPlanReviewRevertSubmit, GlobalGoalPlanReviewSubmit, GlobalGoalPlanReviewVerbose, GlobalGoalPlanReviewer, GlobalGoalReview, GlobalGoalReviewCompleteSubmit, GlobalGoalReviewRevertSubmit, GlobalGoalReviewSubmit, GlobalGoalReviewVerbose, GlobalGoalReviewer } from '../models/global-reviews.model';

const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class GlobalReviewsService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getReviewPlans(take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<ReviewPlan>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}${api_routes.GLOBAL_REVIEWS}`, {
      headers: headers,
      params: params
    });
  }

  /*
  ..######...##........#######..########.....###....##................######....#######.....###....##...............########..##..........###....##....##.........########..########.##.....##.####.########.##......##..######......######..########..##.....##.########.
  .##....##..##.......##.....##.##.....##...##.##...##...............##....##..##.....##...##.##...##...............##.....##.##.........##.##...###...##.........##.....##.##.......##.....##..##..##.......##..##..##.##....##....##....##.##.....##.##.....##.##.....##
  .##........##.......##.....##.##.....##..##...##..##...............##........##.....##..##...##..##...............##.....##.##........##...##..####..##.........##.....##.##.......##.....##..##..##.......##..##..##.##..........##.......##.....##.##.....##.##.....##
  .##...####.##.......##.....##.########..##.....##.##...............##...####.##.....##.##.....##.##...............########..##.......##.....##.##.##.##.........########..######...##.....##..##..######...##..##..##..######.....##.......########..##.....##.##.....##
  .##....##..##.......##.....##.##.....##.#########.##...............##....##..##.....##.#########.##...............##........##.......#########.##..####.........##...##...##........##...##...##..##.......##..##..##.......##....##.......##...##...##.....##.##.....##
  .##....##..##.......##.....##.##.....##.##.....##.##...............##....##..##.....##.##.....##.##...............##........##.......##.....##.##...###.........##....##..##.........##.##....##..##.......##..##..##.##....##....##....##.##....##..##.....##.##.....##
  ..######...########..#######..########..##.....##.########.#######..######....#######..##.....##.########.#######.##........########.##.....##.##....##.#######.##.....##.########....###....####.########..###..###...######......######..##.....##..#######..########.
  */

  getGlobalGoalPlanReviews(take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<GlobalGoalPlanReview>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.GLOBAL_GOAL_PLAN_REVIEWS}`, {
      headers: headers,
      params: params
    });
  }

  getGlobalGoalPlanReview(reviewPlanId: string, goalPlanId: string, reviewerId: string): Observable<GlobalGoalPlanReviewVerbose> {
    let params = new HttpParams();

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.GLOBAL_GOAL_PLAN_REVIEWS}/${reviewPlanId}/GoalPlan/${goalPlanId}/Reviewer/${reviewerId}`, {
      headers: headers,
      params: params
    });
  }

  updateGlobalGoalPlanReview(globalGoalPlanReviewSubmit: GlobalGoalPlanReviewSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.GLOBAL_GOAL_PLAN_REVIEWS}`, globalGoalPlanReviewSubmit, {
      headers: headers
    });
  }

  revertGlobalGoalPlanReview(globalGoalPlanReviewRevertSubmit: GlobalGoalPlanReviewRevertSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.GLOBAL_GOAL_PLAN_REVIEWS}/Revert`, globalGoalPlanReviewRevertSubmit, {
      headers: headers
    });
  }
  
  completeGlobalGoalPlanReview(globalGoalPlanReviewCompleteSubmit: GlobalGoalPlanReviewCompleteSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.GLOBAL_GOAL_PLAN_REVIEWS}/Complete`, globalGoalPlanReviewCompleteSubmit, {
      headers: headers
    });
  }

  getGlobalGoalPlanReviewer(reviewPlanId: string, goalId: string, reviewer: string): Observable<GlobalGoalPlanReviewer> {
    let params = new HttpParams();

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.GLOBAL_GOAL_PLAN_REVIEWS}/${reviewPlanId}/Goal/${goalId}/Reviewer/${reviewer}`, {
      headers: headers,
      params: params
    });
  }

  /*
  ..######...##........#######..########.....###....##...........######....#######.....###....##..........########..########.##.....##.####.########.##......##..######......######..########..##.....##.########.
  .##....##..##.......##.....##.##.....##...##.##...##..........##....##..##.....##...##.##...##..........##.....##.##.......##.....##..##..##.......##..##..##.##....##....##....##.##.....##.##.....##.##.....##
  .##........##.......##.....##.##.....##..##...##..##..........##........##.....##..##...##..##..........##.....##.##.......##.....##..##..##.......##..##..##.##..........##.......##.....##.##.....##.##.....##
  .##...####.##.......##.....##.########..##.....##.##..........##...####.##.....##.##.....##.##..........########..######...##.....##..##..######...##..##..##..######.....##.......########..##.....##.##.....##
  .##....##..##.......##.....##.##.....##.#########.##..........##....##..##.....##.#########.##..........##...##...##........##...##...##..##.......##..##..##.......##....##.......##...##...##.....##.##.....##
  .##....##..##.......##.....##.##.....##.##.....##.##..........##....##..##.....##.##.....##.##..........##....##..##.........##.##....##..##.......##..##..##.##....##....##....##.##....##..##.....##.##.....##
  ..######...########..#######..########..##.....##.########.....######....#######..##.....##.########....##.....##.########....###....####.########..###..###...######......######..##.....##..#######..########.
  */

  getGlobalGoalReviews(take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<GlobalGoalReview>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.GLOBAL_GOAL_REVIEWS}`, {
      headers: headers,
      params: params
    });
  }

  getGlobalGoalReview(reviewPlanId: string, goalPlanId: string, reviewerId: string): Observable<GlobalGoalReviewVerbose> {
    let params = new HttpParams();

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.GLOBAL_GOAL_REVIEWS}/${reviewPlanId}/GoalPlan/${goalPlanId}/Reviewer/${reviewerId}`, {
      headers: headers,
      params: params
    });
  }

  updateGlobalGoalReview(globalGoalReviewSubmit: GlobalGoalReviewSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.GLOBAL_GOAL_REVIEWS}`, globalGoalReviewSubmit, {
      headers: headers
    });
  }

  revertGlobalGoalReview(globalGoalReviewRevertSubmit: GlobalGoalReviewRevertSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.GLOBAL_GOAL_REVIEWS}/Revert`, globalGoalReviewRevertSubmit, {
      headers: headers
    });
  }
  
  completeGlobalGoalReview(globalGoalReviewCompleteSubmit: GlobalGoalReviewCompleteSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.GLOBAL_GOAL_REVIEWS}/Complete`, globalGoalReviewCompleteSubmit, {
      headers: headers
    });
  }

  getGlobalGoalReviewer(reviewPlanId: string, goalId: string, reviewer: string): Observable<GlobalGoalReviewer> {
    let params = new HttpParams();

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.GLOBAL_GOAL_REVIEWS}/${reviewPlanId}/Goal/${goalId}/Reviewer/${reviewer}`, {
      headers: headers,
      params: params
    });
  }
  
}