import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-diversity-scorecard',
  templateUrl: './diversity-scorecard.component.html',
  styleUrls: ['./diversity-scorecard.component.scss']
})
export class DiversityScorecardComponent implements OnInit {
  data = [
    {
      icon: "./assets/icons/green-up-arrow.png",
      label: "Female % (US)",
      value: "41.5%",
      target: "40.0%",
    },
    {
      icon: "./assets/icons/warning.svg",
      label: "Millenial % (US)",
      value: "18.5%",
      target: "20.0%",
    },
    {
      icon: "./assets/icons/green-up-arrow.png",
      label: "Veteran % (US)",
      value: "5.1%",
      target: "5.0%",
    },
    {
      icon: "./assets/icons/green-up-arrow.png",
      label: "Disabled % (US)",
      value: "5.6%",
      target: "3.0%",
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
