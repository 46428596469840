<mat-card>
    <mat-card-title>
        <h5 appLocalizationPopupDirective [localizationCode]="'RequestTimeOff'">{{ 'RequestTimeOff' | translate: {Default: "Request Time Off"} }}</h5>
    </mat-card-title>

    <mat-card-content>
        <kendo-grid
            [data]="gridDataResult"
            [height]="410"
            [loading]="isLoading"
        >
            <kendo-grid-column field="timeOffPolicy" width="150">
                <ng-template kendoGridHeaderTemplate>
                    <div appLocalizationPopupDirective [localizationCode]="'RequestTimeOff-TimeOffType'">
                        {{ 'RequestTimeOff-TimeOffType' | translate: {Default: "Time Off Type"} }}
                    </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        {{dataItem.timeOffType.name}}
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="availableHours" width="200px">
                <ng-template kendoGridHeaderTemplate>
                    <div appLocalizationPopupDirective [localizationCode]="'RequestTimeOff-AvailableHours'">
                        {{ 'RequestTimeOff-AvailableHours' | translate: {Default: "Available Hours"} }}
                    </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        {{dataItem.availableHours  | number:'1.0-1'}}
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="futureHoursScheduled" width="250px">
                <ng-template kendoGridHeaderTemplate>
                    <div appLocalizationPopupDirective [localizationCode]="'RequestTimeOff-FutureHoursScheduled'">
                        {{ 'RequestTimeOff-FutureHoursScheduled' | translate: {Default: "Future Hours Scheduled"} }}
                    </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        {{dataItem.futureHoursScheduled  | number:'1.0-1'}}
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="hoursTaken" width="150px">
                <ng-template kendoGridHeaderTemplate>
                    <div appLocalizationPopupDirective [localizationCode]="'RequestTimeOff-HoursTaken'">
                        {{ 'RequestTimeOff-HoursTaken' | translate: {Default: "Hours Taken"} }}
                    </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        {{dataItem.hoursTaken  | number:'1.0-1'}}
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="actionButtons" title="" width="170px">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        <button mat-stroked-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openDialog(dataItem.timeOffType.id)">
                            {{ 'timeOffLeaveTypeRequestTimeOff' | translate: {Default: "Request Time Off"} }}
                        </button>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="actionButtons" title="" width="200px">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical" *ngIf="dataItem?.timeOffPolicy?.showAccrualSchedule === true">
                        <button mat-stroked-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openFutureScheduleDialog(dataItem)">
                            {{ 'ViewFutureSchedule' | translate: {Default: "View Future Schedule"} }}
                        </button>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <ng-template kendoGridNoRecordsTemplate>
                <div appLocalizationPopupDirective [localizationCode]="'NoPoliciesAssigned'">
                    {{ 'NoPoliciesAssigned' | translate: {Default: "No Policies Assigned"} }}
                </div>
             </ng-template>

             <kendo-grid-messages 
                [noRecords]="isLoading ? '' : translate.instant('Grid-NoRecordsAvailable')"
                [pagerItemsPerPage]="translate.instant('Grid-Pager-ItemsPerPage')" 
                [pagerItems]="translate.instant('Grid-Pager-Items')"
                [pagerOf]="translate.instant('Grid-Pager-Of')"
            ></kendo-grid-messages>
        </kendo-grid>
    </mat-card-content>
</mat-card>
