<mat-card class="chart">
    <mat-card-title class="chart__header">
      <h5 class="chart__header-title" appLocalizationPopupDirective localizationCode="HeadcountByMonth-ByTenure">{{ 'HeadcountByMonth-ByTenure' | translate: {Default: "Headcount By Month (by Tenure)"} }}</h5>
  
      <app-settings-menu></app-settings-menu>
    </mat-card-title>
  
    <mat-card-content class="chart__content">
        <div id="chart">
            <apx-chart
                [series]="chartOptions.series"
                [chart]="chartOptions.chart"
                [xaxis]="chartOptions.xaxis"
                [title]="chartOptions.title"
            ></apx-chart>
        </div>
    </mat-card-content>
</mat-card>