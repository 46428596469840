import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { api_routes, db_tables } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { GoalType } from '@app/modules/performance/components/goal-types/models/goal-type.model';
import { GoalTypeService } from '@app/modules/performance/components/goal-types/services/goal-type.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-select-goal-type-dialog',
  templateUrl: './select-goal-type-dialog.component.html',
  styleUrls: ['./select-goal-type-dialog.component.scss']
})
export class SelectGoalTypeDialogComponent implements OnInit {
  public columns: any[] = [
    { field: "name", tableId: "tfi_PgTName", title: "Name" },
    { field: "allowEmployeeGoalEntry", tableId: "tfi_PgTAllowEmployeeGoalEntry", title: "Allow Employee Goal Entry", type: "booleanToggle"},
    { field: "hasNotes", tableId: "tfi_PgTHasNotes", title: "Has Notes", type: "booleanToggle"},
    { field: "allowEmployeeSubGoalCreation", tableId: "tfi_PgTAllowEmployeeSubGoalCreation", title: "Allow Employee Sub Goal Creation", type: "booleanToggle"},
    { field: "completionDateFieldSetting", subField: "description", tableId: "tfi_PgTCompletionDateFieldSetting", title: "Completion Date Field Setting"},
    { field: "expectedCompletionDateFieldSetting", subField: "description", tableId: "tfi_PgTExpectedCompletionDateFieldSetting", title: "Expected Completion Date Field Setting"},
    // { field: "statusFieldSetting", subField: "description", tableId: "tfi_PgTStatusFieldSetting", title: "Status Field Setting"},
    { field: "percentageCompleteFieldSetting", subField: "description", tableId: "tfi_PgTPercentageCompleteFieldSetting", title: "Percentage Complete Field Setting"},
    { field: "status", subField: "name", tableId: "tfi_PgTStatus", title: "Status", type: 'chip', chipValue: ['Active', 'Archived'] },
  ];

  filterCategories: any[] = [
    { field: "name", tableId: "tfi_PgTName", title: "Name", type: "String", dataType: 'String'},
    { field: "completionDateFieldSetting", subField: "description", tableId: "tfi_PgTCompletionDateFieldSetting", title: "Completion Date Field Setting", dataType: "SpecialLookup", lookupCode: 'GOAL_TYPE_FIELD_SETTING'},
    { field: "expectedCompletionDateFieldSetting", subField: "description", tableId: "tfi_PgTExpectedCompletionDateFieldSetting", title: "Expected Completion Date Field Setting", dataType: "SpecialLookup", lookupCode: 'GOAL_TYPE_FIELD_SETTING'},
    // { field: "statusFieldSetting", subField: "description", tableId: "tfi_PgTStatusFieldSetting", title: "Status Field Setting", dataType: "SpecialLookup", lookupCode: 'GOAL_TYPE_FIELD_SETTING'},
    { field: "percentageCompleteFieldSettingDescription", subField: "description", tableId: "tfi_PgTPercentageCompleteFieldSetting", title: "Percentage Complete Field Setting", dataType: "SpecialLookup", lookupCode: 'GOAL_TYPE_FIELD_SETTING'},
    { field: "status", subField: "name", tableId: "tfi_PgTStatus", title: "Status", dataType: "SpecialLookup", lookupCode: 'GOAL_TYPE_STATUS'},
    { field: "allowEmployeeSubGoalCreation", tableId: "tfi_PgTAllowEmployeeSubGoalCreation", title: "Allow Employee Sub Goal Creation", dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
    { field: "hasNotes", tableId: "tfi_PgTHasNotes", title: "Has Notes", dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
    { field: "allowEmployeeGoalEntry", tableId: "tfi_PgTAllowEmployeeGoalEntry", title: "Allow Employee Goal Entry", dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
  ];

  sortableColumns: any[] = [
    { field: "name", sortValue: "name"},
    { field: "allowEmployeeGoalEntry", sortValue: "allowEmployeeGoalEntry"},
    { field: "allowEmployeeSubGoalCreation", sortValue: "allowEmployeeSubGoalCreation"},
    { field: "hasNotes", sortValue: "hasNotes"},
    { field: "completionDateFieldSetting", sortValue: "completionDateFieldSettingDescription"},
    { field: "expectedCompletionDateFieldSetting", sortValue: "expectedCompletionDateFieldSettingDescription"},
    // { field: "statusFieldSetting", sortValue: "statusFieldSettingDescription"},
    { field: "percentageCompleteFieldSetting", sortValue: "percentageCompleteFieldSettingDescription"},
    { field: "status", sortValue: "statusName" },
  ];

  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 10;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  formValid: any;
  searchValue: string;
  searchFilterString: string;
  getGoalsTypesRequest: any;
  sortString: string;
  filterString: string;
  selectedGoalType: GoalType;
  mandatoryFilter: string;
  
  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private overlayService: OverlayService,
    private dialogRef: MatDialogRef<SelectGoalTypeDialogComponent>,
    private goalTypeService: GoalTypeService,
    @Inject(MAT_DIALOG_DATA) data
  ) { 

    data !== null && data.mandatoryFilter !== null ? this.mandatoryFilter = data.mandatoryFilter : null
  }

  ngOnInit(): void {
    this.view = this.getGoalTypes();
  }

  getGoalTypes() {
    let filter = this.mandatoryFilter;

    if(this.searchFilterString) {
      if(filter === undefined){
        filter = this.searchFilterString;
      }
      else {
        filter = `${filter} AND ${this.searchFilterString}`;
      }
    }
    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }

    this.isLoading = true;

    this.getGoalsTypesRequest = this.goalTypeService.getGoalTypes(String(this.pageSize), this.skip, this.sortString, filter)
    .pipe(
      finalize( () => {
        this.isLoading = false;
      })
    )
    .subscribe(
      (res) => {
        this.gridDataResult = {
          data: res.data,
          total: res.total,
        }
        this.gridData = res.data;
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.view = this.getGoalTypes();
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;;
    this.getGoalTypes();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getGoalTypes();
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;

    let variants = this.searchValue.split(' ').filter(value => value);
    this.searchFilterString = '';
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `(name like "${variant}")`;
        }
    });

    this.getGoalsTypesRequest.unsubscribe();
    this.getGoalTypes();
  }

  save() {
    this.dialogRef.close(this.selectedGoalType);
  }

  close() {
    this.dialogRef.close();
  }

  selectedRowsChanged(selectionEvent: SelectionEvent){
    if(selectionEvent?.selectedRows[0]?.dataItem){
      this.selectedGoalType = selectionEvent.selectedRows[0].dataItem;
    }
    else {
      this.selectedGoalType = null;
    }
  }
}
