import { returnedLookup } from "@app/modules/lookup/models/lookup.model";
import { Version } from "./version.model";

export class Employee {
    userId: string;
    maidenName: string;
    birthday: string;
    gender: returnedLookup;
    maritalStatus: returnedLookup;
    culturalAffiliation: returnedLookup;
    religiousAffiliation: returnedLookup;
    profilePictureUri: string;
    profilePictureThumbnailUri: string;
    timezone: string;
    customClientId: string;
    positions: {
        id: string;
        employmentRecordId: string;
        positionId: string;
        startDate: string;
        endDate: string;
        name: string
    }[];
    managers: {
        id: string;
        firstname: string;
        lastname: string;
        profilePictureThumbnailUri: string
    }[];
    id: string;
    username: string;
    preferredLanguage: string;
    firstname: string;
    lastname: string;
    middleName: string;
    preferredName: string;
    title: returnedLookup;
}

export class Talent {
    id: string;
    userId: string;
    firstname: string;
    lastname: string;
    title: returnedLookup;
    preferred: string;
    maidenName: string;
    middleName: string;
    gender: returnedLookup;
    birthday: string;
    maritalStatus: returnedLookup;
    culturalAffiliation: returnedLookup;
    religiousAffiliation: returnedLookup;
    customClientId: string;
    linkedInUrl: string;
    twitterHandle: string;
    facebookUrl: string;
    status: string;
    subStatus: returnedLookup;
    preferredLanguage: string;
    streetAddress: string;
    city: string;
    province: string;
    zip: string;
    country: returnedLookup;
    email: string;
    workPhone: string;
    homePhone: string;
    cellPhone: string;
    positions: returnedLookup[];
    workLocations: returnedLookup[];
    departments: returnedLookup[];
    managers: {
        id: string;
        firstName: string;
        lastName: string
    }[];
    profilePictureUri: string;
    timezone: returnedLookup;
    culture: returnedLookup;
}

export class TalentVerbose {
    userId: string;
    username: string;
    version: Version;
    id: string;
    firstName: string;
    lastName: string;
    title: returnedLookup;
    preferredName: string;
    maidenName: string;
    middleName: string;
    gender: returnedLookup;
    culturalAffiliation: returnedLookup;
    religiousAffiliation: returnedLookup;
    customClientId: string;
    linkedInUrl: string;
    twitterHandle: string;
    facebookUrl: string;
    status: string;
    birthday: string;
    maritalStatus: returnedLookup;
    subStatus: returnedLookup;
    preferredLanguage: returnedLookup;
    streetAddress: string;
    city: string;
    province: string;
    zip: string;
    country: returnedLookup;
    email: string;
    workPhone: string;
    homePhone: string;
    cellPhone: string;
    positions: returnedLookup[];
    workLocations: returnedLookup[];
    departments: returnedLookup[];
    managers:{
        id: string;
        firstName: string;
        lastName: string
    }[];
    profilePictureUri: string
    timezone: returnedLookup;
    culture: returnedLookup;
    organization: {
        id: string
        name: string
        parent: string
    }
    streetAddress2: string;
    municipality: string;
    region1: string;
    region2: string;
    region3: string;
    neighbourhood: string;
    email2: string;
    internetAddress: string;
    socialSecurityNumber: string;
    identityNumber: string;
    regionOfBirth: string;
    townOfBirth: string;
    poBox: string;
    registeredDisabled: returnedLookup;
    disability: returnedLookup;
    veteranStatus: returnedLookup;
    equalEmploymentOpportunity: returnedLookup;
    nationality: returnedLookup;
    countryOfBirth: returnedLookup;
    preferredPronoun: string;
    sexualOrientation: returnedLookup;
}

export class TalentSubmit {
    userId: string;
    id: string;
    asOf: string;
    changeReason: string;
    changeReasonComments: string;
    firstname: string;
    lastname: string;
    middleName: string;
    maidenName: string;
    preferredName: string;
    title: string;
    gender: string;
    maritalStatus: string;
    culturalAffiliation: string;
    religiousAffiliation: string;
    birthday: string;
    customClientId: string;
    linkedInUrl: string;
    twitterHandle: string;
    facebookUrl: string;
    streetAddress: string;
    city: string;
    province: string;
    zip: string;
    country: string;
    email: string;
    workPhone: string;
    homePhone: string;
    cellPhone: string;
    preferredLanguage: string;
    username: string;
    timezone: string;
    culture: string;
}

export class EmployeeTableSecurity {
    table: {
        id: string
        name: string
        parentTable: {
            id: string
            name: string
        }
    }
    fields : EmployeeTableFieldSecurity[]
    create: boolean
    read: boolean
    update: boolean
    delete: boolean
    viewFutureDated: boolean
    viewHistory: boolean
}


export class EmployeeTableFieldSecurity {
    field: {
        id: string
        name: string
    }
    read: boolean
    update: boolean
    deny: boolean
}

export class CurrentUserDetails {
    username: string
    firstname: string
    lastname: string
    preferredName: string
    userType: string
    timezone: {
      id: string
      text: string
    }
    culture: {
      id: string
      text: string
    }
}