<app-site-settings-menu>
    <app-work-locations-list *ngIf="hasFeatureAccess"></app-work-locations-list>
    <no-feature-access *ngIf="!hasFeatureAccess"></no-feature-access>
    <!-- <mat-card *ngIf="hasFeatureAccess">
        <mat-card-title class="header">
            <h5>{{ 'WorkLocations' | translate: {Default: "Work Locations"} }}</h5>
            <div>
                <button mat-flat-button color="primary" (click)="emitEventToChild()">
                    <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> Add work location
                </button>
            </div>
        </mat-card-title>
        <mat-card-content>
        </mat-card-content>
    </mat-card> -->

</app-site-settings-menu>
