import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  // Choose the locale from this link:
  //https://github.com/angular/angular/tree/master/packages/common/locales

  constructor() { }

  private _locale: string;

  set locale(value: string) {
    this._locale = value;
  }
  get locale(): string {
    return this._locale || 'en-GB';
  }

  public registerCulture(culture: string) {
    if (!culture) {
      return;
    }
    switch (culture) {
      case 'en-CA': {
        this._locale = 'en';
        console.log('Application Culture Set to English Canada');
        break;
      }
      case 'en-GB': {
        this._locale = 'en';
        console.log('Application Culture Set to English UK');
        break;
      }
      case 'en-US': {
        this._locale = 'en';
        console.log('Application Culture Set to English US');
        break;
      }
      case 'es-MX': {
        this._locale = 'es-MX';
        console.log('Application Culture Set to Spanish Mexico');
        break;
      }
      case 'fr-CA': {
        this._locale = 'fr-CA';
        console.log('Application Culture Set to French Canada');
        break;
      }
      case 'fr-FR': {
        this._locale = 'fr';
        console.log('Application Culture Set to French');
        break;
      }
      default: {
        this._locale = 'en';
        console.log('Application Culture Set to English');
        break;
      }
    }
  }
}
