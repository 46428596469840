import {Component, Inject} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DOCUMENT} from '@angular/common';
import {EnvironmentService} from "@app/core/services/environment.service";
import { OverlayContainer } from '@angular/cdk/overlay';
import OrgChart from "../assets/balkanapp/orgchart";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'Aurora';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translate: TranslateService,
    private overlay: OverlayContainer,
    private environmentService: EnvironmentService
  ) {
    // translate.addLangs(['en', 'de']);
    // translate.setDefaultLang('en');

    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|de/) ? browserLang : 'en');

  }

  ngOnInit() {
    if (this.environmentService.env.customCss) {
      // this.loadStyle("client-sight-savers.css");
      this.loadStyle(this.environmentService.env.customCss);
      this.loadTheme(this.environmentService.env.customTheme);
    }
  }

  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

  loadTheme(themeName: string) {
    const head = this.document.getElementsByTagName('html')[0];

    head.classList.add(themeName);
    this.overlay.getContainerElement().classList.add(themeName);
  }
}
