<app-layout>
    <mat-toolbar class="page-header" role="heading">
      <h1>Global Insights</h1>
    </mat-toolbar>
  
    <div class="content" fxLayout="row" fxLayoutAlign="start start">
      <div>
        <app-headcount-by-country></app-headcount-by-country>
        <app-headcount-by-gender></app-headcount-by-gender>
      </div>
      <div fxLayout="column" fxLayoutAlign="start stretch">
        <div fxLayout="row" fxLayoutAlign="start start">
          <app-talent-and-cem></app-talent-and-cem>
          <app-kpi-overview></app-kpi-overview>
        </div>
        <div>
          <app-new-hires></app-new-hires>
        </div>
      </div>
    </div>

</app-layout>