<div class="main-container">
    <div class="top-container">
        <h5>New Hire Packet</h5>
        <div class="buttons">
            <div routerLink="{{routes.PACKET}}/1">
                <mat-icon class="material-icons-outlined">visibility</mat-icon> 
                <span>View Packet</span>
            </div>
            <div>
                <mat-icon class="material-icons-outlined">send</mat-icon> 
                <span>Resend</span>
            </div>
            <div>
                <mat-icon class="material-icons-outlined">cancel</mat-icon> 
                <span>Cancel</span>
            </div>
        </div>
    </div>
    <div class="bottom-container">
        <ul>
            <li>
                <mat-icon class="material-icons-outlined">done</mat-icon>
                <span class="label">
                    <span class="task">New Hire Packet Sent</span>
                    <span class="date">Feb 4, 2020</span>
                </span>
            </li>
            <li>
                <mat-icon class="material-icons-outlined">done</mat-icon>
                <span class="label">
                    <span class="task">New Hire Packet Viewed</span>
                    <span class="date">Feb 4, 2020</span>
                </span>
            </li>
            <li>
                <mat-icon class="material-icons-outlined">done</mat-icon>
                <span class="label">
                    <span class="task">New Employee Info Completed</span>
                    <span class="date">Feb 4, 2020</span>
                </span>
            </li>
            <li class="active">
                <mat-icon class="material-icons-outlined">done</mat-icon>
                <span class="label">
                    <span class="task">Get To Know You Questions Completed</span>
                    <span class="date">Feb 4, 2020</span>
                </span>
            </li>
            <li>
                <span class="label">
                    <span class="task">New Employee Info Completed</span>
                </span>
            </li>
        </ul>
    </div>
</div>