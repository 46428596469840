import { Component, OnInit } from '@angular/core';
import { SearchService } from '@app/core/services/search/search.service';
import { Search } from '@app/shared/models/search/search.model';
import { routes } from '@app/consts';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-site-search-bar',
    templateUrl: './site-search-bar.component.html',
    styleUrls: ['./site-search-bar.component.scss']
})
export class SiteSearchBarComponent implements OnInit {
    searchValue: string;
    searchRequest$: any;
    results: Search;
    skip: number = 0;
    take: string = '5';
    public isLoading;
    public routes: typeof routes = routes;
    loadingMore: boolean = false;

    constructor(
        public translate: TranslateService,
        private searchService: SearchService,
        private router: Router
    ) {
        // force route reload whenever params change;
    }

    ngOnInit(): void {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    searchRequest() {
        this.loadingMore === false ? this.isLoading = true : null;

        this.searchRequest$ = this.searchService.search(this.searchValue, this.skip, this.take)
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                    this.loadingMore = false;
                })
            )
            .subscribe(
                res => {
                    this.results = res;
                }
            );
    }

    loadMore() {
        this.take = (parseInt(this.take) + 5).toString();
        this.loadingMore = true;
        this.search();
    }

    search() {
        if (this.searchRequest$) {
            this.searchRequest$.unsubscribe();
        }
        this.searchRequest();
    }

}
