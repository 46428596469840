import { Localization } from "@app/shared/models/localization.model";
import { Version } from "@app/shared/models/version.model";

export class BenefitsGroup {
    id: string;
    name: string;
    deductionFrequency: {
        id: string;
        name: string;
    }
}

export class BenefitsGroupVerbose {
    id: string;
    name: Localization[];
    deductionFrequency: {
        id: string;
        name: string;
    };
    version: Version;
}

export class BenefitsGroupSubmit {
    id: string;
    asOf: string;
    changeReason: string;
    changeReasonComments: string;
    name: Localization[];
    deductionFrequency: string;
}

export class DeductionFrequencies {
    id: string;
    name: string;
}