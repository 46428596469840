<div class="main-container">
    <div class="left">
        <div class="top">
            <h5>{{tableListField.name}}</h5>
            <div class="buttons-container">
                <button mat-flat-button setColor="primaryColour" color="primary" (click)="showAddMultiple = true; showAddSingle = false">
                    <mat-icon class="material-icons-outlined">add</mat-icon> Add Multiple
                </button>
                <button mat-flat-button (click)="showAddSingle = true; showAddMultiple = false">
                    <mat-icon class="material-icons-outlined">add</mat-icon> Add
                </button>
            </div>
        </div>
        <div class="bottom">

            <ng-template *ngIf="isLoading; else loaded">
                <ngx-skeleton-loader count="10" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
            </ng-template>

            <ng-template #loaded>
                <div *ngIf="tableListFieldOptions.listOptions.length > 0 || tableListFieldOptions.prioritized.length > 0; else noContent" class="lookup-values-container">
                    <div class="select-all-container">
                        <div>Select All</div>
                        <mat-checkbox setColor="primaryColour" class="example-margin" (change)="toggleSelectAll($event)" [checked]="selectAll"></mat-checkbox>
                    </div>

                    <mat-selection-list #selectionList disableRipple="true" [(ngModel)]="selectedTableListFieldOptions">
                        <!-- Prioritized Items -->
                        <div *ngIf="tableListFieldOptions.prioritized.length > 0">
                            <div mat-subheader>Prioritized</div>
                            <mat-list-option setColor="primaryColour" *ngFor="let option of tableListFieldOptions.prioritized" [value]="option">
                                <div class="list-item-content">
                                    <div class="text">
                                        {{option.text}} <span class="dates">{{option.startDate | date:medium}} - {{option.endDate | date:medium}}</span>
                                    </div>
                                    <div class="lookup-buttons">
                                        <mat-icon 
                                            setColor
                                            [setColorRules]="[
                                                {
                                                    color: 'primaryColour',
                                                    events: ['hover']
                                                }
                                            ]"
                                            class="material-icons-outlined" 
                                            matTooltip="Copy to another group" 
                                            appPreventDoubleClick 
                                            (throttledClick)="openCopyDialog($event, option.id)"
                                        >
                                            content_copy
                                        </mat-icon>
                                        <mat-icon 
                                            setColor
                                            [setColorRules]="[
                                                {
                                                    color: 'primaryColour',
                                                    events: ['hover']
                                                }
                                            ]"
                                            class="material-icons-outlined" 
                                            matTooltip="Edit" 
                                            appPreventDoubleClick 
                                            (throttledClick)="openEditLookupDialog($event, option.id)"
                                        >
                                            edit
                                        </mat-icon>
                                        <mat-icon 
                                            setColor
                                            [setColorRules]="[
                                                {
                                                    color: 'primaryColour',
                                                    events: ['hover']
                                                }
                                            ]"
                                            class="material-icons-outlined" 
                                            matTooltip="Delete" 
                                            appPreventDoubleClick 
                                            (throttledClick)="openConfirmDeleteDialog($event, option)"
                                        >
                                            delete
                                        </mat-icon>
                                    </div>
                                </div>
                            </mat-list-option>

                            <mat-divider></mat-divider>
                        </div>

                        <!-- Non prioritized items -->
                        <mat-list-option setColor="primaryColour" *ngFor="let option of tableListFieldOptions.listOptions" [value]="option">
                            <div class="list-item-content">
                                <div class="text">
                                    {{option.text}} <span class="dates">{{option.startDate | date:medium}} - {{option.endDate | date:medium}}</span>
                                </div>
                                <div class="lookup-buttons">
                                    <mat-icon 
                                        setColor
                                        [setColorRules]="[
                                            {
                                                color: 'primaryColour',
                                                events: ['hover']
                                            }
                                        ]"
                                        class="material-icons-outlined" 
                                        matTooltip="Copy to another group" 
                                        appPreventDoubleClick 
                                        (throttledClick)="openCopyDialog($event, option.id)"
                                    >
                                        content_copy
                                    </mat-icon>
                                    <mat-icon 
                                        setColor
                                        [setColorRules]="[
                                            {
                                                color: 'primaryColour',
                                                events: ['hover']
                                            }
                                        ]"
                                        class="material-icons-outlined" 
                                        matTooltip="Edit" 
                                        appPreventDoubleClick 
                                        (throttledClick)="openEditLookupDialog($event, option.id)"
                                    >
                                        edit
                                    </mat-icon>
                                    <mat-icon 
                                        setColor
                                        [setColorRules]="[
                                            {
                                                color: 'primaryColour',
                                                events: ['hover']
                                            }
                                        ]"
                                        class="material-icons-outlined" 
                                        matTooltip="Delete" 
                                        appPreventDoubleClick 
                                        (throttledClick)="openConfirmDeleteDialog($event, option)"
                                    >
                                        delete
                                    </mat-icon>
                                </div>
                            </div>
                        </mat-list-option>
                    </mat-selection-list>

                    <div class="bottom-controls" *ngIf="selectedTableListFieldOptions.length > 0">
                        <button mat-flat-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openConfirmDeleteAllDialog()">
                            <mat-icon class="material-icons-outlined">delete</mat-icon> Delete all selected
                        </button>
                        <button mat-flat-button appPreventDoubleClick (throttledClick)="openCopyDialog($event)">
                            <mat-icon class="material-icons-outlined">content_copy</mat-icon> Copy all selected
                        </button>
                    </div>
                </div>

                <ng-template #noContent>
                    <div class="lookup-values-container">
                        <div class="no-content-container">
                            <h3>No list option values yet</h3>
                            <div class="text">Add some values</div>
                            <div class="buttons-container">
                                <button mat-flat-button setColor="primaryColour" color="primary" (click)="showAddMultiple = true; showAddSingle = false">
                                    <mat-icon class="material-icons-outlined">add</mat-icon> Add Multiple
                                </button>
                                <button mat-flat-button (click)="showAddSingle = true; showAddMultiple = false">
                                    <mat-icon class="material-icons-outlined">add</mat-icon> Add
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-template>
        </div>
    </div>
    <div class="right" *ngIf="showAddMultiple || showAddSingle">
        <app-add-multiple-values
            *ngIf="showAddMultiple"
            [listGroup]="tableListField.ListGroup"
            (saveComplete)="saveComplete($event)"
            (close)="closeAddValue()"
        >
        </app-add-multiple-values>

        <app-add-single-value
            *ngIf="showAddSingle"
            [listGroup]="tableListField.ListGroup"
            (saveComplete)="saveComplete($event)"
            (close)="closeAddValue()"
        ></app-add-single-value>
    </div>
</div>

