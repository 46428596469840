import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Letter } from '@app/modules/letter-generator/models/leter.model';

@Component({
  selector: 'app-add-letter-details',
  templateUrl: './add-letter-details.component.html',
  styleUrls: ['./add-letter-details.component.scss']
})
export class AddLetterDetailsComponent implements OnInit {
  
  @Output() letterVariables = new EventEmitter<any>();
  @Input() letter: Letter;
  variables: string[] = [];
  formControls: string[] = [];
  form: UntypedFormGroup;


  constructor() { }

  ngOnInit(): void {
    this.form.valueChanges.subscribe(x => {
      this.letterVariables.emit(this.form.value);
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.letter){
      this.getVariablesFromHTML();
    }
  }

  getVariablesFromHTML(){
    let regex = /(?=\{)(.*?)(?=\})/g;

    if(this.letter){
      this.variables = this.letter.letterHTML.match(regex);
      this.variables = this.variables.map(x => x.substring(1));
      this.variables = [...new Set(this.variables)];

      // this.formControls = this.variables.map(x => x.split(" ").join(""));
      this.createForm();
    }
  }

  createForm() {
    const group: any = {};

    this.variables.forEach(
      variable => {
        group[variable] = new UntypedFormControl('', Validators.required);
      }
    )

    this.form = new UntypedFormGroup(group);

    console.log(this.form.value);
  }

  getFormValues(){
    console.log(this.form.value);
  }
}
