<app-performance>
    <ng-container *ngIf="isLoading === true; else loaded">
        <ngx-skeleton-loader
            count="5"
            [theme]="{ 
                'height.px': 50
            }"
        ></ngx-skeleton-loader>
    </ng-container>
    <ng-template #loaded>
        <mat-card class="mat-elevation-z0">
            <mat-card-title class="header">
                <h5 mat-dialog-title>{{ 'GlobalReview' | translate: {Default: 'Global Review'} }}</h5>
            </mat-card-title>

            <mat-card-content>
                <div class="left">
                    <mat-card class="review-plan">
                        <mat-card-title>
                            <h6 appLocalizationPopupDirective localizationCode="Review-Plan" >{{ 'Review-Plan' | translate: {Default: 'Review Plan'} }}</h6>
                        </mat-card-title>
        
                        <mat-card-content>
                            <div>
                                <div class="label">{{getLabel('tfi_RpName')}}</div>
                                <div class="value">{{reviewPlan?.name}}</div>
                            </div>
                            <div>
                                <div class="label">{{getLabel('tfi_RpDescription')}}</div>
                                <div class="value">{{reviewPlan?.description}}</div>
                            </div>
                            <div>
                                <div class="label">{{getLabel('tfi_RpStartDate')}}</div>
                                <div class="value">{{reviewPlan?.startDate | date:'mediumDate'}}</div>
                            </div>
                            <div>
                                <div class="label">{{getLabel('tfi_RpEndDate')}}</div>
                                <div class="value">{{reviewPlan?.endDate | date:'mediumDate'}}</div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>

                <mat-card class="right mat-elevation-z0">
                    <mat-card-content>
                        <div class="goal-plans-list">
                            <mat-list role="list">
                                <h6 mat-subheader>{{ 'Review-GoalPlans' | translate: {Default: 'Goal Plans'} }}</h6>
    
                                <mat-list-item *ngIf="goalPlans === null || goalPlans.length === 0">
                                    <span appLocalizationPopupDirective localizationCode="Review-NoGoalPlans">
                                        {{ 'Review-NoGoalPlans' | translate: {Default: 'No Goal Plans'} }}
                                    </span>
                                </mat-list-item>
                                
                                <ng-container *ngFor="let goalPlan of goalPlans; let last = last">
        
                                    <mat-list-item
                                        role="listitem"
                                        [ngClass]="{
                                            'selected-goal-plan': selectedGoalPlan?.id == goalPlan?.id
                                        }"
                                        (click)="selectedGoalPlan = goalPlan"
                                    >
                                        <div class="top">
                                            <div matListItemTitle>
                                                {{goalPlan?.name}}
                                            </div>
                                        </div>
                                    </mat-list-item>
                    
                                    <mat-divider *ngIf="!last"></mat-divider>
                                </ng-container>
                            </mat-list>
                        </div>
                        
                        <div class="reviews-section" *ngIf="selectedGoalPlan">
                            <mat-tab-group class="mat-tab-group" *ngIf="selectedGoalPlan">
    
                                <mat-tab>
                                    <ng-template mat-tab-label>
                                      <div class="mat-tab-header" appLocalizationPopupDirective localizationCode="GlobalReview-GoalPlanReview">
                                        {{ 'GlobalReview-GoalPlanReview' | translate: {Default: 'Goal Plan Review'} }}
                                      </div>
                                    </ng-template>
    
                                    <div *ngFor="let review of selectedGoalPlan?.reviews; let last = last; let index = index; trackBy:trackByIndex;">
                                        <app-global-goal-plan-reviews [(globalGoalPlanReview)]="selectedGoalPlan?.reviews[index]" [reviewPlanOwners]="reviewPlanOwners" (refreshPage)="refreshPage($event)"></app-global-goal-plan-reviews>
                                        <mat-divider *ngIf="!last"></mat-divider>
                                    </div>
                                </mat-tab>
    
                                <mat-tab>
                                    <ng-template mat-tab-label>
                                      <div class="mat-tab-header" appLocalizationPopupDirective localizationCode="GlobalReview-GoalReview">
                                        {{ 'GlobalReview-GoalReview' | translate: {Default: 'Goal Review'} }}
                                      </div>
                                    </ng-template>
    
                                    <div *ngFor="let goalReview of selectedGoalPlan?.goals; let last = last; let index = index; trackBy:trackByIndex;">
                                        <app-global-goal-reviews [(globalGoalReview)]="selectedGoalPlan?.goals[index]" [reviewPlanOwners]="reviewPlanOwners" (refreshPage)="refreshPage($event)"></app-global-goal-reviews>
                                        <mat-divider *ngIf="!last"></mat-divider>
                                    </div>
                                </mat-tab>
                            </mat-tab-group>
                        </div>
                    </mat-card-content>
                </mat-card>
            </mat-card-content>
        </mat-card>
    </ng-template>
</app-performance>