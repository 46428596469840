import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectionList } from '@angular/material/list';
import { ActivatedRoute } from '@angular/router';
import { routes } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { TableListFieldOption } from '@app/modules/lookup/models/lookup.model';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import * as moment from 'moment';
import { Observable, from } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BenefitsPlanType } from '../../../benefits-plan-types/models/benefits-plan-type.model';
import { BenefitsPlanTypesService } from '../../../benefits-plan-types/services/benefits-plan-types.service';
import { BenefitsPlanVerbose, BenefitsRateType } from '../../models/benefits-plan.model';
import { BenefitsPlansService } from '../../services/benefits-plans.service';

@Component({
  selector: 'app-benefits-plan-editor',
  templateUrl: './benefits-plan-editor.component.html',
  styleUrls: ['./benefits-plan-editor.component.scss']
})
export class BenefitsPlanEditorComponent implements OnInit {
  @ViewChild('selectionList', {static: false}) private selectionList: MatSelectionList; 
  public routes: typeof routes = routes;
  plan: BenefitsPlanVerbose;
  form: UntypedFormGroup;
  cultures: Culture[];
  loading: boolean;
  planId: string;
  planTypeOptions: Observable<BenefitsPlanType[]>;
  rateTypeOptions: Observable<BenefitsRateType[]>;
  planProviderOptions: Observable<PagedData<TableListFieldOption>>;

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private cultureService: CultureService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private benefitsPlansService: BenefitsPlansService,
    private lookupService: LookupService,
    private benefitsPlanTypesService: BenefitsPlanTypesService,
  ) { }

  ngOnInit(): void {
    this.cultureService.getCultures().subscribe( res => { this.cultures = res } );
    this.planTypeOptions = this.benefitsPlanTypesService.getPlanTypes();
    this.rateTypeOptions = this.benefitsPlansService.getRateTypes();
    this.planProviderOptions = from(this.lookupService.getListOptions("BENEFIT_PLAN_PROVIDER"));
    this.planId = this.getIdInURL();

    if(this.planId !== null){
      this.getPlan();
    }
    else {
      this.loading = false; 
      this.createForm();
    }
  }

  getIdInURL(): string {
    let IdInURL: string;

    this.route.paramMap.subscribe(
        params => IdInURL = params.get('planId')
    );

    return IdInURL;
  }

  getPlan() {
    this.loading = true;

    this.benefitsPlansService.getPlan(this.planId)
    .pipe(
      finalize(() => this.loading = false)
    )
    .subscribe(
      res => {
        this.plan = res;
        this.createForm();
      }
    )
  }

  createForm() {
    this.form = this.fb.group({
      id: [this.plan ? this.plan.id : null],
      asOf: [moment().format()],
      name: this.fb.array([]),
      startDate: [this.plan ? this.plan.startDate : null],
      endDate: [this.plan ? this.plan.endDate : null],
      planType: [this.plan  ? this.plan.planType?.id : null],
      rateType: [this.plan ? this.plan.rateType?.id : null],
      planProvider: [this.plan ? this.plan.planProvider?.id : null],
      description: this.fb.array([]),
      contractNumber: [this.plan ? this.plan.contractNumber : null],
      changeReason: [''],
      changeReasonComments: [''],
    });

    if(this.planId){
      this.plan.name.forEach(
        localization => {
          this.addNewLocalization(localization.culture, localization.text);
        }
      );

      this.plan.description.forEach(
        localization => {
          this.addNewDescriptionLocalization(localization.culture, localization.text);
        }
      );
    }
    else {
      this.addNewLocalization();
      this.addNewDescriptionLocalization();
    }
  }

  get localizations() {
    return this.form.controls["name"] as UntypedFormArray;
  }

  get descriptionsLocalizations() {
    return this.form.controls["description"] as UntypedFormArray;
  }

  //Create a textLocalization form group object to add to the localizations form array
  addNewLocalization(culture?: string, text?: string) {
    const localizationForm = this.fb.group({
      culture: [culture || '', Validators.required],
      text: [text || '', Validators.required]
    });

    this.localizations.push(localizationForm);
  }

  //Create a textLocalization form group object to add to the localizations form array
  addNewDescriptionLocalization(culture?: string, text?: string) {
    const localizationForm = this.fb.group({
      culture: [culture || '', Validators.required],
      text: [text || '', Validators.required]
    });

    this.descriptionsLocalizations.push(localizationForm);
  }

  deleteLocalization(index: number) {
    this.localizations.removeAt(index);
  }

  deleteDescriptionLocalization(index: number) {
    this.descriptionsLocalizations.removeAt(index);
  }

  save() {
    this.overlayService.show();

    if(this.planId){
      this.benefitsPlansService.updatePlan(this.form.value)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar('Plan updated successfully', 'clear', 'success');
        }
      );
    }
    else {
      this.benefitsPlansService.createPlan(this.form.value)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar('Plan created successfully', 'clear', 'success');
        }
      );
    }
  }

  close() {
    // this.dialogRef.close();
  }

  

}
