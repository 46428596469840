<mat-card class="mat-elevation-z0">
  <mat-card-title class="header">
      <h5 appLocalizationPopupDirective localizationCode="CompanyLogo">{{ 'CompanyLogo' | translate: {Default: "Company Logo"} }}</h5>
    </mat-card-title>
    
    <mat-card-content>
      <ng-container *ngIf="isLoading; else loaded">
        <ngx-skeleton-loader
          count="5"
          [theme]="{ 
            'height.px': 50
          }"
          ></ngx-skeleton-loader>
      </ng-container>

      <ng-template #loaded>
        <div class="form-section">
            <div class="form-section-title">
              <h6 appLocalizationPopupDirective localizationCode="CurrentLogo">{{ 'CurrentLogo' | translate: {Default: "Current Logo"} }}</h6>
            </div>
            <div class="form-section-contents">
                <div class="section-row">
                  <div *ngIf="logoImage$ | withLoading | async as logoImage">
                    <ng-template [ngIf]="logoImage.value">
                        <img class="logo-image" [src]="logoImage.value">
                    </ng-template>
                    <ng-template [ngIf]="logoImage.error">
                        <div>Error</div>
                      </ng-template>
                      <ng-template [ngIf]="logoImage.loading">
                        <ngx-skeleton-loader
                        class="profiler"
                        count="1" 
                        [theme]="{ 
                                'height.px': 200,
                                'width.px': 200
                            }"
                        >
                      </ngx-skeleton-loader>
                    </ng-template>
                  </div>
                </div>
            </div>
        </div>
        
        <app-form-generator 
          [formId]="formId"
          [formData]="formData"
          [emitFormDataTrigger]="getFormData"
          (emitFormData)="formDataEmitted($event)"
          (emitFormStatus)="formStatusUpdated($event)"
        ></app-form-generator>
        
        <button mat-raised-button setColor="primaryColour" color="primary" [disabled]="formValid === false" appPreventDoubleClick (throttledClick)="getFormData = !getFormData">{{ 'Save' | translate: {Default: "Save"} }}</button> 
      
      </ng-template>
  </mat-card-content>
</mat-card>