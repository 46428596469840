import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { features, routes } from '@app/consts';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SecurityRoleService } from '@app/modules/security-roles/services/security-role.service';
import { RoleType, SecurityFeature, SecurityRoleSubmit, SecurityRoleVerbose } from '@app/modules/security-setup/models/security-role.model';
import { MatSelectionList } from '@angular/material/list';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { Observable, of } from 'rxjs';
import { Culture } from '@app/shared/models/system-language/culture.model';
import { TranslateService } from '@ngx-translate/core';
import { CheckableSettings } from '@progress/kendo-angular-treeview';
import { CopyRoleDialogComponent } from '../copy-role-dialog/copy-role-dialog.component';
import { modules } from '@app/consts/modules';
import { ModuleConfigurationService } from '@app/core/services/module-configuration/module-configuration.service';

@Component({
    selector: 'app-security-role-editor',
    templateUrl: './security-role-editor.component.html',
    styleUrls: ['./security-role-editor.component.scss']
})
export class SecurityRoleEditorComponent implements OnInit {
    public modules: typeof modules = modules;

    @ViewChild('selectionList', {static: false}) private selectionList: MatSelectionList;
    public isLoading: boolean;
    public isLoadingUsers: boolean = false;
    public isLoadingOrgs: boolean = false;

    public form: UntypedFormGroup;
    public features: SecurityFeature[] = [];
    public securityRoleId: string;
    public securityRole: SecurityRoleVerbose;
    public securityRoleCopy: SecurityRoleVerbose;
    public cultures: Culture[];

    public selectAll: boolean = false;
    public expandedKeys: any[] = ['4', '5']; // e.g. ['0', '1'];
    public checkedKeys: any[] = []; // e.g. ['0_1'];

    public gridDataResult: GridDataResult;
    public selectedItems: any[] = [];
    public pageSize: string = '20';
    public skip: number = 0;

    roleTypes: Observable<RoleType[]>;

    public get checkableSettings(): CheckableSettings {
        return {
          checkChildren: false,
          checkDisabledChildren: false,
          checkParents: true,
          enabled: true,
          mode: 'multiple',
          checkOnClick: true,
        };
      }

    public data: any[] = [
        {
            name: 'Homepage',
            id: features.HOMEPAGE,
        },
        {
            name: 'Dashboards',
            id: features.DASHBOARDS,
        },
        // {
        //     name: 'News Feed',
        //     id: features.NEWS_FEED,
        // },
        {
            name: 'Talent',
            id: features.TALENT,
        },
        {
            name: 'Reports',
            id: features.REPORTS,
            items: [
                {
                    name: 'Bank Features Report',
                    id: features.BANK_FEATURES_REPORT,
                },
                {
                    name: 'Organization Chart',
                    id: features.ORG_REPORT,
                },
                {
                    name: 'Position Chart',
                    id: features.POSITIONS_REPORT,
                },
                {
                    name: this.translate.instant('BasicEmployeeReport'),
                    id: features.BASIC_EMPLOYEE_DETAILS_REPORT
                },
                {
                    name: this.translate.instant('BasicHeadcountReport'),
                    id: features.HEAD_COUNT_REPORT
                },

            ]
        },
        {
            name: 'Site Settings',
            id: '',
            items: [
                {name: this.translate.instant('OrganizationAdministration'),id: features.ORGANIZATION,},
                {name: 'Position Administration', id: features.POSITIONS},
                {name: 'Cost Centers', id: features.COST_CENTERS},
                {name: 'Lists', id: features.LISTS},
                {name: 'Work Location Administration', id: features.WORK_LOCATIONS},
                {name: this.translate.instant('WorkRotationAdministration'), id: features.WORK_ROTATIONS},
                {name: `Timeoff Administration & ${this.translate.instant('StatutoryHolidays')}`, id: features.TIME_OFF, module: modules.TIME_OFF,},
                {name: `Time Off In Lieu Administration`, id: features.TOIL_ADMINISTRATION, module: modules.TOIL,},
                {name: 'Forms', id: features.FORMS},
                {name: 'Site Configuration', id: features.SITE_SETTINGS},
                {name: 'Currency Administration', id: features.CURRENCY},
                {name: 'Globalization Administration', id: features.GLOBALIZATION},
                {name: this.translate.instant('SchedulesAdministration'), id: features.SCHEDULES},
                {name: this.translate.instant('CountryConfig'), id: features.COUNTRY_CONFIG},
                {name: this.translate.instant('BusinessRuleAutomation'), id: features.BUSINESS_RULE_AUTOMATION, module: modules.BUSINESS_RULE_AUTOMATION},
                {name: this.translate.instant('Styling'), id: features.SITE_STYLING},
            ],
        },
        {
            name: 'Security Administration',
            id: features.SECURITY,
        },
        {
            id: features.PERFORMANCE,
            module: modules.PERFORMANCE,
            name: this.translate.instant('PerformanceFeature'),
            items: [
                {name: this.translate.instant('PerformanceGoalPlans'), id: features.PERFORMANCE_GOAL_PLANS,},
                {name: this.translate.instant('PerformanceManageGoalPlans'), id: features.PERFORMANCE_MANAGE_GOAL_PLANS,},
                {name: this.translate.instant('PerformanceGoalTypes'), id: features.PERFORMANCE_GOAL_TYPES},
                {name: this.translate.instant('PerformanceMyGoals'), id: features.PERFORMANCE_MY_GOALS},
                {name: this.translate.instant('PerformanceDirectReporteeGoals'), id: features.PERFORMANCE_DIRECT_REPORTEE_GOALS},
                {name: this.translate.instant('PerformanceManagerReviewPlans'), id: features.PERFORMANCE_MANAGER_REVIEW_PLANS},
                {name: this.translate.instant('PerformanceReviewPlansAdministration'), id: features.PERFORMANCE_REVIEW_PLANS_ADMINISTRATION},
            ]
        },
        {
            id: features.TOIL,
            module: modules.TOIL,
            name: this.translate.instant('TimeOffInLieu'),
        },
        // {
        //     name: this.translate.instant('Integrations'),
        //     id: features.INTEGRATIONS,
        // },
        // {
        //     name: 'Benefit Administration',
        //     id: features.BENEFITS,
        // },
    ];

    public children = (dataItem: any): Observable<any[]> => of(dataItem.items);
    public hasChildren = (dataItem: any): boolean => !!dataItem.items;


    constructor(
        private dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        private snackbarService: SnackbarService,
        private fb: UntypedFormBuilder,
        private overlayService: OverlayService,
        private cultureService: CultureService,
        private securityRoleService: SecurityRoleService,
        private translate: TranslateService,
        private moduleConfigurationService: ModuleConfigurationService
    ) {
    }

    ngOnInit(): void {
        this.cultureService.getCultures().subscribe( res => { this.cultures = res } );
        this.roleTypes = this.securityRoleService.getRoleTypes();
        this.securityRoleId = this.getIdInURL();
        this.getSecurityFeatures();

        this.securityRoleId !== null
            ? this.getSecurityRole()
            : this.isLoading = false;
        this.createForm();

        this.removeFeaturesWithoutModuleAccess();
    }

    getIdInURL(): string {
        let IdInURL: string;

        this.route.paramMap.subscribe(
            params => IdInURL = params.get('roleId')
        );

        return IdInURL;
    }

    get localizations() {
        return this.form.controls["description"] as UntypedFormArray;
    }

    deleteLocalization(index: number) {
        this.localizations.removeAt(index);
    }

    // If the client doesnt have access to a module remove it form the list
    removeFeaturesWithoutModuleAccess() {
        this.data = this.data.filter(
            feature => {
                if(feature.items) {
                    feature.items = feature.items.filter(
                        item => {
                            if(item.module) {
                                return this.moduleConfigurationService.hasModuleAccess(item.module);
                            }
                            else {
                                return true;
                            }
                        }
                    )
                }

                if(feature.module) {
                    return this.moduleConfigurationService.hasModuleAccess(feature.module);
                }
                else {
                    return true;
                }
            }
        )
    }

    getSecurityRole() {
        this.isLoading = true;

        this.securityRoleService.getSecurityRole(this.securityRoleId)
            .subscribe(
                res => {
                    this.securityRole = res;
                    this.isLoading = false;
                    this.createForm();
                }
            );
    }

    getSecurityFeatures() {
        this.securityRoleService.getFeatures()
            .subscribe(
                res => {
                    this.features = res;
                }
            );
    }

    createForm() {
        this.form = this.fb.group({
            id: [this.securityRole ? this.securityRole.id : null],
            name: [this.securityRole ? this.securityRole.name : '', Validators.required],
            roleType: [this.securityRole ? this.securityRole?.roleType?.id : null, Validators.required],
            description: this.fb.array([]),
        });

        this.checkedKeys = this.getCheckedKeysFromFeatures(this.securityRole ? this.securityRole.features : []);

        if(this.securityRole){
            this.securityRole.description?.forEach(
                localization => {
                    this.addNewLocalization(localization.culture, localization.text);
                }
            );
        }
        else {
            this.addNewLocalization();
        }
    }

    addNewLocalization(culture?: string, text?: string) {
        const localizationForm = this.fb.group({
            culture: [culture || '', Validators.required],
            text: [text || '', Validators.required]
        });

        this.localizations.push(localizationForm);
    }


    save() {
        this.overlayService.show();

        let securityRoleSubmit: SecurityRoleSubmit = {
            name: this.form.value.name,
            features: this.checkedKeys.filter( key => key !== '' ),
            roleType: this.form.value.roleType,
            description: this.form.value.description
        };
        if (this.securityRole) {
            this.updateSecurityRole(this.securityRole.id, securityRoleSubmit);
        } else {
            this.createSecurityRole(securityRoleSubmit);
        }
    }


    getSelectedFeaturesFromTreeview(keys) {
        let features = [];
        keys.forEach(key => {
            if (isNaN(key) && key.includes('_')) {
                let arr = key.split('_');
                let feature = this.data[arr[0]].items[arr[1]];
                features.push(feature.featureCode);
            } else {
                let treeViewNode = this.data[key];
                if (treeViewNode.items && treeViewNode.items.length > 0) {
                    treeViewNode.items.forEach(item => {
                        features.push(item.featureCode);
                    });
                } else {
                    features.push(treeViewNode.featureCode);
                }
            }
        });
        return features;
    }

    getCheckedKeysFromFeatures(features) {
        // let keys = [];

        // keys = features.map( elem => elem.id)

        // features.forEach(feature => {
        //     this.data.forEach((elem, index) => {
        //         if (feature.id === elem.featureCode) {
        //             keys.push(`${index}`);
        //         } else if (elem.items && elem.items.length > 0) {
        //             elem.items.forEach((e, i) => {
        //                 if (feature.id == e.featureCode) {
        //                     keys.push(`${index}_${i}`);
        //                 }
        //             });
        //         }
        //     });
        // });
        return features.map(elem => elem.id);
    }

    createSecurityRole(securityRoleSubmit: SecurityRoleSubmit) {
        this.securityRoleService.postSecurityRole(securityRoleSubmit)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe(
                (res) => {
                    this.navigateToSecurityRoles();
                    this.snackbarService.openSnackBar('Security Role saved successfully', 'clear', 'success');
                },
                err => {
                    this.snackbarService.openSnackBar(err, 'clear', 'warn');
                }
            );
    }

    updateSecurityRole(securityRoleId: string, securityRoleSubmit: SecurityRoleSubmit) {
        this.securityRoleService.putSecurityRole(securityRoleId, securityRoleSubmit)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe(
                (res) => {
                    this.snackbarService.openSnackBar('Security Role saved successfully', 'clear', 'success');
                },
                err => {
                    this.snackbarService.openSnackBar(err, 'clear', 'warn');
                }
            );
    }

    navigateToSecurityRoles() {
        this.router.navigate([`${routes.SECURITY}/${routes.ROLES}`]);
    }

    copySecurityRole() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            roleId: this.securityRoleId
        };

        const dialogRef = this.dialog.open(CopyRoleDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data) {
                    this.router.navigate([`${routes.SECURITY}/${routes.ROLES}/${routes.EDITOR}/${data}`]);
                }
            }
        );
    }

    deleteSecurityRole() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            text: `Are you sure you want to delete this Security Role?`
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data === true) {
                    this.overlayService.show();

                    this.securityRoleService.deleteSecurityRole(this.securityRoleId)
                        .pipe(finalize(() => this.overlayService.hide()))
                        .subscribe(
                            (res) => {
                                this.snackbarService.openSnackBar(this.translate.instant('DeletedSuccessfully'), 'clear', 'success');
                                this.navigateToSecurityRoles();
                            }
                        );
                }
            }
        );
    }




}
