import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { SecurityRoleService } from '@app/modules/security-roles/services/security-role.service';
import { SecurityUser } from '@app/modules/security-setup/models/security-role.model';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { PageChangeEvent, RowArgs } from '@progress/kendo-angular-grid';
import { defer, finalize, forkJoin } from 'rxjs';
import { AddUserDialogComponent } from '../../../add-user-dialog/add-user-dialog.component';

@Component({
  selector: 'app-users-in-role',
  templateUrl: './users-in-role.component.html',
  styleUrls: ['./users-in-role.component.scss']
})
export class UsersInRoleComponent implements OnInit {
  @Input() securityRoleId: string;
  isLoading: boolean;
  gridDataResult: any;
  gridData: any;
  clearSelectedItems: boolean = false;
  public view: any;
  public pageSize: string = '20';
  public skip: number = 0;
  public bindingType: String = "array";
  searchValue: string;
  searchFilterString: string;
  getUsersRequest: any;

  public columns: any[] = [
    { field: "user", title: "User", type:'employee' },
  ];

  constructor(
    private dialog: MatDialog,
    private overlayService: OverlayService,
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private securityRoleService: SecurityRoleService
  ) { }

  ngOnInit(): void {
    this.getRoleUsers();
  }

  getRoleUsers() {
    this.isLoading = true;

    this.getUsersRequest = this.securityRoleService.getRoleUsers(this.securityRoleId, this.skip, this.pageSize, this.searchFilterString)
    .pipe(
      finalize(() => this.isLoading = false)
    )
    .subscribe(
      (res) => {
        // let outputArray = res.data.map(item => {
        //   return {
        //     employee: {
        //       id: item.id,
        //       firstName: item.firstName,
        //       lastName: item.lastName
        //     }
        //   };
        // });

        this.gridDataResult = {
          data: res.data,
          total: res.total,
        }
        this.gridData = res.data;
      }
    );
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;

    let variants = this.searchValue.split(' ').filter(value => value);
    this.searchFilterString = '';
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `((UserFirstName like "${variant}") OR (UserLastName like "${variant}") OR (UserId like "${variant}"))`;
        }
    });

    this.getUsersRequest.unsubscribe();
    this.getRoleUsers();
  }

  showAddUserModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {};

    const dialogRef = this.dialog.open(AddUserDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
          if (data && data.selectedItems && Array.isArray(data.selectedItems) && data.selectedItems.length > 0) {
            const observables = data.selectedItems.map(selectedUserId => defer(() => this.securityRoleService.addUserToRole(this.securityRoleId, selectedUserId)));
            this.overlayService.show();

            forkJoin(observables)
            .pipe(finalize(() => this.overlayService.hide()))
            .subscribe(
                (res) => {
                    this.getRoleUsers();
                    this.snackbarService.openSnackBar('Added successfully', 'clear', 'success');
                }
            );
          }
      }
    );
  }

  pageChange(event: PageChangeEvent) {
    this.skip = event.skip;
    this.pageSize = event.take.toString();

    this.getRoleUsers();
  }

  deleteAllSelected(selectedItems: SecurityUser[]) {
    this.overlayService.show();

    const observables = selectedItems.map(selectedEmployee => defer(() => this.securityRoleService.deleteRoleUser(this.securityRoleId, selectedEmployee?.user?.id)));

    forkJoin(observables)
    .pipe(finalize(() => this.overlayService.hide()))
    .subscribe(
        (res) => {
          this.clearSelectedItems = !this.clearSelectedItems;
          this.getRoleUsers();
          this.snackbarService.openSnackBar('Removed successfully', 'clear', 'success');
        }
    );
  }

  public selectEntireRow = (args: RowArgs) => {
    return args.dataItem;
  };
}
