import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TimeOffInLieuService } from '@app/core/services/time-off-in-lieu/time-off-in-lieu.service';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { EmployeeToilPolicy } from '@app/shared/models/time-off-in-lieu/time-off-in-lieu.model';
import { Observable, finalize } from 'rxjs';
import { AssignToilPolicyDialogComponent } from '../assign-toil-policy-dialog/assign-toil-policy-dialog.component';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { db_tables } from '@app/consts';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import * as moment from 'moment';
import { DecimalPipe } from '@angular/common';
import { ToilPolicyCalculationLogDialogComponent } from '../toil-policy-calculation-log-dialog/toil-policy-calculation-log-dialog.component';

@Component({
  selector: 'app-employee-toil-policy',
  templateUrl: './employee-toil-policy.component.html',
  styleUrls: ['./employee-toil-policy.component.scss']
})
export class EmployeeToilPolicyComponent implements OnInit {
  @Input() employeeId: string;
  employeeToilPolicies$: Observable<PagedData<EmployeeToilPolicy>>;
  isLoadingPermissions:boolean = true;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];
  modifyTimeOffTypeBanceFormId: string = 'frm_vbKs1DqvUDvz8S'
  modifyTimeOffTypeBanceChangeReasonFormId: string = 'frm_fjJulLMP4AnW5h'
  dialogRef: any;

  constructor(
    private timeOffInLieuService: TimeOffInLieuService,
    private dialog: MatDialog,
    private tablePermissionsService: TablePermissionsService,
    private overlayService: OverlayService,
    private translate: TranslateService,
    private snackbarService: SnackbarService,
    private decimalPipe: DecimalPipe,
  ) { }

  ngOnInit(): void {
    this.getEmployeeToilPolicies();
    this.getTargetEmployeeTablePermissions();
  }

  getTargetEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeId);

    // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
    if(currentFetchedData !== null){
      this.targetEmployeeTablePermissions = currentFetchedData;
      this.isLoadingPermissions = false;
    }
    else {
      this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeId)
      .pipe(
        finalize(() => {
            this.isLoadingPermissions = false;
        })
      )
      .subscribe(
        res => {
          this.targetEmployeeTablePermissions = res;
        }
      );
    }
  }

  get tableReadAccessObj() {
    return {
      tableId: db_tables.TOILEmployeeManualAdjustments,
      permission: 'read',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableCreateAccessObj() {
    return {
      tableId: db_tables.TOILEmployeeManualAdjustments,
      permission: 'create',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableUpdateAccessObj() {
    return {
      tableId: db_tables.TOILEmployeeManualAdjustments,
      permission: 'update',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableDeleteAccessObj() {
    return {
      tableId: db_tables.TOILEmployeeManualAdjustments,
      permission: 'delete',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  getEmployeeToilPolicies() {
    this.employeeToilPolicies$ = this.timeOffInLieuService.getEmployeeToilPolicies(this.employeeId);
  }

  getIcon(toilTypeId: string): Observable<string>{
    return this.timeOffInLieuService.getToilTypeIcon(toilTypeId)
  }

  openAssignPolicyDialog(employeeToilPolicy?: EmployeeToilPolicy) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      employeeId: this.employeeId,
      employeeToilPolicy: employeeToilPolicy
    };

    const dialogRef = this.dialog.open(AssignToilPolicyDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.getEmployeeToilPolicies();
        }
      }
    );
  }

  openConfirmDeleteDialog(employeeToilPolicy: EmployeeToilPolicy) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
        text: 'Are you sure you want to delete this?'
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.deletePolicy(employeeToilPolicy);
        }
      }
    );
  }

  deletePolicy(employeeToilPolicy: EmployeeToilPolicy){
    this.overlayService.show();

    this.timeOffInLieuService.removeEmployeeToilPolicy(employeeToilPolicy.id)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      res => {
        this.snackbarService.openSnackBar(this.translate.instant('DeletedSuccessfully'), 'clear', 'success');
        this.getEmployeeToilPolicies();
      }
    )
  }

  openModifyBalanceFormDialog(employeeToilPolicy: EmployeeToilPolicy) {
    let formData = {
      hours: this.decimalPipe.transform(employeeToilPolicy.availableHours, '1.0-1'),
      asOf: moment().format(),
      changeReason: '',
      changeReasonComments: '',
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formTitle: `${this.translate.instant('ModifyBalance')}`,
      formId: this.modifyTimeOffTypeBanceFormId,
      changeReasonFormId: this.modifyTimeOffTypeBanceChangeReasonFormId,
      formData: formData
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      event.policyId = employeeToilPolicy.toilPolicy?.id
      event.employeeId = this.employeeId
      this.saveModifyBalance(event)
    });
  }

  saveModifyBalance(formData: any) {
    formData.asOf = moment().format();

    this.overlayService.show();

    this.timeOffInLieuService.postManualAdjustments(formData)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      res => {
        this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
        this.dialogRef.close();
        this.getEmployeeToilPolicies();
      }
    )
  }

  openCalculationLogDialog(employeeToilPolicy: EmployeeToilPolicy) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      employeeId: this.employeeId,
      employeeToilPolicy: employeeToilPolicy
    };

    const dialogRef = this.dialog.open(ToilPolicyCalculationLogDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {

        }
      }
    );
  }

}
