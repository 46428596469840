import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { miscellaneous, routes } from '@app/consts';
import { finalize } from 'rxjs/operators';
import { BenefitsPlanType } from './models/benefits-plan-type.model';
import { BenefitsPlanTypesService } from './services/benefits-plan-types.service';

@Component({
  selector: 'app-benefits-plan-types',
  templateUrl: './benefits-plan-types.component.html',
  styleUrls: ['./benefits-plan-types.component.scss']
})
export class BenefitsPlanTypesComponent implements OnInit {
  public miscellaneous: typeof miscellaneous = miscellaneous;
  planTypes: BenefitsPlanType[] = [];
  loading: boolean;

  constructor(
    private benefitsPlanTypesService: BenefitsPlanTypesService,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.getPlanTypes();
  }

  getPlanTypes() {
    this.loading = true;

    this.benefitsPlanTypesService.getPlanTypes()
    .pipe(
      finalize( () => this.loading = false )
    )
    .subscribe(
      res => {
        this.planTypes = res;
      }
    )
  }

  navigateToPlanTypeDetailsPage(planTypeId: string) {
    this.router.navigateByUrl(`${routes.BENEFITS}${routes.PLAN_TYPES}/${planTypeId}`);
  }

}
