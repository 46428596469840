<div class="k-d-flex k-d-flex-row k-mb-3">
    <h5>Edit Time Off Type</h5>
    <div class="k-flex-grow"></div>
    <div class="buttons-container">
        <button mat-raised-button  (click)="cancel()" class="k-mr-2">Cancel</button>
        <button mat-raised-button setColor="primaryColour" color="primary" (click)="saveForm()" class="k-mr-4" [disabled]="!form?.valid">Save Form</button>
        <button mat-raised-button color="warn" (click)="showDeleteTypeConfirmation()">
            <span class="material-icons-outlined">delete</span>
            Delete Type
        </button>
    </div>
</div>

<div class="form-section-title">
</div>

<div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white; width:100%">
    <mat-spinner></mat-spinner>
</div>

<div class="form" [formGroup]="form" *ngIf="!isLoading">

    <h6>Time Off Name</h6>
    <ng-container formArrayName="localizations">
        <ng-container *ngFor="let localizationForm of localizations.controls; let i = index">
            <div class="lookup-form-row">
                <div [formGroup]="localizationForm">
                    <mat-form-field appearance="fill" class="k-mr-2">
                        <mat-label>
                            Select Language
                        </mat-label>

                        <mat-select disableOptionCentering formControlName="culture">
                            <mat-option *ngFor="let culture of cultures" [value]="culture.id">{{culture.text}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="k-mr-2">
                        <mat-label>
                            Enter Type Name
                        </mat-label>

                        <input matInput
                               formControlName="text"
                               placeholder="Type Name">
                    </mat-form-field>

                    <mat-icon 
                        setColor
                        [setColorRules]="[
                            {
                                color: 'primaryColour',
                                events: ['hover']
                            }
                        ]"
                        class="material-icons-outlined" 
                        (click)="deleteLocalization(i)" 
                        matTooltip="Delete"
                    >delete</mat-icon>
                </div>

            </div>
        </ng-container>

        <div class="k-mb-3">
            <button mat-raised-button (click)="addNewLocalization()" class="k-mr-2">
                <mat-icon class="material-icons-outlined">add</mat-icon>
                Add Text Localization
            </button>
        </div>

        <div class="form-section-title">
            <h6>Time Off Icon</h6>
        </div>
        <div class="k-mb-3">
            <div *ngIf="filePath && filePath !== ''; else noImage">
                <img [src]="domSanitizer.bypassSecurityTrustUrl(filePath)" class="timeoff-type-icon">
            </div>
            <ng-template #noImage>
                <div class="no-image"></div>
            </ng-template>
        </div>

        <div class="k-mb-3">
            <button type="button" mat-raised-button (click)="fileInput.click()">Choose File</button>
            <input hidden type="file" accept="image/*" #fileInput (change)="imagePreview($event)"/>
        </div>

    </ng-container>

</div>


