<app-employee-layout selectedComponent="EmploymentRecords">
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
            <h5 appLocalizationPopupDirective localizationCode="EmploymentRecords">{{ 'EmploymentRecords' | translate: {Default: "Employment Records"} }}</h5>
            <div>
                <button appLocalizationPopupDirective localizationCode="AddEmploymentRecord" mat-raised-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openFormDialog()" *appTableAccess="[tableCreateAccessObj]">
                    <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> 
                    {{ 'AddEmploymentRecord' | translate: {Default: "Add Employment Record"} }}
                </button> 
            </div>
        </mat-card-title>
        <mat-card-content>
            <ng-container *ngIf="isLoading; else loaded">
                <ngx-skeleton-loader
                    count="5"
                    [theme]="{ 
                        'height.px': 50
                    }"
                ></ngx-skeleton-loader>
            </ng-container>

            <ng-template #loaded>
                <app-employment-record-expansion-panel 
                    *ngFor="let employmentRecord of employmentRecords" 
                    (formGeneratorComponentEmit)="formGeneratorComponentEmitted($event, employmentRecord.id)"
                    (getEmploymentRecords)="getEmploymentRecords()"
                    [employmentRecord]="employmentRecord" 
                    [employeeId]="employeeId">
                </app-employment-record-expansion-panel>

                <div class="no-data" *ngIf="employmentRecords.length === 0">
                    <h6>{{ 'NoEmploymentRecords' | translate: {Default: "No Employment Records"} }}</h6>
                </div>
            </ng-template>

        </mat-card-content>
    </mat-card>
</app-employee-layout>