import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {api_routes} from '@app/consts';
import {BenefitsPlanType} from '../models/benefits-plan-type.model';
import {EnvironmentService} from "@app/core/services/environment.service";


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class BenefitsPlanTypesService {

  constructor(private http: HttpClient, private envService: EnvironmentService) {}

  getPlanTypes(): Observable<BenefitsPlanType[]> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.BENEFITS}/${api_routes.PLAN_TYPES}`, {
      headers: headers,
    });
  }
}
