import { Component, OnInit, ViewChild } from '@angular/core';
import { routes } from '@app/consts';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { GoalsReportService } from '../../services/goals-report.service';
import { MatSlideToggleChange } from "@angular/material/slide-toggle";

@Component({
    selector: 'app-goals-details-data-grid',
    templateUrl: './goals-report-details-data-grid.component.html',
    styleUrls: ['./goals-report-details-data-grid.component.scss']
})
export class GoalsReportDetailsDataGridComponent implements OnInit {
    @ViewChild(GridComponent)
    public grid: GridComponent;
    public routes: typeof routes = routes;
    public bindingType = 'array';
    public gridDataResult: GridDataResult;
    isLoading: boolean;
    sortString: string;
    filterString: string;
    filterToggleDetails: {
        text: string,
        checked: boolean
    } = {
        text: 'FilterInactive',
        checked: true
    }
    public columns: any[] = [
        {field: 'employeeId', title: this.translate.instant('GoalsReport-EmployeeId'), dataType: 'String'},
        {field: 'employeeFirstname', title: this.translate.instant('GoalsReport-EmployeeFirstName'), dataType: 'String'},
        {field: 'employeeLastname', title: this.translate.instant('GoalsReport-EmployeeLastName'), dataType: 'String'},
        {field: 'managerName', title: this.translate.instant('GoalsReport-ManagerName'), dataType: 'String'},
        {field: 'goalName', title: this.translate.instant('GoalsReport-GoalName'), dataType: 'String'},
        {field: 'goalTitle', title: this.translate.instant('GoalsReport-GoalTitle'), dataType: 'String'},
        {field: 'goalDescription', title: this.translate.instant('GoalsReport-GoalDescription'), dataType: 'String'},
        {field: 'goalExpectedCompletionDate', title: this.translate.instant('GoalsReport-GoalExpectedCompletionDate'), type: 'date', dataType: 'Date'},
        {field: 'goalActualCompletionDate', title: this.translate.instant('GoalsReport-GoalActualCompletionDate'), type: 'date', dataType: 'Date'},
        {field: 'parentGoal', title: this.translate.instant('GoalsReport-ParentGoal'), dataType: 'String'},
    ];
    sortableColumns: any[] = [
        {field: 'employeeId', sortValue: 'employeeId'},
        {field: 'employeeFirstname', sortValue: 'employeeFirstname'},
        {field: 'employeeLastname', sortValue: 'employeeLastname'},
        {field: 'managerName', sortValue: 'managerName'},
        {field: 'goalName', sortValue: 'goalName'},
        {field: 'goalTitle', sortValue: 'goalTitle'},
        {field: 'goalDescription', sortValue: 'goalDescription'},
        {field: 'goalExpectedCompletionDate', sortValue: 'goalExpectedCompletionDate'},
        {field: 'goalActualCompletionDate', sortValue: 'goalActualCompletionDate'},
        {field: 'parentGoal', sortValue: 'parentGoal'},
    ];

    filterCategories: any[] = [
        {field: 'Id', title: this.translate.instant('GoalsReport-EmployeeId'), dataType: 'String'},
        {field: 'FirstName', title: this.translate.instant('GoalsReport-EmployeeFirstName'), dataType: 'String'},
        {field: 'Lastname', title: this.translate.instant('GoalsReport-EmployeeLastName'), dataType: 'String'},
    ];

    constructor(
        private translate: TranslateService,
        private goalsReportService: GoalsReportService,
    ) {

        this.getData();
    }

    ngOnInit(): void {
    }

    getData(): void {
        this.isLoading = true;
        this.goalsReportService.fetch(this.filterString, this.sortString, this.filterToggleDetails.checked)
        .subscribe((response) => {
            console.log(response);
            this.gridDataResult = response;
            this.isLoading = false;
        });
    }

    sortChange(sortString: string) {
        if (sortString != this.sortString && this.isSortColumnSupported(sortString)) {
            this.sortString = sortString
            this.getData();
        } else {
            console.log(`Sorting by ${sortString} is not supported.`);
        }
    }

    isSortColumnSupported(sortString: string): boolean {
        const columnName = sortString.split('-')[0];
        return this.sortableColumns.some(column => column.sortValue === columnName);
    }


    updateFilterToggleDetails(toggleChange: MatSlideToggleChange) {
        this.filterToggleDetails.checked = toggleChange.checked;
        this.getData();
    }

    filterCallback(filterString: string) {
        this.filterString = filterString;
        console.log(this.filterString);
        this.getData();
    }
}
