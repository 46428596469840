<mat-card class="performance-chart">
  <mat-card-title class="performance-chart__header">
    <h5 appLocalizationPopupDirective localizationCode="TeamGoals" class="performance-chart__header-title">{{ 'TeamGoals' | translate: {Default: "Team Goals"} }}</h5>

    <app-settings-menu></app-settings-menu>
  </mat-card-title>

  <mat-card-content class="performance-chart__content">
    <div class="performance-chart__content-header">
      <div class="performance-chart__content-header-item">
        <div class="performance-chart__content-header-item-icon_blue"></div>
        <span class="performance-chart__content-header-item-text">Production</span>
      </div>
      <div class="performance-chart__content-header-item">
        <div class="performance-chart__content-header-item-icon_yellow"></div>
        <span class="performance-chart__content-header-item-text">Maintenance</span>
      </div>
    </div>

    <div class="performance-chart__progress-wrapper">
      <h6 class="performance-chart__progress-title">Production</h6>
      <mat-progress-bar
        class="performance-chart__progress-bar"
        mode="determinate"
        [value]=performanceChartData.integration
      ></mat-progress-bar>
      <h6 class="performance-chart__progress-title">Maintenance</h6>
      <mat-progress-bar
        class="performance-chart__progress-bar"
        mode="determinate"
        color="accent"
        [value]=performanceChartData.sdk
      ></mat-progress-bar>
    </div>
  </mat-card-content>
</mat-card>
