<div class="main-container" *ngIf="letter">
    <div class="variable-form-container">
        <h5>Form Values</h5>
        <form *ngIf="form" [formGroup]="form">
            <div *ngFor="let variable of variables">
                <mat-form-field appearance="fill">
                    <mat-label>
                        {{variable}}
                    </mat-label>
        
                    <input matInput type="text"
                        [formControlName]="variable">
                </mat-form-field>
            </div>
        </form>
    </div>

    <div class="editor-container">
        <kendo-editor [value]="letter.letterHTML" style="height: 100%;"></kendo-editor>
    </div>
</div>