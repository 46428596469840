import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { ReviewPlanGoalSetting } from '../../../../models/review-plan.model';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { ReviewPlansService } from '../../../../services/review-plans.service';
import { GoalPlanService } from '@app/modules/performance/components/goal-plans/services/goal-plan.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable, defer, finalize, forkJoin } from 'rxjs';
import { SelectEmployeesDialogComponent } from '@app/shared/components/select-employees-dialog/select-employees-dialog.component';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { ChangeReasonDialogComponent } from '@app/shared/components/change-reason-dialog/change-reason-dialog.component';

@Component({
  selector: 'app-review-plan-goal-plan-settings',
  templateUrl: './review-plan-goal-plan-settings.component.html',
  styleUrls: ['./review-plan-goal-plan-settings.component.scss']
})
export class ReviewPlanGoalPlanSettingsComponent implements OnInit {
  @Input() goalPlanGoalSettings: ReviewPlanGoalSetting[];
  @Input() selectedGoals: string[] = [];

  @Output() triggerRefresh = new EventEmitter<boolean>();

  isGloballyRated: boolean = false;

  public columns: any[] = [
    { field: "reviewer", title: "Name", type: 'employee'}
  ];
  reviewersGridDataResult: { data: {
    reviewer: {
      id: string
      firstName: string
      lastName: string
    }
  }[]; total: number; } = {
    data: [],
    total: 0
  };
  pageSize: number = 5;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  searchValue: string;
  searchFilterString: string;
  getUsersRequest: any;
  selectEmployeesDialogRef: any;

  // Goal Settings Form
  getFormData: boolean = false;
  formValid: boolean = false;
  formPristine: boolean = true;
  ratingsPristine: boolean = true;
  currentGoalSettingsFormData: any;
  
  constructor(
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private reviewPlansService: ReviewPlansService,
    private goalService: GoalPlanService,
    private dialog: MatDialog,
    private ngZone: NgZone,
  ) { }

  ngOnInit(): void {
  }

  saveGoalSettings() {
    const observables = this.selectedGoals.map(selectedGoalId => defer(
        () => {

          let submitData = 
          {
            ...this.currentGoalSettingsFormData,
            ...{ reviewers: this.currentGoalSettingsFormData?.isGloballyRated === true 
                            ? this.reviewersGridDataResult.data.map( reviewer => reviewer.reviewer.id ) 
                            : []
            }
          }

          return this.reviewPlansService.updateReviewPlanGoalSetting(this.goalPlanGoalSettings[0].reviewPlan.id, selectedGoalId, submitData)
        }
      )
    );

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(
        () => {
          this.overlayService.hide();
          this.triggerRefresh.emit(true)
        }
      )
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

  showAddUserModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      nonSelectableEmployees: [],
      employeeValueToSelect: 'employee'
    };

    this.ngZone.runOutsideAngular(() => {
      this.selectEmployeesDialogRef = this.dialog.open(SelectEmployeesDialogComponent, dialogConfig);
    });

    const sub = this.selectEmployeesDialogRef.componentInstance.emitSelectedEmployees.subscribe((event) => {
      const transformedData = event.map(item => ({
        reviewer: {
          id: item.id,
          firstName: item.firstName,
          lastName: item.lastName
        }
      }));

      this.reviewersGridDataResult.data.push(...transformedData)

      this.selectEmployeesDialogRef.close();
    });
  }

  openConfirmDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      localizationCode: 'ConfirmSetGoalSettings'
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.openChangeReasonDialog();
        }
      }
    ); 
  }

  openChangeReasonDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formId: 'frm_kFYw2RpJlweXyv',
    };

    const dialogRef = this.dialog.open(ChangeReasonDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
          if (data) {
            this.currentGoalSettingsFormData = { ...this.currentGoalSettingsFormData, ...data }
            this.saveGoalSettings();
          }
      }
    );
  }

  deleteAllSelected(employees: {
    id: string
    firstName: string
    lastName: string
  }[]) {
    this.reviewersGridDataResult.data = this.reviewersGridDataResult.data.filter( reviewer => !employees.some(employee => employee.id === reviewer.reviewer.id) )
    this.clearSelectedItems = !this.clearSelectedItems
  }

  search(newSearchString: string){
    this.searchValue = newSearchString.toLowerCase();
    this.skip = 0;
  }

  formDataEmitted(formDataEmitted) {
    this.openChangeReasonDialog();
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  formPristineEmitted(formPristine: boolean) {
    this.formPristine = formPristine;
  }

  formDataChange(formData) {
    this.currentGoalSettingsFormData = formData;
  }

}
