import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { routes } from '@app/consts';
import { EmployeeService } from '@app/core/services/employee.service';
import { CurrentUserDetails, TalentVerbose } from '@app/shared/models/employee.model';
import { User } from '@app/shared/models/user';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  @Input() user: any;
  @Output() signOut: EventEmitter<void> = new EventEmitter<void>();
  public routes: typeof routes = routes;
  currentEmployeeDetails: CurrentUserDetails;

  constructor(
    private employeeService: EmployeeService,
    private router: Router
  ) {
    // force route reload whenever params change;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.currentEmployeeDetails = this.employeeService.getFetchedCurrentUserDetails()
  }

  public signOutEmit(): void {
    this.signOut.emit();
  }
}
