<div class="container">
    <div class="top">
        <div class="employee-name">
            <app-employee-image [employeeId]="review.reviewedBy?.id" [width]="35" [height]="35" [round]="true"></app-employee-image>

            <span class="employee">
                {{review.reviewedBy?.firstName}} {{review.reviewedBy?.lastName}}
            </span>
        </div>

        <div *ngIf="user$.userId === review.reviewedBy?.id">
            <button class="settings-button" mat-mini-fab [matMenuTriggerFor]="settings" (click)="$event.stopPropagation()">
                <mat-icon class="settings-button__icon material-icons-outlined">more_vert</mat-icon>
            </button>
            <div class="settings-menu">
                <mat-menu #settings="matMenu" xPosition="before">
                    <button class="settings-menu__item material-icons-outlined" (click)="showForm = true" mat-menu-item>
                        <mat-icon class="settings-button__icon material-icons-outlined">edit</mat-icon>
                        {{ 'Edit' | translate: {Default: "Edit"} }}
                    </button>
                    <!-- <button class="settings-menu__item material-icons-outlined" (click)="deleteRating();" mat-menu-item>
                        <mat-icon class="settings-button__icon material-icons-outlined">delete</mat-icon>
                        {{ 'Delete' | translate: {Default: "Delete"} }}
                    </button> -->
                </mat-menu>
            </div>
        </div>
    </div>

    <div *ngIf="user$.userId === review.reviewedBy?.id && showForm; else showDetails">
        <form *ngIf="reviewRatingForm" [formGroup]="reviewRatingForm">
            <mat-form-field appearance="fill" *ngIf="reviewerPermissions.rating !== 'None'">
              <mat-label>{{ 'ReviewRating' | translate: {Default: 'Rating'} }}</mat-label>
              <mat-select disableOptionCentering formControlName="rating">
                <mat-option></mat-option>
                <mat-option *ngFor="let rating of goalTypeRatings" [value]="rating.id">
                  {{rating?.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" *ngIf="reviewerPermissions.comments !== 'None'">
              <mat-label>{{ 'ReviewComments' | translate: {Default: 'Comments'} }}</mat-label>
              <textarea
                matInput
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="20"
                cdkAutosizeMaxRows="30"
                formControlName="comments">
              </textarea>
            </mat-form-field>

            <div class="buttons-container">
                <button
                    mat-raised-button
                    appPreventDoubleClick
                    (throttledClick)="showForm = false"
                >{{ 'Cancel' | translate: {Default: "Cancel"} }}</button>

                <button
                    mat-raised-button
                    color="primary"
                    setColor="primaryColour"
                    appPreventDoubleClick
                    (throttledClick)="save()"
                    [disabled]="!reviewRatingForm.valid"
                >{{ 'Save' | translate: {Default: "Save"} }}</button>
            </div>
        </form>
    </div>
    <ng-template #showDetails>
        <ng-container *ngIf="review?.rating === null && review?.comments === null; else showReview">
            <div>{{ 'NoReview' | translate: {Default: "No Review"} }}</div>
        </ng-container>
        <ng-template #showReview>
            <div *ngIf="this.reviewerPermissions?.rating !== 'None' && review.rating !== null">
                <div class="label">{{ 'ReviewRating' | translate: {Default: 'Rating'} }}</div>
                <div class="value">{{review.rating?.name}}</div>
            </div>
            <div *ngIf="this.reviewerPermissions?.comments !== 'None' && review.comments !== null">
                <div class="label">{{ 'ReviewComments' | translate: {Default: 'Comments'} }}</div>
                <div class="value">{{review?.comments}}</div>
            </div>
        </ng-template>
    </ng-template>
</div>
