import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { Lookup } from '@app/modules/lookup/models/lookup.model';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EmploymentRecordInjuryIllness } from '../../models/employment-record-injury-illness.model';
import { EmploymentRecordInjuryIllnessService } from '../../services/employment-record-injury-illness.service';

@Component({
  selector: 'app-employment-record-injury-illness-dialog',
  templateUrl: './employment-record-injury-illness-dialog.component.html',
  styleUrls: ['./employment-record-injury-illness-dialog.component.scss']
})
export class EmploymentRecordInjuryIllnessDialogComponent implements OnInit {
  form: UntypedFormGroup;
  employmentRecordId: any;
  employeeId: any;
  injuryIllnessId: any;
  isLoading: boolean;
  employmentRecordInjuryIllness: EmploymentRecordInjuryIllness;
  caseDescriptionOptions: Observable<Lookup[]>;
  dayOfWeekOptions: Observable<Lookup[]>;
  governmentReportableOptions: Observable<Lookup[]>;
  occupationalIllnessOptions: Observable<Lookup[]>;
  occuredOnEmployeePremiseOptions: Observable<Lookup[]>;
  occurenceLocationOptions: Observable<Lookup[]>;
  occurenceTypeOptions: Observable<Lookup[]>;
  partOfBodyOptions: Observable<Lookup[]>;
  statusOptions: Observable<Lookup[]>;
  occurenceCountryOptions: Observable<Lookup[]>;

  constructor(
    private fb: UntypedFormBuilder,
    private lookupService: LookupService,
    private overlayService: OverlayService,
    private employmentRecordInjuryIllnesssService: EmploymentRecordInjuryIllnessService,
    private snackbarService: SnackbarService,
    private dialogRef: MatDialogRef<EmploymentRecordInjuryIllnessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data)
  {
    this.employmentRecordId = data.employmentRecordId;
    this.employeeId = data.employeeId;
    this.injuryIllnessId = data.InjuryIllnessId;
  } 

  ngOnInit(): void {
    this.getLookups();

    this.injuryIllnessId !== null 
      ? this.getEmploymentRecordInjuryIllness() 
      : this.isLoading = false; 
        this.createForm();
  }

  getEmploymentRecordInjuryIllness() {
    this.isLoading = true;
    
    this.employmentRecordInjuryIllnesssService.getInjuryIllness(this.employeeId, this.employmentRecordId, this.injuryIllnessId)
    .pipe(
      finalize(() => this.isLoading = false)
    )
    .subscribe(
      res => {
        this.employmentRecordInjuryIllness = res;
        this.createForm();
      }
    );
  }

  getLookups() {
    this.caseDescriptionOptions = this.lookupService.getLookups("/lookup/INJURY_CASE_DESCRIPTION");
    this.dayOfWeekOptions = this.lookupService.getLookups("/lookup/INJURY_DAY_OF_WEEK");
    this.governmentReportableOptions = this.lookupService.getLookups("/lookup/INJURY_GOVERNMENT_REPORTABLE");
    this.occupationalIllnessOptions = this.lookupService.getLookups("/lookup/INJURY_OCCUPATIONAL_ILLNESS");
    this.occuredOnEmployeePremiseOptions = this.lookupService.getLookups("/lookup/INJURY_OCCURED_ON_EMP_PREMISE");
    this.occurenceLocationOptions = this.lookupService.getLookups("/lookup/INJURY_OCCURENCE_LOCATION");
    this.occurenceTypeOptions = this.lookupService.getLookups("/lookup/INJURY_OCCURENCE_TYPE");
    this.partOfBodyOptions = this.lookupService.getLookups("/lookup/INJURY_PART_OF_BODY");
    this.statusOptions = this.lookupService.getLookups("/lookup/INJURY_STATUS");
    this.occurenceCountryOptions = this.lookupService.getLookups("/lookup/Country");
  }

  createForm() {
    this.form = this.fb.group({
      id: [this.employmentRecordInjuryIllness ? this.employmentRecordInjuryIllness.id : null],
      effectiveDate: [moment().format()],
      status: [this.employmentRecordInjuryIllness && this.employmentRecordInjuryIllness.status ? this.employmentRecordInjuryIllness.status.id : null],
      governmentReportable: [this.employmentRecordInjuryIllness && this.employmentRecordInjuryIllness.governmentReportable ? this.employmentRecordInjuryIllness.governmentReportable.id : null],
      caseDescription: [this.employmentRecordInjuryIllness && this.employmentRecordInjuryIllness.caseDescription ? this.employmentRecordInjuryIllness.caseDescription.id : null],
      dayOfWeek: [this.employmentRecordInjuryIllness && this.employmentRecordInjuryIllness.dayOfWeek ? this.employmentRecordInjuryIllness.dayOfWeek.id : null],
      occurenceLocation: [this.employmentRecordInjuryIllness && this.employmentRecordInjuryIllness.occurenceLocation ? this.employmentRecordInjuryIllness.occurenceLocation.id : null],
      occuredOnEmployeePremise: [this.employmentRecordInjuryIllness && this.employmentRecordInjuryIllness.occuredOnEmployeePremise ? this.employmentRecordInjuryIllness.occuredOnEmployeePremise.id : null],
      partOfBody: [this.employmentRecordInjuryIllness && this.employmentRecordInjuryIllness.partOfBody ? this.employmentRecordInjuryIllness.partOfBody.id : null],
      occurenceType: [this.employmentRecordInjuryIllness && this.employmentRecordInjuryIllness.occurenceType ? this.employmentRecordInjuryIllness.occurenceType.id : null],
      occupationalIllness: [this.employmentRecordInjuryIllness && this.employmentRecordInjuryIllness.occupationalIllness ? this.employmentRecordInjuryIllness.occupationalIllness.id : null],
      occurenceCountry: [this.employmentRecordInjuryIllness && this.employmentRecordInjuryIllness.occurenceCountry ? this.employmentRecordInjuryIllness.occurenceCountry.id : null],
      dateReported: [this.employmentRecordInjuryIllness ? this.employmentRecordInjuryIllness.dateReported : null],
      leaveStart: [this.employmentRecordInjuryIllness ? this.employmentRecordInjuryIllness.leaveStart : null],
      leaveEnd: [this.employmentRecordInjuryIllness ? this.employmentRecordInjuryIllness.leaveEnd : null],
      injuryIllnessDate: [this.employmentRecordInjuryIllness ? this.employmentRecordInjuryIllness.injuryIllnessDate : null],
      timeOfIncident: [this.employmentRecordInjuryIllness ? this.employmentRecordInjuryIllness.timeOfIncident : null],
      daysOffWork: [this.employmentRecordInjuryIllness ? this.employmentRecordInjuryIllness.daysOffWork : null],
      comments: [this.employmentRecordInjuryIllness ? this.employmentRecordInjuryIllness.comments : null],
      changeReason: [''],
      changeReasonComments: ['']
    });
  }

  save(){
    this.overlayService.show();

    this.employmentRecordInjuryIllnesssService.postInjuryIllness(this.employeeId, this.employmentRecordId, this.form.value)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar('Injury Illness saved successfully', 'clear', 'success');
        this.dialogRef.close(true);
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  close(){
    this.dialogRef.close();
  }

}



