import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { api_routes } from '@app/consts';
import {EnvironmentService} from "@app/core/services/environment.service";



const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class EmployeeProfilePhotoService {

  constructor(private http: HttpClient, private envService: EnvironmentService) {}

  getProfilePhotos(employeeId: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.PHOTO}`, {
      headers: headers
    });
  }

  public uploadProfilePhoto(image: File, employeeId: string): Observable<Object> {
    const formData = new FormData();

    formData.append('file', image);

    return this.http.post(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.PHOTO}`, formData);
  }
}
