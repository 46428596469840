import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableField } from "@app/modules/security-setup/models/table-field.model";
import { Table } from "@app/modules/security-setup/models/table.model";
import { ConditionType } from "@app/modules/business-rule-automation/models/business-rule-automation.model";
import { MetadataTableService } from "@app/core/services/metadata/metadata-table.service";

@Component({
    selector: 'business-rule-automation-rule',
    templateUrl: './business-rule-automation-rule.component.html',
    styleUrls: ['./business-rule-automation-rule.component.scss']
})
export class BusinessRuleAutomationRuleComponent implements OnInit {

    @Input() index: number = -1;
    @Input() propertyForm: any;
    @Input() tables: Table[];
    @Input() fields: TableField[];
    @Input() conditionTypes: ConditionType[];
    @Output() deleteRequest = new EventEmitter<number>();

    constructor(private metadataTableService: MetadataTableService,
    ) {
    }

    ngOnInit(): void {
    }

    onDelete() {
        this.deleteRequest.emit(this.index);
    }
}
