import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormElement } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { finalize } from 'rxjs/operators';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { EmployeeSecurityService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-security/services/employee-security.service';
import { SelectGoalTypeDialogComponent } from './components/select-goal-type-dialog/select-goal-type-dialog.component';
import { GoalType } from '@app/modules/performance/components/goal-types/models/goal-type.model';
import { GoalTypeService } from '@app/modules/performance/components/goal-types/services/goal-type.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-goal-type-selection-form-field',
  templateUrl: './goal-type-selection-form-field.component.html',
  styleUrls: ['./goal-type-selection-form-field.component.scss']
})
export class GoalTypeSelectionFormFieldComponent implements OnInit {
  @Input() formElement: FormElement;
  @Input() parentGroup: UntypedFormGroup;
  @Input() readOnly: boolean;
  @Input() mandatoryFilter: string;

  @ViewChild('goalTypeSelect') goalTypeSelect;
  goalTypeOptions: GoalType[] = [];
  isLoading: boolean;
  user$: any;
  filterString: string;
  showWarning: boolean = false;

  constructor(
    public translate: TranslateService,
    private goalTypeService: GoalTypeService,
    private oidcAuthService: OidcAuthService,
    private employeeSecurityService: EmployeeSecurityService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.user$ = this.oidcAuthService.userProfile;

    if(this.parentGroup.controls[this.formElement.formControl].value !== null){
      this.getGoalType();
    }
  }

  getGoalType() {
    this.isLoading = true;

    if(this.parentGroup.controls[this.formElement.formControl].value !== undefined){
      this.filterString = `(id = "${this.parentGroup.controls[this.formElement.formControl].value}")`
    }

    this.goalTypeService.getGoalTypes('20', 0, null, this.filterString)
    .pipe(
      finalize(() => {
          this.isLoading = false;
          this.checkIfGoalTypeIsArchived();
        }
      )
    )
    .subscribe(
      (res) => {
        this.goalTypeOptions = res.data
      }
    );
  }

  openSelectGoalTypeDialog() {
    this.goalTypeSelect.close();

    if(!this.readOnly){
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      dialogConfig.data = {
        mandatoryFilter: this.mandatoryFilter
      }

      const dialogRef = this.dialog.open(SelectGoalTypeDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        data => {
          if (data !== undefined) {
            this.setValue(data)
          }
        }
      );
    }
  }

  clearSelectedPosition() {
    this.goalTypeSelect.close();
    this.parentGroup.controls[this.formElement.formControl].setValue(null);
    this.goalTypeOptions = [];
    this.showWarning = false;
  }

  setValue(selectedGoalType: GoalType) {
    this.parentGroup.controls[this.formElement.formControl].setValue(selectedGoalType.id);
    this.getGoalType();
  }

  //This function will check if the selected goal type has status === 'Archived'
  checkIfGoalTypeIsArchived() {
    const selectedGoalType = this.goalTypeOptions.find((goalType) => goalType.id === this.parentGroup.controls[this.formElement.formControl].value);
    if (selectedGoalType && selectedGoalType.status?.id === 'Archived') {
      this.showWarning = true;
      // Do something if the selected goal type is archived
    }
    else {
      this.showWarning = false;
    }
  }


}

