import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { finalize } from 'rxjs/operators';
import { defer, forkJoin } from 'rxjs';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { api_routes, db_tables } from '@app/consts';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ReviewRatingsService } from './services/review-ratings.service';
import { ReviewRating } from './models/review-rating.model';

@Component({
  selector: 'app-review-ratings',
  templateUrl: './review-ratings.component.html',
  styleUrls: ['./review-ratings.component.scss']
})
export class ReviewRatingsComponent implements OnInit {
  public columns: any[] = [
    { field: "name", title: "Name", tableId: "tfi_RName" },
    { field: "description", title: "Description", tableId: "tfi_RDescription", type: "longText", longTextLimit: 200  },
    { field: "status", subField: "name", title: "Status", tableId: "tfi_RStatus", type: 'chip', chipValue: ['Active', 'Archived'] },
  ];

  filterCategories: any[] = [
    { field: "name", tableId: "tfi_RName", title: "Name", type: "String", dataType: 'String'},
    { field: "description", tableId: "tfi_RDescription", title: "Description", type: "String", dataType: 'String'},
    { field: "status", subField: "name", filterField: "status.id", tableId: "tfi_RStatus", title: "Status", dataType: "SpecialLookup", lookupCode: 'RATING_STATUS'},
  ];

  sortableColumns: any[] = [
    { field: "name", sortValue: "name"},
    { field: "description", sortValue: "description"},
    { field: "status", sortValue: "status.name"},
  ];

  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 10;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  formId: string = 'frm_rSF47qcFdGmOnw'
  dialogRef: any;
  formValid: any;
  searchValue: string;
  searchFilterString: string;
  getReviewRatingsRequest: any;
  sortString: string;
  filterString: string;

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private reviewRatingsService: ReviewRatingsService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
  ) {}

  ngOnInit(): void {
    this.view = this.getReviewRatings();
  }

  getReviewRatings() {
    this.isLoading = true;

    let filter;
    if(this.searchFilterString) {
      filter = this.searchFilterString;
    }
    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }

    this.getReviewRatingsRequest = this.reviewRatingsService.getReviewRatings(String(this.pageSize), this.skip, this.sortString, filter)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          }
          this.gridData = res.data;
        }
      );
  }

  getReviewRating(reviewRating?: ReviewRating) {
    if(reviewRating === undefined){
      this.openFormDialog();
    }
    else {
      this.isLoading = true;

      this.reviewRatingsService.getReviewRating(reviewRating.id)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        res => {
          this.openFormDialog(res);
        }
      );
    }
  }

  openFormDialog(reviewRating?: ReviewRating) {
    let formData = {
      id: reviewRating ? reviewRating.id : null,
      asOf: moment().format(),
      changeReason: '',
      changeReasonComments: '',
      name: reviewRating ? reviewRating.name : null,
      description: reviewRating ? reviewRating.description : null,
      status: reviewRating ? reviewRating.status?.id : null,
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formId: this.formId,
      formData: formData
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      this.save(event)
    });
  }

  formDataEmitted(formData) {
    this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  save(formData: any){
    this.overlayService.show();
    if(!formData.id){
      this.reviewRatingsService.createReviewRating(formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getReviewRatings();
        }
      );
    }
    else {
      this.reviewRatingsService.updateReviewRating(formData.id, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getReviewRatings();
        }
      );
    }
  }

  deleteReviewRatings(reviewRatingIds: string[]) {
    const observables = reviewRatingIds.map(selectedItem => defer(() => this.reviewRatingsService.deleteReviewRating(selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.view = this.getReviewRatings();
        this.clearSelectedItems = !this.clearSelectedItems;
          this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.view = this.getReviewRatings();
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;

    let variants = this.searchValue.split(' ').filter(value => value);
    this.searchFilterString = '';
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `(name like "${variant}")`;
        }
    });

    this.getReviewRatingsRequest.unsubscribe();
    this.getReviewRatings();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getReviewRatings();
  }

  filterCallback(filterString: string) {
    debugger;
    this.filterString = filterString;
    this.skip = 0;
    this.getReviewRatings();
  }

}