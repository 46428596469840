 spot
 <app-layout>
    <mat-toolbar class="page-header" role="heading">
        <h1>{{ 'AbsenceFinanceReport' | translate: {Default: "Absence Finance Report"} }}</h1>
    </mat-toolbar>

    <div class="content">
        <app-absence-finance-details-data-grid></app-absence-finance-details-data-grid>
    </div>
</app-layout>
