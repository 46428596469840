import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TimeOffInLieuService } from '@app/core/services/time-off-in-lieu/time-off-in-lieu.service';
import { EmployeeToilRequest, TOIL } from '@app/shared/models/time-off-in-lieu/time-off-in-lieu.model';
import * as moment from 'moment';
import { MyToilRequestDialogComponent } from '.././my-toil-requests/components/my-toil-request-dialog/my-toil-request-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-my-toil-requests',
  templateUrl: './my-toil-requests.component.html',
  styleUrls: ['./my-toil-requests.component.scss']
})
export class MyToilRequestsComponent implements OnInit {
  @Input() refreshMyToilRequest: boolean;

  leaveRequests$: any;
  public selectedFilter;

  selectedStatusFilters = [];
  statusOptions = [
    {id: "trs_Pending", name: 'Pending'},
    {id: "trs_Cancelled", name: 'Cancelled'},
    {id: "trs_Approved", name: 'Approved'},
    {id: "trs_Denied", name: 'Denied'}
  ]

  filterOptions = [
    {
      value: '3',
      text: 'Last3Months'
    },
    {
      value: '6',
      text: 'Last6Months'
    },
    {
      value: '12',
      text: 'Last12Months'
    },
    {
      value: '',
      text: 'All'
    },
  ]

  constructor(
    private toilService: TimeOffInLieuService,
    private translate: TranslateService,
    private dialog: MatDialog
  ) { 
    this.selectedFilter = this.filterOptions[0];
  }

  ngOnInit(): void {
    this.getLeaveRequests();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.refreshMyToilRequest && changes.refreshMyToilRequest.currentValue !== undefined){
      this.getLeaveRequests()
    }
  }

  buildFilterString(): string {
    let filterString;
    let statusOptionString;


    if(this.selectedFilter !== "") {
      filterString = `(StartDate > "${moment().subtract(parseInt(this.selectedFilter.value), 'months').format()}")`
    }

    this.selectedStatusFilters.forEach(
      option => {
        if(statusOptionString !== undefined){
          statusOptionString = `${statusOptionString} OR (RequestStatus.Id = "${option.id}")`
        }
        else {
          statusOptionString = `(RequestStatus.Id = "${option.id}")`
        }
      }
    )

    if(filterString !== undefined && statusOptionString !== undefined){
      filterString = `${filterString} AND (${statusOptionString})`;
    }
    else if(statusOptionString !== undefined){
      filterString = `(${statusOptionString})`;
    }

    return filterString;
  }

  getLeaveRequests() {
    this.toilService.getMyRequests(0, 100, `version.createdOn-desc`, this.buildFilterString())
    .subscribe(
      res => {
        if(res instanceof Observable){
          res.subscribe(
            r => {
              this.leaveRequests$ = res;
            }
          )
        }
        else {
          this.leaveRequests$ = of(res);
        }
      }
    );

    // this.leaveRequests$ = this.toilService.getMyRequests(filterTime);
  }

  openLeaveRequestDialog(employeeToilRequest: EmployeeToilRequest) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 500;

    dialogConfig.data = {
      employeeToilRequest: employeeToilRequest
    };

    const dialogRef = this.dialog.open(MyToilRequestDialogComponent, dialogConfig);

  }

  getSelectedStatusesText(): string {
    const selectedStatuses = this.selectedStatusFilters || [];
    return selectedStatuses.map((status: any) => this.translate.instant(status.name)).join(', ');
  }

}
