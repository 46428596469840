<div class="main-container">
    <div class="top">
        <h5>Add new list option value</h5>

        <mat-icon 
            setColor 
            [setColorRules]="[
                {
                    color: 'primaryColour',
                    events: ['hover']
                }
            ]"
            class="material-icons-outlined" 
            (click)="emitClose()" 
            matTooltip="Close"
        >
            close
        </mat-icon> 
    </div>
    <div class="bottom">
        <div class="buttons-container">
            <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid">Save</button>

            <button mat-raised-button (click)="addNewLocalization()">
                <mat-icon class="material-icons-outlined">add</mat-icon> Add Text Localization
            </button>
        </div>

        <div class="add-lookup-form" [formGroup]="form">
            <ng-container formArrayName="localizations">
                <ng-container *ngFor="let localizationForm of localizations.controls; let i = index">
                    <div class="lookup-form-row">
                        <div [formGroup]="localizationForm">
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Select Language
                                </mat-label>
    
                                <mat-select disableOptionCentering formControlName="culture">
                                    <mat-option *ngFor="let culture of cultures" [value]="culture.id">{{culture.text}}</mat-option>
                                </mat-select>
                            </mat-form-field>
    
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Enter List Option Value
                                </mat-label>
    
                                <input matInput
                                    formControlName="text"
                                    placeholder="List Option Value">
                            </mat-form-field>
                        </div>
                        <mat-icon 
                            setColor 
                            [setColorRules]="[
                                {
                                    color: 'primaryColour',
                                    events: ['hover']
                                }
                            ]" 
                            class="material-icons-outlined" 
                            (click)="deleteLocalization(i)" 
                            matTooltip="Delete"
                        >
                            delete
                        </mat-icon> 
                    </div>
                </ng-container>
            </ng-container>

            <mat-form-field  appearance="fill">
                <mat-label>
                    Start Date
                </mat-label>
            
                <input matInput [matDatepicker]="startDatePicker" formControlName="startDate">
                <span fxLayout="row" matSuffix>
                    <mat-datepicker-toggle  [for]="startDatePicker"></mat-datepicker-toggle>
                </span>
                <mat-datepicker #startDatePicker></mat-datepicker> 
            </mat-form-field>

            <mat-form-field  appearance="fill">
                <mat-label>
                    End Date
                </mat-label>
            
                <input matInput [matDatepicker]="endDatePicker" formControlName="endDate">
                <span fxLayout="row" matSuffix>
                    <mat-datepicker-toggle  [for]="endDatePicker"></mat-datepicker-toggle>
                </span>
                <mat-datepicker #endDatePicker></mat-datepicker> 
            </mat-form-field>

            <mat-slide-toggle setColor="primaryColour" formControlName="prioritize">Prioritize</mat-slide-toggle>
        </div>
    </div>
</div>