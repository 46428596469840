import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { api_routes } from '@app/consts';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { FormElementVerbose } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { EnvironmentService } from "@app/core/services/environment.service";

const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class MetadataFormService {

  constructor(private http: HttpClient, private envService: EnvironmentService) {}

  getForms(skip?: number, take?: string, filterString?: string, sortString?: string): Observable<any> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    filterString ? params = params.append('Filter', filterString) : null;
    sortString ? params = params.append('Sort', sortString) : null;

    return this.http
      .get<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.FORMS}`, {
        headers: headers,
        params: params
      });
  }

  getForm(id: string): Observable<any> {
    return this.http
    .get<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.FORMS}/${id}`, {
      headers: headers,
    });
  }

  postForm(data: string): Observable<any> {
    return this.http
    .post<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.FORMS}`, data, {
      headers: headers,
    });
  }

  updateForm(formId: string, data: any): Observable<any> {
    return this.http
    .put<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.FORMS}/${formId}`, data, {
      headers: headers,
    });
  }

  deleteForm(formId: string): Observable<any> {
    return this.http
    .delete<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.FORMS}/${formId}`, {
      headers: headers,
    });
  }

  getFormElementTypes(skip?: number, take?: string, filterString?: string, sortString?: string): Observable<any> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    filterString ? params = params.append('Filter', filterString) : null;
    sortString ? params = params.append('Sort', sortString) : null;

    return this.http
    .get<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.FORM_ELEMENT_TYPES}`, {
      headers: headers,
      params: params
    });
  }

  getFormElements(formId: string, skip?: number, take?: string, filterString?: string, sortString?: string): Observable<any> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    filterString ? params = params.append('Filter', filterString) : null;
    sortString ? params = params.append('Sort', sortString) : null;

    return this.http
    .get<PagedData<any>>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.FORMS}/${formId}/${api_routes.ELEMENTS}`, {
      headers: headers,
      params: params
    })
    .pipe(
      catchError(err => throwError(err)),
      map(res => {

        res.data.forEach(field => {
          // field.children.forEach(child => {
          //   child.properties.forEach(prop => {
          //     child[prop.property] = prop.value;
          //   });
          // });

          // //sort the children elements by order
          // field.children.sort((a, b) => {
          //   if (parseInt(a.order) > parseInt(b.order)) {
          //     return 1;
          //   }
          //   if (parseInt(a.order) < parseInt(b.order)) {
          //     return -1;
          //   }
          //   return 0;
          // });

          field.properties?.forEach(prop => {
            field[prop.property] = prop.value;
          });
        });

        //sort the elements by order
        res.data.sort((a, b) => {
          if (parseInt(a.order) > parseInt(b.order)) {
            return 1;
          }
          if (parseInt(a.order) < parseInt(b.order)) {
            return -1;
          }
          return 0;
        });

        return res;
      })
    );
  }

  getFormElement(formId: string, formElementId: string): Observable<FormElementVerbose> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.FORMS}/${formId}/${api_routes.ELEMENTS}/${formElementId}`, {
      headers: headers
    })
  }

  postFormElement(formId: string, data: string) {
    return this.http
    .post<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.FORMS}/${formId}/${api_routes.ELEMENTS}`, data, {
      headers: headers,
    });
  }

  updateFormElement(formId: string, formElementId: string, data: any) {
    return this.http
    .put<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.FORMS}/${formId}/${api_routes.ELEMENTS}/${formElementId}`, data, {
      headers: headers,
    })
  }

  deleteFormElement(formId: string, elementId:String) {
    return this.http
    .delete<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.FORMS}/${formId}/${api_routes.ELEMENTS}/${elementId}`, {
      headers: headers,
    });
  }

  getFormListTableHeaders() {
    return of(
       [
        { name: 'ID', dataKey: 'id', isSortable: true, position: 'left' },
        { name: 'Name', dataKey: 'name', isSortable: true, position: 'left' },
        { name: 'Root Table Id', dataKey: 'rootTableId', isSortable: true, position: 'left' },
        { name: 'Root Table Name', dataKey: 'rootTableName', isSortable: true, position: 'left' },
        { name: 'Created By Firstname', dataKey: 'versionCreatedByfirstName', isSortable: true, position: 'left' },
        { name: 'Created By Lastname', dataKey: 'versionCreatedBylastName', isSortable: true, position: 'left' },
        { name: 'Created On', dataKey: 'versionCreatedOn', isSortable: true, position: 'left' },
        { name: 'Effective Date', dataKey: 'versionEffectiveDate', isSortable: true, position: 'left' }
      ]
    );
  }
}
