<h5 mat-dialog-title>
    {{ 'AddOrganizations' | translate: {Default: 'Add Organizations'} }}
</h5>

<mat-dialog-content>

    <mat-form-field appearance="fill" class="module-dropdown">
        <mat-label>Select a Module</mat-label>
        <mat-select [(ngModel)]="selectedModule" required>
          <mat-option *ngFor="let module of modules" [value]="module.id">
            {{ module.name }}
          </mat-option>
        </mat-select>
    </mat-form-field>

    <kendo-grid
            class="grid"
            [data]="gridDataResult"
            [loading]="isLoading"
            [selectable]="{enabled: true}"
            kendoGridSelectBy="id"
            [(selectedKeys)]="selectedItems"
            [style.maxHeight.vh]="85" 
            [pageSize]="pageSize"
            [skip]="skip"
            [pageable]="{
                info: true,
                pageSizes: [5, 10, 20, 50, 100],
                previousNext: true
            }"
            (pageChange)="pageChange($event)"
            [sortable]="true">

        <ng-template kendoGridToolbarTemplate>
            <div class="filters-container">
                <mat-form-field class="search-field" appearance="outline">
                    <input matInput
                           placeholder="Search..."
                           [(ngModel)]="searchValue"
                           (input)="search()"
                    >
                    <mat-icon matPrefix class="material-icons-outlined">
                        search
                    </mat-icon>
                </mat-form-field>
            </div>
        </ng-template>

        <kendo-grid-checkbox-column
                [width]="80"
                showSelectAll="true"
        ></kendo-grid-checkbox-column>

        <ng-container *ngFor="let col of columns">
            <!-- Normal Column Data -->
            <kendo-grid-column
                    *ngIf="!col.type"
                    [field]="col.field"
                    [title]="col.title"
                    [width]="200">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        <ng-container *ngIf="col.subField && dataItem[col.field]; else noSubField">
                            {{dataItem[col.field][col.subField]}}
                        </ng-container>
                        <ng-template #noSubField>
                            {{dataItem[col.field]}}
                        </ng-template>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <!-- Date Column Data -->
            <kendo-grid-column
                    *ngIf="col.type === 'date'"
                    [field]="col.field"
                    [title]="col.title"
                    [width]="200">
                <ng-template kendoGridCellTemplate let-dataItem >
                    <div class="grid-cell-vertical">
                                <span>
                                    {{dataItem[col.field] | date:'mediumDate' }}
                                </span>
                    </div>
                </ng-template>
            </kendo-grid-column>
        </ng-container>

    </kendo-grid>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Cancel</button>

    <button 
        class="add-all-selected-button" 
        mat-raised-button
        setColor="primaryColour" 
        color="primary" 
        [disabled]="selectedModule === null || selectedItems.length < 1"
        appPreventDoubleClick 
        (throttledClick)="openConfirmAddUsersDialog()">
        <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> Add All Selected ({{selectedItems.length}})
    </button>
</mat-dialog-actions>
