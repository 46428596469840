<mat-card class="revenue-chart">
    <mat-card-title class="revenue-chart__header">
      <h5 class="revenue-chart__header-title">Contingent Worker Analysis</h5>
      
      <app-settings-menu></app-settings-menu>
    </mat-card-title>
    
    <mat-card-content class="revenue-chart__content">
      <div class="leave-chart-container">
          <div class="revenue-chart__content-chart" echarts [options]="chart"></div>
          <div class="chart-figures">
              <div *ngFor="let item of labelData" class="row">
                  <div class="dot" [ngStyle]="{'background-color': item.color}"></div>
                  <div class="details">
                      <div class="label"> {{item.label}}</div>
                      <!-- <div class="value">{{item.value}}</div> -->
                  </div>
              </div>
          </div>
      </div>

      <app-table
            [tableData]="labelData"
            [tableColumns]="tableColumns"
            [isFilterable]="false"
            [isPageable]="false"
        ></app-table>
    </mat-card-content>
  </mat-card>