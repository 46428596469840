import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { routes } from '@app/consts';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-admin-controls',
  templateUrl: './admin-controls.component.html',
  styleUrls: ['./admin-controls.component.scss']
})
export class AdminControlsComponent implements OnInit {

  public routes: typeof routes = routes;
    navList = [
      {
          text: this.translate.instant('Positions'),
          link: `${routes.ADMIN_CONTROLS}${routes.POSITIONS}`,
          icon: 'badge'
      },
      {
        text: this.translate.instant('OrganizationStructure'),
        link: `${routes.ADMIN_CONTROLS}${routes.ORGANIZATION}`,
        icon: 'domain'
      },
      {
        text: this.translate.instant('Talent'),
        link: `${routes.ADMIN_CONTROLS}${routes.TALENT_TRACK}`,
        icon: 'group'
      },
      {
        text: this.translate.instant('AssignPositions'),
        link: `${routes.ADMIN_CONTROLS}${routes.ASSIGN_POSITIONS}`,
        icon: 'group'
      },
      {
        text: this.translate.instant('WorkRotations'),
        link: `${routes.ADMIN_CONTROLS}${routes.WORK_ROTATIONS}`,
        icon: 'group'
      },
      {
        text: this.translate.instant('WorkLocations'),
        link: `${routes.ADMIN_CONTROLS}${routes.WORK_LOCATIONS}`,
        icon: 'group'
      },
      {
        text: this.translate.instant('CostCenters'),
        link: `${routes.ADMIN_CONTROLS}${routes.COST_CENTERS}`,
        icon: 'group'
      },
      {
        text: this.translate.instant('ModuleConfiguration'),
        link: `${routes.ADMIN_CONTROLS}${routes.MODULE_CONFIGURATION}`,
        icon: 'dataset'
      },
      {
        text: this.translate.instant('CultureConfiguration'),
        link: `${routes.ADMIN_CONTROLS}${routes.CULTURE_CONFIGURATION}`,
        icon: 'dataset'
      },
    ];

    constructor(
        private translate: TranslateService,
        public router: Router,
    ) {
    }

    ngOnInit(): void {
    }

}
