import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {
  @Input() pdfUrl: string;

  @ViewChild('signature')
  public signaturePad: SignaturePadComponent;
  @ViewChild('typeSignature')
  public typeSignatureInput: ElementRef;

  url: string;
  showSignature: boolean = true;
  signaturePadOptions: NgSignaturePadOptions = { // passed through to szimek/signature_pad constructor
    minWidth: 5,
    canvasWidth: 500,
    canvasHeight: 100
  };

  constructor() {
  }

  ngOnInit(): void {
    this.url = this.pdfUrl;
  }

  ngAfterViewInit() {
    // // this.signaturePad is now available
    // this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
    // this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete(event: MouseEvent | Touch) {
    // will be notified of szimek/signature_pad's onEnd event
    console.log('Completed drawing', event);
    console.log(this.signaturePad.toDataURL());
  }

  drawStart(event: MouseEvent | Touch) {
    // will be notified of szimek/signature_pad's onBegin event
    console.log('Start drawing', event);
  }

  clearSignature() {
    this.signaturePad ? this.signaturePad.clear() : this.typeSignatureInput.nativeElement.value = '';
  }

}
