<div *ngIf="isLoading" class="loading-container">
  <div style="width:100%; padding: 10px;">
    <ngx-skeleton-loader
      count="5"
      [theme]="{ 
        'height.px': 50
      }"
    ></ngx-skeleton-loader>
  </div>
</div>

<div *ngIf="!isLoading" class="card">
  <div 
    *ngIf="!hideTitle"
    fxLayout="row" 
    fxLayoutAlign="space-between center"
    class="header">
    <h6>{{formName}}</h6>
    <div>
      <mat-icon *ngIf="!emptyForm && canDelete" aria-hidden="false" class="material-icons-outlined delete-icon" aria-label="delete icon" appPreventDoubleClick (throttledClick)="openConfirmDeleteDialog()">delete</mat-icon>
    </div>
  </div>

  <!-- Form div -->
  <div class="content form">

    <form [formGroup]="form"
          (ngSubmit)="onSubmit()"
    >
    <!-- We have to use ng container and individual mat-form-field because of a known error in angular material with mat-select -->
      <div class="form-container">

        <ng-container *ngFor="let field of this.formData.body">
          <ng-container *ngIf="field.type !== fieldTypes.TEXT_LOCALIZATION; else textLocalizationContainer">
          
            <div class="container-element" *ngIf="field.type === fieldTypes.CONTAINER; else formField">
              <h6>
                {{field.text}}
              </h6>

              <ng-container *ngFor="let child of field.children">
                <app-dynamic-form-field
                  [controlName]="child.formControl"
                  [field]="child"
                ></app-dynamic-form-field>
              </ng-container>
            </div>

            <ng-template #formField>
              <app-dynamic-form-field
                [controlName]="field.formControl"
                [field]="field"
              ></app-dynamic-form-field>
            </ng-template>

          </ng-container>

          <ng-template let-formArrayName="field" #textLocalizationContainer>
            <ng-container formArrayName="localizations" *ngIf="localizations">
              <ng-container *ngFor="let localizationForm of localizations.controls; let i = index">
                  <div class="localization">
                      <div [formGroup]="localizationForm">
                          <mat-form-field appearance="fill">
                              <mat-label>
                                  Language
                              </mat-label>

                              <mat-select disableOptionCentering formControlName="culture">
                                  <mat-option *ngFor="let culture of cultures" [value]="culture.culture">{{culture.text}}</mat-option>
                              </mat-select>
                          </mat-form-field>

                          <mat-form-field appearance="fill">
                              <mat-label>
                                  Work location name
                              </mat-label>

                              <input matInput formControlName="localization">
                          </mat-form-field>
                      </div>
                      <mat-icon class="material-icons-outlined" (click)="deleteLocalization(i)" matTooltip="Delete">delete</mat-icon> 
                  </div>
              </ng-container>
            </ng-container>
          </ng-template>

        </ng-container>
      </div>
      
      <div *ngIf="!hideButtons" class="button-container">
        <button mat-raised-button (click)="emitCloseDialog()">Cancel</button>
        <button mat-raised-button class="submit-button" type="submit" [disabled]="(form && !form.valid) || submittingForm">Submit</button>
      </div>
    </form>
  </div>
</div>