import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MetadataFormService } from '@app/core/services/metadata/metadata-form.service';
import { MetadataTableService } from '@app/core/services/metadata/metadata-table.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { Form, Table } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-form-details-form',
  templateUrl: './form-details-form.component.html',
  styleUrls: ['./form-details-form.component.scss']
})
export class FormDetailsFormComponent implements OnInit {
  @Input() formId: string;
  form: UntypedFormGroup;
  cultures: Culture[];
  formDetails: Form;
  tables: Table[];

  constructor(
    private fb: UntypedFormBuilder,
    private cultureService: CultureService,
    private metadataFormService: MetadataFormService,
    private metadataTableService: MetadataTableService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit(): void {
    this.getFormDetails();
    this.getTables();
    this.cultureService.getCultures().subscribe( res => { this.cultures = res } );
  }

  getFormDetails() {
    this.metadataFormService.getForm(this.formId)
      .subscribe(
        res => {
          this.formDetails = res;
          this.createForm();
        }
      );
  }

  getTables() {
    this.metadataTableService.getTables(0, '1000')
      .subscribe(
        res => {
          this.tables = res.data;
        }
      );
  }

  createForm() {
    this.form = this.fb.group({
      id: [this.formDetails ? this.formDetails.id : null],
      asOf: [moment().format()],
      name: this.fb.array([]),
      table: [this.formDetails ? this.formDetails.table?.id : null],
      changeReason: [''],
      changeReasonComments: [''],
    });

    if(this.formDetails){
      this.formDetails.name.forEach(
        localization => {
          this.addNewLocalization(localization.culture, localization.text);
        }
      );
    }
    else {
      this.addNewLocalization();
    }
  }

  get localizations() {
    return this.form.controls["name"] as UntypedFormArray;
  }

  //Create a textLocalization form group object to add to the localizations form array
  addNewLocalization(culture?: string, text?: string) {
    const localizationForm = this.fb.group({
      culture: [culture || '', Validators.required],
      text: [text || '', Validators.required]
    });

    this.localizations.push(localizationForm);
  }

  deleteLocalization(index: number) {
    this.localizations.removeAt(index);
  }

  save() {
    this.overlayService.show();

    this.metadataFormService.updateForm(this.formId, this.form.value)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
          this.snackbarService.openSnackBar('Form updated successfully', 'clear', 'success');
      },
    )
  }

}
