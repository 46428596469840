import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { GoalPlanService } from '../goal-plans/services/goal-plan.service';
import { forkJoin } from 'rxjs';
import { EmployeeAssignedGoalPlan } from '../goal-plans/models/goal-plan.model';
import * as moment from 'moment';

@Component({
  selector: 'app-employee-goal-plans',
  templateUrl: './employee-goal-plans.component.html',
  styleUrls: ['./employee-goal-plans.component.scss']
})
export class EmployeeGoalPlansComponent implements OnInit {
  @Input() employeeId: string;
  isLoading: boolean = true;
  isLoadingGoalPlans: boolean;
  goalPlans: EmployeeAssignedGoalPlan[];
  filteredGoalPlans: EmployeeAssignedGoalPlan[];
  goalEntryAllowedGoalPlans: EmployeeAssignedGoalPlan[];

  selectedStatusFilter: { id: string, name: string }
  statusFilterOptions = [
    {id: "Archived", name: 'Archived'},
    {id: "Complete", name: 'Complete'},
    {id: "Active", name: 'Active'},
  ]

  selectedFromFilter: string;
  selectedToFilter: string;

  searchValue: any;
  getRequest: any;

  employeeGoalEntryAllowedFilter: { id: string, name: string }
  employeeGoalEntryAllowedFilterOptions = [
    {id: "True", name: 'Allowed'},
    {id: "False", name: 'NotAllowed'},
  ];

  sortByFilter: { id: string, name: string }
  sortByFilterOptions = [
    {id: "GoalPlan.name-asc", name: 'GoalPlan-Name-Asc'},
    {id: "GoalPlan.name-desc", name: 'GoalPlan-Name-Desc'},
    {id: "lastPublished-asc", name: 'GoalPlan-LastPublished-Asc'},
    {id: "lastPublished-desc", name: 'GoalPlan-LastPublished-Desc'},
  ]

  constructor(
    public translate: TranslateService,
    private goalService: GoalPlanService
  ) {}

  ngOnInit(): void {
    this.getGoalPlans();
  }

  getGoalPlans() {
    this.getRequest?.unsubscribe();
    this.isLoadingGoalPlans = true;

    this.getRequest = this.goalService.getEmployeeGoalPlans(this.employeeId, '100', 0, this.sortByFilter?.id, this.getFilter())
    .pipe(
      finalize( () => {
        this.isLoading = false;
        this.isLoadingGoalPlans = false;
      })
    )
    .subscribe(
      (res) => {
        this.goalPlans = res?.data;
      }
    );
  }

  getFilter(): string {
    let filter;

    if(this.searchValue) {
      let searchString = `(goalPlan.name like "${this.searchValue}")`;

      if (filter) {
        filter = `${filter} AND ${searchString}`;
      } else {
        filter = searchString;
      }
    }

    if(this.selectedStatusFilter) {
      let filterString = `(status.id = "${this.selectedStatusFilter.id}")`;

      if (filter) {
        filter = `${filter} AND ${filterString}`;
      } else {
        filter = filterString;
      }
    }

    if(this.employeeGoalEntryAllowedFilter) {
      let filterString = `(employeeGoalEntryAllowed = "${this.employeeGoalEntryAllowedFilter.id}")`;

      if (filter) {
        filter = `${filter} AND ${filterString}`;
      } else {
        filter = filterString;
      }
    }

    if(this.selectedFromFilter) {
      let filterString = `(lastPublished >= "${moment(this.selectedFromFilter).format("YYYY-MM-DD")}")`;

      if (filter) {
        filter = `${filter} AND ${filterString}`;
      } else {
        filter = filterString;
      }
    }

    if(this.selectedToFilter) {
      let filterString = `(lastPublished <= "${moment(this.selectedToFilter).format("YYYY-MM-DD")}")`;

      if (filter) {
        filter = `${filter} AND ${filterString}`;
      } else {
        filter = filterString;
      }
    }

    return filter;
  }
}
