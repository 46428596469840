<h5 mat-dialog-title>{{ 'AssignSecurityRole' | translate: {Default: "Assign Security Role"} }}</h5>

<mat-dialog-content>
    <kendo-grid
        [data]="gridDataResult"
        [loading]="isLoading"
        [pageSize]="pageSize"
        [style.maxHeight.vh]="85" 
        [skip]="skip"
        [pageable]="{
            info: true,
            pageSizes: [5, 10, 20, 50, 100],
            previousNext: true
        }"
        (pageChange)="pageChange($event)"
        [selectable]="{enabled: true}"
        kendoGridSelectBy="id"
        [(selectedKeys)]="selectedItems"
        [sortable]="true"
    >

        <ng-template kendoGridToolbarTemplate>
            <filter-control [categories]="filterCategories" (callback)="filterCallback($event)"></filter-control>
        </ng-template>

        <kendo-grid-checkbox-column
                width="60"
                showSelectAll="true"
        ></kendo-grid-checkbox-column>

        <kendo-grid-column field="name" [title]="translate.instant('AssignSecurityRole-Name')" width="400">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="grid-cell-vertical">
                <span class="position-name navigation-link">
                    {{dataItem.name}}
                </span>
                </div>
            </ng-template>
        </kendo-grid-column>


        <kendo-grid-column field="totalUsers" [title]="translate.instant('AssignSecurityRole-TotalUsers')">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>
                    {{dataItem.totalUsers}}
                </span>
            </ng-template>
        </kendo-grid-column>

    </kendo-grid>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Cancel' | translate: {Default: "Cancel"} }}</button>

    <div *ngIf="selectedItems.length > 0">
        <button class="add-all-selected-button" mat-raised-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openConfirmAddUsersDialog()">
            <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> 
            {{ 'AssignAllSelectedRoles' | translate: {Default: "Assign All Selected Roles"} }} ({{selectedItems.length}})
        </button>
    </div>
</mat-dialog-actions>
