import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'birthdayTimePeriodSearch' })
export class BirthdayTimePeriodPipe implements PipeTransform {
  public transform(employees: any[], searchText: any): any {
    if (searchText == null || employees == null) {
      return employees;
    }

    if(searchText === 'thisMonth'){
        return employees.filter(employee => 
            moment().month() === moment(employee.birthday).month()
        );
    }
    else if(searchText === 'thisWeek'){
        return employees.filter(employee => 
            moment().isoWeek() === moment(employee.birthday).isoWeek()
        );
    }

    return employees;
  }
}