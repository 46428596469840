<div class="container">
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
            <h5>{{ formElement.text }}</h5>
    
            <button
                mat-flat-button
                class="action-button"
                setColor="primaryColour"
                color="primary"
                (click)="showAddUserModal()"
            >
                <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon>
                <span class="button-text" appLocalizationPopupDirective localizationCode="Add">{{ 'Add' | translate: {Default: "Add"} }}</span>
            </button>
        </mat-card-title>
    
        <mat-card-content>
            <app-local-data-grid-comp
                [tableId]="db_tables.Talent"
                class="reviewers-data-grid"
                [columns]="columns"
                [data]="employeesGridDataResult"
                [rowSelectionSettings]="{
                    canSelectRows: true,
                    selectableMode: 'multiple',
                    selectRowBy: 'employee'
                }"
                [isLoading]="isLoading"
                [pageable]="true"
                (emitDeleteEvent)="deleteAllSelected($event)"
                [clearSelectedItems]="clearSelectedItems"
            ></app-local-data-grid-comp>
        </mat-card-content>
    </mat-card>
</div>


<!-- <app-data-grid-comp
    [tableId]="db_tables.PerformanceReviewPlanReviewers"
    [columns]="columns"
    [bindingType]="bindingType"
    [view]="view"
    [gridDataResult]="gridDataResult"
    [isLoading]="isLoading"
    [pageSize]="pageSize"
    [skip]="skip"
    category="Reviewers"
    [rowSelectionSettings]="{
        canSelectRows: true,
        selectableMode: 'multiple',
        selectRowBy: 'id'
    }"
    clickableRows="true"
    [isLoading]="isLoading"
    [clearSelectedItems]="clearSelectedItems"
    [filterCategories]="filterCategories"
    [sortable]="{
        mode: 'multiple'
    }"
    [sortableColumns]="sortableColumns"
    [searchable]="true"
    (emitSortEvent)="sortChange($event)"
    (emitSearchEvent)="search($event)"
    (emitFilterEvent)="filterCallback($event)"
    (emitPageChangeEvent)="pageChange($event)"
    (emitOpenDialogEvent)="getReviewPlanReviewerVerbose($event)"
    (emitDeleteEvent)="deleteReviewPlanReviewers($event)"
></app-data-grid-comp> -->