<app-site-settings-menu>
    <mat-card *ngIf="hasFeatureAccess" class="mat-elevation-z0">
        <mat-card-title class="header">
        </mat-card-title>
        <mat-card-content>
            <div class="main-container">
                    <h5 appLocalizationPopupDirective localizationCode="GenerateSchedule">{{ 'GenerateSchedule' | translate: {Default: "Generate Schedule"} }}</h5>
    
                    <div class="page-description-section">
                        {{ 'GenerateScheduleDescriptionMsg' | translate: {Default: "Select a date range and the positions you wish to generate shifts for. Only positions with an End Date after today will be selectable."} }}
                    </div>

                    <div class="form-container">
                        <div class="k-d-flex-row">
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    {{ 'StartDate' | translate: {Default: "Start Date"} }} *
                                </mat-label>
            
                                <input matInput [matDatepicker]="startDatepicker" [(ngModel)]="startDate">
                                <span fxLayout="row" matSuffix>
                                <mat-datepicker-toggle [for]="startDatepicker"></mat-datepicker-toggle>
                            </span>
                                <mat-datepicker #startDatepicker></mat-datepicker>
                                <mat-hint></mat-hint>
                            </mat-form-field>
                        </div>
            
                        <div class="k-d-flex-row">
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    {{ 'EndDate' | translate: {Default: "End Date"} }} *
                                </mat-label>
            
                                <input matInput [matDatepicker]="endDatepicker" [(ngModel)]="endDate">
                                <span fxLayout="row" matSuffix>
                                <mat-datepicker-toggle [for]="endDatepicker"></mat-datepicker-toggle>
                            </span>
                                <mat-datepicker #endDatepicker></mat-datepicker>
                                <mat-hint></mat-hint>
                            </mat-form-field>
                        </div>

                        <div class="selected-positions-container">
                            <h6>
                                {{ 'AddPositionSelectedPositions' | translate: {Default: "Selected Positions"} }}: {{selectedPositions.length}} of {{positions.length}} positions
                            </h6>
            
                            <mat-list class="list" role="list">
                                <div *ngIf="selectedPositions.length === 0">
                                    {{ 'NoSelectedPositions' | translate: {Default: "No Selected Positions"} }}
                                </div>

                                <mat-list-item *ngFor="let position of selectedPositions">
                                    <mat-icon
                                        class="material-icons-outlined clickable-icon"
                                        setColor 
                                        [setColorRules]="[
                                            {
                                                color: 'primaryColour',
                                                events: ['hover']
                                            }
                                        ]" 
                                        (click)="removePosition(position)"
                                        matTooltip="Unselect Position"
                                        mat-list-icon>remove_circle_outline</mat-icon>
            
                                    {{position.name}}
                                </mat-list-item>
                            </mat-list>
                        </div>

                        <button mat-flat-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!isFormValid">
                            <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">play_circle_outline</mat-icon> Generate Shifts
                        </button>
                    </div>
        
                    <div *ngIf="employeePositionId == null">
                        <div class="positions-container">
                            <h6>
                                {{ 'AddPositionPositions' | translate: {Default: "Positions"} }}
                            </h6>
            
                            <mat-form-field class="search-field" appearance="outline">
                                <input matInput
                                        placeholder="Search..."
                                        [(ngModel)]="searchValue"
                                        (input)="search()"
                                        >
                                <mat-icon matPrefix class="material-icons-outlined">
                                    search
                                </mat-icon>
                            </mat-form-field>

                            <ng-container *ngIf="!loadingPositions; else showLoading">
                                <div class="select-all-container">
                                    Select All <mat-checkbox setColor="primaryColour" (change)="toggleSelectAll($event)" [checked]="selectAll"></mat-checkbox>
                                </div>

                                <div *ngIf="positions.length === 0; else showPositions" class="no-positions-container">
                                    <h6>{{ 'NoPositions' | translate: {Default: "No positions found"} }}</h6>
                                </div>
            
                                <ng-template #showPositions>
                                    <mat-accordion class="example-headers-align" multi>
            
                                        <cdk-virtual-scroll-viewport  style="height: 500px" itemSize="50" >
                                            <ng-container *cdkVirtualFor="let position of positions;">
                                                <mat-expansion-panel>
                                                <mat-expansion-panel-header>
                                                    <mat-panel-title>
                                                        <mat-icon
                                                        *ngIf="selectedPositions.indexOf(position) === -1"
                                                        class="material-icons-outlined clickable-icon"
                                                        setColor 
                                                        [setColorRules]="[
                                                            {
                                                                color: 'primaryColour',
                                                                events: ['hover']
                                                            }
                                                        ]" 
                                                        matTooltip="Select Position"
                                                        (click)="addPosition(position); $event.stopPropagation()">add</mat-icon>
            
                                                        <mat-icon
                                                        *ngIf="selectedPositions.indexOf(position) !== -1"
                                                        class="material-icons-outlined clickable-icon"
                                                        setColor 
                                                        [setColorRules]="[
                                                            {
                                                                color: 'primaryColour',
                                                                events: ['hover']
                                                            }
                                                        ]" 
                                                        matTooltip="Unselect Position"
                                                        (click)="removePosition(position); $event.stopPropagation()">remove_circle_outline</mat-icon>
            
                                                        {{position.name}}
                                                    </mat-panel-title>
                                                </mat-expansion-panel-header>
            
                                                <div class="section">
                                                    <div class="section-title">
                                                        <h6>{{ 'Title' | translate: {Default: "Title"} }}</h6>
                                                    </div>
                                                    <div class="section-contents">
                                                        <div class="section-row">
                                                            <div class="detail">
                                                                <div class="detail-label">{{ 'Name' | translate: {Default: "Name"} }}</div>
                                                                <div class="detail-value">{{position.name}}</div>
                                                            </div>
            
                                                            <div class="detail">
                                                                <div class="detail-label">{{ 'FunctionalJobTitle' | translate: {Default: "Functional Job Title"} }}</div>
                                                                <div class="detail-value">{{position.functionalJobTitle?.text}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
            
                                                <div class="section">
                                                    <div class="section-title">
                                                        <h6>Location</h6>
                                                    </div>
                                                    <div class="section-contents">
                                                        <div class="section-row">
                                                            <div class="detail">
                                                                <div class="detail-label">{{ 'WorkLocation' | translate: {Default: "Work Location"} }}</div>
                                                                <div class="detail-value">{{position.workLocation?.name}}</div>
                                                            </div>
            
                                                            <div class="detail">
                                                                <div class="detail-label">{{ 'Region' | translate: {Default: "Region"} }}</div>
                                                                <div class="detail-value">{{position.region?.text}}</div>
                                                            </div>
            
                                                            <div class="detail">
                                                                <div class="detail-label">{{ 'Department' | translate: {Default: "Department"} }}</div>
                                                                <div class="detail-value">{{position.department?.text}}</div>
                                                            </div>
            
                                                            <div class="detail">
                                                                <div class="detail-label">{{ 'Division' | translate: {Default: "Division"} }}</div>
                                                                <div class="detail-value">{{position.division?.text}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
            
                                                <div class="section">
                                                    <div class="section-title">
                                                        <h6>{{ 'WorkRotation' | translate: {Default: "Work Rotation"} }}</h6>
                                                    </div>
                                                    <div class="section-contents">
                                                        <div class="section-row">
                                                            <div class="detail">
                                                                <div class="detail-label">{{ 'WorkRotation' | translate: {Default: "Work Rotation"} }}</div>
                                                                <div class="detail-value">{{position.workRotation?.name}}</div>
                                                            </div>
            
                                                            <div class="detail">
                                                            <div class="detail-label">{{ 'WorkRotationStartDate' | translate: {Default: "Work Rotation Start Date"} }}</div>
                                                            <div class="detail-value">{{position.workRotationStartDate | date: 'mediumDate'}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
            
                                                <div class="section">
                                                    <div class="section-title">
                                                        <h6>{{ 'Employment' | translate: {Default: "Employment"} }}</h6>
                                                    </div>
                                                    <div class="section-contents">
                                                        <div class="section-row">
                                                            <div class="detail">
                                                                <div class="detail-label">{{ 'EmployeeCategory' | translate: {Default: "Employee Category"} }}</div>
                                                                <div class="detail-value">{{position.employeeCategory?.text}}</div>
                                                            </div>
            
                                                            <div class="detail">
                                                                <div class="detail-label">{{ 'EmploymentGroup' | translate: {Default: "Employment Group"} }}</div>
                                                                <div class="detail-value">{{position.employmentGroup?.text}}</div>
                                                            </div>
            
                                                            <div class="detail">
                                                                <div class="detail-label">{{ 'EmploymentType' | translate: {Default: "Employment Type"} }}</div>
                                                                <div class="detail-value">{{position.employmentType?.text}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
            
                                                <div class="section">
                                                    <div class="section-title">
                                                        <h6>{{ 'Job' | translate: {Default: "Job"} }}</h6>
                                                    </div>
                                                    <div class="section-contents">
                                                        <div class="section-row">
                                                            <div class="detail">
                                                                <div class="detail-label">{{ 'Job Band' | translate: {Default: "Job Band"} }}</div>
                                                                <div class="detail-value">{{position.jobBand?.text}}</div>
                                                            </div>
            
                                                            <div class="detail">
                                                                <div class="detail-label">{{ 'JobFamily' | translate: {Default: "Job Family"} }}</div>
                                                                <div class="detail-value">{{position.jobFamily?.text}}</div>
                                                            </div>
            
                                                            <div class="detail">
                                                                <div class="detail-label">{{ 'JobGroup' | translate: {Default: "Job Group"} }}</div>
                                                                <div class="detail-value">{{position.jobGroup?.text}}</div>
                                                            </div>
            
                                                            <div class="detail">
                                                                <div class="detail-label">{{ 'JobStep' | translate: {Default: "Job Step"} }}</div>
                                                                <div class="detail-value">{{position.jobStep?.text}}</div>
                                                            </div>
            
                                                            <div class="detail">
                                                                <div class="detail-label">{{ 'JobType' | translate: {Default: "Job Type"} }}</div>
                                                                <div class="detail-value">{{position.jobType?.text}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
            
                                                <div class="section">
                                                    <div class="section-title">
                                                        <h6>{{ 'Market' | translate: {Default: "Market"} }}</h6>
                                                    </div>
                                                    <div class="section-contents">
                                                        <div class="section-row">
                                                            <div class="detail">
                                                                <div class="detail-label">{{ 'MarketPosition' | translate: {Default: "Market Position"} }}</div>
                                                                <div class="detail-value">{{position.marketPosition?.text}}</div>
                                                            </div>
            
                                                            <div class="detail">
                                                                <div class="detail-label">{{ 'MarketPositionTitle' | translate: {Default: "Market Position Title"} }}</div>
                                                                <div class="detail-value">{{position.marketPositionTitle?.text}}</div>
                                                            </div>
            
                                                            <div class="detail">
                                                                <div class="detail-label">{{ 'MarketView' | translate: {Default: "Market View"} }}</div>
                                                                <div class="detail-value">{{position.marketView?.text}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
            
                                                <div class="section">
                                                    <div class="section-title">
                                                        <h6>{{ 'Slots' | translate: {Default: "Slots"} }}</h6>
                                                    </div>
                                                    <div class="section-contents">
                                                        <div class="section-row">
                                                            <div class="detail">
                                                                <div class="detail-label">{{ 'NumberOfSlots' | translate: {Default: "Number Of Slots"} }}</div>
                                                                <div class="detail-value">{{position.numberOfSlots}}</div>
                                                            </div>
            
                                                            <div class="detail">
                                                                <div class="detail-label">{{ 'SlotsFilled' | translate: {Default: "Slots Filled"} }}</div>
                                                                <div class="detail-value">{{position.slotsFilled}}</div>
                                                            </div>
            
                                                            <div class="detail">
                                                                <div class="detail-label">{{ 'SlotsAvailable' | translate: {Default: "Slots Available"} }}</div>
                                                                <div class="detail-value">{{position.slotsAvailable}}</div>
                                                            </div>
            
                                                            <div class="detail">
                                                                <div class="detail-label">{{ 'EnforceSlotLimit' | translate: {Default: "Enforce Slot Limit"} }}</div>
                                                                <div class="detail-value">{{position.enforceSlotLimit}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
            
                                                <div class="section">
                                                    <div class="section-title">
                                                    </div>
                                                    <div class="section-contents">
                                                        <div class="section-row">
                                                            <div class="detail">
                                                                <div class="detail-label">{{ 'ProjectTeam' | translate: {Default: "Project Team"} }}</div>
                                                                <div class="detail-value">{{position.projectTeam?.text}}</div>
                                                            </div>
            
                                                            <div class="detail">
                                                                <div class="detail-label">{{ 'ShiftCode' | translate: {Default: "Shift Code"} }}</div>
                                                                <div class="detail-value">{{position.shiftCode?.text}}</div>
                                                            </div>
            
                                                            <div class="detail">
                                                                <div class="detail-label">{{ 'SubDivision' | translate: {Default: "Sub Division"} }}</div>
                                                                <div class="detail-value">{{position.subDivision?.text}}</div>
                                                            </div>
            
                                                            <div class="detail">
                                                                <div class="detail-label">{{ 'UnionCode' | translate: {Default: "Union Code"} }}</div>
                                                                <div class="detail-value">{{position.unionCode?.text}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
            
                                                </mat-expansion-panel>
                                            </ng-container>
                                        </cdk-virtual-scroll-viewport>
            
                                    </mat-accordion>
                                </ng-template>
                            </ng-container>
            
                            <ng-template #showLoading>
                                <ngx-skeleton-loader
                                    count="5"
                                    [theme]="{ 
                                        'height.px': 50
                                    }"
                                ></ngx-skeleton-loader>
                            </ng-template>
                        </div>
        
                    </div>
                </div>
        </mat-card-content>
    </mat-card>

    <no-feature-access *ngIf="!hasFeatureAccess"></no-feature-access>
</app-site-settings-menu>

