import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api_routes } from '@app/consts';
import { DocumentUpdate } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-documents/models/document.model';
import { DirectorySubmit } from '@app/shared/models/employee/documents/directory-submit.model';
import { Directory } from '@app/shared/models/employee/documents/directory.model';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import {EnvironmentService} from "@app/core/services/environment.service";



const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class PositionDocumentsService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getDocuments(positionId: string, folderId?: string): Observable<any> {
    let params = new HttpParams();
    folderId ? params = params.append('FolderId', folderId) : null;

    return this.http.get<any[]>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}/${positionId}/${api_routes.DOCUMENTS}`, {
      headers: headers,
      params: params
    });
  }

  getDocument(positionId: string, documentId: string): Observable<any> {
    return this.http.get<any[]>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}/${positionId}/${api_routes.DOCUMENTS}/${documentId}`, {
      headers: headers
    });
  }

  deleteDocument(positionId: string, documentId: string): Observable<any> {
    return this.http.delete<any[]>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}/${positionId}/${api_routes.DOCUMENTS}/${documentId}`, {
      headers: headers
    });
  }

  createDocument(positionId: string, directory: DirectorySubmit): Observable<Directory> {
    return this.http.post<Directory>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}/${positionId}/${api_routes.DOCUMENTS}`, directory, {
      headers: headers
    });
  }

  updateDocument(positionId: string, documentId: string, documentUpdate: DocumentUpdate): Observable<Directory> {
    return this.http.put<Directory>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}/${positionId}/${api_routes.DOCUMENTS}/${documentId}`, documentUpdate, {
      headers: headers
    });
  }

  uploadDocument(positionId: string, file: File, name: string, parentId: string): Observable<any> {
    const formData: FormData = new FormData();

    formData.append('File', file);
    formData.append('Parent', parentId ? parentId : '');
    formData.append('Comments', '');
    formData.append('Name', name ? name : '');

    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}/${positionId}/${api_routes.DOCUMENTS}`,
      formData,
      {
        headers: {},
        reportProgress: true,
        observe: 'events'
      }
    );
  }

  getDocumentUrl(positionId: string, documentId: string): Observable<any> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}/${positionId}/${api_routes.DOCUMENTS}/${documentId}/${api_routes.DOWNLOAD}`,
    {
      headers: headers,
      responseType: 'text' as 'json'
    }
    );
  }

  downloadDocument(positionId: string, documentId: string): Observable<any> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}/${positionId}/${api_routes.DOCUMENTS}/${documentId}/${api_routes.DOWNLOAD}`,
    {
      headers: headers,
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  delete(positionId: string, documentId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS}/${positionId}/${api_routes.DOCUMENTS}/${documentId}`, {
      headers: headers
    });
  }
}
