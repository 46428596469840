import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StylingColoursService } from './services/styling-colours.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { FeatureService } from '@app/core/services/feature.service';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { Observable, defer, finalize, forkJoin } from 'rxjs';
import { SortDescriptor } from '@progress/kendo-data-query';
import { db_tables, features, routes } from '@app/consts';
import { StylingColour } from './models/styling-colours.model';
import { SecurityProtectedBase } from '@app/shared/components/security-protected/security-protected';
import { SecuritySetupService } from '@app/modules/security-setup/services/security-setup.service';

@Component({
  selector: 'app-styling-colours',
  templateUrl: './styling-colours.component.html',
  styleUrls: ['./styling-colours.component.scss']
})
export class StylingColoursComponent extends SecurityProtectedBase implements OnInit {
  isLoading: boolean;

  public db_tables = db_tables;
  public routes: typeof routes = routes;
  public features: typeof features = features;
  public columns: any[] = [
    {field: 'name', title: 'name'},
    {field: 'active', title: 'active', type: 'booleanToggle'},
    {field: 'defaultColours', title: 'default Colours', type: 'booleanToggle'},
    {field: 'primaryColour', title: 'primary Colour', tableId: "", type: 'colour'},
    {field: 'accentColour', title: 'accent Colour', tableId: "", type: 'colour'},
    {field: 'warnColour', title: 'warn Colour', tableId: "", type: 'colour'},
  ];
  public sort: SortDescriptor[] = [];
  public bindingType: String = 'array';
  public gridDataResult: GridDataResult;
  clearSelectedItems: boolean = false;
  pageSize: number = 20;
  skip: number = 0;
  sortString: string = "active-asc";
  sortingColumns: boolean;
  public searchFilterString: string;
  public searchValue: string;
  filterString: string;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public translate: TranslateService,
    public router: Router,
    private stylingColoursService: StylingColoursService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    featureService: FeatureService,
    private securitySetupService: SecuritySetupService,
    private tablePermissionsService: TablePermissionsService,
    
  ) {
    super(featureService, features.SITE_STYLING)
  }

  ngOnInit(): void {
    this.getStylingColours();
  }

  getStylingColours() {
    this.isLoading = true;

    this.stylingColoursService.getStylingColours(this.skip, this.pageSize)
    .pipe(
        finalize( () => {
          this.isLoading = false;
        })
    )
    .subscribe(
        res => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          };
        }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.getStylingColours();
  }

  public sortChange(sort: SortDescriptor[]): void {
      this.sort = sort;

      if (sort[0].dir === undefined) {
          this.sortString = null;
      } else {
          //use regex to get column field to sort with
          let field: any;

          //if it is an object category like (department.text) trim .text from the end, else it doesnt need to be trimmed
          (sort[0].field).match(/.+(?=\.)/) === null
              ? field = sort[0].field
              : field = (sort[0].field).match(/.+(?=\.)/);
          this.sortString = `${field}-${sort[0].dir}`;
      }

      this.getStylingColours();
  }

  deleteAllSelected(itemsToDelete: string[]) {
    const observables = itemsToDelete.map(item => defer(() => this.stylingColoursService.deleteStylingColour(item)));

    this.overlayService.show();

    forkJoin(observables)
        .pipe(
            finalize(() => this.overlayService.hide())
        )
        .subscribe(
            (res) => {
                this.clearSelectedItems = !this.clearSelectedItems;
                this.getStylingColours();
                this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
            },
            err => {
                this.snackbarService.openSnackBar(err, 'clear', 'warn');
            }
        );
  }


  filterCallback(filterString: string) {
      this.searchFilterString = filterString;
      // this.filterString = filterString;
      this.skip = 0;;
      this.getStylingColours();
  }

  navigateToEditor(stylingColour?: StylingColour) {
    if(stylingColour){
      this.router.navigate([`${routes.SITE_SETTINGS}${routes.STYLING}${routes.STYLING_COLOURS}${routes.EDITOR}/${stylingColour?.id}`], {relativeTo: this.route});
    }
    else {
      this.router.navigate([`${routes.SITE_SETTINGS}${routes.STYLING}${routes.STYLING_COLOURS}${routes.EDITOR}`], {relativeTo: this.route});
    }
  }
}
