<h5 mat-dialog-title>Edit list option value</h5>

<mat-dialog-content *ngIf="!loading">
    <div class="add-lookup-form" [formGroup]="form">
        <ng-container formArrayName="text">
            <ng-container *ngFor="let localizationForm of localizations.controls; let i = index">
                <div class="lookup-form-row">
                    <div [formGroup]="localizationForm">
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Language
                            </mat-label>

                            <mat-select disableOptionCentering formControlName="culture">
                                <mat-option *ngFor="let culture of cultures" [value]="culture.id">{{culture.text}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>
                                List Option Value
                            </mat-label>

                            <input matInput
                                formControlName="text"
                                placeholder="List Option Value">
                        </mat-form-field>
                    </div>
                    <mat-icon class="material-icons-outlined" (click)="deleteLocalization(i)" matTooltip="Delete">delete</mat-icon> 
                </div>
            </ng-container>
        </ng-container>

        <mat-form-field  appearance="fill">
            <mat-label>
                Start Date
            </mat-label>
        
            <input matInput [matDatepicker]="startDatePicker" formControlName="startDate">
            <span fxLayout="row" matSuffix>
                <mat-datepicker-toggle  [for]="startDatePicker"></mat-datepicker-toggle>
            </span>
            <mat-datepicker #startDatePicker></mat-datepicker> 
        </mat-form-field>

        <mat-form-field  appearance="fill">
            <mat-label>
                End Date
            </mat-label>
        
            <input matInput [matDatepicker]="endDatePicker" formControlName="endDate">
            <span fxLayout="row" matSuffix>
                <mat-datepicker-toggle  [for]="endDatePicker"></mat-datepicker-toggle>
            </span>
            <mat-datepicker #endDatePicker></mat-datepicker> 
        </mat-form-field>

        <mat-slide-toggle setColor="primaryColour" formControlName="prioritize">Prioritize</mat-slide-toggle>

        <div class="buttons-container">
            
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!loading">
    <button mat-raised-button (click)="addNewLocalization()">
        <mat-icon class="material-icons-outlined">add</mat-icon> Add Text Localization
    </button>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid">Save</button>
</mat-dialog-actions>