import { Component, OnInit } from '@angular/core';
import { routes } from '@app/consts/routes';
import { TimeOff } from '@app/modules/lookup/models/time-off.model';
import { RefreshListener } from '@app/shared/models/refresh-listener';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TimeOffInLieuService } from '@app/core/services/time-off-in-lieu/time-off-in-lieu.service';
import { ToilListVariant } from '@app/modules/lookup/models/toil-list-variant.model';
import { SecurityProtectedBase } from '@app/shared/components/security-protected/security-protected';
import { features } from '@app/consts';
import { FeatureService } from '@app/core/services/feature.service';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { ToilPolicy } from '@app/modules/lookup/models/toil-policy.model';


enum TimeOffViewState {
    LIST_TYPES,
    ADD_TYPE,
    EDIT_TYPE,
    POLICY
}

@Component({
  selector: 'app-toil-policies-types',
  templateUrl: './toil-policies-types.component.html',
  styleUrls: ['./toil-policies-types.component.scss']
})
export class ToilPoliciesTypesComponent extends SecurityProtectedBase implements OnInit, RefreshListener {

    public eTimeOffViewState = TimeOffViewState;
    public viewState: TimeOffViewState = TimeOffViewState.LIST_TYPES;

    public toilTypeList: ToilListVariant[] = [];
    public iconObservables: Observable<any>[] = [];
    private toilPolicyId: string;
    toilTypeId: string;
    toilType: ToilListVariant;
    isLoading: boolean = true;
    isLoadingType: boolean = false;
    timeOffTypeSearchValue: string = ''


    constructor(
        private translate: TranslateService,
        private timeOffInLieuService: TimeOffInLieuService,
        private dialog: MatDialog,
        private snackbarService: SnackbarService,
        featureService: FeatureService
    ) {
        super(featureService, features.TOIL)
    }

    ngOnInit(): void {
        this.getToilTypes();
    }


    private getToilTypes() {
        this.isLoading = true;

        this.timeOffInLieuService.getToilTypes()
        .pipe(
            finalize(() => this.isLoading = false)
        )
        .subscribe(
            timeOffTypePager => {
                this.toilTypeList = timeOffTypePager.data;
            }
        );
    }



    getIcon(toilTypeId: string, index: number){
        this.timeOffInLieuService.getToilTypeIcon(toilTypeId)
        .subscribe(
            res => {
                this.toilTypeList[index].image = res;
            }
        )
    }

    getRouterLink(item: TimeOff) {
        return `${routes.SITE_SETTINGS}${routes.TIME_OFF}/Edit/${item.id}`;
    }

    goToAdd() {
        this.viewState = TimeOffViewState.ADD_TYPE;
    }

    goToEdit(id: string) {
        this.viewState = TimeOffViewState.EDIT_TYPE;
        this.toilTypeId = id;
    }

    refresh(): void {
        this.getToilTypes();
        this.viewState = TimeOffViewState.LIST_TYPES;
    }

    showDeleteTypeConfirmation(timeOffType: TimeOff) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            text: `Are you sure you want to delete ${timeOffType.name}?`
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data === true) {
                    this.deleteType(timeOffType.id);
                }
            }
        );
    }

    deleteType(typeId: string) {
        this.timeOffInLieuService.deleteToilTypeById(typeId)
        .subscribe(
            timeOffType => {
                this.getToilTypes();
                this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
            }
        );
    }

    cancel(): void {
        this.viewState = TimeOffViewState.LIST_TYPES;
    }


    goToTimeOffPolicy(toilType: ToilListVariant, toilPolicy: ToilPolicy) {
        this.toilType = toilType;
        this.toilPolicyId = toilPolicy?.id;
        this.viewState = TimeOffViewState.POLICY;
    }
}
