import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { defer, forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EmploymentRecordPension } from './models/employment-record-pension.model';
import { EmploymentRecordPensionsService } from './services/employment-record-pensions.service';
import { EmploymentRecordPensionDialogComponent } from './components/employment-record-pension-dialog/employment-record-pension-dialog.component';

@Component({
  selector: 'app-employment-record-pensions',
  templateUrl: './employment-record-pensions.component.html',
  styleUrls: ['./employment-record-pensions.component.scss']
})
export class EmploymentRecordPensionsComponent implements OnInit {
  @Input() employeeId: string;
  @Input() employmentRecordId: string;
  public columns: any[] = [
    { field: "fundProvider", subField: "text", title: "Fund Provider", type: "navigation" },
    { field: "startDate", title: "Start Date", type: "date" },
    { field: "endDate", title: "End Date", type: "date" },
    { field: "planType", subField: "text", title: "Plan Type" },
    { field: "employeeContributionDollarAmount", title: "Employee Contribution Dollar Amount" },
    { field: "employeeContributionPercentage", title: "Employee Contribution Percentage" },
    { field: "employeeVoluntaryPercentage", title: "Employee Voluntary Percentage" },
    { field: "employerContributionDollarAmount", title: "Employer Contribution Dollar Amount" },
    { field: "employerContributionPercentage", subField: "text", title: "Employer Contribution Percentage" },
    { field: "comments", title: "Comments" },
  ];
  public bindingType: String = "array";
  public view: Observable<GridDataResult>;
  public gridData: any;
  public gridDataResult: GridDataResult;
  isLoading: boolean;
  pageSize: number = 5;
  skip: number = 0;

  constructor(
    private dialog: MatDialog,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private employmentRecordPensionsService: EmploymentRecordPensionsService
  ) {}

  ngOnInit(): void {
    this.view = this.employmentRecordPensionsService.getPensions(this.employeeId, this.employmentRecordId);
    this.getPensions();
  }

  getPensions(): void {
    this.isLoading = true;

    this.employmentRecordPensionsService.getPensions(this.employeeId, this.employmentRecordId, this.skip, String(this.pageSize))
    .pipe(
        finalize(()=>{ this.isLoading = false; })
    )
    .subscribe(
      res => {
        this.gridDataResult = {
          data: res.pensions,
          total: res.pagination.TotalCount,
        }
        this.gridData = res.employmentRecords;
      }
    )
  }

  openEmploymentRecordPensionDialog(employmentRecordPension?: EmploymentRecordPension) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      employmentRecordId: this.employmentRecordId,
      employeeId: this.employeeId,
      PensionId: employmentRecordPension ? employmentRecordPension.id : null
    };

    const dialogRef = this.dialog.open(EmploymentRecordPensionDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.getPensions();
        }
      }
    );
  }

  deleteEmploymentRecordPension(employmentRecordPensionIds: string[]) {
    const observables = employmentRecordPensionIds.map(selectedItem => defer(() => this.employmentRecordPensionsService.deletePension(this.employeeId, this.employmentRecordId, selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.getPensions();
        this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.getPensions();
  }

}

