import { Component, OnInit } from '@angular/core';
import { features } from '@app/consts';
import { FeatureService } from '@app/core/services/feature.service';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { TimeOffInLieuService } from '@app/core/services/time-off-in-lieu/time-off-in-lieu.service';
import { TranslationService } from '@app/core/services/translation.service';
import { ScheduleItem } from '@app/modules/generate-schedules/models/generate-schedules.model';
import { GenerateScheduleService } from '@app/modules/generate-schedules/services/generate-schedule.service';
import { Holiday } from '@app/modules/statutory-holidays/models/statutory-holidays.model';
import { StatutoryHolidaysService } from '@app/modules/statutory-holidays/services/statutory-holidays.service';
import { ScheduleAbsence } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-absences/models/employee-absence.model';
import { EmployeeAbsenceService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-absences/services/employee-absence.service';
import { TimeOffDetail } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-leave/models/leave-request.model';
import { EmployeeLeaveService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-leave/services/employee-leave.service';
import { SecurityProtectedBase } from '@app/shared/components/security-protected/security-protected';
import { EmployeeToilRequest } from '@app/shared/models/time-off-in-lieu/time-off-in-lieu.model';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DateChangeEvent, SchedulerEvent } from '@progress/kendo-angular-scheduler';
import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

const displayDate = moment().toDate();
const currentYear = new Date().getFullYear();

const randomInt = (min, max): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const parseAdjust = (eventDate: string): Date => {
  const date = new Date(eventDate.substring(0, 19));
  // date.setFullYear(currentYear);
  return date;
};

const parseAddOneday = (eventDate: string): Date => {
  const date = new Date(eventDate.substring(0, 19));
  // date.setFullYear(currentYear);
  date.setDate(date.getDate() + 1);
  return date;
};

const AddTimeToDate = (eventDate: string, eventTime: string): Date => {
  const date = new Date(eventDate.substring(0, 19));
  let hours = '00', minutes = '00', seconds = '00';

  if(eventTime !== null){
    [hours, minutes, seconds] = eventTime.split(':');
  }

  date.setHours(parseInt(hours, 10), parseInt(minutes, 10), parseInt(seconds, 10));

  return date;
};

@Component({
  selector: 'app-schedule-widget',
  templateUrl: './schedule-widget.component.html',
  styleUrls: ['./schedule-widget.component.scss']
})
export class ScheduleWidgetComponent extends SecurityProtectedBase  implements OnInit {
  public selectedDate: Date = displayDate;
  from: string = moment(this.selectedDate).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
  to: string = moment(this.selectedDate).add(1, 'months').endOf('month').format('YYYY-MM-DD');
  isLoading: boolean = true;
  sampleData: SchedulerEvent[] = [];
  schedule: ScheduleItem[] = [];
  absences: ScheduleAbsence[] = [];
  toilAbsences: EmployeeToilRequest[] = [];
  statutoryHolidays: Holiday[] = [];
  timeOffDetail: TimeOffDetail;
  user$: any;
  translationsLoaded: boolean;

  filterOptions = [
    { id: "Work", label: this.translate.instant('Scheduler.Work'), selected: true },
    { id: "Leave", label: this.translate.instant('Scheduler.TimeOff'), selected: true },
    { id: "StatutoryHoliday", label: this.translate.instant('Scheduler.StatutoryHolidays'), selected: true },
  ];

  constructor(
    private generateScheduleService: GenerateScheduleService,
    private employeeAbsenceService: EmployeeAbsenceService,
    private employeeLeaveService: EmployeeLeaveService,
    private statutoryHolidaysService: StatutoryHolidaysService,
    private translationService: TranslationService,
    private toilService: TimeOffInLieuService,
    public translate: TranslateService,
    private oidcAuthService: OidcAuthService,
    featureService: FeatureService
  ) {
    super(featureService, features.TOIL)
    this.user$ = this.oidcAuthService.userProfile;
  }

  ngOnInit(): void {
    // If translations are already loaded set the columns
    if(this.translationService.currentTranslations !== null){
      this.translationsLoaded = true;
      this.setFiltersWithTranslations();
      this.getTimeOffDetail();
    }

    // once the translations for the app have loaded set the columns to the correct translation values
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translationsLoaded = true;
      this.setFiltersWithTranslations();
      this.getTimeOffDetail();
    });
  }

  setFiltersWithTranslations() {
    this.filterOptions = [
      { id: "Work", label: this.translate.instant('Scheduler.Work'), selected: true },
      { id: "Leave", label: this.translate.instant('Scheduler.TimeOff'), selected: true },
      { id: "StatutoryHoliday", label: this.translate.instant('Scheduler.StatutoryHolidays'), selected: true },
    ];

    // If the user has access to TOIL add it to the list of schedule items
    if(this.hasFeatureAccess){
      this.filterOptions.push({ id: "Toil", label: this.translate.instant('Scheduler.Toil'), selected: true });
    }
  }

  filterChanged(item: any): void {
    item.selected = !item.selected;
    this.getScheduleItems();
  }

  dateChange(event: DateChangeEvent){
    if(!this.isLoading){
      this.selectedDate = event.selectedDate;
      this.from = moment(this.selectedDate).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      this.to = moment(this.selectedDate).add(1, 'months').endOf('month').format('YYYY-MM-DD');

      if(this.timeOffDetail) {
        this.getScheduleItems();
      } else {
        this.getTimeOffDetail();
      }
    }
  }

  getTimeOffDetail() {
    this.isLoading = true;

    this.employeeLeaveService.getTimeOffDetail(this.user$.userId)
    .pipe(
        finalize(() => {
            this.isLoading = false;
        })
    )
    .subscribe(
        res => {
            this.timeOffDetail = res;
            this.getScheduleItems();
        }
    );
  }

  getScheduleItems() {
    this.isLoading = true;

    let options = {};
    this.schedule = [];
    this.absences = [];
    this.toilAbsences = [];
    this.statutoryHolidays = [];

    if(this.filterOptions[0].selected) {
      options = Object.assign({}, options, { work: this.generateScheduleService.getCurrentUserSchedule(this.from, this.to) })
    }

    if(this.filterOptions[1].selected) {
      options = Object.assign({}, options, { absences: this.employeeAbsenceService.getCurrentEmployeesAbsences(this.from, this.to, '100', 0) })
    }
    
    // If statutory holidays hasnt been loaded yet add it to the network calls
    if(this.filterOptions[2].selected) {
      options = Object.assign({}, options, { statutoryHolidays: this.statutoryHolidaysService.getHolidayDays(0, '100', null, `StatutoryHolidayGroup.id = "${this.timeOffDetail?.statutoryHolidayGroup?.id}"`) })
    }
    
    if(this.hasFeatureAccess && this.filterOptions[3].selected) {
      options = Object.assign({}, options, { toil: this.toilService.getMyRequests(0, 100, `version.createdOn-desc`, `(RequestStatus.Id = "trs_Approved") AND (StartDate >= "${this.from}") AND (EndDate <= "${this.to}")`) })
    }



    forkJoin(
      options
    )
    .pipe(
      finalize( () => {
        this.isLoading = false;
      })
    )
    .subscribe(
      (res: {work, absences, toil, statutoryHolidays}) => {

        if(res.work) {
          this.schedule = res.work;
        }
        if(res.absences) {
          this.absences = res.absences?.data;
        }
        if(res.toil) {
          this.toilAbsences = res.toil?.data;
        }
        if(res.statutoryHolidays) {
          this.statutoryHolidays = res.statutoryHolidays?.data;
        }

        this.formatData();
      }
    );
  }

  formatData() {

    let schedule = this.schedule.map(dataItem => (
      <SchedulerEvent> {
          id: randomInt(1, 10),
          start: parseAdjust(dataItem.start),
          startTimezone: null,
          end: parseAdjust(dataItem.end),
          endTimezone: null,
          isAllDay: false,
          title: 'Work Day',
          description: 'Work',
          recurrenceRule: null,
          recurrenceId: null,
          recurrenceException: null,

          roomId: null,
          ownerID: null,
          type: 'Work',
      }
    ));

    let absences = this.absences.map(dataItem => (
      <SchedulerEvent> {
          id: randomInt(1, 10),
          start: parseAdjust(dataItem.startDate),
          startTimezone: null,
          end: parseAddOneday(dataItem.endDate),
          endTimezone: null,
          isAllDay: false,
          title: dataItem.timeOffType.name,
          description: 'Leave',
          recurrenceRule: null,
          recurrenceId: null,
          recurrenceException: null,

          roomId: null,
          ownerID: null,
          type: 'Leave',
      }
    ));

    let toilAbsences = this.toilAbsences.map(dataItem => (
      <SchedulerEvent> {
          id: randomInt(1, 10),
          start: dataItem.startTime ? AddTimeToDate(dataItem.startDate, dataItem.startTime) : parseAdjust(dataItem.startDate),
          startTimezone: null,
          end: dataItem.endTime ? AddTimeToDate(dataItem.endDate, dataItem.endTime) : parseAddOneday(dataItem.endDate),
          endTimezone: null,
          isAllDay: false,
          title: `${dataItem.toilPolicy?.toilType?.name} - ${dataItem.toilPolicy?.name}`,
          description: 'Toil',
          recurrenceRule: null,
          recurrenceId: null,
          recurrenceException: null,

          roomId: null,
          ownerID: null,
          type: 'Toil',
      }
    ));

    let statutoryHolidays = this.statutoryHolidays.map(dataItem => (
      <SchedulerEvent> {
          id: randomInt(1, 10),
          start: parseAdjust(dataItem.startDate),
          startTimezone: null,
          end: parseAdjust(dataItem.endDate),
          endTimezone: null,
          isAllDay: true,
          title: dataItem.name,
          description: 'StatutoryHoliday',
          recurrenceRule: null,
          recurrenceId: null,
          recurrenceException: null,

          roomId: null,
          ownerID: null,
          type: 'StatutoryHoliday',
      }
    ));

    this.sampleData = [...schedule, ...absences, ...toilAbsences, ...statutoryHolidays]
  }

}
