<div class="content employee-security-role-data-grid">
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
            <h4 appLocalizationPopupDirective localizationCode="EmployeeSecurity-SecurityRoles">{{ 'EmployeeSecurity-SecurityRoles' | translate: {Default: "Employee Security Roles"} }}</h4>

            <button  appLocalizationPopupDirective localizationCode="SecurityRoles-AddSecurityRole" mat-raised-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openAddSecurityRoleDialog()">
                <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon>
                {{ 'SecurityRoles-AddSecurityRole' | translate: {Default: "Add Security Role"} }}
            </button>
        </mat-card-title>
    
        <mat-card-content>
            <kendo-grid 
                [data]="employeeSecurityRoles" 
                [resizable]="true"
                [style.maxHeight.vh]="85" 
                [loading]="isLoading">

                <kendo-grid-column field="role" [width]="150">
                    <ng-template kendoGridHeaderTemplate>
                        <div appLocalizationPopupDirective localizationCode="SecurityRoles-Role">{{ 'SecurityRoles-Role' | translate: {Default: "Role"} }}</div>
                    </ng-template>

                    <ng-template kendoGridCellTemplate let-dataItem >
                        <div class="grid-cell-vertical navigation-link" routerLink="{{routes.SECURITY}}{{routes.ROLES}}{{routes.EDITOR}}/{{dataItem.role.id}}">
                            {{dataItem.role.name}}
                        </div>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="assignedOn" [width]="150">
                    <ng-template kendoGridHeaderTemplate>
                        <div appLocalizationPopupDirective localizationCode="SecurityRoles-AssignedOn">{{ 'SecurityRoles-AssignedOn' | translate: {Default: "Assigned On"} }}</div>
                    </ng-template>

                    <ng-template kendoGridCellTemplate let-dataItem >
                        <div class="grid-cell-vertical">
                            {{dataItem.createdOn | date:'medium'}}
                        </div>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="assignedBy" [width]="150">
                    <ng-template kendoGridHeaderTemplate>
                        <div appLocalizationPopupDirective localizationCode="SecurityRoles-AssignedBy">{{ 'SecurityRoles-AssignedBy' | translate: {Default: "Assigned By"} }}</div>
                    </ng-template>

                    <ng-template kendoGridCellTemplate let-dataItem >
                        <div class="employee-name navigation-link" routerLink="{{routes.TALENT_TRACK}}{{routes.EDIT}}/{{dataItem.createdBy.id}}">
                            <app-employee-image class="profile-pic" [employeeId]="dataItem.createdBy.id" [width]="50" [height]="50" [round]="true"></app-employee-image>
                            
                            <span class="name">
                                {{dataItem.createdBy.firstName}} {{dataItem.createdBy.lastName}}
                            </span>
                        </div>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column [width]="150">
                    <ng-template kendoGridCellTemplate let-dataItem >
                        <div class="grid-cell-vertical">
                            <mat-icon
                                setColor 
                                [setColorRules]="[
                                    {
                                        color: 'primaryColour',
                                        events: ['hover']
                                    }
                                ]"
                                appLocalizationPopupDirective localizationCode="SecurityRoles-RemoveSecurityRole"
                                class="material-icons-outlined clickable-icon"
                                appPreventDoubleClick (throttledClick)="openConfirmDeleteDialog(dataItem)"
                                [matTooltip]="translate.instant('SecurityRoles-RemoveSecurityRole')"
                                mat-list-icon>
                                clear
                            </mat-icon>
                        </div>
                    </ng-template>
                </kendo-grid-column>

            </kendo-grid>
        </mat-card-content>
    </mat-card>
</div>
