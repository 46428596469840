import { Component, Input, OnInit, TemplateRef, Type } from '@angular/core';
import { MyOverlayRef } from './myoverlay-ref';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit {
  contentType: 'template' | 'string' | 'component';
  content: string | TemplateRef<any> | Type<any>;
  context;

  constructor(private ref: MyOverlayRef) {}

  ngOnInit(): void {
    this.content = this.ref.content;
  }
}
