<mat-card class="employee-page-card">
  <mat-card-title class="header">
    <h5 appLocalizationPopupDirective localizationCode="Talent">{{ 'Talent' | translate: {Default: "Talent"} }}</h5>

    <div class="buttons-container">
      <mat-icon class="material-icons-outlined" 
        *ngIf="!cardView" 
        (click)="cardView = true"
        matTooltip="Card View"
      >grid_view</mat-icon>
      <mat-icon 
        class="material-icons-outlined" 
        *ngIf="cardView" 
        (click)="cardView = false"
        matTooltip="Table View"
      >table_rows</mat-icon>
    </div>
  </mat-card-title>

  <mat-card-content>
    <div class="card-view" *ngIf="cardView; else tableView">
      <div class="card-container" *ngIf="!isLoading; else loading">
          <app-employee-card 
            *ngFor="let employee of employees"
            [employee]="employee"
          ></app-employee-card>
      </div>
  
      <ng-template #loading>
        <div class="card-container">
          <app-employee-card *ngFor="let number of [0,1,2,3]"></app-employee-card>
        </div>
      </ng-template>
    </div>

    <ng-template #tableView>
        <app-skeleton-table *ngIf="isLoading"></app-skeleton-table>

        <app-table
          *ngIf="tableColumns && !isLoading"
          [tableData]="employees"
          [tableColumns]="tableColumns"
          [isPageable]="true"
          [paginationSizes]="[5,10,15,20]"
          [defaultPageSize]="10"
          [pagination]="pagination"
          (sort)="sortData($event)"
          [rowActionIcon]="['create']"
          (rowAction)="rowEvent($event)"
          (pageChangeAction)="pageChangeEvent($event)"
          [isFilterable]="true"
        ></app-table>
    </ng-template>
  </mat-card-content>
</mat-card>
