<mat-card>
    <mat-card-title>
        <h5 appLocalizationPopupDirective [localizationCode]="'RequestTimeOffInLieu'">{{ 'RequestTimeOffInLieu' | translate: {Default: "Request Time Off in Lieu"} }}</h5>
        <button mat-flat-button setColor="primaryColour" color="primary" (click)="openAccrualDialog()" appLocalizationPopupDirective [localizationCode]="'AccrueToil'">
            <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon>
            {{ 'AccrueToil' | translate: {Default: "Accrue TOIL"} }}
        </button>
    </mat-card-title>

    <mat-card-content>
        <kendo-grid
            [data]="gridDataResult"
            [height]="410"
            [loading]="isLoading"
        >
            <kendo-grid-column field="toilType" width="150px">
                <ng-template kendoGridHeaderTemplate>
                    <div appLocalizationPopupDirective [localizationCode]="'RequestTimeOffInLieu-ToilType'">
                        {{ 'RequestTimeOffInLieu-ToilType' | translate: {Default: "Toil Type"} }}
                    </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        {{dataItem.toilPolicy?.toilType?.name}}
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="toilPolicy" width="150px">
                <ng-template kendoGridHeaderTemplate>
                    <div appLocalizationPopupDirective [localizationCode]="'RequestTimeOffInLieu-ToilPolicy'">
                        {{ 'RequestTimeOffInLieu-ToilPolicy' | translate: {Default: "Toil Policy"} }}
                    </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        {{dataItem.toilPolicy?.name}}
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="availableHours" title="Available Hours" width="200px">
                <ng-template kendoGridHeaderTemplate>
                    <div appLocalizationPopupDirective [localizationCode]="'RequestTimeOffInLieu-AvailableHours'">
                        {{ 'RequestTimeOffInLieu-AvailableHours' | translate: {Default: "Available Hours"} }}
                    </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        {{dataItem.availableHours  | number:'1.0-1'}}
                    </div>
                </ng-template>
            </kendo-grid-column>

            <!-- <kendo-grid-column field="futureHoursScheduled" title="Future Hours Scheduled" width="250px">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        {{dataItem.futureHoursScheduled  | number:'1.0-1'}}
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="hoursTaken" title="Hours Taken" width="150px">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        {{dataItem.hoursTaken  | number:'1.0-1'}}
                    </div>
                </ng-template>
            </kendo-grid-column> -->

            <kendo-grid-column field="actionButtons" title="" width="170px">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        <button mat-stroked-button setColor="primaryColour" color="primary"  appLocalizationPopupDirective [localizationCode]="'timeOffLeaveTypeRequestToil'" appPreventDoubleClick (throttledClick)="openRequestDialog(dataItem)">
                            {{ 'timeOffLeaveTypeRequestToil' | translate: {Default: "Request TOIL"} }}
                        </button>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <ng-template kendoGridNoRecordsTemplate>
                <div appLocalizationPopupDirective [localizationCode]="'NoPoliciesAssigned'">
                    {{ 'NoPoliciesAssigned' | translate: {Default: "No Policies Assigned"} }}
                </div>
             </ng-template>

             <kendo-grid-messages 
                [noRecords]="isLoading ? '' : translate.instant('Grid-NoRecordsAvailable')"
                [pagerItemsPerPage]="translate.instant('Grid-Pager-ItemsPerPage')" 
                [pagerItems]="translate.instant('Grid-Pager-Items')"
                [pagerOf]="translate.instant('Grid-Pager-Of')"
            ></kendo-grid-messages>
        </kendo-grid>
    </mat-card-content>
</mat-card>
