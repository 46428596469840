<div *ngIf="employeeImage$ | withLoading | async as employeeImage">
    <ng-template [ngIf]="employeeImage.value">
        <img class="profiler" [ngClass]="{'round': round }" [src]="employeeImage.value" [ngStyle]="{'width.px': width}">
    </ng-template>
    <ng-template [ngIf]="employeeImage.error">
        <img class="profiler" [src]="miscellaneous.STOCK_EMPLOYEE_IMAGE" [ngClass]="{'round': round }" [ngStyle]="{'width.px': width}">
    </ng-template>
    <ng-template [ngIf]="employeeImage.loading">
        <ngx-skeleton-loader
                class="profiler"
            count="1" 
            appearance="circle"
            [theme]="{ 
                'height.px': height,
                'width.px': width
            }"
        >
        </ngx-skeleton-loader>
    </ng-template>
</div>
