import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-employee-toil-history',
  templateUrl: './employee-toil-history.component.html',
  styleUrls: ['./employee-toil-history.component.scss']
})
export class EmployeeToilHistoryComponent implements OnInit {
  employeeId: string;

  constructor(
    private route: ActivatedRoute,
  ) {
    this.route.paramMap.subscribe(
      params => this.employeeId = params.get("id")
    )
  }

  ngOnInit(): void {
  }
}
