<ng-container>

    <!-- Filter -->
    <ng-container *ngIf="isFilterable" [matColumnDef]="this.rowActionIcon">
      <mat-form-field class="filterField" appearance="legacy">
        <mat-label>
          <mat-icon class="material-icons-outlined">search</mat-icon>
          Filter
        </mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="filter">
      </mat-form-field>
    </ng-container>
  
    <!-- Table -->
    <div class="table-container">
      <table mat-table class="table" [dataSource]="tableDataSource" matSort (matSortChange)="sortTable($event)">
    
        <!-- action column -->
        <ng-container *ngIf="rowActionIcon?.length" [matColumnDef]="btnsColumn"  class="table-row">
          <th mat-header-cell *matHeaderCellDef class="table-row-title"></th>
          <td class="btns-column table-content" mat-cell *matCellDef="let element" [id]="btnsColumn">
            <!-- <mat-icon *ngFor="let icon of rowActionIcon" (click)="emitRowAction(element, icon)">{{icon}}</mat-icon> -->
            <span class="material-icons-outlined" 
                  *ngFor="let icon of rowActionIcon" 
                  (click)="emitRowAction(element, icon)">
              {{icon}}
            </span>
          </td>
        </ng-container>
  
        <ng-container *ngFor="let tableColumn of tableColumns" [matColumnDef]="tableColumn.name" class="table-row">
    
          <!-- if sortable column header -->
          <ng-container *ngIf="tableColumn.isSortable; else notSortable">
            <th class="table-row-title" mat-header-cell *matHeaderCellDef [mat-sort-header]="tableColumn.name"
                [arrowPosition]="tableColumn.position === 'right' ? 'before' : 'after'">
              {{tableColumn.name}}
            </th>
          </ng-container>
          <!-- else not sortable -->
          <ng-template #notSortable>
            <th class="table-row-title" mat-header-cell *matHeaderCellDef [class.text-right]="tableColumn.position == 'right'">
              {{tableColumn.name}}
            </th>
          </ng-template>
    
          <!-- column data -->
          <td class="table-content" mat-cell *matCellDef="let element" [class.text-right]="tableColumn.position == 'right'">
            {{element | dataPropertyGetter: tableColumn.dataKey}}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  
    <!-- Pagination -->
    <mat-paginator *ngIf="isPageable && pagination"
                   [length]="pagination.TotalCount"
                   [pageSizeOptions]="paginationSizes"
                   [pageSize]="defaultPageSize"
                   [pageIndex]="pagination.CurrentPage-1"
                   (page)="emitChangePage($event)"
                   showFirstLastButtons>
    </mat-paginator>
  </ng-container>
