import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {SnackbarService} from '@app/core/services/snackbar.service';
import {OverlayService} from '@app/shared/components/overlay/overlay.service';
import * as moment from 'moment';
import {finalize} from 'rxjs/operators';
import {api_routes, db_tables} from '@app/consts';
import {Observable} from 'rxjs';
import {EmployeeCompensation} from './models/employee-compensation.model';
import {EmployeeCompensationService} from './services/employee-compensation.service';
import {SettingsService} from '@app/core/services/settings.service';
import {Currency} from '@app/modules/lookup/models/currency.model';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {AccessLogsDialogComponent} from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/access-logs-dialog/access-logs-dialog.component';
import {HistoryDialogComponent} from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/history-dialog/history-dialog.component';
import { ChangeReasonDialogComponent } from '@app/shared/components/change-reason-dialog/change-reason-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { FormGeneratorComponent } from '@app/shared/components/form-generator/form-generator.component';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';

@Component({
    selector: 'app-employee-compensation',
    templateUrl: './employee-compensation.component.html',
    styleUrls: ['./employee-compensation.component.scss']
})
export class EmployeeCompensationComponent implements OnInit {
    @ViewChild(FormGeneratorComponent) formGeneratorComponent: FormGeneratorComponent
    employeeID: string;
    form: UntypedFormGroup;
    compensation: EmployeeCompensation;
    isLoading: boolean = true;
    formData: any;
    formId: string = 'frm_jVYSMy8oJaI6hf';
    changeReasonFormId: string = 'frm_L1Hjaj1ct6E9LJ';
    getFormData: boolean = false;
    formValid: boolean = false;
    targetEmployeeTablePermissions: EmployeeTableSecurity[];
    isLoadingPermissions: boolean = true;
    
    salaryOther: number;
    salaryNonGmg: number;
    salarySystem: number;
    salarySystemCurrency: string;
    salaryOtherCurrency: string;
    salaryNonGmgCurrency: string;
    currencyOptions: Observable<Currency[]>;

    today = new Date();

    public columns: any[] = [
        {field: 'baseSalary', title: 'Base Salary'},
        {field: 'baseSalaryFteAdjusted', title: 'Base Salary Fte Adjusted', tableId: "tfi_CompensationBaseSalaryFteAdjusted"},
        {field: 'payRateCurrency', subField: 'id', title: 'pay Rate Currency', tableId: "tfi_CompensationPayRateCurrency"},
        {field: 'payRate', title: 'Pay Rate', tableId: "tfi_CompensationPayRate"},
        {field: 'payRateInterval', subField: 'text', title: 'pay Rate Interval', tableId: "tfi_CompensationPayRateInterval"},
        {field: 'hoursPerWeek', title: 'hours Per Week', tableId: "tfi_CompensationHoursPerWeek"},
        {field: 'salaryOtherCurrency', subField: 'id', title: 'salary Other Currency', tableId: "tfi_CompensationSalaryOtherCurrency"},
        {field: 'salaryNonGmgCurrency', subField: 'id', title: 'salary NonGmg Currency', tableId: "tfi_CompensationSalaryNonGmgCurrency"},
        {field: 'salarySystemCurrency', subField: 'id', title: 'salary System Currency', tableId: "tfi_CompensationSalarySystemCurrency"},
        {field: 'stiIncentivePlan', subField: 'text', title: 'sti Incentive Plan', tableId: "tfi_CompensationStiIncentivePlan"},
        {field: 'stiActualAmount', title: 'sti Actual Amount', tableId: "tfi_CompensationStiActualAmount"},
        {field: 'stiActualPercentage', title: 'sti Actual Percentage', tableId: "tfi_CompensationStiActualPercentage"},
        {field: 'stiTargetAmount', title: 'sti Target Amount', tableId: "tfi_CompensationStiTargetAmount"},
        {field: 'stiTargetPercentage', title: 'sti Target Percentage', tableId: "tfi_CompensationStiTargetPercentage"},
        {field: 'stiCurrency', subField: 'id', title: 'sti Currency', tableId: "tfi_CompensationStiCurrency"},
        {field: 'ltiPsu', subField: 'text', title: 'lti Psu', tableId: "tfi_CompensationLtiPsu"},
        {field: 'ltiStockOption', subField: 'text', title: 'lti Stock Option', tableId: "tfi_CompensationLtiStockOption"},
        {field: 'ltiRsu', subField: 'text', title: 'lti Rsu', tableId: "tfi_CompensationltiRsu"},
        {field: 'compensationRatio', title: 'compensation Ratio', tableId: "tfi_CompensationRatio"},
        {field: 'comments', title: 'comments', tableId: "tfi_CompensationComments"},
        {field: 'term', subField: 'text', title: 'term', tableId: "tfi_CompensationTerm"},
        {field: 'marketView', subField: 'text', title: 'market View', tableId: "tfi_CompensationMarketView"},
        {field: 'payType', subField: 'text', title: 'pay Type', tableId: "tfi_CompensationPayType"},
        {field: 'payGrade', subField: 'text', title: 'pay Grade', tableId: "tfi_CompensationPayGrade"},
        {field: 'roleGroup', subField: 'text', title: 'role Group', tableId: "tfi_CompensationRoleGroup"},
        {field: 'roleCategory', subField: 'text', title: 'role Category', tableId: "tfi_CompensationRoleCategory"},
        {field: 'payScale', subField: 'text', title: 'pay Scale', tableId: "tfi_CompensationPayScale"},
        {field: 'payLevel', subField: 'text', title: 'pay Level', tableId: "tfi_CompensationPayLevel"},
        {field: 'payFrequency', subField: 'text', title: 'pay Frequency', tableId: "tfi_CompensationPayFrequency"},
        {field: 'overtimeEligible', subField: 'text', title: 'overtime Eligible', tableId: "tfi_CompensationOvertimeEligible"},
        {field: 'country', subField: 'name', title: 'country', tableId: "tfi_CompensationCountry"},
        {field: 'payrollId', subField: 'text', title: 'payroll Id', tableId: "tfi_PayrollId"},

        {field: 'compensationUdtf1', title: 'Compensation Udtf 1', tableId: "tfi_CompensationUdtf1"},
        {field: 'compensationUdtf2', title: 'Compensation Udtf 2', tableId: "tfi_CompensationUdtf2"},
        {field: 'compensationUddf1', title: 'Compensation Uddf 1', type: 'date', tableId: "tfi_CompensationUddf1"},
        {field: 'compensationUddf2', title: 'Compensation Uddf 2', type: 'date', tableId: "tfi_CompensationUddf2"},
        {field: 'compensationUdf1', subField: 'text', title: 'Compensation Udf 1', type: "tfi_CompensationUdf1"},
        {field: 'compensationUdf2', subField: 'text', title: 'Compensation Udf 2', tableId: "tfi_CompensationUdf2"},
    ];
    calculatedFTE: number;
    salaryFullFte: number;
    baseSalary: number;
    baseSalaryFteAdjusted: number;
    salaryNonGmgFteAdjusted: number;
    salarySystemFteAdjusted: number;
    salaryOtherFteAdjusted: number;
    payRateCurrency: string;

    constructor(
        private dialog: MatDialog,
        private overlayService: OverlayService,
        private snackbarService: SnackbarService,
        private fb: UntypedFormBuilder,
        private employeeCompensationService: EmployeeCompensationService,
        private tablePermissionsService: TablePermissionsService,
        private route: ActivatedRoute,
        private settingsService: SettingsService,
    ) {
        this.route.paramMap.subscribe(
            params => this.employeeID = params.get("id")
        )
    }

    ngOnInit(): void {
        this.currencyOptions = this.settingsService.getCurrencies();
        this.getEmployeeCompensation();
        this.getTargetEmployeeTablePermissions();
    }

    get tableReadAccessObj() {
        return {
            tableId: db_tables.Compensation,
            permission: 'read',
            targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        }
    }

    get tableCreateAccessObj() {
        return {
            tableId: db_tables.Compensation,
            permission: 'create',
            targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        }
    }

    get tableUpdateAccessObj() {
        return {
            tableId: db_tables.Compensation,
            permission: 'update',
            targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        }
    }

    get tableDeleteAccessObj() {
        return {
            tableId: db_tables.Compensation,
            permission: 'delete',
            targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        }
    }

    getEmployeeCompensation() {
        this.isLoading = true;

        let asOf = moment(this.today).format('YYYY-MM-DD');
        this.employeeCompensationService.getCompensation(this.employeeID, asOf)
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                    this.createForm();
                })
            )
            .subscribe(
                res => {
                    this.compensation = res;
                }
            );
    }

    createForm() {
        this.compensation ? this.calculatedFTE = this.compensation.fte : null;

        this.compensation ? this.salaryNonGmg = this.compensation.salaryNonGmg : null;
        this.compensation ? this.salaryNonGmgFteAdjusted = this.compensation.salaryNonGmgFteAdjusted : null;
        this.compensation ? this.salaryNonGmgCurrency = this.compensation.salaryNonGmgCurrency?.id : null;

        this.compensation ? this.baseSalary = this.compensation.baseSalary : null;
        this.compensation ? this.baseSalaryFteAdjusted = this.compensation.baseSalaryFteAdjusted : null;
        this.compensation ? this.payRateCurrency = this.compensation.payRateCurrency?.id : null;

        this.compensation ? this.salaryOther = this.compensation.salaryOther : null;
        this.compensation ? this.salaryOtherFteAdjusted = this.compensation.salaryOtherFteAdjusted : null;
        this.compensation ? this.salaryOtherCurrency = this.compensation.salaryOtherCurrency?.id : null;

        this.compensation ? this.salarySystem = this.compensation.salarySystem : null;
        this.compensation ? this.salarySystemFteAdjusted = this.compensation.salarySystemFteAdjusted : null;
        this.compensation ? this.salarySystemCurrency = this.compensation.salarySystemCurrency?.id : null;

        this.formData = {
            id: '',
            asOf: moment(this.today).format('YYYY-MM-DD'),
            changeReason: '',
            changeReasonComments: '',

            payRate: this.compensation ? this.compensation.payRate : null,
            payRateCurrency: this.compensation ? this.compensation.payRateCurrency?.id : null,
            payRateInterval: this.compensation ? this.compensation.payRateInterval?.id : null,
            hoursPerWeek: this.compensation ? this.compensation.hoursPerWeek : null,
            fte: this.compensation ? this.compensation.fte : null,
            adjustedFte: this.compensation ? this.compensation.adjustedFte : null,
            country: this.compensation ? this.compensation.country?.id : null,

            salaryOtherCurrency: this.compensation ? this.compensation.salaryOtherCurrency?.id : null,
            salaryNonGmgCurrency: this.compensation ? this.compensation.salaryNonGmgCurrency?.id : null,
            salarySystemCurrency: this.compensation ? this.compensation.salarySystemCurrency?.id : null,

            stiIncentivePlan: this.compensation ? this.compensation.stiIncentivePlan?.id : null,
            stiActualAmount: this.compensation ? this.compensation.stiActualAmount : null,
            stiActualPercentage: this.compensation ? this.compensation.stiActualPercentage : null,
            stiTargetAmount: this.compensation ? this.compensation.stiTargetAmount : null,
            stiTargetPercentage: this.compensation ? this.compensation.stiTargetPercentage : null,
            stiCurrency: this.compensation ? this.compensation.stiCurrency?.id : null,
            ltiPsu: this.compensation ? this.compensation.ltiPsu?.id : null,
            ltiStockOption: this.compensation ? this.compensation.ltiStockOption?.id : null,
            ltiRsu: this.compensation ? this.compensation.ltiRsu?.id : null,

            compensationRatio: this.compensation ? this.compensation.compensationRatio : null,
            comments: this.compensation ? this.compensation.comments : null,
            term: this.compensation ? this.compensation.term?.id : null,
            marketView: this.compensation ? this.compensation.marketView?.id : null,
            payType: this.compensation ? this.compensation.payType?.id : null,
            payGrade: this.compensation ? this.compensation.payGrade?.id : null,
            roleGroup: this.compensation ? this.compensation.roleGroup?.id : null,
            roleCategory: this.compensation ? this.compensation.roleCategory?.id : null,
            payScale: this.compensation ? this.compensation.payScale?.id : null,
            payLevel: this.compensation ? this.compensation.payLevel?.id : null,
            payFrequency: this.compensation ? this.compensation.payFrequency?.id : null,
            overtimeEligible: this.compensation ? this.compensation.overtimeEligible?.id : null,
            payrollId: this.compensation ? this.compensation.payrollId?.id : null,
            sterlineEquivalent: this.compensation ? this.compensation.sterlineEquivalent : null,

            compensationUdtf1: this.compensation ? this.compensation.compensationUdtf1 : null,
            compensationUdtf2: this.compensation ? this.compensation.compensationUdtf2 : null,
            compensationUddf1: this.compensation ? this.compensation.compensationUddf1 : null,
            compensationUddf2: this.compensation ? this.compensation.compensationUddf2 : null,
            compensationUdf1: this.compensation ? this.compensation.compensationUdf1?.id : null,
            compensationUdf2: this.compensation ? this.compensation.compensationUdf2?.id : null,
        };
    }

    getTargetEmployeeTablePermissions() {
        this.isLoadingPermissions = true;
    
        let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeID);
    
        // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
        if(currentFetchedData !== null){
          this.targetEmployeeTablePermissions = currentFetchedData;
          this.isLoadingPermissions = false;
        }
        else {
          this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeID)
          .pipe(
            finalize(() => {
                this.isLoadingPermissions = false;
            })
          )
          .subscribe(
            res => {
              this.targetEmployeeTablePermissions = res;
            }
          );
        }
    }

    formDataEmitted(formData) {
        this.openChangeReasonDialog(formData);
    }

    formStatusUpdated(formValid) {
        this.formValid = formValid;
    }

    openChangeReasonDialog(formData: any) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
          formId: this.changeReasonFormId,
        };

        const dialogRef = this.dialog.open(ChangeReasonDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
          data => {
            if (data) {
              this.addChangeReasonsToFormDataAndEmit(formData, data);
            }
          }
        );
    }

    addChangeReasonsToFormDataAndEmit(formData: any, changeReasonFormData: any) {
        let merged = {...formData, ...changeReasonFormData};
        this.save(merged);
    }

    save(formData: any) {
        this.overlayService.show();

        formData.originalCreatedOn = this.compensation?.version?.createdOn ? this.compensation?.version?.createdOn : null;
        formData.asOf = moment(this.today).format('YYYY-MM-DD');
        this.employeeCompensationService.updateCompensation(this.employeeID, formData)
            .pipe(
                finalize(() => {
                    this.overlayService.hide();
                })
            )
            .subscribe(
                (res) => {
                    this.snackbarService.openSnackBar('Compensation saved successfully', 'clear', 'success');
                    this.getEmployeeCompensation();
                }
            );
    }

    openAccessLogsDialog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            employeeId: this.employeeID,
            entityId: this.employeeID,
            url: `${api_routes.TALENT}/${this.employeeID}/${api_routes.COMPENSATION}/${this.employeeID}`,
        };

        const dialogRef = this.dialog.open(AccessLogsDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
            }
        );
    }

    openHistoryDialog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            employeeId: this.employeeID,
            entityId: null,
            url: `${api_routes.TALENT}/${this.employeeID}/${api_routes.COMPENSATION}`,
            columns: this.columns,
            tableId: db_tables.Compensation
        };

        const dialogRef = this.dialog.open(HistoryDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
            }
        );
    }

    effectiveDateChange(event) {
        this.getEmployeeCompensation();
    }
}
