import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { EmployeeLeaveService } from './services/employee-leave.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LeaveRequestDialogComponent } from './components/leave-request-dialog/leave-request-dialog.component';
import { EmployeeTimeOffType } from './models/leave-request.model';
import { AssignEmployeePolicyDialogComponent } from './components/assign-employee-policy-dialog/assign-employee-policy-dialog.component';
import { db_tables } from '@app/consts';
import { ActivatedRoute } from '@angular/router';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-employee-leave',
  templateUrl: './employee-leave.component.html',
  styleUrls: ['./employee-leave.component.scss']
})
export class EmployeeLeaveComponent implements OnInit {
  employeeId: string;
  timeOffLeaveTypes$: Observable<EmployeeTimeOffType[]>;
  isLoadingPermissions: boolean = true;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];

  constructor(
    private employeeLeaveService: EmployeeLeaveService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private tablePermissionsService: TablePermissionsService,
    private snackbarService: SnackbarService
  ) {

    this.route.paramMap.subscribe(
      params => this.employeeId = params.get("id")
    )
  }

  ngOnInit(): void {
    this.getTimeOffTypes();
    this.getTargetEmployeeTablePermissions();
  }

  getTargetEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeId);

    // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
    if(currentFetchedData !== null){
      this.targetEmployeeTablePermissions = currentFetchedData;
      this.isLoadingPermissions = false;
    }
    else {
      this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeId)
      .pipe(
        finalize(() => {
            this.isLoadingPermissions = false;
        })
      )
      .subscribe(
        res => {
          this.targetEmployeeTablePermissions = res;
        }
      );
    }
  }

  get tableReadAccessObj() {
    return {
      tableId: db_tables.EmployeeTimeOffPolicies,
      permission: 'read',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableCreateAccessObj() {
    return {
      tableId: db_tables.EmployeeTimeOffPolicies,
      permission: 'create',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableUpdateAccessObj() {
    return {
      tableId: db_tables.EmployeeTimeOffPolicies,
      permission: 'update',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableDeleteAccessObj() {
    return {
      tableId: db_tables.EmployeeTimeOffPolicies,
      permission: 'delete',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  getTimeOffTypes() {
    this.timeOffLeaveTypes$ = this.employeeLeaveService.getEmployeeTimeOffTypes(this.employeeId)
  }

  openAssignPolicyDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      employeeId: this.employeeId
    };

    const dialogRef = this.dialog.open(AssignEmployeePolicyDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.getTimeOffTypes();
        }
      }
    );
  }
}
