<h5 mat-dialog-title>Leave Request</h5>

<mat-dialog-content>
    <form class="form-container" *ngIf="form" [formGroup]="form">
        <mat-form-field appearance="fill">
            <mat-label>
                Status
            </mat-label>

            <input matInput
                type="text"
                disabled="true"
                formControlName="status">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Leave Type
            </mat-label>

            <mat-select disableOptionCentering formControlName="leaveType">
                <mat-option *ngFor="let leaveTypeOption of leaveTypeOptions" [value]="leaveTypeOption">{{leaveTypeOption}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field  appearance="fill">
            <mat-label>
                Start Date
            </mat-label>
        
            <input matInput [matDatepicker]="startDatepicker" formControlName="startDate">
            <span fxLayout="row" matSuffix>
                <mat-datepicker-toggle  [for]="startDatepicker"></mat-datepicker-toggle>
            </span>
            <mat-datepicker #startDatepicker></mat-datepicker> 
            <mat-hint></mat-hint>
        </mat-form-field>

        <mat-form-field  appearance="fill">
            <mat-label>
                End Date
            </mat-label>
        
            <input matInput [matDatepicker]="endDatepicker" formControlName="endDate">
            <span fxLayout="row" matSuffix>
                <mat-datepicker-toggle  [for]="endDatepicker"></mat-datepicker-toggle>
            </span>
            <mat-datepicker #endDatepicker></mat-datepicker> 
            <mat-hint></mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Calendar Days
            </mat-label>

            <input matInput
                type="number"
                formControlName="calendarDays">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Work Days
            </mat-label>

            <input matInput
                type="number"
                formControlName="workDays">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Manager
            </mat-label>

            <input matInput
                type="text"
                formControlName="manager">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Notice
            </mat-label>

            <textarea matInput formControlName="notice"></textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button> 
</mat-dialog-actions>