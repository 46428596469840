<div>
    <div class="title">
        <h3>
            {{data.title}}
        </h3>
    </div>
    <div class="text">
        <p>
            {{data.text}}
        </p>
    </div>
</div>
<div class="form-container">
    <div class="form-title">
        <h5>{{data.formTitle}}</h5>
    </div>
    <app-dynamic-form 
      [hideTitle]="true"
      [hideButtons]="true"
      [canViewHistory]="false"
      [data]="" 
      [submittingForm]="false"
      [emptyForm]="true"
      [formId]="data.formId">
    </app-dynamic-form>
</div>