import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {OverlayService} from '@app/shared/components/overlay/overlay.service';
import {GridDataResult, PageChangeEvent} from '@progress/kendo-angular-grid';
import {SecurityRoleService} from '@app/modules/security-roles/services/security-role.service';
import {OrganizationStructureService} from '@app/modules/organization-structure/services/organization-structure.service';
import {ActivatedRoute} from '@angular/router';
import {FormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Culture} from '@app/shared/models/system-language/culture.model';
import {CultureService} from '@app/core/services/system-language/culture.service';
import {CopySecurityRole, SecurityRoleSubmit, SecurityRoleVerbose} from '@app/modules/security-setup/models/security-role.model';
import {finalize} from 'rxjs/operators';
import {SnackbarService} from '@app/core/services/snackbar.service';

@Component({
    selector: 'copy-role-dialog',
    templateUrl: './copy-role-dialog.component.html',
    styleUrls: ['./copy-role-dialog.component.scss']
})
export class CopyRoleDialogComponent implements OnInit {
    public isLoading: boolean;
    public form: UntypedFormGroup;
    private roleId: string;

    constructor(
        private overlayService: OverlayService,
        private fb: UntypedFormBuilder,
        private snackbarService: SnackbarService,
        private securityRoleService: SecurityRoleService,
        private dialogRef: MatDialogRef<CopyRoleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.roleId = data.roleId
    }

    ngOnInit(): void {
        this.createForm();
    }

    createForm() {
        this.form = this.fb.group({
            name: [null, Validators.required],
        });
    }

    close() {
        this.dialogRef.close();
    }

    copySecurityRole() {
        let copySecurityRole: CopySecurityRole = {
            name: this.form.value.name
        };

        this.overlayService.show();

        this.securityRoleService.copySecurityRole(this.roleId, copySecurityRole)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe(
                (res) => {
                    this.snackbarService.openSnackBar('Security Role copied successfully', 'clear', 'success');
                    this.dialogRef.close(res.roleId)
                },
                err => {
                    this.snackbarService.openSnackBar(err, 'clear', 'warn');
                }
            );
    }
}
