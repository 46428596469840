import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { defer, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ProfessionalExpertise, ProfessionalExpertiseVerbose } from './models/professional-expertise.model';
import { EmployeeProfessionalExpertiseService } from './services/employee-professional-expertise.service';
import { api_routes, db_tables } from '@app/consts';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';

@Component({
  selector: 'app-employee-professional-expertises',
  templateUrl: './employee-professional-expertises.component.html',
  styleUrls: ['./employee-professional-expertises.component.scss']
})
export class EmployeeProfessionalExpertisesComponent implements OnInit {
  @Input() formIds: string[];
  @Input() employeeID: string;

  public columns: any[] = [
    { field: "areaOfExpertise", subField: "text", title: "area Of Expertise", type: "navigation"},
    { field: "proficiencyLevel", subField: "text", title: "proficiency Level"},
    { field: "institution", title: "institution"},
    { field: "comments", title: "comments"},
  ];
  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 5;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  dialogRef: MatDialogRef<FormGeneratorDialogComponent>;
  formId: string = 'frm_e1Q9MR0lVqvY02'
  changeReasonFormId: string = 'frm_vxHOZIduNXgsmS';
  formValid: boolean;
  private sortString: string = '';
  isLoadingPermissions: boolean = true;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];
  accessAndHistoryURL: string = `${api_routes.TALENT}/[employeeId]/${api_routes.PROFESSIONAL_EXPERTISES}/[entityId]`;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private employeeProfessionalExpertiseService: EmployeeProfessionalExpertiseService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private tablePermissionsService: TablePermissionsService,
    private route: ActivatedRoute,
  ) {
    this.route.paramMap.subscribe(
        params => this.employeeID = params.get("id")
    )
  }

  ngOnInit(): void {
    this.getTargetEmployeeTablePermissions();
    this.view = this.getProfessionalExpertises();
  }

  getTargetEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeID);

    // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
    if(currentFetchedData !== null){
      this.targetEmployeeTablePermissions = currentFetchedData;
      this.isLoadingPermissions = false;
    }
    else {
      this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeID)
      .pipe(
        finalize(() => {
            this.isLoadingPermissions = false;
        })
      )
      .subscribe(
        res => {
          this.targetEmployeeTablePermissions = res;
        }
      );
    }
  }

  getProfessionalExpertises() {
    this.isLoading = true;

    this.employeeProfessionalExpertiseService.getProfessionalExpertises(this.employeeID, String(this.pageSize), this.skip, this.sortString)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          }
          this.gridData = res.data;
        }
      );
  }

  getProfessionalExpertise(professionalExpertise?: ProfessionalExpertise) {
    if(professionalExpertise === undefined){
      this.openFormDialog();
    }
    else {
      this.isLoading = true;

      this.employeeProfessionalExpertiseService.getProfessionalExpertise(this.employeeID, professionalExpertise.id)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        res => {
          this.openFormDialog(res);
        }
      );
    }
  }

  openFormDialog(professionalExpertise?: ProfessionalExpertiseVerbose) {
    let formData = {
      id: professionalExpertise ? professionalExpertise.id : null,
      asOf: moment().format(),
      changeReason: '',
      changeReasonComments: '',
      institution: professionalExpertise ? professionalExpertise.institution : null,
      proficiencyLevel: professionalExpertise ? professionalExpertise.proficiencyLevel?.id : null,
      areaOfExpertise: professionalExpertise ? professionalExpertise.areaOfExpertise?.id : null,
      comments: professionalExpertise ? professionalExpertise.comments : null,
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formTitle: `${this.translate.instant('ProfessionalExpertise')}`,
      formId: this.formId,
      changeReasonFormId: this.changeReasonFormId,
      formData: formData
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      this.save(event)
    });
  }

  formDataEmitted(formData) {
    this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  save(formData: any){
    this.overlayService.show();

    if(!formData.id){
      this.employeeProfessionalExpertiseService.createProfessionalExpertise(this.employeeID, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getProfessionalExpertises();
        }
      );
    }
    else {
      this.employeeProfessionalExpertiseService.updateProfessionalExpertise(this.employeeID, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getProfessionalExpertises();
        }
      );
    }
  }

  deleteProfessionalExpertises(professionalExpertiseIds: string[]) {
    const observables = professionalExpertiseIds.map(selectedItem => defer(() => this.employeeProfessionalExpertiseService.deleteProfessionalExpertise(this.employeeID, selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.view = this.getProfessionalExpertises();
        this.clearSelectedItems = !this.clearSelectedItems;
          this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.view = this.getProfessionalExpertises();
  }

}




