import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TimeOffInLieuService } from '@app/core/services/time-off-in-lieu/time-off-in-lieu.service';
import { ToilListVariant } from '@app/modules/lookup/models/toil-list-variant.model';
import { ToilPolicy } from '@app/modules/lookup/models/toil-policy.model';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { defer, finalize, forkJoin } from 'rxjs';

@Component({
  selector: 'app-toil-policies-data-grid',
  templateUrl: './toil-policies-data-grid.component.html',
  styleUrls: ['./toil-policies-data-grid.component.scss']
})
export class ToilPoliciesDataGridComponent implements OnInit {
  @Input() toilType: ToilListVariant;
  @Output() emitGoToToilPolicy: EventEmitter<any> = new EventEmitter();

  public columns: any[] = [
    {field: 'name', title: this.translate.instant('ToilPolicy-Name'), dataType: "String"},
    {field: 'maximumAccrualHours', title: this.translate.instant('ToilPolicy-MaximumAccrualHours'), dataType: "String"},
    {field: 'expiryPeriodLength', title: this.translate.instant('ToilPolicy-ExpiryPeriodLength'), dataType: "String"},
    {field: 'expiryPeriodUnit', subField: 'name', title: this.translate.instant('ToilPolicy-ExpiryPeriodUnit'), dataType: "String"},
    {field: 'expiryPoint', subField: 'name', title: this.translate.instant('ToilPolicy-ExpiryPoint'), dataType: "String"},
    {field: 'allowNegativeHours', title: this.translate.instant('ToilPolicy-AllowNegativeHours'), type: "booleanToggle"},
    {field: 'createdOn', title: this.translate.instant('ToilPolicy-CreatedOn'), type: "dateTime"},
    {field: 'createdBy', title: this.translate.instant('ToilPolicy-CreatedBy'), type: "employee"},
  ];
  public filterCategories: any[] = null;
  public sortableColumns: any[] = [
    { field: "name", sortValue: "name"},
    { field: "expiryPeriodLength", sortValue: "expiryPeriodLength"},
    { field: "expiryPeriodUnit", sortValue: "ExpiryPeriodUnit.Name"},
    { field: "expiryPoint", sortValue: "ExpiryPoint.Name"},
    { field: "maximumAccrualHours", sortValue: "maximumAccrualHours"},
    { field: "allowNegativeHours", sortValue: "allowNegativeHours"},
    { field: "createdOn", sortValue: "createdOn"},
  ];

  isLoading: boolean;
  gridData: any;
  pageSize: number = 5;
  skip: number = 0;
  sortString: string = 'name-asc';
  clearSelectedItems: boolean;
  getPoliciesRequest: any;
  filterString: string;

  constructor(
    private translate: TranslateService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private timeOffInLieuService: TimeOffInLieuService,
  ) { }

  ngOnInit(): void {
    this.getPolicies()
  }

  getPolicies() {
    this.isLoading = true;

    this.getPoliciesRequest = this.timeOffInLieuService.getToilPolicies(this.toilType.id, this.pageSize, this.skip, this.sortString, this.filterString)
    .pipe(
      finalize( () => {
        this.isLoading = false;
      })
    )
    .subscribe(
      (res) => {
        this.gridData = res;
      }
    );
  }

  deleteAllSelected(itemsToDelete: string[]) {
    const observables = itemsToDelete.map(item => defer(() => this.timeOffInLieuService.deleteToilPolicyById(this.toilType.id, item)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
            this.clearSelectedItems = !this.clearSelectedItems;
            this.getPolicies();
            this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
        }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.getPolicies();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getPolicies();
  }

  search(searchValue) {
    searchValue ? this.filterString = `name like "${searchValue}"` : this.filterString = null
    this.getPoliciesRequest.unsubscribe();
    this.getPolicies();
  }

}
