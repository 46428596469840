import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {api_routes} from '@app/consts';
import {BenefitsCoverageSubmit, BenefitsCoverageVerbose} from '../models/benefits-coverage.model';
import {EnvironmentService} from "@app/core/services/environment.service";

const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class BenefitsCoverageService {

  constructor(private http: HttpClient, private envService: EnvironmentService) {}

  getCoverages(): Observable<any> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.BENEFITS}/${api_routes.COVERAGES}`, {
      headers: headers,
    });
  }

  deleteCoverage(id: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.BENEFITS}/${api_routes.COVERAGES}/${id}`, {
      headers: headers
    });
  }

  getCoverage(id: string): Observable<BenefitsCoverageVerbose> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.BENEFITS}/${api_routes.COVERAGES}/${id}`, {
      headers: headers
    });
  }

  updateCoverage(employeeCoverage: BenefitsCoverageSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.BENEFITS}/${api_routes.COVERAGES}/${employeeCoverage.id}`, employeeCoverage, {
      headers: headers
    });
  }

  createCoverage(employeeCoverage: BenefitsCoverageSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.BENEFITS}/${api_routes.COVERAGES}`, employeeCoverage, {
      headers: headers
    });
  }
}

