import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';

@Component({
  selector: 'app-change-reason-dialog',
  templateUrl: './change-reason-dialog.component.html',
  styleUrls: ['./change-reason-dialog.component.scss']
})
export class ChangeReasonDialogComponent implements OnInit {
  formId: string;
  formValid: boolean = true;
  getFormData: boolean = false;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];

  constructor(
    private dialogRef: MatDialogRef<ChangeReasonDialogComponent>,
  @Inject(MAT_DIALOG_DATA) data) {
    this.formId = data.formId;
    this.targetEmployeeTablePermissions = data.targetEmployeeTablePermissions;
  }

  ngOnInit(): void {
  }
  
  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  formDataEmitted(formData) {
    this.save(formData);
  }

  save(formData) {
    setTimeout(() => {   
      this.dialogRef.close(formData);
    }, 0);
  }

  cancel() {
    this.dialogRef.close()
  }
}
