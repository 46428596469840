<div class="global-review-goal-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>
                {{ globalGoalReview.goal.objective }}
                <mat-chip-list>
                    <mat-chip 
                        [ngClass]="{
                            'red-chip': globalGoalReview.status?.id === 'Archived',
                            'green-chip': globalGoalReview.status?.id === 'Complete',
                            'orange-chip': globalGoalReview.status?.id === 'InProgress',
                            'blue-chip': globalGoalReview.status?.id === 'Pending'
                        }"
                    >{{ globalGoalReview.status?.name }}</mat-chip>
                </mat-chip-list>
            </mat-card-title>
            <mat-card-subtitle>{{ globalGoalReview.goal.description }}</mat-card-subtitle>

            <button mat-icon-button [matMenuTriggerFor]="menu" class="actions-btn"
                *ngIf="
                    (user$.userId === globalGoalReview.reviewer?.id && globalGoalReview?.status?.id !== 'Complete') || 
                    (user$.userId === globalGoalReview.reviewer?.id && globalGoalReview?.status?.id !== 'Complete') ||
                    (isOwner && globalGoalReview?.status?.id === 'Complete')
                "
            >
                <mat-icon class="material-icons-outlined">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button *ngIf="user$.userId === globalGoalReview.reviewer?.id && globalGoalReview?.status?.id !== 'Complete'" mat-menu-item appPreventDoubleClick (throttledClick)="showForm = true">
                    <mat-icon class="material-icons-outlined">edit</mat-icon> 
                    <span>{{ 'Edit' | translate: {Default: "Edit"} }}</span>
                </button>
                <ng-container *ngIf="user$.userId === globalGoalReview.reviewer?.id">
                    <button *ngIf="globalGoalReview?.status?.id !== 'Complete'" class="green-button" mat-menu-item appPreventDoubleClick (throttledClick)="complete()">
                        <mat-icon class="material-icons-outlined">check_circle</mat-icon>
                        {{ 'Complete' | translate: {Default: "Complete"} }}
                    </button>
                </ng-container>
                <ng-container *ngIf="isOwner">
                    <button *ngIf="globalGoalReview?.status?.id === 'Complete'" class="blue-button" mat-menu-item appPreventDoubleClick (throttledClick)="revert()">
                        <mat-icon class="material-icons-outlined">undo</mat-icon>
                        {{ 'Revert' | translate: {Default: "Revert"} }}
                    </button>
                </ng-container>
            </mat-menu> 
        </mat-card-header>

        <mat-card-content>
            <div class="top">
                <div class="employee-name">
                    <app-employee-image [employeeId]="globalGoalReview.reviewer?.id" [width]="35" [height]="35" [round]="true"></app-employee-image>
        
                    <span class="employee">
                        {{globalGoalReview.reviewer?.firstName}} {{globalGoalReview.reviewer?.lastName}}
                    </span>
                </div>
            </div>
        
            <div *ngIf="user$.userId === globalGoalReview.reviewer?.id && showForm; else showDetails">
                <form *ngIf="reviewRatingForm" [formGroup]="reviewRatingForm">
                    <mat-form-field appearance="fill">
                      <mat-label>{{ 'ReviewRating' | translate: {Default: 'Rating'} }}</mat-label>
                      <mat-select disableOptionCentering formControlName="rating">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let reviewRatingOption of reviewRatingOptions" [value]="reviewRatingOption?.rating.id">
                          {{reviewRatingOption?.rating?.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
        
                    <mat-form-field appearance="fill">
                      <mat-label>{{ 'ReviewComments' | translate: {Default: 'Comments'} }}</mat-label>
                      <textarea
                        matInput
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="20"
                        cdkAutosizeMaxRows="30"
                        formControlName="comments">
                      </textarea>
                    </mat-form-field>
        
                    <div class="buttons-container">
                        <button
                            mat-raised-button
                            appPreventDoubleClick
                            (throttledClick)="showForm = false"
                        >{{ 'Cancel' | translate: {Default: "Cancel"} }}</button>
        
                        <button
                            mat-raised-button
                            color="primary"
                            setColor="primaryColour"
                            appPreventDoubleClick
                            (throttledClick)="save()"
                            [disabled]="!reviewRatingForm.valid"
                        >{{ 'Save' | translate: {Default: "Save"} }}</button>
                    </div>
                </form>
            </div>
            <ng-template #showDetails>
                <ng-container *ngIf="globalGoalReview?.rating === null && globalGoalReview?.comments === null; else showReview">
                    <div>{{ 'NoReview' | translate: {Default: "No Review"} }}</div>
                </ng-container>
                <ng-template #showReview>
                    <div>
                        <div class="label">{{ 'ReviewRating' | translate: {Default: 'Rating'} }}</div>
                        <div class="value">{{globalGoalReview.rating?.name}}</div>
                    </div>
                    <div>
                        <div class="label">{{ 'ReviewComments' | translate: {Default: 'Comments'} }}</div>
                        <div class="value">{{globalGoalReview?.comments}}</div>
                    </div>
                </ng-template>
            </ng-template>
        </mat-card-content>
    </mat-card>
</div>
