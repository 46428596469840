<app-layout>
    <div class="main-container">
        <div class="left-container">
            <div class="title">
                <mat-icon class="material-icons-outlined">settings_applications</mat-icon>
                <h3>Settings</h3>
            </div>
            <mat-list>
                <mat-list-item *ngFor="let item of navList">
                  <mat-icon class="material-icons-outlined">{{item.icon}}</mat-icon> {{item.text}}
                </mat-list-item>
            </mat-list>
        </div>
        <div class="right-container">
            <div class="top">
                <div class="title">
                    <mat-icon class="material-icons-outlined">person_add</mat-icon>
                    <h3>Onboarding</h3>
                </div>
                <div class="controls">
                    <div class="left">
                        <button class="add-task" mat-flat-button>
                            <mat-icon class="material-icons-outlined">add</mat-icon> 
                            Add Task
                        </button>
                        <button mat-flat-button>
                            <mat-icon class="material-icons-outlined">create_new_folder</mat-icon> 
                            Add Category
                        </button>
                    </div>
                    <div class="right">
                        <button mat-flat-button>
                            <mat-icon class="material-icons-outlined">settings</mat-icon> 
                        </button>
                    </div>
                </div>
            </div>
            <div class="bottom">
                <mat-accordion multi>
                    <mat-expansion-panel [expanded]="true" *ngFor="let task of tasks">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{task.category}}

                            <button mat-flat-button>
                                <mat-icon class="material-icons-outlined">add</mat-icon> 
                                Add Task
                            </button>
                        </mat-panel-title>
                        <mat-panel-description>
                          
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                  
                      <mat-list>
                          <mat-list-item class="header">
                            <span class="task">Task Name</span>
                            <span class="assigned-to">Assigned To</span>
                            <span class="date">Due</span>
                          </mat-list-item>
                          <mat-divider></mat-divider>

                          <div *ngFor="let item of task.list; let lastItem = last;">
                              <mat-list-item>
                                <span class="task">{{item.task}}</span>
                                <span class="assigned-to">{{item.assignedTo}}</span>
                                <span class="date">{{item.date}}</span>
                                <span class="icons">
                                    <mat-icon class="material-icons-outlined">create</mat-icon> 
                                    <mat-icon class="material-icons-outlined">delete</mat-icon> 
                                </span>
                              </mat-list-item>
                              <mat-divider *ngIf="!lastItem"></mat-divider>
                          </div>
                      </mat-list>
                  
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>
</app-layout>

