import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmployeeGoalSubmit, GoalVerbose } from '../../../goal-plans/models/goal-plan.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GoalType } from '../../../goal-types/models/goal-type.model';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { Router } from '@angular/router';
import { GoalTypeService } from '../../../goal-types/services/goal-type.service';
import { GoalPlanService } from '../../../goal-plans/services/goal-plan.service';
import { finalize } from 'rxjs';
import { db_tables } from '@app/consts';
import { TableField } from '@app/modules/security-setup/models/table-field.model';
import { EmployeeTableFieldSecurity, EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { SecuritySetupService } from '@app/modules/security-setup/services/security-setup.service';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';

@Component({
  selector: 'app-employee-personal-goal-form',
  templateUrl: './employee-personal-goal-form.component.html',
  styleUrls: ['./employee-personal-goal-form.component.scss']
})
export class EmployeePersonalGoalFormComponent implements OnInit {
  @Input() goal: GoalVerbose;
  @Input() employeeId: string;
  @Input() parentId: string;
  @Input() goalPlan: { id: string, name: string };
  @Input() readOnly: boolean = false;

  @Output() selectedParentId = new EventEmitter<string>();
  @Output() saveComplete = new EventEmitter<boolean>();

  goalForm: FormGroup;
  goalTypes: GoalType[] = [];
  // Slider variables
  sliderValue: number = 0;
  percentageSliderValue: number = 0;
  percentageInputDisabled: boolean;

  weightSliderValue: number = 0;
  weightPercentageSliderValue: number = 0;
  weightPercentageInputDisabled: boolean;

  showComments: boolean = true;

  isLoading: boolean = true;
  isAParent: boolean = false;
  checkingIfParent: boolean;
  selectedGoalType: GoalType;
  hideGoalPlan: boolean = false;

  public db_tables = db_tables;
  tableId = db_tables.EmployeeGoals;
  public columns: any[] = [
    { field: "employee", tableId: "", title: "Employee", type: "employee" },
    { field: "objective", tableId: "tfi_PEgObjective", title: this.translate.instant("EmployeeGoals-Objective") },
    { field: "description", tableId: "tfi_PEgDescription", title: "Description", type: "longText", longTextLimit: 200 },
    { field: "expectedCompletionDate", tableId: "tfi_PEgExpectedCompletionDate", title: "Expected Completion Date", type: "date", dataType: 'Date'},
    { field: "actualCompletionDate", tableId: "tfi_PEgActualCompletionDate", title: "Actual Completion Date", type: "date", dataType: 'Date'},
    { field: "goalType", subField: "name", tableId: "tfi_PEgGoalType", title: "Goal Type", dataType: "SpecialLookup", lookupCode: 'GOAL_TYPE'},
    { field: "goalPlan", subField: "name", tableId: "", title: this.translate.instant("EmployeeGoals-GoalPlan"), dataType: "SpecialLookup", lookupCode: 'GOAL_PLAN'},
    { field: "createdBy", tableId: "", title: this.translate.instant("EmployeeGoals-CreatedBy"), type: "employee" },
    { field: "parent", subField: "objective", tableId: "tfi_PEgParentGoal", title: this.translate.instant("EmployeeGoals-Parent") },
    { field: "percentageComplete", tableId: "tfi_PEgPercentageComplete", title: "Percentage Complete", type: "percentage", percentageMaxValue: 1 },
    { field: "weight", tableId: "tfi_PEgWeight", title: "Weight", type: "percentage", percentageMaxValue: 1 },
    { field: "managers", tableId: "", title: "Managers", type: "employees" },
  ];
  sortingColumns: boolean;
  fieldList: TableField[] = [];
  isLoadingPermissions: boolean;
  user$: any;
  targetEmployeeTablePermissions: EmployeeTableSecurity[] = [];

  mandatoryFilter;
  mandatoryGoalTypeFilter;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    public translate: TranslateService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    public router: Router,
    private goalTypeService: GoalTypeService,
    private goalService: GoalPlanService,
    private oidcAuthService: OidcAuthService,
    private securitySetupService: SecuritySetupService,
    private tablePermissionsService: TablePermissionsService,
  ){
    this.user$ = this.oidcAuthService.userProfile;
  }
  
  ngOnInit(): void {
    this.mandatoryGoalTypeFilter = `(allowEmployeeGoalEntry = "true")`

    this.buildFormData();
    this.getTargetEmployeeTablePermissions();
    this.getFields(this.tableId);
    this.getTableFieldPermissions();
    
    if(this.goal) {
      this.checkIfIsAParent();
    }
  }

  getTargetEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.user$.userId);

    // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
    if(currentFetchedData !== null){
      this.targetEmployeeTablePermissions = currentFetchedData;
      this.isLoadingPermissions = false;
    }
    else {
      this.tablePermissionsService.getTargetEmployeeTablePermissions(this.user$.userId)
      .pipe(
        finalize(() => {
            this.isLoadingPermissions = false;
        })
      )
      .subscribe(
        res => {
          this.targetEmployeeTablePermissions = res;
        }
      );
    }
  }

  getFields(tableId) {
    this.sortingColumns = true;

    this.securitySetupService.getFields(tableId, 0, '200')
    .pipe(
      finalize( () => {
        this.sortingColumns = false;
        this.getTableFieldPermissions();
      })
    )
    .subscribe(
        pagedFieldList => {
          this.fieldList = pagedFieldList.data;

          this.fieldList.forEach(
            field => {
              let index = this.columns.findIndex( column => column.tableId === field.id )
              // let filterCategoriesIndex = this.filterCategories.findIndex( column => column.tableId === field.id )

              if(index !== -1){
                if(field.enabled === false){
                  this.columns.splice(index, 1);
                }
                else if(field.name !== null){
                  this.columns[index].title = field.name;
                }
              }

              // if(filterCategoriesIndex !== -1){
              //   if(field.enabled === false){
              //     this.filterCategories.splice(filterCategoriesIndex, 1);
              //   }
              //   else if(field.name !== null){
              //     this.filterCategories[filterCategoriesIndex].title = field.name;
              //   }
              // }
            }
          );
        }
    );
  }

  getTableFieldPermissions() {
    let tableFieldPermissions: EmployeeTableFieldSecurity[] = this.tablePermissionsService.getTableFieldsPermissions(this.tableId, this.targetEmployeeTablePermissions);

    if(tableFieldPermissions !== null) {
      tableFieldPermissions.forEach(
        tableFieldPermission => {
          let index = this.columns.findIndex( column => column.tableId === tableFieldPermission.field.id )
  
          if(index !== -1){
            if(tableFieldPermission.read === false || tableFieldPermission.deny === true){
              this.columns.splice(index, 1);
            }
          }
        }
      )
    }
  }

  getColumnTitle(tableId: string): string | undefined {
    const column = this.columns.find(col => col.tableId === tableId);
    return column ? column.title : undefined;
  }

  buildFormData(){
    this.isLoading = true;

    this.goalForm = this.fb.group(
      {
        id: [{value: this.goal ? this.goal.id : null, disabled: this.readOnly}],
        objective: [{value: this.goal ? this.goal.objective : null, disabled: this.readOnly}, Validators.required],
        description: [{value: this.goal ? this.goal.description : null, disabled: this.readOnly}, Validators.required],
        parentId: [{value: this.goal ? this.goal.parent?.id : this.parentId, disabled: this.readOnly}],
        goalTypeId: [{value: this.goal ? this.goal.goalType?.id : null, disabled: this.readOnly}, Validators.required],
        goalPlan: [{value: this.goal ? this.goal.goalPlan : null, disabled: this.readOnly}],
        percentageComplete: [{value: this.goal ? this.goal.percentageComplete : null, disabled: this.readOnly}],
        weight: [{value: this.goal ? this.goal.weight : null, disabled: this.readOnly}],
        expectedCompletionDate: [{value: this.goal ? this.goal.expectedCompletionDate : null, disabled: this.readOnly}],
        actualCompletionDate: [{value: this.goal ? this.goal.actualCompletionDate : null, disabled: this.readOnly}],
      }
    );

    // If you are editting a goal you cannot change the goal plan
    if(this.goal) {
      this.goalForm.get('goalPlan').disable()
      this.goalForm.get('goalTypeId').disable()
      this.goalForm.get('parentId').disable()
      
      if(this.goal.parent?.id) {
        this.goalForm.get('goalPlan').setValue(null);
        this.hideGoalPlan = true;
      }
    }
    // if you are creating a new goal but the goal plan has been passed in then set the goal plan value
    else if(this.goalPlan) {
      this.goalForm.get('goalPlan').setValue(this.goalPlan);
      this.goalForm.get('goalPlan').disable()
    }
    // if you are createing a new child goal
    else if(this.goal === undefined && this.parentId) {
      this.goalForm.get('parentId').disable();
      this.goalForm.get('goalPlan').setValue(null);
      this.hideGoalPlan = true;
    }

    this.goalForm.get('parentId').valueChanges.subscribe(value => {
      this.selectedParentId.emit(value);

      if(value !== null){
        this.goalForm.get('goalPlan').setValue(null);
        this.hideGoalPlan = true;
      }
      else {
        this.goalForm.get('goalPlan').setValue(null);
        this.hideGoalPlan = false;
      }
    });

    // When the goaltypeid value changes set the mandatory filter for the goal form field
    this.goalForm.get('goalTypeId').valueChanges.subscribe(value => {
      this.mandatoryFilter = `(GoalTypeId = "${value}") AND (GoalPlanId = "${this.goalPlan?.id}")`
    });

    this.calculateSliderPercentage(this.goalForm.get('percentageComplete').value, 1);
    this.calculateWeightSliderPercentage(this.goalForm.get('weight').value, 1);

    this.isLoading = false;
  }

  checkIfIsAParent(){
    let filter
    this.checkingIfParent = true;

    if(this.goal){
      filter = `(parent.id = "${this.goal.id}")`;
    }

    this.goalService.getEmployeeGoals(this.employeeId, '20', 0, null, filter)
      .pipe(
        finalize( () => {
          this.checkingIfParent = false;
        })
      )
      .subscribe(
        (res) => {
          if(res.employeeGoals.data.length > 0){
            this.isAParent = true;
          }
        }
      );
  }

  calculateSliderPercentage(value: number, max: number) {
    this.percentageSliderValue = Math.round((value/max) * (100/1));
  }

  calculateWeightSliderPercentage(value: number, max: number) {
    this.weightSliderValue = Math.round((value/max) * (100/1));
  }

  setValue(value, formGroup, formControl) {
    formGroup.get(formControl).setValue(value)
  }

  save(){
    if(this.goal?.id){
      this.updateGoal(this.goalForm.getRawValue())
    }
    else {
      this.createNewGoal(this.goalForm.getRawValue())
    }
  }

  createNewGoal(formData: any){
    this.overlayService.show();

    this.goalService.createEmployeeGoal(this.employeeId, formData)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');  
          this.saveComplete.emit(true);
        }
    );
  }

  updateGoal(formData: EmployeeGoalSubmit){
    this.overlayService.show();

    this.goalService.updateEmployeeGoal(this.employeeId, formData)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');  
          this.saveComplete.emit(true);
        }
    );
  }
}
