<div class="content">
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
            <h4 appLocalizationPopupDirective localizationCode="EmployeeSecurity-ResetPassword">{{ 'EmployeeSecurity-ResetPassword' | translate: {Default: "Reset Password"} }}</h4>
        </mat-card-title>
    
        <mat-card-content>
            <div class="page-description-section" appLocalizationPopupDirective localizationCode="EmployeeSecurity-PasswordRequirments">
                {{ 'EmployeeSecurity-PasswordRequirments' | translate: {Default: "Password must have at least one uppercase letter, one lowercase letter, one number and one special character, be at least 8 characters and a maximum of 15 characters."} }}
            </div>

            <form [formGroup]="form">
                <mat-form-field appearance="fill" appLocalizationPopupDirective localizationCode="EmployeeSecurity-Password">
                    <input matInput type="password" [placeholder]="translate.instant('EmployeeSecurity-Password')" formControlName="password" (input)="onPasswordInput()">
                    <mat-error *ngIf="password.hasError('required')" appLocalizationPopupDirective localizationCode="EmployeeSecurity-PasswordIsRequired">{{ 'EmployeeSecurity-PasswordIsRequired' | translate: {Default: "Password is required"} }}</mat-error>
                    <mat-error *ngIf="password.hasError('minlength')" appLocalizationPopupDirective localizationCode="EmployeeSecurity-PasswordMinLength">{{ 'EmployeeSecurity-PasswordMinLength' | translate: {Default: "Password must have at least 8 characters"} }}</mat-error>
                    <mat-error *ngIf="password.hasError('maxlength')" appLocalizationPopupDirective localizationCode="EmployeeSecurity-PasswordMaxLength">{{ 'EmployeeSecurity-PasswordMaxLength' | translate: {Default: "Password must have no more than 15 characters"} }}</mat-error>
                    <mat-error *ngIf="password.hasError('pattern')" appLocalizationPopupDirective localizationCode="EmployeeSecurity-PasswordRequirmentsError">{{ 'EmployeeSecurity-PasswordRequirmentsError' | translate: {Default: "Password must have at least one uppercase letter, one lowercase letter, one number and one special character"} }}</mat-error>
                </mat-form-field>
                
                <mat-form-field appearance="fill" appLocalizationPopupDirective localizationCode="EmployeeSecurity-ConfirmPassword">
                    <input matInput type="password" [placeholder]="translate.instant('EmployeeSecurity-ConfirmPassword')" formControlName="password2" (input)="onPasswordInput()">
                    <mat-error *ngIf="password2.hasError('required')" appLocalizationPopupDirective localizationCode="EmployeeSecurity-ConfirmPassword">{{ 'EmployeeSecurity-ConfirmPassword' | translate: {Default: "Please confirm your password"} }}</mat-error>
                    <mat-error *ngIf="password2.invalid && !password2.hasError('required')" appLocalizationPopupDirective localizationCode="EmployeeSecurity-PasswordDontMatch">{{ 'EmployeeSecurity-PasswordDontMatch' | translate: {Default: "Passwords don't match"} }}</mat-error>
                </mat-form-field>
                
                <div class="buttons-container">
                    <button mat-raised-button setColor="primaryColour" color="primary" [disabled]="!form.valid" appPreventDoubleClick (throttledClick)="save()">{{ 'Save' | translate: {Default: "Save"} }}</button> 
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>