import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { routes } from '@app/consts/routes';
import { TimeOff } from '@app/modules/lookup/models/time-off.model';
import { RefreshListener } from '@app/shared/models/refresh-listener';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TimeOffService } from '@app/modules/time-off/services/time-off.service';
import { TimeOffPolicy } from '@app/modules/lookup/models/time-off-policy.model';
import { TimeOffListVariant } from '@app/modules/lookup/models/time-off-list-variant.model';
import { SecurityProtectedBase } from '@app/shared/components/security-protected/security-protected';
import { features } from '@app/consts';
import { FeatureService } from '@app/core/services/feature.service';
import { defer, forkJoin, Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { finalize } from 'rxjs/operators';


enum TimeOffViewState {
    LIST_TYPES,
    ADD_TYPE,
    EDIT_TYPE,
    POLICY
}

@Component({
  selector: 'app-time-off-policies-types',
  templateUrl: './time-off-policies-types.component.html',
  styleUrls: ['./time-off-policies-types.component.scss']
})
export class TimeOffPoliciesTypesComponent extends SecurityProtectedBase implements OnInit, RefreshListener {
    public eTimeOffViewState = TimeOffViewState;
    public viewState: TimeOffViewState = TimeOffViewState.LIST_TYPES;

    public timeOffTypeList: TimeOffListVariant[] = [];
    public iconObservables: Observable<any>[] = [];
    private timeOffTypeId: string;
    private timeOffType: TimeOff;
    private timeOffPolicyId: string;
    isLoading: boolean = true;
    isLoadingType: boolean = false;
    clearSelectedItems: boolean = false;
    timeOffTypeSearchValue: string = ''

    public columns: any[] = [
        {field: 'name', title: 'Policies', dataType: "String"},
        {field: 'accruedAt', subField: 'name', title: 'Accrued At', dataType: "String"},
    ];
    pageSize: number = 20;
    skip: number = 0;


    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService,
        private timeOffService: TimeOffService,
        private dialog: MatDialog,
        private snackbarService: SnackbarService,
        private overlayService: OverlayService,
        private domSanitizer: DomSanitizer,
        featureService: FeatureService
    ) {
        super(featureService, features.TIME_OFF)
    }

    ngOnInit(): void {
        this.getTimeOffTypes();
    }


    private getTimeOffTypes() {
        this.isLoading = true;
        this.timeOffService.getTimeOffTypes().subscribe(
            timeOffTypePager => {
                this.timeOffTypeList = timeOffTypePager.data;
                console.log(this.timeOffTypeList)
                this.timeOffTypeList.forEach(
                    (timeOffType, index) => {
                        this.getIcon(timeOffType.id, index)
                    }
                )
                this.isLoading = false;
            },
            err => {
                console.log(err);
            }
        );
    }

    getIcon(timeOffTypeId: string, index: number){
        this.timeOffService.getTimeOffTypeIcon(timeOffTypeId)
        .subscribe(
            res => {
                this.timeOffTypeList[index].image = res;
            }
        )
    }

    getRouterLink(item: TimeOff) {
        return `${routes.SITE_SETTINGS}${routes.TIME_OFF}/Edit/${item.id}`;
    }

    goToAdd() {
        this.viewState = TimeOffViewState.ADD_TYPE;
    }

    goToEdit(id: string) {
        this.viewState = TimeOffViewState.EDIT_TYPE;
        this.timeOffTypeId = id;
    }

    refresh(): void {
        this.getTimeOffTypes();
        this.viewState = TimeOffViewState.LIST_TYPES;
    }

    deleteAllSelected(timeOffPolicy: TimeOffPolicy, itemsToDelete: string[]) {
        const observables = itemsToDelete.map(item => defer(() => this.timeOffService.deleteTimeOffPolicyById(timeOffPolicy.id, item)));

        this.overlayService.show();

        forkJoin(observables)
        .pipe(
            finalize(() => this.overlayService.hide())
        )
        .subscribe(
            (res) => {
                this.clearSelectedItems = !this.clearSelectedItems;
                this.getTimeOffTypes();
                this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
            },
            err => {
                this.snackbarService.openSnackBar(err, 'clear', 'warn');
            }
        );
    }

    showDeleteTypeConfirmation(timeOffType: TimeOff) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            text: `Are you sure you want to delete ${timeOffType.name}?`
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data === true) {
                    this.deleteType(timeOffType.id);
                }
            }
        );
    }

    deleteType(typeId: string) {
        this.timeOffService.deleteTimeOffTypeById(typeId).subscribe(
            timeOffType => {
                this.getTimeOffTypes();
                this.snackbarService.openSnackBar('Time Off Type Deleted', 'clear', 'success');
            },
            err => {
                console.log(err);
            }
        );
    }

    cancel(): void {
        this.viewState = TimeOffViewState.LIST_TYPES;
    }


    goToTimeOffPolicy(timeOffType: TimeOff, timeOffPolicy: TimeOffPolicy) {
        this.timeOffType = timeOffType;
        this.timeOffPolicyId = timeOffPolicy?.id;
        this.viewState = TimeOffViewState.POLICY;
    }
}

