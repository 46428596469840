import {TimeOffAccrualRateInterval} from '@app/modules/lookup/models/time-off-accrual-rate-interval.model';
import {TimeOffStartAtUnit} from '@app/modules/lookup/models/time-off-start-at-unit.model';

export class TimeOffPolicySchedule {
    id: string;
    startsAt: number;
    accrualRate: number;
    accrualMaximum: number;
    carryOverAmount: number;
    accrualRateInterval: TimeOffAccrualRateInterval;
    intervalStartDate: string;
    startAtUnit: TimeOffStartAtUnit;
    biMonthlyFirstDay: number
    biMonthlySecondDay: number
    biMonthlySecondDayEndOfMonth: boolean
    yearlyMonth: number
    yearlyDay: number
    monthlyDay?: number
    monthlyEndOfMonth?: boolean
    monthlyOnHireDay?: boolean
}
