<h5 mat-dialog-title>{{ 'SelectACompanyLogo' | translate: {Default: "Select A Company Logo"} }}</h5>

<mat-dialog-content>
    <div *ngIf="selectedCompanyLogo" class="selected-company-logo page-description-section">
        <h6>{{ 'SelectedCompanyLogo' | translate: {Default: "Selected company logo"} }}: {{selectedCompanyLogo.name}}</h6>
    </div>

    <app-data-grid-comp
        [columns]="columns"
        [gridDataResult]="gridDataResult"
        [isLoading]="isLoading"
        [pageSize]="pageSize"
        [skip]="skip"
        [rowSelectionSettings]="{
            canSelectRows: true,
            selectableMode: 'single',
            selectRowBy: 'id'
        }"
        clickableRows="false"
        hideActionButtons="true"
        [clearSelectedItems]="clearSelectedItems"
        [selectedRowsButtonSettings]="selectedRowsButtonSettings"
        (emitPageChangeEvent)="pageChange($event)"
        (emitSelectedRows)="selectedRowsChanged($event)"
    ></app-data-grid-comp>

</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!selectedCompanyLogo">{{ 'Save' | translate: {Default: "Save"} }}</button>
</mat-dialog-actions>

