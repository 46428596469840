<mat-card class="mat-elevation-z0" *appTableAccess="[tableReadAccessObj]">
    <mat-card-title class="header">
        <h5 appLocalizationPopupDirective localizationCode="StatutoryHolidayGroup">
            {{ 'StatutoryHolidayGroup' | translate: {Default: "Statutory Holiday Group"} }}
        </h5>
    </mat-card-title>
    <mat-card-content>
        <button appLocalizationPopupDirective localizationCode="AssignStatutoryHolidayGroup" class="assign-btn" mat-stroked-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openAssignStatHolidayGroupDialog()" *appTableAccess="[tableCreateAccessObj, tableUpdateAccessObj]">
            <mat-icon class="material-icons-outlined" >add</mat-icon>
            {{ 'AssignStatutoryHolidayGroup' | translate: {Default: "Assign Statutory Holiday Group"} }}
        </button>

        <div *ngIf="isLoading; else loaded" class="loading-container">
            <ngx-skeleton-loader
                count="5"
                [theme]="{ 
                    'height.px': 50
                }"
            ></ngx-skeleton-loader>
        </div>

        <ng-template #loaded>
            <div class="holiday-group">
                <ng-container *ngIf="timeOffDetail.statutoryHolidayGroup; else noGroup">
                    <h6>
                        {{timeOffDetail.statutoryHolidayGroup.name}}
                    </h6>

                    <div appLocalizationPopupDirective localizationCode="StatutoryVacationDifference" class="page-description-section statutory-vacation-difference">
                        {{ 'StatutoryVacationDifference' | translate: {Default: "Statutory Vacation Difference"} }}: <span setColor="primaryColour" class="value">{{timeOffDetail.statutoryVacationDifference}}</span>
                    </div>

                    <app-data-grid-comp
                        [columns]="columns"
                        [filterCategories]="filterCategories"
                        [sortableColumns]="sortableColumns"
                        [gridDataResult]="gridDataResult"
                        [isLoading]="isLoading"
                        [pageSize]="pageSize"
                        [skip]="skip"
                        [rowSelectionSettings]="{
                            canSelectRows: false,
                            selectableMode: 'none',
                            selectRowBy: 'id'
                        }"
                        clickableRows="false"
                        searchable="true"
                        hideActionButtons="true"
                        [sortable]="{
                            mode: 'multiple'
                        }"
                        (emitSearchEvent)="search($event)"
                        (emitPageChangeEvent)="pageChange($event)"
                        (emitOpenDialogEvent)="getReview($event)"
                        (emitSortEvent)="sortChange($event)"
                        (emitFilterEvent)="filterCallback($event)"
                    ></app-data-grid-comp>
                </ng-container>
                <ng-template #noGroup>
                    <div appLocalizationPopupDirective localizationCode="NoStatutoryHolidayGroup">{{ 'NoStatutoryHolidayGroup' | translate: {Default: "No statutory holiday group assigned"} }}</div>
                </ng-template>
            </div>
        </ng-template>
    </mat-card-content>
</mat-card>