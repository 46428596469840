import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { api_routes } from '@app/consts';
import { EmployeePayrollDetail } from '../models/employee-payroll-details.model';
import {EnvironmentService} from "@app/core/services/environment.service";



const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class EmployeePayrollDetailsService {

  constructor(private http: HttpClient, private envService: EnvironmentService) {}

  getPayrollDetails(employeeId: string, asOf?: string): Observable<EmployeePayrollDetail> {
    let params = new HttpParams();
    asOf ? params = params.append('AsOf', asOf) : null;
    
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.PAYROLL_DETAIL}`, {
      params: params,
      headers: headers
    });
  }

  updatePayrollDetails(employeeId: string, employeePayrollDetailsSubmit: any): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.PAYROLL_DETAIL}`, employeePayrollDetailsSubmit, {
      headers: headers
    });
  }
}
