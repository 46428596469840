import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { routes } from '@app/consts/routes';
import { features } from '@app/consts';
import { TranslateService } from '@ngx-translate/core';
import { FeatureService } from '@app/core/services/feature.service';
import { modules } from '@app/consts/modules';
import { ModuleConfigurationService } from '@app/core/services/module-configuration/module-configuration.service';

@Component({
    selector: 'app-site-settings-menu',
    templateUrl: './site-settings-menu.component.html',
    styleUrls: ['./site-settings-menu.component.scss']
})
export class SiteSettingsMenuComponent implements OnInit {
    public routes: typeof routes = routes;
    navList = [
        {
            text: 'OrganizationStructure',
            link: `${routes.SITE_SETTINGS}${routes.ORGANIZATION}`,
            icon: 'domain',
            featureCode: features.ORGANIZATION
        },
        {
            text: 'Positions',
            link: `${routes.SITE_SETTINGS}${routes.POSITIONS}`,
            icon: 'badge',
            featureCode: 'Positions'
        },
        {
            text: 'CostCenters',
            link: `${routes.SITE_SETTINGS}${routes.COST_CENTERS}`,
            icon: 'attach_money',
            featureCode: features.COST_CENTERS
        },
        {
            text: 'Lists',
            link: `${routes.SITE_SETTINGS}${routes.LISTS}`,
            icon: 'edit_note',
            featureCode: 'Lists'
        },
        {
            text: 'tbl_WorkLocations',
            link: `${routes.SITE_SETTINGS}${routes.WORK_LOCATIONS}`,
            icon: 'home_work',
            featureCode: 'WorkLocations'
        },
        {
            text: 'WorkRotations',
            link: `${routes.SITE_SETTINGS}${routes.WORK_ROTATIONS}`,
            icon: 'pending_actions',
            featureCode: 'WorkRotations',
            module: modules.TIME_OFF
        },
        {
            text: 'TimeOff',
            link: `${routes.SITE_SETTINGS}${routes.TIME_OFF}${routes.TIME_OFF_POLICIES_TYPES}`,
            icon: 'alarm',
            featureCode: features.TIME_OFF,
            module: modules.TIME_OFF
        },
        {
            text: 'TimeOffInLieu',
            link: `${routes.SITE_SETTINGS}${routes.TOIL}${routes.TOIL_POLICIES}`,
            icon: 'work_history',
            featureCode: features.TOIL_ADMINISTRATION,
            module: modules.TOIL,
            // svg: '/assets/icons/calendar-clock-icon.svg'
        },
        {
            text: 'StatutoryHolidays',
            link: `${routes.SITE_SETTINGS}${routes.STATUTORY_HOLIDAYS}`,
            icon: 'wb_sunny',
            featureCode: features.TIME_OFF,
            module: modules.TIME_OFF
        },
        {
            text: 'Forms',
            link: `${routes.SITE_SETTINGS}${routes.FORMS}`,
            icon: 'library_books',
            featureCode: 'Forms'
        },
        {
            text: 'Configure',
            link: `${routes.SITE_SETTINGS}${routes.CONFIGURE}`,
            icon: 'settings',
            featureCode: features.SITE_SETTINGS
        },
        {
            text: 'Currency',
            link: `${routes.SITE_SETTINGS}${routes.CURRENCY}`,
            icon: 'attach_money',
            featureCode: 'Currency'
        },
        {
            text: 'Translations',
            link: `${routes.SITE_SETTINGS}${routes.TRANSLATIONS}`,
            icon: 'translate',
            featureCode: features.GLOBALIZATION
        },
        {
            text: 'CountryConfig',
            link: `${routes.SITE_SETTINGS}${routes.COUNTRY_CONFIG}`,
            icon: 'public',
            featureCode: features.COUNTRY_CONFIG
        },
        {
            text: 'SchedulesAdministration',
            link: `${routes.SITE_SETTINGS}${routes.GENERATE_SCHEDULE}`,
            icon: 'calendar_month',
            featureCode: features.SCHEDULES,
            module: modules.TIME_OFF
        },
        {
            text: 'TableFieldEditor',
            link: `${routes.SITE_SETTINGS}${routes.TABLE_FIELD_EDITOR}`,
            icon: 'edit_note',
            featureCode: 'allow'
        },
        {
            text: 'BusinessRuleAutomation',
            link: `${routes.SITE_SETTINGS}${routes.BUSINESS_RULE_AUTOMATION}`,
            icon: 'mark_email_unread',
            featureCode: features.BUSINESS_RULE_AUTOMATION,
            module: modules.BUSINESS_RULE_AUTOMATION
        },
        {
            text: 'Styling',
            link: `${routes.SITE_SETTINGS}${routes.STYLING}`,
            icon: 'style',
            featureCode: features.SITE_STYLING
        },
        // {
        //     text: 'CompanyLogos'),
        //     link: `${routes.SITE_SETTINGS}${routes.STYLING}${routes.COMPANY_LOGOS}`,
        //     icon: 'photo',
        //     featureCode: features.SITE_STYLING
        // },
    ];

    constructor(
        public translate: TranslateService,
        public router: Router,
        private featureService: FeatureService,
        private moduleConfigurationService: ModuleConfigurationService,
    ) {
    }

    ngOnInit(): void {
        this.removeFeaturesWithoutModuleAccess();
        this.navigateToFirstAllowedSection();
    }

    // If the client doesnt have access to a module remove it form the list
    removeFeaturesWithoutModuleAccess() {
        this.navList = this.navList.filter(
            feature => {
                if(feature.module) {
                    return this.moduleConfigurationService.hasModuleAccess(feature.module);
                }
                else {
                    return true;
                }
            }
        )
    }

    navigateToFirstAllowedSection() {
        let urlToNavigateTo = '';

        if(this.router.url === `${routes.SITE_SETTINGS}`) {
          this.navList.every(element => {
            let hasAccess = this.featureService.hasFeatureAccess(element.featureCode)

            if(hasAccess){
              urlToNavigateTo = element.link;
              return false;
            }

            return true;
          });
        }

        if(urlToNavigateTo !== ''){
          this.router.navigate([urlToNavigateTo]);
        }
    }
}
