<h5 mat-dialog-title>Coverage</h5>

<mat-dialog-content>
    <form class="form" *ngIf="form" [formGroup]="form">

        <div class="form-section">
            <div class="form-section-title">
                <h6>Text Localizations</h6>
            </div>
            <div class="form-section-contents">
                <div class="section-row">
                    <ng-container formArrayName="name">
                        <ng-container *ngFor="let localizationForm of localizations.controls; let i = index">
                            <div class="form-row">
                                <div [formGroup]="localizationForm">
                                    <mat-form-field appearance="fill">
                                        <mat-label>
                                            Select Language
                                        </mat-label>
            
                                        <mat-select disableOptionCentering formControlName="culture">
                                            <mat-option *ngFor="let culture of cultures" [value]="culture.id">{{culture.text}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
            
                                    <mat-form-field appearance="fill">
                                        <mat-label>
                                            Name
                                        </mat-label>
            
                                        <input matInput
                                            formControlName="text">
                                    </mat-form-field>
                                </div>
                                <mat-icon class="material-icons-outlined" (click)="deleteLocalization(i)" matTooltip="Delete">delete</mat-icon> 
                            </div>

                            <mat-divider></mat-divider>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button setColor="primaryColour" color="primary" (click)="addNewLocalization()">
        <mat-icon class="material-icons-outlined">add</mat-icon> Add Text Localization
    </button>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button> 
</mat-dialog-actions>