import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { Lookup, LookupSubmit, LookupVerbose, TableListFieldOption, TableListFieldOptionSubmit, TableListFieldOptionVerbose } from '../../models/lookup.model';
import { LookupService } from '../../services/lookup.service';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { Culture } from '@app/shared/models/system-language/culture.model';

@Component({
  selector: 'app-edit-lookup-dialog',
  templateUrl: './edit-lookup-dialog.component.html',
  styleUrls: ['./edit-lookup-dialog.component.scss']
})
export class EditLookupDialogComponent implements OnInit {
  listGroup: string;
  listOptionId: string;
  tableListFieldOption: TableListFieldOptionVerbose;
  form: UntypedFormGroup;
  loading: boolean = true;
  cultures: Culture[];

  constructor(
    private snackbarService: SnackbarService,
    private fb: UntypedFormBuilder,
    private lookupService: LookupService,
    private overlayService: OverlayService,
    private cultureService: CultureService,
    private dialogRef: MatDialogRef<EditLookupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      this.listGroup = data.listGroup;
      this.listOptionId = data.listOptionId;
    }

  ngOnInit(): void {
    this.cultureService.getCultures().subscribe( res => { this.cultures = res } );
    this.getLookupData();
  }

  getLookupData() {
    this.loading = true;

    this.lookupService.getTableListFieldOption(this.listGroup, this.listOptionId)
    .pipe(
      finalize(() => this.loading = false)
    )
    .subscribe(
      res => {
        this.tableListFieldOption = res;
        this.createForm();
      },
      err => {

      }
    )
  }

  createForm() {
    this.form = this.fb.group({
      id: [this.tableListFieldOption.id],
      asOf: [moment().format()],
      text: this.fb.array([]),
      endDate: [this.tableListFieldOption.endDate],
      startDate: [this.tableListFieldOption.startDate],
      prioritize: [this.tableListFieldOption.prioritize, Validators.required]
    });

    this.tableListFieldOption.text.forEach(
      localization => {
        this.addNewLocalization(localization.culture, localization.text);
      }
    );
  }

  get localizations() {
    return this.form.controls["text"] as UntypedFormArray;
  }

  //Create a textLocalization form group object to add to the localizations form array
  addNewLocalization(culture?: string, text?: string) {
    const localizationForm = this.fb.group({
      culture: [culture || '', Validators.required],
      text: [text || '', Validators.required]
    });

    this.localizations.push(localizationForm);
  }

  deleteLocalization(index: number) {
    this.localizations.removeAt(index);
  }

  save() {
    let tableListFieldOptionSubmit: TableListFieldOptionSubmit = {
      asOf: moment().format(),
      changeReason: '',
      changeReasonComments: '',
      id: this.form.value.id,
      prioritize: this.form.value.prioritize,
      startDate: this.form.value.startDate,
      endDate: this.form.value.endDate,
      text: []
    }

    this.form.value.text.forEach(localization => {
      tableListFieldOptionSubmit.text.push(localization);
    });

    this.overlayService.show();

    this.lookupService.updateTableListFieldOption(this.listGroup, this.tableListFieldOption.id, tableListFieldOptionSubmit)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.dialogRef.close(true);
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  close() {
    this.dialogRef.close();
  }

}
