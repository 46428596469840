import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PacketsService {

  constructor() { }

  getPackets(): Observable<any> {
    return of(
      
        [
          { 
            id: '1',
            name: 'Onboarding'
          },
          { 
            id: '2',
            name: 'Maternity Leave'
          },
          { 
            id: '3',
            name: 'Sick Leave'
          }
        ]
      
    );
  }

  getPacket(id: string): Observable<any> {
    return of(
      [
        {
          id: '1234',
          type: 'info',
          title: 'When & Where',
          data: {
            title: 'Welcome Dave',
            text: "We are excited to have you join the team here at Your Company Name. We would like to make your onboarding as smooth as possible. So let's get you up and running. Just click on the Continue button to get started."
          }
        },
        {
          id: '4321',
          type: 'questionnaire',
          title: 'Get Introduced',
          data: {
            id: '12341234312',
            title: 'Get Introduced to The Team',
            text: "You're almost done, but first we'd like to get to know a bit more about you so we can introduce you to the team. Please answer the following questions and we'll send out a quick information email to the people you'll be working with.",
            formTitle: "Tell us a little about yourself:",
            formId: 'frm_ostZzTtkUGNYsz'
          }
        },
        {
          id: '4567',
          type: 'checklist',
          title: 'Tasks to Complete',
          data: {
            id: '214234',
            label: '',
            description: {
              title: 'Tasks to Complete',
              description: "We want to make sure that you are ready to go on your first day. Please complete the tasks below and we'll be able to get you up and running quickly. Again, if you have any questions please email or call Charlotte at 415-555-1237 ext. 1273.",
            },
            list: [
              {
                id: '234235',
                checked: true,
                task: 'Watch Company Overview Video'
              },
              {
                id: '234235',
                checked: true,
                task: 'Review and Sign W4',
                document: {
                  title: 'W4(2017)',
                  url: '/assets/fw4.pdf',
                  signatureRequired: true,
                  signed: true,
                  icon: 'description'
                }
              },
              {
                id: '234235',
                checked: false,
                task: 'Complete Direct Deposit Form',
                document: {
                  title: 'Direct Deposit Form',
                  url: '/assets/direct-deposit.pdf',
                  signatureRequired: false,
                  signed: false,
                  icon: 'description'
                }
              },
              {
                id: '234235',
                checked: false,
                task: 'Read Employee Handbook'
              },
              {
                id: '234235',
                checked: false,
                task: 'Review and Sign I9',
                document: {
                  title: 'I9',
                  url: '/assets/i-9.pdf',
                  signatureRequired: true,
                  signed: false,
                  icon: 'description'
                }
              }
            ]
          }
        }
      ]
    );
  }
}
