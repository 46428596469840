import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { api_routes, db_tables } from '@app/consts';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { CellClickEvent, GridDataResult, PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { defer, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GoalDialogComponent } from '../goal-dialog/goal-dialog.component';
import { EmployeeGoal, EmployeeGoalSubmit } from '../goal-plans/models/goal-plan.model';
import { GoalPlanService } from '../goal-plans/services/goal-plan.service';

@Component({
  selector: 'app-multiple-employee-goals',
  templateUrl: './multiple-employee-goals.component.html',
  styleUrls: ['./multiple-employee-goals.component.scss']
})
export class MultipleEmployeeGoalsComponent implements OnInit {
  @Input() employees: any[];

  public columns: any[] = [
    { field: "description", title: "Description", type: "longText" },
    { field: "expectedCompletionDate", title: "Expected Completion Date", type: "date" },
    { field: "actualCompletionDate", title: "Actual Completion Date", type: "date" },
    { field: "goalType", subField: "name", title: "Goal Type",},
    { field: "percentageComplete", title: "Percentage Complete", type: "percentage", percentageMaxValue: 1 },
  ];
  public bindingType: String = "array";
  public view: any;
  public gridData: EmployeeGoal[];
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 5;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  dialogRef: any;
  formValid: any;
  user$: any;

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private router: Router,
    private overlayService: OverlayService,
    private oidcAuthService: OidcAuthService,
    private goalService: GoalPlanService
  ) {
    this.user$ = this.oidcAuthService.userProfile;
  }

  ngOnInit(): void {
    this.view = this.getGoals();
  }

  getEmployeeName(employeeId: string): string {
    let employee = this.employees.find( employee => employee.id = employeeId );

    return `${employee?.firstname} ${employee?.lastname}`;
  }

  calculateSliderPercentage(value: string, max: number): number {
    return (parseFloat(value)/max) * (100/1);
  }

  cellClickHandler(args: CellClickEvent){
    this.openGoalDialog(args.dataItem);
    // this.emitOpenDialogEvent.emit(args.dataItem)
  }

  // Use an arrow function to capture the 'this' execution context of the class.
  public rowCallback = (context: RowClassArgs) => {
    return { clickable: true };
  };

  getGoals() {
    const observables = this.employees.map(employee => defer(() => this.goalService.getEmployeeGoals(employee.id, '100', 0)));

    this.isLoading = true;

    forkJoin(observables)
    .pipe(
      finalize(() => this.isLoading = false)
    )
    .subscribe(
      (res) => {
        let goals: EmployeeGoal[] = []

        res.forEach(
          pagedData => {
            pagedData.employeeGoals.data.map( employeeGoal => employeeGoal.employee.id = pagedData.employeeId )
            goals = goals.concat(pagedData.employeeGoals.data)
          }
        )

        this.gridData = goals;
      }
    );
  }

  getGoalVerbose(goal?: EmployeeGoal) {
    if(goal === undefined){
      this.openGoalDialog();
    }
    else {
      let employeeGoal: EmployeeGoal;

      this.isLoading = true;

      this.goalService.getEmployeeGoal(this.user$.userId, goal.id)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        res => {
          employeeGoal = res;
          this.openGoalDialog(employeeGoal);
        }
      );
    }
  }

  openGoalDialog(goal?: EmployeeGoal) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      goal: goal,
      employeeId: this.user$.userId,
      readOnly: true
    };

    this.dialogRef = this.dialog.open(GoalDialogComponent, dialogConfig);

    this.dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.getGoals();
        }
      }
    );

  }

  // openGoalDialog(goal?: EmployeeGoal) {
  //   let formData = {
  //     id: goal ? goal.id : null,
  //     description: goal ? goal.description : null,
  //     percentageComplete: goal ? goal.percentageComplete : null,
  //     expectedCompletionDate: goal ? goal.expectedCompletionDate : null,
  //     actualCompletionDate: goal ? goal.actualCompletionDate : null,
  //     goalTypeId: goal ? goal.goalType?.id : null,
  //   };

  //   const dialogConfig = new MatDialogConfig();

  //   dialogConfig.disableClose = true;
  //   dialogConfig.autoFocus = true;

  //   dialogConfig.data = {
  //     formTitle: `${this.translate.instant('Goal')}`,
  //     formId: 'frm_iel8cV73NvPu4w',
  //     formData: formData
  //   };

  //   this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

  //   const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
  //     goal ? this.updateGoal(event) : this.createNewGoal(event)
  //   });
  // }

  createNewGoal(formData: EmployeeGoalSubmit){
    this.overlayService.show();

    this.goalService.createEmployeeGoal(this.user$.userId, formData)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(res);
          this.getGoals();
        }
    );
  }

  updateGoal(formData: EmployeeGoalSubmit){
    this.overlayService.show();

    this.goalService.updateEmployeeGoal(this.user$.userId, formData)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(res);
          this.getGoals();
        }
    );
  }

  deleteGoals(goalIds: string[]) {
    const observables = goalIds.map(selectedItem => defer(() => this.goalService.deleteEmployeeGoal(this.user$.userId, selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.view = this.getGoals();
        this.clearSelectedItems = !this.clearSelectedItems;
        this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.view = this.getGoals();
  }
}
