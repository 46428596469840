<app-data-grid-comp
    [columns]="columns"
    [bindingType]="bindingType"
    [view]="view"
    [gridData]="gridData"
    [gridDataResult]="gridDataResult"
    [isLoading]="isLoading"
    [pageSize]="pageSize"
    [skip]="skip"
    category="EmploymentRecords"
    [isLoading]="isLoading"
    [clearSelectedItems]="clearSelectedItems"
    (emitPageChangeEvent)="pageChange($event)"
    (emitOpenDialogEvent)="openEmploymentRecordDialog($event)"
    (emitDeleteEvent)="deleteEmploymentRecords($event)"
></app-data-grid-comp>

