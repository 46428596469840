
<app-layout>
    <mat-toolbar class="page-header" role="heading">
      <app-welcome-message></app-welcome-message>
    </mat-toolbar>

    <div *ngIf="currentEmployeeDetails.userType === 'Employee'" class="content">
      <ng-container *appHasModuleAccess="modules.PERFORMANCE">
        <ng-container *appHasFeature="features.PERFORMANCE">
          <app-homepage-employee-goals-widget *appHasFeature="features.PERFORMANCE_MY_GOALS" [employeeId]="user$.userId"></app-homepage-employee-goals-widget>
  
          <!-- <app-employee-goals *appHasFeature="features.PERFORMANCE_MY_GOALS" [employeeId]="user$.userId"></app-employee-goals> -->
        </ng-container>
      </ng-container>

      <div class="time-off-row">
          <app-time-off-policy-widget *appHasModuleAccess="modules.TIME_OFF"
                                      (timeOffRequestCreated)="timeOffRequestCreated($event)"></app-time-off-policy-widget>
          <app-my-time-off-requests *appHasModuleAccess="modules.TIME_OFF"
                                    [refreshMyTimeOffRequest]="refreshMyTimeOffRequest"></app-my-time-off-requests>
      </div>

      <ng-container *appHasModuleAccess="modules.TOIL">
        <div class="time-off-row" *appHasFeature="features.TOIL">
          <app-toil-policy-widget 
            (toilRequestCreated)="toilRequestCreated($event)">
          </app-toil-policy-widget>
          
          <app-my-toil-requests 
            [refreshMyToilRequest]="refreshMyToilRequest">
          </app-my-toil-requests>
        </div>
      </ng-container>

      <app-schedule-widget *appHasModuleAccess="modules.TIME_OFF"></app-schedule-widget>




      <!-- <app-employee-directory-widget></app-employee-directory-widget> -->


      <!-- <app-compensation-history-widget></app-compensation-history-widget> -->
      <!-- <app-tasks-widget></app-tasks-widget> -->
      <!-- <app-time-off-widget ></app-time-off-widget> -->
      <!-- <app-inbox-widget></app-inbox-widget>
      <app-birthdays></app-birthdays>
      <app-out-of-office-widget></app-out-of-office-widget> -->
      <!-- <app-news-and-holidays-widget></app-news-and-holidays-widget> -->
    </div>
  </app-layout>
