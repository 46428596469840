import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormElement } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { finalize } from 'rxjs';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { CompanyLogo } from '@app/modules/site-settings-styling/components/company-logos/models/company-logos.model';
import { CompanyLogosService } from '@app/modules/site-settings-styling/components/company-logos/services/company-logos.service';
import { SelectCompanyLogoDialogComponent } from './components/select-company-logo-dialog/select-company-logo-dialog.component';

@Component({
  selector: 'app-company-logo-form-field',
  templateUrl: './company-logo-form-field.component.html',
  styleUrls: ['./company-logo-form-field.component.scss']
})
export class CompanyLogoFormFieldComponent implements OnInit {

  @Input() formElement: FormElement;
  @Input() parentGroup: UntypedFormGroup;
  @Input() readOnly: boolean;
  
  companyLogo: CompanyLogo;
  isLoading: boolean;
  user$: any;
  filterString: string;

  constructor(
    private companyLogosService: CompanyLogosService,
    private oidcAuthService: OidcAuthService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.user$ = this.oidcAuthService.userProfile;

    if(this.parentGroup.controls[this.formElement.formControl].value !== null && this.parentGroup.controls[this.formElement.formControl].value !== undefined){
      this.getCompanyLogo();
    }
  }

  getCompanyLogo() {
    this.isLoading = true;

    if(this.parentGroup.controls[this.formElement.formControl].value !== undefined){
      this.filterString = `(id = "${this.parentGroup.controls[this.formElement.formControl].value}")`
    }

    this.companyLogosService.getCompanyLogos(0, 1, null, this.filterString)
    .pipe(
      finalize(() => this.isLoading = false)
    )
    .subscribe(
      (res) => {
        this.companyLogo = res.data[0]
      }
    );
  }

  openSelectCompanyLogoDialog() {
    if(!this.readOnly){
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      const dialogRef = this.dialog.open(SelectCompanyLogoDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        data => {
          if (data !== undefined) {
            this.setValue(data)
          }
        }
      );
    }
  }

  clearSelectedPosition() {
    this.parentGroup.controls[this.formElement.formControl].setValue(null);
    this.companyLogo = null;
  }

  setValue(selectedCompanyLogo: CompanyLogo) {
    this.parentGroup.controls[this.formElement.formControl].setValue(selectedCompanyLogo.id);
    this.getCompanyLogo();
  }

}
