<h5 mat-dialog-title>Organization Type</h5>

<mat-dialog-content >
    <ng-container *ngIf="isLoading; else loaded">
        <div style="width:100%">
            <ngx-skeleton-loader
            count="5"
            [theme]="{ 
                'height.px': 50
            }"
            ></ngx-skeleton-loader>
        </div>
    </ng-container>
    
    <ng-template #loaded>
        <div class="form" *ngIf="form" [formGroup]="form">
            <ng-container formArrayName="localizations">
                <ng-container *ngFor="let localizationForm of localizations.controls; let i = index">
                    <div class="localization">
                        <div [formGroup]="localizationForm">
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Language
                                </mat-label>
    
                                <mat-select disableOptionCentering formControlName="culture">
                                    <mat-option *ngFor="let culture of cultures" [value]="culture.id">{{culture.text}}</mat-option>
                                </mat-select>
                            </mat-form-field>
    
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Name
                                </mat-label>
    
                                <input matInput formControlName="text">
                            </mat-form-field>
                        </div>
                        <mat-icon class="material-icons-outlined" (click)="deleteLocalization(i)" matTooltip="Delete">delete</mat-icon> 
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </ng-template>

</mat-dialog-content>

<mat-dialog-actions *ngIf="form">
    <button mat-raised-button (click)="addNewLocalization()">
        <mat-icon class="material-icons-outlined">add</mat-icon> Add Text Localization
    </button>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid">Save</button>
</mat-dialog-actions>
