<app-layout>

    <mat-card>
        <mat-card-title class="header">
            <h5 appLocalizationPopupDirective localizationCode="ReportGroup">{{ 'ReportGroup' | translate: {Default: "Report Group"} }} - {{ reportType }}</h5>

            <div>

                <button *ngIf="selectedItems.length > 0" class="delete-all-selected-button k-mr-4" mat-stroked-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openConfirmDeleteDialog()">
                    <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">delete</mat-icon>
                    Delete All Selected ({{selectedItems.length}})
                </button>

                <button mat-stroked-button setColor="primaryColour" color="primary" (click)="backToReportGroups()">
                    Back to Custom Reports
                </button>

                <button mat-flat-button setColor="primaryColour" color="primary" (click)="openNewReportDialog()" class="k-ml-4">
                    <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon>
                    Create Custom Report
                </button>

            </div>
        </mat-card-title>

        <mat-card-content>

            <app-hardcoded-reports-data-grid
                [reportType]="reportType"
            ></app-hardcoded-reports-data-grid>

            <app-custom-reports-data-grid
                [reportType]="reportType"
            ></app-custom-reports-data-grid>

            
        </mat-card-content>

    </mat-card>
</app-layout>
