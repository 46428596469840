import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';

@Component({
  selector: 'app-leave-request-dialog',
  templateUrl: './leave-request-dialog.component.html',
  styleUrls: ['./leave-request-dialog.component.scss']
})
export class LeaveRequestDialogComponent implements OnInit {
  form: UntypedFormGroup;

  leaveTypeOptions = [
    'Annual Leave',
    'Sick Leave',
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private dialogRef: MatDialogRef<LeaveRequestDialogComponent>,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      status: [{value:'Pending', disabled: true}, Validators.required],
      calendarDays: [null, Validators.required],
      workDays: [null, Validators.required],
      manager: [null, Validators.required],
      notice: [null],
    });
  }

  save(){
    this.overlayService.show();

    setTimeout(() => {
      this.overlayService.hide();
      this.snackbarService.openSnackBar('Leave request submitted successfully', 'clear', 'success');
      this.dialogRef.close();
    }, 2000);
  }

  close(){
    this.dialogRef.close();
  }

}
