<div class="schedule">
    <div class="top">
        <h6 appLocalizationPopupDirective [localizationCode]="'Schedule'">{{ 'Schedule' | translate: {Default: "Schedule"} }}</h6>

        <div class="buttons">
            <button mat-raised-button (click)="dateChange('back')">
                <mat-icon class="material-icons-outlined">arrow_back_ios</mat-icon>
            </button>
            <button mat-raised-button (click)="dateChange('forward')">
                <mat-icon class="material-icons-outlined">arrow_forward_ios</mat-icon>
            </button>
        </div>
    </div>

    
    <ng-container *ngIf="isLoading; else loaded">
        <div class="loading">
            <ngx-skeleton-loader
                count="5"
                [theme]="{ 
                    'height.px': 50,
                    'background-color': 'white',
                    border: '1px solid white'
                }"
            ></ngx-skeleton-loader>
        </div>
    </ng-container>
    <ng-template #loaded>
        <div class="day" [ngClass]="{ 'currentDay': date.isToday }" *ngFor="let date of datesArray">
            <div class="top">
                <div class="date">
                    {{ date.monthAndYear }}
                </div>

                <span *ngIf="date.isToday" class="dot"></span>
            </div>
    
            <mat-chip-list class="mat-chip-list-stacked">
                <mat-chip class="event" [ngClass]="event.description" *ngFor="let event of date.events" selected>
                    <mat-icon *ngIf="event.description === 'StatutoryHoliday'" class="material-icons-outlined  public-holiday">celebration</mat-icon>
                    <mat-icon *ngIf="event.description === 'Leave'" class="material-icons-outlined  absence">date_range</mat-icon>
                    <mat-icon *ngIf="event.description === 'Work'" class="material-icons-outlined  company-event">work</mat-icon>
                    
                    {{event.title}}
                </mat-chip>
            </mat-chip-list>
        </div>
    </ng-template>
</div>