<mat-card>
    <mat-card-title class="header">
        <h5 appLocalizationPopupDirective localizationCode="EmploymentRecord-Compensation">{{ 'EmploymentRecord-Compensation' | translate: {Default: "Employment Record: Compensation"} }}</h5>
        <div>
            <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button> 
        </div>
    </mat-card-title>
    <mat-card-content>
        <form class="form" *ngIf="form" [formGroup]="form">
            <div class="form-container">
                
                <!-- <div class="form-section">
                    <div class="form-section-title">
                        <h6>Time Period</h6>
                    </div>
                    <div class="form-section-contents">
                        <mat-form-field  appearance="fill">
                            <mat-label>
                                Start Date
                            </mat-label>
                        
                            <input matInput [matDatepicker]="startDatepicker" formControlName="startDate">
                            <span fxLayout="row" matSuffix>
                                <mat-datepicker-toggle  [for]="startDatepicker"></mat-datepicker-toggle>
                            </span>
                            <mat-datepicker #startDatepicker></mat-datepicker> 
                            <mat-hint></mat-hint>
                        </mat-form-field>
                    
                        <mat-form-field  appearance="fill">
                            <mat-label>
                                End Date
                            </mat-label>
                        
                            <input matInput [matDatepicker]="endDatepicker" formControlName="endDate">
                            <span fxLayout="row" matSuffix>
                                <mat-datepicker-toggle  [for]="endDatepicker"></mat-datepicker-toggle>
                            </span>
                            <mat-datepicker #endDatepicker></mat-datepicker> 
                            <mat-hint></mat-hint>
                        </mat-form-field>
                    </div>
                </div> -->
                
                <div class="form-section">
                    <div class="form-section-title">
                        <h6>Base Salary</h6>
                    </div>
                    <div class="form-section-contents">
                        <div class="section-row">
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Base Salary
                                </mat-label>
                                
                                <input matInput
                                type="number"
                                formControlName="baseSalary">
                            </mat-form-field>
                            
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Base Salary Currency
                                </mat-label>
                        
                                <mat-select disableOptionCentering formControlName="baseSalaryCurrency">
                                    <mat-option *ngFor="let currencyOption of currencyOptions | async" [value]="currencyOption.id">{{currencyOption.text}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="section-row">
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Base Salary Local
                                </mat-label>
                        
                                <input matInput
                                    type="number"
                                    formControlName="baseSalaryLocal">
                            </mat-form-field>
                            
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Base Salary Local Currency
                                </mat-label>
                        
                                <mat-select disableOptionCentering formControlName="baseSalaryLocalCurrency">
                                    <mat-option *ngFor="let currencyOption of currencyOptions | async" [value]="currencyOption.id">{{currencyOption.text}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="section-row">
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Base Salary Other
                                </mat-label>
                        
                                <input matInput
                                    type="number"
                                    formControlName="baseSalaryOther">
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Base Salary Other Currency
                                </mat-label>
                        
                                <mat-select disableOptionCentering formControlName="baseSalaryOtherCurrency">
                                    <mat-option *ngFor="let currencyOption of currencyOptions | async" [value]="currencyOption.id">{{currencyOption.text}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="section-row">
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Base Salary Non Gmg
                                </mat-label>
                        
                                <input matInput
                                    type="number"
                                    formControlName="baseSalaryNonGmg">
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Base Salary Non Gmg Currency
                                </mat-label>
                        
                                <mat-select disableOptionCentering formControlName="baseSalaryNonGmgCurrency">
                                    <mat-option *ngFor="let currencyOption of currencyOptions | async" [value]="currencyOption.id">{{currencyOption.text}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="form-section">
                    <div class="form-section-title">
                        <h6>Rates</h6>
                    </div>
                    <div class="form-section-contents">
                        <div class="section-row">
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Hourly Rate
                                </mat-label>
                        
                                <input matInput
                                    type="number"
                                    formControlName="hourlyRate">
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Hourly Rate Currency
                                </mat-label>
                        
                                <mat-select disableOptionCentering formControlName="hourlyRateCurrency">
                                    <mat-option *ngFor="let currencyOption of currencyOptions | async" [value]="currencyOption.id">{{currencyOption.text}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="section-row">
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Daily Rate
                                </mat-label>
                                
                                <input matInput
                                type="number"
                                formControlName="dailyRate">
                            </mat-form-field>
                            
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Daily Rate Currency
                                </mat-label>
                        
                                <mat-select disableOptionCentering formControlName="dailyRateCurrency">
                                    <mat-option *ngFor="let currencyOption of currencyOptions | async" [value]="currencyOption.id">{{currencyOption.text}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="section-row">
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Monthly Rate
                                </mat-label>
                                
                                <input matInput
                                type="number"
                                formControlName="monthlyRate">
                            </mat-form-field>
                            
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Monthly Rate Currency
                                </mat-label>
                        
                                <mat-select disableOptionCentering formControlName="monthlyRateCurrency">
                                    <mat-option *ngFor="let currencyOption of currencyOptions | async" [value]="currencyOption.id">{{currencyOption.text}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="form-section">
                    <div class="form-section-title">
                        <h6>Pay Details</h6>
                    </div>
                    <div class="form-section-contents">
                        <!-- <mat-form-field appearance="fill">
                            <mat-label>
                                Currency
                            </mat-label>
                    
                            <mat-select disableOptionCentering formControlName="currency">
                                <mat-option *ngFor="let currencyOption of currencyOptions | async" [value]="currencyOption.id">{{currencyOption.text}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>
                                Amount
                            </mat-label>
                    
                            <input matInput
                                type="number"
                                formControlName="amount">
                        </mat-form-field> -->
                        
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Term
                            </mat-label>
                    
                            <mat-select disableOptionCentering formControlName="term">
                                <mat-option *ngFor="let termOption of termOptions | async" [value]="termOption.id">{{termOption.text}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Market View
                            </mat-label>
                    
                            <mat-select disableOptionCentering formControlName="marketView">
                                <mat-option *ngFor="let marketViewOption of marketViewOptions | async" [value]="marketViewOption.id">{{marketViewOption.text}}</mat-option>
                            </mat-select>
                        </mat-form-field>
               
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Pay Type
                            </mat-label>
                    
                            <mat-select disableOptionCentering formControlName="payType">
                                <mat-option *ngFor="let payTypeOption of payTypeOptions | async" [value]="payTypeOption.id">{{payTypeOption.text}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Pay Grade
                            </mat-label>
                    
                            <mat-select disableOptionCentering formControlName="payGrade">
                                <mat-option *ngFor="let payGradeOption of payGradeOptions | async" [value]="payGradeOption.id">{{payGradeOption.text}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>
                                Pay Scale
                            </mat-label>
                    
                            <mat-select disableOptionCentering formControlName="payScale">
                                <mat-option *ngFor="let payScaleOption of payScaleOptions | async" [value]="payScaleOption.id">{{payScaleOption.text}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Pay Level
                            </mat-label>
                    
                            <mat-select disableOptionCentering formControlName="payLevel">
                                <mat-option *ngFor="let payLevelOption of payLevelOptions | async" [value]="payLevelOption.id">{{payLevelOption.text}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Pay Frequency
                            </mat-label>
                    
                            <mat-select disableOptionCentering formControlName="payFrequency">
                                <mat-option *ngFor="let payFrequencyOption of payFrequencyOptions | async" [value]="payFrequencyOption.id">{{payFrequencyOption.text}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>
                                Compensation Ratio
                            </mat-label>
                    
                            <input matInput
                                type="number"
                                formControlName="compensationRatio">
                        </mat-form-field>
                    </div>
                </div>

                <div class="form-section">
                    <div class="form-section-title">
                        <h6>Overtime</h6>
                    </div>
                    <div class="form-section-contents">
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Overtime Eligible
                            </mat-label>
                    
                            <mat-select disableOptionCentering formControlName="overtimeEligible">
                                <mat-option *ngFor="let overtimeEligibleOption of overtimeEligibleOptions | async" [value]="overtimeEligibleOption.id">{{overtimeEligibleOption.text}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            
                <div class="form-section">
                    <div class="form-section-title">
                        <h6>Role</h6>
                    </div>
                    <div class="form-section-contents">
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Role Group
                            </mat-label>
                    
                            <mat-select disableOptionCentering formControlName="roleGroup">
                                <mat-option *ngFor="let roleGroupOption of roleGroupOptions | async" [value]="roleGroupOption.id">{{roleGroupOption.text}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Role Category
                            </mat-label>
                    
                            <mat-select disableOptionCentering formControlName="roleCategory">
                                <mat-option *ngFor="let roleCategoryOption of roleCategoryOptions | async" [value]="roleCategoryOption.id">{{roleCategoryOption.text}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            
                <div class="form-section">
                    <div class="form-section-title">
                        <h6>Short Term Incentive</h6>
                    </div>
                    <div class="form-section-contents">
                        <mat-form-field appearance="fill">
                            <mat-label>
                                STI Incentive Plan
                            </mat-label>
                    
                            <mat-select disableOptionCentering formControlName="stiIncentivePlan">
                                <mat-option *ngFor="let stiIncentivePlanOption of stiIncentivePlanOptions | async" [value]="stiIncentivePlanOption.id">{{stiIncentivePlanOption.text}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                STI Currency
                            </mat-label>
                    
                            <mat-select disableOptionCentering formControlName="stiCurrency">
                                <mat-option *ngFor="let currencyOption of currencyOptions | async" [value]="currencyOption.id">{{currencyOption.text}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>
                                STI Target Amount
                            </mat-label>
                    
                            <input matInput
                                type="number"
                                formControlName="stiTargetAmount">
                        </mat-form-field>
                    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                STI Actual Amount
                            </mat-label>
                    
                            <input matInput
                                type="number"
                                formControlName="stiActualAmount">
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>
                                STI Target Percentage
                            </mat-label>
                    
                            <input matInput
                                type="number"
                                formControlName="stiTargetPercentage">
                        </mat-form-field>
                    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                STI Actual Percentage
                            </mat-label>
                    
                            <input matInput
                                type="number"
                                formControlName="stiActualPercentage">
                        </mat-form-field>
                    </div>
                </div>
                
                <div class="form-section">
                    <div class="form-section-title">
                        <h6>Long Term Incentive</h6>
                    </div>
                    <div class="form-section-contents">
                        <mat-form-field appearance="fill">
                            <mat-label>
                                LTI Psu
                            </mat-label>
                    
                            <mat-select disableOptionCentering formControlName="ltiPsu">
                                <mat-option *ngFor="let ltiPsuOption of ltiPsuOptions | async" [value]="ltiPsuOption.id">{{ltiPsuOption.text}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                LTI Stock Option
                            </mat-label>
                    
                            <mat-select disableOptionCentering formControlName="ltiStockOption">
                                <mat-option *ngFor="let ltiStockOptionOption of ltiStockOptionOptions | async" [value]="ltiStockOptionOption.id">{{ltiStockOptionOption.text}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                LTI Rsu
                            </mat-label>
                    
                            <mat-select disableOptionCentering formControlName="ltiRsu">
                                <mat-option *ngFor="let ltiRsuOption of ltiRsuOptions | async" [value]="ltiRsuOption.id">{{ltiRsuOption.text}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            
                <div class="form-section">
                    <div class="form-section-title">
                        <h6>Comments</h6>
                    </div>
                    <div class="form-section-contents">
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Comments
                            </mat-label>
                    
                            <textarea matInput formControlName="comments"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </mat-card-content>
</mat-card>