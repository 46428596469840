<app-employee-details>
    <ng-container *ngIf="isLoadingPermissions; else loaded">
        <ngx-skeleton-loader
          count="5"
          [theme]="{ 
              'height.px': 50
          }"
        ></ngx-skeleton-loader>
    </ng-container>
    <ng-template #loaded>
        <mat-card class="mat-elevation-z0">
            <mat-card-title class="header">
                <div class="k-d-flex k-d-flex-row k-align-items-center">
                    <h5 class="k-flex-grow" appLocalizationPopupDirective localizationCode="Compensation">{{ 'Compensation' | translate: {Default: "Compensation"} }}</h5>
        
                    <mat-form-field appearance="fill" class="k-mr-2">
                        <mat-label>
                            {{ 'EffectiveDate' | translate: {Default: "Effective Date"} }}
                        </mat-label>
        
                        <input matInput [matDatepicker]="effectiveDatePicker" (dateChange)="effectiveDateChange($event)" [(ngModel)]="today">
                        <span fxLayout="row" matSuffix>
                            <mat-datepicker-toggle [for]="effectiveDatePicker"></mat-datepicker-toggle>
                        </span>
                        <mat-datepicker #effectiveDatePicker></mat-datepicker>
                    </mat-form-field>
        
                    <mat-icon 
                        setColor 
                        [setColorRules]="[
                            {
                                color: 'primaryColour',
                                events: ['hover']
                            }
                        ]"
                        aria-hidden="false" 
                        class="material-icons-outlined k-mr-3" 
                        aria-label="add icon"
                        (click)="$event.stopPropagation(); openAccessLogsDialog()"
                    >
                        fingerprint
                    </mat-icon>
                    <mat-icon 
                    setColor 
                    [setColorRules]="[
                        {
                            color: 'primaryColour',
                            events: ['hover']
                        }
                    ]"
                    aria-hidden="false"
                    class="material-icons-outlined k-mr-3" 
                    aria-label="add icon"
                    (click)="$event.stopPropagation(); openHistoryDialog()"
                    >
                        history
                    </mat-icon>
        
                    <button
                            mat-raised-button
                            setColor="primaryColour" 
                            color="primary"
                            [disabled]="formValid === false"
                            appPreventDoubleClick 
                            (throttledClick)="getFormData = !getFormData"
                            *appTableAccess="[tableCreateAccessObj, tableUpdateAccessObj]">
                        {{ 'Save' | translate: {Default: "Save"} }}
                    </button>
                </div>
            </mat-card-title>
            <mat-card-content>
                <div *ngIf="isLoading">
                    <ngx-skeleton-loader
                    count="5"
                    [theme]="{
                        'height.px': 50
                    }"
                    ></ngx-skeleton-loader>
                </div>
        
                <div *ngIf="!isLoading">
                    <div class="form-section">
                        <div class="form-section-title">
                            <h6 appLocalizationPopupDirective localizationCode="CalculatedValues">{{ 'CalculatedValues' | translate: {Default: "Calculated Values"} }}</h6>
                        </div>
                        <div class="form-section-contents calculated-fields-container">
        
                            <div class="bottom" appLocalizationPopupDirective localizationCode="CompensationValuesWillBeRecalulated">
                                {{ 'CompensationValuesWillBeRecalulated' | translate: {Default: "These values will be recalulated when you save new values."} }}
                            </div>
        
                            <div class="calculated-fte" *ngIf="calculatedFTE" appLocalizationPopupDirective localizationCode="CalculatedFTE">
                                <span setColor="primaryColour" class="label">{{ 'CalculatedFTE' | translate: {Default: "Calculated FTE"} }}:</span> {{calculatedFTE}}
                            </div>
        
                            <div class="top">
                                <div class="sub-container" *ngIf="salarySystemCurrency && salarySystem">
                                    <div class="calculated-fields-label" appLocalizationPopupDirective localizationCode="SalarySystem">{{ 'SalarySystem' | translate: {Default: "Salary System"} }}</div>
                                    <div class="calculated-fields-amount">
                                        <div setColor="primaryColour" class="type" appLocalizationPopupDirective localizationCode="FullFTE">
                                            {{ 'FullFTE' | translate: {Default: "Full FTE"} }}
                                        </div>
                                        <div class="value">
                                          {{salarySystemCurrency | currencyDisplay: salarySystem}}
                                        </div>
                                    </div>
                                    <div class="calculated-fields-amount">
                                        <div setColor="primaryColour" class="type" appLocalizationPopupDirective localizationCode="AdjustedToFTE">
                                            {{ 'AdjustedToFTE' | translate: {Default: "Adjusted To FTE"} }}
                                        </div>
                                        <div class="value">
                                          {{salarySystemCurrency | currencyDisplay: salarySystemFteAdjusted}}
                                        </div>
                                    </div>
                                </div>
        
                                <div class="sub-container" *ngIf="payRateCurrency && baseSalary">
                                    <div class="calculated-fields-label" appLocalizationPopupDirective localizationCode="BaseSalary">{{ 'BaseSalary' | translate: {Default: "Base Salary"} }}</div>
                                    <div class="calculated-fields-amount">
                                        <div setColor="primaryColour" class="type" appLocalizationPopupDirective localizationCode="FullFTE">
                                            {{ 'FullFTE' | translate: {Default: "Full FTE"} }}
                                        </div>
                                        <div class="value">
                                          {{payRateCurrency | currencyDisplay: baseSalary}}
                                        </div>
                                    </div>
                                    <div class="calculated-fields-amount">
                                        <div setColor="primaryColour" class="type" appLocalizationPopupDirective localizationCode="AdjustedToFTE">
                                            {{ 'AdjustedToFTE' | translate: {Default: "Adjusted To FTE"} }}
                                        </div>
                                        <div class="value">
                                          {{payRateCurrency | currencyDisplay: baseSalaryFteAdjusted}}
                                        </div>
                                    </div>
                                </div>
        
                                <div class="sub-container" *ngIf="salaryOtherCurrency && salaryOther">
                                    <div class="calculated-fields-label" appLocalizationPopupDirective localizationCode="SalaryOther">{{ 'SalaryOther' | translate: {Default: "Salary Other"} }}</div>
                                    <div class="calculated-fields-amount">
                                        <div setColor="primaryColour" class="type" appLocalizationPopupDirective localizationCode="FullFTE">
                                            {{ 'FullFTE' | translate: {Default: "Full FTE"} }}
                                        </div>
                                        <div class="value">
                                          {{salaryOtherCurrency | currencyDisplay: salaryOther}}
                                        </div>
                                    </div>
                                    <div class="calculated-fields-amount">
                                        <div setColor="primaryColour" class="type" appLocalizationPopupDirective localizationCode="AdjustedToFTE">
                                            {{ 'AdjustedToFTE' | translate: {Default: "Adjusted To FTE"} }}
                                        </div>
                                        <div class="value">
                                          {{salaryOtherCurrency | currencyDisplay: salaryOtherFteAdjusted}}
                                        </div>
                                    </div>
                                </div>
        
                                <div class="sub-container" *ngIf="salaryNonGmgCurrency && salaryNonGmg">
                                    <div class="calculated-fields-label" appLocalizationPopupDirective localizationCode="SalaryNonGMG">{{ 'SalaryNonGMG' | translate: {Default: "Salary Non GMG"} }}</div>
                                    <div class="calculated-fields-amount">
                                        <div setColor="primaryColour" class="type" appLocalizationPopupDirective localizationCode="FullFTE">
                                            {{ 'FullFTE' | translate: {Default: "Full FTE"} }}
                                        </div>
                                        <div class="value">
                                          {{salaryNonGmgCurrency | currencyDisplay: salaryNonGmg}}
                                        </div>
                                    </div>
                                    <div class="calculated-fields-amount" appLocalizationPopupDirective localizationCode="AdjustedToFTE">
                                        <div setColor="primaryColour" class="type">
                                            {{ 'AdjustedToFTE' | translate: {Default: "Adjusted To FTE"} }}
                                        </div>
                                        <div class="value">
                                          {{salaryNonGmgCurrency | currencyDisplay: salaryNonGmgFteAdjusted}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <app-form-generator
                        [formId]="formId"
                        [formData]="formData"
                        [emitFormDataTrigger]="getFormData"
                        [targetEmployeeTablePermissions]="targetEmployeeTablePermissions"
                        (emitFormData)="formDataEmitted($event)"
                        (emitFormStatus)="formStatusUpdated($event)"
                    ></app-form-generator>
                </div>
            </mat-card-content>
        </mat-card>
    </ng-template>
</app-employee-details>
