import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-goal-plan-rating-comment',
  templateUrl: './goal-plan-rating-comment.component.html',
  styleUrls: ['./goal-plan-rating-comment.component.scss']
})
export class GoalPlanRatingCommentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
