<div class="main-container">
    <ng-template [ngIf]="workRotations && !isLoading">
        <div class="accordian-container">
            <mat-accordion class="example-headers-align" multi>
                <mat-expansion-panel *ngFor="let workRotation of workRotations; let first = first;" expanded="false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div class="label">{{workRotation.name}}</div>
                        </mat-panel-title>
                        <mat-panel-description>
                            <span></span>
                            <div>
                                <mat-icon
                                        class="material-icons-outlined"
                                        appPreventDoubleClick (throttledClick)="openConfirmDeleteDialog(workRotation.id); $event.stopPropagation();"
                                        matTooltip="Delete"
                                >delete
                                </mat-icon>
                                <mat-icon
                                        class="material-icons-outlined"
                                        appPreventDoubleClick (throttledClick)="openWorkRotationDialog(workRotation.id); $event.stopPropagation();"
                                        matTooltip="Edit"
                                >edit
                                </mat-icon>
                            </div>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

<!--                                    <div>-->
<!--                                        <div class="detail-label">Positions Assigned: <span class="detail-value">{{workRotation.positionsAssignedRotation}}</span></div>-->
<!--                                        <div class="detail-label">Total Days: <span class="detail-value">{{workRotation.totalDays}}</span></div>-->
<!--                                        <div class="detail-label">Total Working Hours: <span class="detail-value">{{workRotation.totalWorkingHours}}</span></div>-->
<!--                                    </div>-->

                    <app-work-rotation-elements-list
                            [elements]="workRotation.workRotationElements" [workRotationId]="workRotation.id"
                    ></app-work-rotation-elements-list>

                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </ng-template>
    <!--    <ng-template [ngIf]="workRotations.error">Error {{ obs.error }}</ng-template>-->
    <ng-template [ngIf]="isLoading">
        <ngx-skeleton-loader
                count="5"
                [theme]="{
                'height.px': 50
            }"
        ></ngx-skeleton-loader>
    </ng-template>
    <ng-template [ngIf]="!isLoading && !workRotations && workRotations.length == 0">
        <div class="no-workRotations-container">
            <h6>No Work Rotations</h6>
        </div>
    </ng-template>
</div>
