import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormElement } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { finalize } from 'rxjs/operators';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { EmployeeSecurityService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-security/services/employee-security.service';
import { GoalPlanService } from '@app/modules/performance/components/goal-plans/services/goal-plan.service';
import { Goal } from '@app/modules/performance/components/goal-plans/models/goal-plan.model';
import { SelectGoalDialogComponent } from './components/select-goal-dialog/select-goal-dialog.component';

@Component({
  selector: 'app-goal-selection-form-field',
  templateUrl: './goal-selection-form-field.component.html',
  styleUrls: ['./goal-selection-form-field.component.scss']
})
export class GoalSelectionFormFieldComponent implements OnInit {
  @Input() formElement: FormElement;
  @Input() parentGroup: UntypedFormGroup;
  @Input() readOnly: boolean;
  @Input() mandatoryFilter: string;
  @ViewChild('goalSelect') goalSelect;
  goalOptions: Goal[] = [];
  isLoading: boolean;
  user$: any;
  filterString: string;

  constructor(
    private goalService: GoalPlanService,
    private oidcAuthService: OidcAuthService,
    private employeeSecurityService: EmployeeSecurityService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.user$ = this.oidcAuthService.userProfile;

    if(this.parentGroup.controls[this.formElement.formControl].value !== null){
      this.getGoal();
    }
  }

  getGoal() {
    this.isLoading = true;

    if(this.parentGroup.controls[this.formElement.formControl].value !== undefined){
      this.filterString = `(id = "${this.parentGroup.controls[this.formElement.formControl].value}")`
    }

    this.goalService.getAllGoals('10', 0, null, this.filterString)
    .pipe(
      finalize(() => this.isLoading = false)
    )
    .subscribe(
      (res) => {
        this.goalOptions = res.data
      }
    );
  }

  openSelectGoalDialog() {
    this.goalSelect.close();

    if(!this.readOnly){
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      dialogConfig.data = {
        mandatoryFilter: this.mandatoryFilter
      }

      const dialogRef = this.dialog.open(SelectGoalDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        data => {
          if (data !== undefined) {
            this.setValue(data)
          }
        }
      );
    }
  }

  clearSelectedPosition() {
    this.goalSelect.close();
    this.parentGroup.controls[this.formElement.formControl].setValue(null);
  }

  setValue(selectedGoal: Goal) {
    this.parentGroup.controls[this.formElement.formControl].setValue(selectedGoal.id);
    this.getGoal();
  }

}

