<div class="chart-container">
    <div class="chart" echarts [options]="chart"></div>
    <div class="chart-figures">
        <div class="row days-remaining">
            <div class="dot"></div>
            <div>
                <div class="label"> Gross Amount</div>
                <!-- <div class="value">11 Days</div> -->
            </div>
        </div>
        <div class="row days-used">
            <div class="dot"></div>
            <div>
                <div class="label"> Deductions</div>
                <!-- <div class="value">3 Days</div> -->
            </div>
        </div>
    </div>
</div>