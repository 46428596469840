<div class="content-container">
    <div class="top-container">
        <ngx-extended-pdf-viewer [src]="url" [showUnverifiedSignatures]="true" useBrowserLocale="true" height="100%"></ngx-extended-pdf-viewer>
    </div>
    
    <!-- <div class="bottom-container">
        <div>
            <button mat-raised-button #typeSignature color="warn">Save Form</button>
        </div>
        <div class="signature-container">
            <signature-pad *ngIf="showSignature; else type" #signature [options]="signaturePadOptions" (drawStart)="drawStart($event)" (drawEnd)="drawComplete($event)"></signature-pad>
            <ng-template #type>
                <input matInput placeholder="Type Signature" >
            </ng-template>
            <div class="signature-buttons">
                <div>
                    <button mat-stroked-button [ngClass]="{'selected': showSignature }" (click)="showSignature = true;">Sign</button>
                    <button mat-stroked-button [ngClass]="{'selected': !showSignature }" (click)="showSignature = false;">Type</button>
                </div>
                <button mat-stroked-button (click)="clearSignature()">Clear</button>
            </div>
        </div>
    </div> -->
</div>
