import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PacketsService } from '../../services/packets.service';

@Component({
  selector: 'app-packet',
  templateUrl: './packet.component.html',
  styleUrls: ['./packet.component.scss']
})
export class PacketComponent implements OnInit {
  packetId: string;
  packet$: any;
  selectedIndex: number = 0;
  dialog: any;

  constructor(
    private packetsService: PacketsService,
    private route: ActivatedRoute
  ) { 
    this.packetId = this.getIdInURL();
    this.packet$ = this.packetsService.getPacket(this.packetId);
  }

  ngOnInit(): void {
    
  }

  getIdInURL(): string {
    let IdInURL: string;
    
    this.route.paramMap.subscribe(
      params => IdInURL = params.get("id")
    )
      
    return IdInURL;
  }

  selectNextTab(): void {
    this.selectedIndex = this.selectedIndex+1;
  }

  selectPreviousTab(): void {
    this.selectedIndex = this.selectedIndex-1;
  }
}
