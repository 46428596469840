<h5 mat-dialog-title>Form Element</h5>

<mat-dialog-content>
    <ng-container  *ngIf="!form; else LoadedFormElement">
        <div class="loading-container" style="width:100%;">
            <ngx-skeleton-loader
                count="5"
                [theme]="{ 
                'height.px': 50
                }"
            ></ngx-skeleton-loader>
        </div>
    </ng-container>

    <ng-template #LoadedFormElement>
        <form class="form" *ngIf="form" [formGroup]="form">
            

            <div class="form-section">
                <div class="form-section-title">
                    <h6>Text Localizations *</h6>
                    
                </div>
                <div class="form-section-contents">
                    <div class="section-row">
                        <ng-container formArrayName="name">
                            <ng-container *ngFor="let localizationForm of localizations.controls; let i = index">
                                <div class="form-row">
                                    <div [formGroup]="localizationForm">
                                        <mat-form-field appearance="fill">
                                            <mat-label>
                                                Select Language
                                            </mat-label>
                
                                            <mat-select required="True" disableOptionCentering formControlName="culture">
                                                <mat-option *ngFor="let culture of cultures" [value]="culture.id">{{culture.text}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                
                                        <mat-form-field appearance="fill">
                                            <mat-label>
                                                Name
                                            </mat-label>
                
                                            <input matInput required="True"
                                                formControlName="text">
                                        </mat-form-field>
                                    </div>
                                    <mat-icon 
                                    setColor 
                                    [setColorRules]="[
                                        {
                                            color: 'primaryColour',
                                            events: ['hover']
                                        }
                                    ]" 
                                    class="material-icons-outlined clickable-icon" 
                                    (click)="deleteLocalization(i)" 
                                    matTooltip="Delete">delete</mat-icon> 
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="form-section">
                <div class="form-section-title">
                    <h6>Table Field Details</h6>
                </div>
                <div class="form-section-contents">
                    <div class="section-row">
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Table Field
                            </mat-label>
                    
                            <mat-select (selectionChange)="getTableFieldDetails()" disableOptionCentering formControlName="tableField">
                                <ng-container *ngIf="tableFields | async as tableFields">
                                    <mat-option></mat-option>
                                    <mat-option *ngFor="let item of tableFields.data" [value]="item.id">
                                        {{item.name}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div *ngIf="formFieldId" class="section-row">
                        <ng-container *ngIf="loadingTableField; else loadedTableField">
                            <div style="width:100%; padding: 10px;">
                                <ngx-skeleton-loader
                                  count="5"
                                  [theme]="{ 
                                    'height.px': 50
                                  }"
                                ></ngx-skeleton-loader>
                            </div>
                        </ng-container>
                        
                        <ng-template #loadedTableField>
                            <div *ngIf="selectedFieldVerbose && !loadingTableField" class="selected-field-form">
                                <div class="page-description-section">
                                    These table field values will take precedence over the form element values. This includes name, hide/show and required values.
                                </div>

                                <h6>Table Field Values</h6>

                                <button mat-stroked-button setColor="primaryColour" color="primary" (click)="navigateToTableFieldEditor()">
                                    <mat-icon class="material-icons-outlined">edit</mat-icon> Edit Table Field
                                </button>

                                <app-form-generator 
                                    [formId]="selectedFieldVerboseFormId"
                                    [formData]="selectedFieldVerboseFormData"
                                ></app-form-generator>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
    
            <div class="form-section">
                <div class="form-section-title">
                    <h6></h6>
                </div>
                <div class="form-section-contents">
                    <div class="section-row">
                        <mat-form-field appearance="fill">
                            <mat-label>
                                {{ 'FormElementFormElementType' | translate: {Default: "Form Element Type"} }}
                            </mat-label>
                    
                            <mat-select disableOptionCentering formControlName="formElementType" (selectionChange)="onFormElementTypeSelection($event)">
                                <mat-option></mat-option>
                                <mat-option 
                                    *ngFor="let option of formElementTypes" 
                                    [value]="option.id">
                                        {{option.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>
                                Parent Element
                            </mat-label>
                    
                            <mat-select disableOptionCentering formControlName="parentFormElement">
                                <ng-container *ngIf="parentElements">
                                    <mat-option value=""></mat-option>
                                    <mat-option *ngFor="let item of parentElements" [value]="item.id">
                                        {{item.text}} - {{item.id}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
    
            <div class="form-section">
                <div class="form-section-title">
                    <h6>Properties</h6>
                </div>
                <div class="form-section-contents properties">
                    <div class="section-row">
                        <ng-container formArrayName="properties">
                            <ng-container *ngFor="let propertyForm of properties.controls; let i = index">
                                <div class="form-row">
                                    <div [formGroup]="propertyForm">
                                        <ng-container *ngIf="propertyForm.value.property === 'lookupGroup'">
                                            <mat-form-field appearance="fill">
                                                <mat-label>
                                                    Dropdown List
                                                </mat-label>
                                        
                                                <mat-select disableOptionCentering formControlName="value">
                                
                                                        <ng-container *ngIf="tableLists | async as tableLists">
                                                            <mat-optgroup *ngFor="let tableList of tableLists" [label]="tableList.name">
                                                                <mat-option *ngFor="let item of tableList.fields" [value]="item.properties[1].value">
                                                                    {{tableList.name}} - {{item.name}}
                                                                </mat-option>
                                                            </mat-optgroup>
                                                        </ng-container>
                                
                                                </mat-select>
                                            </mat-form-field>
                                        </ng-container>
    
                                        <ng-container *ngIf="propertyForm.value.property === 'requiredField'">
                                            <div class="toggle">
                                                <mat-label>
                                                    Required Field
                                                </mat-label>
                                                <mat-slide-toggle setColor="primaryColour" formControlName="value"></mat-slide-toggle>
                                            </div>
                                        </ng-container>
                        
                                        <ng-container *ngIf="propertyForm.value.property === 'disabled'">
                                            <div class="toggle">
                                                <mat-label>
                                                    Global Read Only
                                                </mat-label>
                                                <mat-slide-toggle setColor="primaryColour" formControlName="value"></mat-slide-toggle>
                                            </div>
                                        </ng-container>
                        
                                        <ng-container *ngIf="propertyForm.value.property === 'hidden'">
                                            <div class="toggle">
                                                <mat-label>
                                                    Global Hidden
                                                </mat-label>
                                                <mat-slide-toggle setColor="primaryColour" formControlName="value"></mat-slide-toggle>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="propertyForm.value.property === 'minLength'">
                                            <mat-form-field appearance="fill">
                                                <mat-label>
                                                    Minimum Value
                                                </mat-label>
                                                <input matInput type="number" formControlName="value">
                                            </mat-form-field>
                                        </ng-container>

                                        <ng-container *ngIf="propertyForm.value.property === 'maxLength'">
                                            <mat-form-field appearance="fill">
                                                <mat-label>
                                                    Maximum Value
                                                </mat-label>
                                                <input matInput type="number" formControlName="value">
                                            </mat-form-field>
                                        </ng-container>
    
                                        <ng-container 
                                            *ngIf="propertyForm.value.property !== 'hidden' &&
                                                propertyForm.value.property !== 'disabled' &&
                                                propertyForm.value.property !== 'requiredField' &&
                                                propertyForm.value.property !== 'minLength' &&
                                                propertyForm.value.property !== 'maxLength' &&
                                                propertyForm.value.property !== 'lookupGroup'">
                                            <mat-form-field appearance="fill">
                                                <mat-label>
                                                    Property
                                                </mat-label>
                    
                                                <input matInput
                                                    formControlName="property">
                                            </mat-form-field>
                    
                                            <mat-form-field appearance="fill">
                                                <mat-label>
                                                    Value
                                                </mat-label>
                    
                                                <input matInput
                                                    formControlName="value">
                                            </mat-form-field>
                                            <mat-icon 
                                            setColor 
                                            [setColorRules]="[
                                                {
                                                    color: 'primaryColour',
                                                    events: ['hover']
                                                }
                                            ]" 
                                            class="material-icons-outlined clickable-icon" 
                                            (click)="deleteProperty(i)" 
                                            matTooltip="Delete">delete</mat-icon> 
                                        </ng-container>
    
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </form>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button setColor="primaryColour" color="primary" (click)="addNewLocalization()">
        <mat-icon class="material-icons-outlined">add</mat-icon> Add Text Localization
    </button>
    <button mat-stroked-button setColor="primaryColour" color="primary" (click)="addNewProperty()">
        <mat-icon class="material-icons-outlined">add</mat-icon> Add Property
    </button>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button> 
</mat-dialog-actions>