<form class="form" *ngIf="form" [formGroup]="form">

    <mat-form-field appearance="fill">
        <mat-label>
            Chart Type
        </mat-label>

        <mat-select disableOptionCentering formControlName="chartType" [disabled]="element != null">
            <ng-container *ngFor="let option of chartTypes">
                <mat-option [value]="option.id">{{option.display}}</mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>
            Parent Table
        </mat-label>

        <mat-select disableOptionCentering formControlName="parentTable">
            <ng-container *ngFor="let option of tables">
                <mat-option [value]="option.name">{{option.name}}</mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>
            Grouping Threshold (Percent)
        </mat-label>

        <input
            matInput
            type="number"
            min="0"
            max="100"
            formControlName="groupingThreshold">
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>
            Color Scheme
        </mat-label>

        <mat-select disableOptionCentering formControlName="colorScheme">
            <ng-container *ngFor="let colorScheme of colorSchemes">
                <mat-option [value]="colorScheme">
                    {{colorScheme.name}}
                    <div style="width: 15px; height: 15px; background-color: {{colorScheme.hex[0]}}; display: inline-block; margin-right: 10px;"></div>
                    <div style="width: 15px; height: 15px; background-color: {{colorScheme.hex[1]}}; display: inline-block; margin-right: 10px;"></div>
                    <div style="width: 15px; height: 15px; background-color: {{colorScheme.hex[2]}}; display: inline-block; margin-right: 10px;"></div>
                </mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>

    <div class="form-section">
        <div class="form-section-title">
            <h6>Attributes</h6>
        </div>
        <div class="form-section-contents properties">
            <div class="section-row">
                <ng-container formArrayName="columns">

                    <div cdkDropList (cdkDropListDropped)="drop($event)">

                        <ng-container *ngFor="let propertyForm of columns.controls; let i = index">
                            <div class="form-row" cdkDrag>
                                <div class="example-custom-placeholder" *cdkDragPlaceholder></div>

                                <div>
                                    <ng-container *ngIf="form.value.parentTable">

                                        <report-element-grid-column [propertyForm]="propertyForm"
                                                                    [tables]="getSubTables(form.value.parentTable)"
                                                                    [parentTable]="form.value.parentTable"
                                                                    [index]="i"
                                        ></report-element-grid-column>

                                        <mat-icon 
                                        setColor 
                                        [setColorRules]="[
                                            {
                                                color: 'primaryColour',
                                                events: ['hover']
                                            }
                                        ]" 
                                        class="material-icons-outlined clickable-icon" 
                                        (click)="deleteColumn(i)" 
                                        matTooltip="Delete">delete</mat-icon>
                                    </ng-container>

                                </div>
                            </div>
                        </ng-container>

                    </div>

                </ng-container>
            </div>
        </div>
    </div>


    <mat-dialog-actions>
        <button mat-stroked-button color="primary" (click)="debugDataEmp()" *ngIf="isDebug">
            <mat-icon class="material-icons-outlined">add</mat-icon>
            Debug (Emp)
        </button>

        <button mat-stroked-button color="primary" (click)="debugDataEmpSum()" *ngIf="isDebug">
            <mat-icon class="material-icons-outlined">add</mat-icon>
            Debug (Emp - Sum Chart)
        </button>

        <button mat-stroked-button color="primary" (click)="debugDataCompensation()" *ngIf="isDebug">
            <mat-icon class="material-icons-outlined">add</mat-icon>
            Debug (Compensation)
        </button>

        <button mat-stroked-button color="primary" (click)="debugDataCompensationOther()" *ngIf="isDebug">
            <mat-icon class="material-icons-outlined">add</mat-icon>
            Debug (Other Compensation)
        </button>


        <button mat-stroked-button color="primary" (click)="addNewColumn()">
            <mat-icon class="material-icons-outlined">add</mat-icon>
            Add Attribute
        </button>
    </mat-dialog-actions>


</form>
