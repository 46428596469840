<mat-list-item appPreventDoubleClick (throttledClick)="openEmployeeToilRequestDialog.emit(employeeToilRequest)">
    <div class="employeeToilRequest-row">
        <div class="left">
            <div class="timeofftype-details">
                <img *ngIf="iconUrl" [src]="domSanitizer.bypassSecurityTrustUrl(iconUrl)"/>
                <h6>{{employeeToilRequest.toilPolicy?.toilType?.name}} - {{employeeToilRequest.toilPolicy?.name}}</h6>
            </div>

            <div class="dates">
                {{ moment(employeeToilRequest.startDate).format('ll') }} - {{ moment(employeeToilRequest.endDate).format('ll') }}
            </div>

            <mat-chip
                [ngClass]="{
                    'blue-chip': employeeToilRequest.toilClassType?.id === 'AccrueToil',
                    'yellow-chip': employeeToilRequest.toilClassType?.id === 'RequestToil'
                }"
            >{{ employeeToilRequest.toilClassType?.name }}</mat-chip>

            <mat-chip class="half-day-chip green-chip" *ngIf="employeeToilRequest.halfDay === true">{{ 'employeeToilRequestHalfDay' | translate: {Default: "Half Day"} }}</mat-chip>
        </div>

        <div class="middle">
            <ng-container *ngIf="employeeToilRequest.managers.length > 1">
                <mat-icon class="material-icons-outlined employee-list-icon" [matBadge]="employeeToilRequest.managers.length" [matTooltip]="showEmployeeTooltip(employeeToilRequest.managers)">
                    account_circle
                </mat-icon>
            </ng-container>

            <ng-container *ngIf="employeeToilRequest.managers.length == 1">
                <ng-container *ngFor="let employee of employeeToilRequest.managers">
                    <div class="employee-name">
                        <app-employee-image [employeeId]="employee.id" [width]="35" [height]="35" [round]="true" *ngIf="!isLoading"></app-employee-image>

                        <span class="employee">
                            {{employee.firstName}} {{employee.lastName}}
                        </span>
                    </div>
                </ng-container>
            </ng-container>
        </div>

        <div class="right">
            <mat-chip-list>
                <mat-chip
                    [ngClass]="{
                        'red-chip': employeeToilRequest.requestStatus?.id === 'trs_Denied',
                        'green-chip': employeeToilRequest.requestStatus?.id === 'trs_Approved',
                        'orange-chip': employeeToilRequest.requestStatus?.id === 'trs_Pending'
                    }"
                >{{ employeeToilRequest.requestStatus?.name }}</mat-chip>
            </mat-chip-list>

            <ng-container *ngIf="employeeToilRequest.requestStatus?.id === 'trs_Pending'">
                <button class="settings-button" mat-mini-fab [matMenuTriggerFor]="settings" appPreventDoubleClick (throttledClick)="$event.stopPropagation()">
                    <mat-icon class="settings-button__icon material-icons-outlined">more_vert</mat-icon>
                </button>
                <div class="settings-menu">
                    <mat-menu #settings="matMenu" xPosition="before">
                        <button class="settings-menu__item material-icons-outlined" appPreventDoubleClick (throttledClick)="$event.stopPropagation(); openEditRequestDialog()" mat-menu-item>
                            <mat-icon class="settings-button__icon material-icons-outlined">edit</mat-icon>
                            {{ 'EmployeeTimeOffTypeEdit' | translate: {Default: "Edit"} }}
                        </button>
                        <button class="settings-menu__item material-icons-outlined" appPreventDoubleClick (throttledClick)="$event.stopPropagation(); openConfirmDeleteDialog();" mat-menu-item>
                            <mat-icon class="settings-button__icon material-icons-outlined">delete</mat-icon>
                            {{ 'EmployeeTimeOffTypeCancel' | translate: {Default: "Cancel"} }}
                        </button>
                    </mat-menu>
                </div>
            </ng-container>

            <ng-container *ngIf="employeeToilRequest.requestStatus?.id === 'trs_Approved' && isInFuture(employeeToilRequest.startDate)">
                <button class="settings-button" mat-mini-fab [matMenuTriggerFor]="settings" appPreventDoubleClick (throttledClick)="$event.stopPropagation()">
                    <mat-icon class="settings-button__icon material-icons-outlined">more_vert</mat-icon>
                </button>
                <div class="settings-menu">
                    <mat-menu #settings="matMenu" xPosition="before">
                        <button class="settings-menu__item material-icons-outlined" appPreventDoubleClick (throttledClick)="$event.stopPropagation(); openConfirmDeleteDialog();" mat-menu-item>
                            <mat-icon class="settings-button__icon material-icons-outlined">delete</mat-icon>
                            {{ 'EmployeeTimeOffTypeCancel' | translate: {Default: "Cancel"} }}
                        </button>
                    </mat-menu>
                </div>
            </ng-container>

            <!-- <ng-container *ngIf="employeeToilRequest.isApproved === null">
                <button class="settings-button" mat-mini-fab [matMenuTriggerFor]="settings" appPreventDoubleClick (throttledClick)="$event.stopPropagation()">
                    <mat-icon class="settings-button__icon material-icons-outlined">more_vert</mat-icon>
                </button>
                <div class="settings-menu">
                    <mat-menu #settings="matMenu" xPosition="before">
                        <button class="settings-menu__item material-icons-outlined" appPreventDoubleClick (throttledClick)="$event.stopPropagation(); openEditRequestDialog(employeeToilRequest.id)" mat-menu-item *ngIf="employeeToilRequest.isApproved === null">
                            <mat-icon class="settings-button__icon material-icons-outlined">edit</mat-icon>
                            {{ 'EmployeeTimeOffTypeEdit' | translate: {Default: "Edit"} }}
                        </button>
                        <button class="settings-menu__item material-icons-outlined" appPreventDoubleClick (throttledClick)="$event.stopPropagation(); openConfirmDeleteDialog();" mat-menu-item>
                            <mat-icon class="settings-button__icon material-icons-outlined">delete</mat-icon>
                            {{ 'EmployeeTimeOffTypeCancel' | translate: {Default: "Cancel"} }}
                        </button>
                    </mat-menu>
                </div>
            </ng-container>

            <ng-container *ngIf="employeeToilRequest.isApproved === true && isInFuture(employeeToilRequest.from)">
                <button class="settings-button" mat-mini-fab [matMenuTriggerFor]="settings" appPreventDoubleClick (throttledClick)="$event.stopPropagation()">
                    <mat-icon class="settings-button__icon material-icons-outlined">more_vert</mat-icon>
                </button>
                <div class="settings-menu">
                    <mat-menu #settings="matMenu" xPosition="before">
                        <button class="settings-menu__item material-icons-outlined" appPreventDoubleClick (throttledClick)="$event.stopPropagation(); openConfirmDeleteDialog();" mat-menu-item>
                            <mat-icon class="settings-button__icon material-icons-outlined">delete</mat-icon>
                            {{ 'EmployeeToilRequestTypeCancel' | translate: {Default: "Cancel"} }}
                        </button>
                    </mat-menu>
                </div>
            </ng-container> -->

        </div>
    </div>
</mat-list-item>
