import { Component, Input, OnInit } from '@angular/core';
import { CompanyLogosService } from '@app/modules/site-settings-styling/components/company-logos/services/company-logos.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-logo-image',
  templateUrl: './logo-image.component.html',
  styleUrls: ['./logo-image.component.scss']
})
export class LogoImageComponent implements OnInit {

  @Input() logoId: string;
  logoImage$: Observable<any>;

  @Input() width: number;
  @Input() height: number;
  @Input() round: boolean = false;

  constructor(
    private companyLogosService: CompanyLogosService,
  ) { }

  ngOnInit(): void {
    if(this.logoId !== null){
      this.logoImage$ = this.companyLogosService.getCompanyLogoImage(this.logoId)
    }
  }

}
