import { Localization } from '@app/shared/models/localization.model';
import { Version } from '@app/shared/models/version.model';

export interface CustomReport {
    id: string,
    name: string,
    type: string,
    createdBy?: {
        id: string,
        firstName: string,
        lastName: string
    },
    createdOn?: string,
    url?: string,
    version: Version
}

export interface CustomReportVerbose {
    id: string,
    name: Localization[],
    type: { id: string, name: string },
    reportElements: CustomReportElement[]
}

export interface CustomReportElement {
    id: string,
    type: string,
    name: string,
    columns: CustomReportDataGridColumn[],
}

// TODO investigate CustomReportColumn vs CustomReportDataGridColumn
export interface CustomReportDataGridColumn {
    title: string,
    table: CustomReportTable,
    field: string,
    fieldType: FieldType,
    filter: string,
}

export interface CustomReportColumn {
    title: string,
    table: string,
    field: string,
    fieldType: FieldType,
    filter: string,
}


export interface ServerReportElement {
    id: string,
    name: string,
    elementTypeId: string,
    properties: { property: string, value: string }[],
}

export interface CustomReportFields {
    tableName: string,
    fields: any[]
}

export interface FieldType {
    id: string,
    name: string,
}

export enum CustomReportTable {
    Employee = 'Employee',
    EmployeeDetails = 'Employee Details',
    EmployeePosition = 'Employee Position',
    Position = 'Position',
    CostCentre = 'Cost Centre',
    OrganizationStructure = 'Organization Structure',
    WorkLocation = 'Work Location',
    WorkRotation = 'Work Rotation',
    TimeOffType = 'Time Off Type',
    TimeOffPolicy = 'Time Off Policy',
    EmploymentRecord = 'Employment Record',
    Compensation = 'Compensation',
    OtherCompensations = 'Other Compensations',
    TimeOffHistory = 'Time Off History',
    EmergencyContacts = 'Emergency Contacts',
    FamilyDependants = 'Family Dependants',
    MedicalTests = 'Medical Tests',
    VisasAndPermits = 'Visas and Permits',
    Languages = 'Languages',
    TrainingAndCertification = 'Training and Certification',
    Education = 'Education',
    WorkHistory = 'Work History',
    ProfessionalExpertise = 'Professional Expertise',
    Associations = 'Associations',
    CompanyAssets = 'Company Assets',
    Relocations = 'Relocations',
    Grievances = 'Grievances',
    InjuryIllness = 'Injury Illness',
    BankDetails = 'Bank Details',
    PayrollDetails = 'Payroll Details',
    Goals = 'Goals',
}

export interface HardcodedReport {
    id: string
    name: string
    icon: string
    detail: string
    type: string
    href: string[]
    featureCode: string
}
