<mat-card class="employee-card" fxLayout="column" fxLayoutAlign="space-around center">
    <div class="profile-pic-container" fxLayout="column" fxLayoutAlign="space-around center">

      <app-employee-image mat-card-image class="profile-pic" round="true" width="200" height="200" [employeeId]="directReportee.id" appPreventDoubleClick (throttledClick)="openEditEmployee(directReportee.id)"></app-employee-image>
    </div>
    <mat-card-content>
      <div class="name" *ngIf="directReportee.firstname && directReportee.lastname">{{directReportee.firstname}} {{directReportee.lastname}}</div>
      <div class="position" *ngIf="directReportee.positions.length > 0">
          <div *ngFor="let position of directReportee.positions">{{position.name | uppercase }}</div>
      </div>
      <div class="workLocations" *ngIf="directReportee.workLocations.length > 0">
        <div *ngFor="let workLocation of directReportee.workLocations">{{workLocation.name | uppercase }}</div>
      </div>

      <div class="location" *ngIf="directReportee.office"><mat-icon class="material-icons-outlined">location_on</mat-icon> {{directReportee.office}}</div>
<!--      <mat-chip-list class="status">-->
<!--        <mat-chip *ngIf="directReportee.inOffice === true" color="primary" selected>In Office</mat-chip>-->
<!--        <mat-chip *ngIf="directReportee.inOffice === false" class="inactive-chip" selected>Out Of Office</mat-chip>-->
<!--      </mat-chip-list>-->
      <div class="status" *ngIf="directReportee.status">
        <mat-chip-list>
            <mat-chip *ngIf="directReportee.status === 'Active'" class="active-chip" selected>{{directReportee.status}}</mat-chip>
            <mat-chip *ngIf="directReportee.status === 'Inactive'" class="inactive-chip" selected>{{directReportee.status}}</mat-chip>
        </mat-chip-list>
      </div>
    </mat-card-content>

  </mat-card>
