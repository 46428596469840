import { Component, OnInit } from '@angular/core';
import { colors } from '@app/consts'; 

@Component({
  selector: 'app-headcount-by-gender',
  templateUrl: './headcount-by-gender.component.html',
  styleUrls: ['./headcount-by-gender.component.scss']
})
export class HeadcountByGenderComponent implements OnInit {
  chartData = {
    groupA: 1067,
    groupB: 812,
    groupC: 54,
    groupD: 102
  }

  public chart: any;
  public colors: typeof colors = colors;

  constructor() { }
  
  public ngOnInit(): void {
    this.initChart();
  }

  public initChart(): void {
    this.chart = {
      color: [colors.GREEN, colors.PINK, colors.YELLOW, colors.BLUE],
      tooltip: {
        trigger: 'item'
      },
      legend: {
        top: 'center',
        right: 'right',
        data: ['Male', 'Female', 'Non-Binary', 'Other'],
        textStyle: {
          color: '#6E6E6E'
        }
      },
      series: [{
        type: 'pie',
        radius: ['50%', '70%'],
        center: ['24%', '50%'],
        label: {
          show: false
        },
        labelLine: {
          normal: {
            show: false
          }
        },
        hoverAnimation: false,
        avoidLabelOverlap: false,
        data: [
          {
            name: 'Male',
            value: this.chartData.groupA
          },
          {
            name: 'Female',
            value: this.chartData.groupB
          },
          {
            name: 'Non-Binary',
            value: this.chartData.groupC
          },
          {
            name: 'Other',
            value: this.chartData.groupD
          },
        ]
      }]
    };
  }

}
