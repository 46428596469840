import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api_routes } from '@app/consts';
import { DirectorySubmit } from '@app/shared/models/employee/documents/directory-submit.model';
import { Directory } from '@app/shared/models/employee/documents/directory.model';
import { environment } from '@environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DocumentUpdate } from '../models/document.model';
import {EnvironmentService} from "@app/core/services/environment.service";



const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class EmployeeDocumentsService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getDocuments(employeeId: string, folderId?: string): Observable<any> {
    let params = new HttpParams();
    folderId ? params = params.append('FolderId', folderId) : null;

    return this.http.get<any[]>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.DOCUMENTS}`, {
      headers: headers,
      params: params
    });
  }

  getDocument(employeeId: string, documentId: string): Observable<any> {
    return this.http.get<any[]>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.DOCUMENTS}/${documentId}`, {
      headers: headers
    });
  }

  deleteDocument(employeeId: string, documentId: string): Observable<any> {
    return this.http.delete<any[]>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.DOCUMENTS}/${documentId}`, {
      headers: headers
    });
  }

  createDocument(employeeId: string, directory: DirectorySubmit): Observable<Directory> {
    return this.http.post<Directory>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.DOCUMENTS}`, directory, {
      headers: headers
    });
  }

  updateDocument(employeeId: string, documentId: string, documentUpdate: DocumentUpdate): Observable<Directory> {
    return this.http.put<Directory>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.DOCUMENTS}/${documentId}`, documentUpdate, {
      headers: headers
    });
  }

  uploadDocument(employeeId: string, file: File, name: string, parentId: string): Observable<any> {
    const formData: FormData = new FormData();

    formData.append('File', file);
    formData.append('Parent', parentId ? parentId : '');
    formData.append('Comments', '');
    formData.append('Name', name ? name : '');

    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.DOCUMENTS}`,
      formData,
      {
        headers: {},
        reportProgress: true,
        observe: 'events'
      }
    );
  }

  getDocumentUrl(employeeId: string, documentId: string): Observable<any> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.DOCUMENTS}/${documentId}`,
    {
      headers: headers,
      responseType: 'text' as 'json'
    }
    );
  }

  downloadDocument(employeeId: string, documentId: string): Observable<any> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.DOCUMENTS}/${documentId}/${api_routes.DOWNLOAD}`,
    {
      headers: headers,
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }



  // getDirectories(empId: string): Observable<Directory[]> {
  //   return this.http.get<any>(endpoint + 'Employee/' + empId + '/Documents/Directory/', {
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   });
  // }

  // getDirectoryContents(empId: string, directoryId: string): Observable<any> {
  //   return this.http.get<any>(endpoint + 'Employee/' + empId + '/Documents/Directory/' + directoryId, {
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   });
  // }

  // getDocumentUrl(empId: string, documentId: string): Observable<any> {
  //   return this.http.get<any>(endpoint + 'Employee/' + empId + '/Documents/' + documentId + '/Download',
  //   {
  //     headers: {
  //       'Content-Type': 'text/plain; charset=utf-8'
  //     },
  //     responseType: 'text' as 'json'
  //   }
  //   );
  // }

  // updateDirectory(empId: string, directory: DirectorySubmit): Observable<Directory> {
  //   return this.http.post<Directory>(endpoint + 'Employee/' + empId + '/Documents/Directory', directory, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //     }
  //   });
  // }

  // uploadDocument(empId: string, file: File, parentId: string): Observable<any> {
  //   const formData: FormData = new FormData();

  //   formData.append('Id', '');
  //   formData.append('File', file);
  //   formData.append('FolderId', parentId ? parentId : '');
  //   formData.append('Comments', '');
  //   formData.append('EffectiveDate', '');
  //   formData.append('ChangeReason', '');
  //   formData.append('ChangeReasonComments', '');

  //   return this.http.post<any>(endpoint + 'Employee/' + empId + '/Documents/',
  //     formData,
  //     {
  //       headers: {},
  //       reportProgress: true,
  //       observe: 'events'
  //     }
  //   );
  // }

  delete(employeeId: string, documentId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.DOCUMENTS}/${documentId}`, {
      headers: headers
    });
  }

  // downloadDocument(empId: string, id: string): Observable<any> {
  //   return this.http.get<any>(endpoint + 'Employee/' + empId + '/Documents/' + id + '/Download', {
  //     headers: {
  //       'Content-Type': 'application/json',
  //     }
  //   });
  // }
}
