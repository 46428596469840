<mat-card class="mat-elevation-z0">
    <mat-card-title class="header">
        <h5 appLocalizationPopupDirective localizationCode="CompanyLogoDetails">{{ 'CompanyLogoDetails' | translate: {Default: "Company Logo Details"} }}</h5>
    </mat-card-title>
      
    <mat-card-content>
        <app-form-generator 
            [formId]="formId"
            [formData]="formData"
            [emitFormDataTrigger]="getFormData"
            (emitFormData)="formDataEmitted($event)"
            (emitFormStatus)="formStatusUpdated($event)"
        ></app-form-generator>

        <button mat-raised-button setColor="primaryColour" color="primary" [disabled]="formValid === false" appPreventDoubleClick (throttledClick)="getFormData = !getFormData">{{ 'Save' | translate: {Default: "Save"} }}</button> 
    </mat-card-content>
</mat-card>
