<div class="main-container">
    <div class="top-container">
        <h5>New Hire Tasks</h5>
        <div class="buttons">
            <button mat-flat-button>
                <mat-icon class="material-icons-outlined">add</mat-icon> 
                Add
            </button>
            <button mat-flat-button routerLink="{{routes.CHECKLIST}}/1">
                <mat-icon class="material-icons-outlined">settings</mat-icon> 
            </button>
        </div>
    </div>
    <div class="bottom-container">
        <mat-accordion multi>
            <mat-expansion-panel [expanded]="true" *ngFor="let task of tasks">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{task.category}}
                </mat-panel-title>
                <mat-panel-description>
                  
                </mat-panel-description>
              </mat-expansion-panel-header>
          
              <mat-list class="checkbox-task-list">
                  <div class="checkbox-task" *ngFor="let item of task.list">
                      <mat-list-item>
                        <mat-checkbox setColor="primaryColour" class="example-margin" [(ngModel)]="item.checked"></mat-checkbox>
                        <span class="task">{{item.task}}</span>
                        <span class="assigned-to">{{item.assignedTo}}</span>
                        <span class="date">{{item.date}}</span>
                        <span class="icon">
                            <mat-icon class="material-icons-outlined">{{item.icon}}</mat-icon> 
                        </span>
                      </mat-list-item>
                      <mat-divider></mat-divider>
                  </div>
              </mat-list>
          
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>