import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LookupService} from '@app/modules/lookup/services/lookup.service';
import {TimeOff} from '@app/modules/lookup/models/time-off.model';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SnackbarService} from '@app/core/services/snackbar.service';
import {SiteSettings} from '@app/modules/lookup/models/site-settings.model';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {TimeOffPolicySchedule} from '@app/modules/lookup/models/time-off-policy-schedule.model';
import {Currency} from '@app/modules/lookup/models/currency.model';
import {Timezone} from '@app/modules/lookup/models/timezone.model';
import {Culture} from '@app/shared/models/system-language/culture.model';
import {SettingsService} from '@app/core/services/settings.service';
import {features} from '@app/consts';
import {SecurityProtectedBase} from '@app/shared/components/security-protected/security-protected';
import {FeatureService} from '@app/core/services/feature.service';

@Component({
    selector: 'site-settings-currency',
    templateUrl: './site-settings-currency.component.html',
    styleUrls: ['./site-settings-currency.component.scss']
})
export class SiteSettingsCurrencyComponent extends SecurityProtectedBase implements OnInit {

    isLoading: boolean = true;
    settingsForm: UntypedFormGroup;
    currencySearchValue: string = '';
    currencies: Currency[] = [];
    selectedCurrency: Currency

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private settingsService: SettingsService,
        private dialog: MatDialog,
        private snackbarService: SnackbarService,
        private fb: UntypedFormBuilder,
        featureService: FeatureService
    ) {
        super(featureService, features.CURRENCY)
    }

    ngOnInit(): void {
        this.getCurrencies();
    }


    private getCurrencies() {
        this.settingsService.getCurrencies().subscribe(
            currencies => {
                this.currencies = currencies;
                this.selectedCurrency = currencies[0]
                this.isLoading = false;
            },
            err => {
                console.log(err);
            }
        );
    }

    // updateCurrency(currency, checked) {
    //     this.isLoading = true;
    //     let data = {"isEnabled": checked};
    //     this.settingsService.updateCurrency(currency.id, data).subscribe(
    //         res => {
    //             this.getCurrencies();
    //             this.snackbarService.openSnackBar('Currency updated', 'clear', 'success');
    //         },
    //         err => {
    //             console.log(err);
    //         }
    //     )
    // }
}
