<app-data-grid-comp
    [isLoading]="isLoading"
    [columns]="columns"
    [sortableColumns]="sortableColumns"
    [gridDataResult]="gridData"
    [pageSize]="pageSize"
    [skip]="skip"
    [rowSelectionSettings]="{
        canSelectRows: true,
        selectableMode: 'multiple',
        selectRowBy: 'id'
    }"
    [sortable]="{
        mode: 'multiple'
    }"
    searchable="true"
    clickableRows="true"
    hideActionButtons="true"
    [clearSelectedItems]="clearSelectedItems"
    [filterCategories]="filterCategories"
    (emitPageChangeEvent)="pageChange($event)"
    (emitDeleteEvent)="deleteAllSelected($event)"
    (emitSearchEvent)="search($event)"
    (emitOpenDialogEvent)="emitGoToToilPolicy.emit($event)"
    (emitSortEvent)="sortChange($event)"
></app-data-grid-comp>