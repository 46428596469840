import {Component, OnInit, ViewChild} from '@angular/core';
import {
    ApexAxisChartSeries,
    ApexChart,
    ChartComponent,
    ApexDataLabels,
    ApexPlotOptions,
    ApexYAxis,
    ApexLegend,
    ApexStroke,
    ApexXAxis,
    ApexFill,
    ApexTooltip
} from 'ng-apexcharts';
import {colors} from '@app/consts';

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    dataLabels: ApexDataLabels;
    plotOptions: ApexPlotOptions;
    yaxis: ApexYAxis;
    xaxis: ApexXAxis;
    fill: ApexFill;
    tooltip: ApexTooltip;
    stroke: ApexStroke;
    legend: ApexLegend;
    colors: any;
};

@Component({
    selector: 'app-compensation-report',
    templateUrl: './compensation-report.component.html',
    styleUrls: ['./compensation-report.component.scss']
})
export class CompensationReportComponent implements OnInit {

    public chartSettings: any[] = [];

    data = [
        {
            label: 'Merit',
            currency: 'EURO',
            value: '96.4%',
            displayValue: '173,373.93',
            target: '179,910.30',
        },
        {
            label: 'Bonus',
            value: '18.5%',
            currency: 'EURO',
            displayValue: '464,519.02',
            target: '464,519.02',
        },
        {
            label: 'Stock',
            currency: 'EURO',
            value: '98.9%',
            displayValue: '315,112.49',
            target: '318,779.83',
        },
    ];

    tableData = [
        {
            picture: '/assets/icons/world-icon.png',
            label: 'Global Innovation Group',
            subOrgs: 'account_tree',
            view: 'create',
            activity: 'chat',
            status: 'Awaiting Action',
            positions: '6',
            errors: '6',
            merit: '100.00%',
            bonus: '100.00%',
            stock: '100.00%',
            period: '05/2020',
            a: '5',
            b: '9',
            c: '4',
            d: '7',
            e: '5',
            f: '7',
        },
        {
            picture: '/assets/icons/africa-union-icon.png',
            label: 'Innovation Team - Africa',
            subOrgs: 'account_tree',
            view: 'create',
            activity: 'chat',
            status: 'Awaiting Action',
            positions: '16',
            errors: '8',
            merit: '100.00%',
            bonus: '94.15%',
            stock: '100.00%',
            period: '05/2020',
            a: '7',
            b: '4',
            c: '8',
            d: '12',
            e: '10',
            f: '4',
        },
        {
            picture: '/assets/icons/europe-icon.png',
            label: 'Innovation Team - Europe',
            subOrgs: 'account_tree',
            view: 'search',
            activity: 'chat',
            status: 'Awaiting Action',
            positions: '23',
            errors: '0',
            merit: '100.00%',
            bonus: '100.00%',
            stock: '100.00%',
            period: '05/2020',
            a: '8',
            b: '3',
            c: '10',
            d: '5',
            e: '9',
            f: '8',
        },
        {
            picture: '/assets/icons/japan-icon.png',
            label: 'Innovation Team - Japan',
            subOrgs: 'account_tree',
            view: 'create',
            activity: 'chat',
            status: 'Awaiting Action',
            positions: '12',
            errors: '1',
            merit: '100.00%',
            bonus: '100.00%',
            stock: '91.84%',
            period: '05/2020',
            a: '6',
            b: '2',
            c: '6',
            d: '4',
            e: '8',
            f: '12',
        },
        {
            picture: '/assets/icons/america-icon.png',
            label: 'Innovation Team - Americas',
            subOrgs: 'account_tree',
            view: 'search',
            activity: 'chat',
            status: 'Awaiting Action',
            positions: '35',
            errors: '2',
            merit: '97.03%',
            bonus: '100.00%',
            stock: '100.00%',
            period: '05/2020',
            a: '6',
            b: '5',
            c: '9',
            d: '4',
            e: '15',
            f: '8',
        },
    ];

    tableColumns = [
        {name: 'Organization', dataKey: 'label', isSortable: true, position: 'left'},
        {name: 'Sub Orgs', dataKey: 'period', isSortable: true, position: 'left'},
        {name: 'View', dataKey: 'a', isSortable: true, position: 'left'},
        {name: 'Activity', dataKey: 'b', isSortable: true, position: 'left'},
        {name: 'Status', dataKey: 'c', isSortable: true, position: 'left'},
        {name: 'Positions', dataKey: 'd', isSortable: true, position: 'left'},
        {name: 'Errors', dataKey: 'e', isSortable: true, position: 'left'},
        {name: 'Merit', dataKey: 'f', isSortable: true, position: 'left'},
        {name: 'Bonus', dataKey: 'f', isSortable: true, position: 'left'},
        {name: 'Stock', dataKey: 'f', isSortable: true, position: 'left'},
        {name: 'Actions', dataKey: 'f', isSortable: true, position: 'left'},
    ];

    constructor() {
    }

    public ngOnInit(): void {
        this.chartSettings.push({
            chart: {
                animations: {
                    speed: 0,
                    animateGradually: {
                        enabled: false
                    }
                },
                fontFamily: 'inherit',
                foreColor: 'inherit',
                // height: '56px',
                // width: '70px',
                type: 'donut',
                sparkline: {
                    enabled: true
                }
            },

            colors: ['#84C13C', '#E2E2E2'],
            labels: ['A', 'B'],
            plotOptions: {
                pie: {
                    expandOnClick: false,
                    donut: {
                        size: '80%'
                    }
                }
            },
            series: [96.4, 3.6],
            states: {
                hover: {
                    filter: {
                        type: 'none'
                    }
                },
                active: {
                    filter: {
                        type: 'none'
                    }
                }
            },
            grid: {
                padding: {
                    left: 0,
                    right: 0
                }
            },
            stroke: {
                width: 0,
            },
            tooltip: {
                enabled: false
            }
        });
        this.chartSettings.push({
            chart: {
                animations: {
                    speed: 0,
                    animateGradually: {
                        enabled: false
                    }
                },
                fontFamily: 'inherit',
                foreColor: 'inherit',
                // height: '56px',
                // width: '70px',
                type: 'donut',
                sparkline: {
                    enabled: true
                }
            },

            colors: ['#FF7842', '#E2E2E2'],
            labels: ['A', 'B'],
            plotOptions: {
                pie: {
                    expandOnClick: false,
                    donut: {
                        size: '80%'
                    }
                }
            },
            series: [100, 0],
            states: {
                hover: {
                    filter: {
                        type: 'none'
                    }
                },
                active: {
                    filter: {
                        type: 'none'
                    }
                }
            },
            grid: {
                padding: {
                    left: 0,
                    right: 0
                }
            },
            stroke: {
                width: 0,

            },
            tooltip: {
                enabled: false
            }
        });
        this.chartSettings.push({
            chart: {
                animations: {
                    speed: 0,
                    animateGradually: {
                        enabled: false
                    }
                },
                fontFamily: 'inherit',
                foreColor: 'inherit',
                // height: '56px',
                // width: '70px',
                type: 'donut',
                sparkline: {
                    enabled: true
                }
            },

            colors: ['#84C13C', '#E2E2E2'],
            labels: ['A', 'B'],
            plotOptions: {
                pie: {
                    expandOnClick: false,
                    donut: {
                        size: '80%'
                    }
                }
            },
            series: [98.9, 1.1],
            states: {
                hover: {
                    filter: {
                        type: 'none'
                    }
                },
                active: {
                    filter: {
                        type: 'none'
                    }
                }
            },
            grid: {
                padding: {
                    left: 0,
                    right: 0
                }
            },
            stroke: {
                width: 0,

            },
            tooltip: {
                enabled: false
            }
        });
    }

}
