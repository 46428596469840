import { Component, OnInit, ViewChild } from "@angular/core";
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};

@Component({
  selector: 'app-contingent-worker-cost',
  templateUrl: './contingent-worker-cost.component.html',
  styleUrls: ['./contingent-worker-cost.component.scss']
})
export class ContingentWorkerCostComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  chartData = [
    {
      'month': 'January',
      'contingentWorker': '40,000',
      'employee': '380,000',
    },
    {
      'month': 'February',
      'contingentWorker': '41,000',
      'employee': '390,000',
    },
    {
      'month': 'March',
      'contingentWorker': '43,500',
      'employee': '400,000',
    },
    {
      'month': 'April',
      'contingentWorker': '43,500',
      'employee': '400,000',
    },
    {
      'month': 'May',
      'contingentWorker': '45,000',
      'employee': '420,000',
    },
    {
      'month': 'June',
      'contingentWorker': '47,100',
      'employee': '425,000',
    },
    {
      'month': 'July',
      'contingentWorker': '48,000',
      'employee': '430,000',
    },
    {
      'month': 'August',
      'contingentWorker': '48,000',
      'employee': '430,000',
    },
    {
      'month': 'September',
      'contingentWorker': '50,000',
      'employee': '450,000',
    }
  ];

  tableColumns = [
    { name: 'month', dataKey: 'month', isSortable: true, position: 'left' },
    { name: 'Employee', dataKey: 'employee', isSortable: true, position: 'left' },
    { name: 'Contingent Worker', dataKey: 'contingentWorker', isSortable: true, position: 'left' },
  ];

  constructor() {
    this.chartOptions = {
      series: [
        {
          name: "Employee",
          data: [400000, 410000, 435000, 435000, 450000, 471000, 480000, 480000, 500000]
        },
        {
          name: "Contingent Worker",
          data: [38000, 39000, 40000, 40000, 42000, 42500, 43000, 43000, 45000]
        },
      ],
      chart: {
        type: "bar",
        height: 350,
        width: 800
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct"
        ]
      },
      yaxis: {
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val.toString();
          }
        }
      }
    };
  }

  ngOnInit(): void {
  }

}
