export enum db_tables {
    ABSENCES = 'tbl_Absences',
    Associations = 'tbl_Associations',
    BankDetails = 'tbl_BankDetails',
    CompanyAssets = 'tbl_CompanyAssets',
    Compensation = 'tbl_Compensations',
    Disciplines = 'tbl_Disciplines',
    Education = 'tbl_Education',
    EmergencyContacts = 'tbl_EmergencyContacts',
    Talent = 'tbl_Employees',
    EmploymentRecords = 'tbl_EmploymentRecords',
    Family = 'tbl_Family',
    Grievances = 'tbl_Grievances',
    InjuryIllness = 'tbl_InjuryIllness',
    IntegrationDetails = 'tbl_IntegrationDetails',
    Languages = 'tbl_Languages',
    MedicalTestings = 'tbl_MedicalTestings',
    OtherCompensations = 'tbl_OtherCompensations',
    PayrollDetails = 'tbl_PayrollDetails',
    Positions = 'tbl_Positions',
    PositionsEmployees = 'tbl_PositionsEmployees',
    ProfessionalExpertises = 'tbl_ProfessionalExpertises',
    Projects = 'tbl_Projects',
    Relocations = 'tbl_Relocations',
    StockPurchasePlans = 'tbl_StockPurchasePlans',
    TrainingAndCertifications = 'tbl_TrainingAndCertifications',
    User = 'tbl_User',
    VisaPermits = 'tbl_VisaPermits',
    WorkExperiences = 'tbl_WorkExperiences',
    WorkLocations = 'tbl_WorkLocations',
    EmploymentDetails = 'tbl_EmploymentDetails',
    TimeOffDetail = 'tbl_TimeOffDetails',
    EmployeeTimeOffPolicies = 'tbl_EmployeeTimeOffPolicies',
    DocumentLibrary = 'tbl_DocumentLibrary',
    ProfilePictures = 'tbl_ProfilePictures',
    TOIL = 'tbl_Toil',
    TOILEmployeeManualAdjustments = 'tbl_ToilManualAdjustments',
    PerformanceReviewPlans = 'tbl_PerformanceReviewPlans',
    PerformanceReviewPlansGoalPlans = 'tbl_PerformanceReviewPlansGoalPlans',
    PerformanceReviewPlanReviewers  = 'tbl_PerformanceReviewPlanReviewers',
    GoalTypes = 'tbl_PerformanceGoalTypes',
    GoalPlans = 'tbl_PerformanceGoalPlans',
    GoalPlanGoals = 'tbl_PerformanceGoalPlanGoals',
    EmployeeGoals = 'tbl_PerformanceEmployeeGoals',
    PerformanceReviewsRatings = 'tbl_PerformanceReviewsRatings',
    ReviewRatings = 'tbl_PerformanceReviewRatings',
    Reviews = 'tbl_PerformanceReviews',
    PerformanceReviewPlansGoalTypes = 'tbl_PerformanceReviewPlansGoalTypes,'
}
