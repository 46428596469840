<h5 mat-dialog-title>{{ 'PerformanceGoal' | translate: {Default: "Goal"} }}</h5>

<mat-dialog-content>

    <ng-container *ngIf="isLoading; else loaded">
        <ngx-skeleton-loader
          count="5"
          [theme]="{ 
              'height.px': 50
          }"
        ></ngx-skeleton-loader>
    </ng-container>
    <ng-template #loaded>
        <div class="top">
            <div class="left">

                <div class="form-section">
                    <div class="form-section-title">
                        <h6>Step 1</h6>
                    </div>
                    <div class="form-section-contents">
                        <div class="section-row">
                            <form [formGroup]="firstFormGroup">
                                <mat-form-field appearance="fill">
                                    <mat-label>
                                        {{ 'EmployeeGoals-Objective' | translate: {Default: "Objective"} }}
                                    </mat-label>
                        
                                    <input 
                                        matInput 
                                        type="text"
                                        formControlName="objective"
                                    >
                                </mat-form-field>
                    
                                <mat-form-field appearance="fill">
                                    <mat-label>
                                        {{ 'EmployeeGoals-Description' | translate: {Default: "Description"} }}
                                    </mat-label>
                        
                                    <textarea 
                                        matInput 
                                        cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize"
                                        cdkAutosizeMinRows="1"
                                        cdkAutosizeMaxRows="30"
                                        formControlName="description"
                                    ></textarea>
                                </mat-form-field>
                              </form>
                        </div>
                    </div>
                </div>
                <div class="form-section">
                    <div class="form-section-title">
                        <h6>Step 2</h6>
                    </div>
                    <div class="form-section-contents">
                        <div class="section-row">
                            <form [formGroup]="secondFormGroup">
                                <app-goal-selection-form-field
                                    *ngIf="!isAParent"
                                    [formElement]="{
                                        text: 'Parent Goal',
                                        formControl: 'parentId'
                                    }"
                                    [parentGroup]="secondFormGroup"
                                >
                                </app-goal-selection-form-field>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="form-section">
                    <div class="form-section-title">
                        <h6>Step 3</h6>
                    </div>
                    <div class="form-section-contents">
                        <div class="section-row">
                            <form [formGroup]="thirdFormGroup">
                                <app-goal-type-selection-form-field
                                    [formElement]="{
                                        text: 'Goal Type',
                                        formControl: 'goalTypeId'
                                    }"
                                    [parentGroup]="thirdFormGroup"
                                ></app-goal-type-selection-form-field>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="form-section">
                    <div class="form-section-title">
                        <h6>Step 4</h6>
                    </div>
                    <div class="form-section-contents">
                        <div class="section-row">
                            <form [formGroup]="forthFormGroup">
                                <mat-form-field  appearance="fill">
                                    <mat-label>
                                        {{ 'EmployeeGoals-ExpectedCompletionDate' | translate: {Default: "Expected Completion Date"} }}
                                    </mat-label>
                        
                                    <input matInput [matDatepicker]="expectedCompletionDatepicker" formControlName="expectedCompletionDate">
                                    <span fxLayout="row" matSuffix>
                                        <mat-datepicker-toggle  [for]="expectedCompletionDatepicker"></mat-datepicker-toggle>
                                    </span>
                                    <mat-datepicker #expectedCompletionDatepicker></mat-datepicker>
                                </mat-form-field>
                    
                                <mat-form-field  appearance="fill">
                                    <mat-label>
                                        {{ 'EmployeeGoals-ActualCompletionDate' | translate: {Default: "Actual Completion Date"} }}
                                    </mat-label>
                        
                                    <input matInput [matDatepicker]="actualCompletionDatepicker" formControlName="actualCompletionDate">
                                    <span fxLayout="row" matSuffix>
                                        <mat-datepicker-toggle  [for]="actualCompletionDatepicker"></mat-datepicker-toggle>
                                    </span>
                                    <mat-datepicker #actualCompletionDatepicker></mat-datepicker>
                                </mat-form-field>
                    
                                <div class="slider-container">
                                    <div class="slider-label">{{ 'EmployeeGoals-PercentageComplete' | translate: {Default: "Percentage Complete"} }}</div>
                                    <div class="slider-input">
                        
                                        <mat-form-field appearance="fill" class="invisible">
                                            <input
                                                #modValue
                                                type="number"
                                                max="1"
                                                min="0"
                                                step="0.01"
                                                formControlName="percentageComplete"
                                                matInput>
                                        </mat-form-field>
                        
                                        <div class="slider">
                                            <ng-container>
                                                {{percentageSliderValue}}%
                                            </ng-container>
                        
                                            <mat-slider
                                                max="1"
                                                min="0"
                                                step="0.01"
                                                [value]="modValue.value"
                                                [disabled]="percentageInputDisabled"
                                                (input)="setValue($event.value, forthFormGroup, 'percentageComplete'); calculateSliderPercentage($event.value, 1)"
                                            >
                                            </mat-slider>
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="slider-container">
                                    <div class="slider-label">{{ 'EmployeeGoals-Weight' | translate: {Default: "Weight"} }}</div>
                                    <div class="slider-input">
                        
                                        <mat-form-field appearance="fill" class="invisible">
                                            <input
                                                #modValueWeight
                                                type="number"
                                                max="1"
                                                min="0"
                                                step="0.01"
                                                formControlName="weight"
                                                matInput>
                                        </mat-form-field>
                        
                                        <div class="slider">
                                            <ng-container>
                                                {{weightSliderValue}}%
                                            </ng-container>
                        
                                            <mat-slider
                                                max="1"
                                                min="0"
                                                step="0.01"
                                                [value]="modValueWeight.value"
                                                [disabled]="percentageInputDisabled"
                                                (input)="setValue($event.value, forthFormGroup, 'weight'); calculateWeightSliderPercentage($event.value, 1)"
                                            >
                                            </mat-slider>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- <div class="right" *ngIf="goal && showComments">
                <app-goal-comments [goalId]="goal.id" [employeeId]="employeeId"></app-goal-comments>
            </div> -->
        </div>
    
        <!-- <app-child-goals-data-grid *ngIf="isAParent" category="Child Goals" [employeeId]="employeeId" [parentId]="goal.id"></app-child-goals-data-grid> -->
        <!-- <app-child-goals-data-grid *ngIf="!isAParent" category="Sibling Goals"[employeeId]="employeeId" [parentId]="goalForm.get('parentId').value"></app-child-goals-data-grid> -->
    </ng-template>

</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
    <button 
        *ngIf="readOnly !== true"
        mat-raised-button 
        setColor="primaryColour" 
        color="primary" 
        appPreventDoubleClick 
        (throttledClick)="save()" 
    >{{ 'Save' | translate: {Default: "Save"} }}</button>
</mat-dialog-actions>