<mat-card class="revenue-chart">
    <mat-card-title class="revenue-chart__header">
      <h5 class="revenue-chart__header-title">
          Absence Per Month
      </h5>
      
      <app-settings-menu></app-settings-menu>
    </mat-card-title>
    
    <mat-card-content class="revenue-chart__content">
        <div id="chart">
            <apx-chart
              [series]="chartOptions.series"
              [chart]="chartOptions.chart"
              [xaxis]="chartOptions.xaxis"
              [stroke]="chartOptions.stroke"
              [tooltip]="chartOptions.tooltip"
              [dataLabels]="chartOptions.dataLabels"
            ></apx-chart>
          </div>
    </mat-card-content>
</mat-card>