import { Component, OnInit } from '@angular/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { TimezoneService } from '@app/core/services/timezone/timezone.service';
import { CostCentersService } from '@app/modules/cost-centers/services/cost-centers.service';
import { Organization } from '@app/modules/organization-structure/models/organization-structure.model';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import { Timezone } from '@app/shared/models/timezone.model';
import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-add-dummy-cost-centers',
  templateUrl: './add-dummy-cost-centers.component.html',
  styleUrls: ['./add-dummy-cost-centers.component.scss']
})
export class AddDummyCostCentersComponent implements OnInit {
  loadingTypes: boolean = true;
  loadingOrgs: boolean = true;
  orgs: Organization[];
  numberOfItems: number = 20;
  organizationTypes: Organization[];
  cultures: Culture[];
  timezones: Timezone[];

  constructor(
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private cultureService: CultureService,
    private timezoneService: TimezoneService,
    private costCentersService: CostCentersService,
  ) { }

  ngOnInit(): void {
    this.getCultures();
    this.getTimezones();
  }

  createRandomString(length: number): string {
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, length);
  }

  getCultures() {
    this.cultureService.getCultures()
    .subscribe(
      res => {
        this.cultures = res;
      }
    )
  }

  getTimezones() {
    this.timezoneService.getTimezones()
    .subscribe(
      res => {
        this.timezones = res.data;
      }
    )
  }

  random_item(items){
    return items[Math.floor(Math.random()*items.length)];
  }

  getNewCostCenter(): any {
    return {
      "name":[{"culture":"en-CA","text":`Dummy-${this.createRandomString(10)}`}],
      "code":null,
      "id":null,
      "startDate":null,
      "endDate":null
    }
  }

  createItems() {
    let createItemRequests: Observable<any>[] = [];

    for (let i = 0; i < this.numberOfItems; i++) {
      createItemRequests.push(this.costCentersService.createCostCenter(this.getNewCostCenter()))
    }

    this.overlayService.show();
    forkJoin(createItemRequests)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
            this.snackbarService.openSnackBar(`Created ${this.numberOfItems} Cost Center(s) Successfully`, 'clear', 'success');
        }
    );
  }
}
