<h5 mat-dialog-title>Create A Directory</h5>

<mat-dialog-content [formGroup]="form">
  
    <mat-form-field appearance="fill">
        <input 
            matInput
            placeholder="Directory Name"
            formControlName="directoryName">
    </mat-form-field>
 
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" class="submit-button" (click)="save()" [disabled]="!form.valid" >Save</button>
</mat-dialog-actions>