import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-api-setup',
  templateUrl: './api-setup.component.html',
  styleUrls: ['./api-setup.component.scss']
})
export class ApiSetupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
