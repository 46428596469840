import {FeatureService} from '@app/core/services/feature.service';

export class SecurityProtectedElement {
    text: string;
    link: string;
    icon: string;
    featureCode: string;
}

export class SecurityProtectedBase {
    hasFeatureAccess: boolean;

    constructor(private featureService: FeatureService, featureCode : string = 'allow') {
        this.hasFeatureAccess = this.featureService.hasFeatureAccess(featureCode);
    }
}
