import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartComponent } from "ng-apexcharts";

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
};

@Component({
  selector: 'app-compensation-details',
  templateUrl: './compensation-details.component.html',
  styleUrls: ['./compensation-details.component.scss']
})
export class CompensationDetailsComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  
  tableColumns = [
    {name: 'Pay Profile', dataKey: 'payProfile', isSortable: true, position: 'left'},
    {name: '2020 Total Rewards', dataKey: 'totalRewards', isSortable: true, position: 'left'},
    {name: '% of Total Pay', dataKey: 'totalPay', isSortable: true, position: 'left'}
  ];

  tableData = [
    {
      payProfile: 'Annualized Base Pay',
      totalRewards: '$161,628',
      totalPay: '68%',
    },
    {
      payProfile: 'Company Bonus Paid',
      totalRewards: '$10,000',
      totalPay: '4%',
    },
    {
      payProfile: 'Incentive Comp Target',
      totalRewards: '$48,000',
      totalPay: '20%',
    },
    {
      payProfile: 'Other Bonus',
      totalRewards: '$2,000',
      totalPay: '1%',
    },
    {
      payProfile: 'Long Term Incentive',
      totalRewards: '$15,000',
      totalPay: '6%',
    },
    {
      payProfile: 'Company Paid Benefits',
      totalRewards: '',
      totalPay: '',
    },
    {
      payProfile: 'Total Compensation',
      totalRewards: '$236,628',
      totalPay: '100%',
    },
  ];

  constructor() {
    this.chartOptions = {
      series: [161628, 10000, 48000, 2000, 15000, 0, 236628],
      chart: {
        width: 600,
        type: "pie"
      },
      labels: ["Annualized Base Pay", "Company Bonus Paid", "Incentive Comp Target", "Other Bonus", "Long Term Incentive", "Company Paid Benefits", "Total Compensation"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }

  ngOnInit(): void {
  }

}
