import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {FormField} from '@app/shared/models/form-field.model';
import {EnvironmentService} from "@app/core/services/environment.service";

const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class FormFieldService {

  constructor(private http: HttpClient, private envService: EnvironmentService) {}

  getFormDetails(formId: string){
    const path = `/Metadata/Form/${formId}`;

    return this.http
      .get<FormField>(this.envService.env.apiUrl + path, {
        headers: headers,
        observe: 'response'
      })
      .pipe(
        catchError(err => throwError(err)),
        map(res => {
          return res;
        })
      );
  }

  getFormFieldDetails(formId: string){
    const path = `/Metadata/Form/${formId}/Element`;

    return this.http
      .get<FormField[]>(this.envService.env.apiUrl + path, {
        headers: headers,
        observe: 'response'
      })
      .pipe(
        catchError(err => throwError(err)),
        map(res => {
          res.body.forEach(field => {
            field.children.forEach(child => {
              child.properties.forEach(prop => {
                child[prop.property] = prop.value;
              });
            });

            field.properties.forEach(prop => {
              field[prop.property] = prop.value;
            });
          });

          res.body.sort((a, b) => {
            if (parseInt(a.order) > parseInt(b.order)) {
              return 1;
            }
            if (parseInt(a.order) < parseInt(b.order)) {
              return -1;
            }
            return 0;
          });

          return res;
        })
      );
  }

  updateFormField(fieldId: string, companyId: string, fieldLabel: string): Observable<any> {
    return of({
      id: fieldId,
      companyId: companyId,
      fieldLabel: fieldLabel
    });
  }

  getDropdownOptions(path: string): Observable<any> {
    path = this.sanitizePath(path);

    return this.http
      .get(this.envService.env.apiUrl + path, {
        headers: headers,
        observe: 'response'
      })
      .pipe(
        catchError(err => throwError(err)),
        map(res => {
          return res;
        })
      );
  }

  sanitizePath(path: string): string{
    path.charAt(0) === '/' ? path = path.slice(1) : null;

    return path;
  }

  delete(path: string): Observable<any> {
    return this.http.delete<any>(this.envService.env.apiUrl + path, {
      headers: {
        'Content-Type': 'application/json',
        // Authorization: environment.authorization
      }
    })
    .pipe(
      catchError(
        err => throwError(err)
      ),
      map(res => {
        return res;
      })
    );
  }

  submitForm(path: string, data: string): Observable<any> {
    return this.http.post<any>(this.envService.env.apiUrl + path, data, {
      headers: {
        'Content-Type': 'application/json',
        // Authorization: environment.authorization
      }
    })
    .pipe(
      catchError(
        err => throwError(err)
      ),
      map(res => {
        return res;
      })
    );
  }
}
