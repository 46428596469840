<mat-card>
    <mat-card-title >
      <h5>Global Mobility Overview</h5>
  
      <app-settings-menu></app-settings-menu>
    </mat-card-title>
  
    <mat-card-content fxLayout="row" fxLayoutAlign="space-around start" >
        <div>
            <div class="block">
                <div class="label">Leavers</div>
                <div class="value">424</div>
            </div>
            <div class="block">
                <div class="label">Joiners</div>
                <div class="value">447</div>
            </div>
            <div class="block">
                <div class="label">Promotions</div>
                <div class="value">
                    <div>371</div>
                    <div>21.1%</div>
                </div>
            </div>
        </div>

        <div>
            <div class="block">
                <div class="label">Contractor Hires</div>
                <div class="value">
                    <div>179</div>
                    <div>40.0%</div>
                </div>
            </div>
            <div class="block">
                <div class="label">College Hires</div>
                <div class="value">
                    <div>158</div>
                    <div>35.3%</div>
                </div>
            </div>
            <div class="block">
                <div class="label">Av Cost of Hire</div>
                <div class="value">$6196.20</div>
            </div>
            <div class="block">
                <div class="label">Cost of Hire %</div>
                <div class="value">13.4%</div>
            </div>
        </div>
    </mat-card-content>
</mat-card>