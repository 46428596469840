<app-site-settings-styling>
    <mat-card class="mat-elevation-z0">
      <mat-card-title class="header">
          <h5 appLocalizationPopupDirective localizationCode="CreateCompanyLogo">{{ 'CreateCompanyLogo' | translate: {Default: "Create Company Logo"} }}</h5>
          <div class="k-flex-grow"></div>
          <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
          <button mat-raised-button setColor="primaryColour" color="primary" [disabled]="formValid === false" appPreventDoubleClick (throttledClick)="getFormData = !getFormData">{{ 'Save' | translate: {Default: "Save"} }}</button> 
      </mat-card-title>
  
      <mat-card-content>
        <app-form-generator 
            [formId]="formId"
            [formData]="formData"
            [emitFormDataTrigger]="getFormData"
            (emitFormData)="formDataEmitted($event)"
            (emitFormStatus)="formStatusUpdated($event)"
        ></app-form-generator>
      </mat-card-content>
    </mat-card>
</app-site-settings-styling>