<h5 mat-dialog-title>Employment Record: Stock Purchase Plan</h5>

<mat-dialog-content>
    <form class="form-container" *ngIf="form" [formGroup]="form">
        <mat-form-field  appearance="fill">
            <mat-label>
                Enrollment Date
            </mat-label>
        
            <input matInput [matDatepicker]="enrollmentDatepicker" formControlName="enrollmentDate">
            <span fxLayout="row" matSuffix>
                <mat-datepicker-toggle  [for]="enrollmentDatepicker"></mat-datepicker-toggle>
            </span>
            <mat-datepicker #enrollmentDatepicker></mat-datepicker> 
            <mat-hint></mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Member
            </mat-label>

            <mat-select disableOptionCentering formControlName="member">
                <mat-option *ngFor="let memberOption of memberOptions | async" [value]="memberOption.id">{{memberOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Division
            </mat-label>

            <mat-select disableOptionCentering formControlName="division">
                <mat-option *ngFor="let divisionOption of divisionOptions | async" [value]="divisionOption.id">{{divisionOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Employer Contribution Percentage
            </mat-label>

            <mat-select disableOptionCentering formControlName="employerContributionPercentage">
                <mat-option *ngFor="let employerContributionPercentageOption of employerContributionPercentageOptions | async" [value]="employerContributionPercentageOption.id">{{employerContributionPercentageOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Employee Contribution Percentage
            </mat-label>

            <input matInput
                type="number"
                formControlName="employeeContributionPercentage">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Employee Voluntary Percentage
            </mat-label>

            <input matInput
                type="number"
                formControlName="employeeVoluntaryPercentage">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Comments
            </mat-label>

            <textarea matInput formControlName="comments"></textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button> 
</mat-dialog-actions>