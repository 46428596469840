import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from  '@angular/forms';
import { routes } from '@app/consts';

const emailPattern = '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,63}';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private fb: UntypedFormBuilder,
  ) { }

  loginForm: UntypedFormGroup;
  isSubmitted  =  false;
  public routers: typeof routes = routes;

  ngOnInit(): void {
    this.loginForm  =  this.fb.group({
      email: ['', Validators.compose([Validators.required,Validators.pattern(emailPattern)])],
      password: ['', Validators.required]
    });
  }

  get formControls() { return this.loginForm.controls; }

  login(){
    // console.log(this.loginForm.value);
    // this.isSubmitted = true;
    // if(this.loginForm.invalid){
    //   return;
    // }
    // this.authService.login(this.loginForm.get('email').value, this.loginForm.get('password').value)
    // .subscribe(
    //   res => {
    //     debugger;
    //     this.router.navigate([this.routers.TALENT_TRACK]).then();
    //   },
    //   err => {
    //     console.log(err);
    //   },
    //   () => console.log('Done')
    // ); 
    // this.authService.login(this.loginForm.value);
    // this.router.navigateByUrl('/admin');
  }

}
