<h5 mat-dialog-title>Employment Record: Other Compensation</h5>

<mat-dialog-content>
    <form class="form-container" *ngIf="form" [formGroup]="form">
        <mat-form-field appearance="fill">
            <mat-label>
                Start Date
            </mat-label>
        
            <input matInput [ngxMatDatetimePicker]="startDatepicker" placeholder="Choose a date" formControlName="startDate">
            <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #startDatepicker showSpinners="" showSeconds="" stepHour=""
                stepMinute="" stepSecond="" touchUi="" color=""
                enableMeridian="" disableMinute="" hideTime="">
                <ng-template>
                <span>OK</span>
                </ng-template>
            </ngx-mat-datetime-picker>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                End Date
            </mat-label>
        
            <input matInput [ngxMatDatetimePicker]="endDatepicker" placeholder="Choose a date" formControlName="endDate">
            <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #endDatepicker showSpinners="" showSeconds="" stepHour=""
                stepMinute="" stepSecond="" touchUi="" color=""
                enableMeridian="" disableMinute="" hideTime="">
                <ng-template>
                <span>OK</span>
                </ng-template>
            </ngx-mat-datetime-picker>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Amount
            </mat-label>

            <input matInput
                type="number"
                formControlName="amount">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Percentage
            </mat-label>

            <input matInput
                type="number"
                formControlName="percentage">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Currency
            </mat-label>

            <mat-select disableOptionCentering formControlName="currency">
                <mat-option *ngFor="let currencyOption of currencyOptions | async" [value]="currencyOption.id">{{currencyOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Payment Cycle
            </mat-label>

            <mat-select disableOptionCentering formControlName="paymentCycle">
                <mat-option *ngFor="let paymentCycleOption of paymentCycleOptions | async" [value]="paymentCycleOption.id">{{paymentCycleOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Compensation Type
            </mat-label>

            <mat-select disableOptionCentering formControlName="compensationType">
                <mat-option *ngFor="let compensationTypeOption of compensationTypeOptions | async" [value]="compensationTypeOption.id">{{compensationTypeOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button> 
</mat-dialog-actions>