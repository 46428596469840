import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { modules } from '@app/consts/modules';

@Component({
  selector: 'app-employee-profile-goals',
  templateUrl: './employee-profile-goals.component.html',
  styleUrls: ['./employee-profile-goals.component.scss']
})
export class EmployeeProfileGoalsComponent implements OnInit {
  public modules: typeof modules = modules;

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }

  get employeeId(): string {
    let employeeId: string;
    
    this.route.paramMap.subscribe(
      params => employeeId = params.get("id")
    )
      
    return employeeId;
  }

}
