import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TableList, TableLookup } from '../../models/lookup.model';

@Component({
  selector: 'app-copy-lookup-dialog',
  templateUrl: './copy-lookup-dialog.component.html',
  styleUrls: ['./copy-lookup-dialog.component.scss']
})
export class CopyLookupDialogComponent implements OnInit {
  loading: boolean;
  selectedLookupGroup: string = '';
  tableList: TableList[]

  constructor(
    private dialogRef: MatDialogRef<CopyLookupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.tableList = data.tableListFields;
  }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }

  save(){
    if(this.selectedLookupGroup != ''){
      this.dialogRef.close(this.selectedLookupGroup);
    }
  }

}
