import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { finalize } from 'rxjs/operators';
import { WorkLocation, WorkLocationVerbose } from '../../models/work-location.model';
import { WorkLocationsService } from '../../services/work-locations.service';
import * as moment from 'moment';

@Component({
  selector: 'app-work-location-dialog',
  templateUrl: './work-location-dialog.component.html',
  styleUrls: ['./work-location-dialog.component.scss']
})
export class WorkLocationDialogComponent implements OnInit {
  workLocation: WorkLocation;
  workLocationVerbose: WorkLocationVerbose;
  isLoading: boolean = false;
  formId: string = 'frm_cG2ukGyUomdLRr'
  getFormData: boolean = false;
  formValid: boolean = false;
  formData: any;

  constructor(
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private workLocationsService: WorkLocationsService,
    private dialogRef: MatDialogRef<WorkLocationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.workLocation = data.workLocation;
  }

  ngOnInit(): void {
    this.workLocation 
    ? this.getWorkLocation()
    : this.createFormData()
  }

  getWorkLocation() {
    this.isLoading = true;

    this.workLocationsService.getWorkLocation(this.workLocation.id)
    .pipe(
      finalize( () => this.isLoading = false )
    )
    .subscribe(
      res => { 
        this.workLocationVerbose = res;
        this.createFormData();
      }
    )
  }

  createFormData() {
    this.formData = 
    {
      id: this.workLocation ? this.workLocationVerbose.id : null,
      streetAddress: this.workLocation ? this.workLocationVerbose.streetAddress : null,
      city: this.workLocation ? this.workLocationVerbose.city : null,
      province: this.workLocation ? this.workLocationVerbose.province : null,
      country: this.workLocation ? this.workLocationVerbose.country?.id : null,
      zip: this.workLocation ? this.workLocationVerbose.zip : null,
      name: this.workLocation ? this.workLocationVerbose.name : null,
      timezone: this.workLocationVerbose?.timezone ? this.workLocationVerbose.timezone.id : null,
      region: this.workLocationVerbose?.region ? this.workLocationVerbose.region.id : null,
      companyLogo: this.workLocationVerbose?.companyLogo ? this.workLocationVerbose.companyLogo.id : null,
      stylingColours: this.workLocationVerbose?.stylingColours ? this.workLocationVerbose.stylingColours.id : null,
      asOf: moment().format(),
    };
  }

  formDataEmitted(formData) {
    this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  close(){
    this.dialogRef.close();
  }

  save(formData: any){
    this.overlayService.show();

    if(this.workLocation !== null) {
      this.workLocationsService.updateWorkLocation(formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.dialogRef.close(true);
        }
      );
    }
    else {
      this.workLocationsService.createWorkLocation(formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.dialogRef.close(true);
        }
      );
    }

  }
}
