<h5 mat-dialog-title>{{ 'PerformanceGoal' | translate: {Default: "Goal"} }}</h5>

<mat-dialog-content>
        <div class="top">
            <div class="left">
                <app-employee-personal-goal-form
                    [goal]="goal"
                    [employeeId]="employeeId"
                    [goalPlan]="goalPlan"
                    [parentId]="selectedParentId"
                    [readOnly]="readOnly"
                    (selectedParentId)="selectedParentId = $event"
                    (saveComplete)="saveComplete()"
                ></app-employee-personal-goal-form>
                
                <app-goal-comments *ngIf="goal" [goalId]="goal?.id" [employeeId]="employeeId"></app-goal-comments>
            </div>
            
            <div class="right">
                <div class="child-goals" *ngIf="isAParent && goal">
                    <app-employee-personal-goal-child-goals
                        [goalId]="goal?.id" 
                        [readOnly]="readOnly"
                        [employeeId]="employeeId" 
                        [parentId]="goal?.id"
                    ></app-employee-personal-goal-child-goals>
                </div>
                
                <div class="sibling-goals" *ngIf="!isAParent && selectedParentId">
                    <app-child-goals-data-grid category="Sibling Goals" [goalId]="goal?.id" [employeeId]="employeeId" [parentId]="selectedParentId"></app-child-goals-data-grid>
                </div>
            </div>
        </div>
        
    
        <!-- <app-child-goals-data-grid *ngIf="isAParent" category="Child Goals" [goalId]="goal?.id" [employeeId]="employeeId" [parentId]="goal?.id"></app-child-goals-data-grid> -->

</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
</mat-dialog-actions>