<h5 mat-dialog-title>{{ 'Holiday' | translate: {Default: "Holiday"} }}</h5>

<mat-dialog-content>
    <ng-container *ngIf="isLoading; else loaded">
        <div style="width:100%">
            <ngx-skeleton-loader
                    count="5"
                    [theme]="{
                'height.px': 50,
                'width.px': 50
            }"
            ></ngx-skeleton-loader>
        </div>
    </ng-container>

    <ng-template #loaded>
        <div class="form" *ngIf="form" [formGroup]="form">
            <ng-container formArrayName="localizations">
                <ng-container *ngFor="let localizationForm of localizations.controls; let i = index">
                    <div class="localization">
                        <div [formGroup]="localizationForm">
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Language
                                </mat-label>

                                <mat-select disableOptionCentering formControlName="culture">
                                    <mat-option *ngFor="let culture of allCultures" [value]="culture.id" [hidden]="isCultureHidden(culture.id)">{{culture.text}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Holiday name
                                </mat-label>

                                <input matInput formControlName="text">
                            </mat-form-field>
                        </div>
                        <mat-icon class="material-icons-outlined" (click)="deleteLocalization(i)" matTooltip="Delete">delete</mat-icon>
                    </div>
                </ng-container>
            </ng-container>

            <div class="k-d-flex k-d-flex-row k-justify-content-between">
                <mat-form-field appearance="fill" class="k-mr-3">
                    <mat-label>
                        Start Date
                    </mat-label>

                    <input matInput [matDatepicker]="startDateDatepicker" formControlName="startDate">
                    <span fxLayout="row" matSuffix>
                        <mat-datepicker-toggle [for]="startDateDatepicker"></mat-datepicker-toggle>
                    </span>
                    <mat-datepicker #startDateDatepicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>
                        End Date
                    </mat-label>

                    <input matInput [matDatepicker]="endDateDatepicker" formControlName="endDate" [min]="this.form.value.startDate">
                    <span fxLayout="row" matSuffix>
                        <mat-datepicker-toggle [for]="endDateDatepicker"></mat-datepicker-toggle>
                    </span>
                    <mat-datepicker [startAt]="this.form.value.startDate"  #endDateDatepicker></mat-datepicker>
                </mat-form-field>
            </div>

            <div>
                <mat-form-field appearance="fill" class="k-mr-3">
                    <mat-label>
                        Hour Override
                    </mat-label>

                    <input matInput
                           type="number"
                           min="1"
                           max="99999"
                           formControlName="hourOverride"
                           placeholder="1"
                    >
                </mat-form-field>
            </div>

            <mat-form-field appearance="fill">
                <mat-label>
                    Notes
                </mat-label>

                <input matInput formControlName="notes">
            </mat-form-field>


        </div>
    </ng-template>

</mat-dialog-content>

<mat-dialog-actions *ngIf="form">
    <button *ngIf="localizations.controls.length < allCultures.length" mat-raised-button (click)="addNewLocalization()">
        <mat-icon class="material-icons-outlined">add</mat-icon>
        Add Text Localization
    </button>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid">Save</button>
</mat-dialog-actions>
