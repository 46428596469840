<app-site-settings-menu>
    <div class="full-width-container">
        <mat-card class="mat-elevation-z0">
            <mat-card-title class="header">
                <h5 appLocalizationPopupDirective localizationCode="TableFieldEditor">{{ 'TableFieldEditor' | translate: {Default: "Table Field Editor"} }}</h5>
            </mat-card-title>
        
            <mat-card-content>
                <div class="top">
    
                    <div class="step">
                        <h6 appLocalizationPopupDirective localizationCode="TableFieldEditor">
                            {{ 'TableFieldEditor-Step1' | translate: {Default: "Step 1: Choose Table"} }}
                        </h6>
    
                        <ng-container *ngIf="loadingTables; else loadedTables">
                            <ngx-skeleton-loader
                                count="1"
                                [theme]="{ 
                                'height.px': 50,
                                'width.px': 300
                                }"
                            ></ngx-skeleton-loader>
                        </ng-container>
    
                        <ng-template #loadedTables>
                            <mat-form-field appearance="fill">

                                <mat-label appLocalizationPopupDirective localizationCode="TableFieldEditor">
                                    {{ 'TableFieldEditor-Table' | translate: {Default: "Table"} }}
                                </mat-label>

                                <mat-select disableOptionCentering [(value)]="selectedTable" (valueChange)="handleSelectTableClick($event)" class="mat-form-field">
                                    
                                    <mat-form-field appearance="fill" class="dropdown-search">
                                        <mat-label>
                                            {{ 'Search' | translate: {Default: "Search"} }}
                                        </mat-label>
                                        <input matInput (keyup)="onTableDropdownSearchKeyReceived($event.target.value, tableGroups)">
                                    </mat-form-field>
                                    
                                    <mat-option value="">-- None --</mat-option>
                    
                                    <mat-optgroup *ngFor="let group of filteredTableGroups" [label]="group.groupName">
                                        <mat-option *ngFor="let table of group.tables" [value]="table">
                                            {{table.name}}
                                        </mat-option>
                                    </mat-optgroup>
                    
                                </mat-select>

                            </mat-form-field>
                        </ng-template>
                        
                    </div>
    
                    <div class="step" *ngIf="selectedTable != null">
                        <h6 appLocalizationPopupDirective localizationCode="TableFieldEditor">
                            {{ 'TableFieldEditor-Step2' | translate: {Default: "Step 2: Choose Field"} }}
                        </h6>
    
                        <ng-container *ngIf="loadingFields; else loadedFields">
                            <ngx-skeleton-loader
                                count="1"
                                [theme]="{ 
                                'height.px': 50,
                                'width.px': 300
                                }"
                            ></ngx-skeleton-loader>
                        </ng-container>
    
                        <ng-template #loadedFields>
                            <mat-form-field appearance="fill">
                                <mat-label appLocalizationPopupDirective localizationCode="TableFieldEditor">
                                    {{ 'TableFieldEditor-Fields' | translate: {Default: "Fields"} }}
                                </mat-label>
                                <mat-select disableOptionCentering [(value)]="selectedField" (valueChange)="handleSelectFieldClick($event)">
                                    <mat-form-field appearance="fill" class="dropdown-search">
                                        <mat-label>
                                            {{ 'Search' | translate: {Default: "Search"} }}
                                        </mat-label>
                                        <input matInput (keyup)="onFieldDropdownSearchKeyReceived($event.target.value, fieldList)">
                                    </mat-form-field>

                                    <mat-option>-- None --</mat-option>
                
                                    <mat-option *ngFor="let field of filteredFieldList" [value]="field">
                                        {{field.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-template>
    
                    </div>
                </div>
    
    
                <div class="bottom">
                    
                    <ng-container *ngIf="loadingTableField; else loadedTableField">
                        <div style="width:100%; padding: 10px;">
                            <ngx-skeleton-loader
                              count="5"
                              [theme]="{ 
                                'height.px': 50
                              }"
                            ></ngx-skeleton-loader>
                        </div>
                    </ng-container>
                    
                    <ng-template #loadedTableField>
                        <div *ngIf="selectedFieldVerbose && !loadingTableField">
                            <h6 appLocalizationPopupDirective localizationCode="TableFieldEditor">
                                {{ 'TableFieldEditor-Step3' | translate: {Default: "Step 3: Update Field Details"} }}
                            </h6>
        
                            <app-form-generator 
                            [formId]="formId"
                            [formData]="formData"
                            [emitFormDataTrigger]="getFormData"
                            (emitFormData)="formDataEmitted($event)"
                            (emitFormStatus)="formStatusUpdated($event)"
                            ></app-form-generator>
            
                            <div class="button-container">
                                <button 
                                    mat-raised-button 
                                    setColor="primaryColour" 
                                    color="primary" 
                                    [disabled]="formValid === false" 
                                    appPreventDoubleClick 
                                    (throttledClick)="getFormData = !getFormData">
                                    {{ 'Save' | translate: {Default: "Save"} }}
                                </button>
                            </div>
                        </div>
                    </ng-template>
                </div>
    
            </mat-card-content>
        </mat-card>
    </div>
</app-site-settings-menu>
