import { Component, OnInit } from '@angular/core';
import { SchedulerEvent } from '@progress/kendo-angular-scheduler';
import { sampleData, displayDate } from "./news-and-holidays-data";

@Component({
  selector: 'app-news-and-holidays-widget',
  templateUrl: './news-and-holidays-widget.component.html',
  styleUrls: ['./news-and-holidays-widget.component.scss']
})
export class NewsAndHolidaysWidgetComponent implements OnInit {
  public selectedDate: Date = displayDate;
  public events: SchedulerEvent[] = sampleData;

  constructor() { }

  ngOnInit(): void {
  }

}
