<div class="comment">
    <app-employee-image [width]="25" [height]="25" [round]="true" class="profile-pic" [employeeId]="comment.version.createdBy.id"></app-employee-image>
    <div class="right primaryColorBackground35">
        <div class="top">
            <div class="name">
                {{comment.version.createdBy.firstName}} {{comment.version.createdBy.lastName}}
            </div>

            <div *ngIf="comment.version.createdBy.id === user$.userId">
                <button class="settings-button" mat-mini-fab [matMenuTriggerFor]="settings" (click)="$event.stopPropagation()">
                    <mat-icon class="settings-button__icon material-icons-outlined">more_vert</mat-icon>
                </button>
                <div class="settings-menu">
                    <mat-menu #settings="matMenu" xPosition="before">
                        <button class="settings-menu__item material-icons-outlined" (click)="$event.stopPropagation(); showForm = true" mat-menu-item>
                            <mat-icon class="settings-button__icon material-icons-outlined">edit</mat-icon>
                            {{ 'Edit' | translate: {Default: "Edit"} }}
                        </button>
                        <button class="settings-menu__item material-icons-outlined" (click)="$event.stopPropagation(); emitDeleteComment.emit(comment.id);" mat-menu-item>
                            <mat-icon class="settings-button__icon material-icons-outlined">delete</mat-icon>
                            {{ 'Delete' | translate: {Default: "Delete"} }}
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>

        <ng-container *ngIf="showForm !== true; else formContainer">
            <div class="text">
                {{comment.comments}}
            </div>
            <div class="date">
                {{comment.commentedOn | date:'medium'}}
            </div>
        </ng-container>
        <ng-template #formContainer>
            <app-form-generator 
                [formId]="formId"
                [formData]="formData"
                [emitFormDataTrigger]="getFormData"
                skeletonLoadingRows="1"
                (emitFormData)="formDataEmitted($event)"
                (emitFormStatus)="formStatusUpdated($event)"
                (emitFormPristine)="formPristineEmitted($event)"
            ></app-form-generator>

            <div class="buttons-container">
                <button class="mat-raised-button" (click)="showForm = false">{{ 'Close' | translate: {Default: "Close"} }}</button>
                <button 
                    mat-raised-button 
                    setColor="primaryColour" 
                    color="primary" 
                    appPreventDoubleClick 
                    (throttledClick)="getFormData = !getFormData" 
                    [disabled]="formValid === false"
                >{{ 'Save' | translate: {Default: "Save"} }}</button>
            </div>
        </ng-template>
    </div>
</div>