import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { CompanyLogosService } from '../../services/company-logos.service';
import { finalize } from 'rxjs';
import { CompanyLogo } from '../../models/company-logos.model';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-company-logo-details',
  templateUrl: './edit-company-logo-details.component.html',
  styleUrls: ['./edit-company-logo-details.component.scss']
})
export class EditCompanyLogoDetailsComponent implements OnInit {
  companyLogoId: string;
  formId: string ='frm_ufSv8rKliWLNZh';
  getFormData: boolean = false;
  isLoading: boolean = true;
  companyLogo: CompanyLogo;
  formData: { id: string; name: string; defaultLogo: boolean; active: boolean; };
  formValid: any = false;

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private companyLogosService: CompanyLogosService,
  ) { }

  ngOnInit(): void {
    this.companyLogoId = this.getIdInURL();
    if(this.companyLogoId){
      this.getCompanyLogo();
    }
  }

  getIdInURL(): string {
    let IdInURL: string;

    this.route.paramMap.subscribe(
        params => IdInURL = params.get('id')
    );

    return IdInURL;
  }

  getCompanyLogo() {
    this.isLoading = true;

    this.companyLogosService.getCompanyLogo(this.getIdInURL())
    .pipe(
      finalize( () => {
        this.buildFormData();
      })
    )
    .subscribe(
      res => {
        this.companyLogo = res;
      }
    );
  }

  buildFormData(){
    this.isLoading = true;

    this.formData = {
      id: this.companyLogo ? this.companyLogo.id : null,
      name: this.companyLogo ? this.companyLogo.name : null,
      defaultLogo: this.companyLogo ? this.companyLogo.defaultLogo : null,
      active: this.companyLogo ? this.companyLogo.active : null,
    }

    this.isLoading = false;
  }

  formDataEmitted(formData) {
    this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  save(formData) {
    this.overlayService.show()

    formData.id = this.getIdInURL();
    formData.asOf = moment().format('YYYY-MM-DD'),
    formData.changeReason = null,
    formData.changeReasonComments = null,
    formData.originalCreatedOn = this.companyLogo.version?.createdOn,

    this.companyLogosService.putCompanyLogoDetails(this.getIdInURL(), formData)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

}
