import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SiteSettings} from '@app/modules/lookup/models/site-settings.model';
import {Currency} from '@app/modules/lookup/models/currency.model';
import {PagedData} from '@app/modules/lookup/models/paged-data.model';
import {ExchangeRate} from '@app/modules/lookup/models/exchange-rate.model';
import {EnvironmentService} from "@app/core/services/environment.service";


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  public siteSettings: SiteSettings;

  constructor(
    private http: HttpClient, 
    private envService: EnvironmentService
  ) {
  }

  syncSiteSettings(callback: () => any) {
    return this.http.get<any>(this.envService.env.apiUrl + 'MetaData/SiteSettings', {
      headers: headers
    }).subscribe(res => {
      this.siteSettings = res;
      callback();
    });
  }

  getSiteSettings(): Observable<SiteSettings> {
    return this.http.get<any>(this.envService.env.apiUrl + 'MetaData/SiteSettings', {
      headers: headers
    });
  }

  updateSiteSettings(data: any): Observable<any> {
    return this.http.put<any>( `${this.envService.env.apiUrl}MetaData/SiteSettings`, data, {
      headers: headers
    });
  }

  getCurrencies(): Observable<Currency[]> {
    return this.http.get<any>(this.envService.env.apiUrl + 'Currencies', {
      headers: headers
    });
  }

  getExchangeRates(currencyId:string): Observable<PagedData<ExchangeRate>> {
    return this.http.get<any>(this.envService.env.apiUrl + `Currencies/${currencyId}/ExchangeRates`, {
      headers: headers
    });
  }

  updateCurrency(currencyId: string, data: any): Observable<any> {
    return this.http.put<any>( `${this.envService.env.apiUrl}Currencies/${currencyId}`, data, {
      headers: headers
    });
  }
}
