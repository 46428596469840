import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Review, ReviewGoalPlan, ReviewGoalPlanGoal, ReviewGoalPlanGoalReview, ReviewSetting, ReviewSubmit } from '../../../reviews/models/review.model';
import { ReviewsService } from '../../../reviews/services/reviews.service';
import * as _ from 'lodash';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { SecuritySetupService } from '@app/modules/security-setup/services/security-setup.service';
import { db_tables } from '@app/consts';
import { finalize } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-review-goals-section',
  templateUrl: './review-goals-section.component.html',
  styleUrls: ['./review-goals-section.component.scss']
})
export class ReviewGoalsSectionComponent implements OnInit {
  @Input() review: Review;
  @Output() saveGoalReview = new EventEmitter<{goalId: string, id: string, rating: string, comments: string}>();
  @Output() saveGoalPlanReview = new EventEmitter<{goalPlanId: string, id: string, rating: string, comments: string}>();
  @Output() allGoalPlansReviewStatus = new EventEmitter<string>();
  

  selectedGoalPlan: ReviewGoalPlan;
  user$: any;
  settings: ReviewSetting;
  loadingDbTableFields: boolean;

  goalLabels = [
    {
      id: 'tfi_PEgDescription',
      label: 'Description'
    },
    {
      id: 'tfi_PEgGoalType',
      label: 'Goal Type'
    },
    {
      id: 'tfi_PEgPercentageComplete',
      label: 'Percentage Complete'
    },
    {
      id: 'tfi_PEgWeight',
      label: 'Weight'
    },
    {
      id: 'tfi_PEgExpectedCompletionDate',
      label: 'Expected Completion Date'
    },
    {
      id: 'tfi_PEgActualCompletionDate',
      label: 'Actual Completion Date'
    }
  ]

  constructor(
    private oidcAuthService: OidcAuthService,
    private securitySetupService: SecuritySetupService,
    public translate: TranslateService

  ) {
    this.user$ = this.oidcAuthService.userProfile;
  }

  ngOnInit(): void {
    this.getDbTableFields();
    // this.organizeGoals();

    if(this.review.goalPlans !== null && this.review.goalPlans.length > 0) {
      this.selectedGoalPlan = this.review?.goalPlans[0]
    }
    this.settings = this.review?.settings;
  }

  getDbTableFields() {
    this.loadingDbTableFields = true;

    this.securitySetupService.getFields(db_tables.EmployeeGoals, 0, '100')
    .pipe(
      finalize(()=>{
        this.loadingDbTableFields = false;
      })
    )
    .subscribe(
      res => {
        res.data.forEach(dataItem => {
          const labelToUpdate = this.goalLabels.find(labelItem => labelItem.id === dataItem.id);
          if (labelToUpdate) {
              labelToUpdate.label = dataItem.name; // Updating label value from data
          }
        });
      }
    );
  }

  getLabel(id: string){
    const reviewPlanLabel = this.goalLabels.find(labelItem => labelItem.id === id);

    return reviewPlanLabel?.label
  }

  calculateSliderPercentage(value: string, max: number): number {
    return Number( ((parseFloat(value)/max) * (100/1)).toFixed() );
  }

  getReviewerGoalPlanPermissions(goalPlanId: string, reviewerId: string) {
    let goalPlanPermissions = this.review.settings.goalPlanPermissions.find(permissions => permissions.goalPlan === goalPlanId)

    let reviewerPermissions = goalPlanPermissions.reviewerPermissions.find(permissions => permissions.reviewer === reviewerId)

    return reviewerPermissions;
  }

  showReview(reviewGoalPlanGoalReview: ReviewGoalPlanGoalReview): boolean {
    return true;
  }

  goalPlanReviewSubmitted(newReview) {
    newReview.goalPlanId = this.selectedGoalPlan.id
    this.saveGoalPlanReview.emit(newReview);
  }
}
