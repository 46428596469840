import { Component, Input, OnInit } from '@angular/core';
import { colors } from '@app/consts/colors';

@Component({
  selector: 'app-toil-pie-chart',
  templateUrl: './toil-pie-chart.component.html',
  styleUrls: ['./toil-pie-chart.component.scss']
})
export class ToilPieChartComponent implements OnInit {
  @Input() employee: any;
  chartData = {
    groupA: 20,
    groupB: 68,
    groupC: 49,
  }

  labelData = [
    {
      label: 'TOIL Claimed',
      color: '#b676b1',
      value: '20',
    },
    {
      label: 'TOIL Used',
      color: '#82caaf',
      value: '68',
    },
    {
      label: 'TOIL Available',
      color: '#75c0e0',
      value: '49',
    }
  ]

  public chart: any;
  public colors: typeof colors = colors;

  constructor() { }
  
  public ngOnInit(): void {
    this.initChart();
  }

  public initChart(): void {
    this.chart = {
      color: ['#b676b1', '#82caaf', '#75c0e0'],
      tooltip: {
        trigger: 'item'
      },
      series: [{
        type: 'pie',
        radius: ['60%', '70%'],
        center: ['50%', '50%'],
        label: {
          show: false
        },
        labelLine: {
          normal: {
            show: false
          }
        },
        hoverAnimation: false,
        avoidLabelOverlap: false,
        data: [
          {
            name: 'TOIL Claimed',
            value: this.employee.TOILClaimed
          },
          {
            name: 'TOIL Used',
            value: this.employee.TOILUsed
          },
          {
            name: 'TOIL Available',
            value: this.employee.TOILAvailable
          },
        ]
      }]
    };

    this.labelData = [
      {
        label: 'TOIL Claimed',
        color: '#b676b1',
        value: this.employee.TOILClaimed,
      },
      {
        label: 'TOIL Used',
        color: '#82caaf',
        value: this.employee.TOILUsed,
      },
      {
        label: 'TOIL Available',
        color: '#75c0e0',
        value: this.employee.TOILAvailable,
      }
    ]
  }

}
