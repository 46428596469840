<div *ngIf="logoImage$ | withLoading | async as logoImage">
    <ng-template [ngIf]="logoImage.value">
        <img class="profiler" [src]="logoImage.value" [ngStyle]="{'width.px': width}">
    </ng-template>
    <ng-template [ngIf]="logoImage.error">
        <div>Error</div>
    </ng-template>
    <ng-template [ngIf]="logoImage.loading">
        <ngx-skeleton-loader
            class="profiler"
            count="1" 
            [theme]="{ 
                'height.px': height,
                'width.px': width
            }"
        >
        </ngx-skeleton-loader>
    </ng-template>
</div>
