<div [formGroup]="parentGroup">
    <mat-form-field appearance="fill" [ngClass]="{'invisible':formElement.hidden === 'true'}">
        <mat-label>
            {{ formElement.text }}
        </mat-label>

        <mat-select disableOptionCentering [formControlName]="formElement.formControl">
            <mat-option></mat-option>

            <ng-container *ngIf="$feedbackSettingsOptions | async as feedbackSettingsOptions">
                <mat-option *ngFor="let option of feedbackSettingsOptions.data" [value]="option.id">{{option.name}}</mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>
</div>
