import { Component, OnInit } from '@angular/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { Organization } from '@app/modules/organization-structure/models/organization-structure.model';
import { OrganizationStructureService } from '@app/modules/organization-structure/services/organization-structure.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-add-dummy-organizations',
  templateUrl: './add-dummy-organizations.component.html',
  styleUrls: ['./add-dummy-organizations.component.scss']
})
export class AddDummyOrganizationsComponent implements OnInit {
  loadingTypes: boolean = true;
  loadingOrgs: boolean = true;
  orgs: Organization[];
  numberOfItems: number = 20;
  organizationTypes: Organization[];
  cultures: Culture[];

  constructor(
    private snackbarService: SnackbarService,
    private cultureService: CultureService,
    private overlayService: OverlayService,
    private organizationService: OrganizationStructureService
  ) { }

  ngOnInit(): void {
    this.getOrganizationTypes();
    this.getCultures();
    this.getAllOrganizations();
  }

  createRandomString(length: number): string {
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, length);
  }

  getCultures() {
    this.cultureService.getCultures()
    .subscribe(
      res => {
        this.cultures = res;
      }
    )
  }

  getAllOrganizations() {
    this.loadingOrgs = true;

    this.organizationService.getOrganizations(0, '200')
    .pipe(
      finalize( () => {
        this.loadingOrgs = false;
      })
    )
    .subscribe(
      res => {
        this.orgs = res.data;
      }
    )
  }

  getOrganizationTypes() {
    this.loadingTypes = true;

    this.organizationService.getOrganizationTypes()
    .pipe(
      finalize( () => {
        this.loadingTypes = false;
      })
    )
    .subscribe(
        res => {
            this.organizationTypes = res.data;
        }
    );
  }

  random_item(items){
    return items[Math.floor(Math.random()*items.length)];
  }

  getNewOrganization(): any {
    return {
        startDate: '2022-05-26',
        organizationType: this.random_item(this.organizationTypes).id,
        parentOrganization: this.random_item(this.orgs).id,
        name: [{
          "culture": this.random_item(this.cultures).id,
          "text": `Dummy - ${this.createRandomString(10)}`
        }],
    };
  }

  createPositions() {
    let createPositionRequests: Observable<any>[] = [];

    for (let i = 0; i < this.numberOfItems; i++) {
      createPositionRequests.push(this.organizationService.createOrganization(this.getNewOrganization()))
    }

    this.overlayService.show();
    forkJoin(createPositionRequests)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
            this.snackbarService.openSnackBar(`Created ${this.numberOfItems} Organization(s) Successfully`, 'clear', 'success');
        }
    );
  }
}
