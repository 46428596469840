import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { api_routes } from '@app/consts';
import { EnvironmentService } from "@app/core/services/environment.service";
import { StylingColour, StylingColourSubmit } from '../models/styling-colours.model';


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});
@Injectable({
  providedIn: 'root'
})
export class StylingColoursService {
  activeStylingColour: StylingColour;

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  syncActiveStylingColour(callback: () => any) {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.SITE_SETTINGS}/${api_routes.STYLING_COLOURS}/${api_routes.CURRENT_USER}`, {
      headers: headers
    }).pipe(
      catchError( callback() )
    ).subscribe(res => {
      this.activeStylingColour = res;
      callback();
    });
  }

  getActiveStylingColour() {
    return this.activeStylingColour;
  }

  getStylingColours(skip?: number, take?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<StylingColour>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.SITE_SETTINGS}/${api_routes.STYLING_COLOURS}`, {
        headers: headers,
        params: params
    });
  }

  getStylingColour(id: string): Observable<StylingColour> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.SITE_SETTINGS}/${api_routes.STYLING_COLOURS}/${id}`, {
      headers: headers
    });
  }

  postStylingColour(position: StylingColourSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.SITE_SETTINGS}/${api_routes.STYLING_COLOURS}`, position, {
      headers: headers
    });
  }

  putStylingColour(id: string, position: StylingColourSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.SITE_SETTINGS}/${api_routes.STYLING_COLOURS}/${id}`, position, {
      headers: headers
    });
  }

  deleteStylingColour(id: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.SITE_SETTINGS}/${api_routes.STYLING_COLOURS}/${id}`, {
      headers: headers
    });
  }

  getCurrentUserStylingColour(): Observable<StylingColour> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.SITE_SETTINGS}/${api_routes.STYLING_COLOURS}/${api_routes.CURRENT_USER}`, {
      headers: headers
    });
  }
}
