import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-absence-finance-report',
    templateUrl: './absence-finance-report.component.html',
    styleUrls: ['./absence-finance-report.component.scss']
})
export class AbsenceFinanceReportComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
