<app-site-settings-menu>
    <mat-card>
        <mat-card-title class="header">
            <h5 appLocalizationPopupDirective localizationCode="EditWorkRotation" mat-dialog-title *ngIf="workRotationId">
                {{ 'EditWorkRotation' | translate: {Default: "Edit Work Rotation"} }}
            </h5>
            <h5 appLocalizationPopupDirective localizationCode="CreateNewWorkRotation" mat-dialog-title *ngIf="!workRotationId">
                {{ 'CreateNewWorkRotation' | translate: {Default: "Create New Work Rotation"} }}
            </h5>

            <div class="buttons-container">
                <button mat-raised-button (click)="navigateBack()">{{ 'Close' | translate: {Default: "Close"} }}</button>
                <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid">{{ 'Save' | translate: {Default: "Save"} }}</button>
            </div>
        </mat-card-title>
        <mat-card-content>
            <ng-container *ngIf="isLoading; else loaded">
                <ngx-skeleton-loader
                  count="5"
                  [theme]="{ 
                      'height.px': 50
                  }"
                ></ngx-skeleton-loader>
            </ng-container>
            <ng-template #loaded>
                <div class="position-details-form">
                    <div *ngIf="!isLoading;" [formGroup]="form">
                        <div class="form-section">
                            <div class="form-section-title">
                                <h6>Text Localizations</h6>
                            </div>
                            <div class="form-section-contents">
                                <div class="section-row">
                                    <ng-container formArrayName="localizations">
                                        <ng-container *ngFor="let localizationForm of localizations.controls; let i = index">
                                            <div class="localization-row">
                                                <div [formGroup]="localizationForm">
                                                    <mat-form-field appearance="fill">
                                                        <mat-label>
                                                            Language
                                                        </mat-label>
    
                                                        <mat-select disableOptionCentering formControlName="culture">
                                                            <mat-option *ngFor="let culture of allCultures" [value]="culture.id" [hidden]="isCultureHidden(culture.id)">{{culture.text}}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
    
                                                    <mat-form-field appearance="fill">
                                                        <mat-label>
                                                            {{ 'WorkRotationName' | translate: {Default: "Work Rotation Name"} }}
                                                        </mat-label>
    
                                                        <input matInput
                                                               formControlName="text">
                                                    </mat-form-field>
                                                </div>
                                                <mat-icon 
                                                    setColor
                                                    [setColorRules]="[
                                                        {
                                                            color: 'primaryColour',
                                                            events: ['hover']
                                                        }
                                                    ]"
                                                    class="material-icons-outlined" 
                                                    (click)="deleteLocalization(i)" 
                                                    matTooltip="Delete"
                                                >delete</mat-icon>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                    <button *ngIf="localizations.controls.length < allCultures.length" mat-stroked-button class="add-localization-btn" setColor="primaryColour" color="primary" (click)="addNewLocalization()">
                                        <mat-icon class="material-icons-outlined">add</mat-icon>
                                        Add text Localization
                                    </button>
                                </div>
                            </div>
                        </div>
    
                        <div class="form-section">
                            <div class="form-section-title">
                                <h6>Work Weeks</h6>
                            </div>
                            <div class="form-section-contents">
                                <div class="section-row">
                                    <ng-container formArrayName="workWeeks">
    
                                        <div class="accordian-container">
                                            <mat-accordion class="example-headers-align" multi>
                                                <mat-expansion-panel *ngFor="let workWeek of workWeeks.controls; let i = index" [expanded]="workRotation?.id == null && i == 0">
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                            <div class="label">Work Week #{{i + 1}}</div>
                                                        </mat-panel-title>
                                                        <div class="card-buttons-container">
                                                            <mat-icon
                                                                setColor
                                                                [setColorRules]="[
                                                                    {
                                                                        color: 'primaryColour',
                                                                        events: ['hover']
                                                                    }
                                                                ]"
                                                                class="material-icons-outlined"
                                                                (click)="copyWorkWeek(i); $event.stopPropagation();"
                                                                matTooltip="Create a Copy"
                                                            >copy
                                                            </mat-icon>
    
                                                            <mat-icon
                                                                setColor
                                                                [setColorRules]="[
                                                                    {
                                                                        color: 'primaryColour',
                                                                        events: ['hover']
                                                                    }
                                                                ]"
                                                                class="material-icons-outlined"
                                                                (click)="deleteWorkWeek(i); $event.stopPropagation();"
                                                                matTooltip="Delete"
                                                            >delete
                                                            </mat-icon>
                                                        </div>
                                                    </mat-expansion-panel-header>
    
                                                    <mat-panel-description>
                                                        <span></span>
                                                        <div>
                                                            <app-work-rotation-element-form
                                                                    [form]="workWeek.controls[0]"
                                                                    [label]="'Sunday'"
                                                            >
                                                            </app-work-rotation-element-form>
    
                                                            <app-work-rotation-element-form
                                                                    [form]="workWeek.controls[1]"
                                                                    [label]="'Monday'"
                                                            >
                                                            </app-work-rotation-element-form>
    
                                                            <app-work-rotation-element-form
                                                                    [form]="workWeek.controls[2]"
                                                                    [label]="'Tuesday'"
                                                            >
                                                            </app-work-rotation-element-form>
    
                                                            <app-work-rotation-element-form
                                                                    [form]="workWeek.controls[3]"
                                                                    [label]="'Wednesday'"
                                                            >
                                                            </app-work-rotation-element-form>
    
                                                            <app-work-rotation-element-form
                                                                    [form]="workWeek.controls[4]"
                                                                    [label]="'Thursday'"
                                                            >
                                                            </app-work-rotation-element-form>
    
                                                            <app-work-rotation-element-form
                                                                    [form]="workWeek.controls[5]"
                                                                    [label]="'Friday'"
                                                            >
                                                            </app-work-rotation-element-form>
    
                                                            <app-work-rotation-element-form
                                                                    [form]="workWeek.controls[6]"
                                                                    [label]="'Saturday'"
                                                            >
                                                            </app-work-rotation-element-form>
    
    
                                                        </div>
                                                    </mat-panel-description>
    
    
                                                </mat-expansion-panel>
                                            </mat-accordion>
                                        </div>
                                    </ng-container>
                                    <button mat-stroked-button class="add-localization-btn k-mt-4" setColor="primaryColour" color="primary" (click)="addNewWorkWeek(null)">
                                        <mat-icon class="material-icons-outlined">add</mat-icon>
                                        Add Work Week
                                    </button>
                                </div>
                            </div>
                        </div>
    
                        <div class="form-section" *ngIf="workRotationId">
                            <br>
                            <br>
                            <div class="form-section-title">
                                <h6>Positions Using This {{ 'WorkRotation' | translate: {Default: "Work Rotation"} }}</h6>
                            </div>
                            
                            <div class="kendo-grid">
                                    <kendo-grid
                                            [data]="gridDataResult"
                                            [pageSize]="pageSize"
                                            [style.maxHeight.vh]="85" 
                                            [skip]="skip"
                                            [pageable]="{
                                                info: true,
                                                pageSizes: [5, 10, 20, 50, 100],
                                                previousNext: true
                                            }"
                                            (pageChange)="pageChange($event)"
                                            [loading]="isLoading">
    
                                        <kendo-grid-column field="name" title="Name" width="300">
                                            
                                        </kendo-grid-column>

                                        <kendo-grid-messages 
                                            [noRecords]="isLoading ? '' : translate.instant('Grid-NoRecordsAvailable')"
                                            [pagerItemsPerPage]="translate.instant('Grid-Pager-ItemsPerPage')" 
                                            [pagerItems]="translate.instant('Grid-Pager-Items')"
                                            [pagerOf]="translate.instant('Grid-Pager-Of')"
                                        ></kendo-grid-messages>
    
                                    </kendo-grid>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </mat-card-content>
    </mat-card>
</app-site-settings-menu>
