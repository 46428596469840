import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { api_routes } from '@app/consts';
import { catchError, map } from 'rxjs/operators';
import { Culture } from '@app/shared/models/system-language/culture.model';
import { EnvironmentService } from "@app/core/services/environment.service";


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class EmployeeSecurityService {
  currentCulture: Culture;

  constructor(private http: HttpClient, private envService: EnvironmentService) {}

  setEmployeePassword(employeeId: string, password: {password: string}): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.SECURITY}/${api_routes.USERS}/${employeeId}/${api_routes.PASSWORD}`, password, {
      headers: headers
    });
  }

  getEmployeeOrganizations(employeeId: string, skip?: number, take?: string, filterString?: string, sortString?: string): Observable<any> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    filterString ? params = params.append('Filter', filterString) : null;
    sortString ? params = params.append('Sort', sortString) : null;

    return this.http
      .get<any>(`${this.envService.env.apiUrl}${api_routes.SECURITY}/${api_routes.USERS}/${employeeId}/${api_routes.ORGANIZATIONS}`, {
        headers: headers,
        params: params
      })
      .pipe(
        catchError(err => throwError(err)),
        map(res => {
          let data = {
            data: res.data,
            total: res.total
          }

          return data;
        })
      );
  }

  getEmployeeLoginHistory(employeeId: string, skip?: number, take?: string, filterString?: string, sortString?: string): Observable<any> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    filterString ? params = params.append('Filter', filterString) : null;
    sortString ? params = params.append('Sort', sortString) : null;

    return this.http
      .get<any>(`${this.envService.env.apiUrl}${api_routes.SECURITY}/${api_routes.USERS}/${employeeId}/${api_routes.LOGIN_HISTORIES}`, {
        headers: headers,
        params: params
      })
      .pipe(
        catchError(err => throwError(err)),
        map(res => {
          let data = {
            data: res.data,
            total: res.total
          }

          return data;
        })
      );
  }

  getCurrentEmployeeCulture(): Observable<Culture> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.SECURITY}/${api_routes.USERS}/${api_routes.GET_CURRENT_CULTURE}`, {
      headers: headers
    });
  }

  setCurrentEmployeeCulture(cultureId): Observable<Culture> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.SECURITY}/${api_routes.USERS}/${api_routes.SET_CURRENT_CULTURE}?culture=${cultureId}`, {
      headers: headers
    });
  }

  get currentUserCulture(): Culture {
    return this.currentCulture != null ? this.currentCulture : null;
  }

  setCurrentUserCulture(culture: Culture) {
    this.currentCulture = culture;
  }

}
