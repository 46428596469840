import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { defer, forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CompanyAsset, CompanyAssetVerbose } from './models/employee-company-asset.model';
import { EmployeeCompanyAssetsService } from './services/employee-company-assets.service';
import { api_routes, db_tables } from '@app/consts';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';

@Component({
  selector: 'app-employee-company-asset',
  templateUrl: './employee-company-asset.component.html',
  styleUrls: ['./employee-company-asset.component.scss']
})
export class EmployeeCompanyAssetComponent implements OnInit {
  employeeID: string;
  public columns: any[] = [
    { field: "assetType", subField: "text", title: "Asset Type", type: "navigation", tableId: "tfi_AssetType"},
    { field: "dateAssigned", title: "Date Assigned", type: "date", tableId: "tfi_AssetDateAssigned" },
    { field: "dateReturned", title: "Date Returned", type: "date", tableId: "tfi_AssetDateReturned" },
    { field: "comments", title: "Comments", tableId: "tfi_AssetComments" }
  ];
  public bindingType: String = "array";
  public view: Observable<GridDataResult>;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 5;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  dialogRef: MatDialogRef<FormGeneratorDialogComponent>;
  formId: string = 'frm_BCwCiOgFYGOW8E'
  changeReasonFormId: string = 'frm_QfjetqSInzFTFa';
  formValid: boolean;
  private sortString: string = 'dateAssigned-desc';
  isLoadingPermissions: boolean = true;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];
  accessAndHistoryURL: string = `${api_routes.TALENT}/[employeeId]/${api_routes.COMPANY_ASSETS}/[entityId]`;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private tablePermissionsService: TablePermissionsService,
    private companyAssetsService: EmployeeCompanyAssetsService,
    private route: ActivatedRoute,
  ) {
    this.route.paramMap.subscribe(
        params => this.employeeID = params.get("id")
    )
  }

  ngOnInit(): void {
    this.view = this.companyAssetsService.getCompanyAssets(this.employeeID);
    this.getCompanyAssets();
    this.getTargetEmployeeTablePermissions();
  }

  getCompanyAssets() {
    this.isLoading = true;

    this.companyAssetsService.getCompanyAssets(this.employeeID, String(this.pageSize), this.skip, this.sortString)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          }
          this.gridData = res.data;
        }
      );
  }

  getCompanyAsset(companyAsset?: CompanyAsset) {
    if(companyAsset === undefined){
      this.openFormDialog();
    }
    else {
      this.isLoading = true;

      this.companyAssetsService.getCompanyAsset(this.employeeID, companyAsset.id)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        res => {
          this.openFormDialog(res);
        }
      );
    }
  }

  getTargetEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeID);

    // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
    if(currentFetchedData !== null){
      this.targetEmployeeTablePermissions = currentFetchedData;
      this.isLoadingPermissions = false;
    }
    else {
      this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeID)
      .pipe(
        finalize(() => {
            this.isLoadingPermissions = false;
        })
      )
      .subscribe(
        res => {
          this.targetEmployeeTablePermissions = res;
        }
      );
    }
  }

  openFormDialog(companyAsset?: CompanyAssetVerbose) {
    let formData = {
      id: companyAsset ? companyAsset.id : null,
      asOf: moment().format(),
      assetType: companyAsset && companyAsset.assetType ? companyAsset.assetType.id : null,
      dateAssigned: companyAsset ? companyAsset.dateAssigned : null,
      dateReturned: companyAsset ? companyAsset.dateReturned : null,
      comments: companyAsset ? companyAsset.comments : null,
      changeReason: '',
      changeReasonComments: ''
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formTitle: `${this.translate.instant('CompanyAsset')}`,
      formId: this.formId,
      changeReasonFormId: this.changeReasonFormId,
      formData: formData,
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions,
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      this.save(event)
    });
  }

  formDataEmitted(formData) {
    this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  save(formData: any){
    this.overlayService.show();

    if(!formData.id){
      this.companyAssetsService.createCompanyAsset(this.employeeID, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getCompanyAssets();
        }
      );
    }
    else {
      this.companyAssetsService.updateCompanyAsset(this.employeeID, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getCompanyAssets();
        }
      );
    }
  }

  deleteCompanyAssets(CompanyAssetIds: string[]) {
    const observables = CompanyAssetIds.map(selectedItem => defer(() => this.companyAssetsService.deleteCompanyAsset(this.employeeID, selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.clearSelectedItems = !this.clearSelectedItems;
        this.getCompanyAssets();
          this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.getCompanyAssets();
  }

}

