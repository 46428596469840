<app-employee-layout selectedComponent="EmployeeSecurity">
    <div class="main-container">
        <div class="left">
            <mat-list>
                <mat-list-item 
                    *ngFor="let item of navList" 
                    (click)="navigateToSection(item.route)" 
                    [ngClass]="{'selected': section === item.route }"
                    appLocalizationPopupDirective 
                    [localizationCode]="item.text"
                    setColor 
                    [setColorRules]="[
                        {
                            color: 'primaryColour',
                            events: ['hover']
                        },
                        {
                            color: 'primaryColour',
                            setIfClass: ['selected']
                        },
                    ]"
                >
                    {{ translate.instant(item.text) }}
                </mat-list-item>
            </mat-list>
        </div>

        <div class="main-content" [ngSwitch]="section">
            <app-employee-password-reset *ngSwitchCase="'Password'" [employeeID]="employeeId"></app-employee-password-reset>
            <app-employee-organizations *ngSwitchCase="'Organization'" [employeeID]="employeeId"></app-employee-organizations>
            <app-employee-login-history *ngSwitchCase="'LoginHistory'" [employeeID]="employeeId"></app-employee-login-history>
            <app-employee-delete *ngSwitchCase="'DeleteEmployee'" [employeeID]="employeeId"></app-employee-delete>
            <app-employee-security-roles *ngSwitchCase="'SecurityRoles'" [employeeID]="employeeId"></app-employee-security-roles>
            <!-- <app-employee-work-experience *ngSwitchCase="'Password'" [employeeID]="employee.id"></app-employee-work-experience> -->
            
        </div>
    </div>
</app-employee-layout>