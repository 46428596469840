import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { WorkRotation, WorkRotationSubmit, WorkRotationWorkDay, WorkRotationWorkWeek } from '../../models/work-rotation.model';
import { WorkRotationService } from '../../services/work-rotation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { routes } from '@app/consts';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-work-rotation-editor',
    templateUrl: './work-rotation-editor.component.html',
    styleUrls: ['./work-rotation-editor.component.scss']
})
export class WorkRotationEditorComponent implements OnInit {

    public workRotationId: string;
    public allCultures: Culture[] = [];
    public isLoading: boolean;
    public workRotation: WorkRotation = null;
    public form: UntypedFormGroup;
    public gridDataResult: GridDataResult = {
        data: [],
        total: 0,
    };
    public pageSize: number = 20;
    public skip: number = 0;


    private routes: typeof routes = routes;

    constructor(
        private translate: TranslateService,
        private dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        private snackbarService: SnackbarService,
        private fb: UntypedFormBuilder,
        private overlayService: OverlayService,
        private cultureService: CultureService,
        private workRotationService: WorkRotationService,
    ) {
    }

    ngOnInit(): void {
        this.cultureService.getCultures().subscribe(res => {
            this.allCultures = res;
        });

        this.workRotationId = this.getIdInURL();

        this.workRotationId !== null
            ? this.getWorkRotation()
            : this.isLoading = false;
        this.createForm();
    }

    getIdInURL(): string {
        let IdInURL: string;

        this.route.paramMap.subscribe(
            params => IdInURL = params.get('workRotationId')
        );

        return IdInURL;
    }

    getWorkRotation() {
        this.isLoading = true;
        this.workRotationService.getWorkRotation(this.workRotationId)
            .subscribe(
                res => {
                    this.workRotation = res;
                    this.getWorkRotationPositions(this.workRotationId);
                }
            );
    }

    getWorkRotationPositions(workRotationId: string) {
        this.workRotationService.getWorkRotationPositions(workRotationId, this.skip, String(this.pageSize))
            .pipe(
                finalize(() => this.isLoading = false)
            )
            .subscribe(
                res => {
                    this.gridDataResult = {
                        data: res.data,
                        total: res.total,
                    };
                    this.createForm();
                }
            );
    }

    createForm() {
        this.form = this.fb.group({
            id: [this.workRotation ? this.workRotation.id : null],
            localizations: this.fb.array([], Validators.required),
            workWeeks: this.fb.array([], Validators.required)
        });

        if (this.workRotation) {
            this.workRotation.name.forEach(
                localization => {
                    this.addNewLocalization(localization.culture, localization.text);
                }
            );
            if (this.workRotation.workWeeks.length > 0) {
                this.workRotation.workWeeks.forEach(
                    workWeek => {
                        this.addNewWorkWeek(workWeek);
                    }
                );
            }
        } else {
            this.addNewLocalization();
            this.addNewWorkWeek();
        }
    }

    get localizations() {
        return this.form.controls['localizations'] as UntypedFormArray;
    }

    isCultureHidden(cultureId) {
        let res = false;
        this.localizations.value.forEach(localization => {
            if (localization.culture === cultureId) {
                res = true;
            }
        });
        return res;
    }

    //Create a textLocalization form group object to add to the localizations form array
    addNewLocalization(culture?: string, localization?: string) {
        const localizationForm = this.fb.group({
            culture: [culture || '', Validators.required],
            text: [localization || '', Validators.required]
        });

        this.localizations.push(localizationForm);
    }

    deleteLocalization(index: number) {
        this.localizations.removeAt(index);
    }

    get workWeeks() {
        return this.form.controls['workWeeks'] as UntypedFormArray;
    }

    addNewWorkWeek(workWeek?: WorkRotationWorkWeek) {
        const workWeekForm = this.fb.array([
            this.createWorkDayForm(workWeek?.sunday),
            this.createWorkDayForm(workWeek?.monday),
            this.createWorkDayForm(workWeek?.tuesday),
            this.createWorkDayForm(workWeek?.wednesday),
            this.createWorkDayForm(workWeek?.thursday),
            this.createWorkDayForm(workWeek?.friday),
            this.createWorkDayForm(workWeek?.saturday),
        ]);

        this.workWeeks.push(workWeekForm);
    }

    deleteWorkWeek(index: number) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            text: 'Are you sure you want to delete this element?'
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data === true) {
                    this.workWeeks.removeAt(index);
                }
            }
        );
    }

    copyWorkWeek(index: number) {
        let form = this.workWeeks.controls[index];
        const workWeekForm = this.fb.array([
            this.createWorkDayFormFromForm(form.value[0]),
            this.createWorkDayFormFromForm(form.value[1]),
            this.createWorkDayFormFromForm(form.value[2]),
            this.createWorkDayFormFromForm(form.value[3]),
            this.createWorkDayFormFromForm(form.value[4]),
            this.createWorkDayFormFromForm(form.value[5]),
            this.createWorkDayFormFromForm(form.value[6]),
        ]);
        this.workWeeks.push(workWeekForm);
    }

    createWorkDayForm(day: WorkRotationWorkDay) {
        return this.fb.group({
            startTime: [day?.startTime ? day.startTime.substring(11, 16) : null],
            endTime: [day?.endTime ? day.endTime.substring(11, 16) : null],
            breakTime: [day?.breakTime ? day.breakTime : 0],
            unpaidBreakTime: [day?.unpaidBreakTime ? day.unpaidBreakTime : 0],
            workingHours: [day?.workingHours ? day.workingHours : 0],
        }, {validator: this.startTimeEndTimeValidation});
    }

    createWorkDayFormFromForm(day: WorkRotationWorkDay) {
        return this.fb.group({
            startTime: [day?.startTime ? day.startTime : null],
            endTime: [day?.endTime ? day.endTime : null],
            breakTime: [day?.breakTime ? day.breakTime : 0],
            unpaidBreakTime: [day?.unpaidBreakTime ? day.unpaidBreakTime : 0],
            workingHours: [day?.workingHours ? day.workingHours : 0],
        }, {validator: this.startTimeEndTimeValidation});
    }

    startTimeEndTimeValidation(formGroup): any {
        let startTime = formGroup.controls['startTime'].value;
        let endTime = formGroup.controls['endTime'].value;

        return (startTime && !endTime || !startTime && endTime) ? {required: true} : null;
    }


    save() {
        this.overlayService.show();

        let workRotationSubmit: WorkRotationSubmit = {
            effectiveDate: moment().format(),
            changeReason: '',
            changeReasonComments: '',
            customProperties: [],
            id: this.form.value.id,
            name: this.form.value.localizations,
            workWeeks: []
        };
        if (this.workWeeks.length > 0) {
            this.workWeeks.value.forEach(workWeek => {
                let workRotationWorkWeek: WorkRotationWorkWeek = {
                    sunday: this.getWorkDayOrNull(workWeek[0]),
                    monday: this.getWorkDayOrNull(workWeek[1]),
                    tuesday: this.getWorkDayOrNull(workWeek[2]),
                    wednesday: this.getWorkDayOrNull(workWeek[3]),
                    thursday: this.getWorkDayOrNull(workWeek[4]),
                    friday: this.getWorkDayOrNull(workWeek[5]),
                    saturday: this.getWorkDayOrNull(workWeek[6]),
                };
                workRotationSubmit.workWeeks.push(workRotationWorkWeek);
            });
        }
        if (this.workRotation) {
            this.updateWorkRotation(workRotationSubmit);
        } else {
            this.createWorkRotation(workRotationSubmit);
        }
    }

    getWorkDayOrNull(workDay: any): WorkRotationWorkDay {
        if (workDay.startTime == null && workDay.endTime == null) {
            return null;
        } else {
            return workDay;
        }
    }

    createWorkRotation(workRotationSubmit: WorkRotationSubmit) {
        this.workRotationService.postWorkRotation(workRotationSubmit)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe(
                (res) => {
                    this.navigateBack();
                    this.snackbarService.openSnackBar(this.translate.instant('SavedSuccessfully'), 'clear', 'success');
                },
                err => {
                    this.snackbarService.openSnackBar(err, 'clear', 'warn');
                }
            );
    }

    updateWorkRotation(workRotationSubmit: WorkRotationSubmit) {
        this.workRotationService.putWorkRotation(workRotationSubmit.id, workRotationSubmit)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe(
                (res) => {
                    this.snackbarService.openSnackBar(this.translate.instant('SavedSuccessfully'), 'clear', 'success');
                },
                err => {
                    this.snackbarService.openSnackBar(err, 'clear', 'warn');
                }
            );
    }

    navigateBack() {
        this.router.navigate([`${routes.SITE_SETTINGS}/${routes.WORK_ROTATIONS}`]);
    }

    public pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.pageSize = event.take;
        this.getWorkRotationPositions(this.workRotationId);
    }
}
