import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { db_tables, features, routes } from '@app/consts';
import { CompanyLogo } from '@app/modules/site-settings-styling/components/company-logos/models/company-logos.model';
import { CompanyLogosService } from '@app/modules/site-settings-styling/components/company-logos/services/company-logos.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-select-company-logo-dialog',
  templateUrl: './select-company-logo-dialog.component.html',
  styleUrls: ['./select-company-logo-dialog.component.scss']
})
export class SelectCompanyLogoDialogComponent implements OnInit {
  isLoading: boolean;

  public db_tables = db_tables;
  public routes: typeof routes = routes;
  public features: typeof features = features;
  public columns: any[] = [
    {field: 'id', title: 'Logo', type: 'logoImage'},
    {field: 'name', title: 'name'},
    {field: 'active', title: 'active', type: 'booleanToggle'},
    {field: 'defaultLogo', title: 'defaultLogo', type: 'booleanToggle'},
  ];
  public sort: SortDescriptor[] = [];
  public bindingType: String = 'array';
  public gridDataResult: GridDataResult;
  clearSelectedItems: boolean = false;
  pageSize: number = 20;
  skip: number = 0;
  sortString: string = "active-asc";
  sortingColumns: boolean;
  public searchFilterString: string;
  public searchValue: string;
  filterString: string;

  selectedCompanyLogo: CompanyLogo;
  selectedRowsButtonSettings : {
    show: boolean
    text: boolean
    icon: string
  } = {
    show: false,
    text: null,
    icon: null
  }


  constructor(
    public translate: TranslateService,
    private companyLogosService: CompanyLogosService,
    private dialogRef: MatDialogRef<SelectCompanyLogoDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.getCompanyLogos();
  }

  getCompanyLogos() {
    this.isLoading = true;

    this.companyLogosService.getCompanyLogos(this.skip, this.pageSize)
    .pipe(
        finalize( () => {
          this.isLoading = false;
        })
    )
    .subscribe(
        res => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          };
        }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.getCompanyLogos();
  }

  public sortChange(sort: SortDescriptor[]): void {
      this.sort = sort;

      if (sort[0].dir === undefined) {
          this.sortString = null;
      } else {
          //use regex to get column field to sort with
          let field: any;

          //if it is an object category like (department.text) trim .text from the end, else it doesnt need to be trimmed
          (sort[0].field).match(/.+(?=\.)/) === null
              ? field = sort[0].field
              : field = (sort[0].field).match(/.+(?=\.)/);
          this.sortString = `${field}-${sort[0].dir}`;
      }

      this.getCompanyLogos();
  }

  save() {
    this.dialogRef.close(this.selectedCompanyLogo);
  }

  close() {
    this.dialogRef.close();
  }

  filterCallback(filterString: string) {
      this.searchFilterString = filterString;
      // this.filterString = filterString;
      this.skip = 0;;
      this.getCompanyLogos();
  }

  selectedRowsChanged(selectionEvent: SelectionEvent){
    if(selectionEvent?.selectedRows[0]?.dataItem){
      this.selectedCompanyLogo = selectionEvent.selectedRows[0].dataItem;
    }
  }

}
