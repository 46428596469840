<h5 mat-dialog-title>{{ 'AssignToilPolicy' | translate: {Default: "Assign Time Off In Lieu Policy"} }}</h5>

<mat-dialog-content>
    <form class="form-container" *ngIf="form && toilTypeOptions.length > 0; else loading" [formGroup]="form">

        <mat-form-field appearance="fill">
            <mat-label>
                {{ 'ToilType' | translate: {Default: "Time Off In Lieu Type"} }}
            </mat-label>

            <mat-select
                disableOptionCentering
                formControlName="type"
                (selectionChange)="setSelectedToilType($event)">
                <mat-option></mat-option>
                <ng-container *ngIf="toilTypeOptions.length > 0">
                    <mat-option *ngFor="let option of toilTypeOptions" [value]="option.id">{{option.name}}</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                {{ 'ToilPolicy' | translate: {Default: "Time Off In Lieu Policy"} }}
            </mat-label>

            <mat-select disableOptionCentering formControlName="policy">
                <mat-option></mat-option>
                <ng-container *ngIf="selectedToilPolicies.length > 0">
                    <mat-option *ngFor="let option of selectedToilPolicies" [value]="option.id">{{option.name}}</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>

    </form>

    <ng-template #loading>
        <ngx-skeleton-loader
            count="5"
            [theme]="{
                'height.px': 50
            }"
        ></ngx-skeleton-loader>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button>
</mat-dialog-actions>
