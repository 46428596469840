<app-layout>
    <mat-toolbar class="page-header" role="heading">
      <h1>Monthly Absence Report</h1>
    </mat-toolbar>
  
    <div class="content">
      <app-monthly-absence-type-pie-chart></app-monthly-absence-type-pie-chart>

      <div>
        <app-leave-type-per-month-area-chart></app-leave-type-per-month-area-chart>
        <app-employee-absence-data-grid></app-employee-absence-data-grid>
      </div>
    </div>

</app-layout>