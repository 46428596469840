<mat-card>
    <mat-card-title class="header">
      <h5 appLocalizationPopupDirective localizationCode="Birthdays" class="title">{{ 'Birthdays' | translate: {Default: "Birthdays"} }}</h5>
        
      <mat-form-field appearance="outline">
        <mat-label>Filter</mat-label>
        <mat-select [(ngModel)]="birthdayTimePeriodSearchValue">
          <mat-option value="thisMonth">This Month</mat-option>
          <mat-option value="thisWeek">This Week</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-card-title>
  
    <mat-card-content class="content">
        <ng-container *ngIf="loading; else loaded">
            <ngx-skeleton-loader count="5" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
        </ng-container>
        <ng-template #loaded>
            <mat-list role="list">
                <mat-list-item class="employee" role="listitem" *ngFor="let employee of (employees  | birthdayTimePeriodSearch:birthdayTimePeriodSearchValue)">
                    <div class="employee-details">
                        <img 
                            class="profile-pic"
                            [src]="employee.profilePictureThumbnailUri">
                        <div>
                            <span class="name" routerLink="{{routes.TALENT_TRACK}}{{routes.EDIT}}/{{employee.id}}">
                                {{employee.firstName}} {{employee.lastName}}
                            </span>
                            <div class="positions">
                                <span *ngFor="let position of employee.positions">
                                    {{position.name}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="employee-birthday">
                        <ng-container *ngIf="isToday(employee.birthday); else showDate">
                            <button mat-raised-button setColor="primaryColour" color="primary" (click)="sendCongrats()">
                                <mat-icon class="material-icons-outlined">cake</mat-icon>
                                Congrats
                            </button> 
                        </ng-container>
                        <ng-template #showDate>
                            {{employee.birthday | date:'mediumDate'}}
                        </ng-template>
                    </div>
                </mat-list-item>
            </mat-list>
            <ng-container *ngIf="employees.length === 0">
                <h6>No bithdays coming up</h6>
            </ng-container>
        </ng-template>
    </mat-card-content>
</mat-card>
  