<mat-card>
    <mat-card-title class="header">
        <h5 appLocalizationPopupDirective localizationCode="EmploymentRecord-Payroll">{{ 'EmploymentRecord-Payroll' | translate: {Default: "Employment Record: Payroll"} }}</h5>
        <div>
        </div>
    </mat-card-title>
    <mat-card-content>
        <div class="top">
            <form class="form" *ngIf="form" [formGroup]="form">
                <div class="form-container">
                    <div class="form-section">
                        <div class="form-section-title">
                        </div>
                        <div class="form-section-contents">
                            <div class="section-row">
                                <mat-form-field appearance="fill">
                                    <mat-label>
                                        Hire Category
                                    </mat-label>
                        
                                    <mat-select disableOptionCentering formControlName="payrollGroup">
                                        <mat-option value="ID4">ID4</mat-option>
                                    </mat-select>
                                </mat-form-field>
                        
                                <mat-form-field appearance="fill">
                                    <mat-label>
                                        Payroll Number
                                    </mat-label>
                        
                                    <input matInput
                                        formControlName="payrollNumber">
                                </mat-form-field>
                            </div>
                            <div class="section-row">
                                <mat-form-field appearance="fill">
                                    <mat-label>
                                        Gross Amount
                                    </mat-label>
                        
                                    <input matInput
                                        type="text"
                                        formControlName="grossAmount">
                                </mat-form-field>
                    
                                <mat-form-field appearance="fill">
                                    <mat-label>
                                        Bank Transit Account No.
                                    </mat-label>
                        
                                    <input matInput
                                        type="text"
                                        formControlName="bankTransitAccountNo">
                                </mat-form-field>
                            </div>
                            <div class="section-row">
                                <mat-form-field appearance="fill">
                                    <mat-label>
                                        Pay Group - Corporate Salary
                                    </mat-label>
                        
                                    <mat-select disableOptionCentering formControlName="payrollGroupCorporateSalary">
                                        <mat-option value="1">1</mat-option>
                                    </mat-select>
                                </mat-form-field>
                    
                                <mat-form-field appearance="fill">
                                    <mat-label>
                                        On Cycle
                                    </mat-label>
                        
                                    <mat-select disableOptionCentering formControlName="onCycle">
                                        <mat-option value="yes">Yes</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="section-row">
        
                                <mat-form-field appearance="fill">
                                    <mat-label>
                                        Payroll Year
                                    </mat-label>
                        
                                    <mat-select disableOptionCentering formControlName="payrollYear">
                                        <mat-option value="2021">2021</mat-option>
                                    </mat-select>
                                </mat-form-field>
                    
                                <mat-form-field appearance="fill">
                                    <mat-label>
                                        Payroll Status
                                    </mat-label>
                        
                                    <mat-select disableOptionCentering formControlName="payrollStatus">
                                        <mat-option value="active">Active</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="section-row">
                                <mat-form-field appearance="fill">
                                    <mat-label>
                                        Deductions
                                    </mat-label>
                        
                                    <input matInput
                                        formControlName="deductions">
                                </mat-form-field>
                    
                                <mat-form-field  appearance="fill">
                                    <mat-label>
                                        Pay Period End Date
                                    </mat-label>
                    
                                    <input matInput [matDatepicker]="payPeriodEndDatepicker" formControlName="payPeriodEndDate">
                                    <span fxLayout="row" matSuffix>
                                        <mat-datepicker-toggle  [for]="payPeriodEndDatepicker"></mat-datepicker-toggle>
                                    </span>
                                    <mat-datepicker #payPeriodEndDatepicker></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="buttons-container">
                        <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button> 
                    </div>
                </div>
            </form>
    
            <app-payroll-pie-chart></app-payroll-pie-chart>
        </div>

        <div class="bottom">
            <app-direct-deposit-details></app-direct-deposit-details>
            <app-pay-stubs-data-grid></app-pay-stubs-data-grid>
        </div>
    </mat-card-content>
</mat-card>