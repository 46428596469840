import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TableListFieldOption } from '@app/modules/lookup/models/lookup.model';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import * as moment from 'moment';
import { Observable, from } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EmploymentRecord, EmploymentRecordSubmit } from '../../models/employment-record.model';
import { EmploymentRecordsService } from '../../services/employment-records.service';

@Component({
  selector: 'app-employment-record-dialog',
  templateUrl: './employment-record-dialog.component.html',
  styleUrls: ['./employment-record-dialog.component.scss']
})
export class EmploymentRecordDialogComponent implements OnInit {
  employmentRecordId: string;
  employeeId: string;
  employmentRecord: EmploymentRecord;
  isLoading: boolean;
  refreshAfterClose: boolean = false;
  form: UntypedFormGroup;
  medicalCompletedOptions: Observable<PagedData<TableListFieldOption>>;
  codeOfConductSignedOptions: Observable<PagedData<TableListFieldOption>>;
  hireCategoryOptions: Observable<PagedData<TableListFieldOption>>;
  noticePeriodUnitsOptions: Observable<PagedData<TableListFieldOption>>;
  payrollCodeOptions: Observable<PagedData<TableListFieldOption>>;
  rehireEligibilityOptions: Observable<PagedData<TableListFieldOption>>;
  recruitmentAgencyOptions: Observable<PagedData<TableListFieldOption>>;
  securityClearanceOptions: Observable<PagedData<TableListFieldOption>>;
  terminationReasonOptions: Observable<PagedData<TableListFieldOption>>;
  terminationRecordEmploymentReasonOptions: Observable<PagedData<TableListFieldOption>>;
  terminationRehireReasonOptions: Observable<PagedData<TableListFieldOption>>;
  terminationRehireRecommendOptions: Observable<PagedData<TableListFieldOption>>;
  terminationSpecialCircumstancesOptions: Observable<PagedData<TableListFieldOption>>;
  graduateProgramMemberOptions: Observable<PagedData<TableListFieldOption>>;
  subStatusOptions: Observable<PagedData<TableListFieldOption>>;

  constructor(
    private fb: UntypedFormBuilder,
    private employmentRecordsService: EmploymentRecordsService,
    private lookupService: LookupService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private dialogRef: MatDialogRef<EmploymentRecordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data)
  {
    this.employmentRecordId = data.employmentRecordId;
    this.employeeId = data.employeeId;
  } 

  ngOnInit(): void {
    this.getListOptions();

    this.employmentRecordId !== null 
      ? this.getEmploymentRecord() 
      : this.isLoading = false; 
        this.createForm();
  }

  getEmploymentRecord() {
    this.isLoading = true;
    
    this.employmentRecordsService.getEmploymentRecord(this.employeeId, this.employmentRecordId)
    .pipe(
      finalize(() => this.isLoading = false)
    )
    .subscribe(
      res => {
        this.employmentRecord = res;
        this.createForm();
      }
    );
  }

  createForm() {
    this.form = this.fb.group({
      id: [this.employmentRecord ? this.employmentRecord.id : null],
      employeeId: [this.employmentRecord ? this.employmentRecord.employeeId : null],
      hireDate: [this.employmentRecord ? this.employmentRecord.hireDate : null, Validators.required],
      budgetAuthority: [this.employmentRecord ? this.employmentRecord.budgetAuthority : null],
      inductionCompletedOn: [this.employmentRecord ? this.employmentRecord.inductionCompletedOn : null],
      medicalCompletedDate: [this.employmentRecord ? this.employmentRecord.medicalCompletedDate : null],
      codeOfConductSignedOn: [this.employmentRecord ? this.employmentRecord.codeOfConductSignedOn : null],
      probationPeriodLength: [this.employmentRecord ? this.employmentRecord.probationPeriodLength : null],
      probationPeriodEnd: [this.employmentRecord ? this.employmentRecord.probationPeriodEnd : null],
      noticePeriodLength: [this.employmentRecord ? this.employmentRecord.noticePeriodLength : null],
      replacementFor: [this.employmentRecord ? this.employmentRecord.replacementFor : null],
      searchFee: [this.employmentRecord ? this.employmentRecord.searchFee : null],
      rehireEligibilityComments: [this.employmentRecord ? this.employmentRecord.rehireEligibilityComments : null],
      hireDetailComments: [this.employmentRecord ? this.employmentRecord.hireDetailComments : null],
      terminationVoluntary: [this.employmentRecord ? this.employmentRecord.terminationVoluntary : null],
      terminationRecordOfEmploymentComment: [this.employmentRecord ? this.employmentRecord.terminationRecordOfEmploymentComment : null],
      terminationSeveranceAmount: [this.employmentRecord ? this.employmentRecord.terminationSeveranceAmount : null],
      terminationPayInLieu: [this.employmentRecord ? this.employmentRecord.terminationPayInLieu : null],
      terminationVacationPayout: [this.employmentRecord ? this.employmentRecord.terminationVacationPayout : null],
      terminationOtherPayment: [this.employmentRecord ? this.employmentRecord.terminationOtherPayment : null],
      terminationLastDateOnSite: [this.employmentRecord ? this.employmentRecord.terminationLastDateOnSite : null],
      terminationDateNotified: [this.employmentRecord ? this.employmentRecord.terminationDateNotified : null],
      terminationDateProjected: [this.employmentRecord ? this.employmentRecord.terminationDateProjected : null],
      terminationDateActual: [this.employmentRecord ? this.employmentRecord.terminationDateActual : null],
      terminationBenefitsEndOfNotice: [this.employmentRecord ? this.employmentRecord.terminationBenefitsEndOfNotice : null],
      terminationBenefitsEnd: [this.employmentRecord ? this.employmentRecord.terminationBenefitsEnd : null],
      terminationItConfirmation: [this.employmentRecord ? this.employmentRecord.terminationItConfirmation : null],
      terminationAcceptedByDate: [this.employmentRecord ? this.employmentRecord.terminationAcceptedByDate : null],
      terminationAcceptedByName: [this.employmentRecord ? this.employmentRecord.terminationAcceptedByName : null],
      terminationComments: [this.employmentRecord ? this.employmentRecord.terminationComments : null],
      payrollNumber: [this.employmentRecord ? this.employmentRecord.payrollNumber : null],
      codeOfConductSigned: [this.employmentRecord && this.employmentRecord.codeOfConductSigned ? this.employmentRecord.codeOfConductSigned.id : null],
      medicalCompleted: [this.employmentRecord && this.employmentRecord.medicalCompleted ? this.employmentRecord.medicalCompleted.id : null],
      securityClearance: [this.employmentRecord && this.employmentRecord.securityClearance ? this.employmentRecord.securityClearance.id : null],
      noticePeriodUnits: [this.employmentRecord && this.employmentRecord.noticePeriodUnits ? this.employmentRecord.noticePeriodUnits.id : null],
      reHireEligibility: [this.employmentRecord && this.employmentRecord.reHireEligibility ? this.employmentRecord.reHireEligibility.id : null],
      hireCategory: [this.employmentRecord && this.employmentRecord.hireCategory ? this.employmentRecord.hireCategory.id : null],
      recruitmentAgency: [this.employmentRecord && this.employmentRecord.recruitmentAgency ? this.employmentRecord.recruitmentAgency.id : null],
      terminationReason: [this.employmentRecord && this.employmentRecord.terminationReason ? this.employmentRecord.terminationReason.id : null],
      terminationRecordOfEmploymentReason: [this.employmentRecord && this.employmentRecord.terminationRecordOfEmploymentReason ? this.employmentRecord.terminationRecordOfEmploymentReason.id : null],
      terminationSpecialCircumstances: [this.employmentRecord && this.employmentRecord.terminationSpecialCircumstances ? this.employmentRecord.terminationSpecialCircumstances.id : null],
      terminationRehireRecommend: [this.employmentRecord && this.employmentRecord.terminationRehireRecommend ? this.employmentRecord.terminationRehireRecommend.id : null],
      terminationRehireReason: [this.employmentRecord && this.employmentRecord.terminationRehireReason ? this.employmentRecord.terminationRehireReason.id : null],
      payrollCode: [this.employmentRecord && this.employmentRecord.payrollCode ? this.employmentRecord.payrollCode.id : null],
      apprenticeshipProgramEndDate: [this.employmentRecord ? this.employmentRecord.apprenticeshipProgramEndDate : null], 
      apprenticeshipProgramStartDate: [this.employmentRecord ? this.employmentRecord.apprenticeshipProgramStartDate : null], 
      graduateProgramEndDate: [this.employmentRecord ? this.employmentRecord.graduateProgramEndDate : null], 
      graduateProgramStartDate: [this.employmentRecord ? this.employmentRecord.graduateProgramStartDate : null], 
      graduateProgramMember: [this.employmentRecord && this.employmentRecord.graduateProgramMember ? this.employmentRecord.graduateProgramMember.id : null],
      supervisorLegacyId: [this.employmentRecord ? this.employmentRecord.supervisorLegacyId : null],
      email: [this.employmentRecord ? this.employmentRecord.email : null],
      phoneNumber: [this.employmentRecord ? this.employmentRecord.phoneNumber : null],
      cellNumber: [this.employmentRecord ? this.employmentRecord.cellNumber : null],
      office: [this.employmentRecord ? this.employmentRecord.office : null],
      subStatus: [this.employmentRecord ? this.employmentRecord.subStatus?.id : null],
      asOf: [moment().format()],
    });
  }

  getListOptions() {
    this.medicalCompletedOptions = from(this.lookupService.getListOptions("MEDICAL_COMPLETED"));
    this.codeOfConductSignedOptions = from(this.lookupService.getListOptions("CODE_OF_CONDUCT_SIGNED"));
    this.hireCategoryOptions = from(this.lookupService.getListOptions("HIRE_CATEGORY"));
    this.noticePeriodUnitsOptions = from(this.lookupService.getListOptions("NOTICE_PERIOD_UNITS"));
    this.payrollCodeOptions = from(this.lookupService.getListOptions("PAYROLL_CODE"));
    this.rehireEligibilityOptions = from(this.lookupService.getListOptions("REHIRE_ELIGIBILITY"));
    this.recruitmentAgencyOptions = from(this.lookupService.getListOptions("RECRUITMENT_AGENCY"));
    this.securityClearanceOptions = from(this.lookupService.getListOptions("SECURITY_CLEARANCE"));
    this.terminationReasonOptions = from(this.lookupService.getListOptions("TERMINATION_REASON"));
    this.terminationRecordEmploymentReasonOptions = from(this.lookupService.getListOptions("TERMINATION_RECORD_EMPLOYMENT_REASON"));
    this.terminationRehireReasonOptions = from(this.lookupService.getListOptions("TERMINATION_REHIRE_REASON"));
    this.terminationRehireRecommendOptions = from(this.lookupService.getListOptions("TERMINATION_REHIRE_RECOMMEND"));
    this.terminationSpecialCircumstancesOptions = from(this.lookupService.getListOptions("TERMINATION_SPECIAL_CIRCUMSTANCES"));
    this.graduateProgramMemberOptions = from(this.lookupService.getListOptions("GRADUATE_PROGRAM_MEMBER"));
    this.subStatusOptions = from(this.lookupService.getListOptions("SUB_STATUS"));
  }

  save() {
    this.overlayService.show();

    if(this.employmentRecordId) {
      this.employmentRecordsService.updateEmploymentRecord(this.employeeId, this.form.value)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.refreshAfterClose = true;
          this.snackbarService.openSnackBar('Saved Successfully', 'clear', 'success');
        },
        err => {
          this.snackbarService.openSnackBar(err, 'clear', 'warn');
        }
      );
    }
    else {
      this.employmentRecordsService.postEmploymentRecord(this.employeeId, this.form.value)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.dialogRef.close(true);
        },
        err => {
          this.snackbarService.openSnackBar(err, 'clear', 'warn');
        }
      );
    }

  }

  close() {
    this.dialogRef.close(this.refreshAfterClose);
  }

}
