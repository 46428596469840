<app-layout>
    <div class="employee-layout-main-container" *ngIf="employeeContactCard$ | withLoading | async as employeeContactCard">
        <ng-template [ngIf]="employeeContactCard.value">
            <ng-container *ngIf="employeeContactCard.value.permissible; else noAccess">
                <div class="employee-layout-left">

                    <app-employee-contact-card [employeeId]="employeeId"></app-employee-contact-card>
        
                    <div class="nav-list" *ngIf="!isLoadingPermissions">
                        <mat-list>
                            <mat-list-item 
                                (click)="changeRoute('EmployeeDetails')" 
                                setColor 
                                [setColorRules]="[
                                    {
                                        color: 'primaryColour',
                                        events: ['hover']
                                    },
                                    {
                                        color: 'primaryColour',
                                        setIfClass: ['primaryColor']
                                    },
                                ]" 
                                class="primaryHoverColor" 
                                [ngClass]="{'primaryColor': selectedComponent === 'EmployeeDetails' }"
                            >
                                <mat-icon class="material-icons-outlined">account_box</mat-icon> <span appLocalizationPopupDirective localizationCode="EmployeeDetails">{{ 'EmployeeDetails' | translate: {Default: "Employee Details"} }}</span>
                            </mat-list-item>
                            <ng-container *appHasModuleAccess="modules.PERFORMANCE">
                                <ng-container *appHasFeature="features.PERFORMANCE">
                                    <ng-container *appHasFeature="features.PERFORMANCE_MY_GOALS">
                                        <mat-list-item 
                                            *ngIf="hasAccessToGoals()" 
                                            (click)="changeRoute('Performance')" 
                                            setColor
                                            [setColorRules]="[
                                                {
                                                    color: 'primaryColour',
                                                    events: ['hover']
                                                },
                                                {
                                                    color: 'primaryColour',
                                                    setIfClass: ['primaryColor']
                                                },
                                            ]"
                                            class="primaryHoverColor" 
                                            [ngClass]="{'primaryColor': selectedComponent === 'Performance' }"
                                        >
                                            <mat-icon class="material-icons-outlined">adjust</mat-icon> <span appLocalizationPopupDirective localizationCode="Performance">{{ 'Performance' | translate: {Default: "Performance"} }}</span>
                                        </mat-list-item>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                            <mat-list-item 
                                (click)="changeRoute('EmploymentRecords')" 
                                class="primaryHoverColor" 
                                setColor
                                [setColorRules]="[
                                    {
                                        color: 'primaryColour',
                                        events: ['hover']
                                    },
                                    {
                                        color: 'primaryColour',
                                        setIfClass: ['primaryColor']
                                    },
                                ]"
                                [ngClass]="{'primaryColor': selectedComponent === 'EmploymentRecords' }"
                            >
                                <mat-icon class="material-icons-outlined">article</mat-icon> <span appLocalizationPopupDirective localizationCode="tbl_EmploymentRecords">{{ 'tbl_EmploymentRecords' | translate: {Default: "Employment Records"} }}</span>
                            </mat-list-item>
                            <mat-list-item 
                                (click)="changeRoute('Positions')" 
                                *appTableAccess="[employeePositionReadAccessObj]" 
                                class="primaryHoverColor" 
                                setColor
                                [setColorRules]="[
                                    {
                                        color: 'primaryColour',
                                        events: ['hover']
                                    },
                                    {
                                        color: 'primaryColour',
                                        setIfClass: ['primaryColor']
                                    },
                                ]"
                                [ngClass]="{'primaryColor': selectedComponent === 'Positions' }"
                            >
                                <mat-icon class="material-icons-outlined">badge</mat-icon> <span appLocalizationPopupDirective localizationCode="Positions">{{ 'Positions' | translate: {Default: "Positions"} }}</span>
                            </mat-list-item>
                            <mat-list-item 
                                (click)="changeRoute('EmployeeSecurity/Password')" 
                                class="primaryHoverColor" 
                                setColor
                                [setColorRules]="[
                                    {
                                        color: 'primaryColour',
                                        events: ['hover']
                                    },
                                    {
                                        color: 'primaryColour',
                                        setIfClass: ['primaryColor']
                                    },
                                ]"
                                [ngClass]="{'primaryColor': selectedComponent === 'EmployeeSecurity/Password' }"  
                                *appHasFeature="'Security'"
                            >
                                <mat-icon class="material-icons-outlined">lock</mat-icon> <span appLocalizationPopupDirective localizationCode="Security">{{ 'Security' | translate: {Default: "Security"} }}</span>
                            </mat-list-item>
                        </mat-list>
                    </div>
                </div>
        
                <div class="main-content" *ngIf="!isLoadingPermissions">
                    <ng-content></ng-content>
                </div>
            </ng-container>
            <ng-template #noAccess>
                <div class="no-access-container">
                    <mat-card>
                        <app-employee-contact-card [employeeId]="employeeId"></app-employee-contact-card>
                    </mat-card>
                </div>
            </ng-template>
        </ng-template>
        <ng-template [ngIf]="employeeContactCard.error">
            <h6>There was an error loading</h6>
        </ng-template>
        <ng-template [ngIf]="employeeContactCard.loading">
            <div class="loading-container">
                <div class="employee-details">
                    <div class="profile-pic-container">
                        <ngx-skeleton-loader 
                        count="1" 
                        appearance="circle"
                        [theme]="{ 
                            'height.px': 200,
                            'width.px': 200
                        }">
                        </ngx-skeleton-loader>
                    </div>
                    <div style="width:200px; padding: 10px;">
                        <ngx-skeleton-loader
                        count="3"
                        [theme]="{ 
                            'height.px': 50
                        }"
                        ></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="main-content">
                <ngx-skeleton-loader
                count="10"
                [theme]="{ 
                    'height.px': 50
                }"
                ></ngx-skeleton-loader>
                </div>
            </div>
        </ng-template>
    </div>
</app-layout>
    
