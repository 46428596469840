import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@app/core/services/environment.service';
import { ModuleConfiguration } from '../models/module-configuration.model';
import { Observable } from 'rxjs';
import { api_routes } from '@app/consts';

const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class ModuleConfigurationService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getModuleConfiguration(): Observable<ModuleConfiguration> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.MODULE_CONFIGURATION}`, {
      headers: headers
    })
  }

  saveModuleConfiguration(formData: ModuleConfiguration): Observable<ModuleConfiguration> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.MODULE_CONFIGURATION}`, formData, {
      headers: headers
    })
  }
}


