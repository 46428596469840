import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { VisaPermitSubmit, VisaPermitVerbose } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-visas-and-permits/models/visa-permit.model';
import { catchError, map } from 'rxjs/operators';
import { api_routes } from '@app/consts';
import { EnvironmentService } from "@app/core/services/environment.service";


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class VisasAndPermitsService {

  constructor(private http: HttpClient, private envService: EnvironmentService) {}

  getVisaPermits(employeeId: string, take?: string, skip?: number, sort?: string): Observable<any> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    sort ? params = params.append('sort', sort) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.VISAS_PERMITS}`, {
      headers: headers,
      params: params
    })
    .pipe(
      catchError(err => throwError(err)),
      map(res => {
        let data = {
          data: res.data,
          total: res.total
        }

        return data;
      })
    );
  }

  deleteVisaPermit(employeeId: string, id: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.VISAS_PERMITS}/${id}`, {
      headers: headers
    });
  }

  getVisaPermit(employeeId: string, id: string): Observable<VisaPermitVerbose> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.VISAS_PERMITS}/${id}`, {
      headers: headers
    });
  }

  updateVisaPermit(employeeId: string, employeeVisaPermit: VisaPermitSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.VISAS_PERMITS}/${employeeVisaPermit.id}`, employeeVisaPermit, {
      headers: headers
    });
  }

  createVisaPermit(employeeId: string, employeeVisaPermit: VisaPermitSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.VISAS_PERMITS}`, employeeVisaPermit, {
      headers: headers
    });
  }
}
