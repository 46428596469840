import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TimeOffInLieuService } from '@app/core/services/time-off-in-lieu/time-off-in-lieu.service';
import { GenerateScheduleService } from '@app/modules/generate-schedules/services/generate-schedule.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { EmployeeToilPolicy, EmployeeToilRequest, TOIL, TOILRequest } from '@app/shared/models/time-off-in-lieu/time-off-in-lieu.model';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { db_tables, fieldTypes } from "@app/consts";
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TableField } from '@app/modules/security-setup/models/table-field.model';
import { SecuritySetupService } from '@app/modules/security-setup/services/security-setup.service';

@Component({
    selector: 'app-accrue-toil-dialog',
    templateUrl: './accrue-toil-dialog.component.html',
    styleUrls: ['./accrue-toil-dialog.component.scss']
})
export class AccrueToilDialogComponent implements OnInit {

    formId: string = 'frm_zGoKX06xIAFT2D';
    public db_tables = db_tables;
    employeeToilRequest: EmployeeToilRequest;
    employeeId: string;
    
    formElements = [
        {
            formControl: 'toilPolicyId',
            text: 'Toil Policy',
            tableField: {
                id: 'tfi_ToilRecordToilPolicy'
            }
        },
    ]
    formData: any;
    extraFieldsForm: UntypedFormGroup;
    formValid: any;
    formPristine: boolean;
    targetEmployeeTablePermissions: EmployeeTableSecurity[];
    loadingDbTableFields: boolean;
    employeeToilPolicies: EmployeeToilPolicy[] = [];
    dbTableFields: TableField[];
    readOnly: boolean = false;
    getFormData: boolean = false;
        
    constructor(
        private fb: UntypedFormBuilder,
        public translate: TranslateService,
        private timeOffInLieuService: TimeOffInLieuService,
        private snackbarService: SnackbarService,
        private overlayService: OverlayService,
        private generateScheduleService: GenerateScheduleService,
        private dialogRef: MatDialogRef<AccrueToilDialogComponent>,
        private securitySetupService: SecuritySetupService,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.employeeToilRequest = data.employeeToilRequest,
        this.employeeId = data.employeeId
        this.targetEmployeeTablePermissions = data.targetEmployeeTablePermissions
    }

    ngOnInit(): void {
        this.getDbTableFields();
        this.getEmployeeToilPolicies(0, 100);
        this.buildForm();
    }

    getEmployeeToilPolicies(skip: number, take: number){
        this.timeOffInLieuService.getEmployeeToilPolicies(this.employeeId, take.toString(), skip)
        .subscribe(pagedData => {
            this.employeeToilPolicies.push(...pagedData.data);

            if(take < pagedData.total){
                this.getEmployeeToilPolicies(skip+100, take+100);
            }
        });
    }

    buildForm() {

        this.extraFieldsForm = this.fb.group({
            toilPolicyId: [{value: this.employeeToilRequest?.toilPolicy?.id ?? null, disabled: false}, Validators.required],
            toilClassTypeId: ["AccrueToil", Validators.required],
        });
              

        this.formData = {
          startDate: this.employeeToilRequest ? this.employeeToilRequest?.startDate : null,
          endDate: this.employeeToilRequest ? this.employeeToilRequest?.endDate : null,
          comments: this.employeeToilRequest ? this.employeeToilRequest?.comments : null,
          halfDay: this.employeeToilRequest ? this.employeeToilRequest?.halfDay : false,
          setTime: this.employeeToilRequest ? this.employeeToilRequest?.startTime || this.employeeToilRequest?.endTime || this.employeeToilRequest?.hours : false,
          startTime: this.employeeToilRequest ? this.formatTime(this.employeeToilRequest?.startTime) : null,
          endTime: this.employeeToilRequest ? this.formatTime(this.employeeToilRequest?.endTime) : null,
          hours: this.employeeToilRequest ? this.employeeToilRequest?.hours : ''
        };
    
    }

    getDbTableFields() {
        this.loadingDbTableFields = true;
    
        this.securitySetupService.getFields(this.db_tables.TOIL, 0, '100')
        .pipe(
          finalize(()=>{
            this.loadingDbTableFields = false;
          })
        )
        .subscribe(
          res => {
            this.dbTableFields = res.data;
            this.addDBTableValues();
          }
        );
      }
    
      addDBTableValues() {
        this.dbTableFields.forEach(
          dbTableField => {
            let formElIndex = this.formElements.findIndex( el => el.tableField?.id === dbTableField?.id)
    
            if(formElIndex !== -1){
              this.formElements[formElIndex] = this.useDbTableFieldValues(this.formElements[formElIndex], dbTableField)
            }
          }
        )
    
      }
    
      useDbTableFieldValues(formElement: any, dbTableField: TableField) {
        // set field name
        // let currentCultureFieldName: Localization[] = dbTableField.name.filter( n => n.culture === this.currentCulture.id );
        // formElement.text = currentCultureFieldName[0].text;
        formElement.text = dbTableField.name;
    
        // set field hidden value
        formElement.hidden = dbTableField.enabled ? 'false' : 'true';
    
        // set required value
        formElement.requiredField = dbTableField.requiredField ? 'true' : 'false';
    
        // set min length value
        // formElement.minLength = dbTableField.minimumValue;
    
        // set max length value
        // formElement.maxLength = dbTableField.maximumValue;
    
        // set the placeholder
        formElement.placeholder = dbTableField.defaultValue;
    
        return formElement
      }

    formatTime(time) {
        try {
            const [hours, minutes] = time.split(':');
            return `${hours}:${minutes}`;
        } catch (error) {
            return '';
        }
    }

    formDataEmitted(formData) {
        this.save(formData);
    }

    formStatusUpdated(formValid) {
        this.formValid = formValid;
    }

    formPristineEmitted(formPristine: boolean) {
        this.formPristine = formPristine;
    }

    save(formData: any){
        this.overlayService.show();
        
        formData = {...formData, ...this.extraFieldsForm.getRawValue()}
        
        if(this.employeeToilRequest) {
            this.timeOffInLieuService.editRequest(this.employeeToilRequest.id, formData)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe(
                (res) => {
                    this.snackbarService.openSnackBar(`${this.translate.instant('EdittedSuccessfully')}`, 'clear', 'success');
                    this.dialogRef.close(true);
                }
            );
        }
        else {
            this.timeOffInLieuService.submitRequest(formData)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe(
                (res) => {
                    this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
                    this.dialogRef.close(true);
                }
            );
        }
    }
    

    close() {
        this.dialogRef.close();
    }

    
}
