import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dataPropertyGetter'
})
export class DataPropertyGetterPipe implements PipeTransform {

  transform(object: any, keyName: string, ...args: unknown[]): unknown {
    //If the data is an object get the text property
    if(typeof object[keyName] === 'object' && object[keyName] !== null && object[keyName].text) {
      return object[keyName].text;
    }

    return object[keyName];
  }

}