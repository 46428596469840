import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  text: string;
  localizationCode: string;

  constructor(
    public translate: TranslateService,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.text = data.text;
    this.localizationCode = data.localizationCode;
  }

  ngOnInit() {}

  confirm() {
      this.dialogRef.close(true);
  }

  close() {
      this.dialogRef.close();
  }


}
