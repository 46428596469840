<div class="container">
    <div class="top">
        <h6>{{ 'PositionDocuments' | translate: {Default: "Position Documents"} }}</h6>
    </div>

    <div class="main-container" *ngIf="documentsList$ | withLoading | async as documentsList">
        <ng-template [ngIf]="documentsList.value">
            <div class="controls">
                <div class="buttons">
                    <button mat-flat-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openDocumentUploadDialog()">
                        <mat-icon class="material-icons-outlined">add</mat-icon> 
                        Add
                    </button>
                    <button mat-flat-button appPreventDoubleClick (throttledClick)="openDirectoryFormDialog()">
                        <mat-icon class="material-icons-outlined">create_new_folder</mat-icon> 
                    </button>
                </div>
            </div>
            
            <div class="tree-container">
                <kendo-treeview
                    [nodes]="documentsList.value"
                    [textField]="['name']"
                    kendoTreeViewExpandable
                    [(expandedKeys)]="expandedKeys"
                    [hasChildren]="hasChildren"
                    [children]="fetchChildren"
                    kendoTreeViewDragAndDrop
                    kendoTreeViewDragAndDropEditing
                    kendoTreeViewHierarchyBinding
                    (nodeDrop)="handleDrop($event)"
                >
                    <ng-template kendoTreeViewNodeTemplate let-dataItem>
                        <div class="details-container" *ngIf="dataItem.isFolder">
                            <img 
                            class="ms-icon" 
                            src="assets/icons/folder.svg" 
                            style="width: 30px;
                                    height: 30px;
                                    vertical-align: middle;"> 
                            <div class="document-label-top">
                                {{ dataItem.name }}
                            </div>
                            <div class="controls-container">
                                <button mat-icon-button [matMenuTriggerFor]="menu" class="actions-btn" aria-label="Example icon-button with a menu">
                                    <mat-icon 
                                        setColor 
                                        [setColorRules]="[
                                        {
                                            color: 'primaryColour',
                                            events: ['hover']
                                        }
                                        ]"
                                        class="material-icons-outlined"
                                    >more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item appPreventDoubleClick (throttledClick)="openDocumentEditDialog(dataItem)">
                                        <mat-icon class="material-icons-outlined">edit</mat-icon> 
                                        <span>Edit</span>
                                    </button>
                                    <button mat-menu-item appPreventDoubleClick (throttledClick)="openConfirmDeleteDialog(dataItem)">
                                        <mat-icon class="material-icons-outlined">delete</mat-icon> 
                                        <span>Delete</span>
                                    </button>
                                    <button mat-menu-item appPreventDoubleClick (throttledClick)="openDirectoryFormDialog(dataItem)">
                                        <mat-icon class="material-icons-outlined">create_new_folder</mat-icon> 
                                        <span>Add a Directory</span>
                                    </button>
                                    <button mat-menu-item appPreventDoubleClick (throttledClick)="openDocumentUploadDialog(dataItem)">
                                        <mat-icon class="material-icons-outlined">note_add</mat-icon> 
                                        <span>Upload a File</span>
                                    </button>
                                </mat-menu>    
                            </div>
                        </div>
                        <div *ngIf="!dataItem.isFolder">
                            <div class="details-container">
                                <ng-container *ngIf="icons[dataItem.contentType]; else blankIcon">
                                    <img 
                                        class="ms-icon" 
                                        src={{icons[dataItem.contentType]}} 
                                        style="width: 30px;
                                                height: 30px;
                                                vertical-align: middle;"> 
                                </ng-container>
                                <ng-template #blankIcon>
                                    <mat-icon class="blank-document-icon material-icons-outlined">description</mat-icon>
                                </ng-template>
                                <div class="document-label">
                                    <div class="document-label-top">
                                        <div class="title" (click)="download(dataItem)">{{dataItem.name}}</div>
                                        <div class="filesize">{{dataItem.fileLength | filesize }}</div>
                                    </div>
                                    <div class="document-label-bottom">
                                        Created {{dataItem.version.createdOn | date:'longDate'}} by <span setColor [setColorRules]="[ { color: 'primaryColour', events: ['hover'] } ]" class="clickable-icon" routerLink="{{routes.TALENT_TRACK}}{{routes.EDIT}}/{{dataItem.version.createdBy.id}}">{{dataItem.version.createdBy.firstName}} {{dataItem.version.createdBy.lastName}}</span>
                                    </div>
                                </div>
                                <div class="controls-container">
                                    <button mat-icon-button [matMenuTriggerFor]="menu" class="actions-btn" aria-label="Example icon-button with a menu">
                                        <mat-icon 
                                            setColor 
                                            [setColorRules]="[
                                            {
                                                color: 'primaryColour',
                                                events: ['hover']
                                            }
                                            ]"
                                            class="material-icons-outlined"
                                        >more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item appPreventDoubleClick (throttledClick)="openConfirmDeleteDialog(dataItem)">
                                            <mat-icon class="material-icons-outlined">delete</mat-icon> 
                                            <span>Delete</span>
                                        </button>
                                        <button mat-menu-item (click)="download(dataItem)">
                                            <mat-icon class="material-icons-outlined">download</mat-icon> 
                                            <span>Download</span>
                                        </button>
                                    </mat-menu>    
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template
                        kendoTreeViewDragClueTemplate
                        let-action="action"
                        let-destinationItem="destinationItem"
                        let-text="text"
                        let-sourceItem="sourceItem"
                    >
                        <div class="drag-clue-template">
                            <span
                                class="k-drag-status k-icon"
                                [ngClass]="getDragStatus(action, destinationItem)"
                            ></span>
                            <span *ngIf="sourceItem">{{ sourceItem.item.dataItem.name }}</span>
                        </div>
                    </ng-template>
                </kendo-treeview>
            </div>
        </ng-template>
        <ng-template [ngIf]="documentsList.error">Error {{ obs.error }}</ng-template>
        <ng-template [ngIf]="documentsList.loading">
            <ngx-skeleton-loader
                count="5"
                [theme]="{ 
                    'height.px': 50
                }"
            ></ngx-skeleton-loader>
        </ng-template>
    </div>
</div>


