import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { TranslationService } from '@app/core/services/translation.service';
import { EmployeeSecurityService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-security/services/employee-security.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-update-translation-dialog',
  templateUrl: './update-translation-dialog.component.html',
  styleUrls: ['./update-translation-dialog.component.scss']
})
export class UpdateTranslationDialogComponent implements OnInit {
  loadingCultures: boolean;
  cultures: Culture[];
  selectedCulture: any;
  selectedTranslationId: null;
  form: any;
  loadingTranslations: boolean;
  currentTranslations: any;
  currentCulture: Culture;

  constructor(
    private cultureService: CultureService,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private translationService: TranslationService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private employeeSecurityService: EmployeeSecurityService,
    private dialogRef: MatDialogRef<UpdateTranslationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.form = this.fb.group({
      systemId: [data?.systemId ?? null, Validators.required],
      cultureId: [null, Validators.required],
      text: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.getCurrentCulture();
    this.getCultures();
  }

  getCurrentCulture() {
    this.employeeSecurityService.getCurrentEmployeeCulture()
    .subscribe(
      res => {
        this.currentCulture = res;
        this.form.get('cultureId').setValue(this.currentCulture?.id)
        this.getCultureTranslations();
      }
    )
  }

  getCultures() {
    this.loadingCultures = true;
    
    this.cultureService.getCultures()
    .pipe(
      finalize(
        () => {
          this.loadingCultures = false;
        }
      )
    )
    .subscribe(
      res => {
        this.cultures = res;
        this.cultures.length > 0 && this.selectedCulture === undefined ? this.selectedCulture = this.cultures[0] : null;
      }
    );
  }

  // get the translations for a culture
  getCultureTranslations() {
    this.loadingTranslations = true;
    
    this.translationService.getTranslations(this.form.get('cultureId').value)
    .pipe(
      finalize(
        () => {
          this.loadingTranslations = false;
        }
      )
    )
    .subscribe(
      res => {
        this.currentTranslations = res;
        this.form.get('text').setValue(this.currentTranslations[this.form.get('systemId').value])
      }
    )
  }

  // Update the translation when the user presses save in the form
  updateTranslation() {
    this.overlayService.show();

    this.translationService.updateTranslation(this.form.value.cultureId, this.form.value.systemId, this.form.value.text)
    .pipe(
      finalize(
        () => {
          this.overlayService.hide();
        }
      )
    )
    .subscribe(
      res => {
        this.snackbarService.openSnackBar(this.translate.instant('UpdatedSuccessfully'), 'clear', 'success');
      }
    );
  }

  close(){
    this.dialogRef.close();
  }

}
