import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkLocation, WorkLocationSubmit, WorkLocationVerbose } from '../models/work-location.model';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { api_routes } from '@app/consts';
import { EnvironmentService } from "@app/core/services/environment.service";


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class WorkLocationsService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getWorkLocations(skip?: number, take?: string, filterString?: string, sortString?: string, filterInactive?: boolean): Observable<PagedData<WorkLocation>> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    filterString ? params = params.append('Filter', filterString) : null;
    sortString ? params = params.append('Sort', sortString) : null;
    filterInactive !== null ? params = params.append('filterInactive', filterInactive) : null;

    return this.http
      .get<any>(`${this.envService.env.apiUrl}${api_routes.WORK_LOCATIONS}`, {
        headers: headers,
        params: params
      })
  }

  async getAllWorkLocations(): Promise<PagedData<any>> {
    let take = 100;
    let skip = 0;
    let workLocations = {
      skip: null,
      take: null,
      totalPages: null,
      total: null,
      data: []
    }

    while (workLocations.total === null || skip < workLocations.total) {
        const res = await this.getWorkLocations(skip, take.toString(), null, null, false).toPromise();

        if (res && res.data && res.data.length > 0) {
            workLocations.data = workLocations.data.concat(res.data);
            skip += take;
            workLocations.total = res.total;
        } else {
            break;
        }
    }

    return workLocations;
  }

  deleteWorkLocation(workLocationId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.WORK_LOCATIONS}/${workLocationId}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  getWorkLocation(workLocationId: string): Observable<WorkLocationVerbose> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.WORK_LOCATIONS}/${workLocationId}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  createWorkLocation(workLocationSubmit: WorkLocationSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.WORK_LOCATIONS}`, workLocationSubmit, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  updateWorkLocation(workLocationSubmit: WorkLocationSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.WORK_LOCATIONS}/${workLocationSubmit.id}`, workLocationSubmit, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }


  // getFields(tableId: string): Observable<TableField[]> {
  //   return this.http.get<any>(endpoint + 'Metadata/Table/' + tableId + '/Field', {
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   });
  // }

  // getTableLookups(): Observable<TableLookups> {
  //   return this.http.get<any>(endpoint + 'Metadata/Table/TableLookups', {
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   });
  // }

  // getLookups(lookupTypeId: string): Observable<Lookup[]> {
  //   return this.http.get<any>(endpoint + lookupTypeId, {
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   });
  // }

  // getLookup(lookupTypeId: string, lookupId: string): Observable<LookupVerbose> {
  //   return this.http.get<any>(endpoint + lookupTypeId + `/${lookupId}`, {
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   });
  // }

  // deleteLookup(lookupTypeId: string, lookupId: string): Observable<any> {
  //   return this.http.delete<any>(endpoint + lookupTypeId + '/' + lookupId, {
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   });
  // }

  // updateLookup(lookupTypeId: string, lookupSubmit: LookupSubmit): Observable<Lookup> {
  //   return this.http.post<any>(endpoint + lookupTypeId, lookupSubmit, {
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   });
  // }
}
