import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Search } from '@app/shared/models/search/search.model';
import { EnvironmentService } from "@app/core/services/environment.service";

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private http: HttpClient, private envService: EnvironmentService) {}

  search(searchString: string, skip?: number, take?: string): Observable<Search> {
    let params = new HttpParams();
    searchString ? params = params.append('searchString', searchString) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<Search>(this.envService.env.apiUrl + 'Site/',
      {
        headers: {'Content-Type': 'application/json'},
        params
      }
    );
  }
}
