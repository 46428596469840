import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { api_routes, db_tables } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { ReviewPlansService } from '../../services/review-plans.service';
import { defer, finalize, forkJoin } from 'rxjs';
import { ReviewPlanReviewer, ReviewPlanReviewerSubmit } from '../../models/review-plan.model';
import { ReviewPlanReviewerDialogComponent } from './components/review-plan-reviewer-dialog/review-plan-reviewer-dialog.component';

@Component({
  selector: 'app-review-plan-reviewers',
  templateUrl: './review-plan-reviewers.component.html',
  styleUrls: ['./review-plan-reviewers.component.scss']
})
export class ReviewPlanReviewersComponent implements OnInit {
  @Input() reviewPlanId: string;

  public columns: any[] = [
    { field: "reviewer", title: "Reviewer", tableId: "tfi_PrpRReviewerUser", type: "employee"},
    { field: "reviewerType", subField: "name", title: "reviewerType", tableId: "tfi_PrpRReviewerType"},
    { field: "orderNumber", title: "Order Number", tableId: "tfi_PrpROrderNumber"},
    { field: "rating", subField: "name", title: "Rating", tableId: "tfi_PrpRRating"},
    { field: "comments", subField: "name", title: "Comments", tableId: "tfi_PrpRComments"},
    { field: "overallRating", subField: "name", title: "Overall Rating", tableId: "tfi_PrpROverallRating" },
    { field: "overallComments", subField: "name", title: "Overall Comments", tableId: "tfi_PrpROverallComments"},
    { field: "goalPlanOverallRating", subField: "name", title: "Goal Plan Overall Rating", tableId: "tfi_PrpRGoalPlanOverallRating" },
    { field: "goalPlanOverallComments", subField: "name", title: "Goal Plan Overall Rating", tableId: "tfi_PrpRGoalPlanOverallRating" },
    { field: "includeCalculatedRatings", title: "Include Calculated Ratings", tableId: "tfi_PrpRIncludeCalculatedRatings", type: "booleanToggle"},
  ];

  filterCategories: any[] = [
    { field: "orderNumber", title: "Order Number", tableId: "tfi_PrpROrderNumber", dataType: "Number"},
    { field: "includeCalculatedRatings", title: "Include Calculated Ratings", tableId: "tfi_PrpRIncludeCalculatedRatings", dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
  ];

  sortableColumns: any[] = [
    { field: "reviewer", sortValue: ["Reviewer.FirstName", "Reviewer.Id", "Reviewer.LastName"]},
    { field: "orderNumber", sortValue: "OrderNumber"},
    { field: "reviewerType", sortValue: ["ReviewerType.Id", "ReviewerType.Name"]},
    { field: "provideRating", sortValue: "ProvideRating"},
    { field: "provideComments", sortValue: "ProvideComments"},
    { field: "includeCalculatedRatings", sortValue: "IncludeCalculatedRatings"},
    { field: "goalPlanOverallComments", sortValue: "goalPlanOverallComments"},
    { field: "goalPlanOverallRating", sortValue: "goalPlanOverallRating"},
  ];

  public bindingType: String = "array";
  public view: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 20;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  dialogRef: any;
  formValid: any;
  sortString: string = 'OrderNumber-asc';
  searchValue: string;
  searchFilterString: string;
  filterString: any;
  getGoalsRequest: any;

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private router: Router,
    private overlayService: OverlayService,
    private reviewPlansService: ReviewPlansService
  ) { }

  ngOnInit(): void {
    this.view = this.getReviewPlanReviewers();
  }

  getReviewPlanReviewers() {
    this.isLoading = true;

    let filter;
    if(this.searchFilterString) {
      filter = this.searchFilterString;
    }
    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }

    this.getGoalsRequest = this.reviewPlansService.getReviewPlanReviewers(this.reviewPlanId, String(this.pageSize), this.skip, this.sortString, filter)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          }
        }
      );
  }

  getReviewPlanReviewerVerbose(ReviewPlanReviewer?: ReviewPlanReviewer) {
    if (ReviewPlanReviewer === undefined){
        this.openReviewPlanReviewerDialog();
    }
    else {
      this.openReviewPlanReviewerDialog(ReviewPlanReviewer);
    }
  }

  openReviewPlanReviewerDialog(reviewPlanReviewer?: ReviewPlanReviewer) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '80vw';

    dialogConfig.data = {
      reviewPlanId: this.reviewPlanId,
      reviewPlanReviewer: reviewPlanReviewer
    };

    this.dialogRef = this.dialog.open(ReviewPlanReviewerDialogComponent, dialogConfig);

    this.dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.getReviewPlanReviewers();
        }
      }
    );
  }

  deleteReviewPlanReviewers(reviewPlanReviewers: string[]) {
    const observables = reviewPlanReviewers.map(selectedItem => defer(() => this.reviewPlansService.deleteReviewPlanReviewer(this.reviewPlanId, selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.view = this.getReviewPlanReviewers();
        this.clearSelectedItems = !this.clearSelectedItems;
        this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.view = this.getReviewPlanReviewers();
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;

    let variants = this.searchValue.split(' ').filter(value => value);
    this.searchFilterString = '';
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `((Reviewer.FirstName like "${variant}") OR (Reviewer.LastName like "${variant}") OR (ReviewerType.Name like "${variant}"))`;
        }
    });

    this.getGoalsRequest.unsubscribe();
    this.getReviewPlanReviewers();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getReviewPlanReviewers();
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;;
    this.getReviewPlanReviewers();
  }

}

