import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {routes} from '@app/consts';
import {SanitiseGridDataService} from '@app/core/services/sanitise-grid-data/sanitise-grid-data.service';
import {TranslateService} from '@ngx-translate/core';
import {ExcelExportEvent, GridComponent, GridDataResult, PageChangeEvent, PageSizeItem} from '@progress/kendo-angular-grid';
import {SortDescriptor, State} from '@progress/kendo-data-query';
import {BehaviorSubject, Observable, from} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {AbsenceFinanceReportService} from '../../services/absence-finance-report.service';
import {AbsenceRecord} from '@app/modules/reports/components/absence-report/models/absence-report.model';
import {MatSelect, MatSelectChange} from "@angular/material/select";
import {TimeOffService} from "@app/modules/time-off/services/time-off.service";

@Component({
    selector: 'app-absence-finance-details-data-grid',
    templateUrl: './absence-finance-report-details-data-grid.component.html',
    styleUrls: ['./absence-finance-report-details-data-grid.component.scss']
})
export class AbsenceFinanceReportDetailsDataGridComponent implements OnInit {
    @ViewChild(GridComponent)
    public grid: GridComponent;

    public routes: typeof routes = routes;
    public sort: SortDescriptor[] = [];
    public bindingType = 'array';
    public view: Observable<GridDataResult>;
    public gridDataResult: GridDataResult;
    public selectedPositions: any[] = [];
    public pageSizes: (PageSizeItem | number)[] = [
        5,
        10,
        20,
        {
            text: 'All',
            value: 100000,
        },
    ];
    isLoading: boolean;
    pageSize = 4;
    skip = 0;
    sortString: string;
    filterString: string;
    absences: AbsenceRecord[];

    private filterTimeOffTypes: { [key: string]: string } = {};

    private selectedTimeOffTypes: string[] = [];
    private filterInactive = true;

    private filterFutureTimeOff = true;
    private filterFrom: Date;
    private filterTo: Date;

    public columns: any[] = [
        {field: 'employeeName', title: this.translate.instant('AbsenceFinance-EmployeeName'), dataType: 'String' , tableId: 'AbsenceFinance-EmployeeName'},
        {field: 'timeOffType', title: this.translate.instant('ABSENCEFINANCE-TIMEOFFTYPENAME'), dataType: 'String', tableId: 'ABSENCEFINANCE-TIMEOFFTYPENAME'},
        {field: 'totalHoursAvailable', title: this.translate.instant('AbsenceFinance-TotalHoursAvailable'), dataType: 'String', tableId: 'AbsenceFinance-TotalHoursAvailable'},
        {field: 'salary', title: this.translate.instant('AbsenceFinance-Salary'), dataType: 'String', tableId: 'AbsenceFinance-Salary'},
        {field: 'hourlyRate', title: this.translate.instant('AbsenceFinance-HourlyRate'), dataType: 'String', tableId: 'AbsenceFinance-HourlyRate'},
        {field: 'quarterlyTotal', title: this.translate.instant('AbsenceFinance-Outstanding-Liability'), dataType: 'String', tableId: 'AbsenceFinance-Outstanding-Liability'},
    ];
    public state: State = {
        skip: 0,
        take: 50,
    };
    public query: Observable<GridDataResult>;
    private stateChange = new BehaviorSubject<State>(this.state);
    public allData = (): Observable<GridDataResult> => {
        return from(this.absenceFinanceReportService.queryAll(this.state, null, null, null,
            this.filterTo, this.filterInactive, this.selectedTimeOffTypes, this.filterFutureTimeOff) );
    }

    constructor(
        private translate: TranslateService,
        private ngZone: NgZone,
        private absenceFinanceReportService: AbsenceFinanceReportService,
        private sanitiseGridDataService: SanitiseGridDataService,
        private timeOffService: TimeOffService
    ) {

        this.query = this.stateChange.pipe(
            tap((state) => {
                this.state = state;
                this.isLoading = true;
            }),
            switchMap((state) => absenceFinanceReportService.fetch(state, this.filterString, this.sortString,
                false, this.filterTo, this.filterInactive, this.selectedTimeOffTypes, this.filterFutureTimeOff)),
            tap(() => {
                this.isLoading = false;
            })
        );

        this.timeOffService.getTimeOffTypes(0, '999')
            .subscribe(
                resp => {

                    resp.data.forEach( val => {
                        this.filterTimeOffTypes[val.id] = val.name;
                    });

                });

        // Bind 'this' explicitly to capture the execution context of the component.
        this.allData = this.allData.bind(this);

    }

    ngOnInit(): void {
    }

    public pageChange(state: PageChangeEvent): void {
        this.stateChange.next(state);
    }

    public onExcelExport(e: ExcelExportEvent): void {
        e = this.sanitiseGridDataService.sanitise(e);
    }

    toggleFilterInactive($event: MatSlideToggleChange): void {
        this.filterInactive = $event.checked;
        this.stateChange.next(this.state);
    }

    toggleFilterFutureTimeOff($event: MatSlideToggleChange): void {
        this.filterFutureTimeOff = $event.checked;
        this.stateChange.next(this.state);
    }

    changeFromDate($event: any): void {
        this.filterFrom = $event.value.format('YYYY-MM-DD');
        this.stateChange.next(this.state);
    }

    changeToDate($event: any): void {
        this.filterTo = $event.value.format('YYYY-MM-DD');
        this.stateChange.next(this.state);
    }

    selectTimeOffTypes($event: MatSelectChange): void {
        this.selectedTimeOffTypes = $event.value;
        this.stateChange.next(this.state);
    }

}
