import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { finalize } from 'rxjs/operators';
import { defer, forkJoin } from 'rxjs';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TrainingAndCertificationService } from './services/training-and-certification.service';
import { TrainingAndCertification, TrainingAndCertificationVerbose } from './models/training-and-certification.model';
import { api_routes, db_tables } from '@app/consts';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';

@Component({
  selector: 'app-training-and-certifications',
  templateUrl: './training-and-certifications.component.html',
  styleUrls: ['./training-and-certifications.component.scss']
})
export class TrainingAndCertificationsComponent implements OnInit {
  @Input() formIds: string[];
  employeeID: string;

  public columns: any[] = [
    { field: "trainingName", title: "training Name", type: "navigation", default: "Not Provided", tableId: "tfi_TcTrainingName"},
    { field: "trainingType", subField: "text", title: "training Type", tableId: "tfi_tcTrainingType"},
    { field: "trainingProvider", subField: "text", title: "training Provider", tableId: "tfi_TcTrainingProvider"},
    { field: "trainingDescription", subField: "text", title: "training Description", tableId: "tfi_TcTrainingDescription"},
    { field: "trainingFunction", subField: "text", title: "training Function", tableId: "tfi_TcTrainingFunction"},
    { field: "dateCompleted", title: "date Completed", type: "date", tableId: "tfi_TcDateCompleted"},
    { field: "issueDate", title: "issue Date", type: "date", tableId: "tfi_TcIssueDate"},
    { field: "expiryDate", title: "expiry Date", type: "date", tableId: "tfi_TcExpiryDate"},
    { field: "institution", subField: "text", title: "institution", tableId: "tfi_TcInstitution"},
    { field: "certificateNo", title: "certificate number", tableId: "tfi_TcCertificateno"},
    { field: "vehicleType", subField: "text", title: "vehicle Type", tableId: "tfi_TcVehicleType"},
    { field: "countryOfIssue", subField: "name", title: "country Of Issue", tableId: "tfi_TcCountryOfIssue"},
    { field: "certificatePlaceOfIssue", title: "place Of Issue", tableId: "tfi_TcCPoI"},
    { field: "comments", title: "comments", tableId: "tfi_TcComments"},
  ];

  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 5;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  dialogRef: MatDialogRef<FormGeneratorDialogComponent>;
  formId: string = 'frm_Kr04U33rv5KcPv'
  changeReasonFormId: string = 'frm_v8zSVvw2z1PEB1';
  formValid: boolean;
  private sortString: string = 'dateCompleted-desc';
  isLoadingPermissions: boolean = true;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];
  accessAndHistoryURL: string = `${api_routes.TALENT}/[employeeId]/${api_routes.TRAINING_AND_CERTIFICATIONS}/[entityId]`;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private trainingAndCertificationService: TrainingAndCertificationService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private tablePermissionsService: TablePermissionsService,
    private route: ActivatedRoute,
  ) {
    this.route.paramMap.subscribe(
        params => this.employeeID = params.get("id")
    )
  }

  ngOnInit(): void {
    this.view = this.getTrainingAndCertifications();
    this.getTargetEmployeeTablePermissions();
  }

  getTrainingAndCertifications() {
    this.isLoading = true;

    this.trainingAndCertificationService.getTrainingAndCertifications(this.employeeID, String(this.pageSize), this.skip, this.sortString)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          }
          this.gridData = res.data;
        }
      );
  }

  getTrainingAndCertification(trainingAndCertification?: TrainingAndCertification) {
    if(trainingAndCertification === undefined){
      this.openFormDialog();
    }
    else {
      this.isLoading = true;

      this.trainingAndCertificationService.getTrainingAndCertification(this.employeeID, trainingAndCertification.id)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        res => {
          this.openFormDialog(res);
        }
      );
    }
  }

  getTargetEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeID);

    // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
    if(currentFetchedData !== null){
      this.targetEmployeeTablePermissions = currentFetchedData;
      this.isLoadingPermissions = false;
    }
    else {
      this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeID)
      .pipe(
        finalize(() => {
            this.isLoadingPermissions = false;
        })
      )
      .subscribe(
        res => {
          this.targetEmployeeTablePermissions = res;
        }
      );
    }
  }

  openFormDialog(trainingAndCertification?: TrainingAndCertificationVerbose) {
    let formData = {
      id: trainingAndCertification ? trainingAndCertification.id : null,
      asOf: moment().format(),
      changeReason: '',
      changeReasonComments: '',
      trainingName: trainingAndCertification ? trainingAndCertification.trainingName : null,
      dateCompleted: trainingAndCertification ? trainingAndCertification.dateCompleted : null,
      expiryDate: trainingAndCertification ? trainingAndCertification.expiryDate : null,
      issueDate: trainingAndCertification ? trainingAndCertification.issueDate : null,
      certificateNo: trainingAndCertification ? trainingAndCertification.certificateNo : null,
      certificatePlaceOfIssue: trainingAndCertification ? trainingAndCertification.certificatePlaceOfIssue : null,
      trainingFunction: trainingAndCertification ? trainingAndCertification.trainingFunction?.id : null,
      trainingType: trainingAndCertification ? trainingAndCertification.trainingType?.id : null,
      trainingProvider: trainingAndCertification ? trainingAndCertification.trainingProvider?.id : null,
      trainingDescription: trainingAndCertification ? trainingAndCertification.trainingDescription?.id : null,
      institution: trainingAndCertification ? trainingAndCertification.institution?.id : null,
      vehicleType: trainingAndCertification ? trainingAndCertification.vehicleType?.id : null,
      countryOfIssue: trainingAndCertification ? trainingAndCertification.countryOfIssue?.id : null,
      comments: trainingAndCertification ? trainingAndCertification.comments : null,
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formTitle: `${this.translate.instant('TrainingAndCertification')}`,
      formId: this.formId,
      changeReasonFormId: this.changeReasonFormId,
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions,
      formData: formData
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      this.save(event)
    });
  }

  formDataEmitted(formData) {
    this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  save(formData: any){
    this.overlayService.show();

    if(!formData.id){
      this.trainingAndCertificationService.createTrainingAndCertification(this.employeeID, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getTrainingAndCertifications();
        }
      );
    }
    else {
      this.trainingAndCertificationService.updateTrainingAndCertification(this.employeeID, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getTrainingAndCertifications();
        }
      );
    }
  }

  deleteTrainingAndCertifications(trainingAndCertificationIds: string[]) {
    const observables = trainingAndCertificationIds.map(selectedItem => defer(() => this.trainingAndCertificationService.deleteTrainingAndCertification(this.employeeID, selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.view = this.getTrainingAndCertifications();
        this.clearSelectedItems = !this.clearSelectedItems;
          this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.view = this.getTrainingAndCertifications();
  }

}






