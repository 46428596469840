<mat-card class="revenue-chart">
  <mat-card-title class="revenue-chart__header">
    <h5 class="revenue-chart__header-title">Workforce Cost Visibility</h5>
    
    <app-settings-menu></app-settings-menu>
  </mat-card-title>
  
  <mat-card-content class="revenue-chart__content">
    <div class="leave-chart-container">
        <div class="revenue-chart__content-chart" echarts [options]="chart"></div>
        <div class="chart-figures">
            <div class="row days-remaining">
                <div class="dot"></div>
                <div>
                    <div class="label"> Employee</div>
                    <!-- <div class="value">11 Days</div> -->
                </div>
            </div>
            <div class="row days-used">
                <div class="dot"></div>
                <div>
                    <div class="label"> Contingent Worker</div>
                    <!-- <div class="value">3 Days</div> -->
                </div>
            </div>
        </div>
    </div>
  </mat-card-content>
</mat-card>