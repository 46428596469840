<mat-card class="revenue-chart">
    <mat-card-title class="revenue-chart__header">
      <h5 class="revenue-chart__header-title">
          Employee Absences
      </h5>
      
      <app-settings-menu></app-settings-menu>
    </mat-card-title>
    
    <mat-card-content class="revenue-chart__content">
        <kendo-grid
            [data]="gridDataResult"
            [pageSize]="20"
            [pageable]="true"
            [sortable]="true"
            [resizable]="true"
            [height]="550"
            [columnMenu]="{ filter: true }"
        >
    
            <ng-template kendoGridToolbarTemplate>
                <mat-form-field class="grid-search-field" appearance="outline">
                    <input matInput
                            (input)="onFilter($event.target.value)"
                            placeholder="Search in all columns...">
                    <mat-icon matPrefix class="material-icons-outlined">
                        search
                    </mat-icon>
                </mat-form-field>

                <kendo-grid-spacer></kendo-grid-spacer>

                <mat-form-field  appearance="outline">
                    <mat-label>
                        Start Date
                    </mat-label>
                
                    <input matInput [matDatepicker]="startDatepicker">
                    <span fxLayout="row" matSuffix>
                        <mat-datepicker-toggle  [for]="startDatepicker"></mat-datepicker-toggle>
                    </span>
                    <mat-datepicker #startDatepicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>
                        End Date
                    </mat-label>
                
                    <input matInput [matDatepicker]="endDatepicker">
                    <span fxLayout="row" matSuffix>
                        <mat-datepicker-toggle  [for]="endDatepicker"></mat-datepicker-toggle>
                    </span>
                    <mat-datepicker #endDatepicker></mat-datepicker>
                </mat-form-field>
            </ng-template>

            <kendo-grid-column field="profilePictureThumbnailUri" title="" [width]="150">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="employee-name" routerLink="{{routes.TALENT_TRACK}}{{routes.EDIT}}/{{dataItem.id}}">
                        <img 
                        class="profile-pic"
                        [src]="dataItem.profilePictureThumbnailUri">
                        <span class="name">
                            {{dataItem.firstName}} {{dataItem.lastName}}
                        </span>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="customClientId" title="custom Client Id" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="absencesTaken" title="absences taken" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="annualLeaveRemaining" title="annual Leave Remaining" [width]="120"></kendo-grid-column>

            <kendo-grid-messages 
                [noRecords]="isLoading ? '' : translate.instant('Grid-NoRecordsAvailable')"
                [pagerItemsPerPage]="translate.instant('Grid-Pager-ItemsPerPage')" 
                [pagerItems]="translate.instant('Grid-Pager-Items')"
                [pagerOf]="translate.instant('Grid-Pager-Of')"
            ></kendo-grid-messages>
        </kendo-grid>
    </mat-card-content>
</mat-card>