<app-performance>
    <app-data-grid-comp
        [tableId]="db_tables.Reviews"
        [columns]="columns"
        [filterCategories]="filterCategories"
        [bindingType]="bindingType"
        [view]="view"
        [gridDataResult]="gridDataResult"
        [isLoading]="isLoading"
        [pageSize]="pageSize"
        [skip]="skip"
        [category]="translate.instant('Reviews')"
        [rowSelectionSettings]="{
            canSelectRows: false,
            selectableMode: 'none',
            selectRowBy: 'id'
        }"
        clickableRows="true"
        [isLoading]="isLoading"
        [clearSelectedItems]="clearSelectedItems"
        searchable="true"
        hideActionButtons="true"
        [sortable]="{
            mode: 'multiple'
        }"
        [sortableColumns]="sortableColumns"
        (emitSearchEvent)="search($event)"
        (emitPageChangeEvent)="pageChange($event)"
        (emitOpenDialogEvent)="getReview($event)"
        (emitSortEvent)="sortChange($event)"
        (emitFilterEvent)="filterCallback($event)"
    ></app-data-grid-comp>
</app-performance>
