import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ReportsService } from '../../services/reports.service';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ReportBuilderComponent} from "@app/modules/custom-reports/components/report-builder/report-builder.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-reports-rectangle-display-list',
  templateUrl: './reports-rectangle-display-list.component.html',
  styleUrls: ['./reports-rectangle-display-list.component.scss']
})
export class ReportsRectangleDisplayListComponent implements OnInit {
  reports$: Observable<any>;
  public displayedColumns: string[] = ['name'];

  constructor(
    private reportsService: ReportsService,
    private dialog: MatDialog,
    private router: Router,

  ) {
    this.reports$ = reportsService.getReports();
  }

  ngOnInit(): void {
  }

  openNewReportDialog() {
    this.router.navigate(['Reports/Builder']);

    // const dialogConfig = new MatDialogConfig();
    //
    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    // dialogConfig.minWidth = 1200;
    //
    // const dialogRef = this.dialog.open(ReportBuilderComponent, dialogConfig);
  }
}
