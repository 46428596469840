import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { api_routes, db_tables } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { ChangeReasonDialogComponent } from '@app/shared/components/change-reason-dialog/change-reason-dialog.component';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { FormGeneratorComponent } from '@app/shared/components/form-generator/form-generator.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { EmploymentRecord } from '../../../../models/employment-record.model';
import { EmploymentRecordsService } from '../../../../services/employment-records.service';
import { AccessLogsDialogComponent } from '../../../employment-record-details/components/access-logs-dialog/access-logs-dialog.component';
import { HistoryDialogComponent } from '../../../employment-record-details/components/history-dialog/history-dialog.component';

@Component({
  selector: 'app-employment-record-expansion-panel',
  templateUrl: './employment-record-expansion-panel.component.html',
  styleUrls: ['./employment-record-expansion-panel.component.scss']
})
export class EmploymentRecordExpansionPanelComponent implements OnInit {
  @Input() employmentRecord: EmploymentRecord;
  @Input() employeeId: string;
  @Output() getEmploymentRecords: EventEmitter<void> = new EventEmitter<void>();
  @Output() formGeneratorComponentEmit: EventEmitter<FormGeneratorComponent> = new EventEmitter<FormGeneratorComponent>();

  @ViewChild(FormGeneratorComponent) formGeneratorComponent: FormGeneratorComponent
  formData: any;
  formId: string = 'frm_ygxyk9Zfg6zaXh';
  changeReasonFormId: string = 'frm_foZ0pFhUtVBJ0O';
  getFormData: boolean = false;
  formValid: boolean = false;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];
  isLoadingPermissions: boolean = true;
  employmentRecordVerbose: EmploymentRecord;

  public columns: any[] = [
    {field: 'apprenticeshipProgramEndDate', tableId: "tfi_ApprenticeshipProgramEndDate", title: 'apprenticeship Program End Date', type: 'date'},
    {field: 'apprenticeshipProgramStartDate', tableId: "tfi_ApprenticeshipProgramStartDate", title: 'apprenticeship Program Start Date', type: 'date'},
    {field: 'hireDate', tableId: "tfi_ErHireDate", title: 'hire Date', type: 'date'},
    {field: 'budgetAuthority', tableId: "tfi_ErBudgetAuthority", title: 'budget Authority'},
    {field: 'inductionCompletedOn', tableId: "tfi_ErInductionCompletedOn", title: 'induction Completed On', type: 'date'},
    {field: 'medicalCompletedDate', tableId: "tfi_ErMedicalCompletedDate", title: 'medical Completed Date', type: 'date'},
    {field: 'codeOfConductSignedOn', tableId: "tfi_ErCodeOfConductSignedOn", title: 'code Of Conduct Signed On', type: 'date'},
    {field: 'probationPeriodLength', tableId: "tfi_ErProbationPeriodLength", title: 'probationPeriodLength'},
    {field: 'probationPeriodEnd', tableId: "tfi_ErProbationPeriodEnd", title: 'probation Period End', type: 'date'},
    {field: 'probationPeriodUnit', subField: "text", tableId: "tfi_ErProbationPeriodUnits", title: 'probation Period Units'},
    {field: 'noticePeriodLength', tableId: "tfi_ErNoticePeriodLength", title: 'notice Period Length'},
    {field: 'replacementFor', tableId: "tfi_ErReplacementFor", title: 'replacement For'},
    {field: 'searchFee', tableId: "tfi_ErSearchFee", title: 'search Fee'},
    {field: 'hireDetailComments', tableId: "tfi_ErHireDetailComments", title: 'hireDetailComments'},
    {field: 'terminationVoluntary', tableId: "tfi_ErTerminationVoluntary", title: 'terminationVoluntary'},
    {field: 'terminationRecordOfEmploymentComment', tableId: "tfi_ErTerminationRecordOfEmploymentComment", title: 'terminationRecordOfEmploymentComment'},
    {field: 'terminationSeveranceAmount', tableId: "tfi_ErTerminationSeveranceAmount", title: 'terminationSeveranceAmount'},
    {field: 'terminationPayInLieu', tableId: "tfi_ErTerminationPayInLieu", title: 'terminationPayInLieu'},
    {field: 'terminationVacationPayout', tableId: "tfi_ErTerminationVacationPayout", title: 'terminationVacationPayout'},
    {field: 'terminationOtherPayment', tableId: "tfi_ErTerminationOtherPayment", title: 'terminationOtherPayment'},
    {field: 'terminationLastDateOnSite', tableId: "tfi_ErTerminationLastDateOnSite", title: 'terminationLastDateOnSite', type: 'date'},
    {field: 'terminationDateNotified', tableId: "tfi_ErTerminationDateNotified", title: 'terminationDateNotified', type: 'date'},
    {field: 'terminationDateProjected', tableId: "tfi_ErTerminationDateProjected", title: 'terminationDateProjected', type: 'date'},
    {field: 'terminationDateActual', tableId: "tfi_ErTerminationDateActual", title: 'terminationDateActual', type: 'date'},
    {field: 'terminationBenefitsEndOfNotice', tableId: "tfi_ErTerminationBenefitsEndOfNotice", title: 'terminationBenefitsEndOfNotice', type: 'date'},
    {field: 'terminationBenefitsEnd', tableId: "tfi_ErTerminationBenefitsEnd", title: 'terminationBenefitsEnd', type: 'date'},
    {field: 'terminationItConfirmation', tableId: "tfi_ErTerminationItConfirmation", title: 'terminationBenefitsEnd', type: 'date'},
    {field: 'terminationAcceptedByDate', tableId: "tfi_ErTerminationAcceptedByDate", title: 'terminationBenefitsEnd', type: 'date'},
    {field: 'terminationAcceptedByName', tableId: "tfi_ErTerminationAcceptedByName", title: 'termination Accepted By Name'},
    {field: 'terminationComments', tableId: "tfi_ErTerminationComments", title: 'termination Comments'},
    {field: 'payrollNumber', tableId: "tfi_ErPayrollNumber", title: 'payroll Number'},
    {field: 'codeOfConductSigned', subField: "text", tableId: "tfi_ErCodeOfConductSigned", title: 'code Of Conduct Signed'},
    {field: 'medicalCompleted', subField: "text", tableId: "tfi_ErMedicalCompleted", title: 'medical Completed'},
    {field: 'securityClearance', subField: "text", tableId: "tfi_ErSecurityClearance", title: 'security Clearance'},
    {field: 'noticePeriodUnits', subField: "text", tableId: "tfi_ErNoticePeriodUnits", title: 'notice Period Units'},
    {field: 'reHireEligibility', subField: "text", tableId: "tfi_ErReHireEligibility", title: 're Hire Eligibility'},
    {field: 'rehireEligibilityComments', tableId: "tfi_ErRehireEligibilityComments", title: 'rehireEligibility Comments'},
    {field: 'hireCategory', subField: "text", tableId: "tfi_ErHireCategory", title: 'hire Category'},
    {field: 'recruitmentAgency', subField: "text", tableId: "tfi_ErRecruitmentAgency", title: 'recruitment Agency'},
    {field: 'terminationReason', subField: "text", tableId: "tfi_ErTerminationReason", title: 'termination Reason'},
    {field: 'terminationRecordOfEmploymentReason', subField: "text", tableId: "tfi_ErTerminationRecordOfEmploymentReason", title: 'termination Record Of Employment Reason'},
    {field: 'terminationSpecialCircumstances', subField: "text", tableId: "tfi_ErTerminationSpecialCircumstances", title: 'termination Special Circumstances'},
    {field: 'terminationRehireRecommend', subField: "text", tableId: "tfi_ErTerminationRehireRecommend", title: 'termination Rehire Recommend'},
    {field: 'terminationRehireReason', subField: "text", tableId: "tfi_ErTerminationRehireReason", title: 'termination Rehire Reason'},
    {field: 'payrollCode', subField: "text", tableId: "tfi_ErPayrollCode", title: 'payroll Code'},
    {field: 'graduateProgramEndDate', subField: "date", tableId: "tfi_GraduateProgramEndDate", title: 'graduate Program End Date'},
    {field: 'graduateProgramStartDate', subField: "date", tableId: "tfi_GraduateProgramStartDate", title: 'graduate Program Start Date'},
    {field: 'graduateProgramMember', subField: "text", tableId: "tfi_GraduateProgramMember", title: 'graduate Program Member'},
    {field: 'email', tableId: "tfi_ErEmail", title: 'email'},
    {field: 'phoneNumber', tableId: "tfi_ErWorkPhoneNumber", title: 'phone Number'},
    {field: 'cellNumber', tableId: "tfi_ErWorkCellNumber", title: 'cell Number'},
    {field: 'office', tableId: "tfi_ErOffice", title: 'office'},
    {field: 'subStatus', subField: "text", tableId: "tfi_SubStatus", title: 'sub Status'},
    {field: 'supervisorLegacyId', tableId: "tfi_SupervisorLegacyId", title: 'Supervisor Legacy Id'},
    {field: 'startReason', subField: "text", tableId: "tfi_ErStartReason", title: 'Start Reason'},
    {field: 'office2', tableId: "tfi_ErOffice2", title: 'Office 2'},
    {field: 'reHireReason', subField: "text", tableId: "tfi_ErReHireReason", title: 'Re Hire Reason'},
    {field: 'hireDetailsUdf1', subField: "text", tableId: "tfi_ErHireDetailsUdf1", title: 'Hire Details Udf1'},
    {field: 'hireDetailsUdf2', subField: "text", tableId: "tfi_ErHireDetailsUdf2", title: 'Hire Details Udf2'},
    {field: 'hireDetailsUdf3', subField: "text", tableId: "tfi_ErHireDetailsUdf3", title: 'Hire Details Udf3'},
    {field: 'hireDetailsUdd1', tableId: "tfi_ErHireDetailsUdd1", title: 'Hire Details Udd1', type: 'date'},
    {field: 'hireDetailsUdd2', tableId: "tfi_ErHireDetailsUdd2", title: 'Hire Details Udd2', type: 'date'},
  ];
  formPristine: boolean;
  isLoadingEmploymentRecord: boolean = true;

  constructor(
    public translate: TranslateService,
    private dialog: MatDialog,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private tablePermissionsService: TablePermissionsService,
    private employmentRecordsService: EmploymentRecordsService,
  ) {}

  ngOnInit(): void {
    this.getEmploymentRecord();
    this.getTargetEmployeeTablePermissions();
  }

  get tableReadAccessObj() {
    return {
      tableId: db_tables.EmploymentRecords,
      permission: 'read',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableCreateAccessObj() {
    return {
      tableId: db_tables.EmploymentRecords,
      permission: 'create',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableUpdateAccessObj() {
    return {
      tableId: db_tables.EmploymentRecords,
      permission: 'update',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableDeleteAccessObj() {
    return {
      tableId: db_tables.EmploymentRecords,
      permission: 'delete',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  isCurrentEmploymentRecord(terminationDateActual: string){
    if(!terminationDateActual){
      return true;
    }

    if(moment().isBefore(terminationDateActual)){
      return true;
    }

    return false;
  }

  getTargetEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeId);

    // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
    if(currentFetchedData !== null){
      this.targetEmployeeTablePermissions = currentFetchedData;
      this.isLoadingPermissions = false;
    }
    else {
      this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeId)
      .pipe(
        finalize(() => {
            this.isLoadingPermissions = false;
        })
      )
      .subscribe(
        res => {
          this.targetEmployeeTablePermissions = res;
        }
      );
    }
  }

  getEmploymentRecord() {
    this.isLoadingEmploymentRecord = true;

    this.employmentRecordsService.getEmploymentRecord(this.employeeId, this.employmentRecord.id)
    .pipe(
      finalize(() => {
          this.isLoadingEmploymentRecord = false;
      })
    )
    .subscribe(
      res => {
        this.employmentRecordVerbose = res;
        this.createFormData();
      }
    );
  }

  createFormData(){
    this.formData = {
      id: this.employmentRecordVerbose ? this.employmentRecordVerbose.id : null,
      employeeId: this.employmentRecordVerbose ? this.employmentRecordVerbose.employeeId : null,
      hireDate: this.employmentRecordVerbose ? this.employmentRecordVerbose.hireDate : null,
      budgetAuthority: this.employmentRecordVerbose ? this.employmentRecordVerbose.budgetAuthority : null,
      inductionCompletedOn: this.employmentRecordVerbose ? this.employmentRecordVerbose.inductionCompletedOn : null,
      medicalCompletedDate: this.employmentRecordVerbose ? this.employmentRecordVerbose.medicalCompletedDate : null,
      codeOfConductSignedOn: this.employmentRecordVerbose ? this.employmentRecordVerbose.codeOfConductSignedOn : null,
      probationPeriodLength: this.employmentRecordVerbose ? this.employmentRecordVerbose.probationPeriodLength : null,
      probationPeriodUnit: this.employmentRecordVerbose ? this.employmentRecordVerbose.probationPeriodUnit?.id : null,
      probationPeriodEnd: this.employmentRecordVerbose ? this.employmentRecordVerbose.probationPeriodEnd : null,
      noticePeriodLength: this.employmentRecordVerbose ? this.employmentRecordVerbose.noticePeriodLength : null,
      replacementFor: this.employmentRecordVerbose ? this.employmentRecordVerbose.replacementFor : null,
      searchFee: this.employmentRecordVerbose ? this.employmentRecordVerbose.searchFee : null,
      rehireEligibilityComments: this.employmentRecordVerbose ? this.employmentRecordVerbose.rehireEligibilityComments : null,
      hireDetailComments: this.employmentRecordVerbose ? this.employmentRecordVerbose.hireDetailComments : null,
      terminationVoluntary: this.employmentRecordVerbose ? this.employmentRecordVerbose.terminationVoluntary : null,
      terminationRecordOfEmploymentComment: this.employmentRecordVerbose ? this.employmentRecordVerbose.terminationRecordOfEmploymentComment : null,
      terminationSeveranceAmount: this.employmentRecordVerbose ? this.employmentRecordVerbose.terminationSeveranceAmount : null,
      terminationPayInLieu: this.employmentRecordVerbose ? this.employmentRecordVerbose.terminationPayInLieu : null,
      terminationVacationPayout: this.employmentRecordVerbose ? this.employmentRecordVerbose.terminationVacationPayout : null,
      terminationOtherPayment: this.employmentRecordVerbose ? this.employmentRecordVerbose.terminationOtherPayment : null,
      terminationLastDateOnSite: this.employmentRecordVerbose ? this.employmentRecordVerbose.terminationLastDateOnSite : null,
      terminationDateNotified: this.employmentRecordVerbose ? this.employmentRecordVerbose.terminationDateNotified : null,
      terminationDateProjected: this.employmentRecordVerbose ? this.employmentRecordVerbose.terminationDateProjected : null,
      terminationDateActual: this.employmentRecordVerbose ? this.employmentRecordVerbose.terminationDateActual : null,
      terminationBenefitsEndOfNotice: this.employmentRecordVerbose ? this.employmentRecordVerbose.terminationBenefitsEndOfNotice : null,
      terminationBenefitsEnd: this.employmentRecordVerbose ? this.employmentRecordVerbose.terminationBenefitsEnd : null,
      terminationItConfirmation: this.employmentRecordVerbose ? this.employmentRecordVerbose.terminationItConfirmation : null,
      terminationAcceptedByDate: this.employmentRecordVerbose ? this.employmentRecordVerbose.terminationAcceptedByDate : null,
      terminationAcceptedByName: this.employmentRecordVerbose ? this.employmentRecordVerbose.terminationAcceptedByName : null,
      terminationComments: this.employmentRecordVerbose ? this.employmentRecordVerbose.terminationComments : null,
      payrollNumber: this.employmentRecordVerbose ? this.employmentRecordVerbose.payrollNumber : null,
      codeOfConductSigned: this.employmentRecordVerbose && this.employmentRecordVerbose.codeOfConductSigned ? this.employmentRecordVerbose.codeOfConductSigned.id : null,
      medicalCompleted: this.employmentRecordVerbose && this.employmentRecordVerbose.medicalCompleted ? this.employmentRecordVerbose.medicalCompleted.id : null,
      securityClearance: this.employmentRecordVerbose && this.employmentRecordVerbose.securityClearance ? this.employmentRecordVerbose.securityClearance.id : null,
      noticePeriodUnits: this.employmentRecordVerbose && this.employmentRecordVerbose.noticePeriodUnits ? this.employmentRecordVerbose.noticePeriodUnits.id : null,
      reHireEligibility: this.employmentRecordVerbose && this.employmentRecordVerbose.reHireEligibility ? this.employmentRecordVerbose.reHireEligibility.id : null,
      hireCategory: this.employmentRecordVerbose && this.employmentRecordVerbose.hireCategory ? this.employmentRecordVerbose.hireCategory.id : null,
      recruitmentAgency: this.employmentRecordVerbose && this.employmentRecordVerbose.recruitmentAgency ? this.employmentRecordVerbose.recruitmentAgency.id : null,
      terminationReason: this.employmentRecordVerbose && this.employmentRecordVerbose.terminationReason ? this.employmentRecordVerbose.terminationReason.id : null,
      terminationRecordOfEmploymentReason: this.employmentRecordVerbose && this.employmentRecordVerbose.terminationRecordOfEmploymentReason ? this.employmentRecordVerbose.terminationRecordOfEmploymentReason.id : null,
      terminationSpecialCircumstances: this.employmentRecordVerbose && this.employmentRecordVerbose.terminationSpecialCircumstances ? this.employmentRecordVerbose.terminationSpecialCircumstances.id : null,
      terminationRehireRecommend: this.employmentRecordVerbose && this.employmentRecordVerbose.terminationRehireRecommend ? this.employmentRecordVerbose.terminationRehireRecommend.id : null,
      terminationRehireReason: this.employmentRecordVerbose && this.employmentRecordVerbose.terminationRehireReason ? this.employmentRecordVerbose.terminationRehireReason.id : null,
      payrollCode: this.employmentRecordVerbose && this.employmentRecordVerbose.payrollCode ? this.employmentRecordVerbose.payrollCode.id : null,
      apprenticeshipProgramEndDate: this.employmentRecordVerbose ? this.employmentRecordVerbose.apprenticeshipProgramEndDate : null,
      apprenticeshipProgramStartDate: this.employmentRecordVerbose ? this.employmentRecordVerbose.apprenticeshipProgramStartDate : null,
      graduateProgramEndDate: this.employmentRecordVerbose ? this.employmentRecordVerbose.graduateProgramEndDate : null,
      graduateProgramStartDate: this.employmentRecordVerbose ? this.employmentRecordVerbose.graduateProgramStartDate : null,
      graduateProgramMember: this.employmentRecordVerbose && this.employmentRecordVerbose.graduateProgramMember ? this.employmentRecordVerbose.graduateProgramMember.id : null,
      email: this.employmentRecordVerbose ? this.employmentRecordVerbose.email : null,
      phoneNumber: this.employmentRecordVerbose ? this.employmentRecordVerbose.phoneNumber : null,
      cellNumber: this.employmentRecordVerbose ? this.employmentRecordVerbose.cellNumber : null,
      office: this.employmentRecordVerbose ? this.employmentRecordVerbose.office : null,
      subStatus: this.employmentRecordVerbose ? this.employmentRecordVerbose.subStatus?.id : null,
      asOf: moment().format(),

      supervisorLegacyId: this.employmentRecordVerbose ? this.employmentRecordVerbose.supervisorLegacyId : null,
      startReason: this.employmentRecordVerbose ? this.employmentRecordVerbose.startReason?.id : null,
      office2: this.employmentRecordVerbose ? this.employmentRecordVerbose.office2 : null,
      reHireReason: this.employmentRecordVerbose ? this.employmentRecordVerbose.reHireReason?.id : null,
      hireDetailsUdf1: this.employmentRecordVerbose ? this.employmentRecordVerbose.hireDetailsUdf1?.id : null,
      hireDetailsUdf2: this.employmentRecordVerbose ? this.employmentRecordVerbose.hireDetailsUdf2?.id : null,
      hireDetailsUdf3: this.employmentRecordVerbose ? this.employmentRecordVerbose.hireDetailsUdf3?.id : null,
      hireDetailsUdd1: this.employmentRecordVerbose ? this.employmentRecordVerbose.hireDetailsUdd1 : null,
      hireDetailsUdd2: this.employmentRecordVerbose ? this.employmentRecordVerbose.hireDetailsUdd2 : null,




    };
  }

  openConfirmDeleteDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
        text: this.translate.instant('ConfirmDeleteEmploymentRecord')
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.deleteEmploymentRecord();
        }
      }
    );
  }

  deleteEmploymentRecord() {
    this.overlayService.show();

    this.employmentRecordsService.deleteEmploymentRecord(this.employeeId, this.employmentRecord.id)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
        this.getEmploymentRecords.emit();
      }
    );
  }

  formDataEmitted(formData) {
    this.openChangeReasonDialog(formData);
  }

  formStatusUpdated(formValid) {
      this.formValid = formValid;
  }

  formPristineEmitted(formPristine: boolean) {
    this.formPristine = formPristine;
    this.formGeneratorComponentEmit.emit(this.formGeneratorComponent);
  }

  openChangeReasonDialog(formData: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formId: this.changeReasonFormId,
    };

    const dialogRef = this.dialog.open(ChangeReasonDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.addChangeReasonsToFormDataAndEmit(formData, data);
        }
      }
    );
  }

  addChangeReasonsToFormDataAndEmit(formData: any, changeReasonFormData: any) {
      let merged = {...formData, ...changeReasonFormData};
      this.save(merged);
  }

  save(formData: any) {
      this.overlayService.show();

      formData.originalCreatedOn = this.employmentRecordVerbose?.version?.createdOn ? this.employmentRecordVerbose?.version?.createdOn : null;

      this.employmentRecordsService.updateEmploymentRecord(this.employeeId, formData)
      .pipe(
          finalize(() => {
              this.overlayService.hide();
          })
      )
      .subscribe(
          (res) => {
            this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
            this.getEmploymentRecords.emit();
          }
      );
  }

  openAccessLogsDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
        employeeId: this.employeeId,
        entityId: this.employmentRecord.id,
        url: api_routes.TALENT + '/' + this.employeeId + '/' + api_routes.EMPLOYMENT_RECORDS + '/' + this.employmentRecord.id ,
    };

    const dialogRef = this.dialog.open(AccessLogsDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
        data => {
        }
    );
  }

  openHistoryDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
        employeeId: this.employeeId,
        entityId: this.employmentRecord.id,
        url: api_routes.TALENT + '/' + this.employeeId + '/' + api_routes.EMPLOYMENT_RECORDS + '/' + this.employmentRecord.id,
        columns: this.columns,
        tableId: db_tables.EmploymentRecords
    };

    const dialogRef = this.dialog.open(HistoryDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
        data => {
        }
    );
  }

}
