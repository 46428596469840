<div class="main-container">

    <div class="top k-p-8">
        <div>
        <h5>Currency: {{_currency.id}}</h5>
        <p>Status: {{_currency.isEnabled ? "Enabled" : "Disabled"}}</p>
        </div>
        <div class="buttons-container">
            <button mat-flat-button setColor="primaryColour" color="primary" (click)="toggleCurrencyState()">{{ _currency.isEnabled ? "Disable Currency" : "Enable Currency" }}</button>
        </div>
    </div>
    <kendo-grid 
        [data]="exchangeRates"
        [style.maxHeight.vh]="85" >
        <kendo-grid-column field="rate" title="Rate">
        </kendo-grid-column>
        <kendo-grid-column field="asOf" title="As Of">
            <ng-template kendoGridCellTemplate let-dataItem>
            <span >{{dataItem.asOf | date:'mediumDate'}}</span>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="from.id" title="From">
        </kendo-grid-column>

        <kendo-grid-column field="to.id" title="To">
        </kendo-grid-column>

    </kendo-grid>
</div>
