import { Component, OnInit } from '@angular/core';
import { ExcelExportEvent, GridDataResult, MultipleSortSettings, PageChangeEvent, PageSizeItem } from '@progress/kendo-angular-grid';
import { SortDescriptor, State } from '@progress/kendo-data-query';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { EmployeeBankDetailsReportService } from '../../services/employee-bank-details-report.service';
import { routes } from '@app/consts';
import { Position } from '@app/modules/positions/models/positions.model';
import { SanitiseGridDataService } from '@app/core/services/sanitise-grid-data/sanitise-grid-data.service';

@Component({
  selector: 'app-bank-details-data-grid',
  templateUrl: './bank-details-data-grid.component.html',
  styleUrls: ['./bank-details-data-grid.component.scss']
})
export class BankDetailsDataGridComponent implements OnInit {
  public routes: typeof routes = routes;

  public employmentRecordColumns: any[] = [
    { field: "employmentRecord.payrollNumber", title: "Payroll Number" },
    { field: "employmentRecord.hireDate", title: "Hire Date" },
  ];

  public employeeColumns: any[] = [
    { field: "employee.firstName", title: "First Name" },
    { field: "employee.lastName", title: "Last Name" },
    { field: "employee.socialSecurityNumber", title: "Social Security Number" },
    { field: "employee.streetAddress", title: "Street Address" },
    { field: "employee.city", title: "City" },
    { field: "employee.province", title: "Province" },
    { field: "employee.zip", title: "ZIP" },
    { field: "employee.country.name", title: "Country" },
    { field: "employee.birthday", title: "birthday" },
  ];

  public bankDetailColumns: any[] = [
    { field: "bankDetails.bankAccountName", title: "Bank Account Name" },
    { field: "bankDetails.bankSortCode", title: "Bank Sort Code" },
    { field: "bankDetails.bankAccountNumber", title: "Bank Account Number" },
    { field: "bankDetails.buildingSocietyRollNo", title: "Building Society Roll No" },
    { field: "bankDetails.bankName", title: "Bank Name" },
    { field: "bankDetails.iban", title: "IBAN" },
    { field: "bankDetails.swift", title: "Swift" },
    { field: "bankDetails.big", title: "BIG" },
    { field: "positions", title: "Positions" },
    { field: "workLocations", title: "Work Locations" },
  ];

  public sortableColumns: any[] = [
    { field: "employee.firstName", title: "First Name" },
    { field: "employee.lastName", title: "Last Name" },
    { field: "employee.socialSecurityNumber", title: "Social Security Number" },
    { field: "employee.streetAddress", title: "Street Address" },
    { field: "employee.city", title: "City" },
    { field: "employee.province", title: "Province" },
    { field: "employee.zip", title: "ZIP" },
    { field: "employee.country.name", title: "Country" },
    { field: "employee.birthday", title: "birthday" },
    { field: "employmentRecord.payrollNumber", title: "Payroll Number" },
    { field: "employmentRecord.hireDate", title: "Hire Date" },
    { field: "bankDetails.bankAccountName", title: "Bank Account Name" },
    { field: "bankDetails.bankSortCode", title: "Bank Sort Code" },
    { field: "bankDetails.bankAccountNumber", title: "Bank Account Number" },
    { field: "bankDetails.buildingSocietyRollNo", title: "Building Society Roll No" },
    { field: "bankDetails.bankName", title: "Bank Name" },
    { field: "bankDetails.iban", title: "IBAN" },
    { field: "bankDetails.swift", title: "Swift" },
    { field: "bankDetails.big", title: "BIG" },
  ]

  public sort: SortDescriptor[] = [];
  public bindingType: String = "array";
  public view: Observable<GridDataResult>;
  public gridDataResult: GridDataResult;
  public selectedPositions: any[] = [];
  public pageSizes: (PageSizeItem | number)[] = [
    5,
    10,
    20,
    {
      text: "All",
      value: 1000,
    },
  ];
  isLoading: boolean;
  pageSize: number = 20;
  skip: number = 0;
  sortString: string;
  getBankDetailsRequest: any;
  positions: Position[];

  public sortSettings: MultipleSortSettings = {
    mode: "multiple",
    initialDirection: "desc",
    allowUnsort: true,
    showIndexes: true,
  };

  public filterCategories: any[] = [
    {field: 'employee.firstName', title: 'First Name', dataType: "String"},
    {field: 'employee.lastName', title: 'Last Name', dataType: "String"},
    { field: "employee.socialSecurityNumber", title: "Social Security Number", dataType: "String" },
    { field: "employee.streetAddress", title: "Street Address", dataType: "String" },
    { field: "employee.city", title: "City", dataType: "String" },
    { field: "employee.province", title: "Province", dataType: "String" },
    { field: "employee.zip", title: "ZIP", dataType: "String" },
    { field: "employee.country.name", title: "Country", dataType: "String" },
    { field: "employee.birthday", title: "Birthday", dataType: "Date" },
    { field: "employmentRecord.payrollNumber", title: "Payroll Number", dataType: "String" },
    { field: "employmentRecord.hireDate", title: "Hire Date", dataType: "Date" },
    // {field: 'bankAccountName', title: 'Bank Account Name', dataType: "String"},
    {field: 'BankDetails.BankSortCode', title: 'Bank Sort Code', dataType: "String"},
    {field: 'BankDetails.BankAccountNumber', title: 'Bank Account Number', dataType: "Number"},
    {field: 'BankDetails.BuildingSocietyRollNo', title: 'Building Society Roll No', dataType: "String"},
    {field: 'BankDetails.BankName', title: 'Bank Name', dataType: "String"},
    {field: 'BankDetails.BIG', title: 'BIG', dataType: "String"},
    {field: 'BankDetails.IBAN', title: 'IBAN', dataType: "String"},
    {field: 'BankDetails.SWIFT', title: 'SWIFT', dataType: "String"},
  ]
  filterString: string;
  searchFilterString: string;
  searchValue: any;
  loadingPositions: boolean = false;

  public state: State = {
    skip: 0,
    take: 50,
  };
  public query: Observable<GridDataResult>;
  private stateChange = new BehaviorSubject<State>(this.state);
  public allData = (): Observable<GridDataResult> => {
    return from(this.employeeBankDetailsReportService.queryAll(this.state));
  };

  constructor(
    private employeeBankDetailsReportService: EmployeeBankDetailsReportService,
    private sanitiseGridDataService: SanitiseGridDataService
  ) {
    this.query = this.stateChange.pipe(
      tap((state) => {
        this.state = state;
        this.isLoading = true;
      }),
      switchMap((state) => employeeBankDetailsReportService.fetch(state, this.filterString, this.sortString, false, true)),
      tap(() => {
        this.isLoading = false;
      })
    );

    // Bind 'this' explicitly to capture the execution context of the component.
    this.allData = this.allData.bind(this);
  }

  ngOnInit(): void {}

  public pageChange(state: PageChangeEvent): void {
    this.stateChange.next(state);
  }

  public sortChange(sort: SortDescriptor[]): void {
    let sortString: string = '';

    sort.forEach(
      (sortObj, index) => {

        if(sortObj.dir === undefined){
          sort.splice(index, 1);
        }
        else {
          let sortableColumn = this.sortableColumns.find(col => col.field === sortObj.field)

          if(sortableColumn){
            sortString = this.createSortString(sortableColumn, sortObj, sortString)
          }
          else {
            sort.splice(index, 1);
          }
        }
      }
    )

    this.sort = sort;
    this.sortString = sortString;
    this.state.skip = 0;
    this.state.take = 50;
    this.stateChange.next(this.state);
  }

  createSortString(sortableColumn, sort: SortDescriptor, sortString: string): string {
    if (Array.isArray(sortableColumn?.sortValue)) {
      sortableColumn?.field.forEach(
        sortVal => {
          if(sortString !== ''){
            sortString = `${sortString}~${sortVal}-${sort.dir}`;
          }
          else{
            sortString = `${sortVal}-${sort.dir}`;
          }
        }
      )
    } else {
      if(sortString !== ''){
        sortString = `${sortString}~${sortableColumn?.field}-${sort.dir}`;
      }
      else{
        sortString = `${sortableColumn?.field}-${sort.dir}`;
      }
    }

    return sortString;
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.state.skip = 0;
    this.state.take = 50;
    this.stateChange.next(this.state);
  }

  public onExcelExport(e: ExcelExportEvent): void {
    e = this.sanitiseGridDataService.sanitise(e);
  }

}
