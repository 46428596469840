<h5 mat-dialog-title>
    {{ 'MyCompensationHistory' | translate: {Default: "My Compensation History"} }}
</h5>

<mat-dialog-content>
    <kendo-grid
        [data]="gridDataResult" 
        [height]="410" 
        [loading]="isLoading">


        <kendo-grid-column
                title="Created On"
                [width]="210">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="grid-cell-vertical">
                    <span>
                        {{dataItem.version.createdOn | date:'medium' }}
                    </span>
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
                title="Effective Date"
                [width]="200">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="grid-cell-vertical">
                    <span>
                        {{dataItem.version.effectiveDate  | date:'mediumDate' }}
                    </span>
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
                title="Created By"
                [width]="200">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="grid-cell-vertical">
                    <span>
                        {{dataItem.version.createdBy.firstName }} {{dataItem.version.createdBy.lastName }}
                    </span>
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
                title="Change Reason"
                [width]="200">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="grid-cell-vertical">
                    <span>
                        {{dataItem.version?.changeReason?.text }}
                    </span>
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
                title="Change Comments"
                [width]="200">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="grid-cell-vertical">
                    <span>
                        {{dataItem.version?.comments }}
                    </span>
                </div>
            </ng-template>
        </kendo-grid-column>


        <ng-container
                *ngFor="let col of columns"
        >
            <!-- Normal Column Data -->
            <kendo-grid-column
                    *ngIf="!col.type || col.type === 'navigation'"
                    [field]="col.field"
                    [title]="col.title"
                    [width]="200">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        <ng-container *ngIf="col.subField && dataItem[col.field]; else noSubField">
                            <span [className]="wasModified(col.field, dataItem) ? 'highlight' : ''">{{dataItem[col.field][col.subField]}}</span>
                        </ng-container>
                        <ng-template #noSubField>
                            <span [className]="wasModified(col.field, dataItem) ? 'highlight' : ''">{{dataItem[col.field]}}</span>
                        </ng-template>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <!-- Date Column Data -->
            <kendo-grid-column
                    *ngIf="col.type === 'date'"
                    [field]="col.field"
                    [title]="col.title"
                    [width]="200">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        <span [className]="wasModified(col.field, dataItem) ? 'highlight' : ''">
                            {{dataItem[col.field] | date:'mediumDate' }}
                        </span>
                    </div>
                </ng-template>
            </kendo-grid-column>
        </ng-container>
    </kendo-grid>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button class="close" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
</mat-dialog-actions>