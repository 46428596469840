import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EmployeeService } from '@app/core/services/employee.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { finalize } from 'rxjs/operators';
import { routes } from '@app/consts';

@Component({
  selector: 'app-new-employee-form-dialog',
  templateUrl: './new-employee-form-dialog.component.html',
  styleUrls: ['./new-employee-form-dialog.component.scss']
})
export class NewEmployeeFormDialogComponent implements OnInit {
  formId: string = 'frm_C2EziiNXjk0igH';
  getFormData: boolean = false;
  formValid: boolean = false;
  formData: any;

  constructor(
    private dialogRef: MatDialogRef<NewEmployeeFormDialogComponent>,
    private employeeService: EmployeeService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    public router: Router,
  ) { }

  ngOnInit(): void {
  }

  formDataEmitted(formData) {
    this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  save(formData) {
    this.overlayService.show();

    this.formData = formData
    
    formData.organization = formData.organization.id;

    this.employeeService.createTalent(formData)
    .pipe(
      finalize(()=>{ 
        this.overlayService.hide();
      })
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar('Talent created successfully', 'clear', 'success');
        this.close(res);
      }
    );
    
  }

  close(payload?:any){
    this.dialogRef.close({result: payload, formData : this.formData});
  }

}
