<mat-expansion-panel class="expansion-panel mat-elevation-z0">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <mat-icon class="material-icons-outlined">settings</mat-icon>

            <h5 appLocalizationPopupDirective localizationCode="GoalSettings-EditAllSelected">{{ 'GoalSettings-EditAllSelected' | translate: {Default: "Set goal settings for all selected goals"} }}</h5>
        </mat-panel-title>
    </mat-expansion-panel-header>
    
    <ng-template matExpansionPanelContent>
        <div class="container">
            <div class="page-description-section" appLocalizationPopupDirective localizationCode="GoalSettings-EditAllSelectedDescription">
                {{ 'GoalSettings-EditAllSelectedDescription' | translate: {Default: "This will override the goal settings for all the selected goals"} }}
            </div>

            <div class="goal-settings">
                <div class="slider-container">
                    <app-form-generator 
                        formId="frm_sv2hp920QwMeeU"
                        [formData]=""
                        [emitFormDataTrigger]="getFormData"
                        (emitFormDataChange)="formDataChange($event)"
                        (emitFormData)="formDataEmitted($event)"
                        (emitFormStatus)="formStatusUpdated($event)"
                        (emitFormPristine)="formPristineEmitted($event)"
                    ></app-form-generator>
                </div>

                <div class="reviewers-grid" *ngIf="currentGoalSettingsFormData.isGloballyRated === true">
                    <app-data-grid-comp
                        [columns]="columns"
                        [gridDataResult]="reviewersGridDataResult"
                        [pageSize]="pageSize"
                        [skip]="skip"
                        category="Reviewers"
                        [rowSelectionSettings]="{
                            canSelectRows: true,
                            selectableMode: 'multiple',
                            selectRowBy: 'reviewer'
                        }"
                        actionButtonText="Add"
                        [isLoading]="isLoadingEmployees"
                        [clearSelectedItems]="clearSelectedItems"
                        searchable="true"
                        (emitSearchEvent)="search($event)"
                        (emitPageChangeEvent)="pageChange($event)"
                        (emitOpenDialogEvent)="showAddUserModal()"
                        (emitDeleteEvent)="deleteAllSelected($event)"
                    ></app-data-grid-comp>
                </div>

                <button mat-stroked-button setColor="primaryColour" color="primary"  appLocalizationPopupDirective [localizationCode]="'Save'" appPreventDoubleClick (throttledClick)="openConfirmDialog()">
                    {{ 'Save' | translate: {Default: "Save"} }}
                </button>
            </div>
        </div>

    </ng-template>
</mat-expansion-panel>