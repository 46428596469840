<app-site-settings-styling>
    <mat-card class="mat-elevation-z0">
      <mat-card-title class="header">
          <h5 appLocalizationPopupDirective localizationCode="Styling">{{ 'Styling' | translate: {Default: "Styling"} }}</h5>
          <div class="k-flex-grow"></div>
          <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
          <button mat-raised-button setColor="primaryColour" color="primary" [disabled]="formValid === false" appPreventDoubleClick (throttledClick)="getFormData = !getFormData">{{ 'Save' | translate: {Default: "Save"} }}</button> 
      </mat-card-title>
  
      <mat-card-content>

        <div>
          <ng-container *ngIf="isLoading; else loaded">
            <ngx-skeleton-loader
              count="5"
              [theme]="{ 
                  'height.px': 50
              }"
            ></ngx-skeleton-loader>
          </ng-container>
          <ng-template #loaded>
            <app-form-generator 
              [formId]="formId"
              [formData]="formData"
              [emitFormDataTrigger]="getFormData"
              (emitFormData)="formDataEmitted($event)"
              (emitFormStatus)="formStatusUpdated($event)"
            ></app-form-generator>
          </ng-template>
        </div>

        <app-data-grid-comp
          *ngIf="stylingColourId"
          category="WorkLocationsUsingThisStyle"
          class="work-locations"
          [columns]="columns"
          hideActionButtons="true"
          [gridDataResult]="gridDataResult"
          [isLoading]="isLoadingWorkLocations"
          [pageSize]="pageSize"
          [skip]="skip"
          searchable="true"
          [filterCategories]="columns"
          [filterToggleDetails]="filterToggleDetails"
          [sortable]="{
            mode: 'multiple'
          }"
          [sortableColumns]="sortableColumns"
          (emitToggleFilter)="updateFilterToggleDetails($event)"
          (emitPageChangeEvent)="pageChange($event)"
          (emitSearchEvent)="search($event)"
          (emitFilterEvent)="filterCallback($event)"
          (emitSortEvent)="sortChange($event)"
        ></app-data-grid-comp>

      </mat-card-content>
    </mat-card>
  
  </app-site-settings-styling>
  
  