<mat-card>
    <mat-card-title>
        <h5 appLocalizationPopupDirective localizationCode="RequestTimeOff">{{ 'RequestTimeOff' | translate: {Default: "Request Time Off"} }}</h5>
    </mat-card-title>
  
    <mat-card-content>
        <div class="time-off-types-container" *ngIf="timeOffLeaveTypes$ | withLoading | async as timeOffLeaveTypes">
            <ng-template [ngIf]="timeOffLeaveTypes.value">

                <ng-container *ngFor="let timeOffLeaveType of timeOffLeaveTypes.value">
                    <div class="time-off-type-container" *ngIf="timeOffLeaveType.timeOffPolicy !== null">
                        <div class="top">
                    
                            <div class="title">
                                <h5>{{timeOffLeaveType.timeOffType?.name}}</h5>
                            </div>
                        </div>
                        <div class="balance-container">
                            <div class="label">
                                <h6>
                                    {{ 'timeOffLeaveTypeBalance' | translate: {Default: "Balance"} }}
                                </h6>
                            </div>
                            <div class="balance">
                                <ng-container *ngIf="timeOffLeaveType.availableHours; else noAvailableHours">
                                    <h3>{{timeOffLeaveType.availableHours | number:'1.0-1' }}</h3>
                                </ng-container>
                                <ng-template #noAvailableHours>
                                    <h3>0</h3>
                                </ng-template>
                            </div>
                            <div class="hours">
                                <h6>Hours</h6>
                            </div>
                        </div>
                    
                        <div class="buttons-container">
                            <button mat-stroked-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openLeaveRequestDialog(timeOffLeaveType.timeOffType.id)">
                                {{ 'timeOffLeaveTypeRequestTimeOff' | translate: {Default: "Request Time Off"} }}
                            </button>
                        </div>
                    </div>
                </ng-container>
            </ng-template>
            <ng-template [ngIf]="timeOffLeaveTypes.error">Error {{ obs.error }}</ng-template>
            <ng-template [ngIf]="timeOffLeaveTypes.loading">
                <ngx-skeleton-loader
                    count="5"
                    [theme]="{ 
                        'height.px': 50
                    }"
                ></ngx-skeleton-loader>
            </ng-template>
        </div>
    </mat-card-content>
</mat-card>