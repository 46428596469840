<div class="content">
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
            <h4 appLocalizationPopupDirective localizationCode="EmployeeSecurity-LoginHistory">{{ 'EmployeeSecurity-LoginHistory' | translate: {Default: "Employee Login History"} }}</h4>
        </mat-card-title>
    
        <mat-card-content>
            <kendo-grid 
                [data]="gridDataResult" 
                [pageSize]="pageSize"
                [skip]="skip"
                [style.maxHeight.vh]="85" 
                [resizable]="true"
                [pageable]="{
                    info: true,
                    pageSizes: [5, 10, 20, 50, 100],
                    previousNext: true
                }"
                (pageChange)="pageChange($event)"
                [loading]="isLoading">

                <kendo-grid-column field="logTime" [width]="150">
                    <ng-template kendoGridCellTemplate let-dataItem >
                        <ng-template kendoGridHeaderTemplate>
                            <div appLocalizationPopupDirective localizationCode="LoginHistory-LogTime">{{ 'LoginHistory-LogTime' | translate: {Default: "Log Time"} }}</div>
                        </ng-template>

                        <div class="grid-cell-vertical">
                            {{dataItem.logTime  | date:'medium'}}
                        </div>
                    </ng-template>
                </kendo-grid-column>
                
                <kendo-grid-column
                    *ngFor="let col of columns"
                    [field]="col.field"
                    [width]="100"
                >
                    <ng-template kendoGridHeaderTemplate>
                        <div appLocalizationPopupDirective [localizationCode]="col.title">{{ translate.instant(col.title) }}</div>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-messages 
                    [noRecords]="isLoading ? '' : translate.instant('Grid-NoRecordsAvailable')"
                    [pagerItemsPerPage]="translate.instant('Grid-Pager-ItemsPerPage')" 
                    [pagerItems]="translate.instant('Grid-Pager-Items')"
                    [pagerOf]="translate.instant('Grid-Pager-Of')"
                ></kendo-grid-messages>
            </kendo-grid>
        </mat-card-content>
    </mat-card>
</div>