import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { WorkflowsAbsencesService } from '@app/core/services/workflows-absences/workflows-absences.service';
import { GenerateScheduleService } from '@app/modules/generate-schedules/services/generate-schedule.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { MyRequest } from '@app/shared/models/workflows-absences/workflows-absences.model';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-request-time-off-dialog',
    templateUrl: './request-time-off-dialog.component.html',
    styleUrls: ['./request-time-off-dialog.component.scss']
})
export class RequestTimeOffDialogComponent implements OnInit {
    form: UntypedFormGroup;
    timeOffType: string;
    employeeId: string;
    workingHours: number;
    loadingWorkingHours: boolean;
    timeOffRequestId: string;
    loading: boolean;
    timeOffRequest: MyRequest;

    constructor(
        private fb: UntypedFormBuilder,
        public translate: TranslateService,
        private workflowsAbsencesService: WorkflowsAbsencesService,
        private snackbarService: SnackbarService,
        private overlayService: OverlayService,
        private generateScheduleService: GenerateScheduleService,
        private dialogRef: MatDialogRef<RequestTimeOffDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.timeOffType = data.timeOffType;
        this.employeeId = data.employeeId;
        this.timeOffRequestId = data.timeOffRequestId;
    }

    ngOnInit(): void {
        if (this.timeOffRequestId) {
            this.getTimeOffRequest();
        } else {
            this.buildForm();
        }

    }

    getTimeOffRequest() {
        this.loading = true;

        this.workflowsAbsencesService.getRequest(this.timeOffRequestId)
        .pipe(
            finalize(() => {
                this.loading = false;
            })
        )
        .subscribe(
            (res) => {
                this.timeOffRequest = res;
                this.buildForm();
            }
        );
    }

    buildForm() {

        this.form = this.fb.group({
            from: [this.timeOffRequest ? this.timeOffRequest.from : '', [Validators.required]],
            to: [this.timeOffRequest ? this.timeOffRequest.to : '', [Validators.required]],
            comments: [this.timeOffRequest ? this.timeOffRequest.comments : ''],
            halfDay: [this.timeOffRequest ? this.timeOffRequest.halfDay : false, [Validators.required]],
            setTime: [this.timeOffRequest ? this.timeOffRequest.startTime || this.timeOffRequest.endTime || this.timeOffRequest.hours : false],
            fromTime: [this.timeOffRequest ? this.formatTime(this.timeOffRequest.startTime) : ''],
            toTime: [this.timeOffRequest ? this.formatTime(this.timeOffRequest.endTime) : ''],
            hours: [this.timeOffRequest ? this.timeOffRequest.hours : ''],
            timeOffType: [this.timeOffRequest ? this.timeOffRequest.timeOffType.id : this.timeOffType, [Validators.required]]
        });

        this.form.get('setTime').valueChanges.subscribe(value => {
            if (value) {
                this.form.get('fromTime').setValidators([Validators.required]);
                this.form.get('toTime').setValidators([Validators.required]);
                this.form.get('hours').setValidators([Validators.required]);

                const halfDayControl = this.form.get('halfDay');
                if (halfDayControl.value) {
                    halfDayControl.setValue(false);
                }
            } else {
                this.form.get('fromTime').setValidators(null);
                this.form.get('toTime').setValidators(null);
                this.form.get('hours').setValidators(null);
            }

            this.form.get('fromTime').updateValueAndValidity();
            this.form.get('toTime').updateValueAndValidity();
            this.form.get('hours').updateValueAndValidity();
        });

        this.form.get('halfDay').valueChanges.subscribe(value => {
            if (value) {
                const setTimeControl = this.form.get('setTime');
                if (setTimeControl.value) {
                    setTimeControl.setValue(false);
                }
            }
        });

        this.formControlValueChanged('from');
        this.formControlValueChanged('to');
        this.getEmployeeWorkingHours();

        this.form.controls.to.valueChanges.subscribe(to => {
            const from = this.form.controls.from.value;
            // if (from && to && from.toISOString().slice(0,10) === to.toISOString().slice(0,10)) {
            if (from && to && this.fromValue === this.toValue) {
                this.showTimeSelection();
            } else {
                this.hideTimeSelection();
            }
        })
    }

    private showTimeSelection() {

    }

    private hideTimeSelection() {

    }

    formControlValueChanged(formControl: string) {
        this.form.get(formControl).valueChanges
        .subscribe(
            (mode: string) => {
                this.getEmployeeWorkingHours();
                this.form.get('halfDay').setValue(false);
            }
        );
    }

    get fromValue() {
        const date = this.formatDate(this.form.get('from').value);
        // console.log("fromValue", date);
        if (date === 'Invalid date') {
            return null;
        }
        return date;
    }

    get toValue() {
        // return this.formatDate(this.form.get('to').value);
        const date = this.formatDate(this.form.get('to').value);
        // console.log("toValue", date, this.form.get('setTime').value);
        if (date === 'Invalid date') {
            return null;
        }
        return date;
    }

    get calculatedWorkingHours() {
        if (this.form.get('halfDay').value === true) {
            return this.workingHours / 2;
        } else {
            return this.workingHours;
        }
    }

    formatDate(date: any): string {
        if (typeof date === 'string') {
            return moment(date).format('YYYY-MM-DD')
        } else {
            if (date !== null) {
                return date.format('YYYY-MM-DD')
            }

            return null;
        }
    }

    getEmployeeWorkingHours() {
        if (this.isValidDates()) {
            this.loadingWorkingHours = true;

            this.generateScheduleService.getEmployeeWorkingHours(this.employeeId, this.fromValue, this.toValue)
            .pipe(
                finalize(() => this.loadingWorkingHours = false)
            )
            .subscribe(
                (res) => {
                    this.workingHours = res.workingHours;
                }
            );
        }
    }

    isValidDates(): boolean {
        if (this.fromValue != null && this.toValue !== '' && this.toValue !== 'Invalid date') {
            const fromValue = this.form.get('from').value;
            const toValue = this.form.get('to').value;

            if (moment(fromValue).isSameOrBefore(moment(toValue))) {

                // if (this.form.get('from').value.isSameOrBefore(this.form.get('to').value)) {
                return true;
            }
        }

        return false;
    }

    submitForm() {
        if (this.timeOffRequestId) {
            this.editTimeOffRequest();
        } else {
            this.requestTimeOff();
        }
    }

    requestTimeOff() {
        this.overlayService.show();

        let data = {
            from: this.form.get('from').value,
            to: this.form.get('to').value,
            comments: this.form.get('comments').value,
            halfDay: this.form.get('halfDay').value,
            timeOffType: this.form.get('timeOffType').value,
            startTime:  this.form.get('fromTime').value,
            endTime: this.form.get('toTime').value,
            hours: this.form.get('hours').value
        }

        console.log("data", data);

        this.workflowsAbsencesService.submitRequest(data)
        .pipe(
            finalize(() => this.overlayService.hide())
        )
        .subscribe(
            (res) => {
                this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
                this.dialogRef.close(true);
            }
        );
    }

    editTimeOffRequest() {
        this.overlayService.show();

        // let data = {
        //     from: this.form.get('from').value,
        //     to: this.form.get('to').value,
        //     comments: this.form.get('comments').value,
        //     halfDay: this.form.get('halfDay').value,
        //     timeOffType: this.form.get('timeOffType').value,
        // }
        //
        // if (this.form.value.setTime) {
        //     data['fromTime'] = this.setTimeOnDateString(this.form.get('from').value, this.form.get('fromTime').value);
        //     data['toTime'] = this.setTimeOnDateString(this.form.get('to').value, this.form.get('toTime').value);
        //     data['hours'] = this.form.get('hours').value;
        // }

        let data = {
            from: this.form.get('from').value,
            to: this.form.get('to').value,
            comments: this.form.get('comments').value,
            halfDay: this.form.get('halfDay').value,
            timeOffType: this.form.get('timeOffType').value,
            startTime:  this.form.get('fromTime').value,
            endTime: this.form.get('toTime').value,
            hours: this.form.get('hours').value
        }

        this.workflowsAbsencesService.editRequest(this.timeOffRequestId, data)
        .pipe(
            finalize(() => this.overlayService.hide())
        )
        .subscribe(
            (res) => {
                this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
                this.dialogRef.close(true);
            }
        );
    }

    close() {
        this.dialogRef.close();
    }

    // setTimeOnDateString(dateString: string, timeString: string): string {
    //     // Parse the string object to a Date object
    //     let dateObj = new Date(dateString);
    //
    //     // Split the time string into hours and minutes
    //     let [hours, minutes] = timeString.split(":");
    //
    //     // Set the hours and minutes on the Date object using UTC time
    //     dateObj.setUTCHours(parseInt(hours, 10));
    //     dateObj.setUTCMinutes(parseInt(minutes, 10));
    //
    //     // Convert the Date object back to a string in the original format
    //     let newDateString = dateObj.toISOString();
    //     return newDateString;
    // }
    //
    // getTimeFromDateString(dateString: string): string {
    //     if (!dateString || typeof dateString !== 'string') {
    //         return '';
    //     }
    //     const match = dateString.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/);
    //     if (!match) {
    //         return '';
    //     }
    //
    //     const date = new Date(dateString);
    //
    //     const offset = date.getTimezoneOffset();
    //     date.setUTCMinutes(date.getUTCMinutes() - offset);
    //
    //     const hours = String(date.getUTCHours()).padStart(2, '0');
    //     const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    //     const timeString = `${hours}:${minutes}`;
    //     return timeString;
    //
    // }

    formatTime(time) {
        try {
            const [hours, minutes] = time.split(':');
            return `${hours}:${minutes}`;
        } catch (error) {
            return '';
        }
    }
}
