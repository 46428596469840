import { CurrencyPipe, DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api_routes } from '@app/consts';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { EmployeeBankDetail } from '../models/employee-bank-details-report.model';
import { EnvironmentService } from "@app/core/services/environment.service";


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class EmployeeBankDetailsReportService {

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private overlayService: OverlayService,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private envService: EnvironmentService
  ) { }

  getBankDetails(skip?: number, take?: string, filterString?: string, sortString?: string): Observable<PagedData<EmployeeBankDetail>> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    filterString ? params = params.append('Filter', filterString) : null;
    sortString ? params = params.append('Sort', sortString) : null;

    return this.http
      .get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${api_routes.REPORTS}/${api_routes.BANK_DETAILS}`, {
        headers: headers,
        params: params
      })
      .pipe(
        catchError(err => throwError(err)),
        map(res => {
          return res;
        })
      );
  }

  public fetch(state: State, filterString: string, sortString: string, showOverlay?: boolean, formatData?: boolean): Observable<GridDataResult> {
    let params = new HttpParams();
    state.skip ? params = params.append('skip', state.skip.toString()) : null;
    state.take ? params = params.append('take', state.take.toString()) : null;
    filterString ? params = params.append('Filter', filterString) : null;
    sortString ? params = params.append('Sort', sortString) : null;

    if(showOverlay){
      this.overlayService.show(this.translate.instant('FetchingData'));
    }

    return this.http.get(`${this.envService.env.apiUrl}${api_routes.TALENT}/${api_routes.REPORTS}/${api_routes.BANK_DETAILS}`, {
      headers: headers,
      params: params
    })
    .pipe(
      finalize( () => showOverlay ? this.overlayService.hide() : null ),
      map(
        (response) =>
          <GridDataResult>{
            data: formatData ? this.formatData(response['data']) : response['data'],
            total: parseInt(response["total"], 10),
          }
      )
    );
  }

  public async queryAll(st?: State, filterString?: string, sortString?: string): Promise<GridDataResult> {
    const state = Object.assign({}, st);
    state.skip = 0;
    state.take = 100;

    let dataToReturn: GridDataResult = {
      data: [],
      total: null
    }; 

    while(dataToReturn.total === null || state.skip < dataToReturn.total) {
      const res = await this.fetch(state, filterString, sortString, true, true).toPromise();;

      if (res && res.data && res.data.length > 0) {
        dataToReturn.data = dataToReturn.data.concat(res.data);
        state.skip += state.take;
        dataToReturn.total = res.total;
      } else {
          break;
      }
    }

    return dataToReturn
  }

  formatData(data: any) {

    data.forEach(element => {
      if(element?.employee?.birthday) {
        element.employee.birthday = this.datePipe.transform(element.employee.birthday, 'longDate');
      }
      if(element?.employmentRecord?.hireDate) {
        element.employmentRecord.hireDate = this.datePipe.transform(element.employmentRecord.hireDate, 'longDate');
      }

      for (const [key, value] of Object.entries(element)) {

        // If it is an array extract the values into a string and set the value as this string
        if(Array.isArray(value)){
          let val: string = '';

          value.forEach( (item) => {
            if(typeof item === 'object' && item !== null) {
              if(item.hasOwnProperty('name')){
                if(val !== ''){
                  val = val.concat(', ')
                }
                if(item.name !== null){
                  val = val.concat(item.name)
                }
              }
              else if(item.hasOwnProperty('firstName') && item.hasOwnProperty('lastName')){
                if(val !== ''){
                  val = val.concat(', ')
                }
                if(item.firstName !== null && item.lastName !== null){
                  val = val.concat(`${item.firstName} ${item.lastName}`)
                }
              }

            }
          })

          if(val !== ''){
            element[key] = val;
          }
        }
      }

    });

    return data;
  }
}
