<table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Position Column -->
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef> 
        <div class="skeleton-table-element">
            <ngx-skeleton-loader count="1" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="skeleton-table-element">
            <ngx-skeleton-loader count="1" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
        </div>
      </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> 
            <div class="skeleton-table-element">
                <ngx-skeleton-loader count="1" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="skeleton-table-element">
                <ngx-skeleton-loader count="1" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
            </div>
          </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="weight">
        <th mat-header-cell *matHeaderCellDef> 
            <div class="skeleton-table-element">
                <ngx-skeleton-loader count="1" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="skeleton-table-element">
                <ngx-skeleton-loader count="1" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
            </div>
          </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="symbol">
        <th mat-header-cell *matHeaderCellDef> 
            <div class="skeleton-table-element">
                <ngx-skeleton-loader count="1" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
            </div>
        </th>
        <td mat-cell *matCellDef="let element">
            <div class="skeleton-table-element">
                <ngx-skeleton-loader count="1" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
            </div>
        </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>