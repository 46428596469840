<mat-card>
    <mat-card-title>
        <h5 appLocalizationPopupDirective [localizationCode]="'ToilRequests'">{{ 'TimeOffInLieuRequests' | translate: {Default: "Time Off In Lieu Requests"} }}</h5>

        <mat-form-field appLocalizationPopupDirective [localizationCode]="'Filter'" appearance="outline">
            <mat-label>{{ 'Filter' | translate: {Default: "Filter"} }}</mat-label>
            <mat-select (selectionChange)="getLeaveRequests()" [(ngModel)]="selectedFilter">
                <mat-select-trigger>
                    {{ selectedFilter.text | translate}}
                </mat-select-trigger>
                <mat-option *ngFor="let option of filterOptions" [value]="option">
                    <div appLocalizationPopupDirective [localizationCode]="option.text">{{ option.text | translate}}</div>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </mat-card-title>

    <mat-card-content>
        <div class="time-off-types-container" *ngIf="employeeToilRequests$ | withLoading | async as employeeToilRequests">
            <ng-template [ngIf]="employeeToilRequests.value">
                <div class="no-data" *ngIf="employeeToilRequests.value.data.length === 0">
                    <mat-icon aria-hidden="false" class="date-icon material-icons-outlined">date_range</mat-icon>
                    <div appLocalizationPopupDirective [localizationCode]="'NoToilRequests'">
                        {{ 'NoToilRequests' | translate: {Default: "No TOIL Requests To Approve"} }}
                    </div>
                </div>

                <mat-list>
                    <ng-container *ngFor="let employeeToilRequest of employeeToilRequests.value.data">
                        <app-toil-approval-list-item
                            [employeeToilRequest]="employeeToilRequest"
                            (openPendingTimeOffDialog)="openPendingTimeOffDialog($event)"
                        ></app-toil-approval-list-item>
                    </ng-container>
                </mat-list>
            </ng-template>
            <ng-template [ngIf]="employeeToilRequests.error">Error {{ employeeToilRequests.error }}</ng-template>
            <ng-template [ngIf]="employeeToilRequests.loading">
                <ngx-skeleton-loader
                    count="5"
                    [theme]="{
                        'height.px': 50
                    }"
                ></ngx-skeleton-loader>
            </ng-template>
        </div>
    </mat-card-content>
</mat-card>
