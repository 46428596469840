<div class="container" *ngIf="hasFeatureAccess">
    <div class="top">
        <h6>{{ 'GenerateSchedule' | translate: {Default: "Generate Schedule"} }}</h6>
    </div>

    <div class="main-container">
        <div class="page-description-section">
            {{ 'GeneratePositionScheduleDescriptionMsg' | translate: {Default: "Select a date range you wish to generate the schedule for."} }}
        </div>

    <div class="form-container">
        <div class="k-d-flex-row">
            <mat-form-field appearance="fill">
                <mat-label>
                    {{ 'StartDate' | translate: {Default: "Start Date"} }} *
                </mat-label>

                <input matInput [matDatepicker]="startDatepicker" [(ngModel)]="startDate">
                <span fxLayout="row" matSuffix>
                <mat-datepicker-toggle [for]="startDatepicker"></mat-datepicker-toggle>
            </span>
                <mat-datepicker #startDatepicker></mat-datepicker>
                <mat-hint></mat-hint>
            </mat-form-field>
        </div>

        <div class="k-d-flex-row">
            <mat-form-field appearance="fill">
                <mat-label>
                    {{ 'EndDate' | translate: {Default: "End Date"} }} *
                </mat-label>

                <input matInput [matDatepicker]="endDatepicker" [(ngModel)]="endDate">
                <span fxLayout="row" matSuffix>
                <mat-datepicker-toggle [for]="endDatepicker"></mat-datepicker-toggle>
            </span>
                <mat-datepicker #endDatepicker></mat-datepicker>
                <mat-hint></mat-hint>
            </mat-form-field>
        </div>

        <button mat-flat-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!isFormValid">
            <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">play_circle_outline</mat-icon> {{ 'GenerateSchedule' | translate: {Default: "Generate Schedule"} }}
        </button>
    </div>
</div>