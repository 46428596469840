import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MetadataFormService } from '@app/core/services/metadata/metadata-form.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { finalize } from 'rxjs/operators';
import { routes } from '@app/consts';
import { SortDescriptor } from '@progress/kendo-data-query';
import { defer, forkJoin } from 'rxjs';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-form-list',
    templateUrl: './form-list.component.html',
    styleUrls: ['./form-list.component.scss']
})
export class FormListComponent implements OnInit {
    public routes: typeof routes = routes;
    public sort: SortDescriptor[] = [];
    public bindingType: String = 'array';
    public gridData: any;
    public gridDataResult: GridDataResult;
    public selectedItems: any[] = [];
    isLoading: boolean;
    pageSize: number = 20;
    skip: number = 0;
    sortString: string = "name-asc";

    private getRequest: any;
    public searchValue: string;
    public searchFilterString: string;


    filters: {
        id: string,
        filterCategory: any,
        filterOperator: string,
        filterString: any
    }[] = [];

    constructor(
        private dialog: MatDialog,
        private overlayService: OverlayService,
        private snackbarService: SnackbarService,
        public translate: TranslateService,
        private metadataFormService: MetadataFormService
    ) {
    }

    ngOnInit(): void {
        this.getForms();
    }

    getForms(): void {
        this.isLoading = true;

        this.getRequest = this.metadataFormService.getForms(this.skip, String(this.pageSize), this.searchFilterString, this.sortString)
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                })
            )
            .subscribe(
                res => {
                    this.gridDataResult = {
                        data: res.data,
                        total: res.total,
                    };
                }
            );
    }

    public pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.pageSize = event.take;
        this.getForms();
    }

    public sortChange(sort: SortDescriptor[]): void {
        this.sort = sort;

        if (sort[0].dir === undefined) {
            this.sortString = null;
        } else {
            //use regex to get column field to sort with
            let field: any;

            //if it is an object category like (department.text) trim .text from the end, else it doesnt need to be trimmed
            (sort[0].field).match(/.+(?=\.)/) === null
                ? field = sort[0].field
                : field = (sort[0].field).match(/.+(?=\.)/);
            this.sortString = `${field}-${sort[0].dir}`;
        }

        this.getForms();
    }


    deleteAllSelected() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            text: `Are you sure you want to delete ${this.selectedItems.length} selected form(s)?`
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data === true) {
                    const observables = this.selectedItems.map(selectedItem => defer(() => this.metadataFormService.deleteForm(selectedItem)));

                    this.overlayService.show();

                    forkJoin(observables)
                        .pipe(
                            finalize(() => this.overlayService.hide())
                        )
                        .subscribe(
                            (res) => {
                                this.selectedItems = [];
                                this.getForms();
                                this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
                            },
                            err => {
                                this.snackbarService.openSnackBar(err, 'clear', 'warn');
                            }
                        );
                }
            }
        );
    }

    search() {
        this.searchFilterString = `(Name like "${this.searchValue}")`;
        this.getRequest.unsubscribe();
        this.getForms();
    }
}
