import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { FormElement } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { Dimensions, ImageCroppedEvent, ImageTransform, base64ToFile } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-upload-form-field',
  templateUrl: './image-upload-form-field.component.html',
  styleUrls: ['./image-upload-form-field.component.scss']
})
export class ImageUploadFormFieldComponent implements OnInit {
  @Input() formElement: FormElement;
  @Input() parentGroup: UntypedFormGroup;
  @Input() readOnly: boolean;
  
  employeeID: string;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  wrongFileTypeUploaded: boolean = false;

  constructor(
    public translate: TranslateService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit(): void { }

  //return a promise that resolves with a File instance
  urltoFile(url, filename, mimeType){
    return (fetch(url)
      .then(function(res){return res.arrayBuffer();})
      .then(function(buf){return new File([buf], filename,{type:mimeType});})
    );
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.setValue();
  }

  imageLoaded() {
    this.showCropper = true;
    this.setValue();
  }

  cropperReady(sourceImageDimensions: Dimensions) {
  }

  loadImageFailed() {
    this.imageChangedEvent = '';
    this.snackbarService.openSnackBar(this.translate.instant('IncorrectFileType'), 'clear', 'error');
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
        ...this.transform,
        flipH: flippedV,
        flipV: flippedH
    };
  }


  flipHorizontal() {
    this.transform = {
        ...this.transform,
        flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
        ...this.transform,
        flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
        ...this.transform,
        rotate: this.rotation
    };
  }

  setValue() {
    // this.parentGroup.controls[this.formElement.formControl].setValue(this.croppedImage);


    this.urltoFile(this.croppedImage, `CompanyLogo.png`, base64ToFile(this.croppedImage).type)
    .then(
      (file) => { 
        debugger;
        this.parentGroup.controls[this.formElement.formControl].setValue(file);
      }
    )
  }
}
