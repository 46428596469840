<table mat-table [dataSource]="letters">

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox setColor="primaryColour"
                      (click)="$event.stopPropagation()"
                      (change)="$event ? checkboxChange($event, row) : null"
                      [checked]="selectedLetter === row">
        </mat-checkbox>
      </td>
    </ng-container>
  
    <!-- Position Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> Description </th>
      <td mat-cell *matCellDef="let element"> {{element.description}} </td>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="controls">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element"> 
        <button mat-raised-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openLetterPreviewDialog(element)">Preview</button>    
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (click)="selection.toggle(row)">
    </tr>
</table>
  