import { Component, Input, OnInit } from '@angular/core';
import { HardcodedReport } from '@app/modules/custom-reports/models/custom-report.model';
import { CustomReportsService } from '@app/modules/custom-reports/services/reports.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-hardcoded-reports-data-grid',
  templateUrl: './hardcoded-reports-data-grid.component.html',
  styleUrls: ['./hardcoded-reports-data-grid.component.scss']
})
export class HardcodedReportsDataGridComponent implements OnInit {
  @Input() reportType: string;
  isLoading: boolean;
  reports: HardcodedReport[] = []
  reports$: any;
  reportSearchValue: string = '';

  public columns: any[] = [
    { field: "name", title: "Name" }
  ];

  constructor(
    public translate: TranslateService,
    private reportsService: CustomReportsService,
  ) { }

  ngOnInit(): void {
    this.reports$ = this.reportsService.getHardcodedReports(this.reportType);
  }
}
