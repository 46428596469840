<h5 mat-dialog-title>{{ 'CreateADirectory' | translate: {Default: "Create A Directory"} }}</h5>

<mat-dialog-content [formGroup]="form">
  
    <mat-form-field>
        <input 
            matInput
            [placeholder]="translate.instant('DirectoryName')"
            formControlName="directoryName">
    </mat-form-field>
 
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
    <button mat-raised-button setColor="primaryColour" color="primary" class="submit-button" (click)="save()" [disabled]="!form.valid" >{{ 'Save' | translate: {Default: "Save"} }}</button>
</mat-dialog-actions>