<app-data-grid-comp
    [columns]="columns"
    [bindingType]="bindingType"
    [view]="view"
    [gridData]="gridData"
    [gridDataResult]="gridDataResult"
    [isLoading]="isLoading"
    [pageSize]="pageSize"
    [skip]="skip"
    category="Pensions"
    [isLoading]="isLoading"
    (emitPageChangeEvent)="pageChange($event)"
    (emitOpenDialogEvent)="openEmploymentRecordPensionDialog($event)"
    (emitDeleteEvent)="deleteEmploymentRecordPension($event)"
></app-data-grid-comp>