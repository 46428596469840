import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { Lookup } from '@app/modules/lookup/models/lookup.model';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EmploymentRecordRelocation } from '../../models/employment-record-relocation.model';
import { EmploymentRecordRelocationService } from '../../services/employment-record-relocation.service';

@Component({
  selector: 'app-employment-record-relocation-dialog',
  templateUrl: './employment-record-relocation-dialog.component.html',
  styleUrls: ['./employment-record-relocation-dialog.component.scss']
})
export class EmploymentRecordRelocationDialogComponent implements OnInit {
  form: UntypedFormGroup;
  employmentRecordId: any;
  employeeId: any;
  relocationId: any;
  isLoading: boolean;
  employmentRecordRelocation: EmploymentRecordRelocation;
  sendingLocationOptions: Observable<Lookup[]>;
  pointOfOriginOptions: Observable<Lookup[]>;
  receivingLocationOptions: Observable<Lookup[]>;

  constructor(
    private fb: UntypedFormBuilder,
    private lookupService: LookupService,
    private overlayService: OverlayService,
    private employmentRecordRelocationsService: EmploymentRecordRelocationService,
    private snackbarService: SnackbarService,
    private dialogRef: MatDialogRef<EmploymentRecordRelocationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data)
  {
    this.employmentRecordId = data.employmentRecordId;
    this.employeeId = data.employeeId;
    this.relocationId = data.RelocationId;
  } 

  ngOnInit(): void {
    this.getLookups();

    this.relocationId !== null 
      ? this.getEmploymentRecordRelocation() 
      : this.isLoading = false; 
        this.createForm();
  }

  getEmploymentRecordRelocation() {
    this.isLoading = true;
    
    this.employmentRecordRelocationsService.getRelocation(this.employeeId, this.employmentRecordId, this.relocationId)
    .pipe(
      finalize(() => this.isLoading = false)
    )
    .subscribe(
      res => {
        this.employmentRecordRelocation = res;
        this.createForm();
      }
    );
  }

  getLookups() {
    this.pointOfOriginOptions = this.lookupService.getLookups("/lookup/RELOCATION_POINT_OF_ORIGIN");
    this.receivingLocationOptions = this.lookupService.getLookups("/lookup/RELOCATION_RECEIVING_LOCATION");
    this.sendingLocationOptions = this.lookupService.getLookups("/lookup/RELOCATION_SENDING_LOCATION");
  }

  createForm() {
    this.form = this.fb.group({
      id: [this.employmentRecordRelocation ? this.employmentRecordRelocation.id : null],
      effectiveDate: [moment().format()],
      dateInitiated: [this.employmentRecordRelocation ? this.employmentRecordRelocation.dateInitiated : null],
      relocationStartDate: [this.employmentRecordRelocation ? this.employmentRecordRelocation.relocationStartDate : null],
      relocationEndDate: [this.employmentRecordRelocation ? this.employmentRecordRelocation.relocationEndDate : null],
      totalCosts: [this.employmentRecordRelocation ? this.employmentRecordRelocation.totalCosts : null],
      sendingLocation: [this.employmentRecordRelocation && this.employmentRecordRelocation.sendingLocation ? this.employmentRecordRelocation.sendingLocation.id : null],
      receivingLocation: [this.employmentRecordRelocation && this.employmentRecordRelocation.receivingLocation ? this.employmentRecordRelocation.receivingLocation.id : null],
      pointOfOrigin: [this.employmentRecordRelocation && this.employmentRecordRelocation.pointOfOrigin ? this.employmentRecordRelocation.pointOfOrigin.id : null],
      comments: [this.employmentRecordRelocation ? this.employmentRecordRelocation.comments : null],
      changeReason: [''],
      changeReasonComments: ['']
    });
  }

  save(){
    this.overlayService.show();

    this.employmentRecordRelocationsService.postRelocation(this.employeeId, this.employmentRecordId, this.form.value)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar('Relocation saved successfully', 'clear', 'success');
        this.dialogRef.close(true);
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  close(){
    this.dialogRef.close();
  }

}

