<mat-card>
    <mat-card-title class="header">
        <h5>API Calls Per User for This Year</h5>
    </mat-card-title>
    <mat-card-content>
        <div id="chart">
            <apx-chart
              [series]="chartOptions.series"
              [chart]="chartOptions.chart"
              [xaxis]="chartOptions.xaxis"
              [stroke]="chartOptions.stroke"
              [dataLabels]="chartOptions.dataLabels"
              [yaxis]="chartOptions.yaxis"
              [labels]="chartOptions.labels"
              [legend]="chartOptions.legend"
              [title]="chartOptions.title"
              [subtitle]="chartOptions.subtitle"
            ></apx-chart>
          </div>
    </mat-card-content>
</mat-card>

