import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TimeOffInLieuService } from '@app/core/services/time-off-in-lieu/time-off-in-lieu.service';
import { MyRequest } from '@app/shared/models/workflows-absences/workflows-absences.model';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { PendingToilDialogComponent } from './components/pending-toil-dialog/pending-toil-dialog.component';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-pending-toil-approvals',
  templateUrl: './pending-toil-approvals.component.html',
  styleUrls: ['./pending-toil-approvals.component.scss']
})
export class PendingToilApprovalsComponent implements OnInit {
  public selectedFilter;
  employeeToilRequests$: any;

  selectedStatusFilters = [];
  statusOptions = [
    {id: "trs_Pending", name: this.translate.instant('Pending')},
    {id: "trs_Cancelled", name: this.translate.instant('Cancelled')},
    {id: "trs_Approved", name: this.translate.instant('Approved')},
    {id: "trs_Denied", name: this.translate.instant('Denied')}
  ]

  filterOptions = [
    {
      value: '3',
      text: 'Last3Months'
    },
    {
      value: '6',
      text: 'Last6Months'
    },
    {
      value: '12',
      text: 'Last12Months'
    },
    {
      value: '',
      text: 'All'
    },
  ]

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private timeOffInLieuService: TimeOffInLieuService,
  ) {
    this.selectedFilter = this.filterOptions[0];
  }

  ngOnInit(): void {
    this.getLeaveRequests();
  }

  buildFilterString(): string {
    let filterString;
    let statusOptionString;


    if(this.selectedFilter !== "") {
      filterString = `(StartDate > "${moment().subtract(parseInt(this.selectedFilter.value), 'months').format()}")`
    }

    this.selectedStatusFilters.forEach(
      option => {
        if(statusOptionString !== undefined){
          statusOptionString = `${statusOptionString} OR (RequestStatus.Id = "${option}")`
        }
        else {
          statusOptionString = `(RequestStatus.Id = "${option}")`
        }
      }
    )

    if(filterString !== undefined && statusOptionString !== undefined){
      filterString = `${filterString} AND (${statusOptionString})`;
    }
    else if(statusOptionString !== undefined){
      filterString = `(${statusOptionString})`;
    }

    return filterString;
  }

  getLeaveRequests() {
    this.timeOffInLieuService.getPendingApproval(0, 100, `version.createdOn-desc`, this.buildFilterString())
    .subscribe(
      res => {
        if(res instanceof Observable){
          res.subscribe(
            r => {
              this.employeeToilRequests$ = res;
            }
          )
        }
        else {
          this.employeeToilRequests$ = of(res);
        }
      }
    );
  }

  openPendingTimeOffDialog(toilLeaveRequest: MyRequest) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 500;

    dialogConfig.data = {
      employeeToilRequest: toilLeaveRequest
    };

    const dialogRef = this.dialog.open(PendingToilDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data === true) {
          this.getLeaveRequests();
        }
      }
    );
  }

}

