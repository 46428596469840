<div class="container">
    <div class="top">
        <h6>{{ 'PlanCoverages' | translate: {Default: "Plan Coverages"} }}</h6>
    </div>

    <div class="main-content">
        <ng-container *ngIf="loadingCoverages; else loaded">
            <ngx-skeleton-loader
                count="5"
                [theme]="{ 
                    'height.px': 50
                }"
            ></ngx-skeleton-loader>
        </ng-container>
        
        <ng-template #loaded>
            <div *ngIf="planCoverages.length > 0 else noContent" class="list-container">
                <div *ngIf="selectedPlanCoverages.length > 0">
                    <button class="delete-all-selected-button" mat-stroked-button setColor="primaryColour" color="primary" (click)="deleteAllSelected()">
                      <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">delete</mat-icon> 
                      {{ 'PlanCoveragesDeleteAllSelected' | translate: {Default: "Delete All Selected"} }} ({{selectedPlanCoverages.length}})
                    </button>
                </div>               
               
                <div class="select-all-container">
                    <div>
                        {{ 'PlanCoveragesSelectAll' | translate: {Default: "Select All"} }}
                    </div>
                    <mat-checkbox setColor="primaryColour" class="example-margin" (change)="toggleSelectAll($event)" [checked]="selectAll"></mat-checkbox>
                </div>
        
                <mat-selection-list #selectionList disableRipple="true" [(ngModel)]="selectedPlanCoverages">
                    <mat-list-option *ngFor="let planCoverage of planCoverages" [value]="planCoverage">
                        <div class="list-item-content">
                            <div class="coverageName">
                                {{planCoverage.coverage.name}}
                            </div>
                            <div class="costPerMonth">
                                {{ ( planCoverage.costPerMonth |currency: planCoverage.currency.id: true:'1.2-2') }} ({{planCoverage.currency.id}}) 
                                <span class="perMonth">
                                    {{ 'PlanCoveragesPerMonth' | translate: {Default: "Per Month"} }}
                                </span>
                            </div>
                            <div class="planCoverage-action-buttons">
                                <mat-icon class="material-icons-outlined" matTooltip="Edit" appPreventDoubleClick (throttledClick)="openFormDialog($event, planCoverage)">edit</mat-icon> 
                                <mat-icon class="material-icons-outlined" matTooltip="Delete" appPreventDoubleClick (throttledClick)="openConfirmDeleteDialog($event, planCoverage)">delete</mat-icon> 
                            </div>
                        </div>
                    </mat-list-option>
                </mat-selection-list>
            </div>

            <ng-template #noContent>
                <div>
                    {{ 'PlanCoveragesNoCoveragesAssignedToThisPlan' | translate: {Default: "No coverages assigned to this plan"} }}
                </div>
            </ng-template>
        </ng-template>

        <div class="buttons-container">
            <button mat-stroked-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openFormDialog($event)">
                <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> {{ 'PlanCoveragesAddCoverage' | translate: {Default: "Add Coverage"} }}
            </button>
        </div>

    </div>
</div>

