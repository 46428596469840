import { Component, Input, OnInit } from '@angular/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { WorkRotationElement } from '../../models/work-rotation.model';
import { WorkRotationService } from '../../services/work-rotation.service';

@Component({
  selector: 'app-work-rotation-elements-list',
  templateUrl: './work-rotation-elements-list.component.html',
  styleUrls: ['./work-rotation-elements-list.component.scss']
})
export class WorkRotationElementsListComponent implements OnInit {

  @Input() workRotationId: string;
  @Input() elements: WorkRotationElement[];

  newFormElement: WorkRotationElement = {
    id: null,
    startTime: null,
    endTime: null,
    numberOfDays: 1,
    isWorkday: true,
    breakTime: 0,
    // totalHours: null,
    // totalWorkHours: null
  }
  loading: boolean;

  constructor(
    private workRotationService: WorkRotationService
  ) { }

  ngOnInit(): void {
  }


  addNewElement() {
    this.elements.push(this.newFormElement);
  }

  elementRemoved(event: any){
    if(event === true) {
      // this.getWorkRotationElements()
    }
    else {
      const index = this.elements.indexOf(event);
      if (index > -1) {
        this.elements.splice(index, 1);
      }
    }
  }

}
