import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { routes } from '@app/consts';

@Component({
  selector: 'app-employment-record-details',
  templateUrl: './employment-record-details.component.html',
  styleUrls: ['./employment-record-details.component.scss']
})
export class EmploymentRecordDetailsComponent implements OnInit {
  employeeId: string;
  employmentRecordId: string;
  navList = [
    {
      text: 'Employment Record',
      icon: 'badge',
      subList: []
    },
    {
      text: 'Position',
      icon: 'article',
      subList: []
    },
    {
      text: 'Compensation',
      icon: 'article',
      subList: []
    },
    {
      text: 'Other Compensation',
      icon: 'article',
      subList: []
    },
    {
      text: 'Payroll',
      icon: 'article',
      subList: []
    },
    {
      text: 'Benefits',
      icon: 'article',
      subList: []
    },
    {
      text: 'Pension',
      icon: 'article',
      subList: []
    },
    {
      text: 'Stock Purchase Plans',
      icon: 'article',
      subList: []
    },
    {
      text: 'Company Assets',
      icon: 'article',
      subList: []
    },
    {
      text: 'Relocation',
      icon: 'article',
      subList: []
    },
    {
      text: 'Grievances',
      icon: 'article',
      subList: []
    },
    {
      text: 'Discipline',
      icon: 'article',
      subList: []
    },
    {
      text: 'Injury Illness',
      icon: 'article',
      subList: []
    }
  ];
  selectedNav: { text: string; icon: string; subList: any[]; };

  constructor(
    private route: ActivatedRoute,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.selectedNav = this.navList[0];
    this.setValuesFromUrl();
  }

  navigateToEmploymentRecordsList() {
    this.router.navigateByUrl(`${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYMENT_RECORDS}`);
  }

  setValuesFromUrl(): void {
    this.route.paramMap.subscribe(
      params => {
        this.employeeId = params.get("id");
        this.employmentRecordId = params.get("employmentRecordId");
      }
    )
  }

}
