import OrgChart from "../../../../../assets/balkanapp/orgchart";
import {routes} from "@app/consts";
import {OrgChartListener} from "@app/modules/org-chart/components/org-chart-display/org-chart-listener";
import {TranslateService} from "@ngx-translate/core";

export class OrgChartsUtil {

  constructor(private translate: TranslateService) {
  }

  tags = {
    EMPLOYEE: "employee",
    EMPLOYEE_SELECTED: "employee_selected",
    POSITION: "position",
    POSITION_SELECTED: "position_selected",
    ASSISTANT: "assistant",
    ASSISTANT_SELECTED: "assistant_selected",
    ORGANIZATION: "organization",
    ORGANIZATION_SELECTED: "organization_selected",
    FILTER: "filter",
  }

  POSITION_FIELDS = {
    positionName: "Position Name",
    localization: "Localization",
    jobTitle: "Functional Job Title",
    marketPositionTitle: "Market Position Title",
    marketPosition: "Market Position",
    marketView: "Market View",
    unionCode: "Union Code",
    shiftCode: "Shift Code",
    numberSlots: "Number Of Slots",
    enforceSlotLimit: "Enforce Slot Limit",
    costCenter: "Cost Center",
    department: "Department",
    division: "Division",
    empCategory: "Employee Category",
    empGroup: "Employment Group",
    empType: "Employment Type",
    jobBand: "Job Band",
    jobFamily: "Job Family",
    jobGroup: "Job Group",
    jobType: "Job Type",
    jobStep: "Job Step",
    projectTeam: "Project Team",
    region: "Region",
    workLocation: "Work Location",
    subDivision: "Sub Division",
    workRotation: this.translate.instant('WorkRotation'),
    workRotationStart: this.translate.instant('WorkRotationStartDate'),
    isVacant: "Position Occupancy",
    startDate: "Start Date",
    endDate: "End Date",
    status: "Status",
  }


  EMPLOYEE_FIELDS = {
    name: "Name",
    firstName: "First Name",
    lastName: "Last Name",
    title: "Title",
    preferred: "Preferred",
    maidenName: "Maiden Name",
    middleName: "Middle Name",
    gender: "Gender",
    cultureAffil: "Cultural Affiliation",
    religiousAffil: "Religious Affiliation",
    linkedin: "Linkedin",
    twitter: "Twitter",
    facebook: "Facebook",
    status: "Status",
    birthday: "Birthday",
    maritalStatus: "Marital Status",
    subStatus: "Substatus",
    language: "Preferred Language",
    streetAddress: "Street Address",
    city: "City",
    province: "Province",
    zip: "Zip",
    country: "Country",
    email: "Email",
    workPhone: "Work Phone",
    homePhone: "Home Phone",
    cellPhone: "Cellphone",
    culture: "Culture"
  }

  ORGANIZATION_FIELDS = {
    startDate: "Start Date",
    endDate: "End Date",
    organizationType: "Organization Type"
  }


  CHART_TYPES = {
    organizations: "organizations",
    positions: "positions"
  }

  NODE_BINDiNGS = {
    field_Position_Name: this.POSITION_FIELDS.positionName,
    field_0: this.EMPLOYEE_FIELDS.name,
    field_1: this.POSITION_FIELDS.marketPosition,
    field_2: this.POSITION_FIELDS.marketPositionTitle,
    field_53: this.POSITION_FIELDS.isVacant,
    field_3: this.POSITION_FIELDS.marketView,
    field_4: this.POSITION_FIELDS.unionCode,
    field_5: this.POSITION_FIELDS.shiftCode,
    field_6: this.POSITION_FIELDS.numberSlots,
    field_7: this.POSITION_FIELDS.enforceSlotLimit,
    field_8: this.POSITION_FIELDS.costCenter,
    field_9: this.POSITION_FIELDS.department,
    field_10: this.POSITION_FIELDS.division,
    field_11: this.POSITION_FIELDS.empCategory,
    field_12: this.POSITION_FIELDS.empGroup,
    field_13: this.POSITION_FIELDS.empType,
    field_14: this.POSITION_FIELDS.jobTitle,
    field_15: this.POSITION_FIELDS.jobBand,
    field_16: this.POSITION_FIELDS.jobFamily,
    field_17: this.POSITION_FIELDS.jobGroup,
    field_18: this.POSITION_FIELDS.jobType,
    field_19: this.POSITION_FIELDS.jobStep,
    field_20: this.POSITION_FIELDS.projectTeam,
    field_21: this.POSITION_FIELDS.region,
    field_22: this.POSITION_FIELDS.workLocation,
    field_23: this.POSITION_FIELDS.subDivision,
    field_24: this.POSITION_FIELDS.workRotation,
    field_25: this.POSITION_FIELDS.workRotationStart,

    field_26: this.EMPLOYEE_FIELDS.firstName,
    field_27: this.EMPLOYEE_FIELDS.lastName,
    field_28: this.EMPLOYEE_FIELDS.title,
    field_29: this.EMPLOYEE_FIELDS.preferred,
    field_30: this.EMPLOYEE_FIELDS.maidenName,
    field_31: this.EMPLOYEE_FIELDS.middleName,
    field_32: this.EMPLOYEE_FIELDS.gender,
    field_33: this.EMPLOYEE_FIELDS.cultureAffil,
    field_34: this.EMPLOYEE_FIELDS.religiousAffil,
    field_35: this.EMPLOYEE_FIELDS.linkedin,
    field_36: this.EMPLOYEE_FIELDS.twitter,
    field_37: this.EMPLOYEE_FIELDS.facebook,
    field_38: this.EMPLOYEE_FIELDS.status,
    field_39: this.EMPLOYEE_FIELDS.birthday,
    field_40: this.EMPLOYEE_FIELDS.maritalStatus,
    field_41: this.EMPLOYEE_FIELDS.subStatus,
    field_42: this.EMPLOYEE_FIELDS.language,
    field_43: this.EMPLOYEE_FIELDS.streetAddress,
    field_44: this.EMPLOYEE_FIELDS.city,
    field_45: this.EMPLOYEE_FIELDS.province,
    field_46: this.EMPLOYEE_FIELDS.zip,
    field_47: this.EMPLOYEE_FIELDS.country,
    field_48: this.EMPLOYEE_FIELDS.email,
    field_49: this.EMPLOYEE_FIELDS.workPhone,
    field_50: this.EMPLOYEE_FIELDS.homePhone,
    field_51: this.EMPLOYEE_FIELDS.cellPhone,
    field_52: this.EMPLOYEE_FIELDS.culture,

    field_54: this.ORGANIZATION_FIELDS.startDate,
    field_55: this.ORGANIZATION_FIELDS.endDate,
    field_56: this.ORGANIZATION_FIELDS.organizationType,

    imgs: "img",
    img_0: "img",
    detailsLoaded: 'detailsLoaded',
  }


  addEditPermissionMenuItems(listener: OrgChartListener, positionMenu, employeeMenu, organizationMenu) {
    positionMenu['addPosition'] = {
      text: "Add new position", icon: OrgChart.icon.add(24, 24, "#7A7A7A"), onClick: (nodeId) => {
        listener.openNewPositionDialog(nodeId)
      }
    }
    positionMenu['addEmployee'] = {
      text: "Add a new employee", icon: OrgChart.icon.add(24, 24, "#7A7A7A"), onClick: (nodeId) => {
        listener.openNewEmployeeDialog(nodeId)
      }
    }
    positionMenu['edit'] = {
      text: "Edit position", onClick: (nodeId) => {
        listener.redirectTo(`${routes.SITE_SETTINGS}${routes.POSITIONS}/${routes.EDITOR}/${nodeId}`, 'position')
      }
    }
    positionMenu['remove'] = {
      text: "Delete", onClick: (nodeId) => {
        listener.deletePosition(nodeId)
      }
    }


    employeeMenu['edit'] = {
      text: "Edit employee", onClick: (nodeId) => {
        let employee = listener.getEmployeeByNodeId(nodeId)
        if (employee) {
          listener.redirectTo(`${routes.TALENT_TRACK}/${routes.EDIT}/${employee.id}`, 'employee')
        }
      }
    }
    employeeMenu['remove'] = {
      text: "Delete", onClick: (nodeId) => {
        listener.deleteEmployeePosition(nodeId)
      }
    }


    organizationMenu['addOrganization'] = {
      text: "Add a new organization", icon: OrgChart.icon.add(24, 24, "#7A7A7A"), onClick: (nodeId) => {
        listener.openNewOrganizationDialog(nodeId)
      }
    },
      organizationMenu['edit'] = {
        text: "Edit organization", onClick: (nodeId) => {
          listener.redirectTo(`${routes.SITE_SETTINGS}/${routes.ORGANIZATION}/${routes.EDITOR}/${nodeId}`, 'organization')
        }
      }
    organizationMenu['remove'] = {
      text: "Delete", onClick: (nodeId) => {
        listener.deleteOrganization(nodeId)
      }
    }
  }


  initTemplates() {
    OrgChart.RES.IT_IS_LONELY_HERE_LINK = "";

    //Position Template
    OrgChart.templates.position = Object.assign({}, OrgChart.templates.group)
    OrgChart.templates.position.plus = '<circle cx="15" cy="15" r="15" fill="#ffffff" stroke="#aeaeae" stroke-width="1"></circle>'
      + '<text text-anchor="middle" style="font-size: 18px;cursor:pointer;" fill="#757575" x="15" y="22">{collapsed-children-count}</text>';

    OrgChart.templates.position.min = Object.assign({}, OrgChart.templates.group)
    OrgChart.templates.position.min['field_0'] = '<text data-width="180" data-text-overflow="multiline-2-ellipsis" style="font-size: 16px;" fill="#757575" x="125" y="30" text-anchor="middle">{val}</text>';
    OrgChart.templates.position.min["imgs"] = "{val}";
    OrgChart.templates.position.min.img_0 = "";
    OrgChart.templates.position.min.link = '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="{rounded}" />';
    OrgChart.templates.position.min.plus = '<circle cx="15" cy="15" r="15" fill="#ffffff" stroke="#aeaeae" stroke-width="1"></circle>'
      + '<text text-anchor="middle" style="font-size: 18px;cursor:pointer;" fill="#757575" x="15" y="22">{collapsed-children-count}</text>';
    OrgChart.templates.position.editFormHeaderColor = '#db8e4b';
    OrgChart.templates.position.link = '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="{rounded}" />';
    OrgChart.templates.position_selected = Object.assign({}, OrgChart.templates.position)

    //Employee template
    OrgChart.templates.employee = Object.assign({}, OrgChart.templates.ula)
    OrgChart.templates.employee['field_0'] = '<text data-width="150" data-text-overflow="multiline-2-ellipsis" style="font-size: 16px;" fill="#757575" x="160" y="60" text-anchor="middle">{val}</text>';
    OrgChart.templates.employee.editFormHeaderColor = '#db8e4b';

    OrgChart.templates.employee_selected = Object.assign({}, OrgChart.templates.employee)

    //Assistant template
    OrgChart.templates.assistant = Object.assign({}, OrgChart.templates.group)
    OrgChart.templates.assistant.link = '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="{curve}" />';

    //Organization
    OrgChart.templates.organization = Object.assign({}, OrgChart.templates.group)
    OrgChart.templates.organization.min = Object.assign({}, OrgChart.templates.group)
    OrgChart.templates.organization.size = [350, 180];
    OrgChart.templates.organization.min.size = [350, 180];

    OrgChart.templates.organization.min["imgs"] = "{val}";
    OrgChart.templates.organization.min.img_0 = "";

    OrgChart.templates.organization.link = '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="{rounded}" />';
    OrgChart.templates.organization.min.link = '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="{rounded}" />';

    OrgChart.templates.organization["field_0"] = '<text data-width="290" data-text-overflow="multiline-3-ellipsis" style="font-size: 16px; background-color: #de1010;" fill="#757575" x="175" y="26" text-anchor="middle">{val}</text>';
    OrgChart.templates.organization.min['field_0'] = '<text data-width="290" data-text-overflow="multiline-3-ellipsis" style="font-size: 16px; background-color: #de1010;" fill="#757575" x="175" y="26" text-anchor="middle">{val}</text>';
    OrgChart.templates.organization["field_55"] = '<text data-width="290" data-text-overflow="multiline-2-ellipsis" style="font-size: 16px; background-color: #de1010;" fill="#757575" x="175" y="130" text-anchor="middle">{val}</text>';
    OrgChart.templates.organization.min["field_55"] = '<text data-width="290" data-text-overflow="multiline-2-ellipsis" style="font-size: 16px;" fill="#757575" x="175" y="130" text-anchor="middle">{val}</text>';

    OrgChart.templates.organization.plus = '<circle cx="15" cy="15" r="15" fill="#ffffff" stroke="#aeaeae" stroke-width="1"></circle>'
      + '<text text-anchor="middle" style="font-size: 18px;cursor:pointer;" fill="#757575" x="15" y="22">{collapsed-children-count}</text>';
    OrgChart.templates.organization.min.plus = '<circle cx="15" cy="15" r="15" fill="#ffffff" stroke="#aeaeae" stroke-width="1"></circle>'
      + '<text text-anchor="middle" style="font-size: 18px;cursor:pointer;" fill="#757575" x="15" y="22">{collapsed-children-count}</text>';
    OrgChart.templates.organization.editFormHeaderColor = '#db8e4b';
    OrgChart.templates.organization.link = '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="{rounded}" />';
    OrgChart.templates.organization.min.link = '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="{rounded}" />';


    //Organization Selected
    OrgChart.templates.organization_selected = Object.assign({}, OrgChart.templates.organization)
  }


  handleNodeClick(listener: OrgChartListener, sender, args) {
    let node = sender.getNode(args['node'].id)
    if (node) {
      if ([this.tags.POSITION, this.tags.POSITION_SELECTED, this.tags.ORGANIZATION, this.tags.ORGANIZATION_SELECTED
      ].includes(args['node'].templateName)) {
        if (args['node'].min) {
          sender.maximize(args['node'].id);
        } else {
          sender.minimize(args['node'].id);
        }
      } else if (args['node'].templateName == this.tags.EMPLOYEE) {
        let employee = listener.getEmployeeByNodeId(node.id)
        if (employee) {
          listener.loadEmployeeDetails(args["node"].id, employee.id)
        }
      }
    }
    return false;
  }

  handleExpandCollapse(listener: OrgChartListener, chartType, sender: OrgChart, collapse: any, id: any, ids: any) {
    if (!collapse) {
      sender.expand(id, ids, () => {
          listener.loadPhotos()
      })
    } else {
      sender.collapse(id, ids)
    }

    return false;
  }


  treeTags(positionMenu, employeeMenu, organizationMenu) {
    return {
      [this.tags.POSITION]: {
        template: this.tags.POSITION,
        nodeMenu: positionMenu,
        subTreeConfig: {
          columns: 3,
          collapse: {
            level: 1,
          }
        }
      },
      [this.tags.POSITION_SELECTED]: {
        template: this.tags.POSITION_SELECTED,
        nodeMenu: positionMenu,
        subTreeConfig: {
          columns: 3,
          collapse: {
            level: 1,
          }
        }
      },
      [this.tags.EMPLOYEE]: {
        template: this.tags.EMPLOYEE,
        nodeMenu: employeeMenu,
        subTreeConfig: {
          layout: OrgChart.layout.tree,
          collapse: {
            level: 1,
            allChildren: true,
          }
        }
      },
      [this.tags.EMPLOYEE_SELECTED]: {
        template: this.tags.EMPLOYEE,
        nodeMenu: employeeMenu,
        subTreeConfig: {
          layout: OrgChart.layout.tree,
          collapse: {
            level: 1,
            allChildren: true,
          }
        }
      },
      [this.tags.ASSISTANT]: {
        template: this.tags.ASSISTANT,
        subTreeConfig: {
          layout: OrgChart.layout.tree,
          collapse: {
            level: 1,
            allChildren: true
          }
        },
      },
      [this.tags.ORGANIZATION]: {
        template: this.tags.ORGANIZATION,
        nodeMenu: organizationMenu,
        subTreeConfig: {
          collapse: {
            level: 1,
          }
        }
      },
      [this.tags.ORGANIZATION_SELECTED]: {
        template: this.tags.ORGANIZATION_SELECTED,
        nodeMenu: organizationMenu,
        subTreeConfig: {
          collapse: {
            level: 1,
          }
        }
      },
      filter: {
        template: 'dot'
      }
    }
  }

}
