<h5 mat-dialog-title>Edit Localization for Hubbub Field Name: {{formField.formFieldLabel}}</h5>

<mat-dialog-content [formGroup]="form">
  
    <mat-form-field>
        <input matInput
                placeholder="Form Field Value"
               formControlName="formFieldLabel">
    </mat-form-field>
 
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button class="submit-button" (click)="save()">Save</button>
</mat-dialog-actions>