<h5 mat-dialog-title appLocalizationPopupDirective [localizationCode]="'ToilRequest'">
    {{ 'ToilRequest' | translate: {Default: "Time Off In Lieu Request"} }}
</h5>

<mat-dialog-content>
    <div class="top">
        <div class="requested-on">
            <span class="label" appLocalizationPopupDirective [localizationCode]="'RequestedOn'">{{ 'RequestedOn' | translate: {Default: "Requested On"} }}:</span> {{employeeToilRequest.createdOn | date:'medium'}}
        </div>
        <div class="approval">
            <div class="approved-by">
                <div class="label" appLocalizationPopupDirective [localizationCode]="'LeaveRequestRequestedBy'">{{ 'LeaveRequestRequestedBy' | translate: {Default: "Requested By"} }}: </div>
                <app-employee-image class="profile-pic" round="true" width="35" height="35" [employeeId]="employeeToilRequest.createdBy.id"></app-employee-image>
                <div>{{employeeToilRequest.createdBy.firstName}} {{employeeToilRequest.createdBy.lastName}}</div>
            </div>

            <mat-chip 
                [ngClass]="{
                    'red-chip': employeeToilRequest.requestStatus?.id === 'trs_Denied',
                    'green-chip': employeeToilRequest.requestStatus?.id === 'trs_Approved',
                    'orange-chip': employeeToilRequest.requestStatus?.id === 'trs_Pending'
                }"
            >{{ employeeToilRequest.requestStatus?.name }}</mat-chip>
        </div>
    </div>
    <div class="bottom">
        <h6>{{employeeToilRequest.toilPolicy?.toilType?.name}} - {{employeeToilRequest.toilPolicy?.name}}</h6>

        <mat-chip 
            [ngClass]="{
                'blue-chip': employeeToilRequest.toilClassType?.id === 'AccrueToil',
                'yellow-chip': employeeToilRequest.toilClassType?.id === 'RequestToil'
            }"
        >{{ employeeToilRequest.toilClassType?.name }}</mat-chip>

        <h6 class="dates">
            {{employeeToilRequest.startDate | date:'mediumDate'}} - {{employeeToilRequest.endDate | date:'mediumDate'}}
            <mat-chip-list>
                <mat-chip class="green-chip" *ngIf="employeeToilRequest.halfDay === true" appLocalizationPopupDirective [localizationCode]="'LeaveRequestHalfDay'">{{ 'LeaveRequestHalfDay' | translate: {Default: "Half Day"} }}</mat-chip>
            </mat-chip-list>
        </h6>
        <h6 *ngIf="!loadingWorkingHours && workingHours">
            {{workingHours}} working hours
        </h6>

        <h6 *ngIf="employeeToilRequest.hours">{{ employeeToilRequest.hours }} hours</h6>
        <h6 *ngIf="employeeToilRequest.endTime && employeeToilRequest.endTime">{{ formatTime(employeeToilRequest.startTime) }} - {{ formatTime(employeeToilRequest.endTime) }}</h6>

        <h6 class="comments">
            {{employeeToilRequest.comments}}
        </h6>
    </div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!showDenialComment">
    <button mat-stroked-button class="close" (click)="close()" appLocalizationPopupDirective [localizationCode]="'Close'">{{ 'Close' | translate: {Default: "Close"} }}</button>
    <button mat-raised-button color="warn" (click)="openDenialCommentsDialog()" appLocalizationPopupDirective [localizationCode]="'Deny'">
        <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">cancel</mat-icon>
        {{ 'Deny' | translate: {Default: "Deny"} }}
    </button>
    <button mat-raised-button class="approve" (click)="approve()" appLocalizationPopupDirective [localizationCode]="'Approve'">
        <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">task_alt</mat-icon>
        {{ 'Approve' | translate: {Default: "Approve"} }}
    </button>
</mat-dialog-actions>
<mat-dialog-actions *ngIf="showDenialComment">
    <button mat-stroked-button class="close" (click)="closeDenialCommentsDialog()" appLocalizationPopupDirective [localizationCode]="'Cancel'">{{ 'Cancel' | translate: {Default: "Cancel"} }}</button>
    <button mat-raised-button color="warn" (click)="denyFromDialog()" appLocalizationPopupDirective [localizationCode]="'Deny'">
        <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">cancel</mat-icon>
        {{ 'Deny' | translate: {Default: "Deny"} }}
    </button>
</mat-dialog-actions>
