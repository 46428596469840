import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { api_routes } from '@app/consts';
import { EnvironmentService } from "@app/core/services/environment.service";
import { CompanyLogo, CompanyLogoSubmit } from '../models/company-logos.model';


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});
@Injectable({
  providedIn: 'root'
})
export class CompanyLogosService {
  activeCompanyLogo: CompanyLogo;

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  syncActiveCompanyLogo(callback: () => any) {
    return this.http.get(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.SITE_SETTINGS}/${api_routes.COMPANY_LOGOS}/${api_routes.CURRENT_USER}`, {
      responseType: 'text' 
    }).pipe(
      catchError( callback() )
    ).subscribe(res => {
      this.activeCompanyLogo = res;
      callback();
    });
  }

  getActiveCompanyLogo() {
    return this.activeCompanyLogo;
  }

  getCompanyLogos(skip?: number, take?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<CompanyLogo>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.SITE_SETTINGS}/${api_routes.COMPANY_LOGOS}`, {
        headers: headers,
        params: params
    });
  }

  getCompanyLogo(id: string): Observable<CompanyLogo> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.SITE_SETTINGS}/${api_routes.COMPANY_LOGOS}/${id}`, {
      headers: headers
    });
  }

  getCompanyLogoImage(id: string): Observable<any> {
    return this.http.get(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.SITE_SETTINGS}/${api_routes.COMPANY_LOGOS}/${id}/${api_routes.LOGO}`, 
      { responseType: 'text' }
    );
  }

  getCurrentUserCompanyLogo(): Observable<any> {
    return this.http.get(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.SITE_SETTINGS}/${api_routes.COMPANY_LOGOS}/${api_routes.CURRENT_USER}`, 
      { responseType: 'text' }
    );
  }

  putCompanyLogoImage(id: string, companyLogo): Observable<any> {
    const formData = new FormData();

    formData.append('file', companyLogo.file);

    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.SITE_SETTINGS}/${api_routes.COMPANY_LOGOS}/${id}/${api_routes.LOGO}`, formData);
  }

  postCompanyLogo(companyLogo: CompanyLogoSubmit): Observable<any> {
    const formData = new FormData();

    formData.append('data', JSON.stringify(companyLogo));
    formData.append('file', companyLogo.file);

    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.SITE_SETTINGS}/${api_routes.COMPANY_LOGOS}`, formData);
  }

  putCompanyLogoDetails(id: string, companyLogo: CompanyLogoSubmit): Observable<any> {
    // const formData = new FormData();

    // formData.append('data', JSON.stringify(companyLogo));
    // formData.append('file', companyLogo.file);

    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.SITE_SETTINGS}/${api_routes.COMPANY_LOGOS}/${id}`, companyLogo, {
      headers: headers
    });
  }

  deleteCompanyLogo(id: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.SITE_SETTINGS}/${api_routes.COMPANY_LOGOS}/${id}`, {
      headers: headers
    });
  }
}
