import { Component, OnInit, ViewChild } from '@angular/core';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-terminations-by-month',
  templateUrl: './terminations-by-month.component.html',
  styleUrls: ['./terminations-by-month.component.scss']
})
export class TerminationsByMonthComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  terminations = [
    {
      'month': 'January',
      'terminations': '1',
    },
    {
      'month': 'February',
      'terminations': '4',
    },
    {
      'month': 'March',
      'terminations': '3',
    },
    {
      'month': 'April',
      'terminations': '5',
    },
    {
      'month': 'May',
      'terminations': '4',
    },
    {
      'month': 'June',
      'terminations': '6',
    },
    {
      'month': 'July',
      'terminations': '6',
    },
    {
      'month': 'August',
      'terminations': '9',
    },
    {
      'month': 'September',
      'terminations': '14',
    }
  ];

  tableColumns = [
    { name: 'Month', dataKey: 'month', isSortable: true, position: 'left' },
    { name: 'Terminations', dataKey: 'terminations', isSortable: true, position: 'left' },
  ];

  constructor() { 
    this.chartOptions = {
      series: [
        {
          name: "Terminations",
          data: [1, 4, 3, 5, 4, 6, 6, 9, 14]
        }
      ],
      chart: {
        height: 350,
        width: 600,
        type: "bar"
      },
      xaxis: {
        categories: ["Jan", "Feb",  "Mar",  "Apr",  "May",  "Jun",  "Jul",  "Aug", "Sep"]
      }
    };
  }

  ngOnInit(): void {
  }

}
