import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { api_routes, db_tables, routes } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { ReviewPlansService } from './services/review-plans.service';
import { defer, finalize, forkJoin } from 'rxjs';
import { ReviewPlan } from './models/review-plan.model';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';

@Component({
  selector: 'app-review-plans',
  templateUrl: './review-plans.component.html',
  styleUrls: ['./review-plans.component.scss']
})
export class ReviewPlansComponent implements OnInit {
  requireEmployeeWeightEntry: boolean
    enforceFullWeightAcrossGoalPlans: boolean
    setWeightByGoalType: boolean

  public columns: any[] = [
    { field: "name", title: "Name", tableId: "tfi_RpName" },
    { field: "description", title: "description", tableId: "tfi_RpDescription", type: "longText", longTextLimit: 200 },
    { field: "startDate", title: "start Date", tableId: "tfi_RpStartDate", type: "date" },
    { field: "endDate", title: "end Date", tableId: "tfi_RpEndDate", type: "date" },
    { field: "finalReview", title: "final Review", tableId: "tfi_RpFinalReview", type: "booleanToggle" },
    { field: "setOverallRating", title: "set Overall Rating", tableId: "tfi_RpSetOverallRating", type: "booleanToggle" },
    { field: "requireEmployeeWeightEntry", title: "require Employee Weight Entry", tableId: "tfi_RpRequireEmployeeWeightEntry", type: "booleanToggle" },
    { field: "enforceFullWeightAcrossGoalPlans", title: "enforce Full Weight Across Goal Plans", tableId: "tfi_RpEnforceFullWeightAcrossGoalPlans", type: "booleanToggle" },
    { field: "setWeightByGoalType", title: "set Weight By Goal Type", tableId: "tfi_RpSetWeightByGoalType", type: "booleanToggle" },
  ];

  filterCategories: any[] = [
    { field: "name", title: "Name", tableId: "tfi_RpName", type: "String", dataType: 'String' },
    { field: "description", title: "description", tableId: "tfi_RpDescription", type: "String", dataType: 'String' },
    { field: "startDate", title: "start Date", tableId: "tfi_RpStartDate", type: "date", dataType: 'Date' },
    { field: "endDate", title: "end Date", tableId: "tfi_RpEndDate", type: "date", dataType: 'Date' },
    { field: "finalReview", title: "final Review", tableId: "tfi_RpFinalReview", dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
    { field: "setOverallRating", title: "Set Overall Rating", tableId: "tfi_RpSetOverallRating", dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
    { field: "requireEmployeeWeightEntry", title: "Require Employee Weight Entry", tableId: "tfi_RpSetOverallRating", dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
    { field: "enforceFullWeightAcrossGoalPlans", title: "Enforce Full Weight Across Goal Plans", tableId: "tfi_RpEnforceFullWeightAcrossGoalPlans", dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
    { field: "setWeightByGoalType", title: "Set Weight By Goal Type", tableId: "tfi_RpSetWeightByGoalType", dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
  ];

  sortableColumns: any[] = [
    { field: "name", sortValue: "name"},
    { field: "description", sortValue: "description"},
    { field: "startDate", sortValue: "startDate"},
    { field: "endDate", sortValue: "endDate"},
    { field: "finalReview", sortValue: "finalReview"},
    { field: "setOverallRating", sortValue: "setOverallRating"},
    { field: "requireEmployeeWeightEntry", sortValue: "requireEmployeeWeightEntry"},
    { field: "enforceFullWeightAcrossGoalPlans", sortValue: "enforceFullWeightAcrossGoalPlans"},
    { field: "setWeightByGoalType", sortValue: "setWeightByGoalType"},
  ];

  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 10;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  formId: string = 'frm_6DUL8Zq9roVBT3'
  dialogRef: any;
  formValid: any;
  searchValue: string;
  searchFilterString: string;
  getGoalsTypesRequest: any;
  sortString: string;
  filterString: string;

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private overlayService: OverlayService,
    private router: Router,
    private reviewPlansService: ReviewPlansService
  ) {
  }

  ngOnInit(): void {
    this.view = this.getReviewPlans();
  }

  getReviewPlans() {
    let filter;
    if(this.searchFilterString) {
      filter = this.searchFilterString;
    }
    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }

    this.isLoading = true;

    this.getGoalsTypesRequest = this.reviewPlansService.getReviewPlans(String(this.pageSize), this.skip, this.sortString, filter)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          }
          this.gridData = res.data;
        }
      );
  }

  getReviewPlan(reviewPlan?: ReviewPlan) {
    if(reviewPlan === undefined){
      this.openNewReviewPlanDialog();
    }
    else {
      this.router.navigate([`${routes.PERFORMANCE}/${routes.REVIEW_PLANS}/Editor/${reviewPlan.id}`]);
    }
  }

  openNewReviewPlanDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formId: this.formId,
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      this.save(event)
    });
  }

  formDataEmitted(formData) {
    this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  save(formData: any){
    this.overlayService.show();

    if(formData.id === null){
      this.reviewPlansService.createReviewPlan(formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getReviewPlans();
        }
      );
    }
    else {
      this.reviewPlansService.updateReviewPlan(formData.id, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getReviewPlans();
        }
      );
    }
  }

  deleteReviewPlans(reviewPlanIds: string[]) {
    const observables = reviewPlanIds.map(selectedItem => defer(() => this.reviewPlansService.deleteReviewPlan(selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.view = this.getReviewPlans();
        this.clearSelectedItems = !this.clearSelectedItems;
        this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.view = this.getReviewPlans();
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;

    let variants = this.searchValue.split(' ').filter(value => value);
    this.searchFilterString = '';
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `(name like "${variant}")`;
        }
    });

    this.getGoalsTypesRequest.unsubscribe();
    this.getReviewPlans();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getReviewPlans();
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;;
    this.getReviewPlans();
  }

}
