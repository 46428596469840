import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// import { WorkLocation, WorkLocationSubmit, WorkLocationVerbose } from '../models/work-location.model';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { api_routes } from '@app/consts';
import { CostCenter, CostCenterVerbose } from '@app/modules/cost-centers/models/cost-center.model';
import { EnvironmentService } from "@app/core/services/environment.service";


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class CostCentersService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getCostCenters(skip?: number, take?: string, filterString?: string, sortString?: string, filterInactive?: string): Observable<PagedData<CostCenter>> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    filterString ? params = params.append('Filter', filterString) : null;
    sortString ? params = params.append('Sort', sortString) : null;
    filterInactive ? params = params.append('filterInactive', filterInactive) : null;

    return this.http
      .get<any>(`${this.envService.env.apiUrl}${api_routes.COST_CENTERS}`, {
        headers: headers,
        observe: 'response',
        params: params
      })
      .pipe(
        catchError(err => throwError(err)),
        map(res => {
          return res.body;
        })
      );
  }

  async getAllCostCenters(): Promise<PagedData<any>> {
    let take = 100;
    let skip = 0;
    let costCenters = {
      skip: null,
      take: null,
      totalPages: null,
      total: null,
      data: []
    }

    while (costCenters.total === null || skip < costCenters.total) {
        const res = await this.getCostCenters(skip, take.toString(), null, 'name-asc', 'false').toPromise();

        if (res && res.data && res.data.length > 0) {
          costCenters.data = costCenters.data.concat(res.data);
            skip += take;
            costCenters.total = res.total;
        } else {
            break;
        }
    }

    return costCenters;
  }

  getCostCenterById(costCenterId: string): Observable<CostCenterVerbose> {

    return this.http
      .get<any>(`${this.envService.env.apiUrl}${api_routes.COST_CENTERS}/${costCenterId}`, {
        headers: headers,
        observe: 'response',
      })
      .pipe(
        catchError(err => throwError(err)),
        map(res => {
          return res.body;
        })
      );
  }

  deleteCostCenter(workLocationId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.COST_CENTERS}/${workLocationId}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }



  createCostCenter(costCenter: CostCenterVerbose): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.COST_CENTERS}`, costCenter, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  updateCostCenter(costCenter: CostCenterVerbose): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.COST_CENTERS}/${costCenter.id}`, costCenter, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

}
