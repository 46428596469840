<h5 mat-dialog-title>{{ 'SelectAGoalType' | translate: {Default: "Select A Goal Type"} }}</h5>

<mat-dialog-content>
    <app-bootstrap-alert 
        *ngIf="selectedGoalType?.status?.id === 'Archived'"
        [text]="translate.instant('InactiveGoalTypeSelectedWarning')" 
        localizationCode="InactiveGoalTypeSelectedWarning" 
        type="danger">
    </app-bootstrap-alert>

    <div *ngIf="selectedGoalType" class="selected-goal-type page-description-section">
        <h6>{{ 'SelectedGoalType' | translate: {Default: "Selected Goal Type"} }}: {{selectedGoalType.name}}</h6>
    </div>

    <app-data-grid-comp
        [tableId]="db_tables.GoalTypes"
        [columns]="columns"
        [filterCategories]="filterCategories"
        [view]="view"
        [gridDataResult]="gridDataResult"
        [isLoading]="isLoading"
        [pageSize]="pageSize"
        [skip]="skip"
        [rowSelectionSettings]="{
            canSelectRows: true,
            selectRowBy: 'id',
            showDeleteAllButton: false,
            selectableMode: 'single'
        }"
        searchable="true"
        hideActionButtons="true"
        [sortable]="{
            mode: 'multiple'
        }"
        [sortableColumns]="sortableColumns"
        (emitSortEvent)="sortChange($event)"
        [isLoading]="isLoading"
        (emitPageChangeEvent)="pageChange($event)"
        (emitSearchEvent)="search($event)"
        (emitFilterEvent)="filterCallback($event)"
        (emitSortEvent)="sortChange($event)"
        (emitSelectedRows)="selectedRowsChanged($event)"
    ></app-data-grid-comp>
    
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!selectedGoalType">{{ 'Save' | translate: {Default: "Save"} }}</button>
</mat-dialog-actions>