import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { TableColumn } from '../table/table-column.model';
import { DatePipe } from '@angular/common';
import { Regex } from '@app/consts/index';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-expansion-table',
  templateUrl: './expansion-table.component.html',
  styleUrls: ['./expansion-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ExpansionTableComponent implements OnInit {
  public tableDataSource = new MatTableDataSource([]);
  public displayedColumns: string[];
  public btnsColumn: string = "btns-column";
  rowActionIcon = ['create', 'delete'];
  
  @Input() tableColumns: string[];
  @Input() addressesColumns;
  @Input() emailsColumns;
  @Input() expansionData: Observable<any>;
  @Input() dataForTableExpansions: Object[];
  @Input() set tableData(data: any[]) {
    this.changeDateFormat(data);
    this.setTableDataSource(data);
  }

  @Output() rowAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() addNew: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.displayedColumns = [this.btnsColumn, ...this.tableColumns]
  }

  //If data is returned as 1991-02-06T00:00:00 or 2021-05-18T20:31:01.5134269+00:00 it will be converted to standard angular date pipe
  changeDateFormat(data) {
    const regex = Regex.DATE_TIME;
    let pipe = new DatePipe('en-US');

    data.forEach(element => {
      for (let key of Object.keys(element)) {
        // if(Array.isArray(element[key]) == false && typeof element[key] != "boolean" && element[key] !== null && element[key].match(regex)){
        if(typeof element[key] === 'string' && element[key].match(regex)){
          element[key] = pipe.transform(element[key]);
        }
      }
    });
  }

  setTableDataSource(data: any) {
    this.tableDataSource = new MatTableDataSource<any>(data);
  }

  emitRowAction(row: any, icon: any) {
    this.rowAction.emit({row, icon});
  }

  rowEvent(event: any) {
    this.rowAction.emit(event);
  }

  emitAddNew(label: string, parentId: string) {
    let event = {
      label: label,
      parentId: parentId
    }
    this.addNew.emit(event);
  }

}
