import { Component, OnInit } from '@angular/core';
import { CultureConfigurationService } from './services/culture-configuration.service';
import { finalize } from 'rxjs';
import { CurrentUserDetails } from '@app/shared/models/employee.model';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { EmployeeService } from '@app/core/services/employee.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-culture-configuration',
  templateUrl: './culture-configuration.component.html',
  styleUrls: ['./culture-configuration.component.scss']
})
export class CultureConfigurationComponent implements OnInit {
  formId: string = "frm_1OKrx84BJVXELo";
  formData: any;
  getFormData: boolean = false;
  formValid: boolean = false;
  formPristine: boolean;
  currentEmployeeDetails: CurrentUserDetails;

  constructor(
    private cultureConfigurationService: CultureConfigurationService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private translate: TranslateService,
    private employeeService: EmployeeService,
    private router: Router,
  ) {
    this.currentEmployeeDetails = this.employeeService.getFetchedCurrentUserDetails();
    
    if(this.currentEmployeeDetails.userType !== 'User'){
      this.router.navigate(['Homepage'])
    }
  }

  ngOnInit(): void {
    this.getCultureConfiguration();
  }

  getCultureConfiguration() {
    this.cultureConfigurationService.getCultureConfiguration()
    .pipe(
      finalize(() => {})
    )
    .subscribe(
      (res) => {
        // Convert response to form data, reverse boolean value because the backend returned "disabled" but the frontend wants to reflect "enabled"
        this.formData = res.reduce((acc, item) => {
                                      acc[item.name] = !item.disabled;
                                      return acc;
                                    }, {});
      }
    );
  }

  formDataEmitted(formData) {
    this.save(formData);
  }

  formPristineEmitted(formPristine: boolean) {
    this.formPristine = formPristine;
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  save(formData) {
    // Convert formdata to submit format, reverse boolean value because the backend returned "disabled" but the frontend wants to reflect "enabled"
    const convertedData = Object.keys(formData).map(name => ({
      name,
      disabled: !formData[name]
    }));

    this.overlayService.show();

    this.cultureConfigurationService.saveCultureConfiguration(convertedData)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

}
