import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-table-dialog',
  templateUrl: './table-dialog.component.html',
  styleUrls: ['./table-dialog.component.scss']
})
export class TableDialogComponent implements OnInit {
  title: string;
  displayedColumns: string[];
  dataSource;

  constructor(
    private dialogRef: MatDialogRef<TableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) 
  {
    this.title = data.title;
    this.displayedColumns = data.displayedColumns;
    this.dataSource = data.dataSource;
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

}

  

