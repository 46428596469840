import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { Organization, OrganizationSubmit, OrganizationTypeVerbose, OrganizationVerbose } from '@app/modules/organization-structure/models/organization-structure.model';
import { EnvironmentService } from "@app/core/services/environment.service";


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class OrganizationStructureService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getOrganizations(skip?: number, take?: string, filterString?: string, sort?: string, filterInactive?: string): Observable<PagedData<Organization>> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    filterString ? params = params.append('Filter', filterString) : null;
    sort ? params = params.append('sort', sort) : null;
    filterInactive ? params = params.append('filterInactive', filterInactive) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}Organization/Units`, {
        headers: headers,
        observe: 'response',
        params: params
      })
      .pipe(
        catchError(err => throwError(err)),
        map(res => {
          return res.body;
        })
      );
  }

  getOrganizationTypes(skip?: number, take?: string): Observable<PagedData<Organization>> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : 0;
    take ? params = params.append('take', take) : 100;

    return this.http.get<any>(`${this.envService.env.apiUrl}Organization/Types`, {
      headers: headers,
      params: params
    })
  }

  async getAllOrganizations(): Promise<PagedData<any>> {
    let take = 100;
    let skip = 0;
    let organizations = {
      skip: null,
      take: null,
      totalPages: null,
      total: null,
      data: []
    }

    while (organizations.total === null || skip < organizations.total) {
        const res = await this.getOrganizations(skip, take.toString()).toPromise();

        if (res && res.data && res.data.length > 0) {
          organizations.data = organizations.data.concat(res.data);
          skip += take;
          organizations.total = res.total;
        } else {
            break;
        }
    }

    return organizations;
  }

  createOrganizationType(newOrganizationType: OrganizationTypeVerbose): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}Organization/Types/`, newOrganizationType, {
      headers: headers,
    });
  }

  deleteOrganizationType(typeId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}Organization/Types/${typeId}`, {
      headers: headers,
    });
  }

  getOrganization(holidayGroupId: string): Observable<OrganizationVerbose> {
    return this.http.get<any>(`${this.envService.env.apiUrl}Organization/Units/${holidayGroupId}`, {
      headers: headers,
    });
  }

  createOrganization(newOrganization: OrganizationSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}Organization/Units`, newOrganization, {
      headers: headers,
    });
  }

  updateOrganization(organizationId: string, updatedOrganization: OrganizationSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}Organization/Units/${organizationId}`, updatedOrganization, {
      headers: headers,
    });
  }

  deleteOrganization(organizationId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}Organization/Units/${organizationId}`, {
      headers: headers,
    });
  }

}
