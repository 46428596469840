import { Component, OnInit } from '@angular/core';
import { routes } from '@app/consts';
import { EmployeeService } from '@app/core/services/employee.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import * as moment from 'moment';

@Component({
  selector: 'app-tasks-widget',
  templateUrl: './tasks-widget.component.html',
  styleUrls: ['./tasks-widget.component.scss']
})
export class TasksWidgetComponent implements OnInit {
  employees: any[] = []; 
  loading: boolean = true;
  public routes: typeof routes = routes;
  birthdayTimePeriodSearchValue: string = "thisMonth";

  taskOptions = [
    {
      task: 'General',
      description: 'Fill the personal details',
    },
    {
      task: 'Sign Document',
      description: 'Confirm and sign company time-off-policy details',
    },
    {
      task: 'Read Document',
      description: 'Read general company document',
    },
  ];

  constructor(
    private employeeService: EmployeeService,
    private snackbarService: SnackbarService,
  ) { }

  ngOnInit(): void {
    this.getEmployees();
  }

  getEmployees() {
    this.employeeService.getEmployees()
    .subscribe(
      res => {
        this.employees = res.employees;

        this.employees.forEach(
          employee => {
            employee.task = this.taskOptions[Math.floor(Math.random()*this.taskOptions.length)];
            employee.taskDueDate = moment().add(Math.floor(Math.random() * 14),'d').format('YYYY-MM-DD');
          }
        )

        this.employees = this.shuffleArray(this.employees);
        this.loading = false;
      }
    )
  }

  shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {

        // Generate random number
        var j = Math.floor(Math.random() * (i + 1));

        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }

    return array;
}
}
