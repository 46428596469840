<div class="snack-container">
    <div class="icon-div">
        <span class="material-icons-outlined">
            {{getIcon}}
        </span>
    </div>
    <div class="message-div">

        <ng-container *ngIf="data.snackType === 'error' || data.snackType === 'warn'; else noError">
            <mat-accordion>
                <mat-expansion-panel [expanded]="panelOpenState" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-description>
                            {{ 'ErrorAlertMessage' | translate: {Default: "Oops! Something went wrong"} }}
                        </mat-panel-description>
                    </mat-expansion-panel-header>
    
                    <div class="error-div">
                        <div class="header" *ngIf="data.snackType === 'error'">
                            {{ 'ErrorAlertErrorDetails' | translate: {Default: "Error Details"} }}
                        </div>
                        {{data.message}}
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-container>

        <ng-template #noError>
            {{data.message}}
        </ng-template>

    </div>
    <div class="clear-div">
        <span (click)="dismiss()" class="dismiss material-icons-outlined">
            clear
        </span>
    </div>
</div>