<h5 mat-dialog-title>
    {{ 'ToilRequest' | translate: {Default: "Time Off In Lieu Request"} }}
</h5>

<mat-dialog-content>
    <div class="top">
        
        <div class="status">
            <span class="label">{{ 'Status' | translate: {Default: "Status"} }}:</span>

                <mat-chip 
                [ngClass]="{
                    'red-chip': employeeToilRequest.requestStatus?.id === 'trs_Denied',
                    'green-chip': employeeToilRequest.requestStatus?.id === 'trs_Approved',
                    'orange-chip': employeeToilRequest.requestStatus?.id === 'trs_Pending'
                }"
                >{{ employeeToilRequest.requestStatus?.name }}</mat-chip>
            
            <div class="requested-on">
                <span class="label">{{ 'RequestedOn' | translate: {Default: "Requested On"} }}:</span> {{ moment(employeeToilRequest.createdOn).format('lll') }}
            </div>

            <div class="status-updated-by" *ngIf="employeeToilRequest.requestStatusSetBy !== null">
                <span class="label">{{ 'RequestStatusSetBy' | translate: {Default: "Request Status Set By"} }}:</span>
                
                <div class="employee-name">
                    <app-employee-image [employeeId]="employeeToilRequest.requestStatusSetBy?.id" [width]="35" [height]="35" [round]="true" *ngIf="!isLoading"></app-employee-image>

                    <span class="employee">
                        {{employeeToilRequest.requestStatusSetBy?.firstName}} {{employeeToilRequest.requestStatusSetBy?.lastName}}
                    </span>
                </div>
            </div>

            <div class="status-date" *ngIf="employeeToilRequest.requestStatusUpdatedOn !== null">
                <span class="label">{{ 'RequestStatusUpdatedOn' | translate: {Default: "Request Status Updated On"} }}:</span>

                {{ moment(employeeToilRequest.requestStatusUpdatedOn).format('lll') }}
            </div>

            <div class="status-date" *ngIf="employeeToilRequest.requestStatusComments">
                <div class="label">{{ 'StatusComments' | translate: {Default: "Status Comments "} }}: </div>
                {{employeeToilRequest.requestStatusComments}}
            </div>
        </div>
        
        <div class="approval">
            <div class="approved-by">
                <span class="label">{{ 'Approvers' | translate: {Default: "Approvers"} }}:</span>

                <ng-container *ngIf="employeeToilRequest.managers.length > 1">
                    <mat-icon class="material-icons-outlined employee-list-icon" [matBadge]="employeeToilRequest.managers.length" [matTooltip]="showEmployeeTooltip(employeeToilRequest.managers)">
                        account_circle
                    </mat-icon>
                </ng-container>
    
                <ng-container *ngIf="employeeToilRequest.managers.length == 1">
                    <ng-container *ngFor="let employee of employeeToilRequest.managers">
                        <div class="employee-name">
                            <app-employee-image [employeeId]="employee.id" [width]="35" [height]="35" [round]="true" *ngIf="!isLoading"></app-employee-image>
    
                            <span class="employee">
                                {{employee.firstName}} {{employee.lastName}}
                            </span>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>

    </div>
    <div class="bottom">
        <div class="employee-name">
            <app-employee-image [employeeId]="employeeToilRequest.employee.id" [width]="35" [height]="35" [round]="true"></app-employee-image>

            <h6 class="employee">
                {{employeeToilRequest.employee.firstName}} {{employeeToilRequest.employee.lastName}}
            </h6>
        </div>

        <h6>{{employeeToilRequest.toilPolicy?.toilType?.name}} - {{employeeToilRequest.toilPolicy?.name}}</h6>

        <h6 class="dates">
            {{ moment(employeeToilRequest.startDate).format('ll') }} - {{ moment(employeeToilRequest.endDate).format('ll') }}
            <mat-chip class="green-chip" *ngIf="employeeToilRequest.halfDay === true">{{ 'LeaveRequestHalfDay' | translate: {Default: "Half Day"} }}</mat-chip>
        </h6>

        <h6 *ngIf="employeeToilRequest.endTime && employeeToilRequest.endTime">{{ formatTime(employeeToilRequest.startTime) }} - {{ formatTime(employeeToilRequest.endTime) }}</h6>
        <h6 *ngIf="!loadingWorkingHours && workingHours">{{workingHours}} working hours</h6>
        <h6 *ngIf="employeeToilRequest.hours">{{ employeeToilRequest.hours }} hours</h6>
        
        <h6 class="comments">
            {{employeeToilRequest.comments}}
        </h6>

        <mat-chip 
            [ngClass]="{
                'blue-chip': employeeToilRequest.toilClassType?.id === 'AccrueToil',
                'yellow-chip': employeeToilRequest.toilClassType?.id === 'RequestToil'
            }"
        >{{ employeeToilRequest.toilClassType?.name }}</mat-chip>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button class="close" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
</mat-dialog-actions>
