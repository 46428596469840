import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {ReportBuilderComponent} from '@app/modules/custom-reports/components/report-builder/report-builder.component';

@Injectable({ providedIn: 'root' })

export class UnsavedGuard implements CanDeactivate<ReportBuilderComponent> {
    canDeactivate(
        component: any,
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        if (component?.isSavingChanges() === true){
            component.resetSavingFlag();
            return of(true);
        }
        if (component?.isUnsavedChanges() === true){
            return component.openConfirmCloseDialog();
        }
        else {
            return of(true);
        }
    }
}
