<app-site-settings-menu>
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
            <h5 appLocalizationPopupDirective localizationCode="GenerateSchedule">{{ 'GenerateSchedule' | translate: {Default: "Generate Schedule"} }}</h5>
        </mat-card-title>
        <mat-card-content>
            <app-jobs-queue></app-jobs-queue>
        </mat-card-content>
    </mat-card>
</app-site-settings-menu>
