import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '@app/shared/components/snackbar/snackbar.component';


@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackBar:MatSnackBar) { }

  public openSnackBar(message: string, action: string, snackType?: string, dontClose?: boolean, panelOpenState?: boolean) {
    let durationTime

    if(dontClose) {
      durationTime = undefined;
    }
    else {
      durationTime = snackType == 'error' ? null : 5000;
    }
     
    snackType = snackType !== undefined ? snackType : 'Success';

    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: durationTime,
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      data: { message: message, snackType: snackType, panelOpenState: panelOpenState },
      panelClass: [ `${snackType}-snackbar` ]
    });
  } 
}
