<h5 mat-dialog-title>Employment Record: Grievance</h5>

<mat-dialog-content>
    <form class="form-container" *ngIf="form" [formGroup]="form">
        <mat-form-field appearance="fill">
            <mat-label>
                Type
            </mat-label>

            <mat-select disableOptionCentering formControlName="type">
                <mat-option *ngFor="let typeOption of typeOptions | async" [value]="typeOption.id">{{typeOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Filed By
            </mat-label>

            <mat-select disableOptionCentering formControlName="filedBy">
                <mat-option *ngFor="let filedByOption of filedByOptions | async" [value]="filedByOption.id">{{filedByOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Status
            </mat-label>

            <mat-select disableOptionCentering formControlName="status">
                <mat-option *ngFor="let statusOption of statusOptions | async" [value]="statusOption.id">{{statusOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Final Resolution
            </mat-label>

            <mat-select disableOptionCentering formControlName="finalResolution">
                <mat-option *ngFor="let finalResolutionOption of finalResolutionOptions | async" [value]="finalResolutionOption.id">{{finalResolutionOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field  appearance="fill">
            <mat-label>
                Date
            </mat-label>
        
            <input matInput [matDatepicker]="datepicker" formControlName="date">
            <span fxLayout="row" matSuffix>
                <mat-datepicker-toggle  [for]="datepicker"></mat-datepicker-toggle>
            </span>
            <mat-datepicker #datepicker></mat-datepicker> 
            <mat-hint></mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Short Description
            </mat-label>
        
            <input matInput type="text" formControlName="shortDescription">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Description
            </mat-label>
        
            <input matInput type="text" formControlName="description">
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button> 
</mat-dialog-actions>