import { CurrencyPipe, DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api_routes } from '@app/consts';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { BasicEmployeeRecord } from '../models/basic-employee-report.model';
import { EnvironmentService } from "@app/core/services/environment.service";


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class BasicEmployeeReportService {

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private overlayService: OverlayService,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private envService: EnvironmentService
  ) { }

  getBasicEmployeeReport(skip?: number, take?: string, filterString?: string, sortString?: string): Observable<PagedData<BasicEmployeeRecord>> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    filterString ? params = params.append('Filter', filterString) : null;
    sortString ? params = params.append('Sort', sortString) : null;

    return this.http
      .get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${api_routes.REPORTS}/${api_routes.BASIC_EMPLOYEES}`, {
        headers: headers,
        params: params
      })
      .pipe(
        catchError(err => throwError(err)),
        map(res => {
          return res;
        })
      );
  }

  public fetch(state: State, filterString: string, sortString: string, showOverlay?: boolean, formatData?: boolean, filterInactive?: boolean): Observable<GridDataResult> {
    let params = new HttpParams();
    state.skip ? params = params.append('skip', state.skip.toString()) : null;
    state.take ? params = params.append('take', state.take.toString()) : null;
    filterString ? params = params.append('Filter', filterString) : null;
    sortString ? params = params.append('Sort', sortString) : null;
    filterInactive ? params = params.append('FilterInactive', filterInactive.toString()) : false;

    if(showOverlay){
      this.overlayService.show(this.translate.instant('FetchingData'));
    }

    return this.http.get(`${this.envService.env.apiUrl}${api_routes.TALENT}/${api_routes.REPORTS}/${api_routes.BASIC_EMPLOYEES}`, {
      headers: headers,
      params: params
    })
    .pipe(
      finalize( () => showOverlay ? this.overlayService.hide() : null ),
      map(
        (response) =>
          <GridDataResult>{
            data: formatData ? this.formatData(response['data']) : response['data'],
            total: parseInt(response["total"], 10),
          }
      )
    );
  }


  public async queryAll(st: State, filterString?: string, sortString?: string, filterInactive?: boolean): Promise<GridDataResult> {
    const state = Object.assign({}, st);
    state.skip = 0;
    state.take = 100;

    let dataToReturn: GridDataResult = {
      data: [],
      total: null
    };

    while(dataToReturn.total === null || state.skip < dataToReturn.total) {
      const res = await this.fetch(state, filterString, sortString, true, true, filterInactive).toPromise();

      if (res && res.data && res.data.length > 0) {
        dataToReturn.data = dataToReturn.data.concat(res.data);
        state.skip += state.take;
        dataToReturn.total = res.total;
      } else {
          break;
      }
    }

    return dataToReturn
  }

  formatData(data: any) {

      let result: any[] = [];

      data.forEach((element, index) => {

          // Create a new entry with a position object for each position an employee has
          element.positions?.forEach( (position, i) => {
                  let employeeCopy = JSON.parse(JSON.stringify(element));
                  if (i === 0){
                      employeeCopy.position = position;
                      result.push(this.transformValue(employeeCopy));
                  }
                  else {
                      employeeCopy.position = position;
                      employeeCopy.positions = null;
                      result.push(this.transformValue(employeeCopy));
                  }
              }
          );
      });

      return result;
  }

  transformValue(element: any) {

      if(element?.position?.startDate) {
          element.position.startDate = this.datePipe.transform(element.position.startDate, 'longDate');
      }
      if(element?.position?.udd1) {
          element.position.udd1 = this.datePipe.transform(element.position.udd1, 'longDate');
      }
      if(element?.position?.fixedTermEndDate) {
          element.position.fixedTermEndDate = this.datePipe.transform(element.position.fixedTermEndDate, 'longDate');
      }

      // format base salary to include currency
      if(element?.compensation?.baseSalary && element?.compensation?.payRateCurrency?.id) {
          element.compensation.baseSalary =  this.currencyPipe.transform( element.compensation.baseSalary, element.compensation.payRateCurrency.id )
      }
      // format baseSalaryFteAdjusted to include currency
      if(element?.compensation?.baseSalaryFteAdjusted && element?.compensation?.payRateCurrency?.id) {
          element.compensation.baseSalaryFteAdjusted =  this.currencyPipe.transform( element.compensation.baseSalaryFteAdjusted, element.compensation.payRateCurrency.id )
      }
      // format salaryOther to include currency
      if(element?.compensation?.salaryOther && element?.compensation?.salaryOtherCurrency?.id) {
          element.compensation.salaryOther =  this.currencyPipe.transform( element.compensation.salaryOther, element.compensation.salaryOtherCurrency.id )
      }
      // format salaryOtherFteAdjusted to include currency
      if(element?.compensation?.salaryOtherFteAdjusted && element?.compensation?.salaryOtherCurrency?.id) {
          element.compensation.salaryOtherFteAdjusted =  this.currencyPipe.transform( element.compensation.salaryOtherFteAdjusted, element.compensation.salaryOtherCurrency.id )
      }
      // format salaryNonGmg to include currency
      if(element?.compensation?.salaryNonGmg && element?.compensation?.salaryNonGmgCurrency?.id) {
          element.compensation.salaryNonGmg =  this.currencyPipe.transform( element.compensation.salaryNonGmg, element.compensation.salaryNonGmgCurrency.id )
      }
      // format salaryNonGmgFteAdjusted to include currency
      if(element?.compensation?.salaryNonGmgFteAdjusted && element?.compensation?.salaryNonGmgCurrency?.id) {
          element.compensation.salaryNonGmgFteAdjusted =  this.currencyPipe.transform( element.compensation.salaryNonGmgFteAdjusted, element.compensation.salaryNonGmgCurrency.id )
      }
      // format salarySystem to include currency
      if(element?.compensation?.salarySystem && element?.compensation?.salarySystemCurrency?.id) {
          element.compensation.salarySystem =  this.currencyPipe.transform( element.compensation.salarySystem, element.compensation.salarySystemCurrency.id )
      }
      // format salarySystemFteAdjusted to include currency
      if(element?.compensation?.salarySystemFteAdjusted && element?.compensation?.salarySystemCurrency?.id) {
          element.compensation.salarySystemFteAdjusted =  this.currencyPipe.transform( element.compensation.salarySystemFteAdjusted, element.compensation.salarySystemCurrency.id )
      }

      if(element?.employee?.birthday) {
          element.employee.birthday = this.datePipe.transform(element.employee.birthday, 'longDate');
      }
      if(element?.employee?.nextBirthday) {
          element.employee.nextBirthday = this.datePipe.transform(element.employee.nextBirthday, 'longDate');
      }
      if(element?.employmentRecord?.hireDate) {
          element.employmentRecord.hireDate = this.datePipe.transform(element.employmentRecord.hireDate, 'longDate');
      }
      if(element?.employmentRecord?.inductionCompletedOn) {
          element.employmentRecord.inductionCompletedOn = this.datePipe.transform(element.employmentRecord.inductionCompletedOn, 'longDate');
      }
      if(element?.employmentRecord?.medicalCompletedDate) {
          element.employmentRecord.medicalCompletedDate = this.datePipe.transform(element.employmentRecord.medicalCompletedDate, 'longDate');
      }
      if(element?.employmentRecord?.codeOfConductSignedOn) {
          element.employmentRecord.codeOfConductSignedOn = this.datePipe.transform(element.employmentRecord.codeOfConductSignedOn, 'longDate');
      }
      if(element?.employmentRecord?.probationPeriodEnd) {
          element.employmentRecord.probationPeriodEnd = this.datePipe.transform(element.employmentRecord.probationPeriodEnd, 'longDate');
      }
      if(element?.employmentRecord?.terminationLastDateOnSite) {
          element.employmentRecord.terminationLastDateOnSite = this.datePipe.transform(element.employmentRecord.terminationLastDateOnSite, 'longDate');
      }
      if(element?.employmentRecord?.terminationDateNotified) {
          element.employmentRecord.terminationDateNotified = this.datePipe.transform(element.employmentRecord.terminationDateNotified, 'longDate');
      }
      if(element?.employmentRecord?.terminationDateProjected) {
          element.employmentRecord.terminationDateProjected = this.datePipe.transform(element.employmentRecord.terminationDateProjected, 'longDate');
      }
      if(element?.employmentRecord?.terminationDateActual) {
          element.employmentRecord.terminationDateActual = this.datePipe.transform(element.employmentRecord.terminationDateActual, 'longDate');
      }
      if(element?.employmentRecord?.terminationBenefitsEndOfNotice) {
          element.employmentRecord.terminationBenefitsEndOfNotice = this.datePipe.transform(element.employmentRecord.terminationBenefitsEndOfNotice, 'longDate');
      }
      if(element?.employmentRecord?.terminationBenefitsEnd) {
          element.employmentRecord.terminationBenefitsEnd = this.datePipe.transform(element.employmentRecord.terminationBenefitsEnd, 'longDate');
      }
      if(element?.employmentRecord?.terminationAcceptedByDate) {
          element.employmentRecord.terminationAcceptedByDate = this.datePipe.transform(element.employmentRecord.terminationAcceptedByDate, 'longDate');
      }
      if(element?.employmentRecord?.graduateProgramStartDate) {
          element.employmentRecord.graduateProgramStartDate = this.datePipe.transform(element.employmentRecord.graduateProgramStartDate, 'longDate');
      }
      if(element?.employmentRecord?.graduateProgramEndDate) {
          element.employmentRecord.graduateProgramEndDate = this.datePipe.transform(element.employmentRecord.graduateProgramEndDate, 'longDate');
      }
      if(element?.employmentRecord?.apprenticeshipProgramStartDate) {
          element.employmentRecord.apprenticeshipProgramStartDate = this.datePipe.transform(element.employmentRecord.apprenticeshipProgramStartDate, 'longDate');
      }
      if(element?.employmentRecord?.apprenticeshipProgramEndDate) {
          element.employmentRecord.apprenticeshipProgramEndDate = this.datePipe.transform(element.employmentRecord.apprenticeshipProgramEndDate, 'longDate');
      }

      if(element?.employee?.managers) {
          let val: string = '';

          element?.employee?.managers.forEach( (manager) => {
              if(manager.hasOwnProperty('firstName') && manager.hasOwnProperty('lastName')){
                  if (val !== ''){
                      val = val.concat(', ');
                  }
                  if (manager.firstName !== null && manager.lastName !== null){
                      val = val.concat(`${manager.firstName} ${manager.lastName}`);
                  }
              }
          });

          element.employee.managers = val;
      }

      for (const [key, value] of Object.entries(element)) {
          // If it is an array extract the values into a string and set the value as this string
          if (Array.isArray(value)){
              let val: string = '';

              value.forEach( (item) => {
                  if (typeof item === 'object' && item !== null) {
                      if (item.hasOwnProperty('name')){
                          if (val !== ''){
                              val = val.concat(', ');
                          }
                          if (item.name !== null){
                              val = val.concat(item.name);
                          }
                      }
                      else if (item.hasOwnProperty('firstName') && item.hasOwnProperty('lastName')){
                          if (val !== ''){
                              val = val.concat(', ');
                          }
                          if (item.firstName !== null && item.lastName !== null){
                              val = val.concat(`${item.firstName} ${item.lastName}`);
                          }
                      }
                  }
              });

              if (val !== ''){
                  element[key] = val;
              }
          }
      }
      return element;
  }
}
