<mat-card>
    <mat-card-title class="header">
        <h5>Total API Calls for This Year</h5>
    </mat-card-title>
    <mat-card-content>
        <div id="chart">
            <apx-chart
              [series]="chartOptions.series"
              [chart]="chartOptions.chart"
              [dataLabels]="chartOptions.dataLabels"
              [plotOptions]="chartOptions.plotOptions"
              [yaxis]="chartOptions.yaxis"
              [xaxis]="chartOptions.xaxis"
              [legend]="chartOptions.legend"
              [colors]="chartOptions.colors"
              [grid]="chartOptions.grid"
            ></apx-chart>
        </div>
    </mat-card-content>
</mat-card>

