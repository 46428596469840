<mat-card>
    <mat-card-title class="header">
      <h5 class="title">Inbox</h5>
    </mat-card-title>
  
    <mat-card-content class="content">
        <div class="email-menu-header">
            <!-- <h4 class="email-menu-header__title">New Messages</h4> -->
            <p class="email-menu-header__subtitle" routerLink="{{routes.INBOX}}">{{emails.length}} New Messages</p>
        </div>
        
        <div *ngFor="let email of emails, let i = index" class="mail-wrapper" routerLink="{{routes.INBOX}}">
            <div class="mail-wrapper__icon-wrapper">
              <button class="mail-wrapper__icon" [ngClass]="colors[i]" mat-mini-fab>{{email.name | shortName}}</button>
              <span class="mail-wrapper__time">{{email.time}}</span>
            </div>
            <div class="mail-content">
              <span class="mail-content__user">{{email.name}}</span>
              <span class="mail-content__message">{{email.message}}</span>
            </div>
        </div>
    </mat-card-content>
</mat-card>