import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {api_routes} from '@app/consts';
import {Observable} from 'rxjs';
import {BenefitsGroupPlanCoverage, BenefitsGroupPlanCoverageSubmit} from '../models/benefits-group-plan-coverages.model';
import {EnvironmentService} from "@app/core/services/environment.service";

const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});
@Injectable({
  providedIn: 'root'
})
export class BenefitsGroupPlanCoverageService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getBenefitsGroupPlanCoverages(groupId: string, planId: string): Observable<BenefitsGroupPlanCoverage[]> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.BENEFITS}/${api_routes.GROUPS}/${groupId}/${api_routes.PLANS}/${planId}/${api_routes.COVERAGES}`, {
      headers: headers
    })
  }

  getBenefitsGroupPlanCoverage(groupId: string, planId: string, coverageId: string): Observable<BenefitsGroupPlanCoverage> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.BENEFITS}/${api_routes.GROUPS}/${groupId}/${api_routes.PLANS}/${planId}/${api_routes.COVERAGES}/${coverageId}`, {
      headers: headers
    })
  }

  addBenefitsGroupPlanCoverages(groupId: string, planId: string, coverageId: string, groupPlanCoverageSubmit: BenefitsGroupPlanCoverageSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.BENEFITS}/${api_routes.GROUPS}/${groupId}/${api_routes.PLANS}/${planId}/${api_routes.COVERAGES}/${coverageId}`, groupPlanCoverageSubmit,{
      headers: headers
    })
  }

  updateBenefitsGroupPlanCoverages(groupId: string, planId: string, coverageId: string, groupPlanCoverageSubmit: BenefitsGroupPlanCoverageSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.BENEFITS}/${api_routes.GROUPS}/${groupId}/${api_routes.PLANS}/${planId}/${api_routes.COVERAGES}/${coverageId}`, groupPlanCoverageSubmit,{
      headers: headers
    })
  }

  deleteBenefitsGroupPlanCoverages(groupId: string, planId: string, coverageId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.BENEFITS}/${api_routes.GROUPS}/${groupId}/${api_routes.PLANS}/${planId}/${api_routes.COVERAGES}/${coverageId}`, {
      headers: headers
    })
  }
}
