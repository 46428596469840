<mat-card>
    <mat-card-title class="header">
        <h5>API User</h5>
        <div>
            <button mat-flat-button setColor="primaryColour" color="primary" (click)="openNewApiUserDialog()">
                <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> Add
            </button>
        </div>
    </mat-card-title>
    <mat-card-content>
        <kendo-grid 
        [data]="gridDataResult"
        [style.maxHeight.vh]="85"  
        [pageSize]="pageSize"
        [skip]="skip"
        [pageable]="{
            info: true,
            pageSizes: [5, 10, 20, 50, 100],
            previousNext: true
          }"
        (pageChange)="pageChange($event)"
        [loading]="isLoading">
            <kendo-grid-column
              *ngFor="let col of columns"
              [field]="col.field"
              [title]="col.title"
            ></kendo-grid-column>
            <kendo-grid-command-column>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <mat-icon class="material-icons-outlined" (click)="openApiUserDialog(dataItem)">edit</mat-icon>
                    <mat-icon class="material-icons-outlined">delete</mat-icon>
                </ng-template>
            </kendo-grid-command-column>
        </kendo-grid>
    </mat-card-content>
</mat-card>
