<mat-card>
    <mat-card-title class="header">
      <h5 class="title" appLocalizationPopupDirective localizationCode="DirectReportsSchedule">{{ 'DirectReportsSchedule' | translate: {Default: "Direct Reports Schedule"} }}</h5>
    </mat-card-title>
  
    <mat-card-content class="content">

        <div *ngIf="!isLoading">
            <form [formGroup]="scheduleFiltersForm">
                <mat-form-field appLocalizationPopupDirective [localizationCode]="'DirectReportsSchedule-Employees'" appearance="fill" class="k-mr-3">
                    <mat-label>{{ 'DirectReportsSchedule-Employees' | translate: {Default: "Employees"} }}</mat-label>
                    <mat-select formControlName="employees" multiple>
                        <mat-option value="all">{{ 'All' | translate: {Default: "All"} }}</mat-option>
                        <mat-option *ngFor="let data of schedule" [value]="data.employee">
                            {{data.employee.firstName}} {{data.employee.lastName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appLocalizationPopupDirective [localizationCode]="'DirectReportsSchedule-Display'" appearance="fill" class="k-mr-3">
                    <mat-label>{{ 'DirectReportsSchedule-Display' | translate: {Default: "Display"} }}</mat-label>
                    <mat-select formControlName="eventsToDisplay" multiple>
                        <mat-option value="all">{{ 'All' | translate: {Default: "All"} }}</mat-option>
                        <mat-option *ngFor="let eventsToDisplayOption of eventsToDisplayOptions" [value]="eventsToDisplayOption.id">
                            <div appLocalizationPopupDirective [localizationCode]="eventsToDisplayOption.text">
                                {{ translate.instant(eventsToDisplayOption.text) }} 
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appLocalizationPopupDirective [localizationCode]="'DirectReportsSchedule-TimeOffType'" appearance="fill" class="k-mr-3">
                    <mat-label>{{ 'DirectReportsSchedule-TimeOffType' | translate: {Default: "Time Off Type"} }}</mat-label>
                    <mat-select formControlName="timeOffType" multiple>
                        <mat-option value="all">{{ 'All' | translate: {Default: "All"} }}</mat-option>
                        <mat-option *ngFor="let timeOffType of timeOffTypes" [value]="timeOffType.id">
                            {{timeOffType.name}} 
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appLocalizationPopupDirective [localizationCode]="'DirectReportsSchedule-TimeOffType'" appearance="fill" class="k-mr-3">
                    <mat-label>{{ 'DirectReportsSchedule-TimeOffStatus' | translate: {Default: "Time Off Status"} }}</mat-label>
                    <mat-select formControlName="timeOffStatus" multiple>
                        <mat-option value="all">{{ 'All' | translate: {Default: "All"} }}</mat-option>
                        <mat-option *ngFor="let timeOffType of timeOffStatus" [value]="timeOffType.id">
                            <div appLocalizationPopupDirective [localizationCode]="timeOffType.text">
                                {{ translate.instant(timeOffType.text) }} 
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
        </div>


        <kendo-scheduler
            [kendoSchedulerBinding]="filteredScheduleData"
            [selectedDate]="selectedDate"
            [selectedViewIndex]="1"
            [loading]="isLoading"
            (dateChange)="dateChange($event)"
            style="height: 2000px;"
        >

            <ng-template kendoSchedulerEventTemplate let-event="event">
                <div class="event-container" [ngClass]="event.description">
                    <mat-icon *ngIf="event.description === 'StatutoryHoliday'" class="material-icons-outlined  public-holiday">celebration</mat-icon>
                    <mat-icon *ngIf="event.description === 'Leave'" class="material-icons-outlined  absence">date_range</mat-icon>
                    <mat-icon *ngIf="event.description === 'PendingTimeOff'" class="material-icons-outlined  absence">date_range</mat-icon>
                    <mat-icon *ngIf="event.description === 'Work'" class="material-icons-outlined  company-event">work</mat-icon>
                    
                    {{event.title}}
                </div>
            </ng-template>

            <ng-template kendoSchedulerAllDayEventTemplate let-event="event">
                <div class="event-container" [ngClass]="event.description">
                    <mat-icon *ngIf="event.description === 'StatutoryHoliday'" class="material-icons-outlined  public-holiday">celebration</mat-icon>
                    <mat-icon *ngIf="event.description === 'Leave'" class="material-icons-outlined  absence">date_range</mat-icon>
                    <mat-icon *ngIf="event.description === 'PendingTimeOff'" class="material-icons-outlined  absence">date_range</mat-icon>
                    <mat-icon *ngIf="event.description === 'Work'" class="material-icons-outlined  company-event">work</mat-icon>
                    
                    {{event.title}}
                </div>
            </ng-template>

            <kendo-scheduler-day-view> </kendo-scheduler-day-view>

            <kendo-scheduler-month-view [eventHeight]="30">
            </kendo-scheduler-month-view>

            <kendo-scheduler-messages
                [today]="translate.instant('Scheduler-Today')"
                [allDay]="translate.instant('Scheduler-AllDay')"
                [dayViewTitle]="translate.instant('Scheduler-DayViewTitle')"
                [weekViewTitle]="translate.instant('Scheduler-WeekViewTitle')"
                [monthViewTitle]="translate.instant('Scheduler-MonthViewTitle')"
                [showWorkDay]="translate.instant('Scheduler-ShowWorkDay')"
                [showFullDay]="translate.instant('Scheduler-ShowFullDay')"
            >
            </kendo-scheduler-messages>
        </kendo-scheduler>
    </mat-card-content>
</mat-card>