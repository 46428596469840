<app-performance>
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
          <h5 appLocalizationPopupDirective localizationCode="EditReviewPlan" mat-dialog-title>{{ 'EditReviewPlan' | translate: {Default: 'Edit Review Plan'} }}</h5>
      
          <div class="k-flex-grow"></div>

          <button class="mat-stroked-button publish-history-button" color="primary" setColor="primaryColour" appPreventDoubleClick (throttledClick)="openPublishHistoryDialog()">
            <mat-icon aria-hidden="false" class="material-icons-outlined  k-mr-3">history</mat-icon>
            {{ 'PublishHistory' | translate: {Default: "Publish History"} }}</button>
          <button class="mat-stroked-button" appPreventDoubleClick setColor="primaryColour" (throttledClick)="openConfirmPublishReviewPlanDialog()">{{ 'Publish' | translate: {Default: "Publish"} }}</button>
          <button class="mat-stroked-button" appPreventDoubleClick (throttledClick)="navigateBack()">{{ 'Close' | translate: {Default: "Close"} }}</button>
          <button 
              mat-flat-button 
              setColor="primaryColour" 
              color="primary" 
              appPreventDoubleClick 
              (throttledClick)="getFormData = !getFormData" 
              [disabled]="formValid === false"
          >{{ 'Save' | translate: {Default: "Save"} }}</button>  
        </mat-card-title>

        <mat-card-content>
          <ng-container *ngIf="isLoading; else loaded">
            <ngx-skeleton-loader
              count="5"
              [theme]="{ 
                  'height.px': 50
              }"
            ></ngx-skeleton-loader>
          </ng-container>
          <ng-template #loaded>
            <div class="top-row">
                <app-form-generator 
                    [formId]="formId"
                    [formData]="formData"
                    [emitFormDataTrigger]="getFormData"
                    (emitFormDataChange)="formDataChange($event)"
                    (emitFormData)="formDataEmitted($event)"
                    (emitFormStatus)="formStatusUpdated($event)"
                    (emitFormPristine)="formPristineEmitted($event)"
                ></app-form-generator>
    
                <div class="grid-container" *ngIf="reviewPlanId">
                  <app-review-plan-owners
                    *ngIf="!isLoading"
                    [reviewPlan]="reviewPlan"
                  ></app-review-plan-owners>

                  <app-review-plan-employees
                    *ngIf="!isLoading"
                    [reviewPlan]="reviewPlan"
                  ></app-review-plan-employees>
                </div>
            </div>

            
            <div class="section-container">
              <app-review-plan-reviewers [reviewPlanId]="reviewPlanId"></app-review-plan-reviewers>
            </div>
            
            <div class="section-container">
              <app-review-plan-goal-plans [reviewPlanId]="reviewPlanId" (dataUpdated)="refreshGoalSettings = !refreshGoalSettings"></app-review-plan-goal-plans>
            </div>
            
            <div class="section-container">
              <app-review-plan-goal-settings [reviewPlanId]="reviewPlanId" [refresh]="refreshGoalSettings"></app-review-plan-goal-settings>
            </div>

            <div class="section-container">
              <app-review-plan-goal-type-weights [reviewPlanId]="reviewPlanId"></app-review-plan-goal-type-weights>
            </div>

            <div *ngIf="currentFormData?.setOverallRating" class="section-container">
              <app-review-plan-overall-ratings [reviewPlanId]="reviewPlanId"></app-review-plan-overall-ratings>
            </div>
          </ng-template>
        </mat-card-content>
    </mat-card>
</app-performance>
