<h5 mat-dialog-title>Employment Record Benefit</h5>

<mat-dialog-content>
    <form class="form-container" *ngIf="form" [formGroup]="form">

        <mat-form-field  appearance="fill">
            <mat-label>
                Effective Date
            </mat-label>

            <input matInput [matDatepicker]="effectiveDatepicker" formControlName="effectiveDate">
            <span fxLayout="row" matSuffix>
                <mat-datepicker-toggle  [for]="effectiveDatepicker"></mat-datepicker-toggle>
            </span>
            <mat-datepicker #effectiveDatepicker></mat-datepicker>
            <mat-hint></mat-hint>
        </mat-form-field>

        <mat-form-field  appearance="fill">
            <mat-label>
                Start Date
            </mat-label>

            <input matInput [matDatepicker]="startDatepicker" formControlName="startDate">
            <span fxLayout="row" matSuffix>
                <mat-datepicker-toggle  [for]="startDatepicker"></mat-datepicker-toggle>
            </span>
            <mat-datepicker #startDatepicker></mat-datepicker>
            <mat-hint></mat-hint>
        </mat-form-field>

        <mat-form-field  appearance="fill">
            <mat-label>
                End Date
            </mat-label>

            <input matInput [matDatepicker]="endDatepicker" formControlName="endDate">
            <span fxLayout="row" matSuffix>
                <mat-datepicker-toggle  [for]="endDatepicker"></mat-datepicker-toggle>
            </span>
            <mat-datepicker #endDatepicker></mat-datepicker>
            <mat-hint></mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Number Of Dependents
            </mat-label>

            <input matInput
                type="number"
                formControlName="numberOfDependents">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Benefit Group
            </mat-label>

            <mat-select disableOptionCentering formControlName="benefitGroup">
                <mat-option *ngFor="let benefitGroupOption of benefitGroupOptions | async" [value]="benefitGroupOption.id">{{benefitGroupOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Plan Name
            </mat-label>

            <mat-select disableOptionCentering formControlName="planName">
                <mat-option *ngFor="let planNameOption of benefitPlanNameOptions | async" [value]="planNameOption.id">{{planNameOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Benefits Division
            </mat-label>

            <mat-select disableOptionCentering formControlName="benefitsDivision">
                <mat-option *ngFor="let benefitsDivisionOption of benefitDivisionOptions | async" [value]="benefitsDivisionOption.id">{{benefitsDivisionOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Contract No
            </mat-label>

            <mat-select disableOptionCentering formControlName="contractNo">
                <mat-option *ngFor="let contractNoOption of benefitContactNoOptions | async" [value]="contractNoOption.id">{{contractNoOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Sub Group
            </mat-label>

            <mat-select disableOptionCentering formControlName="subGroup">
                <mat-option *ngFor="let subGroupOption of benefitSubGroupOptions | async" [value]="subGroupOption.id">{{subGroupOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Plan Provider
            </mat-label>

            <mat-select disableOptionCentering formControlName="planProvider">
                <mat-option *ngFor="let planProviderOption of benefitPlanProviderOptions | async" [value]="planProviderOption.id">{{planProviderOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Benefits Sub Division
            </mat-label>

            <mat-select disableOptionCentering formControlName="benefitsSubDivision">
                <mat-option *ngFor="let benefitsSubDivisionOption of benefitSubDivisionOptions | async" [value]="benefitsSubDivisionOption.id">{{benefitsSubDivisionOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Language
            </mat-label>

            <mat-select disableOptionCentering formControlName="language">
                <mat-option *ngFor="let languageOption of languageOptions | async" [value]="languageOption.id">{{languageOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button> 
</mat-dialog-actions>