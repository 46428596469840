import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-workforce-planning-report',
  templateUrl: './workforce-planning-report.component.html',
  styleUrls: ['./workforce-planning-report.component.scss']
})
export class WorkforcePlanningReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
