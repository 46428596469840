import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SnackbarService} from '@app/core/services/snackbar.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Currency} from '@app/modules/lookup/models/currency.model';
import {SettingsService} from '@app/core/services/settings.service';
import {ExchangeRate} from '@app/modules/lookup/models/exchange-rate.model';

@Component({
    selector: 'view-currency',
    templateUrl: './view-currency.component.html',
    styleUrls: ['./view-currency.component.scss']
})
export class ViewCurrencyComponent implements OnInit {

    isLoading: boolean = true;
    settingsForm: UntypedFormGroup;

    _currency: Currency;

    @Input() set currency(value: Currency) {
        this._currency = value;
        this.getExchangeRates();
    };

    exchangeRates: ExchangeRate[] = []

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private settingsService: SettingsService,
        private dialog: MatDialog,
        private snackbarService: SnackbarService,
        private fb: UntypedFormBuilder
    ) {
    }

    ngOnInit(): void {
        // this.getExchangeRates();
    }

    private getExchangeRates() {
        this.settingsService.getExchangeRates(this._currency.id).subscribe(
            pagedExchangeRates => {
                this.exchangeRates = pagedExchangeRates.data
                console.log(this.exchangeRates);
                this.isLoading = false;
            },
            err => {
                console.log(err);
            }
        );
    }

    toggleCurrencyState() {
        this.isLoading = true;
        let data = {'isEnabled': !this._currency.isEnabled };
        this.settingsService.updateCurrency(this._currency.id, data).subscribe(
            res => {
                this._currency.isEnabled = !this._currency.isEnabled
                this.snackbarService.openSnackBar('Currency updated', 'clear', 'success');
            },
            err => {
                console.log(err);
            }
        );
    }
}
