import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { OidcAuthService } from '../services/oidc-auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private authService: OidcAuthService) { }

    canActivate(): boolean {
        if (this.authService.isAuthenticated()) {
            return true;
        }
        else {
            this.authService.redirectToLogin();
            return false;
        }
    }
}
