<div class="content">
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
            <h4 appLocalizationPopupDirective localizationCode="EmployeeSecurity-OrganizationalAccess">{{ 'EmployeeSecurity-OrganizationalAccess' | translate: {Default: "Organizational Access"} }}</h4>
        </mat-card-title>
    
        <mat-card-content>
            <div class="page-description-section" appLocalizationPopupDirective localizationCode="AccessToOrganizationsBasedOnRole">
                {{ 'AccessToOrganizationsBasedOnRole' | translate: {Default: "Access to Organizations based on Roles assigned to user"} }}
            </div>

            <kendo-grid 
                [data]="gridDataResult" 
                [pageSize]="pageSize"
                [skip]="skip"
                [style.maxHeight.vh]="85" 
                [resizable]="true"
                [pageable]="{
                    info: true,
                    pageSizes: [5, 10, 20, 50, 100],
                    previousNext: true
                }"
                (pageChange)="pageChange($event)"
                [loading]="isLoading">

                <kendo-grid-column field="role" [width]="150">
                    <ng-template kendoGridHeaderTemplate>
                        <div appLocalizationPopupDirective localizationCode="EmployeeSecurity-OrganizationalAccess-Role">{{ 'EmployeeSecurity-OrganizationalAccess-Role' | translate: {Default: "Role"} }}</div>
                    </ng-template>

                    <ng-template kendoGridCellTemplate let-dataItem >
                        <div class="grid-cell-vertical">
                            {{dataItem.role.name}}
                        </div>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="organization" [width]="150">
                    <ng-template kendoGridHeaderTemplate>
                        <div appLocalizationPopupDirective localizationCode="EmployeeSecurity-OrganizationalAccess-Organization">{{ 'EmployeeSecurity-OrganizationalAccess-Organization' | translate: {Default: "Organization"} }}</div>
                    </ng-template>

                    <ng-template kendoGridCellTemplate let-dataItem >
                        <div class="grid-cell-vertical">
                            {{dataItem.organization.name}}
                        </div>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="organization" [width]="100">
                    <ng-template kendoGridHeaderTemplate>
                        <div appLocalizationPopupDirective localizationCode="EmployeeSecurity-OrganizationalAccess-OrganizationType">{{ 'EmployeeSecurity-OrganizationalAccess-OrganizationType' | translate: {Default: "Organization Type"} }}</div>
                    </ng-template>

                    <ng-template kendoGridCellTemplate let-dataItem >
                        <div class="grid-cell-vertical">
                            {{dataItem.organization.organizationType?.name}}
                        </div>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-messages 
                    [noRecords]="isLoading ? '' : translate.instant('Grid-NoRecordsAvailable')"
                    [pagerItemsPerPage]="translate.instant('Grid-Pager-ItemsPerPage')" 
                    [pagerItems]="translate.instant('Grid-Pager-Items')"
                    [pagerOf]="translate.instant('Grid-Pager-Of')"
                ></kendo-grid-messages>

            </kendo-grid>
        </mat-card-content>
    </mat-card>
</div>
