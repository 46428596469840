import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { api_routes } from '@app/consts';
import { EmployeeCompensationSubmit } from '../models/employee-compensation.model';
import { EnvironmentService } from "@app/core/services/environment.service";


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class EmployeeCompensationService {

  constructor(private http: HttpClient, private envService: EnvironmentService) {}

  getCompensation(employeeId: string, asOf?: string): Observable<any> {
    let params = new HttpParams();
    asOf ? params = params.append('AsOf', asOf) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.COMPENSATION}`, {
      headers: headers,
      params: params
    });
  }

  updateCompensation(employeeId: string, employeeCompensationSubmit: EmployeeCompensationSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.COMPENSATION}`, employeeCompensationSubmit, {
      headers: headers
    });
  }

  getPayRateIntervals(): Observable<any> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${api_routes.PAY_RATE_INTERVALS}`, {
      headers: headers
    });
  }

  getHistory(employeeId: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('skip', 0);
    params = params.append('take', '1000');

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.COMPENSATION}/${api_routes.HISTORY}`, {
      params: params,
      headers: headers
    });
  }
}
