import { Component, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { defer, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BenefitCoverageDialogComponent } from './components/benefit-coverage-dialog/benefit-coverage-dialog.component';
import { BenefitsCoverage } from './models/benefits-coverage.model';
import { BenefitsCoverageService } from './services/benefits-coverage.service';

@Component({
  selector: 'app-benefits-coverages',
  templateUrl: './benefits-coverages.component.html',
  styleUrls: ['./benefits-coverages.component.scss']
})
export class BenefitsCoveragesComponent implements OnInit {
  @ViewChild('selectionList', {static: false}) private selectionList: MatSelectionList; 
  coverages: BenefitsCoverage[] = [];
  loadingCoverages: boolean = false;
  selectedCoverages: BenefitsCoverage[] = [];
  selectAll: boolean;

  constructor(
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
    private benefitsCoverageService: BenefitsCoverageService,
  ) { }

  ngOnInit(): void {
    this.getCoverages();
  }

  getCoverages() {
    this.loadingCoverages = true;

    this.benefitsCoverageService.getCoverages()
    .pipe(
      finalize( () => this.loadingCoverages = false )
    )
    .subscribe(
      res => {
        this.coverages = res.data;
      }
    )
  }

  toggleSelectAll(event: MatCheckboxChange) {
    this.selectAll = event.checked;
    event.checked ? this.selectionList.selectAll() : this.selectionList.deselectAll();
  }

  openFormDialog(event, coverage?: BenefitsCoverage) {
    event ? event.stopPropagation() : null

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      coverageId: coverage ? coverage.id : null
    };

    const dialogRef = this.dialog.open(BenefitCoverageDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.getCoverages();
        }
      }
    );
  }

  openConfirmDeleteDialog(event, coverage?: BenefitsCoverage) {
    event.stopPropagation();

    const dialogConfig = new MatDialogConfig();
    let text;

    if(coverage !== null){
      text = `Are you sure you want to delete this coverage (${coverage.name})`;
    }
    else {
      text = `Are you sure you want to delete all selected coverages`;
    }

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
        text: text
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          if(coverage !== null){
            this.deleteCoverage(coverage);
          }
          else {
            this.deleteAllSelected();
          }
        }
      }
    ); 
  }

  deleteCoverage(coverage: BenefitsCoverage){
    this.overlayService.show();

    this.benefitsCoverageService.deleteCoverage(coverage.id)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.getCoverages();
        this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
      }
    );
  }

  deleteAllSelected() {
    const observables = this.selectedCoverages.map(listOption => defer(() => this.benefitsCoverageService.deleteCoverage(listOption.id)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.selectAll = false;
        this.selectedCoverages = [];
        this.getCoverages();
        this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
      }
    );
  }

}

