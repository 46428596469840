<h5 mat-dialog-title>Report Element</h5>

<mat-dialog-content>
    <ng-container *ngIf="!form; else LoadedFormElement">
        <div class="loading-container" style="width:100%;">
            <ngx-skeleton-loader
                count="5"
                [theme]="{
                'height.px': 50
                }"
            ></ngx-skeleton-loader>
        </div>
    </ng-container>

    <ng-template #LoadedFormElement>
        <form class="form" *ngIf="form" [formGroup]="form">

            <mat-form-field appearance="fill">
                <mat-label>
                    Element Type
                </mat-label>

                <mat-select disableOptionCentering formControlName="elementType" [disabled]="element != null">
                    <ng-container *ngFor="let option of elementTypes">
                        <mat-option [value]="option.id">{{option.display}}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>
                    Element Name
                </mat-label>

                <input matInput type="text" formControlName="elementName">
            </mat-form-field>

            <app-report-element-datagrid *ngIf="form.get('elementType').value === 'datagrid_element'"
                                         [form]="form"
                                         [element]="element"
                                         (outputSave)="save()"
                                         (outputClose)="close()">
            </app-report-element-datagrid>

            <app-report-element-pie-chart *ngIf="form.get('elementType').value === 'piechart_element'"
                                          [form]="form"
                                          (outputSave)="save()"
                                          (outputClose)="close()"></app-report-element-pie-chart>

        </form>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button>
</mat-dialog-actions>
