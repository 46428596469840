import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { EnvironmentService } from '../services/environment.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') sidenav: MatSidenav;
  popupContainer = document.getElementById("popupContainer");
  public isShowSidebar: boolean;
  public mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;

  sequence = '';
  isPopupVisible: boolean;
  animateClass = ['hidden'];

  showChristmasGif: boolean = false;

  constructor(
    changeDetectorRef: ChangeDetectorRef, 
    public environmentService: EnvironmentService,
    media: MediaMatcher
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 599px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);

    this.isShowSidebar = this.mobileQuery.matches;
  }

  ngOnInit() {
    const today = new Date();

    // If its december and the christmas theme is set, check to see if you should show christmas gif
    if (today.getMonth() === 11 && this.environmentService.env.customTheme === 'christmasTheme') {
      this.showChristmasGifPeriodically();
    }
  }

  public ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener);

    this.sidenav?.close();
  }

  calculateGifChance(): number {
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 11, 1); // December is represented by 11 (0-indexed)
    const endDate = new Date(currentDate.getFullYear(), 11, 25);

    if (currentDate < startDate) {
      return 0; // If date is before December 1st, chance is 0%
    }

    const diffDays = Math.floor((currentDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
    const baseChance = 5; // Starting chance on December 1st
    const maxChance = 50; // Maximum chance on December 25th

    const totalDays = Math.floor((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
    const percentageIncreasePerDay = (maxChance - baseChance) / totalDays;

    const currentChance = baseChance + percentageIncreasePerDay * diffDays;

    return Math.min(currentChance, maxChance); // Cap the chance at 75%
  }

  showChristmasGifPeriodically() {
    const probability = Math.random() * 100; // Generate a random number between 0 and 100

    // Adjust the range to represent the desired probability (5% in this case)
    if (probability <= this.calculateGifChance()) {
      this.showChristmasGif = true;
    }

    setTimeout(() => {
      this.showChristmasGif = false;
      this.showChristmasGifPeriodically(); // Call the function again after 1 minute
    }, 1 * 60 * 1000); // 1 minute in milliseconds
  }

  onAnimationEnd() {
    this.showChristmasGif = false; // Hide the GIF after animation ends
  }
}
