import { Component, OnInit } from '@angular/core';
import { colors } from '@app/consts'; 

@Component({
  selector: 'app-payroll-pie-chart',
  templateUrl: './payroll-pie-chart.component.html',
  styleUrls: ['./payroll-pie-chart.component.scss']
})
export class PayrollPieChartComponent implements OnInit {

  chartData = {
    groupA: 4146,
    groupB: 1567
  }

  public chart: any;
  public colors: typeof colors = colors;

  constructor() { }
  
  public ngOnInit(): void {
    this.initChart();
  }

  public initChart(): void {
    this.chart = {
      color: ['#0071ce', '#ff5100'],
      tooltip: {
        trigger: 'item'
      },
      series: [{
        type: 'pie',
        label: {
          show: false
        },
        labelLine: {
          normal: {
            show: false
          }
        },
        hoverAnimation: false,
        avoidLabelOverlap: false,
        data: [
          {
            name: 'Gross Amount',
            value: this.chartData.groupA
          },
          {
            name: 'Deductions',
            value: this.chartData.groupB
          }
        ]
      }]
    };
  }

}
