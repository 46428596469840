<div 
fxLayout="column" 
fxLayoutAlign="space-around center"
class="login">
    <h2 class="login-header">Log in</h2>
    <form 
    fxLayout="column" 
    fxLayoutAlign="space-around center"
    [formGroup]="loginForm" class="login-container" (ngSubmit)="login()">

        <mat-form-field appearance="fill">
            <mat-label>Email</mat-label>
            <input type="email" matInput formControlName="email" placeholder="Ex. pat@example.com">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Password</mat-label>
            <input matInput type="password" formControlName="password">
            <mat-hint></mat-hint>
        </mat-form-field>

        <div class="button-container">
            <button mat-raised-button type="submit" [disabled]="loginForm.invalid">Login</button>
        </div>
    </form>
  </div>