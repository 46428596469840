import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-absence-report',
    templateUrl: './absence-report.component.html',
    styleUrls: ['./absence-report.component.scss']
})
export class AbsenceReportComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
