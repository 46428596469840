import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeService } from '@app/core/services/employee.service';
import { Employee } from '@app/shared/models/employee.model';
import { routes } from '@app/consts';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-employees-page',
  templateUrl: './employees-page.component.html',
  styleUrls: ['./employees-page.component.scss']
})
export class EmployeesPageComponent implements OnInit {
  employees$: Observable<any[]>;
  employees: Employee[] = [];
  pagination: Object;
  isLoading: boolean;
  loadingError: boolean;
  cardView: boolean = false;

  tableColumns: any[] = [
    // { name: 'ID', dataKey: 'id', isSortable: true, position: 'left' },
    { name: 'Firstname', dataKey: 'firstName', isSortable: true, position: 'left' },
    { name: 'Lastname', dataKey: 'lastName', isSortable: true, position: 'left' },
    { name: 'Middle Name', dataKey: 'middleName', isSortable: true, position: 'left' },
    { name: 'Maiden Name', dataKey: 'maidenName', isSortable: true, position: 'left' },
    { name: 'Preferred Name', dataKey: 'preferredName', isSortable: true, position: 'left' },
    { name: 'Birthday', dataKey: 'birthday', isSortable: true, position: 'left' },
    { name: 'Gender', dataKey: 'gender', isSortable: true, position: 'left' },
    { name: 'Marital Status', dataKey: 'maritalStatus', isSortable: true, position: 'left' },
    { name: 'Title', dataKey: 'title', isSortable: true, position: 'left' },
    { name: 'Cultural Affiliation', dataKey: 'culturalAffiliation', isSortable: true, position: 'left' },
    // { name: 'Religious Affiliation', dataKey: 'religiousAffiliation', isSortable: true, position: 'left' },
    { name: 'Username', dataKey: 'username', isSortable: true, position: 'left' },
    { name: 'Timezone', dataKey: 'timezone', isSortable: true, position: 'left' },
  ];
  
  constructor(
    private employeeService: EmployeeService,
    public router: Router,
  ) {}

  ngOnInit() {
    this.getEmployees();
  }

  getEmployees(pageSize?: string, page?: string, nextCursor?: string, properties?: string, filter?: string, orderBy?: string): void {
    this.isLoading = true;
    this.employeeService.getEmployees(pageSize, page)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        res => {
          for(let x=0; this.employees.length < 10; x++) {
            this.employees.push(res.employees[x]);
          }
          // this.employees = res.employees;
          this.pagination = res.pagination;
        },
        err => {
          this.loadingError = true;
        },
        () => this.isLoading = false
      );     
  }  

  onChangePage(event: PageEvent) {
    const pageSize = (+event.pageSize).toString(); // get the pageSize
    const currentPage = (+event.pageIndex + 1).toString(); // get the current page
   
    this.getEmployees(pageSize, currentPage); // call the service
  }

  rowEvent(event: any) {
    if (event.icon === 'create') {
      this.openEditEmployee(event.row.id);
    }
  }

  sortData(sortParameters: MatSort) {
    const keyName = sortParameters.active;
    if (sortParameters.direction === 'asc') {
      // WHEN THE API ENDPOINT WORKS ADD IN GETEMPLOYEES WITH SORTING
      this.getEmployees();
      // this.employees = this.employees.sort((a: Employee, b: Employee) => a[keyName].localeCompare(b[keyName]));
    } else if (sortParameters.direction === 'desc') {
      // this.orders = this.orders.sort((a: Order, b: Order) => b[keyName].localeCompare(a[keyName]));
    } else {
      // return this.orders = this.getOrders();
    }
  }

  pageChangeEvent(event: any) {
    const pageSize = (+event.pageSize).toString(); // get the pageSize
    const currentPage = (+event.pageIndex + 1).toString(); // get the current page
   
    this.getEmployees(pageSize, currentPage); // call the service
  }

  openEditEmployee(id: string): void {
    this.router.navigateByUrl(`${routes.TALENT_TRACK}${routes.EDIT}/${id}`);
  }
}
