import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { EmployeeBankDetail } from '../../models/employee-bank-details-report.model';
import { EmployeeBankDetailsReportService } from '../../services/employee-bank-details-report.service';

@Component({
  selector: 'app-bank-name-chart',
  templateUrl: './bank-name-chart.component.html',
  styleUrls: ['./bank-name-chart.component.scss']
})
export class BankNameChartComponent implements OnInit {
  public data = [
    {
      kind: "Hydroelectric",
      share: 0.175,
    },
    {
      kind: "Nuclear",
      share: 0.238,
    },
    {
      kind: "Coal",
      share: 0.118,
    },
    {
      kind: "Solar",
      share: 0.052,
    },
    {
      kind: "Wind",
      share: 0.225,
    },
    {
      kind: "Other",
      share: 0.192,
    },
  ];
  isLoading: boolean;
  getBankDetailsRequest: any;
  pageSize: number = 1000;
  skip: number = 0;
  sortString: string;
  filterString: string;

  bankNames: any = [];

  constructor(
    private employeeBankDetailsReportService: EmployeeBankDetailsReportService,
  ) { }

  ngOnInit(): void {
    this.getBankDetails();
  }

  getBankDetails() {
    this.isLoading = true;

    this.getBankDetailsRequest = this.employeeBankDetailsReportService.getBankDetails(this.skip, String(this.pageSize), this.filterString, this.sortString)
    .pipe(
        finalize(()=>{ this.isLoading = false; })
    )
    .subscribe(
      res => {
        let bankNames = res.data.map(
          (employeeBankDetails: EmployeeBankDetail) => employeeBankDetails.bankDetails.bankName
        );

        console.log(bankNames)
      }
    )
  }

  public labelContent(e: { category: string }): string {
    return e.category;
  }
}
