import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { api_routes } from '@app/consts';
import { Table } from '@app/modules/security-setup/models/table.model';
import { TableField, TableFieldSubmit, TableFieldVerbose } from '@app/modules/security-setup/models/table-field.model';
import { TableList } from '@app/modules/lookup/models/lookup.model';
import { FieldPermissions, FieldPermissionsSubmit, RoleSecurityForField, SecurityRole, TablePermissions, TablePermissionsSubmit } from '@app/modules/security-setup/models/security-role.model';
import { EnvironmentService } from "@app/core/services/environment.service";


const headers = new HttpHeaders({
    'Content-Type': 'application/json',
});

@Injectable({
    providedIn: 'root'
})
export class SecuritySetupService {

    constructor(private http: HttpClient, private envService: EnvironmentService) { }

    getTables(skip?: number, take?: string): Observable<PagedData<Table>> {
        let params = new HttpParams();
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;

        return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.TABLES}`, {
            headers: headers,
            params: params
        });
    }

    getFields(tableId: string, skip?: number, take?: string): Observable<PagedData<TableField>> {
        let params = new HttpParams();
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;

        return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.TABLES}/${tableId}/${api_routes.FIELDS}`, {
            headers: headers,
            params: params
        });
    }

    getField(tableId: string, fieldId: string): Observable<TableFieldVerbose> {
        return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.TABLES}/${tableId}/${api_routes.FIELDS}/${fieldId}`, {
            headers: headers
        });
    }

    updateField(tableId: string, fieldId: string, tableFieldSubmit: TableFieldSubmit): any {
        return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.TABLES}/${tableId}/${api_routes.FIELDS}/${fieldId}`, tableFieldSubmit, {
            headers: headers
        });
    }

    getTableLists(): Observable<TableList[]> {
        return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.TABLES}/${api_routes.TABLE_LISTS}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getSecurityPermissionsForTable(tableId: string): Observable<TablePermissions[]> {
        return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.SECURITY}/${api_routes.ROLES}/${api_routes.PERMISSIONS}/${api_routes.TABLES}/${tableId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    updateSecurityPermissionsForTable(roleId:string, tableId: string, tablePermissionsSubmit: TablePermissionsSubmit): Observable<any> {
        return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.SECURITY}/${api_routes.ROLES}/${roleId}/${api_routes.TABLES}/${tableId}`, tablePermissionsSubmit, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getSecurityPermissionsForField(fieldId: string): Observable<FieldPermissions[]> {
        return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.SECURITY}/${api_routes.ROLES}/${api_routes.PERMISSIONS}/${api_routes.FIELDS}/${fieldId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getRoleSecurityPermissionsForField(roleId:string, fieldId: string): Observable<RoleSecurityForField> {
        return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.SECURITY}/${api_routes.ROLES}/${roleId}/${api_routes.FIELDS}/${fieldId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    updateSecurityPermissionsForField(roleId:string, fieldId: string, fieldPermissionSubmit: FieldPermissionsSubmit): Observable<FieldPermissions> {
        return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.SECURITY}/${api_routes.ROLES}/${roleId}/${api_routes.FIELDS}/${fieldId}`, fieldPermissionSubmit, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getTableRoles(id: string): Observable<any[]> {
        return this.http.get<any>(this.envService.env.apiUrl + `Security/Tables/${id}/List`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getFieldRoles(id: string): Observable<any[]> {
        return this.http.get<any>(this.envService.env.apiUrl + `Security/Fields/${id}/List`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getSecurityRoles(): Observable<PagedData<SecurityRole>> {
        return this.http.get<any>(this.envService.env.apiUrl + `Security/Roles`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

}
