import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormFieldService } from '@app/core/services/form-field.service';
import { FormField } from '@app/shared/models/form-field.model'
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-field-rename-dialog',
  templateUrl: './field-rename-dialog.component.html',
  styleUrls: ['./field-rename-dialog.component.scss']
})
export class FieldRenameDialogComponent implements OnInit {

  form: UntypedFormGroup;
  formField: FormField;
  isLoading: boolean;
  loadingError: boolean;

  constructor(
      private fb: UntypedFormBuilder,
      private dialogRef: MatDialogRef<FieldRenameDialogComponent>,
      private formFieldService: FormFieldService,
      @Inject(MAT_DIALOG_DATA) data) {

      // this.formField = data;
      this.formFieldService.getFormFieldDetails(data.id)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        res => {
          // this.formField = res;
        },
        err => {
          this.loadingError = true;
        },
        () => this.isLoading = false
      ); 
  }

  ngOnInit() {
      this.form = this.fb.group({
        formFieldLabel: [this.formField.formFieldLabel, []]
      });
  }

  save() {
      this.dialogRef.close(this.form.value);
  }

  close() {
      this.dialogRef.close();
  }

}
