import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormElement } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { GoalPlanService } from '@app/modules/performance/components/goal-plans/services/goal-plan.service';
import { GoalTypeStatus } from '@app/modules/performance/components/goal-types/models/goal-type.model';
import { GoalTypeService } from '@app/modules/performance/components/goal-types/services/goal-type.service';
import { from, Observable } from 'rxjs';

@Component({
  selector: 'app-goal-plan-status-form-field',
  templateUrl: './goal-plan-status-form-field.component.html',
  styleUrls: ['./goal-plan-status-form-field.component.scss']
})
export class GoalPlanStatusFormFieldComponent implements OnInit {
  @Input() formElement: FormElement;
  @Input() parentGroup: UntypedFormGroup;
  @Input() readOnly: boolean;
  isLoading: boolean;
  user$: any;
  filterString: string;
  options: Observable<PagedData<GoalTypeStatus>>;

  constructor(
    private goalPlanService: GoalPlanService,
  ) { }

  ngOnInit(): void {
    this.options = from(this.goalPlanService.getGoalPlanStatuses('100', 0))
  }


}
