import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { WorkRotation, WorkRotationSubmit } from '../../models/work-rotation.model';
import { WorkRotationService } from '../../services/work-rotation.service';

@Component({
  selector: 'app-work-rotation-dialog',
  templateUrl: './work-rotation-dialog.component.html',
  styleUrls: ['./work-rotation-dialog.component.scss']
})
// TODO: Delete this component
export class WorkRotationDialogComponent implements OnInit {
  workRotationId: string;
  cultures: Culture[];
  isLoading: boolean;
  workRotation: WorkRotation;
  form: UntypedFormGroup;

  constructor(
    private snackbarService: SnackbarService,
    private fb: UntypedFormBuilder,
    private overlayService: OverlayService,
    private cultureService: CultureService,
    private workRotationService: WorkRotationService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<WorkRotationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.workRotationId = data.workRotationId;
  }

  ngOnInit(): void {
    this.cultureService.getCultures().subscribe( res => { this.cultures = res; } );

    this.workRotationId !== undefined 
      ? this.getPosition() 
      : this.isLoading = false; 
        this.createForm();
  }

  getPosition() {
    this.isLoading = true;
    this.workRotationService.getWorkRotation(this.workRotationId)
    .pipe(
      finalize(() => this.isLoading = false)
    )
    .subscribe(
      res => {
        this.workRotation = res;
        this.createForm();
      }
    );
  }

  createForm() {
    this.form = this.fb.group({
      id: [this.workRotation ? this.workRotation.id : null],
      localizations: this.fb.array([])
    });

    if(this.workRotation) {
      this.workRotation.name.forEach(
        localization => {
          this.addNewLocalization(localization.culture, localization.text);
        }
      );
    } else {
      this.addNewLocalization();
    }
  }

  get localizations() {
    return this.form.controls["localizations"] as UntypedFormArray;
  }

  //Create a textLocalization form group object to add to the localizations form array
  addNewLocalization(culture?: string, localization?: string) {
    const localizationForm = this.fb.group({
      culture: [culture || '', Validators.required],
      text: [localization || '', Validators.required]
    });

    this.localizations.push(localizationForm);
  }

  deleteLocalization(index: number) {
    this.localizations.removeAt(index);
  }

  save() {
    let workRotationSubmit: WorkRotationSubmit = {
      effectiveDate: moment().format(),
      changeReason: '',
      changeReasonComments: '',
      customProperties:[],
      id: this.form.value.id,
      name: this.form.value.localizations,
      workWeeks: []
    }

    this.overlayService.show();

    if (this.workRotation) {
      this.updateWorkRotation(workRotationSubmit)
    } else {
      this.createWorkRotation(workRotationSubmit)
    }
  }


  createWorkRotation(workRotationSubmit: WorkRotationSubmit) {
    this.workRotationService.postWorkRotation(workRotationSubmit)
        .pipe(
            finalize(() => this.overlayService.hide())
        )
        .subscribe(
            (res) => {
              this.snackbarService.openSnackBar(this.translate.instant('SavedSuccessfully'), 'clear', 'success');
              this.dialogRef.close(true);
            },
            err => {
              this.snackbarService.openSnackBar(err, 'clear', 'warn');
            }
        );
  }

  updateWorkRotation(workRotationSubmit: WorkRotationSubmit) {
    this.workRotationService.putWorkRotation(workRotationSubmit.id, workRotationSubmit)
        .pipe(
            finalize(() => this.overlayService.hide())
        )
        .subscribe(
            (res) => {
              this.snackbarService.openSnackBar(this.translate.instant('SavedSuccessfully'), 'clear', 'success');
              this.dialogRef.close(true);
            },
            err => {
              this.snackbarService.openSnackBar(err, 'clear', 'warn');
            }
        );
  }

  close() {
    this.dialogRef.close();
  }

}
