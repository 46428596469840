<div [formGroup]="parentGroup" (click)="$event.stopPropagation(); openSelectStylingColourDialog()">
    <mat-form-field appearance="fill" [ngClass]="{'invisible':formElement.hidden === 'true'}">
        <mat-label>
            {{ formElement.text }}
            <span class="asterisk" *ngIf="formElement.requiredField">*</span>
        </mat-label>

        <span matSuffix 
          *ngIf="!readOnly"
          style="margin-right: 8px;">
          <mat-icon 
            setColor 
            [setColorRules]="[
                {
                    color: 'primaryColour',
                    events: ['hover']
                }
            ]"
            class="material-icons-outlined clickable-icon" 
            (click)="$event.stopPropagation(); clearSelectedPosition()">close</mat-icon>
        </span>

        <mat-select disableOptionCentering #stylingColoursSelect [formControlName]="formElement.formControl" (click)="$event.stopPropagation(); openSelectStylingColourDialog()">
              <mat-option></mat-option>

              <ng-container>
                <mat-option *ngFor="let option of stylingColoursOptions" [value]="option.id">{{option.name}}</mat-option>
              </ng-container>
        </mat-select>
    </mat-form-field>
</div>
