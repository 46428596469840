<app-benefits>
    <mat-toolbar class="page-header" role="heading">
        <h1 appLocalizationPopupDirective localizationCode="Benefits-Coverages">{{ 'Benefits-Coverages' | translate: {Default: "Benefits: Coverages"} }}</h1>
        <button mat-flat-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openFormDialog($event)">
            <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> Add New
        </button>
    </mat-toolbar>
    
    <mat-card>
        <mat-card-title class="header">
            <h5></h5>
        </mat-card-title>
        <mat-card-content>
            <ng-container *ngIf="loadingCoverages; else loaded">
                <ngx-skeleton-loader
                    count="5"
                    [theme]="{ 
                        'height.px': 50
                    }"
                ></ngx-skeleton-loader>
            </ng-container>
    
            <ng-template #loaded>
                <div *ngIf="coverages.length > 0 else noContent" class="list-container">
                    <div *ngIf="selectedCoverages.length > 0">
                        <button class="delete-all-selected-button" mat-stroked-button setColor="primaryColour" color="primary" (click)="deleteAllSelected()">
                          <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">delete</mat-icon> Delete All Selected ({{selectedCoverages.length}})
                        </button>
                    </div>               
                   
                    <div class="select-all-container">
                        <div>Select All</div>
                        <mat-checkbox setColor="primaryColour" class="example-margin" (change)="toggleSelectAll($event)" [checked]="selectAll"></mat-checkbox>
                    </div>
    
                    <mat-selection-list #selectionList disableRipple="true" [(ngModel)]="selectedCoverages">
                        <mat-list-option *ngFor="let coverage of coverages" [value]="coverage">
                            <div class="list-item-content">
                                <div class="text">
                                    {{coverage.name}}
                                </div>
                                <div class="coverage-action-buttons">
                                    <mat-icon class="material-icons-outlined" matTooltip="Edit" appPreventDoubleClick (throttledClick)="openFormDialog($event, coverage)">edit</mat-icon> 
                                    <mat-icon class="material-icons-outlined" matTooltip="Delete" appPreventDoubleClick (throttledClick)="openConfirmDeleteDialog($event, coverage)">delete</mat-icon> 
                                </div>
                            </div>
                        </mat-list-option>
                    </mat-selection-list>
                </div>
            </ng-template>
        </mat-card-content>
    </mat-card>
</app-benefits>
