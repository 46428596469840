import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api_routes } from '@app/consts';
import { Observable } from 'rxjs';
import { DirectReportees } from '../models/direct-reportee.model';
import { EnvironmentService } from "@app/core/services/environment.service";


@Injectable({
  providedIn: 'root'
})
export class DirectReporteesService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getDirectReportees(skip?: number, take?: string, filterString?: string, sortString?: string): Observable<DirectReportees> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    filterString ? params = params.append('Filter', filterString) : null;
    sortString ? params = params.append('Sort', sortString) : null;

    return this.http.get<any>(
      `${this.envService.env.apiUrl}${api_routes.TALENT}/${api_routes.DIRECT_REPORTEES}`, {
      headers: {
        'Content-Type': 'application/json'
      },
      params: params
    });
  }
}
