<div class="k-d-flex k-d-flex-row k-mb-3">
    <h5 mat-dialog-title *ngIf="timeOffPolicy">{{ 'EditPolicy' | translate: {Default: 'Edit Policy'} }}</h5>
    <h5 mat-dialog-title *ngIf="!timeOffPolicy">{{ 'CreateAPolicy' | translate: {Default: 'Create a Policy'} }}</h5>
    <div class="k-flex-grow"></div>
    <div class="buttons-container">
        <button mat-raised-button (click)="close()">{{ 'Cancel' | translate: {Default: 'Cancel'} }}</button>
        <button mat-raised-button setColor="primaryColour" color="primary" (click)="savePolicy()" [disabled]="!policyForm?.valid">{{ 'Save' | translate: {Default: 'Save'} }}</button>
    </div>
</div>

<div class="form-section-title">
</div>

<div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white; width:100%">
    <mat-spinner></mat-spinner>
</div>

<div class="form-container" *ngIf="!isLoading" [formGroup]="policyForm">

    <div class="lookup-form-row">
        <mat-form-field appearance="fill">
            <mat-label>
                {{ 'TimeOffType' | translate: {Default: 'Time Off Type'} }}
            </mat-label>

            <mat-select disableOptionCentering formControlName="timeOffType" [disabled]="timeOffType">
                <mat-option *ngFor="let tot of timeOffTypeList" [value]="tot.id">{{tot.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <ng-container formArrayName="localizations">
        <ng-container *ngFor="let localizationForm of localizations.controls; let i = index">
            <div class="lookup-form-row" [formGroup]="localizationForm">
                <mat-form-field appearance="fill" class="k-mr-2">
                    <mat-label>
                        {{ 'SelectLanguage' | translate: {Default: 'Select Language'} }}
                    </mat-label>

                        <mat-select disableOptionCentering formControlName="culture">
                            <mat-option *ngFor="let culture of cultures" [value]="culture.id">{{culture.text}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                <mat-form-field appearance="fill" class="k-mr-2">
                    <mat-label>
                        {{ 'EnterPolicyName' | translate: {Default: 'Enter Policy Name'} }}
                    </mat-label>

                    <input matInput
                           formControlName="text"
                           placeholder="Type Name">
                </mat-form-field>
                <mat-icon 
                    setColor
                    [setColorRules]="[
                        {
                            color: 'primaryColour',
                            events: ['hover']
                        }
                    ]"
                    class="material-icons-outlined k-pb-5" 
                    (click)="deleteLocalization(i)" 
                    matTooltip="Delete"
                >delete</mat-icon>

            </div>
        </ng-container>
    </ng-container>

    <div class="k-mb-3">
        <button mat-raised-button (click)="addNewLocalization()" class="k-mr-2">
            <mat-icon class="material-icons-outlined">add</mat-icon>
            {{ 'AddTextLocalization' | translate: {Default: 'Add Text Localization'} }}
        </button>
    </div>

    <div class="k-mb-3">
        <mat-slide-toggle setColor="primaryColour" class="fte-prorated" formControlName="showMaximumCarryOverToEndUser">
            {{ 'ShowMaximumCarryOverToEndUser' | translate: {Default: 'Show Maximum Carry Over To End User'} }}
        </mat-slide-toggle>
    </div>

    <div class="k-mb-3">
        <mat-slide-toggle setColor="primaryColour" class="fte-prorated" formControlName="showCarryOverDateToEndUser">
            {{ 'ShowCarryOverDateToEndUser' | translate: {Default: 'Show Carry Over Date To End User'} }}
        </mat-slide-toggle>
    </div>

    <div class="k-mb-3">
        <mat-slide-toggle setColor="primaryColour" class="fte-prorated" formControlName="showAccrualSchedule">
            {{ 'ShowAccrualSchedule' | translate: {Default: 'Show Accrual Schedule'} }}
        </mat-slide-toggle>
    </div>

    <div class="k-mb-3">
        <mat-slide-toggle setColor="primaryColour" class="fte-prorated" formControlName="prorateHireDate">
            {{ 'ProrateHireDate' | translate: {Default: 'Prorate Hire Date'} }}
        </mat-slide-toggle>
    </div>

    <div class="form-section-title">
        <h6>
            {{ 'AccrualOptions' | translate: {Default: 'Accrual Options'} }}
        </h6>
    </div>
    <div class="accrual-options-row">

        <mat-slide-toggle setColor="primaryColour" class="fte-prorated" formControlName="fteProrated">
            {{ 'FTEProrated' | translate: {Default: 'FTE Prorated'} }}
        </mat-slide-toggle>

        <div>
            <label class="radio-group-label">
                {{ 'CarryOver' | translate: {Default: 'Carry Over'} }}
            </label>
            <mat-radio-group class="tp-radio-group" formControlName="carryOverUseHireDate">
                <mat-radio-button setColor="primaryColour" class="tp-radio-button" [value]="true" (change)="clearDayAndMonth($event)">
                    {{ 'HireDate' | translate: {Default: 'Hire Date'} }}
                </mat-radio-button>
                <mat-radio-button setColor="primaryColour" class="tp-radio-button" [value]="false">
                    {{ 'SetDate' | translate: {Default: 'Set Date'} }}
                    <mat-form-field appearance="fill" *ngIf="policyForm.value.carryOverUseHireDate === false">
                        <mat-label>
                            {{ 'Day' | translate: {Default: 'Day'} }}
                        </mat-label>

                        <input matInput type="number" formControlName="carryOverDay">
                    </mat-form-field>

                    <mat-form-field appearance="fill" *ngIf="policyForm.value.carryOverUseHireDate === false">
                        <mat-label>
                            {{ 'Month' | translate: {Default: 'Month'} }}
                        </mat-label>

                        <input matInput type="number" formControlName="carryOverMonth">
                    </mat-form-field>
                 </mat-radio-button>
             </mat-radio-group>
        </div>
    </div>

    <div class="lookup-form-row">
        <mat-form-field appearance="fill">
            <mat-label>
                {{ 'AccruedAt' | translate: {Default: 'Accrued At'} }}
            </mat-label>

            <mat-select disableOptionCentering formControlName="accruedAt">
                <mat-option *ngFor="let timeOffAccruedAt of timeOffAccruedAtList" [value]="timeOffAccruedAt.id">{{timeOffAccruedAt.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <!-- <div class="lookup-form-row">
        <mat-form-field appearance="fill">
            <mat-label>
                First Accrual Type
            </mat-label>

            <mat-select disableOptionCentering formControlName="firstAccrual">
                <mat-option *ngFor="let timeOffFirstAccrualType of timeOffFirstAccrualTypeList" [value]="timeOffFirstAccrualType.id">{{timeOffFirstAccrualType.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div> -->


    <ng-container *ngIf="timeOffType">
        <div class="form-section-title">
            <h6>
                {{ 'AccrualSchedule' | translate: {Default: 'Accrual Schedule'} }}
            </h6>
        </div>
        <ng-container *ngIf="!editOrCreateScheduleMode">
            <div class="lookup-form-row" *ngFor="let timeOffPolicySchedule of timeOffPolicySchedules; let i = index">
                <p>{{ 'StartAt' | translate: {Default: 'Start at'} }} {{timeOffPolicySchedule.startsAt}} {{timeOffPolicySchedule.startAtUnit.name}} {{ 'AfterHire' | translate: {Default: 'after hire'} }}.<br>
                    Accrued at a rate of {{timeOffPolicySchedule.accrualRate}} hour(s) per {{timeOffPolicySchedule.accrualRateInterval.name}}

                    <span *ngIf="timeOffPolicySchedule.intervalStartDate !== null"><br>starting on {{timeOffPolicySchedule.intervalStartDate | date:'mediumDate'}}</span>

                    <span *ngIf="timeOffPolicySchedule?.accrualRateInterval?.id === 'Year'">
                        on {{getMonthText(timeOffPolicySchedule.yearlyMonth)}} {{timeOffPolicySchedule.yearlyDay}}{{ordinalDateSuffix(timeOffPolicySchedule.yearlyDay)}}
                    </span>

                    .<br>

                    <span *ngIf="timeOffPolicySchedule?.accrualRateInterval?.id === 'BiMonthly'">
                        On the {{timeOffPolicySchedule.biMonthlyFirstDay}}{{ordinalDateSuffix(timeOffPolicySchedule.biMonthlyFirstDay)}} day of the month and the
                        <span *ngIf="timeOffPolicySchedule?.biMonthlySecondDayEndOfMonth === true"> last day of the month.</span>
                        <span *ngIf="!timeOffPolicySchedule?.biMonthlySecondDayEndOfMonth === true"> {{timeOffPolicySchedule.biMonthlySecondDay}}{{ordinalDateSuffix(timeOffPolicySchedule.biMonthlySecondDay)}} day of the month.</span>
                        <br>
                    </span>


                    <span *ngIf="timeOffPolicySchedule?.accrualRateInterval?.id === 'Month'">
                        <span *ngIf="timeOffPolicySchedule?.monthlyDay">On the {{timeOffPolicySchedule.monthlyDay}}{{ordinalDateSuffix(timeOffPolicySchedule.monthlyDay)}} day of the month.</span>
                        <span *ngIf="timeOffPolicySchedule?.monthlyEndOfMonth === true">On the last day of the month.</span>
                        <span *ngIf="timeOffPolicySchedule?.monthlyOnHireDay === true">On the Hire Day.</span>
                        <br>
                    </span>

                    Maximum accrual of {{timeOffPolicySchedule.accrualMaximum}} hour(s).<br>
                    Maximum carry over amount of {{timeOffPolicySchedule.carryOverAmount}} hour(s).
                </p>
                <div>
                    <mat-icon
                            class="material-icons-outlined"
                            (click)="editSchedule(timeOffPolicySchedule); $event.stopPropagation();"
                            matTooltip="Edit"
                    >edit
                    </mat-icon>
                    <mat-icon class="material-icons-outlined" (click)="showDeleteScheduleConfirmation(timeOffPolicySchedule.id)" matTooltip="Delete">delete</mat-icon>
                </div>
            </div>

            <button mat-flat-button setColor="primaryColour" color="primary" (click)="addPolicySchedule()" class="k-mb-2">
                <mat-icon aria-hidden="false" class="material-icons-outlined white" aria-label="add icon">add</mat-icon>
                {{ 'AddSchedule' | translate: {Default: 'Add Schedule'} }}
            </button>
        </ng-container>

        <div class="accrual-schedule-form" [formGroup]="scheduleForm" *ngIf="editOrCreateScheduleMode">
            <div class="form-container">
                <p class="k-font-weight-bold">{{editTimeOffPolicySchedule ? 'Edit' : 'Add' }} Schedule</p>
                <ng-container>
                    <div class="form-row">
                        <mat-form-field appearance="fill" class="k-mr-3">
                            <mat-label>
                                {{ 'StartAt' | translate: {Default: 'Start at'} }}
                            </mat-label>

                            <input matInput
                                   type="number"
                                   min="0"
                                   max="99999"
                                   formControlName="startsAt"
                                   placeholder="1"
                            >
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>
                                {{ 'StartAtUnit' | translate: {Default: 'Start at Unit'} }}
                            </mat-label>

                            <mat-select disableOptionCentering formControlName="startAtUnit">
                                <mat-option *ngFor="let timeOffStartAtUnit of timeOffStartAtUnitList" [value]="timeOffStartAtUnit.id">{{timeOffStartAtUnit.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="form-row">
                        <mat-form-field appearance="fill" class="k-mr-3">
                            <mat-label>
                                {{ 'AccruedAtARateOf' | translate: {Default: 'Accrued at a rate of'} }}
                            </mat-label>

                            <input matInput
                                   type="number"
                                   min="0"
                                   max="99999"
                                   formControlName="accrualRate"
                                   placeholder="1"
                            >
                        </mat-form-field>

                        <mat-form-field appearance="fill" >
                            <mat-label>
                                {{ 'AccrualRateInterval' | translate: {Default: 'Accrual Rate Interval'} }}
                            </mat-label>

                            <mat-select disableOptionCentering formControlName="accrualRateInterval">
                                <mat-option *ngFor="let timeOffAccrualRateInterval of timeOffAccrualRateIntervalList" [value]="timeOffAccrualRateInterval.id">{{timeOffAccrualRateInterval.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill" *ngIf="!hideIntervalStartDate" class="k-ml-3">
                            <mat-label>
                                {{ 'IntervalStartDate' | translate: {Default: 'Interval Start Date'} }}
                            </mat-label>

                            <input matInput [matDatepicker]="intervalStartDatePicker" formControlName="intervalStartDate">
                            <span fxLayout="row" matSuffix>
                                <mat-datepicker-toggle [for]="intervalStartDatePicker"></mat-datepicker-toggle>
                            </span>
                            <mat-datepicker #intervalStartDatePicker></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="form-row" *ngIf="!hideYearlyMonthDay">
                        <mat-form-field appearance="fill">
                            <mat-label>
                                {{ 'YearlyMonth' | translate: {Default: 'Yearly Month'} }}
                            </mat-label>

                            <mat-select disableOptionCentering formControlName="yearlyMonth" >
                                <mat-option *ngFor="let month of months" [value]="month.value">{{month.text}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill"  class="k-ml-3">
                            <mat-label>
                                {{ 'YearlyDay' | translate: {Default: 'Yearly Day'} }}
                            </mat-label>

                            <mat-select disableOptionCentering formControlName="yearlyDay">
                                <mat-option *ngFor="let number of numberOfDays" [value]="number">{{number}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="form-row month-details" *ngIf="!hideMonthDetails">

                        <section class="example-section">
                            <label class="example-margin">{{ 'MonthlyAccrualDay' | translate: {Default: 'Monthly Accrual Day'} }}</label>
                            <mat-radio-group formControlName="monthlyAccrualDayRadioValue">
                                <mat-radio-button class="example-margin" value="monthlyEndOfMonth">{{ 'MonthlyEndOfMonth' | translate: {Default: 'End Of Month'} }}</mat-radio-button>
                                <mat-radio-button class="example-margin" value="monthlyOnHireDay">{{ 'MonthlyOnHireDay' | translate: {Default: 'On Hire Day'} }}</mat-radio-button>
                                <mat-radio-button class="example-margin" value="monthlyDay">{{ 'DayOfMonth' | translate: {Default: 'Day of Month'} }}</mat-radio-button>
                            </mat-radio-group>
                        </section>

                        <mat-form-field class="monthly-day-form-field" appearance="fill" *ngIf="scheduleForm.get('monthlyAccrualDayRadioValue').value === 'monthlyDay'">
                            <mat-label>
                                {{ 'DayOfMonth' | translate: {Default: 'Day of Month'} }}
                            </mat-label>

                            <input matInput type="number" min="1" max="28" formControlName="monthlyDay">

                            <span matTextSuffix>{{ordinalDateSuffix(scheduleForm.get('monthlyDay').value)}}</span>

                            <div class="error-text" *ngIf="scheduleFormControls.biMonthlyFirstDay.errors?.min">
                                {{ 'MustBeAtLeast' | translate: {Default: "Must be at least"} }} 1
                            </div>
                            <div class="error-text" *ngIf="scheduleFormControls.biMonthlyFirstDay.errors?.max">
                                {{ 'CannotBeMoreThan' | translate: {Default: "Cannot be more than"} }} 28
                            </div>
                        </mat-form-field>

                        <!-- <mat-form-field appearance="fill"  class="k-ml-3">
                            <mat-label>
                                {{ 'YearlyDay' | translate: {Default: 'Yearly Day'} }}
                            </mat-label>

                            <mat-select disableOptionCentering formControlName="yearlyDay">
                                <mat-option *ngFor="let number of numberOfDays" [value]="number">{{number}}</mat-option>
                            </mat-select>
                        </mat-form-field> -->
                    </div>

                    <div class="form-row" *ngIf="!hideBiMonthlyFormFields">
                        <mat-form-field appearance="fill" class="k-mr-3">
                            <mat-label>
                                {{ 'BiMonthlyFirstDate' | translate: {Default: 'Bi Monthly First Date'} }}
                            </mat-label>

                            <input matInput type="number" formControlName="biMonthlyFirstDay">

                            <div class="error-text" *ngIf="scheduleFormControls.biMonthlyFirstDay.errors?.min">
                                {{ 'MustBeAtLeast' | translate: {Default: "Must be at least"} }} 1
                            </div>
                            <div class="error-text" *ngIf="scheduleFormControls.biMonthlyFirstDay.errors?.max">
                                {{ 'CannotBeMoreThan' | translate: {Default: "Cannot be more than"} }} 31
                            </div>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="k-mr-3">
                            <mat-label>
                                {{ 'BiMonthlySecondDate' | translate: {Default: 'Bi Monthly Second Date'} }}
                            </mat-label>

                            <input matInput type="number" formControlName="biMonthlySecondDay">

                            <div class="error-text" *ngIf="scheduleFormControls.biMonthlySecondDay.errors?.min">
                                {{ 'MustBeAtLeast' | translate: {Default: "Must be at least"} }} 1
                            </div>
                            <div class="error-text" *ngIf="scheduleFormControls.biMonthlySecondDay.errors?.max">
                                {{ 'CannotBeMoreThan' | translate: {Default: "Cannot be more than"} }} 31
                            </div>
                        </mat-form-field>

                        <mat-slide-toggle formControlName="biMonthlySecondDayEndOfMonth">
                            {{ 'BiMonthlySecondDateEndOfMonth' | translate: {Default: 'Bi Monthly Second Date End Of Month'} }}
                        </mat-slide-toggle>
                    </div>

                    <div class="form-row">
                        <mat-form-field appearance="fill" class="k-mr-3">
                            <mat-label>
                                {{ 'MaximumAccrual' | translate: {Default: 'Maximum Accrual'} }}
                            </mat-label>

                            <input matInput
                                   type="number"
                                   min="0"
                                   max="99999"
                                   formControlName="accrualMaximum"
                                   placeholder="1"
                            >
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>
                                {{ 'CarryOverAmount' | translate: {Default: 'Carry Over Amount'} }}
                            </mat-label>

                            <input matInput
                                   type="number"
                                   min="0"
                                   max="99999"
                                   formControlName="carryOverAmount"
                                   placeholder="1"
                            >
                        </mat-form-field>
                    </div>

                    <div class="form-button-row k-mb-2">
                        <button class="mat-raised-button" (click)="discardScheduleChanges()">{{ 'DiscardSchedule' | translate: {Default: 'Discard Schedule'} }} {{editTimeOffPolicySchedule ? 'Changes' : '' }}</button>
                        <button mat-raised-button setColor="primaryColour" color="primary" (click)="saveSchedule()" [disabled]="!scheduleForm.valid">{{editTimeOffPolicySchedule ? 'Save Schedule Changes' : 'Add Schedule' }}</button>
                    </div>

                </ng-container>
            </div>
        </div>
    </ng-container>
</div>
