import { Component, Input, OnInit } from '@angular/core';
import { features } from '@app/consts';
import { FeatureService } from '@app/core/services/feature.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { GenerateShiftsSubmit } from '@app/modules/generate-schedules/models/generate-schedules.model';
import { GenerateScheduleService } from '@app/modules/generate-schedules/services/generate-schedule.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { SecurityProtectedBase } from '@app/shared/components/security-protected/security-protected';
import { TranslateService } from '@ngx-translate/core';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-generate-position-schedule',
  templateUrl: './generate-position-schedule.component.html',
  styleUrls: ['./generate-position-schedule.component.scss']
})
export class GeneratePositionScheduleComponent extends SecurityProtectedBase implements OnInit {
  @Input() positionId: string;
  startDate: string = null;
  endDate: string = null;
  
  constructor(
    private overlayService: OverlayService,
    private generateScheduleService: GenerateScheduleService,
    private translate: TranslateService,
    featureService: FeatureService,
    private snackbarService: SnackbarService,
  ) { 
    super(featureService, features.SCHEDULES)
  }

  ngOnInit(): void {
  }

  get isFormValid() {
    if(this.startDate !== null && this.endDate !== null) {
      return true;
    }

    return false;
  }

  save() {
    let generateShiftsSubmit: GenerateShiftsSubmit = {
      positions: [this.positionId],
      from: this.startDate,
      to: this.endDate
    }

    this.overlayService.show();

    this.generateScheduleService.generateShifts(generateShiftsSubmit)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      res => {
        this.snackbarService.openSnackBar(this.translate.instant('GeneratingShiftsJobStartedSuccessfully'), 'clear', 'success');
      }
    )
  }

}
