import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { GoalComment } from '../../../../goal-plans/models/goal-plan.model';

@Component({
  selector: 'app-goal-employee-comment',
  templateUrl: './goal-comment.component.html',
  styleUrls: ['./goal-comment.component.scss']
})
export class GoalCommentComponent implements OnInit {
  @Input() comment: GoalComment;
  
  @Output() emitDeleteComment: EventEmitter<void> = new EventEmitter<void>();
  @Output() emitUpdateComment: EventEmitter<void> = new EventEmitter<void>();

  formId: string = 'frm_0h2Z0sjoLVLB56';
  formData;
  formValid: boolean = false;
  formPristine: boolean = true;
  user$: any;
  showForm = false;
  getFormData: boolean = false;

  constructor(
    private oidcAuthService: OidcAuthService,
  ) { 
    this.user$ = this.oidcAuthService.userProfile;
  }

  ngOnInit(): void {
    this.formData = {
      id: this.comment?.id,
      comments: this.comment?.comments
    }
  }

  formDataEmitted(formDataEmitted) {
    this.emitUpdateComment.emit(formDataEmitted)
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  formPristineEmitted(formPristine: boolean) {
    this.formPristine = formPristine;
  }

}
