import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PdfViewerDialogComponent } from '@app/shared/components/pdf-viewer-dialog/pdf-viewer-dialog.component';

@Component({
  selector: 'app-pay-stubs-data-grid',
  templateUrl: './pay-stubs-data-grid.component.html',
  styleUrls: ['./pay-stubs-data-grid.component.scss']
})
export class PayStubsDataGridComponent implements OnInit {
  public gridData: any[] = [
    {
      payDate: 'July 1, 2021',
      totalGrossPay: '$3,653.85',
      taxes: '$934.70',
      deductions: '$185.62',
      netAmount: '$2,533.53',
      ytdNet: '$23,386.69',
    },
    {
      payDate: 'June 28, 2021',
      totalGrossPay: '$3,653.85',
      taxes: '$934.70',
      deductions: '$185.62',
      netAmount: '$2,533.53',
      ytdNet: '$20,853.16',
    },
    {
      payDate: 'June 4, 2021',
      totalGrossPay: '$3,653.85',
      taxes: '$934.70',
      deductions: '$185.62',
      netAmount: '$2,533.53',
      ytdNet: '$18,319.63',
    },
  ];

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  openPdfViewerDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '80%';

    dialogConfig.data = {
        pdfUrl: '/assets/adp-paystub-sample.pdf',
        signatureRequired: false,
        allowFormSave: false
    };

    const dialogRef = this.dialog.open(PdfViewerDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          // this.snackbarService.openSnackBar('Form saved successfully', 'clear', 'success');
        }
      }
    );  
  }

}
