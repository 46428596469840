import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  colors: any;
};

@Component({
  selector: 'app-diversity-benchmark-report',
  templateUrl: './diversity-benchmark-report.component.html',
  styleUrls: ['./diversity-benchmark-report.component.scss']
})
export class DiversityBenchmarkReportComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  @ViewChild("chart") groupChart: ChartComponent;
  public groupChartOptions: Partial<ChartOptions>;

  groupChartData = [
    {
      label: 'Manager Female %',
      period: '05/2020',
      a: '4',
      b: '5',
      c: '7',
      d: '8',
      e: '6',
      f: '6',
    },
    {
      label: 'America Indian or Alaska Native %',
      period: '05/2020',
      a: '5',
      b: '9',
      c: '4',
      d: '7',
      e: '5',
      f: '7',
    },
    {
      label: 'Asian %',
      period: '05/2020',
      a: '7',
      b: '4',
      c: '8',
      d: '12',
      e: '10',
      f: '4',
    },
    {
      label: 'Black or African American %',
      period: '05/2020',
      a: '8',
      b: '3',
      c: '10',
      d: '5',
      e: '9',
      f: '8',
    },
    {
      label: 'Hispanic or Latino %',
      period: '05/2020',
      a: '6',
      b: '2',
      c: '6',
      d: '4',
      e: '8',
      f: '12',
    },
    {
      label: 'Two or More Races %',
      period: '05/2020',
      a: '6',
      b: '5',
      c: '9',
      d: '4',
      e: '15',
      f: '8',
    },
  ];

  tableColumns = [
    { name: 'Benchmark', dataKey: 'label', isSortable: true, position: 'left' },
    { name: 'Period', dataKey: 'period', isSortable: true, position: 'left' },
    { name: '10th Percentile', dataKey: 'a', isSortable: true, position: 'left' },
    { name: '25th Percentile', dataKey: 'b', isSortable: true, position: 'left' },
    { name: '50th Percentile', dataKey: 'c', isSortable: true, position: 'left' },
    { name: '75th Percentile', dataKey: 'd', isSortable: true, position: 'left' },
    { name: '90th Percentile', dataKey: 'e', isSortable: true, position: 'left' },
    { name: 'Actual Value', dataKey: 'f', isSortable: true, position: 'left' },
  ];

  constructor() {
    this.chartOptions = {
      series: [
        {
          name: "Hires",
          data: [
            {
              x: 'Manager Female %',
              y: 12,
              goals: [
                {
                  name: 'Expected',
                  value: 14,
                  // strokeWidth: 203,
                  strokeColor: '#775DD0'
                }
              ]
            },
            {
              x: 'America Indian or Alaska Native %',
              y: 44,
              goals: [
                {
                  name: 'Expected',
                  value: 54,
                  strokeColor: '#775DD0'
                }
              ]
            },
            {
              x: 'Asian %',
              y: 54,
              goals: [
                {
                  name: 'Expected',
                  value: 52,
                  strokeColor: '#775DD0'
                }
              ]
            },
            {
              x: 'Black or African American %',
              y: 66,
              goals: [
                {
                  name: 'Expected',
                  value: 65,
                  strokeColor: '#775DD0'
                }
              ]
            },
            {
              x: 'Hispanic or Latino %',
              y: 81,
              goals: [
                {
                  name: 'Expected',
                  value: 66,
                  strokeColor: '#775DD0'
                }
              ]
            },
            {
              x: 'Two or More Races %',
              y: 67,
              goals: [
                {
                  name: 'Expected',
                  value: 70,
                  strokeColor: '#775DD0'
                }
              ]
            }
          ]
        }
      ],
      chart: {
        height: 350,
        type: 'bar'
      },
      plotOptions: {
        bar: {
          horizontal: false,
        }
      },
      colors: ['#00E396'],
      // dataLabels: {
      //   formatter: (val, opt) => {
      //     const goals =
      //       opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
      //         .goals;
      
      //     if (goals && goals.length) {
      //       return `${val} / ${goals[0].value}`;
      //     }
      //     return val;
      //   }
      // },
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: ['Actual', 'Expected'],
        markers: {
          fillColors: ['#008ffb', '#775DD0']
        }
      }
    };

    this.groupChartOptions = {
      series: [
        {
          name: "10th Percentile",
          data: [4, 5, 7, 6, 6, 8]
        },
        {
          name: "25th Percentile",
          data: [5,4,9,4,3,2]
        },
        {
          name: "50th Percentile",
          data: [7,4,8,10,6,9]
        },
        {
          name: "75th Percentile",
          data: [8,7,12,5,4,4]
        },
        {
          name: "90th Percentile",
          data: [6, 5, 10, 9, 8, 15]
        },
        {
          name: "Actual Value",
          data: [6,7,4,8,12,8]
        }
      ],
      chart: {
        type: "bar",
        height: 350,
        width: '100%',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
          "Manager Female %",
          "America Indian or Alaska Native %",
          "Asian %",
          "Black or African American %",
          "Hispanic or Latino %",
          "Two or More Races %",
        ]
      },
      yaxis: {
        
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val.toString();
          }
        }
      }
    };
   }

  ngOnInit(): void {
  }

}
