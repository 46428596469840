import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { features } from '@app/consts';
import { FeatureService } from '@app/core/services/feature.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { SecurityProtectedBase } from '@app/shared/components/security-protected/security-protected';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { CountryConfiguration } from './models/country-configuration.model';
import { CountryConfigurationService } from './services/country-configuration.service';

@Component({
  selector: 'app-country-configuration',
  templateUrl: './country-configuration.component.html',
  styleUrls: ['./country-configuration.component.scss']
})
export class CountryConfigurationComponent extends SecurityProtectedBase implements OnInit {
  public bindingType: String = 'array';
  public gridDataResult: GridDataResult;
  isLoading: boolean;
  pageSize: number = 20;
  skip: number = 0;
  filterString: string;
  sortString: string = 'name-asc';
  formId: string = 'frm_0iVw7hpkMzUMm6';
  changeReasonFormId: string = 'frm_4shlPLavT0CtkO';
  dialogRef: any;
  formValid: any;
  searchFilterString: string;
  getCountryConfigurationsRequest: any;
  searchValue: any;

  public columns: any[] = [
    {
      field: 'name',
      title: 'Name',
      dataType: "String"
    },
    {
      field: 'workHoursPerDay',
      title: 'Work Hours Per Day',
      dataType: "String"
    },
    {
      field: 'workDaysPerWeek',
      title: 'Work Days Per Week',
      dataType: "String"
    },
    {
      field: 'hoursPerWeek',
      title: 'Hours Per Week',
      dataType: "String"
    },
  ];

  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    public router: Router,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private countryConfigurationService: CountryConfigurationService,
    featureService: FeatureService
  ) {
    super(featureService, features.COUNTRY_CONFIG)
  }

  ngOnInit(): void {
    this.getCountryConfigs();
  }

  getCountryConfigs(): void {
    this.isLoading = true;

    this.getCountryConfigurationsRequest = this.countryConfigurationService.getCountries(this.skip, String(this.pageSize), this.searchFilterString, this.sortString)
    .pipe(
        finalize(() => {
            this.isLoading = false;
        })
    )
    .subscribe(
        res => {
            this.gridDataResult = {
                data: res.data,
                total: res.total,
            };
        }
    );
  }

  search(searchValue) {
    this.searchValue = searchValue;
    this.searchFilterString = `name like "${this.searchValue}"`;
    this.getCountryConfigurationsRequest.unsubscribe();
    this.getCountryConfigs();
  }

  public pageChange(event: PageChangeEvent): void {
      this.skip = event.skip;
      this.pageSize = event.take;
      this.getCountryConfigs();
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;;
    this.getCountryConfigs();
  }

  openFormDialog(countryConfiguration: CountryConfiguration) {
    let formData = {
      id: countryConfiguration.id,
      asOf: moment().format(),
      changeReason: '',
      changeReasonComments: '',
      name: countryConfiguration.name,
      workHoursPerDay: countryConfiguration.workHoursPerDay,
      workDaysPerWeek: countryConfiguration.workDaysPerWeek,
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formTitle: `${this.translate.instant('CountryConfiguration')}`,
      formId: this.formId,
      changeReasonFormId: this.changeReasonFormId,
      formData: formData
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      this.save(event)
    });
  }

  formDataEmitted(formData) {
    this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  save(formData: any){
    this.overlayService.show();

    this.countryConfigurationService.updateCountryConfig(formData.id, formData)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
        this.dialogRef.close(true);
        this.getCountryConfigs();
      }
    );

  }

}
