import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { routes } from '@app/consts';

@Component({
  selector: 'app-edit-form-v3',
  templateUrl: './edit-form-v3.component.html',
  styleUrls: ['./edit-form-v3.component.scss']
})
export class EditFormV3Component implements OnInit {
  public routes: typeof routes = routes;
  formId: string;

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.formId = this.getIdInURL();
  }

  getIdInURL(): string {
    let IdInURL: string;
    
    this.route.paramMap.subscribe(
      params => IdInURL = params.get("id")
    )
      
    return IdInURL;
  }

}
