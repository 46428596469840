import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { api_routes, db_tables } from '@app/consts';
import { SettingsService } from '@app/core/services/settings.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { ChangeReasonDialogComponent } from '@app/shared/components/change-reason-dialog/change-reason-dialog.component';
import { FormGeneratorComponent } from '@app/shared/components/form-generator/form-generator.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { AccessLogsDialogComponent } from '../../../employment-records/components/employment-record-details/components/access-logs-dialog/access-logs-dialog.component';
import { HistoryDialogComponent } from '../../../employment-records/components/employment-record-details/components/history-dialog/history-dialog.component';
import { EmployeePayrollDetail } from './models/employee-payroll-details.model';
import { EmployeePayrollDetailsService } from './services/employee-payroll-details.service';

@Component({
  selector: 'app-employee-payroll-details',
  templateUrl: './employee-payroll-details.component.html',
  styleUrls: ['./employee-payroll-details.component.scss']
})
export class EmployeePayrollDetailsComponent implements OnInit {
  @ViewChild(FormGeneratorComponent) formGeneratorComponent: FormGeneratorComponent
  employeeID: string;
  form: UntypedFormGroup;
  payrollDetails: EmployeePayrollDetail;
  isLoading: boolean = true;
  formData: any;
  formId: string = 'frm_qWsGdG14rVuwEB';
  changeReasonFormId: string = 'frm_7ezj9pBIw0cI4D';
  getFormData: boolean = false;
  formValid: boolean = false;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];
  isLoadingPermissions: boolean = true;
  effectiveDate = new Date();

  public columns: any[] = [
    {field: 'payrollCode', subField: 'text', title: 'payrollCode', tableId: "tfi_PayrollCode"},
    {field: 'payrollNumber', title: 'payrollNumber', tableId: "tfi_PayrollNumber"},
    {field: 'payrollUdtf1', title: 'payrollUdtf1', tableId: "tfi_PayrollUdtf1"},
    {field: 'payrollUdtf2', title: 'payrollUdtf2', tableId: "tfi_PayrollUdtf2"},
    {field: 'payrollUdf1', subField: 'text', title: 'payrollUdf1', tableId: "tfi_PayrollUdf1"},
    {field: 'payrollUdf2', subField: 'text', title: 'payrollUdf2', tableId: "tfi_PayrollUdf2"},
    {field: 'payrollUdf3', subField: 'text', title: 'payrollUdf3', tableId: "tfi_PayrollUdf3"},
    {field: 'payrollUdf4', subField: 'text', title: 'payrollUdf4', tableId: "tfi_PayrollUdf4"},
    {field: 'payrollUdf5', subField: 'text', title: 'payrollUdf5', tableId: "tfi_PayrollUdf5"},
    {field: 'payrollUddf1', title: 'payrollUddf1', tableId: "tfi_PayrollUddf1"},
    {field: 'payrollUddf2', title: 'payrollUddf2', tableId: "tfi_PayrollUddf2"},
    {field: 'payElementType', subField: 'text', title: 'payElementType', tableId: "tfi_PayElementType"},
    {field: 'payChangeReason', subField: 'text', title: 'payChangeReason', tableId: "tfi_PayChangeReason"},
    {field: 'payFromDate', title: 'payFromDate', type: 'date', tableId: "tfi_PayFromDate"},
    {field: 'fixedEndDate', title: 'fixedEndDate', type: 'date', tableId: "tfi_FixedEndDate"},
    {field: 'legacyId', title: 'legacyId', tableId: "tfi_LegacyId"},
    {field: 'taxId', title: 'taxId', tableId: "tfi_TaxId"},
    {field: 'taxIdSecondary', title: 'taxIdSecondary', tableId: "tfi_TaxIdSecondary"},
  ];

  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private employeePayrollDetailsService: EmployeePayrollDetailsService,
    private tablePermissionsService: TablePermissionsService,
    private route: ActivatedRoute,
  ) {
    this.route.paramMap.subscribe(
        params => this.employeeID = params.get("id")
    )
  }

  ngOnInit(): void {
    this.getEmployeePayrollDetails()
    this.getTargetEmployeeTablePermissions();
  }

  get tableReadAccessObj() {
    return {
      tableId: db_tables.PayrollDetails,
      permission: 'read',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableCreateAccessObj() {
    return {
      tableId: db_tables.PayrollDetails,
      permission: 'create',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableUpdateAccessObj() {
    return {
      tableId: db_tables.PayrollDetails,
      permission: 'update',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableDeleteAccessObj() {
    return {
      tableId: db_tables.PayrollDetails,
      permission: 'delete',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  getTargetEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeID);

    // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
    if(currentFetchedData !== null){
      this.targetEmployeeTablePermissions = currentFetchedData;
      this.isLoadingPermissions = false;
    }
    else {
      this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeID)
      .pipe(
        finalize(() => {
            this.isLoadingPermissions = false;
        })
      )
      .subscribe(
        res => {
          this.targetEmployeeTablePermissions = res;
        }
      );
    }
  }

  getEmployeePayrollDetails() {
    this.isLoading = true;
    let asOf = moment(this.effectiveDate).format('YYYY-MM-DD');

    this.isLoading = true;

    this.employeePayrollDetailsService.getPayrollDetails(this.employeeID, asOf)
    .pipe(
        finalize(() => {
            this.isLoading = false;
            this.createForm();
        })
    )
    .subscribe(
        res => {
            this.payrollDetails = res;
        }
    );
  }

  createForm() {
    this.formData = {
      asOf: moment().format(),
      changeReason: '',
      changeReasonComments: '',

      payrollCode: this.payrollDetails ? this.payrollDetails?.payrollCode?.id : null,
      payrollNumber: this.payrollDetails ? this.payrollDetails?.payrollNumber : null,
      payrollUdtf1: this.payrollDetails ? this.payrollDetails?.payrollUdtf1 : null,
      payrollUdtf2: this.payrollDetails ? this.payrollDetails?.payrollUdtf2 : null,
      payrollUdf1: this.payrollDetails ? this.payrollDetails?.payrollUdf1?.id : null,
      payrollUdf2: this.payrollDetails ? this.payrollDetails?.payrollUdf2?.id : null,
      payrollUdf3: this.payrollDetails ? this.payrollDetails?.payrollUdf3?.id : null,
      payrollUdf4: this.payrollDetails ? this.payrollDetails?.payrollUdf4?.id : null,
      payrollUdf5: this.payrollDetails ? this.payrollDetails?.payrollUdf5?.id : null,
      payrollUddf1: this.payrollDetails ? this.payrollDetails?.payrollUddf1 : null,
      payrollUddf2: this.payrollDetails ? this.payrollDetails?.payrollUddf2 : null,
      payElementType: this.payrollDetails ? this.payrollDetails?.payElementType?.id : null,
      payChangeReason: this.payrollDetails ? this.payrollDetails?.payChangeReason?.id : null,
      payFromDate: this.payrollDetails ? this.payrollDetails?.payFromDate : null,
      fixedEndDate: this.payrollDetails ? this.payrollDetails?.fixedEndDate : null,
      legacyId: this.payrollDetails ? this.payrollDetails?.legacyId : null,
      taxId: this.payrollDetails ? this.payrollDetails?.taxId : null,
      taxIdSecondary: this.payrollDetails ? this.payrollDetails?.taxIdSecondary : null,
      payrollNextNumber: this.payrollDetails ? this.payrollDetails?.payrollNextNumber : null,
    };
  }

  formDataEmitted(formData) {
      this.openChangeReasonDialog(formData);
  }

  formStatusUpdated(formValid) {
      this.formValid = formValid;
  }

  openChangeReasonDialog(formData: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formId: this.changeReasonFormId,
    };

    const dialogRef = this.dialog.open(ChangeReasonDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
          if (data) {
              this.addChangeReasonsToFormData(formData, data);
          }

      }
    );
  }

  addChangeReasonsToFormData(formData: any, changeReasonFormData: any) {
    let merged = {...formData, ...changeReasonFormData};
    this.save(merged);
  }

  save(formData: any) {
    formData.asOf = this.effectiveDate;
    formData.originalCreatedOn = this.payrollDetails?.version?.createdOn ? this.payrollDetails?.version?.createdOn : null;

      this.overlayService.show();

      this.employeePayrollDetailsService.updatePayrollDetails(this.employeeID, formData)
          .pipe(
              finalize(() => {
                  this.overlayService.hide();
              })
          )
          .subscribe(
              (res) => {
                this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
                this.getEmployeePayrollDetails()
              }
          );
  }

  openAccessLogsDialog() {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
          employeeId: this.employeeID,
          entityId: this.employeeID,
          url: api_routes.TALENT + '/' + this.employeeID + '/' + api_routes.PAYROLL_DETAIL + '/' + this.employeeID ,
      };

      const dialogRef = this.dialog.open(AccessLogsDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
          data => {
          }
      );
  }

  openHistoryDialog() {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
          employeeId: this.employeeID,
          entityId: null,
          url: api_routes.TALENT + '/' + this.employeeID + '/' + api_routes.PAYROLL_DETAIL,
          columns: this.columns,
          tableId: db_tables.PayrollDetails,
      };

      const dialogRef = this.dialog.open(HistoryDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
          data => {
          }
      );
  }

  effectiveDateChange(event) {
    if(this.payrollDetails){
        this.getEmployeePayrollDetails();
    }
  }

}

