<time-off-menu>
    <div class="main-container" *ngIf="hasFeatureAccess">
        <ng-container *ngIf="isLoading; else loaded">
            <ngx-skeleton-loader
                count="5"
                [theme]="{
                    'height.px': 50
                }"
            ></ngx-skeleton-loader>
        </ng-container>
        <ng-template #loaded>
            <div class="content" *ngIf="!isLoading">
    
                <div class="main-content k-p-5">
                    <div *ngIf="this.viewState == eTimeOffViewState.ADD_TYPE">
                        <add-time-off [refreshListener]="this"></add-time-off>
                    </div>
    
                    <div *ngIf="this.viewState == eTimeOffViewState.EDIT_TYPE">
                        <edit-time-off  [timeOffTypeId]="timeOffTypeId" [refreshListener]="this"></edit-time-off>
                    </div>
    
                    <div *ngIf="this.viewState == eTimeOffViewState.POLICY">
                        <time-off-policy [timeOffType]="timeOffType" [timeOffPolicyId]="timeOffPolicyId" [timeOffTypeList]="timeOffTypeList" [refreshListener]="this"></time-off-policy>
                    </div>
    
    
                    <div *ngIf="this.viewState == eTimeOffViewState.LIST_TYPES">
    
                        <div class="k-d-flex k-d-flex-row k-mb-3">
                            <h5>{{ 'TimeOff' | translate: {Default: "Time Off"} }}</h5>
                            <div class="k-flex-grow"></div>
                            <div class="buttons-container">
                                <button mat-flat-button setColor="primaryColour" color="primary" (click)="goToTimeOffPolicy(null,null)" class="k-mr-4">
                                    <mat-icon aria-hidden="false" class="material-icons-outlined white" aria-label="add icon">add</mat-icon>
                                    Add Policy
                                </button>
    
                                <button mat-flat-button setColor="primaryColour" color="primary" (click)="goToAdd()">
                                    <mat-icon aria-hidden="false" class="material-icons-outlined white" aria-label="add icon">add</mat-icon>
                                    Add Time Off Type
                                </button>
                            </div>
                        </div>
    
                        <div class="list-types-container">
                            <mat-form-field class="search-field" appearance="outline" >
                                <input matInput
                                       [(ngModel)]="timeOffTypeSearchValue"
                                       placeholder="Search...">
                                <mat-icon matPrefix class="material-icons-outlined">
                                    search
                                </mat-icon>
                            </mat-form-field>
    
                            <div class="cards-container">
                                <mat-card *ngFor="let timeOffType of timeOffTypeList | timeOffTypeSearch:timeOffTypeSearchValue">
                                    <mat-card-title class="header">
                                        <img *ngIf="timeOffType.image" [src]="domSanitizer.bypassSecurityTrustUrl(timeOffType.image)" class="timeoff-type-icon"/>
                                        <h5 
                                            setColor
                                            [setColorRules]="[
                                                {
                                                    color: 'primaryColour',
                                                    events: ['hover']
                                                }
                                            ]"
                                            class="title" 
                                            (click)="goToEdit(timeOffType.id)"
                                        >{{timeOffType.name}}</h5>
    
                                        <div class="k-flex-grow"></div>
    
                                        <div>
                                            <mat-icon 
                                                setColor
                                                [setColorRules]="[
                                                    {
                                                        color: 'primaryColour',
                                                        events: ['hover']
                                                    }
                                                ]"
                                                class="material-icons-outlined" 
                                                (click)="showDeleteTypeConfirmation(timeOffType)" 
                                                matTooltip="Delete"
                                            >delete</mat-icon>
                                        </div>
                                    </mat-card-title>
                                    <mat-card-content>
                                        <p *ngIf="timeOffType.policies.length == 0">No policies have been created for this time off type yet...</p>
    
                                        <app-data-grid-comp
                                            *ngIf="timeOffType.policies.length > 0"
                                            [columns]="columns"
                                            [gridDataResult]="timeOffType.policies"
                                            [rowSelectionSettings]="{
                                                canSelectRows: true,
                                                selectableMode: 'multiple',
                                                selectRowBy: 'id'
                                            }"
                                            clickableRows="true"
                                            hideActionButtons="true"
                                            [pageSettings] = "{
                                                info: false,
                                                pageSizes: false,
                                                previousNext: false
                                              }"
                                            [filterCategories]="filterCategories"
                                            [clearSelectedItems]="clearSelectedItems"
                                            (emitDeleteEvent)="deleteAllSelected(timeOffType, $event)"
                                            (emitOpenDialogEvent)="goToTimeOffPolicy(timeOffType, $event)"
                                        ></app-data-grid-comp>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    
    </div>
    
    <no-feature-access *ngIf="!hasFeatureAccess"></no-feature-access>
</time-off-menu>
