import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { api_routes } from '@app/consts';
import { EmployeeOtherCompensationSubmit, EmployeeOtherCompensationVerbose } from '../models/employee-other-compensation.model';
import { EnvironmentService } from "@app/core/services/environment.service";


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class EmployeeOtherCompensationService {

  constructor(private http: HttpClient, private envService: EnvironmentService) {}

  getOtherCompensations(employeeId: string, take?: string, skip?: number): Observable<any> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.OTHER_COMPENSATIONS}`, {
      headers: headers,
      params: params
    })
    .pipe(
      catchError(err => throwError(err)),
      map(res => {
        let data = {
          data: res.data,
          total: res.total
        }

        return data;
      })
    );
  }

  deleteOtherCompensation(employeeId: string, id: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.OTHER_COMPENSATIONS}/${id}`, {
      headers: headers
    });
  }

  getOtherCompensation(employeeId: string, id: string): Observable<EmployeeOtherCompensationVerbose> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.OTHER_COMPENSATIONS}/${id}`, {
      headers: headers
    });
  }

  updateOtherCompensation(employeeId: string, employeeOtherCompensation: EmployeeOtherCompensationSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.OTHER_COMPENSATIONS}/${employeeOtherCompensation.id}`, employeeOtherCompensation, {
      headers: headers
    });
  }

  createOtherCompensation(employeeId: string, employeeOtherCompensation: EmployeeOtherCompensationSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.OTHER_COMPENSATIONS}`, employeeOtherCompensation, {
      headers: headers
    });
  }
}

