import { Injectable } from '@angular/core';
import { ExcelExportEvent } from '@progress/kendo-angular-grid';

@Injectable({
  providedIn: 'root'
})
export class SanitiseGridDataService {

  constructor() { }

  // Add a single quote to the beginning of each string to prevent formula injection when exporting to excel file
  sanitiseData(data: any) {
    if(Array.isArray(data)){
      data.forEach(
        element => {

          if(typeof element === 'object' && element !== null) {
            for (const [key, value] of Object.entries(element)) {
              if(typeof value === 'string') {
                element[key] = `'${value}`;
              }
              else if(typeof value === 'object' && value !== null) {
                element[key] = this.sanitiseData(value);
              }
            }
          }

          else if(Array.isArray(element)){
            element.forEach(
              x => {
                if(typeof x === 'object' && x !== null) {
                  for (const [key, value] of Object.entries(x)) {
                    if(typeof value === 'string') {
                      x[key] = `'${value}`;
                    }
                    else if(typeof value === 'object' && value !== null) {
                      x[key] = this.sanitiseData(value);
                    }
                  }
                }
              }
            )
          }

        }
      );
    }

    else if(typeof data === 'object' && data !== null){
      for (const [key, value] of Object.entries(data)) {
        if(typeof value === 'string') {
          data[key] = `'${value}`;
        }
        else if(typeof value === 'object' && value !== null) {
          data[key] = this.sanitiseData(value);
        }
      }
    }

    return data;
  }

  sanitise(e: ExcelExportEvent) {
    let workbook = e.workbook;
    let sheet = workbook.sheets[0];
    let evilCharacters = ["=", "+", "-", "@", "\r", "\t"]

    for (var i = 0; i < sheet.rows.length; i++) {
      for (var ci = 0; ci < sheet.rows[i].cells.length; ci++) {
        if(typeof sheet.rows[i].cells[ci].value === "string"){
           // replace the undesired literals with single quote
           if(evilCharacters.includes(sheet.rows[i].cells[ci].value[0])) {
             sheet.rows[i].cells[ci].value = "'" + sheet.rows[i].cells[ci].value;
           }
        }
      }
    }

    return e;
  }
}
