import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@app/core/services/environment.service';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class HistoryDialogService {

  constructor(
    private http: HttpClient,
    private envService: EnvironmentService
  ) { }

  getHistory(url: string, skip?: number, take?: string): Observable<PagedData<any>> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${url}`, {
      headers: headers,
      params: params
    }).pipe(
      tap(res => {
        res.data.forEach(row => {
          if (row.version != null) {
            row.version.createdOn = row.version.createdOn ? (moment(row.version.createdOn).format('MMM-DD-YYYY h:mm:ss a')) : null
            row.version.effectiveDate = row.version.effectiveDate ? (moment(row.version.effectiveDate).format('MMM-DD-YYYY')) : null
            row.version.createdBy.fullName = row.version.createdBy.firstName + " " + row.version.createdBy.lastName
          }
        })
        return res;
      })
    );
  }
}
