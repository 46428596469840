import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { api_routes, db_tables } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { defer, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Goal, GoalSubmit, GoalVerbose } from '../../models/goal-plan.model';
import { GoalPlanService } from '../../services/goal-plan.service';

@Component({
  selector: 'app-goal-plan-employee-goals',
  templateUrl: './goal-plan-employee-goals.component.html',
  styleUrls: ['./goal-plan-employee-goals.component.scss']
})
export class GoalPlanEmployeeGoalsComponent implements OnInit {
  @Input() goalPlanId: string;
  public columns: any[] = [
    { field: "employee", tableId: "", title: "Employee", type: "employee" },
    { field: "objective", tableId: "tfi_PGPgObjective", title: "Objective" },
    { field: "description", tableId: "tfi_PGPgDescription", title: "Description", type: "longText", longTextLimit: 200 },
    { field: "expectedCompletionDate", tableId: "tfi_PGPgExpectedCompletionDate", title: "Expected Completion Date", type: "date" },
    { field: "actualCompletionDate", tableId: "tfi_PGPgActualCompletionDate", title: "Actual Completion Date", type: "date" },
    { field: "goalType", subField: "name", tableId: "tfi_PGPgGoalType", title: "Goal Type",},
    { field: "percentageComplete", tableId: "tfi_PGPgPercentageComplete", title: "Percentage Complete", type: "percentage", percentageMaxValue: 1 },
    { field: "weight", tableId: "tfi_PGPgWeight", title: "Weight", type: "percentage", percentageMaxValue: 1 },
  ];

  filterCategories: any[] = [
    { field: "objective", tableId: "tfi_PGPgObjective", title: "Objective", type: "string", dataType: 'String' },
    { field: "description", tableId: "tfi_PGPgDescription", title: "Description", type: "string", dataType: 'String' },
    { field: "expectedCompletionDate", tableId: "tfi_PGPgExpectedCompletionDate", title: "Expected Completion Date", type: "date", dataType: 'Date'},
    { field: "actualCompletionDate", tableId: "tfi_PGPgActualCompletionDate", title: "Actual Completion Date", type: "date", dataType: 'Date'},
    { field: "GoalTypeId", subField: "name", tableId: "tfi_PGPgGoalType", title: "Goal Type", dataType: "SpecialLookup", lookupCode: 'GOAL_TYPE'},
    { field: "GoalPlanId", subField: "name", tableId: "tfi_PGPgParentGoal", title: "Goal Plan", dataType: "SpecialLookup", lookupCode: 'GOAL_PLAN'},
  ];

  sortableColumns: any[] = [
    { field: "objective", sortValue: "objective"},
    { field: "description", sortValue: "description"},
    { field: "expectedCompletionDate", sortValue: "expectedCompletionDate"},
    { field: "actualCompletionDate", sortValue: "actualCompletionDate"},
    { field: "goalType", sortValue: "goalTypeName"},
    { field: "goalPlan", sortValue: "goalPlanName"},
    { field: "percentageComplete", sortValue: "percentageComplete"},
    { field: "weight", sortValue: "weight"},
  ];

  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 5;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  dialogRef: any;
  formValid: any;
  sortString: string;
  searchValue: string;
  searchFilterString: string;
  filterString: any;
  getGoalsRequest: any;

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private router: Router,
    private overlayService: OverlayService,
    private goalService: GoalPlanService
  ) { }

  ngOnInit(): void {
    this.view = this.getGoals();
  }

  getGoals() {
    this.isLoading = true;

    let filter = "(employee.id != null)";
    if(this.searchFilterString) {
      filter = `${filter} AND ${this.searchFilterString}`;
    }
    if(this.filterString) {
      filter = `${filter} AND ${this.filterString}`;
    }

    this.getGoalsRequest = this.goalService.getGoals(this.goalPlanId, String(this.pageSize), this.skip, this.sortString, filter)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          }
          this.gridData = res.data;
        }
      );
  }

  getGoalVerbose(goal?: Goal) {
    if(goal === undefined){
      this.openGoalDialog();
    }
    else {
      let goalVerbose: GoalVerbose;

      this.isLoading = true;

      this.goalService.getGoal(this.goalPlanId, goal.id)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        res => {
          goalVerbose = res;
          this.openGoalDialog(goalVerbose);
        }
      );
    }
  }

  openGoalDialog(goal?: GoalVerbose) {
    let formData = {
      id: goal ? goal.id : null,
      objective: goal ? goal.objective : null,
      description: goal ? goal.description : null,
      percentageComplete: goal ? goal.percentageComplete : null,
      weight: goal ? goal.weight : null,
      expectedCompletionDate: goal ? goal.expectedCompletionDate : null,
      actualCompletionDate: goal ? goal.actualCompletionDate : null,
      goalTypeId: goal ? goal.goalType?.id : null,
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formTitle: `${this.translate.instant('Goal')}`,
      formId: 'frm_b0icGslD8kiybK',
      formData: formData
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      goal ? this.updateGoal(event) : this.createNewGoal(event)
    });
  }

  // openGoalDialog(goal?: GoalVerbose) {
  //   const dialogConfig = new MatDialogConfig();

  //   dialogConfig.disableClose = true;
  //   dialogConfig.autoFocus = true;

  //   dialogConfig.data = {
  //     goal: goal,
  //     goalPlanId: this.goalPlanId
  //   };

  //   this.dialogRef = this.dialog.open(GoalDialogComponent, dialogConfig);

  //   this.dialogRef.afterClosed().subscribe(
  //     data => {
  //       if (data === true) {
  //         this.getGoals();
  //       }
  //     }
  //   );

  // }

  createNewGoal(formData: GoalSubmit){
    this.overlayService.show();

    this.goalService.createGoal(this.goalPlanId, formData)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(res);
          this.getGoals();
        }
    );
  }

  updateGoal(formData: GoalSubmit){
    this.overlayService.show();

    this.goalService.updateGoal(this.goalPlanId, formData)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(res);
          this.getGoals();
        }
    );
  }

  deleteGoals(goalIds: string[]) {
    const observables = goalIds.map(selectedItem => defer(() => this.goalService.deleteGoal(this.goalPlanId, selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.view = this.getGoals();
        this.clearSelectedItems = !this.clearSelectedItems;
        this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.view = this.getGoals();
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;

    let variants = this.searchValue.split(' ').filter(value => value);
    this.searchFilterString = '';
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `(Objective like "${variant}") OR (Description like "${variant}")`;
        }
    });

    this.getGoalsRequest.unsubscribe();
    this.getGoals();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getGoals();
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;;
    this.getGoals();
  }
}

