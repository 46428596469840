<div class="goal-comments-container" *ngIf="comments$ | withLoading | async as comments">
    <h6>{{ 'Comments' | translate: {Default: "Comments"} }}</h6>

    <div class="top">
        <ng-template [ngIf]="comments.value">
            <div class="no-data" *ngIf="comments.value.data.length === 0">
                <mat-icon aria-hidden="false" class="material-icons-outlined">chat</mat-icon>
    
                <h5>{{ 'NoGoalComments' | translate: {Default: "No Comments"} }}</h5>
            </div>
    
            <mat-list>
                <ng-container *ngFor="let comment of comments.value.data">
                    <app-goal-employee-comment
                        [comment]="comment"
                        (emitDeleteComment)="openConfirmDeleteDialog($event)"
                        (emitUpdateComment)="updateComment($event)"
                    ></app-goal-employee-comment>
                </ng-container>
            </mat-list>
        </ng-template>
        <ng-template [ngIf]="comments.error">Error {{ obs.error }}</ng-template>
        <ng-template [ngIf]="comments.loading">
            <ngx-skeleton-loader
                count="5"
                [theme]="{ 
                    'height.px': 50
                }"
            ></ngx-skeleton-loader>
        </ng-template>

    </div>

    <div class="comment-form">
        <app-form-generator 
            [formId]="formId"
            [formData]="formData"
            [emitFormDataTrigger]="getFormData"
            skeletonLoadingRows="1"
            (emitFormData)="formDataEmitted($event)"
            (emitFormStatus)="formStatusUpdated($event)"
            (emitFormPristine)="formPristineEmitted($event)"
        ></app-form-generator>
        <button 
            mat-raised-button 
            setColor="primaryColour" 
            color="primary" 
            appPreventDoubleClick 
            (throttledClick)="getFormData = !getFormData" 
            [disabled]="formValid === false"
        >{{ 'SaveComment' | translate: {Default: "Save Comment"} }}</button>
    </div>
</div>