<app-layout>
    <div class="content">
        <mat-card class="revenue-chart">
            <mat-card-title class="revenue-chart__header">
              <h5 class="content__header-title" appLocalizationPopupDirective localizationCode="DiversityAnalysis">{{ 'DiversityAnalysis' | translate: {Default: "Diversity Analysis"} }}</h5>
              
              <app-settings-menu></app-settings-menu>
            </mat-card-title>
            
            <mat-card-content class="revenue-chart__content">
                <div id="chart">
                    <apx-chart
                      [series]="chartOptions.series"
                      [chart]="chartOptions.chart"
                      [dataLabels]="chartOptions.dataLabels"
                      [plotOptions]="chartOptions.plotOptions"
                      [yaxis]="chartOptions.yaxis"
                      [legend]="chartOptions.legend"
                      [fill]="chartOptions.fill"
                      [stroke]="chartOptions.stroke"
                      [tooltip]="chartOptions.tooltip"
                      [xaxis]="chartOptions.xaxis"
                    ></apx-chart>
                </div>

                <div id="groupChartOptions">
                    <apx-chart
                      [series]="groupChartOptions.series"
                      [chart]="groupChartOptions.chart"
                      [dataLabels]="groupChartOptions.dataLabels"
                      [plotOptions]="groupChartOptions.plotOptions"
                      [yaxis]="groupChartOptions.yaxis"
                      [legend]="groupChartOptions.legend"
                      [fill]="groupChartOptions.fill"
                      [stroke]="groupChartOptions.stroke"
                      [tooltip]="groupChartOptions.tooltip"
                      [xaxis]="groupChartOptions.xaxis"
                    ></apx-chart>
                </div>

                <app-table
                    [tableData]="groupChartData"
                    [tableColumns]="tableColumns"
                    [isFilterable]="false"
                    [isPageable]="false"
                ></app-table>

              <!-- <div class="leave-chart-container">
                  <div class="revenue-chart__content-chart" echarts [options]="chart"></div>
                  <div class="chart-figures">
                      <div *ngFor="let item of labelData" class="row">
                          <div class="dot" [ngStyle]="{'background-color': item.color}"></div>
                          <div class="details">
                              <div class="label"> {{item.label}}</div>
                          </div>
                      </div>
                  </div>
              </div>
        
              <app-table
                    [tableData]="labelData"
                    [tableColumns]="tableColumns"
                    [isFilterable]="false"
                    [isPageable]="false"
                ></app-table>  -->
            </mat-card-content>
          </mat-card>
    </div>

</app-layout>