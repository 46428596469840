import { Component, OnInit } from '@angular/core';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { IPDFViewerApplication } from 'ngx-extended-pdf-viewer';

@Component({
  selector: 'app-onboarding-demo',
  templateUrl: './onboarding-demo.component.html',
  styleUrls: ['./onboarding-demo.component.scss']
})
export class OnboardingDemoComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
