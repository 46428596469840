import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { features, routes } from '@app/consts';
import { FeatureService } from '@app/core/services/feature.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-site-settings-styling',
  templateUrl: './site-settings-styling.component.html',
  styleUrls: ['./site-settings-styling.component.scss']
})
export class SiteSettingsStylingComponent implements OnInit {
  public routes: typeof routes = routes;
  navList = [
      {
          text: 'StylingColours',
          link: `${routes.SITE_SETTINGS}${routes.STYLING}${routes.STYLING_COLOURS}`,
          icon: 'email',
          featureCode: features.SITE_STYLING
      },
      {
          text: 'CompanyLogos',
          link: `${routes.SITE_SETTINGS}${routes.STYLING}${routes.COMPANY_LOGOS}`,
          icon: 'email',
          featureCode: features.SITE_STYLING
      },
      
  ];

  constructor(
      public translate: TranslateService,
      public router: Router,
      private featureService: FeatureService,
  ) {}

  ngOnInit(): void {
      this.navigateToFirstAllowedSection();
  }

  navigateToFirstAllowedSection() {
      let urlToNavigateTo = '';

      if(this.router.url === `${routes.SITE_SETTINGS}${routes.STYLING}`) {
        this.navList.every(element => {
          let hasAccess = this.featureService.hasFeatureAccess(element.featureCode)

          if(hasAccess){
            urlToNavigateTo = element.link;
            return false;
          }

          return true;
        });
      }

      if(urlToNavigateTo !== ''){
        this.router.navigate([urlToNavigateTo]);
      }
  }

}
