import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { features, routes } from '@app/consts';
import { FeatureService } from '@app/core/services/feature.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { SecurityProtectedBase } from '@app/shared/components/security-protected/security-protected';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { defer, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HolidayGroup } from './models/statutory-holidays.model';
import { StatutoryHolidaysService } from './services/statutory-holidays.service';

@Component({
  selector: 'app-statutory-holidays',
  templateUrl: './statutory-holidays.component.html',
  styleUrls: ['./statutory-holidays.component.scss']
})
export class StatutoryHolidaysComponent extends SecurityProtectedBase implements OnInit {
  refreshView: boolean = true;
  public routes: typeof routes = routes;
  public columns: any[] = [
    {
      field: 'name',
      title: 'Name'
    },
    {
      field: 'country',
      subField: 'name',
      title: 'Country',
      dataType: 'Lookup',
      lookupCode: 'Country'
    }
  ];
  public bindingType: String = 'array';
  public gridDataResult: GridDataResult;
  public selectedPositions: any[] = [];
  isLoading: boolean;
  pageSize: number = 20;
  skip: number = 0;
  filterString: string;
  getRequest: any;
  public searchValue: string;
  clearSelectedItems: boolean = false;

  constructor(
    featureService: FeatureService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private holidayService: StatutoryHolidaysService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super(featureService, features.TIME_OFF)
  }

  ngOnInit(): void {
    this.getHolidayGroups();
  }

  getHolidayGroups(): void {
    this.isLoading = true;

    this.getRequest = this.holidayService.getHolidayGroups(this.skip, String(this.pageSize), this.filterString)
        .pipe(
            finalize(() => {
                this.isLoading = false;
            })
        )
        .subscribe(
            res => {
                this.gridDataResult = {
                    data: res.data,
                    total: res.total,
                };
            }
        );
  }

  public pageChange(event: PageChangeEvent): void {
      this.skip = event.skip;
      this.pageSize = event.take;
      this.getHolidayGroups();
  }

  deleteAllSelected(itemsToDelete: string[]) {
    const observables = itemsToDelete.map(selectedPosition => defer(() => this.holidayService.deleteHolidayGroup(selectedPosition)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
            this.clearSelectedItems = !this.clearSelectedItems;
            this.getHolidayGroups();
            this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
        },
        err => {
            this.snackbarService.openSnackBar(err, 'clear', 'warn');
        }
    );
  }

  filterCallback(filterString: string) {
      this.filterString = filterString;
      this.skip = 0;;
      this.getHolidayGroups();
  }

  search(searchValue) {
    this.searchValue = searchValue;
    this.filterString = `(Name like "${this.searchValue}")`;
    this.getRequest.unsubscribe();
    this.getHolidayGroups();
  }

  navigateToWorkRotation(holidayGroup?: HolidayGroup) {
    if(holidayGroup){
      this.router.navigate([`${routes.SITE_SETTINGS}${routes.STATUTORY_HOLIDAYS}${routes.EDITOR}/${holidayGroup?.id}`], {relativeTo: this.route});
    }
    else {
      this.router.navigate([`${routes.SITE_SETTINGS}${routes.STATUTORY_HOLIDAYS}${routes.EDITOR}`], {relativeTo: this.route});
    }
  }
}
