import { Component, OnInit, ViewChild } from '@angular/core';
import { routes } from '@app/consts';
import { EmployeeService } from '@app/core/services/employee.service';
import { DataBindingDirective, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { finalize } from 'rxjs/operators';
import { process } from "@progress/kendo-data-query";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-employee-absence-data-grid',
  templateUrl: './employee-absence-data-grid.component.html',
  styleUrls: ['./employee-absence-data-grid.component.scss']
})
export class EmployeeAbsenceDataGridComponent implements OnInit {
  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public mySelection: string[] = [];
  public routes: typeof routes = routes;
  pageSize: number = 20;
  skip: number = 0;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  isLoading: boolean;

  constructor(
    public translate: TranslateService,
    private employeeService: EmployeeService
  ) { }

  ngOnInit(): void {
    this.view = this.employeeService.getEmployeesKendo();
    this.getEmployees();
  }

  getEmployees(): void {
    this.isLoading = true;

    this.employeeService.getEmployeesKendo(this.skip, String(this.pageSize))
    .pipe(
        finalize(()=>{ this.isLoading = false; })
    )
    .subscribe(
      res => {
        this.gridDataResult = {
          data: res.employees,
          total: res.pagination.TotalCount,
        }

        this.gridDataResult.data.forEach(
          employee => {
            employee.absencesTaken = this.getRandomIntInclusive(0, 26);
            employee.annualLeaveRemaining = 26 - employee.absencesTaken;
          }
        );

        this.gridData = res.employees;
      }
    )
  }

  getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.round(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
  }

  public onFilter(inputValue: string): void {
    this.view = process(this.gridData, {
      filter: {
        logic: "or",
        filters: [
          {
            field: "full_name",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "job_title",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "budget",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "phone",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "address",
            operator: "contains",
            value: inputValue,
          },
        ],
      },
    }).data;

    this.dataBinding.skip = 0;
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.getEmployees();
  }

}
