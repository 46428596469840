import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { Lookup } from '@app/modules/lookup/models/lookup.model';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EmploymentRecordPension } from '../../models/employment-record-pension.model';
import { EmploymentRecordPensionsService } from '../../services/employment-record-pensions.service';

@Component({
  selector: 'app-employment-record-pension-dialog',
  templateUrl: './employment-record-pension-dialog.component.html',
  styleUrls: ['./employment-record-pension-dialog.component.scss']
})
export class EmploymentRecordPensionDialogComponent implements OnInit {
  form: UntypedFormGroup;
  employmentRecordId: any;
  employeeId: any;
  pensionId: any;
  isLoading: boolean;
  employmentRecordPension: EmploymentRecordPension;
  planTypeOptions: Observable<Lookup[]>;
  fundProviderOptions: Observable<Lookup[]>;
  employerContributionPercentageOptions: Observable<Lookup[]>;

  constructor(
    private fb: UntypedFormBuilder,
    private lookupService: LookupService,
    private overlayService: OverlayService,
    private employmentRecordPensionsService: EmploymentRecordPensionsService,
    private snackbarService: SnackbarService,
    private dialogRef: MatDialogRef<EmploymentRecordPensionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data)
  {
    this.employmentRecordId = data.employmentRecordId;
    this.employeeId = data.employeeId;
    this.pensionId = data.PensionId;
  } 

  ngOnInit(): void {
    this.getLookups();

    this.pensionId !== null 
      ? this.getEmploymentRecordPension() 
      : this.isLoading = false; 
        this.createForm();
  }

  getEmploymentRecordPension() {
    this.isLoading = true;
    
    this.employmentRecordPensionsService.getPension(this.employeeId, this.employmentRecordId, this.pensionId)
    .pipe(
      finalize(() => this.isLoading = false)
    )
    .subscribe(
      res => {
        this.employmentRecordPension = res;
        this.createForm();
      }
    );
  }

  getLookups() {
    this.planTypeOptions = this.lookupService.getLookups("/lookup/PENSION_PLAN_TYPE");
    this.fundProviderOptions = this.lookupService.getLookups("/lookup/PENSION_FUND_PROVIDER");
    this.employerContributionPercentageOptions = this.lookupService.getLookups("/lookup/PENSION_EMPLOYER_CONTRIBUTION_PERCENTAGE");
  }

  createForm() {
    this.form = this.fb.group({
      id: [this.employmentRecordPension ? this.employmentRecordPension.id : null],
      effectiveDate: [moment().format()],
      planType: [this.employmentRecordPension ? this.employmentRecordPension.planType.id : null],
      fundProvider: [this.employmentRecordPension && this.employmentRecordPension.fundProvider ? this.employmentRecordPension.fundProvider.id : null],
      employeeContributionPercentage: [this.employmentRecordPension ? this.employmentRecordPension.employeeContributionPercentage : null],
      employeeContributionDollarAmount: [this.employmentRecordPension ? this.employmentRecordPension.employeeContributionDollarAmount : null],
      employerContributionPercentage: [this.employmentRecordPension && this.employmentRecordPension.employerContributionPercentage ? this.employmentRecordPension.employerContributionPercentage.id : null],
      employerContributionDollarAmount: [this.employmentRecordPension ? this.employmentRecordPension.employerContributionDollarAmount : null],
      employeeVoluntaryPercentage: [this.employmentRecordPension ? this.employmentRecordPension.employeeVoluntaryPercentage : null],
      startDate: [this.employmentRecordPension ? this.employmentRecordPension.startDate : null],
      endDate: [this.employmentRecordPension ? this.employmentRecordPension.endDate : null],
      comments: [this.employmentRecordPension ? this.employmentRecordPension.comments : null],
      changeReason: [''],
      changeReasonComments: ['']
    });
  }

  save(){
    this.overlayService.show();

    this.employmentRecordPensionsService.postPension(this.employeeId, this.employmentRecordId, this.form.value)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar('Pension saved successfully', 'clear', 'success');
        this.dialogRef.close(true);
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  close(){
    this.dialogRef.close();
  }

}

