<app-letter-generator>
    <div class="main-container">

        <div *ngIf="tables; else loadingTables">
            <mat-card class="system-variables-card">
                <mat-card-title class="header">
                    <h5>System Variables</h5>
                </mat-card-title>
                <mat-card-content>
                    <mat-form-field appearance="outline">
                        <input matInput
                               #systemVariableSearch
                               placeholder="Search for a variable group">
                        <mat-icon matPrefix class="material-icons-outlined">
                            search
                        </mat-icon>
                    </mat-form-field>

                    <div class="list-container">
                        <mat-list>
                            <ng-container *ngFor="let table of (tables | systemVariableSearch:systemVariableSearch.value)">
                                <mat-list-item> 
                                    {{table.name}} 
                                </mat-list-item>
            
                                <mat-list style="margin-left:30px;">
                                    <div *ngFor="let field of table.fields | async">
                                        <mat-list-item 
                                            class="field-list-item" 
                                            matTooltip="Copy variable to clipboard" 
                                            cdkCopyToClipboard="{ {{table.name}}-{{field.name}} }"> 
                                            {{field.name}} 
                                            <mat-icon class="material-icons-outlined">
                                                content_copy
                                            </mat-icon>
                                        </mat-list-item>
                                    </div>
                                </mat-list>
                            </ng-container>
                        </mat-list>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>

        <ng-template #loadingTables>
            <ngx-skeleton-loader count="10" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
        </ng-template>

        <mat-card *ngIf="form" class="form">
            <mat-card-title class="header">
                <h5 *ngIf="letterIdInUrl">Edit Letter</h5>
                <h5 *ngIf="!letterIdInUrl">Create Letter</h5>
                <div>
                    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid">Save</button> 
                </div>
            </mat-card-title>
            <mat-card-content>
                <form [formGroup]="form">
                    <mat-form-field appearance="fill">
                        <mat-label>
                            Letter Name
                        </mat-label>
            
                        <input matInput
                            formControlName="name">
                    </mat-form-field>
            
                    <mat-form-field appearance="fill">
                        <mat-label>Description</mat-label>
                        <textarea matInput matTextareaAutosize matAutosizeMinRows=1 matAutosizeMaxRows=30
                            formControlName="description"></textarea>
                    </mat-form-field>
            
                    <div class="example-wrapper">
                        <kendo-editor 
                            formControlName="letterHTML"
                            style="height: 750px;">
                            <kendo-toolbar>
                                <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                                <kendo-toolbar-button
                                    kendoEditorUnderlineButton
                                ></kendo-toolbar-button>
                                <kendo-toolbar-button
                                    kendoEditorStrikethroughButton
                                ></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button
                                    kendoEditorSubscriptButton
                                ></kendo-toolbar-button>
                                <kendo-toolbar-button
                                    kendoEditorSuperscriptButton
                                ></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button
                                    kendoEditorAlignLeftButton
                                ></kendo-toolbar-button>
                                <kendo-toolbar-button
                                    kendoEditorAlignCenterButton
                                ></kendo-toolbar-button>
                                <kendo-toolbar-button
                                    kendoEditorAlignRightButton
                                ></kendo-toolbar-button>
                                <kendo-toolbar-button
                                    kendoEditorAlignJustifyButton
                                ></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-dropdownlist
                                kendoEditorFormat
                                ></kendo-toolbar-dropdownlist>
                                <kendo-toolbar-dropdownlist
                                kendoEditorFontSize
                                ></kendo-toolbar-dropdownlist>
                                <kendo-toolbar-dropdownlist
                                kendoEditorFontFamily
                                ></kendo-toolbar-dropdownlist>
                                <kendo-toolbar-colorpicker
                                kendoEditorForeColor
                                ></kendo-toolbar-colorpicker>
                                <kendo-toolbar-colorpicker
                                kendoEditorBackColor
                                view="gradient"
                                ></kendo-toolbar-colorpicker>
                                <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button
                                    kendoEditorInsertUnorderedListButton
                                ></kendo-toolbar-button>
                                <kendo-toolbar-button
                                    kendoEditorInsertOrderedListButton
                                ></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button
                                    kendoEditorCreateLinkButton
                                ></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                                <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button
                                    kendoEditorAddColumnBeforeButton
                                ></kendo-toolbar-button>
                                <kendo-toolbar-button
                                    kendoEditorAddColumnAfterButton
                                ></kendo-toolbar-button>
                                <kendo-toolbar-button
                                    kendoEditorAddRowBeforeButton
                                ></kendo-toolbar-button>
                                <kendo-toolbar-button
                                    kendoEditorAddRowAfterButton
                                ></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button
                                    kendoEditorDeleteColumnButton
                                ></kendo-toolbar-button>
                                <kendo-toolbar-button
                                    kendoEditorDeleteRowButton
                                ></kendo-toolbar-button>
                                <kendo-toolbar-button
                                    kendoEditorDeleteTableButton
                                ></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-button
                                kendoEditorInsertFileButton
                                ></kendo-toolbar-button>
                                <kendo-toolbar-button
                                kendoEditorInsertImageButton
                                ></kendo-toolbar-button>
                                <kendo-toolbar-button
                                kendoEditorViewSourceButton
                                ></kendo-toolbar-button>
                                <kendo-toolbar-button
                                kendoEditorCleanFormattingButton
                                ></kendo-toolbar-button>
                            </kendo-toolbar>
                        </kendo-editor>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</app-letter-generator>

