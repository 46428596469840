<div class="employee-contact-card-container" *ngIf="employeeContactCard$ | withLoading | async as employeeContactCard">
    <ng-template [ngIf]="employeeContactCard.value">
        <div class="employee-details">
            <div class="profile-pic-container">
                <app-employee-image class="profile-pic" mat-card-image [employeeId]="employeeContactCard.value.id" round="true" width="200" height="200"></app-employee-image>
            </div>
            <div class="details">
                <div class="name-details">
                    <div class="name" *ngIf="employeeContactCard.value.firstname && employeeContactCard.value.lastname">{{employeeContactCard.value.firstname}} {{employeeContactCard.value.lastname}}</div>
                    <div class="username" *ngIf="employeeContactCard.value.preferredName">{{employeeContactCard.value.preferredName}}</div>
                    <div class="username" *ngIf="employeeContactCard.value.customClientId">{{employeeContactCard.value.customClientId}}</div>
                    <!-- <div class="username" *ngIf="employeeContactCard.value.organization">{{employeeContactCard.value.organization.name}}</div> -->
                    <mat-chip-list>
                        <mat-chip 
                            *ngIf="employeeContactCard.value.subStatus"
                            class="green-chip"
                        >
                            {{employeeContactCard.value.subStatus?.text}}
                        </mat-chip>
                    </mat-chip-list>
                </div>

                <div class="other-details">
                    <div class="phone" *ngIf="employeeContactCard.value.userId">
                        {{employeeContactCard.value.userId}}
                    </div>
                    <div class="phone" *ngIf="employeeContactCard.value.workPhone">
                        <mat-icon class="material-icons-outlined">call</mat-icon>
                        {{employeeContactCard.value.phoneNumber}}
                    </div>
                    <div class="phone" *ngIf="employeeContactCard.value.email">
                        <mat-icon class="material-icons-outlined">contact_mail</mat-icon>
                        {{employeeContactCard.value.email}}
                    </div>
                    <div class="title" *ngIf="employeeContactCard.value.positions?.length > 0">
                        <ng-container *ngFor="let position of employeeContactCard.value.positions">
                            <div>
                                <mat-icon class="material-icons-outlined">account_balance</mat-icon>
                                {{position.name}}
                                <ng-container *ngIf="getLocation(employeeContactCard.value.workLocations, position)!= null">
                                    <br>
                                    <span class="k-ml-4">{{getLocation(employeeContactCard.value.workLocations, position)}}</span>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="manager-details" *ngIf="employeeContactCard.value.managers?.length > 0">
                    <div 
                        class="primaryColor label" 
                        setColor="primaryColour" 
                        appLocalizationPopupDirective 
                        [localizationCode]="'ReportsTo'"
                    >{{ 'ReportsTo' | translate: {Default: "Reports To"} }}</div>
                    <ng-container *ngFor="let manager of employeeContactCard.value.managers">
                        <div 
                        setColor 
                        [setColorRules]="[
                            {
                                color: 'primaryColour',
                                events: ['hover']
                            }
                        ]" 
                        class="manager-name primaryHoverColor" 
                        routerLink="{{routes.TALENT_TRACK}}{{routes.EDIT}}/{{manager.id}}{{routes.EMPLOYEE_DETAILS}}">
                            <app-employee-image class="profile-pic" [employeeId]="manager.id"></app-employee-image>

                            <span class="name">
                                {{manager.firstName}} {{manager.lastName}}
                            </span>
                        </div>
                    </ng-container>
                </div>

                <mat-accordion>
                    <mat-expansion-panel class="mat-elevation-z0" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                      <mat-expansion-panel-header>
                        <mat-panel-title appLocalizationPopupDirective [localizationCode]="'MoreDetails'">
                            {{ 'MoreDetails' | translate: {Default: "More Details"} }}
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <mat-list>
                
                        <mat-list-item *ngIf="employeeContactCard.value.status" appLocalizationPopupDirective [localizationCode]="'Status'">{{ 'Status' | translate: {Default: "Status"} }}: {{employeeContactCard.value.status}} </mat-list-item>
                        <mat-list-item *ngIf="employeeContactCard.value.organization" appLocalizationPopupDirective [localizationCode]="'Organization'">{{ 'Organization' | translate: {Default: "Organization"} }}: {{employeeContactCard.value.organization?.name}} </mat-list-item>
                        <mat-list-item *ngIf="employeeContactCard.value.timezone"> {{employeeContactCard.value.timezone?.text}} </mat-list-item>
                        <a *ngIf="employeeContactCard.value.facebookUrl" target="_blank" [href]="employeeContactCard.value.facebookUrl">
                            <mat-list-item class="navigation-link">
                                <img class="ms-icon" src="assets/icons/facebook-icon.svg" style="width: 30px;
                                    margin-right: 10px;
                                    vertical-align: middle;"> {{employeeContactCard.value.facebookUrl}} 
                            </mat-list-item>
                        </a>
                        <a *ngIf="employeeContactCard.value.linkedInUrl" target="_blank" [href]="employeeContactCard.value.linkedInUrl">
                            <mat-list-item class="navigation-link">
                                <img class="ms-icon" src="assets/icons/linkedin-icon.svg" style="width: 30px;
                                    margin-right: 10px;
                                    vertical-align: middle;"> {{employeeContactCard.value.linkedInUrl}} 
                            </mat-list-item>
                        </a>
                        <a *ngIf="employeeContactCard.value.linkedInUrl" target="_blank" [href]="employeeContactCard.value.linkedInUrl">
                            <mat-list-item *ngIf="employeeContactCard.value.twitterHandle">
                                <img class="ms-icon" src="assets/icons/twitter-icon.svg" style="width: 30px;
                                    margin-right: 10px;
                                    vertical-align: middle;"> {{employeeContactCard.value.twitterHandle}} 
                            </mat-list-item>
                        </a>
                        
                       </mat-list>

                        <ng-container *appHasModuleAccess="modules.TIME_OFF">
                            <ng-container *appHasFeature="features.TIME_OFF">
                                <app-employee-schedule *ngIf="panelOpenState === true" [employeeId]="employeeId"></app-employee-schedule>
                            </ng-container>
                        </ng-container>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </ng-template>
    <ng-template [ngIf]="employeeContactCard.error">
      <h6>There was an error loading</h6>
    </ng-template>
    <ng-template [ngIf]="employeeContactCard.loading">
        <div class="employee-details">
            <div class="profile-pic-container">
                <ngx-skeleton-loader 
                count="1" 
                appearance="circle"
                [theme]="{ 
                    'height.px': 200,
                    'width.px': 200
                }">
                </ngx-skeleton-loader>
            </div>
            <div style="width:200px; padding: 10px;">
                <ngx-skeleton-loader
                count="3"
                [theme]="{ 
                    'height.px': 50
                }"
                ></ngx-skeleton-loader>
            </div>
        </div>
    </ng-template>
</div>
