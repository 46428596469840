import { Component, OnInit, ViewChild } from "@angular/core";

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTooltip,
  ApexStroke
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
};
@Component({
  selector: 'app-leave-type-per-month-area-chart',
  templateUrl: './leave-type-per-month-area-chart.component.html',
  styleUrls: ['./leave-type-per-month-area-chart.component.scss']
})
export class LeaveTypePerMonthAreaChartComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  constructor() {
    this.chartOptions = {
      series: [
        {
          name: "Sick Leave",
          data: [100, 91, 80, 51, 42, 10, 12, 10, 23, 35]
        },
        {
          name: "Vacation Leave",
          data: [32, 15, 20, 32, 34, 52, 41, 43, 20, 15]
        }
      ],
      chart: {
        height: 500,
        width: '100%',
        type: "area"
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth"
      },
      xaxis: {
        type: "datetime",
        categories: [
          "2021-01-01T00:00:00.000Z",
          "2021-02-01T00:00:00.000Z",
          "2021-03-01T00:00:00.000Z",
          "2021-04-01T00:00:00.000Z",
          "2021-05-01T00:00:00.000Z",
          "2021-06-01T00:00:00.000Z",
          "2021-07-01T00:00:00.000Z",
          "2021-08-01T00:00:00.000Z",
          "2021-09-01T00:00:00.000Z",
          "2021-10-01T00:00:00.000Z",
        ]
      },
      tooltip: {
        x: {
          format: "MM/yyyy"
        }
      }
    };
  }

  public generateData(baseval, count, yrange) {
    var i = 0;
    var series = [];
    while (i < count) {
      var x = Math.floor(Math.random() * (750 - 1 + 1)) + 1;
      var y =
        Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
      var z = Math.floor(Math.random() * (75 - 15 + 1)) + 15;

      series.push([x, y, z]);
      baseval += 86400000;
      i++;
    }
    return series;
  }
  
  ngOnInit(): void {
  }

}
