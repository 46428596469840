import { Component, OnInit } from '@angular/core';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureService } from '@app/core/services/feature.service';
import { TablePermissionsService } from '../services/table-permissions/table-permissions.service';
import { SettingsService } from "@app/core/services/settings.service";
import { EmployeeService } from '../services/employee.service';
import { EnvironmentService } from "@app/core/services/environment.service";
import { ModuleConfigurationService } from '../services/module-configuration/module-configuration.service';
import { StylingColoursService } from '@app/modules/site-settings-styling/components/styling-colours/services/styling-colours.service';
import { CompanyLogosService } from '@app/modules/site-settings-styling/components/company-logos/services/company-logos.service';

@Component({
    selector: 'app-auth-callback',
    templateUrl: './auth-callback.component.html',
    styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit {

    error: boolean;

    constructor(
      private environmentService: EnvironmentService,
      private authService: OidcAuthService,
      private router: Router,
      private route: ActivatedRoute,
      private featureService: FeatureService,
      private tablePermissionsService: TablePermissionsService,
      private moduleConfigurationService: ModuleConfigurationService,
      private settingsService: SettingsService,
      private employeeService: EmployeeService,
      private stylingColoursService: StylingColoursService,
      private companyLogosService: CompanyLogosService
    ) {
    }

    async ngOnInit() {
        // check for error
        // if (this.route.snapshot.fragment.indexOf('error') >= 0) {
        //     this.error = true;
        //     return;
        // }

        await this.authService.completeAuthentication()
        .then(
          res => {

            this.companyLogosService.syncActiveCompanyLogo(
              () => {
                this.stylingColoursService.syncActiveStylingColour(
                  () => {
                    this.employeeService.syncCurrentUserDetails(
                      () => {
                        this.moduleConfigurationService.syncCurrentUserModules(
                          () => {
                            this.featureService.syncCurrentUserFeatures(
                              () => {
                                this.settingsService.syncSiteSettings(
                                  () => {
                                    this.router.navigate(['Homepage']);
                                  }
                                )
                              }
                            )
                          }
                        )
                      }
                    )
                  }
                )
              }
            )
            
          }
        )
        .catch(
            (err) => {
                this.router.navigate(['Homepage']);
            }
        );
        // this.router.navigate(['TalentTrack/Edit/emp_kZfFjYzMEPTCuH/EmploymentRecords/ere_lGAaygoBvta1m0']);
        // this.router.navigate(['TalentTrack/Edit/usr_BNcd0WL3yIqQEJ/Positions']);
        // this.router.navigate(['TalentTrack/Edit/usr_BNcd0WL3yIqQEJ/EmployeeDetails']);
        // this.router.navigate(['TalentTrack/Edit/usr_BNcd0WL3yIqQEJ/EmploymentRecords']);
        // this.router.navigate(['TalentTrack/Edit/emp_kl7inRhZLrIcy9/EmploymentCompensation']);
        // this.router.navigate(['TalentTrack']);
        // this.router.navigate(['Newsfeed']);

        // this.router.navigate(['Security']);
        // this.router.navigate(['Form/Edit/frm_HxOAVm3Tk2eIeR']);
        // this.router.navigate(['Benefits/Groups/Editor/bgp_kPSNiEXjGnPnXh']);
        // this.router.navigate(['Letters/Send']);
        // this.router.navigate(['Form/Edit/frm_7CGuNBqNgqMO97']);

    }


}
