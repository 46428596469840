<app-layout>
    <mat-card *ngIf="packet$ | async as packet">
        <mat-card-title class="header">
        </mat-card-title>
        <mat-card-content>
            <div>
                <mat-tab-group class="my-mat-tab-group" [selectedIndex]="selectedIndex">
                    <mat-tab *ngFor="let packetItem of packet; let i = index">
                        <ng-template mat-tab-label> <span class="mat-tab-label-number">{{i+1}}</span> {{packetItem.title}} </ng-template>
                        <div class="tab-container">
                            <app-info-page
                                *ngIf="packetItem.type === 'info'"
                                [data]="packetItem.data"
                            ></app-info-page>
    
                            <app-questionnaire
                                *ngIf="packetItem.type === 'questionnaire'"
                                [data]="packetItem.data"
                            ></app-questionnaire>
    
                            <app-checklist
                                *ngIf="packetItem.type === 'checklist'"
                                [data]="packetItem.data"
                            ></app-checklist>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button class="continue-button" mat-flat-button color="warn" [disabled]="selectedIndex === packet.length-1" (click)="selectNextTab()">Continue</button>
            <button mat-button [disabled]="selectedIndex === 0" (click)="selectPreviousTab()">Previous Step</button>
          </mat-card-actions>
    </mat-card>
</app-layout>

