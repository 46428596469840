import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { GenerateScheduleService } from '@app/modules/generate-schedules/services/generate-schedule.service';
import { MyRequest } from '@app/shared/models/workflows-absences/workflows-absences.model';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-my-time-off-request-dialog',
  templateUrl: './my-time-off-request-dialog.component.html',
  styleUrls: ['./my-time-off-request-dialog.component.scss']
})
export class MyTimeOffRequestDialogComponent implements OnInit {
  timeOffRequest: MyRequest;
  loadingWorkingHours: boolean;
  user$: any;
  workingHours: number;
  moment = moment

  constructor(
    private dialogRef: MatDialogRef<MyTimeOffRequestDialogComponent>,
    private generateScheduleService: GenerateScheduleService,
    private oidcAuthService: OidcAuthService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.timeOffRequest = data.timeOffRequest;
    this.user$ = this.oidcAuthService.userProfile;
  }

  ngOnInit(): void {
    if(this.timeOffRequest.hours === 0){
      this.getEmployeeWorkingHours();
    }
  }

  getEmployeeWorkingHours() {
    this.loadingWorkingHours = true;

    this.generateScheduleService.getEmployeeWorkingHours(this.user$.userId, this.timeOffRequest.from, this.timeOffRequest.to)
    .pipe(
      finalize(() => this.loadingWorkingHours = false)
    )
    .subscribe(
      (res) => {
        this.workingHours = res.workingHours;
        if(this.timeOffRequest.halfDay === true){
          this.workingHours = this.workingHours / 2;
        }
      }
    );
  }

  close(){
    this.dialogRef.close();
  }

    formatTime(time) {
        const [hours, minutes] = time.split(':');
        return `${hours}:${minutes}`;
    }
}
