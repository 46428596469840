<div id="chart">
    <apx-chart
      #chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [dataLabels]="chartOptions.dataLabels"
      [plotOptions]="chartOptions.plotOptions"
      [xaxis]="chartOptions.xaxis"
    ></apx-chart>
</div>
  