import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { api_routes } from '@app/consts';
import { EnvironmentService } from "@app/core/services/environment.service";

const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class MetadataTableService {

  constructor(private http: HttpClient, private envService: EnvironmentService) {}

  getTables(skip?: number, take?: string, filterString?: string, sortString?: string): Observable<any> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    filterString ? params = params.append('Filter', filterString) : null;
    sortString ? params = params.append('Sort', sortString) : null;

    return this.http
    .get<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.TABLES}`, {
      headers: headers,
      params: params
    });
  }

  getTableFields(tableId: string, skip?: number, take?: string, filterString?: string, sortString?: string): Observable<any> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    filterString ? params = params.append('Filter', filterString) : null;
    sortString ? params = params.append('Sort', sortString) : null;

    return this.http
    .get<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.TABLES}/${tableId}/${api_routes.FIELDS}`, {
      headers: headers,
      params: params
    });
  }

  getTableFieldsAsync(tableId: string): Observable<any> {
    return this.http
    .get<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.TABLES}/${tableId}/${api_routes.FIELDS}`, {
      headers: headers,
    });
  }

}
