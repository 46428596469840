<h5 mat-dialog-title>{{title}}</h5>

<mat-dialog-content>
    <app-table
        [tableData]="dataSource"
        [tableColumns]="displayedColumns"
        [isPageable]="true"
        [paginationSizes]="[1,2,4,6]"
        [defaultPageSize]="4"
        [isFilterable]="true"
    ></app-table>
 
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
</mat-dialog-actions>