import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api_routes } from '@app/consts';
import { EnvironmentService } from '@app/core/services/environment.service';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { Observable } from 'rxjs';
import {
    CompletionDateType,
    ExpectedCompletionDateType,
    FieldSetting,
    GoalType,
    GoalTypeRating,
    GoalTypeRatingSubmit,
    GoalTypeRatingSubmitResponse,
    GoalTypeRatingVerbose,
    GoalTypeStatus,
    GoalTypeStatusType,
    GoalTypeSubmit,
    GoalTypeSubmitResponse,
    GoalTypeVerbose
} from '../models/goal-type.model';


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})

export class GoalTypeService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getGoalTypes(take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<GoalType>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_TYPES}`, {
      headers: headers,
      params: params
    });
  }

  getGoalType(goalTypeId: string): Observable<GoalTypeVerbose> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_TYPES}/${goalTypeId}`, {
      headers: headers
    });
  }

  createGoalType(goalType: GoalTypeSubmit): Observable<GoalTypeSubmitResponse> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_TYPES}`, goalType, {
      headers: headers
    });
  }

  updateGoalType(goalType: GoalTypeSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_TYPES}/${goalType.id}`, goalType, {
      headers: headers
    });
  }

  deleteGoalType(goalTypeId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_TYPES}/${goalTypeId}`, {
      headers: headers
    });
  }

  getGoalTypeStatuses(take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<GoalTypeStatus>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_TYPE_STATUSES}`, {
      headers: headers,
      params: params
    });
  }

  getGoalTypeRatings(goalTypeId: string, take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<GoalTypeRating>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_TYPES}/${goalTypeId}/${api_routes.RATINGS}`, {
      headers: headers,
      params: params
    });

    // return of(
    //   {
    //     skip: 0,
    //     take: 0,
    //     total: 0,
    //     totalPages: 0,
    //     data: [
    //       {
    //         id: "12345",
    //         name: "Test Rating 1",
    //         sortOrder: 1,
    //         goalTypeId: null
    //       },
    //       {
    //         id: "123456",
    //         name: "Test Rating 2",
    //         sortOrder: 2,
    //         goalTypeId: null
    //       },
    //       {
    //         id: "123457",
    //         name: "Test Rating 3",
    //         sortOrder: 3,
    //         goalTypeId: null
    //       }
    //     ],
    //   }
    // )
  }

  getGoalTypeRating(goalTypeId: string, goalTypeRatingId: string): Observable<GoalTypeRatingVerbose> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_TYPES}/${goalTypeId}/${api_routes.RATINGS}/${goalTypeRatingId}`, {
      headers: headers
    });

    // return of(
    //   {
    //     id: (Math.floor(Math.random() * 100) + 1).toString(),
    //     name: [
    //       {
    //         culture: 'en-CA',
    //         text: `rating ${goalTypeRatingId}`
    //       }
    //     ],
    //     sortOrder: (Math.floor(Math.random() * 100) + 1),
    //     goalTypeId: (Math.floor(Math.random() * 100) + 1).toString(),
    //     version: null
    //   },
    // );
  }

  createGoalTypeRating(goalTypeId: string,goalTypeRating: GoalTypeRatingSubmit): Observable<GoalTypeRatingSubmitResponse> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_TYPES}/${goalTypeId}/${api_routes.RATINGS}`, goalTypeRating, {
      headers: headers
    });
  }

  updateGoalTypeRating(goalTypeId: string,goalTypeRatingId: string, goalType: GoalTypeRatingSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_TYPES}/${goalTypeId}/${api_routes.RATINGS}/${goalTypeRatingId}`, goalType, {
      headers: headers
    });
  }

  deleteGoalTypeRating(goalTypeId: string,goalTypeRatingId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_TYPES}/${goalTypeId}/${api_routes.RATINGS}/${goalTypeRatingId}`, {
      headers: headers
    });
  }

  getFieldSettings(take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<FieldSetting>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_TYPES}/${api_routes.FIELD_SETTINGS}`, {
      headers: headers,
      params: params
    });
  }

  getCompletionDateTypes(take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<CompletionDateType>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_TYPES}/${api_routes.COMPLETION_DATE_TYPES}`, {
      headers: headers,
      params: params
    });
  }

  getExpectedCompletionDateTypes(take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<ExpectedCompletionDateType>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_TYPES}/${api_routes.EXPECTED_COMPLETION_DATE_TYPES}`, {
      headers: headers,
      params: params
    });
  }

  getGoalTypeStatusTypes(take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<GoalTypeStatusType>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.GOAL_TYPES}/${api_routes.STATUS_TYPES}`, {
      headers: headers,
      params: params
    });
  }
}
