<h5 mat-dialog-title>Employment Record: Relocation</h5>

<mat-dialog-content>
    <form class="form-container" *ngIf="form" [formGroup]="form">
        <mat-form-field  appearance="fill">
            <mat-label>
                Date Initiated
            </mat-label>
        
            <input matInput [matDatepicker]="dateInitiatedpicker" formControlName="dateInitiated">
            <span fxLayout="row" matSuffix>
                <mat-datepicker-toggle  [for]="dateInitiatedpicker"></mat-datepicker-toggle>
            </span>
            <mat-datepicker #dateInitiatedpicker></mat-datepicker> 
            <mat-hint></mat-hint>
        </mat-form-field>

        <mat-form-field  appearance="fill">
            <mat-label>
                Relocation Start Date
            </mat-label>
        
            <input matInput [matDatepicker]="relocationStartDatepicker" formControlName="relocationStartDate">
            <span fxLayout="row" matSuffix>
                <mat-datepicker-toggle  [for]="relocationStartDatepicker"></mat-datepicker-toggle>
            </span>
            <mat-datepicker #relocationStartDatepicker></mat-datepicker> 
            <mat-hint></mat-hint>
        </mat-form-field>

        <mat-form-field  appearance="fill">
            <mat-label>
                Relocation End Date
            </mat-label>
        
            <input matInput [matDatepicker]="relocationEndDatepicker" formControlName="relocationEndDate">
            <span fxLayout="row" matSuffix>
                <mat-datepicker-toggle  [for]="relocationEndDatepicker"></mat-datepicker-toggle>
            </span>
            <mat-datepicker #relocationEndDatepicker></mat-datepicker> 
            <mat-hint></mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Point Of Origin
            </mat-label>

            <mat-select disableOptionCentering formControlName="pointOfOrigin">
                <mat-option *ngFor="let pointOfOriginOption of pointOfOriginOptions | async" [value]="pointOfOriginOption.id">{{pointOfOriginOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Receiving Location
            </mat-label>

            <mat-select disableOptionCentering formControlName="receivingLocation">
                <mat-option *ngFor="let receivingLocationOption of receivingLocationOptions | async" [value]="receivingLocationOption.id">{{receivingLocationOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Sending Location
            </mat-label>

            <mat-select disableOptionCentering formControlName="sendingLocation">
                <mat-option *ngFor="let sendingLocationOption of sendingLocationOptions | async" [value]="sendingLocationOption.id">{{sendingLocationOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Total Costs
            </mat-label>

            <input matInput
                type="number"
                formControlName="totalCosts">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Comments
            </mat-label>

            <textarea matInput formControlName="comments"></textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button> 
</mat-dialog-actions>