<mat-list>
    <mat-list-item> 
        <div class="timeOffLeaveRequest-row" appPreventDoubleClick (throttledClick)="openPendingTimeOffDialog.emit(timeOffLeaveRequest)">
            <div class="left">
                <div class="timeofftype-details">
                    <img *ngIf="iconUrl" [src]="domSanitizer.bypassSecurityTrustUrl(iconUrl)"/>
                    <h6>{{timeOffLeaveRequest.timeOffType.name}}</h6>
                </div>
                <div class="dates">
                    {{timeOffLeaveRequest.from | date:'mediumDate'}} - {{timeOffLeaveRequest.to | date:'mediumDate'}}
                </div>
                <mat-chip-list class="half-day">
                    <mat-chip class="green-chip" *ngIf="timeOffLeaveRequest.halfDay === true" appLocalizationPopupDirective [localizationCode]="'LeaveRequestHalfDay'">{{ 'LeaveRequestHalfDay' | translate: {Default: "Half Day"} }}</mat-chip>
                </mat-chip-list>
            </div>
            <div class="middle">
                <app-employee-image class="profile-pic" round="true" width="50" height="50" [employeeId]="timeOffLeaveRequest.requestedBy?.id"></app-employee-image>
                {{timeOffLeaveRequest.requestedBy?.firstName}} {{timeOffLeaveRequest.requestedBy?.lastName}}
            </div>
            <div class="right">
                <mat-chip-list>
                    <mat-chip 
                        [ngClass]="{
                            'red-chip': timeOffLeaveRequest.requestStatus?.id === 'trs_Denied',
                            'green-chip': timeOffLeaveRequest.requestStatus?.id === 'trs_Approved',
                            'orange-chip': timeOffLeaveRequest.requestStatus?.id === 'trs_Pending'
                        }"
                    >{{ timeOffLeaveRequest.requestStatus?.name }}</mat-chip>
                </mat-chip-list>
            </div>
        </div>    
    </mat-list-item>
</mat-list>