import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Injectable, Injector, TemplateRef, Type } from '@angular/core';
import { OverlayComponent } from './overlay.component';
import { MyOverlayRef } from './myoverlay-ref';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {
  private overlayRef: OverlayRef = undefined;

  constructor(private overlay: Overlay, private injector: Injector) {}
   
  public show<R = any, T = any>(
    content?: string | TemplateRef<any> | Type<any>,
    data?: T
  ): MyOverlayRef<R> {

    const configs = new OverlayConfig({
      hasBackdrop: true,
      panelClass: ['modal', 'is-active'],
      backdropClass: 'modal-background'
    });

    this.overlayRef = this.overlay.create(configs);

    const myOverlayRef = new MyOverlayRef<R, T>(this.overlayRef, content, data);

    const injector = this.createInjector(myOverlayRef, this.injector);
    this.overlayRef.attach(new ComponentPortal(OverlayComponent, null, injector));

    return myOverlayRef;
  }

  public hide(): void {
    this.overlayRef.detach();
    this.overlayRef = undefined;
  }

  createInjector(ref: MyOverlayRef, inj: Injector) {
    const injectorTokens = new WeakMap([[MyOverlayRef, ref]]);
    return new PortalInjector(inj, injectorTokens);
  }
}
