import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WorkflowsAbsencesService } from '@app/core/services/workflows-absences/workflows-absences.service';
import { TimeOffService } from '@app/modules/time-off/services/time-off.service';
import { MyRequest } from '@app/shared/models/workflows-absences/workflows-absences.model';
import * as moment from 'moment';
import { Observable, isObservable, of, startWith } from 'rxjs';
import { MyTimeOffRequestDialogComponent } from './components/my-time-off-request-dialog/my-time-off-request-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-my-time-off-requests',
  templateUrl: './my-time-off-requests.component.html',
  styleUrls: ['./my-time-off-requests.component.scss']
})
export class MyTimeOffRequestsComponent implements OnInit {
  @Input() refreshMyTimeOffRequest: boolean;
  
  leaveRequests$: any;
  public selectedFilter;
  firstrun : boolean = true;

  selectedStatusFilters = [];
  statusOptions = [
    {id: "trs_Pending", name: 'Pending'},
    {id: "trs_Cancelled", name: 'Cancelled'},
    {id: "trs_Approved", name: 'Approved'},
    {id: "trs_Denied", name: 'Denied'}
  ]

  filterOptions = [
    {
      value: '3',
      text: 'Last3Months'
    },
    {
      value: '6',
      text: 'Last6Months'
    },
    {
      value: '12',
      text: 'Last12Months'
    },
    {
      value: '',
      text: 'All'
    },
  ]

  constructor(
    private workflowsAbsencesService: WorkflowsAbsencesService,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {
    this.selectedFilter = this.filterOptions[0];
  }

  ngOnInit(): void {
    this.getLeaveRequests();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.refreshMyTimeOffRequest && changes.refreshMyTimeOffRequest.currentValue !== undefined){
      if(this.firstrun) {
        this.firstrun = false;
      }
      else {
        this.getLeaveRequests()
      }
    }
  }

  getLeaveRequests() {
    this.workflowsAbsencesService.getMyRequests(0, 100, `version.createdOn-desc`, this.buildFilterString())
    .subscribe(
      res => {
        if(res instanceof Observable){
          res.subscribe(
            r => {
              this.leaveRequests$ = res;
            }
          )
        }
        else {
          this.leaveRequests$ = of(res);
        }
      }
    );
  }

  buildFilterString(): string {
    let filterString;
    let statusOptionString;


    if(this.selectedFilter !== "") {
      filterString = `(from > "${moment().subtract(parseInt(this.selectedFilter.value), 'months').format()}")`
    }

    this.selectedStatusFilters.forEach(
      option => {
        if(statusOptionString !== undefined){
          statusOptionString = `${statusOptionString} OR (RequestStatus.Id = "${option.id}")`
        }
        else {
          statusOptionString = `(RequestStatus.Id = "${option.id}")`
        }
      }
    )

    if(filterString !== undefined && statusOptionString !== undefined){
      filterString = `${filterString} AND (${statusOptionString})`;
    }
    else if(statusOptionString !== undefined){
      filterString = `(${statusOptionString})`;
    }

    return filterString;
  }
  
  openLeaveRequestDialog(timeOffLeaveRequest: MyRequest) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 500;

    dialogConfig.data = {
      timeOffRequest: timeOffLeaveRequest
    };

    const dialogRef = this.dialog.open(MyTimeOffRequestDialogComponent, dialogConfig);
  }

  getSelectedStatusesText(): string {
    const selectedStatuses = this.selectedStatusFilters || [];
    return selectedStatuses.map((status: any) => this.translate.instant(status.name)).join(', ');
  }
}
