import { Component, OnInit } from '@angular/core';
import { colors } from '@app/consts';

@Component({
  selector: 'app-monthly-absence-type-pie-chart',
  templateUrl: './monthly-absence-type-pie-chart.component.html',
  styleUrls: ['./monthly-absence-type-pie-chart.component.scss']
})
export class MonthlyAbsenceTypePieChartComponent implements OnInit {

  chartData = {
    groupA: 20,
    groupB: 68,
    groupC: 49,
    groupD: 15,
    groupE: 21,
    groupF: 2,
    groupG: 23,
  }

  tableColumns = [
    { name: 'Leave Type', dataKey: 'label', isSortable: true, position: 'left' },
    { name: 'Total', dataKey: 'value', isSortable: true, position: 'left' }
  ];

  labelData = [
    {
      label: 'Sick',
      color: '#b676b1',
      value: '20',
    },
    {
      label: 'Vacation',
      color: '#82caaf',
      value: '68',
    },
    {
      label: 'Doctor Approved Leave',
      color: '#75c0e0',
      value: '49',
    },
    {
      label: 'House Emergency',
      color: '#fecf6a',
      value: '15',
    },
    {
      label: 'Family Emergency',
      color: '#39a275',
      value: '21',
    },
    {
      label: 'Delivery of Major Purchase',
      color: '#df1c44',
      value: '2',
    },
    {
      label: 'Dental',
      color: '#8F3985',
      value: '23',
    },
  ]

  public chart: any;
  public colors: typeof colors = colors;

  constructor() { }
  
  public ngOnInit(): void {
    this.initChart();
  }

  public initChart(): void {
    this.chart = {
      color: ['#b676b1', '#82caaf', '#75c0e0', '#fecf6a', '#39a275', '#df1c44', '#8F3985'],
      tooltip: {
        trigger: 'item'
      },
      series: [{
        type: 'pie',
        radius: ['60%', '70%'],
        center: ['50%', '50%'],
        label: {
          show: false
        },
        labelLine: {
          normal: {
            show: false
          }
        },
        hoverAnimation: false,
        avoidLabelOverlap: false,
        data: [
          {
            name: 'Sick',
            value: this.chartData.groupA
          },
          {
            name: 'Vacation',
            value: this.chartData.groupB
          },
          {
            name: 'Doctor Approved Leave',
            value: this.chartData.groupC
          },
          {
            name: 'House Emergency',
            value: this.chartData.groupD
          },
          {
            name: 'Family Emergency',
            value: this.chartData.groupE
          },
          {
            name: 'Delivery of Major Purchase',
            value: this.chartData.groupF
          },
          {
            name: 'Dental',
            value: this.chartData.groupG
          }
        ]
      }]
    };
  }
}
