import { Component, OnInit } from '@angular/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { SecurityRole, } from '@app/modules/security-setup/models/security-role.model';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { routes } from '@app/consts';
import { SecurityRoleService } from '@app/modules/security-roles/services/security-role.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { defer, forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Filterable } from '@app/shared/components/filter/filterable';
import { CopyRoleDialogComponent } from '@app/modules/security-roles/components/copy-role-dialog/copy-role-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { RowActionButtonInput, RowActionEventOutput } from '../talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/data-grid-comp/models/data-grid-comp.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-security-roles',
    templateUrl: './security-roles.component.html',
    styleUrls: ['./security-roles.component.scss'],
})
export class SecurityRolesComponent implements OnInit, Filterable {
    public routes: typeof routes = routes;
    public gridDataResult: GridDataResult;
    public view: Observable<GridDataResult>;
    public bindingType: String = 'array';
    public isLoading: boolean;
    public selectedItems: any[] = [];
    public roleList: SecurityRole[] = [];
    private sortString = "name-asc";
    public searchValue: string;
    public searchFilterString: string;
    clearSelectedItems: boolean = false;
    pageSize: number = 20;
    skip: number = 0;
    filterString: string;

    public columns: any[] = [
        {field: 'name', title: 'Name', dataType: "String"},
        {field: 'totalUsers', title: 'Total Users', dataType: "String"},
        {field: 'roleType', subField: 'name', title: 'Role Type', dataType: "String"},
        {field: 'description', title: 'Description', type: "longText", dataType: "String"},
    ]

    public filterCategories: any[] = [
        {field: 'name', title: 'Name', dataType: "String"},
        {field: 'description', title: 'Description', dataType: "String"}
    ];
    gridActionButtons: RowActionButtonInput[] = [
        {
          id: 'copy',
          name: this.translate.instant('Copy'),
          icon: 'copy'
        }
    ];
    getRequest: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private snackbarService: SnackbarService,
        private securityRoleService: SecurityRoleService,
        public translate: TranslateService,
        private dialog: MatDialog,
        private overlayService: OverlayService,
    ) {}

    ngOnInit(): void {
        this.getSecurityRoles();
    }

    getSecurityRoles() {
        this.isLoading = true;

        this.getRequest = this.securityRoleService.getSecurityRoles(this.skip, String(this.pageSize), this.searchFilterString, this.sortString)
        .pipe(
            finalize(
                () => this.isLoading = false
            )
        )
        .subscribe(
            pagedSecurityRoles => {
                this.gridDataResult = {
                    data: pagedSecurityRoles.data,
                    total: pagedSecurityRoles.total,
                };
            }
        );
    }

    deleteAllSelected(itemsToDelete: string[]) {
        const observables = itemsToDelete.map(item => defer(() => this.securityRoleService.deleteSecurityRole(item)));

        this.overlayService.show();

        forkJoin(observables)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe(
                (res) => {
                    this.clearSelectedItems = !this.clearSelectedItems;
                    this.getSecurityRoles();
                    this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
                },
                err => {
                    this.snackbarService.openSnackBar(err, 'clear', 'warn');
                }
            );
    }

    search(searchValue) {
        this.searchValue = searchValue;
        this.searchFilterString = `(Name like "${this.searchValue}")`;
        this.getRequest.unsubscribe();
        this.getSecurityRoles();
    }

    public pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.pageSize = event.take;
        this.getSecurityRoles();
    }

    filterCallback(filterString: string) {
        this.searchFilterString = filterString;
        this.skip = 0;;
        this.getSecurityRoles();
    }

    copySecurityRole(rowActionEventOutput: RowActionEventOutput) {
        let selectedRole = rowActionEventOutput?.rowData?.id;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            roleId: selectedRole
        };

        const dialogRef = this.dialog.open(CopyRoleDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data) {
                    this.router.navigate([`${routes.SECURITY}/${routes.ROLES}/${routes.EDITOR}/${data}`]);
                }
            }
        );
    }

    navigateToSecurityRole(securityRole?: SecurityRole) {
        if(securityRole){
          this.router.navigate([`${routes.SECURITY}${routes.ROLES}${routes.EDITOR}/${securityRole?.id}`], {relativeTo: this.route});
        }
        else {
          this.router.navigate([`${routes.SECURITY}${routes.ROLES}${routes.EDITOR}`], {relativeTo: this.route});
        }
    }
}
