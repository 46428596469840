import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { Letter } from '@app/modules/letter-generator/models/leter.model';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { employees } from "../select-recipient-list/employees";
import { images } from "../select-recipient-list/images";

@Component({
  selector: 'app-review-and-send',
  templateUrl: './review-and-send.component.html',
  styleUrls: ['./review-and-send.component.scss']
})
export class ReviewAndSendComponent implements OnInit {
  @Input() selectedLetter: Letter;
  @Input() selectedRecipients: any;
  @Input() letterVariables: any;

  public employees: any[] = employees;
  recipients: any[] = [];

  constructor(
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.selectedRecipients && changes.selectedRecipients.currentValue !== undefined){
      this.getRecipients()
    }

    if(changes.letterVariables && changes.letterVariables.currentValue !== undefined){
      console.log(changes.letterVariables)
    }
  }

  getRecipients(){
    this.recipients = [];

    if(this.selectedRecipients.length > 0){
      this.recipients = this.employees.filter(employee => {
        return this.selectedRecipients.includes(employee.id);
      });
    }
  }

  private photoURL(dataItem: any): string {
    const code: string = dataItem.img_id + dataItem.gender;
    const image: any = images;

    return image[code];
  }

  send(){
    this.overlayService.show();

    setTimeout(() => {
      this.overlayService.hide();
      this.snackbarService.openSnackBar('Letter sent successfully', 'clear', 'success');
    }, 2000);
  }

}
