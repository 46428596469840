<kendo-grid [data]="positions">
    <kendo-grid-column-group title="Positions" [columnMenu]="false">
        <ng-container
            *ngFor="let col of columns"
        >
            <!-- Normal Column Data -->
            <kendo-grid-column
                *ngIf="!col.type || col.type === 'navigation'"
                [field]="col.field"
                [title]="col.title"
                [width]="200">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        <ng-container *ngIf="col.subField && dataItem[col.field]; else noSubField">
                            {{dataItem[col.field][col.subField]}}
                        </ng-container>
                        <ng-template #noSubField>
                            {{dataItem[col.field]}}
                        </ng-template>
                    </div>
                </ng-template>
            </kendo-grid-column>
    
            <!-- Date Column Data -->
            <kendo-grid-column
                *ngIf="col.type === 'date'"
                [field]="col.field"
                [title]="col.title"
                [width]="200">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        <span>
                            {{dataItem[col.field] | date:'mediumDate' }}
                        </span>
                    </div>
                </ng-template>
            </kendo-grid-column>
        </ng-container>
    </kendo-grid-column-group>
</kendo-grid>