import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Directory } from '@app/shared/models/employee/documents/directory.model';
import { finalize } from 'rxjs/operators';
import { EmployeeDocumentsService } from '../../services/employee-documents.service';

@Component({
  selector: 'app-documents-drill-down-view',
  templateUrl: './documents-drill-down-view.component.html',
  styleUrls: ['./documents-drill-down-view.component.scss']
})
export class DocumentsDrillDownViewComponent implements OnInit {
  @Input() employeeID: string;
  @Input() refreshView: boolean;
  @Output() editAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() moveAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() showCreateNewDirectoryForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() showDocumentUploadDialog: EventEmitter<any> = new EventEmitter<any>();
  
  isLoading: boolean = true;
  directories: Directory[] = [];
  files: Directory[] = [];
  navigationPath: Directory[] = [
    {
      id: null,
      elementType: '',
      name: 'Employee Documents',
      parent: '',
      size: 0,
      comments: '',
      contentType: '',
      version: {
        versionCreatedOn: '',
        versionCreatedBy: {
          id: '',
          firstName: '',
          lastName: ''
        },
        versionEffectiveDate:'',
        modifiedList: [],
        changeReason: '',
        changeReasonComments: '',
        createdOn: ''
      }
    }
  ];
  selectedSortByOption = 'Name: A-Z';
  sortByOptions: string[] = [
    'Name: A-Z',
    'Name: Z-A',
    'Newest',
    'Oldest',
  ]

  icons = {
    'application/pdf': 'assets/icons/pdf-icon.svg',
    'application/msword': 'assets/icons/docx-icon.svg',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'assets/icons/docx-icon.svg',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'assets/icons/excel-icon.svg',
    'application/vnd.oasis.opendocument.spreadsheet': 'assets/icons/ods-icon.svg',
    'text/csv': 'assets/icons/csv-icon.svg',
    'application/zip': 'assets/icons/zip-icon.svg',
    'image/jpeg': 'assets/icons/jpg-icon.svg'
  }
  
  constructor(
    private employeeDocumentsService: EmployeeDocumentsService
  ) { }

  ngOnInit(): void {
    this.getRootDirectoryContents();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.refreshView.currentValue !== changes.refreshView.previousValue) {
      if(this.navigationPath.length === 1){
        this.getRootDirectoryContents();
      }
      else {
        this.getDirectoryContents(this.navigationPath[this.navigationPath.length - 1]);
      }
    }
  }

  getRootDirectoryContents(){
    // this.isLoading = true;
    // this.directories = [];
    // this.files = [];

    // this.employeeDocumentsService.getDirectories(this.employeeID)
    // .pipe(
    //   finalize(() => { this.isLoading = false; })
    // )
    // .subscribe(
    //   (res) => {
    //     res.forEach(element => {
    //       if(element.elementType === "FOLDER") {
    //         this.directories.push(element);
    //       }
    //       else if(element.elementType === "FILE") {
    //         this.files.push(element);
    //       }
    //     });
    //   },
    //   err => {
    //     console.log(err);
    //   }
    // );
  }

  getDirectoryContents(directory: Directory) {
    // this.isLoading = true;
    // this.directories = [];
    // this.files = [];

    // this.employeeDocumentsService.getDirectoryContents(this.employeeID, directory.id)
    // .pipe(
    //   finalize(() => { this.isLoading = false; })
    // )
    // .subscribe(
    //   (res) => {
    //     if(this.navigationPath[this.navigationPath.length - 1].id !== directory.id){
    //       this.navigationPath.push(directory);
    //     }

    //     res.forEach(element => {
    //       if(element.elementType === "FOLDER") {
    //         this.directories.push(element);
    //       }
    //       else if(element.elementType === "FILE") {
    //         this.files.push(element);
    //       }
    //     });

    //     this.sort(this.selectedSortByOption);
    //   },
    //   err => {
    //     console.log(err);
    //   }
    // );
  }

  sort(value: string) {
    if(value === 'Name: A-Z') {
      this.directories.sort((a, b) => a.name.localeCompare(b.name));
      this.files.sort((a, b) => a.name.localeCompare(b.name));
    }
    else if(value === 'Name: Z-A') {
      this.directories.sort((a, b) => a.name.localeCompare(b.name)).reverse();
      this.files.sort((a, b) => a.name.localeCompare(b.name)).reverse();
    }
    else if(value === 'Newest') {
      this.directories.sort((a, b) => (a.version.versionCreatedOn > b.version.versionCreatedOn ? -1 : 1));
      this.files.sort((a, b) => (a.version.versionCreatedOn > b.version.versionCreatedOn ? -1 : 1));
    }
    else if(value === 'Oldest') {
      this.directories.sort((a, b) => (a.version.versionCreatedOn < b.version.versionCreatedOn ? -1 : 1));
      this.files.sort((a, b) => (a.version.versionCreatedOn < b.version.versionCreatedOn ? -1 : 1));
    }
  }

  navigateToDirectory(directory: Directory) {
    //If destination directory is not the root
    if(directory.id) {
      //get index of destination and trim navigation path to that point
      let index = this.navigationPath.indexOf(directory);

      this.setNavigationPath(index);
      this.getDirectoryContents(directory);
    }
    else {
      this.setNavigationPath(1);
      this.getRootDirectoryContents();
    }
  }

  setNavigationPath(index: number) {
    this.navigationPath = this.navigationPath.splice(0, index); 
  }

  emitEditEvent(item: Directory) {
    this.editAction.emit(item);
  }
  
  emitDeleteEvent(item: Directory) {
    this.deleteAction.emit(item);
  }

  emitViewFileEvent(item: Directory) {
    this.viewAction.emit(item);
  }

  emitDownloadEvent(item: Directory) {
    this.downloadAction.emit(item.id);
  }

  emitShowCreateNewDirectoryForm(parentDiretory?: Directory) {
    this.showCreateNewDirectoryForm.emit(parentDiretory ? parentDiretory : null);
  }

  emitShowDocumentUploadDialog(parentDiretory?: Directory) {
    this.showDocumentUploadDialog.emit(parentDiretory ? parentDiretory : null);
  }

}
