<app-layout>
    <mat-toolbar class="page-header" role="heading">
      <h1>Workforce Planning</h1>
    </mat-toolbar>
  
    <div class="content">
      <div>
          <app-workforce-cost-visibility></app-workforce-cost-visibility>
          <app-diversity-scorecard></app-diversity-scorecard>
      </div>

      <app-contingent-worker-analysis></app-contingent-worker-analysis>

      <app-contingent-worker-cost></app-contingent-worker-cost>
    </div>

</app-layout>