import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {api_routes} from '@app/consts';
import {Observable} from 'rxjs';
import {PagedData} from '@app/modules/lookup/models/paged-data.model';
import {Timezone} from '@app/modules/lookup/models/timezone.model';
import {EnvironmentService} from "@app/core/services/environment.service";

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getTimezones(): Observable<PagedData<Timezone>> {
    return this.http.get<any>( `${this.envService.env.apiUrl}${api_routes.TIMEZONES}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
}
