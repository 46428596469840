import { Component, Input } from '@angular/core';
import { routes } from '@app/consts';
import {SecurityRoleService} from '@app/modules/security-roles/services/security-role.service';
import {SecurityFeature} from '@app/modules/security-setup/models/security-role.model';
import { features } from '@app/consts';
import { modules } from '@app/consts/modules';
import { EmployeeService } from '../services/employee.service';
import { CurrentUserDetails } from '@app/shared/models/employee.model';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../services/translation.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  public routes: typeof routes = routes;
  public modules: typeof modules = modules;
  public features: typeof features = features;
  public isOpenUiElements = false;
  @Input() showTooltip: boolean;

  siteSettingsFeatures = [
    features.SITE_SETTINGS,
    features.ORGANIZATION,
    features.POSITIONS,
    features.COST_CENTERS,
    features.LISTS,
    features.WORK_LOCATIONS,
    features.WORK_ROTATIONS,
    features.TIME_OFF,
    features.FORMS,
    features.CURRENCY,
    features.GLOBALIZATION,
    features.SCHEDULES,
    features.COUNTRY_CONFIG,
    features.BUSINESS_RULE_AUTOMATION,
  ]
  currentEmployeeDetails: CurrentUserDetails;

  menuItems = [];

  translationsLoaded: boolean = false;

  constructor(
    public translate: TranslateService,
    private employeeService: EmployeeService,
    private translationService: TranslationService,
  ) {
    this.currentEmployeeDetails = this.employeeService.getFetchedCurrentUserDetails()
  }

  ngOnInit(): void {
    // If translations are already loaded set the columns
    if(this.translationService.currentTranslations !== null){
      this.translationsLoaded = true;
      this.setColumnsWithTranslations();
    }

    // once the translations for the app have loaded set the columns to the correct translation values
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translationsLoaded = true;
      this.setColumnsWithTranslations();
    });
  }

  setColumnsWithTranslations() {
    this.menuItems = [
      {
        route: routes.HOMEPAGE,
        feature: features.HOMEPAGE,
        module: 'allow',
        icon: 'home',
        localizationCode: 'Homepage'
      },
      {
        route: routes.DASHBOARD,
        feature: features.DASHBOARDS,
        module: 'allow',
        icon: 'dashboard',
        localizationCode: 'Dashboard'
      },
      {
        route: routes.TALENT_TRACK,
        feature: features.TALENT,
        module: 'allow',
        icon: 'group',
        localizationCode: 'Talent'
      },
      {
        route: routes.REPORTS,
        feature: features.REPORTS,
        module: 'allow',
        icon: 'assessment',
        localizationCode: 'Reports'
      },
      {
        route: routes.SITE_SETTINGS,
        feature: this.siteSettingsFeatures,
        module: 'allow',
        icon: 'settings_applications',
        localizationCode: 'SiteSettings'
      },
      {
        route: `${routes.SECURITY}${routes.ROLES}`,
        feature: features.SECURITY,
        module: 'allow',
        icon: 'security',
        localizationCode: 'Security'
      },
      {
        route: `${routes.PERFORMANCE}`,
        feature: features.PERFORMANCE,
        module: modules.PERFORMANCE,
        icon: 'speed',
        localizationCode: 'Performance'
      },
    ]

    if(this.currentEmployeeDetails.userType === 'User') {
      this.menuItems.push(
        {
          route: `${routes.ADMIN_CONTROLS}${routes.POSITIONS}`,
          feature: 'allow',
          module: 'allow',
          icon: 'admin_panel_settings',
          localizationCode: 'AdminControls'
        }
      )
    }
  }

  public openUiElements() {
    this.isOpenUiElements = !this.isOpenUiElements;
  }
}
