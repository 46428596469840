<ng-container *ngIf="isLoadingPermissions || isLoadingEmploymentRecord; else loaded">
    <ngx-skeleton-loader
      count="5"
      [theme]="{ 
          'height.px': 50
      }"
    ></ngx-skeleton-loader>
</ng-container>
<ng-template #loaded>
    <mat-accordion class="example-headers-align">
        <mat-expansion-panel [expanded]="isCurrentEmploymentRecord(employmentRecord.terminationDateActual)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <!-- <div class="label">{{position.position.name}}</div> -->
                        <span class="date">
                            <span class="label" appLocalizationPopupDirective localizationCode="EmploymentRecord-StartDate">{{ 'EmploymentRecord-StartDate' | translate: {Default: "Start Date"} }}: </span>
                            {{employmentRecord.hireDate | date}}
                        </span>
                        <span class="date">
                            <span class="label" appLocalizationPopupDirective localizationCode="EmploymentRecord-EndDate">{{ 'EmploymentRecord-EndDate' | translate: {Default: "End Date"} }}: </span>
                            {{employmentRecord.terminationDateActual | date}}
                        </span>
    
                        <mat-chip-list>
                            <mat-chip *ngIf="isCurrentEmploymentRecord(employmentRecord.terminationDateActual)" class="active-chip" selected appLocalizationPopupDirective localizationCode="EmploymentRecord-Active">{{ 'EmploymentRecord-Active' | translate: {Default: "Active"} }}</mat-chip>
                            <mat-chip *ngIf="!isCurrentEmploymentRecord(employmentRecord.terminationDateActual)" class="inactive-chip" selected appLocalizationPopupDirective localizationCode="EmploymentRecord-Terminated">{{ 'EmploymentRecord-Terminated' | translate: {Default: "Terminated"} }}</mat-chip>
                        </mat-chip-list>
    
                        <div class="k-flex-grow"></div>
    
    
                </mat-panel-title>
                <mat-panel-description>
                    <button
                        mat-raised-button
                        setColor="primaryColour" 
                        color="primary"
                        [disabled]="formValid === false"
                        appPreventDoubleClick 
                        (throttledClick)="getFormData = !getFormData; $event.stopPropagation();"
                        *appTableAccess="[tableCreateAccessObj, tableUpdateAccessObj]">
                        {{ 'Save' | translate: {Default: "Save"} }}
                    </button>
                    <mat-icon
                        setColor 
                        [setColorRules]="[
                            {
                                color: 'primaryColour',
                                events: ['hover']
                            }
                        ]"
                        *appTableAccess="[tableDeleteAccessObj]"
                        class="material-icons-outlined clickable-icon"
                        appPreventDoubleClick (throttledClick)="openConfirmDeleteDialog(); $event.stopPropagation();"
                        [matTooltip]="translate.instant('DeleteEmploymentRecord')"
                    >delete</mat-icon>
                    <mat-icon 
                        setColor 
                        [setColorRules]="[
                            {
                                color: 'primaryColour',
                                events: ['hover']
                            }
                        ]"
                        aria-hidden="false" 
                        class="material-icons-outlined  k-mr-3 clickable-icon" 
                        aria-label="add icon"
                        (click)="$event.stopPropagation(); openHistoryDialog()"
                    >history</mat-icon>
                    <mat-icon 
                        setColor 
                        [setColorRules]="[
                            {
                                color: 'primaryColour',
                                events: ['hover']
                            }
                        ]"
                        aria-hidden="false" 
                        class="material-icons-outlined k-mr-3 clickable-icon" 
                        aria-label="add icon"
                        (click)="$event.stopPropagation(); openAccessLogsDialog()"
                    >fingerprint</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>
    
            <app-form-generator
                [formId]="formId"
                [formData]="formData"
                [emitFormDataTrigger]="getFormData"
                [targetEmployeeTablePermissions]="targetEmployeeTablePermissions"
                (emitFormData)="formDataEmitted($event)"
                (emitFormPristine)="formPristineEmitted($event)"
                (emitFormStatus)="formStatusUpdated($event)"
            ></app-form-generator>
    
        </mat-expansion-panel>
    </mat-accordion>
</ng-template>

