import {Component, OnInit, ViewChild} from '@angular/core';
import {SnackbarService} from '@app/core/services/snackbar.service';
import {SiteSettings} from '@app/modules/lookup/models/site-settings.model';
import {SettingsService} from '@app/core/services/settings.service';
import {SecurityProtectedBase} from '@app/shared/components/security-protected/security-protected';
import {FeatureService} from '@app/core/services/feature.service';
import {features} from '@app/consts';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { FormGeneratorComponent } from '@app/shared/components/form-generator/form-generator.component';

@Component({
    selector: 'site-settings-configure',
    templateUrl: './site-settings-configure.component.html',
    styleUrls: ['./site-settings-configure.component.scss']
})
export class SiteSettingsConfigureComponent extends SecurityProtectedBase implements OnInit {
    @ViewChild(FormGeneratorComponent) formGeneratorComponent: FormGeneratorComponent

    isLoading: boolean = true;
    formData: any;
    formId: string = 'frm_MBiQZtfBpdVqKo';
    getFormData: boolean = false;
    formValid: boolean = false;
    siteSettings: SiteSettings;
    show: boolean = false;

    constructor(
        private settingsService: SettingsService,
        private snackbarService: SnackbarService,
        private overlayService: OverlayService,
        private translate: TranslateService,
        featureService: FeatureService
    ) {
        super(featureService, features.SITE_SETTINGS);
    }

    ngOnInit(): void {
        if (this.hasFeatureAccess) {
            this.getSiteSettings();
        }
    }

    private getSiteSettings() {
        this.isLoading = true;
        this.settingsService.getSiteSettings().subscribe(
            siteSettings => {
                this.siteSettings = siteSettings;
                this.createSettingsForm(siteSettings);
                this.isLoading = false;
            }
        );
    }

    createSettingsForm(siteSettings: SiteSettings) {
        this.formData = {
            baseTimezone: siteSettings?.baseTimezone?.id ?? null,
            baseCurrency: siteSettings?.baseCurrency?.id ?? null,
            companyName: siteSettings?.companyName ?? null,
            defaultCulture: siteSettings?.defaultCulture?.id ?? null,
            securityAllowHubbubHrLogin: siteSettings?.securityAllowHubbubHrLogin ?? null,
            securityAllowHubbubAuthentication: siteSettings?.securityAllowHubbubAuthentication ?? null,
            securityAllowAzureAdAuthentication: siteSettings?.securityAllowAzureAdAuthentication ?? null,
            username: siteSettings?.email?.username ?? null,
            password: siteSettings?.email?.password ?? null,
            port: siteSettings?.email?.port ?? null,
            smtp: siteSettings?.email?.smtp ?? null,
            sendFromHubbubHr: siteSettings?.email?.sendFromHubbubHr ?? null,
            disabled: siteSettings?.email?.disabled ?? null,
            payrollStartingNumber: siteSettings?.payrollStartingNumber ?? null,
        }
    }

    formDataEmitted(formData) {
        this.saveSettings(formData);
    }
    
    formStatusUpdated(formValid) {
        this.formValid = formValid;
    }

    saveSettings(formData) {
        this.overlayService.show();

        formData.email = {
            username: formData?.username || formData?.username != '' ? formData?.username : null,
            password: formData?.password ?? null,
            port: formData.port ?? null,
            smtp: formData?.smtp ?? null,
            sendFromHubbubHr: formData?.sendFromHubbubHr ?? null,
            disabled: formData?.disabled ?? null,
        }
        
        this.settingsService.updateSiteSettings(formData)
        .pipe(
            finalize(()=>{ 
              this.overlayService.hide();
            })
        )
        .subscribe(
            res => {
                this.getSiteSettings();
                this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
            }
        );
    }
}
