<h5 mat-dialog-title>{{ 'AssignTimeOffPolicy' | translate: {Default: "Assign Time Off Policy"} }}</h5>

<mat-dialog-content>
    <form class="form-container" *ngIf="form && timeOffTypeOptions.length > 0; else loading" [formGroup]="form">

        <mat-form-field appearance="fill">
            <mat-label>
                {{ 'TimeOffType' | translate: {Default: "Time Off Type"} }}
            </mat-label>

            <mat-select 
                disableOptionCentering 
                formControlName="type"
                (selectionChange)="setSelectedTimeOffType($event)">
                <mat-option></mat-option>
                <ng-container *ngIf="timeOffTypeOptions.length > 0">
                    <mat-option *ngFor="let option of timeOffTypeOptions" [value]="option.id">{{option.name}}</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                {{ 'Policy' | translate: {Default: "Policy"} }}
            </mat-label>

            <mat-select disableOptionCentering formControlName="policy">
                <mat-option></mat-option>
                <ng-container *ngIf="selectedTimeOffType">
                    <mat-option *ngFor="let option of selectedTimeOffType.policies" [value]="option.id">{{option.name}}</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>

    </form>

    <ng-template #loading>
        <ngx-skeleton-loader
            count="5"
            [theme]="{ 
                'height.px': 50
            }"
        ></ngx-skeleton-loader>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button> 
</mat-dialog-actions>