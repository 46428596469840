<mat-card>
    <mat-card-content>

        <app-data-grid-comp
            [tableId]="db_tables.EmployeeGoals"
            [columns]="columns"
            [filterCategories]="filterCategories"
            [sortableColumns]="sortableColumns"
            [bindingType]="bindingType"
            [view]="view"
            [gridData]="gridData"
            [gridDataResult]="gridDataResult"
            [isLoading]="isLoading"
            [pageSize]="pageSize"
            [skip]="skip"
            category="DirectReportsGoals"
            [rowSelectionSettings]="{
                canSelectRows: true,
                selectableMode: 'multiple',
                selectRowBy: 'id'
            }"
            clickableRows="true"
            searchable="true"
            hideActionButtons="true"
            sortable="true"
            [isLoading]="isLoading"
            [clearSelectedItems]="clearSelectedItems"
            (emitPageChangeEvent)="pageChange($event)"
            (emitOpenDialogEvent)="getGoalVerbose($event)"
            (emitDeleteEvent)="deleteGoals($event)"
            (emitSearchEvent)="search($event)"
            (emitFilterEvent)="filterCallback($event)"
            (emitSortEvent)="sortChange($event)"
        ></app-data-grid-comp>
          
    </mat-card-content>
</mat-card>
