<h5 mat-dialog-title></h5>

<mat-dialog-content>
    <h4 *ngIf="localizationCode" appLocalizationPopupDirective [localizationCode]="localizationCode">
        {{ translate.instant(localizationCode) }}
    </h4>
    
    <h4>{{this.text}}</h4>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Cancel</button>
    <button *ngIf="localizationCode !== null || text !== null" setColor="primaryColour" class="mat-raised-button submit-button" (click)="confirm()">Confirm</button>
</mat-dialog-actions>