import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TimeOffInLieuService } from '@app/core/services/time-off-in-lieu/time-off-in-lieu.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { MyRequest } from '@app/shared/models/workflows-absences/workflows-absences.model';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { RequestToilDialogComponent } from '../../../toil-policies-widget/components/request-toil-dialog/request-toil-dialog.component';
import { EmployeeToilRequest } from '@app/shared/models/time-off-in-lieu/time-off-in-lieu.model';
import { AccrueToilDialogComponent } from '../../../toil-policies-widget/components/accrue-toil-dialog/accrue-toil-dialog.component';

@Component({
    selector: 'toil-requests-list-item',
    templateUrl: './toil-requests-list-item.component.html',
    styleUrls: ['./toil-requests-list-item.component.scss']
})
export class ToilRequestsListItemComponent implements OnInit {
    @Output() openEmployeeToilRequestDialog = new EventEmitter<EmployeeToilRequest>();
    @Output() refreshRequests = new EventEmitter<boolean>();
    @Input() employeeToilRequest: EmployeeToilRequest;
    iconUrl: string;
    user$: any;
    moment = moment;

    constructor(
        private dialog: MatDialog,
        private timeOffInLieuService: TimeOffInLieuService,
        public domSanitizer: DomSanitizer,
        private overlayService: OverlayService,
        private datePipe: DatePipe,
        private oidcAuthService: OidcAuthService,
        private translate: TranslateService,
        private snackbarService: SnackbarService,
    ) {
        this.user$ = this.oidcAuthService.userProfile;
        console.log(this.employeeToilRequest)
    }

    ngOnInit(): void {
        this.timeOffInLieuService.getToilTypeIcon(this.employeeToilRequest?.toilClassType?.id)
        .subscribe(
            res => {
                this.iconUrl = res;
            }
        )
    }

    isInPast(from: string): boolean {
        return moment(from).isBefore();
    }

    isInFuture(from: string): boolean {
        return moment(from).isAfter();
    }

    openEditRequestDialog() {
        let dialogRef;
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            employeeId: this.employeeToilRequest?.employee?.id,
            employeeToilRequest: this.employeeToilRequest
        };

        if(this.employeeToilRequest.toilClassType?.id === 'AccrueToil'){
            dialogRef = this.dialog.open(AccrueToilDialogComponent, dialogConfig);
        }
        else {
            dialogRef = this.dialog.open(RequestToilDialogComponent, dialogConfig);
        }

        dialogRef.afterClosed().subscribe(
            data => {
                if (data === true) {
                    this.refreshRequests.emit(true);
                }
            }
        );
    }

    openConfirmDeleteDialog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            // text: `Are you sure you want to cancel this request?\r\n(${this.employeeToilRequest.toilPolicy.name}: ${this.datePipe.transform(this.employeeToilRequest.startDate, 'mediumDate')} - ${this.datePipe.transform(this.employeeToilRequest.endDate, 'mediumDate')})`
            text: this.translate.instant('ConfirmDeleteToilRequest')
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data === true) {
                    this.delete();
                }
            }
        );
    }

    delete() {
        this.overlayService.show();

        this.timeOffInLieuService.deleteRequest(this.employeeToilRequest.id)
        .pipe(
            finalize(() => this.overlayService.hide())
        )
        .subscribe(
            (res) => {
                this.refreshRequests.emit(true);
                this.snackbarService.openSnackBar(this.translate.instant('CancelledSuccessfully'), 'clear', 'success');
            }
        );
    }

    showEmployeeTooltip(employees: {id: string, firstName: string, lastName: string}[]) {
        if(employees){
            return employees.map( employee => `${employee.firstName} ${employee.lastName}`).join(', ');
        }
    
        return null;
    }

}
