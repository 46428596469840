import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'currencySearch' })
export class CurrencySearchPipe implements PipeTransform {
  public transform(currencies: any[], searchText: any): any {
    if (searchText == '' || currencies == null) {
      return currencies;
    }

    return currencies.filter(currency => 
        currency.id.toUpperCase().includes(searchText.toUpperCase())
    );
  }
}