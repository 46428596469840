<mat-dialog-content>
    <app-local-data-grid-comp
        class="calculation-log-data-grid"
        [sort]="sort"
        [columns]="columns"
        [data]="timeOffCalculationLog"
        [isLoading]="isLoading"
        [pageSize]="pageSize"
        [pageable]="false"
        category="CalculationLog"
        export="excel"
        [filterToggleDetails]="filterToggleDetails"
        (emitToggleFilter)="updateFilterToggleDetails($event)"
    ></app-local-data-grid-comp>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
</mat-dialog-actions>