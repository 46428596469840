import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Letter } from '@app/modules/letter-generator/models/leter.model';

@Component({
  selector: 'app-preview-letter-dialog',
  templateUrl: './preview-letter-dialog.component.html',
  styleUrls: ['./preview-letter-dialog.component.scss']
})
export class PreviewLetterDialogComponent implements OnInit {
  letter: Letter;

  constructor(
    private dialogRef: MatDialogRef<PreviewLetterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data)
  {
    this.letter = data.letter;
  }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }

}
