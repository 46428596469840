import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {SnackbarService} from '@app/core/services/snackbar.service';
import {CultureService} from '@app/core/services/system-language/culture.service';
import {LookupService} from '@app/modules/lookup/services/lookup.service';
import {OverlayService} from '@app/shared/components/overlay/overlay.service';
import {Culture} from '@app/shared/models/system-language/culture.model';
import {CostCentersService} from '@app/modules/cost-centers/services/cost-centers.service';
import {finalize} from 'rxjs/operators';
import {CostCenter, CostCenterVerbose} from '@app/modules/cost-centers/models/cost-center.model';
import {WorkLocationSubmit} from '@app/modules/work-locations/models/work-location.model';
import {Holiday, HolidaySubmit, HolidayVerbose} from '@app/modules/statutory-holidays/models/statutory-holidays.model';
import {StatutoryHolidaysService} from '@app/modules/statutory-holidays/services/statutory-holidays.service';
import * as moment from 'moment';

@Component({
    selector: 'app-holiday-day-dialog',
    templateUrl: './holiday-day-dialog.component.html',
    styleUrls: ['./holiday-day-dialog.component.scss']
})
export class HolidayDayDialogComponent implements OnInit {

    form: UntypedFormGroup;
    isLoading: boolean = false;
    public allCultures: Culture[] = [];
    holidayId: string;
    holidayGroupId: string;
    holiday: HolidayVerbose;

    constructor(
        private snackbarService: SnackbarService,
        private fb: UntypedFormBuilder,
        private overlayService: OverlayService,
        private cultureService: CultureService,
        private lookupService: LookupService,
        private dialogRef: MatDialogRef<HolidayDayDialogComponent>,
        private costCentresService: CostCentersService,
        private holidayService: StatutoryHolidaysService,
        @Inject(MAT_DIALOG_DATA) data) {
        this.holidayId = data.holidayId;
        this.holidayGroupId = data.holidayGroupId;
    }

    ngOnInit(): void {
        this.cultureService.getCultures().subscribe(res => {
            this.allCultures = res;
        });

        this.holidayId
            ? this.getHoliday()
            : this.createForm();

    }

    getHoliday() {
        this.isLoading = true;
        this.holidayService.getHolidayDay(this.holidayId)
            .pipe(finalize(() => {
                this.isLoading = false;
            }))
            .subscribe(
                holidayVerbose => {
                    this.holiday = holidayVerbose;
                    this.createForm();
                }
            );
    }

    createForm() {
        this.form = this.fb.group({
            id: [this.holiday ? this.holiday.id : null],
            localizations: this.fb.array([]),
            startDate: [this.holiday ? moment(this.holiday.startDate, 'YYYY-MM-DD').format() : null, Validators.required],
            endDate: [this.holiday ? moment(this.holiday.endDate, 'YYYY-MM-DD').format() : null, [Validators.required]],
            hourOverride: [this.holiday?.hourOverride ?? null, Validators.min(1)],
            notes: [this.holiday?.notes ?? null],
        });

        if (this.holiday?.name.length > 0) {
            this.holiday.name.forEach(
                localization => {
                    this.addNewLocalization(localization.culture, localization.text);
                }
            );
        } else {
            this.addNewLocalization();
        }

    }

    get localizations() {
        return this.form.controls['localizations'] as UntypedFormArray;
    }

    addNewLocalization(culture?: string, localization?: string) {
        const localizationForm = this.fb.group({
            culture: [culture || null, Validators.required],
            text: [localization || null, Validators.required]
        });

        this.localizations.push(localizationForm);
    }

    deleteLocalization(index: number) {
        this.localizations.removeAt(index);
    }

    isCultureHidden(cultureId) {
        let res = false;
        this.localizations.value.forEach(localization => {
            if (localization.culture === cultureId) {
                res = true;
            }
        });
        return res;
    }

    close() {
        this.dialogRef.close();
    }

    save() {
        this.overlayService.show();

        const holidaySubmit: HolidaySubmit = {
            id: this.form.value.id,
            name: this.form.value.localizations,
            startDate: this.form.value.startDate,
            endDate: this.form.value.endDate,
            halfStat: this.form.value.halfStat,
            statutoryHolidayGroup: this.holidayGroupId,
            notes: this.form.value.notes,
            hourOverride: this.form.value.hourOverride
        };

        if (this.form.value.id !== null) {
            this.updateHoliday(holidaySubmit);
        } else {
            this.createHoliday(holidaySubmit);
        }
    }

    updateHoliday(holidaySubmit: HolidaySubmit) {
        this.holidayService.putHolidayDay(this.holidayId, holidaySubmit)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe((res) => {
                    this.dialogRef.close(true);
                    this.snackbarService.openSnackBar('Updated successfully', 'clear', 'success');
                },
                err => {
                    this.snackbarService.openSnackBar(err, 'clear', 'warn');
                }
            );
    }

    createHoliday(holidaySubmit: HolidaySubmit) {
        this.holidayService.postHolidayDay(holidaySubmit)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe((res) => {
                    this.dialogRef.close(true);
                    this.snackbarService.openSnackBar('Created successfully', 'clear', 'success');
                },
                err => {
                    this.snackbarService.openSnackBar(err, 'clear', 'warn');
                }
            );
    }

}
