<h5 mat-dialog-title>{{ 'ReviewPlanPublishHistory' | translate: {Default: "Review Plan Publish History"} }}</h5>

<mat-dialog-content>
    <app-data-grid-comp
        [tableId]="db_tables.PerformanceReviewPlans"
        [columns]="columns"
        [filterCategories]="filterCategories"
        [bindingType]="bindingType"
        [view]="view"
        [gridDataResult]="gridDataResult"
        [isLoading]="isLoading"
        [pageSize]="pageSize"
        [skip]="skip"
        [category]=""
        [rowSelectionSettings]="{
            canSelectRows: false
        }"
        clickableRows="false"
        hideActionButtons="true"
        [isLoading]="isLoading"
        [sortable]="{
            mode: 'multiple'
        }"
        [sortableColumns]="sortableColumns"
        maxHeight="50"
        showRefreshButton="true"
        (emitRefreshEvent)="getGoalPlanPublishHistory()"
        (emitPageChangeEvent)="pageChange($event)"
        (emitSortEvent)="sortChange($event)"
        (emitFilterEvent)="filterCallback($event)"
    ></app-data-grid-comp>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
</mat-dialog-actions>