import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { SecurityRoleService } from '@app/modules/security-roles/services/security-role.service';
import { SecurityRole } from '@app/modules/security-setup/models/security-role.model';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { defer, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-assign-security-role-employee-dialog',
  templateUrl: './assign-security-role-employee-dialog.component.html',
  styleUrls: ['./assign-security-role-employee-dialog.component.scss']
})
export class AssignSecurityRoleEmployeeDialogComponent implements OnInit {
  employeeId: any;

  constructor(
    private snackbarService: SnackbarService,
    private securityRoleService: SecurityRoleService,
    private dialog: MatDialog,
    public translate: TranslateService,
    private overlayService: OverlayService,
    private dialogRef: MatDialogRef<AssignSecurityRoleEmployeeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.employeeId = data.employeeId
  }

  public gridDataResult: GridDataResult;
  public isLoading: boolean;
  public selectedItems: any[] = [];
  public roleList: SecurityRole[] = [];
  pageSize: number = 20;
  skip: number = 0;

  public filterCategories: any[] = [{field: 'name', title: 'Name' , dataType: "String"}];
  filterString: string;

  ngOnInit(): void {
    this.getSecurityRoles();
  }

  getSecurityRoles() {
      this.isLoading = true;
      this.securityRoleService.getSecurityRoles(this.skip, String(this.pageSize), this.filterString)
          .pipe(finalize(() => this.isLoading = false))
          .subscribe(
          pagedSecurityRoles => {
              this.gridDataResult = {
                  data: pagedSecurityRoles.data,
                  total: pagedSecurityRoles.total,
              };
          });
  }

  openConfirmAddUsersDialog() {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      dialogConfig.data = {
          text: `Are you sure you want to assign ${this.selectedItems.length} selected Security Role(s)?`
      };

      const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
          data => {
              if (data === true) {
                  const observables = this.selectedItems.map(selectedSecurityRole => defer(() => this.securityRoleService.addUserToRole(selectedSecurityRole, this.employeeId)));

                  this.overlayService.show();

                  forkJoin(observables)
                      .pipe(
                          finalize(() => this.overlayService.hide())
                      )
                      .subscribe(
                          (res) => {
                              this.selectedItems = [];
                              this.dialogRef.close(true)
                              this.snackbarService.openSnackBar(this.translate.instant('AssignedSuccessfully'), 'clear', 'success');
                          }
                      );
              }
          }
      );
  }

  close(){
    this.dialogRef.close(true)
  }

  public pageChange(event: PageChangeEvent): void {
      this.skip = event.skip;
      this.pageSize = event.take
      this.getSecurityRoles();
  }

  filterCallback(filterString: string) {
      this.filterString = filterString;
      this.skip = 0;;
      this.getSecurityRoles();
  }

}
