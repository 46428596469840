<app-employee-layout selectedComponent="EmployeeDetails">
    <div class="navigation-menu-list main-container">
        <div class="left">
            <ng-container *ngIf="isLoading; else loaded">
                <ngx-skeleton-loader
                    count="5"
                    [theme]="{ 
                        'height.px': 50,
                        'width.px': 200,
                        'background-color': 'white',
                        border: '1px solid white'
                    }"
                ></ngx-skeleton-loader>
            </ng-container>
            <ng-template #loaded>
                <mat-list>
                    <ng-container *ngFor="let item of navList">
                        <mat-list-item 
                            *appTableAccess="[item.tableAccess]" 
                            appLocalizationPopupDirective 
                            [localizationCode]="item.text"
                            [routerLink]="[item.link]" 
                            class="primaryHoverColor"
                            setColor 
                            [setColorRules]="[
                                {
                                    color: 'primaryColour',
                                    events: ['hover']
                                },
                                {
                                    color: 'primaryColour',
                                    setIfClass: ['primaryColor'],
                                    events: ['routerLinkActive']
                                },
                            ]" 
                            (load)="scrollToElement($event)"
                            [ngClass]="{'primaryColor scrollTarget': router.url === item.link }"> 
                            {{ translate.instant(item.text) }}
                        </mat-list-item>
                    </ng-container>
                </mat-list>
            </ng-template>
        </div>

        <div class="main-content">
            <ng-content></ng-content>
        </div>
        
        <!-- <div *ngIf="employeeId" class="main-content" [ngSwitch]="section">
            <app-employee-work-experience *ngSwitchCase="'WorkHistory'" [employeeID]="employeeId" [formIds]="['frm_pIddWWLTy6JZ5t']"></app-employee-work-experience>
            <app-employee-leave *ngSwitchCase="'LeaveHistory'" [employeeId]="employeeId"></app-employee-leave>
            <app-employee-documents *ngSwitchCase="'Documents'" [employeeID]="employeeId"></app-employee-documents>
            <app-employee-personal-details *ngSwitchCase="'PersonalDetails'" [employeeId]="employeeId"></app-employee-personal-details>
            <app-employee-visas-and-permits *ngSwitchCase="'VisaPermits'" [employeeID]="employeeId" [formIds]="['frm_Ah07TMe2So49GQ']"></app-employee-visas-and-permits>
            <app-training-and-certifications *ngSwitchCase="'TrainingAndCertifications'" [employeeID]="employeeId" [formIds]="['frm_Kr04U33rv5KcPv']"></app-training-and-certifications>
            <app-employee-languages *ngSwitchCase="'Languages'" [employeeID]="employeeId" [formIds]="['frm_wiVZbFOqsnDtZH']"></app-employee-languages>
            <app-employee-medical-tests *ngSwitchCase="'MedicalTests'" [employeeID]="employeeId" [formIds]="['frm_7CGuNBqNgqMO97']"></app-employee-medical-tests>
            <app-employee-professional-expertises *ngSwitchCase="'ProfessionalExpertises'" [employeeID]="employeeId" [formIds]="['frm_e1Q9MR0lVqvY02']"></app-employee-professional-expertises>
            <app-employee-associations *ngSwitchCase="'Association'" [employeeID]="employeeId" [formIds]="['frm_HxOAVm3Tk2eIeR']"></app-employee-associations>
            <app-employee-education *ngSwitchCase="'Education'" [employeeID]="employeeId" [formIds]="['frm_SRqaXq6BeLpeks']"></app-employee-education>
            <app-employee-family-dependants *ngSwitchCase="'FamilyDependants'" [employeeID]="employeeId" [formIds]="['frm_ItuEzwtvGFrp73']"></app-employee-family-dependants>
            <app-emergency-contacts *ngSwitchCase="'EmergencyContacts'" [employeeID]="employeeId" [formIds]="['frm_IVv9vIRPmA3YXO']"></app-emergency-contacts>
            <app-upload-profile-image *ngSwitchCase="'ProfileImage'" [employeeID]="employeeId"></app-upload-profile-image>
            <app-employee-compensation *ngSwitchCase="'Compensation'" [employeeID]="employeeId"></app-employee-compensation>
            <app-employee-other-compensation *ngSwitchCase="'OtherCompensation'" [employeeID]="employeeId"></app-employee-other-compensation>
            <app-employee-company-asset *ngSwitchCase="'CompanyAssets'" [employeeID]="employeeId"></app-employee-company-asset>
            <app-employee-relocation *ngSwitchCase="'Relocations'" [employeeID]="employeeId"></app-employee-relocation>
            <app-employee-grievances *ngSwitchCase="'Grievances'" [employeeID]="employeeId"></app-employee-grievances>
            <app-employee-injury-illness *ngSwitchCase="'InjuryIllness'" [employeeID]="employeeId"></app-employee-injury-illness>
            <app-employee-bank-details *ngSwitchCase="'BankDetails'" [employeeID]="employeeId"></app-employee-bank-details>
            <app-employee-employment-details *ngSwitchCase="'EmploymentDetails'" [employeeID]="employeeId"></app-employee-employment-details>
            <app-employee-payroll-details *ngSwitchCase="'PayrollDetails'" [employeeID]="employeeId"></app-employee-payroll-details>-->
            
            
            <!-- <app-onboarding-demo *ngSwitchCase="'New Hire Checklist'"></app-onboarding-demo> -->
            <!-- <app-employee-roster-demo *ngSwitchCase="'Schedule'" [employeeID]="employeeId"></app-employee-roster-demo> -->
            <!-- <app-employee-addresses *ngSwitchCase="'Addresses'" [employeeID]="employeeId" [formIds]="['frm_nk27jnK1eaWnlH']"></app-employee-addresses> -->
            <!-- <app-employee-email-addresses *ngSwitchCase="'Email Addresses'" [employeeID]="employeeId" [formIds]="['frm_RCyHA5DmA1oZly']"></app-employee-email-addresses> -->
            <!-- <app-employee-phone-numbers *ngSwitchCase="'Phone Numbers'" [employeeID]="employeeId" [formIds]="['frm_Q2vkn5tLeOZYpQ']"></app-employee-phone-numbers> -->
        <!-- </div>  -->
    </div>
</app-employee-layout>