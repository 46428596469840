import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { finalize } from 'rxjs/operators';
import { EmployeeSecurityService } from '../../services/employee-security.service';
import { TranslateService } from '@ngx-translate/core';
const passwordMatchValidator: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  if (formGroup.get('password').value === formGroup.get('password2').value)
    return null;
  else
    return {passwordMismatch: true};
};

@Component({
  selector: 'app-employee-password-reset',
  templateUrl: './employee-password-reset.component.html',
  styleUrls: ['./employee-password-reset.component.scss']
})

export class EmployeePasswordResetComponent implements OnInit {
  @Input() employeeID: string;
  minPw = 8;
  maxPw = 15;
  form: UntypedFormGroup;
  // at least one uppercase letter, one lowercase letter, one number and one special character
  passwordPattern = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";

  constructor(
    private fb: UntypedFormBuilder,
    private employeeSecurityService: EmployeeSecurityService,
    private overlayService: OverlayService,
    public translate: TranslateService,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(this.minPw), Validators.maxLength(this.maxPw), Validators.pattern(this.passwordPattern)]],
      password2: ['', [Validators.required]]
    }, {validator: passwordMatchValidator});
  }

  /* Shorthands for form controls (used from within template) */
  get password() { return this.form.get('password'); }
  get password2() { return this.form.get('password2'); }

  /* Called on each input in either password field */
  onPasswordInput() {
    if (this.form.hasError('passwordMismatch'))
      this.password2.setErrors([{'passwordMismatch': true}]);
    else
      this.password2.setErrors(null);
  }

  save(){
    let passwordSubmit = {
      password: this.form.value.password
    }
    this.overlayService.show();

    this.employeeSecurityService.setEmployeePassword(this.employeeID, passwordSubmit) 
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar('Password updated successfully', 'clear', 'success');
      }
    );
  }

}
