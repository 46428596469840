<mat-card>
    <mat-card-title class="header">
        <h5 appLocalizationPopupDirective localizationCode="EmploymentRecord">{{ 'EmploymentRecord' | translate: {Default: "Employment Record"} }}</h5>
        <div>
            <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button> 
        </div>
    </mat-card-title>
    <mat-card-content>
        <form class="form" *ngIf="form" [formGroup]="form">
            <div class="form-container">
    
                <div class="form-section">
                    <div class="form-section-title">
                        <h6>Hire Details</h6>
                    </div>
                    <div class="form-section-contents">
                        <mat-form-field  appearance="fill">
                            <mat-label>
                                Hire Date
                            </mat-label>
    
                            <input matInput [matDatepicker]="hireDatepicker" formControlName="hireDate">
                            <span fxLayout="row" matSuffix>
                                <mat-datepicker-toggle  [for]="hireDatepicker"></mat-datepicker-toggle>
                            </span>
                            <mat-datepicker #hireDatepicker></mat-datepicker>
                            <mat-hint></mat-hint>
                        </mat-form-field>
    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Hire Category
                            </mat-label>
    
                            <mat-select disableOptionCentering formControlName="hireCategory">
                                <ng-container *ngIf="hireCategoryOptions | async as hireCategoryOptions">
                                    <mat-option></mat-option>
                                    <mat-option 
                                        *ngFor="let option of hireCategoryOptions.data" 
                                        [value]="option.id">
                                            {{option.text}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Hire Detail Comments
                            </mat-label>
                
                            <input matInput
                                formControlName="hireDetailComments">
                        </mat-form-field>
                    </div>
                </div>
    
                <div class="form-section">
                    <div class="form-section-title">
                        <h6>Contact Details</h6>
                    </div>
                    <div class="form-section-contents">
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Email
                            </mat-label>
                
                            <input matInput
                                formControlName="email">
                        </mat-form-field>
    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Phone Number
                            </mat-label>
                
                            <input matInput
                                formControlName="phoneNumber">
                        </mat-form-field>
    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Cell Number
                            </mat-label>
                
                            <input matInput
                                formControlName="cellNumber">
                        </mat-form-field>
    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Office
                            </mat-label>
                
                            <input matInput
                                formControlName="office">
                        </mat-form-field>
    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Sub Status
                            </mat-label>
                
                            <mat-select disableOptionCentering formControlName="subStatus">
                                <ng-container *ngIf="subStatusOptions | async as subStatusOptions">
                                    <mat-option></mat-option>
                                    <mat-option 
                                        *ngFor="let option of subStatusOptions.data" 
                                        [value]="option.id">
                                            {{option.text}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
    
                <div class="form-section">
                    <div class="form-section-title">
                        <h6>Recruitment</h6>
                    </div>
                    <div class="form-section-contents">
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Recruitment Agency
                            </mat-label>
                
                            <mat-select disableOptionCentering formControlName="recruitmentAgency">
                                <ng-container *ngIf="recruitmentAgencyOptions | async as recruitmentAgencyOptions">
                                    <mat-option></mat-option>
                                    <mat-option 
                                        *ngFor="let option of recruitmentAgencyOptions.data" 
                                        [value]="option.id">
                                            {{option.text}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Search Fee
                            </mat-label>
                
                            <input matInput
                                type="number"
                                formControlName="searchFee">
                        </mat-form-field>
    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Budget Authority
                            </mat-label>
                
                            <input matInput
                                formControlName="budgetAuthority">
                        </mat-form-field>
                    </div>
                </div>
    
                <div class="form-section">
                    <div class="form-section-title">
                        <h6>Rehire Details</h6>
                    </div>
                    <div class="form-section-contents">
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Rehire Eligibility
                            </mat-label>
                
                            <mat-select disableOptionCentering formControlName="reHireEligibility">
                                <ng-container *ngIf="rehireEligibilityOptions | async as rehireEligibilityOptions">
                                    <mat-option></mat-option>
                                    <mat-option 
                                        *ngFor="let option of rehireEligibilityOptions.data" 
                                        [value]="option.id">
                                            {{option.text}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Rehire Eligibility Comments
                            </mat-label>
                
                            <input matInput
                                formControlName="rehireEligibilityComments">
                        </mat-form-field>
    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Termination Rehire Reason
                            </mat-label>
                
                            <mat-select disableOptionCentering formControlName="terminationRehireReason">
                                <ng-container *ngIf="terminationRehireReasonOptions | async as terminationRehireReasonOptions">
                                    <mat-option></mat-option>
                                    <mat-option 
                                        *ngFor="let option of terminationRehireReasonOptions.data" 
                                        [value]="option.id">
                                            {{option.text}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Termination Rehire Recommend
                            </mat-label>
                
                            <mat-select disableOptionCentering formControlName="terminationRehireRecommend">
                                <ng-container *ngIf="terminationRehireRecommendOptions | async as terminationRehireRecommendOptions">
                                    <mat-option></mat-option>
                                    <mat-option 
                                        *ngFor="let option of terminationRehireRecommendOptions.data" 
                                        [value]="option.id">
                                            {{option.text}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
        
                <div class="form-section">
                    <div class="form-section-title">
                        <h6>Onboarding</h6>
                    </div>
                    <div class="form-section-contents">
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Induction Completed On
                            </mat-label>
    
                            <input matInput [matDatepicker]="inductionCompletedOnpicker" formControlName="inductionCompletedOn">
                            <span fxLayout="row" matSuffix>
                                <mat-datepicker-toggle  [for]="inductionCompletedOnpicker"></mat-datepicker-toggle>
                            </span>
                            <mat-datepicker #inductionCompletedOnpicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
        
                <div class="form-section">
                    <div class="form-section-title">
                        <h6>Code of Conduct</h6>
                    </div>
                    <div class="form-section-contents">
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Code Of Conduct Signed
                            </mat-label>

                            <mat-select disableOptionCentering formControlName="codeOfConductSigned">
                                <ng-container *ngIf="codeOfConductSignedOptions | async as codeOfConductSignedOptions">
                                    <mat-option></mat-option>
                                    <mat-option 
                                        *ngFor="let option of codeOfConductSignedOptions.data" 
                                        [value]="option.id">
                                            {{option.text}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Code Of Conduct Signed On
                            </mat-label>
    
                            <input matInput [matDatepicker]="codeOfConductSignedOnpicker" formControlName="codeOfConductSignedOn">
                            <span fxLayout="row" matSuffix>
                                <mat-datepicker-toggle  [for]="codeOfConductSignedOnpicker"></mat-datepicker-toggle>
                            </span>
                            <mat-datepicker #codeOfConductSignedOnpicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
    
                <div class="form-section">
                    <div class="form-section-title">
                        <h6>Medical</h6>
                    </div>
                    <div class="form-section-contents">
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Medical Completed
                            </mat-label>
                
                            <mat-select disableOptionCentering formControlName="medicalCompleted">
                                <ng-container *ngIf="medicalCompletedOptions | async as medicalCompletedOptions">
                                    <mat-option></mat-option>
                                    <mat-option 
                                        *ngFor="let option of medicalCompletedOptions.data" 
                                        [value]="option.id">
                                            {{option.text}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Medical Completed Date
                            </mat-label>
    
                            <input matInput [matDatepicker]="medicalCompletedDatepicker" formControlName="medicalCompletedDate">
                            <span fxLayout="row" matSuffix>
                                <mat-datepicker-toggle  [for]="medicalCompletedDatepicker"></mat-datepicker-toggle>
                            </span>
                            <mat-datepicker #medicalCompletedDatepicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
    
    
                <div class="form-section">
                    <div class="form-section-title">
                        <h6>Security Clearance</h6>
                    </div>
                    <div class="form-section-contents">
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Security Clearance
                            </mat-label>
                
                            <mat-select disableOptionCentering formControlName="securityClearance">
                                <ng-container *ngIf="securityClearanceOptions | async as securityClearanceOptions">
                                    <mat-option></mat-option>
                                    <mat-option 
                                        *ngFor="let option of securityClearanceOptions.data" 
                                        [value]="option.id">
                                            {{option.text}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
    
                <div class="form-section">
                    <div class="form-section-title">
                        <h6>Probation</h6>
                    </div>
                    <div class="form-section-contents">
    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Probation Period Length
                            </mat-label>
                
                            <input matInput
                                type="number"
                                formControlName="probationPeriodLength">
                        </mat-form-field>
    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Probation Period End
                            </mat-label>
    
                            <input matInput [matDatepicker]="probationPeriodEndpicker" formControlName="probationPeriodEnd">
                            <span fxLayout="row" matSuffix>
                                <mat-datepicker-toggle  [for]="probationPeriodEndpicker"></mat-datepicker-toggle>
                            </span>
                            <mat-datepicker #probationPeriodEndpicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
    
                <div class="form-section">
                    <div class="form-section-title">
                        <h6>Notice Period</h6>
                    </div>
                    <div class="form-section-contents">
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Notice Period Units
                            </mat-label>
                
                            <mat-select disableOptionCentering formControlName="noticePeriodUnits">
                                <ng-container *ngIf="noticePeriodUnitsOptions | async as noticePeriodUnitsOptions">
                                    <mat-option></mat-option>
                                    <mat-option 
                                        *ngFor="let option of noticePeriodUnitsOptions.data" 
                                        [value]="option.id">
                                            {{option.text}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Notice Period Length
                            </mat-label>
                
                            <input matInput
                                type="number"
                                formControlName="noticePeriodLength">
                        </mat-form-field>
                    </div>
                </div>
    
                <div class="form-section">
                    <div class="form-section-title">
                        <h6>Payroll</h6>
                    </div>
                    <div class="form-section-contents">
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Payroll Number
                            </mat-label>
                
                            <input matInput
                                formControlName="payrollNumber">
                        </mat-form-field>
    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Payroll Code
                            </mat-label>
                
                            <mat-select disableOptionCentering formControlName="payrollCode">
                                <ng-container *ngIf="payrollCodeOptions | async as payrollCodeOptions">
                                    <mat-option></mat-option>
                                    <mat-option 
                                        *ngFor="let option of payrollCodeOptions.data" 
                                        [value]="option.id">
                                            {{option.text}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
    
                <div class="form-section">
                    <div class="form-section-title">
                        <h6>Apprenticeship Program</h6>
                    </div>
                    <div class="form-section-contents">
                        <mat-form-field  appearance="fill">
                            <mat-label>
                                Apprenticeship Program Start Date
                            </mat-label>
    
                            <input matInput [matDatepicker]="apprenticeshipProgramStartDatepicker" formControlName="apprenticeshipProgramStartDate">
                            <span fxLayout="row" matSuffix>
                                <mat-datepicker-toggle  [for]="apprenticeshipProgramStartDatepicker"></mat-datepicker-toggle>
                            </span>
                            <mat-datepicker #apprenticeshipProgramStartDatepicker></mat-datepicker>
                            <mat-hint></mat-hint>
                        </mat-form-field>
    
                        <mat-form-field  appearance="fill">
                            <mat-label>
                                Apprenticeship Program End Date
                            </mat-label>
    
                            <input matInput [matDatepicker]="apprenticeshipProgramEndDatepicker" formControlName="apprenticeshipProgramEndDate">
                            <span fxLayout="row" matSuffix>
                                <mat-datepicker-toggle  [for]="apprenticeshipProgramEndDatepicker"></mat-datepicker-toggle>
                            </span>
                            <mat-datepicker #apprenticeshipProgramEndDatepicker></mat-datepicker>
                            <mat-hint></mat-hint>
                        </mat-form-field>
                    </div>
                </div>
    
                <div class="form-section">
                    <div class="form-section-title">
                        <h6>Graduate Program</h6>
                    </div>
                    <div class="form-section-contents">
                        <mat-form-field  appearance="fill">
                            <mat-label>
                                Graduate Program Start Date
                            </mat-label>
                        
                            <input matInput [matDatepicker]="graduateProgramStartDatepicker" formControlName="graduateProgramStartDate">
                            <span fxLayout="row" matSuffix>
                                <mat-datepicker-toggle  [for]="graduateProgramStartDatepicker"></mat-datepicker-toggle>
                            </span>
                            <mat-datepicker #graduateProgramStartDatepicker></mat-datepicker>
                        </mat-form-field>
    
                        <mat-form-field  appearance="fill">
                            <mat-label>
                                Graduate Program End Date
                            </mat-label>
                        
                            <input matInput [matDatepicker]="graduateProgramEndDatepicker" formControlName="graduateProgramEndDate">
                            <span fxLayout="row" matSuffix>
                                <mat-datepicker-toggle  [for]="graduateProgramEndDatepicker"></mat-datepicker-toggle>
                            </span>
                            <mat-datepicker #graduateProgramEndDatepicker></mat-datepicker>
                        </mat-form-field>
    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Graduate Program Member
                            </mat-label>
                
                            <mat-select disableOptionCentering formControlName="graduateProgramMember">
                                <ng-container *ngIf="graduateProgramMemberOptions | async as graduateProgramMemberOptions">
                                    <mat-option></mat-option>
                                    <mat-option 
                                        *ngFor="let option of graduateProgramMemberOptions.data" 
                                        [value]="option.id">
                                            {{option.text}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
    
                <div class="form-section">
                    <div class="form-section-title">
                        <h6>Termination</h6>
                    </div>
                    <div class="form-section-contents">
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Termination Accepted By Name
                            </mat-label>
                
                            <input matInput
                                formControlName="terminationAcceptedByName">
                        </mat-form-field>
                
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Termination Comments
                            </mat-label>
                
                            <input matInput
                                formControlName="terminationComments">
                        </mat-form-field>
                
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Termination Record Of Employment Comment
                            </mat-label>
                
                            <input matInput
                                formControlName="terminationRecordOfEmploymentComment">
                        </mat-form-field>
                
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Termination Other Payment
                            </mat-label>
                
                            <input matInput
                                type="number"
                                formControlName="terminationOtherPayment">
                        </mat-form-field>
                
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Termination Pay In Lieu
                            </mat-label>
                
                            <input matInput
                                type="number"
                                formControlName="terminationPayInLieu">
                        </mat-form-field>
                
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Termination Severance Amount
                            </mat-label>
                
                            <input matInput
                                type="number"
                                formControlName="terminationSeveranceAmount">
                        </mat-form-field>
                
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Termination Vacation Payout
                            </mat-label>
                
                            <input matInput
                                type="number"
                                formControlName="terminationVacationPayout">
                        </mat-form-field>
                
                        <mat-form-field  appearance="fill">
                            <mat-label>
                                Termination Accepted By Date
                            </mat-label>
                        
                            <input matInput [matDatepicker]="terminationAcceptedByDatepicker" formControlName="terminationAcceptedByDate">
                            <span fxLayout="row" matSuffix>
                                <mat-datepicker-toggle  [for]="terminationAcceptedByDatepicker"></mat-datepicker-toggle>
                            </span>
                            <mat-datepicker #terminationAcceptedByDatepicker></mat-datepicker> 
                            <mat-hint></mat-hint>
                        </mat-form-field>
                
                        <mat-form-field  appearance="fill">
                            <mat-label>
                                Termination Benefits End
                            </mat-label>
                        
                            <input matInput [matDatepicker]="terminationBenefitsEndpicker" formControlName="terminationBenefitsEnd">
                            <span fxLayout="row" matSuffix>
                                <mat-datepicker-toggle  [for]="terminationBenefitsEndpicker"></mat-datepicker-toggle>
                            </span>
                            <mat-datepicker #terminationBenefitsEndpicker></mat-datepicker> 
                            <mat-hint></mat-hint>
                        </mat-form-field>
                
                        <mat-form-field  appearance="fill">
                            <mat-label>
                                Termination Benefits End Of Notice
                            </mat-label>
                        
                            <input matInput [matDatepicker]="terminationBenefitsEndOfNoticepicker" formControlName="terminationBenefitsEndOfNotice">
                            <span fxLayout="row" matSuffix>
                                <mat-datepicker-toggle  [for]="terminationBenefitsEndOfNoticepicker"></mat-datepicker-toggle>
                            </span>
                            <mat-datepicker #terminationBenefitsEndOfNoticepicker></mat-datepicker> 
                            <mat-hint></mat-hint>
                        </mat-form-field>
                
                        <mat-form-field  appearance="fill">
                            <mat-label>
                                Actual Termination Date
                            </mat-label>
                        
                            <input matInput [matDatepicker]="terminationDateActualpicker" formControlName="terminationDateActual">
                            <span fxLayout="row" matSuffix>
                                <mat-datepicker-toggle  [for]="terminationDateActualpicker"></mat-datepicker-toggle>
                            </span>
                            <mat-datepicker #terminationDateActualpicker></mat-datepicker> 
                            <mat-hint></mat-hint>
                        </mat-form-field>
                
                        <mat-form-field  appearance="fill">
                            <mat-label>
                                Termination Date Notified
                            </mat-label>
                        
                            <input matInput [matDatepicker]="terminationDateNotifiedpicker" formControlName="terminationDateNotified">
                            <span fxLayout="row" matSuffix>
                                <mat-datepicker-toggle  [for]="terminationDateNotifiedpicker"></mat-datepicker-toggle>
                            </span>
                            <mat-datepicker #terminationDateNotifiedpicker></mat-datepicker> 
                            <mat-hint></mat-hint>
                        </mat-form-field>
                
                        <mat-form-field  appearance="fill">
                            <mat-label>
                                Termination Date Projected
                            </mat-label>
                        
                            <input matInput [matDatepicker]="terminationDateProjectedPicker" formControlName="terminationDateProjected">
                            <span fxLayout="row" matSuffix>
                                <mat-datepicker-toggle  [for]="terminationDateProjectedPicker"></mat-datepicker-toggle>
                            </span>
                            <mat-datepicker #terminationDateProjectedPicker></mat-datepicker> 
                            <mat-hint></mat-hint>
                        </mat-form-field> 
                
                        <mat-form-field  appearance="fill">
                            <mat-label>
                                Termination Last Date On Site
                            </mat-label>
                        
                            <input matInput [matDatepicker]="terminationLastDateOnSitepicker" formControlName="terminationLastDateOnSite">
                            <span fxLayout="row" matSuffix>
                                <mat-datepicker-toggle  [for]="terminationLastDateOnSitepicker"></mat-datepicker-toggle>
                            </span>
                            <mat-datepicker #terminationLastDateOnSitepicker></mat-datepicker> 
                            <mat-hint></mat-hint>
                        </mat-form-field>
    
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Termination Reason Options
                            </mat-label>
                
                            <mat-select disableOptionCentering formControlName="terminationReason">
                                <ng-container *ngIf="terminationReasonOptions | async as terminationReasonOptions">
                                    <mat-option></mat-option>
                                    <mat-option 
                                        *ngFor="let option of terminationReasonOptions.data" 
                                        [value]="option.id">
                                            {{option.text}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Termination Record Of Employment Reason
                            </mat-label>
                
                            <mat-select disableOptionCentering formControlName="terminationRecordOfEmploymentReason">
                                <ng-container *ngIf="terminationRecordEmploymentReasonOptions | async as terminationRecordEmploymentReasonOptions">
                                    <mat-option></mat-option>
                                    <mat-option 
                                        *ngFor="let option of terminationRecordEmploymentReasonOptions.data" 
                                        [value]="option.id">
                                            {{option.text}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Termination Special Circumstances
                            </mat-label>
                
                            <mat-select disableOptionCentering formControlName="terminationSpecialCircumstances">
                                <ng-container *ngIf="terminationSpecialCircumstancesOptions | async as terminationSpecialCircumstancesOptions">
                                    <mat-option></mat-option>
                                    <mat-option 
                                        *ngFor="let option of terminationSpecialCircumstancesOptions.data" 
                                        [value]="option.id">
                                            {{option.text}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
    
                        <mat-slide-toggle setColor="primaryColour" formControlName="terminationItConfirmation">Termination It Confirmation</mat-slide-toggle>
                        
                        <mat-slide-toggle setColor="primaryColour" formControlName="terminationVoluntary">Termination Voluntary</mat-slide-toggle>
                    </div>
                </div>
            </div>
        </form>
    </mat-card-content>
</mat-card>
