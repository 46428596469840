import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SanitiseGridDataService } from '@app/core/services/sanitise-grid-data/sanitise-grid-data.service';
import { ExcelExportEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { filter, finalize, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import * as moment from 'moment';
import { EmployeeTimeOffType, TimeOffTypeLog } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-leave/models/leave-request.model';
import { EmployeeLeaveService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-leave/services/employee-leave.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-view-future-schedule-dialog',
  templateUrl: './view-future-schedule-dialog.component.html',
  styleUrls: ['./view-future-schedule-dialog.component.scss']
})
export class ViewFutureScheduleDialogComponent implements OnInit {
  public columns: any[] = [
    { field: "on", title: "On", localizationCode: 'FutureSchedule-On'},
    { field: "adjustment", title: "Adjustment", localizationCode: 'FutureSchedule-Adjustment', type: 'decimal'},
    { field: "reason", title: "Reason", localizationCode: 'FutureSchedule-Reason'},
  ];

  employeeId: string;
  employeeTimeOffType: EmployeeTimeOffType;
  isLoading: boolean;
  timeOffCalculationLog: TimeOffTypeLog[];
  pageSettings = {
    pageSizes: [5, 10, 20, 50, 100],
  };
  pageSize: number = 5;
  skip: number = 0;
  dateRangeForm: FormGroup;
  unFilteredCalculationLog: TimeOffTypeLog[];
  
  constructor(
    private employeeLeaveService: EmployeeLeaveService,
    private sanitiseGridDataService: SanitiseGridDataService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<ViewFutureScheduleDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data) {
    this.employeeId = data.employeeId;
    this.employeeTimeOffType  = data.employeeTimeOffType;

    this.dateRangeForm = this.fb.group({
      from: [null],
      to: [null],
    });
  }

  ngOnInit(): void {
    this.getCalculationLog();

    // Subscribe to form value changes
    this.dateRangeForm.valueChanges.subscribe((formValues) => {
      // Do something with the updated form values
      this.filterCalculationLog();
    });
  }

  getCalculationLog() {
    this.isLoading = true;
    
    this.employeeLeaveService.getCalculationLog(this.employeeId, this.employeeTimeOffType.timeOffType.id)
    .pipe(
      finalize(() => {
          this.isLoading = false;
      })
    )
    .subscribe(
      res => {
        this.timeOffCalculationLog = this.formatData(res);
        this.unFilteredCalculationLog = [...this.timeOffCalculationLog];
      }
    );
  }

  formatData(timeOffCalculationLog: TimeOffTypeLog[]) {
    let itemsToCombine = {};

    // remove any items from the past
    timeOffCalculationLog = timeOffCalculationLog.filter(item => moment(item.on).isSameOrAfter(moment()))

    // Iterate through the raw data
    timeOffCalculationLog.forEach((item, index) => {
      if (item.properties && item.properties.length > 0) {
        // Find the property with key "AbsenceId"
        const absenceProperty = item.properties.find((property) => property.key === "AbsenceId");

        if (absenceProperty) {
          // Get the "value" of the property
          const absenceId = absenceProperty.value;

          // Add the timeOff item to the group of objects to combine
          if(itemsToCombine[absenceId]){
            itemsToCombine[absenceId].push(item)
          } else {
            itemsToCombine[absenceId] = [item]
          }
        }
      }
    });

    // Go through each absence item and 
    // 1. get the combined hours adjustment 
    // 2. update the value adjustment with the combined value
    // 3. update the absence reason
    // 4. remove all the absences from the grid data
    // 5. Add the first absence to the grid data with the new combined abjestment value and reason
    for (const key of Object.keys(itemsToCombine)) {
      const value = itemsToCombine[key]

      let totalAdjustment = itemsToCombine[key].reduce((accumulator, currentItem) => {
        return accumulator + currentItem.adjustment;
      }, 0);

      // Remove all the absences from the grid data
      value.forEach(
        v => {
          let i = timeOffCalculationLog.findIndex( item => item === v );
          timeOffCalculationLog.splice(i, 1);

          v.adjustment = totalAdjustment;
          v.reason = `Absence was scheduled for ${totalAdjustment} hours`
        }
      )

      timeOffCalculationLog.push(value[0])
    }
    
    // return timeOffCalculationLog.sort((a, b) => new Date(b.on).valueOf() - new Date(a.on).valueOf());
    return timeOffCalculationLog;
  }

  filterCalculationLog() {
    let filteredCalculationLog = this.unFilteredCalculationLog.filter(
      item => {
        if(this.dateRangeForm.get('from').value !== null && this.dateRangeForm.get('to').value === null) {
          return moment(item.on).isSameOrAfter(this.dateRangeForm.get('from').value)
        }
        else if(this.dateRangeForm.get('from').value === null && this.dateRangeForm.get('to').value !== null) {
          return moment(item.on).isSameOrBefore(this.dateRangeForm.get('to').value)
        }
        else if(this.dateRangeForm.get('from').value !== null && this.dateRangeForm.get('to').value !== null) {
          return moment(item.on).isSameOrAfter(this.dateRangeForm.get('from').value) && moment(item.on).isSameOrBefore(this.dateRangeForm.get('to').value)
        }
        else {
          return true;
        }
      }
    )

    this.timeOffCalculationLog = filteredCalculationLog;
  }

  public onExcelExport(e: ExcelExportEvent): void {
    e = this.sanitiseGridDataService.sanitise(e);
  }

  close(){
    this.dialogRef.close();
  }

}
