import { Component, Input, NgZone, OnInit } from '@angular/core';
import { GoalPlanOwner, GoalPlanVerbose } from '../../../../models/goal-plan.model';
import { GoalPlanService } from '../../../../services/goal-plan.service';
import { defer, finalize, forkJoin } from 'rxjs';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SelectEmployeesDialogComponent } from '@app/shared/components/select-employees-dialog/select-employees-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { RowActionButtonInput } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/data-grid-comp/models/data-grid-comp.model';
import { EmployeeDetailsDialogComponent } from '../employee-details-dialog/employee-details-dialog.component';

@Component({
  selector: 'app-goal-plan-owners',
  templateUrl: './goal-plan-owners.component.html',
  styleUrls: ['./goal-plan-owners.component.scss']
})
export class GoalPlanOwnersComponent implements OnInit {
  @Input() goalPlan: GoalPlanVerbose;

  isLoadingEmployees: boolean;
  employeesGridDataResult: { data: any[]; total: number; };
  pageSize: number = 5;
  skip: number = 0;
  public columns: any[] = [
    { field: "owner", title: "Name", type:'employee' },
  ];
  clearSelectedItems: boolean = false;
  searchValue: string;
  searchFilterString: string;
  getUsersRequest: any;
  selectEmployeesDialogRef: MatDialogRef<SelectEmployeesDialogComponent>;
  gridActionButtons: RowActionButtonInput[] = [
    {
      id: 'details',
      name: this.translate.instant('Details'),
      icon: 'info'
    }
  ];
  
  constructor(
    private dialog: MatDialog,
    private ngZone: NgZone,
    private goalPlanService: GoalPlanService,
    public translate: TranslateService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
  ) { }

  ngOnInit(): void {
    this.getGoalPlanOwners();
  }

  getGoalPlanOwners() {
    this.isLoadingEmployees = true;

    let filter: string = `(GoalPlan.Id = "${this.goalPlan.id}")`;
    
    if(this.searchFilterString) {
      filter = `${this.searchFilterString} AND ${filter}`
    }

    this.getUsersRequest = this.goalPlanService.getGoalPlanOwners(this.pageSize.toString(), this.skip, null, filter)
    .pipe(
      finalize( () => {
        this.isLoadingEmployees = false;
      })
    )
    .subscribe(
      (res) => {
        this.employeesGridDataResult = {
          data: res.data,
          total: res.total,
        }
      }
    );
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;
    this.searchFilterString = '';
    this.skip = 0;

    let variants = this.searchValue.split(' ').filter(value => value);
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `(Owner.FirstName like "${variant}") OR (Owner.LastName like "${variant}")`;
        }
    });

    this.getUsersRequest.unsubscribe();
    this.getGoalPlanOwners();
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.getGoalPlanOwners();
  }

  showAddUserModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      nonSelectableEmployees: [ ...this.employeesGridDataResult.data.map( goalPlanOwner => goalPlanOwner?.owner?.id ), this.goalPlan?.version?.createdBy?.id]
    };

    this.ngZone.runOutsideAngular(() => {
      this.selectEmployeesDialogRef = this.dialog.open(SelectEmployeesDialogComponent, dialogConfig);
    });

    const sub = this.selectEmployeesDialogRef.componentInstance.emitSelectedEmployees.subscribe((event) => {
      this.addOwners(event)
    });
  }

  addOwners(selectedEmployees: string[]) {
    const observables = selectedEmployees.map(
      selectedUserId => defer(
        () => this.goalPlanService.addGoalPlanOwner({userId: selectedUserId, goalPlanId: this.goalPlan.id})
      )
    );

    this.overlayService.show();

    forkJoin(observables)
        .pipe(finalize(() => this.overlayService.hide()))
        .subscribe(
            (res) => {
                this.getGoalPlanOwners();
                this.selectEmployeesDialogRef.close();
                this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
            }
        );
  }

  deleteAllSelected(goalPlanOwners: string[]) {
    const observables = goalPlanOwners.map(goalPlanOwner => defer(() => this.goalPlanService.deleteGoalPlanOwner(goalPlanOwner)));

    this.overlayService.show();

    forkJoin(observables)
      .pipe(finalize(() => this.overlayService.hide()))
      .subscribe(
          (res) => {
              this.clearSelectedItems = !this.clearSelectedItems;
              this.getGoalPlanOwners();
              this.snackbarService.openSnackBar(`${this.translate.instant('RemovedSuccessfully')}`, 'clear', 'success');
          }
      );
  }

  showDetails(event) {
    // change owner value to employee in order to use employee details dialog
    let details = event.rowData;
    const { owner, ...rest } = details;
    const newObject = {
      ['employee']: owner,
      ...rest
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      title: "GoalPlan-Owner-Details",
      details: newObject
    };

    const dialogRef = this.dialog.open(EmployeeDetailsDialogComponent, dialogConfig);
  }

}
