import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { defer, forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EmploymentRecordRelocation } from './models/employment-record-relocation.model';
import { EmploymentRecordRelocationService } from './services/employment-record-relocation.service';
import { EmploymentRecordRelocationDialogComponent } from './components/employment-record-relocation-dialog/employment-record-relocation-dialog.component';

@Component({
  selector: 'app-employment-record-relocation',
  templateUrl: './employment-record-relocation.component.html',
  styleUrls: ['./employment-record-relocation.component.scss']
})
export class EmploymentRecordRelocationComponent implements OnInit {
  @Input() employeeId: string;
  @Input() employmentRecordId: string;
  public columns: any[] = [
    { field: "receivingLocation", subField: "text", title: "Receiving Location", type: "navigation" },
    { field: "sendingLocation", subField: "text", title: "Sending Location" },
    { field: "dateInitiated", title: "Date Initiated", type: "date" },
    { field: "relocationStartDate", title: "Relocation Start Date", type: "date" },
    { field: "relocationEndDate", title: "Relocation End Date", type: "date" },
    { field: "pointOfOrigin", subField: "text", title: "Point Of Origin" },
    { field: "totalCosts", title: "Total Costs" },
    { field: "comments", title: "Comments" },
  ];
  public bindingType: String = "array";
  public view: Observable<GridDataResult>;
  public gridData: any;
  public gridDataResult: GridDataResult;
  isLoading: boolean;
  pageSize: number = 5;
  skip: number = 0;

  constructor(
    private dialog: MatDialog,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private employmentRecordRelocationService: EmploymentRecordRelocationService
  ) {}

  ngOnInit(): void {
    this.view = this.employmentRecordRelocationService.getRelocations(this.employeeId, this.employmentRecordId);
    this.getRelocations();
  }

  getRelocations(): void {
    this.isLoading = true;

    this.employmentRecordRelocationService.getRelocations(this.employeeId, this.employmentRecordId, this.skip, String(this.pageSize))
    .pipe(
        finalize(()=>{ this.isLoading = false; })
    )
    .subscribe(
      res => {
        this.gridDataResult = {
          data: res.relocations,
          total: res.pagination.TotalCount,
        }
        this.gridData = res.employmentRecords;
      }
    )
  }

  openEmploymentRecordRelocationDialog(employmentRecordRelocation?: EmploymentRecordRelocation) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      employmentRecordId: this.employmentRecordId,
      employeeId: this.employeeId,
      RelocationId: employmentRecordRelocation ? employmentRecordRelocation.id : null
    };

    const dialogRef = this.dialog.open(EmploymentRecordRelocationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.getRelocations();
        }
      }
    );
  }

  deleteEmploymentRecordRelocation(employmentRecordRelocationIds: string[]) {
    const observables = employmentRecordRelocationIds.map(selectedItem => defer(() => this.employmentRecordRelocationService.deleteRelocation(this.employeeId, this.employmentRecordId, selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.getRelocations();
        this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.getRelocations();
  }

}


