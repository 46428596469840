import { Component, Input, OnInit } from '@angular/core';
import { GlobalGoalReview, GlobalGoalReviewCompleteSubmit, GlobalGoalReviewSubmit } from '../../models/global-reviews.model';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { GoalTypeRating } from '../../../goal-types/models/goal-type.model';
import { FormBuilder, Validators } from '@angular/forms';
import { GoalPlanVerbose } from '../../../goal-plans/models/goal-plan.model';
import { ReviewPlansService } from '../../../review-plans/services/review-plans.service';
import { GlobalReviewsService } from '../../services/global-reviews.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { ReviewPlanOwner } from '../../../review-plans/models/review-plan.model';

@Component({
  selector: 'app-global-goal-reviews',
  templateUrl: './global-goal-reviews.component.html',
  styleUrls: ['./global-goal-reviews.component.scss']
})
export class GlobalGoalReviewsComponent implements OnInit {
  @Input() globalGoalReview: GlobalGoalReview;
  @Input() reviewPlanOwners: ReviewPlanOwner[] = [];
  
  user$: any;
  showForm: boolean = false;
  error: boolean;
  reviewRatingForm: any;
  goalPlan: GoalPlanVerbose;
  reviewRatingOptions: any[];
  isLoadingRatings: boolean;
  isOwner: boolean = false;

  constructor(
    private fb: FormBuilder,
    private reviewPlansService: ReviewPlansService,
    private oidcAuthService: OidcAuthService,
    private overlayService: OverlayService,
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private globalReviewsService: GlobalReviewsService
  ) { 
    this.user$ = this.oidcAuthService.userProfile;
  }

  ngOnInit(): void {
    this.getReviewPlanRatings();

    this.isOwner = this.checkIfUserIsOwner();
  }

  checkIfUserIsOwner(): boolean {
    let index = this.reviewPlanOwners?.findIndex(reviewPlanOwner => reviewPlanOwner?.owner?.id === this.user$.userId)

    if(index === -1) {
      return false;
    }
    else  {
      return true;
    }
  }

  getReviewPlanRatings() {
    this.isLoadingRatings = true;

    this.reviewPlansService.getReviewPlanRatings(this.globalGoalReview.reviewPlan.id, '100', 0, 'SortOrder-asc')
    .subscribe(
      {
        next: (v) => {
          this.reviewRatingOptions = v.data;
        },
        error: (e) => this.error = true,
        complete: () => this.createReviewRatingForm()
      }
    );
  }

  createReviewRatingForm() {
    this.reviewRatingForm = this.fb.group({});
    this.reviewRatingForm.addControl('rating', this.fb.control(this.globalGoalReview?.rating?.id, Validators.required));
    this.reviewRatingForm.addControl('comments', this.fb.control(this.globalGoalReview?.comments, Validators.required));
  }

  save() {
    let globalGoalReviewSubmit: GlobalGoalReviewSubmit = {
      reviewer: this.globalGoalReview?.reviewer?.id,
      goal: this.globalGoalReview?.goal?.id,
      reviewPlan: this.globalGoalReview?.reviewPlan?.id,
      rating: this.reviewRatingForm.get('rating') ? this.reviewRatingForm.get('rating').value : null,
      comments:  this.reviewRatingForm.get('comments') ? this.reviewRatingForm.get('comments').value : null,
    }
    
    this.overlayService.show();
    
    this.globalReviewsService.updateGlobalGoalReview(globalGoalReviewSubmit)
    .subscribe(
      {
        next: (v) => {
          this.snackbarService.openSnackBar(this.translate.instant('SavedSuccessfully'), 'clear', 'success');
        },
        error: (e) => {
          this.error = true,
          this.overlayService.hide(); 
        },
        complete: () => this.overlayService.hide()
      }
    );
  }

  complete() {
    let globalGoalReviewCompleteSubmit: GlobalGoalReviewCompleteSubmit = {
      reviewer: this.globalGoalReview?.reviewer?.id,
      goal: this.globalGoalReview?.goal?.id,
      reviewPlan: this.globalGoalReview?.reviewPlan?.id,
    }
    
    this.overlayService.show();

    this.globalReviewsService.completeGlobalGoalReview(globalGoalReviewCompleteSubmit)
    .subscribe(
      {
        next: (v) => {
          this.snackbarService.openSnackBar(this.translate.instant('SavedSuccessfully'), 'clear', 'success');
        },
        error: (e) => {
          this.error = true,
          this.overlayService.hide(); 
        },
        complete: () => {
          this.overlayService.hide();
          this.globalGoalReview.status = { id: 'Complete', name: 'Complete' };
        }
      }
    );
  }

  revert() {
    let globalGoalReviewCompleteSubmit: GlobalGoalReviewCompleteSubmit = {
      reviewer: this.globalGoalReview?.reviewer?.id,
      goal: this.globalGoalReview?.goal?.id,
      reviewPlan: this.globalGoalReview?.reviewPlan?.id,
    }
    
    this.overlayService.show();

    this.globalReviewsService.revertGlobalGoalReview(globalGoalReviewCompleteSubmit)
    .subscribe(
      {
        next: (v) => {
          this.snackbarService.openSnackBar(this.translate.instant('SavedSuccessfully'), 'clear', 'success');
        },
        error: (e) => {
          this.error = true,
          this.overlayService.hide(); 
        },
        complete: () => { 
          this.overlayService.hide(); 
          this.globalGoalReview.status = { id: 'Pending', name: 'Pending' };
        }
      }
    );
  }

}

