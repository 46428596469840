<ng-container [formGroup]="propertyForm">
    <mat-form-field appearance="fill">
        <mat-label>
            Title
        </mat-label>

        <input matInput
               formControlName="title">
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>
            Data Table
        </mat-label>

        <mat-select disableOptionCentering formControlName="table">
            <ng-container *ngFor="let table of tables">
                <mat-option [value]="table.tableName">{{table.tableName}}</mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="propertyForm.value.table">
        <mat-label>
            {{ 'DataField' | translate: {Default: "Data Field"} }}
        </mat-label>


        <mat-select disableOptionCentering formControlName="field">
            <ng-container>
                <mat-form-field appearance="fill" class="dropdown-search">
                    <mat-label>
                        Search
                    </mat-label>
                    <input matInput (keyup)="onDropdownSearchKeyReceived($event.target.value, index)">
                </mat-form-field>

                <mat-option></mat-option>
                <ng-container *ngIf="!(tableFieldsFiltered && dropdownIndexChanged == index); else showFiltered">
                    <mat-option *ngFor="let tableField of getRelevantFields()" [value]="tableField.id">{{tableField.name}}</mat-option>
                </ng-container>
                <ng-template #showFiltered>
                    <mat-option *ngFor="let tableField of tableFieldsFiltered" [value]="tableField.id">{{tableField.name}}</mat-option>
                </ng-template>
            </ng-container>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="propertyForm.value.table">
        <mat-label>Filter</mat-label>

        <mat-select disableOptionCentering #filterSelect formControlName="filter">
            <mat-select-trigger>
                {{ displayFilterValue() }}
            </mat-select-trigger>

            <ng-container>
                <mat-optgroup label="Standard Filters">
                    <mat-option></mat-option>
                    <mat-option value="not-empty" (click)="applyFilter('not-empty', 'not-empty'); filterSelect.close()">Not Empty</mat-option>
                    <mat-option value="empty" (click)="applyFilter('empty', 'empty'); filterSelect.close()">Empty</mat-option>
                    <mat-option *ngFor="let option of hiddenOptions" [value]="option.value" style="display: none;">{{option.label}}</mat-option>
                </mat-optgroup>

                <mat-optgroup label="Input Filters">
                </mat-optgroup>

                <mat-form-field appearance="fill" class="dropdown-search">
                    <mat-label>
                        Like
                    </mat-label>
                    <input matInput #likeInput>
                    <button mat-icon-button matSuffix (click)="applyFilter(likeInput.value, 'like'); filterSelect.close()">
                        <mat-icon class="material-icons-outlined">done</mat-icon>
                    </button>
                </mat-form-field>

                <mat-form-field appearance="fill" class="dropdown-search">
                    <mat-label>
                        Not Like
                    </mat-label>
                    <input matInput #notLikeInput>
                    <button mat-icon-button matSuffix (click)="applyFilter(notLikeInput.value, 'not-like'); filterSelect.close()">
                        <mat-icon class="material-icons-outlined">done</mat-icon>
                    </button>
                </mat-form-field>
            </ng-container>
        </mat-select>
    </mat-form-field>

</ng-container>
