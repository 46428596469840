import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { FormElement } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { GoalPlan } from '@app/modules/performance/components/goal-plans/models/goal-plan.model';
import { SelectPermissibleEmployeeGoalPlanDialogComponent } from './components/select-permissible-employee-goal-plan-dialog/select-permissible-employee-goal-plan-dialog.component';

@Component({
  selector: 'app-permissible-employee-goal-plans',
  templateUrl: './permissible-employee-goal-plans.component.html',
  styleUrls: ['./permissible-employee-goal-plans.component.scss']
})
export class PermissibleEmployeeGoalPlansComponent implements OnInit {
  @Input() formElement: FormElement;
  @Input() parentGroup: UntypedFormGroup;
  @Input() readOnly: boolean;
  @ViewChild('goalPlanSelect') goalPlanSelect;
  goalPlanOptions: GoalPlan[] = [];
  isLoading: boolean;
  user$: any;
  filterString: string;

  constructor(
    private oidcAuthService: OidcAuthService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.user$ = this.oidcAuthService.userProfile;

    if(this.parentGroup.controls[this.formElement.formControl].value !== null){
      this.setValue(this.parentGroup.controls[this.formElement.formControl].value)
    }
  }

  openSelectGoalPlanDialog() {
    this.goalPlanSelect.close();

    if(!this.readOnly){
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      const dialogRef = this.dialog.open(SelectPermissibleEmployeeGoalPlanDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        data => {
          if (data !== undefined) {
            this.setValue(data)
          }
        }
      );
    }
  }

  clearSelectedPosition() {
    this.goalPlanSelect.close();
    this.parentGroup.controls[this.formElement.formControl].setValue(null);
    this.goalPlanOptions = [];
  }

  setValue(selectedGoalPlan: GoalPlan) {
    this.parentGroup.controls[this.formElement.formControl].setValue(selectedGoalPlan.id);
    this.goalPlanOptions = [selectedGoalPlan];
  }

}
