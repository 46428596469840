import { Component, OnInit, ViewChild } from '@angular/core';
import {MatAccordion} from '@angular/material/expansion';
import { routes } from '@app/consts/routes';

@Component({
  selector: 'app-onboarding-task-checklist',
  templateUrl: './onboarding-task-checklist.component.html',
  styleUrls: ['./onboarding-task-checklist.component.scss']
})
export class OnboardingTaskChecklistComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  public routes: typeof routes = routes;

  tasks = [
    {
      category: 'IT',
      list: [
        {
          checked: true,
          task: 'Create email address',
          assignedTo: 'Eric Pasture',
          date: 'Feb 3, 2020'
        },
        {
          checked: false,
          task: 'Setup workstation',
          assignedTo: 'Eric Pasture',
          date: 'Feb 3, 2020'
        },
        {
          checked: false,
          task: 'Create phone extension',
          assignedTo: 'Eric Pasture',
          date: 'Feb 3, 2020'
        },
      ]
    },
    {
      category: 'HR',
      list: [
        {
          checked: false,
          task: 'New hire orientation',
          assignedTo: 'Daniel John',
          date: 'Feb 4, 2020'
        },
        {
          checked: false,
          task: 'Office Tour',
          assignedTo: 'Daniel John',
          date: 'Feb 4, 2020'
        },
        {
          checked: false,
          task: 'Introduce new hire ro 2 employees outside of group',
          assignedTo: 'Daniel John',
          date: 'Feb 4, 2020'
        },
        {
          checked: false,
          task: 'New hire training',
          assignedTo: 'Daniel John',
          date: 'Feb 4, 2020'
        },
        {
          checked: false,
          task: 'Confirm W4 is signed',
          assignedTo: 'Daniel John',
          date: 'Feb 4, 2020'
        },
      ]
    },
    {
      category: 'New Hire Paperwork',
      list: [
        {
          checked: true,
          task: 'Complete direct deposit form',
          assignedTo: 'George Allen',
          date: 'Feb 4, 2020',
          icon: 'description'
        },
        {
          checked: true,
          task: 'Read employee Handbook',
          assignedTo: 'George Allen',
          date: 'Feb 4, 2020'
        },
        {
          checked: true,
          task: 'Review and sign 19',
          assignedTo: 'George Allen',
          date: 'Feb 4, 2020',
          icon: 'description'
        },
      ]
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
