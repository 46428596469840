<app-layout>
    <div class="main-container">
        <mat-card class="form-details-card">
            <mat-card-title class="header">
                <h5 appLocalizationPopupDirective localizationCode="FormDetails">{{ 'FormDetails' | translate: {Default: "Form Details"} }}</h5>
        
                <div class="buttons-container">
                    <button mat-stroked-button setColor="primaryColour" color="primary" (click)="addNewLocalization()">
                        <mat-icon class="material-icons-outlined">add</mat-icon> Add text Localization
                    </button>
                    <button mat-raised-button setColor="primaryColour" color="primary" [disabled]="!form.valid" (click)="save()">
                        Save
                    </button>
                </div>
            </mat-card-title>
            <mat-card-content>
        
                <form class="form" *ngIf="form" [formGroup]="form">
        
                    <div class="form-section">
                        <div class="form-section-title">
                            <h6>Text Localizations</h6>
                        </div>
                        <div class="form-section-contents">
                            <div class="section-row">
                                <ng-container formArrayName="name">
                                    <ng-container *ngFor="let localizationForm of localizations.controls; let i = index">
                                        <div class="form-row">
                                            <div class="localization-row" [formGroup]="localizationForm">
                                                <mat-form-field appearance="fill">
                                                    <mat-label>
                                                        Select Language
                                                    </mat-label>
                        
                                                    <mat-select disableOptionCentering formControlName="culture">
                                                        <mat-option *ngFor="let culture of cultures" [value]="culture.id">{{culture.text}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                        
                                                <mat-form-field appearance="fill">
                                                    <mat-label>
                                                        Name
                                                    </mat-label>
                        
                                                    <input matInput
                                                        formControlName="text">
                                                </mat-form-field>
                                                
                                                <mat-icon class="material-icons-outlined" (click)="deleteLocalization(i)" matTooltip="Delete">delete</mat-icon> 
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
        
                    <mat-form-field appearance="fill">
                        <mat-label>
                            Root Table
                        </mat-label>
        
                        <mat-select disableOptionCentering formControlName="table">
                            <mat-option *ngFor="let table of tables" [value]="table.id">{{table.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
        
                </form>
        
            </mat-card-content>
        </mat-card>
    </div>
</app-layout>