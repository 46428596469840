import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api_routes } from '@app/consts';
import { EnvironmentService } from '../environment.service';

const headers = new HttpHeaders({
  'Content-Type': 'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class ModuleConfigurationService {

  private modules: {performance: boolean, timeOff: boolean, toil: boolean, ruleAutomation: boolean};

  constructor(
    private http: HttpClient,
    private envService: EnvironmentService
  ) {}

  syncCurrentUserModules(callback: () => any) {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.MODULE_CONFIGURATION}`,
        {
            headers: headers
        })
        .subscribe(res => {
            this.modules = res;
            // this.modules = {performance: false, timeOff: false, toil: false};
            callback();
        });
  }

  getModules() {
    return this.modules;
  }

  public hasModuleAccess(moduleCode: string): boolean {
    if (moduleCode === 'allow') {
      return true;
    }
    return this.modules[moduleCode];
  }

  public hasAnyModuleAccess(moduleCodes: string[]): boolean {
    return false;
  }
}
