import { EmployeePayrollDetailsService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-payroll-details/services/employee-payroll-details.service";
import { EmployeeService } from "@app/core/services/employee.service";
import {
    EmploymentRecordPositionsService
} from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-positions/services/employment-record-positions.service";
import { PositionsService } from "@app/modules/positions/services/positions.service";
import { CostCentersService } from "@app/modules/cost-centers/services/cost-centers.service";
import { OrganizationStructureService } from "@app/modules/organization-structure/services/organization-structure.service";
import { WorkLocationsService } from "@app/modules/work-locations/services/work-locations.service";
import { WorkRotationService } from "@app/modules/work-rotations/services/work-rotation.service";
import { TimeOffService } from "@app/modules/time-off/services/time-off.service";
import { EmployeeAbsenceService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-absences/services/employee-absence.service";
import { EmploymentRecordsService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/services/employment-records.service";
import { EmployeeCompensationService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-compensation/services/employee-compensation.service";
import { EmployeeOtherCompensationService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-other-compensation/services/employee-other-compensation.service";
import { EmergencyContactService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/emergency-contacts/services/emergency-contact.service";
import { FamilyDependantService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-family-dependants/services/family-dependant.service";
import { EmployeeLeaveService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-leave/services/employee-leave.service";
import { EmployeeMedicalTestService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-medical-tests/services/employee-medical-test.service";
import { VisasAndPermitsService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-visas-and-permits/services/visas-and-permits.service";
import { EmployeeLanguageService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-languages/services/employee-language.service";
import { TrainingAndCertificationService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/training-and-certifications/services/training-and-certification.service";
import { EducationService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-education/services/education.service";
import { EmployeeWorkExperienceService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-work-experience/services/employee-work-experience.service";
import {
    EmployeeProfessionalExpertiseService
} from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-professional-expertises/services/employee-professional-expertise.service";
import { EmployeeAssociationService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-associations/services/employee-association.service";
import { EmployeeCompanyAssetsService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-company-asset/services/employee-company-assets.service";
import { EmployeeRelocationService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-relocation/services/employee-relocation.service";
import { EmployeeGrievancesService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-grievances/services/employee-grievances.service";
import { EmployeeInjuryIllnessService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-injury-illness/services/employee-injury-illness.service";
import { EmployeeBankDetailsService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-bank-details/services/employee-bank-details.service";
import { Observable } from "rxjs";
import { GoalPlanService } from "@app/modules/performance/components/goal-plans/services/goal-plan.service";

export class ReportsService {

    constructor(
        private employeeService: EmployeeService,
        private employmentRecordPositionsService: EmploymentRecordPositionsService,
        private positionsService: PositionsService,
        private costCentersService: CostCentersService,
        private organizationStructureService: OrganizationStructureService,
        private workLocationsService: WorkLocationsService,
        private workRotationService: WorkRotationService,
        private timeOffService: TimeOffService,
        private employeeAbsenceService: EmployeeAbsenceService,
        private employmentRecordsService: EmploymentRecordsService,
        private employeeCompensationService: EmployeeCompensationService,
        private employeeOtherCompensationService: EmployeeOtherCompensationService,
        private employeeEmergencyContactService: EmergencyContactService,
        private employeeFamilyDependantsService: FamilyDependantService,
        private employeeLeaveService: EmployeeLeaveService,
        private employeeMedicalTestService: EmployeeMedicalTestService,
        private visasAndPermitsService: VisasAndPermitsService,
        private employeeLanguageService: EmployeeLanguageService,
        private trainingAndCertificationService: TrainingAndCertificationService,
        private educationService: EducationService,
        private employeeWorkExperienceService: EmployeeWorkExperienceService,
        private employeeProfessionalExpertiseService: EmployeeProfessionalExpertiseService,
        private employeeAssociationService: EmployeeAssociationService,
        private companyAssetsService: EmployeeCompanyAssetsService,
        private employeeRelocationService: EmployeeRelocationService,
        private employeeGrievanceService: EmployeeGrievancesService,
        private employeeInjuryIllnesService: EmployeeInjuryIllnessService,
        private employeeBankDetailsService: EmployeeBankDetailsService,
        private employeePayrollDetailsService: EmployeePayrollDetailsService,
        private employeeGoalPlanService: GoalPlanService
    ) {
    }


    async getAllEmployees(preview, shouldFetch, listener) {
        return await this.getAllData(this.employeeService, 'getEmployeesNew', preview, shouldFetch, listener, 'Employees', 'employeeData');
    }

    async getAllPositions(preview, shouldFetch, listener) {
        return await this.getAllData(this.positionsService, 'getPositions', preview, shouldFetch, listener, 'Positions', 'positionData');
    }


    private async getAllData(service, method, preview?, shouldFetch?, listener?, description?, shouldFetchKey?) {
        this.updateProgressAndCompletion(shouldFetch, shouldFetchKey, `Preparing to fetch ${description}.`, listener);

        let take = preview ? 25 : 100;
        let skip = 0;
        let max = null; // prod
        // let max = 10; // debug only
        let total = null;
        let fetchedData = [];

        while ((total === null || skip < total) && (max === null || fetchedData.length < max)) {
            const res = await service[method](skip, take.toString()).toPromise();

            if (res && res.data && res.data.length > 0) {
                fetchedData = fetchedData.concat(res.data);
                skip += take;
                total = res.total;

                if (shouldFetch && listener && description) {
                    this.updateProgressAndCompletion(
                        shouldFetch,
                        shouldFetchKey,
                        `Fetched ${skip} out of ${total} ${description}.`,
                        listener,
                        total,
                        skip
                    );
                }

                if (preview || (max !== null && fetchedData.length >= max)) {
                    break;
                }
            } else {
                break;
            }
        }

        return fetchedData;
    }


    private async fetchEmployeeDataBatched(
        empData: any[],
        serviceFunc: (id: any) => Observable<any>,
        resultMapper: (result: any) => any = res => res,
        shouldFetch: any,
        listener: any,
        dataType: string,
        description?: string,
        max?: number,
        batchSize: number = 100
    ): Promise<any[]> {
        this.updateProgressAndCompletion(shouldFetch, dataType, `Preparing to fetch data from the ${description}.`, listener);

        const totalDataItems = max ? Math.min(empData.length, max) : empData.length;
        let fetchedItems = 0;
        const limitedData = max ? empData.slice(0, max) : empData;

        const results = [];

        for (let i = 0; i < limitedData.length; i += batchSize) {
            const batch = limitedData.slice(i, i + batchSize);
            // console.log('batch', batch);
            const batchPromises = batch.map(async (employee) => {
                try {
                    const result = await serviceFunc(employee.id).toPromise();
                    const mappedResult = resultMapper(result);
                    // console.log('mappedResult', mappedResult);
                    return mappedResult;
                } catch (err) {
                    console.error(`Error in batch: ${err}`);
                    return {error: err};
                }
            });

            const batchResults = await Promise.all(batchPromises);
            // console.log('batchResults', batchResults);

            results.push(...batchResults);
            fetchedItems += batch.length;

            this.updateProgressAndCompletion(
                shouldFetch,
                dataType,
                `Fetched ${fetchedItems} out of ${totalDataItems} records from the ${description}.`,
                listener,
                totalDataItems,
                fetchedItems
            );
        }

        return results;
    }


    updateProgressAndCompletion(shouldFetch, key, message, listener, totalItems = null, fetchedItems = null) {
        if (!key) {
            listener.setProgress(-1, message);
            return;
        }
        const fetchFiltered = Object.keys(shouldFetch).filter(key => shouldFetch[key].fetch);

        const fetchCount = fetchFiltered.length;

        let overallProgress = 0;
        let eachFetchProgress = 100 / fetchCount;

        // get index of current key
        let currentStep = fetchFiltered.indexOf(key);

        overallProgress = (currentStep) * eachFetchProgress;

        if (totalItems !== null && fetchedItems !== null) {
            const currentTaskProgress = (fetchedItems / totalItems) * eachFetchProgress;
            overallProgress += currentTaskProgress;
        }

        listener.setProgress(overallProgress, message);
    }


    async fetchPayrollDetailsData(data, shouldFetch, listener, description?: string, max?: number): Promise<any> {
        return await this.fetchEmployeeDataBatched(
            data,
            this.employeePayrollDetailsService.getPayrollDetails.bind(this.employeePayrollDetailsService),
            undefined,
            shouldFetch,
            listener,
            'payrollDetailsData',
            description,
            max
        );
    }


    async fetchPositionData(empData, shouldFetch, listener, description?: string, max?: number): Promise<any> {
        return await this.fetchEmployeeDataBatched(
            empData,
            this.employmentRecordPositionsService.getEmployeePositions.bind(this.employmentRecordPositionsService),
            undefined,
            shouldFetch,
            listener,
            'positionData',
            description,
        );
    }

    async fetchEmploymentRecordData(empData, shouldFetch, listener, description?: string, max?: number): Promise<any> {
        return await this.fetchEmployeeDataBatched(
            empData,
            this.employmentRecordsService.getEmploymentRecords.bind(this.employmentRecordsService),
            result => result.data,
            shouldFetch,
            listener,
            'employmentRecordData',
            description,
        );
    }

    async fetchTimeOffHistoryData(empData, shouldFetch, listener, description?: string, max?: number): Promise<any> {
        return await this.fetchEmployeeDataBatched(
            empData,
            this.employeeAbsenceService.getEmployeeAbsences.bind(this.employeeAbsenceService),
            result => result.data,
            shouldFetch,
            listener,
            'timeOffHistoryData',
            description,
        );
    }

    async fetchCompensationData(data, shouldFetch, listener, description?: string, max?: number): Promise<any> {
        return await this.fetchEmployeeDataBatched(
            data,
            this.employeeCompensationService.getCompensation.bind(this.employeeCompensationService),
            undefined,
            shouldFetch,
            listener,
            'compensationData',
            description,
            max
        );
    }

    async fetchOtherCompensationsData(data, shouldFetch, listener, description?: string, max?: number): Promise<any> {
        return await this.fetchEmployeeDataBatched(
            data,
            this.employeeOtherCompensationService.getOtherCompensations.bind(this.employeeOtherCompensationService),
            result => result.data,
            shouldFetch,
            listener,
            'otherCompensationsData',
            description,
            max
        );
    }

    async fetchEmergencyContactData(data, shouldFetch, listener, description?: string, max?: number): Promise<any> {
        return await this.fetchEmployeeDataBatched(
            data,
            this.employeeEmergencyContactService.getEmergencyContacts.bind(this.employeeEmergencyContactService),
            result => result.data,
            shouldFetch,
            listener,
            'emergencyContactData',
            description,
            max
        );
    }

    async fetchFamilyDependantsData(data, shouldFetch, listener, description?: string, max?: number): Promise<any> {
        return await this.fetchEmployeeDataBatched(
            data,
            this.employeeFamilyDependantsService.getFamilyDependants.bind(this.employeeFamilyDependantsService),
            result => result.data,
            shouldFetch,
            listener,
            'familyDependantsData',
            description,
            max
        );
    }

    async fetchMedicalTestsData(data, shouldFetch, listener, description?: string, max?: number): Promise<any> {
        return await this.fetchEmployeeDataBatched(
            data,
            this.employeeMedicalTestService.getMedicalTests.bind(this.employeeMedicalTestService),
            result => result.data,
            shouldFetch,
            listener,
            'medicalTestsData',
            description,
            max
        );
    }

    async fetchVisasAndPermitsData(data, shouldFetch, listener, description?: string, max?: number): Promise<any> {
        return await this.fetchEmployeeDataBatched(
            data,
            this.visasAndPermitsService.getVisaPermits.bind(this.visasAndPermitsService),
            result => result.data,
            shouldFetch,
            listener,
            'visasAndPermitsData',
            description,
            max
        );
    }

    async fetchLanguageData(data, shouldFetch, listener, description?: string, max?: number) {
        return await this.fetchEmployeeDataBatched(
            data,
            this.employeeLanguageService.getLanguages.bind(this.employeeLanguageService),
            result => result.data,
            shouldFetch,
            listener,
            'languageData',
            description,
            max
        );
    }

    async fetchTrainingAndCertificationData(data, shouldFetch, listener, description?: string, max?: number): Promise<any> {
        return await this.fetchEmployeeDataBatched(
            data,
            this.trainingAndCertificationService.getTrainingAndCertifications.bind(this.trainingAndCertificationService),
            result => result.data,
            shouldFetch,
            listener,
            'trainingAndCertificationData',
            description,
            max
        );
    }

    async fetchEducationData(data, shouldFetch, listener, description?: string, max?: number): Promise<any> {
        return await this.fetchEmployeeDataBatched(
            data,
            this.educationService.getEducations.bind(this.educationService),
            result => result.data,
            shouldFetch,
            listener,
            'educationData',
            description,
            max
        );
    }

    async fetchWorkHistoryData(data, shouldFetch, listener, description?: string, max?: number): Promise<any> {
        return await this.fetchEmployeeDataBatched(
            data,
            this.employeeWorkExperienceService.getWorkExperiences.bind(this.employeeWorkExperienceService),
            result => result.data,
            shouldFetch,
            listener,
            'workHistoryData',
            description,
            max
        );
    }

    async fetchProfessionalExpertiseData(data, shouldFetch, listener, description?: string, max?: number): Promise<any> {
        return await this.fetchEmployeeDataBatched(
            data,
            this.employeeProfessionalExpertiseService.getProfessionalExpertises.bind(this.employeeProfessionalExpertiseService),
            result => result.data,
            shouldFetch,
            listener,
            'professionalExpertiseData',
            description,
            max
        );
    }

    async fetchAssociationsData(data, shouldFetch, listener, description?: string, max?: number): Promise<any> {
        return await this.fetchEmployeeDataBatched(
            data,
            this.employeeAssociationService.getAssociations.bind(this.employeeAssociationService),
            result => result.data,
            shouldFetch,
            listener,
            'associationsData',
            description,
            max
        );
    }

    async fetchCompanyAssetsData(data, shouldFetch, listener, description?: string, max?: number): Promise<any> {
        return await this.fetchEmployeeDataBatched(
            data,
            this.companyAssetsService.getCompanyAssets.bind(this.companyAssetsService),
            result => result.data,
            shouldFetch,
            listener,
            'companyAssetsData',
            description,
            max
        );
    }


    async fetchRelocationsData(data, shouldFetch, listener, description?: string, max?: number): Promise<any> {
        return await this.fetchEmployeeDataBatched(
            data,
            this.employeeRelocationService.getRelocations.bind(this.employeeRelocationService),
            result => result.data,
            shouldFetch,
            listener,
            'relocationsData',
            description,
            max
        );
    }

    async fetchGrievancesData(data, shouldFetch, listener, description?: string, max?: number): Promise<any> {
        return await this.fetchEmployeeDataBatched(
            data,
            this.employeeGrievanceService.getGrievances.bind(this.employeeGrievanceService),
            result => result.data,
            shouldFetch,
            listener,
            'grievancesData',
            description,
            max
        );
    }

    async fetchInjuryIllnessData(data, shouldFetch, listener, description?: string, max?: number): Promise<any> {
        return await this.fetchEmployeeDataBatched(
            data,
            this.employeeInjuryIllnesService.getInjuryIllnesses.bind(this.employeeInjuryIllnesService),
            result => result.data,
            shouldFetch,
            listener,
            'injuryIllnessData',
            description,
            max
        );
    }

    async fetchBankDetailsData(data, shouldFetch, listener, description?: string, max?: number): Promise<any> {
        return await this.fetchEmployeeDataBatched(
            data,
            this.employeeBankDetailsService.getBankDetails.bind(this.employeeBankDetailsService),
            undefined,
            shouldFetch,
            listener,
            'bankDetailsData',
            description,
            max
        );
    }

    async fetchGoalsData(data, shouldFetch, listener, description?: string, max?: number): Promise<any> {
        return await this.fetchEmployeeDataBatched(
            data,
            this.employeeGoalPlanService.getEmployeeGoals.bind(this.employeeGoalPlanService),
            result => result.employeeGoals.data,
            shouldFetch,
            listener,
            'goalsData',
            description,
            max
        );
    }
}
