import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { OrganizationStructureService } from '@app/modules/organization-structure/services/organization-structure.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'add-user-dialog',
    templateUrl: './add-org-dialog.component.html',
    styleUrls: ['./add-org-dialog.component.scss']
})
export class AddOrgDialogComponent implements OnInit {

    public gridDataResult: GridDataResult;
    public isLoading: boolean;
    public pageSize: number = 20;
    public skip: number = 0;
    public searchFilterString: string;
    public searchValue: string;
    public selectedItems: any[] = [];
    private getRequest: any;

    public columns: any[] = [
        {field: 'name', title: 'Name'},
        {field: 'organizationType', subField: 'name', title: 'Type'},
        {field: 'parentOrganization', subField: 'name', title: this.translate.instant('ParentOrganization')},
        {field: 'startDate', title: 'Start Date', type: 'date'},
        {field: 'endDate', title: 'End Date', type: 'date'},
    ];

    modules = [
        {
            id: 'Talent',
            name: 'Talent'
        },
        {
            id: 'Performance',
            name: 'Performance'
        },
    ]
    selectedModule: string;

    constructor(
        private dialog: MatDialog,
        private overlayService: OverlayService,
        private dialogRef: MatDialogRef<AddOrgDialogComponent>,
        private translate: TranslateService,
        private organizationService: OrganizationStructureService,
        @Inject(MAT_DIALOG_DATA) data
    ) {

    }

    ngOnInit(): void {
        this.getAllOrganizations();
    }

    getAllOrganizations() {
        this.getRequest = this.organizationService.getOrganizations(this.skip, String(this.pageSize), this.searchFilterString)
            .subscribe(
                res => {
                    this.gridDataResult = {
                        data: res.data,
                        total: res.total,
                    };
                }
            );
    }

    close() {
        this.dialogRef.close();
    }


    pageChange(event: PageChangeEvent) {
        this.skip = event.skip;
        this.pageSize = event.take;
        this.getAllOrganizations();
    }

    openConfirmAddUsersDialog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            text: `Are you sure you want to add ${this.selectedItems.length} selected Organization(s) to this Role?`
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data === true) {
                    this.dialogRef.close(
                        {
                            selectedItems: this.selectedItems,
                            selectedModule: this.selectedModule
                        }
                    );
                }
            });
    }

    search() {
        // this.searchFilterString = `(FirstName like "${this.searchValue}") OR (LastName like "${this.searchValue}")`;
        this.searchFilterString = `(Name like "${this.searchValue}")`;
        this.getRequest.unsubscribe();
        this.getAllOrganizations();
    }

}
