<app-layout>
    <div class="main-container">
        <div class="menu">
            <mat-list>
                <mat-list-item *ngFor="let item of navList" [routerLink]="item.link" routerLinkActive="active">
                  <mat-icon class="material-icons-outlined">{{item.icon}}</mat-icon> 
                  <div>{{item.text}}</div>
                </mat-list-item>
            </mat-list>
        </div>
        <div class="content">
            <div class="main-content">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</app-layout>
