import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { routes } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { DataBindingDirective, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { defer, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BenefitsPlan } from './models/benefits-plan.model';
import { BenefitsPlansService } from './services/benefits-plans.service';

@Component({
  selector: 'app-benefits-plans',
  templateUrl: './benefits-plans.component.html',
  styleUrls: ['./benefits-plans.component.scss']
})
export class BenefitsPlansComponent implements OnInit {
  public columns: any[] = [
    { field: "name", title: "name", type: "navigation"},
    { field: "planProvider", subField: "text", title: "plan Provider"},
    { field: "planType", subField: "name", title: "plan Type"},
    { field: "rateType", subField: "name", title: "rate Type"},
    { field: "startDate", title: "start Date", type: "date"},
    { field: "endDate", title: "end Date", type: "date"},
    { field: "contractNumber", title: "contract Number"},
  ];
  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public mySelection: string[] = [];
  public routes: typeof routes = routes;
  public sort: SortDescriptor[] = [];
  pageSize: number = 20;
  skip: number = 0;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  filterString: string;
  searchFilterString: string;
  searchValue: string = "";
  sortString: string;
  loadingPlans: boolean;
  getPlansRequest: any;
  clearSelectedItems: boolean = false;

  constructor(
    private dialog: MatDialog,
    private benefitsPlansService: BenefitsPlansService,
    private overlayService: OverlayService,
    public router: Router,
    private snackbarService: SnackbarService,
  ) { }

  ngOnInit(): void {
    this.getPlans();
  }

  getPlans() {
    let filterString;
    this.loadingPlans = true;

    if(this.searchValue !== ""){
      filterString = `(name like "${this.searchValue}")`;
    }

    this.getPlansRequest = this.benefitsPlansService.getPlans(null, filterString, String(this.pageSize), this.skip)
    .pipe(
      finalize( () => { this.loadingPlans = false } )
    )
    .subscribe(
      res => {
        this.gridDataResult = {
          data: res.data,
          total: res.total,
        }
        this.gridData = res.data;
      }
    )
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;
    this.getPlansRequest.unsubscribe();
    this.getPlans();
  }

  navigateToPlanEditor(plan?: BenefitsPlan) {
    if(plan) {
      this.router.navigate([`${routes.BENEFITS}${routes.PLANS}/${routes.EDITOR}/${plan?.id}`]);
    }
    else {
      this.router.navigate([`${routes.BENEFITS}${routes.PLANS}/${routes.EDITOR}`]);
    }
  }

  deletePlans(planIds: string[]) {
    const observables = planIds.map(selectedItem => defer(() => this.benefitsPlansService.deletePlan(selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.view = this.getPlans();
        this.clearSelectedItems = !this.clearSelectedItems;
        this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.getPlans();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;

    if (sort[0].dir === undefined) {
      this.sortString = null
    }
    else {
      //use regex to get column field to sort with
      let field: any;

      //if it is an object category like (department.text) trim .text from the end, else it doesnt need to be trimmed
      (sort[0].field).match(/.+(?=\.)/) === null
      ? field = sort[0].field
      : field = (sort[0].field).match(/.+(?=\.)/)
      this.sortString = `${field}-${sort[0].dir}`;
    }

    this.getPlans();
  }
}
