import { Component, OnInit } from '@angular/core';
import { TableList, TableListField } from './models/lookup.model';
import { LookupService } from './services/lookup.service';
import {features} from '@app/consts';
import {FeatureService} from '@app/core/services/feature.service';
import {SecurityProtectedBase} from '@app/shared/components/security-protected/security-protected';

@Component({
  selector: 'app-lookup',
  templateUrl: './lookup.component.html',
  styleUrls: ['./lookup.component.scss']
})
export class LookupComponent extends SecurityProtectedBase implements OnInit {
  isLoading: boolean = true;
  loadingTableList: boolean = false;
  tableLookups: {name:string, list:TableList[]}[];
  tableLists: TableList[];
  selectedTableListField: TableListField;
  tableListSearchValue: string = '';

  constructor(
    private lookupService: LookupService,
    featureService: FeatureService
  ) {
    super(featureService, features.LISTS)
  }

  ngOnInit(): void {
    this.getTableLookups();
  }

  capitalizeFirstLetter(string) {
    return string.charAt(1).toUpperCase() + string.slice(2);
  }

  getTableLookups() {
    this.isLoading = true;

    this.lookupService.getTableLists().subscribe(
      res => {
        this.tableLists = res;
        this.selectedTableListField = this.tableLists[0].fields[0];
        this.isLoading = false;
      },
      err => {

      }
    );
  }

  refeshTableList(){
    this.loadingTableList = true;

    setTimeout(() => {
      this.loadingTableList = false;
    }, 1);
  }
}
