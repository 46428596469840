<h5 mat-dialog-title>{{ 'PlanCoverage' | translate: {Default: "Plan Coverage"} }}</h5>

<mat-dialog-content>
    <ng-container *ngIf="form === undefined">
        <ngx-skeleton-loader
            count="5"
            [theme]="{ 
                'height.px': 50
            }"
        ></ngx-skeleton-loader>
    </ng-container>

    <form class="form" *ngIf="form" [formGroup]="form">

        <div class="form-section">
            <div class="form-section-title">
                <h6></h6>
            </div>
            <div class="form-section-contents">
                <div class="section-row">
                    <mat-form-field appearance="fill">
                        <mat-label>
                            {{ 'Coverage' | translate: {Default: "Coverage"} }}
                        </mat-label>
                
                        <mat-select disableOptionCentering formControlName="coverage" [disabled]="coverageId !== null">
                                <mat-option></mat-option>
                                <mat-option 
                                    *ngFor="let option of coverageOptions" 
                                    [value]="option.id">
                                        {{option.name}}
                                </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>
                            {{ 'CoverageCurrency' | translate: {Default: "Currency"} }}
                        </mat-label>
                
                        <mat-select disableOptionCentering formControlName="currency">
                            <ng-container *ngIf="currencyOptions | async as currencyOptions">
                                <mat-option></mat-option>
                                <ng-container *ngFor="let currencyOption of currencyOptions">
                                    <mat-option *ngIf="currencyOption.isEnabled" [value]="currencyOption.id">{{currencyOption.id}}</mat-option>
                                </ng-container>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>
                            {{ 'CoverageCostPerMonth' | translate: {Default: "Cost Per Month"} }}
                        </mat-label>
                        
                        <input 
                            matInput
                            type="number"
                            formControlName="costPerMonth">
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>

</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button> 
</mat-dialog-actions>