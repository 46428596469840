import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { finalize } from 'rxjs/operators';
import { ReportsService } from '../../../../services/reports.service';

@Component({
  selector: 'app-new-hires',
  templateUrl: './new-hires.component.html',
  styleUrls: ['./new-hires.component.scss']
})
export class NewHiresComponent implements OnInit {
  newHires: MatTableDataSource<any[]>;
  tableColumns = [
    { name: 'Name', dataKey: 'name', isSortable: true, position: 'left' },
    { name: 'Email', dataKey: 'email', isSortable: true, position: 'left' },
    { name: 'Position', dataKey: 'position', isSortable: true, position: 'left' },
    { name: 'Join Date', dataKey: 'joinDate', isSortable: true, position: 'left' },
    { name: 'City', dataKey: 'city', isSortable: true, position: 'left' }
  ];
  isLoading: boolean = true;
  pageSize: number = 5;
  page: string = '1';
  pagination: Object;
  
  constructor(
    private reportsService: ReportsService
  ) { 
    this.getNewHires();
  }

  ngOnInit(): void {
  }

  getNewHires() {
    this.isLoading = true;

    this.reportsService.getNewHires()
    .pipe(
      finalize( () => {
        this.isLoading = false;
      })
    )
    .subscribe( 
      res => {
        this.newHires = res;
      });
  }

}
