import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-basic-employee-report',
  templateUrl: './basic-employee-report.component.html',
  styleUrls: ['./basic-employee-report.component.scss']
})
export class BasicEmployeeReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
