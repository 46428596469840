<app-employee-layout selectedComponent="EmploymentCompensation">
    <div class="main-container">
        <div class="left">
            <mat-list>
                <mat-list-item *ngFor="let item of navList" (click)="selectedComponent = item" [ngClass]="{'selected': selectedComponent === item }">
                    {{item.text}}
                </mat-list-item>
            </mat-list>
        </div>
        
        <div *ngIf="!isLoading" class="main-content" [ngSwitch]="selectedComponent.text">
            <!-- <app-compensation-details *ngSwitchCase="'My Compensation'"></app-compensation-details> -->
            <app-benefits *ngSwitchCase="'My Benefits'"></app-benefits>
        </div>
    </div>
</app-employee-layout>