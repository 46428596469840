<div class="container">
    <h6>{{ 'ChildGoals' | translate: {Default: 'Child Goals'} }}</h6>

    <ng-container *ngIf="employeeGoals.length === 0; else showGoals">
        <div class="no-goals">
            <div>{{ 'NoChildGoals' | translate: {Default: 'No Child Goals'} }}</div>
        </div>
    </ng-container>
    <ng-template #showGoals>
        <mat-expansion-panel *ngFor="let employeeGoal of employeeGoals; let first = first" [expanded]="first" class="goal">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{employeeGoal.objective}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            
            <ng-template matExpansionPanelContent>
                <app-employee-personal-goal-form
                    [goal]="employeeGoal"
                    [employeeId]="employeeId"
                    [readOnly]="readOnly"
                ></app-employee-personal-goal-form>
            </ng-template>
        </mat-expansion-panel>
    </ng-template>
</div>