import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-directory-form-dialog',
  templateUrl: './directory-form-dialog.component.html',
  styleUrls: ['./directory-form-dialog.component.scss']
})
export class DirectoryFormDialogComponent implements OnInit {

  text: string;
  form: UntypedFormGroup;

  constructor(
    public translate: TranslateService,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<DirectoryFormDialogComponent>
  ){}

  ngOnInit() {
    this.form = this.fb.group({
      directoryName: ['', Validators.required]
    });
  }

  save() {
      this.dialogRef.close(this.form.value);
  }

  close() {
      this.dialogRef.close();
  }

}
