import { Version } from "@app/shared/models/version.model";

export interface LeaveRequest {
    type: string;
    to: string;
    from: string;
    status: string;
    reason: string;
}

export interface EmployeeTimeOffType {
    availableHours: number;
    timeOffType: {
      id: string;
      name: string;
    },
    timeOffPolicy: {
      id: string;
      name: string;
      carryOverAmount: number;
      carryOverMonth: number;
      carryOverDay: number;
    }
}

export interface AssignEmployeeTimeOffType {
    asOf: string;
    changeReason: string;
    changeReasonComments: string;
    type: string;
    policy: string;
}

export interface TimeOffDetail {
  statutoryHolidayGroup: {
    id: string
    name: string
  },
  statutoryVacationDifference: number
  version: Version
}

export interface SubmitTimeOffDetail {
  asOf: string
  changeReason: string
  changeReasonComments: string
  employeeId: string
  statutoryHolidayGroup: string
}

export interface SubmitTimeOffTypeHours {
  hours: number
  asOf: string
  changeReason: string
  changeReasonComments: string
}

export interface ManualAdjustmentsHistory {
  id: string
  hours: number
  asOf: string
  version: Version
}

export interface TimeOffTypeLog {
  on: string
  adjustment: number
  reason: string
  runningTotal: number
  createdOn: string
  properties: {
    key: string
    value: string
  }[]
}
  