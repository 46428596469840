import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DenialComment, MyRequest } from '@app/shared/models/workflows-absences/workflows-absences.model';
import { map, Observable, of } from 'rxjs';
import { EnvironmentService } from "@app/core/services/environment.service";
import * as moment from 'moment';
import { GetAllRequestService } from '@app/shared/services/get-all-request.service';

const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class WorkflowsAbsencesService {

  constructor(
    private http: HttpClient, 
    private envService: EnvironmentService,
    private getAllRequestService: GetAllRequestService
  ) { }

  submitRequest(data: any): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}Workflows/Absences`, data, {
      headers: headers
    });
  }

  editRequest(requestId: string, requestSubmit: any): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}Workflows/Absences/Edit/${requestId}`, requestSubmit, {
      headers: headers
    });
  }

  getRequest(requestId: string): Observable<MyRequest> {
    return this.http.get<any>(`${this.envService.env.apiUrl}Workflows/Absences/${requestId}`, {
      headers: headers
    });
  }

  approveRequest(requestId: string): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}Workflows/Absences/Approve/${requestId}`, {
      headers: headers
    });
  }

  denyRequest(requestId: string, denialComments: DenialComment): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}Workflows/Absences/Deny/${requestId}`, denialComments, {
      headers: headers
    });
  }

  getPendingApproval(filterTime?: string): Observable<{ data: MyRequest[], total: number }> {
    return this.http.get<any>(`${this.envService.env.apiUrl}Workflows/Absences/PendingApproval`, {
      headers: headers
    })
    .pipe(
      map(res => {
        if(filterTime){
          let filteredRequests: MyRequest[] = [];

          res.data.forEach((request: MyRequest) => {
            if(moment(request.from).isAfter(filterTime)) {
              filteredRequests.push(request);
            }
          });
          
          res.data = filteredRequests;
          res.total = filteredRequests.length;
          return res;
        }
        else {
          return res;
        }

      })
    );
  }

  getMyRequests(skip?: number, take?: number, sort?: string, filter?: string, asOf?: string) {
    return this.getAllRequestService.fetchAllData<MyRequest>(`${this.envService.env.apiUrl}Workflows/Absences/MyRequests`, skip, take, sort, filter, asOf)
  }

  deleteRequest(requestId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}Workflows/Absences/Delete/${requestId}`, {
      headers: headers
    });
  }
}
