import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { CustomReportsService } from "@app/modules/custom-reports/services/reports.service";
import { CustomReportVerbose, ServerReportElement } from "@app/modules/custom-reports/models/custom-report.model";
import {
    EmploymentRecordPositionsService
} from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-positions/services/employment-record-positions.service";
import { OrganizationStructureService } from "@app/modules/organization-structure/services/organization-structure.service";
import { WorkLocationsService } from "@app/modules/work-locations/services/work-locations.service";
import { WorkRotationService } from "@app/modules/work-rotations/services/work-rotation.service";
import { TimeOffService } from "@app/modules/time-off/services/time-off.service";
import { PositionsService } from "@app/modules/positions/services/positions.service";
import { CostCentersService } from "@app/modules/cost-centers/services/cost-centers.service";
import { EmployeeService } from "@app/core/services/employee.service";
import { EmploymentRecordsService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/services/employment-records.service";
import { EmployeeLeaveService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-leave/services/employee-leave.service";
import { SortDescriptor, State } from "@progress/kendo-data-query";
import { FilterableSettings, GridComponent, MultipleSortSettings, PageChangeEvent } from "@progress/kendo-angular-grid";
import { SnackbarService } from "@app/core/services/snackbar.service";
import { LegendLabelsContentArgs } from "@progress/kendo-angular-charts";
import { EmployeeCompensationService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-compensation/services/employee-compensation.service";
import { EmployeeOtherCompensationService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-other-compensation/services/employee-other-compensation.service";
import { CustomProgressUpdate, ReportGeneratorListener } from "@app/modules/custom-reports/util/report-generator-listener";
import { ReportsMapping } from "@app/modules/custom-reports/util/reports-mapping";
import { ReportsUtil } from "@app/modules/custom-reports/util/reports-util";
import { EmergencyContactService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/emergency-contacts/services/emergency-contact.service";
import { EmployeeMedicalTestService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-medical-tests/services/employee-medical-test.service";
import { VisasAndPermitsService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-visas-and-permits/services/visas-and-permits.service";
import { EmployeeLanguageService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-languages/services/employee-language.service";
import { TrainingAndCertificationService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/training-and-certifications/services/training-and-certification.service";
import { EducationService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-education/services/education.service";
import { EmployeeWorkExperienceService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-work-experience/services/employee-work-experience.service";
import {
    EmployeeProfessionalExpertiseService
} from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-professional-expertises/services/employee-professional-expertise.service";
import { EmployeeAssociationService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-associations/services/employee-association.service";
import { EmployeeCompanyAssetsService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-company-asset/services/employee-company-assets.service";
import { EmployeeRelocationService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-relocation/services/employee-relocation.service";
import { EmployeeGrievancesService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-grievances/services/employee-grievances.service";
import { EmployeeInjuryIllnessService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-injury-illness/services/employee-injury-illness.service";
import { EmployeeBankDetailsService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-bank-details/services/employee-bank-details.service";
import { EmployeePayrollDetailsService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-payroll-details/services/employee-payroll-details.service";
import { EmployeeAbsenceService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-absences/services/employee-absence.service";
import { FamilyDependantService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-family-dependants/services/family-dependant.service";
import { of } from "rxjs";
import { GoalPlanService } from "@app/modules/performance/components/goal-plans/services/goal-plan.service";
import { TranslateService } from "@ngx-translate/core";


@Component({
    selector: 'app-custom-reports',
    templateUrl: './report-renderer.component.html',
    styleUrls: ['./report-renderer.component.scss']
})
export class ReportRendererComponent implements OnInit, ReportGeneratorListener {

    @ViewChild('grid') private grid: GridComponent;

    public reportsUtil: ReportsUtil = new ReportsUtil(
        this.employeeService,
        this.employmentRecordPositionsService,
        this.positionsService,
        this.costCentersService,
        this.organizationStructureService,
        this.workLocationsService,
        this.workRotationService,
        this.timeOffService,
        this.employeeAbsenceService,
        this.employmentRecordsService,
        this.employeeCompensationService,
        this.employeeOtherCompensationService,
        this.employeeEmergencyContactService,
        this.employeeFamilyDependantsService,
        this.employeeLeaveService,
        this.employeeMedicalTestService,
        this.visasAndPermitsService,
        this.employeeLanguageService,
        this.trainingAndCertificationService,
        this.educationService,
        this.employeeWorkExperienceService,
        this.employeeProfessionalExpertiseService,
        this.employeeAssociationService,
        this.companyAssetsService,
        this.employeeRelocationService,
        this.employeeGrievanceService,
        this.employeeInjuryIllnesService,
        this.employeeBankDetailsService,
        this.employeePayrollDetailsService,
        this.employeeGoalPlanService
    );
    public reportsMapping: ReportsMapping = new ReportsMapping();

    public isLoading: boolean = true;
    public customLoadingIndicator: CustomProgressUpdate = {
        progress: 0,
        message: 'Starting up...',
    }

    public report: CustomReportVerbose;
    public reportElements: ServerReportElement[] = [];

    public pageSettings = {
        info: false,
        pageSizes: false,
        previousNext: false
    }
    public columns: any[] = [];

    public sortSettings: MultipleSortSettings = {
        mode: "multiple",
        initialDirection: "desc",
        allowUnsort: true,
        showIndexes: true,
    };
    public sort: SortDescriptor[] = [];
    public filterMode: FilterableSettings = "row";

    public state: State = {
        skip: 0,
        take: 5,
        sort: [],
        group: [],
        filter: {
            logic: "and",
            filters: [],
        },
    };

    public defaultPageSize = 20;
    public defaultSkip = 0;
    public paginationSettings = [];

    public labelContent(args: LegendLabelsContentArgs): string {
        let percentage: any = (args.percentage * 100).toFixed(2);
        return percentage % 1 === 0 ?
            `${args.dataItem.category}: ${percentage.slice(0, -3)}%` :
            `${args.dataItem.category}: ${percentage}%`;
    }

    public reportElementData: any[] = [];
    private reportId: string;
    pageable: any = {
        info: true,
        pageSizes: [5, 10, 20, 50, 100],
        previousNext: true
    };

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        protected translate: TranslateService,
        private snackbarService: SnackbarService,
        private reportService: CustomReportsService,
        private employeeService: EmployeeService,
        private employmentRecordPositionsService: EmploymentRecordPositionsService,
        private positionsService: PositionsService,
        private costCentersService: CostCentersService,
        private organizationStructureService: OrganizationStructureService,
        private workLocationsService: WorkLocationsService,
        private workRotationService: WorkRotationService,
        private timeOffService: TimeOffService,
        private employeeAbsenceService: EmployeeAbsenceService,
        private employmentRecordsService: EmploymentRecordsService,
        private employeeCompensationService: EmployeeCompensationService,
        private employeeOtherCompensationService: EmployeeOtherCompensationService,
        private employeeEmergencyContactService: EmergencyContactService,
        private employeeFamilyDependantsService: FamilyDependantService,
        private employeeLeaveService: EmployeeLeaveService,
        private employeeMedicalTestService: EmployeeMedicalTestService,
        private visasAndPermitsService: VisasAndPermitsService,
        private employeeLanguageService: EmployeeLanguageService,
        private trainingAndCertificationService: TrainingAndCertificationService,
        private educationService: EducationService,
        private employeeWorkExperienceService: EmployeeWorkExperienceService,
        private employeeProfessionalExpertiseService: EmployeeProfessionalExpertiseService,
        private employeeAssociationService: EmployeeAssociationService,
        private companyAssetsService: EmployeeCompanyAssetsService,
        private employeeRelocationService: EmployeeRelocationService,
        private employeeGrievanceService: EmployeeGrievancesService,
        private employeeInjuryIllnesService: EmployeeInjuryIllnessService,
        private employeeBankDetailsService: EmployeeBankDetailsService,
        private employeePayrollDetailsService: EmployeePayrollDetailsService,
        private employeeGoalPlanService: GoalPlanService
    ) {
        this.reportId = this.getIdInURL();

        this.reportService.getReportById(this.reportId)
        .subscribe((report) => {
                this.report = report;
                console.log(report);

                this.reportService.getReportElements(this.reportId)
                .subscribe((serverReportElements) => {
                    this.reportElements = this.reportsMapping.orderElements(serverReportElements);

                    this.reportElementData = [];
                    this.paginationSettings = [];
                    this.reportElements.forEach((reportElement, index) => {
                        this.reportElementData.push(null);
                        this.paginationSettings.push({
                            pageSize: this.defaultPageSize,
                            skip: this.defaultSkip,
                        });
                        this.reportsUtil.generateReport(reportElement, this, false, index);
                    });

                    // this.columns = this.reportsUtil.getColumnsWithMapping();
                });
            }
        )
    }

    getReportName() {
        return this.report?.name[0]?.text ?? 'Report';
    }

    getIdInURL(): string {
        let IdInURL: string;
        this.route.paramMap.subscribe(
            params => IdInURL = params.get("id")
        )
        return IdInURL;
    }

    ngOnInit(): void {
    }

    dataLoading() {
        this.isLoading = true
    }


    setProgress(progress: number, message: string) {
        console.log("Progress: " + progress + " Message: " + message);
        if (progress >= 0) {
            this.customLoadingIndicator.progress = progress;
        }
        this.customLoadingIndicator.message = message;
    }

    setData(data: any, elementIndex: number) {
        if (elementIndex !== null) {
            this.reportElementData[elementIndex] = data;
        } else {
            this.reportElementData.push(data);
        }
    }

    getFieldMapping(reportElement: ServerReportElement, table: string, field: string) {

        let fieldMapping = this.reportsUtil.getFieldMapping(reportElement, table, field, true);
        return fieldMapping;
    }

    getColumns(reportElement: ServerReportElement) {
        let columns = this.reportsUtil.getColumns(reportElement);
        return columns;
    }

    public pageChange(index: number, event: PageChangeEvent): void {
        this.paginationSettings[index].skip = event.skip;
        this.paginationSettings[index].pageSize = event.take;
    }

    public getPagedData(index: number): any {
        if (this.reportElementData[index] === null) {
            return null;
        } else {
            return {
                data: this.reportElementData[index].data.slice(this.paginationSettings[index].skip, this.paginationSettings[index].skip + this.paginationSettings[index].pageSize),
                total: this.reportElementData[index].total,
            }
        }
    }

    backToReportGroups() {
        let sanitizedReportType = this.report.type.name.replace(/ /g, '');
        this.router.navigate([`Reports/Custom/Type/${sanitizedReportType}`]);
    }


    getColumnWidth(reportElement: any): number | undefined {
        const totalColumns = this.getColumns(reportElement).length;
        if (totalColumns > 8) {
            return 250;
        }
        return undefined; // Use the default width
    }

    allData(index: number) {
        return (args: any) => {
            console.log("reportElementIndex: " + index);
            return of({
                data: this.reportElementData[index].data,
                total: this.reportElementData[index].total
            });
        };
    }


}
