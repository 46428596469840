import {Field, Table} from '@app/modules/security-setup/models/table.model';
import { Version } from '@app/shared/models/version.model';
import {Localization} from '@app/shared/models/localization.model';

export class SecurityRole {
    id: string;
    name: string;
    roleType: RoleType;
    description: string;
    features: SecurityFeature[];
    totalUsers: number
}

export class SecurityRoleVerbose {
    id: string;
    name: string;
    roleType: RoleType;
    description: Localization[];
    features: SecurityFeature[];
    totalUsers: number
}

export class SecurityFeature {
    id: string;
    name: string;
}

export class UserSecurityRole {
    id: string;
    name: string;
}

export class RoleType {
    id: string;
    name: string;
}

export class SecurityRoleSubmit {
    name: string;
    roleType: string;
    features: string[];
    description: Localization[];
}

export class CopySecurityRole {
    name: string;
}

export class CrudPermission {
    enabled: boolean;
    filter: string;

    constructor(enabled, filter = 'string') {
        this.enabled = enabled;
        this.filter = filter;
    }
}


export class SecurityUser {
    user: {
        id: string
        firstName: string
        lastName: string
    }
    role: {
        id: string
        name: string
    }
    createdOn: string
    createdBy: {
        id: string
        firstName: string
        lastName: string
    }
}

export class SecurityUserVerbose {
    id: string;
    firstName: string;
    lastName: string;
    title: string;
}


export class TablePermissions {
    id: string;
    role: SecurityRole;
    table: Table;
    create: CrudPermission;
    read: CrudPermission;
    update: CrudPermission;
    delete: CrudPermission;
    viewHistory: CrudPermission;
    viewFutureDated: CrudPermission;
}

export class TablePermissionsSubmit {
    create: CrudPermission;
    read: CrudPermission;
    update: CrudPermission;
    delete: CrudPermission;
    viewHistory: CrudPermission;
    viewFutureDated: CrudPermission;
}

export class FieldPermissions {
    id: string;
    role: SecurityRole;
    field: Field;
    read: CrudPermission;
    update: CrudPermission;
    deny: CrudPermission;
}

export class FieldPermissionsSubmit {
    read: CrudPermission;
    update: CrudPermission;
    deny: CrudPermission;
}

export class RoleSecurityForField {
    id: string;
    role: {
        id: string,
        name: string,
        totalUsers: number
    };
    field: {
        id: string,
        name: string
    };
    read: CrudPermission;
    update: CrudPermission;
    deny: CrudPermission;
    version: Version;
}

export class SecurityRoleOrganization {
    organizationUnit: {
        id: string
        name: string
        parent: string
    }
    role: {
        id: string
        name: string
    }
    module: {
        id: string
        name: string
    }
}

