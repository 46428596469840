import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';

@Injectable({
  providedIn: 'root'
})
export class GetAllRequestService {

  constructor(
    private http: HttpClient,
  ) { }

  // If an api endpoint returns a PagedData object keep fetching more records 100 at a time until you have all available records 
  fetchAllData<T>(url: string, skip: number, take: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<T> | Observable<PagedData<T>>>{
    let params = new HttpParams();
    params = params.append('skip', skip);
    params = params.append('take', take);
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
  
    return this.http.get<PagedData<any>>(`${url}`, {
      headers: {
        'Content-Type': 'application/json'
      },
      params: params
    }).pipe(
      map((response: PagedData<any>) => {
        const { data, total } = response;
        if (data.length < take || data.length === total) {
          return response; // All data fetched
        } else {
          // More data available, fetch the next batch
          return this.fetchAllData(url, skip + take, take + take, sort, filter, asOf).pipe(
            map((nextData: PagedData<any>) => {
              response.data = [...response.data, ...nextData.data];

              return response;
            })
          );
        }
      })
    );
  }
}
