<h5 mat-dialog-title></h5>

<mat-dialog-content>
    <app-pdf-viewer [pdfUrl]="pdfUrl" ></app-pdf-viewer>
</mat-dialog-content>

<mat-dialog-actions class="bottom-container">
        <div>
            <button mat-raised-button #typeSignature color="warn" (click)="saveForm()" *ngIf="allowFormSave !== false">Save Form</button>
            <button class="mat-raised-button" (click)="close()">Close</button>
        </div>
        <div class="signature-container" *ngIf="signatureRequired">
            <signature-pad *ngIf="showSignature; else type" #signature [options]="signaturePadOptions" (drawStart)="drawStart($event)" (drawEnd)="drawComplete($event)"></signature-pad>
            <ng-template #type>
                <input matInput placeholder="Type Signature" >
            </ng-template>
            <div class="signature-buttons">
                <div>
                    <button mat-stroked-button [ngClass]="{'selected': showSignature }" (click)="showSignature = true;">Sign</button>
                    <button mat-stroked-button [ngClass]="{'selected': !showSignature }" (click)="showSignature = false;">Type</button>
                </div>
                <button mat-stroked-button (click)="clearSignature()">Clear</button>
            </div>
        </div>
</mat-dialog-actions>