<!-- <app-data-grid-comp
    [columns]="columns"
    hideActionButtons="true"
    [filterCategories]="filterCategories"
    [bindingType]="bindingType"
    [view]="view"
    [gridData]="gridData"
    [gridDataResult]="gridDataResult"
    [isLoading]="isLoading"
    [pageSize]="pageSize"
    [skip]="skip"
    category="CustomReports')"
    [rowSelectionSettings]="{
        canSelectRows: true,
        selectableMode: 'multiple',
        selectRowBy: 'id'
    }"
    clickableRows="true"
    [isLoading]="isLoading"
    [clearSelectedItems]="clearSelectedItems"
    searchable="true"
    [sortable]="{
        mode: 'multiple'
    }"
    [sortableColumns]="sortableColumns"
    [rowActionButtons]="gridActionButtons"
    (rowActionEvent)="goToEditReport($event)"
    (emitSearchEvent)="search($event)"
    (emitPageChangeEvent)="pageChange($event)"
    (emitOpenDialogEvent)="goToRenderReport($event)"
    (emitDeleteEvent)="openConfirmDeleteDialog()"
    (emitSortEvent)="sortChange($event)"
    (emitFilterEvent)="filterCallback($event)"
></app-data-grid-comp> -->

<app-data-grid-comp
    [columns]="columns"
    hideActionButtons="true"
    [bindingType]="bindingType"
    [view]="view"
    [gridData]="gridData"
    [gridDataResult]="gridDataResult"
    [isLoading]="isLoading"
    [pageSize]="pageSize"
    [skip]="skip"
    searchable="true"
    (emitSearchEvent)="search($event)"
    [filterCategories]="filterCategories"
    (emitFilterEvent)="filterCallback($event)"
    category="CustomReports"
    [rowSelectionSettings]="{
        canSelectRows: true,
        selectableMode: 'multiple',
        selectRowBy: 'id'
    }"
    clickableRows="true"
    [clearSelectedItems]="clearSelectedItems"
    [rowActionButtons]="gridActionButtons"
    (rowActionEvent)="goToEditReport($event)"
    (emitPageChangeEvent)="pageChange($event)"
    (emitOpenDialogEvent)="goToRenderReport($event)"
    (emitDeleteEvent)="deleteAllSelected($event)"
></app-data-grid-comp>
