<app-layout>
    <mat-toolbar class="page-header" role="heading">
        <h1>{{ 'FormConfigurator' | translate: {Default: "Form Configurator"} }}: {{formId}}</h1>

        <button mat-raised-button routerLink="{{routes.SITE_SETTINGS}}{{routes.FORMS}}">{{ 'FormConfiguratorReturnToFormsList' | translate: {Default: "Return To Forms List"} }}</button> 
    </mat-toolbar>

    <div class="main-content">
        <app-form-details-form [formId]="formId"></app-form-details-form>
        
        <app-form-elements-display [formId]="formId"></app-form-elements-display>
    </div>
</app-layout>