import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ApiUser } from '../../models/api-setup.model';
import { ApiSetupService } from '../../services/api-setup.service';
import { ApiUserDialogComponent } from '../api-user-dialog/api-user-dialog.component';
import { NewApiUserDialogComponent } from '../new-api-user-dialog/new-api-user-dialog.component';

@Component({
  selector: 'app-api-users-data-grid',
  templateUrl: './api-users-data-grid.component.html',
  styleUrls: ['./api-users-data-grid.component.scss']
})
export class ApiUsersDataGridComponent implements OnInit {
  public columns: any[] = [
    { field: "apiUserId", title: "Api User Id" },
    { field: "name", title: "Name" },
    { field: "username", title: "Username" }
  ];
  public bindingType: String = "array";
  public view: Observable<GridDataResult>;
  public gridData: any;
  public gridDataResult: GridDataResult;
  isLoading: boolean;
  pageSize: number = 5;
  skip: number = 0;

  constructor(
    private dialog: MatDialog,
    private apiSetupService: ApiSetupService,
  ) { }

  ngOnInit(): void {
    this.getApiUsers();
  }

  getApiUsers(): void {
    this.isLoading = true;

    this.apiSetupService.getApiUsers()
    .pipe(
        finalize(()=>{ this.isLoading = false; })
    )
    .subscribe(
      res => {
        this.gridDataResult = {
          data: res,
          total: res.length,
        }
        this.gridData = res;
      }
    )
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.getApiUsers();
  }

  openNewApiUserDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(NewApiUserDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data !== false) {

        }
      }
    );
  }

  openApiUserDialog(apiUser?: ApiUser) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      apiUser: apiUser ? apiUser : null
    };

    const dialogRef = this.dialog.open(ApiUserDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {

        }
      }
    );
  }

}
