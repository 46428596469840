<div class="content">
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
            <h4 appLocalizationPopupDirective localizationCode="EmployeeSecurity-DeleteEmployee">{{ 'EmployeeSecurity-DeleteEmployee' | translate: {Default: "Delete Employee"} }}</h4>
        </mat-card-title>
    
        <mat-card-content>
            <div *ngIf="loadingEmployee; else loaded" class="loading-container">
                <ngx-skeleton-loader
                count="5"
                [theme]="{ 
                    'height.px': 50
                }"
                ></ngx-skeleton-loader>
            </div>
            <ng-template #loaded>
                <h6 appLocalizationPopupDirective localizationCode="DeleteEmployee-ThisActionCannotBeUndone">{{ 'DeleteEmployee-ThisActionCannotBeUndone' | translate: {Default: "This action cannot be undone. This will permanently delete the employee"} }} {{employee.firstName}} {{employee.lastName}}</h6>

                <h6 appLocalizationPopupDirective localizationCode="DeleteEmployee-PleaseTypeThisToConfirm">{{ 'DeleteEmployee-PleaseTypeThisToConfirm' | translate: {Default: "Please type this to confirm"} }} "{{employee.firstName}} {{employee.lastName}}"</h6>
                    
                <mat-form-field appearance="fill">
                    <input matInput [(ngModel)]="nameConfirmationValue" (input)="checkIfNameConfirmed()">
                </mat-form-field>

                <button appLocalizationPopupDirective localizationCode="DeleteEmployee-DeleteEmployeeButtonText" mat-raised-button color="warn" [disabled]="nameNotConfirmed" appPreventDoubleClick (throttledClick)="openConfirmDeleteDialog()">{{ 'DeleteEmployee-DeleteEmployeeButtonText' | translate: {Default: "I understand the consequences, delete the employee"} }}</button> 
            </ng-template>

        </mat-card-content>
    </mat-card>
</div>