<mat-card>
    <kendo-grid
        [data]="query | async"
        [loading]="isLoading"
        [skip]="state.skip"
        [pageSize]="state.take"
        [sort]="sort"
        [sortable]="true"
        (sortChange)="sortChange($event)"
        scrollable="virtual"
        [rowHeight]="36"
        [height]="750"
        (pageChange)="pageChange($event)"
        (excelExport)="onExcelExport($event)"
        >

        <ng-template kendoGridToolbarTemplate>
            <button class="export-to-excel-button" kendoGridExcelCommand>
                <img
                    class="ms-icon"
                    src="assets/icons/excel-icon.svg"
                    style="width: 35px;
                            height: 35px;
                            vertical-align: middle;">
                    {{ 'ExportToExcel' | translate: {Default: "Export to Excel"} }}
            </button>

            <div class="filters-container" *ngIf="!loadingPositions">
                <filter-control [categories]="filterCategories" (callback)="filterCallback($event)"></filter-control>
            </div>

            <div class="k-mb-2">
                <mat-slide-toggle setColor="primaryColour" [checked]="filterInactive" disabled="false" (change)="toggleFilterInactive($event)">{{ 'ActiveOnly' | translate: {Default: "Active Only"} }}</mat-slide-toggle>
            </div>
        </ng-template>

        <kendo-grid-column
            *ngFor="let col of columns"
            [field]="col.field"
            [title]="col.title"
            [width]="300"
        ></kendo-grid-column>

        <!-- <kendo-grid-column field="compensation['baseSalary']" title="Base Salary" [width]="300">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ (dataItem.compensation?.baseSalary | currency:dataItem.compensation?.payRateCurrency?.id : true : '1.2-2') }}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="compensation['baseSalaryFteAdjusted']" title="Base Salary FTE Adjusted" [width]="300">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ (dataItem.compensation?.baseSalaryFteAdjusted | currency:dataItem.compensation?.payRateCurrency?.id : true : '1.2-2') }}
            </ng-template>
        </kendo-grid-column> -->

        <!-- <kendo-grid-column field="workLocations" title="Work Locations" [width]="300">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="grid-cell-vertical array-column">
                    <span *ngFor="let workLocation of dataItem.workLocations" class="array-item">
                        <ng-container *ngIf="workLocation && workLocation.name">
                            {{workLocation.name}}
                        </ng-container>
                    </span>
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="positions" title="Positions" [width]="300">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="grid-cell-vertical array-column">
                    <span *ngFor="let position of dataItem.positions" class="array-item">
                        <ng-container *ngIf="position && position.name">
                            {{position.name}}
                        </ng-container>
                    </span>
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="managers" title="Managers" [width]="300">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="grid-cell-vertical array-column">
                    <span *ngFor="let manager of dataItem.managers" class="array-item">
                        <ng-container *ngIf="manager && manager.firstName">
                            {{manager.firstName}} {{manager.lastName}}
                        </ng-container>
                    </span>
                </div>
            </ng-template>
        </kendo-grid-column> -->

        <kendo-grid-excel
            fileName="BasicHeadcountReport.xlsx"
            [fetchData]="allData"
        ></kendo-grid-excel>
<!--
        <ng-template kendoPagerTemplate>
            <kendo-pager-page-sizes
            [pageSizes]="pageSizes"
            ></kendo-pager-page-sizes>
        </ng-template> -->
    </kendo-grid>
</mat-card>

