import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@app/core/services/environment.service';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { Observable, of } from 'rxjs';
import { api_routes } from '@app/consts';
import { Review } from '../models/review.model';

const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getReviews(take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<Review>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEWS}`, {
      headers: headers,
      params: params
    });
  }

  getReview(id: string): Observable<Review>{
    // return of(
    //   {
    //     "id": "12341234",
    //     "status": {
    //       "id": "inProgress",
    //       "name": "In Progress"
    //     },
    //     "employee": {
    //       "id": "usr_ERUsb25WU3IITd",
    //       "firstName": "Aria",
    //       "lastName": "Chen"
    //     },
    //     "reviewPlan": {
    //       "id": "prp_fvuIrImK8QWTL8",
    //       "name": "Test",
    //       "description": "Test",
    //       "startDate": "2023-10-21T00:00:00",
    //       "endDate": "2023-10-21T00:00:00"
    //     },
    //     "reviewers": [
    //       {
    //         "id": "usr_Q58GDL7caiRl0A",
    //         "firstName": "(: DO NOT CHANGE",
    //         "lastName": "(: Assistants",
    //         "order": [
    //           {
    //             "order": 1,
    //             "status": {
    //               "id": "complete",
    //               "name": "Complete"
    //             },
    //             "reviewerType": {
    //               "id": "SpecifiedUser",
    //               "name": "Specified User"
    //             }
    //           }
    //         ]
    //       }
    //     ],
    //     "rating": {
    //       "id": "prp_0hXWwpu5Zjzkqu",
    //       "name": "test",
    //       "description": "test"
    //     },
    //     "comments": "These are review comments",
    //     "goalPlans": [
    //       {
    //         "id": "pgp_63TJ9K1PQom0QO",
    //         "name": "Test",
    //         "goals": [
    //           {
    //             "id": "gol_1AKMpsZbtgHPHU",
    //             "description": "test",
    //             "percentageComplete": 0.3,
    //             "expectedCompletionDate": "2023-10-01T00:00:00",
    //             "actualCompletionDate": "2023-10-04T00:00:00",
    //             "goalType": {
    //               "id": "pgt_YRRqw7nkcrCyO0",
    //               "name": "Department Littlebug"
    //             },
    //             "parent": null,
    //             "weight": 0.7,
    //             "reviews": [
    //               {
    //                 "id": "34543264345",
    //                 "reviewedBy": {
    //                   "id": "usr_Q58GDL7caiRl0A",
    //                   "firstName": "(: DO NOT CHANGE",
    //                   "lastName": "(: Assistants"
    //                 },
    //                 "comments": "review comment",
    //                 "rating": {
    //                   "id": "complete",
    //                   "name": "Complete"
    //                 },
    //                 "version": null
    //               }
    //             ]
    //           }
    //         ],
    //         "weight": 50
    //       }
    //     ]
    //   }
    // )

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEWS}/${id}`, {
      headers: headers
    });
  }

  updateReview(id: string, reviewSubmit: any): Observable<any>{
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEWS}/${id}`, reviewSubmit,{
      headers: headers
    });
  }

  deleteReview(id: string): Observable<any>{
    return of()
  }

  completeReview(id: string) {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEWS}/${id}/Complete`,{
      headers: headers
    });
  }

  revertReview(id: string) {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEWS}/${id}/Revert`,{
      headers: headers
    });
  }
  
}
