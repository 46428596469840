<h5 mat-dialog-title appLocalizationPopupDirective localizationCode="HistoryLogs">{{ 'HistoryLogs' | translate: {Default: "History Logs"} }}</h5>

<mat-dialog-content>
    <kendo-grid
            class="grid"
            [data]="gridDataResult"
            [loading]="isLoading"
            [style.maxHeight.vh]="85"
            [pageSize]="pageSize"
            [skip]="skip"
            [selectable]="false"
            [pageable]="{
                info: true,
                pageSizes: [5, 10, 20, 50, 100],
                previousNext: true
            }"
            (pageChange)="pageChange($event)"
            (excelExport)="onExcelExport($event)"
            [sortable]="true">

        <ng-template kendoGridToolbarTemplate>
            <button class="export-to-excel-button" kendoGridExcelCommand>
                <img
                    class="ms-icon"
                    src="assets/icons/excel-icon.svg"
                    style="width: 35px;
                            height: 35px;
                            vertical-align: middle;">
                    {{ 'ExportToExcel' | translate: {Default: "Export to Excel"} }}
            </button>

            <button mat-stroked-button setColor="primaryColour" color="primary" (click)="getHistory()" appLocalizationPopupDirective [localizationCode]="'Refresh'">
                <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="refresh icon">refresh</mat-icon>
                {{ 'Refresh' | translate: {Default: "Refresh"} }}
            </button>
        </ng-template>

        <kendo-grid-column
                field="version.createdOn"
                [width]="210">

            <ng-template kendoGridHeaderTemplate>
                <div appLocalizationPopupDirective localizationCode="HistoryLogs-CreatedOn">{{ 'HistoryLogs-CreatedOn' | translate: {Default: "Created On"} }}</div>
            </ng-template>

            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="grid-cell-vertical">
                    <span>
                        {{dataItem.version.createdOn | date:'medium' }}
                    </span>
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
                field="version.effectiveDate"
                [width]="200">

            <ng-template kendoGridHeaderTemplate>
                <div appLocalizationPopupDirective localizationCode="HistoryLogs-EffectiveDate">{{ 'HistoryLogs-EffectiveDate' | translate: {Default: "Effective Date"} }}</div>
            </ng-template>

            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="grid-cell-vertical">
                    <span>
                        {{dataItem.version.effectiveDate  | date:'mediumDate' }}
                    </span>
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
                field="version.createdBy.fullName"
                [width]="200">
            <ng-template kendoGridHeaderTemplate>
                <div appLocalizationPopupDirective localizationCode="HistoryLogs-CreatedBy">{{ 'HistoryLogs-CreatedBy' | translate: {Default: "Created By"} }}</div>
            </ng-template>

            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="grid-cell-vertical">
                    <span>
                        {{dataItem.version.createdBy.fullName }}
                    </span>
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
                field="version.changeReason.text"
                [width]="200">
            <ng-template kendoGridHeaderTemplate>
                <div appLocalizationPopupDirective localizationCode="HistoryLogs-ChangeReason">{{ 'HistoryLogs-ChangeReason' | translate: {Default: "Change Reason"} }}</div>
            </ng-template>    

            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="grid-cell-vertical">
                    <span>
                        {{dataItem.version?.changeReason?.text }}
                    </span>
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
                field="version.comments"
                [width]="200">
            <ng-template kendoGridHeaderTemplate>
                <div appLocalizationPopupDirective localizationCode="HistoryLogs-ChangeComments">{{ 'HistoryLogs-ChangeComments' | translate: {Default: "Change Comments"} }}</div>
            </ng-template>  

            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="grid-cell-vertical">
                    <span>
                        {{dataItem.version?.comments }}
                    </span>
                </div>
            </ng-template>
        </kendo-grid-column>


        <ng-container
                *ngFor="let col of columns"
        >
            <!-- Normal Column Data -->
            <kendo-grid-column
                    *ngIf="!col.type || col.type === 'navigation'"
                    [field]="col.field"
                    [title]="col.title"
                    [width]="200">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        <ng-container *ngIf="col.subField && dataItem[col.field]; else noSubField">
                            <span [className]="wasModified(col.field, dataItem) ? 'highlight' : ''">{{dataItem[col.field][col.subField]}}</span>
                        </ng-container>
                        <ng-template #noSubField>
                            <span [className]="wasModified(col.field, dataItem) ? 'highlight' : ''">{{dataItem[col.field]}}</span>
                        </ng-template>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <!-- Date Column Data -->
            <kendo-grid-column
                    *ngIf="col.type === 'date'"
                    [field]="col.field"
                    [title]="col.title"
                    [width]="200">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        <span [className]="wasModified(col.field, dataItem) ? 'highlight' : ''">
                            {{dataItem[col.field] | date:'mediumDate' }}
                        </span>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <!-- costCenter column -->
            <kendo-grid-column
                    *ngIf="col.type === 'costCenter'"
                    [field]="col.field"
                    [title]="col.title"
                    [width]="200">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        <span [className]="wasModified(col.field, dataItem) ? 'highlight array' : 'array'">
                            <span *ngFor="let costCenter of dataItem[col.field]" >
                                {{costCenter.costCenter.name }} ({{costCenter.percentAllocated }})
                            </span>
                        </span>
                    </div>
                </ng-template>
            </kendo-grid-column>
        </ng-container>

        <kendo-grid-excel
            fileName="ChangeHistory.xlsx"
            [fetchData]="allData"
        ></kendo-grid-excel>

        <kendo-grid-messages 
            [noRecords]="isLoading ? '' : translate.instant('Grid-NoRecordsAvailable')"
            [pagerItemsPerPage]="translate.instant('Grid-Pager-ItemsPerPage')" 
            [pagerItems]="translate.instant('Grid-Pager-Items')"
            [pagerOf]="translate.instant('Grid-Pager-Of')"
        ></kendo-grid-messages>
    </kendo-grid>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
</mat-dialog-actions>
