import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { routes } from '@app/consts';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { finalize } from 'rxjs/operators';
import { Letter } from '../../models/leter.model';
import { LetterService } from '../../services/letter.service';

@Component({
  selector: 'app-created-letters-list',
  templateUrl: './created-letters-list.component.html',
  styleUrls: ['./created-letters-list.component.scss']
})
export class CreatedLettersListComponent implements OnInit {
  public routes: typeof routes = routes;
  tableColumns= [
    { name: 'Name', dataKey: 'name', isSortable: false, position: 'left' },
    { name: 'Description', dataKey: 'description', isSortable: false, position: 'left' }
  ];
  isLoading: boolean = true;
  pageSize: number = 5;
  page: string = '1';
  pagination: Object;
  letters: Letter[] = [];

  constructor(
    private letterService: LetterService,
    private dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getLetters();
  }

  getLetters() {
    this.isLoading = true;

    this.letterService.getLetters()
    .pipe(
      finalize( () => {
        this.isLoading = false;
      })
    )
    .subscribe( 
      res => {
        this.letters = res;
      }
    );
  }

  rowEvent(event: any) {
    if (event.icon === 'create') {
      this.openEditLetter(event.row.id);
    }
    else if (event.icon === 'delete') {
      this.openConfirmDeleteDialog(event.row.id);
    }
  }

  openEditLetter(letterId: string){
    this.router.navigateByUrl(`Letters/Editor/${letterId}`);
  }

  openConfirmDeleteDialog(letterId: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
        text: 'Are you sure you want to delete this?'
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.delete(letterId);
        }
      }
    ); 
  }

  delete(letterId: string){

  }

}

