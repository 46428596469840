import {Component, Input, OnInit} from '@angular/core';
import {LookupService} from '@app/modules/lookup/services/lookup.service';
import {TimeOff} from '@app/modules/lookup/models/time-off.model';
import {ToilPolicy} from '@app/modules/lookup/models/toil-policy.model';
import {SnackbarService} from '@app/core/services/snackbar.service';
import {UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {RefreshListener} from '@app/shared/models/refresh-listener';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import {CultureService} from '@app/core/services/system-language/culture.service';
import {Culture} from '@app/shared/models/system-language/culture.model';
import {OverlayService} from '@app/shared/components/overlay/overlay.service';
import {finalize} from 'rxjs/operators';
import {TimeOffInLieuService} from '@app/core/services/time-off-in-lieu/time-off-in-lieu.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'edit-toil',
    templateUrl: './edit-toil.component.html',
    styleUrls: ['./edit-toil.component.scss']
})
export class EditToilComponent implements OnInit {

    private _timeOffTypeId: string;
    @Input() set timeOffTypeId(value: string) {
        this._timeOffTypeId = value;
        this.getTimeOffTypeById(this._timeOffTypeId);
    }

    get timeOffTypeId(): string {
        return this._timeOffTypeId;
    }
    @Input() refreshListener: RefreshListener;

    public form: UntypedFormGroup;
    public timeOffType: TimeOff;
    public timeOffPolicyList: ToilPolicy[];
    filePath: SafeUrl;
    isLoading: boolean = true;
    cultures: Culture[];

    constructor(
        private translate: TranslateService,
        private fb: UntypedFormBuilder,
        private dialog: MatDialog,
        private timeOffInLieuService: TimeOffInLieuService,
        private snackbarService: SnackbarService,
        private cultureService: CultureService,
        private overlayService: OverlayService,
        private domSanitizer: DomSanitizer
    ) {
    }

    ngOnInit(): void {
        this.cultureService.getCultures().subscribe(res => {
            this.cultures = res;
        });
        this.getTimeOffTypeById(this.timeOffTypeId);
    }

    get localizations() {
        return this.form.controls['localizations'] as UntypedFormArray;
    }

    deleteLocalization(index: number) {
        this.localizations.removeAt(index);
    }

    //Create a textLocalization form group object to add to the localizations form array
    addNewLocalization(culture?: string, localization?: string) {
        const localizationForm = this.fb.group({
            culture: [culture || '', Validators.required],
            text: [localization || '', Validators.required]
        });

        this.localizations.push(localizationForm);
    }

    private getTimeOffTypeById(id) {
        this.isLoading = true;
        this.timeOffInLieuService.getToilTypeById(id).subscribe(
            timeOffType => {
                this.timeOffType = timeOffType;
                this.getIcon();
                this.createForm();
                this.getTimeOffPolicies(id);
            },
            err => {
                console.log(err);
            }
        );
    }

    getIcon(){
        this.timeOffInLieuService.getToilTypeIcon(this.timeOffType.id)
        .pipe(
            finalize( () => this.filePath = this.timeOffType ? this.timeOffType.image : null )
        )
        .subscribe(
            res => {
                this.timeOffType.image = res;
            }
        )
    }

    private createForm() {
        this.form = this.fb.group({
            localizations: this.fb.array([]),
            image: null
        });
        console.log(this.timeOffType);
        this.timeOffType.name.forEach(
            localization => {
                this.addNewLocalization(localization.culture, localization.text);
            }
        );
    }

    private getTimeOffPolicies(id) {
        this.timeOffInLieuService.getToilPolicies(id).subscribe(
            res => {
                this.timeOffPolicyList = res.data;
                this.isLoading = false;
            }
        );
    }

    saveForm() {
        this.overlayService.show();
        let data = {
            'id': this.timeOffTypeId,
            'name': this.form.value.localizations,
            'disabled': false,
        };

        this.timeOffInLieuService.updateToilType(this.timeOffTypeId, data)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe((res) => {
                console.log(res);
                if (this.form.value.image) {
                    this.timeOffInLieuService.postToilTypeIcon(this.form.value.image, this.timeOffTypeId)
                        .subscribe(res => {
                            this.handleSuccess();
                        }, err => {
                            this.handleError(err);
                        })
                } else {
                     this.handleSuccess();
                }
            },
            err => {
                this.handleError(err);
            });
    }

    handleSuccess() {
        this.refreshListener.refresh();
        this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
    }

    handleError(err) {
        console.log(err);
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
    }


    showDeleteTypeConfirmation() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            text: `Are you sure you want to delete this Time Off In Lieu Type?`
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data === true) {
                    this.deleteType();
                }
            }
        );
    }

    deleteType() {
        this.timeOffInLieuService.deleteToilTypeById(this.timeOffTypeId).subscribe(
            timeOffType => {
                this.refreshListener.refresh();
                this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
            },
            err => {
                console.log(err);
            }
        );
    }

    cancel() {
        this.refreshListener.cancel();
    }

    imagePreview(e) {
        const file = (e.target as HTMLInputElement).files[0];

        this.form.patchValue({
            image: file
        });

        this.form.get('image').updateValueAndValidity();

        const reader = new FileReader();
        reader.onload = () => {
            this.filePath = reader.result as string;
        };
        reader.readAsDataURL(file);
    }
}
