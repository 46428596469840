<app-data-grid-comp
[columns]="columns"
[bindingType]="bindingType"
[view]="view"
[gridData]="gridData"
[gridDataResult]="gridDataResult"
[isLoading]="isLoading"
[pageSize]="pageSize"
[skip]="skip"
category="StockPurchasePlans"
[isLoading]="isLoading"
(emitPageChangeEvent)="pageChange($event)"
(emitOpenDialogEvent)="openEmploymentRecordStockPurchasePlanDialog($event)"
(emitDeleteEvent)="deleteEmploymentRecordStockPurchasePlan($event)"
></app-data-grid-comp>