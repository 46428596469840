import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormElement } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { EmployeeContactCardService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/services/employee-contact-card.service';
import { SelectEmployeesDialogComponent } from '@app/shared/components/select-employees-dialog/select-employees-dialog.component';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-employee-form-field',
  templateUrl: './employee-form-field.component.html',
  styleUrls: ['./employee-form-field.component.scss']
})
export class EmployeeFormFieldComponent implements OnInit {
  @Input() formElement: FormElement;
  @Input() parentGroup: UntypedFormGroup;
  @Input() readOnly: boolean;

  @ViewChild('employeeSelect') employeeSelect;

  isLoading: boolean = false;
  selectEmployeesDialogRef: any;

  employeeOptions: {
    id: string
    firstName: string
    lastName: string
  }[] = []

  constructor(
    private dialog: MatDialog,
    private ngZone: NgZone,
    private employeeContactCardService: EmployeeContactCardService
  ) { }

  ngOnInit(): void {
    if(this.parentGroup.controls[this.formElement.formControl].value !== null){
      this.getEmployeeDetails(this.parentGroup.controls[this.formElement.formControl].value);
    }
  }

  openSelectEmployeeDialog() {
    this.employeeSelect.close();

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      nonSelectableEmployees: [],
      rowSelectionSettings: {
        canSelectRows: true,
        selectableMode: 'single',
        selectRowBy: 'id'
      }
    };

    this.ngZone.runOutsideAngular(() => {
      this.selectEmployeesDialogRef = this.dialog.open(SelectEmployeesDialogComponent, dialogConfig);
    });

    const sub = this.selectEmployeesDialogRef.componentInstance.emitSelectedEmployees.subscribe((employeeId: [string]) => {
      this.selectEmployeesDialogRef.close();
      this.setValue(employeeId[0])
    });
  }

  getEmployeeDetails(employeeId) {
    this.isLoading = true;

    this.employeeContactCardService.getEmployeeContactCard(employeeId)
    .pipe(
      finalize(()=>{ 
        this.isLoading = false;
      })
    )
    .subscribe(
      res => {
        this.employeeOptions = [
          {
            id: res.id,
            firstName: res.firstname,
            lastName: res.lastname
          }
        ]
      }
    )
  }

  clearSelectedEmployee() {
    this.employeeSelect.close();
    this.parentGroup.controls[this.formElement.formControl].setValue(null);
  }

  setValue(employeeId: string) {
    this.parentGroup.controls[this.formElement.formControl].setValue(employeeId);
    this.getEmployeeDetails(employeeId)
  }

}
