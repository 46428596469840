import { Component, Inject, OnInit } from '@angular/core';
import { Form, FormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { db_tables } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TimeOffInLieuService } from '@app/core/services/time-off-in-lieu/time-off-in-lieu.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { EmployeeTableFieldSecurity, EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { EmployeeToilPolicy, EmployeeToilRecord, EmployeeToilRecordVerbose, ToilClassType } from '@app/shared/models/time-off-in-lieu/time-off-in-lieu.model';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { finalize } from 'rxjs';
import { EmployeeSecurityService } from '../../../../../employee-security/services/employee-security.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import { SecuritySetupService } from '@app/modules/security-setup/services/security-setup.service';
import { TableField } from '@app/modules/security-setup/models/table-field.model';
import { ChangeReasonDialogComponent } from '@app/shared/components/change-reason-dialog/change-reason-dialog.component';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';

@Component({
  selector: 'app-employee-toil-record-form-dialog',
  templateUrl: './employee-toil-record-form-dialog.component.html',
  styleUrls: ['./employee-toil-record-form-dialog.component.scss']
})
export class EmployeeToilRecordFormDialogComponent implements OnInit {
  formId: string = 'frm_zGoKX06xIAFT2D';
  changeReasonFormId: string = 'frm_0Z0uXmQSOJhLH1';
  employeeToilRecord: EmployeeToilRecord;
  employeeToilRecordVerbose: EmployeeToilRecordVerbose;
  employeeId: string;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];
  isLoading: boolean;
  public db_tables = db_tables;
  currentCulture: Culture;
  formData: any;
  getFormData: boolean = false;
  formValid: boolean = false;
  loadingFormDetails: boolean = true;
  formPristine: boolean = true;
  formDetails: Form;
  employeeToilPolicies: EmployeeToilPolicy[] = [];
  toilClassTypes: ToilClassType[] = [];
  extraFieldsForm: any;
  loadingDbTableFields: boolean;
  dbTableFields: TableField[];
  formElements = [
    {
      formControl: 'toilPolicyId',
      text: 'Toil Policy',
      tableField: {
        id: 'tfi_ToilRecordToilPolicy'
      }
    },
    {
      formControl: 'toilClassTypeId',
      text: 'Toil Class Type',
      tableField: {
        id: 'tfi_ToilRecordToilClassType'
      }
    },
  ]
  fieldPermissions: EmployeeTableFieldSecurity[];

  constructor(
    private fb: FormBuilder,
    private timeOffInLieuService: TimeOffInLieuService,
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private dialogRef: MatDialogRef<EmployeeToilRecordFormDialogComponent>,
    private securitySetupService: SecuritySetupService,
    private tablePermissionsService: TablePermissionsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data
  ){
    this.employeeToilRecord = data.employeeToilRecord,
    this.employeeId = data.employeeId,
    this.targetEmployeeTablePermissions = data.targetEmployeeTablePermissions
    this.fieldPermissions = this.tablePermissionsService.getTableFieldsPermissions(this.db_tables.TOIL, this.targetEmployeeTablePermissions);
  }

  ngOnInit(): void {
    this.getDbTableFields();
    this.getEmployeeToilPolicies(0, 100);
    this.getToilClassTypes(0, 100);

    if(this.employeeToilRecord){
      this.getEmployeeToilRecord(this.employeeToilRecord);
    }
    else {
      this.buildFormData();
    }
  }

  get tableUpdateAccessObj() {
    return {
      tableId: this.db_tables.TOIL,
      permission: 'update',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  getDbTableFields() {
    this.loadingDbTableFields = true;

    this.securitySetupService.getFields(this.db_tables.TOIL, 0, '1000')
    .pipe(
      finalize(()=>{
        this.loadingDbTableFields = false;
      })
    )
    .subscribe(
      res => {
        this.dbTableFields = res.data;
        this.addDBTableValues();
      }
    );
  }

  addDBTableValues() {
    this.dbTableFields.forEach(
      dbTableField => {
        let formElIndex = this.formElements.findIndex( el => el.tableField?.id === dbTableField?.id)

        if(formElIndex !== -1){
          this.formElements[formElIndex] = this.useDbTableFieldValues(this.formElements[formElIndex], dbTableField)
        }
      }
    )

  }

  useDbTableFieldValues(formElement: any, dbTableField: TableField) {
    // set field name
    // let currentCultureFieldName: Localization[] = dbTableField.name.filter( n => n.culture === this.currentCulture.id );
    // formElement.text = currentCultureFieldName[0].text;
    formElement.text = dbTableField.name;

    // set field hidden value
    formElement.hidden = dbTableField.enabled ? 'false' : 'true';

    // set required value
    formElement.requiredField = dbTableField.requiredField ? 'true' : 'false';

    // set min length value
    // formElement.minLength = dbTableField.minimumValue;

    // set max length value
    // formElement.maxLength = dbTableField.maximumValue;

    // set the placeholder
    formElement.placeholder = dbTableField.defaultValue;

    return formElement
  }

  getEmployeeToilPolicies(skip: number, take: number){
    this.timeOffInLieuService.getEmployeeToilPolicies(this.employeeId, take.toString(), skip).subscribe(
      pagedData => {
        this.employeeToilPolicies.push(...pagedData.data);

        if(take < pagedData.total){
          this.getEmployeeToilPolicies(skip+100, take+100);
        }
      });
  }

  getToilClassTypes(skip: number, take: number){
    this.timeOffInLieuService.getToilClassTypes(take.toString(), skip).subscribe(
      pagedData => {
        this.toilClassTypes.push(...pagedData.data);

        if(take < pagedData.total){
          this.getToilClassTypes(skip+100, take+100);
        }
      });
  }

  getEmployeeToilRecord(employeeToilRecord?: EmployeeToilRecord) {
    this.isLoading = true;

    this.timeOffInLieuService.getEmployeeToilRecord(employeeToilRecord.id)
    .pipe(
      finalize(() => this.isLoading = false)
    )
    .subscribe(
      res => {
        this.employeeToilRecordVerbose = res;
        this.buildFormData();
      }
    );
  }

  buildFormData() {
    this.extraFieldsForm = this.fb.group({
      toilPolicyId: new UntypedFormControl(
        {
          value: (this.employeeToilRecordVerbose?.toilPolicy?.id ?? null),
          disabled: (this.getFieldDisabledValue('tfi_ToilRecordToilPolicy'))//converting string to boolean
        }, 
        Validators.required
      ),
      toilClassTypeId: new UntypedFormControl(
        {
          value: (this.employeeToilRecordVerbose?.toilClassType?.id ?? null),
          disabled: (this.getFieldDisabledValue('tfi_ToilRecordToilClassType'))//converting string to boolean
        }, 
        Validators.required
      ),
    });

    this.formData = {
      id: this.employeeToilRecordVerbose?.id ?? null,
      asOf: moment().format(),
      changeReason: '',
      changeReasonComments: '',
      startDate: this.employeeToilRecordVerbose?.startDate ?? null,
      endDate: this.employeeToilRecordVerbose?.endDate ?? null,
      startTime: this.employeeToilRecordVerbose?.startTime ?? null,
      endTime: this.employeeToilRecordVerbose?.endTime ?? null,
      hours: this.employeeToilRecordVerbose?.hours ?? null,
      comments: this.employeeToilRecordVerbose?.comments ?? null,
      halfDay: this.employeeToilRecordVerbose?.halfDay ?? false,
      employeeId: this.employeeId,
    }
  }

  getFieldDisabledValue(fieldId){
    let fieldPermission: EmployeeTableFieldSecurity = this.fieldPermissions?.find( filedPermission => filedPermission.field.id === fieldId );

    if(fieldPermission){
      if(fieldPermission.update) {
        return false;
      }
      else if(fieldPermission.read) {
        return true;
      }
      else if(fieldPermission.deny) {
        return true;
      }
    } else {
      return false;
    }
  }

  formDataEmitted(formData) {
    this.openChangeReasonDialog(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  formPristineEmitted(formPristine: boolean) {
    this.formPristine = formPristine;
  }

  openChangeReasonDialog(formData: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formId: this.changeReasonFormId,
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions,
    };

    const dialogRef = this.dialog.open(ChangeReasonDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.save({...formData, ...data});
        }
      }
    );
  }

  save(formData: any){
    this.overlayService.show();

    formData.employeeId = this.employeeId;

    formData = {...formData, ...this.extraFieldsForm.getRawValue()}

    // formData.toilPolicyId = 'tolp_m4HrxaZmbK3Yxs';

    if(formData.id === null){
      this.timeOffInLieuService.createEmployeeToilRecord(formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
        }
      );
    }
    else {
      this.timeOffInLieuService.updateEmployeeToilRecord(formData.id, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
        }
      );
    }
  }

  close() {
    this.dialogRef.close();
  }

}
