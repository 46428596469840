<mat-card>
    <mat-card-title class="header">
        <h5 appLocalizationPopupDirective localizationCode="MyCompensation">{{ 'MyCompensation' | translate: {Default: "My Compensation"} }}</h5>
    </mat-card-title>
    <mat-card-content>
            <div class="left">
                <div class="top">
                    <div class="effective-date">Data Effective January 30, 2021</div>
                    <div class="blurb">
                        <!-- <div class="blub-header">Compensation: Your Pay Today</div> -->
                        <div class="blurb-details">Your compensation profile is comprised of your salary, variable incentive compensation, company stock options and other employer paid benefits that you have received in 2020.  Your compensation is based on your job, function and level, and it is our investment in your for all you do to contribute to making our company successful.</div>
                    </div>
                </div>
                <app-table
                    [tableData]="tableData"
                    [tableColumns]="tableColumns"
                    [isFilterable]="false"
                    [isPageable]="false"
                ></app-table>
            </div>
    
            <div id="chart">
                <apx-chart
                  [series]="chartOptions.series"
                  [chart]="chartOptions.chart"
                  [labels]="chartOptions.labels"
                  [responsive]="chartOptions.responsive"
                ></apx-chart>
            </div>
    </mat-card-content>
</mat-card>