import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { api_routes, db_tables } from '@app/consts';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { GoalDialogComponent } from '@app/modules/performance/components/goal-dialog/goal-dialog.component';
import { AllGoalsListItem, EmployeeGoal, EmployeeGoalSubmit, Goal } from '@app/modules/performance/components/goal-plans/models/goal-plan.model';
import { GoalPlanService } from '@app/modules/performance/components/goal-plans/services/goal-plan.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { defer, finalize, forkJoin } from 'rxjs';

@Component({
  selector: 'app-select-goal-plan-dialog',
  templateUrl: './select-goal-plan-dialog.component.html',
  styleUrls: ['./select-goal-plan-dialog.component.scss']
})
export class SelectGoalPlanDialogComponent implements OnInit {
  public columns: any[] = [
    { field: "name", tableId: "tfi_PGpName", title: "Name" },
    { field: "description", tableId: "tfi_PGpDescription", title: "Description" },
    { field: "permitEmployeeGoalEntry", tableId: "tfi_PGpPermitEmployeeGoalEntry", title: this.translate.instant("GoalPlan-PermitEmployeeGoalEntry"), type: 'booleanToggle' },
    { field: "permitEmployeeChildGoalEntry", tableId: "tfi_PGpPermitEmployeeChildGoalEntry", title: this.translate.instant("GoalPlan-PermitEmployeeChildGoalEntry"), type: 'booleanToggle' },
    { field: "lockGoals", tableId: "tfi_PGpLockGoals", title: this.translate.instant("GoalPlan-LockGoals"), type: 'booleanToggle' },
    { field: "createdBy", tableId: "", title: this.translate.instant("GoalPlan-CreatedBy"), type: 'employee' },
    { field: "createdOn", tableId: "", title: this.translate.instant("GoalPlan-CreatedOn"), type: 'date' },
    { field: "lastPublishedOn", tableId: "", title: this.translate.instant("GoalPlan-LastPublishedOn"), type: 'dateTime' },
    { field: "status", subField: "name", tableId: "tfi_PGpStatus", title: "Status", type: 'chip', chipValue: ['Active', 'Archived'] },
  ];

  filterCategories: any[] = [
    { field: "name", tableId: "tfi_PGpName", title: "Name", type: "String", dataType: 'String'},
    { field: "description", tableId: "tfi_PGpDescription", title: "Description", type: "String", dataType: 'String'},
    { field: "lockGoals", tableId: "tfi_PGpLockGoals", title: this.translate.instant("GoalPlan-LockGoals"), dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
    { field: "permitEmployeeGoalEntry", tableId: "tfi_PGpPermitEmployeeGoalEntry", title: this.translate.instant("GoalPlan-PermitEmployeeGoalEntry"), dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
    { field: "permitEmployeeChildGoalEntry", tableId: "tfi_PGpPermitEmployeeChildGoalEntry", title: this.translate.instant("GoalPlan-PermitEmployeeGoalEntry"), dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
    { field: "createdBy", tableId: "", title: this.translate.instant("GoalPlan-CreatedBy"), type: "String", dataType: 'String', filterValue: ["CreatedBy.FirstName", "CreatedBy.LastName"]},
    { field: "createdOn", tableId: "", title: this.translate.instant("GoalPlan-CreatedOn"), type: "date", dataType: 'Date'},
    { field: "lastPublishedOn", tableId: "", title: this.translate.instant("GoalPlan-LastPublishedOn"), type: "date", dataType: 'Date'},
    { field: "status.name", subField: "name", tableId: "tfi_PGpStatus", title: "Status", dataType: "SpecialLookup", lookupCode: 'GOAL_TYPE_STATUS'},
  ];

  sortableColumns: any[] = [
    { field: "name", sortValue: "name"},
    { field: "description", sortValue: "description" },
    { field: "permitEmployeeGoalEntry", sortValue: "permitEmployeeGoalEntry" },
    { field: "permitEmployeeChildGoalEntry", sortValue: "permitEmployeeChildGoalEntry" },
    { field: "lockGoals", sortValue: "lockGoals" },
    { field: "createdBy", sortValue: ["Version.CreatedBy.FirstName", "Version.CreatedBy.LastName"] },
    { field: "createdOn", sortValue: "Version.CreatedOn" },
    { field: "lastPublishedOn", sortValue: "lastPublishedOn" },
    { field: "status", sortValue: "Status.Name" },
  ];

  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 5;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  formValid: any;
  user$: any;
  selectedGoal: Goal

  getGoalsRequest: any;
  filterString: string;
  searchFilterString: string;
  searchValue: string = "";
  sortString: string;
  getGoalsPlansRequest: any;

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private oidcAuthService: OidcAuthService,
    private dialogRef: MatDialogRef<SelectGoalPlanDialogComponent>,
    private goalPlanService: GoalPlanService,
  ) {
    this.user$ = this.oidcAuthService.userProfile;
  }

  ngOnInit(): void {
    this.view = this.getGoalPlans();
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;

    let variants = this.searchValue.split(' ').filter(value => value);
    this.searchFilterString = '';
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `(name like "${variant}")`;
        }
    });

    this.getGoalsPlansRequest.unsubscribe();
    this.getGoalPlans();
  }

  getGoalPlans() {
    this.isLoading = true;

    let filter;
    if(this.searchFilterString) {
      filter = this.searchFilterString;
    }
    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }

    this.getGoalsPlansRequest = this.goalPlanService.getGoalPlans(String(this.pageSize), this.skip, this.sortString, filter)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          }
          this.gridData = res.data;
        }
      );
  }

  getGoalVerbose(goal?: AllGoalsListItem) {
    // if(goal === undefined){
    //   this.openGoalDialog();
    // }
    // else {
    //   let employeeGoal: EmployeeGoal;

    //   this.isLoading = true;

    //   this.goalService.getEmployeeGoal(goal.employee?.id, goal.id)
    //   .pipe(
    //     finalize(() => this.isLoading = false)
    //   )
    //   .subscribe(
    //     res => {
    //       employeeGoal = res;
    //       employeeGoal.employeeId = goal.employee?.id;
    //       this.openGoalDialog(employeeGoal);
    //     }
    //   );
    // }
  }

  openGoalDialog(goal?: EmployeeGoal) {
    // const dialogConfig = new MatDialogConfig();

    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;

    // dialogConfig.data = {
    //   goal: goal,
    //   employeeId: goal.employeeId,
    //   readOnly: true
    // };

    // this.dialog.open(GoalDialogComponent, dialogConfig)
    // .afterClosed().subscribe(
    //   data => {
    //     if (data === true) {
    //       this.getGoals();
    //     }
    //   }
    // );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.view = this.getGoalPlans();
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;;
    this.getGoalPlans();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getGoalPlans();
  }

  save() {
    this.dialogRef.close(this.selectedGoal);
  }

  close() {
    this.dialogRef.close();
  }

  selectedRowsChanged(selectionEvent: SelectionEvent){
    if(selectionEvent?.selectedRows[0]?.dataItem){
      this.selectedGoal = selectionEvent.selectedRows[0].dataItem;
    }
    else {
      this.selectedGoal = null;
    }
  }
}

