import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'currencyDisplay'})
export class CurrencyDisplayPipe implements PipeTransform {


    public transform(currency: string, amount: number): any {
        if (currency == null) {
            return "";
        }
        let displayCurrency = this.currencies.find(c =>
            c.currency.toUpperCase() === currency.toUpperCase()
        ).symbol;

        let displayAmount = Number(amount.toFixed(2)).toLocaleString('en', {
            minimumFractionDigits: 2
        });

        return `${displayCurrency}${displayAmount} (${currency})`;
    }


    private currencies = [
        {
            "currency": "AED",
            "symbol": "د.إ",
            "code": 784,
            "name": "UAE Dirham",
            "country": "UAE"
        },
        {
            "currency": "AFN",
            "symbol": "Af",
            "code": 971,
            "name": "Afghani",
            "country": "Afghanistan"
        },
        {
            "currency": "ALL",
            "symbol": "L",
            "code": 8,
            "name": "Lek",
            "country": "Albania"
        },
        {
            "currency": "AMD",
            "symbol": "Դ",
            "code": 51,
            "name": "Armenian Dram",
            "country": "Armenia"
        },
        {
            "currency": "AOA",
            "symbol": "Kz",
            "code": 973,
            "name": "Kwanza",
            "country": "Angola"
        },
        {
            "currency": "ARS",
            "symbol": "$",
            "code": 32,
            "name": "Argentine Peso",
            "country": "Argentina"
        },
        {
            "currency": "AUD",
            "symbol": "$",
            "code": 36,
            "name": "Australian Dollar",
            "country": "Australia Kiribati Coconut Islands Nauru Tuvalu"
        },
        {
            "currency": "AWG",
            "symbol": "ƒ",
            "code": 533,
            "name": "Aruban Guilder/Florin",
            "country": "Aruba"
        },
        {
            "currency": "AZN",
            "symbol": "ман",
            "code": 944,
            "name": "Azerbaijanian Manat",
            "country": "Azerbaijan"
        },
        {
            "currency": "BAM",
            "symbol": "КМ",
            "code": 977,
            "name": "Konvertibilna Marka",
            "country": "Bosnia and Herzegovina"
        },
        {
            "currency": "BBD",
            "symbol": "$",
            "code": 52,
            "name": "Barbados Dollar",
            "country": "Barbados"
        },
        {
            "currency": "BDT",
            "symbol": "৳",
            "code": 50,
            "name": "Taka",
            "country": "Bangladesh"
        },
        {
            "currency": "BGN",
            "symbol": "лв",
            "code": 975,
            "name": "Bulgarian Lev",
            "country": "Bulgaria"
        },
        {
            "currency": "BHD",
            "symbol": "ب.د",
            "code": 48,
            "name": "Bahraini Dinar",
            "country": "Bahrain"
        },
        {
            "currency": "BIF",
            "symbol": "₣",
            "code": 108,
            "name": "Burundi Franc",
            "country": "Burundi"
        },
        {
            "currency": "BMD",
            "symbol": "$",
            "code": 60,
            "name": "Bermudian Dollar",
            "country": "Bermuda"
        },
        {
            "currency": "BND",
            "symbol": "$",
            "code": 96,
            "name": "Brunei Dollar",
            "country": "Brunei Singapore"
        },
        {
            "currency": "BOB",
            "symbol": "Bs.",
            "code": 68,
            "name": "Boliviano",
            "country": "Bolivia"
        },
        {
            "currency": "BRL",
            "symbol": "R$",
            "code": 986,
            "name": "Brazilian Real",
            "country": "Brazil"
        },
        {
            "currency": "BSD",
            "symbol": "$",
            "code": 44,
            "name": "Bahamian Dollar",
            "country": "Bahamas"
        },
        {
            "currency": "BTN",
            "symbol": "",
            "code": 64,
            "name": "Ngultrum",
            "country": "Bhutan"
        },
        {
            "currency": "BWP",
            "symbol": "P",
            "code": 72,
            "name": "Pula",
            "country": "Botswana"
        },
        {
            "currency": "BYN",
            "symbol": "Br",
            "code": 933,
            "name": "Belarusian Ruble",
            "country": "Belarus"
        },
        {
            "currency": "BZD",
            "symbol": "$",
            "code": 84,
            "name": "Belize Dollar",
            "country": "Belize"
        },
        {
            "currency": "CAD",
            "symbol": "CA$",
            "code": 124,
            "name": "Canadian Dollar",
            "country": "Canada"
        },
        {
            "currency": "CDF",
            "symbol": "₣",
            "code": 976,
            "name": "Congolese Franc",
            "country": "Congo (Kinshasa)"
        },
        {
            "currency": "CHF",
            "symbol": "₣",
            "code": 756,
            "name": "Swiss Franc",
            "country": "Lichtenstein Switzerland"
        },
        {
            "currency": "CLP",
            "symbol": "$",
            "code": 152,
            "name": "Chilean Peso",
            "country": "Chile"
        },
        {
            "currency": "CNY",
            "symbol": "¥",
            "code": 156,
            "name": "Yuan",
            "country": "China"
        },
        {
            "currency": "COP",
            "symbol": "$",
            "code": 170,
            "name": "Colombian Peso",
            "country": "Colombia"
        },
        {
            "currency": "CRC",
            "symbol": "₡",
            "code": 188,
            "name": "Costa Rican Colon",
            "country": "Costa Rica"
        },
        {
            "currency": "CUP",
            "symbol": "$",
            "code": 192,
            "name": "Cuban Peso",
            "country": "Cuba"
        },
        {
            "currency": "CVE",
            "symbol": "$",
            "code": 132,
            "name": "Cape Verde Escudo",
            "country": "Cape Verde"
        },
        {
            "currency": "CZK",
            "symbol": "Kč",
            "code": 203,
            "name": "Czech Koruna",
            "country": "Czech Republic"
        },
        {
            "currency": "DJF",
            "symbol": "₣",
            "code": 262,
            "name": "Djibouti Franc",
            "country": "Djibouti"
        },
        {
            "currency": "DKK",
            "symbol": "kr",
            "code": 208,
            "name": "Danish Krone",
            "country": "Denmark"
        },
        {
            "currency": "DOP",
            "symbol": "$",
            "code": 214,
            "name": "Dominican Peso",
            "country": "Dominican Republic"
        },
        {
            "currency": "DZD",
            "symbol": "د.ج",
            "code": 12,
            "name": "Algerian Dinar",
            "country": "Algeria"
        },
        {
            "currency": "EGP",
            "symbol": "£",
            "code": 818,
            "name": "Egyptian Pound",
            "country": "Egypt"
        },
        {
            "currency": "ERN",
            "symbol": "Nfk",
            "code": 232,
            "name": "Nakfa",
            "country": "Eritrea"
        },
        {
            "currency": "ETB",
            "symbol": "",
            "code": 230,
            "name": "Ethiopian Birr",
            "country": "Ethiopia"
        },
        {
            "currency": "EUR",
            "symbol": "€",
            "code": 978,
            "name": "Euro",
            "country": "Akrotiri and Dhekelia Andorra Austria Belgium Cyprus Estonia Finland France Germany Greece Ireland Italy Kosovo Latvia Lithuania Luxembourg Malta Monaco Montenegro Netherlands Portugal San-Marino Slovakia Slovenia Spain Vatican"
        },
        {
            "currency": "FJD",
            "symbol": "$",
            "code": 242,
            "name": "Fiji Dollar",
            "country": "Fiji"
        },
        {
            "currency": "FKP",
            "symbol": "£",
            "code": 238,
            "name": "Falkland Islands Pound",
            "country": "Falkland Islands"
        },
        {
            "currency": "GBP",
            "symbol": "£",
            "code": 826,
            "name": "Pound Sterling",
            "country": "Alderney British Indian Ocean Territory Great Britain Isle of Maine"
        },
        {
            "currency": "GEL",
            "symbol": "ლ",
            "code": 981,
            "name": "Lari",
            "country": "Georgia South Ossetia"
        },
        {
            "currency": "GHS",
            "symbol": "₵",
            "code": 936,
            "name": "Cedi",
            "country": "Ghana"
        },
        {
            "currency": "GIP",
            "symbol": "£",
            "code": 292,
            "name": "Gibraltar Pound",
            "country": "Gibraltar"
        },
        {
            "currency": "GMD",
            "symbol": "D",
            "code": 270,
            "name": "Dalasi",
            "country": "Gambia"
        },
        {
            "currency": "GNF",
            "symbol": "₣",
            "code": 324,
            "name": "Guinea Franc",
            "country": "Guinea"
        },
        {
            "currency": "GTQ",
            "symbol": "Q",
            "code": 320,
            "name": "Quetzal",
            "country": "Guatemala"
        },
        {
            "currency": "GYD",
            "symbol": "$",
            "code": 328,
            "name": "Guyana Dollar",
            "country": "Guyana"
        },
        {
            "currency": "HKD",
            "symbol": "$",
            "code": 344,
            "name": "Hong Kong Dollar",
            "country": "Hong Kong"
        },
        {
            "currency": "HNL",
            "symbol": "L",
            "code": 340,
            "name": "Lempira",
            "country": "Honduras"
        },
        {
            "currency": "HRK",
            "symbol": "Kn",
            "code": 191,
            "name": "Croatian Kuna",
            "country": "Croatia"
        },
        {
            "currency": "HTG",
            "symbol": "G",
            "code": 332,
            "name": "Gourde",
            "country": "Haiti"
        },
        {
            "currency": "HUF",
            "symbol": "Ft",
            "code": 348,
            "name": "Forint",
            "country": "Hungary"
        },
        {
            "currency": "IDR",
            "symbol": "Rp",
            "code": 360,
            "name": "Rupiah",
            "country": "Indonesia"
        },
        {
            "currency": "ILS",
            "symbol": "₪",
            "code": 376,
            "name": "New Israeli Shekel",
            "country": "Israel Palestine"
        },
        {
            "currency": "INR",
            "symbol": "₹",
            "code": 356,
            "name": "Indian Rupee",
            "country": "Bhutan India"
        },
        {
            "currency": "IQD",
            "symbol": "ع.د",
            "code": 368,
            "name": "Iraqi Dinar",
            "country": "Iraq"
        },
        {
            "currency": "IRR",
            "symbol": "﷼",
            "code": 364,
            "name": "Iranian Rial",
            "country": "Iran"
        },
        {
            "currency": "ISK",
            "symbol": "Kr",
            "code": 352,
            "name": "Iceland Krona",
            "country": "Iceland"
        },
        {
            "currency": "JMD",
            "symbol": "$",
            "code": 388,
            "name": "Jamaican Dollar",
            "country": "Jamaica"
        },
        {
            "currency": "JOD",
            "symbol": "د.ا",
            "code": 400,
            "name": "Jordanian Dinar",
            "country": "Jordan"
        },
        {
            "currency": "JPY",
            "symbol": "¥",
            "code": 392,
            "name": "Yen",
            "country": "Japan"
        },
        {
            "currency": "KES",
            "symbol": "Sh",
            "code": 404,
            "name": "Kenyan Shilling",
            "country": "Kenya"
        },
        {
            "currency": "KGS",
            "symbol": "",
            "code": 417,
            "name": "Som",
            "country": "Kyrgyzstan"
        },
        {
            "currency": "KHR",
            "symbol": "៛",
            "code": 116,
            "name": "Riel",
            "country": "Cambodia"
        },
        {
            "currency": "KPW",
            "symbol": "₩",
            "code": 408,
            "name": "North Korean Won",
            "country": "North Korea"
        },
        {
            "currency": "KRW",
            "symbol": "₩",
            "code": 410,
            "name": "South Korean Won",
            "country": "South Korea"
        },
        {
            "currency": "KWD",
            "symbol": "د.ك",
            "code": 414,
            "name": "Kuwaiti Dinar",
            "country": "Kuwait"
        },
        {
            "currency": "KYD",
            "symbol": "$",
            "code": 136,
            "name": "Cayman Islands Dollar",
            "country": "Cayman Islands"
        },
        {
            "currency": "KZT",
            "symbol": "〒",
            "code": 398,
            "name": "Tenge",
            "country": "Kazakhstan"
        },
        {
            "currency": "LAK",
            "symbol": "₭",
            "code": 418,
            "name": "Kip",
            "country": "Laos"
        },
        {
            "currency": "LBP",
            "symbol": "ل.ل",
            "code": 422,
            "name": "Lebanese Pound",
            "country": "Lebanon"
        },
        {
            "currency": "LKR",
            "symbol": "Rs",
            "code": 144,
            "name": "Sri Lanka Rupee",
            "country": "Sri Lanka"
        },
        {
            "currency": "LRD",
            "symbol": "$",
            "code": 430,
            "name": "Liberian Dollar",
            "country": "Liberia"
        },
        {
            "currency": "LSL",
            "symbol": "L",
            "code": 426,
            "name": "Loti",
            "country": "Lesotho"
        },
        {
            "currency": "LYD",
            "symbol": "ل.د",
            "code": 434,
            "name": "Libyan Dinar",
            "country": "Libya"
        },
        {
            "currency": "MAD",
            "symbol": "د.م.",
            "code": 504,
            "name": "Moroccan Dirham",
            "country": "Morocco"
        },
        {
            "currency": "MDL",
            "symbol": "L",
            "code": 498,
            "name": "Moldovan Leu",
            "country": "Moldova"
        },
        {
            "currency": "MGA",
            "symbol": "",
            "code": 969,
            "name": "Malagasy Ariary",
            "country": "Madagascar"
        },
        {
            "currency": "MKD",
            "symbol": "ден",
            "code": 807,
            "name": "Denar",
            "country": "Macedonia"
        },
        {
            "currency": "MMK",
            "symbol": "K",
            "code": 104,
            "name": "Kyat",
            "country": "Myanmar (Burma)"
        },
        {
            "currency": "MNT",
            "symbol": "₮",
            "code": 496,
            "name": "Tugrik",
            "country": "Mongolia"
        },
        {
            "currency": "MOP",
            "symbol": "P",
            "code": 446,
            "name": "Pataca",
            "country": "Macao"
        },
        {
            "currency": "MRU",
            "symbol": "UM",
            "code": 929,
            "name": "Ouguiya",
            "country": "Mauritania"
        },
        {
            "currency": "MUR",
            "symbol": "₨",
            "code": 480,
            "name": "Mauritius Rupee",
            "country": "Mauritius"
        },
        {
            "currency": "MVR",
            "symbol": "ރ.",
            "code": 462,
            "name": "Rufiyaa",
            "country": "Maldives"
        },
        {
            "currency": "MWK",
            "symbol": "MK",
            "code": 454,
            "name": "Kwacha",
            "country": "Malawi"
        },
        {
            "currency": "MXN",
            "symbol": "$",
            "code": 484,
            "name": "Mexican Peso",
            "country": "Mexico"
        },
        {
            "currency": "MYR",
            "symbol": "RM",
            "code": 458,
            "name": "Malaysian Ringgit",
            "country": "Malaysia"
        },
        {
            "currency": "MZN",
            "symbol": "MTn",
            "code": 943,
            "name": "Metical",
            "country": "Mozambique"
        },
        {
            "currency": "NAD",
            "symbol": "$",
            "code": 516,
            "name": "Namibia Dollar",
            "country": "Namibia"
        },
        {
            "currency": "NGN",
            "symbol": "₦",
            "code": 566,
            "name": "Naira",
            "country": "Nigeria"
        },
        {
            "currency": "NIO",
            "symbol": "C$",
            "code": 558,
            "name": "Cordoba Oro",
            "country": "Nicaragua"
        },
        {
            "currency": "NOK",
            "symbol": "kr",
            "code": 578,
            "name": "Norwegian Krone",
            "country": "Norway"
        },
        {
            "currency": "NPR",
            "symbol": "₨",
            "code": 524,
            "name": "Nepalese Rupee",
            "country": "Nepal"
        },
        {
            "currency": "NZD",
            "symbol": "$",
            "code": 554,
            "name": "New Zealand Dollar",
            "country": "Cook Islands New Zealand Niue Pitcairn Island"
        },
        {
            "currency": "OMR",
            "symbol": "ر.ع.",
            "code": 512,
            "name": "Rial Omani",
            "country": "Oman"
        },
        {
            "currency": "PAB",
            "symbol": "B/.",
            "code": 590,
            "name": "Balboa",
            "country": "Panama"
        },
        {
            "currency": "PEN",
            "symbol": "S/.",
            "code": 604,
            "name": "Nuevo Sol",
            "country": "Peru"
        },
        {
            "currency": "PGK",
            "symbol": "K",
            "code": 598,
            "name": "Kina",
            "country": "Papua New Guinea"
        },
        {
            "currency": "PHP",
            "symbol": "₱",
            "code": 608,
            "name": "Philippine Peso",
            "country": "Philippines"
        },
        {
            "currency": "PKR",
            "symbol": "₨",
            "code": 586,
            "name": "Pakistan Rupee",
            "country": "Pakistan"
        },
        {
            "currency": "PLN",
            "symbol": "zł",
            "code": 985,
            "name": "PZloty",
            "country": "Poland"
        },
        {
            "currency": "PYG",
            "symbol": "₲",
            "code": 600,
            "name": "Guarani",
            "country": "Paraguay"
        },
        {
            "currency": "QAR",
            "symbol": "ر.ق",
            "code": 634,
            "name": "Qatari Rial",
            "country": "Qatar"
        },
        {
            "currency": "RON",
            "symbol": "L",
            "code": 946,
            "name": "Leu",
            "country": "Romania"
        },
        {
            "currency": "RSD",
            "symbol": "din",
            "code": 941,
            "name": "Serbian Dinar",
            "country": "Kosovo Serbia"
        },
        {
            "currency": "RUB",
            "symbol": "р.",
            "code": 643,
            "name": "Russian Ruble",
            "country": "Russia South Ossetia"
        },
        {
            "currency": "RWF",
            "symbol": "₣",
            "code": 646,
            "name": "Rwanda Franc",
            "country": "Rwanda"
        },
        {
            "currency": "SAR",
            "symbol": "ر.س",
            "code": 682,
            "name": "Saudi Riyal",
            "country": "Saudi Arabia"
        },
        {
            "currency": "SBD",
            "symbol": "$",
            "code": 90,
            "name": "Solomon Islands Dollar",
            "country": "Solomon Islands"
        },
        {
            "currency": "SCR",
            "symbol": "₨",
            "code": 690,
            "name": "Seychelles Rupee",
            "country": "Seychelles"
        },
        {
            "currency": "SDG",
            "symbol": "£",
            "code": 938,
            "name": "Sudanese Pound",
            "country": "Sudan"
        },
        {
            "currency": "SEK",
            "symbol": "kr",
            "code": 752,
            "name": "Swedish Krona",
            "country": "Sweden"
        },
        {
            "currency": "SGD",
            "symbol": "$",
            "code": 702,
            "name": "Singapore Dollar",
            "country": "Brunei Singapore"
        },
        {
            "currency": "SHP",
            "symbol": "£",
            "code": 654,
            "name": "Saint Helena Pound",
            "country": "Ascension Island Saint Helena Tristan da Cunha"
        },
        {
            "currency": "SLL",
            "symbol": "Le",
            "code": 694,
            "name": "Leone",
            "country": "Sierra Leone"
        },
        {
            "currency": "SLE",
            "symbol": "Le",
            "code": 925,
            "name": "Leone",
            "country": "Sierra Leone"
        },
        {
            "currency": "SOS",
            "symbol": "Sh",
            "code": 706,
            "name": "Somali Shilling",
            "country": "Somalia"
        },
        {
            "currency": "SRD",
            "symbol": "$",
            "code": 968,
            "name": "Suriname Dollar",
            "country": "Suriname"
        },

        {
            "currency": "SSP",
            "symbol": "SSP",
            "code": 968,
            "name": "South Sudanese pound",
            "country": "South Sudan"
        },

        {
            "currency": "STN",
            "symbol": "Db",
            "code": 930,
            "name": "Dobra",
            "country": "Sao Tome and Principe"
        },
        {
            "currency": "SYP",
            "symbol": "ل.س",
            "code": 760,
            "name": "Syrian Pound",
            "country": "Syria"
        },
        {
            "currency": "SZL",
            "symbol": "L",
            "code": 748,
            "name": "Lilangeni",
            "country": "Swaziland"
        },
        {
            "currency": "THB",
            "symbol": "฿",
            "code": 764,
            "name": "Baht",
            "country": "Thailand"
        },
        {
            "currency": "TJS",
            "symbol": "SM",
            "code": 972,
            "name": "Somoni",
            "country": "Tajikistan"
        },
        {
            "currency": "TMT",
            "symbol": "m",
            "code": 934,
            "name": "Manat",
            "country": "Turkmenistan"
        },
        {
            "currency": "TND",
            "symbol": "د.ت",
            "code": 788,
            "name": "Tunisian Dinar",
            "country": "Tunisia"
        },
        {
            "currency": "TOP",
            "symbol": "T$",
            "code": 776,
            "name": "Pa‚Äôanga",
            "country": "Tonga"
        },
        {
            "currency": "TRY",
            "symbol": "₤",
            "code": 949,
            "name": "Turkish Lira",
            "country": "North Cyprus Turkey"
        },
        {
            "currency": "TTD",
            "symbol": "$",
            "code": 780,
            "name": "Trinidad and Tobago Dollar",
            "country": "Trinidad and Tobago"
        },
        {
            "currency": "TWD",
            "symbol": "$",
            "code": 901,
            "name": "Taiwan Dollar",
            "country": "Taiwan"
        },
        {
            "currency": "TZS",
            "symbol": "Sh",
            "code": 834,
            "name": "Tanzanian Shilling",
            "country": "Tanzania"
        },
        {
            "currency": "UAH",
            "symbol": "₴",
            "code": 980,
            "name": "Hryvnia",
            "country": "Ukraine"
        },
        {
            "currency": "UGX",
            "symbol": "Sh",
            "code": 800,
            "name": "Uganda Shilling",
            "country": "Uganda"
        },
        {
            "currency": "USD",
            "symbol": "$",
            "code": 840,
            "name": "US Dollar",
            "country": "American Samoa British Indian Ocean Territory British Virgin Islands Guam Haiti Marshall Islands Micronesia Northern Mariana Islands Pacific Remote islands Palau Panama Puerto Rico Turks and Caicos Islands United States of America US Virgin Islands"
        },
        {
            "currency": "UYU",
            "symbol": "$",
            "code": 858,
            "name": "Peso Uruguayo",
            "country": "Uruguay"
        },
        {
            "currency": "UZS",
            "symbol": "",
            "code": 860,
            "name": "Uzbekistan Sum",
            "country": "Uzbekistan"
        },
        {
            "currency": "VEF",
            "symbol": "Bs F",
            "code": 937,
            "name": "Bolivar Fuerte",
            "country": "Venezuela"
        },
        {
            "currency": "VND",
            "symbol": "₫",
            "code": 704,
            "name": "Dong",
            "country": "Vietnam"
        },
        {
            "currency": "VUV",
            "symbol": "Vt",
            "code": 548,
            "name": "Vatu",
            "country": "Vanuatu"
        },
        {
            "currency": "WST",
            "symbol": "T",
            "code": 882,
            "name": "Tala",
            "country": "Samoa"
        },
        {
            "currency": "XAF",
            "symbol": "₣",
            "code": 950,
            "name": "CFA Franc BCEAO",
            "country": "Benin Burkina Faso Cameroon Central African Republic Chad Congo (Brazzaville) C√¥te d'Ivoire Equatorial Guinea Gabon Guinea-Bissau Mali Niger Senegal Togo"
        },
        {
            "currency": "XCD",
            "symbol": "$",
            "code": 951,
            "name": "East Caribbean Dollar",
            "country": "Anguilla Antigua and Barbuda Dominica Grenada Montserrat Saint Kitts and Nevis Saint Lucia Saint Vincent and Grenadine"
        },
        {
            "currency": "XEU",
            "symbol": "XEU",
            "code": 951,
            "name": "European Currency Unit",
            "country": ""
        },
        {
            "currency": "XOF",
            "symbol": "₣",
            "code": 950,
            "name": "CFA Franc BCEAO",
            "country": "Benin Burkina Faso Cameroon Central African Republic Chad Congo (Brazzaville) C√¥te d'Ivoire Equatorial Guinea Gabon Guinea-Bissau Mali Niger Senegal Togo"
        },
        {
            "currency": "XPF",
            "symbol": "₣",
            "code": 953,
            "name": "CFP Franc",
            "country": "French Polynesia New Caledonia Wallis and Futuna"
        },
        {
            "currency": "YER",
            "symbol": "﷼",
            "code": 886,
            "name": "Yemeni Rial",
            "country": "Yemen"
        },
        {
            "currency": "ZAR",
            "symbol": "R",
            "code": 710,
            "name": "Rand",
            "country": "Lesotho Namibia South Africa"
        },
        {
            "currency": "ZMK",
            "symbol": "ZK",
            "code": 967,
            "name": "Zambian Kwacha",
            "country": "Zambia"
        },
        {
            "currency": "ZMW",
            "symbol": "ZK",
            "code": 967,
            "name": "Zambian Kwacha",
            "country": "Zambia"
        },
        {
            "currency": "ZWL",
            "symbol": "$",
            "code": 932,
            "name": "Zimbabwe Dollar",
            "country": "Zimbabwe"
        }
    ];
}
