import { Component, NgZone, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { routes } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { SelectEmployeesDialogComponent } from '@app/shared/components/select-employees-dialog/select-employees-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import * as moment from 'moment';
import { defer, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GoalPlanEmployee, GoalPlanSubmit, GoalPlanVerbose } from '../../models/goal-plan.model';
import { GoalPlanService } from '../../services/goal-plan.service';
import { GoalPlanPublishHistoryDialogComponent } from '../goal-plan-publish-history-dialog/goal-plan-publish-history-dialog.component';
import { RowActionButtonInput } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/data-grid-comp/models/data-grid-comp.model';
import { EmployeeDetailsDialogComponent } from './components/employee-details-dialog/employee-details-dialog.component';
import { stat } from 'fs';

@Component({
  selector: 'app-goal-plan-editor',
  templateUrl: './goal-plan-editor.component.html',
  styleUrls: ['./goal-plan-editor.component.scss']
})
export class GoalPlanEditorComponent implements OnInit {
  public view: any;
  formId: string = 'frm_1pkiJ44rrnGYUP';
  getFormData: boolean = false;
  formValid: boolean = false;
  formData: any;
  goalPlan: GoalPlanVerbose;
  formGeneratorDialogRef: MatDialogRef<FormGeneratorDialogComponent>;
  selectEmployeesDialogRef: MatDialogRef<SelectEmployeesDialogComponent>;
  formPristine: boolean = true;
  ratingsPristine: boolean = true;
  goalPlanId: any;
  isLoading: boolean;
  isLoadingEmployees: boolean;
  employeesGridDataResult: { data: GoalPlanEmployee[]; total: number; };
  pageSize: number = 5;
  skip: number = 0;
  public columns: any[] = [
    { field: "employee", title: "Name", type:'employee' },
    { field: 'status', subField: 'id', title: this.translate.instant('Status'), type: 'chip', chipValue: ['Complete', 'Archived', null, 'Active'] },
  ];
  gridActionButtons: RowActionButtonInput[] = [
    {
      id: 'details',
      name: this.translate.instant('Details'),
      icon: 'info'
    }
  ];
  clearSelectedItems: boolean = false;
  searchValue: string;
  searchFilterString: string;
  getUsersRequest: any;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private goalPlanService: GoalPlanService,
  ){
    this.goalPlanId = this.getIdInURL();
  }

  ngOnInit(): void {
    this.getGoalPlan();
  }

  getIdInURL(): string {
    let IdInURL: string;

    this.route.paramMap.subscribe(
        params => IdInURL = params.get('goalPlanId')
    );

    return IdInURL;
  }

  getGoalPlan(){
    this.isLoading = true;

    this.goalPlanService.getGoalPlan(this.goalPlanId)
    .pipe(
      finalize( () => {
        this.isLoading = false;
      })
    )
    .subscribe(
      (res) => {
        this.goalPlan = res;
        this.buildFormData();
        this.getEmployees();
      }
    );
  }

  buildFormData() {
    this.formData = {
      id: this.goalPlan ? this.goalPlan.id : null,
      name: this.goalPlan ? this.goalPlan.name : null,
      description: this.goalPlan ? this.goalPlan.description : null,
      permitEmployeeGoalEntry: this.goalPlan ? this.goalPlan.permitEmployeeGoalEntry : false,
      permitEmployeeChildGoalEntry: this.goalPlan ? this.goalPlan.permitEmployeeChildGoalEntry : false,
      lockGoals: this.goalPlan ? this.goalPlan.lockGoals : false,
      status: this.goalPlan ? this.goalPlan.status?.id : null,
      asOf: moment().format(),
      changeReason: null,
      changeReasonComments: null,
    }
  }

  getEmployees() {
    this.isLoadingEmployees = true;

    this.getUsersRequest = this.goalPlanService.getGoalPlanEmployees(this.goalPlanId, this.pageSize.toString(), this.skip, null, this.searchFilterString)
    .pipe(
      finalize( () => {
        this.isLoadingEmployees = false;
      })
    )
    .subscribe(
      (res) => {
        this.employeesGridDataResult = {
          data: res.data,
          total: res.total,
        }
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.view = this.getEmployees();
  }

  showAddUserModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      nonSelectableEmployees: this.employeesGridDataResult.data.map( goalEmployee => goalEmployee.employee.id )
    };

    this.ngZone.runOutsideAngular(() => {
      this.selectEmployeesDialogRef = this.dialog.open(SelectEmployeesDialogComponent, dialogConfig);
    });

    const sub = this.selectEmployeesDialogRef.componentInstance.emitSelectedEmployees.subscribe((event) => {
      this.addEmployees(event)
    });
  }

  openPublishHistoryDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      goalPlanId: this.goalPlanId
    };

    const dialogRef = this.dialog.open(GoalPlanPublishHistoryDialogComponent, dialogConfig);
    
  }

  addEmployees(selectedEmployees: string[]) {
    const observables = selectedEmployees.map(selectedUserId => defer(() => this.goalPlanService.addGoalPlanEmployee(this.goalPlan.id, selectedUserId)));

    this.overlayService.show();

    forkJoin(observables)
        .pipe(finalize(() => this.overlayService.hide()))
        .subscribe(
            (res) => {
                this.getEmployees();
                this.selectEmployeesDialogRef.close();
                this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
            }
        );
  }

  deleteAllSelected(employees: {id: string, firstName: string, lastName: string}[]) {
    const observables = employees.map(employee => defer(() => this.goalPlanService.deleteGoalPlanEmployee(this.goalPlan.id, employee?.id)));

    this.overlayService.show();

    forkJoin(observables)
      .pipe(finalize(() => this.overlayService.hide()))
      .subscribe(
          (res) => {
              this.clearSelectedItems = !this.clearSelectedItems;
              this.getEmployees();
              this.snackbarService.openSnackBar('Removed successfully', 'clear', 'success');
          }
      );
  }

  formDataEmitted(formDataEmitted) {
    this.save(formDataEmitted);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  formPristineEmitted(formPristine: boolean) {
    this.formPristine = formPristine;
  }

  openConfirmCloseDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      text: this.translate.instant('UnsavedChangesMessage')
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.navigateBack();
        }
      }
    );
  }

  navigateBack() {
    this.router.navigate([`${routes.PERFORMANCE}/${routes.GOAL_PLANS}`]);
  }

  save(formData: GoalPlanSubmit){
    this.overlayService.show();

    this.goalPlanService.updateGoalPlan(formData)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
        }
    );
  }

  openConfirmPublishGoalPlanDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      text: this.translate.instant('PublishGoalPlanInformation')
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.publishGoalPlan();
        }
      }
    );
  }

  publishGoalPlan() {
    this.overlayService.show('Sending publish goal plan request');

    this.goalPlanService.publishGoalPlan(this.goalPlanId)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('RequestSentSuccessfully')}`, 'clear', 'success');
        }
    );
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;
    this.searchFilterString = '';
    this.skip = 0;

    let variants = this.searchValue.split(' ').filter(value => value);
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `((EmployeeFirstName like "${variant}") OR (EmployeeLastName like "${variant}"))`;
        }
    });

    this.getUsersRequest.unsubscribe();
    this.getEmployees();
  }

  showDetails(event) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      title: "GoalPlan-Employee-Details",
      details: event.rowData
    };

    const dialogRef = this.dialog.open(EmployeeDetailsDialogComponent, dialogConfig);
  }
}

