<h5 mat-dialog-title>{{ 'GoalPlanPublishHistory' | translate: {Default: "Goal Plan Publish History"} }}</h5>

<mat-dialog-content>
    <kendo-grid
        [kendoGridBinding]="gridData"
        [loading]="isLoading"
        [sortable]="true"
        [sort]="sort"
        [pageable]="{
            buttonCount: 6,
            info: true,
            type: 'numeric',
            pageSizes: [5, 10, 20, 50, 100],
            previousNext: true
        }"
        [pageSize]="20"
    >

        <ng-template kendoGridToolbarTemplate>
            <button mat-stroked-button setColor="primaryColour" color="primary" (click)="getGoalPlanPublishHistory()" appLocalizationPopupDirective [localizationCode]="'Refresh'">
                <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="refresh icon">refresh</mat-icon>
                {{ 'Refresh' | translate: {Default: "Refresh"} }}
            </button>
        </ng-template>

        <kendo-grid-column
            field="goalPlan"
            [title]="translate.instant('GoalPlan')"
            [width]="200">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        {{dataItem?.goalPlan?.name}}
                    </div>
                </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
            field="publishedOn"
            [title]="translate.instant('PublishedOn')"
            [width]="200">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        {{ dataItem?.publishedOn | date:'medium' }}
                    </div>
                </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
            field="startedAt"
            [title]="translate.instant('StartedOn')"
            [width]="200">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        {{ dataItem?.startedAt | date:'medium' }}
                    </div>
                </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
            field="completedAt"
            [title]="translate.instant('CompletedOn')"
            [width]="200">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        {{ dataItem?.completedAt | date:'medium' }}
                    </div>
                </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
            field="publishedBy"
            [title]="translate.instant('PublishedBy')"
            [width]="200">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                        <div class="employee-name">
                            <app-employee-image [employeeId]="dataItem?.publishedBy?.id" [width]="35" [height]="35" [round]="true" *ngIf="!isLoading"></app-employee-image>
            
                            <span class="employee">
                                {{dataItem?.publishedBy?.firstName}} {{dataItem?.publishedBy?.lastName}}
                            </span>
                        </div>
                    </div>
                </ng-template>
        </kendo-grid-column>

        <kendo-grid-messages 
            [noRecords]="isLoading ? '' : translate.instant('Grid-NoRecordsAvailable')"
            [pagerItemsPerPage]="translate.instant('Grid-Pager-ItemsPerPage')" 
            [pagerItems]="translate.instant('Grid-Pager-Items')"
            [pagerOf]="translate.instant('Grid-Pager-Of')"
        ></kendo-grid-messages>
    </kendo-grid>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
</mat-dialog-actions>