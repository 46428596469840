import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FormElement } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { ReviewRating } from '@app/modules/performance/components/review-ratings/models/review-rating.model';
import { ReviewRatingsService } from '@app/modules/performance/components/review-ratings/services/review-ratings.service';
import { Observable, from } from 'rxjs';

@Component({
  selector: 'app-review-ratings-form-field',
  templateUrl: './review-ratings-form-field.component.html',
  styleUrls: ['./review-ratings-form-field.component.scss']
})
export class ReviewRatingsFormFieldComponent implements OnInit {
  @Input() formElement: FormElement;
  @Input() parentGroup: UntypedFormGroup;
  @Input() readOnly: boolean;
  isLoading: boolean;
  user$: any;
  filterString: string;
  reviewRatingsOptions: Observable<PagedData<ReviewRating>>;

  constructor(
    private reviewRatingsService: ReviewRatingsService,
  ) { }

  ngOnInit(): void {
    this.reviewRatingsOptions = from(this.reviewRatingsService.getAllReviewRatings())
  }

}
