import { Component, Input, OnInit } from '@angular/core';
import { Questionnaire } from '../../models/questionnaire.model';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {
  @Input() data: Questionnaire;

  constructor() { }

  ngOnInit(): void {
  }

}
