<mat-expansion-panel class="goal-plan mat-elevation-z0" [expanded]="expanded">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <h5>{{employeeAssignedGoalPlan?.goalPlan?.name}}</h5>
            <mat-chip-list>
                <mat-chip 
                [ngClass]="
                    employeeAssignedGoalPlan?.status?.id === 'Complete' ? 'green-chip' : 
                    employeeAssignedGoalPlan?.status?.id === 'Archived' ? 'red-chip' :
                    employeeAssignedGoalPlan?.status?.id === 'Active' ? 'blue-chip' :
                    ''
                "
                >{{ employeeAssignedGoalPlan?.status?.name }}</mat-chip>
            </mat-chip-list>
        </mat-panel-title>
        <mat-panel-description>
            <div class="k-flex-grow"></div>
            {{ 'LastPublished' | translate: {Default: "Last Published"} }}: {{ moment(employeeAssignedGoalPlan.lastPublished).format('lll') }}
        </mat-panel-description>
    </mat-expansion-panel-header>
    
    <ng-template matExpansionPanelContent>
        <app-employee-published-goal-parent-data-grid 
            [goalPlan]="employeeAssignedGoalPlan.goalPlan" 
            [employeeId]="employeeId"
        ></app-employee-published-goal-parent-data-grid>
    </ng-template>
</mat-expansion-panel>