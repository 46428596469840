import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { WorkflowsAbsencesService } from '@app/core/services/workflows-absences/workflows-absences.service';
import { MyRequest } from '@app/shared/models/workflows-absences/workflows-absences.model';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { PendingTimeOffDialogComponent } from './components/pending-time-off-dialog/pending-time-off-dialog.component';

@Component({
  selector: 'app-pending-time-off-approvals',
  templateUrl: './pending-time-off-approvals.component.html',
  styleUrls: ['./pending-time-off-approvals.component.scss']
})
export class PendingTimeOffApprovalsComponent implements OnInit {
  public selectedFilter;
  timeOffLeaveRequests$: any;

  filterOptions = [
    {
      value: '3',
      text: 'Last3Months'
    },
    {
      value: '6',
      text: 'Last6Months'
    },
    {
      value: '12',
      text: 'Last12Months'
    },
    {
      value: '',
      text: 'All'
    },
  ]

  constructor(
    public translate: TranslateService,
    private dialog: MatDialog,
    private workflowsAbsencesService: WorkflowsAbsencesService,
  ) { 
    this.selectedFilter = this.filterOptions[0];
  }

  ngOnInit(): void {
    this.getLeaveRequests();
  }

  getLeaveRequests() {
    let filterTime;

    if(this.selectedFilter !== "") {
      filterTime = moment().subtract(parseInt(this.selectedFilter.value), 'months').format();
    }

    this.timeOffLeaveRequests$ = this.workflowsAbsencesService.getPendingApproval(filterTime);
  }

  openPendingTimeOffDialog(timeOffLeaveRequest: MyRequest) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 500;

    dialogConfig.data = {
      timeOffRequest: timeOffLeaveRequest
    };

    const dialogRef = this.dialog.open(PendingTimeOffDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data === true) {
          this.getLeaveRequests();
        }
      }
    );
  }

}

