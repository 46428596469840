import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { TranslationService } from '@app/core/services/translation.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import { finalize } from 'rxjs/operators';
import { NewTranslationDialogComponent } from './components/new-translation-dialog/new-translation-dialog.component';
import {features} from '@app/consts';
import {SecurityProtectedBase} from '@app/shared/components/security-protected/security-protected';
import {FeatureService} from '@app/core/services/feature.service';
import { UpdateTranslationDialogComponent } from './components/update-translation-dialog/update-translation-dialog.component';
import { EmployeeService } from '@app/core/services/employee.service';
import { CurrentUserDetails } from '@app/shared/models/employee.model';

@Component({
  selector: 'app-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.scss']
})
export class TranslationsComponent extends SecurityProtectedBase implements OnInit {

  cultures: Culture[];
  selectedCulture: Culture;
  selectedTranslationId: string;
  selectedTranslationValue: string;
  currentTranslations: any;
  filteredCurrentTranslations: any;
  translationsListSearchValue: string;
  form: UntypedFormGroup;
  loadingCultures: boolean;
  loadingTranslations: boolean;
  currentEmployeeDetails: CurrentUserDetails;

  constructor(
    private cultureService: CultureService,
    private fb: UntypedFormBuilder,
    private translationService: TranslationService,
    private overlayService: OverlayService,
    private dialog: MatDialog,
    private employeeService: EmployeeService,
    private snackbarService: SnackbarService,
    featureService: FeatureService
  ) {
      super(featureService, features.GLOBALIZATION)
      this.currentEmployeeDetails = this.employeeService.getFetchedCurrentUserDetails()
  }

  ngOnInit(): void {
    this.getCultures();
  }

  getCultures() {
    this.loadingCultures = true;
    
    this.cultureService.getCultures()
    .pipe(
      finalize(
        () => {
          this.loadingCultures = false;
        }
      )
    )
    .subscribe(
      res => {
        this.cultures = res;
        this.cultures.length > 0 && this.selectedCulture === undefined ? this.selectedCultureChange(this.cultures[0]) : null;
      }
    );
  }

  // Reset variables and load translations for the new culture
  selectedCultureChange(selectedCulture: Culture) {
    this.selectedTranslationId = null;
    this.selectedTranslationValue = null;
    this.form = null;

    this.selectedCulture = selectedCulture;
    this.getCultureTranslations(this.selectedCulture);
  }

  // get the translations for a culture
  getCultureTranslations(culture: Culture) {
    this.loadingTranslations = true;
    
    this.translationService.getTranslations(culture.id)
    .pipe(
      finalize(
        () => {
          this.loadingTranslations = false;
        }
      )
    )
    .subscribe(
      res => {
        this.currentTranslations = res;
        this.filteredCurrentTranslations = res;
        this.translationSearchChange(this.translationsListSearchValue);
      }
    )
  }

  // Open Translation Form
  selectedTranslationChange(translationId?: string, translationValue?: string){
    this.selectedTranslationId = translationId;
    this.selectedTranslationValue = translationValue;
    this.createForm();
  }

  // Create Translation Form
  createForm() {
    this.form = this.fb.group({
      id: [this.selectedTranslationId ? this.selectedTranslationId : null, Validators.required],
      cultureId: [this.selectedCulture ? this.selectedCulture?.id : null, Validators.required],
      newTranslationValue: [this.selectedTranslationValue ? this.selectedTranslationValue : null, Validators.required],
    });
  }

  // Update the translation when the user presses save in the form
  updateTranslation() {
    this.overlayService.show();

    this.translationService.updateTranslation(this.form.value.cultureId, this.form.value.id, this.form.value.newTranslationValue)
    .pipe(
      finalize(
        () => {
          this.overlayService.hide();
        }
      )
    )
    .subscribe(
      res => {
        this.snackbarService.openSnackBar('Translation updated successfully', 'clear', 'success');
        this.getCultureTranslations(this.selectedCulture);
      }
    );
  }

  closeTranslationsForm() {
    this.form = null;
  }

  // filter the translations when the search changes
  translationSearchChange(searchString: string) {
    if(searchString === undefined){
      this.filteredCurrentTranslations = this.currentTranslations;
    }
    else {
      this.filteredCurrentTranslations = Object.fromEntries(
        Object.entries(this.currentTranslations)
        .filter(
          translation => {
            let match = false;
    
            translation.forEach(
              (item: string) => {
                if(item !== null && item.toLowerCase().includes(searchString.toLowerCase())) {
                  match = true;
                }
              }
            );
            
            return match; 
          }
        )
      );
    }
  }

  openNewTranslationCodeDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {};

    const dialogRef = this.dialog.open(NewTranslationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.getCultureTranslations(this.selectedCulture);
        }
      }
    );
  }

  openUpdateTranslationDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {};

    const dialogRef = this.dialog.open(UpdateTranslationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.getCultureTranslations(this.selectedCulture);
        }
      }
    );
  }
}
