<app-site-settings-menu>
    <app-data-grid-comp
        *ngIf="hasFeatureAccess"
        [columns]="columns"
        [bindingType]="bindingType"
        [view]="view"
        [gridData]="gridData"
        [gridDataResult]="gridDataResult"
        [isLoading]="isLoading"
        [pageSize]="pageSize"
        [skip]="skip"
        category="WorkLocations"
        [rowSelectionSettings]="{
            canSelectRows: true,
            selectableMode: 'multiple',
            selectRowBy: 'id'
        }"
        clickableRows="true"
        searchable="true"
        [isLoading]="isLoading"
        [filterCategories]="columns"
        [clearSelectedItems]="clearSelectedItems"
        [filterToggleDetails]="filterToggleDetails"
        [sortable]="{
            mode: 'multiple'
        }"
        [sortableColumns]="sortableColumns"
        (emitToggleFilter)="updateFilterToggleDetails($event)"
        (emitPageChangeEvent)="pageChange($event)"
        (emitOpenDialogEvent)="getWorkLocation($event)"
        (emitSearchEvent)="search($event)"
        (emitDeleteEvent)="deleteAllSelected($event)"
        (emitFilterEvent)="filterCallback($event)"
        (emitSortEvent)="sortChange($event)"
    ></app-data-grid-comp>
    
    <no-feature-access *ngIf="!hasFeatureAccess"></no-feature-access>
</app-site-settings-menu>


<!-- <kendo-grid
        [data]="gridDataResult"
        [pageSize]="pageSize"
        [skip]="skip"
        [style.maxHeight.vh]="85" 
        [resizable]="true"
        [pageable]="{
            info: true,
            pageSizes: [5, 10, 20, 50, 100],
            previousNext: true
        }"
        (pageChange)="pageChange($event)"
        [loading]="isLoading"
        kendoGridSelectBy="id"
        [selectedKeys]="selectedItems"
        [sortable]="true"
        [sort]="sort"
        (sortChange)="sortChange($event)"
>

    <ng-template kendoGridToolbarTemplate>
        <div *ngIf="selectedItems.length > 0">
            <button class="delete-all-selected-button" mat-stroked-button color="primary" (click)="deleteAllSelected()">
                <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">delete</mat-icon>
                Delete All Selected ({{selectedItems.length}})
            </button>
        </div>

        <mat-form-field class="search-field" appearance="outline">
            <input matInput
                   placeholder="Search..."
                   [(ngModel)]="searchValue"
                   (input)="search()"
            >
            <mat-icon matPrefix class="material-icons-outlined">
                search
            </mat-icon>
        </mat-form-field>
        <div class="mt-2">
            <filter-control [categories]="filterCategories" (callback)="filterCallback($event)"></filter-control>
        </div>
    </ng-template>

    <kendo-grid-checkbox-column
            [width]="80"
            showSelectAll="true"
    ></kendo-grid-checkbox-column>

    <kendo-grid-column field="name" title="Name" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="grid-cell-vertical">
            <span class="position-name navigation-link" (click)="$event.stopPropagation(); getWorkLocation(dataItem)">
                {{dataItem.name}}
            </span>
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
        *ngFor="let col of columns"
        [field]="col.field"
        [title]="col.title"
    ></kendo-grid-column>

</kendo-grid> -->
