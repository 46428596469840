<div class="main-container">
    <div class="chart" echarts [options]="chart"></div>
    <div class="chart-figures">
        <div *ngFor="let item of labelData" class="row">
            <div class="dot" [ngStyle]="{'background-color': item.color}"></div>
            <div class="details">
                <div class="label"> {{item.label}}</div>
            </div>
        </div>
    </div>
</div>