<app-security-section>
    <div class="main-content">

        <mat-card>
            <mat-card-title appLocalizationPopupDirective localizationCode="IntegrationConfiguration" class="header">
                <h5>{{ 'IntegrationConfiguration' | translate: {Default: "Integration Configuration"} }}</h5>
            </mat-card-title>
            <mat-card-content>
                <div class="integration-selection">
                    <h6>Select An Integration</h6>

                    <mat-form-field appearance="fill">
                        <mat-label>
                            Integration
                        </mat-label>

                        <mat-select disableOptionCentering [(ngModel)]="selectedIntegration">
                            <ng-container *ngFor="let integration of integrations">
                                <mat-option [value]="integration">{{integration.name}}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>


                <div class="integration-connection-settings" *ngIf="selectedIntegration">
                    <h6>{{selectedIntegration.name}} Connection Settings</h6>

                    <div class="connection-settings-form">
                        <mat-form-field appearance="fill">
                            <mat-label>
                                Client ID
                            </mat-label>

                            <input matInput type="text" [value]="selectedIntegration.connectionSettings.clientId">
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>
                                Username
                            </mat-label>

                            <input matInput type="text" [value]="selectedIntegration.connectionSettings.username">
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>
                                Password
                            </mat-label>

                            <input matInput type="text" [value]="selectedIntegration.connectionSettings.password">
                        </mat-form-field>

                        <div class="button-container">
                            <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()">Save</button>
                        </div>
                    </div>
                </div>

                <div class="integration-field-mapping" *ngIf="selectedIntegration">
                    <h6>Integration Field Mapping</h6>

                    <div class="top">
                        <div class="table-selection">
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Table
                                </mat-label>

                                <mat-select disableOptionCentering [(ngModel)]="selectedTable">
                                    <ng-container *ngFor="let table of tables">
                                        <mat-option [value]="table">{{table.name}}</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="bottom" *ngIf="selectedTable">
                        <div class="hubbub-fields">
                            <div class="fields-title">
                                Hubbub Fields
                            </div>

                            <mat-selection-list #hubbubFields [multiple]="false">
                                <mat-list-option *ngFor="let field of selectedTable.fields" [value]="field">
                                    {{field}}
                                </mat-list-option>
                            </mat-selection-list>
                        </div>

                        <div class="mapped-fields">
                            <div class="fields-title">
                                Mapped Fields
                            </div>

                            <mat-selection-list #mappedFields [multiple]="false">
                                <mat-list-option *ngFor="let field of fields" [value]="field">
                                    {{field.name}}
                                </mat-list-option>
                            </mat-selection-list>
                        </div>

                        <div class="mapping-properties">
                            <div class="fields-title">
                                Mapping Properties
                            </div>

                            <div>
                                <div class="mapping-properties-form">
                                    <mat-form-field appearance="fill">
                                        <mat-label>
                                            Integration Field Name
                                        </mat-label>

                                        <input matInput type="text" [value]="mappedFields.selectedOptions.selected[0]?.value.details.integrationFieldName">
                                    </mat-form-field>

                                    <mat-form-field appearance="fill">
                                        <mat-label>
                                            Field Location
                                        </mat-label>

                                        <input matInput type="text" [value]="mappedFields.selectedOptions.selected[0]?.value.details.fieldLocation">
                                    </mat-form-field>

                                    <mat-checkbox setColor="primaryColour" [checked]="mappedFields.selectedOptions.selected[0]?.value.details.newLookup">Create new lookup value when missing</mat-checkbox>

                                    <div class="button-container">
                                        <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

    </div>
</app-security-section>
