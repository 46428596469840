<app-site-settings-menu>

  <div class="full-width-container">
    <mat-card class="mat-elevation-z0" *ngIf="hasFeatureAccess">
        <mat-card-title class="header">
          <h5 appLocalizationPopupDirective localizationCode="Configure">{{ 'Configure' | translate: {Default: "Configure"} }}</h5>
      
          <div class="k-flex-grow"></div>
          <button 
            mat-raised-button 
            setColor="primaryColour" 
            color="primary" 
            [disabled]="formValid === false" 
            appPreventDoubleClick 
            (throttledClick)="getFormData = !getFormData">
            {{ 'Save' | translate: {Default: "Save"} }}
          </button>
        </mat-card-title>
  
        <mat-card-content>
          <ng-container *ngIf="isLoading; else loaded">
            <ngx-skeleton-loader
              count="5"
              [theme]="{ 
                  'height.px': 50
              }"
            ></ngx-skeleton-loader>
          </ng-container>
          <ng-template #loaded>
            <app-form-generator 
              [formId]="formId"
              [formData]="formData"
              [emitFormDataTrigger]="getFormData"
              (emitFormData)="formDataEmitted($event)"
              (emitFormStatus)="formStatusUpdated($event)"
            ></app-form-generator>
          </ng-template>
        </mat-card-content>
    </mat-card>
  
    <no-feature-access *ngIf="!hasFeatureAccess"></no-feature-access>
  </div>

</app-site-settings-menu>
