<div id="overlay">
    <div class="saving-message-div">

        <h2 *ngIf="content !== undefined">{{content}}</h2>   
        <h2 *ngIf="content === undefined">Saving</h2>   
        <div class="loading">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div> 
    </div>
</div>