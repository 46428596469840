import { AfterViewInit, Component, OnInit } from '@angular/core';
import '@progress/kendo-ui';
import { OrgChartService } from './services/org-chart.service';

declare var kendo: any;

@Component({
  selector: 'app-org-chart',
  templateUrl: './org-chart.component.html',
  styleUrls: ['./org-chart.component.scss']
})
export class OrgChartComponent implements AfterViewInit, OnInit {
  selectedPosition: string;
  orgChartData: any;

  constructor(
    private OrgChartService: OrgChartService
  ) { }

  ngOnInit(): void {
}


  ngAfterViewInit(): void {
  }

}
