<time-off-menu>
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
            <h5 appLocalizationPopupDirective localizationCode="TimeOffEmailLocalizations">{{ 'TimeOffEmailLocalizations' | translate: {Default: "Time Off Email Localizations"} }}</h5>
        </mat-card-title>

        <mat-card-content>
            <div class="main-container">
                <div class="emails">
                    <h6>{{ 'TimeOffEmails' | translate: {Default: "Emails"} }}</h6>
                    <mat-list>
                        <ng-container *ngFor="let email of emails; let lastItem = last">
                            <mat-list-item 
                                setColor 
                                [setColorRules]="[
                                    {
                                        color: 'primaryColour',
                                        events: ['hover']
                                    }
                                ]"
                                appPreventDoubleClick (throttledClick)="getCultureTranslations(email)"
                                class="clickable-icon" 
                                [ngClass]="{'selectedEmail': selectedEmail === email}">
                                {{email.text}}
                            </mat-list-item>
                            <mat-divider *ngIf="!lastItem"></mat-divider>
                        </ng-container>
                    </mat-list>
                </div>
                <div class="form">
                    <ng-container *ngIf="loadingTranslations; else loadedTranslations">
                        <ngx-skeleton-loader
                            count="5"
                            [theme]="{ 
                                'height.px': 50
                            }"
                        ></ngx-skeleton-loader>
                    </ng-container>

                    <ng-template #loadedTranslations>
                        <form [formGroup]="form">
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    {{ 'TimeOffEmailsCulture' | translate: {Default: "Culture"} }}
                                </mat-label>
                    
                                <mat-select disableOptionCentering formControlName="culture">
                                        <mat-option></mat-option>
                                        <mat-option *ngFor="let option of cultures" [value]="option.id">{{option.text}}</mat-option>
                                </mat-select>
                            </mat-form-field>
            
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    {{ 'TimeOffEmailsSubject' | translate: {Default: "Subject"} }}
                                </mat-label>
                    
                                <input matInput type="text" formControlName="subject">
                            </mat-form-field>
            
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    {{ 'TimeOffEmailsBody' | translate: {Default: "Body"} }}
                                </mat-label>
                    
                                <textarea matInput type="text" formControlName="body" rows="30"></textarea>
                            </mat-form-field>
                        </form>
            
                        <button 
                            [disabled]="(form && !form.valid) || submittingForm"
                            mat-raised-button 
                            setColor="primaryColour" 
                            color="primary" 
                            appPreventDoubleClick 
                            (throttledClick)="save()" 
                        >{{ 'Save' | translate: {Default: "Save"} }}</button>
                    </ng-template>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</time-off-menu>

