<h5 mat-dialog-title>
    <ng-container *ngIf="position; else newPosition">
        {{ 'Position' | translate: {Default: "Position"} }}
    </ng-container>
    <ng-template #newPosition>
        {{ 'CreateNewPosition' | translate: {Default: "Create New Position"} }}
    </ng-template>
</h5>

<mat-dialog-content>
    <mat-form-field appearance="fill" class="effective-date-form-field" >
        <mat-label>
            {{ 'EffectiveDate' | translate: {Default: "Effective Date"} }}
        </mat-label>

        <input matInput [matDatepicker]="effectiveDatePicker" (dateChange)="effectiveDateChange($event)" [(ngModel)]="effectiveDate">
        <span fxLayout="row" matSuffix>
            <mat-datepicker-toggle [for]="effectiveDatePicker"></mat-datepicker-toggle>
        </span>
        <mat-datepicker #effectiveDatePicker></mat-datepicker>
    </mat-form-field>

    <app-form-generator 
        *ngIf="isLoading === false"
        [formId]="formId"
        [formData]="formData"
        [emitFormDataTrigger]="getFormData"
        [targetEmployeeTablePermissions]="targetEmployeeTablePermissions"
        (emitFormData)="formDataEmitted($event)"
        (emitFormStatus)="formStatusUpdated($event)"
    ></app-form-generator>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
    <button mat-raised-button setColor="primaryColour" color="primary" [disabled]="formValid === false" appPreventDoubleClick (throttledClick)="getFormData = !getFormData">{{ 'Save' | translate: {Default: "Save"} }}</button> 
</mat-dialog-actions>