<div [formGroup]="parentGroup">
    <!-- <kendo-label
        style="display: block; margin: .8em 0 .2em"
        text="formElement.text"
    > -->
        <kendo-fileselect 
            [multiple]="false"
            [formControlName]="formElement.formControl"
        >
        </kendo-fileselect>
    <!-- </kendo-label> -->
</div>