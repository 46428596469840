<h5 mat-dialog-title>{{ 'CreateNewEmployee' | translate: {Default: "Create New Employee"} }}</h5>

<mat-dialog-content>
    <app-form-generator 
        [formId]="formId"
        [emitFormDataTrigger]="getFormData"
        (emitFormData)="formDataEmitted($event)"
        (emitFormStatus)="formStatusUpdated($event)"
    ></app-form-generator>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
    <button mat-raised-button appPreventDoubleClick (throttledClick)="getFormData = !getFormData" setColor="primaryColour" color="primary" [disabled]="formValid === false">{{ 'Save' | translate: {Default: "Save"} }}</button> 
</mat-dialog-actions>
