import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import {CustomReportsService} from "@app/modules/custom-reports/services/reports.service";
import {ReportBuilderComponent} from "@app/modules/custom-reports/components/report-builder/report-builder.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-custom-reports',
  templateUrl: './custom-reports.component.html',
  styleUrls: ['./custom-reports.component.scss']
})
export class CustomReportsComponent implements OnInit {
  reports$: Observable<any>;
  public displayedColumns: string[] = ['name'];

  constructor(
    private dialog: MatDialog,
    private reportsService: CustomReportsService,
    private router: Router,

  ) {
    this.reports$ = reportsService.getCustomReports();
  }

  ngOnInit(): void {}

  openNewReportDialog() {
    this.router.navigate(['Reports/Builder']);
    // const dialogConfig = new MatDialogConfig();
    //
    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    // dialogConfig.minWidth = 1200;
    //
    // const dialogRef = this.dialog.open(ReportBuilderComponent, dialogConfig);
  }

}
