import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-demo-form-dialog',
  templateUrl: './demo-form-dialog.component.html',
  styleUrls: ['./demo-form-dialog.component.scss']
})
export class DemoFormDialogComponent implements OnInit {
  formValid: boolean = false;
  formId: string;
  getFormData: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<DemoFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data)
  {
    this.formId = data.formId;
  } 

  ngOnInit(): void {
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  formDataEmitted(formData) {
    console.log(formData)
  }

  close(){
    this.dialogRef.close();
  }
}
