<app-layout>

    <mat-card>
        <mat-card-title class="header">
            <h5 appLocalizationPopupDirective localizationCode="BusinessRuleAutomation">{{ businessRuleId ? 'Edit' : 'Create'}} {{ 'BusinessRuleAutomation' | translate: {Default: "Business Rule Automation"} }}</h5>
            <div>
                <button class="mat-raised-button" (click)="close()">Close</button>
                <button mat-raised-button setColor="primaryColour" (click)="getFormData = !getFormData" [disabled]="!form.valid || !formValid" *ngIf="form" class="k-ml-4">Save</button>
            </div>
        </mat-card-title>

        <mat-card-content>
            <ng-container *ngIf="!shouldShowForm; else loaded">
                <ngx-skeleton-loader
                    count="8"
                    [theme]="{
                              'height.px': 50
                          }"
                ></ngx-skeleton-loader>
            </ng-container>

            <ng-template #loaded>
                <app-form-generator
                    [formId]="formId"
                    [formData]="formData"
                    [emitFormDataTrigger]="getFormData"
                    (emitFormData)="formDataEmitted($event)"
                    (emitFormStatus)="formStatusUpdated($event)"
                    (emitFormPristine)="formPristineEmitted($event)"
                ></app-form-generator>

                <ng-container [formGroup]="form">
                    <div class="form-section-title">
                        <h6>Trigger</h6>
                    </div>

                    <mat-form-field appearance="fill">
                        <mat-label>
                            Trigger Type
                        </mat-label>

                        <mat-select disableOptionCentering formControlName="triggerType">
                            <ng-container *ngFor="let triggerType of triggerTypes">
                                <mat-option [value]="triggerType.id">{{triggerType.name}}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>Form</mat-label>
                        <mat-select formControlName="tableId" disableOptionCentering [disabled]="!form.get('triggerType')?.value">
                            <ng-container *ngFor="let table of tables">
                                <mat-option [value]="table.id">{{ table.name }}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>Field</mat-label>
                        <mat-select formControlName="fieldId" disableOptionCentering [disabled]="!form.get('tableId')?.value">
                            <ng-container *ngFor="let field of fields">
                                <mat-option [value]="field.id">{{ field.name }}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>


                    <mat-form-field appearance="fill">
                        <mat-label>Condition</mat-label>
                        <mat-select disableOptionCentering formControlName="conditionType" [disabled]="!form.get('fieldId')?.value">
                            <ng-container *ngFor="let conditionType of filteredConditionTypes">
                                <mat-option [value]="conditionType.id">{{conditionType.name}}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>


                    <mat-form-field *ngIf="form.get('conditionType')?.value != 'DateBefore' && form.get('conditionType')?.value != 'DateAfter'" appearance="fill">
                        <mat-label>Value</mat-label>
                        <input matInput formControlName="valueOne">
                    </mat-form-field>

                    <!-- Datepicker Input -->
                    <mat-form-field *ngIf="form.get('conditionType')?.value == 'DateBefore' || form.get('conditionType')?.value == 'DateAfter'" appearance="fill">
                        <mat-label>Value</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="valueOne">
                        <span fxLayout="row" matSuffix>
                            <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
                        </span>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

<!--                    <mat-form-field appearance="fill">-->
<!--                        <mat-label>-->
<!--                            Value-->
<!--                        </mat-label>-->

<!--                        <input matInput [ngxMatDatetimePicker]="triggerValuePicker" placeholder="Choose a date" formControlName="valueOne">-->
<!--                        <mat-datepicker-toggle matSuffix [for]="triggerValuePicker"></mat-datepicker-toggle>-->
<!--                        <ngx-mat-datetime-picker #triggerValuePicker showSpinners="" showSeconds="" stepHour=""-->
<!--                                                 stepMinute="" stepSecond="" touchUi="" color=""-->
<!--                                                 enableMeridian="" disableMinute="" hideTime="">-->
<!--                            <ng-template>-->
<!--                                <span>OK</span>-->
<!--                            </ng-template>-->
<!--                        </ngx-mat-datetime-picker>-->
<!--                    </mat-form-field>-->


                    <div class="form-section-title">
                        <h6>Rules</h6>
                        <button mat-stroked-button class="add-localization-btn" setColor="primaryColour" color="primary" (click)="addNewRule()" [disabled]="rules.length >= 3">
                            <mat-icon class="material-icons-outlined">add</mat-icon>
                            Add Rule
                        </button>
                    </div>
                    <div class="form-section-contents properties">
                        <div class="section-row">
                            <ng-container formArrayName="rules">
                                <ng-container *ngFor="let propertyForm of rules.controls; let i = index">
                                    <div class="form-row">
                                        <ng-container>

                                            <business-rule-automation-rule [propertyForm]="propertyForm"
                                                                           [tables]="tables"
                                                                           [fields]="fieldsCache[propertyForm.value.tableId]"
                                                                           [conditionTypes]="conditionTypesCache[propertyForm.value.fieldId]"
                                                                           [index]="i"
                                                                           (deleteRequest)="deleteRule(i)"
                                            ></business-rule-automation-rule>


                                        </ng-container>

                                    </div>
                                </ng-container>

                            </ng-container>
                        </div>
                    </div>


                    <div class="form-section-title">
                        <h6>Outcomes</h6>
                    </div>

                    <mat-form-field appearance="fill">
                        <mat-label>
                            Select Action Type
                        </mat-label>

                        <mat-select disableOptionCentering formControlName="outcomeType" [disabled]="!form.valid"
                                    [matTooltip]="!form.valid ? 'Please fill out the form before adding an outcome' : ''"
                                    (selectionChange)="onOutcomeSelectionChange($event)"
                        >
                            <ng-container *ngFor="let outcome of outcomeTypes">
                                <mat-option [value]="outcome.id">{{outcome.name}}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>

                </ng-container>


                <ng-container *ngIf="businessRuleId != null">
                    <div class="notifications-container">
                        <app-business-rule-automation-notifications
                            [businessRuleId]="businessRuleId"
                            [notificationTypes]="notificationTypes"
                            [notificationRecipientTypes]="notificationRecipientTypes"
                        ></app-business-rule-automation-notifications>
                    </div>
                </ng-container>

            </ng-template>
        </mat-card-content>
    </mat-card>
</app-layout>
