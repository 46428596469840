import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { EmployeeService } from '@app/core/services/employee.service';
import { DataBindingDirective, GridDataResult } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { process } from "@progress/kendo-data-query";
import { employees } from "./employees";
import { images } from "./images";
import { routes } from '@app/consts';

@Component({
  selector: 'app-select-recipient-list',
  templateUrl: './select-recipient-list.component.html',
  styleUrls: ['./select-recipient-list.component.scss']
})
export class SelectRecipientListComponent implements OnInit {
  @Output() selectedRecipients = new EventEmitter<any>();
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  public gridData: any[] = employees;
  public gridView: any[];

  public mySelection: string[] = [];

  public ngOnInit(): void {
    this.gridView = this.gridData;
  }

  public onFilter(inputValue: string): void {
    this.gridView = process(this.gridData, {
      filter: {
        logic: "or",
        filters: [
          {
            field: "full_name",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "job_title",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "budget",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "phone",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "address",
            operator: "contains",
            value: inputValue,
          },
        ],
      },
    }).data;

    this.dataBinding.skip = 0;
  }

  private photoURL(dataItem: any): string {
    const code: string = dataItem.img_id + dataItem.gender;
    const image: any = images;

    return image[code];
  }

  private flagURL(dataItem: any): string {
    const code: string = dataItem.country;
    const image: any = images;

    return image[code];
  }

  emitSelectedRecipients(){
    let arr = [... this.mySelection];
    this.selectedRecipients.emit(arr);
  }

}
