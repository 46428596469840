import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs';
import { TimeOffService } from '../services/time-off.service';
import { TimeOffEmailTranslation } from '@app/modules/lookup/models/time-off.model';

@Component({
  selector: 'app-time-off-emails',
  templateUrl: './time-off-emails.component.html',
  styleUrls: ['./time-off-emails.component.scss']
})
export class TimeOffEmailsComponent implements OnInit {
  emails = [
    {
      id: 'AbsenceTimeOffApprovedManager',
      text: this.translate.instant('AbsenceTimeOffApprovedManager')
    },
    {
      id: 'AbsenceTimeOffCancelledManager',
      text: this.translate.instant('AbsenceTimeOffCancelledManager')
    },
    {
      id: 'AbsenceTimeOffCreatedManager',
      text: this.translate.instant('AbsenceTimeOffCreatedManager')
    },
    {
      id: 'AbsenceTimeOffDeletedManager',
      text: this.translate.instant('AbsenceTimeOffDeletedManager')
    },
    {
      id: 'AbsenceTimeOffDeniedManager',
      text: this.translate.instant('AbsenceTimeOffDeniedManager')
    },
    {
      id: 'AbsenceTimeOffApprovedEmployee',
      text: this.translate.instant('AbsenceTimeOffApprovedEmployee')
    },
    {
      id: 'AbsenceTimeOffCancelledEmployee',
      text: this.translate.instant('AbsenceTimeOffCancelledEmployee')
    },
    {
      id: 'AbsenceTimeOffCreatedEmployee',
      text: this.translate.instant('AbsenceTimeOffCreatedEmployee')
    },
    {
      id: 'AbsenceTimeOffDeletedEmployee',
      text: this.translate.instant('AbsenceTimeOffDeletedEmployee')
    },
    {
      id: 'AbsenceTimeOffDeniedEmployee',
      text: this.translate.instant('AbsenceTimeOffDeniedEmployee')
    },
    {
      id: 'AbsenceTimeOffEditedEmployee',
      text: this.translate.instant('AbsenceTimeOffEditedEmployee')
    },
    {
      id: 'AbsenceTimeOffEditedManager',
      text: this.translate.instant('AbsenceTimeOffEditedManager')
    },
  ];
  selectedEmail;
  loadingCultures: boolean;
  cultures: Culture[] = [];
  selectedCulture: Culture;
  loadingTranslations: boolean;
  translations: TimeOffEmailTranslation;
  showForm: boolean = false;

  form: FormGroup;
  submittingForm: boolean = false;

  constructor(
    private cultureService: CultureService,
    private translate: TranslateService,
    private overlayService: OverlayService,
    private timeOffService: TimeOffService,
    private snackbarService: SnackbarService,
    private formBuilder: FormBuilder) {
      this.form = this.formBuilder.group({
        culture: ['', Validators.required],
        subject: ['', Validators.required],
        body: ['', Validators.required]
      });

      // Subscribe to value changes of the form control
      this.form.get('culture').valueChanges.subscribe((value) => {
        this.selectedCulture = this.cultures.find( culture => culture.id === value)
        this.populateForm();
      });
    }

  ngOnInit(): void {
    this.getCultures();
  }

  getCultures() {
    this.loadingCultures = true;
    
    this.cultureService.getCultures()
    .pipe(
      finalize(
        () => {
          this.loadingCultures = false;
          this.getCultureTranslations(this.emails[0]);
        }
      )
    )
    .subscribe(
      res => {
        this.cultures = res;
        this.selectedCulture = this.cultures[0];
      }
    );
  }

  // get the translations for a culture
  getCultureTranslations(email: any) {
    this.loadingTranslations = true;
    this.selectedEmail = email;

    this.timeOffService.getEmailTranslations(this.selectedEmail?.id) 
    .pipe(
      finalize(
        () => {
          this.populateForm();
          this.loadingTranslations = false;
        }
      )
    )
    .subscribe(
      res => {
        this.translations = res;
      }
    )
  }

  populateForm() {
    let subject = this.translations.subject.find(translation => translation.culture === this.selectedCulture.id)
    let body = this.translations.body.find(translation => translation.culture === this.selectedCulture.id)

    if(this.form.get('culture').value !== this.selectedCulture.id){
      this.form.get('culture').setValue(this.selectedCulture.id);
    }

    this.form.patchValue({
      subject: subject.text,
      body: body.text
    });
  }

  save() {
    // Update the translation when the user presses save in the form
    this.overlayService.show();

    let submitData: TimeOffEmailTranslation = {
      subject: [
        {
          culture: this.form.get('culture').value,
          text: this.form.get('subject').value
        }
      ],
      body: [
        {
          culture: this.form.get('culture').value,
          text: this.form.get('body').value
        }
      ]
    }

    this.timeOffService.updateEmailTranslation(this.selectedEmail.id, submitData)
    .pipe(
      finalize(
        () => {
          this.overlayService.hide();
        }
      )
    )
    .subscribe(
      res => {
        this.snackbarService.openSnackBar(this.translate.instant('SavedSuccessfully'), 'clear', 'success');
        this.getCultureTranslations(this.selectedEmail);
      }
    );
  }

}
