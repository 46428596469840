import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {api_routes} from '@app/consts';
import {Culture, SetCurrentCulture} from '@app/shared/models/system-language/culture.model';
import {EnvironmentService} from "@app/core/services/environment.service";


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});
@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  _currentTranslations: any;

  constructor(private http: HttpClient, private envService: EnvironmentService) {}
  
  get currentTranslations(): Culture {
    return this._currentTranslations != null ? this._currentTranslations : null;
  }

  set currentTranslations(translations: any) {
    this._currentTranslations = translations;
  }

  getTranslations(cultureId: string): Observable<any> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.CULTURES}/${cultureId}/${api_routes.SYSTEM}`, {
      headers: headers
    });
  }

  updateTranslation(cultureId: string, translationId: string, translationValue: string) {
    const translationSubmit = {
      'text': translationValue
    };

    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.CULTURES}/${cultureId}/${api_routes.SYSTEM}/${translationId}`, translationSubmit, {
      headers: headers
    });
  }

  createNewTranslationCode(translationId: string) {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.GLOBALIZATION}/${api_routes.SYSTEM}`, translationId, {
      headers: headers
    });
  }

  getCurrentCulture(): Observable<Culture> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.SECURITY}/${api_routes.USERS}/${api_routes.GET_CURRENT_CULTURE}`, {
      headers: headers
    });
  }

  setCurrentCulture(culture: SetCurrentCulture) {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.SECURITY}/${api_routes.USERS}/${api_routes.SET_CURRENT_CULTURE}`, culture, {
      headers: headers
    });
  }
}
