export const series = {
    monthDataSeries1: {
        prices: [
            7838.4,
            7909.85,
            7892.75,
            7897.75,
            7820.05,
            7904.4,
            7872.2,
            7847.5,
            7849.55,
            7789.6,
            7736.35,
            7819.4,
            7875.35,
            7871.8,
            8076.5,
            8114.8,
            8193.55,
            8217.1,
            8235.05,
            8215.3,
            7126.6,
            7116.95,
            7203.7,
            7233.75,
            7451.0,
            7381.15,
            7348.95,
            7347.75,
            7311.25,
            7266.4,
            7253.25,
            7215.45,
            7266.35,
            7315.25,
            7237.2,
            7191.4,
            7238.95,
            7222.6,
            7114.25,
            7126.6,
            7116.95,
            7203.7,
            7233.75,
            7451.0,
            7381.15,
            7348.95,
            7347.75,
            7311.25,
            7266.4,
            7253.25,
            7215.45,
            7266.35,
            7315.25,
            7237.2,
            7191.4,
            7238.95,
            7222.6,
            7114.25,
            7126.6,
            7116.95,
            7203.7,
            7233.75,
            7451.0,
            7381.15,
            7348.95,
            7347.75,
            7311.25,
            7266.4,
            7253.25,
            7215.45,
            7266.35,
            7315.25,
            7237.2,
            7191.4,
            7238.95,
            7222.6,
            7114.25,
            7126.6,
            7116.95,
            7203.7,
            7233.75,
            7451.0,
            7381.15,
            7348.95,
            7347.75,
            7311.25,
            7266.4,
            7253.25,
            7215.45,
            7266.35,
            7315.25,
            7237.2,
            7191.4,
            7238.95,
            7222.6,
            7217.9,
            7359.3,
            7371.55,
            7371.15,
            7469.2,
            7429.25,
            7434.65,
            7451.1,
            7475.25,
            7566.25,
            7556.8,
            7525.55,
            7555.45,
            7560.9,
            7490.7,
            7527.6,
            7551.9,
            7514.85,
            7577.95,
            7592.3,
            7621.95,
            7707.95,
            7859.1,
            7815.7,
            7739.0,
            7778.7,
            7839.45,
            7756.45,
            7669.2,
            7580.45,
            7452.85,
            7617.25,
            7701.6,
            7606.8,
            7620.05,
            7513.85,
            7498.45,
            7575.45,
            7601.95,
            7589.1,
            7525.85,
            7569.5,
            7702.5,
            7812.7,
            7803.75,
            7816.3,
            7851.15,
            7912.2,
            7972.8,
            8145.0,
            8161.1,
            8121.05,
            8071.25,
            8088.2,
            8154.45,
            8148.3,
            8122.05,
            8132.65,
            8074.55,
            7952.8,
            7885.55,
            7733.9,
            7897.15,
            7973.15,
            7888.5,
            7842.8,
            7838.4,
            7909.85,
            7892.75,
            7897.75,
            7820.05,
            7904.4,
            7872.2,
            7847.5,
            7849.55,
            7789.6,
            7736.35,
            7819.4,
            7875.35,
            7871.8,
            8076.5,
            8114.8,
            8193.55,
            8217.1,
            8235.05,
            8215.3,
            8216.4,
            8301.55,
            8235.25,
            8229.75,
            8201.95,
            8164.95,
            8107.85,
            8128.0,
            8122.9,
            8165.5,
            8340.7,
            8423.7,
            8423.5,
            8514.3,
            8481.85,
            8487.7,
            8506.9,
            8626.2
            ],
        dates: [
            "02 Jan 2021",
            "05 Jan 2021",
            "06 Jan 2021",
            "07 Jan 2021",
            "08 Jan 2021",
            "09 Jan 2021",
            "12 Jan 2021",
            "13 Jan 2021",
            "14 Jan 2021",
            "15 Jan 2021",
            "16 Jan 2021",
            "19 Jan 2021",
            "20 Jan 2021",
            "21 Jan 2021",
            "22 Jan 2021",
            "23 Jan 2021",
            "27 Jan 2021",
            "28 Jan 2021",
            "29 Jan 2021",
            "30 Jan 2021",
            
            "02 Feb 2021",
            "05 Feb 2021",
            "06 Feb 2021",
            "07 Feb 2021",
            "08 Feb 2021",
            "09 Feb 2021",
            "12 Feb 2021",
            "13 Feb 2021",
            "14 Feb 2021",
            "15 Feb 2021",
            "16 Feb 2021",
            "19 Feb 2021",
            "20 Feb 2021",
            "21 Feb 2021",
            "22 Feb 2021",
            "23 Feb 2021",
            "27 Feb 2021",
            "28 Feb 2021",
            "29 Feb 2021",
            "30 Feb 2021",
            
            
            "02 Mar 2021",
            "05 Mar 2021",
            "06 Mar 2021",
            "07 Mar 2021",
            "08 Mar 2021",
            "09 Mar 2021",
            "12 Mar 2021",
            "13 Mar 2021",
            "14 Mar 2021",
            "15 Mar 2021",
            "16 Mar 2021",
            "19 Mar 2021",
            "20 Mar 2021",
            "21 Mar 2021",
            "22 Mar 2021",
            "23 Mar 2021",
            "27 Mar 2021",
            "28 Mar 2021",
            "29 Mar 2021",
            "30 Mar 2021",
            
            
            "02 Apr 2021",
            "05 Apr 2021",
            "06 Apr 2021",
            "07 Apr 2021",
            "08 Apr 2021",
            "09 Apr 2021",
            "12 Apr 2021",
            "13 Apr 2021",
            "14 Apr 2021",
            "15 Apr 2021",
            "16 Apr 2021",
            "19 Apr 2021",
            "20 Apr 2021",
            "21 Apr 2021",
            "22 Apr 2021",
            "23 Apr 2021",
            "27 Apr 2021",
            "28 Apr 2021",
            "29 Apr 2021",
            "30 Apr 2021",


            "02 May 2021",
            "05 May 2021",
            "06 May 2021",
            "07 May 2021",
            "08 May 2021",
            "09 May 2021",
            "12 May 2021",
            "13 May 2021",
            "14 May 2021",
            "15 May 2021",
            "16 May 2021",
            "19 May 2021",
            "20 May 2021",
            "21 May 2021",
            "22 May 2021",
            "23 May 2021",
            "27 May 2021",
            "28 May 2021",
            "29 May 2021",
            "30 May 2021",
            "03 Jun 2021",
            "04 Jun 2021",
            "05 Jun 2021",
            "06 Jun 2021",
            "07 Jun 2021",
            "10 Jun 2021",
            "11 Jun 2021",
            "12 Jun 2021",
            "13 Jun 2021",
            "14 Jun 2021",
            "17 Jun 2021",
            "18 Jun 2021",
            "19 Jun 2021",
            "20 Jun 2021",
            "21 Jun 2021",
            "24 Jun 2021",
            "25 Jun 2021",
            "26 Jun 2021",
            "27 Jun 2021",
            "28 Jun 2021",
            "31 Jun 2021",
            "01 Jul 2021",
            "02 Jul 2021",
            "03 Jul 2021",
            "04 Jul 2021",
            "07 Jul 2021",
            "08 Jul 2021",
            "09 Jul 2021",
            "10 Jul 2021",
            "11 Jul 2021",
            "14 Jul 2021",
            "16 Jul 2021",
            "17 Jul 2021",
            "18 Jul 2021",
            "21 Jul 2021",
            "22 Jul 2021",
            "23 Jul 2021",
            "24 Jul 2021",
            "28 Jul 2021",
            "29 Jul 2021",
            "30 Jul 2021",
            "31 Jul 2021",
            "01 Aug 2021",
            "04 Aug 2021",
            "05 Aug 2021",
            "06 Aug 2021",
            "07 Aug 2021",
            "08 Aug 2021",
            "11 Aug 2021",
            "12 Aug 2021",
            "13 Aug 2021",
            "14 Aug 2021",
            "15 Aug 2021",
            "18 Aug 2021",
            "19 Aug 2021",
            "20 Aug 2021",
            "21 Aug 2021",
            "22 Aug 2021",
            "25 Aug 2021",
            "26 Aug 2021",
            "27 Aug 2021",
            "28 Aug 2021",
            "29 Aug 2021",
            "03 Sep 2021",
            "04 Sep 2021",
            "05 Sep 2021",
            "06 Sep 2021",
            "09 Sep 2021",
            "10 Sep 2021",
            "11 Sep 2021",
            "12 Sep 2021",
            "13 Sep 2021",
            "16 Sep 2021",
            "17 Sep 2021",
            "18 Sep 2021",
            "19 Sep 2021",
            "23 Sep 2021",
            "24 Sep 2021",
            "25 Sep 2021",
            "26 Sep 2021",
            "27 Sep 2021",
            "30 Sep 2021",
            "31 Sep 2021",
            "01 Oct 2021",
            "02 Oct 2021",
            "03 Oct 2021",
            "06 Oct 2021",
            "07 Oct 2021",
            "08 Oct 2021",
            "09 Oct 2021",
            "10 Oct 2021",
            "13 Oct 2021",
            "14 Oct 2021",
            "15 Oct 2021",
            "16 Oct 2021",
            "17 Oct 2021",
            "20 Oct 2021",
            "21 Oct 2021",
            "22 Oct 2021",
            "23 Oct 2021",
            "24 Oct 2021",
            "27 Oct 2021",
            "28 Oct 2021"
        ]
    },
    // monthDataSeries2: {
    //   prices: [
    //     8423.7,
    //     8423.5,
    //     8514.3,
    //     8481.85,
    //     8487.7,
    //     8506.9,
    //     8626.2,
    //     8668.95,
    //     8602.3,
    //     8607.55,
    //     8512.9,
    //     8496.25,
    //     8600.65,
    //     8881.1,
    //     9040.85,
    //     8340.7,
    //     8165.5,
    //     8122.9,
    //     8107.85,
    //     8128.0
    //   ],
    //   dates: [
    //     "13 Oct 2021",
    //     "14 Oct 2021",
    //     "15 Oct 2021",
    //     "16 Oct 2021",
    //     "17 Oct 2021",
    //     "20 Oct 2021",
    //     "21 Oct 2021",
    //     "22 Oct 2021",
    //     "23 Oct 2021",
    //     "24 Oct 2021",
    //     "27 Oct 2021",
    //     "28 Oct 2021",
    //     "29 Oct 2021",
    //     "30 Oct 2021",
    //     "01 Dec 2021",
    //     "04 Dec 2021",
    //     "05 Dec 2021",
    //     "06 Dec 2021",
    //     "07 Dec 2021",
    //     "08 Dec 2021"
    //   ]
    // },
    // monthDataSeries3: {
    //   prices: [
    //     7114.25,
    //     7126.6,
    //     7116.95,
    //     7203.7,
    //     7233.75,
    //     7451.0,
    //     7381.15,
    //     7348.95,
    //     7347.75,
    //     7311.25,
    //     7266.4,
    //     7253.25,
    //     7215.45,
    //     7266.35,
    //     7315.25,
    //     7237.2,
    //     7191.4,
    //     7238.95,
    //     7222.6,
    //     7217.9,
    //     7359.3,
    //     7371.55,
    //     7371.15,
    //     7469.2,
    //     7429.25,
    //     7434.65,
    //     7451.1,
    //     7475.25,
    //     7566.25,
    //     7556.8,
    //     7525.55,
    //     7555.45,
    //     7560.9,
    //     7490.7,
    //     7527.6,
    //     7551.9,
    //     7514.85,
    //     7577.95,
    //     7592.3,
    //     7621.95,
    //     7707.95,
    //     7859.1,
    //     7815.7,
    //     7739.0,
    //     7778.7,
    //     7839.45,
    //     7756.45,
    //     7669.2,
    //     7580.45,
    //     7452.85,
    //     7617.25,
    //     7701.6,
    //     7606.8,
    //     7620.05,
    //     7513.85,
    //     7498.45,
    //     7575.45,
    //     7601.95,
    //     7589.1,
    //     7525.85,
    //     7569.5,
    //     7702.5,
    //     7812.7,
    //     7803.75,
    //     7816.3,
    //     7851.15,
    //     7912.2,
    //     7972.8,
    //     8145.0,
    //     8161.1,
    //     8121.05,
    //     8071.25,
    //     8088.2,
    //     8154.45,
    //     8148.3,
    //     8122.05,
    //     8132.65,
    //     8074.55,
    //     7952.8,
    //     7885.55,
    //     7733.9,
    //     7897.15,
    //     7973.15,
    //     7888.5,
    //     7842.8,
    //     7838.4,
    //     7909.85,
    //     7892.75,
    //     7897.75,
    //     7820.05,
    //     7904.4,
    //     7872.2,
    //     7847.5,
    //     7849.55,
    //     7789.6,
    //     7736.35,
    //     7819.4,
    //     7875.35,
    //     7871.8,
    //     8076.5,
    //     8114.8,
    //     8193.55,
    //     8217.1,
    //     8235.05,
    //     8215.3,
    //     8216.4,
    //     8301.55,
    //     8235.25,
    //     8229.75,
    //     8201.95,
    //     8164.95,
    //     8107.85,
    //     8128.0,
    //     8122.9,
    //     8165.5,
    //     8340.7,
    //     8423.7,
    //     8423.5,
    //     8514.3,
    //     8481.85,
    //     8487.7,
    //     8506.9,
    //     8626.2
    //   ],
    //   dates: [
    //     "02 May 2021",
    //     "05 May 2021",
    //     "06 May 2021",
    //     "07 May 2021",
    //     "08 May 2021",
    //     "09 May 2021",
    //     "12 May 2021",
    //     "13 May 2021",
    //     "14 May 2021",
    //     "15 May 2021",
    //     "16 May 2021",
    //     "19 May 2021",
    //     "20 May 2021",
    //     "21 May 2021",
    //     "22 May 2021",
    //     "23 May 2021",
    //     "27 May 2021",
    //     "28 May 2021",
    //     "29 May 2021",
    //     "30 May 2021",
    //     "03 Jun 2021",
    //     "04 Jun 2021",
    //     "05 Jun 2021",
    //     "06 Jun 2021",
    //     "07 Jun 2021",
    //     "10 Jun 2021",
    //     "11 Jun 2021",
    //     "12 Jun 2021",
    //     "13 Jun 2021",
    //     "14 Jun 2021",
    //     "17 Jun 2021",
    //     "18 Jun 2021",
    //     "19 Jun 2021",
    //     "20 Jun 2021",
    //     "21 Jun 2021",
    //     "24 Jun 2021",
    //     "25 Jun 2021",
    //     "26 Jun 2021",
    //     "27 Jun 2021",
    //     "28 Jun 2021",
    //     "31 Jun 2021",
    //     "01 Jul 2021",
    //     "02 Jul 2021",
    //     "03 Jul 2021",
    //     "04 Jul 2021",
    //     "07 Jul 2021",
    //     "08 Jul 2021",
    //     "09 Jul 2021",
    //     "10 Jul 2021",
    //     "11 Jul 2021",
    //     "14 Jul 2021",
    //     "16 Jul 2021",
    //     "17 Jul 2021",
    //     "18 Jul 2021",
    //     "21 Jul 2021",
    //     "22 Jul 2021",
    //     "23 Jul 2021",
    //     "24 Jul 2021",
    //     "28 Jul 2021",
    //     "29 Jul 2021",
    //     "30 Jul 2021",
    //     "31 Jul 2021",
    //     "01 Aug 2021",
    //     "04 Aug 2021",
    //     "05 Aug 2021",
    //     "06 Aug 2021",
    //     "07 Aug 2021",
    //     "08 Aug 2021",
    //     "11 Aug 2021",
    //     "12 Aug 2021",
    //     "13 Aug 2021",
    //     "14 Aug 2021",
    //     "15 Aug 2021",
    //     "18 Aug 2021",
    //     "19 Aug 2021",
    //     "20 Aug 2021",
    //     "21 Aug 2021",
    //     "22 Aug 2021",
    //     "25 Aug 2021",
    //     "26 Aug 2021",
    //     "27 Aug 2021",
    //     "28 Aug 2021",
    //     "29 Aug 2021",
    //     "03 Sep 2021",
    //     "04 Sep 2021",
    //     "05 Sep 2021",
    //     "06 Sep 2021",
    //     "09 Sep 2021",
    //     "10 Sep 2021",
    //     "11 Sep 2021",
    //     "12 Sep 2021",
    //     "13 Sep 2021",
    //     "16 Sep 2021",
    //     "17 Sep 2021",
    //     "18 Sep 2021",
    //     "19 Sep 2021",
    //     "23 Sep 2021",
    //     "24 Sep 2021",
    //     "25 Sep 2021",
    //     "26 Sep 2021",
    //     "27 Sep 2021",
    //     "30 Sep 2021",
    //     "31 Sep 2021",
    //     "01 Oct 2021",
    //     "02 Oct 2021",
    //     "03 Oct 2021",
    //     "06 Oct 2021",
    //     "07 Oct 2021",
    //     "08 Oct 2021",
    //     "09 Oct 2021",
    //     "10 Oct 2021",
    //     "13 Oct 2021",
    //     "14 Oct 2021",
    //     "15 Oct 2021",
    //     "16 Oct 2021",
    //     "17 Oct 2021",
    //     "20 Oct 2021",
    //     "21 Oct 2021",
    //     "22 Oct 2021",
    //     "23 Oct 2021",
    //     "24 Oct 2021",
    //     "27 Oct 2021",
    //     "28 Oct 2021"
    //   ]
    // }
};