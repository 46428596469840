
<app-layout>
  <mat-toolbar class="page-header" role="heading">
    <h1 appLocalizationPopupDirective localizationCode="CustomReports">{{ 'CustomReports' | translate: {Default: "Custom Reports"} }}</h1>
    <div>
      <button mat-flat-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openNewReportDialog()">
        <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> Create Custom Report
      </button>
    </div>
  </mat-toolbar>

  <div class="content">
    <ng-container *ngFor="let report of reports$ | async">
      <mat-card [routerLink]="report.href" class="mat-elevation-z0" *appHasFeature="report.featureCode">
        <div class="icon-div">
          <span class="material-icons-outlined">
              {{report.icon}}
          </span>
        </div>

        <div class="text">
          <mat-card-title class="header">
            {{report.name}}
          </mat-card-title>
          <mat-card-content>
            {{report.detail}}
          </mat-card-content>
        </div>
      </mat-card>
    </ng-container>
  </div>

  <app-footer></app-footer>
</app-layout>
