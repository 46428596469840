<h5 mat-dialog-title>Select A Position</h5>

<mat-dialog-content>
    <div *ngIf="selectedPosition" class="selected-position page-description-section">
        <h6>Selected Position: {{selectedPosition.name}}</h6>
    </div>

        <kendo-grid
            [data]="gridDataResult"
            [pageSize]="pageSize"
            [skip]="skip"
            [style.maxHeight.vh]="55" 
            [pageable]="{
                info: true,
                pageSizes: [5, 10, 20, 50, 100],
                previousNext: true
            }"
            (pageChange)="pageChange($event)"
            [loading]="isLoading"
            [selectable]="{ checkboxOnly: true, mode: 'single' }"
            (selectionChange)="selectionChange($event)"
            [sortable]="true"
            [sort]="sort"
            (sortChange)="sortChange($event)"
        >
        
            <ng-template kendoGridToolbarTemplate>
                <div class="">
                    <mat-form-field class="search-field" appearance="outline">
                        <input matInput
                            placeholder="Search..."
                            [(ngModel)]="searchValue"
                            (input)="search()"
                        >
                        <mat-icon matPrefix class="material-icons-outlined">
                            search
                        </mat-icon>
                    </mat-form-field>
                </div>
                <div class="mt-2">
                    <filter-control [categories]="filterCategories" (callback)="filterCallback($event)"></filter-control>
                </div>
            </ng-template>
        
            <kendo-grid-checkbox-column
                    [width]="80"
                    showSelectAll="true"
            ></kendo-grid-checkbox-column>

            <kendo-grid-column field="name" title="Name" [width]="150">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical">
                    <span class="position-name">
                        {{dataItem.name}}
                    </span>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="employees" title="Employees" [width]="250" [headerStyle]="{ 'display': 'flex', 'justify-content': 'center' }">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical employee-list" [matTooltip]="showEmployeeTooltip(dataItem.employees)">
                        <ng-container *ngIf="dataItem.employees.length > 1">
                            <mat-icon class="material-icons-outlined employee-list-icon" [matBadge]="dataItem.employees.length">
                                account_circle
                            </mat-icon>
                        </ng-container>

                        <ng-container *ngIf="dataItem.employees.length == 1">
                            <ng-container *ngFor="let employee of dataItem.employees" >
                                <div class="employee-name">
                                    <app-employee-image [employeeId]="employee.id" [width]="35" [height]="35" [round]="true"></app-employee-image>
                
                                    <span class="employee">
                                        {{employee.firstName}} {{employee.lastName}}
                                    </span>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-template>
            </kendo-grid-column>
        
            <kendo-grid-column
                    *ngFor="let col of columns"
                    [width]="200"
                    [field]="col.field"
                    [title]="col.title"
            ></kendo-grid-column>

            <kendo-grid-column field="costCenters" title="Cost Centers" [width]="150">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="grid-cell-vertical cost-centers">
                        <ng-container *ngFor="let costCenter of dataItem.costCenters" >
                            <span class="cost-center">
                                {{costCenter.costCenter.name}} <ng-container *ngIf="costCenter.percentAllocated">({{costCenter.percentAllocated}}%)</ng-container> <ng-container *ngIf="!costCenter.percentAllocated">(100%)</ng-container>
                            </span>
                        </ng-container>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="startDate" title="Start Date" [width]="150">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.startDate | date:'mediumDate'}}
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="endDate" title="End Date" [width]="150">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.endDate | date:'mediumDate'}}
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>

        <kendo-grid-messages 
            [noRecords]="isLoading ? '' : translate.instant('Grid-NoRecordsAvailable')"
            [pagerItemsPerPage]="translate.instant('Grid-Pager-ItemsPerPage')" 
            [pagerItems]="translate.instant('Grid-Pager-Items')"
            [pagerOf]="translate.instant('Grid-Pager-Of')"
        ></kendo-grid-messages>

</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!selectedPosition">Save</button>
</mat-dialog-actions>

