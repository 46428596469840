import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectionList } from '@angular/material/list';
import { ActivatedRoute } from '@angular/router';
import { routes } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BenefitsGroupVerbose, DeductionFrequencies } from '../../models/benefits-group.model';
import { BenefitsGroupsService } from '../../services/benefits-groups.service';

@Component({
  selector: 'app-benefit-group-editor',
  templateUrl: './benefit-group-editor.component.html',
  styleUrls: ['./benefit-group-editor.component.scss']
})
export class BenefitGroupEditorComponent implements OnInit {
  @ViewChild('selectionList', {static: false}) private selectionList: MatSelectionList; 
  public routes: typeof routes = routes;
  group: BenefitsGroupVerbose;
  form: UntypedFormGroup;
  cultures: Culture[];
  loading: boolean;
  groupId: string;
  deductionFrequencyOptions: Observable<DeductionFrequencies[]>;

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private cultureService: CultureService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private benefitsGroupsService: BenefitsGroupsService,
  ) { }

  ngOnInit(): void {
    this.cultureService.getCultures().subscribe( res => { this.cultures = res } );
    this.deductionFrequencyOptions = this.benefitsGroupsService.getDeductionFrequencies();
    this.groupId = this.getIdInURL();

    if(this.groupId !== null){
      this.getGroup();
    }
    else {
      this.loading = false; 
      this.createForm();
    }
  }

  getIdInURL(): string {
    let IdInURL: string;

    this.route.paramMap.subscribe(
        params => IdInURL = params.get('groupId')
    );

    return IdInURL;
  }

  getGroup() {
    this.loading = true;

    this.benefitsGroupsService.getGroup(this.groupId)
    .pipe(
      finalize(() => this.loading = false)
    )
    .subscribe(
      res => {
        this.group = res;
        this.createForm();
      }
    )
  }

  createForm() {
    this.form = this.fb.group({
      id: [this.group ? this.group.id : null],
      asOf: [moment().format()],
      name: this.fb.array([]),
      deductionFrequency: [this.group ? this.group.deductionFrequency?.id : null],
      changeReason: [''],
      changeReasonComments: [''],
    });

    if(this.groupId){
      this.group.name.forEach(
        localization => {
          this.addNewLocalization(localization.culture, localization.text);
        }
      );
    }
    else {
      this.addNewLocalization();
    }
  }

  get localizations() {
    return this.form.controls["name"] as UntypedFormArray;
  }

  //Create a textLocalization form group object to add to the localizations form array
  addNewLocalization(culture?: string, text?: string) {
    const localizationForm = this.fb.group({
      culture: [culture || '', Validators.required],
      text: [text || '', Validators.required]
    });

    this.localizations.push(localizationForm);
  }

  deleteLocalization(index: number) {
    this.localizations.removeAt(index);
  }

  save() {
    this.overlayService.show();

    if(this.groupId){
      this.benefitsGroupsService.updateGroup(this.form.value)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar('Group updated successfully', 'clear', 'success');
        }
      );
    }
    else {
      this.benefitsGroupsService.createGroup(this.form.value)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar('Group created successfully', 'clear', 'success');
        }
      );
    }
  }

  close() {
    // this.dialogRef.close();
  }

  

}
