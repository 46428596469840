<h5 mat-dialog-title appLocalizationPopupDirective [localizationCode]="title">
    {{ translate.instant(title) }}
</h5>

<mat-dialog-content>
    <div>
        <div class="label" appLocalizationPopupDirective localizationCode="GoalPlan-Employee-Employee">{{ translate.instant('GoalPlan-Employee-Employee') }}</div>
        <div class="value">
            <div class="employee-name">
                <app-employee-image [employeeId]="details.employee?.id" [width]="35" [height]="35" [round]="true"></app-employee-image>

                <span class="employee">
                    {{details.employee?.firstName}} {{details.employee?.lastName}}
                </span>
            </div>
        </div>
    </div>
    <div *ngIf="details.status">
        <div class="label" appLocalizationPopupDirective localizationCode="GoalPlan-Employee-Status">{{ translate.instant('GoalPlan-Employee-Status') }}</div>
        <div class="value">
            <mat-chip-list>
                <mat-chip 
                    [ngClass]="{
                        'red-chip': details.status?.id === 'Archived',
                        'green-chip': details.status?.id === 'Complete',
                        'blue-chip': details.status?.id === 'Active'
                    }"
                >{{ details.status?.name }}</mat-chip>
            </mat-chip-list>
        </div>
    </div>
    <div *ngIf="details.goalPlan?.name">
        <div class="label" appLocalizationPopupDirective localizationCode="GoalPlan-Employee-GoalPlan">{{ translate.instant('GoalPlan-Employee-GoalPlan') }}</div>
        <div class="value">{{ details.goalPlan?.name }}</div>
    </div>
    <div *ngIf="details.goalPlan?.description">
        <div class="label" appLocalizationPopupDirective localizationCode="GoalPlan-Employee-Description">{{ translate.instant('GoalPlan-Employee-Description') }}</div>
        <div class="value">{{ details.goalPlan?.description }}</div>
    </div>
    <div *ngIf="details.createdOn">
        <div class="label" appLocalizationPopupDirective localizationCode="GoalPlan-Employee-CreatedOn">{{ translate.instant('GoalPlan-Employee-CreatedOn') }}</div>
        <div class="value">{{ moment(details.createdOn).format('lll') }}</div>
    </div>
    <div *ngIf="details.createdBy">
        <div class="label" appLocalizationPopupDirective localizationCode="GoalPlan-Employee-CreatedBy">{{ translate.instant('GoalPlan-Employee-CreatedBy') }}</div>
        <div class="value">
            <div class="employee-name">
                <app-employee-image [employeeId]="details.createdBy?.id" [width]="35" [height]="35" [round]="true"></app-employee-image>

                <span class="employee">
                    {{details.createdBy?.firstName}} {{details.createdBy?.lastName}}
                </span>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Cancel</button>
</mat-dialog-actions>