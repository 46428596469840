<app-security-section>
  <mat-card>
    <mat-card-title class="header">
      <h5 mat-dialog-title *ngIf="securityRoleId" appLocalizationPopupDirective localizationCode="EditSecurityRole">{{ 'EditSecurityRole' | translate: {Default: "Edit Security Role"} }}</h5>
      <h5 mat-dialog-title *ngIf="!securityRoleId" appLocalizationPopupDirective localizationCode="CreateASecurityRole">{{ 'CreateASecurityRole' | translate: {Default: "Create a Security Role"} }}</h5>

      <div class="buttons-container">
        <button mat-stroked-button setColor="primaryColour" color="primary" (click)="copySecurityRole()" *ngIf="securityRoleId">
          <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">copy</mat-icon>
          {{ 'Copy' | translate: {Default: "Copy"} }}
        </button>
        <button mat-stroked-button setColor="primaryColour" color="primary" (click)="deleteSecurityRole()" *ngIf="securityRoleId">
          <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">delete</mat-icon>
          {{ 'Delete' | translate: {Default: "Delete"} }}
        </button>
        <button mat-raised-button (click)="navigateToSecurityRoles()">{{ 'Close' | translate: {Default: "Close"} }}</button>
        <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid">Save</button>
      </div>
    </mat-card-title>
    <mat-card-content>
      <div class="position-details-form">
        <div *ngIf="!isLoading else loading" [formGroup]="form">
          <div class="form-section">
            <div class="form-section-contents">
              <div class="section-row">
                <mat-form-field appearance="fill">
                  <mat-label>
                    {{ 'SecurityRoleName' | translate: {Default: "Security Role Name"} }}
                  </mat-label>

                  <input matInput
                         formControlName="name">
                </mat-form-field>
              </div>
              <div class="section-row">
                <mat-form-field appearance="fill">
                  <mat-label>
                    {{ 'SecurityRoleType' | translate: {Default: "Role Type"} }}
                  </mat-label>

                  <mat-select disableOptionCentering formControlName="roleType">
                    <ng-container *ngIf="roleTypes | async as roleTypes">
                      <mat-option></mat-option>
                      <mat-option *ngFor="let roleType of roleTypes" [value]="roleType.id">{{roleType.name}}</mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="form-section-contents">
                <div class="section-row">
                  <ng-container formArrayName="description">
                    <ng-container *ngFor="let localizationForm of localizations.controls; let i = index">
                      <div class="section-row">
                        <div class="localization-row" [formGroup]="localizationForm">
                          <mat-form-field appearance="fill">
                            <mat-label>
                              {{ 'SelectLanguage' | translate: {Default: "Select Language"} }}
                            </mat-label>

                            <mat-select disableOptionCentering formControlName="culture">
                              <mat-option *ngFor="let culture of cultures" [value]="culture.id">{{culture.text}}</mat-option>
                            </mat-select>
                          </mat-form-field>

                          <mat-form-field appearance="fill">
                            <mat-label>
                              {{ 'Description' | translate: {Default: "Description"} }}
                            </mat-label>

                            <input matInput
                                   formControlName="text">
                          </mat-form-field>

                          <mat-icon 
                            setColor 
                            [setColorRules]="[
                                {
                                    color: 'primaryColour',
                                    events: ['hover']
                                }
                            ]" 
                            class="material-icons-outlined" 
                            (click)="deleteLocalization(i)" 
                            matTooltip="Delete"
                          >delete</mat-icon>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                  <button *ngIf="localizations?.controls?.length < cultures?.length" mat-stroked-button class="add-localization-btn" setColor="primaryColour" color="primary" (click)="addNewLocalization()">
                    <mat-icon class="material-icons-outlined">add</mat-icon>
                    {{ 'AddTextLocalization' | translate: {Default: "Add Text Localization"} }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="k-d-flex-row">
            <div class="">
              <div class="title k-mb-4">
                {{ 'Features' | translate: {Default: "Features"} }}
              </div>
              <kendo-treeview
                setColor
                [setColorRules]="[
                  {
                    color: 'primaryColour',
                    target: 'kendo-treeview'
                  }
                ]" 
                [nodes]="data"
                textField="name"
                [children]="children"
                [hasChildren]="hasChildren"
                checkBy="id"
                [filterable]="true"
                [(checkedKeys)]="checkedKeys"
                [kendoTreeViewCheckable]="checkableSettings"
              >
              </kendo-treeview>
            </div>

            <div class="grid-container k-d-flex-col k-mb-3 k-pb-3 k-mx-6" *ngIf="securityRoleId && (securityRole.id !== 'rle_Employee' && securityRole.id !== 'rle_Manager')">
              <app-module-access-by-organization
                class="k-ml-6"
                [securityRoleId]="securityRoleId"
              ></app-module-access-by-organization>

              <app-users-in-role
                class="k-ml-6"
                [securityRoleId]="securityRoleId"
              ></app-users-in-role>
            </div>
          </div>

        </div>


        <ng-template #loading>
          <div class="loading-container">
            <ngx-skeleton-loader count="5" [theme]="{ 'height.px': 30}"></ngx-skeleton-loader>
          </div>
        </ng-template>
      </div>


    </mat-card-content>
  </mat-card>
</app-security-section>
