import { Component, Input, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { PdfViewerDialogComponent } from '@app/shared/components/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { Checklist } from '../../models/checklist.model';

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss']
})
export class ChecklistComponent implements OnInit {
  @Input() data: any;
  numberOfChecked = 0;
  checkedPercentage = 0;

  constructor(
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getChecklistProgress();
  }

  checkboxChange($event: MatCheckboxChange) {

  }

  getChecklistProgress() {
    let checked = 0;

    this.data.list.forEach(element => {
      if (element.checked) {
        checked++;
      }
    });
    
    this.numberOfChecked = checked;
    this.checkedPercentage = (this.numberOfChecked/this.data.list.length) * (100/1);
  }

  openPdfViewerDialog(url: string, signatureRequired: boolean) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '80%';

    dialogConfig.data = {
        pdfUrl: url,
        signatureRequired: signatureRequired
    };

    const dialogRef = this.dialog.open(PdfViewerDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.snackbarService.openSnackBar('Form saved successfully', 'clear', 'success');
        }
      }
    ); 
  }

}
