import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Letter } from '@app/modules/letter-generator/models/leter.model';
import { LetterService } from '@app/modules/letter-generator/services/letter.service';
import { finalize } from 'rxjs/operators';
import { PreviewLetterDialogComponent } from '../preview-letter-dialog/preview-letter-dialog.component';

@Component({
  selector: 'app-select-letter-list',
  templateUrl: './select-letter-list.component.html',
  styleUrls: ['./select-letter-list.component.scss']
})
export class SelectLetterListComponent implements OnInit {
  @Output() letterSelected = new EventEmitter<Letter>();
  isLoading: boolean = true;
  letters: Letter[];
  selectedLetter: Letter;
  dataSource = new MatTableDataSource<Letter>();
  selection = new SelectionModel<Letter>(false, []);
  displayedColumns: string[] = ['select', 'name', 'description', 'controls'];

  constructor(
    private letterService: LetterService,
    private dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getLetters();
  }

  getLetters() {
    this.isLoading = true;

    this.letterService.getLetters()
    .pipe(
      finalize( () => {
        this.isLoading = false;
      })
    )
    .subscribe( 
      res => {
        this.letters = res;
      });
  }

  checkboxChange(event: any, row: any) {
    if(event.checked === true){
      this.selectedLetter = row;
    }
    else {
      this.selectedLetter = null;
    }

    this.letterSelected.emit(this.selectedLetter);
  }

  openLetterPreviewDialog(letter: Letter) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      letter: letter
    };

    const dialogRef = this.dialog.open(PreviewLetterDialogComponent, dialogConfig);
  }

}
