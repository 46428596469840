<app-employee-details>
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
            <h5 appLocalizationPopupDirective localizationCode="ToilHistory">
                {{ 'ToilHistory' | translate: {Default: "Time Off In Lieu History"} }}
            </h5>
        </mat-card-title>
        <mat-card-content>
            <div class="top-container">
                <app-employee-toil-policy [employeeId]="employeeId"></app-employee-toil-policy>
            </div>

            <app-employee-absences-toil [employeeID]="employeeId"></app-employee-absences-toil>

        </mat-card-content>
      </mat-card>
</app-employee-details>
