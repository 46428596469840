import { Component, Inject, OnInit } from '@angular/core';
import { GoalPlanService } from '../../services/goal-plan.service';
import { finalize } from 'rxjs';
import { GoalPlanPublishHistory } from '../../models/goal-plan.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SortDescriptor } from '@progress/kendo-data-query';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-goal-plan-publish-history-dialog',
  templateUrl: './goal-plan-publish-history-dialog.component.html',
  styleUrls: ['./goal-plan-publish-history-dialog.component.scss']
})
export class GoalPlanPublishHistoryDialogComponent implements OnInit {
  goalPlanId: string;
  isLoading: boolean = true;
  gridData: GoalPlanPublishHistory[];
  public sort: SortDescriptor[] = [{ field: "publishedOn", dir: "desc" }];
  
  constructor(
    private goalPlanService: GoalPlanService,
    public translate: TranslateService,
    private dialogRef: MatDialogRef<GoalPlanPublishHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ){
    this.goalPlanId = data.goalPlanId;
  }

  ngOnInit(): void {
    this.getGoalPlanPublishHistory();
  }

  getGoalPlanPublishHistory() {
    this.isLoading = true;

    this.goalPlanService.getGoalPlanPublishHistory(this.goalPlanId)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridData = res?.data
        }
      );
  }

  close(){
    this.dialogRef.close()
  }

}
