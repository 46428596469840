<app-benefits>
    <mat-card>
        <mat-card-title class="header">
            <h4 appLocalizationPopupDirective localizationCode="Benefits-Plan">{{ 'Benefits-Plan' | translate: {Default: "Plan"} }}</h4>

            <div class="buttons-container" *ngIf="form">
                <button mat-raised-button routerLink="{{routes.BENEFITS}}{{routes.PLANS}}">Return To Plans List</button> 
                <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid">Save</button> 
            </div>
        </mat-card-title>
        <mat-card-content>
            
            <div class="plan-details-form">
                <ng-container *ngIf="form === undefined">
                    <ngx-skeleton-loader
                        count="5"
                        [theme]="{ 
                            'height.px': 50
                        }"
                    ></ngx-skeleton-loader>
                </ng-container>

                <form class="form" *ngIf="form" [formGroup]="form">
                
                    <div class="form-section">
                        <div class="form-section-title">
                            <h6>Name Localizations</h6>
                
                            <button mat-stroked-button setColor="primaryColour" color="primary" (click)="addNewLocalization()">
                                <mat-icon class="material-icons-outlined">add</mat-icon> Add Name Localization
                            </button>
                        </div>
                        <div class="form-section-contents">
                            <div class="section-row">
                                <ng-container formArrayName="name">
                                    <ng-container *ngFor="let localizationForm of localizations.controls; let i = index">
                                        <div class="form-row">
                                            <div [formGroup]="localizationForm">
                                                <mat-form-field appearance="fill">
                                                    <mat-label>
                                                        Select Language
                                                    </mat-label>
                        
                                                    <mat-select disableOptionCentering formControlName="culture">
                                                        <mat-option *ngFor="let culture of cultures" [value]="culture.id">{{culture.text}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                        
                                                <mat-form-field appearance="fill">
                                                    <mat-label>
                                                        Name
                                                    </mat-label>
                        
                                                    <input matInput
                                                        formControlName="text">
                                                </mat-form-field>
                                            </div>
                                            <mat-icon class="material-icons-outlined" (click)="deleteLocalization(i)" matTooltip="Delete">delete</mat-icon> 
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                
                    <div class="form-section">
                        <div class="form-section-title">
                            <h6>Description Localizations</h6>
                
                            <button mat-stroked-button setColor="primaryColour" color="primary" (click)="addNewDescriptionLocalization()">
                                <mat-icon class="material-icons-outlined">add</mat-icon> Add Description Localization
                            </button>
                        </div>
                        <div class="form-section-contents">
                            <div class="section-row">
                                <ng-container formArrayName="description">
                                    <ng-container *ngFor="let localizationForm of descriptionsLocalizations.controls; let i = index">
                                        <div class="form-row">
                                            <div class="inputs" [formGroup]="localizationForm">
                                                <mat-form-field appearance="fill">
                                                    <mat-label>
                                                        Select Language
                                                    </mat-label>
                        
                                                    <mat-select disableOptionCentering formControlName="culture">
                                                        <mat-option *ngFor="let culture of cultures" [value]="culture.id">{{culture.text}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>

                                                <mat-form-field appearance="fill">
                                                    <mat-label>
                                                        Description
                                                    </mat-label>
                                            
                                                    <textarea matInput formControlName="text"></textarea>
                                                </mat-form-field>
                                            </div>
                                            <mat-icon class="material-icons-outlined" (click)="deleteDescriptionLocalization(i)" matTooltip="Delete">delete</mat-icon> 
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                
                    <div class="form-section">
                        <div class="form-section-title">
                            <h6></h6>
                        </div>
                        <div class="form-section-contents">
                            <div class="section-row">
                                <mat-form-field appearance="fill">
                                    <mat-label>
                                        Contract Number
                                    </mat-label>
                                    
                                    <input 
                                        matInput
                                        type="text"
                                        formControlName="contractNumber">
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>
                                        Plan Type
                                    </mat-label>
                            
                                    <mat-select disableOptionCentering formControlName="planType">
                                        <ng-container *ngIf="planTypeOptions | async as planTypeOptions">
                                            <mat-option></mat-option>
                                            <mat-option 
                                                *ngFor="let option of planTypeOptions" 
                                                [value]="option.id">
                                                    {{option.name}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                
                                <mat-form-field appearance="fill">
                                    <mat-label>
                                        Rate Type
                                    </mat-label>
                            
                                    <mat-select disableOptionCentering formControlName="rateType">
                                        <ng-container *ngIf="rateTypeOptions | async as rateTypeOptions">
                                            <mat-option></mat-option>
                                            <mat-option 
                                                *ngFor="let option of rateTypeOptions" 
                                                [value]="option.id">
                                                    {{option.name}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                
                                <mat-form-field appearance="fill">
                                    <mat-label>
                                        Plan Provider
                                    </mat-label>
                            
                                    <mat-select disableOptionCentering formControlName="planProvider">
                                        <ng-container *ngIf="planProviderOptions | async as planProviderOptions">
                                            <mat-option></mat-option>
                                            <mat-option 
                                                *ngFor="let option of planProviderOptions.data" 
                                                [value]="option.id">
                                                    {{option.text}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                
                    <div class="form-section">
                        <div class="form-section-title">
                            <h6></h6>
                        </div>
                        <div class="form-section-contents">
                            <div class="section-row">
                                
                                <mat-form-field  appearance="fill">
                                    <mat-label>
                                        Start Date
                                    </mat-label>
                                
                                    <input matInput [matDatepicker]="startDatepicker" formControlName="startDate">
                                    <span fxLayout="row" matSuffix>
                                        <mat-datepicker-toggle  [for]="startDatepicker"></mat-datepicker-toggle>
                                    </span>
                                    <mat-datepicker #startDatepicker></mat-datepicker> 
                                    <mat-hint></mat-hint>
                                </mat-form-field>
                
                                <mat-form-field  appearance="fill">
                                    <mat-label>
                                        End Date
                                    </mat-label>
                                
                                    <input matInput [matDatepicker]="endDatepicker" formControlName="endDate">
                                    <span fxLayout="row" matSuffix>
                                        <mat-datepicker-toggle  [for]="endDatepicker"></mat-datepicker-toggle>
                                    </span>
                                    <mat-datepicker #endDatepicker></mat-datepicker> 
                                    <mat-hint></mat-hint>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            
            <div class="right">
                <app-benefits-plan-coverage-list [planId]="planId"></app-benefits-plan-coverage-list>

                <app-benefits-plan-documents [planId]="planId"></app-benefits-plan-documents>
            </div>
        </mat-card-content>
    </mat-card>

</app-benefits>
