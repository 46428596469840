<mat-card>
    <!-- <app-create-form></app-create-form>-->
    <mat-card-title class="header">
        <h5>New Hires</h5>
        <div>
            <app-settings-menu></app-settings-menu>
        </div>
    </mat-card-title>
    <mat-card-content>
        <app-skeleton-table *ngIf="isLoading"></app-skeleton-table>

        <app-table
            *ngIf="newHires && !isLoading"
            [tableData]="newHires"
            [tableColumns]="tableColumns"
            (sort)="sortData($event)"
            [isFilterable]="true"
            [isPageable]="true"
            [paginationSizes]="[5, 10, 15, 20]"
            [defaultPageSize]="pageSize"
            [pagination]="pagination"
        ></app-table>
    </mat-card-content>
</mat-card>

