import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { modules } from '@app/consts/modules';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-employee-goals-page',
  templateUrl: './employee-goals-page.component.html',
  styleUrls: ['./employee-goals-page.component.scss']
})
export class EmployeeGoalsPageComponent implements OnInit {
  navList = [
    {
      text: 'Performance-EmployeeGoals',
      route: 'EmployeeGoals'
    },
    {
      text: 'Performance-EmployeeReviews',
      route: 'EmployeeReviews'
    }
  ]

  section: string = "EmployeeGoals";
  public modules: typeof modules = modules;

  constructor(
    public translate: TranslateService,
    private route: ActivatedRoute,
  ) { }
  

  ngOnInit(): void {
  }

  get employeeId(): string {
    let employeeId: string;
    
    this.route.paramMap.subscribe(
      params => employeeId = params.get("id")
    )
      
    return employeeId;
  }

  navigateToSection(section: string) {
    // this.router.navigateByUrl(`${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_SECURITY}/${section}`);
    this.section = section;
  }

}
