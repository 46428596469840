import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { api_routes, db_tables } from '@app/consts';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { GoalDialogComponent } from '@app/modules/performance/components/goal-dialog/goal-dialog.component';
import { AllGoalsListItem, EmployeeAssignedGoalPlan, EmployeeGoal, EmployeeGoalSubmit, Goal } from '@app/modules/performance/components/goal-plans/models/goal-plan.model';
import { GoalPlanService } from '@app/modules/performance/components/goal-plans/services/goal-plan.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { defer, finalize, forkJoin } from 'rxjs';

@Component({
  selector: 'app-select-permissible-employee-goal-plan-dialog',
  templateUrl: './select-permissible-employee-goal-plan-dialog.component.html',
  styleUrls: ['./select-permissible-employee-goal-plan-dialog.component.scss']
})
export class SelectPermissibleEmployeeGoalPlanDialogComponent implements OnInit {
  public columns: any[] = [
    { field: "name", tableId: "tfi_PGpName", title: "Name" }
  ];

  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  
  formValid: any;
  user$: any;
  selectedGoal: Goal

  goalPlans: EmployeeAssignedGoalPlan[];
  pageSize: number = 5;

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private oidcAuthService: OidcAuthService,
    private dialogRef: MatDialogRef<SelectPermissibleEmployeeGoalPlanDialogComponent>,
    private goalPlanService: GoalPlanService,
  ) {
    this.user$ = this.oidcAuthService.userProfile;
  }

  ngOnInit(): void {
    this.getGoalPlans();
  }

  getGoalPlans() {
    this.isLoading = true;

    this.goalPlanService.getEmployeeGoalPlans(this.user$.userId, '100', 0)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.goalPlans = res?.data.filter(goalPlan => goalPlan.employeeGoalEntryAllowed === true)
        }
      );
  }

  save() {
    this.dialogRef.close(this.selectedGoal);
  }

  close() {
    this.dialogRef.close();
  }

  selectedRowsChanged(selectionEvent: SelectionEvent){
    if(selectionEvent?.selectedRows[0]?.dataItem){
      this.selectedGoal = selectionEvent.selectedRows[0].dataItem;
    }
    else {
      this.selectedGoal = null;
    }
  }
}

