<h5 mat-dialog-title>Select A Goal</h5>

<mat-dialog-content>
    <div *ngIf="selectedGoal" class="selected-goal page-description-section">
        <h6>Selected Goal: {{selectedGoal.objective}}</h6>
    </div>

    <app-data-grid-comp
        [tableId]="db_tables.EmployeeGoals"
        [columns]="columns"
        [filterCategories]="filterCategories"
        [view]="view"
        [gridDataResult]="gridDataResult"
        [isLoading]="isLoading"
        [pageSize]="pageSize"
        [skip]="skip"
        [rowSelectionSettings]="{
            canSelectRows: true,
            selectRowBy: 'id',
            showDeleteAllButton: false,
            selectableMode: 'single'
        }"
        searchable="true"
        hideActionButtons="true"
        [sortable]="{
            mode: 'multiple'
        }"
        [sortableColumns]="sortableColumns"
        (emitSortEvent)="sortChange($event)"
        [isLoading]="isLoading"
        [clearSelectedItems]="clearSelectedItems"
        (emitPageChangeEvent)="pageChange($event)"
        (emitDeleteEvent)="deleteGoals($event)"
        (emitSearchEvent)="search($event)"
        (emitFilterEvent)="filterCallback($event)"
        (emitSortEvent)="sortChange($event)"
        (emitSelectedRows)="selectedRowsChanged($event)"
    ></app-data-grid-comp>
    
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!selectedGoal">Save</button>
</mat-dialog-actions>