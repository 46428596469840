<app-data-grid-comp
    [tableId]="db_tables.EmployeeGoals"
    [columns]="columns"
    [bindingType]="bindingType"
    [gridDataResult]="gridDataResult"
    [isLoading]="isLoading"
    [pageSize]="pageSize"
    [skip]="skip"
    [category]="category"
    clickableRows="false"
    [isLoading]="isLoading"
    [filterCategories]="filterCategories"
    [sortable]="{
        mode: 'multiple'
    }"
    [sortableColumns]="sortableColumns"
    [hideActionButtons]="true"
    (emitSortEvent)="sortChange($event)"
    (emitSearchEvent)="search($event)"
    (emitFilterEvent)="filterCallback($event)"
    (emitPageChangeEvent)="pageChange($event)"
></app-data-grid-comp>