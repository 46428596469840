import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-outstanding-absence-report',
    templateUrl: './outstanding-absence-report.component.html',
    styleUrls: ['./outstanding-absence-report.component.scss']
})
export class OutstandingAbsenceReportComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
