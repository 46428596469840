<mat-card class="position-editor mat-elevation-z0">
    <mat-card-title class="header">
        <h4 appLocalizationPopupDirective localizationCode="Position">{{ 'Position' | translate: {Default: "Position"} }}: <span>{{position?.name[0]?.text}}</span></h4>

        <div class="buttons-container" *ngIf="!readOnly">
            <mat-form-field appearance="fill" class="effective-date-form-field">
                <mat-label>
                    {{ 'EffectiveDate' | translate: {Default: "Effective Date"} }}
                </mat-label>

                <input matInput [matDatepicker]="effectiveDatePicker" (dateChange)="effectiveDateChange($event)" [(ngModel)]="effectiveDate">
                <span fxLayout="row" matSuffix>
                    <mat-datepicker-toggle [for]="effectiveDatePicker"></mat-datepicker-toggle>
                </span>
                <mat-datepicker #effectiveDatePicker></mat-datepicker>
            </mat-form-field>
            
            <mat-icon 
                setColor 
                [setColorRules]="[
                    {
                        color: 'primaryColour',
                        events: ['hover']
                    }
                ]" 
                class="material-icons-outlined clickable-icon" 
                appPreventDoubleClick 
                (throttledClick)="openHistoryDialog()"
            >history</mat-icon>
            <button mat-raised-button routerLink="{{routes.SITE_SETTINGS}}{{routes.POSITIONS}}">{{ 'Close' | translate: {Default: "Close"} }}</button>
            <button mat-raised-button setColor="primaryColour" color="primary" [disabled]="formValid === false" appPreventDoubleClick (throttledClick)="getFormData = !getFormData">{{ 'Save' | translate: {Default: "Save"} }}</button>
        </div>
    </mat-card-title>
    <mat-card-content>
        <div class="position-details-form">
            <ng-container *ngIf="isLoading; else loaded">
                <div class="loading-container">
                    <ngx-skeleton-loader
                        count="5"
                        [theme]="{
                            'height.px': 50
                        }"
                    ></ngx-skeleton-loader>
                </div>
            </ng-container>
            <ng-template #loaded>
                <div *ngIf="isWorklocationInactive" class="inactive-worklocation-message">
                    <mat-icon class="material-icons-outlined">report_problem</mat-icon>
                    
                    <h6>{{ 'Position-InactiveWorkLocationWarning' | translate: {Default: "Work location is inactive. A new active work location should be added before saving."} }}</h6>
                </div>

                <app-form-generator
                    [formId]="formId"
                    [formData]="formData"
                    [readOnly]="readOnly"
                    [emitFormDataTrigger]="getFormData"
                    (emitFormData)="formDataEmitted($event)"
                    (emitFormStatus)="formStatusUpdated($event)"
                ></app-form-generator>
            </ng-template>
        </div>

        <div class="right">
            <div class="employees-list">
                <h6>{{ 'AssignedToPosition' | translate: {Default: "Assigned To Position"} }}</h6>

                <div *ngIf="!loadingPositionEmployees; else loadingAssignedIn">
                    <div class="slot-info" *ngIf="position">
                        <div class="number-of-slots">{{ 'NumberOfSlots' | translate: {Default: "Number of slots"} }}: <span>{{position.numberOfSlots}}</span></div>
                        <div class="slots-available">{{ 'SlotsAvailable' | translate: {Default: "Slots available"} }}: <span>{{position.slotsAvailable}}</span></div>
                        <div class="slots-filled">{{ 'SlotsFilled' | translate: {Default: "Slots filled"} }}: <span>{{positionEmployees.length}}</span></div>
                    </div>
                    <kendo-grid
                        [data]="positionEmployees"
                        [style.maxHeight.px]="350"
                        [resizable]="true"
                        [pageable]="false">

                        <kendo-grid-column field="profilePictureThumbnailUri" title="" [width]="80">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="employee-name navigation-link" routerLink="{{routes.TALENT_TRACK}}{{routes.EDIT}}/{{dataItem.employee.id}}">
                                    <app-employee-image class="profile-pic" [employeeId]="dataItem.employee.id"></app-employee-image>
                                    {{dataItem.employee.firstName}} {{dataItem.employee.lastName}}
                                </div>
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column field="status" title="" [width]="80">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <mat-chip-list>
                                    <mat-chip class="active" *ngIf="isActiveEmployee(dataItem.endDate) < 0">{{ 'Active' | translate: {Default: "Active"} }}</mat-chip>
                                    <mat-chip class="terminated" *ngIf="isActiveEmployee(dataItem.endDate) > 0">{{ 'Terminated' | translate: {Default: "Terminated"} }}</mat-chip>
                                </mat-chip-list>
                            </ng-template>
                        </kendo-grid-column>

                        <ng-template kendoGridNoRecordsTemplate>
                            {{ 'ThereAreNoEmployees' | translate: {Default: "There are no employees"} }}
                        </ng-template>
                    </kendo-grid>
                </div>

                <ng-template #loadingAssignedIn>
                    <div class="loading-container">
                        <ngx-skeleton-loader count="5" [theme]="{ 'height.px': 30}"></ngx-skeleton-loader>
                    </div>
                </ng-template>
            </div>

            <div class="managers-list">
                <h6>
                    {{ 'ManagedBy' | translate: {Default: "Managed By"} }}
                </h6>

                <kendo-grid
                    *ngIf="!isLoading; else loadingManagers"
                    [data]="positionManagers"
                    [style.maxHeight.px]="350"
                    [resizable]="true"
                    [pageable]="false">

                    <kendo-grid-column field="profilePictureThumbnailUri" title="" [width]="80">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="employee-name navigation-link" routerLink="{{routes.TALENT_TRACK}}{{routes.EDIT}}/{{dataItem.id}}">
                                <app-employee-image class="profile-pic" [employeeId]="dataItem.id"></app-employee-image>
                                {{dataItem.firstName}} {{dataItem.lastName}}
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="status" title="" [width]="80">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <mat-chip-list>
                                <mat-chip class="active">Active</mat-chip>
                            </mat-chip-list>
                        </ng-template>
                    </kendo-grid-column>

                    <ng-template kendoGridNoRecordsTemplate>
                        {{ 'ThereAreNoManagers' | translate: {Default: "There are no managers"} }}
                    </ng-template>
                </kendo-grid>

                <ng-template #loadingManagers>
                    <div class="loading-container">
                        <ngx-skeleton-loader count="5" [theme]="{ 'height.px': 30}"></ngx-skeleton-loader>
                    </div>
                </ng-template>
            </div>

            <div class="position-documents-container" *ngIf="!readOnly">
                <app-position-documents [positionId]="positionId"></app-position-documents>
            </div>

            <div class="generate-schedule-container" *ngIf="!readOnly">
                <app-generate-position-schedule [positionId]="positionId"></app-generate-position-schedule>
            </div>
        </div>
    </mat-card-content>
</mat-card>
