import { Component, Input, OnInit } from '@angular/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EmploymentRecordCompensation } from './models/employment-record-compensation.model';
import { EmploymentRecordCompensationService } from './services/employment-record-compensation.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Lookup } from '@app/modules/lookup/models/lookup.model';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import * as moment from 'moment';

@Component({
  selector: 'app-employment-record-compensation',
  templateUrl: './employment-record-compensation.component.html',
  styleUrls: ['./employment-record-compensation.component.scss']
})
export class EmploymentRecordCompensationComponent implements OnInit {
  @Input() employeeId: string;
  @Input() employmentRecordId: string;
  form: UntypedFormGroup;
  compensationId: any;
  isLoading: boolean;
  employmentRecordCompensation: EmploymentRecordCompensation;
  sendingLocationOptions: Observable<Lookup[]>;
  pointOfOriginOptions: Observable<Lookup[]>;
  receivingLocationOptions: Observable<Lookup[]>;
  currencyOptions: Observable<Lookup[]>;
  ltiPsuOptions: Observable<Lookup[]>;
  ltiStockOptionOptions: Observable<Lookup[]>;
  marketViewOptions: Observable<Lookup[]>;
  overtimeEligibleOptions: Observable<Lookup[]>;
  payFrequencyOptions: Observable<Lookup[]>;
  payGradeOptions: Observable<Lookup[]>;
  payLevelOptions: Observable<Lookup[]>;
  payTypeOptions: Observable<Lookup[]>;
  roleCategoryOptions: Observable<Lookup[]>;
  roleGroupOptions: Observable<Lookup[]>;
  stiIncentivePlanOptions: Observable<Lookup[]>;
  termOptions: Observable<Lookup[]>;
  ltiRsuOptions: Observable<Lookup[]>;
  payScaleOptions: Observable<Lookup[]>;

  constructor(
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private fb: UntypedFormBuilder,
    private lookupService: LookupService,
    private employmentRecordCompensationService: EmploymentRecordCompensationService
  ) {}

  ngOnInit(): void {
    this.getLookups();
    this.getCompensations();
  }

  getCompensations(): void {
    this.isLoading = true;

    this.employmentRecordCompensationService.getCompensations(this.employeeId, this.employmentRecordId, 0, '20')
    .pipe(
        finalize(()=>{
          this.isLoading = false;
          this.createForm();
        })
    )
    .subscribe(
      res => {
        if(res.compensations.length > 0) {
          this.employmentRecordCompensation = res.compensations[0];
        }
        else {
          this.employmentRecordCompensation = null;
        }
      }
    )
  }

  getLookups() {
    this.currencyOptions = this.lookupService.getLookups("/lookup/CURRENCY");
    this.ltiPsuOptions = this.lookupService.getLookups("/lookup/COMPENSATION_LTI_PSU");
    this.ltiRsuOptions = this.lookupService.getLookups("/lookup/COMPENSATION_LTI_RSU");
    this.ltiStockOptionOptions = this.lookupService.getLookups("/lookup/COMPENSATION_LTI_STOCK_OPTION");
    this.marketViewOptions = this.lookupService.getLookups("/lookup/COMPENSATION_MARKET_VIEW");
    this.overtimeEligibleOptions = this.lookupService.getLookups("/lookup/COMPENSATION_OVERTIME_ELIGIBLE");
    this.payFrequencyOptions = this.lookupService.getLookups("/lookup/COMPENSATION_PAY_FREQUENCY");
    this.payGradeOptions = this.lookupService.getLookups("/lookup/COMPENSATION_PAY_GRADE");
    this.payLevelOptions = this.lookupService.getLookups("/lookup/COMPENSATION_PAY_LEVEL");
    this.payScaleOptions = this.lookupService.getLookups("/lookup/COMPENSATION_PAY_SCALE");
    this.payTypeOptions = this.lookupService.getLookups("/lookup/COMPENSATION_PAY_TYPE");
    this.roleCategoryOptions = this.lookupService.getLookups("/lookup/COMPENSATION_ROLE_CATEGORY");
    this.roleGroupOptions = this.lookupService.getLookups("/lookup/COMPENSATION_ROLE_GROUP");
    this.stiIncentivePlanOptions = this.lookupService.getLookups("/lookup/COMPENSATION_STI_INCENTIVE_PLAN");
    this.termOptions = this.lookupService.getLookups("/lookup/COMPENSATION_TERM");
  }

  createForm() {
    this.form = this.fb.group({
      id: [this.employmentRecordCompensation ? this.employmentRecordCompensation.id : null],
      effectiveDate: [moment().format()],
      startDate: [this.employmentRecordCompensation ? this.employmentRecordCompensation.startDate : null],
      endDate: [this.employmentRecordCompensation ? this.employmentRecordCompensation.endDate : null],
      amount: [this.employmentRecordCompensation ? this.employmentRecordCompensation.amount : null],
      term: [this.employmentRecordCompensation ? this.employmentRecordCompensation.term?.id : null],
      currency: [this.employmentRecordCompensation ? this.employmentRecordCompensation.currency?.id : null],
      marketView: [this.employmentRecordCompensation ? this.employmentRecordCompensation.marketView?.id : null],
      payType: [this.employmentRecordCompensation ? this.employmentRecordCompensation.payType?.id : null],
      payGrade: [this.employmentRecordCompensation ? this.employmentRecordCompensation.payGrade?.id : null],
      roleGroup: [this.employmentRecordCompensation ? this.employmentRecordCompensation.roleGroup?.id : null],
      roleCategory: [this.employmentRecordCompensation ? this.employmentRecordCompensation.roleCategory?.id : null],
      payScale: [this.employmentRecordCompensation ? this.employmentRecordCompensation.payScale?.id : null],
      payLevel: [this.employmentRecordCompensation ? this.employmentRecordCompensation.payLevel?.id : null],
      payFrequency: [this.employmentRecordCompensation ? this.employmentRecordCompensation.payFrequency?.id : null],
      overtimeEligible: [this.employmentRecordCompensation ? this.employmentRecordCompensation.overtimeEligible?.id : null],
      stiIncentivePlan: [this.employmentRecordCompensation ? this.employmentRecordCompensation.stiIncentivePlan?.id : null],
      stiCurrency: [this.employmentRecordCompensation ? this.employmentRecordCompensation.stiCurrency?.id : null],
      ltiPsu: [this.employmentRecordCompensation ? this.employmentRecordCompensation.ltiPsu?.id : null],
      ltiStockOption: [this.employmentRecordCompensation ? this.employmentRecordCompensation.ltiStockOption?.id : null],
      ltiRsu: [this.employmentRecordCompensation ? this.employmentRecordCompensation.ltiRsu?.id : null],
      compensationRatio: [this.employmentRecordCompensation ? this.employmentRecordCompensation.compensationRatio : null],
      stiTargetAmount: [this.employmentRecordCompensation ? this.employmentRecordCompensation.stiTargetAmount : null],
      stiTargetPercentage: [this.employmentRecordCompensation ? this.employmentRecordCompensation.stiTargetPercentage : null],
      stiActualAmount: [this.employmentRecordCompensation ? this.employmentRecordCompensation.stiActualAmount : null],
      stiActualPercentage: [this.employmentRecordCompensation ? this.employmentRecordCompensation.stiActualPercentage : null],
      comments: [this.employmentRecordCompensation ? this.employmentRecordCompensation.comments : null],

      baseSalary: [this.employmentRecordCompensation ? this.employmentRecordCompensation.baseSalary : null],
      baseSalaryCurrency: [this.employmentRecordCompensation ? this.employmentRecordCompensation.baseSalaryCurrency?.id : null],
      baseSalaryLocal: [this.employmentRecordCompensation ? this.employmentRecordCompensation.baseSalaryLocal : null],
      baseSalaryLocalCurrency: [this.employmentRecordCompensation ? this.employmentRecordCompensation.baseSalaryLocalCurrency?.id : null],
      baseSalaryOther: [this.employmentRecordCompensation ? this.employmentRecordCompensation.baseSalaryOther : null],
      baseSalaryOtherCurrency: [this.employmentRecordCompensation ? this.employmentRecordCompensation.baseSalaryOtherCurrency?.id : null],
      baseSalaryNonGmg: [this.employmentRecordCompensation ? this.employmentRecordCompensation.baseSalaryNonGmg : null],
      baseSalaryNonGmgCurrency: [this.employmentRecordCompensation ? this.employmentRecordCompensation.baseSalaryNonGmgCurrency?.id : null],
      hourlyRate: [this.employmentRecordCompensation ? this.employmentRecordCompensation.hourlyRate : null],
      hourlyRateCurrency: [this.employmentRecordCompensation ? this.employmentRecordCompensation.hourlyRateCurrency?.id : null],
      dailyRate: [this.employmentRecordCompensation ? this.employmentRecordCompensation.dailyRate : null],
      dailyRateCurrency: [this.employmentRecordCompensation ? this.employmentRecordCompensation.dailyRateCurrency?.id : null],
      monthlyRate: [this.employmentRecordCompensation ? this.employmentRecordCompensation.monthlyRate : null],
      monthlyRateCurrency: [this.employmentRecordCompensation ? this.employmentRecordCompensation.monthlyRateCurrency?.id : null],

      changeReason: [''],
      changeReasonComments: ['']
    });
  }

  save(){
    this.overlayService.show();

    this.employmentRecordCompensationService.postCompensation(this.employeeId, this.employmentRecordId, this.form.value)
    .pipe(
      finalize(()=>{
        this.overlayService.hide();
      })
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar('Compensation saved successfully', 'clear', 'success');
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

}
