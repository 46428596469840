<app-site-settings-menu>
    <div class="main-container" *ngIf="hasFeatureAccess">
        <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white; width:100%">
            <mat-spinner></mat-spinner>
        </div>

        <div class="left" *ngIf="!isLoading">
            <mat-form-field class="search-field" appearance="outline" >
                <input matInput
                       [(ngModel)]="currencySearchValue"
                       placeholder="Search...">
                <mat-icon matPrefix class="material-icons-outlined">
                    search
                </mat-icon>
            </mat-form-field>

            <div class="tables-list-container">
                <mat-list>
                    <div mat-subheader>Currencies</div>
                    <mat-list-item *ngFor="let currency of currencies | currencySearch:currencySearchValue" (click)="selectedCurrency = currency" class="primaryHoverColor" [ngClass]="{'primaryColor': selectedCurrency === currency }">
                        {{currency.id}}
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </mat-list>
            </div>
        </div>
        <div class="right" *ngIf="!isLoading">
            <mat-card-content>
                <view-currency [currency]="selectedCurrency"></view-currency>
            </mat-card-content>
        </div>
    </div>

    <no-feature-access *ngIf="!hasFeatureAccess"></no-feature-access>
</app-site-settings-menu>
