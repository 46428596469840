<div class="filters-container">
    <div class="chips-container">
        <button appLocalizationPopupDirective localizationCode="AddFilter" mat-stroked-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openFilterDialog()">
            <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon>
            {{ 'AddFilter' | translate: {Default: "Add Filter"} }}
        </button>

        <button appLocalizationPopupDirective localizationCode="ClearFilters" mat-stroked-button class="clear-filters-button" *ngIf="filters.length > 0" (click)="clearFilters()">
            <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">clear</mat-icon>
            {{ 'ClearFilters' | translate: {Default: "Clear Filters"} }}
        </button>

        <mat-chip-list>
            <mat-chip setColor="primaryColour" *ngFor="let filter of filters" color="primary" selected>
                {{filter.filterCategory?.title}}
                {{filter.filterOperator}}

                <ng-container *ngIf="filter.filterCategory?.dataType === 'Percentage'; else notPercentage">{{convertToNumber(filter.filterString)}}%</ng-container>

                <ng-template #notPercentage>
                    <ng-container *ngIf="filter.filterString.text">{{filter.filterString.text}}</ng-container>
                    <ng-container *ngIf="filter.filterString.name">{{filter.filterString.name}}</ng-container>
                    <ng-container *ngIf="!filter.filterString.text && !filter.filterString.name">{{filter.filterString}}</ng-container>
                </ng-template>


                <button mat-icon-button [matMenuTriggerFor]="menu" class="actions-btn">
                    <mat-icon class="material-icons-outlined">more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button appLocalizationPopupDirective localizationCode="Edit" mat-menu-item appPreventDoubleClick (throttledClick)="openFilterDialog(filter)">
                        <mat-icon class="material-icons-outlined">edit</mat-icon>
                        <span>{{ 'Edit' | translate: {Default: "Edit"} }}</span>
                    </button>
                    <button appLocalizationPopupDirective localizationCode="Delete" mat-menu-item (click)="deleteFilter(filter)">
                        <mat-icon class="material-icons-outlined">delete</mat-icon>
                        <span>{{ 'Delete' | translate: {Default: "Delete"} }}</span>
                    </button>
                </mat-menu>
            </mat-chip>
        </mat-chip-list>
    </div>
</div>
