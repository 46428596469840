import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-employee-login-report',
  templateUrl: './employee-login-report.component.html',
  styleUrls: ['./employee-login-report.component.scss']
})
export class EmployeeLoginReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
