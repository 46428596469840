import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { defer, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Lookup, TableList, TableListField, TableListFieldOption } from '../../models/lookup.model';
import { LookupService } from '../../services/lookup.service';
import { CopyLookupDialogComponent } from '../copy-lookup-dialog/copy-lookup-dialog.component';
import { EditLookupDialogComponent } from '../edit-lookup-dialog/edit-lookup-dialog.component';

@Component({
  selector: 'app-lookup-list',
  templateUrl: './lookup-list.component.html',
  styleUrls: ['./lookup-list.component.scss']
})
export class LookupListComponent implements OnInit, OnChanges {
  @ViewChild('selectionList', {static: false}) private selectionList: MatSelectionList;
  @Input() tableList: TableList[];
  @Input() tableListField: TableListField;
  tableListFieldOptions: { listOptions: TableListFieldOption[], prioritized: TableListFieldOption[] } = { listOptions: [], prioritized: [] };
  ListGroup: string;
  selectAll: boolean = false;
  selectedTableListFieldOptions: Lookup[] = [];
  showAddMultiple: boolean = false;
  showAddSingle: boolean = false;
  isLoading: boolean = true;

  constructor(
    private lookupService: LookupService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.setListGroupValue();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.tableListField){
      this.getTableListFieldOptions();
      this.setListGroupValue();
      this.selectedTableListFieldOptions = [];
    }
  }

  getTableListFieldOptions(): void {
    this.isLoading = true;

    this.lookupService.getTableListFieldOptions(this.tableListField.properties[0].value, 0, '300')
    .pipe(
        finalize( () => this.isLoading = false )
    )
    .subscribe(
      res => {
        let prioritized: TableListFieldOption[] = [];
        let listOptions: TableListFieldOption[] = [];

        res.data.forEach( listOption => {
          if(listOption.prioritize === true && !prioritized.find(a => a.id === listOption.id)){
            prioritized.push(listOption);
          }
          if(listOption.prioritize === false && !listOptions.find(a => a.id === listOption.id)){
            listOptions.push(listOption);
          }
        });

        this.tableListFieldOptions = {
          prioritized: prioritized,
          listOptions: listOptions
        }
      }
    )
  }

  capitalizeFirstLetter(string): string {
    return string.charAt(1).toUpperCase() + string.slice(2);
  }

  setListGroupValue() {
    this.tableListField.properties.forEach(
      p => {
        this.tableListField[p.property] = p.value;
      }
    );
  }

  toggleSelectAll(event: MatCheckboxChange) {
    this.selectAll = event.checked;
    event.checked ? this.selectionList.selectAll() : this.selectionList.deselectAll();
  }

  deleteTableListFieldOption(id: string) {
    this.overlayService.show();

    this.lookupService.deleteListOption(this.tableListField.ListGroup, id)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.getTableListFieldOptions();
        this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  deleteAllSelected() {
    const observables = this.selectedTableListFieldOptions.map(listOption => defer(() => this.lookupService.deleteListOption(this.tableListField.ListGroup, listOption.id)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.selectAll = false;
        this.getTableListFieldOptions();
        this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  copyTableListFieldOption(listOptionId: string, targetGroupId: string) {
    this.overlayService.show();

    this.lookupService.copyListOptionToGroup(this.tableListField.ListGroup, listOptionId, targetGroupId)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar('List option value copied successfully', 'clear', 'success');
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  copyAllSelected(targetGroupId: string) {
    const observables = this.selectedTableListFieldOptions.map(listOption => defer(() => this.lookupService.copyListOptionToGroup(this.tableListField.ListGroup, listOption.id, targetGroupId)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar('List option values copied successfully', 'clear', 'success');
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  saveComplete($event) {
    if($event === true) {
      this.getTableListFieldOptions();
    }
  }

  closeAddValue() {
    this.showAddMultiple = false;
    this.showAddSingle = false;
  }

  openConfirmDeleteDialog(event, itemToDelete: TableListFieldOption) {
    event.stopPropagation();

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
        text: `Are you sure you want to delete ${itemToDelete.text}?`
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.deleteTableListFieldOption(itemToDelete.id);
        }
      }
    );
  }

  openCopyDialog(event, itemToCopyId?: string) {
    event.stopPropagation();

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 500;

    dialogConfig.data = {
      tableListFields: this.tableList
    }

    const dialogRef = this.dialog.open(CopyLookupDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data){
          let targetGroupId;
          data.properties.forEach(p => {
            if(p.property === 'ListGroup') {
              targetGroupId = p.value;
            }
          });
          //if single lookup value to copy, else multiple values to copy
          if (itemToCopyId) {
            this.copyTableListFieldOption(itemToCopyId, targetGroupId);
          }
          else {
            this.copyAllSelected(targetGroupId);
          }
        }
      }
    );
  }

  openConfirmDeleteAllDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
        text: `Are you sure you want to delete all selected lookup values?`
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.deleteAllSelected();
        }
      }
    );
  }

  openEditLookupDialog(event, listOptionId: string) {
    event.stopPropagation();
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 500;

    dialogConfig.data = {
      listGroup: this.tableListField.ListGroup,
      listOptionId: listOptionId
    };

    const dialogRef = this.dialog.open(EditLookupDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data === true) {
          this.snackbarService.openSnackBar('Updated successfully', 'clear', 'success');
          this.getTableListFieldOptions();
        }
      }
    );
  }

}
