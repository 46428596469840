import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {SnackbarService} from '@app/core/services/snackbar.service';
import {CultureService} from '@app/core/services/system-language/culture.service';
import {OverlayService} from '@app/shared/components/overlay/overlay.service';
import {Culture} from '@app/shared/models/system-language/culture.model';
import {finalize} from 'rxjs/operators';
import {OrganizationStructureService} from '@app/modules/organization-structure/services/organization-structure.service';
import {OrganizationTypeVerbose} from '@app/modules/organization-structure/models/organization-structure.model';

@Component({
    selector: 'app-work-location-dialog',
    templateUrl: './organization-type-editor-dialog.component.html',
    styleUrls: ['./organization-type-editor-dialog.component.scss']
})
export class OrganizationTypeEditorDialogComponent implements OnInit {

    form: UntypedFormGroup;
    isLoading: boolean = false;
    cultures: Culture[];
    orgTypeId: string;

    constructor(
        private snackbarService: SnackbarService,
        private fb: UntypedFormBuilder,
        private overlayService: OverlayService,
        private cultureService: CultureService,
        private dialogRef: MatDialogRef<OrganizationTypeEditorDialogComponent>,
        private organizationService: OrganizationStructureService,
        @Inject(MAT_DIALOG_DATA) data) {
        this.orgTypeId = data.orgTypeId;
    }

    ngOnInit(): void {
        this.cultureService.getCultures().subscribe(res => {
            this.cultures = res;
        });

        this.createForm();
    }


    createForm() {
        this.form = this.fb.group({
            localizations: this.fb.array([]),
        });

        this.addNewLocalization();
    }

    get localizations() {
        return this.form.controls['localizations'] as UntypedFormArray;
    }

    addNewLocalization(culture?: string, localization?: string) {
        const localizationForm = this.fb.group({
            culture: [culture || null, Validators.required],
            text: [localization || null, Validators.required]
        });

        this.localizations.push(localizationForm);
    }

    deleteLocalization(index: number) {
        this.localizations.removeAt(index);
    }

    close() {
        this.dialogRef.close();
    }

    save() {
        this.overlayService.show();

        const organizationTypeVerbose: OrganizationTypeVerbose = {
            id: null,
            name: this.form.value.localizations,
        };

        this.organizationService.createOrganizationType(organizationTypeVerbose)
            .pipe(finalize(() => this.overlayService.hide()))
            .subscribe((res) => {
                    this.dialogRef.close({showTypesModal: true});
                },
                err => {
                    this.snackbarService.openSnackBar(err, 'clear', 'warn');
                }
            );
    }
}
