import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialogConfig, MatDialog} from '@angular/material/dialog';
import {FilterDialogComponent} from '@app/shared/components/filter/filter-dialog/filter-dialog.component';

@Component({
    selector: 'filter-control',
    templateUrl: './filter-control.component.html',
    styleUrls: ['./filter-control.component.scss']
})
export class FilterControlComponent implements OnInit {

    @Input() categories = [];
    @Output() callback: EventEmitter<any> = new EventEmitter();

    form: UntypedFormGroup;

    filter: {
        id: string,
        filterCategory: string,
        filterOperator: string,
        filterString: any
    };

    filters: {
        id: string,
        filterCategory: any,
        filterOperator: string,
        filterString: any
    }[] = [];

    filterString: string;

    constructor(
        private dialog: MatDialog,
        private fb: UntypedFormBuilder,
    ) {}

    ngOnInit(): void {
        this.createForm();
    }

    createForm() {
        this.form = this.fb.group({
            id: [this.filter ? this.filter.id : this.generateID()],
            filterCategory: [this.filter ? this.filter.filterCategory : null, Validators.required],
            filterOperator: [this.filter ? this.filter.filterOperator : null, Validators.required],
            filterString: [this.filter ? this.filter.filterString : null, Validators.required],
        });
    }

    generateID() {
        return '_' + Math.random().toString(36).substr(2, 9);
    }

    getFilterStringValue(filter: any) {
        if (filter.filterString.id) {
            return filter.filterString.id;
        } else {
            return filter.filterString;
        }
    }

    get filterCategory(): AbstractControl {
        return this.form.get('filterCategory');
    }

    clearFilters() {
        this.filters = [];
        this.filterUpdate();
    }

    deleteFilter(filter: any) {
        let index = this.filters.indexOf(filter);

        if (index !== -1) {
            this.filters.splice(index, 1);
            this.filterUpdate();
        }
    }

    openFilterDialog(filter?: any) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            categories: this.categories,
            filter: filter
        };

        const dialogRef = this.dialog.open(FilterDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data !== undefined) {
                    let index = this.filters.map(filter => {
                        return filter.id;
                    }).indexOf(data.id);

                    //if you are editting a filter replace it or add the new filter
                    if (index !== -1) {
                        this.filters.splice(index, 1, data);
                    } else {
                        this.filters.push(data);
                    }

                    this.filterUpdate();
                }
            }
        );
    }

    convertToNumber(percentage) {
        if (percentage >= 0 && percentage <= 1) {
          return (percentage * 100).toFixed(0);
        } else {
          return "Percentage must be between 0.00 and 1.00";
        }
    }


    public filterUpdate(): void {
        this.filterString = '';

        this.filters.forEach(
            (filter, index, array) => {

                if (filter.filterCategory?.field) {
                    let category: string;

                    //if it is an object category like (department.text) trim .text from the end, else it doesnt need to be trimmed
                    // (filter.filterCategory?.field).match(/.+(?=\.)/) === null
                    //     ? category = filter.filterCategory?.field
                    //     : category = (filter.filterCategory?.field).match(/.+(?=\.)/);

                    // If a filter needs a different value to the field provided in the table you will set the filter here
                    //  EG. table field is field: "createdBy" but the filter value is filterValue: ["CreatedBy.FirstName", "CreatedBy.LastName"]
                    if(filter?.filterCategory?.filterValue) {
                        filter.filterCategory.filterValue.forEach(
                            (filterValue, index) => {
                                category = filterValue
    
                                this.filterString = this.filterString.concat(`(${category} ${filter.filterOperator} "${this.getFilterStringValue(filter)}")`);

                                if(index < filter.filterCategory.filterValue.length -1) {
                                    this.filterString = this.filterString.concat(` OR `);
                                }
                            }
                        );
                    }
                    else {
                        category = filter.filterCategory?.filterField ? filter.filterCategory?.filterField : filter.filterCategory?.field
    
                        this.filterString = this.filterString.concat(`(${category} ${filter.filterOperator} "${this.getFilterStringValue(filter)}")`);
                    }

                }

                if (index < array.length - 1) {
                    this.filterString = this.filterString.concat(` AND `);
                }
            }
        );

        this.callback.emit(this.filterString);
    }

}
