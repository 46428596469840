<app-employee-details>
    <ng-container *ngIf="isLoadingPermissions; else loadedPermissions">
        <ngx-skeleton-loader
            count="5"
            [theme]="{ 
                'height.px': 50
            }"
        ></ngx-skeleton-loader>
    </ng-container>
    <ng-template #loadedPermissions>
        <app-data-grid-comp
            [columns]="columns"
            [bindingType]="bindingType"
            [view]="view"
            [gridData]="gridData"
            [gridDataResult]="gridDataResult"
            [isLoading]="isLoading"
            [pageSize]="pageSize"
            [skip]="skip"
            category="FamilyDependants"
            [rowSelectionSettings]="{
                canSelectRows: true,
                selectableMode: 'multiple',
                selectRowBy: 'id'
            }"
            clickableRows="true"
            [isLoading]="isLoading"
            [clearSelectedItems]="clearSelectedItems"
            [tableId]="db_tables.Family"
            [accessAndHistoryURL]="accessAndHistoryURL"
            [parentModelId]="employeeID"
            [targetEmployeeTablePermissions]="targetEmployeeTablePermissions"
            (emitPageChangeEvent)="pageChange($event)"
            (emitOpenDialogEvent)="getFamilyDependant($event)"
            (emitDeleteEvent)="deleteFamilyDependants($event)"
        ></app-data-grid-comp>
    </ng-template>
</app-employee-details>
