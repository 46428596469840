<h5 mat-dialog-title>
    {{ 'WorkLocation' | translate: {Default: "Work Location"} }}
</h5>

<mat-dialog-content>
    <app-form-generator 
        *ngIf="!isLoading"
        [formId]="formId"
        [formData]="formData"
        [emitFormDataTrigger]="getFormData"
        (emitFormData)="formDataEmitted($event)"
        (emitFormStatus)="formStatusUpdated($event)"
    ></app-form-generator>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
    <button mat-raised-button setColor="primaryColour" color="primary" [disabled]="formValid === false" appPreventDoubleClick (throttledClick)="getFormData = !getFormData">{{ 'Save' | translate: {Default: "Save"} }}</button> 
</mat-dialog-actions>