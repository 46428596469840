import { Component, OnInit } from '@angular/core';
import { routes } from '@app/consts';

@Component({
  selector: 'app-letter-generator',
  templateUrl: './letter-generator.component.html',
  styleUrls: ['./letter-generator.component.scss']
})
export class LetterGeneratorComponent implements OnInit {
  public routes: typeof routes = routes;
  navList = [
    {
      text: 'Letters List',
      link: `${routes.LETTERS}${routes.LETTERS_LIST}`,
      icon: 'history_edu'
    },
    {
      text: 'Send Letter',
      link: `${routes.LETTERS}${routes.LETTERS_SEND}`,
      icon: 'edit_note'
    },
    {
      text: 'Create Letter',
      link: `${routes.LETTERS}${routes.LETTERS_EDITOR}`,
      icon: 'create'
    },
  ];
  
  constructor() { }

  ngOnInit(): void {
  }

}
