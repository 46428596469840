import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { api_routes, db_tables, routes } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { defer, finalize, forkJoin } from 'rxjs';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { ReviewPlansService } from '../review-plans/services/review-plans.service';
import { ReviewPlan } from '../review-plans/models/review-plan.model';
import { GlobalReviewsService } from './services/global-reviews.service';

@Component({
  selector: 'app-global-reviews',
  templateUrl: './global-reviews.component.html',
  styleUrls: ['./global-reviews.component.scss']
})
export class GlobalReviewsComponent implements OnInit {

  public columns: any[] = [
    { field: "name", title: "Name", tableId: "tfi_RpName" },
    { field: "description", title: "description", tableId: "tfi_RpDescription", type: "longText", longTextLimit: 200 },
    { field: "startDate", title: "start Date", tableId: "tfi_RpStartDate", type: "date" },
    { field: "endDate", title: "end Date", tableId: "tfi_RpEndDate", type: "date" },
    { field: "finalReview", title: "final Review", tableId: "tfi_RpFinalReview", type: "booleanToggle" },
    { field: "setOverallRating", title: "set Overall Rating", tableId: "tfi_RpSetOverallRating", type: "booleanToggle" },
  ];

  filterCategories: any[] = [
    { field: "name", title: "Name", tableId: "tfi_RpName", type: "String", dataType: 'String' },
    { field: "description", title: "description", tableId: "tfi_RpDescription", type: "String", dataType: 'String' },
    { field: "startDate", title: "start Date", tableId: "tfi_RpStartDate", type: "date", dataType: 'Date' },
    { field: "endDate", title: "end Date", tableId: "tfi_RpEndDate", type: "date", dataType: 'Date' },
    { field: "finalReview", title: "final Review", tableId: "tfi_RpFinalReview", dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
    { field: "setOverallRating", title: "set Overall Rating", tableId: "tfi_RpSetOverallRating", dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }}
  ];

  sortableColumns: any[] = [
    { field: "name", sortValue: "name"},
    { field: "description", sortValue: "description"},
    { field: "startDate", sortValue: "startDate"},
    { field: "endDate", sortValue: "endDate"},
    { field: "finalReview", sortValue: "finalReview"},
    { field: "setOverallRating", sortValue: "setOverallRating"},
  ];

  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 10;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  formId: string = 'frm_6DUL8Zq9roVBT3'
  dialogRef: any;
  formValid: any;
  searchValue: string;
  searchFilterString: string;
  getGoalsTypesRequest: any;
  sortString: string;
  filterString: string;

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private overlayService: OverlayService,
    private router: Router,
    private globalReviewsService: GlobalReviewsService
  ) {
  }

  ngOnInit(): void {
    this.view = this.getReviewPlans();
  }

  getReviewPlans() {
    let filter;
    if(this.searchFilterString) {
      filter = this.searchFilterString;
    }
    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }

    this.isLoading = true;

    this.getGoalsTypesRequest = this.globalReviewsService.getReviewPlans(String(this.pageSize), this.skip, this.sortString, filter)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          }
        }
      );
  }

  getReviewPlan(reviewPlan?: ReviewPlan) {
    this.router.navigate([`${routes.PERFORMANCE}/${routes.GLOBAL_REVIEWS}/${reviewPlan.id}`]);
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.view = this.getReviewPlans();
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;

    let variants = this.searchValue.split(' ').filter(value => value);
    this.searchFilterString = '';
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `(name like "${variant}")`;
        }
    });

    this.getGoalsTypesRequest.unsubscribe();
    this.getReviewPlans();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getReviewPlans();
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;;
    this.getReviewPlans();
  }

}
