<h6>Paystubs</h6>

<kendo-grid [data]="gridData">
    <kendo-grid-column field="payDate" title="Pay Date">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="payDate-item" appPreventDoubleClick (throttledClick)="openPdfViewerDialog()">
                {{dataItem.payDate}}
            </div>
        </ng-template>
    </kendo-grid-column>    
    <kendo-grid-column field="totalGrossPay" title="Total Gross Pay"> </kendo-grid-column>
    <kendo-grid-column field="taxes" title="Taxes"></kendo-grid-column>
    <kendo-grid-column field="deductions" title="Deductions"> </kendo-grid-column>
    <kendo-grid-column field="netAmount" title="Net Amount"> </kendo-grid-column>
    <kendo-grid-column field="ytdNet" title="YTD Net"> </kendo-grid-column>
    <kendo-grid-column>
        <ng-template kendoGridCellTemplate let-dataItem>
            <mat-icon 
                class="material-icons-outlined download-icon" 
                matTooltip="Download Paystub">
                download</mat-icon> 
        </ng-template>
    </kendo-grid-column>
</kendo-grid>