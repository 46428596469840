<div class="main-container">
    <div class="greeting">
        <div class="text">{{greeting | async}}, </div>
        <div>
            <ng-container *ngIf="currentEmployeeDetails?.preferredName; else showFirstName">{{currentEmployeeDetails?.preferredName}}</ng-container>
            <ng-template #showFirstName>{{currentEmployeeDetails?.firstname}}</ng-template>
        </div>
    </div>
    <div class="time">
        {{time | async}}
    </div>
</div>
