<h6>Direct Deposit Details</h6>

<form class="form" *ngIf="form" [formGroup]="form">
    <div class="form-container">
        <div class="form-section">
            <div class="form-section-title">
            </div>
            <div class="form-section-contents">
                <div class="section-row">
                    <mat-form-field appearance="fill">
                        <mat-label>
                            Bank Name
                        </mat-label>
            
                        <input matInput
                            formControlName="bankName">
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>
                            Account Number
                        </mat-label>
            
                        <input matInput
                            formControlName="accountNumber">
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>
                            Routing Number
                        </mat-label>
            
                        <input matInput
                            formControlName="routingNumber">
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>
                            Account Type
                        </mat-label>
            
                        <mat-select disableOptionCentering formControlName="accountType">
                            <mat-option value="checking">Checking</mat-option>
                            <mat-option value="savings">Savings</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="buttons-container">
            <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button> 
        </div>
    </div>
</form>