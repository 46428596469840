import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { routes } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { defer, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EmploymentRecord } from '../../models/employment-record.model';
import { EmploymentRecordsService } from '../../services/employment-records.service';
import { EmploymentRecordDialogComponent } from '../employment-record-dialog/employment-record-dialog.component';

@Component({
  selector: 'app-employment-records-data-grid',
  templateUrl: './employment-records-data-grid.component.html',
  styleUrls: ['./employment-records-data-grid.component.scss']
})
export class EmploymentRecordsDataGridComponent implements OnInit {
  @Input() employeeId: string;
  public columns: any[] = [
    { field: "id", title: "ID", type: "navigation"},
    { field: "hireCategory", subField: "text", title: "Hire Category" },
    { field: "hireDate", title: "Hire Date", type: "date" },
    { field: "hireDetailComments", title: "Hire Detail Comments" },
    { field: "inductionCompletedOn", title: "Induction Completed On", type: "date" },
    { field: "medicalCompleted", subField: "text", title: "Medical Completed" },
    { field: "medicalCompletedDate", title: "Medical Completed Date", type: "date" },
    { field: "noticePeriodLength", title: "Notice Period Length" },
    { field: "noticePeriodUnits", subField: "text", title: "Notice Period Units" },
    { field: "payrollCode", subField: "text", title: "Payroll Code" },
    { field: "payrollNumber", title: "Payroll Number" }
  ];
  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  isLoading: boolean;
  pageSize: number = 5;
  skip: number = 0;
  clearSelectedItems: boolean;

  constructor(
    private dialog: MatDialog,
    private overlayService: OverlayService,
    public router: Router,
    private snackbarService: SnackbarService,
    private employmentRecordsService: EmploymentRecordsService
  ) {}

  ngOnInit(): void {
    this.view = this.getEmployementRecords();
  }

  getEmployementRecords(): void {
    this.isLoading = true;

    this.employmentRecordsService.getEmploymentRecords(this.employeeId, this.skip, String(this.pageSize))
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          }
          this.gridData = res.data;
        },
        err => {
          console.log(err);
        },
        () => console.log("done")
      );
  }

  openEmploymentRecord(employmentRecord?: EmploymentRecord) {
    if(employmentRecord) {
      this.navigateToEmploymentRecordDetails(employmentRecord);
    }
    else {
      this.openEmploymentRecordDialog();
    }
  }

  navigateToEmploymentRecordDetails(employmentRecord: EmploymentRecord){
    this.router.navigateByUrl(`${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYMENT_RECORDS}/${employmentRecord.id}`);
  }

  openEmploymentRecordDialog(employmentRecord?: EmploymentRecord) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      employmentRecordId: employmentRecord ? employmentRecord.id : null,
      employeeId: this.employeeId
    };

    const dialogRef = this.dialog.open(EmploymentRecordDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.getEmployementRecords();
        }
      }
    );
  }

  deleteEmploymentRecords(employmentRecordIds: string[]) {
    const observables = employmentRecordIds.map(selectedItem => defer(() => this.employmentRecordsService.deleteEmploymentRecord(this.employeeId, selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.getEmployementRecords();
        this.clearSelectedItems = !this.clearSelectedItems;
        this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.getEmployementRecords();
  }

}
