<app-security-section>
    <app-data-grid-comp
        [columns]="columns"
        [bindingType]="bindingType"
        [view]="view"
        [gridDataResult]="gridDataResult"
        [isLoading]="isLoading"
        [pageSize]="pageSize"
        [skip]="skip"
        [rowSelectionSettings]="{
            canSelectRows: true,
            selectableMode: 'multiple',
            selectRowBy: 'id'
        }"
        clickableRows="true"
        searchable="true"
        category="SecurityRoles"
        [isLoading]="isLoading"
        [filterCategories]="filterCategories"
        [clearSelectedItems]="clearSelectedItems"
        [rowActionButtons]="gridActionButtons"
        (emitPageChangeEvent)="pageChange($event)"
        (emitOpenDialogEvent)="navigateToSecurityRole($event)"
        (emitSearchEvent)="search($event)"
        (emitDeleteEvent)="deleteAllSelected($event)"
        (emitFilterEvent)="filterCallback($event)"
        (rowActionEvent)="copySecurityRole($event)"
    ></app-data-grid-comp>
</app-security-section>
