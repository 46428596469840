import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { routes } from '@app/consts';
import { EmployeeService } from '@app/core/services/employee.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TalentVerbose } from '@app/shared/models/employee.model';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-employee-delete',
  templateUrl: './employee-delete.component.html',
  styleUrls: ['./employee-delete.component.scss']
})
export class EmployeeDeleteComponent implements OnInit {
  @Input() employeeID: string;
  loadingEmployee: boolean = true;
  employee: TalentVerbose;
  nameConfirmationValue: string;
  nameNotConfirmed: boolean = true;

  constructor(
    private employeeService: EmployeeService,
    private dialog: MatDialog,
    public translate: TranslateService,
    public router: Router,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit(): void {
    this.getEmployee();
  }

  getEmployee(): void {
    this.loadingEmployee = true;
    this.employeeService.getTalent(this.employeeID)
    .pipe(
      finalize( () => {
        this.loadingEmployee = false;
      })
    )
    .subscribe(
      (res: TalentVerbose) => {
        this.employee = res;
      }
    );
  }

  checkIfNameConfirmed() {
    this.nameNotConfirmed = this.nameConfirmationValue !== `${this.employee.firstName} ${this.employee.lastName}`
  }

  deleteEmployee() {
    this.overlayService.show();

    this.employeeService.deleteTalent(this.employeeID)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.router.navigateByUrl(`${routes.TALENT_TRACK}`);
        this.snackbarService.openSnackBar(this.translate.instant('DeletedSuccessfully'), 'clear', 'success');
      }
    );
  }

  openConfirmDeleteDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      text: `${this.translate.instant('DeleteEmployee-ConfirmDeleteEmployeeText')} (${this.employee.firstName} ${this.employee.lastName})`
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.deleteEmployee();
        }
      }
    ); 
  }
}
