import {Component, OnInit, ViewChild} from '@angular/core';
import {SnackbarService} from '@app/core/services/snackbar.service';
import {OverlayService} from '@app/shared/components/overlay/overlay.service';
import {finalize} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {routes} from '@app/consts';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {OrganizationStructureService} from '@app/modules/organization-structure/services/organization-structure.service';
import {
    Organization,
    OrganizationSubmit,
    OrganizationVerbose
} from '@app/modules/organization-structure/models/organization-structure.model';
import {ConfirmDialogComponent} from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { Localization } from '@app/shared/models/localization.model';
import { ChangeReasonDialogComponent } from '@app/shared/components/change-reason-dialog/change-reason-dialog.component';
import { FormGeneratorComponent } from '@app/shared/components/form-generator/form-generator.component';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-work-rotation-editor',
    templateUrl: './organization-editor.component.html',
    styleUrls: ['./organization-editor.component.scss']
})
export class OrganizationEditorComponent implements OnInit {
    @ViewChild(FormGeneratorComponent) formGeneratorComponent: FormGeneratorComponent

    public organizationId: string;
    public isLoading: boolean;
    public organization: OrganizationVerbose = null;
    formData: { id: string; name: Localization[]; organizationType: string; parentOrganization: Organization; startDate: string; endDate: string; };
    formId: string = 'frm_qK0FYWUEQe7FOz';
    changeReasonFormId: string = 'frm_yPsGlEqUSjqwkp';
    getFormData: boolean = false;
    formValid: boolean = false;

    constructor(
        private dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        private snackbarService: SnackbarService,
        private overlayService: OverlayService,
        private translate: TranslateService,
        private organizationService: OrganizationStructureService
    ) {
    }

    ngOnInit(): void {
        this.organizationId = this.getIdInURL();
        this.organizationId !== null
            ? this.getOrganization()
            : this.isLoading = false;
        this.createForm();
    }

    getIdInURL(): string {
        let IdInURL: string;

        this.route.paramMap.subscribe(
            params => IdInURL = params.get('organizationId')
        );

        return IdInURL;
    }

    getOrganization() {
        this.isLoading = true;
        this.organizationService.getOrganization(this.organizationId)
            .pipe(
                finalize(() => this.isLoading = false)
            )
            .subscribe(
                res => {
                    this.organization = res;
                    this.createForm();
                }
            );
    }

    createForm() {
        this.formData = {
            id: this.organization ? this.organization.id : null,
            name: this.organization ? this.organization.name : null,
            organizationType: this.organization?.organizationType?.id ?? null,
            parentOrganization: this.organization?.parentOrganization ?? null,
            startDate: this.organization?.startDate ?? null,
            endDate: this.organization?.endDate ?? null,
        }
    }

    formDataEmitted(formData) {
        // this.openChangeReasonDialog(formData);
        this.save(formData);
    }

    formStatusUpdated(formValid) {
        this.formValid = formValid;
    }

    openChangeReasonDialog(formData: any) {
        const dialogConfig = new MatDialogConfig();
    
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
    
        dialogConfig.data = {
          formId: this.changeReasonFormId,
        };
    
        const dialogRef = this.dialog.open(ChangeReasonDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
          data => {
              if (data) {
                  this.addChangeReasonsToFormData(formData, data);
              }
    
          }
        );
    }

    addChangeReasonsToFormData(formData: any, changeReasonFormData: any) {
        let merged = {...formData, ...changeReasonFormData};
        this.save(merged);
    }

    save(formData) {
        if (!formData.parentOrganization) {
            const dialogConfig = new MatDialogConfig();

            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;

            dialogConfig.data = {
                text: `Please be aware that this new Organization Unit does not have a Parent Organization Unit assigned. As such it will appear at the top level of your organization. Continue?`
            };

            const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(
                data => {
                    if (data === true) {
                        this.overlayService.show();
                        if (this.organization) {
                            this.updateOrganization(formData);
                        } else {
                        }
                    }
                });
        } else {
            formData.parentOrganization = formData.parentOrganization?.id;
            this.overlayService.show();
            this.updateOrganization(formData);
        }
    }

    updateOrganization(organizationSubmit: OrganizationSubmit) {
        this.organizationService.updateOrganization(organizationSubmit.id, organizationSubmit)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe(
                (res) => {
                    this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
                },
                err => {
                    this.snackbarService.openSnackBar(err, 'clear', 'warn');
                }
            );
    }

    navigateBack() {
        this.router.navigate([`${routes.SITE_SETTINGS}/${routes.ORGANIZATION}`]);
    }
}
