<mat-card>
    <mat-card-title class="header">
      <h5 class="title">Active Tasks</h5>

      <mat-form-field appearance="outline">
        <mat-label>Filter</mat-label>
        <mat-select [(ngModel)]="birthdayTimePeriodSearchValue">
          <mat-option value="thisMonth">This Month</mat-option>
          <mat-option value="thisWeek">This Week</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-card-title>
  
    <mat-card-content class="content">
        <ng-container *ngIf="loading; else loaded">
            <ngx-skeleton-loader count="5" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
        </ng-container>
        <ng-template #loaded>
            <mat-list role="list">
                <mat-list-item class="employee" role="listitem" *ngFor="let employee of employees">
                    <div class="task-details">
                        <div>{{employee.task.task | uppercase}}</div>
                        <div class="date">Due by: {{employee.taskDueDate | date:'mediumDate'}}</div>
                    </div>
                    <div class="employee-details">
                        <img 
                            class="profile-pic"
                            [src]="employee.profilePictureThumbnailUri">
                        <div class="details">
                            <span>
                                {{employee.task.description}}
                            </span>
                            <span class="assigned-by">
                                Assigned by 
                                <span class="name" routerLink="{{routes.TALENT_TRACK}}{{routes.EDIT}}/{{employee.id}}">
                                     {{employee.firstName}} {{employee.lastName}}
                                </span>
                            </span>
                        </div>
                    </div>
                </mat-list-item>
            </mat-list>
        </ng-template>
    </mat-card-content>
</mat-card>