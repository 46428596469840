import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { features, routes } from '@app/consts';
import { FeatureService } from '@app/core/services/feature.service';
import { SecurityProtectedBase } from '@app/shared/components/security-protected/security-protected';
import { TranslateService } from '@ngx-translate/core';
import { WorkRotationService } from './services/work-rotation.service';
import { finalize } from 'rxjs/operators';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { WorkRotation } from './models/work-rotation.model';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { defer, forkJoin } from 'rxjs';

@Component({
  selector: 'app-work-rotations',
  templateUrl: './work-rotations.component.html',
  styleUrls: ['./work-rotations.component.scss']
})
export class WorkRotationsComponent extends SecurityProtectedBase implements OnInit {
  public routes: typeof routes = routes;
  public gridDataResult: GridDataResult;
  public bindingType: String = "array";
  isLoading: boolean;
  pageSize: number = 20;
  skip: number = 0;
  filterString: string;
  getRequest: any;
  clearSelectedItems: boolean = false;
  public searchValue: string;
  private sortString: string = "name-asc";
  public columns: any[] = [
    {
      field: 'name',
      title: 'Name',
      dataType: "String"
    },
    {
      field: 'positionsUsingRotation',
      title: `Positions Using ${this.translate.instant('WorkRotation')}`,
      dataType: "String"
    }
  ];

  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private workRotationService: WorkRotationService,
    private router: Router,
    private route: ActivatedRoute,
    featureService: FeatureService,
  ) {
    super(featureService, features.WORK_ROTATIONS)
  }

  ngOnInit(): void {
    this.getWorkRotations();
  }

  getWorkRotations(): void {
    this.isLoading = true;

    this.getRequest = this.workRotationService.getWorkRotations(this.skip, String(this.pageSize), this.filterString, this.sortString)
    .pipe(
        finalize(() => {
            this.isLoading = false;
        })
    )
    .subscribe(
        res => {
            this.gridDataResult = {
                data: res.data,
                total: res.total,
            };
        }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.getWorkRotations();
  }

  deleteAllSelected(itemsToDelete: string[]) {
    const observables = itemsToDelete.map(item => defer(() => this.workRotationService.deleteWorkRotation(item)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.clearSelectedItems = !this.clearSelectedItems;
        this.getWorkRotations();
        this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
      },
      err => {
          this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;;
    this.getWorkRotations();
  }

  search(searchValue) {
    this.searchValue = searchValue;
    this.filterString = `(Name like "${this.searchValue}")`;
    this.getRequest.unsubscribe();
    this.getWorkRotations();
  }

  navigateToWorkRotation(workRotation?: WorkRotation) {
    if(workRotation){
      this.router.navigate([`${routes.SITE_SETTINGS}${routes.WORK_ROTATIONS}${routes.EDITOR}/${workRotation?.id}`], {relativeTo: this.route});
    }
    else {
      this.router.navigate([`${routes.SITE_SETTINGS}${routes.WORK_ROTATIONS}${routes.EDITOR}`], {relativeTo: this.route});
    }
  }
}
