import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { db_tables, features, routes } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { defer, forkJoin, Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { WorkLocation, WorkLocationVerbose } from '../../models/work-location.model';
import { WorkLocationsService } from '../../services/work-locations.service';
import { Filterable } from '@app/shared/components/filter/filterable';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { SecuritySetupService } from '@app/modules/security-setup/services/security-setup.service';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { TableField } from '@app/modules/security-setup/models/table-field.model';
import { EmployeeTableFieldSecurity } from '@app/shared/models/employee.model';
import { SecurityProtectedBase } from '@app/shared/components/security-protected/security-protected';
import { FeatureService } from '@app/core/services/feature.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-work-locations-list',
  templateUrl: './work-locations-list.component.html',
  styleUrls: ['./work-locations-list.component.scss']
})
export class WorkLocationsListComponent extends SecurityProtectedBase implements OnInit, Filterable {

  @Input() events: Observable<void>;
  private eventsSubscription: Subscription;

  public routes: typeof routes = routes;
  public sort: SortDescriptor[] = [];
  public bindingType: String = "array";
  public view: Observable<GridDataResult>;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public selectedItems: any[] = [];
  public columns: any[] = [
    {field: 'name', title: 'Name', dataType: "String"},
    { field: "streetAddress", title: "Street Address", tableId: "tfi_WlStreetAddress", dataType: "String"},
    { field: "city", title: "City", dataType: "String", tableId: "tfi_WlCity" },
    { field: "province", title: "Province", dataType: "String", tableId: "tfi_WlProvince" },
    { field: "zip", title: "Zip", dataType: "String", tableId: "tfi_WlZip" },
    { field: "region", subField: 'text', title: "Region", dataType: "String", tableId: "" },
    { field: "country", subField: 'name', title: "Country", dataType: "String", tableId: "tfi_WlCountry" },
    { field: "timezone", subField: 'text', title: "Timezone", dataType: "String", tableId: "" },
    { field: "stylingColours", subField: 'name', title: "styling Color", tableId: "" },
    { field: 'companyLogo', subField: 'id', title: 'Logo', type: 'logoImage', tableId: "" },
    { field: "startDate", title: "Start Date", type: "date", dataType: "Date", tableId: "" },
    { field: "endDate", title: "End Date", type: "date", dataType: "Date", tableId: "" },
  ];

  sortableColumns: any[] = [
    { field: "name", sortValue: "name"},
    { field: "streetAddress", sortValue: "streetAddress" },
    { field: "city", sortValue: "city" },
    { field: "province", sortValue: "province" },
    { field: "zip", sortValue: "zip" },
    { field: "region", sortValue: "region.text" },
    { field: "country", sortValue: "country.name" },
    { field: "timezone", sortValue: "timezone.text" },
    { field: "startDate", sortValue: "startDate" },
    { field: "endDate", sortValue: "endDate" },
    { field: "stylingColor", sortValue: "stylingColor" },
  ];

  public filterCategories: any[] = [].concat(this.columns)
  filterString: string;

  filterToggleDetails: {
    text: string,
    checked: boolean
  } = {
    text: 'FilterInactive',
    checked: true
  }

  isLoading: boolean;
  pageSize: number = 20;
  skip: number = 0;

  sortString: string;
  getWorkLocationsRequest: any;

  public searchFilterString: string;
  public searchValue: string;
  formData: { id: string; streetAddress: string; city: string; province: string; country: string; zip: string; name: { culture: string; text: string; }[]; timezone: string; region: string; asOf: string; };
  formId: string = 'frm_cG2ukGyUomdLRr'
  dialogRef: any;
  sortingColumns: boolean;
  fieldList: TableField[];
  clearSelectedItems: boolean = false;

  constructor(
    private dialog: MatDialog,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
    private workLocationsService: WorkLocationsService,
    private securitySetupService: SecuritySetupService,
    private tablePermissionsService: TablePermissionsService,
    featureService: FeatureService
  ) {
    super(featureService, features.WORK_LOCATIONS)
  }

  ngOnInit(): void {
    this.getWorkLocations();
    this.getFields(db_tables.WorkLocations);
  }

  ngOnDestroy() {
    this.getWorkLocationsRequest.unsubscribe();
  }

  getFields(tableId) {
    this.sortingColumns = true;

    this.securitySetupService.getFields(tableId, 0, '200')
    .pipe(
      finalize( () => {
        this.sortingColumns = false;
        this.getTableFieldPermissions();
      })
    )
    .subscribe(
        pagedFieldList => {
          this.fieldList = pagedFieldList.data;

          this.fieldList.forEach(
            field => {
              let index = this.columns.findIndex( column => column.tableId === field.id )

              if(index !== -1){
                if(field.enabled === false){
                  this.columns.splice(index, 1);
                }

                if(field.name !== null){
                  this.columns[index].title = field.name;
                }
              }
            }
          );
        }
    );
  }

  getTableFieldPermissions() {
    let tableFieldPermissions: EmployeeTableFieldSecurity[] = this.tablePermissionsService.getTableFieldsPermissions(db_tables.WorkLocations);

    tableFieldPermissions?.forEach(
      tableFieldPermission => {
        let index = this.columns.findIndex( column => column.tableId === tableFieldPermission.field.id )

        if(index !== -1){
          if(tableFieldPermission.read === false || tableFieldPermission.deny === true){
            this.columns.splice(index, 1);
          }
        }
      }
    )
  }

  getWorkLocations(){
    this.isLoading = true;

    let filter: string = ``;
    
    if(this.searchFilterString) {
      filter = this.searchFilterString;
    }

    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }


    this.getWorkLocationsRequest = this.workLocationsService.getWorkLocations(this.skip, String(this.pageSize), filter, this.sortString, this.filterToggleDetails.checked)
    .pipe(
        finalize(()=>{ this.isLoading = false; })
    )
    .subscribe(
      res => {
        this.gridDataResult = {
          data: res.data,
          total: res.total,
        }
      }
    )
  }

  getWorkLocation(workLocation?: WorkLocation) {
    if(workLocation === undefined){
      this.openWorkLocationDialog();
    }
    else {
      this.isLoading = true;

      this.workLocationsService.getWorkLocation(workLocation.id)
      .pipe(
        finalize( () => this.isLoading = false )
      )
      .subscribe(
        res => {
          this.openWorkLocationDialog(res);
        }
      )
    }
  }

  openWorkLocationDialog(workLocationVerbose?: WorkLocationVerbose) {
    let formData = {
      id: workLocationVerbose?.id ?? null,
      streetAddress: workLocationVerbose?.streetAddress ?? null,
      city: workLocationVerbose?.city ?? null,
      province: workLocationVerbose?.province ?? null,
      country: workLocationVerbose?.country?.id ?? null,
      zip: workLocationVerbose?.zip ?? null,
      name: workLocationVerbose?.name ?? null,
      startDate: workLocationVerbose?.startDate ? moment(workLocationVerbose?.startDate, 'MM-DD-YYYY') : null,
      endDate: workLocationVerbose?.endDate ? moment(workLocationVerbose?.endDate, 'MM-DD-YYYY') : null,
      timezone: workLocationVerbose?.timezone?.id ?? null,
      region: workLocationVerbose?.region?.id ?? null,
      stylingColours: workLocationVerbose?.stylingColours?.id ?? null,
      companyLogo: workLocationVerbose?.companyLogo?.id ?? null,
      asOf: moment().format(),
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formTitle: `${this.translate.instant('WorkLocation')}`,
      formId: this.formId,
      formData: formData,
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      this.saveWorkLocation(event)
    });
  }

  saveWorkLocation(formData: any){
    this.overlayService.show();

    if(formData.id !== null) {
      this.workLocationsService.updateWorkLocation(formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.getWorkLocations();
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
        }
      );
    }
    else {
      this.workLocationsService.createWorkLocation(formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.getWorkLocations();
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
        }
      );
    }

  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.getWorkLocations();
  }


  deleteAllSelected(itemsToDelete: string[]) {
    const observables = itemsToDelete.map(selectedItem => defer(() => this.workLocationsService.deleteWorkLocation(selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.clearSelectedItems = !this.clearSelectedItems;
        this.getWorkLocations();
          this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;;
    this.getWorkLocations();
  }


  search(searchValue) {
    this.searchValue = searchValue;
    this.searchFilterString = `(Name like "${this.searchValue}")`;
    this.getWorkLocationsRequest?.unsubscribe();
    this.getWorkLocations();
  }

  updateFilterToggleDetails(toggleChange: MatSlideToggleChange){
    this.filterToggleDetails.checked = toggleChange.checked;
    this.getWorkLocations();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getWorkLocations();
  }
}
