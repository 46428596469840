import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { finalize } from 'rxjs/operators';
import { defer, forkJoin } from 'rxjs';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { api_routes, db_tables, routes } from '@app/consts';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ReviewsService } from './services/reviews.service';
import { Review } from './models/review.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {
  public columns: any[] = [
    { field: "employee", title: "Employee", type: "employee", tableId: "tfi_VpDn" },
    { field: "reviewPlanName", title: "Review Plan", tableId: "tfi_VpDn" },
    { field: "reviewPlanDescription", title: "Review Plan Description", tableId: "tfi_VpDn" },
    { field: "reviewPlanStartDate", title: "Start Date", type: "date", tableId: "tfi_VpDn" },
    { field: "reviewPlanEndDate", title: "End Date", type: "date", tableId: "tfi_VpDn" },
    { field: "reviewers", title: "Reviewers", type: "employees", tableId: "tfi_VpDn" },
    { field: "status", subField: "name", title: "Status", type: 'chip', chipValue: ['Complete', 'Archived', 'In Progress', 'Pending'], tableId: "tfi_VpDn" },
  ];

  filterCategories: any[] = [
    { field: "name", tableId: "tfi_PgTName", title: "Name", type: "String", dataType: 'String'},
    { field: "escription", tableId: "tfi_PgTName", title: "Description", type: "String", dataType: 'String'},
  ];

  sortableColumns: any[] = [
    { field: "employee", sortValue: ["Employee.FirstName", "Employee.LastName", "Employee.Id"]},
    { field: "reviewPlanName", sortValue: "reviewPlan.name"},
    { field: "reviewPlanDescription", sortValue: "reviewPlan.description"},
    { field: "status", sortValue: "status.name"},
  ];

  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 10;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  formId: string = 'frm_rSF47qcFdGmOnw'
  dialogRef: any;
  formValid: any;
  searchValue: string;
  searchFilterString: string;
  getReviewsRequest: any;
  sortString: string;
  filterString: string;

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private reviewsService: ReviewsService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.view = this.getReviews();
  }

  getReviews() {
    let filter;
    if(this.searchFilterString) {
      filter = this.searchFilterString;
    }
    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }

    this.isLoading = true;

    this.getReviewsRequest = this.reviewsService.getReviews(String(this.pageSize), this.skip, this.sortString, filter)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridDataResult = {
            data: this.formatData(res.data),
            total: res.total,
          }
        }
      );
  }

  formatData(data: Review[]){
    let newArray = data.map(obj => ({
      ...obj,
      "reviewPlanId": obj.reviewPlan.id,
      "reviewPlanName": obj.reviewPlan.name,
      "reviewPlanDescription": obj.reviewPlan.description,
      "reviewPlanStartDate": obj.reviewPlan.startDate,
      "reviewPlanEndDate": obj.reviewPlan.endDate
    }));

    return newArray;
  }

  getReview(review?: Review) {
    if(review === undefined){
      this.openFormDialog();
    }
    else {
      this.router.navigate([`${routes.PERFORMANCE}/${routes.REVIEWS}/Editor/${review.id}`]);
    }
  }

  openFormDialog(review?: Review) {
    let formData = {
      id: review ? review.id : null,
      asOf: moment().format(),
      changeReason: '',
      changeReasonComments: '',
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formId: this.formId,
      formData: formData
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      this.save(event)
    });
  }

  formDataEmitted(formData) {
    this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  save(formData: any){
    this.overlayService.show();
    if(!formData.id){
    }
    else {
      this.reviewsService.updateReview(formData.id, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getReviews();
        }
      );
    }
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.view = this.getReviews();
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;

    let variants = this.searchValue.split(' ').filter(value => value);
    this.searchFilterString = '';
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `(ReviewPlan.Name like "${variant}") OR (ReviewPlan.Description like "${variant}") OR (Employee.FirstName like "${variant}") OR (Employee.LastName like "${variant}")`;
        }
    });

    this.getReviewsRequest.unsubscribe();
    this.getReviews();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getReviews();
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;;
    this.getReviews();
  }

}
