import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { defer, forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SortDescriptor } from '@progress/kendo-data-query';
import { CustomReportsService } from "@app/modules/custom-reports/services/reports.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CustomReport } from "@app/modules/custom-reports/models/custom-report.model";
import { ConfirmDialogComponent } from "@app/shared/components/confirm-dialog/confirm-dialog.component";

@Component({
    selector: 'reports-list',
    templateUrl: './reports-list.component.html',
    styleUrls: ['./reports-list.component.scss']
})
export class ReportsListComponent implements OnInit {
    @Input() refreshView: boolean;
    public bindingType: String = 'array';
    public view: Observable<GridDataResult>;
    public gridData: any;
    public gridDataResult: GridDataResult;
    public selectedItems: any[] = [];
    pageSize: number = 20;
    skip: number = 0;
    isLoading: boolean;

    getRequest: any;

    public searchFilterString: string;
    public searchValue: string;

    public sort: SortDescriptor[] = [];
    public sortString: string = 'name-asc';
    public reportType: string;

    constructor(
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private router: Router,
        private overlayService: OverlayService,
        private snackbarService: SnackbarService,
        private reportsService: CustomReportsService,
    ) {
    }

    ngOnInit(): void {

        this.reportType = this.getTypeInURL();

        // this.getData();
    }

    getTypeInURL(): string {
        let IdInURL: string;

        this.route.paramMap.subscribe(
            params => IdInURL = params.get("type")
        )

        return IdInURL;
    }


    ngOnChanges(changes: SimpleChanges) {
        if (changes.refreshView && (changes.refreshView.currentValue !== changes.refreshView.previousValue)) {
            this.getData();
        }
    }

    getData() {
        this.isLoading = true;

        this.getRequest = this.reportsService.getNewReports(this.reportType, String(this.pageSize), String(this.skip))
        .pipe(
            finalize(() => {
                this.isLoading = false;
            })
        )
        .subscribe(
            res => {
                this.gridDataResult = {
                    data: res.data,
                    total: res.total,
                };
            }
        );
    }


    goToRenderReport(dataItem: CustomReport) {
        if (dataItem.url) {
            this.router.navigate([`${dataItem.url}`]);
        } else {
            this.router.navigate([`Reports/Custom/${dataItem.id}`]);
        }
    }

    goToEditReport(dataItem: CustomReport) {
        this.router.navigate([`Reports/Builder/Edit/${dataItem.id}`]);
    }

    backToReportGroups() {
        this.router.navigate([`Reports`]);
    }

    openNewReportDialog() {
        this.router.navigate([`Reports/Builder/${this.reportType}`]);

        // const dialogConfig = new MatDialogConfig();
        //
        // dialogConfig.disableClose = true;
        // dialogConfig.autoFocus = true;
        // dialogConfig.minWidth = 1200;
        //
        // dialogConfig.data = {
        //   reportType: this.reportType,
        // };
        //
        // const dialogRef = this.dialog.open(ReportBuilderComponent, dialogConfig);
    }

    openConfirmDeleteDialog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            text: `Are you sure you want to delete ${this.selectedItems.length} selected item(s)?`
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data === true) {
                    this.deleteAllSelected(this.selectedItems);
                }
            }
        );


    }

    private deleteAllSelected(itemsToDelete: string[]) {
            const observables = itemsToDelete.map(item => defer(() => this.reportsService.deleteReport(item)));

            this.overlayService.show();

            forkJoin(observables)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe(
                (res) => {
                    this.selectedItems = [];
                    this.getData();
                    this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
                },
                err => {
                    this.snackbarService.openSnackBar(err, 'clear', 'warn');
                }
            );
        }

    public pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.pageSize = event.take;
        this.getData();
    }
}
