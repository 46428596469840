<h5 mat-dialog-title>Employment Record: Discipline</h5>

<mat-dialog-content>
    <form class="form-container" *ngIf="form" [formGroup]="form">
        <mat-form-field appearance="fill">
            <mat-label>
                Reason
            </mat-label>

            <mat-select disableOptionCentering formControlName="reason">
                <mat-option *ngFor="let reasonOption of reasonOptions | async" [value]="reasonOption.id">{{reasonOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Action
            </mat-label>

            <mat-select disableOptionCentering formControlName="action">
                <mat-option *ngFor="let actionOption of actionOptions | async" [value]="actionOption.id">{{actionOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field  appearance="fill">
            <mat-label>
                Date
            </mat-label>
        
            <input matInput [matDatepicker]="startDatepicker" formControlName="date">
            <span fxLayout="row" matSuffix>
                <mat-datepicker-toggle  [for]="startDatepicker"></mat-datepicker-toggle>
            </span>
            <mat-datepicker #startDatepicker></mat-datepicker> 
            <mat-hint></mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Conducted By
            </mat-label>

            <input matInput
                type="text"
                formControlName="conductedBy">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Comments
            </mat-label>

            <textarea matInput formControlName="comments"></textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button> 
</mat-dialog-actions>