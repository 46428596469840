<div class="main-container">
    <ng-container *ngIf="loading; else loaded">
        <ngx-skeleton-loader
                count="5"
                [theme]="{ 
                    'height.px': 50
                }"
        ></ngx-skeleton-loader>
    </ng-container>
    
    <ng-template #loaded>
        <ng-container *ngIf="elements && elements.length > 0; else noElements">
            <div *ngFor="let element of elements">
<!--                <app-work-rotation-element-form -->
<!--                    [workRotationElement]="element" -->
<!--                    [workRotationId]="workRotationId" -->
<!--                    (elementDeleted)="elementRemoved($event)">-->
<!--                </app-work-rotation-element-form>-->
            </div>
        </ng-container>

        <ng-template #noElements>
            <div>
                <h5>No elements added to this work rotation</h5>
            </div>
        </ng-template>
    
        <button class="new-element-button" mat-flat-button setColor="primaryColour" color="primary" (click)="addNewElement()">
            <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> Add new element
        </button>
    </ng-template>
</div>
