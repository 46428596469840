import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { api_routes, db_tables } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { ReviewPlansService } from '../../services/review-plans.service';
import { defer, finalize, forkJoin } from 'rxjs';
import { ReviewPlanGoalPlan, ReviewPlanGoalPlanSubmit } from '../../models/review-plan.model';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';

@Component({
  selector: 'app-review-plan-goal-plans',
  templateUrl: './review-plan-goal-plans.component.html',
  styleUrls: ['./review-plan-goal-plans.component.scss']
})
export class ReviewPlanGoalPlansComponent implements OnInit {
  @Input() reviewPlanId: string;
  @Output() dataUpdated = new EventEmitter<boolean>();

  public columns: any[] = [
    { field: "goalPlan", subField: "name", title: this.translate.instant("GoalPlan"),},
    // { field: "reviewPlan", subField: "name", title: this.translate.instant("ReviewPlan")},
    { field: "weight", title: "weight", type: "percentage", percentageMaxValue: 1, tableId: "tfi_RpGpWeight" },
    { field: "requireWeights", title: "Require Weights", type: "booleanToggle", tableId: "tfi_RpGpRequireWeights" },
    { field: "enforceFullWeight", title: "enforce Full Weight", type: "booleanToggle", tableId: "tfi_RpGpEnforceFullWeight" },
    { field: "requireChildGoalsWeight", title: "require Child Goals Weight", type: "booleanToggle", tableId: "tfi_RpGpRequireChildGoalsWeight" },
    { field: "overallRating", title: "overall Rating", type: "booleanToggle", tableId: "tfi_RpGpOverallRating" },
    { field: "overallComments", title: "overall Comments", type: "booleanToggle", tableId: "tfi_RpGpOverallComments" },
    { field: "visibleOnReview", title: "visible On Review", type: "booleanToggle", tableId: "tfi_RpGpVisibleOnReview" },
    { field: "isGloballyRated", title: "is Globally Rated", type: "booleanToggle", tableId: "tfi_RpGpIsGloballyRated" },
    { field: "globalRatingGloballyVisible", title: "global Rating Globally Visible", type: "booleanToggle", tableId: "tfi_RpGpGlobalRatingGloballyVisible" },
    { field: "reviewers", title: "Reviewers", type: "employees", tableId: "tfi_RpGpReviewers" },
  ];

  filterCategories: any[] = [
    { field: "goalPlan", subField: "name", title: this.translate.instant("GoalPlan"), dataType: "SpecialLookup", lookupCode: 'GOAL_PLAN'},
    // { field: "reviewPlan", subField: "name", title: this.translate.instant("ReviewPlan"), dataType: "SpecialLookup", lookupCode: 'REVIEW_PLAN'},
    { field: "weight", title: "weight", tableId: "tfi_RpGpWeight", dataType: "Percentage"},
    { field: "requireWeights", title: "Require Weights", tableId: "tfi_RpGpRequireWeights", dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
    { field: "enforceFullWeight", title: "enforce Full Weight", tableId: "tfi_RpGpEnforceFullWeight", dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
    { field: "requireChildGoalsWeight", title: "require Child Goals Weight", tableId: "tfi_RpGpRequireChildGoalsWeight", dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
    { field: "overallRating", title: "overall Rating", tableId: "tfi_RpGpOverallRating", dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
    { field: "overallComments", title: "overall Comments", tableId: "tfi_RpGpOverallComments", dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
    { field: "visibleOnReview", title: "visible On Review", tableId: "tfi_RpGpVisibleOnReview", dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
    { field: "isGloballyRated", title: "is Globally Rated", tableId: "tfi_RpGpIsGloballyRated", dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
    { field: "globalRatingGloballyVisible", title: "global Rating Globally Visible", tableId: "tfi_RpGpGlobalRatingGloballyVisible", dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
  ];

  sortableColumns: any[] = [
    { field: "goalPlan", sortValue: "goalPlanName"},
    // { field: "reviewPlan", sortValue: "reviewPlanName"},
    { field: "weight", sortValue: "weight"},
    { field: "enforceFullWeight", sortValue: "enforceFullWeight"},
    { field: "requireChildGoalsWeight", sortValue: "requireChildGoalsWeight"},
    { field: "overallRating", sortValue: "overallRating"},
    { field: "overallComments", sortValue: "overallComments"},
    { field: "visibleOnReview", sortValue: "visibleOnReview"},
    { field: "isGloballyRated", sortValue: "isGloballyRated"},
    { field: "requireWeights", sortValue: "requireWeights"},
    { field: "globalRatingGloballyVisible", sortValue: "globalRatingGloballyVisible"},
  ];

  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 5;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  dialogRef: any;
  formValid: any;
  sortString: string;
  searchValue: string;
  searchFilterString: string;
  filterString: any;
  getGoalsRequest: any;

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private router: Router,
    private overlayService: OverlayService,
    private reviewPlansService: ReviewPlansService
  ) { }

  ngOnInit(): void {
    this.view = this.getReviewPlanGoalPlans();
  }

  getReviewPlanGoalPlans() {
    this.isLoading = true;

    let filter;
    if(this.searchFilterString) {
      filter = this.searchFilterString;
    }
    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }

    this.getGoalsRequest = this.reviewPlansService.getReviewPlanGoalPlans(this.reviewPlanId, String(this.pageSize), this.skip, this.sortString, filter)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          }
          this.gridData = res.data;
        }
      );
  }

  getReviewPlanGoalPlanVerbose(reviewPlanGoalPlan?: ReviewPlanGoalPlan) {
    if(reviewPlanGoalPlan === undefined){
      this.openReviewPlanGoalPlanDialog();
    }
    else {
      this.openReviewPlanGoalPlanDialog(reviewPlanGoalPlan);
    }
  }

  openReviewPlanGoalPlanDialog(reviewPlanGoalPlan?: ReviewPlanGoalPlan) {

    let formData = {
      goalPlanId: reviewPlanGoalPlan ? reviewPlanGoalPlan.goalPlan?.id : null,
      weight: reviewPlanGoalPlan ? reviewPlanGoalPlan.weight : null,
      enforceFullWeight: reviewPlanGoalPlan ? reviewPlanGoalPlan.enforceFullWeight : false,
      requireWeights: reviewPlanGoalPlan ? reviewPlanGoalPlan.requireWeights : false,
      requireChildGoalsWeight: reviewPlanGoalPlan ? reviewPlanGoalPlan.requireChildGoalsWeight : false,
      overallRating: reviewPlanGoalPlan ? reviewPlanGoalPlan.overallRating : false,
      overallComments: reviewPlanGoalPlan ? reviewPlanGoalPlan.overallComments : false,
      visibleOnReview: reviewPlanGoalPlan ? reviewPlanGoalPlan.visibleOnReview : false,
      isGloballyRated: reviewPlanGoalPlan ? reviewPlanGoalPlan.isGloballyRated : false,
      globalRatingGloballyVisible: reviewPlanGoalPlan ? reviewPlanGoalPlan.globalRatingGloballyVisible : false,
      reviewers: reviewPlanGoalPlan ? reviewPlanGoalPlan.reviewers : null,
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formId: 'frm_7CYszLe53FxsLy',
      formData: formData
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      event.reviewers = event.reviewers?.map(data => data.id);

      reviewPlanGoalPlan ? this.updateReviewPlanGoalPlan(event) : this.createNewReviewPlanGoalPlan(event)
    });
  }

  createNewReviewPlanGoalPlan(formData: ReviewPlanGoalPlanSubmit){
    this.overlayService.show();

    this.reviewPlansService.addReviewPlanGoalPlan(this.reviewPlanId, formData)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(res);
          this.dataUpdated.emit(true);
          this.getReviewPlanGoalPlans();
        }
    );
  }

  updateReviewPlanGoalPlan(formData: ReviewPlanGoalPlanSubmit){
    this.overlayService.show();

    this.reviewPlansService.updateReviewPlanGoalPlan(this.reviewPlanId, formData.goalPlanId, formData)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(res);
          this.dataUpdated.emit(true);
          this.getReviewPlanGoalPlans();
        }
    );
  }

  deleteGoals(goals: { id: string, name: string }[]) {
    const observables = goals.map(selectedItem => defer(() => this.reviewPlansService.deleteReviewPlanGoalPlan(this.reviewPlanId, selectedItem.id)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.view = this.getReviewPlanGoalPlans();
        this.clearSelectedItems = !this.clearSelectedItems;
          this.dataUpdated.emit(true);
          this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.view = this.getReviewPlanGoalPlans();
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;

    let variants = this.searchValue.split(' ').filter(value => value);
    this.searchFilterString = '';
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `(GoalPlan.Name like "${variant}")`;
        }
    });

    this.getGoalsRequest.unsubscribe();
    this.getReviewPlanGoalPlans();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getReviewPlanGoalPlans();
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;;
    this.getReviewPlanGoalPlans();
  }

}
