<mat-card class="chart">
    <mat-card-title class="chart__header">
      <h5 class="chart__header-title">Headcount By Country</h5>
  
      <app-settings-menu></app-settings-menu>
    </mat-card-title>
  
    <mat-card-content class="chart__content">
        <div id="chart">
            <apx-chart
              [series]="chartOptions.series"
              [chart]="chartOptions.chart"
              [dataLabels]="chartOptions.dataLabels"
              [plotOptions]="chartOptions.plotOptions"
              [xaxis]="chartOptions.xaxis"
            ></apx-chart>
        </div>
    </mat-card-content>
</mat-card>


