export enum tableid_to_name {
    tbl_Absences = 'Absences',
    tbl_Associations = 'Associations',
    tbl_BankDetails = 'BankDetails',
    tbl_CompanyAssets = 'CompanyAssets',
    tbl_Compensations = 'Compensation',
    tbl_Disciplines = 'Disciplines',
    tbl_Education = 'Education',
    tbl_EmergencyContacts = 'EmergencyContacts',
    tbl_Employees = 'Talent',
    tbl_EmploymentRecords = 'EmploymentRecords',
    tbl_Family = 'Family',
    tbl_Grievances = 'Grievances',
    tbl_InjuryIllness = 'InjuryIllness',
    tbl_Languages = 'Languages',
    tbl_MedicalTestings = 'MedicalTestings',
    tbl_OtherCompensations = 'OtherCompensations',
    tbl_PayrollDetails = 'PayrollDetails',
    tbl_Positions = 'Position',
    tbl_PositionsEmployees = 'PositionsEmployees',
    tbl_ProfessionalExpertises = 'ProfessionalExpertises',
    tbl_Projects = 'Projects',
    tbl_Relocations = 'Relocations',
    tbl_StockPurchasePlans = 'StockPurchasePlans',
    tbl_TrainingAndCertifications = 'TrainingAndCertifications',
    tbl_User = 'User',
    tbl_VisaPermits = 'VisaPermits',
    tbl_WorkExperiences = 'WorkExperiences',
    tbl_WorkLocations = 'WorkLocations',
}