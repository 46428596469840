import { Version } from "../version.model";

export interface TOIL {
    id: string;
    employee: {
        id: string;
        firstName: string;
        lastName: string
    };
    toilPolicy: {
        id: string;
        name: string
    };
    toilClassType: {
        id: string;
        name: string
    };
    startTime: string;
    endTime: string;
    startDate: string;
    endDate: string;
    halfDay: boolean;
    hours: number;
    comments: string;
    isApproved: boolean;
    approvedBy: {
        id: string;
        firstName: string;
        lastName: string
    };
    approvedOn: string;
    deniedBy: {
        id: string;
        firstName: string;
        lastName: string
    };
    denialComments: string;
    createdBy: {
        id: string;
        firstName: string;
        lastName: string
    };
    createdOn: string;
}


export interface TOILRequest {
    toilPolicyId: string
    toilClassTypeId: string
    startDate: string
    endDate: string
    hours: number
    halfDay: boolean
    startTime: string
    endTime: string
    comments: string
}

export interface EmployeeToilRequest {
    id: string
    employee: {
      id: string
      firstName: string
      lastName: string
    }
    toilPolicy: {
      id: string
      name: string
      toilType: {
        id: string
        name: string
      }
    }
    toilClassType: {
      id: string
      name: string
    }
    startTime: string
    endTime: string
    startDate: string
    endDate: string
    halfDay: boolean
    hours: number
    comments: string
    managers: [
      {
        id: string
        firstName: string
        lastName: string
      }
    ]
    createdBy: {
      id: string
      firstName: string
      lastName: string
    }
    createdOn: string
    version: Version
    requestStatus: {
      id: string
      name: string
    }
    requestStatusSetBy: {
      id: string
      firstName: string
      lastName: string
    }
    requestStatusUpdatedOn: string
    requestStatusComments: string
}

export interface DenialComment {
    id: string
    denialComments: string
}

export interface EmployeeToilPolicy {
    id: string
    employee: {
        id: string
        firstName: string
        lastName: string
    }
    toilPolicy: {
        id: string
        name: string
        toilType: {
          id: string
          name: string
        }
    }
    availableHours: number
}

export interface EmployeeToilPolicySubmit {
    policyId: string 
    employeeId: string
}

export interface ToilClassType {
    id: string
    name: string
}

export interface EmployeeToilRecord {
    id: string
    employee: {
        id: string
        firstName: string
        lastName: string
    }
    toilPolicy: {
        id: string
        name: string
    }
    toilClassType: {
        id: string
        name: string
    }
    startTime: string
    endTime: string
    startDate: string
    endDate: string
    halfDay: boolean
    hours: number
    comments: string
    createdBy: {
        id: string
        firstName: string
        lastName: string
    }
    createdOn: string
    version: Version
    toilRequest: {
        id: string
    }
}

export interface EmployeeToilRecordSubmit {
    originalCreatedOn: string
    asOf: string
    changeReason: string
    changeReasonComments: string
    toilPolicyId: string
    toilClassTypeId: string
    startTime: string
    endTime: string
    startDate: string
    endDate: string
    halfDay: boolean
    hours: number
    comments: string
    employeeId?: string
}

export interface EmployeeToilRecordVerbose {
    id: string
    employee: {
      id: string
      firstName: string
      lastName: string
    }
    toilPolicy: {
      id: string
      name: string
    }
    toilClassType: {
      id: string
      name: string
    }
    startTime: string
    endTime: string
    startDate: string
    endDate: string
    halfDay: boolean
    hours: number
    comments: string
    createdBy: {
      id: string
      firstName: string
      lastName: string
    }
    createdOn: string
    version: Version
    toilRequest: {
      id: string
    }
}

export interface ToilManualAdjustmentsSubmit {
  policyId: string
  employeeId: string
  hours: number
  changeReason: string
  changeReasonComments: string
}

export interface UpdateToilManualAdjustmentsSubmit {
  hours: number
  changeReason: string
  changeReasonComments: string
}

export interface ToilPolicyCalculationLog {
  on: string
  adjustment: number
  reason: string
  runningTotal: number
  createdOn: string
  expiredOn: string
}
