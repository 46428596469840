import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api_routes } from '@app/consts';
import { PositionVerbose } from '@app/modules/positions/models/positions.model';
import { environment } from '@environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EmployeePosition, EmployeePositionSubmit, EmployeePositionVerbose } from '../models/employment-record-position.model';
import {EnvironmentService} from "@app/core/services/environment.service";


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class EmploymentRecordPositionsService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getEmployeePositions(employeeId: string): Observable<EmployeePosition[]> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.POSITIONS}`, {
      headers: headers
    });
  }

  getEmployeePosition(positionId: string): Observable<EmployeePositionVerbose> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS_EMPLOYEES}/${positionId}`, {
      headers: headers
    });
  }

  deleteEmployeePosition(positionId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS_EMPLOYEES}/${positionId}`, {
      headers: headers
    });
  }

  assignEmployeeToPosition(employeePositionSubmit: EmployeePositionSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS_EMPLOYEES}`, employeePositionSubmit, {
      headers: headers
    });
  }

  updateEmployeeAssignedPosition(employeePositionId: string, employeePositionSubmit: EmployeePositionSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.POSITIONS_EMPLOYEES}/${employeePositionId}`, employeePositionSubmit, {
      headers: headers
    });
  }

}
