<h5 mat-dialog-title>Employment Record: Injury Illness</h5>

<mat-dialog-content>
    <form class="form-container" *ngIf="form" [formGroup]="form">
        <mat-form-field  appearance="fill">
            <mat-label>
                Date Reported
            </mat-label>

            <input matInput [matDatepicker]="dateReportedpicker" formControlName="dateReported">
            <span fxLayout="row" matSuffix>
                <mat-datepicker-toggle  [for]="dateReportedpicker"></mat-datepicker-toggle>
            </span>
            <mat-datepicker #dateReportedpicker></mat-datepicker> 
            <mat-hint></mat-hint>
        </mat-form-field>

        <mat-form-field  appearance="fill">
            <mat-label>
                Leave Start
            </mat-label>

            <input matInput [matDatepicker]="leaveStartpicker" formControlName="leaveStart">
            <span fxLayout="row" matSuffix>
                <mat-datepicker-toggle  [for]="leaveStartpicker"></mat-datepicker-toggle>
            </span>
            <mat-datepicker #leaveStartpicker></mat-datepicker> 
            <mat-hint></mat-hint>
        </mat-form-field>

        <mat-form-field  appearance="fill">
            <mat-label>
                Leave End
            </mat-label>

            <input matInput [matDatepicker]="leaveEndpicker" formControlName="leaveEnd">
            <span fxLayout="row" matSuffix>
                <mat-datepicker-toggle  [for]="leaveEndpicker"></mat-datepicker-toggle>
            </span>
            <mat-datepicker #leaveEndpicker></mat-datepicker> 
            <mat-hint></mat-hint>
        </mat-form-field>

        <mat-form-field  appearance="fill">
            <mat-label>
                Injury Illness Date
            </mat-label>

            <input matInput [matDatepicker]="injuryIllnessDatepicker" formControlName="injuryIllnessDate">
            <span fxLayout="row" matSuffix>
                <mat-datepicker-toggle  [for]="injuryIllnessDatepicker"></mat-datepicker-toggle>
            </span>
            <mat-datepicker #injuryIllnessDatepicker></mat-datepicker> 
            <mat-hint></mat-hint>
        </mat-form-field>

        <mat-form-field  appearance="fill">
            <mat-label>
                Time Of Incident
            </mat-label>

            <input matInput [matDatepicker]="timeOfIncidentpicker" formControlName="timeOfIncident">
            <span fxLayout="row" matSuffix>
                <mat-datepicker-toggle  [for]="timeOfIncidentpicker"></mat-datepicker-toggle>
            </span>
            <mat-datepicker #timeOfIncidentpicker></mat-datepicker> 
            <mat-hint></mat-hint>
        </mat-form-field>

        

        <mat-form-field appearance="fill">
            <mat-label>
                Status
            </mat-label>

            <mat-select disableOptionCentering formControlName="status">
                <mat-option *ngFor="let statusOption of statusOptions | async" [value]="statusOption.id">{{statusOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Government Reportable
            </mat-label>

            <mat-select disableOptionCentering formControlName="governmentReportable">
                <mat-option *ngFor="let governmentReportableOption of governmentReportableOptions | async" [value]="governmentReportableOption.id">{{governmentReportableOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Case Description
            </mat-label>

            <mat-select disableOptionCentering formControlName="caseDescription">
                <mat-option *ngFor="let caseDescriptionOption of caseDescriptionOptions | async" [value]="caseDescriptionOption.id">{{caseDescriptionOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Day Of Week
            </mat-label>

            <mat-select disableOptionCentering formControlName="dayOfWeek">
                <mat-option *ngFor="let dayOfWeekOption of dayOfWeekOptions | async" [value]="dayOfWeekOption.id">{{dayOfWeekOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Occurence Location
            </mat-label>

            <mat-select disableOptionCentering formControlName="occurenceLocation">
                <mat-option *ngFor="let occurenceLocationOption of occurenceLocationOptions | async" [value]="occurenceLocationOption.id">{{occurenceLocationOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Occured On Employee Premise
            </mat-label>

            <mat-select disableOptionCentering formControlName="occuredOnEmployeePremise">
                <mat-option *ngFor="let occuredOnEmployeePremiseOption of occuredOnEmployeePremiseOptions | async" [value]="occuredOnEmployeePremiseOption.id">{{occuredOnEmployeePremiseOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Part Of Body
            </mat-label>

            <mat-select disableOptionCentering formControlName="partOfBody">
                <mat-option *ngFor="let partOfBodyOption of partOfBodyOptions | async" [value]="partOfBodyOption.id">{{partOfBodyOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Occurence Type
            </mat-label>

            <mat-select disableOptionCentering formControlName="occurenceType">
                <mat-option *ngFor="let occurenceTypeOption of occurenceTypeOptions | async" [value]="occurenceTypeOption.id">{{occurenceTypeOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Occupational Illness
            </mat-label>

            <mat-select disableOptionCentering formControlName="occupationalIllness">
                <mat-option *ngFor="let occupationalIllnessOption of occupationalIllnessOptions | async" [value]="occupationalIllnessOption.id">{{occupationalIllnessOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Occurence Country
            </mat-label>

            <mat-select disableOptionCentering formControlName="occurenceCountry">
                <mat-option *ngFor="let occurenceCountryOption of occurenceCountryOptions | async" [value]="occurenceCountryOption.id">{{occurenceCountryOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Days Off Work
            </mat-label>

            <input matInput
                type="number"
                formControlName="daysOffWork">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Comments
            </mat-label>

            <textarea matInput formControlName="comments"></textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button> 
</mat-dialog-actions>