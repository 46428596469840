<div>

    <div class="k-d-flex k-d-flex-row k-mb-3">
        <h5>{{ 'CreateATimeOffInLieuType' | translate: {Default: "Create a Time Off In Lieu Type"} }}</h5>
        <div class="k-flex-grow"></div>
        <div class="buttons-container">
            <button mat-raised-button (click)="cancel()" class="k-mr-2">{{ 'Close' | translate: {Default: "Close"} }}</button>
            <button mat-raised-button setColor="primaryColour" color="primary" (click)="saveForm()" [disabled]="!form.valid">{{ 'Save' | translate: {Default: "Save"} }}</button>
        </div>
    </div>

    <div class="form-section-title">
    </div>

    <div class="form" [formGroup]="form">

        <h6>{{ 'TimeOffInLieuTypeName' | translate: {Default: "Time Off In Lieu Type Name"} }}</h6>

        <ng-container formArrayName="localizations">
            <ng-container *ngFor="let localizationForm of localizations.controls; let i = index">
                <div class="lookup-form-row">
                    <div [formGroup]="localizationForm">
                        <mat-form-field appearance="fill" class="k-mr-2">
                            <mat-label>
                                {{ 'SelectLanguage' | translate: {Default: "Select Language"} }}
                            </mat-label>

                            <mat-select disableOptionCentering formControlName="culture">
                                <mat-option *ngFor="let culture of cultures" [value]="culture.id">{{culture.text}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="k-mr-2">
                            <mat-label>
                                {{ 'ToilTypeName' | translate: {Default: "TOIL Type Name"} }}
                            </mat-label>

                            <input matInput
                                   formControlName="text"
                                   placeholder="Type Name">
                        </mat-form-field>

                        <mat-icon 
                            setColor
                            [setColorRules]="[
                                {
                                    color: 'primaryColour',
                                    events: ['hover']
                                }
                            ]"
                            class="material-icons-outlined" 
                            (click)="deleteLocalization(i)" 
                            matTooltip="Delete"
                        >delete</mat-icon>
                    </div>

                </div>
            </ng-container>
        </ng-container>


        <div class="k-mb-3 k-mt-3">
            <button mat-raised-button (click)="addNewLocalization()" class="k-mr-2">
                <mat-icon class="material-icons-outlined">add</mat-icon>
                {{ 'AddTextLocalization' | translate: {Default: "Add Text Localization"} }}
            </button>
        </div>

        <div class="form-section-title">
            <h6>{{ 'TimeOffInLieuIcon' | translate: {Default: "Time Off In Lieu Icon"} }}</h6>
        </div>
        <div class="k-mb-3">
            <div *ngIf="filePath && filePath !== ''; else noImage">
                <img [src]="filePath" [alt]="form.value.image.name" class="timeoff-type-icon">
            </div>
            <ng-template #noImage>
                <div class="no-image"></div>
            </ng-template>
        </div>

        <div class="k-mb-3">
            <button type="button" mat-raised-button (click)="fileInput.click()">{{ 'ChooseFile' | translate: {Default: "Choose File"} }}</button>
            <input hidden type="file" accept="image/*" #fileInput (change)="imagePreview($event)"/>
        </div>

    </div>
</div>
