<h5 mat-dialog-title>New Translation Code</h5>

<mat-dialog-content>
    <form class="form" [formGroup]="form">
        <mat-form-field appearance="fill">
            <mat-label>
                Translation Code
            </mat-label>
            
            <input 
                matInput
                type="text"
                formControlName="id">
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button> 
</mat-dialog-actions>