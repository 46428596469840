<h5 mat-dialog-title></h5>

<mat-dialog-content>
    <ngx-doc-viewer
        [url]="fileUrl"
        [viewer]="viewer"
        style="width:100%;height:50vh;"
    ></ngx-doc-viewer>
</mat-dialog-content>

<mat-dialog-actions class="bottom-container">
    <button class="mat-raised-button" (click)="close()">Close</button>
</mat-dialog-actions>