<!-- <div fxLayout="column" fxLayoutAlign="space-around none">
    <div class="navigation">
        <app-navigation></app-navigation>
    </div>
    <div 
        fxLayout="row"
        fxLayout.xs="column" 
        fxLayoutAlign="center">
        <div fxFlex="10"></div>
        <div fxFlex>
            <router-outlet></router-outlet>
        </div>
        <div fxFlex="10"></div>
    </div>
    <div class="footer"></div>
</div> -->

<div  fxLayout="column" class="content-container">
    <div fxFlex="none">
    </div>
    <div fxFlex>
        <router-outlet></router-outlet>
    </div>
</div>


