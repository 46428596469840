<ng-container *ngIf="isLoading; else loaded">
    <ngx-skeleton-loader
        count="1"
        [theme]="{ 
        'height.px': 50,
        'width.px': 300
        }"
    ></ngx-skeleton-loader>
</ng-container>

<ng-template #loaded>
    <div [formGroup]="parentGroup" (click)="$event.stopPropagation(); openSelectEmployeeDialog()">
        <mat-form-field appearance="fill" class="employee-select-form-field" [ngClass]="{'invisible':formElement.hidden === 'true'}">
            <mat-label>
                {{ formElement.text }}
            </mat-label>
    
            <span matSuffix 
              *ngIf="!readOnly"
              style="margin-right: 8px;">
              <mat-icon 
                setColor 
                [setColorRules]="[
                    {
                        color: 'primaryColour',
                        events: ['hover']
                    }
                ]"
                class="material-icons-outlined clickable-icon" 
                (click)="$event.stopPropagation(); clearSelectedEmployee()">close</mat-icon>
            </span>
    
            <mat-select disableOptionCentering #employeeSelect [formControlName]="formElement.formControl" (click)="$event.stopPropagation(); openSelectEmployeeDialog()">
                <mat-select-trigger>
                    <app-employee-image [employeeId]="employeeOptions[0]?.id" [width]="35" [height]="35" [round]="true" *ngIf="!isLoading"></app-employee-image>
                    <span>{{employeeOptions[0]?.firstName}} {{employeeOptions[0]?.lastName}}</span>
                </mat-select-trigger>
                
                <mat-option></mat-option>
    
                <ng-container>
                    <mat-option *ngFor="let option of employeeOptions" [value]="option.id">
                        <app-employee-image [employeeId]="option?.id" [width]="35" [height]="35" [round]="true" *ngIf="!isLoading"></app-employee-image>
                        {{option?.firstName}} {{option?.lastName}}
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
    </div>
</ng-template>
