import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { api_routes, db_tables } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { FormGeneratorComponent } from '@app/shared/components/form-generator/form-generator.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { AccessLogsDialogComponent } from '../../../employment-records/components/employment-record-details/components/access-logs-dialog/access-logs-dialog.component';
import { HistoryDialogComponent } from '../../../employment-records/components/employment-record-details/components/history-dialog/history-dialog.component';
import { EmployeeEmploymentDetails } from './models/employee-employment-details.model';
import { EmployeeEmploymentDetailsService } from './services/employee-employment-details.service';

@Component({
  selector: 'app-employee-employment-details',
  templateUrl: './employee-employment-details.component.html',
  styleUrls: ['./employee-employment-details.component.scss']
})
export class EmployeeEmploymentDetailsComponent implements OnInit {
  @ViewChild(FormGeneratorComponent) formGeneratorComponent: FormGeneratorComponent
  employeeID: string;
  form: UntypedFormGroup;
  employmentDetails: EmployeeEmploymentDetails;
  isLoading: boolean = true;
  formData: any;
  formId: string = 'frm_Klzo4VZuuq977E';
  getFormData: boolean = false;
  formValid: boolean = false;
  public columns: any[] = [
    { field: "adjustedOriginalHireDate", title: "Adjusted Original Hire Date", type: "date", tableId: "tfi_EdAdjustedOriginalHireDate"},
    { field: "adjustedServiceDate", title: "adjusted Service Date", type: "date", tableId: "tfi_EdAdjustedServiceDate"},
    { field: "lengthOfService", title: "length Of Service", tableId: "tfi_EdLengthOfService"},
    { field: "employmentStatus", subField: 'text', title: "employment Status", tableId: "tfi_EdEmploymentStatus"},
    { field: "expat", title: "expat", type: "boolean", default: ["Yes", "No"], tableId: "tfi_EdExpat"},
    { field: "adjustedSiteDate", title: "adjusted Site Date", type: "date", tableId: "tfi_EdAdjustedSiteDate"},
    { field: "adjustedRoleDate", title: "adjusted Role Date", type: "date", tableId: "tfi_EdAdjustedRoleDate"},
    { field: "udl1", subField: 'text', title: "user defined dropdown 1", tableId: "tfi_EdUdl1"},
    { field: "udl2", subField: 'text', title: "user defined dropdown 2", tableId: "tfi_EdUdl2"},
    { field: "udt1", title: "uer defined text 1", tableId: "tfi_EdUdt1"},
    { field: "udt2", title: "uer defined text 2", tableId: "tfi_EdUdt2"},
  ];
  targetEmployeeTablePermissions: EmployeeTableSecurity[];
  isLoadingPermissions: boolean = true;
  formPristine: boolean;
  effectiveDate = new Date();
  
  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private tablePermissionsService: TablePermissionsService,
    private employeeEmploymentDetailsService: EmployeeEmploymentDetailsService,
  ) {
    this.route.paramMap.subscribe(
      params => this.employeeID = params.get("id")
    )
  }

  ngOnInit(): void {
    this.getEmploymentDetails();
    this.getTargetEmployeeTablePermissions();
  }

  get tableReadAccessObj() {
    return {
      tableId: db_tables.EmploymentDetails,
      permission: 'read',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableCreateAccessObj() {
    return {
      tableId: db_tables.EmploymentDetails,
      permission: 'create',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableUpdateAccessObj() {
    return {
      tableId: db_tables.EmploymentDetails,
      permission: 'update',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableDeleteAccessObj() {
    return {
      tableId: db_tables.EmploymentDetails,
      permission: 'delete',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  getEmploymentDetails() {
    this.isLoading = true;

    let asOf = moment(this.effectiveDate).format('YYYY-MM-DD');

    this.employeeEmploymentDetailsService.getEmploymentDetails(this.employeeID, asOf)
    .pipe(
        finalize(() => {
            this.isLoading = false;
            this.createForm();
        })
    )
    .subscribe(
        res => {
            this.employmentDetails = res;
        }
    );
  }

  getTargetEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeID);

    // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
    if(currentFetchedData !== null){
      this.targetEmployeeTablePermissions = currentFetchedData;
      this.isLoadingPermissions = false;
    }
    else {
      this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeID)
      .pipe(
        finalize(() => {
            this.isLoadingPermissions = false;
        })
      )
      .subscribe(
        res => {
          this.targetEmployeeTablePermissions = res;
        }
      );
    }
  }

  createForm() {
    this.formData = {
        asOf: moment().format(),
        changeReason: '',
        changeReasonComments: '',

        employeeId: this.employeeID,
        adjustedOriginalHireDate: this.employmentDetails ? this.employmentDetails.adjustedOriginalHireDate : null,
        adjustedServiceDate: this.employmentDetails ? this.employmentDetails.adjustedServiceDate : null,
        lengthOfService: this.employmentDetails ? this.employmentDetails.lengthOfService : null,
        employmentStatus: this.employmentDetails ? this.employmentDetails.employmentStatus?.id : null,
        adjustedSiteDate: this.employmentDetails ? this.employmentDetails.adjustedSiteDate : null,
        adjustedRoleDate: this.employmentDetails ? this.employmentDetails.adjustedRoleDate : null,
        expat: this.employmentDetails ? this.employmentDetails.expat : false,
        udl1: this.employmentDetails ? this.employmentDetails.udl1?.id : null,
        udl2: this.employmentDetails ? this.employmentDetails.udl2?.id: null,
        udt1: this.employmentDetails ? this.employmentDetails.udt1 : null,
        udt2: this.employmentDetails ? this.employmentDetails.udt2 : null,
    };
  }

  formDataEmitted(formData) {
      this.save(formData);
  }

  formStatusUpdated(formValid) {
      this.formValid = formValid;
  }

  save(formData: any) {
    formData.asOf = this.effectiveDate;
    formData.originalCreatedOn = this.employmentDetails?.version?.createdOn ? this.employmentDetails?.version?.createdOn : null;
    this.overlayService.show();

    this.employeeEmploymentDetailsService.updateEmploymentDetails(this.employeeID, formData)
        .pipe(
            finalize(() => {
                this.overlayService.hide();
            })
        )
        .subscribe(
            (res) => {
              this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
            }
        );
  }

  openAccessLogsDialog() {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
          employeeId: this.employeeID,
          entityId: this.employeeID,
          url: `${api_routes.TALENT}/${this.employeeID}/${api_routes.EMPLOYMENT_DETAILS}/${this.employeeID}`
      };

      const dialogRef = this.dialog.open(AccessLogsDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
          data => {
          }
      );
  }

  openHistoryDialog() {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
          employeeId: this.employeeID,
          entityId: null,
          url: `${api_routes.TALENT}/${this.employeeID}/${api_routes.EMPLOYMENT_DETAILS}`,
          columns: this.columns,
          tableId: db_tables.EmploymentDetails,
      };

      const dialogRef = this.dialog.open(HistoryDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
          data => {
          }
      );
  }

  effectiveDateChange(event) {
    if(this.employmentDetails){
        this.getEmploymentDetails();
    }
  }

}
