import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { FileUploadService } from './services/file-upload.service';

@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss']
})
export class FileUploadDialogComponent implements OnInit {
  emitDocumentToUpload = new EventEmitter();
  currentFile?: File;
  progress = 0;
  message = '';
  parentDirectory: string;
  name: string;
  fileName = 'Select File';
  fileInfos?: Observable<any>;
  form: UntypedFormGroup;
  directories$: Observable<any>;
  uploadApiPath: any;

  constructor(
    private fileUploadService: FileUploadService,
    private dialogRef: MatDialogRef<FileUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      this.parentDirectory = data.parentDirectory;
      this.uploadApiPath = data.uploadApiPath;
  }

  ngOnInit() {
  }

  selectFile(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file: File = event.target.files[0];
      this.currentFile = file;
      this.fileName = this.currentFile.name;
    } else {
      this.fileName = 'Select File';
    }
  }

  upload() {
    this.progress = 0;
    this.message = "";

    if (this.currentFile) {
      this.fileUploadService.uploadDocument(this.currentFile, null, this.parentDirectory, this.uploadApiPath).subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progress = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            this.message = event.body.message;
            this.dialogRef.close(true);
          }
        },
        (err: any) => {
          this.progress = 0;

          if (err.error && err.error.message) {
            this.message = err.error.message;
          } else {
            this.message = 'Could not upload the file!';
          }

          this.currentFile = undefined;
        });
    }
  }

  close() {
      this.dialogRef.close();
  }

}
