<mat-card class="mat-elevation-z0 homepage-employee-goals-tabs">
    <!-- <mat-card-title class="header">
        <h5 appLocalizationPopupDirective localizationCode="EmployeeGoals">{{ 'EmployeeGoals' | translate: {Default: "Employee Goals"} }}</h5>
    </mat-card-title> -->

    <mat-card-content>
        <mat-tab-group animationDuration="0ms">
            <!-- <mat-tab>
                <ng-template mat-tab-label>
                    <div appLocalizationPopupDirective localizationCode="PublishedEmployeeGoals">{{ translate.instant('PublishedEmployeeGoals') }}</div>
                </ng-template>
        
                <ng-template matTabContent>
                    <app-employee-published-goal-parent-data-grid
                        [employeeId]="employeeId"
                    ></app-employee-published-goal-parent-data-grid>
                </ng-template>
            </mat-tab> -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <div appLocalizationPopupDirective localizationCode="GoalPlans">{{ translate.instant('GoalPlans') }}</div>
                </ng-template>
        
                <ng-template matTabContent>
                    <app-employee-goal-plans
                        [employeeId]="employeeId"
                    ></app-employee-goal-plans>
                </ng-template>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    <div appLocalizationPopupDirective localizationCode="PersonalGoals">{{ translate.instant('PersonalGoals') }}</div>
                </ng-template>
        
                <ng-template matTabContent>
                    <app-employee-personal-goals
                        [employeeId]="employeeId"
                    ></app-employee-personal-goals>
                </ng-template>
            </mat-tab>
            
            <mat-tab>
                <ng-template mat-tab-label>
                    <div appLocalizationPopupDirective localizationCode="AllGoals">{{ translate.instant('AllGoals') }}</div>
                </ng-template>
        
                <ng-template matTabContent>
                    <app-employee-goals 
                        [employeeId]="employeeId"
                    ></app-employee-goals>
                </ng-template>
            </mat-tab>
        
        </mat-tab-group>
    </mat-card-content>
</mat-card>
