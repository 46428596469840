import { Component, OnInit } from '@angular/core';
import { routes } from '@app/consts/routes';
import { EmployeeService } from '@app/core/services/employee.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import * as moment from 'moment';

@Component({
  selector: 'app-birthdays',
  templateUrl: './birthdays.component.html',
  styleUrls: ['./birthdays.component.scss']
})
export class BirthdaysComponent implements OnInit {
  employees: any[] = []; 
  loading: boolean = true;
  public routes: typeof routes = routes;
  birthdayTimePeriodSearchValue: string = "thisMonth";

  constructor(
    private employeeService: EmployeeService,
    private snackbarService: SnackbarService,
  ) { }

  ngOnInit(): void {
    this.getEmployees();
  }

  getEmployees() {
    this.employeeService.getEmployees()
    .subscribe(
      res => {
        this.employees = res.employees;
        this.loading = false;
      }
    )
  }

  isToday(date: string){
    return moment().dayOfYear() === moment(date).dayOfYear()
  }

  sendCongrats(){
    this.snackbarService.openSnackBar('Congrats Sent', 'clear', 'success');
  }

}
