<h5 mat-dialog-title>Copy Role</h5>

<mat-dialog-content>

    <div *ngIf="!isLoading else loading" [formGroup]="form">
        <div class="form-section">
            <div class="form-section-contents">
                <div class="section-row">
                    <mat-form-field appearance="fill">
                        <mat-label>
                            Security Role Name
                        </mat-label>

                        <input matInput
                               formControlName="name"
                               placeholder="Security Role Name">
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <ng-template #loading>
        <div class="loading-container">
            <ngx-skeleton-loader count="5" [theme]="{ 'height.px': 30}"></ngx-skeleton-loader>
        </div>
    </ng-template>

</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Cancel</button>

    <button *ngIf="!isLoading && form" class="add-all-selected-button" mat-raised-button setColor="primaryColour" color="primary" (click)="copySecurityRole()" [disabled]="form.valid === false" >
        <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon>
        Create Copy
    </button>

</mat-dialog-actions>
