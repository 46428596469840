import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { EmployeeGoalSubmit, GoalSubmit, GoalVerbose } from '../goal-plans/models/goal-plan.model';
import { GoalPlanService } from '../goal-plans/services/goal-plan.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GoalTypeService } from '../goal-types/services/goal-type.service';
import { Observable } from 'rxjs';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { GoalType } from '../goal-types/models/goal-type.model';

@Component({
  selector: 'app-goal-dialog',
  templateUrl: './goal-dialog.component.html',
  styleUrls: ['./goal-dialog.component.scss']
})
export class GoalDialogComponent implements OnInit {
  goal: GoalVerbose;
  employeeId: string;
  readOnly: boolean;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  forthFormGroup: FormGroup;
  goalForm: FormGroup;
  goalTypes: GoalType[] = [];
  // Slider variables
  sliderValue: number = 0;
  percentageSliderValue: number = 0;
  percentageInputDisabled: boolean;

  weightSliderValue: number = 0;
  weightPercentageSliderValue: number = 0;
  weightPercentageInputDisabled: boolean;
  

  showComments: boolean = true;

  isLoading: boolean = true;
  isAParent: boolean = false;
  checkingIfParent: boolean;
  selectedGoalType: GoalType;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private translate: TranslateService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    public router: Router,
    private dialogRef: MatDialogRef<GoalDialogComponent>,
    private goalTypeService: GoalTypeService,
    private goalService: GoalPlanService,
    @Inject(MAT_DIALOG_DATA) data
  ){
    this.goal = data.goal;
    this.employeeId = data.employeeId;
    this.readOnly = data.readOnly;
  }

  ngOnInit(): void {
    this.getGoalTypes();
    this.checkIfIsAParent();
  }
  
  getGoalTypes() {
    let filter = '(allowEmployeeGoalEntry = "true")';

    this.goalTypeService.getGoalTypes('1000', 0, null, null)
    .pipe(
      finalize(() => this.buildFormData())
    )
    .subscribe(
      (res) => {
        this.goalTypes = res.data;
      }
    );
  }

  buildFormData(){
    this.isLoading = true;

    this.firstFormGroup = this.fb.group(
      {
        id: [this.goal ? this.goal.id : null],
        objective: [this.goal ? this.goal.objective : null],
        description: [this.goal ? this.goal.description : null],
      }
    );

    this.secondFormGroup = this.fb.group(
      {
        parentId: [this.goal ? this.goal.parent?.id : null],
      }
    );

    this.thirdFormGroup = this.fb.group(
      {
        goalTypeId: [this.goal ? this.goal.goalType?.id : null],
      }
    );

    this.forthFormGroup = this.fb.group(
      {
        percentageComplete: [this.goal ? this.goal.percentageComplete : null],
        weight: [this.goal ? this.goal.weight : null],
        expectedCompletionDate: [this.goal ? this.goal.expectedCompletionDate : null],
        actualCompletionDate: [this.goal ? this.goal.actualCompletionDate : null],
      }
    );

    // If it is a published goal disable the goal type dropdown and description
    if(this.goal?.goalPlan !== null && this.goal?.goalPlan !== undefined){
      this.thirdFormGroup.get('goalTypeId').disable()
      this.firstFormGroup.get('objective').disable()
      this.firstFormGroup.get('description').disable()
    }
    else {
      this.thirdFormGroup.get('goalTypeId').enable() 
      this.firstFormGroup.get('objective').enable()
      this.firstFormGroup.get('description').enable()
    }

    this.thirdFormGroup.get('goalTypeId').valueChanges.subscribe(value => {
      this.setFormFieldsDependingOnGoalType();
    });

    this.setFormFieldsDependingOnGoalType();

    this.calculateSliderPercentage(this.forthFormGroup.get('percentageComplete').value, 1);
    this.calculateWeightSliderPercentage(this.forthFormGroup.get('weight').value, 1);

    this.isLoading = false;
  }

  setFormFieldsDependingOnGoalType(){
    this.selectedGoalType = this.goalTypes.find( goalType => goalType.id === this.thirdFormGroup.get('goalTypeId').value );

    this.selectedGoalType?.expectedCompletionDateFieldSetting.id === 'PerEmployee' 
    ? this.forthFormGroup.get('expectedCompletionDate').enable() 
    : this.forthFormGroup.get('expectedCompletionDate').disable();

    this.selectedGoalType?.completionDateFieldSetting.id === 'PerEmployee' 
    ? this.forthFormGroup.get('actualCompletionDate').enable() 
    : this.forthFormGroup.get('actualCompletionDate').disable();

    this.selectedGoalType?.percentageCompleteFieldSetting.id === 'PerEmployee' 
    ? this.percentageInputDisabled = false
    : this.percentageInputDisabled = true

    this.showComments = this.selectedGoalType?.hasNotes; 

    // if(this.goal.goalPlan !== null){
    //   this.thirdFormGroup.get('goalTypeId').disable() 
    // }
    // else {
    //   this.thirdFormGroup.get('goalTypeId').enable();
    // }
  }

  checkIfIsAParent(){
    this.checkingIfParent = true;

    let filter = `(parent.id = "${this.goal.id}")`;

    this.goalService.getEmployeeGoals(this.employeeId, '20', 0, null, filter)
      .pipe(
        finalize( () => {
          this.checkingIfParent = false;
        })
      )
      .subscribe(
        (res) => {
          if(res.employeeGoals.data.length > 0){
            this.isAParent = true;
          }
        }
      );
  }

  calculateSliderPercentage(value: number, max: number) {
    this.percentageSliderValue = Math.round((value/max) * (100/1));
  }

  calculateWeightSliderPercentage(value: number, max: number) {
    this.weightSliderValue = Math.round((value/max) * (100/1));
  }

  setValue(value, formGroup, formControl) {
    formGroup.get(formControl).setValue(value)
  }

  save(){
    if(this.goal?.id){
      this.updateGoal(this.goalForm.getRawValue())
    }
    else {
      this.createNewGoal(this.goalForm.getRawValue())
    }
  }

  createNewGoal(formData: EmployeeGoalSubmit){
    this.overlayService.show();

    this.goalService.createEmployeeGoal(this.employeeId, formData)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');  
          this.dialogRef.close(true);
        }
    );
  }

  updateGoal(formData: EmployeeGoalSubmit){
    this.overlayService.show();

    this.goalService.updateEmployeeGoal(this.employeeId, formData)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');  
          this.dialogRef.close(true);
        }
    );
  }

  close() {
    this.dialogRef.close();
  }

}
