<app-data-grid-comp
    [columns]="columns"
    [bindingType]="bindingType"
    [view]="view"
    [gridData]="gridData"
    [gridDataResult]="gridDataResult"
    [pageSize]="pageSize"
    [skip]="skip"
    category="UsersInRole"
    [rowSelectionSettings]="{
        canSelectRows: true,
        selectableMode: 'multiple',
        selectRowBy: selectEntireRow
    }"
    actionButtonText="Add"
    [isLoading]="isLoading"
    [clearSelectedItems]="clearSelectedItems"
    searchable="true"
    (emitSearchEvent)="search($event)"
    (emitPageChangeEvent)="pageChange($event)"
    (emitOpenDialogEvent)="showAddUserModal()"
    (emitDeleteEvent)="deleteAllSelected($event)"
></app-data-grid-comp>