import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Employee } from '@app/shared/models/employee.model';

@Component({
  selector: 'app-employment-records',
  templateUrl: './employment-records.component.html',
  styleUrls: ['./employment-records.component.scss']
})
export class EmploymentRecordsComponent implements OnInit {
  @Input() employee: Employee;
  employeeId: string;
  
  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.employeeId = this.getIdInURL();
  }

  getIdInURL(): string {
    let IdInURL: string;
    
    this.route.paramMap.subscribe(
      params => IdInURL = params.get("id")
    )
      
    return IdInURL;
  }

}
