import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingsService } from '@app/core/services/settings.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { Currency } from '@app/modules/lookup/models/currency.model';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BenefitsCoverage } from '../../../benefits-coverages/models/benefits-coverage.model';
import { BenefitsCoverageService } from '../../../benefits-coverages/services/benefits-coverage.service';
import { BenefitsGroupVerbose, DeductionFrequencies } from '../../../benefits-groups/models/benefits-group.model';
import { BenefitsGroupsService } from '../../../benefits-groups/services/benefits-groups.service';
import { BenefitsPlanCoverage } from '../../../benefits-plan-coverages/models/benefits-plan-coverage.model';
import { BenefitsPlanCoverageService } from '../../../benefits-plan-coverages/services/benefits-plan-coverage.service';
import { BenefitsPlan } from '../../../benefits-plans/models/benefits-plan.model';
import { BenefitsGroupPlanCoverage } from '../../models/benefits-group-plan-coverages.model';
import { BenefitsGroupPlanCoverageService } from '../../services/benefits-group-plan-coverage.service';

@Component({
  selector: 'app-benefit-group-plan-coverage-dialog',
  templateUrl: './benefit-group-plan-coverage-dialog.component.html',
  styleUrls: ['./benefit-group-plan-coverage-dialog.component.scss']
})
export class BenefitGroupPlanCoverageDialogComponent implements OnInit {
  form: UntypedFormGroup;
  planId: string;
  coverageId: string;
  groupId: string;
  groupPlanCoverage: BenefitsGroupPlanCoverage;
  currencyOptions: Observable<Currency[]>
  coverageOptions: BenefitsPlanCoverage[] = [];
  loadingCoverages: boolean;
  loading: boolean;

  planOptions: BenefitsPlan[] = [];
  deductionFrequencyOptions: Observable<DeductionFrequencies[]>;

  constructor(
    private fb: UntypedFormBuilder,
    private overlayService: OverlayService,
    private settingsService: SettingsService,
    private benefitsGroupsService: BenefitsGroupsService,
    private benefitsCoverageService: BenefitsCoverageService,
    private benefitsPlanCoverageService: BenefitsPlanCoverageService,
    private snackbarService: SnackbarService,
    private benefitsGroupPlanCoverageService: BenefitsGroupPlanCoverageService,
    private dialogRef: MatDialogRef<BenefitGroupPlanCoverageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data)
  {
    this.planId = data.planId;
    this.groupId = data.groupId;
    this.coverageId = data.coverageId;
  } 

  ngOnInit(): void {
    this.loading = true; 
    this.currencyOptions = this.settingsService.getCurrencies();
    this.getDropdownValues();

    if(this.planId !== null && this.groupId !== null && this.coverageId !== null){
      this.getGroupPlanCoverage();
    }
    else {
      this.loading = false; 
      this.createForm();
    }
  }

  getDropdownValues() {
    this.deductionFrequencyOptions = this.benefitsGroupsService.getDeductionFrequencies();
    this.benefitsPlanCoverageService.getPlanCoverages(this.planId).subscribe(
      res => {
        this.coverageOptions = res;
      }
    )
  }

  getGroupPlanCoverage() {
    this.loading = true;

    this.benefitsGroupPlanCoverageService.getBenefitsGroupPlanCoverage(this.groupId, this.planId, this.coverageId)
    .pipe(
      finalize( () => this.loading = false )
    )
    .subscribe(
      res => {
        this.groupPlanCoverage = res;
        this.createForm();
      }
    )
  }

  createForm() {
    this.form = this.fb.group({
      asOf: [moment().format()],
      coverage: [this.coverageId !== undefined ? this.coverageId : null, Validators.required],
      employerContributionAmount: [this.groupPlanCoverage ? this.groupPlanCoverage.employerContributionAmount : null, Validators.required],
      employeeContributionAmount: [this.groupPlanCoverage ? this.groupPlanCoverage.employeeContributionAmount : null, Validators.required],
      employerContributionPercentage: [this.groupPlanCoverage ? this.groupPlanCoverage.employerContributionPercentage : null, Validators.required],
      employeeContributionPercentage: [this.groupPlanCoverage ? this.groupPlanCoverage.employeeContributionPercentage : null, Validators.required],
      deductionFrequency: [this.groupPlanCoverage ? this.groupPlanCoverage.deductionFrequency?.id : null, Validators.required],
      changeReason: [''],
      changeReasonComments: [''],
    });
  }

  save() {
    this.overlayService.show();

    if(this.planId !== null && this.groupId !== null && this.coverageId !== null){
      this.benefitsGroupPlanCoverageService.updateBenefitsGroupPlanCoverages(this.groupId, this.planId, this.form.value.coverage, this.form.value)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.dialogRef.close(true);
          this.snackbarService.openSnackBar('Group Plan Coverage updated successfully', 'clear', 'success');
        }
      );
    }
    else {
      this.benefitsGroupPlanCoverageService.addBenefitsGroupPlanCoverages(this.groupId, this.planId, this.form.value.coverage, this.form.value)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.dialogRef.close(true);
          this.snackbarService.openSnackBar('Group Plan Coverage created successfully', 'clear', 'success');
        }
      );
    }
  }

  close() {
    this.dialogRef.close();
  }
}

