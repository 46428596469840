<h5 mat-dialog-title>Select a list to copy to</h5>

<mat-dialog-content *ngIf="!loading">
    <mat-form-field appearance="fill">
        <mat-label>
            List
        </mat-label>

        <mat-select disableOptionCentering [(ngModel)]="selectedLookupGroup">
            <mat-optgroup *ngFor="let table of tableList" [label]="table.name">
                <mat-option *ngFor="let item of table.fields" [value]="item">
                    {{item.name}}
                </mat-option>
            </mat-optgroup>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!loading">
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="selectedLookupGroup == ''">Save</button>
</mat-dialog-actions>