import { returnedLookup } from "@app/modules/lookup/models/lookup.model";
import { Version } from "@app/shared/models/version.model";

export interface EmploymentRecordSubmit {
    effectiveDate: string,
    changeReason: string,
    changeReasonComments: string,
    customProperties: {
        id: string,
        value: string
    }[],
    id: string,
    hireDate: string,
    budgetAuthority: string,
    inductionCompletedOn: string,
    codeOfConductSigned: string,
    medicalCompletedDate: string,
    codeOfConductSignedOn: string,
    medicalCompleted: string,
    securityClearance: string,
    probationPeriodLength: number,
    probationPeriodUnit: string,
    probationPeriodEnd: string,
    noticePeriodLength: number,
    noticePeriodUnits: string,
    reHireEligibility: string,
    hireCategory: string,
    replacementFor: string,
    recruitmentAgency: string,
    searchFee: number,
    rehireEligibilityComments: string,
    hireDetailComments: string,
    terminationReason: string,
    terminationVoluntary: boolean,
    terminationRecordOfEmploymentReason: string,
    terminationRecordOfEmploymentComment: string,
    terminationSeveranceAmount: number,
    terminationPayInLieu: number,
    terminationVacationPayout: number,
    terminationOtherPayment: number,
    terminationSpecialCircumstances: string,
    terminationLastDateOnSite: string,
    terminationDateNotified: string,
    terminationDateProjected: string,
    terminationDateActual: string,
    terminationBenefitsEndOfNotice: string,
    terminationBenefitsEnd: string,
    terminationItConfirmation: boolean,
    terminationAcceptedByDate: string,
    terminationAcceptedByName: string,
    terminationRehireRecommend: string,
    terminationRehireReason: string,
    terminationComments: string,
    payrollCode: string,
    payrollNumber: string,
    apprenticeshipProgramEndDate: string, 
    apprenticeshipProgramStartDate: string, 
    graduateProgramEndDate: string, 
    graduateProgramStartDate: string, 
    graduateProgramMember: string
    supervisorLegacyId: string,
    email: string, 
    phoneNumber: string, 
    cellNumber: string, 
    office: string, 
    subStatus: string
}

export interface EmploymentRecord {
    id: string,
    employeeId: string,
    hireDate: string,
    budgetAuthority: string,
    inductionCompletedOn: string,
    medicalCompletedDate: string,
    codeOfConductSignedOn: string,
    probationPeriodLength: number,
    probationPeriodUnit: ProbationPeriodUnit,
    probationPeriodEnd: string,
    noticePeriodLength: number,
    replacementFor: string,
    searchFee: string,
    rehireEligibilityComments: string,
    hireDetailComments: string,
    terminationVoluntary: boolean,
    terminationRecordOfEmploymentComment: string,
    terminationSeveranceAmount: string,
    terminationPayInLieu: string,
    terminationVacationPayout: string,
    terminationOtherPayment: string,
    terminationLastDateOnSite: string,
    terminationDateNotified: string,
    terminationDateProjected: string,
    terminationDateActual: string,
    terminationBenefitsEndOfNotice: string,
    terminationBenefitsEnd: string,
    terminationItConfirmation: boolean,
    terminationAcceptedByDate: string,
    terminationAcceptedByName: string,
    terminationComments: string,
    payrollNumber: string,
    codeOfConductSigned: returnedLookup,
    medicalCompleted: returnedLookup,
    securityClearance: returnedLookup,
    noticePeriodUnits: returnedLookup,
    reHireEligibility: returnedLookup,
    hireCategory: returnedLookup,
    recruitmentAgency: returnedLookup,
    terminationReason: returnedLookup,
    terminationRecordOfEmploymentReason: returnedLookup,
    terminationSpecialCircumstances: returnedLookup,
    terminationRehireRecommend: returnedLookup,
    terminationRehireReason: returnedLookup,
    payrollCode: returnedLookup,
    apprenticeshipProgramEndDate: string, 
    apprenticeshipProgramStartDate: string, 
    graduateProgramEndDate: string, 
    graduateProgramStartDate: string, 
    graduateProgramMember: returnedLookup
    email: string, 
    phoneNumber: string, 
    cellNumber: string, 
    office: string, 
    subStatus: returnedLookup,
    version: Version,
    supervisorLegacyId: string, 
    hireDetailsUdd1: string,
    hireDetailsUdd2: string,
    office2: string,
    hireDetailsUdf1: returnedLookup,
    reHireReason: returnedLookup,
    startReason: returnedLookup,
    hireDetailsUdf2: returnedLookup,
    hireDetailsUdf3: returnedLookup
}

export interface ProbationPeriodUnit { 
    id: string
    name: string
}