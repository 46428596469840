import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormElement } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { ReviewPlansService } from '@app/modules/performance/components/review-plans/services/review-plans.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-feedback-settings-dropdown',
  templateUrl: './feedback-settings-dropdown.component.html',
  styleUrls: ['./feedback-settings-dropdown.component.scss']
})
export class FeedbackSettingsDropdownComponent implements OnInit {
  @Input() formElement: FormElement;
  @Input() parentGroup: UntypedFormGroup;
  @Input() readOnly: boolean;
  
  $feedbackSettingsOptions: Observable<PagedData<{id: string, name: string}>>;
  isLoading: boolean;

  constructor(
    private reviewPlansService: ReviewPlansService  
  ) { 
    this.$feedbackSettingsOptions = this.reviewPlansService.getFeedbackSettings();
  }

  ngOnInit(): void {
  }

}
