<h5 mat-dialog-title>{{ 'UploadAFile' | translate: {Default: "Upload a File"} }}</h5>

<mat-dialog-content>
    <div>
        <div>
            <button mat-raised-button class="primary-button" (click)="fileInput.click()">
                {{ 'ChooseAFile' | translate: {Default: "Choose a file"} }}
            </button>
            <input
                #fileInput
                type="file"
                id="fileInput"
                (change)="selectFile($event)"
                name="fileInput"
            />
        </div>
        <div *ngIf="currentFile">
            <div class="current-file">
                <mat-toolbar class="progress-bar">
                    <mat-progress-bar color="accent" [value]="progress"></mat-progress-bar>
                    <span class="progress">{{ progress }}%</span>
                </mat-toolbar>
                <div class="current-file-details">
                    <div class="current-file-name">{{currentFile.name}}</div>
                    <div class="current-file-size">{{ currentFile.size | filesize }}</div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="message" class="message">
        {{ message }}
    </div>
 
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
    <button 
        mat-raised-button 
        class="primary-button submit-button"
        (click)="upload()" 
        [disabled]="!currentFile">
        {{ 'Upload' | translate: {Default: "Upload"} }}
    </button>
</mat-dialog-actions>