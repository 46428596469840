import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sent-history',
  templateUrl: './sent-history.component.html',
  styleUrls: ['./sent-history.component.scss']
})
export class SentHistoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
