import { Component, OnInit } from '@angular/core';
import { BusService } from '@app/core/services/bus/bus.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-jobs-queue',
  templateUrl: './jobs-queue.component.html',
  styleUrls: ['./jobs-queue.component.scss']
})
export class JobsQueueComponent implements OnInit {
  // public columns: any[] = [
  //   { field: "sentOn", title: "sentOn" },
  //   { field: "startedOn", title: "startedOn" },
  //   { field: "completedOn", title: "completedOn" },
  // ];
  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public mySelection: string[] = [];
  public sort: SortDescriptor[] = [];
  pageSize: number = 20;
  skip: number = 0;
  isLoading: boolean;
  searchFilterString: any;
  filterString: any;
  getRequest: any;
  sortString: string;

  constructor(
    public translate: TranslateService,
    private busService: BusService
  ) { }

  ngOnInit(): void {
    this.getJobs();
  }

  getJobs(): void {
    this.isLoading = true;

    let filter;
    if(this.searchFilterString) {
      filter = this.searchFilterString;
    }
    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }

    this.getRequest = this.busService.getBusMessages(this.skip, String(this.pageSize), filter, this.sortString)
    .pipe(
        finalize(()=>{ this.isLoading = false; })
    )
    .subscribe(
      res => {
        this.gridDataResult = {
          data: res.data,
          total: res.total,
        }
        this.gridData = res.data;
      }
    )
  }

  search() {

  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.getJobs();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;

    if (sort[0].dir === undefined) {
      this.sortString = null
    }
    else {
      //use regex to get column field to sort with
      let field: any;

      //if it is an object category like (department.text) trim .text from the end, else it doesnt need to be trimmed
      (sort[0].field).match(/.+(?=\.)/) === null
      ? field = sort[0].field
      : field = (sort[0].field).match(/.+(?=\.)/)
      this.sortString = `${field}-${sort[0].dir}`;
    }

    this.getJobs();
  }

  filterCallback(filterString: string) {
    this.searchFilterString = filterString;
    this.skip = 0;;
    this.getJobs();
  }

}
