import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { Position } from '@app/modules/positions/models/positions.model';
import { PositionsService } from '@app/modules/positions/services/positions.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import * as moment from 'moment';
import { defer, forkJoin, from } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EmploymentRecordPositionsService } from '../../services/employment-record-positions.service';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import { TableListFieldOption } from '@app/modules/lookup/models/lookup.model';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-add-positions-dialog',
    templateUrl: './add-positions-dialog.component.html',
    styleUrls: ['./add-positions-dialog.component.scss']
})
export class AddPositionsDialogComponent implements OnInit {
    employeeId: string;
    employeePositionId: string = null;
    positionId: string = null;
    employmentRecordId: string;
    positions: Position[];
    selectedPositions: Position[] = [];
    positionsSubmitted: boolean = false;
    startDate: string = null;
    endDate: string;
    startReasons: TableListFieldOption[];
    startReason: string;
    endReasons: TableListFieldOption[];
    endReason: string;
    searchFilterString: string;
    searchValue: string;
    positionsRequest: any;
    loadingPositions: boolean;

    constructor(
        private overlayService: OverlayService,
        private positionsService: PositionsService,
        private employmentRecordPositionsService: EmploymentRecordPositionsService,
        private snackbarService: SnackbarService,
        private lookupService: LookupService,
        private dialog: MatDialog,
        private translate: TranslateService,
        private dialogRef: MatDialogRef<AddPositionsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data) {
        this.employeeId = data.employeeId;
        this.employeePositionId = data.positionId;
    }

    ngOnInit(): void {
        this.getEndReasons();
        this.getStartReasons();
        this.getPositions();
        if (this.employeePositionId) {
            this.getPosition();
        }
    }

    getPositions() {
        this.loadingPositions = true;

        this.positionsRequest = this.positionsService.getPositions(0, '1000', this.searchFilterString)
            .pipe(
                finalize(() => this.loadingPositions = false)
            )
            .subscribe(
                res => {
                    this.positions = res.data;
                }
            );
    }

    getPosition() {
        this.positionsRequest = this.employmentRecordPositionsService.getEmployeePosition(this.employeePositionId)
            .subscribe(
                employeePositions => {
                    console.log(employeePositions);
                    this.positionId = employeePositions.position.id;
                    this.startDate = employeePositions.startDate;
                    this.startReason = employeePositions.startReason?.id;
                    this.endDate = employeePositions.endDate;
                    this.endReason = employeePositions.endReason?.id;
                }
            );
    }

    addPosition(position: Position) {
        this.selectedPositions.push(position);
    }

    removePosition(position: Position) {
        const index = this.selectedPositions.indexOf(position);
        if (index > -1) {
            this.selectedPositions.splice(index, 1);
        }
    }

    search() {
        this.searchFilterString = `(name like "${this.searchValue}")`;
        this.positionsRequest.unsubscribe();
        this.getPositions();
    }

    save() {
        if (this.employeePositionId == null) {
            this.assignPositionsToEmployee();
        } else {
            this.updateEmployeePosition();
        }
    }


    private updateEmployeePosition() {
        let submit = {
            asOf: moment().format(),
            changeReason: '',
            changeReasonComments: '',
            id: '',
            startDate: this.startDate,
            endDate: this.endDate,
            startReason: this.startReason,
            endReason: this.endReason,
            employeeId: this.employeeId,
            positionId: this.positionId,
        };
        this.employmentRecordPositionsService.updateEmployeeAssignedPosition(this.employeePositionId, submit)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe(
                (res) => {
                    this.snackbarService.openSnackBar('Updated successfully', 'clear', 'success');
                    this.positionsSubmitted = true;
                    this.dialogRef.close(this.positionsSubmitted);
                },
                err => {
                    this.snackbarService.openSnackBar(err, 'clear', 'warn');
                }
            );
    }

    assignPositionsToEmployee() {
        const observables = this.selectedPositions.map(
            selectedPosition => defer(
                () =>
                    this.employmentRecordPositionsService.assignEmployeeToPosition(
                        {
                            asOf: moment().format(),
                            changeReason: '',
                            changeReasonComments: '',
                            id: '',
                            startDate: this.startDate,
                            endDate: this.endDate,
                            startReason: this.startReason,
                            endReason: this.endReason,
                            employeeId: this.employeeId,
                            positionId: selectedPosition.id,
                        }
                    )
            )
        );

        this.overlayService.show();

        forkJoin(observables)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe(
                (res) => {
                    this.snackbarService.openSnackBar('Added successfully', 'clear', 'success');
                    this.positionsSubmitted = true;
                    this.dialogRef.close(this.positionsSubmitted);
                },
                err => {
                    this.snackbarService.openSnackBar(err, 'clear', 'warn');
                }
            );
    }

    openConfirmCloseDialog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
          text: this.translate.instant('UnsavedChangesMessage')
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
          data => {
            if (data === true) {
              this.dialogRef.close();
            }
          }
        );
      }

    close() {
        (this.selectedPositions.length === 0 && this.employeePositionId == null) || this.startDate === null
        ?   this.openConfirmCloseDialog()
        :   this.dialogRef.close(this.positionsSubmitted)
    }

    private getEndReasons() {
        from(this.lookupService.getListOptions('POSITION_EMPLOYEE_END_REASON')).subscribe(pagedData => {
            this.endReasons = pagedData.data;
        });
    }

    private getStartReasons() {
        from(this.lookupService.getListOptions('POSITION_EMPLOYEE_START_REASON')).subscribe(pagedData => {
            this.startReasons = pagedData.data;
        });
    }

}
