import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { DashboardService } from './services';
import {
  DailyLineChartData,
  PerformanceChartData,
  ProjectStatData,
  RevenueChartData,
  ServerChartData,
  SupportRequestData,
  VisitsChartData
} from './models';
import {SecurityProtectedBase} from '@app/shared/components/security-protected/security-protected';
import {FeatureService} from '@app/core/services/feature.service';
import {features} from '@app/consts';
import { EmployeeService } from '@app/core/services/employee.service';
import { CurrentUserDetails } from '@app/shared/models/employee.model';
import { modules } from '@app/consts/modules';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends SecurityProtectedBase implements OnInit {
  public dailyLineChartData$: Observable<DailyLineChartData>;
  public performanceChartData$: Observable<PerformanceChartData>;
  public revenueChartData$: Observable<RevenueChartData>;
  public serverChartData$: Observable<ServerChartData>;
  public supportRequestData$: Observable<SupportRequestData[]>;
  public visitsChartData$: Observable<VisitsChartData>;
  public projectsStatsData$: Observable<ProjectStatData>;
  currentEmployeeDetails: CurrentUserDetails;
  public modules: typeof modules = modules;
  public features: typeof features = features;

  constructor(
    private service: DashboardService, 
    featureService: FeatureService,
    private employeeService: EmployeeService,
  ) {
    super(featureService, features.DASHBOARDS);
    this.dailyLineChartData$ = this.service.loadDailyLineChartData();
    this.performanceChartData$ = this.service.loadPerformanceChartData();
    this.revenueChartData$ = this.service.loadRevenueChartData();
    this.serverChartData$ = this.service.loadServerChartData();
    this.supportRequestData$ = this.service.loadSupportRequestData();
    this.visitsChartData$ = this.service.loadVisitsChartData();
    this.projectsStatsData$ = this.service.loadProjectsStatsData();
    this.currentEmployeeDetails = this.employeeService.getFetchedCurrentUserDetails()
  }

  ngOnInit(): void {
  }

}
