<h5 mat-dialog-title>{{ 'CalculationLog' | translate: {Default: "Calculation Log"} }}</h5>

<mat-dialog-content>
    <app-local-data-grid-comp
        class="calculation-log-data-grid"
        [columns]="columns"
        [data]="timeOffCalculationLog"
        [isLoading]="isLoading"
        [pageSize]="pageSize"
        [pageable]="false"
        category=""
        export="excel"
    ></app-local-data-grid-comp>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
</mat-dialog-actions>