<app-employee-layout selectedComponent="Positions">
    <ng-container *ngIf="isLoadingPermissions; else loaded">
        <ngx-skeleton-loader
          count="5"
          [theme]="{ 
              'height.px': 50
          }"
        ></ngx-skeleton-loader>
    </ng-container>
    <ng-template #loaded>
        <mat-card>
            <mat-card-title class="header">
                <h5 appLocalizationPopupDirective localizationCode="EmploymentRecord-Positions">{{ 'EmploymentRecord-Positions' | translate: {Default: "Positions"} }}</h5>
                <div>
                    <button appLocalizationPopupDirective localizationCode="EmploymentRecord-Positions-AddPosition" mat-raised-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openAddPositionDialog()" *appTableAccess="[tableCreateAccessObj]">
                        <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> {{ 'EmploymentRecord-Positions-AddPosition' | translate: {Default: "Add Position"} }}
                    </button>
                </div>
            </mat-card-title>
            <mat-card-content>
                <ng-container *ngIf="loading === false; else showLoading">
                    <ng-container *ngIf="positions.length > 0; else noPositions">
                        <mat-accordion class="example-headers-align" multi>
        
                            <ng-container *ngFor="let position of positions">
                                <div *ngIf="position.positionDetails | withLoading | async as positionDetails">
                                    <ng-template [ngIf]="positionDetails.value">
        
                                        <mat-expansion-panel *appTableAccess="[tableReadAccessObj]" [expanded]="isCurrentPosition(position.endDate)">
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    <div class="k-d-flex k-d-flex-col">

                                                        <div class="k-d-flex k-d-flex-row k-mt-3">
                                                            <div [matTooltip]="position?.position?.name" class="label">{{position.position.name}} </div>
                                                            <mat-icon class="material-icons-outlined k-mr-5" *ngIf="hasFeatureAccess" matTooltip="Edit Position" appPreventDoubleClick (throttledClick)="openPositionFormDialog(position); $event.stopPropagation();">open_in_new</mat-icon>
                                                        </div>

                                                        <div class="k-d-flex k-d-flex-row k-mt-3 k-mb-3">
                                                            <div class="k-d-flex k-d-flex-row">
                                                                <div class="date k-d-flex k-d-flex-col">
                                                                    <span class="label" appLocalizationPopupDirective localizationCode="EmploymentRecord-Positions-StartDate">{{ 'EmploymentRecord-Positions-StartDate' | translate: {Default: "Start Date"} }}: </span>
                                                                    {{position.startDate | date}}
                                                                </div>
                                                                <div class="date k-d-flex k-d-flex-col">
                                                                    <span class="label" appLocalizationPopupDirective localizationCode="EmploymentRecord-Positions-StartReason">{{ 'EmploymentRecord-Positions-StartReason' | translate: {Default: "Start Reason"} }}:</span>
                                                                    {{position.startReason?.text}}
                                                                </div>
                                                            </div>

                                                            <div class="k-d-flex k-d-flex-row">
                                                                <div class="date k-d-flex k-d-flex-col">
                                                                    <span class="label" appLocalizationPopupDirective localizationCode="EmploymentRecord-Positions-EndDate">{{ 'EmploymentRecord-Positions-EndDate' | translate: {Default: "End Date"} }}: </span>
                                                                    {{position.endDate | date}}
                                                                </div>
                                                                <div class="date k-d-flex k-d-flex-col">
                                                                    <span class="label" appLocalizationPopupDirective localizationCode="EmploymentRecord-Positions-EndReason">{{ 'EmploymentRecord-Positions-EndReason' | translate: {Default: "End Reason"} }}:</span>
                                                                    {{position.endReason?.text}}
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
        
                                                </mat-panel-title>
                                                <mat-panel-description>
                                                    
                                                    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation();">
                                                        <mat-icon class="material-icons-outlined">more_vert</mat-icon>
                                                    </button>
                                                    <mat-menu #menu="matMenu">
                                                        <button mat-menu-item *appTableAccess="[tableUpdateAccessObj]" appPreventDoubleClick (throttledClick)="openAddPositionDialog(position.id); $event.stopPropagation();">
                                                            <mat-icon class="material-icons-outlined">edit</mat-icon>
                                                            <span appLocalizationPopupDirective localizationCode="EmploymentRecord-Positions-Edit">{{ 'Edit' | translate: {Default: "Edit"} }}</span>
                                                        </button>
                                                        <button mat-menu-item *appTableAccess="[tableDeleteAccessObj]" appPreventDoubleClick (throttledClick)="openConfirmDeleteDialog(position.id); $event.stopPropagation();">
                                                            <mat-icon class="material-icons-outlined" >delete
                                                            </mat-icon>
                                                            <span appLocalizationPopupDirective localizationCode="EmploymentRecord-Positions-RemovePosition">{{ 'RemovePosition' | translate: {Default: "Remove Position"} }}</span>
                                                        </button>
                                                        <button mat-menu-item *appTableAccess="[tableReadAccessObj]" appPreventDoubleClick (throttledClick)="openHistoryDialog(position.position.id) ; $event.stopPropagation();">
                                                            <mat-icon 
                                                                setColor 
                                                                [setColorRules]="[
                                                                    {
                                                                        color: 'primaryColour',
                                                                        events: ['hover']
                                                                    }
                                                                ]"
                                                                class="material-icons-outlined k-mr-3 clickable-icon"
                                                            >history</mat-icon>
                                                            <span appLocalizationPopupDirective localizationCode="EmploymentRecord-Positions-PositionHistory">{{ 'PositionHistory' | translate: {Default: "Position History"} }}</span>
                                                        </button>
                                                    </mat-menu>
                                                
                                                <span></span>
                                                </mat-panel-description>
                                            </mat-expansion-panel-header>
                                            
                                            <div class="managers-list">
                                                <h6 appLocalizationPopupDirective localizationCode="EmploymentRecord-Positions-ManagedBy">
                                                    {{ 'ManagedBy' | translate: {Default: "Managed By"} }}
                                                </h6>
                                                
                                                <kendo-grid
                                                    *ngIf="!isLoading;"
                                                    [data]="position.positionManagers"
                                                    [resizable]="true"
                                                    [style.maxHeight.vh]="85"
                                                    [pageable]="false">
                                                    
                                                    <kendo-grid-column field="profilePictureThumbnailUri" title="" [width]="80">
                                                        <ng-template kendoGridCellTemplate let-dataItem>
                                                            <div class="employee-name navigation-link" routerLink="{{routes.TALENT_TRACK}}{{routes.EDIT}}/{{dataItem.id}}">
                                                                <app-employee-image round="true" width="50" height="50" class="profile-pic" [employeeId]="dataItem.id"></app-employee-image>
                                                                {{dataItem.firstName}} {{dataItem.lastName}}
                                                            </div>
                                                        </ng-template>
                                                    </kendo-grid-column>
        
                                                    <kendo-grid-column field="status" title="" [width]="80">
                                                        <ng-template kendoGridCellTemplate let-dataItem>
                                                            <mat-chip-list>
                                                                <mat-chip class="green-chip">Active</mat-chip>
                                                            </mat-chip-list>
                                                        </ng-template>
                                                    </kendo-grid-column>
        
                                                    <ng-template kendoGridNoRecordsTemplate>
                                                        <div appLocalizationPopupDirective localizationCode="EmploymentRecord-Positions-ThereAreNoManagers">
                                                            {{ 'EmploymentRecord-Positions-ThereAreNoManagers' | translate: {Default: "There are no managers"} }}
                                                        </div>
                                                    </ng-template>
                                                </kendo-grid>
                                            </div>
        
                                            <app-form-generator
                                                readOnly="true"
                                                [formId]="formId"
                                                [targetEmployeeTablePermissions]="targetEmployeeTablePermissions"
                                                [formData]="createFormData(positionDetails.value)"
                                            ></app-form-generator>
        
                                        </mat-expansion-panel>
                                    </ng-template>
                                    <ng-template [ngIf]="positionDetails.error">Error {{ obs.error }}</ng-template>
                                    <ng-template [ngIf]="positionDetails.loading">
                                        <ngx-skeleton-loader
                                                count="5"
                                                [theme]="{
                                            'height.px': 50
                                        }"
                                        ></ngx-skeleton-loader>
                                    </ng-template>
                                </div>
                            </ng-container>
                        </mat-accordion>
                    </ng-container>
        
                    <ng-template #noPositions>
                        <h6 appLocalizationPopupDirective localizationCode="NoPositionsAssignedToEmployee">{{ 'NoPositionsAssignedToEmployee' | translate: {Default: "No positions assigned to employee"} }}</h6>
                    </ng-template>
                </ng-container>
        
                <ng-template #showLoading>
                    <ngx-skeleton-loader
                            count="5"
                            [theme]="{
                        'height.px': 50
                    }"
                    ></ngx-skeleton-loader>
                </ng-template>
            </mat-card-content>
        </mat-card>
    </ng-template>
</app-employee-layout>
