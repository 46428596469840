import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { FileViewerDialogComponent } from '@app/shared/components/file-viewer-dialog/file-viewer-dialog.component';
import { PdfViewerDialogComponent } from '@app/shared/components/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { Directory } from '@app/shared/models/employee/documents/directory.model';
import { Document, DocumentUpdate } from './models/document.model';
import { DocumentUploadDialogComponent } from './components/document-upload-dialog/document-upload-dialog.component';
import { DirectoryFormDialogComponent } from './components/directory-form-dialog/directory-form-dialog.component';
import { EditDocumentDialogComponent } from './components/edit-document-dialog/edit-document-dialog.component';
import { EmployeeDocumentsService } from './services/employee-documents.service';
import * as fileSaver from 'file-saver';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-employee-documents',
  templateUrl: './employee-documents.component.html',
  styleUrls: ['./employee-documents.component.scss']
})
export class EmployeeDocumentsComponent implements OnInit {
  employeeID: string;
  refreshView: boolean;
  selectedView: string = 'treeView';
  
  constructor(
    private employeeDocumentsService: EmployeeDocumentsService,
    private translate: TranslateService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) { 
    this.route.paramMap.subscribe(
        params => this.employeeID = params.get("id")
    ) 
  }
  
  ngOnInit(): void {
  }

  createDirectory(name: string, parentDirectoryID: string) {
    this.employeeDocumentsService.uploadDocument(this.employeeID, null, name, parentDirectoryID)
    .subscribe(
      (res) => {
        this.refreshView = !this.refreshView;
        this.snackbarService.openSnackBar(this.translate.instant('SavedSuccessfully'), 'clear', 'success');
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  download(document: Document) {
    this.overlayService.show(this.translate.instant('Downloading'));
    this.employeeDocumentsService.downloadDocument(this.employeeID, document.id)
    .subscribe(
      response => {
        console.log(response.headers);

        let blob:any = new Blob([response.body], { type: document.contentType });
        fileSaver.saveAs(blob, document.name);
        this.overlayService.hide();

		  }
    ), 
    err => this.snackbarService.openSnackBar(err, 'clear', 'warn');
  }

  delete(id: string) {
    this.employeeDocumentsService.delete(this.employeeID, id)
    .subscribe(
      (res) => {
        this.refreshView = !this.refreshView;
        this.snackbarService.openSnackBar(this.translate.instant('DeletedSuccessfully'), 'clear', 'success');
      }
    );
  }

  moveItem(details: {'destination': Document, 'itemToMove': Document}) {
    const updateDetails: DocumentUpdate = {
      parent: details.destination ? details.destination.id : null,
      comments: details.itemToMove.comments,
      name: details.itemToMove.name
    }

    this.employeeDocumentsService.updateDocument(this.employeeID, details.itemToMove.id, updateDetails)
    .subscribe(
      (res) => {
        this.refreshView = !this.refreshView;
        this.snackbarService.openSnackBar(this.translate.instant('MovedSuccessfully'), 'clear', 'success');
      }
    );
  }

  openConfirmDeleteDialog(itemToDelete: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
        text: `${this.translate.instant('AreYouSureYouWantToDelete')} ${itemToDelete.name}?`
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.delete(itemToDelete.id);
        }
      }
    ); 
  }

  viewFile(file) {
    if(file.contentType === 'application/pdf') {
      this.openPdfViewerDialog(file, true);
    }
    else {
      this.openFileViewerDialog(file);
    }
  }

  openPdfViewerDialog(document: any, signatureRequired: boolean) {
    const dialogConfig = new MatDialogConfig();

    this.employeeDocumentsService.getDocumentUrl(this.employeeID, document.id)
    .subscribe(
      (res) => {
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.minWidth = '80%';

        dialogConfig.data = {
            pdfUrl: res,
            signatureRequired: signatureRequired
        };

        const dialogRef = this.dialog.open(PdfViewerDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(
          data => {
            if (data === true) {
              this.snackbarService.openSnackBar(this.translate.instant('SavedSuccessfully'), 'clear', 'success');
            }
          }
        ); 
      }
    );
  }

  openFileViewerDialog(document: any) {
    const dialogConfig = new MatDialogConfig();

    const viewer = {
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'office',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'office',
      'application/vnd.oasis.opendocument.spreadsheet': 'office',
      'application/msword': 'office',
      'text/csv': 'google',
      'application/zip': 'google',
      'image/jpeg': 'google'
    }

    this.employeeDocumentsService.getDocumentUrl(this.employeeID, document.id)
    .subscribe(
      (res) => {
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.minWidth = '80%';

        dialogConfig.data = {
            fileUrl: res,
            viewer: viewer[document.contentType]
        };

        const dialogRef = this.dialog.open(FileViewerDialogComponent, dialogConfig);

      }
    );
  }

  openDirectoryFormDialog(parentDirectory?: Directory) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 500;

    const dialogRef = this.dialog.open(DirectoryFormDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data && data.directoryName){
          this.createDirectory(data.directoryName, parentDirectory ? parentDirectory.id : null);
        }
      }
    ); 
  }

  openDocumentUploadDialog(parentDirectory?: Directory) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 500;

    dialogConfig.data = {
      employeeId: this.employeeID,
      parentDirectory: parentDirectory ? parentDirectory.id : null,
    };

    const dialogRef = this.dialog.open(DocumentUploadDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data) {
          this.refreshView = !this.refreshView;
          this.snackbarService.openSnackBar(this.translate.instant('UploadedSuccessfully'), 'clear', 'success');
        }
      }
    ); 
  }

  openDocumentEditDialog(document: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 500;

    dialogConfig.data = {
      employeeId: this.employeeID,
      document: document
    };

    const dialogRef = this.dialog.open(EditDocumentDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data === true) {
          this.refreshView = !this.refreshView;
          this.snackbarService.openSnackBar(this.translate.instant('UpdatedSuccessfully'), 'clear', 'success');
        }
      }
    ); 
  }
}
