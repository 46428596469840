import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { routes } from '@app/consts';
import { SanitiseGridDataService } from '@app/core/services/sanitise-grid-data/sanitise-grid-data.service';
import { Position } from '@app/modules/positions/models/positions.model';
import { ExcelExportEvent, GridComponent, GridDataResult, PageChangeEvent, PageSizeItem, RowArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor, State } from '@progress/kendo-data-query';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { BasicHeadcountReportService } from '../../services/basic-headcount-report.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-headcount-report-data-grid',
  templateUrl: './headcount-report-data-grid.component.html',
  styleUrls: ['./headcount-report-data-grid.component.scss']
})
export class HeadcountReportDataGridComponent implements OnInit {
  @ViewChild(GridComponent)
  public grid: GridComponent;

  public routes: typeof routes = routes;
  public sort: SortDescriptor[] = [];
  public bindingType: String = "array";
  public view: Observable<GridDataResult>;
  public gridDataResult: GridDataResult;
  public selectedPositions: any[] = [];
  public pageSizes: (PageSizeItem | number)[] = [
    5,
    10,
    20,
    {
      text: "All",
      value: 1000,
    },
  ];
  isLoading: boolean;
  pageSize: number = 20;
  skip: number = 0;
  sortString: string;
  getBankDetailsRequest: any;
  positions: Position[];
  searchFilterString: any;
  getDetailsRequest: any;
  private filterInactive: boolean = true;

  // Compensation.Organization, Compensation.PayRateCurrency, Employee.CustomClientId, Employee.FirstName, Employee.Gender, Employee.LastName, Employee.Organization, Employee.PreferredName, EmploymentRecord.HireDate, EmploymentRecord.TerminationDateActual, PayrollDetails.PayrollNumber

  public filterCategories: any[] = [
    {field: 'Employee.FirstName', title: 'First Name', dataType: "String"},
    {field: 'Employee.LastName', title: 'Last Name', dataType: "String"},
    {field: 'Employee.PreferredName', title: "Preferred Name", dataType: "String"},
  ]
  filterString: string;

  public employeeColumns: any[] = [
    { field: "employee['firstname']", subField:"Employee.FirstName", title: "First Name" },
    { field: "employee['lastname']", subField:"Employee.LastName", title: "Last Name" },
    { field: "employee['preferredName']", subField:"Employee.PreferredName", title: "Preferred Name" },
    { field: "employee['gender']['text']", subField:"Employee.Gender", subSubField:"text", title: "Gender" },
    { field: "employee['organization']['name']", subField:"Employee.Organization", subSubField:"name", title: "Organisation" },

    { field: "compensation['payRateCurrency']['id']", subField:"Compensation.PayRateCurrency", title: "Pay Rate Currency" },

    { field: "employmentRecord['hireDate']", subField:"EmploymentRecord.HireDate", type: "date", title: "Hire Date" },
    { field: "employmentRecord['terminationDateActual']", subField:"EmploymentRecord.TerminationDateActual", type: "date", title: "Termination Date Actual" },

    { field: "payrollDetails['payrollNumber']", subField:"PayrollDetails.PayrollNumber", title: "Payroll Number" },
  ]

  public columns: any[] = [
    { field: "employee['customClientId']", subField:"customClientId", title: "Custom Client Id" },
    { field: "employee['formalName']", subField:"formalName", title: "Formal Name" },
    { field: "payrollDetails['payrollNumber']", subField:"payrollNumber", title: "Payroll Number" },
    { field: "employmentRecord['status']", subField:"status", title: "Status" },
    { field: "managers", title: "Managers" },
    { field: "regions", title: "Regions" },
    { field: "employee['firstname']", subField:"firstname", title: "First Name" },
    { field: "employee['lastname']", subField:"lastname", title: "Last Name" },
    { field: "employee['directorate']['name']", title: "Directorate" },
    { field: "employee['organization']['name']", subField:"organization", subSubField:"name", title: "Organisation" },
    { field: "workLocations", title: "Work Locations" },
    { field: "position['name']", title: "Position" },
    { field: "position['clientPositionId']", title: "Client Position Id" },
    { field: "position['startDate']", title: "Position Start Date" },
    { field: "employmentRecord['hireDate']", subField:"hireDate", type: "date", title: "Hire Date" },
    { field: "employmentRecord['terminationDateActual']", subField:"terminationDateActual", type: "date", title: "Termination Date Actual" },
    { field: "employee['gender']['text']", subField:"gender", subSubField:"text", title: "Gender" },
    { field: "position['employmentType']['text']", title: "Position Employment Type" },
    { field: "compensation['hoursPerWeek']", subField:"hoursPerWeek", title: "Hours Per Week" },
    { field: "compensation['payRateCurrency']['id']", subField:"payRateCurrency", title: "Pay Rate Currency" },
    { field: "compensation['baseSalary']", subField:"baseSalary", title: "Base Salary" },
    { field: "compensation['baseSalaryFteAdjusted']", subField:"baseSalaryFteAdjusted", title: "Base Salary Fte Adjusted" },
    { field: "position['fixedTermEndDate']", title: "Position Fixed Term End Date" },
    { field: "employmentRecord['noticePeriod']", subField:"noticePeriod", title: "Notice Period" },

    // { field: "position['udd1']", title: "Position Probation End Date" },
    // { field: "position['workRotation']['name']", title: "Position Work Rotation" },
    // { field: "employee['id']", subField:"id", title: "ID" },
    // { field: "positions", title: "Positions" },
  ];

  public state: State = {
    skip: 0,
    take: 50,
  };

  public query: Observable<GridDataResult>;
  private stateChange = new BehaviorSubject<State>(this.state);
  public allData = (): Observable<GridDataResult> => {
    return from(this.basicHeadcountReportService.queryAll(this.state, null, null, this.filterInactive));
  };

  constructor(
    private basicHeadcountReportService: BasicHeadcountReportService,
    private datePipe: DatePipe,
    private sanitiseGridDataService: SanitiseGridDataService
  ) {
    this.query = this.stateChange.pipe(
      tap((state) => {
        this.state = state;
        this.isLoading = true;
      }),
      switchMap((state) => basicHeadcountReportService.fetch(state, this.filterString, this.sortString, false, true, this.filterInactive)),
      tap(() => {
        this.isLoading = false;
      })
    );

    // Bind 'this' explicitly to capture the execution context of the component.
    this.allData = this.allData.bind(this);
  }

  ngOnInit(): void {}

  public expandDetail({ dataItem }: RowArgs): boolean {
    return true;
  }

  public pageChange(state: PageChangeEvent): void {
    this.stateChange.next(state);
  }

  public sortChange(sort: SortDescriptor[]): void {
    let col = this.employeeColumns.find(column => column.field === sort[0].field)

    if(col !== undefined) {
      this.sort = sort;

      if (sort[0].dir === undefined) {
        this.sortString = null
      }
      else {
        //use regex to get column field to sort with
        let field: any;

        //if it is an object category like (department.text) trim .text from the end, else it doesnt need to be trimmed
        (sort[0].field).match(/.+(?=\.)/) === null
        ? field = sort[0].field
        : field = (sort[0].field).substr((sort[0].field).indexOf('.') + 1 )

        this.sortString = `${col.subField}-${sort[0].dir}`;
      }

      this.state.skip = 0;
      this.state.take = 50;
      this.stateChange.next(this.state);

    }
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.state.skip = 0;
    this.state.take = 50;
    this.stateChange.next(this.state);
  }

  public onExcelExport(e: ExcelExportEvent): void {
    e = this.sanitiseGridDataService.sanitise(e);
  }

  toggleFilterInactive($event: MatSlideToggleChange) {
    this.filterInactive = $event.checked;
    this.stateChange.next(this.state);
  }

}
