import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GridDataResult, PageChangeEvent, } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { routes } from '@app/consts';
import { SortDescriptor } from '@progress/kendo-data-query';
import { TranslateService } from '@ngx-translate/core';
import { PositionsService } from '@app/modules/positions/services/positions.service';

// import { ViewPositionDetailsDialogComponent } from '../view-position-details-dialog/view-position-details-dialog.component';

@Component({
  selector: 'app-search-position-dialog',
  templateUrl: './search-position-dialog.component.html',
  styleUrls: ['./search-position-dialog.component.scss']
})
export class SearchPositionDialogComponent implements OnInit {
  @Input() refreshView: boolean;
  public routes: typeof routes = routes;
  public columns: any[] = [
      {field: 'department.text', title: 'Department', dataType: "Lookup", lookupCode: 'DEPARTMENT'},
      {field: 'workRotation.name', title: this.translate.instant('WorkRotation'), dataType: 'String'},
      {field: 'employeeCategory.text', title: 'Employee Category', dataType: 'String'},
      {field: 'employmentType.text', title: 'Employment Type', dataType: 'String'},
      {field: 'functionalJobTitle.text', title: 'Functional Job Title', dataType: 'String'},
      {field: 'region.text', title: 'Region', dataType: "Lookup", lookupCode: 'REGION',},
      {field: 'division.text', title: 'Division', dataType: "Lookup", lookupCode: 'DIVISION'},
      {field: 'subDivision.text', title: 'Sub Division', dataType: "Lookup", lookupCode: 'SUB_DIVISION'},
      {field: 'jobBand.text', title: 'Job Band', dataType: "Lookup", lookupCode: 'JOB_BAND'},
      {field: 'jobStep.text', title: 'Job Step', dataType: "Lookup", lookupCode: 'JOB_STEP'},
      {field: 'jobType.text', title: 'Job Type', dataType: "Lookup", lookupCode: 'JOB_TYPE'},
      {field: 'jobFamily.text', title: 'Job Family', dataType: "Lookup", lookupCode: 'JOB_FAMILY'},
      {field: 'jobGroup.text', title: 'Job Group', dataType: "Lookup", lookupCode: 'JOB_GROUP'},
      {field: 'projectTeam.text', title: 'Project Team', dataType: "Lookup", lookupCode: 'PROJECT_TEAM'},
      {field: 'marketPositionTitle.text', title: 'Market Position Title', dataType: "Lookup", lookupCode: 'MARKET_POSITION_TITLE'},
      {field: 'marketPosition.text', title: 'Market Position', dataType: "Lookup", lookupCode: 'MARKET_POSITION'},
      {field: 'marketView.text', title: 'Market View', dataType: "Lookup", lookupCode: 'MARKET_VIEW'},
      {field: 'unionCode.text', title: 'Union Code', dataType: "Lookup", lookupCode: 'UNION_CODE'},
      {field: 'shiftCode.text', title: 'Shift Code', dataType: "Lookup", lookupCode: 'SHIFT_CODE'},
      {field: 'employmentGroup.text', title: 'Employment Group', dataType: "Lookup", lookupCode: 'EMPLOYMENT_GROUP'},
      {field: 'workLocation.name', title: 'Work Location', dataType: "SpecialLookup", lookupCode: 'WORK_LOCATION'},
      {field: 'organization.name', title: 'Organisation', dataType: 'SpecialLookup', lookupCode: 'ORGANISATION'},
      {field: 'parentPosition.name', title: 'Reports To', dataType: "String"},
      {field: 'clientPositionId', title: 'Client Position ID', dataType: "String"},
    ];
    public sort: SortDescriptor[] = [];
    public bindingType: String = 'array';
    public view: Observable<GridDataResult>;
    public gridDataResult: GridDataResult;
    public selectedPositions: any[] = [];
    isLoading: boolean;
    pageSize: number = 20;
    skip: number = 0;
    sortString: string;
    getPositionsRequest: any;

    public searchFilterString: string;
    public searchValue: string;

    public filterCategories: any[] = [
      {field: 'name', title: 'Name', tableId: "tfi_PosName", dataType: 'String'},
      {field: 'department.id', title: 'Department', tableId: "tfi_PosDepartment", dataType: "Lookup", lookupCode: 'DEPARTMENT'},
      {field: 'workRotation.id', title: this.translate.instant('WorkRotation'), tableId: "tfi_PosWorkRotation", dataType: "SpecialLookup", lookupCode: 'WORK_ROTATION'},
      {field: 'employeeCategory.id', title: 'Employee Category', tableId: "tfi_PosEmployeeCategory", dataType: "Lookup", lookupCode: 'EMPLOYEE_CATEGORY'},
      {field: 'employmentType.id', title: 'Employment Type', tableId: "tfi_PosEmploymentType", dataType: "Lookup", lookupCode: 'EMPLOYMENT_TYPE'},
      {field: 'functionalJobTitle.id', title: 'Functional Job Title', tableId: "tfi_PosFunctionalJobTitle", dataType: "Lookup", lookupCode: 'FUNCTIONAL_JOB_TITLE'},

      {field: 'region.id', title: 'Region', dataType: "Lookup", lookupCode: 'REGION',},
      {field: 'division.id', title: 'Division', dataType: "Lookup", lookupCode: 'DIVISION'},
      {field: 'subDivision.id', title: 'Sub Division', dataType: "Lookup", lookupCode: 'SUB_DIVISION'},
      {field: 'jobBand.id', title: 'Job Band', dataType: "Lookup", lookupCode: 'JOB_BAND'},
      {field: 'jobStep.id', title: 'Job Step', dataType: "Lookup", lookupCode: 'JOB_STEP'},
      {field: 'jobType.id', title: 'Job Type', dataType: "Lookup", lookupCode: 'JOB_TYPE'},
      {field: 'jobFamily.id', title: 'Job Family', dataType: "Lookup", lookupCode: 'JOB_FAMILY'},
      {field: 'jobGroup.id', title: 'Job Group', dataType: "Lookup", lookupCode: 'JOB_GROUP'},
      {field: 'projectTeam.id', title: 'Project Team', dataType: "Lookup", lookupCode: 'PROJECT_TEAM'},
      {field: 'marketPositionTitle.id', title: 'Market Position Title', dataType: "Lookup", lookupCode: 'MARKET_POSITION_TITLE'},
      {field: 'marketPosition.id', title: 'Market Position', dataType: "Lookup", lookupCode: 'MARKET_POSITION'},
      {field: 'marketView.id', title: 'Market View', dataType: "Lookup", lookupCode: 'MARKET_VIEW'},
      {field: 'unionCode.id', title: 'Union Code', dataType: "Lookup", lookupCode: 'UNION_CODE'},
      {field: 'shiftCode.id', title: 'Shift Code', dataType: "Lookup", lookupCode: 'SHIFT_CODE'},
      {field: 'employmentGroup.id', title: 'Employment Group', dataType: "Lookup", lookupCode: 'EMPLOYMENT_GROUP'},
      {field: 'workLocation.name', title: 'Work Location', dataType: "SpecialLookup", lookupCode: 'WORK_LOCATION'},
      {field: 'organization.name', title: 'Organisation', dataType: 'SpecialLookup', lookupCode: 'ORGANISATION'},
      {field: 'parentPosition.name', title: 'Reports To', dataType: "String"},
      {field: 'clientPositionId', title: 'Client Position ID', dataType: "String"},
      {field: 'startDate', title: 'Start Date', dataType: "Date"},
      {field: 'endDate', title: 'End Date', dataType: "Date"},
      {field: 'CostCenter.id', title: 'Cost Center', dataType: 'SpecialLookup', lookupCode: 'CostCenter'},
  ];
  filterString: string;
  selectedPosition: any;

  constructor(
      private dialog: MatDialog,
      public translate: TranslateService,
      private overlayService: OverlayService,
      private snackbarService: SnackbarService,
      private dialogRef: MatDialogRef<SearchPositionDialogComponent>,
      private positionsService: PositionsService,
  ) {
  }

  ngOnInit(): void {
      this.getPositions();
  }

  public selectionChange(e) {
    if(e.selectedRows.length === 0) {
      this.selectedPosition = null;
    }
    else {
      this.selectedPosition = e.selectedRows[0].dataItem;
    }
  }

    showEmployeeTooltip(employees: {id: string, firstName: string, lastName: string}[]) {
        if(employees){
            return employees.map( employee => `${employee.firstName} ${employee.lastName}`).join(', ');
        }

        return null;
    }

  getPositions(): void {
      this.isLoading = true;

      this.getPositionsRequest = this.positionsService.getPositions(this.skip, String(this.pageSize), this.searchFilterString, this.sortString, 'true')
          .pipe(
              finalize(() => {
                  this.isLoading = false;
              })
          )
          .subscribe(
              res => {
                  this.gridDataResult = {
                      data: res.data,
                      total: res.total,
                  };
              }
          );
  }

  public pageChange(event: PageChangeEvent): void {
      this.skip = event.skip;
      this.pageSize = event.take;
      this.getPositions();
  }

  public sortChange(sort: SortDescriptor[]): void {
    let disallowed = ['employees', 'organization.name', 'costCenters'];

    if(!disallowed.includes(sort[0].field)){
      this.sort = sort;

      if (sort[0].dir === undefined) {
          this.sortString = null;
      } 
      // else {
      //     //use regex to get column field to sort with
      //     let field: any;

      //     //if it is an object category like (department.text) trim .text from the end, else it doesnt need to be trimmed
      //     (sort[0].field).match(/.+(?=\.)/) === null
      //         ? field = sort[0].field
      //         : field = (sort[0].field).match(/.+(?=\.)/);
      //     this.sortString = `${field}-${sort[0].dir}`;
      // }

      this.getPositions();
    }
  }

  filterCallback(filterString: string) {
      this.searchFilterString = filterString;
      // this.filterString = filterString;
      this.skip = 0;;
      this.getPositions();
  }

  search() {
      this.searchFilterString = `(Name like "${this.searchValue}") OR (clientPositionId like "${this.searchValue}") OR (Employee.Id like "${this.searchValue}")  OR (Employee.FirstName like "${this.searchValue}") OR (Employee.LastName like "${this.searchValue}")`;
      this.getPositionsRequest.unsubscribe();
      this.getPositions();
  }

  // openPositionDetailsDialog(positionId: string) {
  //   const dialogConfig = new MatDialogConfig();

  //   dialogConfig.disableClose = true;
  //   dialogConfig.autoFocus = true;

  //   dialogConfig.data = {
  //     positionId: positionId,
  //   };

  //   const dialogRef = this.dialog.open(ViewPositionDetailsDialogComponent, dialogConfig);
  // }

  save() {
    this.dialogRef.close(this.selectedPosition);
  }

  close() {
    this.dialogRef.close();
  }

}
