import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {Culture} from '@app/shared/models/system-language/culture.model';
import {api_routes} from '@app/consts';
import {EnvironmentService} from "@app/core/services/environment.service";



@Injectable({
  providedIn: 'root'
})
export class CultureService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getCultures(): Observable<Culture[]> {
    return this.http.get<Culture[]>(`${this.envService.env.apiUrl}${api_routes.CULTURES}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
}
