import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { finalize } from 'rxjs/operators';
import { defer, forkJoin } from 'rxjs';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { EmployeeOtherCompensationService } from './services/employee-other-compensation.service';
import { EmployeeOtherCompensation, EmployeeOtherCompensationVerbose } from './models/employee-other-compensation.model';
import { api_routes, db_tables } from '@app/consts';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';

@Component({
  selector: 'app-employee-other-compensation',
  templateUrl: './employee-other-compensation.component.html',
  styleUrls: ['./employee-other-compensation.component.scss']
})
export class EmployeeOtherCompensationComponent implements OnInit {
  @Input() formIds: string[];
  employeeID: string;

  public columns: any[] = [
    { field: "compensationType", subField: "text", title: "Compensation Type", type: "navigation", default: "Empty", tableId: "tfi_OtherCompensationType" },
    { field: "startDate", title: "Start Date", type: "date", tableId: "tfi_OtherCompensationStartDate" },
    { field: "endDate", title: "End Date", type: "date", tableId: "tfi_OtherCompensationEndDate" },
    { field: "paymentCycle", subField: "text", title: "Payment Cycle", tableId: "tfi_OtherCompensationPaymentCycle" },
    { field: "currency", subField: "id", title: "Currency", tableId: "tfi_OtherCompensationCurrency" },
    { field: "amount", title: "Amount", tableId: "tfi_OtherCompensationAmount"},
    { field: "percentage", title: "Percentage", tableId: "tfi_OtherCompensationPercentage" },
    { field: "comments", title: "Comments", tableId: "tfi_OtherCompensationComments" },
  ];
  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 5;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  formId: string = 'frm_qD2u5ybqzobp54'
  changeReasonFormId: string = 'frm_jxyqqwbOQmJ4NB';
  dialogRef: any;
  formValid: any;
  isLoadingPermissions: boolean = true;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];
  accessAndHistoryURL: string = `${api_routes.TALENT}/[employeeId]/${api_routes.OTHER_COMPENSATIONS}/[entityId]`;

  constructor(
    private dialog: MatDialog,
    private employeeOtherCompensationService: EmployeeOtherCompensationService,
    private translate: TranslateService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private tablePermissionsService: TablePermissionsService,
    private overlayService: OverlayService
  ) {
    this.route.paramMap.subscribe(
      params => this.employeeID = params.get("id")
    )
  }

  ngOnInit(): void {
    this.view = this.getOtherCompensations();
    this.getTargetEmployeeTablePermissions();
  }

  getOtherCompensations() {
    this.isLoading = true;

    this.employeeOtherCompensationService.getOtherCompensations(this.employeeID, String(this.pageSize), this.skip)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          }
          this.gridData = res.data;
        }
      );
  }

  getOtherCompensation(otherCompensation?: EmployeeOtherCompensation) {
    if(otherCompensation === undefined){
      this.openFormDialog();
    }
    else {
      this.isLoading = true;
      this.employeeOtherCompensationService.getOtherCompensation(this.employeeID, otherCompensation.id)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        res => {
          this.openFormDialog(res);
        }
      );
    }
  }

  getTargetEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeID);

    // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
    if(currentFetchedData !== null){
      this.targetEmployeeTablePermissions = currentFetchedData;
      this.isLoadingPermissions = false;
    }
    else {
      this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeID)
      .pipe(
        finalize(() => {
            this.isLoadingPermissions = false;
        })
      )
      .subscribe(
        res => {
          this.targetEmployeeTablePermissions = res;
        }
      );
    }
  }

  openFormDialog(employeeOtherCompensation?: EmployeeOtherCompensationVerbose) {
    let formData = {
      id: employeeOtherCompensation ? employeeOtherCompensation.id : null,
      asOf: moment().format(),
      changeReason: null,
      changeReasonComments: null,
      amount: employeeOtherCompensation ? employeeOtherCompensation.amount : null,
      percentage: employeeOtherCompensation ? employeeOtherCompensation.percentage : null,
      startDate: employeeOtherCompensation ? employeeOtherCompensation.startDate : null,
      endDate: employeeOtherCompensation ? employeeOtherCompensation.endDate : null,
      comments: employeeOtherCompensation ? employeeOtherCompensation.comments : null,
      currency: employeeOtherCompensation && employeeOtherCompensation.currency ? employeeOtherCompensation.currency.id : null,
      paymentCycle: employeeOtherCompensation && employeeOtherCompensation.paymentCycle ? employeeOtherCompensation.paymentCycle.id : null,
      compensationType: employeeOtherCompensation && employeeOtherCompensation.compensationType ? employeeOtherCompensation.compensationType.id : null
    }

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formTitle: `${this.translate.instant('OtherCompensation')}`,
      formId: this.formId,
      changeReasonFormId: this.changeReasonFormId,
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions,
      formData: formData
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      this.save(event)
    });
  }

  formDataEmitted(formData) {
    this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  save(formData: any){
    this.overlayService.show();

    if(formData.id === null){
      this.employeeOtherCompensationService.createOtherCompensation(this.employeeID, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getOtherCompensations();
        }
      );
    }
    else {
      this.employeeOtherCompensationService.updateOtherCompensation(this.employeeID, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getOtherCompensations();
        }
      );
    }
  }

  deleteOtherCompensations(OtherCompensationIds: string[]) {
    const observables = OtherCompensationIds.map(selectedItem => defer(() => this.employeeOtherCompensationService.deleteOtherCompensation(this.employeeID, selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.view = this.getOtherCompensations();
        this.clearSelectedItems = !this.clearSelectedItems;
        this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.view = this.getOtherCompensations();
  }

}




