import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import {EnvironmentService} from "@app/core/services/environment.service";
//

const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});
@Injectable({
  providedIn: 'root'
})
export class CreateDirectoryService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  createDirectory(file: File, name: string, parentId: string, apiPath: string): Observable<any> {
    const formData: FormData = new FormData();

    formData.append('File', file);
    formData.append('Parent', parentId ? parentId : '');
    formData.append('Comments', '');
    formData.append('Name', name ? name : '');

    return this.http.post<any>(`${this.envService.env.apiUrl}${apiPath}`,
      formData,
      {
        headers: {},
        reportProgress: true,
        observe: 'events'
      }
    );
  }
}
