import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { defer, forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CostCentersService } from '@app/modules/cost-centers/services/cost-centers.service';
import { CostCenter, CostCenterVerbose } from '@app/modules/cost-centers/models/cost-center.model';
import { SortDescriptor } from '@progress/kendo-data-query';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { SecurityProtectedBase } from '@app/shared/components/security-protected/security-protected';
import { FeatureService } from '@app/core/services/feature.service';
import { features } from '@app/consts';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-work-rotations',
  templateUrl: './cost-centers.component.html',
  styleUrls: ['./cost-centers.component.scss']
})
export class CostCentersComponent extends SecurityProtectedBase implements OnInit {
  @Input() refreshView: boolean;
  public bindingType: String = 'array';
  public view: Observable<GridDataResult>;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public selectedItems: any[] = [];
  pageSize: number = 20;
  skip: number = 0;
  isLoading: boolean;
  dialogRef: any;
  getRequest: any;

  public columns: any[] = [
      { field: "name", title: "Name", type: "navigation"},
      { field: "code", title: "Code"},
      {field: 'startDate', title: 'Start Date', type:'date'},
      {field: 'endDate', title: 'End Date', type:'date'},
  ];

  filterCategories: any[] = [
    { field: "startDate", title: "Start Date", type: "date", dataType: 'Date'},
    { field: "endDate", title: "End Date", type: "date", dataType: 'Date'},
  ];

  sortableColumns: any[] = [
    { field: "name", sortValue: "name"},
    { field: "code", sortValue: "code"},
    { field: "startDate", sortValue: "startDate"},
    { field: "endDate", sortValue: "endDate"},
  ];

  filterToggleDetails: {
    text: string,
    checked: boolean
  } = {
    text: 'FilterInactive',
    checked: true
  }

  public searchFilterString: string;
  public searchValue: string;
  public sort: SortDescriptor[] = [];
  public sortString: string = 'name-asc';
  clearSelectedItems: boolean = false;
  filterString: string;

  constructor(
    private dialog: MatDialog,
    featureService: FeatureService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
    private costCentresService: CostCentersService
  ) {
    super(featureService, features.COST_CENTERS)
  }

  ngOnInit(): void {
      this.getCostCenters();
  }

  getCostCenters() {
    let filter;
    if(this.searchFilterString) {
      filter = this.searchFilterString;
    }
    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }

    this.isLoading = true;

    this.getRequest = this.costCentresService.getCostCenters(this.skip, String(this.pageSize), filter, this.sortString, this.filterToggleDetails.checked.toString())
        .pipe(
            finalize(() => {
                this.isLoading = false;
            })
        )
        .subscribe(
            res => {
                this.gridDataResult = {
                    data: res.data,
                    total: res.total,
                };
            }
        );
  }

  getCostCenter(costCenter?: CostCenter) {
      if(costCenter === undefined){
          this.openCostCenterDialog();
        }
      else {
          let costCenterVerbose: CostCenterVerbose;

          this.isLoading = true;

          this.costCentresService.getCostCenterById(costCenter.id)
          .pipe(
            finalize(() => this.isLoading = false)
          )
          .subscribe(
            res => {
              costCenterVerbose = res;
              this.openCostCenterDialog(costCenterVerbose);
            }
          );
      }
  }

  openCostCenterDialog(costCenter?: CostCenterVerbose) {
      let formData = {
        id: costCenter?.id ?? null,
        name: costCenter?.name ?? null,
        code: costCenter?.code ?? null,
        startDate: costCenter?.startDate ?? null,
        endDate: costCenter?.endDate ?? null,
        asOf: moment().format(),
        changeReason: null,
        changeReasonComments: '',
      }

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      dialogConfig.data = {
        formTitle: `${this.translate.instant('Association')}`,
        formId: 'frm_lwddA7PGFNfHRk',
        formData: formData,
        targetEmployeeTablePermissions: null
      };

      this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

      const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
        this.saveCostCenter(event)
      });
  }

  saveCostCenter(formData) {
      this.overlayService.show();

      if (formData.id !== null) {
          this.costCentresService.updateCostCenter(formData)
              .pipe(
                  finalize(() => this.overlayService.hide())
              )
              .subscribe((res) => {
                  this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
                  this.dialogRef.close();
                  this.getCostCenters();
              },
              err => {
                  this.snackbarService.openSnackBar(err, 'clear', 'warn');
              }
              );
          } else {
              this.costCentresService.createCostCenter(formData)
              .pipe(
                  finalize(() => this.overlayService.hide())
                  )
              .subscribe((res) => {
                  this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
                      this.dialogRef.close();
                      this.getCostCenters();
                  },
                  err => {
                      this.snackbarService.openSnackBar(err, 'clear', 'warn');
                  }
              );
      }
  }

  deleteCostCenters(associationIds: string[]) {
      const observables = associationIds.map(selectedItem => defer(() => this.costCentresService.deleteCostCenter(selectedItem)));

      this.overlayService.show();

      forkJoin(observables)
      .pipe(
          finalize(() => this.overlayService.hide())
      )
      .subscribe(
          (res) => {
          this.clearSelectedItems = !this.clearSelectedItems;
          this.getCostCenters();
          this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
          },
          err => {
          this.snackbarService.openSnackBar(err, 'clear', 'warn');
          }
      );
  }

  public pageChange(event: PageChangeEvent): void {
      this.skip = event.skip;
      this.pageSize = event.take;
      this.getCostCenters();
  }

  search(searchValue) {
      this.searchValue = searchValue;
      this.searchFilterString = `(Name like "${this.searchValue}") OR (Code like "${this.searchValue}")`;
      this.getRequest.unsubscribe();
      this.getCostCenters();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getCostCenters();
  }

  updateFilterToggleDetails(toggleChange: MatSlideToggleChange){
    this.filterToggleDetails.checked = toggleChange.checked;
    this.getCostCenters();
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;
    this.getCostCenters();
  }
}
