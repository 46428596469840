import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { ActivatedRoute } from '@angular/router';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { defer, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BenefitsPlanTypesService } from '../../../benefits-plan-types/services/benefits-plan-types.service';
import { BenefitsPlansService } from '../../../benefits-plans/services/benefits-plans.service';
import { BenefitsPlanCoverage } from '../../models/benefits-plan-coverage.model';
import { BenefitsPlanCoverageService } from '../../services/benefits-plan-coverage.service';
import { BenefitsPlanCoverageDialogComponent } from '../benefits-plan-coverage-dialog/benefits-plan-coverage-dialog.component';

@Component({
  selector: 'app-benefits-plan-coverage-list',
  templateUrl: './benefits-plan-coverage-list.component.html',
  styleUrls: ['./benefits-plan-coverage-list.component.scss']
})
export class BenefitsPlanCoverageListComponent implements OnInit {
  @ViewChild('selectionList', {static: false}) private selectionList: MatSelectionList; 
  @Input() planId: string;
  @Input() coverageId: string;
  selectAll: boolean;
  loadingCoverages: boolean;
  planCoverages: BenefitsPlanCoverage[];
  selectedPlanCoverages: BenefitsPlanCoverage[] = [];

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private cultureService: CultureService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private benefitsPlanCoverageService: BenefitsPlanCoverageService,
    private lookupService: LookupService,
    private dialog: MatDialog,
    private benefitsPlanTypesService: BenefitsPlanTypesService,
  ) { }

  ngOnInit(): void {
    this.getPlanCoverages();
  }

  getPlanCoverages() {
    this.loadingCoverages = true;

    this.benefitsPlanCoverageService.getPlanCoverages(this.planId)
    .pipe(
      finalize(() => this.loadingCoverages = false)
    )
    .subscribe(
      res => {
        this.planCoverages = res;
      }
    )
  }

  toggleSelectAll(event: MatCheckboxChange) {
    this.selectAll = event.checked;
    event.checked ? this.selectionList.selectAll() : this.selectionList.deselectAll();
  }

  openFormDialog(event, planCoverage?: BenefitsPlanCoverage) {
    event ? event.stopPropagation() : null

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      coverageId: planCoverage ? planCoverage.coverage.id : null,
      planId: this.planId ? this.planId : null
    };

    const dialogRef = this.dialog.open(BenefitsPlanCoverageDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.getPlanCoverages();
        }
      }
    );
  }

  openConfirmDeleteDialog(event, planCoverage?: BenefitsPlanCoverage) {
    event.stopPropagation();

    const dialogConfig = new MatDialogConfig();
    let text;

    if(planCoverage !== null){
      text = `Are you sure you want to delete this plan coverage (${planCoverage.coverage.name})`;
    }
    else {
      text = `Are you sure you want to delete all selected coverages`;
    }

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
        text: text
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          if(planCoverage !== null){
            this.deletePlanCoverage(planCoverage);
          }
          else {
            this.deleteAllSelected();
          }
        }
      }
    ); 
  }

  deletePlanCoverage(planCoverage: BenefitsPlanCoverage){
    this.overlayService.show();

    this.benefitsPlanCoverageService.deletePlanCoverage(this.planId, planCoverage.coverage.id)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.getPlanCoverages();
        this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
      }
    );
  }

  deleteAllSelected() {
    const observables = this.selectedPlanCoverages.map(listOption => defer(() => this.benefitsPlanCoverageService.deletePlanCoverage(this.planId, listOption.coverage.id)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.selectAll = false;
        this.selectedPlanCoverages = [];
        this.getPlanCoverages();
        this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
      }
    );
  }

}
