import { CustomReportColumn, ServerReportElement } from "@app/modules/custom-reports/models/custom-report.model";

export class ReportsMapping {

    constructor() {
    }

    parentChildTables = [
        {
            name: 'Employee',
            subTables: [
                // {tableName: CustomReportTable.EmployeeDetails, fields: []},
                {tableName: 'Employee', fields: []},
                {tableName: 'Time Off History', fields: []},
                {tableName: 'Compensation', fields: []},
                {tableName: 'Other Compensations', fields: []},
                {tableName: 'Emergency Contacts', fields: []},
                {tableName: 'Family Dependants', fields: []},
                {tableName: 'Medical Tests', fields: []},
                {tableName: 'Visas and Permits', fields: []},
                {tableName: 'Languages', fields: []},
                {tableName: 'Training and Certification', fields: []},
                {tableName: 'Education', fields: []},
                {tableName: 'Work History', fields: []},
                {tableName: 'Professional Expertise', fields: []},
                {tableName: 'Associations', fields: []},
                {tableName: 'Company Assets', fields: []},
                {tableName: 'Relocations', fields: []},
                {tableName: 'Grievances', fields: []},
                {tableName: 'Injury Illness', fields: []},
                {tableName: 'Bank Details', fields: []},
                {tableName: 'Payroll Details', fields: []},
                {tableName: 'Employee Position', fields: []},
                {tableName: 'Employment Record', fields: []},
                {tableName: 'Goals', fields: []},
            ]
        },
        {
            name: 'Position',
            subTables: [
                {tableName: 'Position', fields: []},
            ]
        },
        {
            name: 'Organization Structure',
            subTables: [
                {tableName: 'Organization Structure', fields: []},
                {tableName: 'Position', fields: []},
                {tableName: 'Employee', fields: []},
            ]
        },
        {
            name: 'Cost Centre',
            subTables: [
                {tableName: 'Cost Centre', fields: []},
                {tableName: 'Position', fields: []},
                {tableName: 'Employee Position', fields: []},
            ]
        },
        {
            name: 'Work Location',
            subTables: [
                {tableName: 'Work Location', fields: []},
                {tableName: 'Position', fields: []},
                {tableName: 'Employee Position', fields: []},
            ]
        },
        {
            name: 'Work Rotation',
            subTables: [
                {tableName: 'Work Rotation', fields: []},
                {tableName: 'Position', fields: []},
                {tableName: 'Employee Position', fields: []},
            ]
        },
        {
            name: 'Time Off Type',
            subTables: [
                {tableName: 'Time Off Type', fields: []},
                {tableName: 'Time Off Policy', fields: []},
                // {tableName: 'Employee', fields: []},
            ]
        }
    ];


    // TODO: replace once proper data is returned for this table
    costCenterDummyMetadata = [
        {id: "Name", name: "Name"},
        {id: "Code", name: "Code"},
    ]

    organizationDummyMetadata = [
        {id: "Name", name: "Name"},
        {id: "organizationType", name: "Organization Type"},
        {id: "parentOrganization", name: "Parent Organization"},
        {id: "startDate", name: "Start Date", fieldType: {id: 'DATETIME'}},
        {id: "endDate", name: "End Date", fieldType: {id: 'DATETIME'}},
    ]

    workRotationsDummyMetadata = [
        {id: "Name", name: "Name"},
        {id: "positionsUsingRotation", name: "Positions Using Rotation"},
    ]

    timeOffTypeDummyMetadata = [
        {id: "Name", name: "Name"},
        {id: "allowSelectionWithNoPolicy", name: "Allow Selection With No Policy"},
        // {id: "disabled", name: "Disabled"},
    ]

    timeOffPolicyDummyMetadata = [
        // {id: "Id", name: "Id"},
        {id: "Name", name: "Name"},
        // {id: "disabled", name: "Disabled"},
        {id: "accruedAt", name: "Accrued At"},
        {id: "fteProrated", name: "FTE Prorated", fieldType: {id: 'BOOLEAN'}},
        {id: "carryOverMonth", name: "Carry Over Month"},
        {id: "carryOverDay", name: "Carry Over Day", fieldType: {id: 'BOOLEAN'}},
        {id: "carryOverUseHireDate", name: "Carry Over Use Hire Date", fieldType: {id: 'BOOLEAN'}},
    ]

    // public elementTypes: { id: string, display: string }[] = [
    //     {id: "datagrid_element", display: 'Data Grid'},
    //     {id: "piechart_element", display: 'Pie Chart'},
    // ]

    // TODO: generalize the methods below
    getParentTableFromProperties(reportElement: ServerReportElement): string {
        return JSON.parse(reportElement.properties.find(prop => prop.property === 'parentTable')?.value ?? "");
    }

    getGroupingThresholdTableFromProperties(reportElement: ServerReportElement): number {
        return parseInt(reportElement.properties.find(prop => prop.property === 'groupingThreshold')?.value) ?? 0
    }

    getSeparateLinesFromProperties(reportElement: ServerReportElement): boolean {
        return reportElement.properties.find(prop => prop.property === 'multiValueFieldHandling')?.value === 'true' ?? false;
    }

    setSeparateLines(reportElement: ServerReportElement, separateLines: boolean) {
        let prop = reportElement.properties.find(prop => prop.property === 'multiValueFieldHandling');
        if (prop) {
            prop.value = separateLines.toString();
        } else {
            reportElement.properties.push({property: 'multiValueFieldHandling', value: separateLines.toString()})
        }
    }

    getColumnsFromProperties(reportElement: ServerReportElement): CustomReportColumn[] {
        let columnsString = reportElement.properties.find(prop => prop.property === 'columns')?.value ?? "[]";
        let columns = JSON.parse(columnsString) as CustomReportColumn[];
        return columns
    }

    getChartTypeFromProperties(reportElement: ServerReportElement): string {
        return JSON.parse(reportElement.properties.find(prop => prop.property === 'chartType')?.value ?? "");
    }


    getColorScheme(reportElement: ServerReportElement) {
        let colors = reportElement.properties.find(prop => prop.property === 'colorScheme')?.value ?? "[]";
        return JSON.parse(colors);
    }

    getElementName(reportElement: ServerReportElement): string {
        return reportElement.properties.find(prop => prop.property === 'elementName')?.value ?? "";
    }


    setElementsOrder(reportElements: ServerReportElement[]) {
        reportElements.forEach((reportElement, index) => {
            this.setElementOrder(reportElement, index);
        });
    }

    orderElements(reportElements: ServerReportElement[]) {
        return reportElements.sort((a, b) => this.getElementOrder(a) - this.getElementOrder(b));
    }

    getElementOrder(reportElement: ServerReportElement): number {
        return parseInt(reportElement.properties.find(prop => prop.property === 'order')?.value) ?? 0;
    }

    setElementOrder(reportElement: ServerReportElement, order: number) {
        let orderProperty = reportElement.properties.find(prop => prop.property === 'order');
        if (orderProperty) {
            orderProperty.value = order.toString();
        } else {
            reportElement.properties.push({property: 'order', value: order.toString()});
        }
    }

    getTableColumnsFromProperties(columns: CustomReportColumn[]) {
        let tableColumns = columns.map(property => {
            let elem: CustomReportColumn = {title: property.title, table: property.table, field: property.field, fieldType: property.fieldType, filter: property.filter};
            return elem
        });
        return tableColumns;
    }
}
