<div class="container">
    <ng-container *ngIf="isLoading; else loaded">
        <ngx-skeleton-loader
          count="5"
          [theme]="{ 
              'height.px': 50
          }"
        ></ngx-skeleton-loader>
    </ng-container>
    <ng-template #loaded>
        <mat-chip-list *ngIf="readOnly">
            <mat-chip class="green-chip" appLocalizationPopupDirective [localizationCode]="'EmployeeGoals-ReadOnly'">{{ 'EmployeeGoals-ReadOnly' | translate: {Default: "Read Only"} }}</mat-chip>
        </mat-chip-list>

        <div class="form">
            <div class="form-section" *ngIf="!hideGoalPlan">
                <div class="form-section-title">
                    <h6 appLocalizationPopupDirective [localizationCode]="'EmployeeGoals-GoalPlan'">
                        {{ 'EmployeeGoals-GoalPlan' | translate: {Default: "Goal Plan"} }}
                    </h6>
                </div>
                <div class="form-section-contents">
                    <div class="section-row">
                        <form [formGroup]="goalForm">
                            <app-permissible-employee-goal-plans
                                [formElement]="{
                                    text: translate.instant('EmployeeGoals-GoalPlan'),
                                    formControl: 'goalPlan'
                                }"
                                [parentGroup]="goalForm"
                                [readOnly]="this.goalForm.get('goalPlan').disabled"
                            >
                            </app-permissible-employee-goal-plans>
                        </form>
                    </div>
                </div>
            </div>

            <div class="form-section">
                <div class="form-section-title">
                    <h6 appLocalizationPopupDirective [localizationCode]="'EmployeeGoals-GoalDetails'">{{ 'EmployeeGoals-GoalDetails' | translate: {Default: "Goal Details"} }}</h6>
                </div>
                <div class="form-section-contents">
                    <div class="section-row">
                        <form [formGroup]="goalForm">
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    {{ getColumnTitle('tfi_PEgObjective') }}
                                </mat-label>
                    
                                <input 
                                    matInput 
                                    type="text"
                                    formControlName="objective"
                                >
                            </mat-form-field>
                
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    {{ getColumnTitle('tfi_PEgDescription') }}
                                </mat-label>
                    
                                <textarea 
                                    matInput 
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="1"
                                    cdkAutosizeMaxRows="30"
                                    formControlName="description"
                                ></textarea>
                            </mat-form-field>
                          </form>
                    </div>
                </div>
            </div>
            
            

            <div class="form-section">
                <div class="form-section-title">
                    <h6 appLocalizationPopupDirective [localizationCode]="'EmployeeGoals-ProgressDetails'">{{ 'EmployeeGoals-ProgressDetails' | translate: {Default: "Progress Details"} }}</h6>
                </div>
                <div class="form-section-contents">
                    <div class="section-row">
                        <form [formGroup]="goalForm">
                            <mat-form-field  appearance="fill">
                                <mat-label>
                                    {{ getColumnTitle('tfi_PEgExpectedCompletionDate') }}
                                </mat-label>
                    
                                <input matInput [matDatepicker]="expectedCompletionDatepicker" formControlName="expectedCompletionDate">
                                <span fxLayout="row" matSuffix>
                                    <mat-datepicker-toggle  [for]="expectedCompletionDatepicker"></mat-datepicker-toggle>
                                </span>
                                <mat-datepicker #expectedCompletionDatepicker></mat-datepicker>
                            </mat-form-field>
                
                            <mat-form-field  appearance="fill">
                                <mat-label>
                                    {{ getColumnTitle('tfi_PEgActualCompletionDate') }}
                                </mat-label>
                    
                                <input matInput [matDatepicker]="actualCompletionDatepicker" formControlName="actualCompletionDate">
                                <span fxLayout="row" matSuffix>
                                    <mat-datepicker-toggle  [for]="actualCompletionDatepicker"></mat-datepicker-toggle>
                                </span>
                                <mat-datepicker #actualCompletionDatepicker></mat-datepicker>
                            </mat-form-field>
                
                            <div class="slider-container">
                                <div class="slider-label">
                                    {{ getColumnTitle('tfi_PEgPercentageComplete') }}
                                </div>
                                <div class="slider-input">
                                    <mat-form-field appearance="fill" class="invisible">
                                        <input
                                            #modValue
                                            type="number"
                                            max="1"
                                            min="0"
                                            step="0.01"
                                            formControlName="percentageComplete"
                                            matInput>
                                    </mat-form-field>
                    
                                    <mat-form-field appearance="fill">
                                        <input
                                            type="number"
                                            [max]="100"
                                            [min]="0"
                                            [step]="1"
                                            [(value)]="percentageSliderValue"
                                            [readonly]="readOnly"
                                            (input)="setValue($event.target.value / 100, goalForm, 'percentageComplete'); calculateSliderPercentage($event.target.value / 100, 1)"
                                            matInput>
                                        <mat-icon class="material-icons-outlined" matSuffix>percent</mat-icon>
                                    </mat-form-field>

                                    <div class="slider">
                                        <mat-slider
                                            max="1"
                                            min="0"
                                            step="0.01"
                                            [(value)]="modValue.value"
                                            [disabled]="readOnly"
                                            (input)="setValue($event.value, goalForm, 'percentageComplete'); calculateSliderPercentage($event.value, 1)"
                                        >
                                        </mat-slider>
                                    </div>
                                </div>
                            </div>
                
                            <div class="slider-container">
                                <div class="slider-label">
                                    {{ getColumnTitle('tfi_PEgWeight') }}
                                </div>
                                <div class="slider-input">
                                    <mat-form-field appearance="fill" class="invisible">
                                        <input
                                            #modValueWeight
                                            type="number"
                                            max="1"
                                            min="0"
                                            step="0.01"
                                            formControlName="weight"
                                            matInput>
                                    </mat-form-field>
                    
                                    <mat-form-field appearance="fill">
                                        <input
                                            type="number"
                                            [max]="100"
                                            [min]="0"
                                            [step]="1"
                                            [(value)]="weightSliderValue"
                                            [readonly]="readOnly"
                                            (input)="setValue($event.target.value / 100, goalForm, 'weight'); calculateWeightSliderPercentage($event.target.value / 100, 1)"
                                            matInput>
                                        <mat-icon class="material-icons-outlined" matSuffix>percent</mat-icon>
                                    </mat-form-field>
                    
                                    <div class="slider">
                                        <mat-slider
                                            max="1"
                                            min="0"
                                            step="0.01"
                                            [(value)]="modValueWeight.value"
                                            [disabled]="readOnly"
                                            (input)="setValue($event.value, goalForm, 'weight'); calculateWeightSliderPercentage($event.value, 1)"
                                        >
                                        </mat-slider>
                                    </div>
                                </div>
                            </div>

                            <div class="form-section" *ngIf="!hideGoalPlan">
                                <div class="form-section-title">
                                    <h6>
                                        {{ getColumnTitle('tfi_PEgGoalType') }}
                                    </h6>
                                </div>
                                <div class="form-section-contents">
                                    <div class="section-row">
                                        <form [formGroup]="goalForm">
                                            <app-goal-type-selection-form-field
                                                [formElement]="{
                                                    text: getColumnTitle('tfi_PEgGoalType'),
                                                    formControl: 'goalTypeId'
                                                }"
                                                [parentGroup]="goalForm"
                                                [readOnly]="this.goalForm.get('goalTypeId').disabled"
                                                [mandatoryFilter]="mandatoryGoalTypeFilter"
                                            >
                                            </app-goal-type-selection-form-field>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div class="form-section" *ngIf="parentId && !isAParent">
                                <div class="form-section-title">
                                    <h6>
                                        {{ getColumnTitle('tfi_PEgParentGoal') }}
                                    </h6>
                                </div>
                                <div class="form-section-contents">
                                    <div class="section-row">
                                        <form [formGroup]="goalForm">
                                            <app-goal-selection-form-field
                                                [formElement]="{
                                                    text: getColumnTitle('tfi_PEgParentGoal'),
                                                    formControl: 'parentId'
                                                }"
                                                [parentGroup]="goalForm"
                                                [readOnly]="this.goalForm.get('parentId').disabled"
                                                [mandatoryFilter]="mandatoryFilter"
                                            >
                                            </app-goal-selection-form-field>
                                        </form>
                                    </div>
                                </div>
                            </div>
                
                            
                        </form>
                    </div>
                </div>
            </div>

            <button 
                *ngIf="readOnly !== true"
                mat-raised-button 
                color="primary" 
                setColor="primaryColour"
                appPreventDoubleClick 
                (throttledClick)="save()" 
            >{{ 'Save' | translate: {Default: "Save"} }}</button>
        </div>
    </ng-template>
</div>
