import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { LookupSubmit, TableListFieldOptionSubmit } from '../../models/lookup.model';
import { LookupService } from '../../services/lookup.service';

@Component({
  selector: 'app-add-single-value',
  templateUrl: './add-single-value.component.html',
  styleUrls: ['./add-single-value.component.scss']
})
export class AddSingleValueComponent implements OnInit {
  @Input() listGroup: string;
  @Output() saveComplete = new EventEmitter<boolean>();
  @Output() close = new EventEmitter<boolean>();
  lookupValue: string;
  cultures: Culture[];

  form = this.fb.group({
    localizations: this.fb.array([]),
    startDate: [moment().subtract('1', 'day').format()],
    endDate: [null],
    prioritize: [false, Validators.required]
  });

  constructor(
    private fb: UntypedFormBuilder,
    private snackbarService: SnackbarService,
    private lookupService: LookupService,
    private overlayService: OverlayService,
    private cultureService: CultureService
  ) { }

  ngOnInit(): void {
    this.cultureService.getCultures().subscribe( res => { this.cultures = res; this.addNewLocalization(); } );
  }

  get localizations() {
    return this.form.controls["localizations"] as UntypedFormArray;
  }

  //Create a textLocalization form group object to add to the localizations form array
  addNewLocalization(culture?: string, localization?: string) {
    const localizationForm = this.fb.group({
      culture: [culture || '', Validators.required],
      text: [localization || '', Validators.required]
    });

    this.localizations.push(localizationForm);
  }

  deleteLocalization(index: number) {
    this.localizations.removeAt(index);
  }

  emitClose() {
    this.close.emit(true);
  }

  save(){
    let tableListFieldOptionSubmit: TableListFieldOptionSubmit = {
      asOf: moment().format(),
      changeReason: '',
      changeReasonComments: '',
      id: '',
      prioritize: this.form.value.prioritize,
      startDate: this.form.value.startDate,
      endDate: this.form.value.endDate,
      text: this.form.value.localizations
    }

    this.overlayService.show();

    this.lookupService.addNewTableListFieldOption(this.listGroup, tableListFieldOptionSubmit)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.saveComplete.emit(true);
        this.snackbarService.openSnackBar('List option value saved successfully', 'clear', 'success');
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

}
