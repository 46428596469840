<div class="main-container">
    <div class="top">
        <div class="details-container">
            <div class="letter-container">
                <h5>Selected Letter</h5>
    
                <div *ngIf="selectedLetter; else noSelectedLetter">
                    <div class="selected-letter-name">{{selectedLetter.name}}</div>
                    <div class="selected-letter-description">{{selectedLetter.description}}</div>
                </div>
                <ng-template #noSelectedLetter>
                    <div>No letter selected</div>
                </ng-template>
            </div>
            <div class="recipients-container">
                <h5>Recipients</h5>
    
                <div *ngIf="recipients.length > 0; else noSelectedRecipients">
                    <mat-chip-list>
                        <mat-chip *ngFor="let recipient of recipients" color="accent" selected>{{recipient.full_name}}</mat-chip>
                    </mat-chip-list>
                        
                </div>
                <ng-template #noSelectedRecipients>
                    <div>No recipients selected</div>
                </ng-template>
            </div>
            <div class="variables-container">
                <h5>Letter Details</h5>
                <div *ngIf="letterVariables; else noSelectedLetterVariables">
                    <mat-form-field appearance="fill" *ngFor="let item of letterVariables | keyvalue">
                        <mat-label>
                            {{item.key}}
                        </mat-label>
            
                        <input matInput type="text" [value]="item.value" readonly>
                    </mat-form-field>
                </div>
                <ng-template #noSelectedLetterVariables>
                    <div class="no-letter-details">No letter details completed</div>
                </ng-template>
            </div>
        </div>
    
        <div class="editor-container" *ngIf="selectedLetter">
            <kendo-editor [value]="selectedLetter.letterHTML" style="height: 100%;"></kendo-editor>
        </div>
    </div>

    <div class="buttons-container">
        <button mat-stroked-button setColor="primaryColour" color="primary">Close</button>
        <button mat-stroked-button setColor="primaryColour" color="primary">Save To Employee Profile</button>
        <button mat-stroked-button setColor="primaryColour" color="primary">Print</button>
        <button mat-flat-button setColor="primaryColour" color="primary" (click)="send()">Send</button>
    </div>
</div>