import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { SecurityRoleService } from '@app/modules/security-roles/services/security-role.service';
import { SecurityRoleOrganization } from '@app/modules/security-setup/models/security-role.model';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { PageChangeEvent, RowArgs } from '@progress/kendo-angular-grid';
import { defer, finalize, forkJoin } from 'rxjs';
import { AddOrgDialogComponent } from '../../../add-org-dialog/add-org-dialog.component';

@Component({
  selector: 'app-module-access-by-organization',
  templateUrl: './module-access-by-organization.component.html',
  styleUrls: ['./module-access-by-organization.component.scss']
})
export class ModuleAccessByOrganizationComponent implements OnInit {
  @Input() moduleId: string;
  @Input() securityRoleId: string;
  isLoading: boolean;
  gridDataResult: any;
  gridData: any;
  clearSelectedItems: boolean = false;
  public view: any;
  public pageSize: string = '20';
  public skip: number = 0;
  public bindingType: String = "array";

  public columns: any[] = [
    { field: "organizationUnit", subField: "name", title: "Organization Unit" },
    { field: "module", subField: "name", title: "Module" },
    // { field: "role", subField: "name", title: "Role" },
  ];

  public filterCategories: any[] = [
    { field: "organizationUnitId", title: "Organization Unit", dataType: "SpecialLookup", lookupCode: "ORGANISATION" },
    { field: "moduleId", title: "Module", dataType: "SpecialLookup", lookupCode: "MODULES" },
    // { field: "role", subField: "name", title: "Role" },
  ];

  searchFilterString: string;

  constructor(
    private securityRoleService: SecurityRoleService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    public translate: TranslateService,
    private overlayService: OverlayService,
  ) { }

  ngOnInit(): void {
    this.view = this.getRoleOrganizations();
  }

  public selectEntireRow = (args: RowArgs) => {
    return args.dataItem;
  };

  getRoleOrganizations() {
    this.isLoading = true;

    this.securityRoleService.getRoleOrganizations(this.securityRoleId, this.skip, this.pageSize, this.searchFilterString)
    .pipe(
      finalize(
        () => this.isLoading = false
      )
    )
    .subscribe(
      res => {
        this.gridDataResult = {
            data: res.data,
            total: res.total,
        };

        this.gridData = res.data;
      }
    )
  }

  deleteAllSelected(securityRoleOrganizations: SecurityRoleOrganization[]) {
    const observables = securityRoleOrganizations.map(selectedOrg => defer(() => this.securityRoleService.deleteRoleOrg(this.securityRoleId, selectedOrg.module.id, selectedOrg.organizationUnit.id)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(finalize(() => this.overlayService.hide()))
    .subscribe(
      (res) => {
        this.clearSelectedItems = !this.clearSelectedItems;
        this.getRoleOrganizations();
        this.snackbarService.openSnackBar('Removed successfully', 'clear', 'success');
      }
    );
  }

  pageChange(event: PageChangeEvent) {
    this.skip = event.skip;
    this.pageSize = event.take.toString();
    this.isLoading = true;

    this.getRoleOrganizations();
  }

  filterCallback(filterString: string) {
    this.searchFilterString = filterString;
    this.skip = 0;;
    this.getRoleOrganizations();
  }

  showAddOrgModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {};

    const dialogRef = this.dialog.open(AddOrgDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data && data.selectedItems && Array.isArray(data.selectedItems) && data.selectedItems.length > 0) {
          const observables = data.selectedItems.map(selectedOrgId => defer(() => this.securityRoleService.addOrgToRole(this.securityRoleId, data.selectedModule, selectedOrgId)));

          this.overlayService.show();

          forkJoin(observables)
          .pipe(finalize(() => this.overlayService.hide()))
          .subscribe(
              (res) => {
                  this.getRoleOrganizations();
                  this.snackbarService.openSnackBar('Added successfully', 'clear', 'success');
              }
          );
        }
      }
    );
}

}
