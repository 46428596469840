<h5 mat-dialog-title>Add API User</h5>

<mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label>
            Name
        </mat-label>
    
        <input matInput type="text" [(ngModel)]="name">
        <mat-hint></mat-hint>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>
            Username
        </mat-label>
    
        <input matInput type="text" [(ngModel)]="username">
        <mat-hint></mat-hint>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>
            New Password
        </mat-label>
    
        <input matInput type="password" [(ngModel)]="newPassword">
        <mat-hint></mat-hint>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>
            Retype Password
        </mat-label>
    
        <input matInput type="password" [(ngModel)]="newPasswordConfirm">
        <mat-hint></mat-hint>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="newPassword === '' || newPassword !== newPasswordConfirm">Save</button> 
</mat-dialog-actions>