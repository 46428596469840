import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EmployeeService } from '@app/core/services/employee.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { ActivatedRoute, Router } from "@angular/router";
import { ReportElementDialogComponent } from "@app/modules/custom-reports/components/report-element-dialog/report-element-dialog.component";
import { ConfirmDialogComponent } from "@app/shared/components/confirm-dialog/confirm-dialog.component";
import {
    EmploymentRecordPositionsService
} from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-positions/services/employment-record-positions.service";
import { CustomReportVerbose, ServerReportElement } from "@app/modules/custom-reports/models/custom-report.model";
import { PositionsService } from "@app/modules/positions/services/positions.service";
import { CustomReportsService } from "@app/modules/custom-reports/services/reports.service";
import { CostCentersService } from "@app/modules/cost-centers/services/cost-centers.service";
import { OrganizationStructureService } from "@app/modules/organization-structure/services/organization-structure.service";
import { WorkLocationsService } from "@app/modules/work-locations/services/work-locations.service";
import { WorkRotationService } from "@app/modules/work-rotations/services/work-rotation.service";
import { TimeOffService } from "@app/modules/time-off/services/time-off.service";
import { EmploymentRecordsService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/services/employment-records.service";
import { EmployeeLeaveService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-leave/services/employee-leave.service";
import { LegendLabelsContentArgs } from "@progress/kendo-angular-charts";
import { defer, forkJoin, Observable } from "rxjs";
import { finalize, map } from "rxjs/operators";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { FormElement } from "@app/modules/form-generator/edit-form-v3/models/form.model";
import { EmployeeCompensationService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-compensation/services/employee-compensation.service";
import { EmployeeOtherCompensationService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-other-compensation/services/employee-other-compensation.service";
import { CustomProgressUpdate, ReportGeneratorListener } from "@app/modules/custom-reports/util/report-generator-listener";
import { ReportsUtil } from "@app/modules/custom-reports/util/reports-util";
import { ReportsMapping } from "@app/modules/custom-reports/util/reports-mapping";
import { EmployeeMedicalTestService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-medical-tests/services/employee-medical-test.service";
import { EmergencyContactService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/emergency-contacts/services/emergency-contact.service";
import { VisasAndPermitsService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-visas-and-permits/services/visas-and-permits.service";
import { EmployeeLanguageService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-languages/services/employee-language.service";
import { TrainingAndCertificationService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/training-and-certifications/services/training-and-certification.service";
import { EducationService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-education/services/education.service";
import { EmployeeWorkExperienceService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-work-experience/services/employee-work-experience.service";
import {
    EmployeeProfessionalExpertiseService
} from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-professional-expertises/services/employee-professional-expertise.service";
import { EmployeeAssociationService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-associations/services/employee-association.service";
import { EmployeeCompanyAssetsService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-company-asset/services/employee-company-assets.service";
import { EmployeeRelocationService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-relocation/services/employee-relocation.service";
import { EmployeeGrievancesService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-grievances/services/employee-grievances.service";
import { EmployeeInjuryIllnessService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-injury-illness/services/employee-injury-illness.service";
import { EmployeeBankDetailsService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-bank-details/services/employee-bank-details.service";
import { EmployeePayrollDetailsService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-payroll-details/services/employee-payroll-details.service";
import { EmployeeAbsenceService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-absences/services/employee-absence.service";
import { FamilyDependantService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-family-dependants/services/family-dependant.service";
import { TranslateService } from "@ngx-translate/core";
import { GoalPlanService } from "@app/modules/performance/components/goal-plans/services/goal-plan.service";

@Component({
    selector: 'app-report-builder-dialog',
    templateUrl: './report-builder.component.html',
    styleUrls: ['./report-builder.component.scss']
})
export class ReportBuilderComponent implements OnInit, ReportGeneratorListener {

    public reportsUtil: ReportsUtil = new ReportsUtil(
        this.employeeService,
        this.employmentRecordPositionsService,
        this.positionsService,
        this.costCentersService,
        this.organizationStructureService,
        this.workLocationsService,
        this.workRotationService,
        this.timeOffService,
        this.employeeAbsenceService,
        this.employmentRecordsService,
        this.employeeCompensationService,
        this.employeeOtherCompensationService,
        this.employeeEmergencyContactService,
        this.employeeFamilyDependantsService,
        this.employeeLeaveService,
        this.employeeMedicalTestService,
        this.visasAndPermitsService,
        this.employeeLanguageService,
        this.trainingAndCertificationService,
        this.educationService,
        this.employeeWorkExperienceService,
        this.employeeProfessionalExpertiseService,
        this.employeeAssociationService,
        this.companyAssetsService,
        this.employeeRelocationService,
        this.employeeGrievanceService,
        this.employeeInjuryIllnesService,
        this.employeeBankDetailsService,
        this.employeePayrollDetailsService,
        this.employeeGoalPlanService
    );
    public reportsMapping: ReportsMapping = new ReportsMapping();

    private reportTypeId: string;
    // private reportTypeInUrl: string;
    private reportId: string;
    public reportElements: ServerReportElement[] = [];

    public formId: string = 'frm_TeyZSl3iJFB80H';
    public formData: any;
    public formLoaded: boolean = false;
    public formValid: boolean = false;
    public getFormData: boolean = false;

    public form: UntypedFormGroup;
    // public isPreviewDataLoading: boolean = false;

    public isFormLoading: boolean = true;
    public customLoadingIndicator: CustomProgressUpdate = {
        progress: 0,
        message: 'Starting up...',
    }

    public reportTypes: { id: string, display: string }[] = [
        {id: "employees_report", display:  this.translate.instant('Employees')},
        {id: "terminations_report", display:  this.translate.instant('Terminations')},
        {id: "timeoff_report", display:  this.translate.instant('Time Off')},
        {id: "configuration_report", display:  this.translate.instant('Configuration')},
        {id: "security_report", display:  this.translate.instant('Security')},
        {id: "performance_report", display:  this.translate.instant('Performance')},
    ];
    private report: CustomReportVerbose;

    public reportElementData: any[] = [];

    private unsavedReportElements: boolean = false;
    private unsavedChanges: boolean = false;
    private isSaving: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        private overlayService: OverlayService,
        private snackbarService: SnackbarService,
        private reportService: CustomReportsService,
        private lookupService: LookupService,
        private employeeService: EmployeeService,
        private employmentRecordPositionsService: EmploymentRecordPositionsService,
        private positionsService: PositionsService,
        private costCentersService: CostCentersService,
        private organizationStructureService: OrganizationStructureService,
        private workLocationsService: WorkLocationsService,
        private workRotationService: WorkRotationService,
        private timeOffService: TimeOffService,
        private employeeAbsenceService: EmployeeAbsenceService,
        private employmentRecordsService: EmploymentRecordsService,
        private employeeCompensationService: EmployeeCompensationService,
        private employeeOtherCompensationService: EmployeeOtherCompensationService,
        private employeeEmergencyContactService: EmergencyContactService,
        private employeeFamilyDependantsService: FamilyDependantService,
        private employeeLeaveService: EmployeeLeaveService,
        private employeeMedicalTestService: EmployeeMedicalTestService,
        private visasAndPermitsService: VisasAndPermitsService,
        private employeeLanguageService: EmployeeLanguageService,
        private trainingAndCertificationService: TrainingAndCertificationService,
        private educationService: EducationService,
        private employeeWorkExperienceService: EmployeeWorkExperienceService,
        private employeeProfessionalExpertiseService: EmployeeProfessionalExpertiseService,
        private employeeAssociationService: EmployeeAssociationService,
        private companyAssetsService: EmployeeCompanyAssetsService,
        private employeeRelocationService: EmployeeRelocationService,
        private employeeGrievanceService: EmployeeGrievancesService,
        private employeeInjuryIllnesService: EmployeeInjuryIllnessService,
        private employeeBankDetailsService: EmployeeBankDetailsService,
        private employeePayrollDetailsService: EmployeePayrollDetailsService,
        private employeeGoalPlanService: GoalPlanService,
        private translate: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
    ) {
        // this.reportTypeId = this.getTypeInURL();
        this.reportId = this.getIdInURL();
        const typeInURL = this.getTypeInURL();
        this.reportTypeId = this.resolveReportType(typeInURL);
    }

    ngOnInit(): void {
        if (!this.reportId) {
            this.createForm();
            this.isFormLoading = false;
        } else {
            this.getReport(this.reportId);
        }
        this.unsavedReportElements = false;
        this.isSaving = false;
    }

    getTypeInURL(): string {
        let typeInURL: string;

        this.route.paramMap.subscribe(
            params => typeInURL = params.get("type")
        );

        return typeInURL;
    }


    // getTypeInURL(): string {
    //     let typeInURL: string;
    //
    //     this.route.paramMap.subscribe(
    //         params => typeInURL = params.get("type")
    //     )
    //
    //     // console.log("typeInURL", typeInURL);
    //
    //     // let resolved = this.resolveReportType(typeInURL);
    //
    //     let resolved = this.reportTypes.find(reportType => {
    //         let reportTypeInURL = reportType.display.replace(/\s/g, '');
    //         if (reportTypeInURL === typeInURL) {
    //             this.reportTypeInUrl = reportTypeInURL;
    //             return reportType;
    //         }
    //     })?.id;
    //
    //     console.log("resolved", resolved);
    //
    //     return resolved;
    // }

    getIdInURL(): string {
        let IdInURL: string;

        this.route.paramMap.subscribe(
            params => IdInURL = params.get("id")
        )

        return IdInURL;
    }

    createForm() {
        this.form = this.fb.group({
            reportTypeId: [this.reportTypeId ? this.reportTypeId : '', Validators.required],
        });

        this.formData = {
            name: []
        }

        if (this.reportId) {
            this.formData.name = this.report.name;
        }

        this.form.get('reportTypeId').valueChanges.subscribe(value => {
            this.reportTypeId = value;
        });
    }

    formDataEmitted(formData) {
        this.saveReport(formData);
    }

    formStatusUpdated(formValid) {
        if (!this.formLoaded) {
            this.formLoaded = true;
        }
        this.formValid = formValid;
    }

    formPristineEmitted(formPristine) {
        if (formPristine === false) {
            this.unsavedChanges = true;
        }
    }

    get localizations() {
        return this.formData.controls["name"] as UntypedFormArray;
    }

    //Create a textLocalization form group object to add to the localizations form array
    addNewLocalization(culture?: string, text?: string) {
        const localizationForm = this.fb.group({
            culture: [culture || '', Validators.required],
            text: [text || '', Validators.required]
        });

        this.localizations.push(localizationForm);
    }

    deleteLocalization(index: number) {
        this.localizations.removeAt(index);
    }

    private getReport(reportId: string) {

        this.reportService.getReportById(reportId)
        .subscribe((report) => {
                // console.log("report", report);
                this.report = report;
                this.reportTypeId = this.reportTypes.find(reportType => reportType.id === this.report.type.id)?.id;

                this.reportService.getReportElements(reportId)
                .subscribe((reportElements) => {

                    reportElements = this.reportsMapping.orderElements(reportElements);
                    // TODO: remove when fix for the backend not returning the elementTypeId
                    reportElements.forEach(reportElement => {
                        reportElement.elementTypeId = reportElement.name;
                    });

                    this.reportElements = reportElements;

                    this.reportElementData = [];
                    this.reportElements.forEach((reportElement, index) => {
                        this.reportElementData.push(null);
                        this.reportsUtil.generateReport(reportElement, this, true, index);
                    });
                    this.createForm();
                    this.isFormLoading = false;
                });
            }
        )
    }

    saveReport(formData) {
        this.overlayService.show();
        this.isSaving = true;

        formData['reportTypeId'] = this.form.value.reportTypeId;

        this.reportsMapping.setElementsOrder(this.reportElements);

        if (!this.report) {
            this.reportService.postReport(formData)
            .subscribe(res => {
                let newReportId = res.reportId;

                const observables = this.reportElements.map(reportElement => defer(() => this.reportService.postReportElement(newReportId, reportElement)));
                forkJoin(observables)
                .pipe(
                    finalize(() => this.overlayService.hide())
                )
                .subscribe(
                    (res) => {
                        // navigate back
                        const reportTypeInUrl = this.reportTypes.find(reportType => reportType.id === this.reportTypeId)?.display.replace(/\s/g, '');
                        if (reportTypeInUrl) {
                            this.router.navigate(['/Reports/Custom/Type/' + reportTypeInUrl]);
                        } else {
                            this.router.navigate(['Reports']);
                        }
                        this.snackbarService.openSnackBar('Report created successfully', 'clear', 'success');
                    }
                )
            });

        } else {
            this.reportService.updateReport(this.reportId, formData)
            .subscribe(value => {

                let reportElements = this.reportElements;
                const observables = reportElements.map(selectedItem => defer(() => {
                        if (selectedItem.id) {
                            return this.reportService.putReportElement(this.reportId, selectedItem.id, selectedItem);
                        } else {
                            return this.reportService.postReportElement(this.reportId, selectedItem)
                        }
                    }
                ));
                forkJoin(observables)
                .pipe(
                    finalize(() => this.overlayService.hide())
                )
                .subscribe(
                    (res) => {
                        this.snackbarService.openSnackBar('Report updated successfully', 'clear', 'success');
                    }
                )
            });
        }
    }

    close() {
        const reportTypeInUrl = this.reportTypes.find(reportType => reportType.id === this.reportTypeId)?.display.replace(/\s/g, '');
        if (reportTypeInUrl) {
            this.router.navigate(['/Reports/Custom/Type/' + reportTypeInUrl]);
        } else {
            this.router.navigate(['Reports']);
        }
    }

    openReportElementDialog(reportElementIndex?: number) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.minWidth = 1200;

        dialogConfig.data = {
            elementType: this.reportElements[reportElementIndex]?.name ?? null,
            element: this.reportElements[reportElementIndex] ?? null,
            elementIndex: reportElementIndex ?? null,
        };

        const dialogRef = this.dialog.open(ReportElementDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data) {
                    let reportElement = data.element;
                    let elementIndex = data.elementIndex;
                    if (reportElement) {
                        this.unsavedReportElements = this.checkUnsavedReportElements(dialogConfig.data.element, reportElement);
                        // console.log(`Dialog output:`, reportElement);
                        if (elementIndex !== null) {
                            this.reportElements[elementIndex] = reportElement;
                            this.reportElementData.splice(reportElementIndex, 1);
                        } else {
                            this.reportElements.push(reportElement);
                        }
                        this.reportsUtil.generateReport(reportElement, this, true, elementIndex);
                    }
                }
                if (this.unsavedReportElements) {
                    this.unsavedChanges = true;
                }
            }
        );
    }

    checkUnsavedReportElements(data1, data2): boolean {
        if (!data1) {
            return false;
        }

        if (data1.elementTypeId !== data2.elementTypeId) {
            return true;
        }

        let originalParentTable = data1.properties.find(item => item.property === 'parentTable').value;
        let incomingParentTable = data2.properties.find(item => item.property === 'parentTable').value;
        if (originalParentTable !== incomingParentTable) {
            return true;
        }

        let originalColumns = data1.properties.find(item => item.property === 'columns').value;
        let incomingColumns = data2.properties.find(item => item.property === 'columns').value;
        if (originalColumns !== incomingColumns) {
            return true;
        }

        let originalElementName = data1.properties.find(item => item.property === 'elementName').value;
        let incomingElementName = data2.properties.find(item => item.property === 'elementName').value;
        if (originalElementName !== incomingElementName) {
            return true;
        }

        let originalMVFH = data1.properties.find(item => item.property === 'multiValueFieldHandling').value;
        let incomingMVFH = data2.properties.find(item => item.property === 'multiValueFieldHandling').value;
        if (originalMVFH !== incomingMVFH) {
            return true;
        }

        return false;
    }

    openConfirmCloseDialog(): Observable<boolean> {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            text: this.translate.instant('UnsavedChangesMessage')
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        return dialogRef.afterClosed().pipe(
            map(res => {
                if (res === true) {
                    this.unsavedChanges = false;
                }
                return !!res;
            })
        );
    }

    openConfirmDeleteDialog(reportElementIndex: number) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            text: `Are you sure you want to delete this report element?`
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data === true) {

                    let reportElement = this.reportElements[reportElementIndex];

                    // if element type has id, delete from server
                    if (this.reportId && reportElement.id) {
                        this.reportService.deleteReportElement(this.reportId, reportElement.id)
                        .subscribe(
                            res => {
                                this.reportElements.splice(reportElementIndex, 1);
                                this.reportElementData.splice(reportElementIndex, 1);
                            }
                        )
                    } else {
                        this.reportElements.splice(reportElementIndex, 1);
                        this.reportElementData.splice(reportElementIndex, 1);
                    }
                }
            }
        );
    }


    getFieldMapping(reportElement: ServerReportElement, table: string, field: string) {
        let fieldMapping = this.reportsUtil.getFieldMapping(reportElement, table, field, true);
        return fieldMapping;
    }

    getColumns(reportElement: ServerReportElement) {
        let columns = this.reportsUtil.getColumns(reportElement);
        return columns;
    }


    dataLoading() {
        // this.isPreviewDataLoading = true
    }

    setProgress(progress: number, message: string) {
        console.log("setProgress", progress, message);
        if (progress >= 0) {
            this.customLoadingIndicator.progress = progress;
        }
        this.customLoadingIndicator.message = message;
    }


    setData(data: any, elementIndex: number) {
        // console.log("setData", data, "elementIndex", elementIndex);
        // let data = this.reportsUtil.filterData()

        if (elementIndex !== null) {
            this.reportElementData[elementIndex] = data;
        } else {
            this.reportElementData.push(data);
        }
    }

    public labelContent(args: LegendLabelsContentArgs): string {
        let percentage: any = (args.percentage * 100).toFixed(2);
        return percentage % 1 === 0 ?
            `${args.dataItem.category}: ${percentage.slice(0, -3)}%` :
            `${args.dataItem.category}: ${percentage}%`;
    }

    drop(event: CdkDragDrop<FormElement[]>, list: ServerReportElement[], dataList: any[]) {
        moveItemInArray(list, event.previousIndex, event.currentIndex);
        moveItemInArray(dataList, event.previousIndex, event.currentIndex);
    }

    getColumnWidth(reportElement: any): number | undefined {
        const totalColumns = this.getColumns(reportElement).length;
        if (totalColumns > 8) {
            return 250;
        }
        return undefined; // Use the default width
    }

    resolveReportType(typeInURL: string): string | undefined {
        let resolved = this.reportTypes.find(reportType => {
            let reportTypeInURL = reportType.display.replace(/\s/g, '');
            if (reportTypeInURL === typeInURL) {
                return reportType;
            }
        })?.id;

        return resolved;
    }

    isUnsavedChanges(): boolean {
        return this.unsavedChanges;
    }

    isSavingChanges(): boolean {
        return this.isSaving;
    }

    resetSavingFlag() {
        this.isSaving = false;
    }
}
