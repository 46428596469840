import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { OrganizationStructureService } from '@app/modules/organization-structure/services/organization-structure.service';
import { defer, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OrganizationNavListener } from '@app/modules/organization-structure/organization-structure.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'add-user-dialog',
    templateUrl: './organization-types-dialog.component.html',
    styleUrls: ['./organization-types-dialog.component.scss']
})
export class OrganizationTypesDialogComponent implements OnInit {

    public gridDataResult: GridDataResult;
    public isLoading: boolean;
    public pageSize: number = 20;
    public skip: number = 0;
    public searchFilterString: string;
    public searchValue: string;
    public selectedItems: any[] = [];

    public listener: OrganizationNavListener = null;

    public columns: any[] = [
        {field: 'name', title: 'Name'},
    ];

    constructor(
        private dialog: MatDialog,
        private overlayService: OverlayService,
        private snackbarService: SnackbarService,
        private dialogRef: MatDialogRef<OrganizationTypesDialogComponent>,
        public translate: TranslateService,
        private organizationService: OrganizationStructureService,
        @Inject(MAT_DIALOG_DATA) data
    ) {

    }

    ngOnInit(): void {
        this.getData();
    }

    private getData() {
        this.isLoading = true;

        this.organizationService.getOrganizationTypes(this.skip, String(this.pageSize))
            .subscribe(pagedUsers => {
                this.gridDataResult = {
                    data: pagedUsers.data,
                    total: pagedUsers.total,
                };
                this.isLoading = false;
            });
    }

    close() {
        this.dialogRef.close();
    }


    pageChange(event: PageChangeEvent) {
        this.skip = event.skip;
        this.pageSize = event.take;
        this.getData();
    }

    deleteAllSelected() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            text: `Are you sure you want to delete ${this.selectedItems.length} selected Organization Type(s)?`
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data === true) {
                    const observables = this.selectedItems.map(selectedPosition => defer(() => this.organizationService.deleteOrganizationType(selectedPosition)));
                    this.overlayService.show();
                    forkJoin(observables)
                        .pipe(
                            finalize(() => this.overlayService.hide())
                        )
                        .subscribe(
                            (res) => {
                                this.selectedItems = [];
                                this.getData();
                                this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
                            },
                            err => {
                                this.snackbarService.openSnackBar(err, 'clear', 'warn');
                            }
                        );
                }
            }
        );
    }


    showAddOrgTypeModal() {
        this.dialogRef.close({showAddModal: true})
    }
}
