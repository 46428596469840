<app-layout>
    <div class="main-container">
        <div class="menu">
            <mat-list>
                <ng-container *ngFor="let item of navList">
                    <mat-list-item 
                        setColor
                        [setColorRules]="[
                            {
                                color: 'primaryColour',
                                events: ['hover']
                            },
                            {
                                color: 'primaryColour',
                                setIfClass: ['active'],
                                events: ['routerLinkActive']
                            },
                        ]"
                        [routerLink]="item.link" 
                        routerLinkActive="active"
                    >
                        <mat-icon 
                            setColor
                            [setColorRules]="[
                                {
                                    color: 'primaryColour',
                                    events: ['hover']
                                },
                                {
                                    color: 'primaryColour',
                                    setIfClass: ['active'],
                                    events: ['routerLinkActive']
                                },
                            ]"
                            class="material-icons-outlined"
                        >{{item.icon}}</mat-icon>
                        <div>{{item.text}}</div>
                    </mat-list-item>
                </ng-container>
            </mat-list>
        </div>
        <div class="content">
            <div class="main-content">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</app-layout>