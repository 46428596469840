import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LeaveRequestDialogComponent } from '../../../talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-leave/components/leave-request-dialog/leave-request-dialog.component';
import { EmployeeLeaveService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-leave/services/employee-leave.service'
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { WorkflowsAbsencesService } from '@app/core/services/workflows-absences/workflows-absences.service';
import { finalize } from 'rxjs/operators';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';

@Component({
  selector: 'app-time-off-widget',
  templateUrl: './time-off-widget.component.html',
  styleUrls: ['./time-off-widget.component.scss']
})
export class TimeOffWidgetComponent implements OnInit {
  @Output() timeOffRequestCreated = new EventEmitter<boolean>();
  timeOffLeaveTypes$: any;
  user$: any;
  formId: string = 'frm_Sp9UWjaZBsb1Ha';
  dialogRef: any;

  constructor(
    private dialog: MatDialog,
    private employeeLeaveService: EmployeeLeaveService,
    private oidcAuthService: OidcAuthService,
    private translate: TranslateService,
    private workflowsAbsencesService: WorkflowsAbsencesService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
  ) { 
    this.user$ = this.oidcAuthService.userProfile;
  }

  ngOnInit(): void {
    this.getTimeOffTypes();
  }

  getTimeOffTypes() {
    this.timeOffLeaveTypes$ = this.employeeLeaveService.getEmployeeTimeOffTypes(this.user$.userId)
  }

  openLeaveRequestDialog(timeOffType: string) {
    let formData = {
      from: null,
      to: null,
      timeOffType: timeOffType,
      comments: null
    }

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
 
    dialogConfig.data = {
      formTitle: `${this.translate.instant('RequestTimeOff')}`,
      formId: this.formId,
      formData: formData
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      event.timeOffType = timeOffType;
      this.requestTimeOff(event);
    });
  }

  requestTimeOff(data: any) {
    this.overlayService.show();

    this.workflowsAbsencesService.submitRequest(data)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
        this.dialogRef.close(true);
        this.timeOffRequestCreated.emit(true);
      }
    );
  }

  // openLeaveRequestDialog() {
  //   const dialogConfig = new MatDialogConfig();

  //   dialogConfig.disableClose = true;
  //   dialogConfig.autoFocus = true;

  //   const dialogRef = this.dialog.open(LeaveRequestDialogComponent, dialogConfig);
  //   dialogRef.afterClosed().subscribe(
  //     data => {
  //       if (data === true) {

  //       }
  //     }
  //   );
  // }

}
