<mat-card class="revenue-chart">
    <mat-card-title class="revenue-chart__header">
      <h5 class="revenue-chart__header-title">
          Employee Holiday Utilisation
      </h5>
      
      <app-settings-menu></app-settings-menu>
    </mat-card-title>
    
    <mat-card-content class="revenue-chart__content">
        <kendo-grid
            [data]="gridDataResult"
            [pageSize]="20"
            [pageable]="true"
            [sortable]="true"
            [resizable]="true"
            [height]="550"
            [columnMenu]="{ filter: true }"
        >
    
            <ng-template kendoGridToolbarTemplate>
                <mat-form-field class="grid-search-field" appearance="outline">
                    <input matInput
                            (input)="onFilter($event.target.value)"
                            placeholder="Search in all columns...">
                    <mat-icon matPrefix class="material-icons-outlined">
                        search
                    </mat-icon>
                </mat-form-field>

                <kendo-grid-spacer></kendo-grid-spacer>

                <mat-form-field  appearance="outline">
                    <mat-label>
                        Start Date
                    </mat-label>
                
                    <input matInput [matDatepicker]="startDatepicker">
                    <span fxLayout="row" matSuffix>
                        <mat-datepicker-toggle  [for]="startDatepicker"></mat-datepicker-toggle>
                    </span>
                    <mat-datepicker #startDatepicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>
                        End Date
                    </mat-label>
                
                    <input matInput [matDatepicker]="endDatepicker">
                    <span fxLayout="row" matSuffix>
                        <mat-datepicker-toggle  [for]="endDatepicker"></mat-datepicker-toggle>
                    </span>
                    <mat-datepicker #endDatepicker></mat-datepicker>
                </mat-form-field>
            </ng-template>

            <kendo-grid-column field="profilePictureThumbnailUri" title="" [width]="250">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="employee-name" (click)="selectedEmployee = dataItem">
                        <img 
                        class="profile-pic"
                        [src]="dataItem.profilePictureThumbnailUri">
                        <span class="name">
                            {{dataItem.firstName}} {{dataItem.lastName}}
                        </span>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="customClientId" title="custom Client Id" [width]="200"></kendo-grid-column>

            <kendo-grid-column field="positions" title="Positions" [width]="150">
                <ng-template kendoGridCellTemplate let-dataItem >
                    <div class="grid-cell-vertical">
                        <span *ngFor="let position of dataItem.positions" class="position">
                            {{position.name}}
                        </span>
                    </div>
                </ng-template>
            </kendo-grid-column>
        
            <kendo-grid-column field="managers" title="Managers" [width]="150">
                <ng-template kendoGridCellTemplate let-dataItem >
                    <div class="grid-cell-vertical">
                        <span *ngFor="let manager of dataItem.managers" class="manager">
                            {{manager.firstName}} {{manager.lastName}}
                        </span>
                    </div>
                </ng-template>
            </kendo-grid-column>
        
            <kendo-grid-column field="workLocations" title="Work Locations" [width]="200">
                <ng-template kendoGridCellTemplate let-dataItem >
                    <div class="grid-cell-vertical">
                        <span *ngFor="let workLocation of dataItem.workLocations" class="workLocation">
                            {{workLocation.name}}
                        </span>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="basicHolidayEntitlement" title="Basic Holiday Entitlement" [width]="200"></kendo-grid-column>
            <kendo-grid-column field="holidayCarryoverEntitlement" title="Holiday Carryover Entitlement" [width]="200"></kendo-grid-column>
            <kendo-grid-column field="serviceAward" title="Service Award" [width]="200"></kendo-grid-column>
            <kendo-grid-column field="otherAdjustments" title="Other Adjustments" [width]="200"></kendo-grid-column>
            <kendo-grid-column field="totalHolidayBooked" title="Total Holiday Booked" [width]="200"></kendo-grid-column>
            <kendo-grid-column field="totalHolidayTaken" title="Total Holiday Taken" [width]="200"></kendo-grid-column>
            <kendo-grid-column field="basicHolidayRemaining" title="Basic holiday remaining" [width]="200"></kendo-grid-column>
            <kendo-grid-column field="carryoverRemaining" title="Carryover remaining" [width]="200"></kendo-grid-column>
            <kendo-grid-column field="totalOutstandingRequests" title="Total outstanding requests" [width]="200"></kendo-grid-column>
            <kendo-grid-column field="TOILClaimed" title="TOIL claimed" [width]="200"></kendo-grid-column>
            <kendo-grid-column field="TOILUsed" title="TOIL used" [width]="200"></kendo-grid-column>
            <kendo-grid-column field="TOILAvailable" title="TOIL available" [width]="200"></kendo-grid-column>
        </kendo-grid>

        <ng-container *ngIf="selectedEmployee; else noSelectedEmployee">
            <div class="selected-employee-details">
                <h6>{{selectedEmployee.firstName}} {{selectedEmployee.lastName}}</h6>
                <div class="content">
                    <app-holidays-bar-chart [employee]="selectedEmployee"></app-holidays-bar-chart>
                    <app-toil-pie-chart [employee]="selectedEmployee"></app-toil-pie-chart>
                </div>
            </div>
        </ng-container>

        <ng-template #noSelectedEmployee>
            <div class="selected-employee-details">
                <h6>No employee selected</h6>
                <div>Select an employee to see their holiday details</div>
            </div>
        </ng-template>
    </mat-card-content>
</mat-card>