import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { db_tables } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { Holiday } from '@app/modules/statutory-holidays/models/statutory-holidays.model';
import { StatutoryHolidaysService } from '@app/modules/statutory-holidays/services/statutory-holidays.service';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TimeOffDetail } from '../../models/leave-request.model';
import { EmployeeLeaveService } from '../../services/employee-leave.service';

@Component({
  selector: 'app-employee-stat-holiday-group',
  templateUrl: './employee-stat-holiday-group.component.html',
  styleUrls: ['./employee-stat-holiday-group.component.scss']
})
export class EmployeeStatHolidayGroupComponent implements OnInit {
  @Input() employeeId: string;
  @Input() targetEmployeeTablePermissions: EmployeeTableSecurity[];
  statutoryHolidayGroup$
  timeOffDetail: TimeOffDetail;
  isLoading: boolean = true;
  holidays$: Observable<PagedData<Holiday>>;
  formId: string = 'frm_Pw8VpOdn2V0R0u';
  dialogRef: any;
  public gridDataResult: GridDataResult = {
    data: [],
    total: 0,
  };
  public pageSize: number = 20;
  public skip: number = 0;

  public columns: any[] = [
    { field: "name", title: "name", localizationCode: "StatutoryHolidayGroup-Name" },
    { field: "startDate", title: "start Date", localizationCode: "StatutoryHolidayGroup-StartDate", type: "date" },
    { field: "endDate", title: "end Date", localizationCode: "StatutoryHolidayGroup-EndDate", type: "date" },
    { field: "hourOverride", title: "hour Override", localizationCode: "StatutoryHolidayGroup-HourOverride" },
  ];

  filterCategories: any[] = [
    { field: "name", title: this.translate.instant("StatutoryHolidayGroup-Name"), type: "String", dataType: 'String'},
    { field: "startDate", title: this.translate.instant("StatutoryHolidayGroup-StartDate"), type: "date", dataType: 'Date'},
    { field: "endDate", title: this.translate.instant("StatutoryHolidayGroup-EndDate"), type: "date", dataType: 'Date'},
    { field: "hourOverride", title: this.translate.instant("StatutoryHolidayGroup-HourOverride"), type: "String", dataType: 'String'},
  ];

  sortableColumns: any[] = [
    { field: "name", sortValue: "name"},
    { field: "startDate", sortValue: "startDate"},
    { field: "endDate", sortValue: "endDate"},
    { field: "hourOverride", sortValue: "hourOverride"},
  ];
  filterString: string;
  searchValue: string;
  searchFilterString: string;
  getHolidayDaysRequest: any;
  sortString: string;

  constructor(
    private employeeLeaveService: EmployeeLeaveService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private statutoryHolidaysService: StatutoryHolidaysService,
  ) { }

  ngOnInit(): void {
    this.getTimeOffDetail();
  }

  get tableReadAccessObj() {
    return {
      tableId: db_tables.TimeOffDetail,
      permission: 'read',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableCreateAccessObj() {
    return {
      tableId: db_tables.TimeOffDetail,
      permission: 'create',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableUpdateAccessObj() {
    return {
      tableId: db_tables.TimeOffDetail,
      permission: 'update',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableDeleteAccessObj() {
    return {
      tableId: db_tables.TimeOffDetail,
      permission: 'delete',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  getTimeOffDetail() {
    this.isLoading = true;

    this.employeeLeaveService.getTimeOffDetail(this.employeeId)
    .pipe(
        finalize(() => {
            this.isLoading = false;
        })
    )
    .subscribe(
        res => {
            this.timeOffDetail = res;
            this.getHolidayDays();
        }
    );
  }

  getHolidayDays() {
    let filter = `StatutoryHolidayGroup.id = "${this.timeOffDetail?.statutoryHolidayGroup?.id}"`;

    if(this.searchFilterString) {
      filter = `(${filter}) AND ${this.searchFilterString}`;
    }

    if(this.filterString) {
      filter = `(${filter}) AND ${this.filterString}`;
    }

    this.getHolidayDaysRequest = this.statutoryHolidaysService.getHolidayDays(this.skip, String(this.pageSize), this.sortString, filter)
    .pipe(
        finalize(() => this.isLoading = false)
    )
    .subscribe(
        res => {
          this.gridDataResult = {
              data: res.data,
              total: res.total,
          };
        }
    );
  }

  openAssignStatHolidayGroupDialog(){
    let formData = {
      asOf: moment().format(),
      changeReason: '',
      changeReasonComments: '',
      statutoryHolidayGroup: this.timeOffDetail ? this.timeOffDetail.statutoryHolidayGroup?.id : null
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formTitle: `${this.translate.instant('AssignStatutoryHolidayGroup')}`,
      formId: this.formId,
      formData: formData
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      this.save(event)
    });
  }

  save(formData: any){
    this.overlayService.show();

      this.employeeLeaveService.updateTimeOffDetail(this.employeeId, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getTimeOffDetail();
        }
      );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.getHolidayDays();
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;
    this.getHolidayDays();
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;

    let variants = this.searchValue.split(' ').filter(value => value);
    this.searchFilterString = '';
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `(name like "${variant}")`;
        }
    });

    this.getHolidayDaysRequest.unsubscribe();
    this.getHolidayDays();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getHolidayDays();
  }
}
