<app-benefits>
    <mat-card>
        <mat-card-title class="header">
            <h4 appLocalizationPopupDirective localizationCode="BenefitGroup">{{ 'BenefitGroup' | translate: {Default: "Group"} }}</h4>

            <div class="buttons-container" *ngIf="form">
                <button mat-raised-button routerLink="{{routes.BENEFITS}}{{routes.GROUPS}}">{{ 'BenefitReturnToGroupsList' | translate: {Default: "Return To Groups List"} }}</button> 
                <button mat-raised-button setColor="primaryColour"  appPreventDoubleClick (throttledClick)="save()" [disabled]="!form.valid">{{ 'Save' | translate: {Default: "Save"} }}</button> 
            </div>
        </mat-card-title>
        <ng-container *ngIf="form === undefined">
            <ngx-skeleton-loader
                count="5"
                [theme]="{ 
                    'height.px': 50
                }"
            ></ngx-skeleton-loader>
        </ng-container>
        <mat-card-content>
            
            <div class="group-details-form">

                <form class="form" *ngIf="form" [formGroup]="form">
                
                    <div class="form-section">
                        <div class="form-section-title">
                            <h6>{{ 'BenefitGroupNameLocalizations' | translate: {Default: "Name Localizations"} }}</h6>
                
                            <button mat-stroked-button setColor="primaryColour" color="primary" (click)="addNewLocalization()">
                                <mat-icon class="material-icons-outlined">add</mat-icon> {{ 'BenefitGroupAddNameLocalization' | translate: {Default: "Add Name Localization"} }}
                            </button>
                        </div>
                        <div class="form-section-contents">
                            <div class="section-row">
                                <ng-container formArrayName="name">
                                    <ng-container *ngFor="let localizationForm of localizations.controls; let i = index">
                                        <div class="form-row">
                                            <div [formGroup]="localizationForm">
                                                <mat-form-field appearance="fill">
                                                    <mat-label>
                                                        {{ 'BenefitGroupSelectLanguage' | translate: {Default: "Select Language"} }}
                                                    </mat-label>
                        
                                                    <mat-select disableOptionCentering formControlName="culture">
                                                        <mat-option *ngFor="let culture of cultures" [value]="culture.id">{{culture.text}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                        
                                                <mat-form-field appearance="fill">
                                                    <mat-label>
                                                        {{ 'BenefitGroupName' | translate: {Default: "Name"} }}
                                                    </mat-label>
                        
                                                    <input matInput
                                                        formControlName="text">
                                                </mat-form-field>
                                            </div>
                                            <mat-icon class="material-icons-outlined" (click)="deleteLocalization(i)" matTooltip="Delete">delete</mat-icon> 
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                
                    <div class="form-section">
                        <div class="form-section-title">
                            <h6></h6>
                        </div>
                        <div class="form-section-contents">
                            <div class="section-row">

                                <mat-form-field appearance="fill">
                                    <mat-label>
                                        {{ 'BenefitGroupDeductionFrequency' | translate: {Default: "Deduction Frequency"} }}
                                    </mat-label>
                            
                                    <mat-select disableOptionCentering formControlName="deductionFrequency">
                                        <ng-container *ngIf="deductionFrequencyOptions | async as deductionFrequencyOptions">
                                            <mat-option></mat-option>
                                            <mat-option 
                                                *ngFor="let option of deductionFrequencyOptions" 
                                                [value]="option.id">
                                                    {{option.name}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                
                </form>
            </div>

        
            <app-benefit-group-plans *ngIf="group !== undefined" [group]="group"></app-benefit-group-plans>
            
            
        </mat-card-content>
    </mat-card>

</app-benefits>
