<mat-card>
    <kendo-grid
        [data]="query | async"
        [loading]="isLoading"
        [skip]="state.skip"
        [pageSize]="state.take"
        scrollable="virtual"
        [rowHeight]="36"
        [height]="750"
        (pageChange)="pageChange($event)"
        (excelExport)="onExcelExport($event)"
    >

        <ng-template kendoGridToolbarTemplate>
            <button class="export-to-excel-button" kendoGridExcelCommand>
                <img
                    class="ms-icon"
                    src="assets/icons/excel-icon.svg"
                    style="width: 35px;
                            height: 35px;
                            vertical-align: middle;">
                {{ 'ExportToExcel' | translate: {Default: "Export to Excel"} }}
            </button>

            <div class="k-mb-2">
                <mat-slide-toggle setColor="primaryColour" [checked]="filterInactive" disabled="false"
                                  (change)="toggleFilterInactive($event)">{{ 'ActiveOnly' | translate: {Default: "Active Only"} }}</mat-slide-toggle>
            </div>

            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'StartDate' | translate: {Default: "Start Date"} }}
                </mat-label>

                <input matInput [matDatepicker]="startDatepicker" (dateChange)="changeFromDate($event)">
                <span fxLayout="row" matSuffix>
                    <mat-datepicker-toggle [for]="startDatepicker"></mat-datepicker-toggle>
                </span>
                <mat-datepicker #startDatepicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'EndDate' | translate: {Default: "End Date"} }}
                </mat-label>

                <input matInput [matDatepicker]="endDatePicker" (dateChange)="changeToDate($event)">
                <span fxLayout="row" matSuffix>
                    <mat-datepicker-toggle [for]="endDatePicker" ></mat-datepicker-toggle>
                </span>
                <mat-datepicker #endDatePicker></mat-datepicker>
            </mat-form-field>
        </ng-template>


        <kendo-grid-column
            *ngFor="let col of columns"
            [field]="col.field"
            [title]="col.title"
            [width]="300"
        ></kendo-grid-column>

        <kendo-grid-excel
            fileName="AbsenceReport.xlsx"
            [fetchData]="allData"
        ></kendo-grid-excel>
    </kendo-grid>
</mat-card>

