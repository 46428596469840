import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {api_routes} from '@app/consts';
import {BenefitsGroupPlan, BenefitsGroupPlanSubmit, BenefitsGroupPlanVerbose} from '../models/benefits-group-plans.model';
import {EnvironmentService} from "@app/core/services/environment.service";

const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});
@Injectable({
  providedIn: 'root'
})
export class BenefitsGroupPlanService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getGroupPlans(groupId: string): Observable<{data: BenefitsGroupPlan[]}> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.BENEFITS}/${api_routes.GROUPS}/${groupId}/${api_routes.PLANS}`, {
      headers: headers
    })
  }

  getGroupPlan(groupId: string, planId: string): Observable<BenefitsGroupPlanVerbose> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.BENEFITS}/${api_routes.GROUPS}/${groupId}/${api_routes.PLANS}/${planId}`, {
      headers: headers
    })
  }

  addGroupPlan(groupId: string, planId: string, planCoverageSubmit: BenefitsGroupPlanSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.BENEFITS}/${api_routes.GROUPS}/${groupId}/${api_routes.PLANS}/${planId}`, planCoverageSubmit,{
      headers: headers
    })
  }

  updateGroupPlan(groupId: string, planId: string, planCoverageSubmit: BenefitsGroupPlanSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.BENEFITS}/${api_routes.GROUPS}/${groupId}/${api_routes.PLANS}/${planId}`, planCoverageSubmit,{
      headers: headers
    })
  }

  deleteGroupPlan(groupId: string, planId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.BENEFITS}/${api_routes.GROUPS}/${groupId}/${api_routes.PLANS}/${planId}`, {
      headers: headers
    })
  }

  getGroupPlanEligibilities(): Observable<{id: string, name: string}[]> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.BENEFITS}/${api_routes.GROUP_PLAN_ELIGIBILITIES}`, {
      headers: headers
    })
  }
}
