import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormElement } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { Position } from '@app/modules/positions/models/positions.model';
import { PositionsService } from '@app/modules/positions/services/positions.service';
import { Observable } from 'rxjs';
import { SearchPositionDialogComponent } from './components/search-position-dialog/search-position-dialog.component';
import {finalize} from 'rxjs/operators';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { EmployeeSecurityService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-security/services/employee-security.service';

@Component({
  selector: 'app-position-selection-form-field',
  templateUrl: './position-selection-form-field.component.html',
  styleUrls: ['./position-selection-form-field.component.scss']
})
export class PositionSelectionFormFieldComponent implements OnInit {
  @Input() formElement: FormElement;
  @Input() parentGroup: UntypedFormGroup;
  @Input() readOnly: boolean;
  @ViewChild('positionSelect') positionSelect;
  positionOptions: Position[] = [];
  isLoading: boolean;
  user$: any;

  constructor(
    private positionsService: PositionsService,
    private oidcAuthService: OidcAuthService,
    private employeeSecurityService: EmployeeSecurityService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.user$ = this.oidcAuthService.userProfile;

    if(this.parentGroup.controls[this.formElement.formControl].value !== null){
      this.getPosition();
    }
  }

  getPosition() {
    this.isLoading = true;

    this.positionsService.getPosition(this.parentGroup.controls[this.formElement.formControl].value)
    .pipe(
        finalize(() => this.isLoading = false)
    )
    .subscribe(
        res => {
            let currentCultureName = res.name.find( n => n.culture === this.employeeSecurityService.currentUserCulture?.id);

            currentCultureName === undefined ? res.name = res.name[0]?.text : res.name = currentCultureName?.text;

            this.positionOptions.push(res);
        }
    );
}

  openAssignPolicyDialog() {
    this.positionSelect.close();

    if(!this.readOnly){
      const dialogConfig = new MatDialogConfig();
  
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
  
      const dialogRef = this.dialog.open(SearchPositionDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        data => {
          if (data !== undefined) {
            this.setValue(data)
          }
        }
      );
    }
  }

  clearSelectedPosition() {
    this.positionSelect.close();
    this.parentGroup.controls[this.formElement.formControl].setValue(null);
  }

  setValue(selectedPosition: Position) {
    this.parentGroup.controls[this.formElement.formControl].setValue(selectedPosition.id);
    this.getPosition();
  }

}
