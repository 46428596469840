<app-site-settings-styling>
    <mat-card class="mat-elevation-z0">
      <mat-card-title class="header">
          <h5 appLocalizationPopupDirective localizationCode="EditCompanyLogo">{{ 'EditCompanyLogo' | translate: {Default: "EditCompanyLogo"} }}</h5>
          <div class="k-flex-grow"></div>
          <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
      </mat-card-title>
  
      <mat-card-content>

        <div class="left">
          <app-edit-company-logo-details></app-edit-company-logo-details>
  
          <app-edit-company-logo-file></app-edit-company-logo-file>
        </div>

        <app-data-grid-comp
          *ngIf="companyLogoId"
          category="WorkLocationsUsingThisLogo"
          class="work-locations"
          [columns]="columns"
          hideActionButtons="true"
          [gridDataResult]="gridDataResult"
          [isLoading]="isLoadingWorkLocations"
          [pageSize]="pageSize"
          [skip]="skip"
          searchable="true"
          [filterCategories]="columns"
          [filterToggleDetails]="filterToggleDetails"
          [sortable]="{
            mode: 'multiple'
          }"
          [sortableColumns]="sortableColumns"
          (emitToggleFilter)="updateFilterToggleDetails($event)"
          (emitPageChangeEvent)="pageChange($event)"
          (emitSearchEvent)="search($event)"
          (emitFilterEvent)="filterCallback($event)"
          (emitSortEvent)="sortChange($event)"
        ></app-data-grid-comp>

      </mat-card-content>
    </mat-card>
  
  </app-site-settings-styling>
  
  