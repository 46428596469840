import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormElement } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { SelectStylingColoursDialogComponent } from './components/select-styling-colours-dialog/select-styling-colours-dialog.component';
import { finalize } from 'rxjs';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { StylingColour } from '@app/modules/site-settings-styling/components/styling-colours/models/styling-colours.model';
import { StylingColoursService } from '@app/modules/site-settings-styling/components/styling-colours/services/styling-colours.service';

@Component({
  selector: 'app-styling-colours-form-field',
  templateUrl: './styling-colours-form-field.component.html',
  styleUrls: ['./styling-colours-form-field.component.scss']
})
export class StylingColoursFormFieldComponent implements OnInit {

  @Input() formElement: FormElement;
  @Input() parentGroup: UntypedFormGroup;
  @Input() readOnly: boolean;
  @ViewChild('stylingColoursSelect') stylingColoursSelect;
  stylingColoursOptions: StylingColour[] = [];
  isLoading: boolean;
  user$: any;
  filterString: string;

  constructor(
    private stylingColoursService: StylingColoursService,
    private oidcAuthService: OidcAuthService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.user$ = this.oidcAuthService.userProfile;

    if(this.parentGroup.controls[this.formElement.formControl].value !== null && this.parentGroup.controls[this.formElement.formControl].value !== undefined){
      this.getStylingColour();
    }
  }

  getStylingColour() {
    this.isLoading = true;

    if(this.parentGroup.controls[this.formElement.formControl].value !== undefined){
      this.filterString = `(id = "${this.parentGroup.controls[this.formElement.formControl].value}")`
    }

    this.stylingColoursService.getStylingColours(0, 1, null, this.filterString)
    .pipe(
      finalize(() => this.isLoading = false)
    )
    .subscribe(
      (res) => {
        this.stylingColoursOptions = res.data
      }
    );
  }

  openSelectStylingColourDialog() {
    this.stylingColoursSelect.close();

    if(!this.readOnly){
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      const dialogRef = this.dialog.open(SelectStylingColoursDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        data => {
          if (data !== undefined) {
            this.setValue(data)
          }
        }
      );
    }
  }

  clearSelectedPosition() {
    this.stylingColoursSelect.close();
    this.parentGroup.controls[this.formElement.formControl].setValue(null);
  }

  setValue(selectedStylingColour: StylingColour) {
    this.parentGroup.controls[this.formElement.formControl].setValue(selectedStylingColour.id);
    this.getStylingColour();
  }

}
