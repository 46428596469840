import { Component, OnInit } from '@angular/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { Position, } from '@app/modules/positions/models/positions.model';
import { PositionsService } from '@app/modules/positions/services/positions.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { finalize } from 'rxjs/operators';
import { TableListFieldOption } from '@app/modules/lookup/models/lookup.model';
import { GenerateShiftsSubmit } from '../../models/generate-schedules.model';
import { GenerateScheduleService } from '../../services/generate-schedule.service';
import { FeatureService } from '@app/core/services/feature.service';
import { features } from '@app/consts';
import { SecurityProtectedBase } from '@app/shared/components/security-protected/security-protected';
import * as moment from 'moment';

@Component({
  selector: 'app-run-job-form',
  templateUrl: './run-job-form.component.html',
  styleUrls: ['./run-job-form.component.scss']
})
export class RunJobFormComponent extends SecurityProtectedBase implements OnInit {
    employeeId: string;
    employeePositionId: string = null;
    positionId: string = null;
    employmentRecordId: string;
    positions: Position[] = [];
    selectedPositions: Position[] = [];
    positionsSubmitted: boolean = false;
    startDate: string = null;
    endDate: string = null;
    startReasons: TableListFieldOption[];
    startReason: string;
    endReasons: TableListFieldOption[];
    endReason: string;
    searchFilterString: string;
    searchValue: string;
    positionsRequest: any;
    loadingPositions: boolean;

    constructor(
      private overlayService: OverlayService,
      private positionsService: PositionsService,
      private generateScheduleService: GenerateScheduleService,
      private snackbarService: SnackbarService,
      featureService: FeatureService,
    ) {
      super(featureService, features.SCHEDULES)
    }

    ngOnInit(): void {
        this.getPositions();
    }

    get filterString() {
      let today = moment(new Date()).format('YYYY-MM-DD')

      if(this.searchFilterString !== undefined) {
        return `(EndDate > "${today}") AND ${this.searchFilterString}`;
      }

      return `(EndDate > "${today}")`;
    }

    getPositions() {
      this.loadingPositions = true;

      this.positionsRequest = this.positionsService.getPositions(0, '10000', this.searchFilterString)
      .pipe(
          finalize(() => this.loadingPositions = false)
      )
      .subscribe(
          res => {
              this.positions = res.data;

              this.positions = this.positions.filter(
                position => {
                  if(position.endDate === null) {
                    return true;
                  }
                  else if(moment(position.endDate).isAfter(moment())) {
                    return true;
                  }
                  else {
                    return false;
                  }
                }
              )
          }
      );
    }

    toggleSelectAll(event) {
      if(event.checked) {
        this.selectedPositions = this.positions.map(obj => ({...obj}));
      }
      else {
        this.selectedPositions = [];
      }
    }

    get selectAll() {
      if(this.selectedPositions.length === this.positions.length){
        return true;
      }
    }

    get isFormValid() {
      if(this.startDate !== null && this.endDate !== null && this.selectedPositions.length > 0) {
        return true;
      }

      return false;
    }

    addPosition(position: Position) {
        this.selectedPositions.push(position);
    }

    removePosition(position: Position) {
        const index = this.selectedPositions.indexOf(position);
        if (index > -1) {
            this.selectedPositions.splice(index, 1);
        }
    }

    search() {
        this.searchFilterString = `(name like "${this.searchValue}")`;
        this.positionsRequest.unsubscribe();
        this.getPositions();
    }

    save() {
      let positionIds = this.selectedPositions.map(position => position.id);

      let generateShiftsSubmit: GenerateShiftsSubmit = {
        positions: positionIds,
        from: this.startDate,
        to: this.endDate
      }

      this.overlayService.show();

      this.generateScheduleService.generateShifts(generateShiftsSubmit)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        res => {
          this.snackbarService.openSnackBar('Generating Shifts Job Started Successfully', 'clear', 'success');
        }
      )
    }




}
