import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';

@Component({
  selector: 'app-new-api-user-dialog',
  templateUrl: './new-api-user-dialog.component.html',
  styleUrls: ['./new-api-user-dialog.component.scss']
})
export class NewApiUserDialogComponent implements OnInit {
  name: string = '';
  username: string = '';
  newPassword: string = '';
  newPasswordConfirm: string = '';

  constructor(
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private dialogRef: MatDialogRef<NewApiUserDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  save() {
    this.overlayService.show();

    setTimeout(() => {
      this.overlayService.hide();
      this.snackbarService.openSnackBar('API User created', 'clear', 'success');
      this.dialogRef.close(
        {
          'name': this.name,
          'username': this.username,
          'apiUserId': '34563475'
        }
      );
    }, 2000);
  }

  close() {
    this.dialogRef.close(false);
  }

}
