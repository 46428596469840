import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormElement } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { ReviewRatingStatus } from '@app/modules/performance/components/review-ratings/models/review-rating.model';
import { ReviewRatingsService } from '@app/modules/performance/components/review-ratings/services/review-ratings.service';
import { from, Observable } from 'rxjs';

@Component({
  selector: 'app-review-rating-status-form-field',
  templateUrl: './review-rating-status-form-field.component.html',
  styleUrls: ['./review-rating-status-form-field.component.scss']
})
export class ReviewRatingStatusFormFieldComponent implements OnInit {
  @Input() formElement: FormElement;
  @Input() parentGroup: UntypedFormGroup;
  @Input() readOnly: boolean;
  isLoading: boolean;
  user$: any;
  filterString: string;
  options: Observable<PagedData<ReviewRatingStatus>>;

  constructor(
    private reviewRatingsService: ReviewRatingsService,
  ) { }

  ngOnInit(): void {
    this.options = from(this.reviewRatingsService.getReviewRatingStatuses('100', 0))
  }

}

