import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataBindingDirective, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { finalize } from 'rxjs/operators';
import { EmployeeSecurityService } from '../../services/employee-security.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-employee-login-history',
  templateUrl: './employee-login-history.component.html',
  styleUrls: ['./employee-login-history.component.scss']
})
export class EmployeeLoginHistoryComponent implements OnInit {
  @Input() employeeID: string;
  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public mySelection: string[] = [];
  pageSize: number = 20;
  skip: number = 0;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  isLoading: boolean;
  public columns: any[] = [
    { field: "eventType", title: "LoginHistory-EventType" },
    { field: "localIpAddress", title: "LoginHistory-LocalIpAddress" },
    { field: "remoteIpAddress", title: "LoginHistory-RemoteIpAddress" },
    { field: "name", title: "LoginHistory-Name" },
    { field: "endPoint", title: "LoginHistory-EndPoint" },
  ];

  constructor(
    public translate: TranslateService,
    private employeeSecurityService: EmployeeSecurityService
  ) { }

  ngOnInit(): void {
    this.getEmployeeOrganizations();
  }

  getEmployeeOrganizations(): void {
    this.isLoading = true;

    this.employeeSecurityService.getEmployeeLoginHistory(this.employeeID, this.skip, String(this.pageSize))
    .pipe(
        finalize(()=>{ this.isLoading = false; })
    )
    .subscribe(
      res => {
        this.gridDataResult = {
          data: res.data,
          total: res.total,
        }
        this.gridData = res.data;
      }
    )
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.getEmployeeOrganizations();
  }

}
