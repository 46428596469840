import {Component, EventEmitter, Inject, Input, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialogConfig, MatDialog} from '@angular/material/dialog';
import {SnackbarService} from '@app/core/services/snackbar.service';
import {CultureService} from '@app/core/services/system-language/culture.service';
import {OverlayService} from '@app/shared/components/overlay/overlay.service';
import {Culture} from '@app/shared/models/system-language/culture.model';
import {TimeOffPolicy} from '@app/modules/lookup/models/time-off-policy.model';
import {TimeOffStartAtUnit} from '@app/modules/lookup/models/time-off-start-at-unit.model';
import {TimeOffAccrualRateInterval} from '@app/modules/lookup/models/time-off-accrual-rate-interval.model';
import {TimeOffFirstAccrualType} from '@app/modules/lookup/models/time-off-first-accrual-type.model';
import {TimeOffPolicySchedule} from '@app/modules/lookup/models/time-off-policy-schedule.model';
import {ConfirmDialogComponent} from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import {TimeOff} from '@app/modules/lookup/models/time-off.model';
import {TimeOffService} from '@app/modules/time-off/services/time-off.service';
import {RefreshListener} from '@app/shared/models/refresh-listener';
import {TimeOffListVariant} from '@app/modules/lookup/models/time-off-list-variant.model';
import { MatRadioChange } from '@angular/material/radio';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'time-off-policy',
    templateUrl: './time-off-policy.component.html',
    styleUrls: ['./time-off-policy.component.scss']
})
export class TimeOffPolicyComponent implements OnInit {

    @Input() timeOffType: TimeOffListVariant;
    @Input() timeOffPolicyId: string;
    @Input() public timeOffTypeList: TimeOff[] = [];
    @Input() refreshListener: RefreshListener;

    isLoading: boolean;
    timeOffPolicy: TimeOffPolicy;
    policyForm: UntypedFormGroup;
    scheduleForm: UntypedFormGroup;
    cultures: Culture[];
    timeOffFirstAccrualTypeList: TimeOffFirstAccrualType[];
    timeOffStartAtUnitList: TimeOffStartAtUnit[];
    timeOffAccrualRateIntervalList: TimeOffAccrualRateInterval[];
    timeOffAccruedAtList: TimeOffAccrualRateInterval[];
    timeOffPolicySchedules: TimeOffPolicySchedule[] = [];
    editTimeOffPolicySchedule: TimeOffPolicySchedule;
    editOrCreateScheduleMode: boolean = false;
    hideIntervalStartDate: boolean = true;
    hideBiMonthlyFormFields: boolean = true;
    hideYearlyMonthDay: boolean = true;
    hideMonthDetails: boolean = true;
    numberOfDays = Array.from({length: 31}, (_, i) => i + 1);
    months = [
        { value: 1, text: "January" },
        { value: 2, text: "February" },
        { value: 3, text: "March" },
        { value: 4, text: "April" },
        { value: 5, text: "May" },
        { value: 6, text: "June" },
        { value: 7, text: "July" },
        { value: 8, text: "August" },
        { value: 9, text: "September" },
        { value: 10, text: "October" },
        { value: 11, text: "November" },
        { value: 12, text: "December" }
    ];

    constructor(
        public translate: TranslateService,
        private snackbarService: SnackbarService,
        private fb: UntypedFormBuilder,
        private overlayService: OverlayService,
        private timeOffService: TimeOffService,
        private dialog: MatDialog,
        private cultureService: CultureService,
    ) {
    }

    ngOnInit(): void {
        this.createPolicyForm();
        if (this.timeOffPolicyId) {
            this.getTimeOffPolicy(this.timeOffType.id, this.timeOffPolicyId);
        }
        this.cultureService.getCultures().subscribe(res => {
            this.cultures = res;
        });

        this.timeOffService.getTimeOffFirstAccrualTypes().subscribe(res => {
            this.timeOffFirstAccrualTypeList = res;
        });
        this.timeOffService.getTimeOffStartAtUnits().subscribe(res => {
            this.timeOffStartAtUnitList = res;
        });
        this.timeOffService.getTimeOffAccrualIntervals().subscribe(res => {
            this.timeOffAccrualRateIntervalList = res;
        });
        this.timeOffService.getTimeOffAccruedAt().subscribe(res => {
            this.timeOffAccruedAtList = res;
        });
    }

    getMonthText(monthValue): string {
        let month = this.months.filter( month => month.value === monthValue );

        if (month.length === 1) {
            return month[0].text;
        }
        else {
            return null;
        }
    }

    ordinalDateSuffix(d): string {
        if (d > 3 && d < 21) return 'th';
        if (d === 0 || d === null) return '';

        switch (d % 10) {
            case 1:  return "st";
            case 2:  return "nd";
            case 3:  return "rd";
            default: return "th";
        }
    }

    private getTimeOffPolicy(timeOffTypeId: string, timeOffPolicyId: string) {
        this.isLoading = true;
        this.timeOffService.getTimeOffPolicyById(timeOffTypeId, timeOffPolicyId)
            .subscribe(timeOffPolicy => {
                this.timeOffPolicy = timeOffPolicy;
                this.timeOffService.getTimeOffPolicySchedules(timeOffPolicy.id).subscribe(res => {
                    // TODO: fix once Brian sorts API
                    this.timeOffPolicySchedules = res.data;
                    this.createPolicyForm();
                    this.isLoading = false;
                });
            }, error => console.log(error));
    }

    clearDayAndMonth(event: MatRadioChange) {
        if(event.value === true) {
            this.policyForm.patchValue(
                {
                    carryOverMonth: null,
                    carryOverDay: null,
                }
            )
        }
    }


    createPolicyForm() {

        this.policyForm = this.fb.group({
            id: this.timeOffPolicy ? this.timeOffPolicy.id : null,
            localizations: this.fb.array([]),
            accruedAt: [this.timeOffPolicy?.accruedAt ? this.timeOffPolicy.accruedAt.id : null, Validators.required],
            carryOverMonth: [this.timeOffPolicy?.carryOverMonth ? this.timeOffPolicy.carryOverMonth : null, [Validators.maxLength(2), Validators.max(12), Validators.min(1)]],
            carryOverDay: [this.timeOffPolicy?.carryOverDay ? this.timeOffPolicy.carryOverDay : null, [Validators.maxLength(2), Validators.max(31), Validators.min(1)]],
            carryOverUseHireDate: [!this.timeOffPolicy?.carryOverUseHireDate ? false : true, Validators.required],
            fteProrated: [this.timeOffPolicy ? this.timeOffPolicy.fteProrated === true : false],
            showMaximumCarryOverToEndUser: [this.timeOffPolicy ? this.timeOffPolicy.showMaximumCarryOverToEndUser === true : false],
            showCarryOverDateToEndUser: [this.timeOffPolicy ? this.timeOffPolicy.showCarryOverDateToEndUser === true : false],
            showAccrualSchedule: [this.timeOffPolicy ? this.timeOffPolicy.showAccrualSchedule === true : false],
            prorateHireDate: [this.timeOffPolicy ? this.timeOffPolicy.prorateHireDate === true : false],
            timeOffType: [this.timeOffType ? this.timeOffType.id : null, Validators.required]
        });
        if (this.timeOffPolicy) {
            this.timeOffPolicy.name.forEach(
                localization => {
                    this.addNewLocalization(localization.culture, localization.text);
                }
            );
        } else {
            this.addNewLocalization();
        }
    }


    get localizations() {
        return this.policyForm.controls['localizations'] as UntypedFormArray;
    }

    //Create a textLocalization form group object to add to the localizations form array
    addNewLocalization(culture?: string, localization?: string) {
        const localizationForm = this.fb.group({
            culture: [culture || '', Validators.required],
            text: [localization || '', Validators.required]
        });

        this.localizations.push(localizationForm);
    }

    deleteLocalization(index: number) {
        this.localizations.removeAt(index);
    }

    savePolicy() {
        let timeOffPolicyData = {
            accruedAt: this.policyForm.value.accruedAt,
            fteProrated: this.policyForm.value.fteProrated,
            name: this.policyForm.value.localizations,
            carryOverMonth: this.policyForm.value.carryOverMonth,
            carryOverDay: this.policyForm.value.carryOverDay,
            carryOverUseHireDate: this.policyForm.value.carryOverUseHireDate,
            showMaximumCarryOverToEndUser: this.policyForm.value.showMaximumCarryOverToEndUser,
            showCarryOverDateToEndUser: this.policyForm.value.showCarryOverDateToEndUser,
            showAccrualSchedule: this.policyForm.value.showAccrualSchedule,
            prorateHireDate: this.policyForm.value.prorateHireDate,
        };

        this.isLoading = true;
        if (this.policyForm.value.id) {
            this.timeOffService.updateTimeOffPolicy(this.policyForm.value.timeOffType, this.policyForm.value.id, timeOffPolicyData)
                .subscribe(
                    (res) => {
                        this.isLoading = false;
                        this.snackbarService.openSnackBar('Time Off Policy saved successfully', 'clear', 'success');
                        this.refreshListener.refresh();
                    },
                    err => {
                        this.isLoading = false;
                        this.snackbarService.openSnackBar(err, 'clear', 'warn');
                    }
                );
        } else {
            this.timeOffService.createTimeOffPolicy(this.policyForm.value.timeOffType, timeOffPolicyData)
                .subscribe(
                    (res) => {
                        this.isLoading = false;
                        this.snackbarService.openSnackBar('Time Off Policy saved successfully', 'clear', 'success');
                        this.refreshListener.refresh();
                    },
                    err => {
                        this.isLoading = false;
                        this.snackbarService.openSnackBar(err, 'clear', 'warn');
                    }
                );
        }
    }

    close() {
        this.refreshListener.cancel();
    }


    createScheduleForm(editTimeOffPolicySchedule: TimeOffPolicySchedule) {
        this.scheduleForm = this.fb.group({
            id: editTimeOffPolicySchedule ? editTimeOffPolicySchedule?.id : null,
            startsAt: [editTimeOffPolicySchedule ? editTimeOffPolicySchedule?.startsAt : null, Validators.required],
            accrualRate: [editTimeOffPolicySchedule ? editTimeOffPolicySchedule?.accrualRate : null, Validators.required],
            accrualMaximum: [editTimeOffPolicySchedule ? editTimeOffPolicySchedule?.accrualMaximum : null],
            carryOverAmount: [editTimeOffPolicySchedule ? editTimeOffPolicySchedule?.carryOverAmount : null],
            accrualRateInterval: [editTimeOffPolicySchedule ? editTimeOffPolicySchedule?.accrualRateInterval?.id : null, Validators.required],
            intervalStartDate: [editTimeOffPolicySchedule ? editTimeOffPolicySchedule?.intervalStartDate : null],
            startAtUnit: [editTimeOffPolicySchedule ? editTimeOffPolicySchedule?.startAtUnit?.id : null],
            biMonthlyFirstDay: [editTimeOffPolicySchedule ? editTimeOffPolicySchedule?.biMonthlyFirstDay : null],
            biMonthlySecondDay: [editTimeOffPolicySchedule ? editTimeOffPolicySchedule?.biMonthlySecondDay : null],
            biMonthlySecondDayEndOfMonth: [editTimeOffPolicySchedule ? editTimeOffPolicySchedule?.biMonthlySecondDayEndOfMonth : null],
            yearlyMonth: [editTimeOffPolicySchedule ? editTimeOffPolicySchedule?.yearlyMonth : null],
            yearlyDay: [editTimeOffPolicySchedule ? editTimeOffPolicySchedule?.yearlyDay : null],
            monthlyDay: [editTimeOffPolicySchedule ? editTimeOffPolicySchedule?.monthlyDay : null],
            monthlyEndOfMonth: [editTimeOffPolicySchedule ? editTimeOffPolicySchedule?.monthlyEndOfMonth : null],
            monthlyOnHireDay: [editTimeOffPolicySchedule ? editTimeOffPolicySchedule?.monthlyOnHireDay : null],
            monthlyAccrualDayRadioValue: this.getMonthlyAccrualDayRadioValue(editTimeOffPolicySchedule)
        });

        // Add listener to accrual rate interval to hide/show the correct form fields
        this.scheduleForm.get('accrualRateInterval').valueChanges
        .subscribe(value => {
            if(value === 'EveryTwoWeeks') {
                this.clearBiMonthlyFields();
                this.clearYealyDayMonthFields();
                this.clearMonthlyFields();

                this.addIntervalStartDateFieldValidators();

                this.hideIntervalStartDate = false;
                this.hideBiMonthlyFormFields = true;
                this.hideYearlyMonthDay = true;
                this.hideMonthDetails = true;
            }
            else if(value === 'Month') {
                this.clearIntervalStartDateField();
                this.clearYealyDayMonthFields();
                this.clearBiMonthlyFields();

                this.addMonthlyFieldsValidators();

                this.scheduleForm.get('monthlyAccrualDayRadioValue').setValue('monthlyEndOfMonth');

                this.hideIntervalStartDate = true;
                this.hideBiMonthlyFormFields = true;
                this.hideYearlyMonthDay = true;
                this.hideMonthDetails = false;
            }
            else if(value === 'BiMonthly') {
                this.clearIntervalStartDateField();
                this.clearYealyDayMonthFields();
                this.clearMonthlyFields();

                this.addBiMonthlyFieldsValidators();

                this.hideIntervalStartDate = true;
                this.hideBiMonthlyFormFields = false;
                this.hideYearlyMonthDay = true;
                this.hideMonthDetails = true;
            }
            else if(value === 'Year') {
                this.clearIntervalStartDateField();
                this.clearBiMonthlyFields();
                this.clearMonthlyFields();

                this.addYearlydayMonthFieldValidators();

                this.hideIntervalStartDate = true;
                this.hideBiMonthlyFormFields = true;
                this.hideYearlyMonthDay = false;
                this.hideMonthDetails = true;
            }
            else {
                this.clearIntervalStartDateField();
                this.clearYealyDayMonthFields();
                this.clearBiMonthlyFields();
                this.clearMonthlyFields();

                this.hideIntervalStartDate = true;
                this.hideBiMonthlyFormFields = true;
                this.hideYearlyMonthDay = true;
                this.hideMonthDetails = true;
            }
        })

        // Add listener to biMonthlySecondDayEndOfMonth to enable/disable the correct form fields
        this.scheduleForm.get('biMonthlySecondDayEndOfMonth').valueChanges
        .subscribe(value => {
            let biMonthlySecondDayControl = this.scheduleForm.get('biMonthlySecondDay');

            if(value === true){
                biMonthlySecondDayControl.setValue(null);
                biMonthlySecondDayControl.disable();
            }
            else {
                biMonthlySecondDayControl.enable();
            }
        });

        // Add listener to monthlyAccrualDayRadioValue to set monthlyDay, monthlyEndOfMonth, monthlyOnHireDay values
        this.scheduleForm.get('monthlyAccrualDayRadioValue').valueChanges
        .subscribe(value => {
            let monthlyDayControl = this.scheduleForm.get('monthlyDay');
            let monthlyEndOfMonthControl = this.scheduleForm.get('monthlyEndOfMonth');
            let monthlyOnHireDayControl = this.scheduleForm.get('monthlyOnHireDay');

            if(value === 'monthlyDay'){
                // monthlyDayControl.setValue(null);
                monthlyEndOfMonthControl.setValue(null);
                monthlyOnHireDayControl.setValue(null);

                monthlyDayControl.setValidators(Validators.required);
                monthlyDayControl.updateValueAndValidity();
            }
            else if(value === 'monthlyEndOfMonth') {
                monthlyDayControl.setValue(null);
                monthlyEndOfMonthControl.setValue(true);
                monthlyOnHireDayControl.setValue(null);

                monthlyDayControl.clearValidators();
                monthlyDayControl.updateValueAndValidity();
            }
            else if(value === 'monthlyOnHireDay') {
                monthlyDayControl.setValue(null);
                monthlyEndOfMonthControl.setValue(null);
                monthlyOnHireDayControl.setValue(true);

                monthlyDayControl.clearValidators();
                monthlyDayControl.updateValueAndValidity();
            }

        });

        // Add listener to yearlyMonth to set correct number of days for each month
        this.scheduleForm.get('yearlyMonth').valueChanges
        .subscribe(value => {
            let yearlyMonth = this.scheduleForm.get('yearlyMonth');
            let daysInMonth = 31;

            if (yearlyMonth.value === 2) {
                daysInMonth = 28;
            } else if ([4, 6, 9, 11].includes(yearlyMonth.value)) {
                daysInMonth = 30;
            }

            this.numberOfDays = Array.from({length: daysInMonth}, (_, i) => i + 1);
        });

        if(editTimeOffPolicySchedule?.intervalStartDate !== null){
            this.clearBiMonthlyFields();
            this.clearYealyDayMonthFields();
            this.clearMonthlyFields();
            this.addIntervalStartDateFieldValidators();

            this.hideIntervalStartDate = false;
            this.hideBiMonthlyFormFields = true;
            this.hideYearlyMonthDay = true;
            this.hideMonthDetails = true;
        }
        else if(editTimeOffPolicySchedule?.accrualRateInterval?.id === 'BiMonthly') {
            this.clearIntervalStartDateField();
            this.clearYealyDayMonthFields();
            this.clearMonthlyFields();
            this.addBiMonthlyFieldsValidators();

            this.hideIntervalStartDate = true;
            this.hideBiMonthlyFormFields = false;
            this.hideYearlyMonthDay = true;
            this.hideMonthDetails = true;

            if(editTimeOffPolicySchedule.biMonthlySecondDayEndOfMonth === true){
                this.scheduleForm.get('biMonthlySecondDay').setValue(null);
                this.scheduleForm.get('biMonthlySecondDay').disable();
            }
        }
        else if(editTimeOffPolicySchedule?.accrualRateInterval?.id === 'Month') {
            this.clearIntervalStartDateField();
            this.clearYealyDayMonthFields();
            this.clearBiMonthlyFields();
            this.addMonthlyFieldsValidators();

            this.hideIntervalStartDate = true;
            this.hideBiMonthlyFormFields = true;
            this.hideYearlyMonthDay = true;
            this.hideMonthDetails = false;
        }
        else if(editTimeOffPolicySchedule?.accrualRateInterval?.id === 'Year') {
            this.clearBiMonthlyFields();
            this.clearIntervalStartDateField();
            this.clearMonthlyFields();
            this.addYearlydayMonthFieldValidators();

            this.hideIntervalStartDate = true;
            this.hideBiMonthlyFormFields = true;
            this.hideYearlyMonthDay = false;
            this.hideMonthDetails = true;
        }
    }

    getMonthlyAccrualDayRadioValue(editTimeOffPolicySchedule: TimeOffPolicySchedule) {
        if(editTimeOffPolicySchedule?.monthlyDay){
            return 'monthlyDay';
        }
        else if(editTimeOffPolicySchedule?.monthlyEndOfMonth === true) {
            return 'monthlyEndOfMonth';
        }
        else {
            return 'monthlyOnHireDay';
        }
    }

    // convenience getter for easy access to form fields
    get scheduleFormControls() { return this.scheduleForm.controls; }

    clearBiMonthlyFields() {
        let controls = [
            this.scheduleForm.get('biMonthlyFirstDay'),
            this.scheduleForm.get('biMonthlySecondDay'),
            this.scheduleForm.get('biMonthlySecondDayEndOfMonth')
        ];

        controls.forEach(
            control => {
                control.clearValidators();
                control.setValue(null);
                control.updateValueAndValidity();
            }
        )
    }

    clearMonthlyFields() {
        let controls = [
            this.scheduleForm.get('monthlyDay'),
            this.scheduleForm.get('monthlyEndOfMonth'),
            this.scheduleForm.get('monthlyOnHireDay'),
            this.scheduleForm.get('monthlyAccrualDayRadioValue')
        ];

        controls.forEach(
            control => {
                control.clearValidators();
                control.setValue(null);
                control.updateValueAndValidity();
            }
        )
    }

    addYearlydayMonthFieldValidators() {
        let controls = [
            this.scheduleForm.get('yearlyMonth'),
            this.scheduleForm.get('yearlyDay'),
        ];

        controls.forEach(
            control => {
                control.setValidators(Validators.required);
                control.updateValueAndValidity();
            }
        )
    }

    clearYealyDayMonthFields() {
        let controls = [
            this.scheduleForm.get('yearlyMonth'),
            this.scheduleForm.get('yearlyDay'),
        ];

        controls.forEach(
            control => {
                control.clearValidators();
                control.setValue(null);
                control.updateValueAndValidity();
            }
        )
    }

    clearIntervalStartDateField() {
        let intervalStartDateControl = this.scheduleForm.get('intervalStartDate');

        intervalStartDateControl.clearValidators();
        intervalStartDateControl.setValue(null);

        intervalStartDateControl.updateValueAndValidity();
    }

    addIntervalStartDateFieldValidators() {
        let intervalStartDateControl = this.scheduleForm.get('intervalStartDate');

        intervalStartDateControl.setValidators(Validators.required);
        intervalStartDateControl.updateValueAndValidity();
    }

    addBiMonthlyFieldsValidators() {
        let controls = [
            this.scheduleForm.get('biMonthlyFirstDay'),
            this.scheduleForm.get('biMonthlySecondDay'),
        ];

        controls.forEach(
            control => {
                control.setValidators([Validators.min(1), Validators.max(31)]);
                control.updateValueAndValidity();
            }
        )
    }

    addMonthlyFieldsValidators() {
        let controls = [
            // this.scheduleForm.get('monthlyDay'),
            // this.scheduleForm.get('monthlyEndOfMonth'),
            // this.scheduleForm.get('monthlyOnHireDay'),
            this.scheduleForm.get('monthlyAccrualDayRadioValue')
        ];

        controls.forEach(
            control => {
                control.setValidators([Validators.required]);
                control.updateValueAndValidity();
            }
        )
    }

    showDeleteScheduleConfirmation(id) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            text: 'Are you sure you want to delete this element?'
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data === true) {
                    this.deletePolicySchedule(id);
                }
            }
        );
    }

    deletePolicySchedule(scheduleId: string) {
        this.timeOffService.deleteTimeOffPolicyScheduleById(this.timeOffPolicy.id, scheduleId)
            .subscribe(
                res => {
                    this.timeOffService.getTimeOffPolicySchedules(this.timeOffPolicy.id).subscribe(res => {
                        this.timeOffPolicySchedules = res.data;
                    });
                }
            );
    }

    discardScheduleChanges() {
        this.editTimeOffPolicySchedule = null;
        this.editOrCreateScheduleMode = false;
    }

    saveSchedule() {
        let timeOffScheduleData;
        this.overlayService.show();

        // update time-off-policy schedule
        if (this.editTimeOffPolicySchedule) {
            this.editTimeOffPolicySchedule.startsAt = this.scheduleForm.value.startsAt;
            this.editTimeOffPolicySchedule.accrualRate = this.scheduleForm.value.accrualRate;
            this.editTimeOffPolicySchedule.accrualMaximum = this.scheduleForm.value.accrualMaximum;
            this.editTimeOffPolicySchedule.carryOverAmount = this.scheduleForm.value.carryOverAmount;
            this.editTimeOffPolicySchedule.accrualRateInterval = this.scheduleForm.value.accrualRateInterval;
            this.editTimeOffPolicySchedule.intervalStartDate = this.scheduleForm.value.intervalStartDate;
            this.editTimeOffPolicySchedule.startAtUnit = this.scheduleForm.value.startAtUnit;
            this.editTimeOffPolicySchedule.biMonthlyFirstDay = this.scheduleForm.value.biMonthlyFirstDay;
            this.editTimeOffPolicySchedule.biMonthlySecondDay = this.scheduleForm.value.biMonthlySecondDay;
            this.editTimeOffPolicySchedule.biMonthlySecondDayEndOfMonth = this.scheduleForm.value.biMonthlySecondDayEndOfMonth;
            this.editTimeOffPolicySchedule.yearlyMonth = this.scheduleForm.value.yearlyMonth;
            this.editTimeOffPolicySchedule.yearlyDay = this.scheduleForm.value.yearlyDay;
            this.editTimeOffPolicySchedule.monthlyDay = this.scheduleForm.value.monthlyDay;
            this.editTimeOffPolicySchedule.monthlyEndOfMonth = this.scheduleForm.value.monthlyEndOfMonth;
            this.editTimeOffPolicySchedule.monthlyOnHireDay = this.scheduleForm.value.monthlyOnHireDay;

            timeOffScheduleData = this.editTimeOffPolicySchedule;

            this.timeOffService.putTimeOffPolicySchedule(this.timeOffPolicy.id, this.scheduleForm.value.id, timeOffScheduleData)
                .pipe(
                    finalize(() => this.overlayService.hide())
                )
                .subscribe(
                    (res) => {
                        this.snackbarService.openSnackBar('Time Off Policy Schedule saved successfully', 'clear', 'success');
                        this.editOrCreateScheduleMode = false;
                        this.editTimeOffPolicySchedule = null;
                        this.timeOffService.getTimeOffPolicySchedules(this.timeOffPolicy.id).subscribe(res => {
                            this.timeOffPolicySchedules = res.data;
                        });
                    }
                );
        } else { // create time-off-policy schedule

            let newSchedule = new TimeOffPolicySchedule();
            newSchedule.startsAt = this.scheduleForm.value.startsAt;
            newSchedule.accrualRate = this.scheduleForm.value.accrualRate;
            newSchedule.accrualMaximum = this.scheduleForm.value.accrualMaximum;
            newSchedule.carryOverAmount = this.scheduleForm.value.carryOverAmount;
            newSchedule.accrualRateInterval = this.scheduleForm.value.accrualRateInterval;
            newSchedule.intervalStartDate = this.scheduleForm.value.intervalStartDate;
            newSchedule.startAtUnit = this.scheduleForm.value.startAtUnit;
            newSchedule.biMonthlyFirstDay = this.scheduleForm.value.biMonthlyFirstDay;
            newSchedule.biMonthlySecondDay = this.scheduleForm.value.biMonthlySecondDay;
            newSchedule.biMonthlySecondDayEndOfMonth = this.scheduleForm.value.biMonthlySecondDayEndOfMonth;
            newSchedule.yearlyMonth = this.scheduleForm.value.yearlyMonth;
            newSchedule.yearlyDay = this.scheduleForm.value.yearlyDay;
            newSchedule.monthlyDay = this.scheduleForm.value.monthlyDay;
            newSchedule.monthlyEndOfMonth = this.scheduleForm.value.monthlyEndOfMonth;
            newSchedule.monthlyOnHireDay = this.scheduleForm.value.monthlyOnHireDay;

            timeOffScheduleData = newSchedule;

            this.timeOffService.postTimeOffPolicySchedule(this.timeOffPolicy.id, timeOffScheduleData)
            .pipe(
                    finalize(() => this.overlayService.hide())
                )
                .subscribe(
                    (res) => {
                        this.snackbarService.openSnackBar('Time Off Policy Schedule saved successfully', 'clear', 'success');
                        this.editOrCreateScheduleMode = false;
                        this.editTimeOffPolicySchedule = null;
                        this.timeOffService.getTimeOffPolicySchedules(this.timeOffPolicy.id).subscribe(res => {
                            this.timeOffPolicySchedules = res.data;
                        });
                    }
                );
        }
    }

    editSchedule(timeOffPolicySchedule: TimeOffPolicySchedule) {
        this.editTimeOffPolicySchedule = timeOffPolicySchedule;

        this.createScheduleForm(this.editTimeOffPolicySchedule);
        this.editOrCreateScheduleMode = true;
    }

    addPolicySchedule() {
        this.editTimeOffPolicySchedule = null;
        this.createScheduleForm(this.editTimeOffPolicySchedule);
        this.editOrCreateScheduleMode = true;
    }
}
