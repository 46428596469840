<h5 mat-dialog-title *ngIf="!filter">Create a Filter</h5>
<h5 mat-dialog-title *ngIf="filter">Edit Filter</h5>

<mat-dialog-content>
    <div class="form-container" [formGroup]="form">
        <mat-form-field appearance="fill">
            <mat-label>
                Filter Category
            </mat-label>
            
            <mat-select disableOptionCentering formControlName="filterCategory" (selectionChange)="operatorChange($event)">
                <mat-option *ngFor="let category of categories" [value]="category">{{category.title}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Filter Operator
            </mat-label>
            
            <mat-select disableOptionCentering formControlName="filterOperator" >
                <mat-option *ngFor="let operator of operators[selectedCategory.dataType]" [value]="operator.value">{{operator.label}}</mat-option>
            </mat-select>
        </mat-form-field>
        
        <mat-form-field appearance="fill" >
            <mat-label>
                Filter Value
            </mat-label>

            <ng-container  *ngIf="selectedCategory.dataType == 'Date'">
                <input matInput [matDatepicker]="picker" formControlName="filterString">
                <mat-datepicker-toggle 
                    matSuffix 
                    [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </ng-container>

            <mat-select disableOptionCentering formControlName="filterString" *ngIf="selectedCategory.dataType == 'Lookup'" [attr.disabled]="isLoading" >
                <mat-form-field appearance="fill" class="dropdown-search">
                    <mat-label>
                      Search
                    </mat-label>
                    <input matInput (keyup)="onDropdownSearchKeyReceived($event.target.value, filteredList)">
                  </mat-form-field>
                <mat-option *ngFor="let option of filteredList" [value]="option">{{option.text}}</mat-option>
            </mat-select>
            
            <mat-select disableOptionCentering formControlName="filterString" *ngIf="selectedCategory.dataType == 'ProvidedList'">
                <mat-form-field appearance="fill" class="dropdown-search">
                    <mat-label>
                      Search
                    </mat-label>
                    <input matInput (keyup)="onDropdownSearchKeyReceived($event.target.value, filteredList)">
                  </mat-form-field>
                <mat-option *ngFor="let providedListOption of providedList" [value]="providedListOption[providedListId]">{{providedListOption[providedListDisplayName]}}</mat-option>
            </mat-select>

            <mat-select disableOptionCentering formControlName="filterString" *ngIf="selectedCategory.dataType == 'SpecialLookup'" [attr.disabled]="isLoading">
                <mat-form-field appearance="fill" class="dropdown-search">
                    <mat-label>
                      Search
                    </mat-label>
                    <input matInput (keyup)="onDropdownSearchKeyReceived($event.target.value, filteredList)">
                  </mat-form-field>
                <mat-option *ngFor="let option of filteredList" [value]="option">{{option.name}}</mat-option>
            </mat-select>

            <ng-container *ngIf="selectedCategory.dataType == 'Percentage'">
                <div class="percentage-input">
                    <input matInput type="number" min="0" max="100" formControlName="filterString">
                    <span matSuffix>%</span>
                </div>
            </ng-container>

            <input *ngIf="selectedCategory.dataType == '' || selectedCategory.dataType == 'String' || selectedCategory.dataType == 'Number'" matInput formControlName="filterString">

        </mat-form-field>
    </div>

</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid">Save</button> 
</mat-dialog-actions>
