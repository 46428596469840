<app-site-settings-styling>
  <app-data-grid-comp
    *ngIf="hasFeatureAccess"
    category="CompanyLogos"
    [columns]="columns"
    [gridDataResult]="gridDataResult"
    [isLoading]="isLoading"
    [pageSize]="pageSize"
    [skip]="skip"
    [rowSelectionSettings]="{
        canSelectRows: true,
        selectableMode: 'multiple',
        selectRowBy: 'id'
    }"
    clickableRows="true"
    [clearSelectedItems]="clearSelectedItems"
    (emitPageChangeEvent)="pageChange($event)"
    (emitOpenDialogEvent)="navigateToEditor($event)"
    (emitDeleteEvent)="deleteAllSelected($event)"
    (emitFilterEvent)="filterCallback($event)"
  ></app-data-grid-comp>

  <no-feature-access *ngIf="!hasFeatureAccess"></no-feature-access>
</app-site-settings-styling>
  
  