import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { StylingColoursService } from '../../services/styling-colours.service';
import { finalize } from 'rxjs';
import { MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { StylingColour, StylingColourSubmit } from '../../models/styling-colours.model';
import * as moment from 'moment';
import { WorkLocationsService } from '@app/modules/work-locations/services/work-locations.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { routes } from '@app/consts';

@Component({
  selector: 'app-styling-colour-editor',
  templateUrl: './styling-colour-editor.component.html',
  styleUrls: ['./styling-colour-editor.component.scss']
})
export class StylingColourEditorComponent implements OnInit {
  formId: string ='frm_Jy1fDxedjVFYLs';
  getFormData: boolean = false;
  formValid: boolean = false;
  formData: any;

  primaryColour: string;
  accentColour: string;
  warnColour: string;
  active: boolean = false;
  isLoading: boolean;
  stylingColourId: string;
  stylingColour: StylingColour;

  public columns: any[] = [
    {field: 'name', title: 'Name', dataType: "String"},
    { field: "streetAddress", title: "Street Address", tableId: "tfi_WlStreetAddress", dataType: "String"},
    { field: "city", title: "City", dataType: "String", tableId: "tfi_WlCity" },
    { field: "province", title: "Province", dataType: "String", tableId: "tfi_WlProvince" },
    { field: "zip", title: "Zip", dataType: "String", tableId: "tfi_WlZip" },
    { field: "region", subField: 'text', title: "Region", dataType: "String", tableId: "" },
    { field: "country", subField: 'name', title: "Country", dataType: "String", tableId: "tfi_WlCountry" },
    { field: "timezone", subField: 'text', title: "Timezone", dataType: "String", tableId: "" },
    { field: "stylingColours", subField: 'name', title: "stylingColor", tableId: "" },
    { field: "startDate", title: "Start Date", type: "date", dataType: "Date", tableId: "" },
    { field: "endDate", title: "End Date", type: "date", dataType: "Date", tableId: "" },
  ];

  sortableColumns: any[] = [
    { field: "name", sortValue: "name"},
    { field: "streetAddress", sortValue: "streetAddress" },
    { field: "city", sortValue: "city" },
    { field: "province", sortValue: "province" },
    { field: "zip", sortValue: "zip" },
    { field: "region", sortValue: "region.text" },
    { field: "country", sortValue: "country.name" },
    { field: "timezone", sortValue: "timezone.text" },
    { field: "startDate", sortValue: "startDate" },
    { field: "endDate", sortValue: "endDate" },
    { field: "stylingColor", sortValue: "stylingColor" },
  ];

  public filterCategories: any[] = [].concat(this.columns)
  public searchFilterString: string;
  public searchValue: string;
  filterString: string;
  sortString: string;
  getWorkLocationsRequest: any;
  pageSize: number = 20;
  skip: number = 0;
  filterToggleDetails: {
    text: string,
    checked: boolean
  } = {
    text: 'FilterInactive',
    checked: true
  }
  public gridDataResult: GridDataResult;
  isLoadingWorkLocations: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private stylingColoursService: StylingColoursService,
    private workLocationsService: WorkLocationsService,
  ) { }

  ngOnInit(): void {
    this.stylingColourId = this.getIdInURL();
    if(this.stylingColourId){
      this.getStylingColours();
      this.getWorkLocations();
    }
  }

  getIdInURL(): string {
    let IdInURL: string;

    this.route.paramMap.subscribe(
        params => IdInURL = params.get('id')
    );

    return IdInURL;
  }

  getStylingColours() {
    this.isLoading = true;

    this.stylingColoursService.getStylingColour(this.getIdInURL())
    .pipe(
      finalize( () => {
        this.buildFormData();
      })
    )
    .subscribe(
      res => {
        this.stylingColour = res;
      }
    );
  }

  buildFormData(){
    this.isLoading = true;

    this.formData = {
        id: this.stylingColour ? this.stylingColour.id : null,
        name: this.stylingColour ? this.stylingColour.name : null,
        primaryColour: this.stylingColour ? this.stylingColour.primaryColour : null,
        accentColour: this.stylingColour ? this.stylingColour.accentColour : null,
        warnColour: this.stylingColour ? this.stylingColour.warnColour : null,
        defaultColours: this.stylingColour ? this.stylingColour.defaultColours : null,
        active: this.stylingColour ? this.stylingColour.active : null,
    }

    this.isLoading = false;
  }
  
  formDataEmitted(formData) {
    this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  save(formData) {
    if(this.stylingColourId) {
      this.editStylingColour(formData);
    }
    else {
      this.createStylingColour(formData);
    }
  }

  createStylingColour(formData) {
    this.overlayService.show()

    formData.id = this.getIdInURL();
    formData.asOf = moment().format('YYYY-MM-DD'),
    formData.changeReason = null,
    formData.changeReasonComments = null,
    formData.originalCreatedOn = moment().format('YYYY-MM-DD'),

    this.stylingColoursService.postStylingColour(formData)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
        this.router.navigate([`${routes.SITE_SETTINGS}${routes.STYLING}${routes.STYLING_COLOURS}${routes.EDITOR}/${res.id}`]);
      }
    );
  }

  editStylingColour(formData) {
    this.overlayService.show()

    formData.id = this.getIdInURL();
    formData.asOf = moment().format('YYYY-MM-DD'),
    formData.changeReason = null,
    formData.changeReasonComments = null,
    formData.originalCreatedOn = this.stylingColour.version?.createdOn,

    this.stylingColoursService.putStylingColour(this.getIdInURL(), formData)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

  close(){
    this.router.navigate([`${routes.SITE_SETTINGS}${routes.STYLING}`]);
  }

  getWorkLocations(){
    this.isLoadingWorkLocations = true;

    let filter: string = `(stylingColours.Id = "${this.stylingColourId}")`;
    
    if(this.searchFilterString) {
      filter = `${filter} AND ${this.searchFilterString}`;
    }

    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }

    this.getWorkLocationsRequest = this.workLocationsService.getWorkLocations(this.skip, String(this.pageSize), filter, this.sortString, this.filterToggleDetails.checked)
    .pipe(
        finalize(()=>{ this.isLoadingWorkLocations = false; })
    )
    .subscribe(
      res => {
        this.gridDataResult = {
          data: res.data,
          total: res.total,
        }
      }
    )
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.getWorkLocations();
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;;
    this.getWorkLocations();
  }


  search(searchValue) {
    this.searchValue = searchValue;
    this.searchFilterString = `(Name like "${this.searchValue}")`;
    this.getWorkLocationsRequest?.unsubscribe();
    this.getWorkLocations();
  }

  updateFilterToggleDetails(toggleChange: MatSlideToggleChange){
    this.filterToggleDetails.checked = toggleChange.checked;
    this.getWorkLocations();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getWorkLocations();
  }

}
