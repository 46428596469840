import { Component, OnInit } from '@angular/core';
import { features } from '@app/consts';
import { modules } from '@app/consts/modules';
import { EmployeeService } from '@app/core/services/employee.service';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { CurrentUserDetails } from '@app/shared/models/employee.model';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  public modules: typeof modules = modules;
  refreshMyTimeOffRequest: boolean = false;
  refreshMyToilRequest: boolean = false;
  currentEmployeeDetails: CurrentUserDetails;
  public features: typeof features = features;
  user$: any;

  constructor(
    private employeeService: EmployeeService,
    private oidcAuthService: OidcAuthService
  ) {
    this.user$ = this.oidcAuthService.userProfile;
  }

  ngOnInit(): void {
    this.currentEmployeeDetails = this.employeeService.getFetchedCurrentUserDetails()
  }

  timeOffRequestCreated(event: boolean) {
    this.refreshMyTimeOffRequest = !this.refreshMyTimeOffRequest;
  }

  toilRequestCreated(event: boolean) {
    this.refreshMyToilRequest = !this.refreshMyToilRequest;
  }

}
