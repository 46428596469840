<h5 mat-dialog-title>Add A New Report</h5>

<mat-dialog-content>
    <form class="form-container" *ngIf="form" [formGroup]="form">

        <div class="section">
            <div class="section-header">
                <h6>Report Name</h6>
            </div>

            <mat-form-field appearance="fill">
                <mat-label>
                    Report Name
                </mat-label>
    
                <input matInput
                    type="text"
                    formControlName="reportName">
            </mat-form-field>
        </div>

        <div class="section filter-section">
            <div class="section-header">
                <h6>Choose Filters</h6>
            </div>

            <mat-form-field   appearance="fill">
                <mat-label>
                    Employee
                </mat-label>
                
                <mat-select disableOptionCentering formControlName="employee">
                    <ng-container *ngFor="let option of employees">
                        <mat-option [value]="option.id">{{option.firstName}} {{option.lastName}}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>

            <mat-form-field   appearance="fill">
                <mat-label>
                    Manager
                </mat-label>
                
                <mat-select disableOptionCentering formControlName="manager">
                    <ng-container *ngFor="let option of employees">
                        <mat-option [value]="option.id">{{option.firstName}} {{option.lastName}}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>

            <mat-form-field   appearance="fill">
                <mat-label>
                    Division
                </mat-label>
                
                <mat-select disableOptionCentering formControlName="division">
                    <ng-container *ngFor="let option of divisions">
                        <mat-option [value]="option">{{option}}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>

            <mat-form-field   appearance="fill">
                <mat-label>
                    Organization Unit
                </mat-label>
                
                <mat-select disableOptionCentering formControlName="orgUnit">
                    <ng-container *ngFor="let option of orgUnits">
                        <mat-option [value]="option">{{option}}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>

            <mat-form-field   appearance="fill">
                <mat-label>
                    Department
                </mat-label>
                
                <mat-select disableOptionCentering formControlName="department">
                    <ng-container *ngFor="let option of departments">
                        <mat-option [value]="option">{{option}}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>

            <mat-form-field  appearance="fill">
                <mat-label>
                    Employment Status
                </mat-label>
                
                <mat-select disableOptionCentering formControlName="employmentStatus">
                    <ng-container *ngFor="let option of employmentStatus">
                        <mat-option [value]="option">{{option}}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>


        <div class="section">
            <div class="section-header">
                <h6>Choose Fields</h6>
            </div>
            <div>
                <mat-form-field appearance="outline">
                    <input matInput
                           #systemVariableSearch
                           value="Employees"
                           placeholder="Search for a variable group">
                    <mat-icon matPrefix class="material-icons-outlined">
                        search
                    </mat-icon>
                </mat-form-field>
        
                <div class="list-container">
                    <div>
                        <div>Browse Field</div>
    
                        <mat-list class="list">
                            <ng-container *ngFor="let table of (tables | systemVariableSearch:systemVariableSearch.value)">
                                <mat-list-item> 
                                    {{table.name}} 
                                </mat-list-item>
            
                                <mat-list style="margin-left:30px;">
                                    <div *ngFor="let field of table.fields | async">
                                        <mat-list-item 
                                            class="field-list-item" 
                                            (click)="addToSelectedFields(field)"
                                            matTooltip="Add Field"> 
                                            {{field.name}} 
                                            <mat-icon class="material-icons-outlined">
                                                add
                                            </mat-icon>
                                        </mat-list-item>
                                    </div>
                                </mat-list>
                            </ng-container>
                        </mat-list>
                    </div>
    
                    <div>
                        <div>Selected</div>
                        <mat-list class="list">
                            <mat-list-item 
                                (click)="removeFromSelectedFields(selectedField)"    
                                *ngFor="let selectedField of selectedFields"> 
                                {{selectedField.name}} 
                                <mat-icon class="material-icons-outlined">
                                    remove
                                </mat-icon>
                            </mat-list-item>
                        </mat-list>
                    </div>
                </div>
            </div>
        </div>


        <div class="section">
            <div class="section-header">
                <h6>Choose Fields</h6>
            </div>
            
            <mat-form-field appearance="fill">
                <mat-label>
                    Show
                </mat-label>
    
                <mat-select disableOptionCentering formControlName="filterResult">
                    <mat-option value="allEmployees">All employees</mat-option>
                    <mat-option value="managers">Managers</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="section">
            <div class="section-header">
                <h6>Sort Results</h6>
            </div>
            
            <mat-form-field appearance="fill">
                <mat-label>
                    Sort By
                </mat-label>
    
                <mat-select disableOptionCentering formControlName="sortBy">
                    <mat-option value="lastNameFirstname">Last name, First name</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button> 
</mat-dialog-actions>