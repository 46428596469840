<div class="mat-form-field" [formGroup]="parentGroup" (click)="openSelectCompanyLogoDialog()">

    <div *ngIf="!companyLogo"  class="no-logo-selected">
        <div>
            <mat-label>
                {{ formElement.text }}
                <span class="asterisk" *ngIf="formElement.requiredField">*</span>
            </mat-label>
        </div>
    </div>

    <div *ngIf="companyLogo" class="logo-selected">
        <div class="top">
            <mat-label>
                {{ formElement.text }}
                <span class="asterisk" *ngIf="formElement.requiredField">*</span>
            </mat-label>
            
            <span *ngIf="!readOnly">
                <mat-icon 
                    setColor 
                    [setColorRules]="[
                        {
                            color: 'primaryColour',
                            events: ['hover']
                        }
                    ]"
                    class="material-icons-outlined clickable-icon" 
                    (click)="$event.stopPropagation(); clearSelectedPosition()"
                >
                    close
                </mat-icon>
            </span>
        </div>

        <app-logo-image [logoId]="companyLogo?.id"></app-logo-image>
    </div>
</div>
