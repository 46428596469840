
<app-data-grid-comp
[columns]="columns"
[bindingType]="bindingType"
[view]="view"
[gridData]="gridData"
[gridDataResult]="gridDataResult"
[isLoading]="isLoading"
[pageSize]="pageSize"
[skip]="skip"
category="Relocations"
[isLoading]="isLoading"
(emitPageChangeEvent)="pageChange($event)"
(emitOpenDialogEvent)="openEmploymentRecordRelocationDialog($event)"
(emitDeleteEvent)="deleteEmploymentRecordRelocation($event)"
></app-data-grid-comp>