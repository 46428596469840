import { Localization } from "@app/shared/models/localization.model";
import { Version } from "@app/shared/models/version.model";

export interface StylingColour {
    id: string,
    name: Localization
    active: boolean,
    defaultColours: boolean,
    primaryColour: string,
    accentColour: string,
    warnColour: string,
    version: Version
}

export interface StylingColourSubmit {
    id: string,
    asOf: string,
    changeReason: string,
    changeReasonComments: string,
    originalCreatedOn: string,
    name: Localization
    active: boolean,
    defaultColours: boolean,
    primaryColour: string,
    accentColour: string,
    warnColour: string
}