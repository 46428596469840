import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { process } from "@progress/kendo-data-query";
import { ReportsService } from '../../services/reports.service';
import { NewReportDialogComponent } from '../new-report-dialog/new-report-dialog.component';

@Component({
  selector: 'app-reports-list-data-grid',
  templateUrl: './reports-list-data-grid.component.html',
  styleUrls: ['./reports-list-data-grid.component.scss']
})
export class ReportsListDataGridComponent implements OnInit {
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  public gridData: any[];
  public gridView: any[];

  selectedNav: string = 'All Reports';
  navItems = [
    {
      name: 'All Reports',
      children: [],
      count: 26
    },
    {
      name: 'Favorite Reports',
      children: [],
      count: 10
    },
    {
      name: 'Custom Reports',
      children: [
        {
          name: 'Single Object',
          count: 2
        },
        {
          name: 'Cross Object',
          count: 4
        },
      ],
      count: 6
    },
    {
      name: 'Standard Reports',
      children: [],
      count: 6
    },
    {
      name: 'On Dashboard',
      children: [],
      count: 12
    },
    {
      name: 'Not On Dashboard',
      children: [],
      count: 10
    }
  ];

  constructor(
    private reportsService: ReportsService,
    private dialog: MatDialog,
  ) { }

  public ngOnInit(): void {
    this.reportsService.getDataGridReports()
    .subscribe(
      res => {
        this.gridData = res;
        this.gridView = this.gridData;
      }
    )
  }

  public onFilter(inputValue: string): void {
    this.gridView = process(this.gridData, {
      filter: {
        logic: "or",
        filters: [
          {
            field: "title",
            operator: "contains",
            value: inputValue,
          },
        ],
      },
    }).data;

    this.dataBinding.skip = 0;
  }

  openNewReportDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(NewReportDialogComponent, dialogConfig);
  }

}
