import {Component, OnInit} from '@angular/core';
import { routes } from '@app/consts/routes';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-security-section',
  templateUrl: './security-menu.component.html',
  styleUrls: ['./security-menu.component.scss']
})
export class SecurityMenuComponent implements OnInit {
  public routes: typeof routes = routes;
  navList = [
    // {
    //   text: 'Integration Configuration',
    //   link: `${routes.SECURITY}${routes.INTEGRATION}`,
    //   icon: 'integration_instructions'
    // },
    // {
    //   text: 'API Setup',
    //   link: `${routes.SECURITY}${routes.API}`,
    //   icon: 'api'
    // },
    {
      text: 'SecurityRoles',
      link: `${routes.SECURITY}${routes.ROLES}`,
      icon: 'supervised_user_circle'
    },
    {
      text: 'SecuritySetup',
      link: `${routes.SECURITY}${routes.SETUP}`,
      icon: 'lock'
    }
  ];
  selectedComponent: any = this.navList[0];

  constructor(public translate: TranslateService) {
  }

  ngOnInit(): void {
  }

}
