import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { TimeOffInLieuService } from '@app/core/services/time-off-in-lieu/time-off-in-lieu.service';
import { ToilListVariant } from '@app/modules/lookup/models/toil-list-variant.model';
import {ToilPolicy} from "@app/modules/lookup/models/toil-policy.model";
import { EmployeeToilPolicy } from '@app/shared/models/time-off-in-lieu/time-off-in-lieu.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-assign-toil-policy-dialog',
  templateUrl: './assign-toil-policy-dialog.component.html',
  styleUrls: ['./assign-toil-policy-dialog.component.scss']
})
export class AssignToilPolicyDialogComponent implements OnInit {
  toilTypeOptions: ToilListVariant[] = [];
  selectedToilType: ToilListVariant;
  selectedToilPolicies: ToilPolicy[] = [];
  form: UntypedFormGroup;
  employeeId: string;
  employeeToilPolicy: EmployeeToilPolicy;

  constructor(
    private timeOffInLieuService: TimeOffInLieuService,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private dialogRef: MatDialogRef<AssignToilPolicyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      this.employeeId = data.employeeId;
      this.employeeToilPolicy  = data.employeeToilPolicy;
  }

  ngOnInit(): void {
    this.getTimeOffTypes();
    this.createForm();
  }

  getTimeOffTypes() {
    this.timeOffInLieuService.getToilTypes()
    .subscribe(
      res => {
        this.toilTypeOptions = res.data;
        this.selectedToilType = this.toilTypeOptions.find( item => item.id === this.employeeToilPolicy?.toilPolicy?.toilType.id );
        if(this.selectedToilType){
          this.populateSelectedToilPolicies();
        }
      }
    )
  }

  populateSelectedToilPolicies(){
    this.timeOffInLieuService.getAllToilPolicies(this.selectedToilType.id, 0, 100)
    .subscribe(
        res => {
            if(res instanceof Observable){
              res.subscribe(
                r => {
                  this.selectedToilPolicies = r.data;
                }
              )
            }
            else {
              this.selectedToilPolicies = res.data;
            }
        }
    )
  }

  createForm() {
    this.form = this.fb.group({
      type: [
        {
          value: this.employeeToilPolicy ? this.employeeToilPolicy?.toilPolicy?.toilType.id : null,
          disabled: (this.employeeToilPolicy !== undefined ? true : false)
        }, Validators.required
      ],
      policy: [null, Validators.required],
      asOf: [moment().format()],
      changeReason: [''],
      changeReasonComments: ['']
    });
  }

  setSelectedToilType(event: MatSelectChange) {
    this.selectedToilType = this.toilTypeOptions.find( item => item.id === event.value );
    this.populateSelectedToilPolicies();
  }

  save(){
    this.overlayService.show();

    this.timeOffInLieuService.assignEmployeeToilPolicy(
      {
        policyId: this.form.controls['policy'].value, 
        employeeId: this.employeeId
      }
    )
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      res => {
        let message = this.employeeToilPolicy ? this.translate.instant('SavedSuccessfully') : this.translate.instant('CreatedSuccessfully');
        this.snackbarService.openSnackBar(message, 'clear', 'success');
        this.dialogRef.close(true);
      }
    )
  }

  close(){
    this.dialogRef.close();
  }

}
