import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { finalize } from 'rxjs/operators';
import { defer, forkJoin } from 'rxjs';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { EmployeeMedicalTestService } from './services/employee-medical-test.service';
import { EmployeeMedicalTest, EmployeeMedicalTestVerbose } from './models/employee-medical-test.model';
import { api_routes, db_tables } from '@app/consts';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';

@Component({
  selector: 'app-employee-medical-tests',
  templateUrl: './employee-medical-tests.component.html',
  styleUrls: ['./employee-medical-tests.component.scss']
})
export class EmployeeMedicalTestsComponent implements OnInit {
  @Input() formIds: string[];
  employeeID: string;

  public columns: any[] = [
    { field: "testName", subField: "text", title: "test Name", type: "navigation", tableId: "tfi_TestName"},
    { field: "testDate", title: "test Date", type: "date", tableId: "tfi_TestDate"},
    { field: "results", subField: "text", title: "results", tableId: "tfi_Results"},
    { field: "status", subField: "text", title: "status", tableId: "tfi_MedicalStatus"},
    { field: "conductedBy", subField: "text", title: "conducted By", tableId: "tfi_ConductedBy"},
    { field: "comments", title: "comments", tableId: "tfi_MedComments"},
    { field: "nextTestDate", title: "next test Date", type: "date", tableId: "tfi_NextTesetDate"},
  ];
  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 5;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  dialogRef: MatDialogRef<FormGeneratorDialogComponent>;
  formId: string = 'frm_Wbn1EjPE17VwNH'
  changeReasonFormId: string = 'frm_O8hOxM4uEP7Zbg';
  formValid: boolean;
  private sortString: string = 'testDate-desc';
  isLoadingPermissions: boolean = true;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];
  accessAndHistoryURL: string = `${api_routes.TALENT}/[employeeId]/${api_routes.MEDICAL_TESTS}/[entityId]`;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private medicalTestService: EmployeeMedicalTestService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private tablePermissionsService: TablePermissionsService,
    private route: ActivatedRoute,
    ) {
      this.route.paramMap.subscribe(
          params => this.employeeID = params.get("id")
      )
    }

  ngOnInit(): void {
    this.view = this.getMedicalTests();
    this.getTargetEmployeeTablePermissions();
  }

  getMedicalTests() {
    this.isLoading = true;

    this.medicalTestService.getMedicalTests(this.employeeID, String(this.pageSize), this.skip, this.sortString)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          }
          this.gridData = res.data;
        }
      );
  }

  getMedicalTest(medicalTest?: EmployeeMedicalTest) {
    if(medicalTest === undefined){
      this.openFormDialog();
    }
    else {
      this.isLoading = true;

      this.medicalTestService.getMedicalTest(this.employeeID, medicalTest.id)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        res => {
          this.openFormDialog(res);
        }
      );
    }
  }

  getTargetEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeID);

    // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
    if(currentFetchedData !== null){
      this.targetEmployeeTablePermissions = currentFetchedData;
      this.isLoadingPermissions = false;
    }
    else {
      this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeID)
      .pipe(
        finalize(() => {
            this.isLoadingPermissions = false;
        })
      )
      .subscribe(
        res => {
          this.targetEmployeeTablePermissions = res;
        }
      );
    }
  }

  openFormDialog(medicalTest?: EmployeeMedicalTestVerbose) {
    let formData = {
      id: medicalTest ? medicalTest.id : null,
      asOf: moment().format(),
      changeReason: '',
      changeReasonComments: '',
      testName: medicalTest ? medicalTest.testName?.id : null,
      status: medicalTest ? medicalTest.status?.id : null,
      conductedBy: medicalTest ? medicalTest.conductedBy?.id : null,
      results: medicalTest ? medicalTest.results?.id : null,
      frequency: medicalTest ? medicalTest.frequency?.id : null,
      testDate: medicalTest ? medicalTest.testDate : null,
      nextTestDate: medicalTest ? medicalTest.nextTestDate : null,
      comments: medicalTest ? medicalTest.comments : null,
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formTitle: `${this.translate.instant('MedicalTest')}`,
      formId: this.formId,
      changeReasonFormId: this.changeReasonFormId,
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions,
      formData: formData
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      this.save(event)
    });
  }

  formDataEmitted(formData) {
    this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  save(formData: any){
    this.overlayService.show();
    if (formData.testDate) {formData.testDate = moment(formData.testDate).format().slice(0, 10);}
    if (formData.nextTestDate) {formData.nextTestDate = moment(formData.nextTestDate).format().slice(0, 10);}
    if(!formData.id){
      this.medicalTestService.createMedicalTest(this.employeeID, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getMedicalTests();
        }
      );
    }
    else {
      this.medicalTestService.updateMedicalTest(this.employeeID, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getMedicalTests();
        }
      );
    }
  }

  deleteMedicalTests(medicalTestIds: string[]) {
    const observables = medicalTestIds.map(selectedItem => defer(() => this.medicalTestService.deleteMedicalTest(this.employeeID, selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.view = this.getMedicalTests();
        this.clearSelectedItems = !this.clearSelectedItems;
          this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.view = this.getMedicalTests();
  }

}



