import {Directive, ElementRef, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {FeatureService} from '@app/core/services/feature.service';
import { features } from 'process';

@Directive({
    selector: '[appHasFeature]'
})
export class HasFeatureDirective {

    @Input() appHasFeature: string;
    private hasView = false;

    constructor(
        private featureService: FeatureService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) {}

    ngOnInit() {
        let hasAccess = this.hasFeatureAccess(this.appHasFeature);
        if (hasAccess && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (!hasAccess && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }

    public hasFeatureAccess(featureCode: string | string[]) {
        if(typeof featureCode === 'string') {
            return this.featureService.hasFeatureAccess(featureCode);
        }
        else {
            let hasAccess = false;

            for(let feature of featureCode){
                if(this.featureService.hasFeatureAccess(feature)){
                    hasAccess = true;
                    break;
                }
            }

            return hasAccess;
        }
    }

}
