import { Pipe, PipeTransform } from '@angular/core';
import { DirectReportee } from './models/direct-reportee.model';

@Pipe({ name: 'directReporteeSearch' })
export class DirectReporteeSearchPipe implements PipeTransform {
  public transform(directReportees: DirectReportee[], searchText: any): any {
    if (searchText == '' || directReportees == null) {
      return directReportees;
    }

    return directReportees.filter(directReportee => 
        directReportee?.id?.toUpperCase().includes(searchText.toUpperCase())
        || directReportee?.firstname?.toUpperCase().includes(searchText.toUpperCase())
        || directReportee?.lastname?.toUpperCase().includes(searchText.toUpperCase())
        || directReportee?.preferredName?.toUpperCase().includes(searchText.toUpperCase())
    );
  }
}