import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api_routes } from '@app/consts';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { EmployeeEmploymentDetails, EmployeeEmploymentDetailsSubmit } from '../models/employee-employment-details.model';
import {EnvironmentService} from "@app/core/services/environment.service";



const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class EmployeeEmploymentDetailsService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getEmploymentDetails(employeeId: string, asOf?: string): Observable<EmployeeEmploymentDetails> {
    let params = new HttpParams();
    asOf ? params = params.append('AsOf', asOf) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.EMPLOYMENT_DETAILS}`, {
      params: params,
      headers: headers
    });
  }

  updateEmploymentDetails(employeeId: string, employmentDetails: EmployeeEmploymentDetailsSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.EMPLOYMENT_DETAILS}`, employmentDetails, {
      headers: headers
    });
  }
}
