import { Component, Input, NgZone, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SelectEmployeesDialogComponent } from '@app/shared/components/select-employees-dialog/select-employees-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { defer, finalize, forkJoin } from 'rxjs';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { FormElement } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { UntypedFormGroup } from '@angular/forms';
import { db_tables } from '@app/consts';

@Component({
  selector: 'app-multiple-employee-select-form-field',
  templateUrl: './multiple-employee-select-form-field.component.html',
  styleUrls: ['./multiple-employee-select-form-field.component.scss']
})
export class MultipleEmployeeSelectFormFieldComponent implements OnInit {
  @Input() formElement: FormElement;
  @Input() parentGroup: UntypedFormGroup;
  @Input() readOnly: boolean;
  
  public db_tables = db_tables;
  isLoading: boolean;
  employeesGridDataResult: { employee: {id: string, firstName: string, lastName: string}}[] = []
  pageSize: number = 5;
  skip: number = 0;
  public columns: any[] = [
    { field: "employee", title: this.translate.instant('Employee'), type:'employee' },
  ];
  clearSelectedItems: boolean = false;
  searchValue: string;
  searchFilterString: string;
  getUsersRequest: any;
  selectEmployeesDialogRef: MatDialogRef<SelectEmployeesDialogComponent>;

  constructor(
    private dialog: MatDialog,
    private ngZone: NgZone,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    if(this.parentGroup.controls[this.formElement.formControl].value !== null && this.parentGroup.controls[this.formElement.formControl].value !== undefined){
      this.employeesGridDataResult = this.parentGroup.controls[this.formElement.formControl].value.map(
        emp => (
          {
            employee: emp
          }
        )
      )
    }
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;
    this.searchFilterString = '';
    this.skip = 0;

    let variants = this.searchValue.split(' ').filter(value => value);
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `(Owner.FirstName like "${variant}") OR (Owner.LastName like "${variant}")`;
        }
    });

    this.getUsersRequest.unsubscribe();
    // this.getReviewPlanOwners();
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    // this.getReviewPlanOwners();
  }

  showAddUserModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      nonSelectableEmployees: [ ...this.employeesGridDataResult.map( item => item.employee?.id )],
      employeeValueToSelect: 'employee'
    };

    this.ngZone.runOutsideAngular(() => {
      this.selectEmployeesDialogRef = this.dialog.open(SelectEmployeesDialogComponent, dialogConfig);
    });

    const sub = this.selectEmployeesDialogRef.componentInstance.emitSelectedEmployees.subscribe((event) => {
      this.addEmployees(event)
    });
  }

  addEmployees(selectedEmployees: any) {
    this.employeesGridDataResult = [...this.employeesGridDataResult, ...selectedEmployees.map(emp => ({
      employee: emp
    }))]

    this.setFormValue()

    this.selectEmployeesDialogRef.close();
  }

  deleteAllSelected(employees: {id: string, firstName: string, lastName: string}[]) {
    this.employeesGridDataResult = this.employeesGridDataResult.filter( data => !employees.includes(data.employee) )
    this.setFormValue();
    this.clearSelectedItems = !this.clearSelectedItems;
  }

  setFormValue() {
    this.parentGroup.controls[this.formElement.formControl].setValue(this.employeesGridDataResult.map(data => data.employee))
  }
}

