<kendo-grid
        [data]="gridDataResult"
        [pageSize]="pageSize"
        [style.maxHeight.vh]="85" 
        [skip]="skip"
        [pageable]="{
            info: true,
            pageSizes: [5, 10, 20, 50, 100],
            previousNext: true
        }"
        (pageChange)="pageChange($event)"
        [loading]="isLoading"
        [selectable]="{enabled: true}"
        kendoGridSelectBy="id"
        [(selectedKeys)]="selectedPositions"
>
    <ng-template kendoGridToolbarTemplate>
        <div *ngIf="selectedPositions.length > 0">
            <button class="delete-all-selected-button" mat-stroked-button setColor="primaryColour" color="primary" (click)="deleteAllSelected()">
                <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">delete</mat-icon>
                Delete All Selected ({{selectedPositions.length}})
            </button>
        </div>

        <mat-form-field class="search-field" appearance="outline">
            <input matInput
                   placeholder="Search..."
                   [(ngModel)]="searchValue"
                   (input)="search()"
            >
            <mat-icon matPrefix class="material-icons-outlined">
                search
            </mat-icon>
        </mat-form-field>
        <div class="mt-2">
            <filter-control [categories]="filterCategories" (callback)="filterCallback($event)"></filter-control>
        </div>
    </ng-template>

    <kendo-grid-checkbox-column
            width="60"
            showSelectAll="true"
    ></kendo-grid-checkbox-column>


    <kendo-grid-column field="name" title="Name" width="400">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="grid-cell-vertical">
              <span class="position-name navigation-link" routerLink="{{routes.SITE_SETTINGS}}/{{routes.STATUTORY_HOLIDAYS}}{{routes.EDITOR}}/{{dataItem.id}}">
                  {{dataItem.name}}
              </span>
            </div>
        </ng-template>
    </kendo-grid-column>


    <kendo-grid-column field="country" title="Country">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem?.country?.name}}</span>
        </ng-template>
    </kendo-grid-column>


    <!--    <kendo-grid-column-->
    <!--            *ngFor="let col of columns"-->
    <!--            [field]="col.field"-->
    <!--            [title]="col.title"-->
    <!--    >-->
    <!--        -->
    <!--    </kendo-grid-column>-->
</kendo-grid>
