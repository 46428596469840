<mat-card class="visits-chart">
  <mat-card-title class="visits-chart__header">
    <h5 class="visits-chart__title">Personal Goals Reached</h5>

    <app-settings-menu></app-settings-menu>
  </mat-card-title>

  <mat-card-content class="visits-chart__content">
    <div class="visits-chart__content-info">
      <h6 class="visits-chart__content-info-title">{{visitsChartData.all}}</h6>
      <ngx-trend
        class="visits-chart__content-info-chart"
        [data]="visitsChartData.data"
        [gradient]="[colors.GREEN]"
        [height]="44"
        [smooth]="true"
        [strokeWidth]="5"
      ></ngx-trend>
    </div>

    <div class="visits-chart__content-stats">
      <div>
        <p class="visits-chart__content-stats-title">In Progress</p>
        <p class="visits-chart__content-stats-data">{{visitsChartData.registration}}</p>
      </div>

      <div>
        <p class="visits-chart__content-stats-title">Goal Completed</p>
        <p class="visits-chart__content-stats-data">{{visitsChartData.signOut}}</p>
      </div>

      <div>
        <p class="visits-chart__content-stats-title">Percentage Completed</p>
        <p class="visits-chart__content-stats-data">{{visitsChartData.rate}}%</p>
      </div>
    </div>
  </mat-card-content>
</mat-card>
