import { Pipe, PipeTransform } from '@angular/core';
import { TableList } from './models/lookup.model';

@Pipe({ name: 'lookupListSearch' })
export class LookupListSearchPipe implements PipeTransform {
  public transform(tableList: TableList[], searchText: any): any {
    if (searchText == '' || tableList == null) {
      return tableList;
    }

    let tables: TableList[] = []

    tableList.forEach(
        table => {
            // If the table name matches the search add the entire table fields list to be returned
            if(table.name.toUpperCase().includes(searchText.toUpperCase())){
                tables.push(table);
            }
            else {
                // Create a list of fields that match the search term
                let fields = table?.fields.filter(field => 
                    field.name.toUpperCase().includes(searchText.toUpperCase())
                );

                // if there are fields that match the search term the create a copy of the table object and add it to the list of tables to be returned
                if(fields.length > 0) {
                    let tableCopy = Object.assign({}, table);
                    tableCopy.fields = fields;
                    tables.push(tableCopy);
                }
            }
        }
    )

    return tables;
  }
}