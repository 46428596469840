import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TableField } from "@app/modules/security-setup/models/table-field.model";
import { CustomReportFields } from "@app/modules/custom-reports/models/custom-report.model";
import { MatSelect } from "@angular/material/select";
import { capitalize } from "lodash";


@Component({
    selector: 'report-element-grid-column',
    templateUrl: './report-element-grid-column.component.html',
    styleUrls: ['./report-element-grid-column.component.scss']
})
export class ReportElementGridColumnComponent implements OnInit {

    @Input() index: number = -1;
    @Input() tables: CustomReportFields[];
    @Input() propertyForm: any;

    tableFieldsFiltered: TableField[] = null;
    dropdownIndexChanged = -1;
    hiddenOptions = [];

    @ViewChild('searchMenu') searchMenu!: MatSelect;

    constructor() {
    }

    ngOnInit(): void {

        this.hiddenOptions = [
            {value: `${this.propertyForm.value.filter}`, label: ``}
        ];

        this.propertyForm.controls.field.valueChanges.subscribe(value => {
            let table = this.tables.find(tableField => tableField.tableName === this.propertyForm.value.table);

            if (!table) {
                console.error(`No table with name '${this.propertyForm.value.table}' found`);
                return;
            }

            let field = table.fields.find(tableField => tableField.id === value);

            if (!field) {
                console.error(`No field with id '${value}' found`);
                return;
            }

            this.propertyForm.controls.fieldType.value = field.fieldType;

            if (!this.propertyForm.controls.title.value) {
                this.propertyForm.controls.title.setValue(field.name);
            }
        });

    }

    getRelevantFields() {
        try {
            return this.tables.find(tableField => tableField.tableName === this.propertyForm.value.table).fields;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    onDropdownSearchKeyReceived(value: string, propertyIndex: number) {
        if (value) {
            this.dropdownIndexChanged = propertyIndex;
            let filter = value.toLowerCase();
            this.tableFieldsFiltered = this.tables.find(tableField => tableField.tableName === this.propertyForm.value.table).fields.filter(tableField => tableField.name.toLowerCase().includes(filter));
        } else {
            this.dropdownIndexChanged = -1
            this.tableFieldsFiltered = null;
        }
    }

    applyFilter(value: string, filter: string) {
        if (value) {
            let filterValue: string;
            if (filter === 'like') {
                filterValue = `like:${value}`;
            } else if (filter === 'not-like') {
                filterValue = `not-like:${value}`;
            } else {
                filterValue = `${filter}`;
            }
            this.propertyForm.get('filter').setValue(filterValue);
            this.hiddenOptions = [
                {value: `${filterValue}`, label: ``}
            ];

        } else {
            this.propertyForm.get('filter').setValue('');
        }
    }


    displayFilterValue() {
        let res = null;
        if (this.propertyForm.controls.filter.value) {
            if (this.propertyForm.value.filter.includes(':')) {
                let filterArr = this.propertyForm.controls.filter.value.split(':');
                res = capitalize(filterArr[0].replace('-', ' ')) + ': ' + filterArr[1]
            } else {
                res = capitalize(this.propertyForm.controls.filter.value.replace('-', ' '));
            }
        }
        return res;
    }

}
