import { Pipe, PipeTransform } from '@angular/core';
import { ToilListVariant } from '../lookup/models/toil-list-variant.model';

@Pipe({ name: 'toilTypeSearch' })
export class ToilTypeSearchPipe implements PipeTransform {
  public transform(toilTypes: ToilListVariant[], searchText: any): any {
    if (searchText == '' || toilTypes == null) {
      return toilTypes;
    }

    return toilTypes.filter(toilType =>
        toilType.name.toUpperCase().includes(searchText.toUpperCase())
    );
  }
}
