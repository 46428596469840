export enum api_routes {
    ACCESS_LOGS = 'AccessLogs',
    HISTORY = 'History',
    TALENT = 'Talent',
    DIRECT_REPORTEES = 'DirectReportees',
    EMPLOYMENT_RECORDS = 'EmploymentRecords',
    EMPLOYMENT_RECORD = 'EmploymentRecord',
    CURRENT = 'Current',
    LISTS = 'Lists',
    OPTIONS = 'Options',
    COPY = 'Copy',
    METADATA = 'Metadata',
    TABLES = 'Tables',
    PERMISSIONS = 'Permissions',
    ROLES = 'Roles',
    CURRENT_USER = 'CurrentUser',
    FIELDS = 'Fields',
    LIST_FIELDS = 'ListFields',
    TABLE_LISTS = 'TableLists',
    TIMEZONES = 'Timezones',
    CULTURES = 'Cultures',
    EMERGENCY_CONTACTS = 'EmergencyContacts',
    FAMILYS = 'Familys',
    WORK_LOCATIONS = 'WorkLocations',
    WORK_ROTATIONS = 'WorkRotations',
    COST_CENTERS = 'Positions/CostCenters',
    MEDICAL_TESTS = 'MedicalTests',
    VISAS_PERMITS = 'VisaPermits',
    LANGUAGES = 'Languages',
    TRAINING_AND_CERTIFICATIONS = 'TrainingAndCertifications',
    EDUCATION = 'Education',
    COMPENSATION = 'Compensation',
    PAY_RATE_INTERVALS = 'PayRateIntervals',
    WORK_EXPERIENCES = 'WorkExperiences',
    PROFESSIONAL_EXPERTISES = 'ProfessionalExpertises',
    PROJECTS = 'Projects',
    ASSOCIATIONS = 'Associations',
    PHOTO = 'Photo',
    ICON = 'Icon',
    DOCUMENTS = 'Documents',
    DOWNLOAD = 'Download',
    SYSTEM = 'System',
    GLOBALIZATION = 'Globalization',
    POSITIONS = 'Positions',
    POSITIONS_EMPLOYEES = 'PositionsEmployees',
    OTHER_COMPENSATIONS = 'OtherCompensations',
    COMPANY_ASSETS = 'CompanyAssets',
    RELOCATIONS = 'Relocations',
    GRIEVANCES = 'Grievances',
    INJURY_ILLNESS = 'InjuryIllness',
    BENEFITS = 'Benefits',
    COVERAGES = 'Coverages',
    PLAN_TYPES = 'PlanTypes',
    PLANS = 'Plans',
    GROUPS = 'Groups',
    SECURITY = 'Security',
    RATE_TYPES = 'RateTypes',
    WAIT_PERIOD_LENGTH_UNITS = 'WaitPeriodLengthUnits',
    DEDUCTION_FREQUENCIES = 'DeductionFrequencies',
    GROUP_PLAN_ELIGIBILITIES = 'GroupPlanEligibilities',
    EMPLOYEES = 'Employees',
    EMPLOYEE = 'Employee',
    MANAGERS = 'Managers',
    FORMS = 'Forms',
    FORM_ELEMENT_TYPES = 'FormElementTypes',
    ELEMENTS = 'Elements',
    REPORTS = 'Reports',
    REPORTS_TO_POSITION = 'ReportsToPosition',
    REPORTS_TO = 'ReportsTo',
    ORGANIZATION = 'Organization',
    UNITS = 'Units',
    TIMEOFF = 'TimeOff',
    TYPES = 'Types',
    USERS = 'Users',
    SET_CURRENT_CULTURE = 'SetCurrentCulture',
    GET_CURRENT_CULTURE = 'GetCurrentCulture',
    ABSENCES = 'Absences',
    PASSWORD = 'Password',
    ORGANIZATIONS = 'Organizations',
    LOGIN_HISTORIES = 'LoginHistories',
    PARENT_ORGANIZATION = "ParentOrganization",
    TABLE_SECURITY = 'TableSecurity',
    BANK_DETAIL = 'BankDetail',
    BANK_DETAILS = 'BankDetails',
    CONTACT_CARDS = 'ContactCards',
    COUNTRIES = 'Countries',
    EMPLOYMENT_DETAILS = 'EmploymentDetail',
    TIME_OFF_DETAIL = 'TimeOffDetail',
    MANUAL_ADJUSTMENTS = 'ManualAdjustments',
    BASIC_EMPLOYEES = 'BasicEmployees',
    PAYROLL_DETAIL = 'PayrollDetail',
    HEADCOUNT = 'Headcount',
    MY_ABSENCES = 'MyAbsences',
    GENERATE_SCHEDULE = 'GenerateSchedule',
    MY_WORK_SCHEDULE = 'MyWorkSchedule',
    SCHEDULES = 'Schedules',
    GENERATE_SHIFTS = 'GenerateShifts',
    EMPLOYEE_WORKING_HOURS = 'EmployeeWorkingHours',
    ROLE_TYPES = 'RoleTypes',
    PROBATION_PERIOD_UNITS = 'ProbationPeriodUnits',
    PERFORMANCE = 'Performance',
    GOAL_TYPES = 'GoalTypes',
    GOAL_TYPE_STATUSES = 'GoalTypeStatuses',
    GOAL_TYPE_RATINGS = 'GoalTypeRatings',
    RATINGS = 'Ratings',
    GOAL_PLANS = 'GoalPlans',
    GOAL_PLAN_STATUSES = 'GoalPlanStatuses',
    GOAL_PLAN_OWNERS = 'GoalPlanOwners',
    GOALS = 'Goals',
    COMMENTS = 'Comments',
    COMPLETION_DATE_TYPES = 'CompletionDateTypes',
    EXPECTED_COMPLETION_DATE_TYPES = 'ExpectedCompletionDateTypes',
    STATUS_TYPES = 'StatusTypes',
    MODULE_CONFIGURATION = 'ModuleConfiguration',
    PUBLISH = 'Publish',
    FIELD_SETTINGS = 'FieldSettings',
    PUBLISH_HISTORIES = 'PublishHistories',
    PREDEFINED = 'Predefined',
    ABSENCE_REPORT = 'AbsenceReport',
    ABSENCE_FINANCE_REPORT = 'FinanceReport',
    GOALS_REPORT = 'GoalsReport',
    OUTSTANDING_ABSENCE_REPORT = '/OutstandingAbsenceReport',
    TOIL = '/Toil',
    RECORDS = '/Records',
    CLASS_TYPES = '/ClassTypes',
    EMPLOYEES_TOIL_POLICIES = '/EmployeesToilPolicies',
    EMPLOYEE_TOIL_POLICIES = '/EmployeeToilPolicies',
    EMPLOYEE_TOIL_REQUESTS = '/EmployeeToilRequests',
    USERS_LOGIN_INFORMATION = '/UsersLoginInformation',
    CALCULATION_LOGS = '/CalculationLogs',
    REVIEW_PLANS = '/ReviewPlans',
    GLOBAL_REVIEWS = '/GlobalReviews',
    REVIEW_PLANS_EMPLOYEES = '/Employees',
    REVIEW_PLANS_OWNERS = '/Owners',
    REVIEW_PLANS_GOAL_PLANS = '/GoalPlans',
    REVIEWERS = '/Reviewers',
    REVIEWS = '/Reviews',
    REVIEWER_TYPES = '/ReviewerTypes',
    REVIEW_RATINGS = '/Ratings',
    RATING_STATUSES = '/RatingStatuses',
    REVIEWER_SECURITY_PERMISSIONS = '/ReviewerSecurityPermissions',
    GLOBAL_GOAL_PLAN_REVIEWS = '/GlobalGoalPlanReviews',
    GLOBAL_GOAL_REVIEWS = '/GlobalGoalReviews',
    INTEGRATION_DETAIL = 'IntegrationDetail',
    BUSINESS_RULE_AUTOMATIONS = 'RuleAutomations',
    DATA_TRIGGERS = 'DataTriggers',
    TRIGGER_TYPES = 'TriggerTypes',
    CONDITION_TYPES = 'ConditionTypes',
    NOTIFICATION_TYPES = 'NotificationTypes',
    NOTIFICATION_RECIPIENT_TYPES = 'RecipientTypes',
    STYLING = 'Styling',
    STYLING_COLOURS = 'StylingColours',
    COMPANY_LOGOS = 'CompanyLogos',
    LOGO = 'Logo',
    SYSTEM_CULTURES = 'SystemCultures',
    SITE_SETTINGS = 'SiteSettings',
    GOAL_SETTINGS = 'GoalSettings',
    FEEDBACK_SETTINGS = 'FeedbackSettings',
}
