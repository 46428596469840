import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/services/translation.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import { EmployeeSecurityService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-security/services/employee-security.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { EmployeeService } from '@app/core/services/employee.service';
import { CurrentUserDetails } from '@app/shared/models/employee.model';
import { LocaleService } from '@app/core/services/locale.service';
import { IntlService, CldrIntlService } from "@progress/kendo-angular-intl";
@Component({
  selector: 'app-translate',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TranslateComponent implements OnInit {
  currentCulture: Culture;
  cultures$: Observable<Culture[]>;
  currentEmployeeDetails: CurrentUserDetails;

  constructor(
    private translate: TranslateService,
    private translationService: TranslationService,
    private localeService: LocaleService,
    private employeeSecurityService: EmployeeSecurityService,
    private cultureService: CultureService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private employeeService: EmployeeService,
    private _adapter: DateAdapter<any>,
    public intlService: IntlService,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
  ) {
    this.currentEmployeeDetails = this.employeeService.getFetchedCurrentUserDetails()
  }

  ngOnInit(): void {
    this.cultures$ = this.cultureService.getCultures();
    this.getCurrentCulture();
  }

  getCurrentCulture() {
    // If there is a current culter set use it
    if(this.employeeSecurityService.currentUserCulture !== null) {
      this.currentCulture = this.employeeSecurityService.currentUserCulture;
      this.useLanguage(this.currentCulture);
    }
    else {
      this.employeeSecurityService.getCurrentEmployeeCulture()
      .subscribe(
        res => {
          this.currentCulture = res;
          // Set current culture variable in frontend
          this.employeeSecurityService.setCurrentUserCulture(this.currentCulture);
          this.useLanguage(this.currentCulture);
        }
      )
    }

  }

  setCurrentCulture(culture: Culture) {
    this.overlayService.show();

    this.employeeSecurityService.setCurrentEmployeeCulture(culture.id)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      res => {
        this.currentCulture = culture;
        this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
        this.useLanguage(this.currentCulture, true);
      }
    )
  }

  useLanguage(culture: Culture, refreshTranslations?: boolean): void {
    this.localeService.registerCulture(culture.id);
    (<CldrIntlService>this.intlService).localeId = culture.id;

    // Set current culture variable in frontend
    this.employeeSecurityService.setCurrentUserCulture(culture);

    // Set the date format local eg. DD-MM-YYYY or MM-DD-YYYY
    this._locale = culture.id;
    this._adapter.setLocale(this._locale);

    this.translate.use(culture.id)
    .subscribe(
      res => {

        if(this.translationService.currentTranslations !== null && refreshTranslations !== true) {
          this.translate.setTranslation(culture.id, this.translationService.currentTranslations, true);
        }
        else {
          this.translationService.getTranslations(culture.id)
          .subscribe(
            translations => {
              this.translationService.currentTranslations = translations;
              this.translate.setTranslation(culture.id, translations, true);
            }
          ); 
        }

      }
    ); 
  }

  isCurrentCulture(culture: Culture): boolean {
    return culture.id === this.currentCulture?.id;
  }
}
