import { Component, OnInit } from '@angular/core';
import { EmployeeService } from '@app/core/services/employee.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { TimezoneService } from '@app/core/services/timezone/timezone.service';
import { Organization } from '@app/modules/organization-structure/models/organization-structure.model';
import { OrganizationStructureService } from '@app/modules/organization-structure/services/organization-structure.service';
import { WorkRotationService } from '@app/modules/work-rotations/services/work-rotation.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import { Timezone } from '@app/shared/models/timezone.model';
import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-add-dummy-work-rotations',
  templateUrl: './add-dummy-work-rotations.component.html',
  styleUrls: ['./add-dummy-work-rotations.component.scss']
})
export class AddDummyWorkRotationsComponent implements OnInit {
  loadingTypes: boolean = true;
  loadingOrgs: boolean = true;
  orgs: Organization[];
  numberOfItems: number = 20;
  organizationTypes: Organization[];
  cultures: Culture[];
  timezones: Timezone[];

  constructor(
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private cultureService: CultureService,
    private workRotationService: WorkRotationService,
  ) { }

  ngOnInit(): void {
    this.getCultures();
  }

  createRandomString(length: number): string {
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, length);
  }

  getCultures() {
    this.cultureService.getCultures()
    .subscribe(
      res => {
        this.cultures = res;
      }
    )
  }

  random_item(items){
    return items[Math.floor(Math.random()*items.length)];
  }

  getNewWorkRotation(): any {
    return {
      effectiveDate: moment().format(),
      changeReason: "",
      changeReasonComments: "",
      customProperties: [],
      id: null,
      name: [
        {
          "culture":"en-CA",
          "text":`Dummy-${this.createRandomString(10)}`
        }
      ],
      workWeeks:[{"sunday":null,"monday":null,"tuesday":null,"wednesday":null,"thursday":null,"friday":null,"saturday":null}]
    }
  }

  createItems() {
    let createItemRequests: Observable<any>[] = [];

    for (let i = 0; i < this.numberOfItems; i++) {
      createItemRequests.push(this.workRotationService.postWorkRotation(this.getNewWorkRotation()))
    }

    this.overlayService.show();
    forkJoin(createItemRequests)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
            this.snackbarService.openSnackBar(`Created ${this.numberOfItems} Work Rotations(s) Successfully`, 'clear', 'success');
        }
    );
  }
}
