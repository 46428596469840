import { Component, Input, OnInit } from '@angular/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { SchedulerEvent } from '@progress/kendo-angular-scheduler';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EmploymentRecordPositionsService } from '../../../employment-records/components/employment-record-details/components/employment-record-positions/services/employment-record-positions.service';
import { EmploymentRecord } from '../../../employment-records/models/employment-record.model';
import { EmploymentRecordsService } from '../../../employment-records/services/employment-records.service';
import { EmployeeLeaveService } from '../employee-leave/services/employee-leave.service';
import { sampleData, displayDate } from "./events-utc";

@Component({
  selector: 'app-employee-roster-demo',
  templateUrl: './employee-roster-demo.component.html',
  styleUrls: ['./employee-roster-demo.component.scss']
})
export class EmployeeRosterDemoComponent implements OnInit {
  public selectedDate: Date = displayDate;
  public events: SchedulerEvent[] = sampleData;
  @Input() employeeID: string;
  isLoading: boolean;
  currentEmploymentRecord: EmploymentRecord;
  // positions$: Observable<EmploymentRecordPosition[]>;
  public displayedColumns: string[] = ['type', 'reason', 'from', 'to', 'status', 'actions'];
  chips = [
    {name: 'All', state: true},
    {name: 'Pending'},
    {name: 'Approved'},
    {name: 'Rejected'}
  ];
  leaveRequests: any;
  leaveRequestsAll: any;

  constructor(
    private employmentRecordPositionsService: EmploymentRecordPositionsService,
    private employmentRecordsService: EmploymentRecordsService,
    private employeeLeaveService: EmployeeLeaveService,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit(): void {
    // this.getcurrentEmploymentRecordId();

    this.employeeLeaveService.getEmployeeLeave()
    .subscribe(
      (res) => {
        this.leaveRequests = res.leaveRequests;
        this.leaveRequestsAll = res.leaveRequests;
      },
      err => {
        console.log(err);
      }
    );

    console.log(this.events)
  }

  getcurrentEmploymentRecordId(): void {
    this.isLoading = true;

    this.employmentRecordsService.getCurrentEmploymentRecord(this.employeeID)
    .pipe(
        finalize(()=>{ this.isLoading = false; })
    )
    .subscribe(
      res => {
        this.currentEmploymentRecord = res;
        // this.positions$ = this.employmentRecordPositionsService.getPositions(this.employeeID, this.currentEmploymentRecord.id);
      }
    )
  }

  changeSelected(event, chip) {
    console.log(event);
    console.log(chip);
    if(event.selected) {
      if(chip.name === "All") {
        this.leaveRequests = [...this.leaveRequestsAll];
      }
      else {
        this.filterLeaveRequests(chip.name);
      }
    }
  }

  filterLeaveRequests(value) {
    this.leaveRequests = this.leaveRequestsAll.filter( leaveRequest => leaveRequest.status === value);
  }

  changeStatus(leaveRequest, action) {
    let el = this.leaveRequests.find(el => el === leaveRequest);

    el ? el.status = action : null;
    this.snackbarService.openSnackBar('Leave Request Updated Successfully', 'clear', 'success');
  }

}
