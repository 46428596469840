import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Observable} from 'rxjs';
import {OidcAuthService} from "@app/core/services/oidc-auth.service";

export class AddHeaderInterceptor implements HttpInterceptor {
    constructor(private authService: OidcAuthService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let tenantId = this.authService.getTenantId();
        const clonedRequest = req.clone({ headers: req.headers.append('TenantId', tenantId) });

        // Pass the cloned request instead of the original request to the next handle
        return next.handle(clonedRequest);
    }
}
