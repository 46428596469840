<mat-card>
    <mat-card-title class="header">
        <h5 appLocalizationPopupDirective localizationCode="Schedule">{{ 'Schedule' | translate: {Default: "Schedule"} }}</h5>
        <!-- <div>
            <button mat-flat-button color="primary" (click)="openPositionDialog()">
                <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> Create new position
            </button>
        </div> -->
    </mat-card-title>
    <mat-card-content>
        <kendo-scheduler
            [showWorkHours]="true"
            [kendoSchedulerBinding]="events"
            [selectedDate]="selectedDate"
            [selectedViewIndex]="2"
            scrollTime="08:00"
            style="height: 800px;"
        >

            <ng-template
                kendoSchedulerEventTemplate
                let-event="event"
            >
                <div [ngClass]="event.description">
                    {{event.title}}
                </div>
            </ng-template>

            <kendo-scheduler-day-view> </kendo-scheduler-day-view>
        
            <kendo-scheduler-week-view> </kendo-scheduler-week-view>
        
            <kendo-scheduler-month-view> </kendo-scheduler-month-view>
        
            <kendo-scheduler-timeline-view> </kendo-scheduler-timeline-view>
        
            <kendo-scheduler-agenda-view> </kendo-scheduler-agenda-view>
        </kendo-scheduler>

        <div class="right">
            <!-- <div class="positions-container" *ngIf="positions$ | withLoading | async as positions">
                <h6>Positions and Work Rotations</h6>

                <ng-template [ngIf]="positions.value">
                    <mat-list>
                        <ng-container *ngFor="let employmentRecordPosition of positions.value;">
                            <mat-list-item *ngIf="employmentRecordPosition.position && employmentRecordPosition.position.workRotation">
                                {{employmentRecordPosition.position.name}} - {{employmentRecordPosition.position.workRotation.name}}
                            </mat-list-item>
                        </ng-container>
                    </mat-list>
                </ng-template>
    
                <ng-template [ngIf]="positions.error">Error {{ obs.error }}</ng-template>
    
                <ng-template [ngIf]="positions.loading">
                    <ngx-skeleton-loader
                        count="5"
                        [theme]="{ 
                            'height.px': 50
                        }"
                    ></ngx-skeleton-loader>
                </ng-template>
    
                <ng-template [ngIf]="!positions.value && !positions.loading && !positions.error">
                    <div class="no-positions-container">
                        <h6>No positions assigned to this employment record</h6>
                    </div>
                </ng-template>
            </div> -->

            <div class="time-off-container">
                <h6>Time Off</h6>

                <mat-chip-list>
                    <mat-chip *ngFor="let chip of chips" [selected]="chip.state" (selectionChange)="changeSelected($event, chip)" (click)="chip.state=!chip.state">{{chip.name}}</mat-chip>
                </mat-chip-list>
        
                <table *ngIf="leaveRequests != null" mat-table [dataSource]="leaveRequests" class="leave-requests__table" #myTable>
        
                    <ng-container  *ngFor="let column of displayedColumns" matColumnDef="{{column}}" class="leave-requests__table-row">
                        <th mat-header-cell *matHeaderCellDef class="leave-requests__table-row-title">
                        <span *ngIf="column !== 'delete'">{{column}}</span>
                        </th>
                        <td mat-cell *matCellDef="let element" class="leave-requests__table-content">
                        <span *ngIf="column !== 'status' && column !== 'actions'">{{element[column]}}</span>
                        <div *ngIf="column === 'status'" class="leave-requests__content-badge" [ngClass]="element[column]">
                            <span>{{element[column]}}</span>
                        </div>
                        <div *ngIf="column === 'actions'">
                            <button class="settings-button" mat-mini-fab [matMenuTriggerFor]="settings">
                                <mat-icon class="settings-button__icon material-icons-outlined">more_vert</mat-icon>
                            </button>
                            <mat-menu #settings="matMenu" xPosition="before">
                                <button class="settings-menu__item material-icons-outlined" (click)="changeStatus(element, 'Approved')" mat-menu-item>Approve</button>
                                <button class="settings-menu__item material-icons-outlined" (click)="changeStatus(element, 'Rejected')" mat-menu-item>Reject</button>
                                <button class="settings-menu__item material-icons-outlined" (click)="changeStatus(element, 'Pending')" mat-menu-item>Pending</button>
                            </mat-menu>
                            <!-- <mat-icon aria-hidden="false" class="material-icons-outlined leave-requests__actions-icon" aria-label="delete icon">create</mat-icon>
                            <mat-icon (click)="openDialog(element)" aria-hidden="false" class="material-icons-outlined leave-requests__actions-icon" aria-label="delete icon">delete</mat-icon> -->
                        </div>
                        </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>

    </mat-card-content>
</mat-card>
