import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-headcount-by-month-report',
  templateUrl: './headcount-by-month-report.component.html',
  styleUrls: ['./headcount-by-month-report.component.scss']
})
export class HeadcountByMonthReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
