import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { fieldTypes } from '@app/consts';
import { MetadataFormService } from '@app/core/services/metadata/metadata-form.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { FormElementDialogV2Component } from '@app/modules/form-generator/edit-form-v3/components/form-element-dialog-v2/form-element-dialog-v2.component';
import { Form, FormElement, FormElementSubmit, FormElementVerbose } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { TableFieldVerbose } from '@app/modules/security-setup/models/table-field.model';
import { SecuritySetupService } from '@app/modules/security-setup/services/security-setup.service';
import { EmployeeSecurityService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-security/services/employee-security.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Localization } from '@app/shared/models/localization.model';
import { Culture } from '@app/shared/models/system-language/culture.model';
import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DemoFormDialogComponent } from '../demo-form-dialog/demo-form-dialog.component';
import { EmployeeService } from '@app/core/services/employee.service';
import { CurrentUserDetails } from '@app/shared/models/employee.model';

@Component({
  selector: 'app-form-elements-display',
  templateUrl: './form-elements-display.component.html',
  styleUrls: ['./form-elements-display.component.scss']
})
export class FormElementsDisplayComponent implements OnInit {
  public fieldTypes: typeof fieldTypes = fieldTypes;
  @Input() formId: string;
  form: Form;
  loadingFormElements: boolean;
  formElements: FormElement[];
  formElementsToDelete: FormElement[] = [];
  tableFields
  loadingFormDetails: boolean;
  formElementsVerboseArray: FormElementVerbose[] = [];
  dbTableFields: TableFieldVerbose[];
  loadingDbTableFields: boolean = true;
  currentCulture: Culture;
  currentEmployeeDetails: CurrentUserDetails;

  constructor(
    private dialog: MatDialog,
    private metadataFormService: MetadataFormService,
    private snackbarService: SnackbarService,
    private securitySetupService: SecuritySetupService,
    private employeeSecurityService: EmployeeSecurityService,
    private employeeService: EmployeeService,
    private overlayService: OverlayService,
  ) { }

  ngOnInit(): void {
    this.currentEmployeeDetails = this.employeeService.getFetchedCurrentUserDetails()
    this.getCurrentCulture();
    this.getFormDetails();
    // this.getFormElements();
  }

  getCurrentCulture() {
    this.employeeSecurityService.getCurrentEmployeeCulture()
    .subscribe(
      res => {
        this.currentCulture = res;
      }
    )
  }

  isFinishedLoading(): boolean {
    if(!this.loadingFormDetails && !this.loadingFormElements && !this.loadingDbTableFields) {
      return true;
    }
    else {
      return false;
    }
  }

  getFormDetails() {
    this.loadingFormDetails = true;

    this.metadataFormService.getForm(this.formId)
      .pipe(
        finalize(() => {
          this.loadingFormDetails = false;
          this.getFormElements();
        })
      )
      .subscribe(
        res => {
          this.form = res;
        }
      );
  }

  getFormElements() {
    this.loadingFormElements = true;

    this.metadataFormService.getFormElements(this.formId, 0, '1000')
      .pipe(
        finalize(() => {
          this.loadingFormElements = false
        })
        )
      .subscribe(
          res => {
          this.formElements = res.data;
          this.getFormElementsVerbose();
          this.getDbTableField();
        }
      );
  }

  getDbTableField() {
    this.loadingDbTableFields = true;

    let observables: Observable<TableFieldVerbose>[] = [];

    this.formElements.forEach( formElement => {
      if(formElement?.tableField?.id !== undefined){
        observables.push(this.securitySetupService.getField(this.form.table?.id, formElement?.tableField?.id))
      }
    });

    forkJoin(observables)
    .pipe(
      finalize(()=>{
        this.loadingDbTableFields = false;
          this.sortIntoParentsAndChildren();
          // this.buildForm();
      })
    )
    .subscribe(
      res => {
        this.dbTableFields = res;
        this.addDBTableValues();
      }
    );
  }

  addDBTableValues() {
    this.dbTableFields.forEach(
      dbTableField => {
        let formElIndex = this.formElements.findIndex( el => el.tableField?.id === dbTableField?.id)

        if(formElIndex !== -1){
          this.formElements[formElIndex] = this.useDbTableFieldValues(this.formElements[formElIndex], dbTableField)
        }
      }
    )

  }

  useDbTableFieldValues(formElement: FormElement, dbTableField: any) {
    // set field name
    let currentCultureFieldName: Localization[] = dbTableField.name.filter( n => n.culture === this.currentCulture.id );
    formElement.text = currentCultureFieldName[0].text;

    // set field hidden value
    formElement.hidden = dbTableField.enabled ? 'false' : 'true';

    // set required value
    formElement.requiredField = dbTableField.requiredField ? 'true' : 'false';

    // set min length value
    // formElement.minLength = dbTableField.minimumValue;

    // set max length value
    // formElement.maxLength = dbTableField.maximumValue;

    // set the placeholder
    formElement.placeholder = dbTableField.defaultValue;

    return formElement
  }

  sortIntoParentsAndChildren() {
    let childrenToBeRemovedFromFormElementsArray: FormElement[] = [];

    this.formElements.forEach( formElement => {
      //If the form element is a container and it has no children array, add an empty one
      if(formElement.formElementType?.id === fieldTypes.CONTAINER && formElement.children === undefined) {
        formElement.children = [];
      }

      // extract the property values and add them to the form element
      formElement.properties?.forEach(prop => {
        formElement[prop.property] = prop.value;
      })

      if(formElement.parentFormElement !== null) {
        let parentId = formElement.parentFormElement.id;
        let parent = this.formElements.filter(formElement => formElement.id === parentId);

        //If the form element is a child
        if(parent.length === 1){
          //Get the form elements parent index
          let index = this.formElements.indexOf(parent[0]);

          if(index !== -1){
            // create a children array in the parent element
            if(this.formElements[index].children === undefined) {
              this.formElements[index].children = [];
            }

            childrenToBeRemovedFromFormElementsArray.push(formElement)

            // add current form element to the children array of its parent
            this.formElements[index].children.push(formElement);

            this.formElements[index].children.sort((firstItem, secondItem) => parseInt(firstItem.order) - parseInt(secondItem.order))
          }
        }
      }
    });

    // remove any child elements that have been copied to children arrays
    childrenToBeRemovedFromFormElementsArray.forEach( childFormElement => {
      let index = this.formElements.indexOf(childFormElement);

      if(index !== -1) {
        this.formElements.splice(index, 1);
      }
    });

    // sort the form elements by order
    this.formElements.sort((firstItem, secondItem) => parseInt(firstItem.order) - parseInt(secondItem.order))
  }

  openConfirmDeleteDialog(formElement: FormElement) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
        text: `Are you sure you want to delete this (${formElement.text})?`
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.deleteFormElement(formElement);
        }
      }
    );
  }

  openDemoFormDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
        formId: this.formId
    };

    const dialogRef = this.dialog.open(DemoFormDialogComponent, dialogConfig);
  }

  deleteFormElement(formElement: FormElement) {
    this.overlayService.show();

    this.metadataFormService.deleteFormElement(this.formId, formElement.id)
    .pipe(
      finalize( () => {
        this.overlayService.hide();
      })
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar('Form Element Deleted Successfully', 'clear', 'success');
        this.getFormElements();
      }
    );
  }

  openFormElementDialog(formTableId: string, formElement?: FormElement, parent?: FormElement, order?: number) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '80vw';

    dialogConfig.data = {
      formId: this.formId,
      formElementId: formElement ? formElement.id : null,
      parentId: parent ? parent.id : null,
      formTableId: formTableId ? formTableId : null,
      order: order ? order : null
    };

    const dialogRef = this.dialog.open(FormElementDialogV2Component, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data === true) {
          this.getFormElements();
        }
      }
    );
  }

  // reorder formElements array after drag and drop
  drop(event: CdkDragDrop<FormElement[]>, list: FormElement[]) {
    moveItemInArray(list, event.previousIndex, event.currentIndex);
  }

  // get the detailed versions of all the form elements
  getFormElementsVerbose() {
    this.formElementsVerboseArray = [];

    this.formElements.forEach(
      formElement => {
        this.metadataFormService.getFormElement(this.formId, formElement.id)
        .subscribe(
          res => {
            this.formElementsVerboseArray.push(res);
          }
        )
      }
    )
  }

  //the formElements array is in the correct order. for each form element get its detailed version from formElementsVerboseArray, update the order property and save it
  saveFormElementsOrder() {
    let updateRequests: Observable<any>[] = [];

    this.formElements.forEach(
      (formElement, index) => {
        formElement.children?.forEach(
          (childElement, index) => {
            let childFormElementSubmit: FormElementSubmit;
            let childFormElementVerbose = this.formElementsVerboseArray.find( element => element.id === childElement.id )

            childFormElementVerbose = this.addNewOrderValue(childFormElementVerbose, index);
            childFormElementSubmit = this.createFormElementSubmit(childFormElementVerbose);
            updateRequests.push(this.metadataFormService.updateFormElement(this.formId, childFormElementVerbose.id, childFormElementSubmit))
          }
        )

        let formElementSubmit: FormElementSubmit;
        let formElementVerbose = this.formElementsVerboseArray.find( element => element.id === formElement.id )

        formElementVerbose = this.addNewOrderValue(formElementVerbose, index);

        formElementSubmit = this.createFormElementSubmit(formElementVerbose);

        updateRequests.push(this.metadataFormService.updateFormElement(this.formId, formElementVerbose.id, formElementSubmit))
      }
    )


    this.overlayService.show();
    forkJoin(updateRequests)
    .pipe(
      finalize( () => {
        this.getFormElements();
        this.overlayService.hide();
      })
    )
    .subscribe(
      res => {
        this.snackbarService.openSnackBar('Form Element Order Saved Successfully', 'clear', 'success');
      }
      )
  }

  addNewOrderValue(formElementVerbose: FormElementVerbose, index: number): FormElementVerbose {
    //remove any order property value it has
    formElementVerbose.properties = formElementVerbose.properties.filter(property => property.property !== 'order');

    //add a new order property value
    formElementVerbose.properties.push(
      {
        property: 'order',
        value: index.toString()
      }
    )

    return formElementVerbose;
  }

  createFormElementSubmit(formElementVerbose: FormElementVerbose): FormElementSubmit {
    return {
      id: formElementVerbose.id,
      asOf: moment().format(),
      changeReason: null,
      changeReasonComments: null,
      name: formElementVerbose.text,
      properties: formElementVerbose.properties,
      formElementType: formElementVerbose.formElementType?.id,
      parentFormElement: formElementVerbose.parentFormElement?.id,
      tableField: formElementVerbose.tableField?.id
    }
  }
}
