import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { routes } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { finalize } from 'rxjs/operators';
import { PositionsService } from '../../../positions/services/positions.service';
import { ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { ChangeReasonDialogComponent } from '@app/shared/components/change-reason-dialog/change-reason-dialog.component';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';


@Component({
    selector: 'app-new-position-form-dialog',
    templateUrl: './position-form-dialog.component.html',
    styleUrls: ['./position-form-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PositionFormDialogComponent implements OnInit {

    public routes: typeof routes = routes;
    formId: string = 'frm_fAyLSe2p9XQkC4';
    changeReasonFormId: string = 'frm_cwTMKxheRUNZud';
    getFormData: boolean = false;
    formData: any;
    formValid: boolean = false;
    reportsTo: string = null
    position: any = null
    effectiveDate = new Date();
    isLoading: boolean = true;
    targetEmployeeTablePermissions: EmployeeTableSecurity[];

    constructor(
        private dialog: MatDialog,
        private snackbarService: SnackbarService,
        private overlayService: OverlayService,
        private positionsService: PositionsService,
        private translate: TranslateService,
        private dialogRef: MatDialogRef<PositionFormDialogComponent>,
        public router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.position = data.position
        this.targetEmployeeTablePermissions = data.targetEmployeeTablePermissions
    }

    ngOnInit(): void {
        this.createFormData();
    }

    getPosition() {
        this.isLoading = true;

        let asOf = moment(this.effectiveDate).format('YYYY-MM-DD');

        this.positionsService.getPosition(this.position.id, asOf)
        .pipe(
            finalize(() => this.isLoading = false)
        )
        .subscribe(
            res => {
                this.position = res;
                this.createFormData();
            }
        );
    }

    createFormData() {
        this.isLoading = true;

        this.formData = {
            id: this.position ? this.position.id : null,
            clientPositionId: this.position ? this.position.clientPositionId : null,
            startDate: this.position ? this.position.startDate : null,
            endDate: this.position ? this.position.endDate : null,
            organization: this.position ? this.position.organization : null,
            assistant: this.position ? this.position.assistant : false,
            functionalJobTitle: this.position && this.position.functionalJobTitle ? this.position.functionalJobTitle.id : null,
            region: this.position && this.position.region ? this.position.region.id : null,
            division: this.position && this.position.division ? this.position.division.id : null,
            subDivision: this.position && this.position.subDivision ? this.position.subDivision.id : null,
            department: this.position && this.position.department ? this.position.department.id : null,
            workLocation: this.position ? this.position.workLocation?.id : null,
            workRotationStartDate: this.position ? this.position.workRotationStartDate : null,
            workRotation: this.position ? this.position.workRotation?.id : null,
            jobGroup: this.position && this.position.jobGroup ? this.position.jobGroup.id : null,
            jobFamily: this.position && this.position.jobFamily ? this.position.jobFamily.id : null,
            jobBand: this.position && this.position.jobBand ? this.position.jobBand.id : null,
            jobType: this.position && this.position.jobType ? this.position.jobType.id : null,
            jobStep: this.position && this.position.jobStep ? this.position.jobStep.id : null,
            projectTeam: this.position && this.position.projectTeam ? this.position.projectTeam.id : null,
            marketPositionTitle: this.position && this.position.marketPositionTitle ? this.position.marketPositionTitle.id : null,
            marketPosition: this.position && this.position.marketPosition ? this.position.marketPosition.id : null,
            marketView: this.position && this.position.marketView ? this.position.marketView.id : null,
            unionCode: this.position && this.position.unionCode ? this.position.unionCode.id : null,
            shiftCode: this.position && this.position.shiftCode ? this.position.shiftCode.id : null,
            employmentGroup: this.position && this.position.employmentGroup ? this.position.employmentGroup.id : null,
            employeeCategory: this.position && this.position.employeeCategory ? this.position.employeeCategory.id : null,
            employmentType: this.position && this.position.employmentType ? this.position.employmentType.id : null,
            enforceSlotLimit: this.position ? this.position.enforceSlotLimit : false,
            numberOfSlots: this.position ? this.position.numberOfSlots : 1,
            slotsAvailable: this.position ? this.position.slotsAvailable : null,
            slotsFilled: this.position ? this.position.slotsFilled : null,
            parentPosition: this.position?.parentPosition?.id ? this.position.parentPosition.id : null,
            name: this.position ? this.position.name : null,
            costCenters: this.position ? this.position.costCenters : null,
            fte: this.position ? this.position.fte : null,
            udl1: this.position ? this.position.udl1?.id : null,
            udl2: this.position ? this.position.udl2?.id : null,
            udt1: this.position ? this.position.udt1 : null,
            udt2: this.position ? this.position.udt2 : null,
            udd1: this.position ? this.position.udd1 : null,
            udd2: this.position ? this.position.udd2 : null,
        };

        this.isLoading = false;
    }

    formDataEmitted(formData) {
        this.openChangeReasonDialog(formData);
    }

    formStatusUpdated(formValid) {
        this.formValid = formValid;
    }

    openChangeReasonDialog(formData: any) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
          formId: this.changeReasonFormId,
        };

        const dialogRef = this.dialog.open(ChangeReasonDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
          data => {
              if (data) {
                  this.addChangeReasonsToFormData(formData, data);
              }
          }
        );
    }

    addChangeReasonsToFormData(formData: any, changeReasonFormData: any) {
        let merged = {...formData, ...changeReasonFormData};
        this.save(merged);
    }

    save(formData: any) {
        // Add asof and originalCreateOn values for future effective dating
        formData.asOf = moment(this.effectiveDate).format('YYYY-MM-DD');
        formData.originalCreatedOn = this.position?.version?.createdOn ? this.position?.version?.createdOn : null;

        // Adding the organization id to the form data for saving
        formData.organization = formData.organization?.id;
        if (formData.startDate) {formData.startDate = moment(formData.startDate).format().slice(0, 10);}
        if (formData.endDate) {formData.endDate = moment(formData.endDate).format().slice(0, 10);}

        if (this.position) {
            this.positionsService.putPosition(this.position.id, formData)
                .pipe(
                    finalize(() => this.overlayService.hide())
                )
                .subscribe(
                    (res) => {
                        this.formData = formData
                        this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
                        this.close(res)
                    }
                );
        } else {
            this.positionsService.postPosition(formData)
                .pipe(
                    finalize(() => this.overlayService.hide())
                )
                .subscribe(
                    (res) => {
                        this.formData = formData
                        this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
                        this.close(res)
                    }
                );
        }
    }

    close(payload?: any) {
        this.dialogRef.close({ result: payload, formData: this.formData });
    }

    effectiveDateChange(event) {
        if(this.position){
            this.getPosition();
        }
    }
}

