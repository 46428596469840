import { Component, OnInit } from '@angular/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';

@Component({
  selector: 'app-integration-configuration',
  templateUrl: './integration-configuration.component.html',
  styleUrls: ['./integration-configuration.component.scss']
})
export class IntegrationConfigurationComponent implements OnInit {
  selectedIntegration: any;
  integrations: any[] = [
    {
      name: 'ADP Payroll',
      connectionSettings: {
        clientId: '6783',
        username: 'hubbubapi',
        password: '',
      }
    }
  ];

  selectedTable: any;
  tables = [
    {
      name: 'Employee',
      fields: [
        'Actual Amount',
        'Actual Percentage',
        'Address Type',
        'Alternate Title',
        'Assistant',
        'Base Salary',
        'Base Salary - Local',
        'Base Salary - Local Currency',
        'Base Salary Currency',
        'Base Salary Non GMG',
        'Base Salary Non GMG Currency',
      ]
    },
  ];

  selectedMappedField: any;
  fields = [
    {
      name: 'Employee - Base Salary',
      details: {
        integrationFieldName: 'Base Salary',
        fieldLocation: 'Custom Form',
        newLookup: true,
      }
    },
    {
      name: 'Employee - Base Salary Currency',
      details: {
        integrationFieldName: 'CurrencyCDN',
        fieldLocation: 'Custom Form',
        newLookup: true,
      }
    },
    {
      name: 'Employee - Budget Authority',
      details: {
        integrationFieldName: 'CurrencyCDN',
        fieldLocation: 'Custom Form',
        newLookup: true,
      }
    },
    {
      name: 'Employee - City',
      details: {
        integrationFieldName: 'CurrencyCDN',
        fieldLocation: 'Custom Form',
        newLookup: true,
      }
    },
    {
      name: 'Employee - Client EEID',
      details: {
        integrationFieldName: 'CurrencyCDN',
        fieldLocation: 'Custom Form',
        newLookup: true,
      }
    },
    {
      name: 'Employee - Code of Conduct Signed',
      details: {
        integrationFieldName: 'CurrencyCDN',
        fieldLocation: 'Custom Form',
        newLookup: true,
      }
    },
    {
      name: 'Employee - Country',
      details: {
        integrationFieldName: 'CurrencyCDN',
        fieldLocation: 'Custom Form',
        newLookup: true,
      }
    },
    {
      name: 'Employee - Date Of Birth',
      details: {
        integrationFieldName: 'CurrencyCDN',
        fieldLocation: 'Custom Form',
        newLookup: true,
      }
    },
    {
      name: 'Employee - Department',
      details: {
        integrationFieldName: 'CurrencyCDN',
        fieldLocation: 'Custom Form',
        newLookup: true,
      }
    },
    {
      name: 'Employee - Division',
      details: {
        integrationFieldName: 'CurrencyCDN',
        fieldLocation: 'Custom Form',
        newLookup: true,
      }
    },
    {
      name: 'Employee - Email Address',
      details: {
        integrationFieldName: 'CurrencyCDN',
        fieldLocation: 'Custom Form',
        newLookup: true,
      }
    },
    {
      name: 'Employee - Employment Status',
      details: {
        integrationFieldName: 'CurrencyCDN',
        fieldLocation: 'Custom Form',
        newLookup: true,
      }
    },
    {
      name: 'Employee - Employment Type',
      details: {
        integrationFieldName: 'CurrencyCDN',
        fieldLocation: 'Custom Form',
        newLookup: true,
      }
    },
    {
      name: 'Employee - First Nations People/Band',
      details: {
        integrationFieldName: 'CurrencyCDN',
        fieldLocation: 'Custom Form',
        newLookup: true,
      }
    },
    {
      name: 'Employee - Frequency',
      details: {
        integrationFieldName: 'CurrencyCDN',
        fieldLocation: 'Custom Form',
        newLookup: true,
      }
    },
  ];
  
  
  // {
  //   name: 'Employee - Base Salary',
  //   details: {
  //     integrationFieldName: 'Base Salary',
  //     fieldLocation: 'Custom Form',
  //     formId: '313',
  //   }
  // },
  constructor(
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
  ) { 
    // this.selectedIntegration = this.integrations[0];
    // this.selectedTable = this.tables[0];
  }

  ngOnInit(): void {
  }

  save() {
    this.overlayService.show();

    setTimeout(() => {
      this.overlayService.hide();
      this.snackbarService.openSnackBar('Saved successfully', 'clear', 'success');
    }, 2000);
  }

}
