import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { EmployeeTableSecurity } from '../models/employee.model';

@Directive({
  selector: '[appTableAccess]'
})
export class TableAccessDirective {

  @Input() appTableAccess: {tableId: string, permission: string, targetEmployeeTablePermissions?: EmployeeTableSecurity[]}[];
  private hasAccess = false;

  constructor(
    private tablePermissionsService: TablePermissionsService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ){}

  ngOnInit() {
    this.appTableAccess.forEach(
      appTableAccess => {
        if(!this.hasAccess) {
          switch(appTableAccess?.permission) {
            case 'update':
              this.hasAccess = this.tablePermissionsService.hasUpdatePermission(appTableAccess.tableId, appTableAccess.targetEmployeeTablePermissions);
              break;
            case 'create':
              this.hasAccess = this.tablePermissionsService.hasCreatePermission(appTableAccess.tableId, appTableAccess.targetEmployeeTablePermissions);
              break;
            case 'delete':
              this.hasAccess = this.tablePermissionsService.hasDeletePermission(appTableAccess.tableId, appTableAccess.targetEmployeeTablePermissions);
              break;  
            case 'read':
              this.hasAccess = this.tablePermissionsService.hasReadPermission(appTableAccess.tableId, appTableAccess.targetEmployeeTablePermissions);
          }
        }
      }
    )

    if(this.hasAccess) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
    else {
      this.viewContainer.clear();
    }
  }

}
