import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { miscellaneous, routes } from '@app/consts';
import { DataBindingDirective, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { finalize } from 'rxjs/operators';
import { BenefitsPlan } from '../../../benefits-plans/models/benefits-plan.model';
import { BenefitsPlansService } from '../../../benefits-plans/services/benefits-plans.service';
import { BenefitsPlanType } from '../../models/benefits-plan-type.model';
import { BenefitsPlanTypesService } from '../../services/benefits-plan-types.service';

@Component({
  selector: 'app-benefits-plan-type-details',
  templateUrl: './benefits-plan-type-details.component.html',
  styleUrls: ['./benefits-plan-type-details.component.scss']
})
export class BenefitsPlanTypeDetailsComponent implements OnInit {
  public miscellaneous: typeof miscellaneous = miscellaneous;
  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public mySelection: string[] = [];
  public routes: typeof routes = routes;
  public sort: SortDescriptor[] = [];
  pageSize: number = 20;
  skip: number = 0;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  filterString: string;
  searchFilterString: string;
  searchValue: string = "";
  sortString: string;

  planTypeId: string;
  planTypes: BenefitsPlanType[];
  planType: BenefitsPlanType;
  plans: BenefitsPlan[];
  loadingPlans: boolean = true;
  getPlansRequest: any;

  constructor(
    private route: ActivatedRoute,
    private benefitsPlanTypesService: BenefitsPlanTypesService,
    private benefitsPlansService: BenefitsPlansService
  ) { }

  ngOnInit(): void {
    this.getPlanTypeIdInURL();
    this.getPlanType();
  }

  getPlanTypeIdInURL() {
    this.route.paramMap.subscribe(
      params => this.planTypeId = params.get("planTypeId")
    );
  }

  getPlanType() {
    this.benefitsPlanTypesService.getPlanTypes()
    .subscribe(
      res => {
        this.planTypes = res;
        this.setPlanType();
      }
    )
  }

  setPlanType() {
    let filteredPlanTypes = this.planTypes.filter( planType => planType.id === this.planTypeId );

    filteredPlanTypes.length === 1 ? this.planType = filteredPlanTypes[0] : null;

    this.getPlans();
  }

  getPlans() {
    let filterString;
    this.loadingPlans = true;

    if(this.searchValue !== ""){
      filterString = `(planType = "${this.planType.id}") AND (name like "${this.searchValue}")`;
    }
    else {
      filterString = `(planType = "${this.planType.id}")`;
    }

    this.getPlansRequest = this.benefitsPlansService.getPlans(null, filterString, String(this.pageSize), this.skip)
    .pipe(
      finalize( () => { this.loadingPlans = false } )
    )
    .subscribe(
      res => {
        this.gridDataResult = {
          data: res.data,
          total: res.total,
        }
        this.gridData = res.data;
      }
    )
  }

  search(){
    this.getPlansRequest.unsubscribe();
    this.getPlans();
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.getPlans();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;

    if (sort[0].dir === undefined) {
      this.sortString = null
    }
    else {
      //use regex to get column field to sort with
      let field: any;

      //if it is an object category like (department.text) trim .text from the end, else it doesnt need to be trimmed
      (sort[0].field).match(/.+(?=\.)/) === null
      ? field = sort[0].field
      : field = (sort[0].field).match(/.+(?=\.)/)
      this.sortString = `${field}-${sort[0].dir}`;
    }

    this.getPlans();
  }
}
