import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EmploymentRecord, EmploymentRecordSubmit, ProbationPeriodUnit } from '../models/employment-record.model';
import { api_routes } from '@app/consts';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import * as moment from 'moment';
import { EnvironmentService } from "@app/core/services/environment.service";
import { StringMappingType } from 'typescript';


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})

export class EmploymentRecordsService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getEmploymentRecords(employeeId: string, skip?: number, take?: string): Observable<PagedData<EmploymentRecord>> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.EMPLOYMENT_RECORDS}`, {
      headers: headers,
      params: params
    });
  }

  getCurrentEmploymentRecord(employeeId: string): Observable<EmploymentRecord> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.EMPLOYMENT_RECORDS}/${api_routes.CURRENT}`, {
      headers: headers
    });
  }

  getEmploymentRecord(employeeId: string, employmentRecordId: string): Observable<EmploymentRecord> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.EMPLOYMENT_RECORDS}/${employmentRecordId}`, {
      headers: headers
    });
  }

  postEmploymentRecord(employeeId: string, employmentRecord: EmploymentRecordSubmit): Observable<EmploymentRecord> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.EMPLOYMENT_RECORDS}`, employmentRecord, {
      headers: headers
    });
  }

  updateEmploymentRecord(employeeId: string, employmentRecord: EmploymentRecordSubmit): Observable<EmploymentRecord> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.EMPLOYMENT_RECORDS}/${employmentRecord.id}`, employmentRecord, {
      headers: headers
    });
  }

  deleteEmploymentRecord(employeeId: string, employmentRecordId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.EMPLOYMENT_RECORDS}/${employmentRecordId}`, {
      headers: headers
    });
  }

  getProbationPeriodUnits(skip?: number, take?: string, filterString?: string): Observable<PagedData<ProbationPeriodUnit>> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    filterString ? params = params.append('Filter', filterString) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${api_routes.PROBATION_PERIOD_UNITS}`, {
        headers: headers,
        params: params
      });
  }

  getAccessLogs(url_part: string, skip?: number, take?: string): Observable<PagedData<any>> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${url_part}/${api_routes.ACCESS_LOGS}`, {
      headers: headers,
      params: params
    });
  }

  getHistory(url_part?: string, skip?: number, take?: string): Observable<PagedData<any>> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${url_part}/${api_routes.HISTORY}`, {
      headers: headers,
      params: params
    }).pipe(
      tap(res => {
        res.data.forEach(row => {
          if (row.version != null) {
            row.version.createdOn = row.version.createdOn ? (moment(row.version.createdOn).format('MMM-DD-YYYY h:mm:ss a')) : null
            row.version.effectiveDate = row.version.effectiveDate ? (moment(row.version.effectiveDate).format('MMM-DD-YYYY')) : null
            row.version.createdBy.fullName = row.version.createdBy.firstName + " " + row.version.createdBy.lastName
          }
        })
        return res;
      })
    );
  }

  public async queryAllHistory(url_part?: string, skip?: number, take?: string):
      Promise<PagedData<any>> {
      let dataToReturn: PagedData<any> = {
        data: [],
        skip: null,
        take: null,
        totalPages: null,
        total: null
      }; 

      while(dataToReturn.total === null || skip < dataToReturn.total) {
        const res = await this.getHistory(url_part, skip, take).toPromise();;

        if (res && res.data && res.data.length > 0) {
          dataToReturn.data = dataToReturn.data.concat(res.data);
          skip += parseInt(take);
          dataToReturn.total = res.total;
        } else {
            break;
        }
      }

      return dataToReturn
  }

}
