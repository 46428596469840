<h5 mat-dialog-title appLocalizationPopupDirective [localizationCode]="'RequestToil'">
    {{ 'RequestToil' | translate: {Default: "Request Time Off in Lieu"} }}
</h5>

<mat-dialog-content>
    <ng-container *ngIf="!form; else loaded">
        <ngx-skeleton-loader
            count="5"
            [theme]="{
                'height.px': 50
            }"
        ></ngx-skeleton-loader>
    </ng-container>

    <ng-template #loaded>
        <div class="working-hours-container">
            <ngx-skeleton-loader
                *ngIf="loadingWorkingHours"
                count="1"
                [theme]="{
                    height: '50px',
                    width: '100%'
                }"
            ></ngx-skeleton-loader>

            <div class="alert" *ngIf="workingHours === 0">
                <app-bootstrap-alert 
                    [text]="translate.instant('ToilRequestZeroWorkingHoursWarning')" 
                    localizationCode="ToilRequestZeroWorkingHoursWarning" 
                    type="danger">
                </app-bootstrap-alert>
            </div>

            <div class="page-description-section" *ngIf="!loadingWorkingHours && workingHours">
                <span appLocalizationPopupDirective [localizationCode]="'ThisTimeOffRequestIsFor'">{{ 'ThisTimeOffRequestIsFor' | translate: {Default: "This time off request is for"} }}</span> {{calculatedWorkingHours}} <span appLocalizationPopupDirective [localizationCode]="'WorkingHours'">{{ 'WorkingHours' | translate: {Default: "working hours"} }}</span>
            </div>
        </div>

        <form [formGroup]="form">
            <mat-form-field appearance="fill">
                <mat-label>
                    {{formElements[0].text}}
                </mat-label>

                <input matInput [matDatepicker]="frompicker" formControlName="startDate">
                <span fxLayout="row" matSuffix>
                    <mat-datepicker-toggle [for]="frompicker"></mat-datepicker-toggle>
                </span>
                <mat-datepicker #frompicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>
                    {{formElements[1].text}}
                </mat-label>

                <input matInput [min]="startDateValue" [matDatepicker]="topicker" formControlName="endDate">
                <span fxLayout="row" matSuffix>
                    <mat-datepicker-toggle [for]="topicker"></mat-datepicker-toggle>
                </span>
                <mat-datepicker #topicker></mat-datepicker>

                <div class="error-text" *ngIf="form.get('endDate').errors?.matDatepickerMin" appLocalizationPopupDirective [localizationCode]="'MustBeAfterFrom'">
                    {{ 'MustBeAfterFrom' | translate: {Default: "Must be same or after From date"} }}
                </div>
            </mat-form-field>

            <div class="slide-toggle-container" *ngIf="startDateValue != null && startDateValue !== '' && startDateValue === endDateValue">
                <mat-slide-toggle setColor="primaryColour" formControlName="halfDay">
                    {{formElements[5].text}}
                </mat-slide-toggle>
            </div>

            <div class="slide-toggle-container" *ngIf="startDateValue != null && startDateValue !== '' && startDateValue === endDateValue">
                <mat-slide-toggle setColor="primaryColour" formControlName="setTime">
                    {{ 'SetTime' | translate: {Default: "Set Time"} }}
                </mat-slide-toggle>
            </div>

            <ng-container *ngIf="form.controls.setTime.value">
                <mat-form-field appearance="fill">
                    <mat-label>{{formElements[2].text}}</mat-label>
                    <input
                        matInput
                        aria-label="start time"
                        [ngxTimepicker]="startTime"
                        [format]="24"
                        formControlName="startTime"
                    />
                    <ngx-material-timepicker #startTime>
                    </ngx-material-timepicker>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>{{formElements[3].text}}</mat-label>
                    <input
                        matInput
                        aria-label="end time"
                        [ngxTimepicker]="endTime"
                        [format]="24"
                        formControlName="endTime"
                    />
                    <ngx-material-timepicker #endTime>
                    </ngx-material-timepicker>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>
                        {{formElements[4].text}}
                    </mat-label>

                    <input matInput type="number" min="0.5" max="24" formControlName="hours">
                </mat-form-field>
            </ng-container>

            <mat-form-field appearance="fill">
                <mat-label>
                    {{formElements[6].text}}
                </mat-label>

                <textarea matInput formControlName="comments"></textarea>
            </mat-form-field>
        </form>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button class="close" appPreventDoubleClick (throttledClick)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
    <button mat-raised-button setColor="primaryColour" color="primary" [disabled]="!form?.valid" appPreventDoubleClick (throttledClick)="submitForm()">{{ 'Save' | translate: {Default: "Save"} }}</button>
</mat-dialog-actions>
