import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { db_tables, features, miscellaneous, routes } from '@app/consts' ;
import * as moment from 'moment';
import 'moment-precise-range-plugin';
import { ViewEncapsulation } from '@angular/core';
import { EmployeeContactCardService } from './components/employee-contact-card/services/employee-contact-card.service';
import { Observable } from 'rxjs';
import { EmployeeContactCard } from './components/employee-contact-card/models/employee-contact-card.model';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { finalize } from 'rxjs/operators';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { modules } from '@app/consts/modules';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { FeatureService } from '@app/core/services/feature.service';
declare module 'moment' {
  function preciseDiff(start: string | Date | moment.Moment, end: string | Date | moment.Moment, convertToObject?: boolean): any;
}

@Component({
  selector: 'app-employee-layout',
  templateUrl: './employee-layout.component.html',
  styleUrls: ['./employee-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmployeeLayoutComponent implements OnInit {
  public routes: typeof routes = routes;
  public miscellaneous: typeof miscellaneous = miscellaneous;
  public modules: typeof modules = modules;
  public features: typeof features = features;
  @Input() selectedComponent: string;
  employeeId: any = '';
  employeeStatus: {
    name: string,
    time: string,
    icon: string
  } = {
    name: '',
    time: '',
    icon: '',
  };
  
  public db_tables = db_tables;
  employeeContactCard$: Observable<EmployeeContactCard>;
  isLoadingPermissions: boolean = true;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];
  user$: any;

  constructor(
    public router: Router,
    private oidcAuthService: OidcAuthService,
    private employeeContactCardService: EmployeeContactCardService,
    private tablePermissionsService: TablePermissionsService,
    private featureService: FeatureService,
    private route: ActivatedRoute,
  ) {
    this.user$ = this.oidcAuthService.userProfile;
  }

  ngOnInit() {
    this.employeeId = this.getEmployeeId();
    this.employeeContactCard$ = this.employeeContactCardService.getEmployeeContactCard(this.employeeId);
    this.getTargetEmployeeTablePermissions();
  }

  getTargetEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeId);

    // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
    if(currentFetchedData !== null){
      this.targetEmployeeTablePermissions = currentFetchedData;
      this.isLoadingPermissions = false;
    }
    else {
      this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeId)
      .pipe(
        finalize(() => {
            this.isLoadingPermissions = false;
        })
      )
      .subscribe(
        res => {
          this.targetEmployeeTablePermissions = res;
        }
      );
    }
  }

  get employmentRecordReadAccess() {
    return {
      tableId: db_tables.EmploymentRecords,
      permission: 'read'
    }
  }

  get employeePositionReadAccessObj() {
    return {
      tableId: db_tables.PositionsEmployees,
      permission: 'read',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  getEmployeeId(): string {
    let employeeId: string;
    
    this.route.paramMap.subscribe(
      params => employeeId = params.get("id")
    )
      
    return employeeId;
  }

  hasAccessToGoals(): boolean {
    if(this.employeeId === this.user$.userId && this.featureService.hasFeatureAccess(features.PERFORMANCE_MY_GOALS)){
      return true;
    }
    else if(this.employeeId !== this.user$.userId && this.featureService.hasFeatureAccess(features.PERFORMANCE_DIRECT_REPORTEE_GOALS)) {
      return true;
    }
    else {
      return false;
    }
  }

  changeRoute(route: string): void {
    this.router.navigateByUrl(`${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}/${route}`);
  }

  
}
