<div class="redirect">
    <div class="reirect__logo-wrapper">
        <div class="center">
            <img class="reirect__logo-img" src="./assets/HubbubHrLogo.svg" alt="logo">
            <div class="redirect-message"><h2>Redirecting</h2></div>
            <div class="loading">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        </div>
        <!-- <h6 class="reirect__logo-title">Hubbub HR</h6> -->
    </div>
</div>