<app-layout>
    <div class="navigation-menu-list main-container">
        <div class="menu">
            <mat-list>
                <mat-list-item 
                    *ngFor="let item of navList" 
                    [routerLink]="item.link" 
                    routerLinkActive="primaryColor" 
                    class="primaryHoverColor"
                    appLocalizationPopupDirective 
                    [localizationCode]="item.text"
                    setColor
                    [setColorRules]="[
                        {
                            color: 'primaryColour',
                            events: ['hover']
                        },
                        {
                            color: 'primaryColour',
                            setIfClass: ['primaryColor'],
                            events: ['routerLinkActive']
                        },
                    ]"
                >
                    <mat-icon 
                    setColor
                    [setColorRules]="[
                        {
                            color: 'primaryColour',
                            events: ['hover']
                        },
                        {
                            color: 'primaryColour',
                            setIfClass: ['primaryColor'],
                            events: ['routerLinkActive']
                        },
                    ]"
                    routerLinkActive="primaryColor"
                    class="material-icons-outlined">{{item.icon}}</mat-icon>
                    <div>{{ translate.instant(item.text) }}</div>
                </mat-list-item>
            </mat-list>
        </div>
        <div class="content">
            <div class="main-content">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</app-layout>
