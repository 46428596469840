<h5 mat-dialog-title>Employment Record: Compensation</h5>

<mat-dialog-content>
    <form class="form-container" *ngIf="form" [formGroup]="form">
        <mat-form-field  appearance="fill">
            <mat-label>
                Start Date
            </mat-label>
        
            <input matInput [matDatepicker]="startDatepicker" formControlName="startDate">
            <span fxLayout="row" matSuffix>
                <mat-datepicker-toggle  [for]="startDatepicker"></mat-datepicker-toggle>
            </span>
            <mat-datepicker #startDatepicker></mat-datepicker> 
            <mat-hint></mat-hint>
        </mat-form-field>

        <mat-form-field  appearance="fill">
            <mat-label>
                End Date
            </mat-label>
        
            <input matInput [matDatepicker]="endDatepicker" formControlName="endDate">
            <span fxLayout="row" matSuffix>
                <mat-datepicker-toggle  [for]="endDatepicker"></mat-datepicker-toggle>
            </span>
            <mat-datepicker #endDatepicker></mat-datepicker> 
            <mat-hint></mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Term
            </mat-label>

            <mat-select disableOptionCentering formControlName="term">
                <mat-option *ngFor="let termOption of termOptions | async" [value]="termOption.id">{{termOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Currency
            </mat-label>

            <mat-select disableOptionCentering formControlName="currency">
                <mat-option *ngFor="let currencyOption of currencyOptions | async" [value]="currencyOption.id">{{currencyOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Market View
            </mat-label>

            <mat-select disableOptionCentering formControlName="marketView">
                <mat-option *ngFor="let marketViewOption of marketViewOptions | async" [value]="marketViewOption.id">{{marketViewOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Pay Type
            </mat-label>

            <mat-select disableOptionCentering formControlName="payType">
                <mat-option *ngFor="let payTypeOption of payTypeOptions | async" [value]="payTypeOption.id">{{payTypeOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Pay Grade
            </mat-label>

            <mat-select disableOptionCentering formControlName="payGrade">
                <mat-option *ngFor="let payGradeOption of payGradeOptions | async" [value]="payGradeOption.id">{{payGradeOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Role Group
            </mat-label>

            <mat-select disableOptionCentering formControlName="roleGroup">
                <mat-option *ngFor="let roleGroupOption of roleGroupOptions | async" [value]="roleGroupOption.id">{{roleGroupOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Role Category
            </mat-label>

            <mat-select disableOptionCentering formControlName="roleCategory">
                <mat-option *ngFor="let roleCategoryOption of roleCategoryOptions | async" [value]="roleCategoryOption.id">{{roleCategoryOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Pay Scale
            </mat-label>

            <mat-select disableOptionCentering formControlName="payScale">
                <mat-option *ngFor="let payScaleOption of payScaleOptions | async" [value]="payScaleOption.id">{{payScaleOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Pay Level
            </mat-label>

            <mat-select disableOptionCentering formControlName="payLevel">
                <mat-option *ngFor="let payLevelOption of payLevelOptions | async" [value]="payLevelOption.id">{{payLevelOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Pay Frequency
            </mat-label>

            <mat-select disableOptionCentering formControlName="payFrequency">
                <mat-option *ngFor="let payFrequencyOption of payFrequencyOptions | async" [value]="payFrequencyOption.id">{{payFrequencyOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Overtime Eligible
            </mat-label>

            <mat-select disableOptionCentering formControlName="overtimeEligible">
                <mat-option *ngFor="let overtimeEligibleOption of overtimeEligibleOptions | async" [value]="overtimeEligibleOption.id">{{overtimeEligibleOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                STI IncentivePlan
            </mat-label>

            <mat-select disableOptionCentering formControlName="stiIncentivePlan">
                <mat-option *ngFor="let stiIncentivePlanOption of stiIncentivePlanOptions | async" [value]="stiIncentivePlanOption.id">{{stiIncentivePlanOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                STI Currency
            </mat-label>

            <mat-select disableOptionCentering formControlName="stiCurrency">
                <mat-option *ngFor="let currencyOption of currencyOptions | async" [value]="currencyOption.id">{{currencyOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                LTI Psu
            </mat-label>

            <mat-select disableOptionCentering formControlName="ltiPsu">
                <mat-option *ngFor="let ltiPsuOption of ltiPsuOptions | async" [value]="ltiPsuOption.id">{{ltiPsuOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                LTI Stock Option
            </mat-label>

            <mat-select disableOptionCentering formControlName="ltiStockOption">
                <mat-option *ngFor="let ltiStockOptionOption of ltiStockOptionOptions | async" [value]="ltiStockOptionOption.id">{{ltiStockOptionOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                LTI Rsu
            </mat-label>

            <mat-select disableOptionCentering formControlName="ltiRsu">
                <mat-option *ngFor="let ltiRsuOption of ltiRsuOptions | async" [value]="ltiRsuOption.id">{{ltiRsuOption.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Amount
            </mat-label>

            <input matInput
                type="number"
                formControlName="amount">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Compensation Ratio
            </mat-label>

            <input matInput
                type="number"
                formControlName="compensationRatio">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                STI Target Amount
            </mat-label>

            <input matInput
                type="number"
                formControlName="stiTargetAmount">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                STI Target Percentage
            </mat-label>

            <input matInput
                type="number"
                formControlName="stiTargetPercentage">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                STI Actual Amount
            </mat-label>

            <input matInput
                type="number"
                formControlName="stiActualAmount">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                STI Actual Percentage
            </mat-label>

            <input matInput
                type="number"
                formControlName="stiActualPercentage">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                Comments
            </mat-label>

            <textarea matInput formControlName="comments"></textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button> 
</mat-dialog-actions>