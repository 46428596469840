import { Component, OnInit } from '@angular/core';
import { ModuleConfigurationService } from './services/module-configuration.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs';
import { EmployeeService } from '@app/core/services/employee.service';
import { CurrentUserDetails } from '@app/shared/models/employee.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-module-configuration',
  templateUrl: './module-configuration.component.html',
  styleUrls: ['./module-configuration.component.scss']
})
export class ModuleConfigurationComponent implements OnInit {
  formId: string = "frm_3ljFVOm8YoRmNq";
  formData: any;
  getFormData: boolean = false;
  formValid: boolean = false;
  formPristine: boolean;
  currentEmployeeDetails: CurrentUserDetails;

  constructor(
    private moduleConfigurationService: ModuleConfigurationService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private translate: TranslateService,
    private employeeService: EmployeeService,
    private router: Router,
  ) {
    this.currentEmployeeDetails = this.employeeService.getFetchedCurrentUserDetails();
    
    if(this.currentEmployeeDetails.userType !== 'User'){
      this.router.navigate(['Homepage'])
    }
  }

  ngOnInit(): void {
    this.getModuleConfiguration();
  }

  formDataEmitted(formData) {
    this.save(formData);
  }

  formPristineEmitted(formPristine: boolean) {
    this.formPristine = formPristine;
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }
  
  getModuleConfiguration() {
    this.moduleConfigurationService.getModuleConfiguration()
    .pipe(
      finalize(() => {})
    )
    .subscribe(
      (res) => {
        this.formData = res;
      }
    );
  }

  save(formData) {
    formData.timeoff = formData.timeOff;

    this.overlayService.show();

    this.moduleConfigurationService.saveModuleConfiguration(formData)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

}
