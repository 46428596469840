<div class="main-container">
    <div class="left">
        <mat-list class="list">
            <ng-container *ngFor="let navItem of navItems">
                <mat-list-item> 
                    <div class="list-item" (click)="selectedNav = navItem.name" [ngClass]="{'selected': selectedNav === navItem.name }">
                        <span>{{navItem.name}}</span>
                        <span>{{navItem.count}}</span>
                    </div>
                </mat-list-item>
    
                <mat-list style="margin-left:30px;">
                    <div *ngFor="let child of navItem.children">
                        <mat-list-item > 
                            <div class="child-list-item">
                                <span>{{child.name}}</span>
                                <span>{{child.count}}</span>
                            </div>
                        </mat-list-item>
                    </div>
                </mat-list>
            </ng-container>
        </mat-list>
    </div>

    <div class="right">
        <kendo-grid
              [kendoGridBinding]="gridView"
              [pageSize]="20"
              [pageable]="true"
              [sortable]="true"
              [resizable]="true"
              [height]="850"
              [columnMenu]="{ filter: true }"
            >
        
            <ng-template kendoGridToolbarTemplate>
                <mat-form-field class="grid-search-field" appearance="outline">
                    <input matInput
                           (input)="onFilter($event.target.value)"
                           placeholder="Search in all columns...">
                    <mat-icon matPrefix class="material-icons-outlined">
                        search
                    </mat-icon>
                </mat-form-field>

                <kendo-grid-spacer></kendo-grid-spacer>

                <mat-form-field appearance="outline">
                    <mat-label>
                        Owned By
                    </mat-label>
                    
                    <mat-select disableOptionCentering>
                        <ng-container>
                            <mat-option value="anyone">Anyone</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>
                        Start Date
                    </mat-label>
                
                    <input matInput [matDatepicker]="startDatepicker">
                    <span fxLayout="row" matSuffix>
                        <mat-datepicker-toggle  [for]="startDatepicker"></mat-datepicker-toggle>
                    </span>
                    <mat-datepicker #startDatepicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>
                        End Date
                    </mat-label>
                
                    <input matInput [matDatepicker]="endDatepicker">
                    <span fxLayout="row" matSuffix>
                        <mat-datepicker-toggle  [for]="endDatepicker"></mat-datepicker-toggle>
                    </span>
                    <mat-datepicker #endDatepicker></mat-datepicker>
                </mat-form-field>
            </ng-template>
        
            <kendo-grid-command-column
                [sticky]="true"
                [width]="20"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    <mat-icon class="material-icons-outlined">edit</mat-icon>
                </ng-template>
            </kendo-grid-command-column>

            <kendo-grid-column field="title" title="Title" [width]="220">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="title" [routerLink]="dataItem.href">{{dataItem.title}}</div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="category" title="Category" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="createdBy" title="Owned By" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="lastModified" title="Last Modified" [width]="120"></kendo-grid-column>
            
            <kendo-grid-column field="scheduled" title="scheduled" [width]="100">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <mat-chip-list>
                        <mat-chip 
                            *ngIf="dataItem.scheduled === true"
                            class="true-chip"
                        >Scheduled</mat-chip>
        
                        <mat-chip 
                            *ngIf="dataItem.scheduled === false"
                            class="false-chip"
                        >Not Scheduled</mat-chip>
                    </mat-chip-list>
                </ng-template>
            </kendo-grid-column>
        
            <kendo-grid-column field="published" title="published" [width]="100">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <mat-chip-list>
                        <mat-chip 
                            *ngIf="dataItem.published === true"
                            class="true-chip"
                        >Published</mat-chip>
        
                        <mat-chip 
                            *ngIf="dataItem.published === false"
                            class="false-chip"
                        >Not Published</mat-chip>
                    </mat-chip-list>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
</div>


