<mat-card class="mat-elevation-z0 reviewplan-goalsettings-tabs">
    <mat-card-title class="header">
        <h5 appLocalizationPopupDirective localizationCode="ReviewPlan-GoalSettings">{{ 'ReviewPlan-GoalSettings' | translate: {Default: "Goal Settings"} }}</h5>
    </mat-card-title>

    <mat-card-content>
        <mat-tab-group animationDuration="0ms">

            <mat-tab *ngFor="let goalPlanGoalSetting of groupedData | keyvalue">
                <ng-template mat-tab-label>
                    <div>{{ goalPlanGoalSetting.key }}</div>
                </ng-template>
        
                <ng-template matTabContent>
                    <app-review-plan-goal-plan-settings 
                        *ngIf="selectedGoals.length > 0" 
                        [selectedGoals]="selectedGoals" 
                        [goalPlanGoalSettings]="goalPlanGoalSetting.value"
                        (triggerRefresh)="getReviewPlanGoalSettings(); selectedGoals = []"
                    ></app-review-plan-goal-plan-settings>

                    <app-review-plan-goal-setting 
                        *ngFor="let goalSetting of goalPlanGoalSetting.value" 
                        [goalSetting]="goalSetting" 
                        (checkboxChange)="onGoalSettingCheckboxChange($event)"
                    ></app-review-plan-goal-setting>
                </ng-template>
            </mat-tab>
        
        </mat-tab-group>
    </mat-card-content>
</mat-card>
