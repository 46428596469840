import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import {
    CopySecurityRole,
    RoleType,
    SecurityFeature,
    SecurityRole,
    SecurityRoleOrganization,
    SecurityRoleSubmit,
    SecurityRoleVerbose,
    SecurityUser,
    SecurityUserVerbose,
    UserSecurityRole
} from '@app/modules/security-setup/models/security-role.model';
import { api_routes } from '@app/consts';
import { EmployeeSecuirtyRole } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-security/models/employee-security.model';
import { EnvironmentService } from "@app/core/services/environment.service";


const headers = new HttpHeaders({
    'Content-Type': 'application/json',
});

@Injectable({
    providedIn: 'root'
})
export class SecurityRoleService {

    constructor(private http: HttpClient, private envService: EnvironmentService) { }

    getSecurityRoles(skip?: number, take?: string, filterString?: string, sort?: string): Observable<PagedData<SecurityRole>> {
        let params = new HttpParams();
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;
        filterString ? params = params.append('Filter', filterString) : null;
        sort ? params = params.append('sort', sort) : null;

        return this.http
            .get<any>(`${this.envService.env.apiUrl}Security/Roles`, {
                headers: headers,
                observe: 'response',
                params: params
            })
            .pipe(
                catchError(err => throwError(err)),
                map(res => {
                    return res.body;
                })
            );
    }

    getSecurityRole(securityRoleId: string): Observable<SecurityRoleVerbose> {
        return this.http.get<any>(`${this.envService.env.apiUrl}Security/Roles/${securityRoleId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getFeatures(): Observable<SecurityFeature[]> {
        return this.http.get<any>(`${this.envService.env.apiUrl}Security/Features`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    postSecurityRole(securityRole: SecurityRoleSubmit): Observable<any> {
        return this.http.post<any>(`${this.envService.env.apiUrl}Security/Roles`, securityRole, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    copySecurityRole(securityRoleId: string, copySecurityRole: CopySecurityRole): Observable<{roleId: string}> {
        return this.http.post<any>(`${this.envService.env.apiUrl}Security/Roles/${securityRoleId}/Copy`, copySecurityRole, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    putSecurityRole(securityRoleId: string, securityRole: SecurityRoleSubmit): Observable<any> {
        return this.http.put<any>(`${this.envService.env.apiUrl}Security/Roles/${securityRoleId}`, securityRole, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    deleteSecurityRole(securityRoleId: string): Observable<any> {
        return this.http.delete<any>(`${this.envService.env.apiUrl}Security/Roles/${securityRoleId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getCurrentUserFeatures(): Observable<SecurityFeature[]> {
        return this.http.get<any>(`${this.envService.env.apiUrl}Security/Features/CurrentUser`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getCurrentUserRoles(): Observable<UserSecurityRole[]> {
        return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.SECURITY}/${api_routes.ROLES}/${api_routes.CURRENT_USER}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getRoleUsers(securityRoleId: string, skip?: number, take?: string,  filterString?: string): Observable<PagedData<SecurityUser>> {
        let params = new HttpParams();
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;
        filterString ? params = params.append('Filter', filterString) : null;

        return this.http.get<any>(`${this.envService.env.apiUrl}Security/Roles/${securityRoleId}/Users`, {
            headers: headers,
            params: params
        });
    }

    deleteRoleUser(securityRoleId: string, userId: string): Observable<any> {
        return this.http.delete<any>(`${this.envService.env.apiUrl}Security/Roles/${securityRoleId}/Users/${userId}`, {
            headers: headers
        });
    }

    addUserToRole(securityRoleId: string, userId: string): Observable<any> {
        return this.http.post<any>(`${this.envService.env.apiUrl}Security/Roles/${securityRoleId}/Users/${userId}`, {
            headers: headers
        });
    }

    getUserRoles(userId: string): Observable<EmployeeSecuirtyRole[]> {
        return this.http.get<any>(`${this.envService.env.apiUrl}Security/Users/${userId}/Roles`, {
            headers: headers
        });
    }

    getAllUsers(skip?: number, take?: string, filterString?: string): Observable<PagedData<SecurityUserVerbose>> {
        let params = new HttpParams();
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;
        filterString ? params = params.append('Filter', filterString) : null;

        return this.http.get<any>(`${this.envService.env.apiUrl}Security/Users`, {
            headers: headers,
            params: params
        });
    }

    getRoleOrganizations(securityRoleId: string, skip?: number, take?: string, filterString?: string): Observable<PagedData<SecurityRoleOrganization>> {
        let params = new HttpParams();
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;
        filterString ? params = params.append('Filter', filterString) : null;

        return this.http.get<any>(`${this.envService.env.apiUrl}Security/Roles/${securityRoleId}/Organizations`, {
            headers: headers,
            params: params
        });
    }

    deleteRoleOrg(securityRoleId: string, moduleId: string, orgId: string): Observable<any> {
        return this.http.delete<any>(`${this.envService.env.apiUrl}Security/Roles/${securityRoleId}/Organizations/${orgId}/Modules/${moduleId}`, {
            headers: headers
        });
    }


    addOrgToRole(securityRoleId: string, moduleId: string, orgId: string): Observable<any> {
        return this.http.post<any>(`${this.envService.env.apiUrl}Security/Roles/${securityRoleId}/Organizations/${orgId}/Modules/${moduleId}`, {
            headers: headers
        });
    }

    getRoleTypes(): Observable<RoleType[]> {
        return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.SECURITY}/${api_routes.ROLE_TYPES}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

}
