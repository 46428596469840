<mat-form-field *ngIf="field.type === fieldTypes.TEXT_INPUT" appearance="fill" [ngClass]="{'invisible':field.hidden === 'true'}">
    <mat-label>
        {{ (field.id | translate) == field.id ? field.text : (field.id | translate) }}
        <span class="asterisk" *ngIf = "field.requiredField === 'true'">*</span>
    </mat-label>

    <input matInput type="text" [formControlName]="controlName">

    <!-- <span class="field-edit-icon material-icons-outlined" (click)="openDialog(controlName, companyId)" matSuffix>
        settings
    </span> -->
    <!-- <mat-error>{{getFormValidationErrors()}}</mat-error> -->
    <mat-hint></mat-hint>
</mat-form-field>

<!-- Date picker field -->
<mat-form-field  *ngIf="field.type === fieldTypes.DATE_INPUT" appearance="fill" [ngClass]="{'invisible':field.hidden === 'true'}">
    <mat-label>
        {{ (field.id | translate) == field.id ? field.formFieldLabel : (field.id | translate) }}
        <span class="asterisk" *ngIf = "field.requiredField">*</span>
    </mat-label>

    <input matInput [matDatepicker]="picker" [formControlName]="controlName">
    <span fxLayout="row" matSuffix>
        <mat-datepicker-toggle  [for]="picker"></mat-datepicker-toggle>
        <!-- <span class="field-edit-icon material-icons-outlined datepicker-field-edit-icon" (click)="openDialog(controlName, companyId)" matSuffix>
        settings
        </span> -->
    </span>
    <mat-datepicker #picker></mat-datepicker> 

    <!-- <mat-error>{{getFormValidationErrors()}}</mat-error> -->
    <mat-hint></mat-hint>
</mat-form-field>

<!-- Date Time Field -->
<mat-form-field *ngIf="field.type === fieldTypes.DATE_TIME" appearance="fill" [ngClass]="{'invisible':field.hidden === 'true'}">
    <mat-label>
        {{ (field.id | translate) == field.id ? field.formFieldLabel : (field.id | translate) }}
        <span class="asterisk" *ngIf = "field.requiredField">*</span>
    </mat-label>

    <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date" [formControlName]="controlName">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour"
        [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color"
        [enableMeridian]="enableMeridian" [disableMinute]="disableMinute" [hideTime]="hideTime">
        <!-- Custom icon or text of Apply icon -->
        <ng-template>
        <!-- <mat-icon>star</mat-icon> -->
        <span>OK</span>
        </ng-template>
    </ngx-mat-datetime-picker>

    <!-- <mat-error>{{getFormValidationErrors()}}</mat-error> -->
    <mat-hint></mat-hint>   

</mat-form-field>

<!-- dropdown select field -->
<mat-form-field  *ngIf="field.type === fieldTypes.DROPDOWN" appearance="fill" [ngClass]="{'invisible':field.hidden === 'true'}">
<mat-label>
    {{ (field.id | translate) == field.id ? field.formFieldLabel : (field.id | translate) }}
    <span class="asterisk" *ngIf="field.requiredField">*</span>
</mat-label>

<mat-select disableOptionCentering [formControlName]="controlName">
    <ng-container *ngFor="let option of field.dropDownOptions">
        <mat-option [value]="option.id">{{option.text}}</mat-option>
    </ng-container>
</mat-select>

<!-- <span class="field-edit-icon material-icons-outlined" (click)="openDialog(controlName, companyId)" matSuffix>
    settings
</span> -->
<!-- <mat-error>{{getFormValidationErrors()}}</mat-error> -->
<mat-hint></mat-hint>
</mat-form-field>

<!-- Toggle field -->
<mat-slide-toggle *ngIf="field.type === fieldTypes.TOGGLE" [formControlName]="controlName" [ngClass]="{'invisible':field.hidden === 'true'}">
{{ (field.id | translate) == field.id ? field.formFieldLabel : (field.id | translate) }}
</mat-slide-toggle>