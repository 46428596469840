import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { GenerateScheduleService } from '@app/modules/generate-schedules/services/generate-schedule.service';
import { EmployeeToilRequest, TOIL } from '@app/shared/models/time-off-in-lieu/time-off-in-lieu.model';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-my-toil-request-dialog',
  templateUrl: './my-toil-request-dialog.component.html',
  styleUrls: ['./my-toil-request-dialog.component.scss']
})
export class MyToilRequestDialogComponent implements OnInit {
  employeeToilRequest: EmployeeToilRequest;
  loadingWorkingHours: boolean;
  user$: any;
  workingHours: number;
  moment = moment;

  constructor(
    private dialogRef: MatDialogRef<MyToilRequestDialogComponent>,
    private generateScheduleService: GenerateScheduleService,
    private oidcAuthService: OidcAuthService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.employeeToilRequest = data.employeeToilRequest;
    this.user$ = this.oidcAuthService.userProfile;
    console.log(data)
  }

  ngOnInit(): void {
    if(this.employeeToilRequest.hours === 0){
      this.getEmployeeWorkingHours();
    }
  }

  getEmployeeWorkingHours() {
    this.loadingWorkingHours = true;

    this.generateScheduleService.getEmployeeWorkingHours(this.user$.userId, this.employeeToilRequest.startDate, this.employeeToilRequest.endDate)
    .pipe(
      finalize(() => this.loadingWorkingHours = false)
    )
    .subscribe(
      (res) => {
        this.workingHours = res.workingHours;
        if(this.employeeToilRequest.halfDay === true){
          this.workingHours = this.workingHours / 2;
        }
      }
    );
  }

  close(){
    this.dialogRef.close();
  }

  formatTime(time) {
      const [hours, minutes] = time.split(':');
      return `${hours}:${minutes}`;
  }

  showEmployeeTooltip(employees: {id: string, firstName: string, lastName: string}[]) {
    if(employees){
        return employees.map( employee => `${employee.firstName} ${employee.lastName}`).join(', ');
    }

    return null;
  }
}
