import {Localization} from '@app/shared/models/localization.model';
import {Country} from '@app/shared/models/country.model';

export interface HolidayGroup {
    id: string,
    name: string,
    country: Country
}

export interface HolidayGroupVerbose {
    id: string,
    name: Localization[],
    country: Country
}

export interface HolidayGroupSubmit {
    id: string,
    name: Localization[],
    country: string
}

export interface Holiday {
    id: string,
    name: string,
    halfStat: boolean
    endDate: string
    hourOverride: number
    notes: string
    startDate: string
    statutoryHolidayGroup: {id: string, name: string}
    totalHours: number
}

export interface HolidayVerbose {
    id: string,
    name: Localization[],
    startDate: string,
    endDate: string,
    halfStat: boolean,
    hourOverride: number,
    notes: string,
    statutoryHolidayGroup: string,
}

export interface HolidaySubmit {
    id: string,
    name: string,
    startDate: string,
    endDate: string,
    halfStat: boolean,
    statutoryHolidayGroup: string,
    hourOverride: number,
    notes: string
}
