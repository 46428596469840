<mat-card class="revenue-chart">
    <mat-card-title class="revenue-chart__header">
      <h5 class="revenue-chart__header-title">Gender Pay Gap</h5>
  
      <app-settings-menu></app-settings-menu>
    </mat-card-title>
  
    <mat-card-content class="revenue-chart__content">
      <div class="revenue-chart__content-chart" echarts [options]="chart"></div>
    </mat-card-content>
</mat-card>