<h5 mat-dialog-title>{{ 'GroupPlanCoverage' | translate: {Default: "Group Plan Coverage"} }}</h5>

<mat-dialog-content>
    <ng-container *ngIf="form === undefined">
        <ngx-skeleton-loader
            count="5"
            [theme]="{ 
                'height.px': 50
            }"
        ></ngx-skeleton-loader>
    </ng-container>

    <form class="form" *ngIf="form" [formGroup]="form">
        <div class="form-section">
            <div class="form-section-title">
            </div>
            <div class="form-section-contents">
                <div class="section-row">
                    <mat-form-field appearance="fill">
                        <mat-label>
                            {{ 'GroupPlanCoverage' | translate: {Default: "Coverage"} }}
                        </mat-label>
                
                        <mat-select 
                            [disabled]="coverageId !== null"
                            disableOptionCentering 
                            formControlName="coverage">
                                <mat-option></mat-option>
                                <ng-container *ngFor="let option of coverageOptions">
                                    <mat-option [value]="option.coverage?.id">{{option.coverage?.name}}</mat-option>
                                </ng-container>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>
                            {{ 'GroupPlanCoverageEmployerContributionAmount' | translate: {Default: "Employer Contribution Amount"} }}
                        </mat-label>

                        <input 
                            matInput
                            type="number"
                            formControlName="employerContributionAmount">
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>
                            {{ 'GroupPlanCoverageEmployerContributionPercentage' | translate: {Default: "Employer Contribution Percentage"} }}
                        </mat-label>

                        <input 
                            matInput
                            type="number"
                            formControlName="employerContributionPercentage">
                    </mat-form-field>

                    
                    <mat-form-field appearance="fill">
                        <mat-label>
                            {{ 'GroupPlanCoverageEmployeeContributionAmount' | translate: {Default: "Employee Contribution Amount"} }}
                        </mat-label>

                        <input 
                            matInput
                            type="number"
                            formControlName="employeeContributionAmount">
                    </mat-form-field>


                    <mat-form-field appearance="fill">
                        <mat-label>
                            {{ 'GroupPlanCoverageEmployeeContributionPercentage' | translate: {Default: "Employee Contribution Percentage"} }}
                        </mat-label>

                        <input 
                            matInput
                            type="number"
                            formControlName="employeeContributionPercentage">
                    </mat-form-field>
                    
                    <mat-form-field appearance="fill">
                        <mat-label>
                            {{ 'GroupPlanCoverageDeductionFrequency' | translate: {Default: "Coverage Deduction Frequency"} }}
                        </mat-label>
                
                        <mat-select disableOptionCentering formControlName="deductionFrequency">
                            <ng-container *ngIf="deductionFrequencyOptions | async as deductionFrequencyOptions">
                                <mat-option></mat-option>
                                <ng-container *ngFor="let option of deductionFrequencyOptions">
                                    <mat-option [value]="option.id">{{option.name}}</mat-option>
                                </ng-container>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>

</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button> 
</mat-dialog-actions>