import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { EmployeeMedicalTestSubmit, EmployeeMedicalTestVerbose } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-medical-tests/models/employee-medical-test.model';
import { catchError, map } from 'rxjs/operators';
import { api_routes } from '@app/consts';
import { EnvironmentService } from "@app/core/services/environment.service";


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class EmployeeMedicalTestService {

  constructor(private http: HttpClient, private envService: EnvironmentService) {}

  getMedicalTests(employeeId: string, take?: string, skip?: number, sort?: string): Observable<any> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    sort ? params = params.append('sort', sort) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.MEDICAL_TESTS}`, {
      headers: headers,
      params: params
    })
    .pipe(
      catchError(err => throwError(err)),
      map(res => {
        let data = {
          data: res.data,
          total: res.total
        }

        return data;
      })
    );
  }

  deleteMedicalTest(employeeId: string, id: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.MEDICAL_TESTS}/${id}`, {
      headers: headers
    });
  }

  getMedicalTest(employeeId: string, id: string): Observable<EmployeeMedicalTestVerbose> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.MEDICAL_TESTS}/${id}`, {
      headers: headers
    });
  }

  updateMedicalTest(employeeId: string, employeeMedicalTest: EmployeeMedicalTestSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.MEDICAL_TESTS}/${employeeMedicalTest.id}`, employeeMedicalTest, {
      headers: headers
    });
  }

  createMedicalTest(employeeId: string, employeeMedicalTest: EmployeeMedicalTestSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.MEDICAL_TESTS}`, employeeMedicalTest, {
      headers: headers
    });
  }
}

