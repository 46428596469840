import { Component, OnInit, ViewChild } from '@angular/core';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-tenure-by-month',
  templateUrl: './tenure-by-month.component.html',
  styleUrls: ['./tenure-by-month.component.scss']
})
export class TenureByMonthComponent implements OnInit {

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  terminations = [
    {
      'month': 'January',
      'terminations': '400',
    },
    {
      'month': 'February',
      'terminations': '410',
    },
    {
      'month': 'March',
      'terminations': '435',
    },
    {
      'month': 'April',
      'terminations': '435',
    },
    {
      'month': 'May',
      'terminations': '450',
    },
    {
      'month': 'June',
      'terminations': '471',
    },
    {
      'month': 'July',
      'terminations': '480',
    },
    {
      'month': 'August',
      'terminations': '480',
    },
    {
      'month': 'September',
      'terminations': '500',
    }
  ];

  tableColumns = [
    { name: 'Month', dataKey: 'month', isSortable: true, position: 'left' },
    { name: 'Terminations', dataKey: 'terminations', isSortable: true, position: 'left' },
  ];

  constructor() { 
    this.chartOptions = {
      series: [
        {
          name: "Headcount (By Tenure)",
          data: [400, 410, 435, 435, 450, 471, 480, 480, 500]
        }
      ],
      chart: {
        height: 350,
        width: 600,
        type: "bar"
      },
      xaxis: {
        categories: ["Jan", "Feb",  "Mar",  "Apr",  "May",  "Jun",  "Jul",  "Aug", "Sep"]
      }
    };
  }

  ngOnInit(): void {
  }

}
