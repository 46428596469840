import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings-onboarding-task-checklist',
  templateUrl: './settings-onboarding-task-checklist.component.html',
  styleUrls: ['./settings-onboarding-task-checklist.component.scss']
})
export class SettingsOnboardingTaskChecklistComponent implements OnInit {

  navList = [
    {
      text: 'Account',
      icon: 'build'
    },
    {
      text: 'Access Levels',
      icon: 'vpn_key'
    },
    {
      text: 'Employee Fields',
      icon: 'list_alt'
    },
    {
      text: 'Approvals',
      icon: 'thumb_up'
    },
    {
      text: 'Apps',
      icon: 'cloud_circle'
    },
    {
      text: 'Benefit',
      icon: 'volunteer_activism'
    },
    {
      text: 'Company Directory',
      icon: 'contact_mail'
    },
    {
      text: 'Email Alerts',
      icon: 'mark_email_unread'
    },
    {
      text: 'Employee Satisfaction',
      icon: 'sentiment_very_satisfied'
    },
    {
      text: 'Hiring',
      icon: 'work'
    },
    {
      text: 'Holidays',
      icon: 'airplanemode_active'
    },
    {
      text: 'Logo & Color',
      icon: 'brush'
    },
    {
      text: 'Offboarding',
      icon: 'person_remove'
    },
    {
      text: 'Onboarding',
      icon: 'person_add'
    },
    {
      text: 'Performance',
      icon: 'speed'
    },
    {
      text: 'Time Off',
      icon: 'schedule'
    },
    {
      text: 'Time Tracking',
      icon: 'timer'
    },
    {
      text: 'Training',
      icon: 'school'
    },
  ];

  tasks = [
    {
      category: 'IT',
      list: [
        {
          checked: true,
          task: 'Create email address',
          assignedTo: 'Eric Pasture',
          date: 'Due on first day'
        },
        {
          checked: false,
          task: 'Setup workstation',
          assignedTo: 'Eric Pasture',
          date: 'Due on first day'
        },
        {
          checked: false,
          task: 'Create phone extension',
          assignedTo: 'Eric Pasture',
          date: 'Due on first day'
        },
      ]
    },
    {
      category: 'HR',
      list: [
        {
          checked: false,
          task: 'New hire orientation',
          assignedTo: 'Daniel John',
          date: 'Due on first day'
        },
        {
          checked: false,
          task: 'Office Tour',
          assignedTo: 'Daniel John',
          date: 'Due on first day'
        },
        {
          checked: false,
          task: 'Introduce new hire ro 2 employees outside of group',
          assignedTo: 'Daniel John',
          date: 'Due on first day'
        },
        {
          checked: false,
          task: 'New hire training',
          assignedTo: 'Daniel John',
          date: 'Due on first day'
        },
        {
          checked: false,
          task: 'Confirm W4 is signed',
          assignedTo: 'Daniel John',
          date: 'Due on first day'
        },
      ]
    },
    {
      category: 'New Hire Paperwork',
      list: [
        {
          checked: true,
          task: 'Complete direct deposit form',
          assignedTo: 'George Allen',
          date: 'Due on first day',
          icon: 'description'
        },
        {
          checked: true,
          task: 'Read employee Handbook',
          assignedTo: 'George Allen',
          date: 'Due on first day'
        },
        {
          checked: true,
          task: 'Review and sign 19',
          assignedTo: 'George Allen',
          date: 'Due on first day',
          icon: 'description'
        },
      ]
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

  

}
