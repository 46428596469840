<kendo-grid
        [data]="gridDataResult"
        [pageSize]="pageSize"
        [skip]="skip"
        [style.maxHeight.vh]="75"
        [resizable]="true"
        [pageable]="{
            info: true,
            pageSizes: [5, 10, 20, 50, 100],
            previousNext: true
        }"
        [sortable]="true"
        [sort]="sort"
        (sortChange)="sortChange($event)"
        (pageChange)="pageChange($event)"
        [loading]="isLoading">

    <ng-template kendoGridToolbarTemplate>
        <div class="mt-2">
            <filter-control [categories]="filterCategories" (callback)="filterCallback($event)"></filter-control>
        </div>
    </ng-template>

    <!-- <kendo-grid-column
            *ngFor="let col of columns"
            [field]="col.field"
            [title]="col.title"
            [width]="165"
    ></kendo-grid-column> -->

    <kendo-grid-column title="Sent On" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="grid-cell-vertical">
                {{dataItem.sentOn | date:'medium'}}
            </div>
        </ng-template>
    </kendo-grid-column>
    
    <kendo-grid-column title="Started On" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="grid-cell-vertical">
                {{dataItem.startedOn | date:'medium'}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Completed On" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="grid-cell-vertical">
                {{dataItem.completedOn | date:'medium'}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="grid-cell-vertical">
                    <mat-chip-list>
                        <mat-chip 
                            *ngIf="dataItem.completedOn !== null"
                            class="green-chip"
                        >Complete</mat-chip>

                        <mat-chip 
                            *ngIf="dataItem.completedOn === null"
                            class="orange-chip"
                        >Running</mat-chip>
                    </mat-chip-list>
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-messages 
        [noRecords]="isLoading ? '' : translate.instant('Grid-NoRecordsAvailable')"
        [pagerItemsPerPage]="translate.instant('Grid-Pager-ItemsPerPage')" 
        [pagerItems]="translate.instant('Grid-Pager-Items')"
        [pagerOf]="translate.instant('Grid-Pager-Of')"
    ></kendo-grid-messages>


</kendo-grid>
