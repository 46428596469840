import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { ApiUser } from '../../models/api-setup.model';

@Component({
  selector: 'app-api-user-dialog',
  templateUrl: './api-user-dialog.component.html',
  styleUrls: ['./api-user-dialog.component.scss']
})
export class ApiUserDialogComponent implements OnInit {
  apiUser: ApiUser;
  newPassword: string = '';
  newPasswordConfirm: string = '';

  constructor(
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private dialogRef: MatDialogRef<ApiUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data)
  {
    this.apiUser = data.apiUser;
  }

  ngOnInit(): void {
  }

  save() {
    this.overlayService.show();

    setTimeout(() => {
      this.overlayService.hide();
      this.snackbarService.openSnackBar('Password reset successfully', 'clear', 'success');
    }, 2000);
  }

  close() {
    this.dialogRef.close(false);
  }

}
