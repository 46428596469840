import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PdfViewerDialogComponent } from '../pdf-viewer-dialog/pdf-viewer-dialog.component';

@Component({
  selector: 'app-file-viewer-dialog',
  templateUrl: './file-viewer-dialog.component.html',
  styleUrls: ['./file-viewer-dialog.component.scss']
})
export class FileViewerDialogComponent implements OnInit {
  fileUrl: any;
  viewer: any;
  ngOnInit(): void {
  }

  constructor(
    private dialogRef: MatDialogRef<PdfViewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      this.fileUrl = data.fileUrl;
      this.viewer = data.viewer;
  }

  close() {
    this.dialogRef.close();
  }

}
