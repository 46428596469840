<h5 mat-dialog-title appLocalizationPopupDirective localizationCode="AccessLogs">{{ 'AccessLogs' | translate: {Default: "Access Logs"} }}</h5>

<mat-dialog-content>
    <kendo-grid
            class="grid"
            [data]="gridDataResult"
            [loading]="isLoading"
            [pageSize]="pageSize"
            [skip]="skip"
            [style.maxHeight.vh]="85" 
            [pageable]="{
                info: true,
                pageSizes: [5, 10, 20, 50, 100],
                previousNext: true
            }"
            (pageChange)="pageChange($event)"
            [sortable]="true">

        <kendo-grid-column>
            <ng-template kendoGridHeaderTemplate>
                <div appLocalizationPopupDirective localizationCode="AccessLogs-RequestedOn">{{ 'AccessLogs-RequestedOn' | translate: {Default: "Requested On"} }}</div>
            </ng-template>

            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="grid-cell-vertical">
                    <span>
                        {{dataItem.requestedOn | date:'medium' }}
                    </span>
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column>
            <ng-template kendoGridHeaderTemplate>
                <div appLocalizationPopupDirective localizationCode="AccessLogs-EffectiveDate">{{ 'AccessLogs-EffectiveDate' | translate: {Default: "Effective Date"} }}</div>
            </ng-template>

            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="grid-cell-vertical">
                    <span>
                        {{dataItem.recordEffectiveDate | date:'mediumDate' }}
                    </span>
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column>
            <ng-template kendoGridHeaderTemplate>
                <div appLocalizationPopupDirective localizationCode="AccessLogs-CreatedOn">{{ 'AccessLogs-CreatedOn' | translate: {Default: "Created On"} }}</div>
            </ng-template>

            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="grid-cell-vertical">
                    <span>
                        {{dataItem.recordCreatedOn | date:'medium' }}
                    </span>
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column>
            <ng-template kendoGridHeaderTemplate>
                <div appLocalizationPopupDirective localizationCode="AccessLogs-RequestedBy">{{ 'AccessLogs-RequestedBy' | translate: {Default: "Requested By"} }}</div>
            </ng-template>

            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="grid-cell-vertical">
                    <span>
                        {{dataItem.requestedBy.firstName }} {{dataItem.requestedBy.lastName }}
                    </span>
                </div>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-messages 
            [noRecords]="isLoading ? '' : translate.instant('Grid-NoRecordsAvailable')"
            [pagerItemsPerPage]="translate.instant('Grid-Pager-ItemsPerPage')" 
            [pagerItems]="translate.instant('Grid-Pager-Items')"
            [pagerOf]="translate.instant('Grid-Pager-Of')"
        ></kendo-grid-messages>

    </kendo-grid>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
</mat-dialog-actions>
