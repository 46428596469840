import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {routes} from '@app/consts/routes';
import {features} from '@app/consts';
import { TranslateService } from '@ngx-translate/core';
import { FeatureService } from '@app/core/services/feature.service';
import { modules } from '@app/consts/modules';
import { ModuleConfigurationService } from '@app/core/services/module-configuration/module-configuration.service';

@Component({
    selector: 'time-off-menu',
    templateUrl: './time-off-menu.component.html',
    styleUrls: ['./time-off-menu.component.scss']
})
export class TimeOffMenuComponent implements OnInit {
    public routes: typeof routes = routes;
    navList = [
        {
            text: this.translate.instant('TimeOffTypesAndPolicies'),
            link: `${routes.SITE_SETTINGS}${routes.TIME_OFF}${routes.TIME_OFF_POLICIES_TYPES}`,
            icon: 'alarm',
            featureCode: features.TIME_OFF
        },
        {
            text: this.translate.instant('Emails'),
            link: `${routes.SITE_SETTINGS}${routes.TIME_OFF}${routes.TOIL_EMAILS}`,
            icon: 'email',
            featureCode: features.TIME_OFF
        },
        
    ];

    constructor(
        private translate: TranslateService,
        public router: Router,
        private featureService: FeatureService,
        private moduleConfigurationService: ModuleConfigurationService,
    ) {
    }

    ngOnInit(): void {
        this.navigateToFirstAllowedSection();
    }

    navigateToFirstAllowedSection() {
        let urlToNavigateTo = '';

        if(this.router.url === `${routes.SITE_SETTINGS}`) {
          this.navList.every(element => {
            let hasAccess = this.featureService.hasFeatureAccess(element.featureCode)

            if(hasAccess){
              urlToNavigateTo = element.link;
              return false;
            }

            return true;
          });
        }

        if(urlToNavigateTo !== ''){
          this.router.navigate([urlToNavigateTo]);
        }
    }
}
