import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import { fieldTypes } from '@app/consts/index';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss']
})
export class FormFieldComponent implements OnInit {
  public fieldTypes: typeof fieldTypes = fieldTypes;
  form: any;
  formControl = new UntypedFormControl({value: "", disabled: true});

  @Input() field: any;

  constructor() {
  }
  
  ngOnInit(): void {
    this.refactorFieldData(this.field);
  }

  refactorFieldData(field){
    field.properties?.forEach(prop => {
      field[prop.property] = prop.value;
    });

    field.requiredField = field.tableField?.requiredField ? 'true' : 'false';
  }
}
