import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { routes } from '@app/consts';
import { SanitiseGridDataService } from '@app/core/services/sanitise-grid-data/sanitise-grid-data.service';
import { Position } from '@app/modules/positions/models/positions.model';
import { TranslateService } from '@ngx-translate/core';
import { ExcelExportEvent, GridComponent, GridDataResult, PageChangeEvent, PageSizeItem } from '@progress/kendo-angular-grid';
import { SortDescriptor, State } from '@progress/kendo-data-query';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { BasicEmployeeReportService } from '../../services/basic-employee-report.service';

@Component({
  selector: 'app-basic-employee-details-data-grid',
  templateUrl: './basic-employee-details-data-grid.component.html',
  styleUrls: ['./basic-employee-details-data-grid.component.scss']
})
export class BasicEmployeeDetailsDataGridComponent implements OnInit {
  @ViewChild(GridComponent)
  public grid: GridComponent;

  public routes: typeof routes = routes;
  public sort: SortDescriptor[] = [];
  public bindingType: String = "array";
  public view: Observable<GridDataResult>;
  public gridDataResult: GridDataResult;
  public selectedPositions: any[] = [];
  public pageSizes: (PageSizeItem | number)[] = [
    5,
    10,
    20,
    {
      text: "All",
      value: 1000,
    },
  ];
  isLoading: boolean;
  pageSize: number = 4;
  skip: number = 0;
  sortString: string;
  filterString: string;
  getBankDetailsRequest: any;
  positions: Position[];
  searchFilterString: any;
  getDetailsRequest: any;
  private filterInactive: boolean = true;

  public filterCategories: any[] = [
    {field: 'firstName', title: 'First Name', dataType: "String"},
    {field: 'lastName', title: 'Last Name', dataType: "String"},
    {field: 'preferredName', title: "Preferred Name", dataType: "String"},
  ]

  public employeeColumns: any[] = [
    { field: "employee['firstName']", subField:"firstName", title: "First Name" },
    { field: "employee['lastName']", subField:"lastName", title: "Last Name" },
    // { field: "employee['title']['text']", subField:"title", subSubField:"text", title: "Title" },
    { field: "employee['preferredName']", subField:"preferredName", title: "Preferred Name" },
    // { field: "employee['maidenName']", subField:"maidenName", title: "Maiden Name" },
    // { field: "employee['middleName']", subField:"middleName", title: "Middle Name" },
    // { field: "employee['gender']['text']", subField:"gender", subSubField:"text", title: "Gender" },
    // { field: "employee['culturalAffiliation']['text']", subField:"culturalAffiliation", subSubField:"text", title: "Cultural Affiliation" },
    // { field: "employee['religiousAffiliation']['text']", subField:"religiousAffiliation", subSubField:"text", title: "Religious Affiliation" },
    // { field: "employee['sexualOrientation']['text']", subField:"sexualOrientation", subSubField:"text", title: "Sexual Orientation" },
    // { field: "employee['customClientId']", subField:"customClientId", title: "Custom Client Id" },
    // { field: "employee['linkedInUrl']", subField:"linkedInUrl", title: "Linked In Url" },
    // { field: "employee['twitterHandle']", subField:"twitterHandle", title: "Twitter Handle" },
    // { field: "employee['facebookUrl']", subField:"facebookUrl", title: "Facebook Url" },
    // { field: "employee['status']", subField:"status", title: "Status" },
    // { field: "employee['birthday']", subField:"birthday", type: "date", title: "Birthday" },
    // { field: "employee['maritalStatus']['text']", subField:"maritalStatus", subSubField:"text", title: "Marital Status" },
    // { field: "employee['subStatus']['text']", subField:"subStatus", subSubField:"text", title: "Sub Status" },
    // { field: "employee['preferredLanguage']", subField:"preferredLanguage", title: "Preferred Language" },
    // { field: "employee['streetAddress']", subField:"streetAddress", title: "Street Address" },
    // { field: "employee['city']", subField:"city", title: "City" },
    // { field: "employee['province']", subField:"province", title: "Province" },
    // { field: "employee['zip']", subField:"zip", title: "ZIP" },
    // { field: "employee['country']['name']", subField:"country", subSubField:"name", title: "Country" },
    // { field: "employee['email']", subField:"email", title: "Email" },
    // { field: "employee['workPhone']", subField:"workPhone", title: "Work Phone" },
    // { field: "employee['homePhone']", subField:"homePhone", title: "Home Phone" },
    // { field: "employee['cellPhone']", subField:"cellPhone", title: "Cell Phone" },
    // { field: "employee['culture']['text']", subField:"culture", subSubField:"text", title: "Culture" },
    // { field: "employee['timezone']['text']", subField:"timezone", subSubField:"text", title: "Timezone" },
    // { field: "employee['positions']", subField:"positions", arraySubField:"name", type: "array", title: "Positions" },
    // { field: "employee['workLocations']", subField:"workLocations", arraySubField:"name", type: "array", title: "Work Locations" },
    // { field: "employee['departments']", subField:"departments", arraySubField:"text", type: "array", title: "Departments" },
    // { field: "employee['organizationUnits']", subField:"organizationUnits", arraySubField:"name", type: "array", title: "Organization Units" },
    // { field: "employee['streetAddress2']", subField:"streetAddress2", title: "Street Address 2" },
    // { field: "employee['municipality']", subField:"municipality", title: "Municipality" },
    // { field: "employee['region1']", subField:"region1", title: "Region 1" },
    // { field: "employee['region2']", subField:"region2", title: "Region 2" },
    // { field: "employee['region3']", subField:"region3", title: "Region 3" },
    // { field: "employee['neighbourhood']", subField:"neighbourhood", title: "Neighbourhood" },
    // { field: "employee['email2']", subField:"email2", title: "Email 2" },
    // { field: "employee['internetAddress']", subField:"internetAddress", title: "Internet Address" },
    // { field: "employee['socialSecurityNumber']", subField:"socialSecurityNumber", title: "Social Security Number" },
    // { field: "employee['identityNumber']", subField:"identityNumber", title: "Identity Number" },
    // { field: "employee['regionOfBirth']", subField:"regionOfBirth", title: "Region Of Birth" },
    // { field: "employee['townOfBirth']", subField:"townOfBirth", title: "Town Of Birth" },
    // { field: "employee['poBox']", subField:"poBox", title: "PO Box" },
    // { field: "employee['registeredDisabled']['text']", subField:"registeredDisabled", subSubField:"text", title: "Registered Disabled" },
    // { field: "employee['disability']['text']", subField:"disability", subSubField:"text", title: "Disability" },
    // { field: "employee['veteranStatus']['text']", subField:"veteranStatus", subSubField:"text", title: "Veteran Status" },
    // { field: "employee['equalEmploymentOpportunity']['text']", subField:"equalEmploymentOpportunity", subSubField:"text", title: "Equal Employment Opportunity" },
    // { field: "employee['nationality']['text']", subField:"nationality", subSubField:"text", title: "Nationality" },
    // { field: "employee['countryOfBirth']['text']", subField:"countryOfBirth", subSubField:"text", title: "Country Of Birth" },
    // { field: "employee['organization']['name']", subField:"organization", subSubField:"name", title: "Organization" },
    // { field: "employee['preferredPronoun']", subField:"preferredPronoun", title: "Preferred Pronoun" },
  ]

  public columns: any[] = [
    { field: "employee['customClientId']", subField:"customClientId", title: "Custom Client Id" },
    { field: "employee['title']['text']", subField:"title", subSubField:"text", title: "Title" },
    { field: "employee['lastName']", subField:"lastName", title: "Last Name"},
    { field: "employee['firstName']", subField:"firstName", title: "First Name"},
    { field: "employee['formalName']", title: "Formal Name", },
    { field: "employee['preferredName']", subField:"preferredName", title: "Preferred Name" },
    { field: "employee['middleName']", subField:"middleName", title: "Middle Name" },
    { field: "employee['gender']['text']", subField:"gender", subSubField:"text", title: "Gender" },
    { field: "employee['streetAddress']", subField:"streetAddress", title: "Street Address" },
    { field: "employee['streetAddress2']", subField:"streetAddress2", title: "Street Address 2" },
    { field: "employee['city']", subField:"city", title: "City" },
    { field: "employee['province']", subField:"province", title: "Province" },
    { field: "employee['zip']", subField:"zip", title: "ZIP" },
    { field: "employee['homePhone']", subField:"homePhone", title: "Home Phone" },
    { field: "employee['cellPhone']", subField:"cellPhone", title: "Cell Phone" },
    { field: "employee['birthday']", subField:"birthday", type: "date", title: "Birthday" },
    { field: "employee['age']", title: "Age", },
    { field: "employee['nextBirthday']", type: "date", title: "Next Birthday" },
    { field: "position['clientPositionId']", title: "Client Position Id" },
    { field: "position['name']", title: "Position" },
    { field: "employee['organizationUnits']", subField:"organizationUnits", arraySubField:"name", type: "array", title: "Organization Units" },
    { field: "workLocations", title: "Work Locations" },
    { field: "employmentRecord['employmentStatus']", title: "Employment Status" },
    { field: "employmentRecord['hireDate']", subField:"hireDate", title: "Hire Date", type: "date" },
    { field: "employmentRecord['serviceLength']", title: "Service Length" },
    { field: "position['startDate']", title: "Position Start Date" },
    { field: "position['udd1']", title: "Position Probation End Date" },
    { field: "employmentRecord['terminationDateActual']", subField:"terminationDateActual", title: "Termination Date Actual", type: "date" },
    { field: "compensation['payFrequency']['text']", subField:"payFrequency", subSubField:"text", title: "Pay Frequency" },
    { field: "compensation['payType']['text']", subField:"payType", subSubField:"text", title: "Pay Type" },
    { field: "compensation['payRateCurrency']['id']", subField:"payRateCurrency", subSubField:"id", title: "Pay Rate Currency" },
    { field: "compensation['payRate']", subField:"payRate", title: "Pay Rate" },
    { field: "position['workRotation']['name']", title: "Position Work Rotation" },
    { field: "compensation['hoursPerWeek']", subField:"hoursPerWeek", title: "Hours Per Week" },
    { field: "compensation['fte']", subField:"fte", title: "FTE" },
    { field: "position['employmentType']['text']", title: "Position Employment Type" },
    { field: "employmentRecord['payrollNumber']", subField:"payrollNumber", title: "Payroll Number"},
    { field: "employee['managers']", title: "Managers" },
    { field: "employmentRecord['email']", subField:"email", title: "Work Email"},
    { field: "employee['workPhone']", subField:"workPhone", title: "Work Phone" },
    { field: "employee['email']", subField:"email", title: "Email" },
    { field: "employee['maritalStatus']['text']", subField:"maritalStatus", subSubField:"text", title: "Marital Status" },
    { field: "employee['maidenName']", subField:"maidenName", title: "Maiden Name" },
    { field: "employee['nationality']['text']", subField:"nationality", subSubField:"text", title: "Nationality" },
    { field: "employee['culturalAffiliation']['text']", subField:"culturalAffiliation", subSubField:"text", title: "Cultural Affiliation" },
    { field: "employee['registeredDisabled']['text']", subField:"registeredDisabled", subSubField:"text", title: "Registered Disabled" },
    { field: "employee['disability']['text']", subField:"disability", subSubField:"text", title: "Disability" },



    // { field: "position['name']", title: "Position" },
    // { field: "position['startDate']", title: "Position Start Date" },
    // { field: "position['fixedTermEndDate']", title: "Position Fixed Term End Date" },
    // { field: "position['udd1']", title: "Position Probation End Date" },
    // { field: "position['clientPositionId']", title: "Client Position Id" },

    // { field: "employee['firstName']", subField:"firstName", title: "First Name", sticky: true },
    // { field: "employee['lastName']", subField:"lastName", title: "Last Name", sticky: true },
    // { field: "employee['formalName']", title: "Formal Name", },
    // { field: "employee['age']", title: "Age", },
    // { field: "employee['title']['text']", subField:"title", subSubField:"text", title: "Title" },
    // { field: "employee['preferredName']", subField:"preferredName", title: "Preferred Name" },
    // { field: "employee['maidenName']", subField:"maidenName", title: "Maiden Name" },
    // { field: "employee['middleName']", subField:"middleName", title: "Middle Name" },
    // { field: "employee['gender']['text']", subField:"gender", subSubField:"text", title: "Gender" },
    // { field: "employee['culturalAffiliation']['text']", subField:"culturalAffiliation", subSubField:"text", title: "Cultural Affiliation" },
    { field: "employee['religiousAffiliation']['text']", subField:"religiousAffiliation", subSubField:"text", title: "Religious Affiliation" },
    { field: "employee['sexualOrientation']['text']", subField:"sexualOrientation", subSubField:"text", title: "Sexual Orientation" },
    { field: "employee['linkedInUrl']", subField:"linkedInUrl", title: "Linked In Url" },
    { field: "employee['twitterHandle']", subField:"twitterHandle", title: "Twitter Handle" },
    { field: "employee['facebookUrl']", subField:"facebookUrl", title: "Facebook Url" },
    { field: "employee['status']", subField:"status", title: "Status" },
    // { field: "employee['birthday']", subField:"birthday", type: "date", title: "Birthday" },
    // { field: "employee['nextBirthday']", type: "date", title: "Next Birthday" },
    // { field: "employee['maritalStatus']['text']", subField:"maritalStatus", subSubField:"text", title: "Marital Status" },
    { field: "employee['subStatus']['text']", subField:"subStatus", subSubField:"text", title: "Sub Status" },
    { field: "employee['preferredLanguage']", subField:"preferredLanguage", title: "Preferred Language" },
    // { field: "employee['streetAddress']", subField:"streetAddress", title: "Street Address" },
    // { field: "employee['city']", subField:"city", title: "City" },
    // { field: "employee['province']", subField:"province", title: "Province" },
    // { field: "employee['zip']", subField:"zip", title: "ZIP" },
    // { field: "employee['country']['name']", subField:"country", subSubField:"name", title: "Country" },
    // { field: "employee['email']", subField:"email", title: "Email" },
    // { field: "employee['workPhone']", subField:"workPhone", title: "Work Phone" },
    // { field: "employee['homePhone']", subField:"homePhone", title: "Home Phone" },
    // { field: "employee['cellPhone']", subField:"cellPhone", title: "Cell Phone" },
    { field: "employee['culture']['text']", subField:"culture", subSubField:"text", title: "Culture" },
    { field: "employee['timezone']['text']", subField:"timezone", subSubField:"text", title: "Timezone" },
    // { field: "employee['departments']", subField:"departments", arraySubField:"text", type: "array", title: "Departments" },
    // { field: "employee['streetAddress2']", subField:"streetAddress2", title: "Street Address 2" },
    { field: "employee['municipality']", subField:"municipality", title: "Municipality" },
    { field: "employee['region1']", subField:"region1", title: "Region 1" },
    { field: "employee['region2']", subField:"region2", title: "Region 2" },
    { field: "employee['region3']", subField:"region3", title: "Region 3" },
    { field: "employee['neighbourhood']", subField:"neighbourhood", title: "Neighbourhood" },
    { field: "employee['email2']", subField:"email2", title: "Email 2" },
    { field: "employee['internetAddress']", subField:"internetAddress", title: "Internet Address" },
    { field: "employee['socialSecurityNumber']", subField:"socialSecurityNumber", title: "Social Security Number" },
    // { field: "employee['identityNumber']", subField:"identityNumber", title: "Identity Number" },
    { field: "employee['regionOfBirth']", subField:"regionOfBirth", title: "Region Of Birth" },
    { field: "employee['townOfBirth']", subField:"townOfBirth", title: "Town Of Birth" },
    { field: "employee['poBox']", subField:"poBox", title: "PO Box" },
    // { field: "employee['registeredDisabled']['text']", subField:"registeredDisabled", subSubField:"text", title: "Registered Disabled" },
    // { field: "employee['disability']['text']", subField:"disability", subSubField:"text", title: "Disability" },
    { field: "employee['veteranStatus']['text']", subField:"veteranStatus", subSubField:"text", title: "Veteran Status" },
    { field: "employee['equalEmploymentOpportunity']['text']", subField:"equalEmploymentOpportunity", subSubField:"text", title: "Equal Employment Opportunity" },
    // { field: "employee['nationality']['text']", subField:"nationality", subSubField:"text", title: "Nationality" },
    { field: "employee['countryOfBirth']['text']", subField:"countryOfBirth", subSubField:"text", title: "Country Of Birth" },
    { field: "employee['organization']['name']", subField:"organization", subSubField:"name", title: "Organization" },
    { field: "employee['preferredPronoun']", subField:"preferredPronoun", title: "Preferred Pronoun" },

    // { field: "compensation['payRate']", subField:"payRate", title: "Pay Rate" },
    // { field: "compensation['hoursPerWeek']", subField:"hoursPerWeek", title: "Hours Per Week" },
    { field: "compensation['stiActualAmount']", subField:"stiActualAmount", title: "STI Actual Amount" },
    { field: "compensation['stiActualPercentage']", subField:"stiActualPercentage", title: "STI Actual Percentage" },
    { field: "compensation['stiTargetAmount']", subField:"stiTargetAmount", title: "STI Target Amount" },
    { field: "compensation['stiTargetPercentage']", subField:"stiTargetPercentage", title: "STI Target Percentage" },
    { field: "compensation['compensationRatio']", subField:"compensationRatio", title: "Compensation Ratio" },
    { field: "compensation['comments']", subField:"comments", title: "Comments" },
    { field: "compensation['baseSalary']", subField:"baseSalary", title: "Base Salary" },
    { field: "compensation['baseSalaryFteAdjusted']", subField:"baseSalaryFteAdjusted", title: "Base SalaryFteAdjusted" },
    { field: "compensation['salaryOther']", subField:"salaryOther", title: "Salary Other" },
    { field: "compensation['salaryOtherFteAdjusted']", subField:"salaryOtherFteAdjusted", title: "Salary Other FTE Adjusted" },
    { field: "compensation['salaryNonGmg']", subField:"salaryNonGmg", title: "Salary Non Gmg" },
    { field: "compensation['salaryNonGmgFteAdjusted']", subField:"salaryNonGmgFteAdjusted", title: "Salary Non Gmg FTE Adjusted" },
    { field: "compensation['salarySystem']", subField:"salarySystem", title: "Salary System" },
    { field: "compensation['salarySystemFteAdjusted']", subField:"salarySystemFteAdjusted", title: "Salary System FTE Adjusted" },
    { field: "compensation['sterlineEquivalent']", subField:"sterlineEquivalent", title: "Sterline Equivalent" },
    // { field: "compensation['fte']", subField:"fte", title: "FTE" },
    { field: "compensation['adjustedFte']", subField:"adjustedFte", title: "Adjusted FTE" },
    { field: "compensation['term']['text']", subField:"term", subSubField:"text", title: "Term" },
    { field: "compensation['marketView']['text']", subField:"marketView", subSubField:"text", title: "Market View" },
    // { field: "compensation['payType']['text']", subField:"payType", subSubField:"text", title: "Pay Type" },
    { field: "compensation['payGrade']['text']", subField:"payGrade", subSubField:"text", title: "Pay Grade" },
    { field: "compensation['roleGroup']['text']", subField:"roleGroup", subSubField:"text", title: "Role Group" },
    { field: "compensation['roleCategory']['text']", subField:"roleCategory", subSubField:"text", title: "Role Category" },
    { field: "compensation['payScale']['text']", subField:"payScale", subSubField:"text", title: "Pay Scale" },
    { field: "compensation['payLevel']['text']", subField:"payLevel", subSubField:"text", title: "Pay Level" },
    // { field: "compensation['payFrequency']['text']", subField:"payFrequency", subSubField:"text", title: "Pay Frequency" },
    { field: "compensation['overtimeEligible']['text']", subField:"overtimeEligible", subSubField:"text", title: "Overtime Eligible" },
    { field: "compensation['stiIncentivePlan']['text']", subField:"stiIncentivePlan", subSubField:"text", title: "sSTI Incentive Plan" },
    { field: "compensation['ltiPsu']['text']", subField:"ltiPsu", subSubField:"text", title: "LTI Psu" },
    { field: "compensation['ltiStockOption']['text']", subField:"ltiStockOption", subSubField:"text", title: "LTI Stock Option" },
    { field: "compensation['ltiRsu']['text']", subField:"ltiRsu", subSubField:"text", title: "LTI Rsu" },
    { field: "compensation['payrollId']['text']", subField:"payrollId", subSubField:"text", title: "Payroll Id" },
    // { field: "compensation['payRateCurrency']['id']", subField:"payRateCurrency", subSubField:"id", title: "Pay Rate Currency" },
    { field: "compensation['salaryOtherCurrency']['id']", subField:"salaryOtherCurrency", subSubField:"id", title: "Salary Other Currency" },
    { field: "compensation['salaryNonGmgCurrency']['id']", subField:"salaryNonGmgCurrency", subSubField:"id", title: "Salary Non Gmg Currency" },
    { field: "compensation['salarySystemCurrency']['id']", subField:"salarySystemCurrency", subSubField:"id", title: "Salary System Currency" },
    { field: "compensation['stiCurrency']['id']", subField:"stiCurrency", subSubField:"id", title: "STI Currency" },
    { field: "compensation['payRateInterval']['text']", subField:"payRateInterval", subSubField:"text", title: "Pay Rate Interval" },
    { field: "compensation['country']['text']", subField:"country", subSubField:"name", title: "Compensation Country" },

    { field: "employmentRecord['id']", subField:"id", title: "id" },
    // { field: "employmentRecord['employmentStatus']", title: "Employment Status" },
    { field: "employmentRecord['searchFee']", subField:"searchFee", title: "Search Fee" },
    { field: "employmentRecord['terminationSeveranceAmount']", subField:"terminationSeveranceAmount", title: "Termination Severance Amount" },
    { field: "employmentRecord['terminationPayInLieu']", subField:"terminationPayInLieu", title: "Termination Pay In Lieu" },
    { field: "employmentRecord['terminationVacationPayout']", subField:"terminationVacationPayout", title: "Termination Vacation Payout" },
    { field: "employmentRecord['terminationOtherPayment']", subField:"terminationOtherPayment", title: "Termination Other Payment" },
    { field: "employmentRecord['probationPeriodLength']", subField:"probationPeriodLength", title: "Probation Period Length" },
    { field: "employmentRecord['noticePeriodLength']", subField:"noticePeriodLength", title: "Notice Period Length" },
    // { field: "employmentRecord['hireDate']", subField:"hireDate", title: "Hire Date", type: "date" },
    { field: "employmentRecord['inductionCompletedOn']", subField:"inductionCompletedOn", title: "Induction Completed On", type: "date" },
    { field: "employmentRecord['medicalCompletedDate']", subField:"medicalCompletedDate", title: "Medical Completed Date", type: "date" },
    { field: "employmentRecord['codeOfConductSignedOn']", subField:"codeOfConductSignedOn", title: "Code Of Conduct Signed On", type: "date" },
    { field: "employmentRecord['probationPeriodEnd']", subField:"probationPeriodEnd", title: "Probation Period End", type: "date" },
    { field: "employmentRecord['terminationLastDateOnSite']", subField:"terminationLastDateOnSite", title: "Termination Last Date On Site", type: "date" },
    { field: "employmentRecord['terminationDateNotified']", subField:"terminationDateNotified", title: "Termination Date Notified", type: "date" },
    { field: "employmentRecord['terminationDateProjected']", subField:"terminationDateProjected", title: "Termination Date Projected", type: "date" },
    { field: "employmentRecord['terminationBenefitsEndOfNotice']", subField:"terminationBenefitsEndOfNotice", title: "Termination Benefits End Of Notice", type: "date" },
    { field: "employmentRecord['terminationBenefitsEnd']", subField:"terminationBenefitsEnd", title: "Termination Benefits End", type: "date" },
    { field: "employmentRecord['terminationAcceptedByDate']", subField:"terminationAcceptedByDate", title: "Termination Accepted By Date", type: "date" },
    { field: "employmentRecord['terminationAcceptedByName']", subField:"terminationAcceptedByName", title: "Termination Accepted By Name"},
    { field: "employmentRecord['terminationComments']", subField:"terminationComments", title: "Termination Comments"},
    // { field: "employmentRecord['payrollNumber']", subField:"payrollNumber", title: "Payroll Number"},
    { field: "employmentRecord['phoneNumber']", subField:"phoneNumber", title: "Phone Number"},
    { field: "employmentRecord['cellNumber']", subField:"cellNumber", title: "Cell Number"},
    { field: "employmentRecord['office']", subField:"office", title: "Office"},
    { field: "employmentRecord['rehireEligibilityComments']", subField:"rehireEligibilityComments", title: "Rehire Eligibility Comments"},
    { field: "employmentRecord['hireDetailComments']", subField:"hireDetailComments", title: "Hire Detail Comments"},
    { field: "employmentRecord['terminationRecordOfEmploymentComment']", subField:"terminationRecordOfEmploymentComment", title: "Termination Record Of Employment Comment"},
    { field: "employmentRecord['budgetAuthority']", subField:"budgetAuthority", title: "Budget Authority"},
    { field: "employmentRecord['supervisorLegacyId']", subField:"supervisorLegacyId", title: "Supervisor Legacy Id"},
    { field: "employmentRecord['terminationItConfirmation']", subField:"terminationItConfirmation", title: "Termination IT Confirmation", type: "boolean" , default: ["Yes", "No"]},
    { field: "employmentRecord['terminationVoluntary']", subField:"terminationVoluntary", title: "Termination Voluntary", type: "boolean", default: ["Yes", "No"]},
    { field: "employmentRecord['graduateProgramStartDate']", subField:"graduateProgramStartDate", title: "Graduate Program Start Date", type: "date" },
    { field: "employmentRecord['graduateProgramEndDate']", subField:"graduateProgramEndDate", title: "Graduate Program End Date", type: "date" },
    { field: "employmentRecord['apprenticeshipProgramStartDate']", subField:"apprenticeshipProgramStartDate", title: "Apprenticeship Program Start Date", type: "date" },
    { field: "employmentRecord['apprenticeshipProgramEndDate']", subField:"apprenticeshipProgramEndDate", title: "Apprenticeship Program End Date", type: "date" },
    { field: "employmentRecord['payrollCode']['text']", subField:"payrollCode", subSubField:"text", title: "Payroll Code" },
    { field: "employmentRecord['terminationRehireRecommend']['text']", subField:"terminationRehireRecommend", subSubField:"text", title: "Termination Rehire Recommend" },
    { field: "employmentRecord['terminationRehireReason']['text']", subField:"terminationRehireReason", subSubField:"text", title: "Termination Rehire Reason" },
    { field: "employmentRecord['codeOfConductSigned']['text']", subField:"codeOfConductSigned", subSubField:"text", title: "Code Of Conduct Signed" },
    { field: "employmentRecord['medicalCompleted']['text']", subField:"medicalCompleted", subSubField:"text", title: "Medical Completed" },
    { field: "employmentRecord['securityClearance']['text']", subField:"securityClearance", subSubField:"text", title: "Security Clearance" },
    { field: "employmentRecord['noticePeriodUnits']['text']", subField:"noticePeriodUnits", subSubField:"text", title: "Notice Period Units" },
    { field: "employmentRecord['reHireEligibility']['text']", subField:"reHireEligibility", subSubField:"text", title: "ReHire Eligibility" },
    { field: "employmentRecord['hireCategory']['text']", subField:"hireCategory", subSubField:"text", title: "Hire Category" },
    { field: "employmentRecord['terminationSpecialCircumstances']['text']", subField:"terminationSpecialCircumstances", subSubField:"text", title: "Termination Special Circumstances" },
    { field: "employmentRecord['terminationRecordOfEmploymentReason']['text']", subField:"terminationRecordOfEmploymentReason", subSubField:"text", title: "Termination Record Of Employment Reason" },
    { field: "employmentRecord['recruitmentAgency']['text']", subField:"recruitmentAgency", subSubField:"text", title: "Recruitment Agency" },
    { field: "employmentRecord['terminationReason']['text']", subField:"terminationReason", subSubField:"text", title: "Termination Reason" },
    { field: "employmentRecord['subStatus']['text']", subField:"subStatus", subSubField:"text", title: "Sub Status" },
    { field: "employmentRecord['graduateProgramMember']['text']", subField:"graduateProgramMember", subSubField:"text", title: "Graduate Program Member" },
    { field: "employmentRecord['hireDetailsUdd1']", subField:"hireDetailsUdd1", title: "Hire Details Udd1" },
    { field: "employmentRecord['hireDetailsUdd2']", subField:"hireDetailsUdd2", title: "Hire Details Udd2" },
    { field: "employmentRecord['office2']", subField:"office2", title: "Office2" },
    { field: "employmentRecord['hireDetailsUdf1']['text']", subField:"hireDetailsUdf1", subSubField:"text", title: "Hire Details Udf1" },
    { field: "employmentRecord['hireDetailsUdf3']['text']", subField:"hireDetailsUdf3", subSubField:"text", title: "Hire Details Udf3" },
    { field: "employmentRecord['reHireReason']['text']", subField:"reHireReason", subSubField:"text", title: "Re Hire Reason" },
    { field: "employmentRecord['startReason']['text']", subField:"startReason", subSubField:"text", title: "Start Reason" },
    { field: "employmentRecord['hiredAt']['name']", subField:"hiredAt", subSubField:"name", title: "Hired At" },
    { field: "employmentRecord['probationPeriodUnit']['name']", subField:"probationPeriodUnit", subSubField:"name", title: "Probation Period Unit" },

    { field: "positions", title: "positions" },

    { field: "employee['identityNumber']", subField:"identityNumber", title: "Identity Number" },
    { field: "employee['country']['name']", subField:"country", subSubField:"name", title: "Country" },
    { field: "position['fixedTermEndDate']", title: "Position Fixed Term End Date" },

    // { field: "workLocations", title: "Work Locations" },
  ];

  public state: State = {
    skip: 0,
    take: 50,
  };

  public query: Observable<GridDataResult>;
  private stateChange = new BehaviorSubject<State>(this.state);
  public allData = (): Observable<GridDataResult> => {
    return from(this.basicEmployeeReportService.queryAll(this.state));
  };

  constructor(
        private translate: TranslateService,
        private ngZone: NgZone,
        private basicEmployeeReportService: BasicEmployeeReportService,
        private sanitiseGridDataService: SanitiseGridDataService
  ) {

    this.query = this.stateChange.pipe(
      tap((state) => {
        this.state = state;
        this.isLoading = true;
      }),
      switchMap((state) => basicEmployeeReportService.fetch(state, this.filterString, this.sortString, false, true, this.filterInactive)),
      tap(() => {
        this.isLoading = false;
      })
    );

    // Bind 'this' explicitly to capture the execution context of the component.
    this.allData = this.allData.bind(this);

  }

  ngOnInit(): void {
  }

  public pageChange(state: PageChangeEvent): void {
    this.stateChange.next(state);
  }

  public sortChange(sort: SortDescriptor[]): void {
    let col = this.employeeColumns.find(column => column.field === sort[0].field)

    if(col !== undefined) {
      this.sort = sort;

      if (sort[0].dir === undefined) {
        this.sortString = null
      }
      else {
        //use regex to get column field to sort with
        let field: any;

        //if it is an object category like (department.text) trim .text from the end, else it doesnt need to be trimmed
        (sort[0].field).match(/.+(?=\.)/) === null
        ? field = sort[0].field
        : field = (sort[0].field).substr((sort[0].field).indexOf('.') + 1 )

        this.sortString = `${col.subField}-${sort[0].dir}`;
      }

      this.state.skip = 0;
      this.state.take = 50;
      this.stateChange.next(this.state);

    }
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.state.skip = 0;
    this.state.take = 50;
    this.stateChange.next(this.state);
  }

  public onExcelExport(e: ExcelExportEvent): void {
    e = this.sanitiseGridDataService.sanitise(e);
  }

  toggleFilterInactive($event: MatSlideToggleChange) {
    this.filterInactive = $event.checked;
    this.stateChange.next(this.state);
  }

}
