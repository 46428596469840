<h5 mat-dialog-title *ngIf="workRotationId">
    {{ 'EditWorkRotation' | translate: {Default: "Edit Work Rotation"} }}
</h5>
<h5 mat-dialog-title *ngIf="!workRotationId">
    {{ 'CreateNewWorkRotation' | translate: {Default: "Create New Work Rotation"} }}
</h5>

<mat-dialog-content >
    <div class="add-lookup-form" [formGroup]="form">
        <div class="form-container">
            <ng-container formArrayName="localizations">
                <ng-container *ngFor="let localizationForm of localizations.controls; let i = index">
                    <div class="lookup-form-row">
                        <div [formGroup]="localizationForm">
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Language
                                </mat-label>
        
                                <mat-select disableOptionCentering formControlName="culture">
                                    <mat-option *ngFor="let culture of cultures" [value]="culture.id">{{culture.text}}</mat-option>
                                </mat-select>
                            </mat-form-field>
        
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    {{ 'WorkRotationName' | translate: {Default: "Work Rotation Name"} }}
                                </mat-label>
        
                                <input matInput
                                    formControlName="text">
                            </mat-form-field>
                        </div>
                        <mat-icon class="material-icons-outlined" (click)="deleteLocalization(i)" matTooltip="Delete">delete</mat-icon> 
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>

</mat-dialog-content>

<mat-dialog-actions *ngIf="form">
    <button mat-raised-button (click)="addNewLocalization()">
        <mat-icon class="material-icons-outlined">add</mat-icon> Add text Localization
    </button>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid">Save</button> 
</mat-dialog-actions>
