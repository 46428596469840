import { Component, OnInit } from '@angular/core';
import { features } from '@app/consts';
import { FeatureService } from '@app/core/services/feature.service';
import { FormGeneratorComponent } from '@app/shared/components/form-generator/form-generator.component';
import { SecurityProtectedBase } from '@app/shared/components/security-protected/security-protected';

@Component({
  selector: 'app-position-editor-page',
  templateUrl: './position-editor-page.component.html',
  styleUrls: ['./position-editor-page.component.scss']
})
export class PositionEditorPageComponent extends SecurityProtectedBase implements OnInit {
  formGeneratorComponent: FormGeneratorComponent;

  constructor(
    featureService: FeatureService,
  ) { 
    super(featureService, features.POSITIONS)
  }

  ngOnInit(): void {
  }

  formGeneratorComponentEmitted(formGeneratorComponent: FormGeneratorComponent) {
    this.formGeneratorComponent = formGeneratorComponent;
  }

}
