import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CurrentUserDetails, Employee, EmployeeTableSecurity, TalentSubmit, TalentVerbose } from '@app/shared/models/employee.model';
import { api_routes } from '@app/consts';
import { DomSanitizer } from '@angular/platform-browser';
import { EnvironmentService } from "@app/core/services/environment.service";

//

const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  currentUserDetails: CurrentUserDetails;
  endpoint: String = this.environmentService.env.apiUrl;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private environmentService: EnvironmentService
  ) {}

  syncCurrentUserDetails(callback: () => any) {
    return this.http.get<any>(`${this.endpoint}${api_routes.SECURITY}/${api_routes.USERS}/${api_routes.CURRENT_USER}`,
        {
            headers: headers
        })
        .subscribe(res => {
            this.currentUserDetails = res;
            callback();
        });
  }

  getFetchedCurrentUserDetails() {
      return this.currentUserDetails;
  }

  getEmployees(pageSize?: string, page?: string, nextCursor?: string, properties?: string, filter?: string, orderBy?: string): Observable<any> {
    let params = new HttpParams();
    pageSize ? params = params.append('pageSize', pageSize) : null;
    page ? params = params.append('page', page) : null;
    nextCursor ? params = params.append('nextCursor', nextCursor) : null;
    properties ? params = params.append('properties', properties) : null;
    filter ? params = params.append('filter', filter) : null;

    return this.http
      .get<any>(`${this.endpoint}${api_routes.TALENT}`, {
        headers: headers,
        observe: 'response',
        params: params
      })
      .pipe(
        catchError(err => throwError(err)),
        map(res => {
          return res;
        })
      );
  }

  getEmployeesNew(skip?: number, take?: number): Observable<any> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    // nextCursor ? params = params.append('nextCursor', nextCursor) : null;
    // properties ? params = params.append('properties', properties) : null;
    // filter ? params = params.append('filter', filter) : null;

    return this.http
      .get<any>(`${this.endpoint}${api_routes.TALENT}`, {
        headers: headers,
        observe: 'response',
        params: params
      })
      .pipe(
        catchError(err => throwError(err)),
        map(res => {
          return res.body;
        })
      );
  }

  getEmployeesKendo(skip?: number, take?: string, filterString?: string, sortString?: string): Observable<any> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    filterString ? params = params.append('Filter', filterString) : null;
    sortString ? params = params.append('Sort', sortString) : null;

    return this.http
      .get<any>(`${this.endpoint}${api_routes.TALENT}`, {
        headers: headers,
        observe: 'response',
        params: params
      })
      .pipe(
        catchError(err => throwError(err)),
        map(res => {
          return res;
        })
      );
  }

  getTalent(id: string, asOf?: string): Observable<TalentVerbose> {
    let params = new HttpParams();
    asOf ? params = params.append('AsOf', asOf) : null;

    return this.http.get<TalentVerbose>(`${this.endpoint}${api_routes.TALENT}/${id}`, {
      params: params,
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  createTalent(talentSubmit: TalentSubmit): Observable<any> {
    return this.http.post<TalentSubmit>(`${this.endpoint}${api_routes.TALENT}`, talentSubmit, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  deleteTalent(id: string): Observable<any> {
    return this.http.delete<any>(`${this.endpoint}${api_routes.TALENT}/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  updateTalent(talentSubmit: TalentSubmit): Observable<any> {
    return this.http.put<TalentSubmit>(`${this.endpoint}${api_routes.TALENT}/${talentSubmit.id}`, talentSubmit, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  getEmployeeHistory(id: string): Observable<any> {
    return this.http.get<Employee>(`${this.endpoint}${api_routes.TALENT}/${id}/History`, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  getEmployeeProfilePicture(id: string): any {
    return this.http.get(`${this.endpoint}${api_routes.TALENT}/${id}/${api_routes.PHOTO}`, { responseType: 'text' })
  }

  getEmployeeTableSecurity(id: string): Observable<EmployeeTableSecurity[]> {
    return this.http.get<any>(`${this.endpoint}${api_routes.TALENT}/${id}/${api_routes.TABLE_SECURITY}`, {
      headers: headers
    });
  }

  getCurrentUserDetails(): Observable<CurrentUserDetails> {
    return this.http.get<any>(`${this.endpoint}${api_routes.SECURITY}/${api_routes.USERS}/${api_routes.CURRENT_USER}`, {
      headers: headers
    });
  }
}
