import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import {SecurityProtectedBase} from '@app/shared/components/security-protected/security-protected';
import {FeatureService} from '@app/core/services/feature.service';
import {features} from '@app/consts';

@Component({
  selector: 'app-work-locations',
  templateUrl: './work-locations.component.html',
  styleUrls: ['./work-locations.component.scss']
})
export class WorkLocationsComponent extends SecurityProtectedBase implements OnInit {
  eventsSubject: Subject<void> = new Subject<void>();
  
  constructor(featureService: FeatureService) {
    super(featureService, features.WORK_LOCATIONS)
  }

  ngOnInit(): void {
  }

  emitEventToChild() {
    this.eventsSubject.next();
  }
}
