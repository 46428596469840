import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { EmployeeCompensationService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-compensation/services/employee-compensation.service';
import { GridDataResult } from '@progress/kendo-angular-grid';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-compensation-history-widget-dialog',
  templateUrl: './compensation-history-widget-dialog.component.html',
  styleUrls: ['./compensation-history-widget-dialog.component.scss']
})
export class CompensationHistoryWidgetDialogComponent implements OnInit {
  user$: any;
  public gridDataResult: GridDataResult;
  public isLoading: boolean = true;

  public columns: any[] = [
    {field: 'baseSalary', title: 'Base Salary'},
    {field: 'baseSalaryFteAdjusted', title: 'Base Salary Fte Adjusted'},
    {field: 'payRateCurrency', subField: 'id', title: 'pay Rate Currency'},
    {field: 'payRate', title: 'Pay Rate'},
    {field: 'payRateInterval', subField: 'text', title: 'pay Rate Interval'},
    {field: 'hoursPerWeek', title: 'hours Per Week'},
    {field: 'salaryOtherCurrency', subField: 'id', title: 'salary Other Currency'},
    {field: 'salaryNonGmgCurrency', subField: 'id', title: 'salary NonGmg Currency'},
    {field: 'salarySystemCurrency', subField: 'id', title: 'salary System Currency'},
    {field: 'stiIncentivePlan', subField: 'text', title: 'sti Incentive Plan'},
    {field: 'stiActualAmount', title: 'sti Actual Amount'},
    {field: 'stiActualPercentage', title: 'sti Actual Percentage'},
    {field: 'stiTargetAmount', title: 'sti Target Amount'},
    {field: 'stiTargetPercentage', title: 'sti Target Percentage'},
    {field: 'stiCurrency', subField: 'id', title: 'sti Currency'},
    {field: 'ltiPsu', subField: 'text', title: 'lti Psu'},
    {field: 'ltiStockOption', subField: 'text', title: 'lti Stock Option'},
    {field: 'ltiRsu', subField: 'text', title: 'lti Rsu'},
    {field: 'compensationRatio', title: 'compensation Ratio'},
    {field: 'comments', title: 'comments'},
    {field: 'term', subField: 'text', title: 'term'},
    {field: 'marketView', subField: 'text', title: 'market View'},
    {field: 'payType', subField: 'text', title: 'pay Type'},
    {field: 'payGrade', subField: 'text', title: 'pay Grade'},
    {field: 'roleGroup', subField: 'text', title: 'role Group'},
    {field: 'roleCategory', subField: 'text', title: 'role Category'},
    {field: 'payScale', subField: 'text', title: 'pay Scale'},
    {field: 'payLevel', subField: 'text', title: 'pay Level'},
    {field: 'payFrequency', subField: 'text', title: 'pay Frequency'},
    {field: 'overtimeEligible', subField: 'text', title: 'overtime Eligible'},
    {field: 'country', subField: 'name', title: 'country'},
  ];

  constructor(
    private dialogRef: MatDialogRef<CompensationHistoryWidgetDialogComponent>,
    private oidcAuthService: OidcAuthService,
    private employeeCompensationService: EmployeeCompensationService,
  ) {
    this.user$ = this.oidcAuthService.userProfile;
  }

  ngOnInit(): void {
    this.getHistory();
  }

  getHistory() {
    this.isLoading = true;

    this.employeeCompensationService.getHistory(this.user$.userId)
    .pipe(
      finalize(() => this.isLoading = false)
    )
    .subscribe(
      res => {
        // Remove all future effective date items
        res.data = res.data.filter( (history) => {
          return moment(history.version.effectiveDate).isSameOrBefore(moment())
        })

        this.gridDataResult = {
          data: res.data,
          total: res.data.length,
        }
        
      }
    )
  }

  wasModified(column: string, record: any) {
    var res = false;
    if (record?.version?.modifiedList && Array.isArray(record?.version?.modifiedList)) {
      record?.version?.modifiedList.forEach(changedItem => {
        if (changedItem.toLowerCase() == column.toLowerCase()) {
          res = true
        }
      })
    }
    return res;
  }

  close(){
    this.dialogRef.close();
  }
}
