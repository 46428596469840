<kendo-grid 
  [data]="gridDataResult" 
  [pageSize]="pageSize"
  [style.maxHeight.vh]="85" 
  [skip]="skip"
  [pageable]="{
      info: true,
      pageSizes: [5, 10, 20, 50, 100],
      previousNext: true
    }"
  (pageChange)="pageChange($event)"
  [loading]="isLoading"
  kendoGridSelectBy="id"
  [selectedKeys]="selectedItems"
  [sortable]="true"
  [sort]="sort"
  (sortChange)="sortChange($event)"
  >

  <ng-template kendoGridToolbarTemplate>
    <div *ngIf="selectedItems.length > 0">
      <button class="delete-all-selected-button" mat-stroked-button setColor="primaryColour" color="primary" (click)="deleteAllSelected()">
        <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">delete</mat-icon> Delete All Selected ({{selectedItems.length}})
      </button>
    </div>

    <div class="filters-container">
        <mat-form-field class="search-field" appearance="outline">
            <input matInput
                   placeholder="Search..."
                   [(ngModel)]="searchValue" 
                   (input)="search()"
                   >
            <mat-icon matPrefix class="material-icons-outlined">
                search
            </mat-icon>
        </mat-form-field>
    </div>

  </ng-template>

  <kendo-grid-checkbox-column
    [width]="80"
    showSelectAll="true"
  ></kendo-grid-checkbox-column>

  <kendo-grid-column field="name" title="Name" [width]="150">
    <ng-template kendoGridCellTemplate let-dataItem >
        <div class="grid-cell-vertical">
            <span class="position-name navigation-link" routerLink="{{routes.FORM}}{{routes.EDIT}}/{{dataItem.id}}">
                {{dataItem.name}}
            </span>
        </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="table" title="Table" [width]="150">
    <ng-template kendoGridCellTemplate let-dataItem >
        <div class="grid-cell-vertical">
            <span>
                {{dataItem.table?.name}}
            </span>
        </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-messages 
    [noRecords]="isLoading ? '' : translate.instant('Grid-NoRecordsAvailable')"
    [pagerItemsPerPage]="translate.instant('Grid-Pager-ItemsPerPage')" 
    [pagerItems]="translate.instant('Grid-Pager-Items')"
    [pagerOf]="translate.instant('Grid-Pager-Of')"
  ></kendo-grid-messages>

  <!-- <kendo-grid-column field="rootTableName" title="Root Table" [width]="150">
    <ng-template kendoGridCellTemplate let-dataItem >
        <div class="grid-cell-vertical">
            <span>
                {{dataItem.rootTableName}}
            </span>
        </div>
    </ng-template>
  </kendo-grid-column>
  


  <kendo-grid-column field="lastModified" title="Last Modified" [width]="150">
    <ng-template kendoGridCellTemplate let-dataItem >
        <div class="grid-cell-vertical">
            <span>
                {{dataItem.version.versionCreatedOn | date:'mediumDate'}}
            </span>
        </div>
    </ng-template>
  </kendo-grid-column> -->

</kendo-grid>