import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {
  public emails: {
    name: string;
    time: string;
    message: string
  }[] = [
    {name: 'Jane Hew', time: '9:32', message: 'Hey! How is it going?'},
    {name: 'Lloyd Brown', time: '9:18', message: 'Check out my new Dashboard'},
    {name: 'Mark Winstein', time: '9:15', message: 'I want rearrange the appointment'},
    {name: 'Liana Dutti', time: '9:09', message: 'Good news from sale department'}
  ];

  public colors: string[] = [
    'yellow',
    'green',
    'blue',
    'ping'
  ];
  
  constructor() { }

  ngOnInit(): void {
  }

}
