<app-layout>
    <mat-toolbar class="page-header" role="heading">
      <h1>Reports</h1>
      <!-- <button mat-flat-button color="warn" routerLink="/ReportsList">Latest Reports</button> -->
      <div>
        <button mat-flat-button setColor="primaryColour" color="primary" (click)="openNewReportDialog()">
          <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> Create Custom Report
        </button>
      </div>
    </mat-toolbar>

    <div class="content">
        <ng-container *ngFor="let report of reports$ | async">
            <ng-container *appHasModuleAccess="report?.module">
                <mat-card 
                    setColor
                    [setColorRules]="[
                        {
                            color: 'primaryColour',
                            style: 'border-color'
                        }
                    ]"
                    [routerLink]="report.href" 
                    class="mat-elevation-z0 primaryHoverColor" 
                    *appHasFeature="report.featureCode"
                >
                    <div class="icon-div">
                        <span setColor="primaryColour" class="material-icons-outlined primaryColor">
                            {{report.icon}}
                        </span>
                    </div>
    
                    <div class="text">
                        <mat-card-title class="header">
                            {{report.name}}
                        </mat-card-title>
                        <mat-card-content>
                            {{report.detail}}
                        </mat-card-content>
                    </div>
                </mat-card>
            </ng-container>
        </ng-container>
    </div>

    <!-- <app-footer></app-footer> -->
</app-layout>
