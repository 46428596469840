<h5 mat-dialog-title>{{ 'GoalType' | translate: {Default: "Goal Type"} }}</h5>

<mat-dialog-content>
    <app-form-generator 
        [formId]="formId"
        [formData]="formData"
        [emitFormDataTrigger]="getFormData"
        (emitFormData)="formDataEmitted($event)"
        (emitFormStatus)="formStatusUpdated($event)"
        (emitFormPristine)="formPristineEmitted($event)"
    ></app-form-generator>

    <div class="ratings-container" *ngIf="goalType">

      <div class="header">
        <h3>{{ 'Ratings' | translate: {Default: "Ratings"} }}</h3>
                  
        <button setColor="primaryColour" color="primary" mat-flat-button appPreventDoubleClick (throttledClick)="openRatingDialog()">
          <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> {{ 'CreateNew' | translate: {Default: "Create new"} }}
        </button>
      </div>

      <ng-container *ngIf="isLoadingRatings; else ratingsLoaded">
        <ngx-skeleton-loader
        count="5"
        [theme]="{ 
          'height.px': 50
        }"
        ></ngx-skeleton-loader>
      </ng-container>
      <ng-template #ratingsLoaded>
        <div class="no-ratings-container" *ngIf="goalTypeRatingsVerbose.length === 0; else showRatings">
          <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="rating icon">stars</mat-icon>
          
          <div>
            {{ 'NoRatingsAdded' | translate: {Default: "No ratings added."} }}
          </div>
        </div>
        
        <ng-template #showRatings>
          <div class="page-description-section">
            
            {{ 'Drag&DropToReorderRatings' | translate: {Default: "Drag & Drop To Reorder Ratings."} }}
          </div>
  
          <kendo-treeview
            [nodes]="goalTypeRatingsVerbose"
            textField="name"
            kendoTreeViewHierarchyBinding
            kendoTreeViewDragAndDrop
            size="none"
            (nodeDrop)="handleDrop($event)"
          >
            <ng-template kendoTreeViewNodeTemplate let-dataItem>
              <div class="tree-view-item">
                <div class="rating">
                  <span class="text">{{getCurrentCultureRatingName(dataItem)}}</span>
                </div>
    
                <div>
                  <mat-icon 
                  class="material-icons-outlined clickable-icon" 
                  setColor 
                  [setColorRules]="[
                      {
                          color: 'primaryColour',
                          events: ['hover']
                      }
                  ]" 
                  [matTooltip]="translate.instant('Edit')" 
                  appPreventDoubleClick 
                  (throttledClick)="openRatingDialog(dataItem)">edit</mat-icon> 
    
                  <mat-icon 
                  setColor 
                  [setColorRules]="[
                      {
                          color: 'primaryColour',
                          events: ['hover']
                      }
                  ]" 
                  class="material-icons-outlined clickable-icon" 
                  [matTooltip]="translate.instant('Delete')" 
                  (click)="openConfirmDeleteDialog(dataItem)">delete</mat-icon> 
                </div>
              </div>
            </ng-template>
            <ng-template
              kendoTreeViewDragClueTemplate
              let-action="action"
              let-destinationItem="destinationItem"
              let-text="name"
            >
              <span
                class="k-drag-status k-icon"
                [ngClass]="getDragStatus(action, destinationItem)"
              ></span>
              <span>{{ name }}</span>
            </ng-template>
          </kendo-treeview>
        </ng-template>
      </ng-template>
    </div>
      
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
    <button 
        mat-raised-button 
        setColor="primaryColour" 
        color="primary" 
        appPreventDoubleClick 
        (throttledClick)="getFormData = !getFormData" 
        [disabled]="formValid === false"
    >{{ 'Save' | translate: {Default: "Save"} }}</button>
</mat-dialog-actions>