import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fieldTypes } from '@app/consts';
import { MetadataFormService } from '@app/core/services/metadata/metadata-form.service';
import { MetadataTableService } from '@app/core/services/metadata/metadata-table.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import { CustomReportTable, ServerReportElement } from "@app/modules/custom-reports/models/custom-report.model";
import { CustomReportsService } from "@app/modules/custom-reports/services/reports.service";
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { EnvironmentService } from "@app/core/services/environment.service";
import { ReportsMapping } from "@app/modules/custom-reports/util/reports-mapping";

@Component({
    selector: 'app-report-element-pie-chart',
    templateUrl: './report-element-pie-chart.component.html',
    styleUrls: ['./report-element-pie-chart.component.scss']
})
export class ReportElementPieChartComponent implements OnInit {

    private reportsMapping: ReportsMapping = new ReportsMapping();

    public fieldTypes: typeof fieldTypes = fieldTypes;
    formId: string;
    cultures: Culture[];
    loading: boolean;

    formElementId: string;
    parentId: string;
    formTableId: string;
    order: number;
    isDebug = false;


    @Input() element: ServerReportElement;
    @Input() form: UntypedFormGroup;

    @Output() outputSave = new EventEmitter<ServerReportElement>();
    @Output() outputClose = new EventEmitter();

    public chartTypes: { id: string, display: string }[] = [
        {id: "count", display: 'Count'},
        {id: "sum", display: 'Sum'},
    ]

    tables = [
        {
            name: 'Employee',
            subTables: [
                {tableName: 'Employee', fields: []},
                {tableName: 'Employment Record', fields: []},
                {tableName: 'Compensation', fields: []},
                {tableName: 'Other Compensations', fields: []},
            ]
        },
        {
            name: 'Position',
            subTables: [
                {tableName: 'Position', fields: []},
            ]
        },
        // {
        //     name: 'Compensation',
        //     subTables: [
        //         {tableName: 'Compensation', fields: []},
        //     ]
        // },
        // {
        //     name: 'Employment Record',
        //     subTables: [
        //     ]
        // },
    ]

    colorSchemes = [
        {
            name: 'Pastel',
            hex: ["#FFCAD4", "#FFF8E1", "#CCFF90", "#A7FFEB", "#B9F6CA", "#F0F4C3", "#D7CCC8", "#CFD8DC", "#E6E2D3", "#FFE0B2"]
        },
        {
            name: 'Bright Colors',
            hex: ["#FF5733", "#FF9933", "#FFFF33", "#33FF99", "#33FFFF", "#3399FF", "#CC33FF", "#FF33CC", "#9933FF", "#33CCCC"]
        },
        {
            name: 'Earthy Tones',
            hex: ["#A17B5C", "#BFB35A", "#967B5F", "#A15B5F", "#6E5F57", "#7B5F57", "#5A6E5F", "#5F7B5F", "#5F7B5A", "#5F7B6E"]
        },
        {
            name: 'Blues and Greens',
            hex: ["#33CCCC", "#33FFCC", "#33FFFF", "#33CCFF", "#3399FF", "#3366FF", "#3333FF", "#6633FF", "#9933CC", "#CC3399"]
        },
        {
            name: 'Rainbow Colors',
            hex: ["#FF0000", "#FF7F00", "#FFFF00", "#00FF00", "#00FFFF", "#0000FF", "#8B00FF", "#FF00FF", "#FF007F", "#7F00FF"]
        },
    ]

    constructor(
        private snackbarService: SnackbarService,
        private overlayService: OverlayService,
        private metadataFormService: MetadataFormService,
        private reportsService: CustomReportsService,
        private fb: UntypedFormBuilder,
        private cultureService: CultureService,
        private lookupService: LookupService,
        private metadataTableService: MetadataTableService,
        private environmentService: EnvironmentService,
        @Inject(MAT_DIALOG_DATA) data) {
        this.element = data.element
    }

    ngOnInit(): void {
        // this.isDebug = this.environmentService.isDebug;
        this.isDebug = true;
        this.createForm();

    }

    createForm() {

        this.form.addControl('parentTable', this.fb.control('', Validators.required));
        this.form.addControl('chartType', this.fb.control('', Validators.required));
        this.form.addControl('groupingThreshold', this.fb.control('5', Validators.required));
        this.form.addControl('colorScheme', this.fb.control('', Validators.required));
        this.form.addControl('columns', this.fb.array([]));

        if (this.element) {

            let parentTable = this.reportsMapping.getParentTableFromProperties(this.element);
            this.form.controls.parentTable.setValue(parentTable);

            let chartType = this.reportsMapping.getChartTypeFromProperties(this.element);
            this.form.controls.chartType.setValue(chartType);

            let columns = this.reportsMapping.getColumnsFromProperties(this.element)
            columns.forEach(
                property => {
                    this.addNewColumn(property.title, property.table, property.field);
                    this.fetchTables(property.table);
                }
            );

            let groupingThreshold = this.reportsMapping.getGroupingThresholdTableFromProperties(this.element);
            this.form.controls.groupingThreshold.setValue(groupingThreshold);

            let colorScheme = this.reportsMapping.getColorScheme(this.element);
            if (colorScheme && this.colorSchemes.findIndex(x => x.name == colorScheme.name) > -1) {
                let index = this.colorSchemes.findIndex(x => x.name == colorScheme.name);
                this.form.controls.colorScheme.setValue(this.colorSchemes[index]);
            }
        } else {
            this.addNewColumn()
        }

        this.form.controls.parentTable.valueChanges.subscribe(value => {
            this.columns.clear()
            this.addNewColumn()
        });
    }

    close() {
        this.outputClose.emit();
    }


    get columns() {
        return this.form.controls["columns"] as UntypedFormArray;
    }

    addNewColumn(property?: string, value?: string, field?: string) {
        console.log(this.form.controls.chartType.value)
        if (this.form.controls.chartType.value === 'count' && this.columns.length >= 1) {
            this.snackbarService.openSnackBar("Pie chart of type Count can have only one attribute", 'clear', 'warn');
            return;
        }
        if (this.form.controls.chartType.value === 'sum' && this.columns.length >= 2) {
            this.snackbarService.openSnackBar("Pie chart of type Sum can have only two attributes", 'clear', 'warn');
            return;
        }

        // console.log('addNewProperty', property, value, field);
        let val;

        // if the property value is supposed to be a boolean convert it from string to boolean
        (value === "true" || value === "false")
            ? val = (value === 'true')
            : val = value;

        const propertyForm = this.fb.group({
            title: [property || '', Validators.required],
            table: [val || '', Validators.required],
            field: [field || '', Validators.required]
        });

        propertyForm.controls.table.valueChanges.subscribe(value => {
            console.log('property table value changed', value);
            this.fetchTables(value);
        })

        this.columns.push(propertyForm);
    }

    deleteColumn(index: number) {
        this.columns.removeAt(index);
    }

    drop(event: CdkDragDrop<any>) {
        const currentItem = this.columns.at(event.previousIndex)
        this.columns.removeAt(event.previousIndex)
        this.columns.insert(event.currentIndex, currentItem)
    }

    // TODO: move to service
    fetchTables(tableName: string) {
        let cachedTable = this.tables.find(t => t.name == this.form.value.parentTable).subTables.find(s => s.tableName == tableName);

        if (cachedTable && cachedTable.fields.length == 0) {

            switch (tableName) {
                case CustomReportTable.Employee:
                    // if (this.form.value.parentTable === CustomReportTable.CostCentre
                    //     || this.form.value.parentTable === CustomReportTable.WorkLocation
                    //     || this.form.value.parentTable === CustomReportTable.WorkRotation) {
                    //     cachedTable.fields = this.reportsMapping.employeeDummyMetadata
                    // } else {
                        this.metadataTableService.getTableFields("tbl_Employees", 0, '1000')
                        .subscribe(res => {
                            cachedTable.fields = res.data
                        })
                    // }

                    break
                case CustomReportTable.EmployeePosition:
                    this.metadataTableService.getTableFields("tbl_PositionsEmployees", 0, '1000')
                    .subscribe(res => {
                        cachedTable.fields = res.data
                    })
                    break

                case CustomReportTable.Position:
                    // if (this.form.value.parentTable === CustomReportTable.Employee) {
                    //     cachedTable.fields = this.reportsMapping.positionDummyMetadata
                    // } else {
                        this.metadataTableService.getTableFields("tbl_Positions", 0, '1000')
                        .subscribe(res => {
                            cachedTable.fields = res.data
                        })
                    break
                case CustomReportTable.WorkLocation:
                    this.metadataTableService.getTableFields("tbl_WorkLocations", 0, '1000')
                    .subscribe(res => {
                        cachedTable.fields = res.data
                    })
                    break
                case CustomReportTable.EmploymentRecord:
                    this.metadataTableService.getTableFields("tbl_EmploymentRecords", 0, '1000')
                    .subscribe(res => {
                        cachedTable.fields = res.data
                    })
                    break
                case CustomReportTable.Compensation:
                    this.metadataTableService.getTableFields("tbl_Compensations", 0, '1000')
                    .subscribe(res => {
                        cachedTable.fields = res.data
                    })
                    break
                case CustomReportTable.OtherCompensations:
                    this.metadataTableService.getTableFields("tbl_OtherCompensations", 0, '1000')
                    .subscribe(res => {
                        cachedTable.fields = res.data
                    })
                    break
                case CustomReportTable.TimeOffType:
                    cachedTable.fields = this.reportsMapping.timeOffTypeDummyMetadata
                    break
                case CustomReportTable.TimeOffPolicy:
                    cachedTable.fields = this.reportsMapping.timeOffPolicyDummyMetadata
                    break
                case CustomReportTable.OrganizationStructure:
                    cachedTable.fields = this.reportsMapping.organizationDummyMetadata
                    break
                case CustomReportTable.CostCentre:
                    cachedTable.fields = this.reportsMapping.costCenterDummyMetadata
                    break
                case CustomReportTable.WorkRotation:
                    cachedTable.fields = this.reportsMapping.workRotationsDummyMetadata
                    break
                default:
                    console.error("Table not found: " + tableName);
                    break
            }
        }
    }

    debugDataEmp() {
        this.form.controls['chartType'].setValue('count');
        this.form.controls['parentTable'].setValue(CustomReportTable.Employee);
        this.form.controls['colorScheme'].setValue(this.colorSchemes[0]);
        this.deleteColumn(0);
        this.addNewColumn('gender', CustomReportTable.Employee, 'tfi_Gender');
        this.fetchTables(CustomReportTable.Employee);
    }

    debugDataEmpSum() {
        this.form.controls['chartType'].setValue('sum');
        this.form.controls['parentTable'].setValue(CustomReportTable.Employee);
        this.form.controls['colorScheme'].setValue(this.colorSchemes[0]);
        this.deleteColumn(0);
        this.addNewColumn('gender', CustomReportTable.Employee, 'tfi_Gender');
        this.fetchTables(CustomReportTable.Employee);
    }

    debugDataCompensation() {
        this.form.controls['chartType'].setValue('count');
        this.form.controls['parentTable'].setValue(CustomReportTable.Employee);
        this.form.controls['colorScheme'].setValue(this.colorSchemes[0]);
        this.deleteColumn(0);
        this.addNewColumn('Pay Frequency', CustomReportTable.Compensation, 'tfi_CompensationPayFrequency');
        this.fetchTables(CustomReportTable.Compensation);
    }

    debugDataCompensationOther() {
        this.form.controls['chartType'].setValue('count');
        this.form.controls['parentTable'].setValue(CustomReportTable.Employee);
        this.form.controls['colorScheme'].setValue(this.colorSchemes[0]);
        this.deleteColumn(0);
        this.addNewColumn('Comp Type', CustomReportTable.OtherCompensations, 'tfi_OtherCompensationType');
        this.fetchTables(CustomReportTable.OtherCompensations);
    }

    getSubTables(parentTable: any) {
        let subTables = this.tables.find(t => t.name == parentTable).subTables;
        return subTables;
    }
}
