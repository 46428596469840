<mat-card class="mat-elevation-z0" [formGroup]="parentGroup">
    <mat-card-title class="header">
        <h5>{{ formElement.text }} <span class="asterisk" *ngIf="formElement.requiredField">*</span></h5>
    </mat-card-title>
    <mat-card-content>
        <div class="section">
            <div class="section-header">
                <h6 appLocalizationPopupDirective localizationCode="Step1ChooseImage">{{ 'Step1ChooseImage' | translate: {Default: "Step 1: Choose Image"} }}</h6>
                <mat-divider></mat-divider>
            </div>
            <button appLocalizationPopupDirective localizationCode="ChooseImage" class="choose-file-button" type="button" mat-raised-button (click)="fileInput.click()">{{ 'ChooseImage' | translate: {Default: "Choose Image"} }}</button>
            <input hidden type="file" #fileInput (change)="fileChangeEvent($event)" />
        </div>

        <div *ngIf="imageChangedEvent && imageChangedEvent !== ''; else noImage">

            <div class="section">
                <div class="section-header">
                    <h6 appLocalizationPopupDirective localizationCode="Step2EditImage">{{ 'Step2EditImage' | translate: {Default: "Step 2: Edit Image"} }}</h6>
                    <mat-divider></mat-divider>
                </div>
                
                <div class="image-button-controls">
                    <div>
                        <button appLocalizationPopupDirective localizationCode="RotateLeft" mat-stroked-button (click)="rotateLeft()">{{ 'RotateLeft' | translate: {Default: "Rotate left"} }}</button>
                        <button appLocalizationPopupDirective localizationCode="RotateRight" mat-stroked-button (click)="rotateRight()">{{ 'RotateRight' | translate: {Default: "Rotate right"} }}</button>
                        <button appLocalizationPopupDirective localizationCode="FlipHorizontal" mat-stroked-button (click)="flipHorizontal()">{{ 'FlipHorizontal' | translate: {Default: "Flip horizontal"} }}</button>
                        <button appLocalizationPopupDirective localizationCode="FlipVertical" mat-stroked-button (click)="flipVertical()">{{ 'FlipVertical' | translate: {Default: "Flip vertical"} }}</button>
                    </div>
                    <div>
                        <button *ngIf="containWithinAspectRatio" appLocalizationPopupDirective localizationCode="FillAspectRatio" mat-stroked-button (click)="toggleContainWithinAspectRatio()">{{ 'FillAspectRatio' | translate: {Default: "Fill Aspect Ratio"} }}</button>
                        <button *ngIf="!containWithinAspectRatio" appLocalizationPopupDirective localizationCode="ContainWithinAspectRatio" mat-stroked-button (click)="toggleContainWithinAspectRatio()">{{ 'ContainWithinAspectRatio' | translate: {Default: "Contain Within Aspect Ratio"} }}</button>
                        <button appLocalizationPopupDirective localizationCode="Step2EditImage" mat-stroked-button (click)="resetImage()">{{ 'ResetImage' | translate: {Default: "Reset image"} }}</button>
                    </div>
                    <div>
                        <button appLocalizationPopupDirective localizationCode="ZoomOut" mat-stroked-button (click)="zoomOut()">{{ 'ZoomOut' | translate: {Default: "Zoom -"} }}</button> <button mat-stroked-button (click)="zoomIn()">{{ 'ZoomIn' | translate: {Default: "Zoom +"} }}</button>
                    </div>
                </div>
        
                <div class="cropper-container">
                    <image-cropper
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="false"
                        [containWithinAspectRatio]="containWithinAspectRatio"
                        [resizeToWidth]="256"
                        [cropperMinWidth]="128"
                        [onlyScaleDown]="true"
                        [roundCropper]="false"
                        [canvasRotation]="canvasRotation"
                        [transform]="transform"
                        [alignImage]="'left'"
                        [style.display]="showCropper ? null : 'none'"
                        format="png"
                        (imageCropped)="imageCropped($event)"
                        (imageLoaded)="imageLoaded()"
                        (cropperReady)="cropperReady($event)"
                        (loadImageFailed)="loadImageFailed()"
                    ></image-cropper>
                </div>
            </div>

            <div class="section">
                <div class="section-header">
                    <h6 appLocalizationPopupDirective localizationCode="Step3PreviewAndUpload">{{ 'Step3PreviewAndUpload' | translate: {Default: "Step 3: Preview and Upload"} }}</h6>
                    <mat-divider></mat-divider>
                </div>
                <div class="preview-container">
                    <img [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'" />
                </div>
            </div>

            <mat-form-field hidden appearance="fill">
                <mat-label>
                    {{ formElement.text }}
                </mat-label>

                <input matInput type="text" [formControlName]="formElement.formControl" [placeholder]="formElement.placeholder">
            </mat-form-field>

        </div>
        <ng-template #noImage>
            <div class="no-image">
                <h6 appLocalizationPopupDirective localizationCode="NoImageSelected">{{ 'NoImageSelected' | translate: {Default: "No Image Selected"} }}</h6>
            </div>
        </ng-template>
    </mat-card-content>
</mat-card>
