export interface ReportGeneratorListener {

    dataLoading()

    setData(data: any, elementIndex: number)

    setProgress(progress: number, message: string)
}


export interface CustomProgressUpdate {
    progress: number
    message: string
}
