import { Component, OnInit, ViewChild } from "@angular/core";
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};

@Component({
  selector: 'app-headcount-by-month-chart-table',
  templateUrl: './headcount-by-month-chart-table.component.html',
  styleUrls: ['./headcount-by-month-chart-table.component.scss']
})
export class HeadcountByMonthChartTableComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  headcount = [
    {
      'month': 'January',
      'headcount': '400',
      'fte': '380',
    },
    {
      'month': 'February',
      'headcount': '410',
      'fte': '390',
    },
    {
      'month': 'March',
      'headcount': '435',
      'fte': '400',
    },
    {
      'month': 'April',
      'headcount': '435',
      'fte': '400',
    },
    {
      'month': 'May',
      'headcount': '450',
      'fte': '420',
    },
    {
      'month': 'June',
      'headcount': '471',
      'fte': '425',
    },
    {
      'month': 'July',
      'headcount': '480',
      'fte': '430',
    },
    {
      'month': 'August',
      'headcount': '480',
      'fte': '430',
    },
    {
      'month': 'September',
      'headcount': '500',
      'fte': '450',
    }
  ];

  tableColumns = [
    { name: 'Month', dataKey: 'month', isSortable: true, position: 'left' },
    { name: 'headcount', dataKey: 'headcount', isSortable: true, position: 'left' },
    { name: 'fte', dataKey: 'fte', isSortable: true, position: 'left' },
  ];

  constructor() {
    this.chartOptions = {
      series: [
        {
          name: "Headcount",
          data: [400, 410, 435, 435, 450, 471, 480, 480, 500]
        },
        {
          name: "FTE",
          data: [380, 390, 400, 400, 420, 425, 430, 430, 450]
        },
      ],
      chart: {
        type: "bar",
        height: 350,
        width: 600
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct"
        ]
      },
      yaxis: {
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val.toString();
          }
        }
      }
    };
  }

  ngOnInit(): void {
  }

}
