import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CreateDirectoryService } from './services/create-directory.service';

@Component({
  selector: 'app-create-directory-dialog',
  templateUrl: './create-directory-dialog.component.html',
  styleUrls: ['./create-directory-dialog.component.scss']
})
export class CreateDirectoryDialogComponent implements OnInit {

  text: string;
  form: UntypedFormGroup;
  parentDirectory: string;
  uploadApiPath: string;

  constructor(
    private createDirectoryService: CreateDirectoryService,
    private fb: UntypedFormBuilder,
    private snackbarService: SnackbarService,
    private dialogRef: MatDialogRef<CreateDirectoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      this.parentDirectory = data.parentDirectory;
      this.uploadApiPath = data.uploadApiPath;
  }

  ngOnInit() {
    this.form = this.fb.group({
      directoryName: ['', Validators.required]
    });
  }

  save() {
    this.createDirectoryService.createDirectory(null, this.form.value.directoryName, this.parentDirectory, this.uploadApiPath)
    .subscribe(
      (res) => {
        this.dialogRef.close(true);
        this.snackbarService.openSnackBar('Directory saved successfully', 'clear', 'success');
      }
    );
  }

  close() {
      this.dialogRef.close();
  }
}
