import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { db_tables, features, routes } from '@app/consts';
import { StylingColour } from '@app/modules/site-settings-styling/components/styling-colours/models/styling-colours.model';
import { StylingColoursService } from '@app/modules/site-settings-styling/components/styling-colours/services/styling-colours.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-select-styling-colours-dialog',
  templateUrl: './select-styling-colours-dialog.component.html',
  styleUrls: ['./select-styling-colours-dialog.component.scss']
})
export class SelectStylingColoursDialogComponent implements OnInit {
  isLoading: boolean;

  public db_tables = db_tables;
  public routes: typeof routes = routes;
  public features: typeof features = features;
  public columns: any[] = [
    {field: 'name', title: 'name'},
    {field: 'active', title: 'active', type: 'booleanToggle'},
    {field: 'defaultColours', title: 'defaultColours', type: 'booleanToggle'},
    {field: 'primaryColour', title: 'primaryColour', tableId: "", type: 'colour'},
    {field: 'accentColour', title: 'accentColour', tableId: "", type: 'colour'},
    {field: 'warnColour', title: 'warnColour', tableId: "", type: 'colour'},
  ];
  public sort: SortDescriptor[] = [];
  public bindingType: String = 'array';
  public gridDataResult: GridDataResult;
  clearSelectedItems: boolean = false;
  pageSize: number = 20;
  skip: number = 0;
  sortString: string = "active-asc";
  sortingColumns: boolean;
  public searchFilterString: string;
  public searchValue: string;
  filterString: string;

  selectedStylingColour: StylingColour;
  selectedRowsButtonSettings : {
    show: boolean
    text: boolean
    icon: string
  } = {
    show: false,
    text: null,
    icon: null
  }


  constructor(
    public translate: TranslateService,
    private stylingColoursService: StylingColoursService,
    private dialogRef: MatDialogRef<SelectStylingColoursDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.getStylingColours();
  }

  getStylingColours() {
    this.isLoading = true;

    this.stylingColoursService.getStylingColours(this.skip, this.pageSize)
    .pipe(
        finalize( () => {
          this.isLoading = false;
        })
    )
    .subscribe(
        res => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          };
        }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.getStylingColours();
  }

  public sortChange(sort: SortDescriptor[]): void {
      this.sort = sort;

      if (sort[0].dir === undefined) {
          this.sortString = null;
      } else {
          //use regex to get column field to sort with
          let field: any;

          //if it is an object category like (department.text) trim .text from the end, else it doesnt need to be trimmed
          (sort[0].field).match(/.+(?=\.)/) === null
              ? field = sort[0].field
              : field = (sort[0].field).match(/.+(?=\.)/);
          this.sortString = `${field}-${sort[0].dir}`;
      }

      this.getStylingColours();
  }

  save() {
    this.dialogRef.close(this.selectedStylingColour);
  }

  close() {
    this.dialogRef.close();
  }

  filterCallback(filterString: string) {
      this.searchFilterString = filterString;
      // this.filterString = filterString;
      this.skip = 0;;
      this.getStylingColours();
  }

  selectedRowsChanged(selectionEvent: SelectionEvent){
    if(selectionEvent?.selectedRows[0]?.dataItem){
      this.selectedStylingColour = selectionEvent.selectedRows[0].dataItem;
    }
  }

}
