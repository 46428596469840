import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import * as moment from 'moment';
import { defer, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EmployeeAssociation, EmployeeAssociationVerbose } from './models/employee-association.model';
import { EmployeeAssociationService } from './services/employee-association.service';
import { api_routes, db_tables } from '@app/consts';
import { ActivatedRoute } from '@angular/router';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';

@Component({
  selector: 'app-employee-associations',
  templateUrl: './employee-associations.component.html',
  styleUrls: ['./employee-associations.component.scss']
})
export class EmployeeAssociationsComponent implements OnInit {
  employeeID: string;

  public columns: any[] = [
    { field: "designationOrAward", title: "designation Or Award", type: "navigation", tableId: "tfi_DesignationOrAward"},
    { field: "associationOrInstitution", title: "association Or Institution", tableId: "tfi_AssociationOrIns"},
    { field: "date", title: "date", type: "date", tableId: "tfi_AssociationDate"},
    { field: "comments", title: "comments", tableId: "tfi_AssociationComments"},
  ];
  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 5;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  formId: string = 'frm_HxOAVm3Tk2eIeR'
  changeReasonFormId: string = 'frm_4yowWYelRKYQqj';
  dialogRef: any;
  isLoadingPermissions: boolean = true;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];
  accessAndHistoryURL: string = `${api_routes.TALENT}/[employeeId]/${api_routes.ASSOCIATIONS}/[entityId]`;

  constructor(
    private dialog: MatDialog,
    private employeeAssociationService: EmployeeAssociationService,
    private translate: TranslateService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private tablePermissionsService: TablePermissionsService,
    private route: ActivatedRoute,
  ) {
    this.route.paramMap.subscribe(
        params => this.employeeID = params.get("id")
    )
  }

  ngOnInit(): void {
    this.view = this.getAssociations(this.employeeID);
    this.getTargetEmployeeTablePermissions();
  }

  getAssociations(empId: string) {
    this.isLoading = true;

    this.employeeAssociationService.getAssociations(empId, String(this.pageSize), this.skip)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          }
          this.gridData = res.data;
        }
      );
  }

  getTargetEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeID);

    // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
    if(currentFetchedData !== null){
      this.targetEmployeeTablePermissions = currentFetchedData;
      this.isLoadingPermissions = false;
    }
    else {
      this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeID)
      .pipe(
        finalize(() => {
            this.isLoadingPermissions = false;
        })
      )
      .subscribe(
        res => {
          this.targetEmployeeTablePermissions = res;
        }
      );
    }
  }

  getAssociationVerbose(association?: EmployeeAssociation) {
    if(association === undefined){
      this.openAssociationsDialog();
    }
    else {
      let associationVerbose: EmployeeAssociationVerbose;

      this.isLoading = true;

      this.employeeAssociationService.getAssociation(this.employeeID, association.id)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        res => {
          associationVerbose = res;
          this.openAssociationsDialog(associationVerbose);
        }
      );
    }
  }

  openAssociationsDialog(associationVerbose?: EmployeeAssociationVerbose) {
    let formData = {
      id: associationVerbose ? associationVerbose.id : null,
      asOf: moment().format(),
      changeReason: null,
      changeReasonComments: '',
      date: associationVerbose ? associationVerbose.date : null,
      comments: associationVerbose ? associationVerbose.comments : null,
      associationOrInstitution: associationVerbose ? associationVerbose.associationOrInstitution : null,
      designationOrAward: associationVerbose ? associationVerbose.designationOrAward : null,
    }

    let fieldPermissions = [
      {
        id: 'tfi_AssociationOrIns',
        permission: 'READ'
      },
      {
        id: 'tfi_AssociationComments',
        permission: 'DENY'
      },
      {
        id: 'tfi_AssociationDate',
        permission: 'WRITE'
      },
      {
        id: 'tfi_DesignationOrAward',
        permission: 'READ'
      },
      {
        id: 'tfi_AsChangeReason',
        permission: 'READ'
      },
      {
        id: 'tfi_AsChangeReasonComments',
        permission: 'READ'
      },
    ]


    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formTitle: `${this.translate.instant('Association')}`,
      formId: this.formId,
      changeReasonFormId: this.changeReasonFormId,
      formData: formData,
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions,
      fieldPermissions: fieldPermissions
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      this.saveAssociation(event)
    });
  }

  saveAssociation(formData) {
    this.overlayService.show();

    if(formData.id === null){
      this.employeeAssociationService.createAssociation(this.employeeID, formData)
      .pipe(
        finalize(() => {
          this.overlayService.hide();
        })
        )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close();
          this.getAssociations(this.employeeID);
      }
      );
    }
    else {
      this.employeeAssociationService.updateAssociation(this.employeeID, formData)
      .pipe(
        finalize(() => {
          this.overlayService.hide();
        })
        )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.getAssociations(this.employeeID);
          this.dialogRef.close();
      }
      );
    }
  }

  deleteAssociations(associationIds: string[]) {
    const observables = associationIds.map(selectedItem => defer(() => this.employeeAssociationService.deleteAssociation(this.employeeID, selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.view = this.getAssociations(this.employeeID);
        this.clearSelectedItems = !this.clearSelectedItems;
        this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.view = this.getAssociations(this.employeeID);
  }

}
