<mat-card class="mat-elevation-z0">
    <mat-card-title class="header">
        <h5 appLocalizationPopupDirective localizationCode="TOILPolicies">{{ 'TOILPolicies' | translate: {Default: "TOIL Policies"} }}</h5>
        <div>
            <button class="add-policy-button" mat-flat-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openAssignPolicyDialog()" *appTableAccess="[tableCreateAccessObj, tableUpdateAccessObj]" appLocalizationPopupDirective localizationCode="AddAPolicy">
                <mat-icon class="material-icons-outlined" >add</mat-icon>
                {{ 'AddAPolicy' | translate: {Default: "Add A Policy"} }}
            </button>
        </div>
    </mat-card-title>
    <mat-card-content>
        <div *ngIf="employeeToilPolicies$ | withLoading | async as employeeToilPolicies">
            <ng-template [ngIf]="employeeToilPolicies.value">
                <div class="policies-container">
                    <h6 *ngIf="employeeToilPolicies.value.data.length === 0" appLocalizationPopupDirective localizationCode="NoPolicies">{{ 'NoPolicies' | translate: {Default: "No Policies"} }}</h6>
        
                    <ng-container *ngFor="let employeeToilPolicy of employeeToilPolicies.value.data" >
                        <div class="policy main-container">
                            <div class="top">
                                <div *appTableAccess="[tableCreateAccessObj, tableUpdateAccessObj]">
                                    <mat-icon class="settings-button__icon material-icons-outlined"></mat-icon>
                        
                                </div>
                        
                                <div class="title">
                                    <!-- <img *ngIf="timeOffType.image" [src]="getIcon(employeeToilPolicy.toilPolicy?.toilType?.id) | async" class="timeoff-type-icon"/> -->
        
                                    <h5>{{employeeToilPolicy.toilPolicy?.toilType?.name}}</h5>
                                    <h6>{{employeeToilPolicy.toilPolicy?.name}}</h6>
                                </div>
                                
                                <div class="admin-controls" *appTableAccess="[tableCreateAccessObj, tableUpdateAccessObj, tableDeleteAccessObj]">
                                    <button class="settings-button" mat-mini-fab [matMenuTriggerFor]="settings">
                                        <mat-icon class="settings-button__icon material-icons-outlined">more_vert</mat-icon>
                                    </button>
                                    <div class="settings-menu">
                                        <mat-menu #settings="matMenu" xPosition="before">
                                            <!-- <button class="settings-menu__item material-icons-outlined" appPreventDoubleClick (throttledClick)="openAssignPolicyDialog(employeeToilPolicy)" mat-menu-item *appTableAccess="[tableUpdateAccessObj]">
                                                <mat-icon class="settings-button__icon material-icons-outlined">edit</mat-icon>
                                                {{ 'EmployeeTimeOffTypeEdit' | translate: {Default: "Edit"} }}
                                            </button> -->
                                            <button class="settings-menu__item material-icons-outlined" appPreventDoubleClick (throttledClick)="openConfirmDeleteDialog(employeeToilPolicy)" mat-menu-item *appTableAccess="[tableDeleteAccessObj]" appLocalizationPopupDirective localizationCode="EmployeeToilPolicyDelete">
                                                <mat-icon class="settings-button__icon material-icons-outlined">delete</mat-icon>
                                                {{ 'EmployeeToilPolicyDelete' | translate: {Default: "Delete"} }}
                                            </button>
                                            <button class="settings-menu__item material-icons-outlined" mat-menu-item appPreventDoubleClick (throttledClick)="openModifyBalanceFormDialog(employeeToilPolicy)" *appTableAccess="[tableUpdateAccessObj]" appLocalizationPopupDirective localizationCode="EmployeeTimeOffTypeModifyBalance">
                                                <mat-icon class="settings-button__icon material-icons-outlined">settings</mat-icon>
                                                {{ 'EmployeeTimeOffTypeModifyBalance' | translate: {Default: "Modify Balance"} }}
                                            </button>
                                            <button class="settings-menu__item material-icons-outlined" mat-menu-item appPreventDoubleClick (throttledClick)="openCalculationLogDialog(employeeToilPolicy)" *appTableAccess="[tableUpdateAccessObj]" appLocalizationPopupDirective localizationCode="EmployeeTimeOffTypeCalculationLog">
                                                <mat-icon class="settings-button__icon material-icons-outlined">calculate</mat-icon>
                                                {{ 'EmployeeTimeOffTypeCalculationLog' | translate: {Default: "Calculation Log"} }}
                                            </button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                            <div class="balance-container">
                                <div class="label">
                                    <h6 appLocalizationPopupDirective localizationCode="EmployeeToilPolicyBalance">
                                        {{ 'EmployeeToilPolicyBalance' | translate: {Default: "Balance"} }}
                                    </h6>
                                </div>
                                <div class="balance">
                                    <ng-container *ngIf="employeeToilPolicy.availableHours; else noAvailableHours">
                                        <h3 setColor="primaryColour">{{employeeToilPolicy.availableHours | number:'1.2-2' }}</h3>
                                    </ng-container>
                                    <ng-template #noAvailableHours>
                                        <h3 setColor="primaryColour">0</h3>
                                    </ng-template>
                                </div>
                                <div class="hours" appLocalizationPopupDirective localizationCode="EmployeeToilPolicyHours">
                                    <h6>
                                        {{ 'EmployeeToilPolicyHours' | translate: {Default: "Hours"} }}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-template>
        
            <ng-template [ngIf]="employeeToilPolicies.error">Error</ng-template>
            <ng-template [ngIf]="employeeToilPolicies.loading">
                <ngx-skeleton-loader
                    count="5"
                    [theme]="{
                        'height.px': 50
                    }"
                ></ngx-skeleton-loader>
            </ng-template>
        </div>
    </mat-card-content>
</mat-card>



