<app-admin-controls>
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
            <h5 appLocalizationPopupDirective localizationCode="AdminControlsPositions">{{ 'AdminControlsPositions' | translate: {Default: "Admin Controls: Assign Positions"} }}</h5>
        </mat-card-title>

        <mat-card-content>
            <div class="page-description-section">
                This will assign a random position to every employee.
            </div>

            <div class="progress-section" *ngIf="numberOfAssigned > 0">
                <h6>Assigned position to {{numberOfAssigned}} of {{employees.length}} employees</h6>
                <mat-progress-bar mode="determinate" [value]="getProgress()"></mat-progress-bar>
            </div>

            <button mat-flat-button setColor="primaryColour" color="primary" (click)="createItems()" [disabled]="isLoading()">Assign Positions</button>
        </mat-card-content>
    </mat-card>
</app-admin-controls>
