<h5 mat-dialog-title appLocalizationPopupDirective [localizationCode]="'RequestTimeOff'">
    {{ 'RequestTimeOff' | translate: {Default: "Request Time Off"} }}
</h5>

<mat-dialog-content>
    <ng-container *ngIf="!form; else loaded">
        <ngx-skeleton-loader
            count="5"
            [theme]="{
                'height.px': 50
            }"
        ></ngx-skeleton-loader>
    </ng-container>

    <ng-template #loaded>
        <div class="working-hours-container">
            <ngx-skeleton-loader
                *ngIf="loadingWorkingHours"
                count="1"
                [theme]="{
                    height: '50px',
                    width: '100%'
                }"
            ></ngx-skeleton-loader>

            <div class="page-description-section" *ngIf="!loadingWorkingHours && workingHours">
                This time off request is for {{calculatedWorkingHours}} working hours
            </div>
        </div>

        <form [formGroup]="form">
            <mat-form-field appearance="fill" appLocalizationPopupDirective [localizationCode]="'RequestTimeOff-From'">
                <mat-label>
                    {{ 'RequestTimeOff-From' | translate: {Default: "From"} }}
                </mat-label>

                <input matInput [matDatepicker]="frompicker" formControlName="from">
                <span fxLayout="row" matSuffix>
                    <mat-datepicker-toggle [for]="frompicker"></mat-datepicker-toggle>
                </span>
                <mat-datepicker #frompicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill" appLocalizationPopupDirective [localizationCode]="'RequestTimeOff-To'">
                <mat-label>
                    {{ 'RequestTimeOff-To' | translate: {Default: "To"} }}
                </mat-label>

                <input matInput [min]="fromValue" [matDatepicker]="topicker" formControlName="to">
                <span fxLayout="row" matSuffix>
                    <mat-datepicker-toggle [for]="topicker"></mat-datepicker-toggle>
                </span>
                <mat-datepicker #topicker></mat-datepicker>

                <div class="error-text" *ngIf="form.get('to').errors?.matDatepickerMin"  appLocalizationPopupDirective [localizationCode]="'MustBeAfterFrom'">
                    {{ 'MustBeAfterFrom' | translate: {Default: "Must be same or after From date"} }}
                </div>
            </mat-form-field>

            <div class="slide-toggle-container" *ngIf="fromValue != null && fromValue !== '' && fromValue === toValue" >
                <mat-slide-toggle setColor="primaryColour" formControlName="halfDay" appLocalizationPopupDirective [localizationCode]="'RequestTimeOff-HalfDay'">
                    {{ 'RequestTimeOff-HalfDay' | translate: {Default: "Half Day"} }}
                </mat-slide-toggle>
            </div>


            <div class="slide-toggle-container" *ngIf="fromValue != null && fromValue !== '' && fromValue === toValue">
                <mat-slide-toggle setColor="primaryColour"formControlName="setTime" appLocalizationPopupDirective [localizationCode]="'RequestTimeOff-SetTime'">
                    {{ 'RequestTimeOff-SetTime' | translate: {Default: "Set Time"} }}
                </mat-slide-toggle>
            </div>

            <ng-container *ngIf="form.controls.setTime.value">
                <mat-form-field appearance="fill" appLocalizationPopupDirective [localizationCode]="'RequestTimeOff-StartTime'">
                    <mat-label>{{ 'RequestTimeOff-StartTime' | translate: {Default: "Start Time"} }}</mat-label>
                    <input
                        matInput
                        aria-label="start time"
                        [ngxTimepicker]="startTime"
                        [format]="24"
                        formControlName="fromTime"
                    />
                    <ngx-material-timepicker #startTime>
                    </ngx-material-timepicker>
                </mat-form-field>

                <mat-form-field appearance="fill" appLocalizationPopupDirective [localizationCode]="'RequestTimeOff-EndTime'">
                    <mat-label>{{ 'RequestTimeOff-EndTime' | translate: {Default: "End Time"} }}</mat-label>
                    <input
                        matInput
                        aria-label="end time"
                        [ngxTimepicker]="endTime"
                        [format]="24"
                        formControlName="toTime"
                    />
                    <ngx-material-timepicker #endTime>
                    </ngx-material-timepicker>
                </mat-form-field>

                <mat-form-field appearance="fill" appLocalizationPopupDirective [localizationCode]="'RequestTimeOff-Hours'">
                    <mat-label>
                        {{ 'RequestTimeOff-Hours' | translate: {Default: "Hours"} }}
                    </mat-label>

                    <input matInput type="number" min="0.5" max="24" formControlName="hours">
                </mat-form-field>

            </ng-container>

            <mat-form-field appearance="fill" appLocalizationPopupDirective [localizationCode]="'RequestTimeOff-Comments'">
                <mat-label>
                    {{ 'RequestTimeOff-Comments' | translate: {Default: "Comments"} }}
                </mat-label>

                <textarea matInput formControlName="comments"></textarea>
            </mat-form-field>
        </form>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button class="close" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
    <button mat-raised-button setColor="primaryColour" color="primary" [disabled]="!form?.valid" appPreventDoubleClick (throttledClick)="submitForm()">{{ 'Save' | translate: {Default: "Save"} }}</button>
</mat-dialog-actions>
