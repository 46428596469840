import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { routes } from '@app/consts';
import { EmployeeService } from '@app/core/services/employee.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { SecurityRoleService } from '@app/modules/security-roles/services/security-role.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { EmployeeSecuirtyRole } from '../../models/employee-security.model';
import { AssignSecurityRoleEmployeeDialogComponent } from '../assign-security-role-employee-dialog/assign-security-role-employee-dialog.component';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-employee-security-roles',
  templateUrl: './employee-security-roles.component.html',
  styleUrls: ['./employee-security-roles.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmployeeSecurityRolesComponent implements OnInit {
  @Input() employeeID: string;
  public routes: typeof routes = routes;
  isLoading: boolean;
  employeeSecurityRoles: EmployeeSecuirtyRole[];

  constructor(
    private securityRoleService: SecurityRoleService,
    private employeeService: EmployeeService,
    private dialog: MatDialog,
    private overlayService: OverlayService,
    public translate: TranslateService,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit(): void {
    this.getUserSecurityRoles();
  }

  getUserSecurityRoles(){
    this.isLoading = true;

    this.securityRoleService.getUserRoles(this.employeeID)
    .pipe(
      finalize(()=>{ this.isLoading = false; })
    )
    .subscribe(
      res => {
        this.employeeSecurityRoles = res;
      }
    )
  }

  deleteUserSecurityRole(securityRole: EmployeeSecuirtyRole) {
    this.isLoading = true;

    this.securityRoleService.deleteRoleUser(securityRole.role.id, this.employeeID)
    .pipe(
      finalize(()=>{ this.isLoading = false; })
    )
    .subscribe(
      res => {
        this.getUserSecurityRoles();
        this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
      }
    )
    
  }

  openConfirmDeleteDialog(securityRole: EmployeeSecuirtyRole) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      text: `Are you sure you want to remove this security role? (${securityRole.role.name})`
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.deleteUserSecurityRole(securityRole);
        }
      }
    ); 
  }

  openAddSecurityRoleDialog(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      employeeId: this.employeeID
    };

    const dialogRef = this.dialog.open(AssignSecurityRoleEmployeeDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.getUserSecurityRoles();
        }
      }
    );
    
  }
  

}
