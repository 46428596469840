import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TimeOffInLieuService } from '@app/core/services/time-off-in-lieu/time-off-in-lieu.service';
import { GenerateScheduleService } from '@app/modules/generate-schedules/services/generate-schedule.service';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { EmployeeToilRequest, TOIL } from '@app/shared/models/time-off-in-lieu/time-off-in-lieu.model';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";

@Component({
  selector: 'app-pending-toil-dialog',
  templateUrl: './pending-toil-dialog.component.html',
  styleUrls: ['./pending-toil-dialog.component.scss']
})
export class PendingToilDialogComponent implements OnInit {
  form: UntypedFormGroup;
  employeeToilRequest: EmployeeToilRequest;
  loadingWorkingHours: boolean;
  showDenialComment: boolean;
  workingHours: number;

  constructor(
      private fb: UntypedFormBuilder,
    private timeOffInLieuService: TimeOffInLieuService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<PendingToilDialogComponent>,
    private denialCommentsDialogRef: MatDialogRef<FormGeneratorDialogComponent>,
    private translate: TranslateService,
    private generateScheduleService: GenerateScheduleService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.employeeToilRequest = data.employeeToilRequest;
  }

  ngOnInit(): void {
    if(this.employeeToilRequest.hours === 0){
      this.getEmployeeWorkingHours();
    }
  }

  getEmployeeWorkingHours() {
    this.loadingWorkingHours = true;

    this.generateScheduleService.getEmployeeWorkingHours(this.employeeToilRequest.createdBy.id, this.employeeToilRequest.startDate, this.employeeToilRequest.endDate)
    .pipe(
      finalize(() => this.loadingWorkingHours = false)
    )
    .subscribe(
      (res) => {
        this.workingHours = res.workingHours;
        if(this.employeeToilRequest.halfDay === true){
          this.workingHours = this.workingHours / 2;
        }
      }
    );
  }

  openDenialCommentsDialog() {
    let formData = {
      id: this.employeeToilRequest.id,
      denialComments: null
    }

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formTitle: `${this.translate.instant('DenyEmployeeToilRequest')}`,
      formId: 'frm_fQjd61MICEhfsM',
      formData: formData
    };

    this.denialCommentsDialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.denialCommentsDialogRef.componentInstance.emitFormData.subscribe((event) => {
      this.deny(event)
    });
  }

  deny(denialComments){
    this.overlayService.show();

    this.timeOffInLieuService.denyRequest(this.employeeToilRequest.id, denialComments)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar(`${this.translate.instant('RequestDenied')}`, 'clear', 'success');
        this.dialogRef.close(true);
        this.denialCommentsDialogRef.close();
      }
    );
  }

  approve(){
    this.overlayService.show();

    this.timeOffInLieuService.approveRequest(this.employeeToilRequest.id)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar(`${this.translate.instant('RequestApproved')}`, 'clear', 'success');
        this.dialogRef.close(true);
      }
    );
  }

  close(){
    this.dialogRef.close();
  }

  formatTime(time) {
      const [hours, minutes] = time.split(':');
      return `${hours}:${minutes}`;
  }
}
