<ngx-skeleton-loader
    *ngIf="isLoading; else loaded"
    count="5"
    [theme]="{ 
    'height.px': 50
    }"
></ngx-skeleton-loader>

<ng-template #loaded>

    <div class="bottom-container">
        <div class="navigation-container" *ngIf="navigationPath.length > 1">
            <ng-container *ngFor="let directory of navigationPath; let firstItem = first;">
                <mat-icon *ngIf="!firstItem" class="material-icons-outlined">chevron_right</mat-icon>
                <div class="nav-link" (click)="navigateToDirectory(directory)">{{directory.name}}</div>
            </ng-container>
        </div>
    
        <div class="controls">
            <div class="buttons">
          <button mat-flat-button setColor="primaryColour" color="primary" (click)="emitShowDocumentUploadDialog(navigationPath[this.navigationPath.length - 1])">
                    <mat-icon class="material-icons-outlined">add</mat-icon> 
                    Add
                </button>
                <button mat-flat-button>
                    <mat-icon class="material-icons-outlined" (click)="emitShowCreateNewDirectoryForm(navigationPath[this.navigationPath.length - 1])">create_new_folder</mat-icon> 
                </button>
            </div>
            <div class="search">
                <mat-form-field>
                    <mat-label>Sort By</mat-label>
                    <mat-select [(value)]="selectedSortByOption" (selectionChange)="sort($event.value)">
                        <mat-option *ngFor="let option of sortByOptions" [value]="option">
                            {{option}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    
        <div class="directories" *ngIf="directories.length > 0">
            <div class="directories-container-label">
                Directories
            </div>
    
            <div class="directories-list">
                <ng-container *ngFor="let directory of directories">
                    <div class="directory">
                        <div class="directory-name" (click)="getDirectoryContents(directory)">
                            <img 
                            class="ms-icon" 
                            src="assets/icons/folder.svg" 
                            style="width: 30px;
                                    height: 30px;
                                    vertical-align: middle;"> 
                            <div>{{directory.name}}</div>
                        </div>
    
                        <div class="controls-container">
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon class="material-icons-outlined">more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="emitEditEvent(directory)">
                                    <mat-icon class="material-icons-outlined">edit</mat-icon> 
                                    <span>Edit</span>
                                </button>
                                <button mat-menu-item (click)="emitDeleteEvent(directory)">
                                    <mat-icon class="material-icons-outlined">delete</mat-icon> 
                                    <span>Delete</span>
                                </button>
                            </mat-menu> 
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="files" *ngIf="files.length > 0">
            <div class="directories-container-label">
                Files
            </div>
            <mat-expansion-panel hideToggle disabled [expanded]="false" *ngFor="let file of files">
                <mat-expansion-panel-header class="document-mat-expansion-panel-header">
                    <mat-panel-title>
                    <div class="title-container">
                        <div class="details-container">
                            <ng-container *ngIf="icons[file.contentType]; else blankIcon">
                                <img 
                                    class="ms-icon" 
                                    src={{icons[file.contentType]}} 
                                    style="width: 35px;
                                            height: 35px;
                                            margin-right: 10px;
                                            vertical-align: middle;"> 
                            </ng-container>
                            <ng-template #blankIcon>
                                <mat-icon class="blank-document-icon material-icons-outlined">description</mat-icon>
                            </ng-template>
                            <div class="document-label">
                                <div class="document-label-top" (click)="emitViewFileEvent(file)">
                                    <div class="title">{{file.name}}</div>
                                </div>
                                <div class="document-label-bottom">
                                    Created {{file.version.versionCreatedOn | date:'longDate'}} by {{file.version.versionCreatedBy.firstName}} {{file.version.versionCreatedBy.lastName}} ({{file.size}}kB)
                                </div>
                            </div>
                        </div>
                        <div class="controls-container">
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon class="material-icons-outlined">more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="emitDownloadEvent(file)">
                                    <mat-icon class="material-icons-outlined">download</mat-icon> 
                                    <span>Download</span>
                                </button>
                                <button mat-menu-item (click)="emitEditEvent(file)">
                                    <mat-icon class="material-icons-outlined">edit</mat-icon> 
                                    <span>Edit</span>
                                </button>
                                <button mat-menu-item (click)="emitDeleteEvent(file)">
                                    <mat-icon class="material-icons-outlined">delete</mat-icon> 
                                    <span>Delete</span>
                                </button>
                            </mat-menu>    
                        </div>
                    </div> 
                    </mat-panel-title>
                    <mat-panel-description></mat-panel-description>
                </mat-expansion-panel-header>
            </mat-expansion-panel>
        </div>
    </div>
</ng-template>