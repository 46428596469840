import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { Lookup } from '@app/modules/lookup/models/lookup.model';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EmploymentRecordOtherCompensation } from '../../models/employment-record-other-compensation.model';
import { EmploymentRecordOtherCompensationsService } from '../../services/employment-record-other-compensations.service';

@Component({
  selector: 'app-employment-record-other-compensation-dialog',
  templateUrl: './employment-record-other-compensation-dialog.component.html',
  styleUrls: ['./employment-record-other-compensation-dialog.component.scss']
})
export class EmploymentRecordOtherCompensationDialogComponent implements OnInit {
  form: UntypedFormGroup;
  employmentRecordId: any;
  employeeId: any;
  otherCompensationId: any;
  isLoading: boolean;
  employmentRecordOtherCompensation: EmploymentRecordOtherCompensation;
  compensationTypeOptions: Observable<Lookup[]>;
  paymentCycleOptions: Observable<Lookup[]>;
  currencyOptions: Observable<Lookup[]>;

  constructor(
    private fb: UntypedFormBuilder,
    private lookupService: LookupService,
    private overlayService: OverlayService,
    private employmentRecordOtherCompensationsService: EmploymentRecordOtherCompensationsService,
    private snackbarService: SnackbarService,
    private dialogRef: MatDialogRef<EmploymentRecordOtherCompensationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data)
  {
    this.employmentRecordId = data.employmentRecordId;
    this.employeeId = data.employeeId;
    this.otherCompensationId = data.OtherCompensationId;
  } 

  ngOnInit(): void {
    this.getLookups();

    this.otherCompensationId !== null 
      ? this.getEmploymentRecordOtherCompensation() 
      : this.isLoading = false; 
        this.createForm();
  }

  getEmploymentRecordOtherCompensation() {
    this.isLoading = true;
    
    this.employmentRecordOtherCompensationsService.getOtherCompensation(this.employeeId, this.employmentRecordId, this.otherCompensationId)
    .pipe(
      finalize(() => this.isLoading = false)
    )
    .subscribe(
      res => {
        this.employmentRecordOtherCompensation = res;
        this.createForm();
      }
    );
  }

  getLookups() {
    this.paymentCycleOptions = this.lookupService.getLookups("/lookup/OTHER_COMPENSATION_PAYMENT_CYCLE");
    this.compensationTypeOptions = this.lookupService.getLookups("/lookup/OTHER_COMPENSATION_TYPE");
    this.currencyOptions = this.lookupService.getLookups("/lookup/CURRENCY");
  }

  createForm() {
    this.form = this.fb.group({
      id: [this.employmentRecordOtherCompensation ? this.employmentRecordOtherCompensation.id : null],
      effectiveDate: [moment().format()],
      changeReason: [null],
      changeReasonComments: [null],
      amount: [this.employmentRecordOtherCompensation ? this.employmentRecordOtherCompensation.amount : null],
      percentage: [this.employmentRecordOtherCompensation ? this.employmentRecordOtherCompensation.percentage : null],
      startDate: [this.employmentRecordOtherCompensation ? this.employmentRecordOtherCompensation.startDate : null],
      endDate: [this.employmentRecordOtherCompensation ? this.employmentRecordOtherCompensation.endDate : null],
      currency: [this.employmentRecordOtherCompensation && this.employmentRecordOtherCompensation.currency ? this.employmentRecordOtherCompensation.currency.id : null],
      paymentCycle: [this.employmentRecordOtherCompensation && this.employmentRecordOtherCompensation.paymentCycle ? this.employmentRecordOtherCompensation.paymentCycle.id : null],
      compensationType: [this.employmentRecordOtherCompensation && this.employmentRecordOtherCompensation.compensationType ? this.employmentRecordOtherCompensation.compensationType.id : null]
    });
  }

  save(){
    this.overlayService.show();

    this.employmentRecordOtherCompensationsService.postOtherCompensation(this.employeeId, this.employmentRecordId, this.form.value)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar('Other Compensation saved successfully', 'clear', 'success');
        this.dialogRef.close(true);
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  close(){
    this.dialogRef.close();
  }

}

